define('freshfone-ember/components/integrations/add-to-existing-ticket/requester-autocomplete/component', ['exports', 'freshfone-ember/utils/call-constants', 'ember', 'ember-concurrency', 'freshfone-ember/config/environment', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin'], function (exports, _freshfoneEmberUtilsCallConstants, _ember, _emberConcurrency, _freshfoneEmberConfigEnvironment, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEqual = _ember['default'].isEqual;
  var isEmpty = _ember['default'].isEmpty;
  var $ = _ember['default'].$;

  var KEY_CODES = {
    UP_KEY_CODE: 38,
    DOWN_KEY_CODE: 40,
    ENTER_KEY_CODE: 13
  };

  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    currentCall: computed.alias('call'),
    searchTimeout: _freshfoneEmberConfigEnvironment['default'].environment === "test" ? 1 : 200,
    successfulTransfer: computed.equal('currentCall.status', 'transferred'),
    callCompleted: computed.equal('currentCall.status', 'completed'),
    callEnded: computed.or('callCompleted', 'successfulTransfer'),
    noSearchString: computed.empty('searchString'),
    doNotSearch: computed.or('noSearchString', 'searchStringIsSelectedRequester'),
    is1ClickIntegratedAccount: computed(function () {
      return this.hasGlobalFeature('integrated_desk_with_1cx') && this.checkFeature('integrated_desk_with_1cx');
    }),

    placeHolder: computed('searchField', function () {
      return this.get('searchField')['t-label'];
    }),

    showResults: computed('currentCall.status', {
      get: function get() {
        if (this.get('callEnded')) {
          return false;
        }
        return this;
      },
      set: function set(k, v) {
        return v;
      }
    }),

    searchStringIsSelectedRequester: computed('searchString', 'selectedRequester', function () {
      var requester = this.get('selectedRequester');
      if (isEmpty(requester)) {
        return;
      }
      return isEqual(this.get('searchString'), requester.get('name'));
    }),

    searchTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var selectedProduct, apiVersion, searchString;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('isSearching', true);
            selectedProduct = this.get('actionItem.integration.name');
            apiVersion = undefined;
            context$1$0.next = 5;
            return (0, _emberConcurrency.timeout)(this.get('searchTimeout'));

          case 5:
            searchString = this.get('searchString');

            if (!this.get('doNotSearch')) {
              context$1$0.next = 13;
              break;
            }

            this.set('showResults', false);
            this.set('isSearching', false);
            this.sendAction('setRequester', null);
            context$1$0.next = 12;
            return [];

          case 12:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 13:

            if (this.get('is1ClickIntegratedAccount')) {
              apiVersion = _freshfoneEmberUtilsCallConstants['default'].ONE_CLICK_INTEGRATION_API_VERSION;
            } else {
              apiVersion = _freshfoneEmberUtilsCallConstants['default'].CONTACT_SEARCH_API_VERISON;
            }

            this.get('store').query('external-contact', {
              filter: { queryString: searchString },
              product: selectedProduct,
              version: apiVersion
            }).then(function (result) {
              _this.set('showResults', true);
              _this.set('isSearching', false);
              _this.set('contactList', result);

              return result;
            });

          case 15:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('showResults', false);
    },

    highlightResult: function highlightResult(keyCode) {
      if ($('.requester-row')) {
        var currentEle = $('.requester-row.selected');
        var prev = currentEle.length ? currentEle.prev() : '';
        var next = currentEle.length ? currentEle.next() : '';
        switch (keyCode) {
          case KEY_CODES.UP_KEY_CODE:
            this.hoverPrevNextEle(currentEle, prev);
            break;
          case KEY_CODES.DOWN_KEY_CODE:
            this.hoverPrevNextEle(currentEle, next);
            break;
        }
      }
    },

    hoverPrevNextEle: function hoverPrevNextEle(currentEle, eleToHover) {
      if (currentEle.length) {
        if (eleToHover.length) {
          currentEle.toggleClass('selected');
          eleToHover.toggleClass('selected');
        }
      } else {
        $('.requester-row:last-child').addClass('selected');
      }
    },

    actions: {
      keyUpEvent: function keyUpEvent(text, str, event) {
        this.set('searchString', text);
        var event = event || window.event;
        if (event) {
          if (event.keyCode === KEY_CODES.ENTER_KEY_CODE) {
            $('.requester-row.selected').trigger('click');
            return;
          }
          if ([KEY_CODES.UP_KEY_CODE, KEY_CODES.DOWN_KEY_CODE].includes(event.keyCode)) {
            return this.highlightResult(event.keyCode);
          }
        }
        this.get('searchTask').perform();
      },
      selectRequester: function selectRequester(requester) {
        this.set('selectedRequester', requester);
        this.set('searchString', requester.get('name'));
        this.sendAction('setRequester', requester);
        this.set('showResults', false);
      }
    }
  });
});