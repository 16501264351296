define('freshfone-ember/components/call-logs/call-log-export/call-life-cycle-export/component', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, _ember, _emberComponentInboundActionsInboundActions) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_emberComponentInboundActionsInboundActions['default'], {
    currentUser: computed.alias('session.user'),
    exportAs: 'csv',
    csvAsDefault: true,
    exportFormat: computed.alias('savedExportConfiguration.export_to'),

    actions: {
      setCheckedValue: function setCheckedValue(value) {
        this.set('exportAs', value);
      },

      triggerExport: function triggerExport() {
        this.get('triggerExport')(this.get('exportAs'), 'events');
        this.get('closeCallLifeCycleExportSlider')();
      },

      closeModal: function closeModal() {
        this.get('closeCallLifeCycleExportSlider')();
      },

      openModal: function openModal() {
        this.set('showCallLifeCycleExport', true);
      }
    }
  });
});