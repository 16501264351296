define('freshfone-ember/helpers/time-ago', ['exports', 'ember'], function (exports, _ember) {
    exports.timeAgo = timeAgo;

    var momentjs = window.moment;

    function timeAgo(date) {
        date = new Date(date);
        return momentjs(date).fromNow();
    }

    exports['default'] = _ember['default'].Helper.helper(timeAgo);
});