define('freshfone-ember/components/billing-base/credit-base/saved-card/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    isAccountBlocked: computed.reads('session.account.blocked'),
    actions: {
      editCard: function editCard() {
        if (this.get('isAccountBlocked')) {
          return;
        }
        this.set('isChangeMode', true);
      }
    }
  });
});