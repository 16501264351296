define('freshfone-ember/serializers/deflection-bot', ['exports', 'freshfone-ember/serializers/application'], function (exports, _freshfoneEmberSerializersApplication) {

  var valuesToChange = ['conversation-end-action-type', 'agent-deflect-action-type', 'bot-failure-action-type', 'invalid-input-action-type'];

  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      valuesToChange.forEach(function (key) {
        json.data.attributes[key] = json.data.attributes[key].decamelize();
      });

      return json;
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (Ember.isArray(payload.data)) {
        payload.data.forEach(function (deflectionBot) {
          valuesToChange.forEach(function (key) {
            deflectionBot.attributes[key] = deflectionBot.attributes[key].camelize();
          });
        });
      } else {
        valuesToChange.forEach(function (key) {
          payload.data.attributes[key] = payload.data.attributes[key].camelize();
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});