define('freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    hasAgents: computed.notEmpty('agents'),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    currentAccount: computed.alias('session.account'),
    isOmnichannelEnabled: computed.alias('currentAccount.isOmnichannelEnabled'),
    agentsCount: computed.alias('agents.length'),
    isEnhancedLiveDashboard: computed(function () {
      return this.hasGlobalFeature('enhanced_live_dashboard');
    }),
    rowHeight: 63,
    componentHeight: 0,
    showAgents: true,
    isValidStatus: function isValidStatus() {
      return typeof this.get('status') === 'object';
    },
    hasPresenceToggleFeature: computed(function () {
      return this.hasFeatures(['agent_preference_toggle']);
    }),
    hasOmnichannelAgentStatusFeature: computed(function () {
      return this.checkFeature('omnichannel_agent_status') && this.get('isOmnichannelEnabled');
    }),
    agentTitle: computed('status', 'agentsCount', function () {
      var status = this.get('status');

      if (this.isValidStatus()) {
        return !status.get('default') && !this.get('hasOmnichannelAgentStatusFeature') ? {
          status: status.get('emoji') + ' ' + status.get('name'),
          count: '(' + this.get('agentsCount') + ')'
        } : {
          status: _ember['default'].i18n.t('live_dashboard.status.' + status.get('statusType')),
          count: '(' + this.get('agentsCount') + ')'
        };
      }

      return {
        status: _ember['default'].i18n.t('live_dashboard.status.no_agents'),
        count: '(' + this.get('agentsCount') + ')'
      };
    }),

    boxHeight: computed('agentsCount', 'componentHeight', function () {
      var extraPadding = 4;
      var boxHeight = this.rowHeight * this.get('agentsCount') + extraPadding;
      return boxHeight < this.get('componentHeight') ? boxHeight : this.get('componentHeight');
    }),

    statusDescription: computed('status', function () {
      var status = this.get('status.statusType') || this.get('status');

      if (this.get('isEnhancedLiveDashboard')) {
        return _ember['default'].i18n.t('live_dashboard.description.enhanced');
      }

      if (status === 'online' || status === 'offline') {
        return _ember['default'].i18n.t('live_dashboard.description.' + status);
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var headerHeight = 80;

      run.scheduleOnce('afterRender', this, function () {
        this.set('componentHeight', this.$().parents('.agents-list.content-wrapper').height() - headerHeight);
      });
    },

    actions: {
      toggleAgentsList: function toggleAgentsList() {
        this.toggleProperty('showAgents');
      },

      toggleAgentStatus: function toggleAgentStatus(agent, isAvailable) {
        var defualtStatuses = this.store.peekAll('agent-status').filterBy('default', true);

        var _defualtStatuses$filterBy = defualtStatuses.filterBy('statusSubType', 'online');

        var _defualtStatuses$filterBy2 = _slicedToArray(_defualtStatuses$filterBy, 1);

        var statusOnline = _defualtStatuses$filterBy2[0];

        var _defualtStatuses$filterBy3 = defualtStatuses.filterBy('statusSubType', 'offline');

        var _defualtStatuses$filterBy32 = _slicedToArray(_defualtStatuses$filterBy3, 1);

        var statusOffline = _defualtStatuses$filterBy32[0];

        agent.set('browserStatusId', isAvailable ? statusOffline.get('id') : statusOnline.get('id'));
        agent.save();
      }
    }

  });
});