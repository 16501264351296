define('freshfone-ember/components/banner-component/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var testing = _ember['default'].testing;

  // eslint-disable-next-line max-len
  var TLS_ARTICLE_LINK = 'https://support.freshcaller.com/en/support/solutions/articles/50000006024--security-update-enabling-tls-1-2-strong-cipher-suites';
  // eslint-disable-next-line max-len
  var WHITELIST_ARTICLE_LINK = 'https://crmsupport.freshworks.com/en/support/solutions/articles/50000005782-network-requirements-and-recommendations?cloud=freshcaller';exports['default'] = Component.extend({
    isAppBeingTested: computed(function () {
      return testing;
    }),
    articleLink: TLS_ARTICLE_LINK,
    whitelistArticleLink: WHITELIST_ARTICLE_LINK,
    actions: {
      dismissBanner: function dismissBanner() {
        this.set('showBanner', false);
      }
    }
  });
});