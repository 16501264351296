define('freshfone-ember/constants/transfer-types', ['exports'], function (exports) {
  exports['default'] = Object.freeze({
    CsatIvr: 'csat_ivr',
    AgentConference: 'agent_conference',
    Team: 'team',
    Agent: 'agent',
    ExternalConference: 'external_conference',
    Contact: 'contact',
    Group: 'group'
  });
});