define('freshfone-ember/mixins/flash-mixin', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var later = _ember['default'].run.later;
  exports['default'] = Mixin.create({
    flash: function flash() {
      var _this = this;

      var timeout = arguments.length <= 0 || arguments[0] === undefined ? 2000 : arguments[0];

      this.set('isFlash', true);

      later(function () {
        if (!_this.isDestroyed) {
          _this.set('isFlash');
        }
      }, timeout);
    }
  });
});