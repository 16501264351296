define('freshfone-ember/helpers/features', ['exports', 'ember'], function (exports, _ember) {
    exports.features = features;

    function features(feature, option) {
        if (_ember['default'].i18n.t('subscription.coming_soon_features').string.trim().includes(feature[0].trim())) {
            return new _ember['default'].Handlebars.SafeString(feature + '<span class="coming-soon-feature"> COMING SOON </span>');
        } else if (_ember['default'].i18n.t('subscription.preview_features').string.trim().includes(feature[0].trim())) {
            return new _ember['default'].Handlebars.SafeString(feature + '<span class="beta-feature"> PREVIEW </span>');
        } else {
            return feature;
        }
    }

    exports['default'] = _ember['default'].Helper.helper(features);
});