define('freshfone-ember/models/team-setting', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    configurations: attr({
      defaultValue: {
        'call-parking': false,
        'ring-busy-agents': false,
        'concurrent-incoming-calls': '',
        'default-parked-calls': 3,
        'max-parked-calls': 3
      }
    }),
    teamId: attr()
  });
});