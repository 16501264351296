define('freshfone-ember/serializers/agent-extension', ['exports', 'freshfone-ember/serializers/application'], function (exports, _freshfoneEmberSerializersApplication) {

  var valuesToChange = ['offline-action-type', 'no-answer-action-type', 'busy-action-type', 'no-input-action-type', 'invalid-input-action-type', 'no-extension-action-type'];

  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      valuesToChange.forEach(function (key) {
        json.data.attributes[key] = json.data.attributes[key].decamelize();
      });

      return json;
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!Ember.testing) {
        if (Ember.isArray(payload.data)) {
          payload.data.forEach(function (agentExtension) {
            valuesToChange.forEach(function (key) {
              agentExtension.attributes[key] = agentExtension.attributes[key].camelize();
            });
          });
        } else {
          valuesToChange.forEach(function (key) {
            payload.data.attributes[key] = payload.data.attributes[key].camelize();
          });
        }
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});