define('freshfone-ember/constants/call-status', ['exports'], function (exports) {
  exports['default'] = Object.freeze({
    Completed: 'completed',
    Transferring: 'transferring',
    Transferred: 'transferred',
    TransferUnavailable: 'transfer unavailable',
    WarmTransferring: 'warm transferring',
    WarmTransferred: 'warm transferred',
    WarmTransferUnavailable: 'warm transfer unavailable',
    InProgress: 'in-progress',
    Queued: 'queued',
    QueuedRinging: 'queued-ringing',
    Abandoned: 'abandoned',
    Onhold: 'on-hold',
    ParallelCallInitiated: 'parallel call initiated',
    ParallelCallAccepted: 'parallel call accepted',
    ParallelCallCancelling: 'parallel call cancelling',
    ParallelCallCanceled: 'parallel call canceled',
    CallInProgress: 'in progress',
    CallEndedStatus: ['completed', 'no-answer', 'missed call', 'call failed', 'call canceled', 'queued', 'blocked', 'voicemail', 'restricted', 'recently-completed', 'voicemail_inprogress', 'abandoned', 'Wait queue timedout', 'bot_inprogress'],
    Concurrent: 'concurrency'
  });
});