define('freshfone-ember/components/data-automated-routing/form/data-routing-rules/import-rules/rules-form/rules-table/row/component', ['exports', 'ember', 'freshfone-ember/mixins/find-or-fetch-mixin', 'freshfone-ember/constants/routing-automation'], function (exports, _ember, _freshfoneEmberMixinsFindOrFetchMixin, _freshfoneEmberConstantsRoutingAutomation) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var typeOf = _ember['default'].typeOf;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFindOrFetchMixin['default'], {
    tagName: 'tr',
    closeModalFn: function closeModalFn() {
      this.set('confirm', false);
    },
    init: function init() {
      this._super.apply(this, arguments);
      document.addEventListener(_freshfoneEmberConstantsRoutingAutomation.IMPORT_MODAL_PARAMS.close, this.closeModalFn.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('confirm', true);
      document.removeEventListener(_freshfoneEmberConstantsRoutingAutomation.IMPORT_MODAL_PARAMS.close, this.closeModalFn.bind(this));
    },

    actionOption: computed('rule.actionType', {
      get: function get() {
        return this.get('actionOptions').findBy('name', this.get('rule.actionType'));
      },
      set: function set(_, v) {
        this.setProperties({
          'rule.actionType': v.name,
          'optionAction': null
        });

        return v;
      }
    }),
    optionActions: computed('modelName', function () {
      return this.findOrFetchAll(this.get('modelName'));
    }),
    modelName: computed('optionAction', function () {
      var actionType = this.get('rule.actionType'),
          messages = ['hangup', 'vm'];

      if (messages.includes(actionType) || !actionType) {
        return 'message';
      }

      return actionType;
    }),
    optionAction: computed('rule.actionType', {
      // eslint-disable-next-line no-empty-function
      get: function get() {
        return this.findOrFetch(this.get('modelName'), this.get('rule.actionId'));
      },
      set: function set(_, action) {
        if (action) {
          this.set('rule.actionId', action.get('id'));
          this.send('updateRule');
        }

        return action;
      }
    }),

    actions: {
      updateRule: function updateRule() {
        var _this = this;

        this.get('rule').save()['catch'](function () {
          return _this.get('rule').rollbackAttributes();
        });
      },
      deleteRule: function deleteRule() {
        if (typeOf(this.get('onDelete')) === 'function') {
          this.get('onDelete')(this.get('rule'));
        }
      }
    }
  });
});