define("freshfone-ember/components/sip-component/sip-form/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 1
            },
            "end": {
              "line": 4,
              "column": 1
            }
          },
          "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          dom.setAttribute(el1, "class", "sidebar-title");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "title", ["loc", [null, [3, 28], [3, 37]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 5
                },
                "end": {
                  "line": 16,
                  "column": 5
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "text-field", [], ["class", "text-field", "label", ["subexpr", "t", ["entityNames.agent"], [], ["loc", [null, [12, 13], [12, 36]]], 0, 0], "value", ["subexpr", "@mut", [["get", "agent.name", ["loc", [null, [13, 13], [13, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [14, 14], [14, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", true], ["loc", [null, [10, 6], [15, 22]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 31,
                      "column": 7
                    },
                    "end": {
                      "line": 33,
                      "column": 7
                    }
                  },
                  "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("								");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "icon-group all-agents");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 33,
                      "column": 7
                    },
                    "end": {
                      "line": 35,
                      "column": 7
                    }
                  },
                  "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("								");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "custom-elements/avatar", [], ["class", "avatar", "type", "user", "model", ["subexpr", "@mut", [["get", "option", ["loc", [null, [34, 66], [34, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "charLimit", 1, "text", ["subexpr", "@mut", [["get", "option.name", ["loc", [null, [34, 90], [34, 101]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [34, 8], [34, 113]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 6
                  },
                  "end": {
                    "line": 37,
                    "column": 6
                  }
                },
                "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("							");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "option-value");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "if", [["get", "agent.isAllAgents", ["loc", [null, [31, 13], [31, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [31, 7], [35, 14]]]], ["content", "option.name", ["loc", [null, [36, 34], [36, 49]]], 0, 0, 0, 0]],
              locals: ["option"],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 5
                },
                "end": {
                  "line": 38,
                  "column": 5
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "custom-elements/power-select", [], ["class", "sip-disabled-agents", "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [19, 14], [19, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "if", [["get", "startValidate", ["loc", [null, [20, 18], [20, 31]]], 0, 0, 0, 0], ["get", "errors.agent", ["loc", [null, [20, 32], [20, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 14], [20, 45]]], 0, 0], "loadingMessage", ["subexpr", "t", ["outgoing_widget.loading"], [], ["loc", [null, [21, 24], [21, 53]]], 0, 0], "noMatchesMessage", ["subexpr", "t", ["entityNames.no_match"], ["entityName", "agents"], ["loc", [null, [22, 26], [22, 72]]], 0, 0], "isSearchEnabled", "true", "searchField", "name", "attributeName", "id", "searchPlaceholder", "Agent search", "label", ["subexpr", "t", ["entityNames.agent"], [], ["loc", [null, [27, 13], [27, 36]]], 0, 0], "options", ["subexpr", "@mut", [["get", "nonSIPAgents", ["loc", [null, [28, 15], [28, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "onSearch", ["subexpr", "action", ["findSIPDisabledAgents"], [], ["loc", [null, [29, 18], [29, 50]]], 0, 0], "value", ["subexpr", "@mut", [["get", "agent", ["loc", [null, [30, 13], [30, 18]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [17, 6], [37, 39]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 5
                },
                "end": {
                  "line": 49,
                  "column": 5
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("						");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "text-field", [], ["class", "text-field", "label", ["subexpr", "t", ["sip.sipForm.sipStatusFieldLabel"], [], ["loc", [null, [45, 13], [45, 50]]], 0, 0], "value", ["subexpr", "t", ["sip.status.never"], [], ["loc", [null, [46, 13], [46, 35]]], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [47, 14], [47, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", true], ["loc", [null, [43, 6], [48, 22]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 6
                  },
                  "end": {
                    "line": 59,
                    "column": 6
                  }
                },
                "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("							");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "option-value");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["content", "status.label", ["loc", [null, [58, 34], [58, 50]]], 0, 0, 0, 0]],
              locals: ["status"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 5
                },
                "end": {
                  "line": 60,
                  "column": 5
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "custom-elements/power-select", [], ["class", "sip-status", "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [52, 14], [52, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "if", [["get", "startValidate", ["loc", [null, [53, 18], [53, 31]]], 0, 0, 0, 0], ["get", "errors.agent.sipStatus", ["loc", [null, [53, 32], [53, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 14], [53, 55]]], 0, 0], "options", ["subexpr", "@mut", [["get", "agent.sipStatuses", ["loc", [null, [54, 15], [54, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "attributeName", "value", "value", ["subexpr", "@mut", [["get", "agent.sipStatusText", ["loc", [null, [56, 13], [56, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "t", ["sip.sipForm.sipStatusFieldLabel"], [], ["loc", [null, [57, 13], [57, 50]]], 0, 0]], 0, null, ["loc", [null, [50, 6], [59, 39]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 63,
                  "column": 4
                },
                "end": {
                  "line": 69,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("					");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "secondary-action");
              var el2 = dom.createTextNode("\n						");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n					");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "common/act-and-alert", [], ["icon", "icon-send-activation-mail", "shouldAlert", ["subexpr", "@mut", [["get", "shouldAlert", ["loc", [null, [65, 74], [65, 85]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "agent", ["loc", [null, [66, 13], [66, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "successMessage", ["subexpr", "t", ["sip.sipForm.credentialsSuccessMessage"], [], ["loc", [null, [66, 34], [66, 77]]], 0, 0], "text", ["subexpr", "t", ["sip.sipForm.resendSIPCredentials"], [], ["loc", [null, [67, 12], [67, 50]]], 0, 0], "clickCallback", ["subexpr", "action", ["resendSIPCredentials"], [], ["loc", [null, [67, 65], [67, 96]]], 0, 0]], ["loc", [null, [65, 6], [67, 98]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 4
                },
                "end": {
                  "line": 75,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("					");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "button-row");
              var el2 = dom.createTextNode("\n						");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "btn btn-primary");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n					");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["save"], [], ["loc", [null, [73, 38], [73, 55]]], 0, 0], ["inline", "if", [["get", "agent.sipEnabled", ["loc", [null, [73, 61], [73, 77]]], 0, 0, 0, 0], ["subexpr", "t", ["sip.sipForm.saveChanges"], [], ["loc", [null, [73, 78], [73, 107]]], 0, 0], ["subexpr", "t", ["sip.sipForm.addNewSIPCredentials"], [], ["loc", [null, [73, 108], [73, 146]]], 0, 0]], [], ["loc", [null, [73, 56], [73, 148]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 3
              },
              "end": {
                "line": 76,
                "column": 3
              }
            },
            "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("				");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n				");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(fragment, [3]);
            var element3 = dom.childAt(fragment, [5]);
            var morphs = new Array(8);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            morphs[2] = dom.createAttrMorph(element2, 'class');
            morphs[3] = dom.createMorphAt(element2, 1, 1);
            morphs[4] = dom.createAttrMorph(element3, 'class');
            morphs[5] = dom.createMorphAt(element3, 0, 0);
            morphs[6] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[7] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["role content-row ", ["subexpr", "unless", [["get", "agent.sipEnabled", ["loc", [null, [8, 42], [8, 58]]], 0, 0, 0, 0], "drop-down"], [], ["loc", [null, [8, 33], [8, 72]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "agent.sipEnabled", ["loc", [null, [9, 11], [9, 27]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [9, 5], [38, 12]]]], ["attribute", "class", ["concat", ["role content-row ", ["subexpr", "if", [["get", "agent", ["loc", [null, [41, 38], [41, 43]]], 0, 0, 0, 0], "drop-down"], [], ["loc", [null, [41, 33], [41, 57]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "unless", [["get", "agent", ["loc", [null, [42, 15], [42, 20]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [42, 5], [60, 16]]]], ["attribute", "class", ["concat", ["sip-disclaimer ", ["subexpr", "if", [["get", "isDisclaimerVisible", ["loc", [null, [62, 36], [62, 55]]], 0, 0, 0, 0], "enabled"], [], ["loc", [null, [62, 31], [62, 67]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["sip.sipForm.disclaimerMessage"], [], ["loc", [null, [62, 69], [62, 106]]], 0, 0], ["block", "if", [["get", "agent.sipEnabled", ["loc", [null, [63, 10], [63, 26]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [63, 4], [69, 11]]]], ["block", "if", [["get", "displayFormSaveButton", ["loc", [null, [71, 10], [71, 31]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [71, 4], [75, 11]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 1
            },
            "end": {
              "line": 78,
              "column": 1
            }
          },
          "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content-form agent-form with-content-border");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("		");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "ui-form", [], ["for", ["subexpr", "@mut", [["get", "agent", ["loc", [null, [7, 18], [7, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "onValidationFailed", "errorFieldFocus", "startValidate", ["subexpr", "@mut", [["get", "startValidate", ["loc", [null, [7, 75], [7, 88]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [7, 3], [76, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/sip-component/sip-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createMorphAt(element4, 1, 1);
        morphs[2] = dom.createMorphAt(element4, 2, 2);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "slideToggle", ["loc", [null, [1, 17], [1, 28]]], 0, 0, 0, 0], "opened", "closed"], [], ["loc", [null, [1, 12], [1, 48]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "title", ["loc", [null, [2, 7], [2, 12]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 1], [4, 8]]]], ["block", "if", [["get", "slideToggle", ["loc", [null, [5, 7], [5, 18]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [5, 1], [78, 8]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});