define('freshfone-ember/routes/protected/admin/account-settings', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var alias = _ember['default'].computed.alias;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    i18n: service(),
    accountConfigurationService: service('account-configuration'),
    accountConfiguration: alias('accountConfigurationService.config'),
    pageLoadedInCrm: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm') && this.get('isCrmView');
    }),
    breadCrumb: computed(function () {
      return {
        title: this.get('i18n').t('admin_components.' + (this.get('pageLoadedInCrm') ? 'additional_settings' : 'account_settings')).string
      };
    }),
    titleToken: computed(function () {
      return this.get('i18n').t('admin_components.' + (this.get('pageLoadedInCrm') ? 'additional_settings' : 'account_settings')).string;
    }),
    actions: {
      willTransition: function willTransition() {
        var accountConfig = this.get('accountConfiguration');

        if (accountConfig._internalModel.hasChangedAttributes()) {
          accountConfig.rollbackAttributes();
        }
      }
    }
  });
});