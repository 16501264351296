define('freshfone-ember/services/hangup-event', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var $ = _ember['default'].$;
  exports['default'] = Service.extend({
    registerHangUpEvent: function registerHangUpEvent(params) {
      $.ajax({
        url: '/hangup_event',
        type: 'post',
        dataType: 'json',
        data: params
      }).fail(function (err) {
        // eslint-disable-next-line no-console
        console.log('Error publishing hangup by agent', err);
      });
    }
  });
});