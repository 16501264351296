define('freshfone-ember/models/custom-power-dialer', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    createdAt: attr(),
    status: attr('number'),
    leftContacts: attr(),
    totalContacts: attr(),
    contact: belongsTo('contact', { async: false })
  });
});