define('freshfone-ember/components/freshchat-widget/component', ['exports', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/utils/subscription-constants', 'freshfone-ember/mixins/safe-set'], function (exports, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberUtilsSubscriptionConstants, _freshfoneEmberMixinsSafeSet) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var service = _ember['default'].inject.service;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend(_freshfoneEmberMixinsSafeSet['default'], {
    session: service(),
    i18n: service(),
    currentUser: alias('session.user'),
    currentAccount: alias('session.account'),
    currentSubscription: alias('currentAccount.subscription'),
    currentChannel: computed('currentAccount.isTrial', 'currentSubscription.mrr', function () {
      if (this.get('currentSubscription.mrr') < _freshfoneEmberUtilsSubscriptionConstants['default'].MRR_TO_COMPARE) {
        return _freshfoneEmberConfigEnvironment['default'].FRESHCHAT.channels[2];
      }
      return _freshfoneEmberConfigEnvironment['default'].FRESHCHAT.channels[+this.get('currentAccount.isTrial')];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      $.getScript(_freshfoneEmberConfigEnvironment['default'].FRESHCHATV2.OldChatWidgetScriptUrl).then(function () {
        _this.closeWidget = _this.closeWidget.bind(_this);
        if (window.fcWidget) {
          var styles = '\n        #fc_frame {\n          left: 60px !important;\n          bottom: 50px !important;\n          z-index: 1049 !important;\n        \n          &.expanded {\n            left: 0 !important;\n            bottom: 0 !important;\n          }\n        \n          &.h-open-notify {\n            left: 64px !important;\n          }\n        }',
              stylesFreshchatWidget = document.createElement('style');

          stylesFreshchatWidget.innerText = styles;
          document.head.appendChild(stylesFreshchatWidget);
          _this.set('widget', window.fcWidget);
          _this.bootstrapFreshchat();
        }
      });
    },
    bootstrapFreshchat: function bootstrapFreshchat() {
      this.registerWidgetEvents();
      this.assignUserIdentifiers();
      this.enrouteChannel();
      this.assignCSATConfigs();
      this.get('widget').init(_freshfoneEmberConfigEnvironment['default'].FRESHCHAT.config);
    },
    registerWidgetEvents: function registerWidgetEvents() {
      var widget = this.get('widget');
      widget.on('widget:loaded', this.widgetLoadedHandler.bind(this));
      widget.on('widget:opened', this.widgetOpenedHandler.bind(this));
      widget.on('widget:closed', this.widgetClosedHandler.bind(this));
      widget.on('unreadCount:notify', this.notifyUnreadMessagesHandler.bind(this));
    },
    widgetLoadedHandler: function widgetLoadedHandler() {
      var _this2 = this;

      var widget = this.get('widget');
      later(function () {
        return _this2.set('widgetReady', true);
      });
      widget.user.setProperties(this.constructUserProps());
      widget.user.get()['catch'](function () {
        return widget.on('user:created', _this2.saveRestoreID.bind(_this2));
      });
    },
    saveRestoreID: function saveRestoreID(user) {
      //TODO uncomment the below lines once backend is configured to store restoreId
      // this.set('currentUser.restoreId', user.data.restoreId);
      // this.get('currentUser').save();
    },
    closeWidget: function closeWidget() {
      this.get('widget').close();
    },
    widgetOpenedHandler: function widgetOpenedHandler() {
      var _this3 = this;

      document.addEventListener('click', this.closeWidget);
      this.set('unreadMessagesCount', 0);
      later(function () {
        return _this3.set('widgetOpened', true);
      });
    },
    widgetClosedHandler: function widgetClosedHandler() {
      document.removeEventListener('click', this.closeWidget);
      this.set('widgetOpened', false);
    },
    notifyUnreadMessagesHandler: function notifyUnreadMessagesHandler(messages) {
      if (!this.get('widgetOpened')) this.set('unreadMessagesCount', messages.count);
    },
    constructUserProps: function constructUserProps() {
      return {
        'firstName': this.get('currentUser.name'),
        'freshcaller-account-state': this.get('currentSubscription.statusText'),
        'freshcaller-signup-date': this.get('currentAccount.created_at'),
        'freshcaller-plan': this.get('currentAccount.plan_name'),
        'freshcaller-account-id': this.get('currentAccount.id'),
        'freshcaller-account-url': this.get('currentAccount.full_domain'),
        'freshcaller-agent-count': this.get('currentAccount.active_users_count'),
        'freshcaller-admin': this.get('currentUser.isAdmin') || this.get('currentUser.isAccountAdmin'),
        //TODO uncomment below lines once we get these values from backend
        // 'first-invoice-date'        : this.get('currentSubscription.firstInvoiceDate'),
        'MRR': this.get('currentSubscription.mrr')
      };
    },
    assignUserIdentifiers: function assignUserIdentifiers() {
      _freshfoneEmberConfigEnvironment['default'].FRESHCHAT.config.externalId = this.get('currentUser.email');
      _freshfoneEmberConfigEnvironment['default'].FRESHCHAT.config.restoreId = this.get('currentUser.restoreId');
    },
    enrouteChannel: function enrouteChannel() {
      _freshfoneEmberConfigEnvironment['default'].FRESHCHAT.config.tags = ['public', this.get('currentChannel')];
    },
    assignCSATConfigs: function assignCSATConfigs() {
      _freshfoneEmberConfigEnvironment['default'].FRESHCHAT.config.config.content = {
        actions: {
          csat_no: '' + this.get('i18n').t('freshchat.csat_no'),
          csat_yes: '' + this.get('i18n').t('freshchat.csat_yes')
        },
        headers: { csat_question: '' + this.get('i18n').t('freshchat.csat_question') }
      };
    },
    actions: {
      openWidget: function openWidget() {
        document.getElementById('fc_frame').style.visibility = 'visible';
        this.get('widget').open();
      }
    }
  });
});