define('freshfone-ember/mixins/validations/server-errors', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _ember['default'].Mixin.create({
    serverErrors: null,
    handleError: function handleError(reason) {
      if (reason) {
        return this.set('serverErrors', reason.errors);
      }
      this.set('serverErrors');
    },
    handleAsModelError: function handleAsModelError(errors, object) {
      this.get('serverErrors').forEach(function (e) {
        var k = e.source.pointer.split("/").get("lastObject");
        object.get("errors").set(k.camelize(), e.detail);
      });
    },
    serverErrorText: computed('serverErrors', function () {
      var errors = {};
      if (isPresent(this.get('serverErrors'))) {
        this.get('serverErrors').forEach(function (e) {
          var k = e.source.pointer.split("/").get("lastObject");
          errors[k.camelize()] = e.detail;
        });
      }
      return errors;
    }),
    serverErrorAttribute: computed('serverErrors', function () {
      var _this = this;

      var errors = [];
      Object.keys(this.get('serverErrorText')).forEach(function (key) {
        errors.push(_this.get('serverErrorText')[key]);
      });
      return errors;
    })
  });
});