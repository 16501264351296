define('freshfone-ember/components/todo-component/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend({

    saveNewTask: function saveNewTask(newTask) {
      var _this = this;

      newTask.save().then(function (task) {
        newTask.set('id', task.id);
        _this.set('taskBody', '');

        return task;
      });
    },

    actions: {
      saveNewTask: function saveNewTask(taskBody) {
        if (isEmpty(taskBody)) {
          return;
        }

        var newTask = this.store.createRecord('todo', {
          user: this.get('user'),
          body: taskBody
        });

        this.saveNewTask(newTask);
      },

      deleteTask: function deleteTask(task) {
        task.destroyRecord();
      }
    }
  });
});