define('freshfone-ember/components/manage-intents/component', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    notification: service('toastr'),
    store: service('store'),
    importService: service('import-service'),
    showImportModal: computed.alias('importService.importParams.showImportModal'),
    fileModel: computed.alias('importService.importParams.fileModel'),
    params: computed.alias('importService.importParams.params'),
    classNameBindings: ['activeTabName'],
    activeTabName: 'view-edit-intent',
    openCreateModal: false,
    listLoading: true,
    intentsList: {},
    oldIntentName: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.getBot();
    },

    tabs: [{
      displayName: _ember['default'].i18n.t('speech_ivr.manage_intents.view_and_edit_intent'),
      name: 'view-edit-intent'
    }, {
      displayName: _ember['default'].i18n.t('speech_ivr.manage_intents.review_customer_phrase'),
      name: 'review-customer-phrases'
    }],

    isGeneralTabActive: computed.equal('activeTabName', 'view-edit-intent'),

    toggleTab: function toggleTab(tabName) {
      if (this.get('activeTabName') !== tabName) {
        this.send('setActiveTabName', tabName);
      }
    },

    getBot: function getBot() {
      var bot = this.store.peekAll('speech-ivr-bot').filterBy('name', this.get('botName'))[0] || this.store.createRecord('speechIvrBot', { 'name': this.get('botName') });
      this.set('bot', bot);
      this.get('getListOfIntents').perform();
    },

    getListOfIntents: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var intentsList;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('bot').getIntentsList({ "bot_type": this.get('botName') });

          case 2:
            intentsList = context$1$0.sent;

            this.set('listLoading', false);
            if (intentsList.intent) {
              (function () {
                var intents = intentsList.intent;
                _this.store.unloadAll('intent');
                Object.keys(intents).forEach(function (intent) {
                  var phrases = intents[intent];
                  _this.send('createIntentModel', intent, phrases);
                });
              })();
            } else {
              this.set('hasEmptyList', true);
            }

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    actions: {
      setActiveTabName: function setActiveTabName(name) {
        this.set('activeTabName', name);
      },

      openCreateIntent: function openCreateIntent(intent) {
        var isEditMode = intent ? true : false;
        var title = intent ? _ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.edit_title') : _ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.create_title');
        this.setProperties({
          isEditMode: isEditMode,
          title: title,
          openCreateModal: true,
          intentToEdit: intent,
          oldIntentName: intent ? intent.get('name') : '',
          phraseList: intent ? intent.get('phrases') : []
        });
      },

      closeCreateIntent: function closeCreateIntent() {
        this.set('openCreateModal', false);
      },

      createIntentModel: function createIntentModel(intent, phrases) {
        var _this2 = this;

        var createdPhrases = [];
        phrases.forEach(function (phrase) {
          createdPhrases.push(_this2.store.createRecord('phrase', { 'name': phrase }));
        });
        this.store.createRecord('intent', { 'name': intent, 'phrases': createdPhrases });
      },

      deletePhraseCallback: function deletePhraseCallback(intent, phrases) {
        var _this3 = this;

        var isEditModalDelete = this.get('openCreateModal') ? true : false;
        this.setProperties({
          'openCreateModal': false,
          'isDeleting': true
        });
        var intentToDelete = {},
            params = {};
        intentToDelete[intent.get('name')] = { "data": [phrases.get('name')], "request_type": "delete" };
        params = { "bot_type": this.get('botName'), "examples": intentToDelete };

        this.get('bot').updateBot(params).then(function (response) {
          run(function () {
            if (response.message && response.message.model_retraining) {
              phrases.destroyRecord().then(function () {
                _this3.set('showDeleteModal', false);
                if (isEditModalDelete) {
                  _this3.send('openCreateIntent', intent, _this3.get('intentsList')[intent]);
                }
              });
            }
          });
        })['catch'](function (error) {
          _this3.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.delete_phrase.error_message'));
        })['finally'](function () {
          _this3.set('isDeleting', false);
        });
      },

      deleteIntentCallback: function deleteIntentCallback(intent) {
        var _this4 = this;

        this.set('isDeleting', true);
        var phraseList = [],
            intentToDelete = {},
            params = {};
        intent.get('phrases').toArray().forEach(function (phrase) {
          phraseList.push(phrase.get('name'));
        });
        intentToDelete[intent.get('name')] = { "data": phraseList, "request_type": "delete" };
        params = { "bot_type": this.get('botName'), "examples": intentToDelete };
        this.get('bot').updateIntents(params).then(function (response) {
          run(function () {
            if (response.message && response.message.model_retraining) {
              intent.destroyRecord().then(function () {
                _this4.setProperties({
                  'showDeleteModal': false,
                  'openCreateModal': false
                });
              });
            }
          });
        })['catch'](function (error) {
          _this4.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.delete_intent.error_message'));
        })['finally'](function () {
          _this4.set('isDeleting', false);
        });
      },
      showImport: function showImport() {
        this.setProperties({
          fileModel: 'bot_intents',
          params: { bot_type: this.get('botName') },
          showImportModal: true
        });
      }
    }
  });
});