define('freshfone-ember/helpers/event-icon', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.eventIcon = eventIcon;
  var Helper = _ember['default'].Helper;

  var iconHash = Object.freeze({
    ringing: ['icon', 'call'].join('-'),
    customer_accept: ['icon', 'call'].join('-'),
    answered: ['icon', 'call'].join('-'),
    busy: ['icon', 'call-busy'].join('-'),
    cancelled: ['icon', 'call-cancelled'].join('-'),
    acw_time_out: ['icon', 'time-out'].join('-'),
    queue_wait_timeout: ['icon', 'time-out'].join('-'),
    timedout: ['icon', 'time-out'].join('-'),
    agent_conference_initiated: ['icon', 'add-contact'].join('-'),
    agent_extension_initiated: ['icon', 'add-contact'].join('-'),
    call_queue_started: ['icon', 'hourglass'].join('-'),
    call_transcription_started: ['icon', 'post-call-transcript'].join('-'),
    call_transcription_ended: ['icon', 'post-call-transcript'].join('-'),
    call_transcription_failed: ['icon', 'transcription-failed'].join('-'),
    callback_requested: ['icon', 'callback'].join('-'),
    callback_initiated: ['icon', 'callback'].join('-'),
    deflection_bot_initiated: ['icon', 'deflection-bot'].join('-'),
    deflection_bot_ended: ['icon', 'deflection-bot'].join('-'),
    deflection_bot_responded: ['icon', 'deflection-bot'].join('-'),
    hangup: ['icon', 'call-end'].join('-'),
    csat_initiated: ['icon', 'csat'].join('-'),
    csat_ended: ['icon', 'csat'].join('-'),
    hangup_initiated: ['icon', 'call-end'].join('-'),
    routing_automation_initiated: ['icon', 'data-automated-routing'].join('-'),
    routing_automation_responded: ['icon', 'data-automated-routing'].join('-'),
    routing_automation_ended: ['icon', 'data-automated-routing'].join('-'),
    voicemail_initiated: ['icon', 'call-voicemail'].join('-'),
    voicemail_transcription_started: ['icon', 'call-voicemail'].join('-'),
    voicemail_transcription_ended: ['icon', 'call-voicemail'].join('-'),
    queue_full: ['icon', 'queued'].join('-'),
    enqueued: ['icon', 'queued'].join('-'),
    dequeued: ['icon', 'dequeued'].join('-'),
    speech_ivr_ended: ['icon', 'speech-ivr'].join('-'),
    speech_ivr_responded: ['icon', 'speech-ivr'].join('-'),
    speech_ivr_initiated: ['icon', 'speech-ivr'].join('-'),
    manually_recorded: ['icon', 'record-btn'].join('-'),
    recording_resumed: ['icon', 'record-btn'].join('-'),
    recording_started: ['icon', 'record-btn'].join('-'),
    hold: ['icon', 'call-hold'].join('-'),
    ignored: ['icon', 'ignored'].join('-'),
    ivr_initiated: ['icon', 'basic-ivr'].join('-'),
    ivr_fallback_initiated: ['icon', 'basic-ivr'].join('-'),
    ivr_input: ['icon', 'basic-ivr'].join('-'),
    recording_paused: ['icon', 'recording-paused'].join('-'),
    transfered: ['icon', 'conference-success'].join('-'),
    barge_supervisor: ['icon', 'supervisor'].join('-'),
    unhold: ['icon', 'unhold'].join('-'),
    call_priority_assigned: ['icon', 'supervisor'].join('-'),
    supervisor_picked_up: ['icon', 'supervisor'].join('-'),
    supervisor_changed_queue_position: ['icon', 'supervisor'].join('-'),
    supervisor_transferred_to_agent: ['icon', 'supervisor'].join('-'),
    answered_blind_transfer: ['icon', 'call-transfer'].join('-'),
    answered_warm_transfer: ['icon', 'warm-transfer'].join('-'),
    parallel_call_initiated: ['icon', 'parallel-calling'].join('-'),
    parallel_call_answered: ['icon', 'parallel-calling'].join('-'),
    parallel_call_merged: ['icon', 'parallel-calling'].join('-'),
    parallel_call_unhold: ['icon', 'unhold'].join('-'),
    parallel_call_hold: ['icon', 'call-hold'].join('-'),
    parallel_call_ended: ['icon', 'call-end'].join('-'),
    conference_call_ringing: ['icon', 'add-contact'].join('-'),
    conference_call_answered: ['icon', 'add-contact'].join('-'),
    conference_call_ended: ['icon', 'call-end'].join('-'),
    call_forward_initiated: ['icon', 'call-queue-forward'].join('-'),
    parked: ['icon', 'call-park'].join('-'),
    customer_input_received: ['icon', 'dialpad'].join('-'),
    invalid_or_no_input_received: ['icon', 'dialpad'].join('-')
  });
  function eventIcon(params) {
    var _params = _slicedToArray(params, 2);

    var eventName = _params[0];
    var content = _params[1];

    if (content && (content === 'warm_transfer' || content === 'blind_transfer')) {
      var hashValue = eventName + '_' + content;

      return iconHash[hashValue];
    }
    if (eventName.startsWith('hangup')) {
      return iconHash.hangup;
    }

    return iconHash[eventName] ? iconHash[eventName] : ['icon', 'admin'].join('-');
  }

  exports['default'] = Helper.helper(eventIcon);
});
/* eslint-disable camelcase */