define('freshfone-ember/components/service-level/global-dashboard/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    targetPercent: computed('globalQueue.targetPercentProxy', function () {
      return this.get('globalQueue.targetPercentProxy') + '%';
    }),
    ttaThreshold: computed('globalQueue.ttaThresholdProxy', function () {
      return this.get('globalQueue.ttaThresholdProxy') + 's';
    }),
    actions: {
      edit: function edit() {
        this.sendAction('onEdit');
      }
    }
  });
});