define('freshfone-ember/components/call-flow/call-flows-list/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], {
    callFlows: computed.filterBy('model', 'isNew', false),
    sortedFlowList: computed.sort('model', 'sortDefinition'),
    sortDefinition: ['name:asc'],
    searchString: '',
    hasSearchText: computed.notEmpty('searchString'),
    callFlowType: computed('callFlow.typeName', function () {
      return this.get('callFlow.typeName');
    }),
    actions: {
      toggleDelete: function toggleDelete(flow) {
        $('.text-cancel').trigger('click');
        flow.toggleProperty('isDeleting');
      },
      onModify: function onModify() {
        this.set('modifyModel', true);
      }
    }
  });
});