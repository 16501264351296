define('freshfone-ember/components/message-components/record-component/component', ['exports', 'ember', 'freshfone-ember/constants/park-state'], function (exports, _ember, _freshfoneEmberConstantsParkState) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var alias = _ember['default'].computed.alias;
  var run = _ember['default'].run;
  var testing = _ember['default'].testing;
  exports['default'] = Component.extend({
    telephony: service(),
    i18n: service(),
    parkedCallsService: service('parked-calls'),
    currentCall: computed.alias('telephony.currentCall'),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    state: 'default',
    inDefault: computed.equal('state', 'default'),
    isWaitingToRecord: computed.equal('state', 'waiting'),
    isRecording: computed.equal('state', 'recording'),
    isRecorded: computed.equal('state', 'recorded'),
    isPreparing: computed.equal('state', 'preparing'),
    isPlaying: false,
    hasRecordingUrl: computed.notEmpty('message.recordingUrl'),
    isAgentBusy: computed.alias('currentAgent.isBusyOrAcw'),
    isOnCall: computed.and('inDefault', 'isUserUnavailable'),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    isUserBusyOrParked: computed.or('isAgentBusy', 'callIsParked'),
    isUserUnavailable: computed.or('isUserBusyOrParked', 'hasActiveCall'),
    parkedCalls: computed.alias('parkedCallsService.parkedCalls'),
    parkedCallExists: computed('parkedCalls.[]', function () {
      return this.get('parkedCalls').some(function (call) {
        return call.parkState === _freshfoneEmberConstantsParkState['default'].Parked;
      });
    }),
    recordingDisabledMessage: computed('isOnCall', function () {
      if (!this.get('isOnCall')) {
        return '';
      }

      if (this.get('isUserBusyOrParked')) {
        return this.get('i18n').t('message.errors.recording_disabled');
      }

      return this.get('i18n').t('message.errors.recording_disabled_active');
    }),
    isRecordingDisabled: computed.or('isOnCall', 'isPreparing', 'isWaitingToRecord'),
    callIsParked: computed('currentCall', 'currentCall.parkState', 'parkedCallExists', function () {
      return this.get('parkedCallExists') || this.get('currentCall') && this.get('currentCall.parkState') === _freshfoneEmberConstantsParkState['default'].Parked;
    }),
    recordingInProgress: alias('telephony.recordingInProgress'),
    init: function init() {
      this._super.apply(this, arguments);
      var state = this.get('hasRecordingUrl') ? 'recorded' : 'default';
      this.set('state', state);
    },

    startRecording: function startRecording() {
      this.bindRecordCallback();
      this.get('telephony').startRecording();
    },

    stopRecording: function stopRecording() {
      this.set('state', 'preparing');
      this.get('telephony').stopRecording();
    },

    bindRecordCallback: function bindRecordCallback() {
      var self = this;
      this.get('telephony.event').subscribe('record_completed', this.recordCompletedCallback.bind(this));
    },

    recordCompletedCallback: function recordCompletedCallback(data) {
      this.set('state', 'recorded');
      this.set('recordingInProgress', false);
      this.set('recordingUrl', data.url);
      this.set('message.recordingUrl', data.url);
    },

    willDestroyElement: function willDestroyElement() {
      this.get('telephony.event').unsubscribe('record_completed', this.recordCompletedCallback.bind(this));
      this.set('recordingInProgress', false);
      this._super.apply(this, arguments);
      if (this.get('state') === 'recording') {
        this.get('telephony').hangup('message-components/record-component/component.js#actions.willDestroyElement');
      }
    },

    recordingState: computed('state', function () {
      if (this.get('inDefault')) {
        return _ember['default'].i18n.t('message.max_duration');
      }
      if (this.get('isWaitingToRecord')) {
        return;
      }
      if (this.get('isRecording')) {
        return _ember['default'].i18n.t('message.recording');
      }
      if (this.get('isPreparing')) {
        return _ember['default'].i18n.t('message.preparing');
      }
    }),
    actions: {

      record: function record() {
        if (this.get('isRecordingDisabled')) {
          return;
        }
        if (this.get('isRecording')) {
          this.stopRecording();
        } else {
          this.set('state', 'waiting');
          var self = this;
          setTimeout(function () {
            run(function () {
              self.set('state', 'recording');
            });
          }, testing ? 0 : 6000);

          this.startRecording();
        }
      },

      cancel: function cancel() {
        this.set('state', 'default');
        this.set('message.recordingUrl', null);
      }

    }
  });
});