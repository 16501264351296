define('freshfone-ember/components/billing-base/subscription-base/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/slick-helper'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersSlickHelper) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var testing = _ember['default'].testing;
  var isEqual = _ember['default'].isEqual;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),
    editPlan: false,
    slidesToShow: 3,
    currentAccount: computed.alias('session.account'),
    isDeletionScheduled: computed.alias('currentAccount.deletionScheduled'),
    productName: computed.alias('currentAccount.productName'),
    isRebrandedAccount: computed.alias('currentAccount.hasPricingRebrandingFeature'),
    invoiceAmount: computed.alias('subscription.invoiceAmount'),
    hasUnpaidInvoice: computed.alias('subscription.unpaidInvoice'),
    isPaymentFailed: computed.alias('subscription.isPaymentFailed'),
    isTrailExpired: computed.alias('subscription.isTrailExpired'),
    isCancelled: computed.alias('subscription.isCancelled'),
    isTrail: computed.alias('subscription.isTrail'),

    currentslidetoshow: computed('subscription.planName', function () {
      // cases like omnichannel
      if (this.get('plans.length') <= this.get('slidesToShow')) {
        return 0;
      }

      var planId = _freshfoneEmberHelpersSlickHelper['default'].slickId[this.get('subscription.planName')],
          currentSlide = planId >= this.get('plans.length') ? planId - this.get('slidesToShow') : planId - this.get('slidesToShow') + 1;

      return planId >= this.get('slidesToShow') ? currentSlide : 0;
    }),

    getRebrandingPlanInfo: function getRebrandingPlanInfo() {
      return this.get('isRebrandedAccount') ? this.get('i18n').t('subscription.rebrandedClassicMessage', {
        planName: this.get('subscription.planName'),
        trial: this.get('isTrail') ? this.get('i18n').t('trial.trial_text') + ' ' : '',
        classic: this.get('is2020Plans') ? '' : this.get('i18n').t('trial.classicText') + ' '
      }) : this.get('i18n').t('subscription.classicMessage', {
        planName: this.get('subscription.planName'),
        trial: this.get('isTrail') ? this.get('i18n').t('trial.trial_text') + ' ' : ''
      });
    },

    showActionForClassicPlan: computed('isCurrentClassic', 'isPaymentFailed', 'isDeletionScheduled', function () {
      return this.get('isCurrentClassic') && !(this.get('isPaymentFailed') || this.get('isDeletionScheduled'));
    }),

    planStatusInfo: computed('isCurrentClassic', 'isTrail', 'isPaymentFailed', 'isTrailExpired', 'isCancelled', function () {
      if (this.get('currentAccount.blocked')) {
        return this.get('i18n').t('subscription.account_blocked_message');
      } else if (this.get('isCurrentClassic')) {
        if (this.get('isPaymentFailed')) {
          return this.get('i18n').t('subscription.paymentFailedClassicMessage', {
            planName: this.get('subscription.planName'),
            trial: '',
            classic: this.get('is2020Plans') ? '' : this.get('i18n').t('trial.classicText') + ' '
          });
        } else if (this.get('isDeletionScheduled')) {
          return this.get('i18n').t('subscription.account_deletion_progress_message');
        }

        return this.getRebrandingPlanInfo();
      } else if (this.get('isTrailExpired')) {
        return this.get('i18n').t('subscription.trial.expiredMessage', { productName: this.get('productName') });
      } else if (this.get('isCancelled')) {
        return this.get('i18n').t('subscription.account_suspended_message');
      } else if (this.get('isDeletionScheduled')) {
        return this.get('i18n').t('subscription.account_deletion_progress_message');
      }

      return this.get('i18n').t('subscription.trial.message');
    }),

    fullPlanName: computed('subscription.planName', 'currentBillingCycle', 'subscription.currency', function () {
      return this.get('subscription.planName') + ' ' + this.get('currentBillingCycle') + ' ' + this.get('subscription.currency');
    }),

    currentBillingCycle: computed('subscription.renewalPeriod', function () {
      switch (this.get('subscription.renewalPeriod')) {
        case '1':
          return this.get('i18n').t('subscription.monthly');
        case '3':
          return this.get('i18n').t('subscription.quarterly');
        case '6':
          return this.get('i18n').t('subscription.half_yearly');
        default:
          return this.get('i18n').t('subscription.yearly');
      }
    }),

    unpaidInvoiceInfo: computed('hasUnpaidInvoice', function () {
      return this.get('i18n').t('subscription.invoice_failed.payment_failed');
    }),

    isClassicPlan: computed('plans.[]', 'subscription.planId', function () {
      var plan = this.store.peekRecord('plan', this.get('subscription.planId'));

      if (plan) {
        this.set('is2020Plans', isEqual(plan.get('year'), '2020'));

        if (!this.get('editPlan')) {
          this.set('isCurrentClassic', plan.get('classic'));
        }

        return plan.get('classic');
      }
    }),

    hasCreditUsagesFeature: computed(function () {
      return this.hasGlobalFeature('credit_usages');
    }),

    actions: {
      closeCallback: function closeCallback() {
        this.set('editPlan', false);
        this.get('subscription.content').rollbackAttributes();
        if (this.get('paymentStatusSuccess') || this.get('downgradeRequestSuccess')) {
          !testing && window.location.reload();
        }
      },
      toggleEditClassicPlan: function toggleEditClassicPlan() {
        var plan = this.store.peekRecord('plan', this.get('subscription.planId'));

        if (plan) {
          this.set('selectedPlan', plan);
          this.set('editPlan', true);
        }
      }
    }
  });
});