define('freshfone-ember/components/call-queue/queue-form/wait-queue-settings/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ["wait-queue-settings"],
    showPresenceOptions: computed.or('queue.agent.id', 'queue.team.id', 'queue.externalNumber', 'queue.isAllAgentsRouteType'),
    busyActionWormHole: computed('parentElementId', function () {
      return ["wait-queue-busy-action-wormhole-", this.get('parentElementId')].join("");
    }),
    queueActionWormHole: computed('parentElementId', function () {
      return ["wait-queue-action-wormhole-", this.get('parentElementId')].join("");
    }),

    actions: {
      showNewMessage: function showNewMessage(attr) {
        this.sendAction('showNewMessage', attr);
      },
      waitQueueMessageToggled: function waitQueueMessageToggled() {
        if (this.get('queue.queuePositionEnabled') && !this.get('queue.busyQueuePositionMessage')) {
          this.set('queue.busyQueuePositionMessage', 'Your queue position is {{queue.position}}');
        }
      },
      setDefaultMsg: function setDefaultMsg(queueType, id) {
        this.sendAction('setDefaultMsg', queueType, id);
      }
    }
  });
});