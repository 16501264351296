define("freshfone-ember/components/outgoing-widget/recent-calls/power-dialer-invalid-number/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/outgoing-widget/recent-calls/power-dialer-invalid-number/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "power-dialer-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "power-dialer-header");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "contact");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "contact-details");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "invalid-number-error");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "icon-info-alert");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "actions");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn btn-primary pull-right");
        dom.setAttribute(el3, "data-toggle", "tooltip");
        dom.setAttribute(el3, "data-placement", "top");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn btn-cancel pull-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element0, [7]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [5, 3]), 0, 0);
        morphs[5] = dom.createAttrMorph(element4, 'data-original-title');
        morphs[6] = dom.createAttrMorph(element4, 'disabled');
        morphs[7] = dom.createElementMorph(element4);
        morphs[8] = dom.createMorphAt(element4, 1, 1);
        morphs[9] = dom.createElementMorph(element5);
        morphs[10] = dom.createMorphAt(element5, 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["power_dialer.inconversation.in_prorgress"], ["dialedContacts", ["subexpr", "@mut", [["get", "dialedContacts", ["loc", [null, [5, 23], [5, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "totalContactsInPowerDialer", ["subexpr", "@mut", [["get", "totalContactsInPowerDialer", ["loc", [null, [6, 35], [6, 61]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 6], [6, 63]]], 0, 0], ["inline", "custom-elements/avatar", [], ["type", "user", "charLimit", 1, "text", ["subexpr", "@mut", [["get", "callParams.recipientName", ["loc", [null, [13, 11], [13, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "32"], ["loc", [null, [10, 4], [15, 6]]], 0, 0], ["content", "callParams.recipientName", ["loc", [null, [17, 12], [17, 40]]], 0, 0, 0, 0], ["content", "callParams.phone_number", ["loc", [null, [18, 12], [18, 39]]], 0, 0, 0, 0], ["content", "errorMessage", ["loc", [null, [23, 10], [23, 26]]], 0, 0, 0, 0], ["attribute", "data-original-title", ["concat", [["get", "nextContactToolTip", ["loc", [null, [30, 29], [30, 47]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disablePowerDialer", ["loc", [null, [31, 17], [31, 35]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["dialNext"], [], ["loc", [null, [32, 6], [32, 27]]], 0, 0], ["inline", "t", ["power_dialer.dial_next"], [], ["loc", [null, [34, 6], [34, 36]]], 0, 0], ["element", "action", ["stopPowerDialerMode"], [], ["loc", [null, [36, 46], [36, 78]]], 0, 0], ["inline", "t", ["power_dialer.end_power_dialer"], [], ["loc", [null, [37, 6], [37, 43]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});