define('freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/secondary-question/question/component', ['exports'], function (exports) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var _Ember = Ember;
  var Component = _Ember.Component;
  exports['default'] = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('questionNumber', this.get('questionNumber') + 1);
    },

    checkInAnsweredList: function checkInAnsweredList(option) {
      return this.get('secondaryQuestionAnswered').some(function (secondaryQuestion) {
        return secondaryQuestion.get('id') === option.get('surveyQuestion').get('id');
      });
    },

    addOptionId: function addOptionId(option) {
      var text = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

      var options = option.get('surveyQuestion').get('option');

      if (options) {
        var addedOption = options.some(function (addedOption) {
          return addedOption.id === option.get('id');
        });
        if (addedOption) {
          var addedOptionIndex = 0;
          options.forEach(function (addedOption, index) {
            if (addedOption.id === option.get('id')) {
              addedOptionIndex = index;
            }
          });
          options[addedOptionIndex] = { id: option.get('id'), text: text };
          return options;
        } else {
          return [].concat(_toConsumableArray(options), [{ id: option.get('id'), text: text }]);
        }
      } else {
        return [{ id: option.get('id'), text: text }];
      }
    },

    removeOptionId: function removeOptionId(option) {
      var options = option.get('surveyQuestion').get('option').filter(function (surveyoption) {
        return surveyoption.id !== option.get('id');
      });

      option.get('surveyQuestion').set('option', options);
    },

    getOptionIndex: function getOptionIndex(surveyQuestion, optionId) {
      var optionIndex = 0;

      surveyQuestion.forEach(function (option, index) {
        if (option.id === optionId) optionIndex = index;
      });

      return optionIndex;
    },

    groupValue: '',
    actions: {
      addOrRemoveOption: function addOrRemoveOption(option, value) {
        if (value && !option.get('otherOption')) {
          option.get('surveyQuestion').set('option', this.addOptionId(option));
          if (!this.checkInAnsweredList(option)) {
            this.get('secondaryQuestionAnswered').pushObject(option.get('surveyQuestion'));
          }
        } else if (value) {
          option.get('surveyQuestion').set('othersEnabled', true);
        } else {
          if (option.get('otherOption')) {
            option.get('surveyQuestion').set('othersEnabled', false);
          }
          this.removeOptionId(option);
          if (!option.get('surveyQuestion').get('option').length) {
            var questionIndex = 0;

            this.get('secondaryQuestionAnswered').forEach(function (answeredQuestion, index) {
              if (answeredQuestion.get('id') === option.get('surveyQuestion').get('id')) {
                questionIndex = index;
              }
            });
            this.get('secondaryQuestionAnswered').removeAt(questionIndex);
          }
        }
      },

      addRadioOption: function addRadioOption(option, value) {
        option.get('surveyQuestion').set('option', [{ id: option.get('id'), text: '' }]);
        if (!this.checkInAnsweredList(option)) {
          this.get('secondaryQuestionAnswered').pushObject(option.get('surveyQuestion'));
        }
      },

      addTextOption: function addTextOption(question, e) {
        question.set('othersText', e.target.value);
        if (e.target.value) {
          if (!this.get('secondaryQuestionAnswered').some(function (secondaryQuestion) {
            return secondaryQuestion.get('id') === question.get('id');
          })) {
            this.get('secondaryQuestionAnswered').pushObject(question);
          }
        } else {
          this.get('secondaryQuestionAnswered').removeObject(question);
        }
      },
      addOthersOption: function addOthersOption(option, e) {
        if (e.target.value && option.get('surveyQuestion.othersEnabled')) {
          option.get('surveyQuestion').set('option', this.addOptionId(option, e.target.value));
          if (!this.checkInAnsweredList(option)) {
            this.get('secondaryQuestionAnswered').pushObject(option.get('surveyQuestion'));
          }
        } else {
          this.removeOptionId(option);
          if (!option.get('surveyQuestion').get('option').length) {
            var questionIndex = 0;

            this.get('secondaryQuestionAnswered').forEach(function (answeredQuestion, index) {
              if (answeredQuestion.get('id') === option.get('surveyQuestion').get('id')) {
                questionIndex = index;
              }
            });
            this.get('secondaryQuestionAnswered').removeAt(questionIndex);
          }
        }
      }
    }
  });
});