define('freshfone-ember/routes/protected/download', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  var $ = _ember['default'].$;
  exports['default'] = Route.extend({
    i18n: service(),
    titleToken: computed(function () {
      return this.get('i18n').t('navigation.download');
    }),

    hasGlobalFeature: function hasGlobalFeature(feature) {
      return this.get('session.account.globalFlags').indexOf(feature) > -1;
    },

    checkFeature: function checkFeature(featureName) {
      return this.get('session.account').hasFeature(featureName);
    },

    beforeModel: function beforeModel(_ref) {
      var queryParams = _ref.queryParams;

      var isCrmUser = this.store.peekRecord('session', 'current').get('user.isCrmUser'),
          isSales360ParamPresent = queryParams && queryParams.bundle && queryParams.bundle === 'sales360';

      if (isCrmUser || isSales360ParamPresent) {
        this.set('session.account.isUnifiedAppAccount', true);
      }
    },

    model: function model(params) {
      var _this = this;

      var fileUrl = ['/download_file', params.export_type, params.token].join('/');

      return new Promise(function (resolve) {
        return $.ajax({
          url: fileUrl,
          dataType: 'json',
          type: 'GET'
        }).then(function (response) {
          if (_this.checkFeature('caller_pages_in_crm') && _this.hasGlobalFeature('caller_pages_in_crm_global') && _this.get('session.account.isUnifiedAppAccount')) {
            _this.transitionTo('protected.admin.numbers.index');
          } else {
            _this.transitionTo('protected.dashboard');
          }
          window.location = response.export_link;
          resolve();
        });
      });
    }
  });
});