define('freshfone-ember/utils/subscription-constants', ['exports'], function (exports) {
  var subscriptionConstants = {
    STATUS: {
      TRIAL: 1,
      FREE: 2,
      ACTIVE: 3,
      EXPIRED: 4,
      PAYMENT_FAILED: 5,
      CANCELLED: 6
    },
    DOWNGRADE_CUSTOMER_STATUS: {
      SCHEDULED: 0,
      INPROGRESS: 1,
      FAILED: 2,
      CANCELLED: 3,
      DOWNGRADED: 4
    },
    DEFAULT_PLANS: {
      Sprout: 1,
      Blossom: 2,
      Garden: 3,
      Estate: 4,
      Forest: 5,
      Free: 1,
      Growth: 2,
      Pro: 4,
      Enterprise: 5
    },
    OMNICHANNEL_PLANS: ['Estate', 'Forest'],
    REBRANDED_OMNICHANNEL_PLANS: ['Growth', 'Pro', 'Enterprise'],
    ACCOUNT_DELETED_REDIRECT_URL: 'https://goo.gl/vb9jtN',
    MRR_TO_COMPARE: '400',
    DEFAULT_PLAN_NAMES: {
      Sprout2020: 'Sprout 2020',
      Sprout: 'Sprout',
      Blossom: 'Blossom',
      Garden: 'Garden',
      Estate: 'Estate',
      Forest: 'Forest',
      Free: 'Free',
      Growth: 'Growth',
      Pro: 'Pro',
      Enterprise: 'Enterprise'
    }
  };

  exports['default'] = subscriptionConstants;
});