define('freshfone-ember/routes/protected/admin/agents', ['exports', 'ember', 'freshfone-ember/routes/protected', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberRoutesProtected, _freshfoneEmberMixinsFeatureCheckMixin) {
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    titleToken: computed(function () {
      return _ember['default'].i18n.t('agent.title');
    }),
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('agent.title') };
    }),
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_agents');
    },
    model: function model() {
      if (this.checkFeature('data_scoping')) {
        this.store.findAll('team'); // deferred
      }

      return this.store.query('user', {});
    }
  });
});