define('freshfone-ember/components/message-components/attachment-component/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _ember['default'].Component.extend({
    acceptFileTypes: ".mp3",
    fileName: computed.alias('message.attachmentName'),

    attachmentUrl: computed('message.attachmentUrl', 'blobURL', function () {
      if (isPresent(this.get('blobURL'))) {
        return { url: this.get('blobURL') };
      }

      return { url: this.get('message.fullAttachmentUrl') };
    }),
    anyErrors: computed.gt('errors.attachment.length', 0),
    hasAttachment: computed.notEmpty('attachmentUrl.url'),
    hasValidAttachment: computed('anyErrors', 'hasAttachment', function () {
      if (this.get('anyErrors')) {
        return;
      }
      return this.get('hasAttachment');
    }),
    actions: {
      cancel: function cancel() {
        var oldUrl = this.get('message.attachmentUrl');
        this.set('oldUrl', oldUrl);
        this.set('message.attachmentName', null);
        this.set('message.attachmentUrl', null);
        this.set('message.attachment', null);
        if (this.get('blobURL')) {
          this.set('blobURL', null);
        }
      },
      triggerUpload: function triggerUpload() {
        this.get("message").notifyPropertyChange("attachment");
        this.$('.file-upload').trigger("click");
      }
    }
  });
});