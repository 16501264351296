define("freshfone-ember/components/message-components/record-component/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/message-components/record-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "class", "note");
          var el2 = dom.createTextNode("\n     ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["content", "recordingState", ["loc", [null, [5, 5], [5, 23]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/message-components/record-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "message-components/audio-player", [], ["message", ["subexpr", "@mut", [["get", "message.playableMessage", ["loc", [null, [11, 44], [11, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "t", ["message_components.record_component.recorded_message"], [], ["loc", [null, [12, 8], [12, 66]]], 0, 0], "classNames", "btn btn-cancel", "cancelAction", ["subexpr", "action", ["cancel"], [], ["loc", [null, [13, 43], [13, 60]]], 0, 0]], ["loc", [null, [11, 2], [13, 62]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 4
              },
              "end": {
                "line": 22,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/message-components/record-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "data-placement", "top");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon-record-btn");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createAttrMorph(element0, 'data-toggle');
            morphs[2] = dom.createAttrMorph(element0, 'title');
            morphs[3] = dom.createElementMorph(element0);
            morphs[4] = dom.createMorphAt(element0, 2, 2);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["btn record-button ", ["subexpr", "if", [["get", "isRecordingDisabled", ["loc", [null, [16, 41], [16, 60]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [16, 36], [16, 73]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-toggle", ["concat", [["subexpr", "if", [["get", "isOnCall", ["loc", [null, [17, 46], [17, 54]]], 0, 0, 0, 0], "tooltip"], [], ["loc", [null, [17, 41], [17, 66]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["get", "recordingDisabledMessage", ["loc", [null, [19, 16], [19, 40]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["record"], [], ["loc", [null, [17, 8], [17, 27]]], 0, 0], ["inline", "if", [["get", "inDefault", ["loc", [null, [20, 41], [20, 50]]], 0, 0, 0, 0], ["subexpr", "t", ["message_components.record_component.record"], [], ["loc", [null, [20, 51], [20, 99]]], 0, 0], ["subexpr", "t", ["message_components.record_component.stop"], [], ["loc", [null, [20, 100], [20, 146]]], 0, 0]], [], ["loc", [null, [20, 36], [20, 148]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/message-components/record-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "text-field", [], ["mandatory", true, "customElem", true, "errors", ["subexpr", "@mut", [["get", "errors.recordingUrl", ["loc", [null, [15, 56], [15, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "for", "recordingUrl", "classNames", "recording-btn-container", "animate", false, "rightAlign", true], 0, null, ["loc", [null, [15, 4], [22, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/message-components/record-component/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["message-label-container ", ["subexpr", "if", [["get", "isRecorded", ["loc", [null, [1, 41], [1, 51]]], 0, 0, 0, 0], "hasRecording"], [], ["loc", [null, [1, 36], [1, 68]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["message_components.record_component.message"], [], ["loc", [null, [2, 9], [2, 60]]], 0, 0], ["block", "unless", [["get", "isRecorded", ["loc", [null, [3, 12], [3, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [7, 13]]]], ["block", "if", [["get", "isRecorded", ["loc", [null, [10, 6], [10, 16]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [10, 0], [23, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});