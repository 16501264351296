define("freshfone-ember/components/ongoing-widget/transfer-status/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 12,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "plus");
            var el2 = dom.createTextNode(" + ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "call-status-alert");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "transferring-msg text-center");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "transfer-agent-name ellipsis");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element8, 3, 3);
            return morphs;
          },
          statements: [["content", "transferAgentName", ["loc", [null, [7, 10], [7, 31]]], 0, 0, 0, 0], ["content", "conferenceStatusMessage", ["loc", [null, [9, 8], [9, 35]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 2
                },
                "end": {
                  "line": 26,
                  "column": 2
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "plus");
              var el2 = dom.createTextNode(" + ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "call-status-alert");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "transferring-msg text-center");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "transfer-agent-name ellipsis");
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "call-status-button");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "cancel-call");
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n  ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [3]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element5, [3, 1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element6, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(element6, 5, 5);
              morphs[3] = dom.createElementMorph(element7);
              morphs[4] = dom.createMorphAt(element7, 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["agent_conference.adding"], [], ["loc", [null, [16, 8], [16, 39]]], 0, 0], ["content", "transferAgentName", ["loc", [null, [18, 10], [18, 31]]], 0, 0, 0, 0], ["inline", "t", ["agent_conference.to_this_call"], [], ["loc", [null, [20, 8], [20, 45]]], 0, 0], ["element", "action", ["cancelCall"], [], ["loc", [null, [23, 34], [23, 57]]], 0, 0], ["inline", "t", ["transfer.cancel_transfer_button"], [], ["loc", [null, [23, 59], [23, 98]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 2
              },
              "end": {
                "line": 26,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "currentCall.isAddingAgent", ["loc", [null, [12, 12], [12, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 2], [26, 2]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "showConferenceMessage", ["loc", [null, [2, 8], [2, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [26, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 2
              },
              "end": {
                "line": 34,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "call-status-alert");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "transferring-msg text-center");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["transfer.cancelling_transfer"], [], ["loc", [null, [31, 8], [31, 44]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 2
                },
                "end": {
                  "line": 40,
                  "column": 2
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "call-status-alert");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "transferring-msg text-center");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["transfer.resuming_transfer"], [], ["loc", [null, [37, 8], [37, 42]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 8
                    },
                    "end": {
                      "line": 46,
                      "column": 8
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["transfer.transferring_state"], [], ["loc", [null, [45, 10], [45, 45]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 47,
                      "column": 8
                    },
                    "end": {
                      "line": 49,
                      "column": 8
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["transfer.unsuccessfull_team_transfer"], [], ["loc", [null, [48, 10], [48, 54]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 8
                    },
                    "end": {
                      "line": 52,
                      "column": 8
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["transfer.transferring_state"], [], ["loc", [null, [51, 12], [51, 47]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child3 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 56,
                      "column": 8
                    },
                    "end": {
                      "line": 58,
                      "column": 8
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["transfer.unsuccessfull_agent_transfer"], [], ["loc", [null, [57, 10], [57, 55]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child4 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 62,
                        "column": 10
                      },
                      "end": {
                        "line": 64,
                        "column": 10
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "resume-call");
                    var el2 = dom.createTextNode(" ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element2);
                    morphs[1] = dom.createMorphAt(element2, 1, 1);
                    return morphs;
                  },
                  statements: [["element", "action", ["resumeCall"], [], ["loc", [null, [63, 38], [63, 61]]], 0, 0], ["inline", "t", ["transfer.resume_transfer_button"], [], ["loc", [null, [63, 63], [63, 102]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 64,
                        "column": 10
                      },
                      "end": {
                        "line": 66,
                        "column": 10
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "cancel-call");
                    var el2 = dom.createTextNode(" ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element1);
                    morphs[1] = dom.createMorphAt(element1, 1, 1);
                    return morphs;
                  },
                  statements: [["element", "action", ["cancelCall"], [], ["loc", [null, [65, 38], [65, 61]]], 0, 0], ["inline", "t", ["transfer.cancel_transfer_button"], [], ["loc", [null, [65, 63], [65, 102]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 60,
                      "column": 6
                    },
                    "end": {
                      "line": 68,
                      "column": 6
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "call-status-button");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "if", [["get", "isTransferUnavailable", ["loc", [null, [62, 16], [62, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [62, 10], [66, 17]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 2
                  },
                  "end": {
                    "line": 70,
                    "column": 2
                  }
                },
                "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "plus");
                var el2 = dom.createTextNode(" + ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "call-status-alert");
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "transferring-msg");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "transfer-agent-name ellipsis");
                var el4 = dom.createTextNode("\n          ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [3]);
                var element4 = dom.childAt(element3, [1]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(element4, 1, 1);
                morphs[1] = dom.createMorphAt(element4, 2, 2);
                morphs[2] = dom.createMorphAt(element4, 3, 3);
                morphs[3] = dom.createMorphAt(dom.childAt(element4, [5]), 1, 1);
                morphs[4] = dom.createMorphAt(element4, 7, 7);
                morphs[5] = dom.createMorphAt(element3, 3, 3);
                return morphs;
              },
              statements: [["block", "unless", [["get", "isTransferUnavailable", ["loc", [null, [44, 18], [44, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [44, 8], [46, 19]]]], ["block", "if", [["get", "targetTeamUnavailable", ["loc", [null, [47, 14], [47, 35]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [47, 8], [49, 15]]]], ["block", "if", [["get", "isTeamTransfer", ["loc", [null, [50, 14], [50, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [50, 8], [52, 15]]]], ["inline", "if", [["get", "isCsatIvrTransfer", ["loc", [null, [54, 15], [54, 32]]], 0, 0, 0, 0], ["subexpr", "t", ["csat_ivr.transfer_name"], [], ["loc", [null, [54, 33], [54, 61]]], 0, 0], ["get", "transferAgentName", ["loc", [null, [54, 62], [54, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [54, 10], [54, 81]]], 0, 0], ["block", "if", [["subexpr", "or", [["get", "targetAgentUnavailable", ["loc", [null, [56, 18], [56, 40]]], 0, 0, 0, 0], ["get", "targetExternalNumberUnavailable", ["loc", [null, [56, 41], [56, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [56, 14], [56, 73]]], 0, 0]], [], 3, null, ["loc", [null, [56, 8], [58, 15]]]], ["block", "unless", [["get", "isCsatIvrTransfer", ["loc", [null, [60, 16], [60, 33]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [60, 6], [68, 17]]]]],
              locals: [],
              templates: [child0, child1, child2, child3, child4]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 70,
                      "column": 2
                    },
                    "end": {
                      "line": 83,
                      "column": 2
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "parallel-call-status");
                  var el2 = dom.createTextNode("\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "icon-container");
                  var el3 = dom.createTextNode("\n        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("i");
                  dom.setAttribute(el3, "class", "icon-parallel-calling");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n      ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "msg-container");
                  var el3 = dom.createTextNode("\n        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" \n        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "connecting-to");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n      ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "action-button");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("      ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n  ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1, 3]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element0, 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "t", ["parallel_call.connecting_to"], [], ["loc", [null, [76, 8], [76, 43]]], 0, 0], ["content", "transferAgentName", ["loc", [null, [77, 36], [77, 57]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 2
                  },
                  "end": {
                    "line": 83,
                    "column": 2
                  }
                },
                "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "isConnectingParallelCall", ["loc", [null, [70, 12], [70, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [70, 2], [83, 2]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 2
                },
                "end": {
                  "line": 83,
                  "column": 2
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["get", "isTransferring", ["loc", [null, [40, 16], [40, 30]]], 0, 0, 0, 0], ["get", "isTransferUnavailable", ["loc", [null, [40, 31], [40, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 12], [40, 53]]], 0, 0]], [], 0, 1, ["loc", [null, [40, 2], [83, 2]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 2
              },
              "end": {
                "line": 83,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isResuming", ["loc", [null, [34, 12], [34, 22]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [34, 2], [83, 2]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 84,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "isCancelling", ["loc", [null, [28, 8], [28, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [28, 2], [83, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 85,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/ongoing-widget/transfer-status/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isAgentConference", ["loc", [null, [1, 6], [1, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [84, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});