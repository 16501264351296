define('freshfone-ember/mixins/adapters/multi-word-underscore', ['exports', 'ember', 'freshfone-ember/adapters/application'], function (exports, _ember, _freshfoneEmberAdaptersApplication) {
  var _Ember$String = _ember['default'].String;
  var underscore = _Ember$String.underscore;
  var pluralize = _Ember$String.pluralize;
  exports['default'] = _freshfoneEmberAdaptersApplication['default'].extend({
    pathForType: function pathForType(type) {
      var underscored = underscore(type);
      return pluralize(underscored);
    }
  });
});