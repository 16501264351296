define("freshfone-ember/components/search-container/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 7,
                "column": 3
              }
            },
            "moduleName": "freshfone-ember/components/search-container/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("			  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "rel", "noopener");
            dom.setAttribute(el1, "target", "_blank");
            dom.setAttribute(el1, "class", "support-portal-link");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element3, 'href');
            morphs[1] = dom.createMorphAt(element3, 0, 0);
            return morphs;
          },
          statements: [["attribute", "href", ["get", "supportPortalLink", ["loc", [null, [6, 46], [6, 63]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["common.learn_more"], [], ["loc", [null, [6, 94], [6, 119]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 1
            },
            "end": {
              "line": 9,
              "column": 1
            }
          },
          "moduleName": "freshfone-ember/components/search-container/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1, "class", "page-title");
          var el2 = dom.createTextNode("\n		  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("		");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element4, 1, 1);
          morphs[1] = dom.createMorphAt(element4, 3, 3);
          return morphs;
        },
        statements: [["content", "title", ["loc", [null, [4, 4], [4, 13]]], 0, 0, 0, 0], ["block", "if", [["get", "supportPortalLink", ["loc", [null, [5, 10], [5, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 4], [7, 10]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 3
                },
                "end": {
                  "line": 14,
                  "column": 3
                }
              },
              "moduleName": "freshfone-ember/components/search-container/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("				");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "page-title ellipsis");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n				");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "caret");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "title", ["loc", [null, [12, 36], [12, 45]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 21,
                        "column": 7
                      },
                      "end": {
                        "line": 21,
                        "column": 55
                      }
                    },
                    "moduleName": "freshfone-ember/components/search-container/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "icon-tick");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 19,
                      "column": 6
                    },
                    "end": {
                      "line": 22,
                      "column": 6
                    }
                  },
                  "moduleName": "freshfone-ember/components/search-container/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("							");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "ellipsis");
                  dom.setAttribute(el1, "href", "javascript:void(0);");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n							");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  return morphs;
                },
                statements: [["content", "item", ["loc", [null, [20, 54], [20, 62]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "title", ["loc", [null, [21, 17], [21, 22]]], 0, 0, 0, 0], ["get", "item", ["loc", [null, [21, 23], [21, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 13], [21, 28]]], 0, 0]], [], 0, null, ["loc", [null, [21, 7], [21, 62]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 24,
                        "column": 7
                      },
                      "end": {
                        "line": 24,
                        "column": 55
                      }
                    },
                    "moduleName": "freshfone-ember/components/search-container/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "icon-tick");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 22,
                      "column": 6
                    },
                    "end": {
                      "line": 25,
                      "column": 6
                    }
                  },
                  "moduleName": "freshfone-ember/components/search-container/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("							");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "ellipsis");
                  dom.setAttribute(el1, "href", "javascript:void(0);");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n							");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  return morphs;
                },
                statements: [["inline", "unless", [["get", "item.default", ["loc", [null, [23, 63], [23, 75]]], 0, 0, 0, 0], ["get", "item.name", ["loc", [null, [23, 76], [23, 85]]], 0, 0, 0, 0], ["get", "item.dashboardStatusTitle", ["loc", [null, [23, 86], [23, 111]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 54], [23, 113]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "title", ["loc", [null, [24, 17], [24, 22]]], 0, 0, 0, 0], ["get", "item", ["loc", [null, [24, 23], [24, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 13], [24, 28]]], 0, 0]], [], 0, null, ["loc", [null, [24, 7], [24, 62]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 4
                  },
                  "end": {
                    "line": 27,
                    "column": 4
                  }
                },
                "moduleName": "freshfone-ember/components/search-container/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("					");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("					");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element2);
                morphs[1] = dom.createMorphAt(element2, 1, 1);
                return morphs;
              },
              statements: [["element", "action", ["dropDownCallback", ["get", "item", ["loc", [null, [18, 37], [18, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 9], [18, 43]]], 0, 0], ["block", "unless", [["get", "item.name", ["loc", [null, [19, 16], [19, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [19, 6], [25, 17]]]]],
              locals: ["item"],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 3
                },
                "end": {
                  "line": 28,
                  "column": 3
                }
              },
              "moduleName": "freshfone-ember/components/search-container/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "dropDownObj.items", ["loc", [null, [17, 12], [17, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 4], [27, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 2
              },
              "end": {
                "line": 29,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/search-container/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "rl-dropdown-toggle", [], ["class", "btn"], 0, null, ["loc", [null, [11, 3], [14, 26]]]], ["block", "rl-dropdown", [], ["tagName", "ul", "class", "dropdown-menu", "closeOnChildClick", "li"], 1, null, ["loc", [null, [16, 3], [28, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 1
            },
            "end": {
              "line": 30,
              "column": 1
            }
          },
          "moduleName": "freshfone-ember/components/search-container/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "rl-dropdown-container", [], ["class", "dropdown", "onOpen", ["subexpr", "action", ["callbackOnToggle"], [], ["loc", [null, [10, 51], [10, 78]]], 0, 0]], 0, null, ["loc", [null, [10, 2], [29, 28]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 1
            },
            "end": {
              "line": 33,
              "column": 1
            }
          },
          "moduleName": "freshfone-ember/components/search-container/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["action-bar-", ["get", "currentStatus", ["loc", [null, [32, 28], [32, 41]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["executeCallBackAction"], [], ["loc", [null, [32, 45], [32, 79]]], 0, 0], ["content", "currentStatus", ["loc", [null, [32, 80], [32, 97]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 1
            },
            "end": {
              "line": 40,
              "column": 1
            }
          },
          "moduleName": "freshfone-ember/components/search-container/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("		");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n			");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "icon-search");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n			");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["search-container ", ["subexpr", "if", [["get", "hasExpandableSearchBar", ["loc", [null, [35, 36], [35, 58]]], 0, 0, 0, 0], "has-expandable-search-bar"], [], ["loc", [null, [35, 31], [35, 88]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["setFocusOnSearchField"], ["on", "mouseDown", "preventDefault", false], ["loc", [null, [35, 90], [35, 160]]], 0, 0], ["inline", "custom-elements/list-search", [], ["class", "search-field", "results", ["subexpr", "@mut", [["get", "results", ["loc", [null, [37, 62], [37, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [37, 82], [37, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "isActive", ["subexpr", "@mut", [["get", "isActive", ["loc", [null, [38, 14], [38, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "searchText", ["subexpr", "@mut", [["get", "searchText", ["loc", [null, [38, 34], [38, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "searchstring", ["loc", [null, [38, 51], [38, 63]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [37, 3], [38, 65]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/search-container/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createMorphAt(element5, 1, 1);
        morphs[2] = dom.createMorphAt(element5, 2, 2);
        morphs[3] = dom.createMorphAt(element5, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["list-search ", ["subexpr", "if", [["get", "isActiveSearch", ["loc", [null, [1, 29], [1, 43]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [1, 24], [1, 54]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "hasEmptyDropdown", ["loc", [null, [2, 7], [2, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 1], [30, 8]]]], ["block", "if", [["get", "showActionBar", ["loc", [null, [31, 7], [31, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [31, 1], [33, 8]]]], ["block", "unless", [["get", "searchDisabled", ["loc", [null, [34, 11], [34, 25]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [34, 1], [40, 12]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});