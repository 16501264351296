define('freshfone-ember/instance-initializers/root-url', ['exports'], function (exports) {
  exports.initialize = initialize;
  /* eslint-disable sort-vars */

  function initialize(appInstance) {
    var router = appInstance.container.lookup('router:main'),
        rootURL = localStorage.getItem('rootURL');

    if (rootURL) {
      if (window.location.pathname + '/' === rootURL) {
        window.history.replaceState(null, '', rootURL);
      }

      router.set('rootURL', rootURL);
    }
  }

  exports['default'] = { initialize: initialize };
});