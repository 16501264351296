define('freshfone-ember/components/account-settings/customise-wq/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var alias = _ember['default'].computed.alias;
  exports['default'] = Component.extend({
    customiseWqEnabled: alias('accountConfiguration.customiseWqEnabled'),
    customiseWqSorting: alias('accountConfiguration.customiseWqSorting'),
    customiseWqScope: alias('accountConfiguration.customiseWqScope'),
    actions: {
      updateSorting: function updateSorting(sortingType) {
        this.set('customiseWqSorting', sortingType);
      },
      updateScope: function updateScope(scopeType) {
        this.set('customiseWqScope', scopeType);
      }
    }
  });
});