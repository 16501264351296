define('freshfone-ember/components/call-queue/route-types/route-search/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    tagName: 'div',
    autofocus: true,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('autofocus')) {
        this.focusInput();
      }
      this.set('numberMode', false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('searchEnabled')) {
        scheduleOnce('actions', this, this.get('select').actions.search, '');
      }
    },
    searchTerm: computed.alias('select.searchText'),
    externalNumberPlaceholder: computed(function () {
      return _ember['default'].i18n.t('call_queue.edit.placeholders.externalNumber');
    }),

    focusInput: function focusInput() {
      this.input = self.document.querySelector('.ember-power-select-search-input');
      if (this.input) {
        scheduleOnce('afterRender', this.input, 'focus');
      }
    },

    actions: {
      onKeydown: function onKeydown(e) {
        var onKeydown = this.get('onKeydown'),
            select = this.get('select'),
            checkHighlightedIsExternalNumber = select.highlighted.id === 'externalNumber',
            hasExternalFeature = this.get('isExternalTransferAllowed');

        if (hasExternalFeature || !hasExternalFeature && !checkHighlightedIsExternalNumber) {
          if (onKeydown(e) === false) {
            return false;
          }
        }
      }
    }
  });
});