define('freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/progress-bar/component', ['exports'], function (exports) {
  var _Ember = Ember;
  var computed = _Ember.computed;
  var Component = _Ember.Component;
  exports['default'] = Component.extend({
    progressWidth: computed('secondaryQuestionAnswered.[]', function () {
      if (this.get('secondaryQuestionAnswered').length) {
        return this.get('secondaryQuestionAnswered').length / this.get('secondaryQuestion').length * 100;
      }

      return 0;
    })
  });
});