define('freshfone-ember/models/message', ['exports', 'ember', 'ember-data', 'freshfone-ember/mixins/attachable', 'freshfone-ember/utils/message-types', 'freshfone-ember/helpers/message-default-types', 'freshfone-ember/utils/regex-utils'], function (exports, _ember, _emberData, _freshfoneEmberMixinsAttachable, _freshfoneEmberUtilsMessageTypes, _freshfoneEmberHelpersMessageDefaultTypes, _freshfoneEmberUtilsRegexUtils) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;

  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;

  var VOICE_TYPES = {
    female: 0,
    male: 1
  };exports['default'] = Model.extend(_freshfoneEmberMixinsAttachable['default'], {
    name: attr(),
    messageType: attr({ defaultValue: _freshfoneEmberUtilsMessageTypes['default'].text }),
    messageText: attr(),
    recordingUrl: attr(),
    attachmentName: attr(),
    attachmentUrl: attr(),
    defaultMessageType: attr(),
    isDeletable: computed.equal('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].userMessage),
    isEditable: computed.not('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].callbackMessage),
    isUpdatable: computed('defaultMessageType', function () {
      if (this.get('defaultMessageType') == _freshfoneEmberHelpersMessageDefaultTypes['default'].callbackMessage || this.get('defaultMessageType') == _freshfoneEmberHelpersMessageDefaultTypes['default'].callbackConfirmationMessage) {
        return false;
      }

      return true;
    }),
    attachmentAs: 'attachment',
    messageVoiceType: attr({ defaultValue: VOICE_TYPES.female }),
    voiceLanguage: attr({ defaultValue: 'en' }),
    messageScope: attr('number'),
    userId: attr('number'),

    isActiveRecording: computed.equal('messageType', _freshfoneEmberUtilsMessageTypes['default'].recording),
    isActiveAttachment: computed.equal('messageType', _freshfoneEmberUtilsMessageTypes['default'].attachment),
    isActiveMessage: computed.or('isTextMessage', 'isDynamicMessage'),
    isTextMessage: computed.equal('messageType', _freshfoneEmberUtilsMessageTypes['default'].text),
    isDynamicMessage: computed.equal('messageType', _freshfoneEmberUtilsMessageTypes['default'].dynamic),
    isFemaleVoice: computed.equal('messageVoiceType', VOICE_TYPES.female),
    isMaleVoice: computed.equal('messageVoiceType', VOICE_TYPES.male),

    hasAttachment: computed.or('attachmentUrl.url', 'attachment'),
    hasRecording: computed.notEmpty('recordingUrl'),
    hasValidAttachmentType: computed('attachment.type', function () {
      return ['audio/mp3', 'audio/mpeg'].includes(this.get('attachment.type'));
    }),
    hasValidAttachmentSize: computed.lte('attachment.size', 5242880),
    hasInvalidAttachment: computed.not('hasAttachment'),
    hasPlaceholderText: computed.match('messageText', /<[a-z].*>/i),
    dynamic: computed(function () {
      return (this.changedAttributes().messageType || [this.get('messageType')])[0] === _freshfoneEmberUtilsMessageTypes['default'].dynamic;
    }),
    fullAttachmentUrl: computed('isActiveAttachment', 'hasAttachment', function () {
      if (this.get('hasAttachment')) {
        return this.getFullUrl(this.get('attachmentUrl.url'));
      }
    }),
    fullRecordingUrl: computed('isActiveRecording', 'hasRecording', function () {
      if (this.get('hasRecording')) {
        return this.getFullUrl(this.get('recordingUrl'));
      }
    }),
    getFullUrl: function getFullUrl(url) {
      var rootURL = localStorage.getItem('rootURL');

      return rootURL && rootURL !== '/' && !_freshfoneEmberUtilsRegexUtils['default'].validUrl(url) ? rootURL + url.slice(1) : url;
    },

    playableMessage: computed('isActiveRecording', 'messageVoiceType', 'isActiveAttachment', 'fullRecordingUrl', 'fullAttachmentUrl', 'messageText', 'voiceLanguage', function () {
      var message = {};

      if (this.get('isActiveRecording')) {
        message.url = this.get('fullRecordingUrl');
      } else if (this.get('isActiveAttachment')) {
        message.url = this.get('fullAttachmentUrl');
      } else {
        message.text = this.get('messageText');
      }
      message.voice = this.get('messageVoiceType');
      message.lang = this.get('voiceLanguage');

      return message;
    }),

    hasValidMessage: computed('playableMessage', function () {
      return !!(this.get('playableMessage').url || this.get('playableMessage').text);
    })
  });
});