define('freshfone-ember/components/data-automated-routing/form/data-routing-rules/import-rules/component', ['exports', 'ember', 'freshfone-ember/constants/routing-automation'], function (exports, _ember, _freshfoneEmberConstantsRoutingAutomation) {
  var Component = _ember['default'].Component;
  var typeOf = _ember['default'].typeOf;
  var computed = _ember['default'].computed;
  var gt = _ember['default'].computed.gt;
  var _Ember$run = _ember['default'].run;
  var later = _Ember$run.later;
  var next = _Ember$run.next;
  exports['default'] = Component.extend({
    classNames: ['import-rules'],

    // avoid loading new rule
    rules: computed(function () {
      return this.get('routingAutomation.routingRules').filterBy('isNew', false);
    }),
    hasDirtyRules: computed('rules.@each.hasDirtyAttributes', function () {
      return this.get('rules').isAny('hasDirtyAttributes', true);
    }),
    showDeleteOption: gt('rules.length', 1),

    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        confirmDelete: false,
        showImportRulesModal: false,
        startValidate: false
      });
    },

    actions: {
      importRulesBackdropClick: function importRulesBackdropClick() {
        // check if the modal is open or not
        if (this.get('showImportRulesModal')) {
          if (this.get('hasDirtyRules')) {
            return this.send('showConfirmDialog', 'confirmUnsavedChanges');
          }

          this.set('showImportRulesModal', false);
          document.dispatchEvent(new CustomEvent(_freshfoneEmberConstantsRoutingAutomation.IMPORT_MODAL_PARAMS.close));
        }
      },
      // shows the type of confirmation and hides the import rules modal
      showConfirmDialog: function showConfirmDialog(type) {
        this.set(type, true);
        // TODO remove these lines once the ui-modal component is refactored for modal stack
        next(function () {
          document.getElementsByClassName('slider-modal')[0].parentElement.style.zIndex = '1040';
          document.getElementsByClassName('modal-backdrop in')[0].style.display = 'none';
        });
      },
      // hide all confirm dialogs and shows the import rules modal
      hideConfirmDialogs: function hideConfirmDialogs() {
        this.setProperties({
          confirmImport: false,
          confirmUnsavedChanges: false
        });
        // TODO remove these lines once the ui-modal component is refactored to for modal stack
        document.getElementsByClassName('modal-backdrop in')[0].style.display = 'block';
        document.getElementsByClassName('slider-modal')[0].parentElement.style.zIndex = '1050';
      },
      rollbackChange: function rollbackChange() {
        this.send('hideConfirmDialogs');
        this.set('showImportRulesModal', false);

        if (this.get('hasDirtyRules')) {
          this.get('rules').forEach(function (rule) {
            return rule.rollbackAttributes();
          });
        }
      },
      showImportModal: function showImportModal() {
        var _this = this;

        this.send('hideConfirmDialogs');
        this.set('showImportRulesModal', false);

        if (typeOf(this.get('onShowImportModal')) === 'function') {
          later(function () {
            return _this.get('onShowImportModal')();
          }, 180);
        }
      },
      deleteAllRules: function deleteAllRules() {
        if (typeOf(this.get('onDeleteAllRules')) === 'function') {
          this.get('onDeleteAllRules')();
        }
      }
    }
  });
});