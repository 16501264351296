define("freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/primary-question/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 4
              },
              "end": {
                "line": 19,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/primary-question/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1, "type", "text");
            dom.setAttribute(el1, "class", "others-text");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'placeholder');
            morphs[1] = dom.createAttrMorph(element0, 'onkeyup');
            return morphs;
          },
          statements: [["attribute", "placeholder", ["subexpr", "t", ["billing.cancel_account.drop_comments"], [], ["loc", [null, [null, null], [16, 64]]], 0, 0], 0, 0, 0, 0], ["attribute", "onkeyup", ["subexpr", "action", ["addOthersOption", ["get", "option", ["loc", [null, [17, 44], [17, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [17, 52]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 20,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/primary-question/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "option");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "common/custom-checkbox", [], ["message", ["subexpr", "@mut", [["get", "option.option", ["loc", [null, [6, 17], [6, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "key", ["subexpr", "@mut", [["get", "option", ["loc", [null, [7, 12], [7, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "checkOption", false, "disableSelect", false, "callBackAction", ["subexpr", "action", ["addOrRemoveOption"], [], ["loc", [null, [10, 23], [10, 51]]], 0, 0]], ["loc", [null, [5, 6], [11, 8]]], 0, 0], ["block", "if", [["get", "option.otherOption", ["loc", [null, [13, 10], [13, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 4], [19, 11]]]]],
        locals: ["option"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/primary-question/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "question-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "question");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        return morphs;
      },
      statements: [["content", "primaryQuestion.question", ["loc", [null, [2, 25], [2, 53]]], 0, 0, 0, 0], ["block", "each", [["get", "options", ["loc", [null, [3, 10], [3, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [20, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});