define('freshfone-ember/routes/protected/get-number', ['exports', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/routes/protected'], function (exports, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberRoutesProtected) {
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    beforeModel: function beforeModel() {
      var renderEligibilty = this.hasSignInTypeEligibilty() && !this.get('session.account.onboarding_completed');

      if (!renderEligibilty) {
        this.transitionTo('protected.dashboard');
      }
    }
  });
});