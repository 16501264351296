define('freshfone-ember/components/business-calendar/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/mixins/model-form-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberMixinsModelFormMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    session: service(),
    routing: service('-routing'),
    currentAccount: computed.alias('session.account'),
    isOmnichannelEnabled: computed.alias('currentAccount.isOmnichannelEnabled'),
    isOmniBusinessHours: computed.and('isOmnichannelEnabled', 'hasOmniBusinessCalendarFeature'),

    hasOmniBusinessCalendarFeature: computed(function () {
      return this.hasGlobalFeature('omni_business_calendar') && this.checkFeature('omni_business_calendar');
    }),

    shouldShowOmniView: computed('isOmniBusinessHours', 'isCrmView', 'isSharedBundleAccount', function () {
      if (this.get('isSharedBundleAccount')) {
        return this.get('isOmniBusinessHours') && !this.get('isCrmView');
      }

      return this.get('isOmniBusinessHours');
    }),

    sharedWithoutOmniBusinessCalendar: computed('isSharedBundleAccount', 'hasOmniBusinessCalendarFeature', function () {
      return this.get('isSharedBundleAccount') && !this.get('hasOmniBusinessCalendarFeature');
    }),

    businessHour: computed({
      get: function get() {
        return this;
      },
      set: function set(k, v) {
        var _this = this;

        if (v) {
          this.store.findRecord("business-calendar", v).then(function (calendar) {
            calendar.saveOriginalRelations();
            _this.editAction(calendar);
          });
        }
      }
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('businessCalendar')) {
        this.get('businessCalendar').rollbackModel();
      }
    },
    editAction: function editAction(item) {
      this.set('businessCalendar', item);
      this.set('slideToggle', true);
      this.set('businessHour', null);
      this.set('businessCalendar.editEnabled', false);
      this.get('businessCalendar.enabledDays').clear();
    },
    actions: {
      toggleDelete: function toggleDelete(item) {
        item.set('isDeleting', true);
      },
      onModify: function onModify() {
        this.set('modifyModel', true);
      },
      afterDelete: function afterDelete() {
        this.set('slideToggle', false);
        if (this.get('businessCalendar')) {
          this.get('businessCalendar').rollbackModel();
        }
      },
      edit: function edit(item) {
        var _this2 = this;

        item.saveOriginalRelations();
        item.reload().then(function (calendarItem) {
          _this2.editAction(calendarItem);
        });
      },
      clearParams: function clearParams() {
        this.get('routing').transitionTo('protected.admin.businesshour', [], { businessHour: undefined });
      }
    }
  });
});