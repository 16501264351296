define('freshfone-ember/models/call-metric', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    handle_time: attr(),
    hold_duration: attr(),
    ivr_time: attr(),
    answeredAt: attr(),
    enqueuedAt: attr(),
    total_ringing_time: attr(),
    queue_duration: attr(),
    answering_speed: attr(),
    talk_time: attr(),
    ringing_at: attr(),
    wait_time: attr(),
    call: belongsTo('call', { async: true }),
    call_work_time: attr(),
    holdAt: attr(),
    shortAbandoned: attr(),
    time_to_answer: attr(),
    time_to_callback: attr()
  });
});