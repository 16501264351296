define('freshfone-ember/adapters/live-agent-status', ['exports', 'freshfone-ember/adapters/application', 'ember'], function (exports, _freshfoneEmberAdaptersApplication, _ember) {
  exports['default'] = _freshfoneEmberAdaptersApplication['default'].extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (query && _ember['default'].isPresent(query["filter"])) {
        query.module = 'live-agent-status';
      }
      if (!id || requestType == 'updateRecord') {
        modelName = 'agent';
      }
      return this._super(modelName, id, snapshot, requestType, query);
    }
  });
});