define('freshfone-ember/components/ivr-component/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/remove-feature-actiontype'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsRemoveFeatureActiontype) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    routing: service('-routing'),

    list: computed('callFlow', function () {
      return [this.get('callFlow.ivr.content')];
    }),
    showLoader: computed('callFlow.ivr.isFulfilled', function () {
      return !this.get('callFlow.ivr.isFulfilled');
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.hasFeatures(['voicemail_and_status_fallback'])) {
        var defaultActionTypes = this.get('callFlow.ivr.actionOptions'),
            filteredActionTypes = this.removeObjFromArray(defaultActionTypes, 'name', 'vm');

        this.set('callFlow.ivr.actionOptions', filteredActionTypes);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      this._super.apply(this, arguments);
      // Clean up
      this.cleanUpUnsavedCallQueue();
      this.rollbackUnsavedChanges();
      this.rollbackIvr();
      this.unloadOptions();
      ['routingAutomation', 'routingRule'].forEach(function (model) {
        return _ember['default'].run(function () {
          return _this.store.unloadAll(model);
        });
      });
    },

    saveIVRCallFlow: function saveIVRCallFlow() {
      var _this2 = this;

      this.set('callFlow.name', this.get('callFlow.ivr.name'));
      this.get('callFlow').save().then(function (callFlow) {
        var id = callFlow.id;

        callFlow.get('ivr.content').save().then(function () {
          _this2.saveCallbacks(id);
        })['catch'](function (e) {
          // handle server errors
          console.log('e =>', e);
        });
      })['catch'](function (e) {
        // handle server errors
        console.log('e =>', e);
      });
    },

    saveCallbacks: function saveCallbacks(id) {
      var _this3 = this;

      this.get('callFlow').reload().then(function () {
        _this3.set('startValidate', false);
        _this3.set('isSaving', false);
        if (_this3.get('editNumber')) {
          _this3.get('routing').transitionTo('protected.admin.numbers', [], {
            callFlowID: id,
            editNumber: _this3.get('editNumber'),
            actionFor: _this3.get('actionFor')
          });
        } else {
          _this3.get('routing').transitionTo('protected.admin.callflows');
        }
      });
    },
    rollbackIvr: function rollbackIvr() {
      this.get('callFlow.ivr').then(function (ivr) {
        if (ivr) {
          ivr.rollbackIvr();
        }
      });
    },
    unloadOptions: function unloadOptions() {
      var newOptions = this.store.peekAll('ivr-option').filterBy('isNew', true);

      newOptions.forEach(function (option) {
        option.unloadOption();
      });
    },
    cleanUpUnsavedCallQueue: function cleanUpUnsavedCallQueue() {
      /*
       * let newQueues = this.store.peekAll('call-queue').filterBy('isNew', true);
       * newQueues.forEach((queue) => { queue.unloadRecord(); });
       */
      this.store.unloadAll('call-queue'); // Clearing old records from DS
    },
    rollbackUnsavedChanges: function rollbackUnsavedChanges() {
      this.get('callFlow.ivr.content').rollbackAttributes();
      this.get('callFlow').rollbackAttributes();
    },
    redirectionURL: computed('editNumber', function () {
      if (this.get('editNumber')) {
        return 'protected.admin.numbers';
      }

      return 'protected.admin.callflows';
    }),

    actions: {
      save: function save() {
        var _this4 = this;

        this.get('callFlow.ivr').then(function (ivr) {
          _this4.set('startValidate', true);
          _this4.set('isSaving', true);
          ivr.validateAndSaveKeyPress().then(function () {
            ivr.validate().then(function () {
              _this4.set('isFlowIncomplete', false);
              _this4.saveIVRCallFlow();
            })['catch'](function () {
              _this4.set('isFlowIncomplete', true);
              _this4.set('isSaving', false);
            });
          })['catch'](function () {
            _this4.set('isFlowIncomplete', true);
            _this4.set('isSaving', false);
          });
        });
      }
    }
  });
});