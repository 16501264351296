define("freshfone-ember/helpers/is-all-selected", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  exports.isAllSelected = isAllSelected;

  function isAllSelected(args) {
    var _args = _slicedToArray(args, 3);

    var groupName = _args[0];
    var gropuedQueues = _args[1];
    var selectedQueues = _args[2];

    if (!selectedQueues) {
      return false;
    }

    if (groupName !== "Other Queues") {
      return gropuedQueues.length === selectedQueues.filter(function (queue) {
        return queue.teamName === groupName;
      }).length;
    } else {
      return gropuedQueues.length === selectedQueues.filter(function (queue) {
        return !queue.teamName;
      }).length;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(isAllSelected);
});