define('freshfone-ember/components/data-automated-routing/form/data-routing-rules/component', ['exports', 'ember', 'freshfone-ember/constants/routing-automation'], function (exports, _ember, _freshfoneEmberConstantsRoutingAutomation) {
  var ajax = _ember['default'].$.ajax;
  var Component = _ember['default'].Component;
  var assert = _ember['default'].assert;
  var typeOf = _ember['default'].typeOf;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var and = _Ember$computed.and;
  var equal = _Ember$computed.equal;
  var gt = _Ember$computed.gt;
  var lt = _Ember$computed.lt;
  var not = _Ember$computed.not;
  exports['default'] = Component.extend({
    classNames: ['rules-container'],
    rulesArray: alias('routingAutomation.routingRules'),
    ruleType: alias('routingAutomation.routingRuleType'),
    showCreateRule: lt('rulesArray.length', 15),
    showRemoveRule: gt('rulesArray.length', 1),
    createRuleContainer: equal('ruleType', _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES.create),
    hasImportedRules: equal('routingAutomation.routingRuleType', _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import']),
    notRulesFieldDisabled: not('rulesFieldDisabled'),
    showImportedRulesInfo: and('notRulesFieldDisabled', 'hasImportedRules', 'routingAutomation.routingRulesCount'),
    importDisabledTooltip: computed('showImportedRulesInfo', function () {
      if (this.get('isAFallBack') && !this.get('showImportedRulesInfo')) {
        return _ember['default'].i18n.t('data_automated_routing.import_rules.disabled_fallback');
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      // create a default rule only if there are no rules
      if (this.get('routingAutomation.routingRulesCount') === 0 && this.get('notRulesFieldDisabled') && !this.get('isAFallBack')) {
        this.set('ruleType', _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES.create);
        this.get('routingAutomation.content').createDefaultRoutingRule();
      }
    },
    postDeleteCleanUp: function postDeleteCleanUp() {
      var _this = this;

      var routingAutomation = this.get('routingAutomation.content') || this.get('routingAutomation');

      routingAutomation.reload().then(function () {
        var nextRuleType = _this.get('ruleType') === _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import'] ? _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES.create : _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import'];

        // rollback the routingRulesCount count, since we are manually setting this property
        routingAutomation.rollbackRoutingRulesCount();
        _this.set('rulesArray', []);
        routingAutomation.createDefaultRoutingRule();
        _this.send('setRuleType', nextRuleType);
      });
    },
    actions: {
      addRule: function addRule(routingAutomation) {
        (routingAutomation.hasOwnProperty('content') && routingAutomation.get('content') ? routingAutomation.get('content') : routingAutomation).createDefaultRoutingRule();
      },
      confirmChangeRuleType: function confirmChangeRuleType(ruleType) {
        // show a confirm dialog to the user before switching between ruleTypes
        if (this.get('routingAutomation.routingRulesCount')) {
          return this.set('confirmOverwrite', true);
        }

        this.send('setRuleType', ruleType);
      },
      setRuleType: function setRuleType(ruleType) {
        assert('onRuleTypeChange should be a function', typeOf(this.get('onRuleTypeChange')) === 'function');

        this.set('confirmOverwrite', false);
        this.get('onRuleTypeChange')(ruleType);
      },
      /**
       * for ruleType toggle just delete the current rules
       * since we are getting user's consent for the same
       */
      toggleRuleType: function toggleRuleType() {
        var _this2 = this;

        ajax({
          url: '/routing_rules/bulk_destroy',
          type: 'DELETE',
          dataType: 'json',
          // eslint-disable-next-line camelcase
          data: { routing_automation_id: this.get('routingAutomation.id') }
        }).done(function () {
          return _this2.postDeleteCleanUp();
        });
      },
      showImportModal: function showImportModal() {
        assert('onRuleTypeChange should be a function', typeOf(this.get('onRuleTypeChange')) === 'function');

        this.get('onRuleTypeChange')(_freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import']);
      },
      removeRule: function removeRule(rule) {
        rule.unloadRecord();
      }
    }
  });
});