define("freshfone-ember/utils/random-number", ["exports"], function (exports) {
  var getRandomNumber = function getRandomNumber(min, max) {
    var array = new Uint32Array(1);

    window.crypto.getRandomValues(array);
    // eslint-disable-next-line one-var
    var randomValue = array[0] / (Math.pow(2, 32) - 1),
        randomNumber = Math.floor(randomValue * max) + min;

    return randomNumber;
  };
  exports.getRandomNumber = getRandomNumber;
});