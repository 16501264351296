define('freshfone-ember/routes/protected/admin/agent-extension/edit', ['exports', 'ember', 'freshfone-ember/mixins/titleize', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberMixinsTitleize, _freshfoneEmberRoutesProtected) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsTitleize['default'], {
    breadCrumb: {},
    isFromModel: false,
    queryParams: {
      agentExtension: {
        refreshModel: true
      },
      editNumber: {}
    },

    titleToken: function titleToken(model) {
      return [model.get('name'), this.titleize(model._internalModel.modelName)].join(" : ");
    },

    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.checkFeature('agent_extension');
    },

    model: function model(params) {
      var _this = this;

      if (params.rule) {
        this.set("rule", params.rule);
      }
      if (isPresent(params.agentExtension)) {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          _this.store.find('agentExtension', params.agentExtension).then(function (agentExtension) {
            agentExtension.get("callFlow").then(function (callFlow) {
              resolve(callFlow);
            });
          });
        });
      }
      return this.store.findRecord('callFlow', params.flow_id, { reload: true });
    },

    afterModel: function afterModel(model) {
      var title = get(model, 'name');
      set(this, 'breadCrumb.title', title);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('rule', undefined);
      }
    }

  });
});