define('freshfone-ember/models/role', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  var _Ember = Ember;
  var computed = _Ember.computed;
  exports['default'] = _emberData['default'].Model.extend(_emberValidations['default'], {
    //user: DS.hasMany('user', { async: true }),
    name: _emberData['default'].attr('string'),
    label: computed('name', function () {
      var roleName = this.get('name').replace(/\s/g, '').toLowerCase();
      return Ember.i18n.t('role_label.' + roleName);
    })
  });
});