define('freshfone-ember/components/manage-intents/review-customer-phrases/review-customer-phrase-row/component', ['exports', 'ember'], function (exports, _ember) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var run = _ember['default'].run;
    var service = _ember['default'].inject.service;
    exports['default'] = Component.extend({
        notification: service('toastr'),
        intents: computed(function () {
            return this.get('store').peekAll('intent');
        }),
        showApply: computed('changedIntent', 'isChangesSaved', function () {
            return this.get('changedIntent') && !this.get('isChangesSaved');
        }),
        actions: {
            onChanged: function onChanged(changedValue) {
                this.setProperties({
                    'changedIntent': changedValue,
                    'isChangesSaved': false
                });
            },
            changeIntentTo: function changeIntentTo() {
                var _this = this;

                this.set('isSaving', true);
                var intentName = this.get('changedIntent.name'),
                    intent = this.store.peekAll('intent').filterBy('name', intentName)[0],
                    phraseList = [].concat(_toConsumableArray(intent.get('phrases').map(function (phrase) {
                    return phrase.get('name');
                })), [this.get('ivrInput.attributes.ivrInputData.query')]),
                    params = {
                    "bot_type": this.get('botName'),
                    "examples": _defineProperty({}, intentName, {
                        "new_data": phraseList,
                        "request_type": "overwrite"
                    })
                };
                this.store.peekAll('speech-ivr-bot').filterBy('name', this.get('botName'))[0].updateBot(params).then(function (response) {
                    run(function () {
                        if (response.message && response.message.model_retraining) {
                            _this.set('isChangesSaved', true);
                            intent.destroyRecord();
                            _this.sendAction('createIntentModel', intentName, phraseList);
                        }
                    });
                })['catch'](function (error) {
                    _this.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.edit_phrases_error_message'));
                })['finally'](function () {
                    _this.set('isSaving', false);
                });
            },
            toggleCallDetails: function toggleCallDetails() {
                this.toggleProperty('showCallDetails');
            }
        }
    });
});