define('freshfone-ember/components/call-logs/call-log-export/export-details/component', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, _ember, _emberComponentInboundActionsInboundActions) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var defaultExportFeilds = ['call_id', 'customer_name', 'customer_number', 'direction', 'agent_name', 'call_queue_name', 'number', 'call_status'];exports['default'] = Component.extend(_emberComponentInboundActionsInboundActions['default'], {
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    exportAs: 'csv',
    csvAsDefault: 'true',
    exportFormat: computed.alias('savedExportConfiguration.export_to'),
    isSelectedAll: computed('exportFieldsToForm', 'triggerValueChanged', {
      get: function get() {
        return this.get('exportFieldsToForm') && [].concat(_toConsumableArray(this.get('exportFieldsToForm').values())).filterBy('isChecked', true).length === this.get('currentUser.exportColumns.length');
      },

      set: function set(key, value) {
        this.filterMap(value);

        return value;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('currentUser.exportColumns.length')) {
        this.filterMap(false, this.get('savedExportConfiguration.export_attributes'));
      }
    },

    filterMap: function filterMap(value, savedConfiguration) {
      var _this = this;

      var newMap = new Map(),
          savedExportConfiguration = Array.isArray(savedConfiguration) ? savedConfiguration : [];

      this.get('currentUser.exportColumns').forEach(function (exportKey) {
        var obj = {};

        obj.isChecked = defaultExportFeilds.indexOf(exportKey) > -1 || savedExportConfiguration.indexOf(exportKey) > -1 ? true : value;
        obj.isDefault = defaultExportFeilds.indexOf(exportKey) > -1;
        obj.name = _ember['default'].i18n.t('export_data.exportColumns.' + exportKey, { deskName: _this.get('currentAccount.deskName') });

        newMap.set(exportKey, obj);
      });

      this.set('exportFieldsToForm', newMap);
    },

    selectAllExportFields: function selectAllExportFields(value) {
      this.set('isSelectedAll', value);
    },

    actions: {
      triggerExport: function triggerExport() {
        this.get('triggerExport')(this.get('exportAs'), 'calls', this.get('exportFieldsToForm'));
        this.get('closeExportDetailsSlider')();
      },

      setCheckedValue: function setCheckedValue(value) {
        this.set('exportAs', value);
      },

      exportFieldActionCallBack: function exportFieldActionCallBack(key, value) {
        if (key === 'select-all') {
          this.selectAllExportFields(value);
        } else {
          var keyToUpdate = this.get('exportFieldsToForm');

          this.toggleProperty('triggerValueChanged');
          keyToUpdate.set(key, Object.assign({}, keyToUpdate.get(key), { isChecked: value }));
        }
      },

      closeModal: function closeModal() {
        this.get('closeExportDetailsSlider')();
      },

      openModal: function openModal() {
        this.set('showExportDetails', true);
      }
    }
  });
});
/* eslint-disable prefer-object-spread */
/* eslint-disable camelcase */
/* eslint-disable ember-suave/no-direct-property-access */