define('freshfone-ember/components/ui-alert/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    icon: computed.alias('alertInfo.icon'),
    isDismissable: computed.alias('alertInfo.isDismissable'),
    message: computed.alias('alertInfo.text'),
    type: computed.alias('alertInfo.type'),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
});