define('freshfone-ember/components/billing-base/subscription-base/cancel-account-modal/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/utils/subscription-constants'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberUtilsSubscriptionConstants) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    reasonOptions: computed(function () {
      var options = [];
      var reasonTypes = _ember['default'].i18n.t('billing.cancel_account.reasons', { productName: this.get('currentAccount.productName') }).string.split('|');
      reasonTypes.forEach(function (reason, i) {
        options.push({ feedback: reason, id: i + 1 });
      });
      return options;
    }),
    reasonEmpty: computed.empty('reason'),
    feedbackEmpty: computed.empty('feedback'),
    feedbackRequired: false,
    currentAccount: computed.alias('session.account'),
    isTrial: computed.alias('currentAccount.isTrial'),
    hasNewDeletionPolicy: computed(function () {
      return this.hasGlobalFeature("new_deletion_policy");
    }),

    cancelAccountMessage: computed(function () {
      if (this.get('hasNewDeletionPolicy') && !this.get('isTrial')) {
        return _ember['default'].i18n.t('billing.cancel_account.request_message');
      } else {
        return _ember['default'].i18n.t('billing.cancel_account.message');
      }
    }),

    cancelButtonText: computed(function () {
      if (this.get('hasNewDeletionPolicy') && !this.get('isTrial')) {
        return _ember['default'].i18n.t('billing.cancel_account.cancel_request');
      } else {
        return _ember['default'].i18n.t('billing.cancel_account.cancel');
      }
    }),

    feedbackLabel: computed('reason.feedback', function () {
      var selectedReason = this.get('reason.feedback'),
          reasonTypes = _ember['default'].i18n.t('billing.cancel_account.reasons', { productName: this.get('currentAccount.productName') }).string.split('|'),
          feedbackLabels = _ember['default'].i18n.t('billing.cancel_account.feedbacks').string.split('|');

      switch (selectedReason) {
        case reasonTypes[1]:
          this.set('feedbackRequired', true);
          return feedbackLabels[0];
        case reasonTypes[2]:
          this.set('feedbackRequired', true);
          return feedbackLabels[1];
        default:
          this.set('feedbackRequired', false);
          return feedbackLabels[2];
      }
    }),

    postDeleteActions: function postDeleteActions() {
      if (this.get('isTrial') || !this.get('hasNewDeletionPolicy')) {
        window.location.replace(_freshfoneEmberUtilsSubscriptionConstants['default'].ACCOUNT_DELETED_REDIRECT_URL);
      } else {
        this.set('isDeleteRequested', true);
        this.send('close');
      }
    },

    actions: {
      close: function close() {
        this.sendAction('close');
      },
      cancel: function cancel() {
        var _this = this;

        this.set('isCancelling', true);
        this.set('isError', false);
        $.ajax({
          url: '/accounts/delete',
          type: 'POST',
          dataType: "json",
          data: { feedback: this.get('feedback'),
            cancel_reason: this.get('reason').id }
        }).done(function (response) {
          _ember['default'].run(function () {
            if (response['success']) {
              _this.postDeleteActions();
            } else {
              _this.set('isError', true);
              _this.set('isCancelling', false);
              _this.set('errorText', _ember['default'].i18n.t('billing.cancel_account.cancel_error'));
            }
          });
        }).fail(function (error) {
          _ember['default'].run(function () {
            _this.set('isCancelling', false);
            _this.set('isError', true);
            _this.set('errorText', _ember['default'].i18n.t('billing.cancel_account.cancel_error'));
          });
        });
      }
    }
  });
});