define('freshfone-ember/components/ongoing-widget/minimized-widget/component', ['exports', 'freshfone-ember/constants/active-call-type', 'freshfone-ember/constants/call-state', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember'], function (exports, _freshfoneEmberConstantsActiveCallType, _freshfoneEmberConstantsCallState, _emberRlDropdownMixinsRlDropdownComponent, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend(_emberRlDropdownMixinsRlDropdownComponent['default'], {
    currentTime: computed.alias('currentCall.callDuration'),
    parallelCallTime: computed.alias('currentCall.parallelCallDuration'),
    isAgentConference: computed.equal('currentCall.type', 'agent_conference'),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    isAgentInPowerDialerMode: computed.alias('currentAgent.powerDialer'),
    recordTypeService: service('record-type'),
    isAutomaticRecording: computed.alias('recordTypeService.isAutomaticRecording'),
    isManualRecording: computed.alias('recordTypeService.isManualRecording'),
    toggleTimer: computed('currentCall.minimized', {
      get: function get() {
        if (this.get('currentCall.isInProgress')) {
          return this.get('currentCall.minimized') ? this.get('timer').send('play') : this.get('timer').send('stop');
        }
        if (this.get('currentCall.isCompleted') && this.get('timer')) {
          this.get('timer').send('stop');
        }
      },
      set: function set(key, value) {}
    }),
    showRecordIcon: computed.alias('currentCall.isBeingRecorded'),
    showMinimizedRecordingState: computed.or('manualRecordingStarted', 'isAutomaticRecording'),
    agentContact: computed('currentCall.from', function () {
      var contact = this.get('currentCall.from');

      return contact.name;
    }),
    isSecondaryParallelCallActive: computed.alias('currentCall.isParallelCall'),
    isParallelCallActive: computed('currentCall.callType', 'isSecondaryParallelCallActive', function () {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel || this.get('isSecondaryParallelCallActive');
    }),
    primaryConferenceCaller: computed('currentCall.conferenceTarget', function () {
      var target = this.get('currentCall.conferenceTarget');

      return target && target.name;
    }),
    parallelConferenceCaller: computed('parallelCalls.@each.conferenceTarget', function () {
      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];
      var conferenceTarget = parallelCall && parallelCall.get('conferenceTarget');

      return conferenceTarget && conferenceTarget.name;
    }),
    // primaryCaller: computed.or('currentCall.contact.name', 'currentCall.callerName'),
    primaryCaller: computed('currentCall.callState', 'currentCall.contact.name', 'currentCall.callerName', 'primaryConferenceCaller', function () {
      var callState = this.get('currentCall.callState');

      if (callState === _freshfoneEmberConstantsCallState['default'].Ended || callState === _freshfoneEmberConstantsCallState['default'].Removed) {
        return this.get('primaryConferenceCaller');
      }

      return this.get('currentCall.contact.name') || this.get('currentCall.callerName');
    }),
    secondaryCaller: computed('parallelCalls.[]', 'isSecondaryParallelCallActive', 'agentContact', 'parallelCalls.@each.state', 'parallelConferenceCaller', function () {
      if (this.get('isSecondaryParallelCallActive')) {
        return this.get('agentContact');
      }

      var _get3 = this.get('parallelCalls');

      var _get32 = _slicedToArray(_get3, 1);

      var parallelCall = _get32[0];

      if (parallelCall && (parallelCall.get('state') === _freshfoneEmberConstantsCallState['default'].Ended || parallelCall.get('state') === _freshfoneEmberConstantsCallState['default'].Removed)) {
        return this.get('parallelConferenceCaller');
      }

      return parallelCall && parallelCall.target ? parallelCall.target.name : '';
    }),
    contactName: computed('isParallelCallActive', 'primaryCaller', 'secondaryCaller', function () {
      return this.get('isParallelCallActive') ? this.get('secondaryCaller') : this.get('primaryCaller');
    }),
    showParallelCallTimer: computed('currentCall.callType', 'callHasMerged', function () {
      return this.get('currentCall.callType') && this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel && !this.get('callHasMerged');
    }),
    hideNotesIcon: computed.or('isSecondaryParallelCallActive', 'isAgentConference'),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },

    actions: {
      toggleMaximise: function toggleMaximise() {
        this.set('currentCall.minimized', false);
        $('.modal-backdrop').show();
        // #todo, make it generic, from a component
      }
    }
  });
});