define('freshfone-ember/routes/protected/admin/call-tags', ['exports', 'ember', 'freshfone-ember/routes/promotable', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/support-portal-link', 'freshfone-ember/promotion-data/tag'], function (exports, _ember, _freshfoneEmberRoutesPromotable, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersSupportPortalLink, _freshfoneEmberPromotionDataTag) {
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesPromotable['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    titleToken: computed(function () {
      return _ember['default'].i18n.t('admin_components.CallTags').string;
    }),
    breadCrumb: computed(function () {
      return {
        title: _ember['default'].i18n.t('admin_components.CallTags').string,
        supportPortalLink: (0, _freshfoneEmberHelpersSupportPortalLink.supportPortalLink)(['settingUpCallTags'])
      };
    }),
    hasCallTaggingFeature: function hasCallTaggingFeature() {
      return this.checkFeature('call_tagging');
    },
    beforeModel: function beforeModel() {
      return this.checkPrivilege('manage_tags');
    },
    model: function model() {
      var _this = this;

      var tags = undefined;
      if (this.hasCallTaggingFeature()) {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          tags = _this.store.query('tag', { paginate: false }).then(function (reponse) {
            resolve(reponse);
          });
        });
      }
      return this._super(_freshfoneEmberPromotionDataTag['default'].data, tags, 'call_tagging');
    }
  });
});