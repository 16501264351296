define('freshfone-ember/mixins/safe-set', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    set: function set() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      return this._super.apply(this, arguments);
    },
    toggleProperty: function toggleProperty() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      return this._super.apply(this, arguments);
    },
    setProperties: function setProperties() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      return this._super.apply(this, arguments);
    }
  });
});