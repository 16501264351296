define('freshfone-ember/components/billing-base/subscription-base/subscription-info/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    currentAccount: computed.alias('session.account'),
    isOmnichannelAccount: computed.alias('currentAccount.isOmnichannelEnabled'),
    newDeletionScheduled: computed(function () {
      return this.get('session.account.deletionScheduled') && this.hasGlobalFeature('account_cancellation_enhancement');
    }),
    subscription: computed.alias('session.account.subscription'),

    nextRenewalDate: computed.alias('subscription.nextRenewalDate'),
    actions: {
      toggleEditClassicPlan: function toggleEditClassicPlan() {
        if (this.get('isOmnichannelAccount')) {
          this.send('redirectToOmnichannelEditPlan');
        } else if (this.get('showActionForClassicPlan')) {
          this.get('toggleEditClassicPlan')();
        }
      },
      redirectToOmnichannelEditPlan: function redirectToOmnichannelEditPlan() {
        var url = this.get('currentAccount.omnichannelOwnerDomain') + '/a/admin/subscription';

        window.open(url, '_blank');
      }
    }
  });
});