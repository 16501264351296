define('freshfone-ember/helpers/warning-name-unit', ['exports'], function (exports) {
  exports['default'] = {
    'low-mos': {
      name: 'Low Mos',
      unit: 'ms'
    },
    'high-jitter': {
      name: 'High Jitter',
      unit: 'ms'
    },
    'high-rtt': {
      name: 'High RTT',
      unit: 'ms'
    },
    'high-packet-loss': {
      name: 'High Packet Loss',
      unit: '%'
    },
    'constant-audio-input-level': {
      name: 'No Constant Audio Input Level',
      unit: ''
    },
    'constant-audio-output-level': {
      name: 'No Constant Audio Output Level',
      unit: ''
    },
    'low-bytes-received': {
      name: 'Low Bytes Received',
      unit: ''
    }
  };
});