define('freshfone-ember/constants/lang-urls', ['exports'], function (exports) {
  // HARDCODED - BASEURL

  exports['default'] = {
    'ar': 'assets/locales/ar.json',
    'ca': 'assets/locales/ca.json',
    'cs': 'assets/locales/cs.json',
    'da': 'assets/locales/da.json',
    'de': 'assets/locales/de.json',
    'en': 'assets/locales/en.json',
    'es-ES': 'assets/locales/es-ES.json',
    'es-MX': 'assets/locales/es-MX.json',
    'et': 'assets/locales/et.json',
    'fi': 'assets/locales/fi.json',
    'fr': 'assets/locales/fr.json',
    'he': 'assets/locales/he.json',
    'hu': 'assets/locales/hu.json',
    'id': 'assets/locales/id.json',
    'it': 'assets/locales/it.json',
    'ja': 'assets/locales/ja.json',
    'ko': 'assets/locales/ko.json',
    'lv': 'assets/locales/lv.json',
    'no': 'assets/locales/no.json',
    'nl': 'assets/locales/nl.json',
    'pl': 'assets/locales/pl.json',
    'pt-BR': 'assets/locales/pt-BR.json',
    'pt-PT': 'assets/locales/pt-PT.json',
    'ro': 'assets/locales/ro.json',
    'ru': 'assets/locales/ru.json',
    'sk': 'assets/locales/sk.json',
    'sl': 'assets/locales/sl.json',
    'sv-SE': 'assets/locales/sv-SE.json',
    'th': 'assets/locales/th.json',
    'tr': 'assets/locales/tr.json',
    'uk': 'assets/locales/uk.json',
    'vi': 'assets/locales/vi.json',
    'zh-CN': 'assets/locales/zh-CN.json',
    'zh-TW': 'assets/locales/zh-TW.json'
  };
});