define("freshfone-ember/components/common/message-manager/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/common/message-manager/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "option.name", ["loc", [null, [19, 2], [19, 17]]], 0, 0, 0, 0]],
        locals: ["option"],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 6
                },
                "end": {
                  "line": 31,
                  "column": 6
                }
              },
              "moduleName": "freshfone-ember/components/common/message-manager/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "component", ["message-components/new-message"], ["message", ["subexpr", "@mut", [["get", "messageHolder", ["loc", [null, [25, 16], [25, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "isFromModal", true, "isOpen", ["subexpr", "@mut", [["get", "showMessageModal", ["loc", [null, [27, 15], [27, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "closeCallBack", ["subexpr", "action", ["closeMessage"], [], ["loc", [null, [28, 22], [28, 45]]], 0, 0], "saveCallBack", ["subexpr", "action", ["setNewMessage"], [], ["loc", [null, [29, 21], [29, 45]]], 0, 0]], ["loc", [null, [24, 8], [30, 10]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 4
              },
              "end": {
                "line": 32,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/common/message-manager/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ui-modal", [], ["size", "sm", "closeOnEscape", false, "closeOnBackdropClick", false], 0, null, ["loc", [null, [23, 6], [31, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 2
            },
            "end": {
              "line": 33,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/common/message-manager/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ember-wormhole", [], ["to", "modal-wormhole"], 0, null, ["loc", [null, [22, 4], [32, 23]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 9
          }
        },
        "moduleName": "freshfone-ember/components/common/message-manager/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "custom-elements/power-select", [], ["label", ["subexpr", "@mut", [["get", "messageLabel", ["loc", [null, [2, 10], [2, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "mandatory", ["subexpr", "@mut", [["get", "mandatory", ["loc", [null, [3, 14], [3, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 10], [4, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "options", ["loc", [null, [5, 12], [5, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [6, 10], [6, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [7, 11], [7, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "hasCreateOption", true, "attributeName", "name", "searchField", "name", "afterOptionsComponent", "custom-elements/new-message", "onNewObjectCreate", ["subexpr", "action", ["createNewMessage"], [], ["loc", [null, [12, 22], [12, 49]]], 0, 0], "onChange", ["subexpr", "action", ["onChanged"], [], ["loc", [null, [13, 13], [13, 33]]], 0, 0], "parent", ["subexpr", "@mut", [["get", "parent", ["loc", [null, [14, 11], [14, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "isOpened", ["subexpr", "@mut", [["get", "isOpened", ["loc", [null, [15, 13], [15, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "optionsComponent", "custom-elements/message-list"], 0, null, ["loc", [null, [1, 0], [20, 33]]]], ["block", "if", [["get", "showMessageModal", ["loc", [null, [21, 8], [21, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [21, 2], [33, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});