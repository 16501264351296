define('freshfone-ember/components/outgoing-widget/keypad/component', ['exports', 'ember', 'freshfone-ember/mixins/click-outside-listener'], function (exports, _ember, _freshfoneEmberMixinsClickOutsideListener) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], _ember['default'].Evented, {
    isOpen: computed({
      get: function get() {},
      set: function set(key, value) {
        this.set('focusDisplay', value);
        return value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('origin') === 'inConversation') {
        this.bindOutsideClick();
      }
      // #todo, understand why this is not happening for the instance
    },
    onOutsideClick: function onOutsideClick() {
      if (this.get('origin') === 'inConversation') {
        this.send('closeKeyPad');
      }
    },

    actions: {
      toggleKeypad: function toggleKeypad() {
        if (!this.get('disabled')) this.toggleProperty('isOpen');
      },
      closeKeyPad: function closeKeyPad() {
        this.set('isOpen', false);
      }
    }
  });
});