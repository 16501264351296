define('freshfone-ember/components/live-calls/real-time-stats/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend({
    currentAccount: computed.alias('session.account'),
    productName: computed.alias('currentAccount.productName'),
    selectedQueueIds: computed.mapBy('selectedQueues', 'id'),
    queuedCallsCount: computed.alias('queuedCalls.length'),
    progressCallsCount: computed.alias('progressCalls.length'),
    calls: computed(function () {
      return this.store.peekAll('call');
    }),

    agents: computed('selectedQueueIds.[]', function () {
      var agents = this.store.peekAll('live-agent-status').filter(function (agent) {
        return !agent.get('deleted');
      });
      var queueIds = this.get("selectedQueueIds");
      if (isEmpty(queueIds)) {
        return agents;
      }
      return agents.filter(function (agent) {
        return queueIds.any(function (item) {
          return agent.get("callQueueIds").includes(Number(item));
        });
      });
    }),

    hasValidQueue: function hasValidQueue(call, queues) {
      if (isEmpty(call.get("callQueueId"))) return;
      return queues.includes(call.get("callQueueId").toString());
    },

    availableAgentsCount: computed('agents.@each.mobileStatus', 'agents.@each.browserStatus.statusType', function () {
      if (this.get('agents.length')) {
        var availableAgents = this.get('agents').filter(function (agent) {
          return agent.get('isAvailable');
        });
        return availableAgents.length;
      }
    }),

    queuedCalls: computed('calls.@each.status', 'selectedQueueIds.[]', function () {
      var _this = this;

      var queues = this.get('selectedQueueIds') || [];
      return this.get("calls").filter(function (call) {
        return call.get('isQueued') && (isEmpty(queues) || _this.hasValidQueue(call, queues));
      });
    }),

    progressCalls: computed('calls.@each.status', 'selectedQueueIds.[]', function () {
      var _this2 = this;

      var queues = this.get('selectedQueueIds') || [];
      return this.get("calls").filter(function (call) {
        return (call.get('isOngoing') || call.get('isOnHold')) && (isEmpty(queues) || _this2.hasValidQueue(call, queues));
      });
    })
  });
});