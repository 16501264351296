define("freshfone-ember/components/feedback-widget/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 6
              },
              "end": {
                "line": 39,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "help-options");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "list video-container");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "video-thumb");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4, "class", "thumbnail-img");
            dom.setAttribute(el4, "src", "assets/images/video-thumb.png");
            dom.setAttribute(el4, "alt", "Getting started with Freshcaller");
            dom.setAttribute(el4, "data-analytics-action-name", "Onboarding Tutorial Video");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "icon-video-play");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "video-description");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "video-title");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "video-duration");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "video-watch");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "list");
            dom.setAttribute(el2, "target", "_blank");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "list");
            var el3 = dom.createTextNode("\n            Contact us\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [1]);
            var element6 = dom.childAt(element5, [3]);
            var element7 = dom.childAt(element4, [3]);
            var element8 = dom.childAt(element4, [5]);
            var morphs = new Array(7);
            morphs[0] = dom.createElementMorph(element5);
            morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element6, [5]), 0, 0);
            morphs[4] = dom.createAttrMorph(element7, 'href');
            morphs[5] = dom.createMorphAt(element7, 1, 1);
            morphs[6] = dom.createElementMorph(element8);
            return morphs;
          },
          statements: [["element", "action", ["toggleShowVideo"], [], ["loc", [null, [21, 44], [21, 72]]], 0, 0], ["content", "videoParams.videoTitle", ["loc", [null, [27, 39], [27, 65]]], 0, 0, 0, 0], ["content", "videoParams.videoDuration", ["loc", [null, [28, 42], [28, 71]]], 0, 0, 0, 0], ["inline", "t", ["help_widget.watch"], [], ["loc", [null, [29, 39], [29, 64]]], 0, 0], ["attribute", "href", ["get", "articlesLink", ["loc", [null, [32, 49], [32, 61]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["help_widget.solution_articles"], [], ["loc", [null, [33, 12], [33, 49]]], 0, 0], ["element", "action", ["toggleShowFeedback"], [], ["loc", [null, [35, 28], [35, 59]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 10
                },
                "end": {
                  "line": 55,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "btn-wrapper");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "btn btn-cancel");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  \n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [3]);
              var element2 = dom.childAt(element1, [1]);
              var element3 = dom.childAt(element1, [3]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createElementMorph(element2);
              morphs[2] = dom.createMorphAt(element2, 1, 1);
              morphs[3] = dom.createAttrMorph(element3, 'class');
              morphs[4] = dom.createElementMorph(element3);
              morphs[5] = dom.createMorphAt(element3, 1, 1);
              return morphs;
            },
            statements: [["inline", "textarea", [], ["class", "feedback-message", "value", ["subexpr", "@mut", [["get", "feedBackMessage", ["loc", [null, [43, 18], [43, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["help_widget.placeholder"], [], ["loc", [null, [44, 24], [44, 53]]], 0, 0], "autofocus", "autofocus", "action", "myAction", "onEvent", "keypress"], ["loc", [null, [42, 12], [46, 50]]], 0, 0], ["element", "action", ["toggleDropDown"], [], ["loc", [null, [48, 45], [48, 72]]], 0, 0], ["inline", "t", ["common.cancel"], [], ["loc", [null, [49, 16], [49, 37]]], 0, 0], ["attribute", "class", ["concat", ["btn btn-primary ", ["subexpr", "if", [["get", "isEmptyMessage", ["loc", [null, [51, 50], [51, 64]]], 0, 0, 0, 0], "action-in-progress"], [], ["loc", [null, [51, 45], [51, 87]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["submitFeedBack"], [], ["loc", [null, [51, 89], [51, 116]]], 0, 0], ["inline", "t", ["help_widget.send"], [], ["loc", [null, [52, 18], [52, 42]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 10
                },
                "end": {
                  "line": 59,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "thank-you");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["help_widget.thanks_message"], [], ["loc", [null, [57, 12], [57, 46]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 6
              },
              "end": {
                "line": 61,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "questionable-area");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "showInput", ["loc", [null, [41, 16], [41, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [41, 10], [59, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 64,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "feedback-widget rl-dropdown");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "feedback-container");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "title");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "title-emoji");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "title-text");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "salution");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment(" {{#if showFeedback}}\n            <div class=\"give-feedback\">{{t \"help_widget.give_feedback\"}}</div>\n          {{/if}} ");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1, 1]);
          var element10 = dom.childAt(element9, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element10, [1, 1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element10, [3, 1]), 0, 0);
          morphs[2] = dom.createMorphAt(element9, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["help_widget.emoji"], [], ["loc", [null, [10, 16], [10, 41]]], 0, 0], ["inline", "t", ["help_widget.title"], [], ["loc", [null, [13, 32], [13, 57]]], 0, 0], ["block", "unless", [["get", "showFeedback", ["loc", [null, [19, 16], [19, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [19, 6], [61, 17]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 70,
                  "column": 4
                },
                "end": {
                  "line": 75,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("iframe");
              dom.setAttribute(el1, "frameborder", "0");
              dom.setAttribute(el1, "allowfullscreen", "");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'width');
              morphs[1] = dom.createAttrMorph(element0, 'height');
              morphs[2] = dom.createAttrMorph(element0, 'src');
              return morphs;
            },
            statements: [["attribute", "width", ["concat", [["get", "videoParams.width", ["loc", [null, [71, 23], [71, 40]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "height", ["concat", [["get", "videoParams.height", ["loc", [null, [71, 54], [71, 72]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "videoParams.videoUrl", ["loc", [null, [72, 13], [72, 33]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 2
              },
              "end": {
                "line": 76,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ui-modal", [], ["class", "onboarding-wrapper", "size", "md", "class", "youtube-video-modal", "closeOnEscape", true, "closeOnBackdropClick", true, "closeCallBack", ["subexpr", "action", ["toggleShowVideo"], [], ["loc", [null, [70, 140], [70, 166]]], 0, 0]], 0, null, ["loc", [null, [70, 4], [75, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 68,
              "column": 0
            },
            "end": {
              "line": 77,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ember-wormhole", [], ["to", "modal-wormhole"], 0, null, ["loc", [null, [69, 2], [76, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 78,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/feedback-widget/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "feedback-menu");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "icon-help");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element11 = dom.childAt(fragment, [0]);
        var element12 = dom.childAt(element11, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element12, 'class');
        morphs[1] = dom.createElementMorph(element12);
        morphs[2] = dom.createMorphAt(element11, 3, 3);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["icon-wrapper rl-dropdown-toggle ", ["subexpr", "if", [["get", "dropdownExpanded", ["loc", [null, [2, 53], [2, 69]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [2, 48], [2, 80]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleDropDown"], [], ["loc", [null, [2, 82], [2, 109]]], 0, 0], ["block", "if", [["get", "dropdownExpanded", ["loc", [null, [5, 8], [5, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 2], [64, 9]]]], ["block", "if", [["get", "showVideo", ["loc", [null, [68, 6], [68, 15]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [68, 0], [77, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});