define('freshfone-ember/components/manage-intents/review-customer-phrases/component', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var Component = _ember['default'].Component;
    exports['default'] = Component.extend({
        currentPage: 1,
        init: function init() {
            this._super.apply(this, arguments);
            if (this.get('ivrId')) {
                this.get('fetchIvr').perform();
            } else {
                this.set('hasEmptyList', true);
            }
        },

        fetchIvr: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var ivr;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.store.peekRecord('ivr', this.get('ivrId')) || this.store.findRecord('ivr', this.get('ivrId'));

                    case 2:
                        ivr = context$1$0.sent;

                        this.set('ivr', ivr);
                        this.getReviewCustomerPhrases();

                    case 5:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getReviewCustomerPhrases: function getReviewCustomerPhrases() {
            var _this = this;

            var params = this.buildParams(this.get('currentPage'));
            return new _ember['default'].RSVP.Promise(function (resolve) {
                _this.get('ivr').reviewCustomerPhrases(params).then(function (response) {
                    if (response.data.length) {
                        _this.get('store').pushPayload('ivr-input', response);
                        _this.set('ivrInputs', response.data);
                        _this.setHasMoreToscroll(response.meta['total-pages'], response.meta['current']);
                    } else {
                        _this.set('hasEmptyList', true);
                    }
                    return resolve(response);
                });
            });
        },

        buildParams: function buildParams(pageToFetch) {
            var params = {
                paginate: true,
                page: pageToFetch
            };
            return params;
        },

        getNextPagePhrases: function getNextPagePhrases() {
            var _this2 = this;

            var params = this.buildParams(this.get('currentPage') + 1);
            return new _ember['default'].RSVP.Promise(function (resolve) {
                _this2.get('ivr').reviewCustomerPhrases(params).then(function (response) {
                    _this2.set('isFetching', false);
                    _this2.set('currentPage', response.meta.current);
                    if (response.data.length) {
                        _this2.get('store').pushPayload('ivr-input', response);
                        _this2.get('ivrInputs').pushObjects(response.data);
                    } else {
                        _this2.set('hasEmptyList', true);
                    }
                    return resolve(response);
                });
            });
        },

        loadMorePhrases: function loadMorePhrases() {
            this.set('isFetching', true);
            if (this.noMoreResultsToscroll()) {
                return;
            }
            this.getNextPagePhrases();
        },

        noMoreResultsToscroll: function noMoreResultsToscroll() {
            if (this.get('currentPage') + 1 > this.get('totalPages')) {
                this.setProperties({
                    isFetching: false,
                    hasMore: false
                });
                return true;
            }
        },

        setHasMoreToscroll: function setHasMoreToscroll(total, current) {
            this.setProperties({
                totalPages: total,
                currentPage: current,
                hasMore: true
            });
        },

        actions: {
            fetchMore: function fetchMore(callback) {
                this.loadMorePhrases(callback);
            }
        }
    });
});