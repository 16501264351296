define('freshfone-ember/components/ongoing-widget/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/park-state', 'freshfone-ember/constants/park-type'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsParkState, _freshfoneEmberConstantsParkType) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var equal = _Ember$computed.equal;
  var alias = _Ember$computed.alias;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    classNameBindings: ['isOldMinimisedWidgetOpen:minimized'],
    telephony: service(),
    parallelCall: service(),
    i18n: service(),
    notifications: service('toastr'),
    parkedCallsService: service('parked-calls'),
    transition: service('state-transition'),
    currentCall: computed.alias('telephony.currentCall'),
    parallelCalls: computed.alias('telephony.parallelCalls'),
    parkedCalls: computed.alias('parkedCallsService.parkedCalls'),
    recordTypeService: service('record-type'),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    agentBusy: computed.equal('currentAgent.browserStatus.statusType', 'busy'),
    activeFreshfoneNumber: '',
    isIncomingCall: computed.notEmpty('currentCall.to'),
    isMonitorOrBarge: computed('currentCall.type', 'agentBusy', function () {
      return ['monitor', 'barge'].includes(this.get('currentCall.type'));
    }),
    isActiveCall: computed('hasActiveCall', 'currentCall', function () {
      if (this.get('isMonitorOrBarge')) {
        return false;
      }

      return this.get('hasActiveCall');
    }),
    hasNewMinimisedWidgetSupport: alias('telephony.hasCallParkingFeatureFlagsEnabled'),
    isMonitoredCall: computed('currentCall', 'agentBusy', function () {
      var isBarge = this.get('currentCall.type') === 'barge';

      this.set('mute', !isBarge);

      return this.get('isMonitorOrBarge') && this.get('agentBusy');
    }),
    isOldMinimisedWidgetOpen: computed('isMinimized', 'hasNewMinimisedWidgetSupport', function () {
      return this.get('isMinimized') && !this.get('hasNewMinimisedWidgetSupport');
    }),
    isMinimized: computed('currentCall.minimized', function () {
      return this.get('currentCall.minimized');
    }),
    isMaximized: computed('currentCall.minimized', function () {
      return !this.get('isMinimized');
    }),
    callHasMerged: computed.alias('parallelCall.callHasMerged'),
    currentCallIsParked: equal('currentCall.parkState', _freshfoneEmberConstantsParkState['default'].Parked),
    parking: computed.alias('parkedCallsService.parking'),
    currentCallCompleted: computed.alias('telephony.currentCallCompleted'),
    hideMainContactInfoCard: computed.alias('telephony.mainCallsRemoved'),
    isParallelCallResumed: alias('telephony.isParallelCallResumed'),
    customerContact: alias('telephony.customerContact'),
    secondaryContact: alias('parallelCall.parallelContact'),
    primaryCaller: computed('isParallelCallResumed', 'customerContact', 'secondaryContact', function () {
      return this.get('isParallelCallResumed') ? this.get('secondaryContact.displayName') : this.get('customerContact');
    }),
    conferenceCallerInWidget: alias('telephony.conferenceCallerInWidget'),
    calls: computed('parkedCalls.[]', 'parallelCalls.[]', 'currentCall', 'currentCall.{id,call_id}', 'isMonitorOrBarge', function () {
      var calls = [].concat(_toConsumableArray(this.get('parkedCalls')));

      if ((this.get('currentCall.id') || this.get('currentCall.call_id')) && !this.get('isMonitorOrBarge')) {
        this.set('currentCall.parallelCalls', this.get('parallelCalls'));
        calls.push(this.get('currentCall'));
      }

      return calls.sort(function (currentCall, nextCall) {
        return parseInt(nextCall.get('id')) - parseInt(currentCall.get('id'));
      });
    }),
    recordType: computed.alias('recordTypeService.recordType'),
    actions: {
      callCompleted: function callCompleted() {
        this.set('telephony.acceptedCallId', undefined);
        this.sendAction('callCompleted', this.get('currentCall.callerNumber'));
      },
      setCallRatingParams: function setCallRatingParams(callId) {
        this.get('setCallRatingParams')(callId);
      },
      switchCall: function switchCall(_ref) {
        var id = _ref.id;
        var callerName = _ref.callerName;

        this.set('parking', true);

        var callIsNotParkedOrEnded = this.get('currentCall.id') && !(this.get('currentCallIsParked') || this.get('currentCallCompleted')),
            currentCallContact = this.get('hideMainContactInfoCard') ? this.get('conferenceCallerInWidget.text') : this.get('primaryCaller');

        this.get('telephony').maximizeWidget();

        if (callIsNotParkedOrEnded) {
          this.get('transition').start(this.get('i18n').t('call_park.connecting_and_parking', {
            connectingTo: callerName,
            parkingWith: currentCallContact
          }));

          return this.get('parkedCallsService').parkCall(_freshfoneEmberConstantsParkType['default'].ParkedCall, id);
        }

        this.get('transition').start(this.get('i18n').t('call_park.connecting_to', { name: callerName }));

        return this.get('parkedCallsService').switchCallWithoutPark(id);
      }
    }

  });
});