define('freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/enhanced-agents-list/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/live-agents-dashboard'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsLiveAgentsDashboard) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var notEmpty = _Ember$computed.notEmpty;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),
    hasAgents: notEmpty('agents'),
    currentUser: alias('session.user'),
    currentAgent: alias('currentUser.agent'),
    currentAccount: alias('session.account'),
    isOmnichannelEnabled: alias('currentAccount.isOmnichannelEnabled'),
    showAgents: true,
    hasPresenceToggleFeature: computed(function () {
      return this.hasFeatures(['agent_preference_toggle']);
    }),
    notAllAgentsStatus: computed('selectedStatusTitle', function () {
      return this.get('selectedStatusTitle') !== _freshfoneEmberConstantsLiveAgentsDashboard['default'].allAgents && !this.get('searchResults');
    }),
    hasOmnichannelAgentStatusFeature: computed(function () {
      return this.checkFeature('omnichannel_agent_status') && this.get('isOmnichannelEnabled');
    }),
    agentTitle: computed('status', 'agentsCount', function () {
      var status = this.get('status');

      if (this.isValidStatus()) {
        return !status.get('default') && !this.get('hasOmnichannelAgentStatusFeature') ? {
          status: status.get('emoji') + ' ' + status.get('name'),
          count: '(' + this.get('agentsCount') + ')'
        } : {
          status: this.get('i18n').t('live_dashboard.status.' + status.get('statusType')),
          count: '(' + this.get('agentsCount') + ')'
        };
      }

      return {
        status: this.get('i18n').t('live_dashboard.status.no_agents'),
        count: '(' + this.get('agentsCount') + ')'
      };
    }),
    statusDescription: computed(function () {
      return this.get('i18n').t('live_dashboard.description.enhanced');
    }),
    isValidStatus: function isValidStatus() {
      return typeof this.get('status') === 'object';
    },

    actions: {
      toggleAgentStatus: function toggleAgentStatus(agent, isAvailable) {
        var defualtStatuses = this.store.peekAll('agent-status').filterBy('default', true);

        var _defualtStatuses$filterBy = defualtStatuses.filterBy('statusSubType', 'online');

        var _defualtStatuses$filterBy2 = _slicedToArray(_defualtStatuses$filterBy, 1);

        var statusOnline = _defualtStatuses$filterBy2[0];

        var _defualtStatuses$filterBy3 = defualtStatuses.filterBy('statusSubType', 'offline');

        var _defualtStatuses$filterBy32 = _slicedToArray(_defualtStatuses$filterBy3, 1);

        var statusOffline = _defualtStatuses$filterBy32[0];

        agent.set('browserStatusId', isAvailable ? statusOffline.get('id') : statusOnline.get('id'));
        agent.save();
      }
    }

  });
});