define('freshfone-ember/components/billing-base/credit-actions/options-dropdown/component', ['exports', 'freshfone-ember/mixins/currency-mixin', 'ember'], function (exports, _freshfoneEmberMixinsCurrencyMixin, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    exports['default'] = Component.extend(_freshfoneEmberMixinsCurrencyMixin['default'], {
        saveLabel: null,
        noValueSelected: computed.empty('selectedValue'),
        disableAction: computed.or('noValueSelected', 'isActionInprogress'),
        init: function init() {
            this._super.apply(this, arguments);
            this.set('saveLabel', this.get('actionText'));
        },
        creditValue: computed('selectedValue', 'subscription', function () {
            return Math.round(this.get('selectedValue') / this.get('currencyConversion')[this.get('subscription.currency')] * 100) / 100;
        }),
        actions: {
            valueChanged: function valueChanged() {
                if (this.get('closeOnSelect')) {
                    this.sendAction('selectCallBack', this.get('selectedValue'));
                }
            },
            callbackAction: function callbackAction(selectedValue) {
                if (!selectedValue) {
                    return;
                }
                this.set('saveLabel', this.get('savingText'));
                this.sendAction('callbackAction', selectedValue);
            }
        }
    });
});