define('freshfone-ember/components/common/tabbed-form/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNameBindings: ['capsule'],

    actions: {
      setActiveTab: function setActiveTab(tabName) {
        var onChange = this.get('onChange');

        if (typeof onChange === 'function') {
          onChange(tabName);
        } else {
          this.set('activeTab', tabName);
        }
      }
    }
  });
});