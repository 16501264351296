define('freshfone-ember/components/common/new-entity/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['data-analytics-action-name'],
    classNames: ['new-entity'],
    classNameBindings: ['formState:open', 'entityDisabled:entity-disabled'],
    entityDisabled: computed.alias('disabled'),
    formState: computed.alias('formMode'),
    addMode: true,
    didInsertElement: function didInsertElement() {
      if (this.get('setComponentReference')) {
        this.get('setComponentReference')(this);
      }
    },
    expandAccordion: function expandAccordion(addMode) {
      this.set('addMode', addMode);
      this.set('formState', true);
    },
    compressAccordion: function compressAccordion() {
      this.set('formState', false);
      this.set('addMode', true);
    },
    click: function click(event) {
      if ($(event.target).is('a[href]')) {
        event.stopPropagation();

        return;
      }

      if (this.get('opensAForm')) {
        if (this.get('formState')) {
          this.compressAccordion();
        } else {
          this.expandAccordion(true);
        }
      }
      if (this.get('clickCallback')) {
        this.sendAction('clickCallback');
      }
    }
  });
});