define('freshfone-ember/components/get-started/email-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/feature-check-mixin', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/helpers/email'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsFeatureCheckMixin, _emberComponentInboundActionsInboundActions, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberHelpersEmail) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsModelFormMixin['default'], _emberComponentInboundActionsInboundActions['default'], {
    user: computed({
      set: function set(key, value) {
        var user = this.store.peekAll('user').get('firstObject');

        user.set(key, value);

        return user;
      },
      get: function get() {
        return this.store.peekAll('user').get('firstObject');
      }
    }),

    saveChanges: function saveChanges() {
      var _this = this;

      var user = this.get('user');

      user.set('source', 'onboarding');
      this.save('user', user).then(function (item) {
        if (!item.errors) {
          _this.sendAction('closeEditModal');
        }
      });
    },

    actions: {
      save: function save() {
        var _this2 = this;

        this.set('isSavingError', false);
        this.validate().then(function () {
          _this2.set('startValidate', false);
          _this2.saveChanges();
        })['catch'](function () {
          return _this2.set('startValidate', true);
        });
      },
      cancel: function cancel() {
        this.rollbackDirtyChanges('user');
        this.sendAction('closeEditModal');
      }
    },
    validations: {
      'user.email': {
        format: {
          presence: true,
          'with': (0, _freshfoneEmberHelpersEmail.emailRegex)(),
          /* eslint-disable ember-suave/no-direct-property-access */
          message: _ember['default'].i18n.t('activemodel.errors.is_invalid')
        }
      }
    }

  });
});