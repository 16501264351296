define('freshfone-ember/components/billing-base/subscription-base/plan-component/component', ['exports', 'ember', 'freshfone-ember/helpers/currency-types', 'freshfone-ember/constants/subscription-constants'], function (exports, _ember, _freshfoneEmberHelpersCurrencyTypes, _freshfoneEmberConstantsSubscriptionConstants) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEqual = _ember['default'].isEqual;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    i18n: service(),
    classNameBindings: ['isRebrandedAccount:rebranded-account'],
    currentAccount: computed.alias('session.account'),
    isAccountBlocked: computed.reads('currentAccount.blocked'),
    isOmnichannelAccount: computed.alias('currentAccount.isOmnichannelEnabled'),
    showBillingExplanation: computed.and('activePlan', 'subscription.isActive'),
    hasMoreFeatures: computed.gt('moreFeaturesCount', 0),
    showAllFeatures: false,
    currentCurrency: computed.alias('subscription.currency'),
    moreFeaturesCount: computed('planFeatures.[]', function () {
      return this.get('planFeatures.length') - this.get('visibleCount');
    }),
    visibleCount: computed(function () {
      return this.get('isRebrandedAccount') ? _freshfoneEmberConstantsSubscriptionConstants.topFeaturesVisibleCount.forRebrandingPlans : _freshfoneEmberConstantsSubscriptionConstants.topFeaturesVisibleCount.forOldPlans;
    }),
    activePlan: computed('plan.id', 'subscription.planId', function () {
      return parseInt(this.get('plan.id'), 10) === this.get('subscription.planId');
    }),
    highlightActivePlan: computed('activePlan', 'subscription.isTrailExpired', function () {
      if (this.get('activePlan')) {
        return !this.get('subscription.isTrailExpired');
      }
    }),
    planFeatures: computed('plan.name', function () {
      var namespace = this.get('hasCreditSplitFeature') ? 'sku_changes.' : '';

      return this.get('i18n').t('subscription.' + namespace + this.get('plan.name') + '.top_features').string.split(',');
    }),

    currencyNotSupported: computed('currentCurrency', function () {
      return this.get('currentAccount.hasPricingRebrandingFeature') && _freshfoneEmberHelpersCurrencyTypes['default'].rebrandedSupportedCurrencies[this.get('currentCurrency')].disabled;
    }),

    buttonText: computed('classicCurrentPlan', 'activePlan', 'subscription.status', function () {
      if (this.get('subscription.isTrailExpired') || this.get('classicCurrentPlan')) {
        this.set('eventName', 'Choose Plan');

        return _ember['default'].i18n.t('subscription.plan.choose_plan');
      }
      if (this.get('activePlan')) {
        if (this.get('subscription.isTrail')) {
          this.set('eventName', 'Subscribe');

          return _ember['default'].i18n.t('subscription.plan.subscribe');
        }
        this.set('eventName', 'Edit Plan');

        return _ember['default'].i18n.t('subscription.edit_plan');
      }
      this.set('eventName', 'Switch Plan');

      return _ember['default'].i18n.t('subscription.plan.switchToPlan');
    }),

    nextRenewalDate: computed.alias('subscription.nextRenewalDate'),

    disabled: computed.or('disableSwitchPlan', 'subscription.isPaymentFailed', 'scheduledCustomer'),
    toolTipText: computed('disabled', 'fullPlanName', function () {
      if (this.get('disableSwitchPlan')) {
        return _ember['default'].i18n.t('billing.downgrade_blocked');
      } else if (this.get('subscription.isPaymentFailed')) {
        return _ember['default'].i18n.t('billing.payment_failed');
      }
    }),

    disableSwitchPlan: computed('isAccountBlocked', 'subscription.planName', function () {
      if (this.get('isAccountBlocked')) {
        return true;
      }
      if (isEqual(this.get('subscription.planName'), this.get('plan.name'))) {
        return false;
      }

      return false;
    }),

    swicthPlanButtonText: computed('switchingPlan', 'buttonText', function () {
      return this.get('switchingPlan') ? _ember['default'].i18n.t('subscription.plan.switchPlanLoader') : this.get('buttonText');
    }),
    currencySymbol: computed(function () {
      return _freshfoneEmberHelpersCurrencyTypes['default'].currencySymbols[this.get('subscription.currency')];
    }),
    analyticsService: service('analytics-tracker'),
    updateNewPlan: function updateNewPlan() {
      var _this = this;

      this.set('subscription.planId', parseInt(this.get('plan.id'), 10));
      this.set('switchingPlan', true);
      this.set('switchingPlanActivated', true);
      this.get('subscription.content').save().then(function () {
        _this.set('switchingPlan', false);
        _this.set('switchingPlanActivated', false);
      });
    },
    actions: {
      toggleEditPlan: function toggleEditPlan() {
        this.get('analyticsService').addEvent(this.get('eventName') + ' ' + this.get('plan.name'));
        if (this.get('isAccountBlocked') || this.get('switchingPlan') || this.get('subscription.isPaymentFailed')) {
          return;
        }
        if (this.get('subscription.isTrail')) {
          if (this.get('currencyNotSupported')) {
            this.set('editPlan', true);

            return;
          }
          if (this.get('subscription.planId') != this.get('plan.id')) {
            return this.updateNewPlan();
          }
          this.get('subscription.content').makeActive();
        }
        this.set('editPlan', true);
        this.set('selectedPlan', this.get('plan'));
      },

      togglePlanFeatures: function togglePlanFeatures() {
        this.toggleProperty('showAllFeatures');
      }
    }
  });
});