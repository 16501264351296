define('freshfone-ember/components/banner-component/warning-banner/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var testing = _ember['default'].testing;
  exports['default'] = Component.extend({
    actions: {
      reloadPage: function reloadPage() {
        if (!testing) {
          window.location.reload();
        }
      }
    }
  });
});