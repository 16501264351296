define('freshfone-ember/routes/protected/companies/show', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/titleize', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsTitleize, _freshfoneEmberRoutesProtected) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsTitleize['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    breadCrumb: {},

    titleToken: function titleToken(model) {
      return [model.get('name'), this.titleize(model._internalModel.modelName)].join(" : ");
    },

    beforeModel: function beforeModel() {
      if (this.hasOmniRedirectFeatureEnabled()) {
        return this.transitionTo('/dashboard');
      }
      this.checkPrivilege('view_customers');
    },

    model: function model(params) {
      return this.store.find('company', params.company_id);
    },

    afterModel: function afterModel(model) {
      model.reload();
      var companyName = get(model, 'name');
      set(this, 'breadCrumb.title', companyName);
    }
  });
});