define('freshfone-ember/mixins/mkp-apps-manager', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = Mixin.create({
    loadingMkpApps: true,

    initMkpApps: function initMkpApps(filter) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        if (_this.get('mkpAdapter.mkpInitialized')) {
          _this.get('mkpAdapter').getApps(filter).then(function (mkpApps) {
            mkpApps.setEach('loaded', true);

            _this.setProperties({
              mkpApps: mkpApps,
              loadingMkpApps: false,
              mkpAppsLoaded: true
            });

            resolve(mkpApps);
          });
        } else {
          _this.setProperties({
            mkpApps: [],
            loadingMkpApps: false,
            mkpAppsLoaded: false
          });
          // eslint-disable-next-line no-console
          console.log('MarketplaceManager was not instantiated.');
          reject('MarketplaceManager was not instantiated.');
        }
      });
    },

    activateMkpApps: function activateMkpApps() {
      var activateMkpApp = function activateMkpApp(app) {
        app.trigger({ type: 'app.deactivated' }).then(function () {
          return app.trigger({ type: 'app.activated' });
        });
      },
          mkpApps = this.get('mkpApps');

      if (mkpApps && mkpApps.length) {
        mkpApps.forEach(function (app) {
          return activateMkpApp(app);
        });
      }
    },

    deactivateMkpApps: function deactivateMkpApps() {
      var deactivateMkpApp = function deactivateMkpApp(app) {
        app.trigger({ type: 'app.deactivated' });
      },
          mkpApps = this.get('mkpApps');

      if (mkpApps && mkpApps.length) {
        mkpApps.forEach(function (app) {
          return deactivateMkpApp(app);
        });
      }
    }
  });
});