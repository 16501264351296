define('freshfone-ember/components/billing-base/credit-actions/number-history/component', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Component.extend({
        showNumberHistory: false,
        numberList: computed(function () {
            return this.store.query('number-charge', {});
        }),
        maxNumbersToShow: 2,
        showMore: computed.gt('numberList.length', 2),
        filterNumber: computed('filterNumber', 'maxNumbersToShow', 'numberList.@each', function () {
            return this.get('numberList').slice(0, this.get('maxNumbersToShow'));
        }),
        hasNumbers: computed.notEmpty('numberList'),
        actions: {
            openNumberHistory: function openNumberHistory() {
                this.toggleProperty('showNumberHistory');
            },
            closeModalParent: function closeModalParent() {
                this.toggleProperty('showNumberHistory');
            }
        }
    });
});