define('freshfone-ember/components/contact-component/contact-item/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',
    currentUser: computed.alias('session.user'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('isSelected', this.get('isAllContactsSelected') || this.get('selectedContacts').includes(this.get('item.id')));
    },
    actions: {
      onChange: function onChange(contactId) {
        this.sendAction('onChange', contactId);
      },
      onModify: function onModify() {
        this.sendAction("onModify");
      },
      edit: function edit(contact) {
        this.sendAction("edit", contact);
      },
      toggleDelete: function toggleDelete(contact) {
        this.sendAction("toggleDelete", contact);
      }
    }
  });
});