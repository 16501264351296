define('freshfone-ember/routes/protected/admin/agent-status', ['exports', 'ember', 'freshfone-ember/routes/promotable', 'freshfone-ember/promotion-data/agent-status'], function (exports, _ember, _freshfoneEmberRoutesPromotable, _freshfoneEmberPromotionDataAgentStatus) {
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesPromotable['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('admin_components.AgentStatuses');
    }),
    hasCustomAgentStatusFeature: function hasCustomAgentStatusFeature() {
      return this.get('session.account').hasFeature('custom_agent_status');
    },
    beforeModel: function beforeModel() {
      if (this.get('session.account').hasFeature('omnichannel_agent_status') && this.get('session.account.isOmnichannelEnabled')) {
        this.transitionTo('protected.error-page');
      }
      this.checkIfRestrictedFetaure('unified_agent_status');

      return this.checkPrivilege('manage_agent_status');
    },

    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('admin_components.AgentStatuses') };
    }),

    model: function model() {
      var agentStatuses = undefined;

      if (this.hasCustomAgentStatusFeature()) {
        agentStatuses = this.store.findAll('agentStatus');
      }

      return this._super(_freshfoneEmberPromotionDataAgentStatus['default'].data, agentStatuses, 'custom_agent_status');
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */