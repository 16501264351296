define('freshfone-ember/models/credit', ['exports', 'ember-data', 'ember', 'ember-api-actions', 'freshfone-ember/constants/subscription-constants'], function (exports, _emberData, _ember, _emberApiActions, _freshfoneEmberConstantsSubscriptionConstants) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var Model = _emberData['default'].Model;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    availableCredit: attr('number'),
    autoRecharge: attr(),
    rechargeAllowed: attr('boolean'),
    rechargeQuantity: attr(),
    maxRechargeCount: attr('number'),
    autoRechargeThreshold: attr('number'),
    rechargeOptions: attr(),
    autoRechargeOptions: attr(),
    autoRechargeThresholdOptions: attr(),
    callingCardRemainingMinutes: attr(),
    isCreditLow: attr('boolean'),
    accountWallets: hasMany('account-wallet'),
    enableAutoRechargeThreshold: (0, _emberApiActions.collectionAction)({
      type: 'POST',
      path: 'auto_recharge_threshold'
    }),
    defaultRechargeOption: computed(function () {
      if (!this.get('rechargeOptions')) {
        return null;
      }

      var option = this.get('rechargeOptions').find(function (item) {
        return item === _freshfoneEmberConstantsSubscriptionConstants.creditConstants.defaultRechargeOption;
      });

      return option ? option : this.get('rechargeOptions.firstObject');
    }),
    disableAutoRechargeAmount: computed(function () {
      return this.get('autoRechargeOptions.firstObject');
    })
  });
});