define("freshfone-ember/components/company-component/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 15,
                      "column": 14
                    },
                    "end": {
                      "line": 17,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/company-component/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["company.new_company"], [], ["loc", [null, [16, 16], [16, 43]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 28,
                        "column": 16
                      },
                      "end": {
                        "line": 38,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/company-component/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "common/new-entity", [], ["class", "import-companies", "iconClass", "icon-list", "featureIconDoesntChangeOnHover", true, "tagName", "li", "entityName", ["subexpr", "t", ["company.import_company"], [], ["loc", [null, [34, 31], [34, 59]]], 0, 0], "entityDescription", ["subexpr", "t", ["company.import_company_description"], [], ["loc", [null, [35, 38], [35, 78]]], 0, 0], "clickCallback", ["subexpr", "action", [["subexpr", "send", [["get", "companyForm", ["loc", [null, [36, 48], [36, 59]]], 0, 0, 0, 0], "showImport"], [], ["loc", [null, [36, 42], [36, 73]]], 0, 0]], [], ["loc", [null, [36, 34], [36, 74]]], 0, 0]], ["loc", [null, [29, 18], [37, 20]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 18,
                      "column": 14
                    },
                    "end": {
                      "line": 39,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/company-component/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "common/new-entity", [], ["class", "create-company", "iconClass", "icon-nav-companies", "featureIconDoesntChangeOnHover", true, "tagName", "li", "entityName", ["subexpr", "t", ["company.add_company"], [], ["loc", [null, [24, 29], [24, 54]]], 0, 0], "entityDescription", ["subexpr", "t", ["company.add_company_description"], [], ["loc", [null, [25, 36], [25, 73]]], 0, 0], "clickCallback", ["subexpr", "action", [["subexpr", "send", [["get", "companyForm", ["loc", [null, [26, 46], [26, 57]]], 0, 0, 0, 0], "toggleNewCompany"], [], ["loc", [null, [26, 40], [26, 77]]], 0, 0]], [], ["loc", [null, [26, 32], [26, 78]]], 0, 0]], ["loc", [null, [19, 16], [27, 18]]], 0, 0], ["block", "if", [["subexpr", "has-feature", [], ["feature", "contact_company_import", "account", ["get", "session.account", ["loc", [null, [28, 76], [28, 91]]], 0, 0, 0, 0]], ["loc", [null, [28, 22], [28, 92]]], 0, 0]], [], 0, null, ["loc", [null, [28, 16], [38, 23]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 12
                  },
                  "end": {
                    "line": 40,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/company-component/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "rl-dropdown-toggle", [], ["tagName", "button", "class", "btn btn-primary dropdown-toggle create-company-options"], 0, null, ["loc", [null, [15, 14], [17, 37]]]], ["block", "rl-dropdown", [], ["tagName", "ul", "class", "dropdown-menu dropdown-menu-right", "closeOnChildClick", "li"], 1, null, ["loc", [null, [18, 14], [39, 30]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 10
                },
                "end": {
                  "line": 41,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/company-component/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "rl-dropdown-container", [], ["class", "dropdown entity-creation-types"], 0, null, ["loc", [null, [14, 12], [40, 38]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 8
              },
              "end": {
                "line": 42,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/company-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ember-wormhole", [], ["to", "miscellaneous-item"], 0, null, ["loc", [null, [13, 10], [41, 29]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 10
                },
                "end": {
                  "line": 51,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/company-component/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
              return morphs;
            },
            statements: [["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["company.name"], [], ["loc", [null, [46, 40], [46, 58]]], 0, 0], "class", "pd-l-55 list-header-element company-row-name"], ["loc", [null, [46, 12], [46, 112]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", [["subexpr", "concat", ["company.", ["subexpr", "if", [["get", "hasCompanyCallsLimitedFeature", ["loc", [null, [47, 66], [47, 95]]], 0, 0, 0, 0], "calls_last_30_days", "calls"], [], ["loc", [null, [47, 62], [47, 125]]], 0, 0]], [], ["loc", [null, [47, 43], [47, 126]]], 0, 0]], [], ["loc", [null, [47, 40], [47, 127]]], 0, 0], "class", "pd-l-55 list-header-element company-row-calls"], ["loc", [null, [47, 12], [47, 183]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["company.contacts"], [], ["loc", [null, [48, 40], [48, 62]]], 0, 0], "class", "pd-l-55 list-header-element company-row-contact-count"], ["loc", [null, [48, 12], [48, 125]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["company.last_call"], [], ["loc", [null, [49, 40], [49, 63]]], 0, 0], "class", "pd-l-55 list-header-element company-row-last-call"], ["loc", [null, [49, 12], [49, 122]]], 0, 0], ["inline", "ui-list-header-item", [], ["class", "pd-l-55 list-header-element item-center"], ["loc", [null, [50, 12], [50, 83]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 10
                },
                "end": {
                  "line": 57,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/company-component/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "loading-wrapper");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "spinner");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 59,
                      "column": 14
                    },
                    "end": {
                      "line": 65,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/company-component/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "empty-state-cover", [], ["imgSrc", "assets/images/empty-states/no-result.svg", "description", ["subexpr", "t", ["company.errors.no_record"], [], ["loc", [null, [62, 30], [62, 60]]], 0, 0], "containerClass", "flex-col"], ["loc", [null, [60, 16], [64, 18]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 74,
                            "column": 20
                          },
                          "end": {
                            "line": 76,
                            "column": 20
                          }
                        },
                        "moduleName": "freshfone-ember/components/company-component/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                       ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("button");
                        dom.setAttribute(el1, "class", "btn btn-primary import-companies");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element0 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createElementMorph(element0);
                        morphs[1] = dom.createMorphAt(element0, 0, 0);
                        return morphs;
                      },
                      statements: [["element", "action", [["subexpr", "send", [["get", "companyForm", ["loc", [null, [75, 92], [75, 103]]], 0, 0, 0, 0], "showImport"], [], ["loc", [null, [75, 86], [75, 117]]], 0, 0]], [], ["loc", [null, [75, 77], [75, 119]]], 0, 0], ["inline", "t", ["company.import_company"], [], ["loc", [null, [75, 120], [75, 150]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 72,
                          "column": 18
                        },
                        "end": {
                          "line": 77,
                          "column": 18
                        }
                      },
                      "moduleName": "freshfone-ember/components/company-component/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1, "class", "btn btn-primary create-company");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element1 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createElementMorph(element1);
                      morphs[1] = dom.createMorphAt(element1, 0, 0);
                      morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["element", "action", [["subexpr", "send", [["get", "companyForm", ["loc", [null, [73, 82], [73, 93]]], 0, 0, 0, 0], "toggleNewCompany"], [], ["loc", [null, [73, 76], [73, 113]]], 0, 0]], [], ["loc", [null, [73, 67], [73, 115]]], 0, 0], ["inline", "t", ["company.add_company"], [], ["loc", [null, [73, 116], [73, 143]]], 0, 0], ["block", "if", [["subexpr", "has-feature", [], ["feature", "contact_company_import", "account", ["get", "session.account", ["loc", [null, [74, 80], [74, 95]]], 0, 0, 0, 0]], ["loc", [null, [74, 26], [74, 96]]], 0, 0]], [], 0, null, ["loc", [null, [74, 20], [76, 27]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 66,
                        "column": 16
                      },
                      "end": {
                        "line": 78,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/company-component/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "has-privilege", [], ["privilege", "manage_customers", "user", ["get", "currentUser", ["loc", [null, [72, 73], [72, 84]]], 0, 0, 0, 0]], ["loc", [null, [72, 24], [72, 85]]], 0, 0]], [], 0, null, ["loc", [null, [72, 18], [77, 25]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 65,
                      "column": 14
                    },
                    "end": {
                      "line": 79,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/company-component/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "empty-state-cover", [], ["imgSrc", "assets/images/empty-states/no-company.svg", "title", ["subexpr", "t", ["company.add_new_company"], [], ["loc", [null, [68, 24], [68, 53]]], 0, 0], "description", ["subexpr", "t", ["company.description"], [], ["loc", [null, [69, 30], [69, 55]]], 0, 0], "containerClass", "flex-row"], 0, null, ["loc", [null, [66, 16], [78, 38]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 58,
                    "column": 12
                  },
                  "end": {
                    "line": 80,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/company-component/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "isSearching", ["loc", [null, [59, 20], [59, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [59, 14], [79, 21]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 82,
                        "column": 16
                      },
                      "end": {
                        "line": 84,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/company-component/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "partial", ["companies/-list-items"], [], ["loc", [null, [83, 20], [83, 55]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 81,
                      "column": 14
                    },
                    "end": {
                      "line": 85,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/company-component/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "unless", [["get", "item.isNew", ["loc", [null, [82, 26], [82, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [82, 16], [84, 27]]]]],
                locals: ["item"],
                templates: [child0]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 88,
                          "column": 18
                        },
                        "end": {
                          "line": 96,
                          "column": 18
                        }
                      },
                      "moduleName": "freshfone-ember/components/company-component/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "confirm-delete-modal", [], ["modelItem", ["subexpr", "@mut", [["get", "deleteItem", ["loc", [null, [90, 32], [90, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", ["closeCallback"], [], ["loc", [null, [91, 28], [91, 52]]], 0, 0], "delete", ["subexpr", "action", ["deleteCallback"], [], ["loc", [null, [92, 29], [92, 54]]], 0, 0], "deleteTitle", ["subexpr", "t", ["company.delete-title"], [], ["loc", [null, [93, 34], [93, 60]]], 0, 0], "deleteMessage", ["subexpr", "t", ["company.delete-message"], [], ["loc", [null, [94, 36], [94, 64]]], 0, 0], "checkMessage", ["subexpr", "t", ["company.check-message"], [], ["loc", [null, [95, 35], [95, 62]]], 0, 0]], ["loc", [null, [89, 20], [95, 64]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 87,
                        "column": 16
                      },
                      "end": {
                        "line": 97,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/company-component/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "ui-modal", [], ["class", "delete-company-wrapper", "size", "sm", "closeOnEscape", true, "closeOnBackdropClick", false, "closeCallBack", ["subexpr", "action", ["closeCallback"], [], ["loc", [null, [88, 131], [88, 155]]], 0, 0]], 0, null, ["loc", [null, [88, 18], [96, 31]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 86,
                      "column": 14
                    },
                    "end": {
                      "line": 98,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/company-component/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "ember-wormhole", [], ["to", "modal-wormhole"], 0, null, ["loc", [null, [87, 16], [97, 35]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 80,
                    "column": 12
                  },
                  "end": {
                    "line": 99,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/company-component/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "ui-list-item", [], ["items", ["subexpr", "@mut", [["get", "sortedCompaniesList", ["loc", [null, [81, 36], [81, 55]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [81, 14], [85, 31]]]], ["block", "if", [["get", "isDelete", ["loc", [null, [86, 20], [86, 28]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [86, 14], [98, 21]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 10
                },
                "end": {
                  "line": 100,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/company-component/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "hasEmptyList", ["loc", [null, [58, 18], [58, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [58, 12], [99, 19]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 8
              },
              "end": {
                "line": 102,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/company-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "ui-list-header", [], ["class", "list-header"], 0, null, ["loc", [null, [45, 10], [51, 29]]]], ["block", "if", [["get", "listLoading", ["loc", [null, [53, 16], [53, 27]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [53, 10], [100, 17]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 103,
              "column": 6
            }
          },
          "moduleName": "freshfone-ember/components/company-component/template.hbs"
        },
        isEmpty: false,
        arity: 4,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["subexpr", "has-privilege", [], ["privilege", "manage_customers", "user", ["get", "currentUser", ["loc", [null, [12, 68], [12, 79]]], 0, 0, 0, 0]], ["loc", [null, [12, 19], [12, 80]]], 0, 0], ["get", "entitiesExistInTheFirstPlace", ["loc", [null, [12, 81], [12, 109]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 14], [12, 110]]], 0, 0]], [], 0, null, ["loc", [null, [12, 8], [42, 15]]]], ["block", "ui-list", [], ["class", "list-container company-list has-stretchable-rows"], 1, null, ["loc", [null, [43, 8], [102, 20]]]]],
        locals: ["listLoading", "hasEmptyList", "isSearching", "entitiesExistInTheFirstPlace"],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 4
              },
              "end": {
                "line": 123,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/company-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "company-form", [], ["onModify", ["subexpr", "action", ["onModify"], [], ["loc", [null, [118, 30], [118, 49]]], 0, 0], "company", ["subexpr", "@mut", [["get", "company", ["loc", [null, [118, 58], [118, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "action", ["edit"], [], ["loc", [null, [118, 71], [118, 86]]], 0, 0], "slideToggle", ["subexpr", "@mut", [["get", "slideToggle", ["loc", [null, [119, 20], [119, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "formMode", ["subexpr", "@mut", [["get", "formMode", ["loc", [null, [120, 17], [120, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "actionReceiver", ["subexpr", "@mut", [["get", "companyForm", ["loc", [null, [121, 23], [121, 34]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [118, 6], [122, 8]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 2
            },
            "end": {
              "line": 124,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/company-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ui-modal", [], ["class", "sidebar-content-modal", "size", "md", "animateClose", true, "isModalOpen", ["subexpr", "@mut", [["get", "slideToggle", ["loc", [null, [111, 18], [111, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "showCloseOnTopLeft", true, "closeOnEscape", true, "closeOnBackdropClick", true, "sliderModal", true, "onClose", ["subexpr", "action", [["subexpr", "send", [["get", "companyForm", ["loc", [null, [116, 28], [116, 39]]], 0, 0, 0, 0], "toggleNewCompany"], [], ["loc", [null, [116, 22], [116, 59]]], 0, 0]], [], ["loc", [null, [116, 14], [116, 60]]], 0, 0]], 0, null, ["loc", [null, [107, 4], [123, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 168,
            "column": 4
          }
        },
        "moduleName": "freshfone-ember/components/company-component/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "contact-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-12 contact-main-content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "contact-main-content-list");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["block", "paginate-search", [], ["title", ["subexpr", "t", ["navigation.companies"], [], ["loc", [null, [5, 14], [5, 40]]], 0, 0], "placeholder", ["subexpr", "t", ["company.search_placeholder"], [], ["loc", [null, [6, 20], [6, 52]]], 0, 0], "modelName", "company", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [8, 14], [8, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "supportPortalLink", ["subexpr", "support-portal-link", ["savingAndImportingCompanies"], [], ["loc", [null, [9, 26], [9, 77]]], 0, 0], "modifyModel", ["subexpr", "@mut", [["get", "modifyModel", ["loc", [null, [10, 20], [10, 31]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [4, 6], [103, 26]]]], ["block", "ember-wormhole", [], ["to", "modal-wormhole"], 1, null, ["loc", [null, [106, 2], [124, 21]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});