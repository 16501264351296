define('freshfone-ember/components/call-queue/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var Promise = _ember['default'].RSVP.Promise;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    classNames: ['call-queue'],
    callFlow: service('call-flow'),
    hasChildQueue: computed.notEmpty('childQueue'),
    queueCount: computed.alias('callFlow.callQueueCount'),
    i18n: service(),
    init: function init() {
      this._super.apply(this, arguments);
      /**
       * to ensure callQueues is attached to the new instance of the component
       * created and not shared across different instances of this component
       */

      this.set('callQueues', []);
    },
    parentQueue: computed({
      get: function get() {
        return this;
      },
      set: function set(key, value) {
        var _this = this;

        var self = this;

        if (value.content || value) {
          (value.then ? value : Promise.resolve(value)).then(function (v) {
            _this.get('callQueues').clear();
            if (v.get('isNew') && isEmpty(v.get('name'))) {
              v.set('name', _this.get('i18n').t('entityNames.new_main_queue') + ' ' + (_this.get('queueCount') + 1));
            }
            self.get('callQueues').pushObject(v);
          });
        }

        return value;
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },

    actions: {
      pushChildQueue: function pushChildQueue(queue) {
        queue.set('isChild', true);
        this.get('callQueues').pushObject(queue);
      },
      popChildQueue: function popChildQueue(queue) {
        this.get('callQueues').removeObject(queue);
        this.get('callQueues.lastObject').set('showChild', false);
      },
      toggleContent: function toggleContent(callFlow) {
        var _this2 = this;

        this.send('popChildQueue', callFlow);
        ['noAnswerActionClicked', 'busyWaitOverActionClicked', 'offlineActionClicked', 'queueFullActionClicked', 'timeThresholdActionClicked', 'callbackFailActionClicked', 'autoAnswerActionClicked'].forEach(function (clickedAction) {
          return _this2.set('parentQueue.' + clickedAction, false);
        });
      }
    }
  });
});