define("freshfone-ember/components/notification-widget/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 6
              },
              "end": {
                "line": 13,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "call-parking-warning-container");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "inner-container");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "notification-widget/call-parking-banner", [], ["closeCallParkingBanner", ["subexpr", "action", ["closeBanner"], [], ["loc", [null, [8, 39], [8, 61]]], 0, 0], "message", ["subexpr", "@mut", [["get", "bannerMessage", ["loc", [null, [9, 24], [9, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 14], [10, 16]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 6
              },
              "end": {
                "line": 17,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "notification-widget/microphone-warning-banner", ["loc", [null, [16, 8], [16, 57]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 29,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "notifications");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 2, 2);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "showBanner", ["loc", [null, [4, 12], [4, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 6], [13, 13]]]], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "firstCall.isSIPCall", ["loc", [null, [15, 22], [15, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 17], [15, 42]]], 0, 0], ["get", "showMicPermissionBanner", ["loc", [null, [15, 43], [15, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 12], [15, 67]]], 0, 0]], [], 1, null, ["loc", [null, [15, 6], [17, 13]]]], ["inline", "notification-widget/incoming", [], ["isListNotification", ["subexpr", "@mut", [["get", "isListNotification", ["loc", [null, [22, 29], [22, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "eligibleForMkp", true, "isAgentInPowerDialerMode", ["subexpr", "@mut", [["get", "isAgentInPowerDialerMode", ["loc", [null, [24, 35], [24, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "connection", ["subexpr", "@mut", [["get", "firstCall", ["loc", [null, [25, 21], [25, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "ignore", ["subexpr", "unless", [["get", "isIncomingIgnoreOptionDisabled", ["loc", [null, [26, 25], [26, 55]]], 0, 0, 0, 0], "ignore"], [], ["loc", [null, [26, 17], [26, 65]]], 0, 0], "accept", "accept"], ["loc", [null, [21, 8], [27, 27]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "ignore-all");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon-call-end");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1, 3, 3);
            return morphs;
          },
          statements: [["element", "action", ["ignoreAll", "ignore_all"], [], ["loc", [null, [34, 35], [34, 70]]], 0, 0], ["inline", "t", ["notification_widget.ignore_all"], [], ["loc", [null, [36, 12], [36, 50]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 6
              },
              "end": {
                "line": 47,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "header call-park-warning-wrapper");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "notification-widget/call-parking-banner", [], ["closeCallParkingBanner", ["subexpr", "action", ["closeBanner"], [], ["loc", [null, [43, 35], [43, 57]]], 0, 0], "message", ["subexpr", "@mut", [["get", "bannerMessage", ["loc", [null, [44, 20], [44, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [42, 10], [45, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 6
              },
              "end": {
                "line": 53,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "mutiple-calls-banner-wrapper");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "notification-widget/microphone-warning-banner", ["loc", [null, [51, 10], [51, 59]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 8
              },
              "end": {
                "line": 62,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "notification-widget/incoming", [], ["connection", ["subexpr", "@mut", [["get", "connection", ["loc", [null, [57, 23], [57, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "isListNotification", ["subexpr", "@mut", [["get", "isListNotification", ["loc", [null, [58, 31], [58, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "isAgentInPowerDialerMode", ["subexpr", "@mut", [["get", "isAgentInPowerDialerMode", ["loc", [null, [59, 37], [59, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "ignore", ["subexpr", "unless", [["get", "isIncomingIgnoreOptionDisabled", ["loc", [null, [60, 27], [60, 57]]], 0, 0, 0, 0], "ignore"], [], ["loc", [null, [60, 19], [60, 67]]], 0, 0], "accept", "accept"], ["loc", [null, [56, 10], [61, 29]]], 0, 0]],
          locals: ["connection"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 4
            },
            "end": {
              "line": 64,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "header");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "notifications");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element3, 0, 0);
          morphs[1] = dom.createMorphAt(element3, 2, 2);
          morphs[2] = dom.createMorphAt(element2, 3, 3);
          morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[5] = dom.createMorphAt(dom.childAt(fragment, [7]), 1, 1);
          return morphs;
        },
        statements: [["content", "calls.length", ["loc", [null, [32, 14], [32, 30]]], 0, 0, 0, 0], ["inline", "t", ["notification_widget.incoming_calls"], [], ["loc", [null, [32, 31], [32, 73]]], 0, 0], ["block", "if", [["get", "showIgnoreAllCall", ["loc", [null, [33, 14], [33, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [33, 8], [38, 15]]]], ["block", "if", [["get", "showBanner", ["loc", [null, [40, 12], [40, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [40, 6], [47, 13]]]], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "firstCall.isSIPCall", ["loc", [null, [49, 22], [49, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 17], [49, 42]]], 0, 0], ["get", "showMicPermissionBanner", ["loc", [null, [49, 43], [49, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 12], [49, 67]]], 0, 0]], [], 2, null, ["loc", [null, [49, 6], [53, 13]]]], ["block", "each", [["get", "calls", ["loc", [null, [55, 16], [55, 21]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [55, 8], [62, 17]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 4
            },
            "end": {
              "line": 69,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "footer text-center");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "more-calls");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["showMore"], [], ["loc", [null, [67, 32], [67, 53]]], 0, 0], ["inline", "t", ["notification_widget.more_calls"], ["count", ["subexpr", "@mut", [["get", "callsMinusOne", ["loc", [null, [67, 97], [67, 110]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [67, 54], [67, 112]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/notification-widget/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createMorphAt(element4, 1, 1);
        morphs[2] = dom.createMorphAt(element4, 2, 2);
        morphs[3] = dom.createMorphAt(element4, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["incoming-notifications-container ", ["subexpr", "if", [["get", "isListNotification", ["loc", [null, [2, 52], [2, 70]]], 0, 0, 0, 0], "list"], [], ["loc", [null, [2, 47], [2, 79]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["get", "firstCall", ["loc", [null, [3, 15], [3, 24]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isListNotification", ["loc", [null, [3, 30], [3, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 25], [3, 49]]], 0, 0]], [], ["loc", [null, [3, 10], [3, 50]]], 0, 0]], [], 0, null, ["loc", [null, [3, 4], [29, 11]]]], ["block", "if", [["get", "isListNotification", ["loc", [null, [30, 10], [30, 28]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [30, 4], [64, 11]]]], ["block", "if", [["subexpr", "and", [["subexpr", "gt", [["get", "calls.length", ["loc", [null, [65, 19], [65, 31]]], 0, 0, 0, 0], 1], [], ["loc", [null, [65, 15], [65, 34]]], 0, 0], ["subexpr", "not", [["get", "isListNotification", ["loc", [null, [65, 40], [65, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 35], [65, 59]]], 0, 0]], [], ["loc", [null, [65, 10], [65, 60]]], 0, 0]], [], 2, null, ["loc", [null, [65, 4], [69, 11]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});