define('freshfone-ember/components/common/content-placeholder/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    exports['default'] = Component.extend({
        init: function init() {
            this._super.apply(this, arguments);
            // #todo - to be made configurable as the requirements evolve
            this.set('size', 'xs');
            this.set('centered', true);
        },

        xs: computed.equal('size', 'xs'),

        classNameBindings: ['centered:center-block', 'xs']
    });
});