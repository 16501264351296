define('freshfone-ember/components/call-flow/form/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/ivr-type'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsIvrType) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!['no_call_queues_restrictions', 'ivr', 'agent_extension', 'deflection_bot', 'speech_enabled_ivr'].some(function (feature) {
        return _this.checkFeature(feature);
      })) {
        this.$().parents('.admin-sidebar-content').addClass('hide');
        this.$().parents('.call-flow-wraper').children('.admin-main-content').removeClass('col-md-8').addClass('col-md-12');
      }
    },
    voiceIvr: computed(function () {
      return _freshfoneEmberConstantsIvrType['default'].voice;
    })
  });
});