define('freshfone-ember/components/auto-complete/component', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed;
  var MINIMUMINPUTLENGTH = 1;

  exports['default'] = _ember['default'].Component.extend({
    valueAttr: computed({
      get: function get() {
        if (this.get('value.id') != null) {
          return {
            id: this.get('value.id'),
            text: _ember['default'].isPresent(this.get('value.displayName')) ? this.get('value.displayName') : this.get('value.name')
          };
        }
      }
    }),

    minimumInputLength: computed({
      get: function get() {
        return MINIMUMINPUTLENGTH;
      },
      set: function set(key) {
        var value = arguments.length <= 1 || arguments[1] === undefined ? MINIMUMINPUTLENGTH : arguments[1];

        return value;
      }
    }),

    setValueAttr: (function () {
      var _this = this,
          $valueAttr = this.get('valueAttr'),
          value = this.get('value');

      return _ember['default'].makeArray($valueAttr).forEach(function (val) {
        _this.get('store').find(val.type, val.id).then(function (record) {
          if (_ember['default'].isArray(value)) {
            _this.get('value').pushObject(record);
          } else {
            _this.set('value', record);
          }
        });
      });
    }).observes('valueAttr'),

    actions: {
      query: function query(_query, deferred) {
        var parsed_type;
        var url = this.get('url');
        var needsGrouping = _ember['default'].isEqual(this.get('needsGrouping'), true);

        return _ember['default'].$.getJSON(url, { q: _query.term }).then(function (content) {
          if (needsGrouping) {
            //grouping by type
            var result = [];
            content.forEach(function (apt) {
              // need to get entityType module from freshsales
              // var parsed_type = entityType(apt.type).classify().pluralize();
              // var found = result.findBy('text', parsed_type);
              apt.text = apt.text || apt.name;

              // if (!found) {
              //   result.pushObject(Ember.Object.create({text: parsed_type, children: []}));
              // }
              result.findBy('text', parsed_type).get('children').pushObject(apt);
            });
            return deferred.resolve({ data: result });
          } else {
            return deferred.resolve({ data: content });
          }
        }, function () {
          return deferred.reject;
        });
      }
    }
  });
});