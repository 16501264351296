define('freshfone-ember/components/contact-components/contact-detail-view/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    model: computed({
      set: function set(key, value) {
        value.rollbackDirtyContactNumbers();
        return value;
      }
    }),
    hasContactNumbers: computed.gt('model.contactNumbers.length', 0)
  });
});