define('freshfone-ember/components/contact-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'ember-component-inbound-actions/inbound-actions'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsFeatureCheckMixin, _emberComponentInboundActionsInboundActions) {
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _emberComponentInboundActionsInboundActions['default'], {
    classNameBindings: ['slideToggle:opened:closed'],
    startValidate: true,
    editMode: false,
    store: service('store'),
    session: service('session'),
    telephony: service(),
    importService: service('import-service'),
    analyticsService: service('analytics-tracker'),
    showImportModal: computed.alias('importService.importParams.showImportModal'),
    fileModel: computed.alias('importService.importParams.fileModel'),
    hasImportFeature: computed(function () {
      return this.checkFeature('contact_company_import');
    }),

    hasUnsavedCompany: computed('contact.company', 'contact.company.id', function () {
      return isPresent(this.get('contact.company.name')) && isEmpty(this.get('contact.company.id'));
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      scheduleOnce('afterRender', function () {
        if (_ember['default'].isEmpty(_this.get('contact.id')) && !_this.get('contact.isNew')) {
          _this.set('contact');
        }
      });
    },

    contact: computed({
      set: function set(key, value) {
        this.set('startValidate', false);
        if (value && value.get('id')) {
          this.set('editMode', true);
          if (this.get('contactSidebar') && this.get('contactSidebar.addMode')) {
            this.get('contactSidebar').expandAccordion(false);
          }
          this.set('slideToggle', true);
          value.saveOriginalRelations();
          return value;
        } else if (value) {
          this.set('slideToggle', false);
          return value;
        }
        this.set('slideToggle', false);
        return this.createNew();
      },
      get: function get() {
        return this;
      }
    }),
    createNew: function createNew() {
      var contact = this.get("store").peekAll('contact');
      var newContact = contact.filterBy('isNew', true)[0] || this.get("store").createRecord('contact');
      var contactNumbers = newContact.get('contactNumbers').toArray();
      if (!newContact.get('isNewContact')) {
        contactNumbers.forEach(function (contactNumber) {
          newContact.get('contactNumbers').removeObject(contactNumber);
          contactNumber.deleteRecord();
        });
        var contactNumber = this.get('store').createRecord('contactNumber', { contact: newContact });
      }
      return newContact;
    },

    title: _ember['default'].computed('contact.isNew', function () {
      return this.get('contact.isNew') ? _ember['default'].i18n.t('contact.new_contact') : _ember['default'].i18n.t('contact.contact_details');
    }),

    isDirty: computed('contact.hasDirtyAttributes', 'contact.company', 'contact.contactNumbers.@each.phone_number', {
      get: function get() {
        return this.get('contact').isDeepDirty();
      }
    }).readOnly(),

    willDestroy: function willDestroy() {
      var model = this.get('contact');
      if (!model.get('hasDirtyAttributes') && !model.get('isDestroying')) {
        if (model.get('isNew')) {
          model.deleteRecord();
        }
        this.rollbackDirty();
      }
    },
    companies: computed({
      get: function get() {
        return this.get("store").findAll('company');
      }
    }),
    resetForm: function resetForm() {
      this.set('slideToggle', false);

      if (!this.get('isSwitchable')) {
        this.set('contact');
      }

      this.set('editMode', false);
    },

    isSwitchable: computed.and('editMode', 'parent'),
    saveContact: function saveContact() {
      var self = this,
          contact;
      this.set('startValidate', true);
      contact = this.get('contact');
      contact.set('isFlash', false);
      contact.save().then(function (contact) {
        contact.set('isFlash', true);
        self.sendAction('onModify');
        contact.rollbackDirtyContactNumbers();
        contact.rollbackDirtyChanges();

        if (!self.get('inConversation')) {
          self.send('toggleNewContact');
        }

        if (self.get('isSwitchable')) {
          return;
        }
      });
      this.set('editMode', true);
      this.get('inConversation') && this.sendAction('confirmNewContactCreation', true);
    },

    saveCompany: function saveCompany() {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this2.get("contact.company").then(function (company) {
          company.save().then(function () {
            resolve();
          });
        });
      });
    },

    rollbackDirty: function rollbackDirty() {
      this.get("contact").rollbackAttributes();
      this.get("contact").rollbackRelationships();
    },
    addNumber: function addNumber() {
      var contact = this.get('contact');
      this.get('store').createRecord('contactNumber', { contact: contact });
    },

    actions: {
      setContactSidebarReference: function setContactSidebarReference(childReference) {
        this.set('contactSidebar', childReference);
      },
      createNewCompany: function createNewCompany(select) {
        if (isEmpty(select.searchText)) {
          return;
        }
        var companyName = select.searchText;
        var company = this.get("store").createRecord('company', { name: companyName });
        select.actions.choose(company);
      },

      save: function save() {
        var _this3 = this;

        if (this.get('hasUnsavedCompany')) {
          this.saveCompany().then(function () {
            _this3.saveContact();
          });
        } else {
          this.saveContact();
        }
      },
      cancel: function cancel() {
        this.sendAction('toggleContactAction', 'Cancel');
      },
      toggleNewContact: function toggleNewContact() {
        this.get('analyticsService').addEvent('Add Contact');
        if (this.get('slideToggle')) {
          this.rollbackDirty();
          this.resetForm();
        } else {
          this.set('slideToggle', true);
        }
      },

      showImport: function showImport() {
        if (this.get('hasImportFeature')) {
          this.setProperties({
            fileModel: 'contacts',
            showImportModal: true
          });
        }
      },

      addNumber: function addNumber() {
        this.addNumber();
      }
    }

  });
});