define("freshfone-ember/components/autoplay-blocked/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/autoplay-blocked/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "icon-mute-container");
          dom.setAttribute(el2, "data-toggle", "tooltip");
          dom.setAttribute(el2, "data-placement", "bottom");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element1, 'title');
          morphs[2] = dom.createAttrMorph(element2, 'class');
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["autoplay-blocked-wrapper ", ["subexpr", "if", [["get", "resumeAutoplay", ["loc", [null, [2, 44], [2, 58]]], 0, 0, 0, 0], "fadeOutMute"], [], ["loc", [null, [2, 39], [2, 74]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "t", ["autoplay-blocked.unmute"], [], ["loc", [null, [null, null], [3, 120]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "resumeAutoplay", ["loc", [null, [4, 21], [4, 35]]], 0, 0, 0, 0], "icon-tick", "icon-volume-mute"], [], ["loc", [null, [4, 16], [4, 68]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/autoplay-blocked/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "and", [["get", "isAutoPlayBlocked", ["loc", [null, [1, 11], [1, 28]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isUIOff", ["loc", [null, [1, 34], [1, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 29], [1, 42]]], 0, 0]], [], ["loc", [null, [1, 6], [1, 43]]], 0, 0]], [], 0, null, ["loc", [null, [1, 0], [7, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});