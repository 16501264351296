define('freshfone-ember/adapters/agent-status', ['exports', 'freshfone-ember/mixins/adapters/multi-word-underscore'], function (exports, _freshfoneEmberMixinsAdaptersMultiWordUnderscore) {
  exports['default'] = _freshfoneEmberMixinsAdaptersMultiWordUnderscore['default'].extend({
    handleResponse: function handleResponse(status, headers, payload) {
      if ((status === 422 || status === 417) && payload.errors) {
        return new DS.InvalidError(payload.errors);
      }
      return this._super.apply(this, arguments);
    },

    findAll: function findAll(store, type, sinceToken, snapshotRecordArray) {
      var url = this.buildURL(type.modelName, null, snapshotRecordArray, 'findAll');

      url = url + '?version=v1';

      return this.ajax(url, 'GET');
    }
  });
});