define("freshfone-ember/components/live-calls/live-agents-dashboard/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "loading-wrapper");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "spinner");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 6
                },
                "end": {
                  "line": 11,
                  "column": 6
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "component", ["live-calls/live-agents-dashboard/agents-list"], ["noResult", true, "agents", ["subexpr", "@mut", [["get", "agents", ["loc", [null, [10, 88], [10, 94]]], 0, 0, 0, 0]], [], [], 0, 0], "status", "no_agents"], ["loc", [null, [10, 8], [10, 115]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 13,
                      "column": 10
                    },
                    "end": {
                      "line": 15,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "component", ["live-calls/live-agents-dashboard/agents-list"], ["agents", ["subexpr", "@mut", [["get", "customAgentStatus.dashboardAgents", ["loc", [null, [14, 78], [14, 111]]], 0, 0, 0, 0]], [], [], 0, 0], "status", ["subexpr", "@mut", [["get", "customAgentStatus.status", ["loc", [null, [14, 119], [14, 143]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 12], [14, 145]]], 0, 0]],
                locals: ["customAgentStatus"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 8
                  },
                  "end": {
                    "line": 16,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "each", [["get", "customAgentStatusList", ["loc", [null, [13, 18], [13, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 10], [15, 19]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 16,
                    "column": 8
                  },
                  "end": {
                    "line": 18,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "component", ["live-calls/live-agents-dashboard/agents-list"], ["agents", ["subexpr", "@mut", [["get", "agents", ["loc", [null, [17, 76], [17, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "status", ["subexpr", "@mut", [["get", "selectedStatus", ["loc", [null, [17, 90], [17, 104]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 10], [17, 106]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 6
                },
                "end": {
                  "line": 19,
                  "column": 6
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "selectedStatus", ["loc", [null, [12, 22], [12, 36]]], 0, 0, 0, 0], "All Agents"], [], ["loc", [null, [12, 18], [12, 50]]], 0, 0], ["get", "displaySearchResults", ["loc", [null, [12, 51], [12, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 14], [12, 72]]], 0, 0]], [], 0, 1, ["loc", [null, [12, 8], [18, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "agents-list-wrapper");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "isAgentListEmpty", ["loc", [null, [9, 12], [9, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [9, 6], [19, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
        },
        isEmpty: false,
        arity: 3,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["get", "fetchingRecord", ["loc", [null, [3, 12], [3, 26]]], 0, 0, 0, 0], ["get", "listLoading", ["loc", [null, [3, 27], [3, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 8], [3, 39]]], 0, 0]], [], 0, 1, ["loc", [null, [3, 2], [21, 9]]]]],
        locals: ["listLoading", "hasEmptyList", "isSearching"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "paginate-search", [], ["title", ["subexpr", "@mut", [["get", "selectedStatusTitle", ["loc", [null, [1, 26], [1, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "showDropDown", true, "callbackOnToggle", ["subexpr", "action", ["loadStatusDropdown"], [], ["loc", [null, [1, 81], [1, 110]]], 0, 0], "placeholder", ["subexpr", "t", ["agent.name_search"], [], ["loc", [null, [1, 123], [1, 146]]], 0, 0], "modelName", "live-agent-status", "version", "v1", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 196], [1, 201]]], 0, 0, 0, 0]], [], [], 0, 0], "dropDownCallback", ["subexpr", "action", ["dropDownCallback"], [], ["loc", [null, [1, 221], [1, 248]]], 0, 0], "modelCallback", ["subexpr", "action", ["modelCallback"], [], ["loc", [null, [1, 263], [1, 287]]], 0, 0], "blockEmptyLoad", true, "dropDownObj", ["subexpr", "@mut", [["get", "dropDownObj", ["loc", [null, [1, 320], [1, 331]]], 0, 0, 0, 0]], [], [], 0, 0], "hasExpandableSearchBar", true, "alwaysShowSearchbar", true], 0, null, ["loc", [null, [1, 0], [23, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});