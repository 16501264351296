define('freshfone-ember/models/ivr-input', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    ivr: belongsTo('ivr'),
    accountId: attr(),
    customerLegId: attr(),
    callId: attr(),
    ivrId: attr(),
    ivrInputData: attr(),
    createdAt: attr(),
    updatedAt: attr()
  });
});