define('freshfone-ember/routes/protected/admin/deflection-bot/index', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.call_workflows');
    }),
    breadCrumb: {},
    features: ['deflection_bot'],
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.transitionTo('protected.admin.callflows');
    }
  });
});