define('freshfone-ember/components/business-calendar/holiday-calendar/predefined-holidays/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    exports['default'] = Component.extend({
        actions: {
            closeHolidayCalendar: function closeHolidayCalendar() {
                this.sendAction('closeCallback');
            },
            gotoCustomHolidayList: function gotoCustomHolidayList() {
                this.sendAction('toggleCurrentView');
            }
        }
    });
});