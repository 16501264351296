define('freshfone-ember/components/conversation/recent-conversations-list/conversations/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    stateCompleted: computed.equal('item.status', 'completed'),
    stateInProgress: computed.equal('item.isCompleted', false),
    showNotesContainer: computed('isInProgressCall', 'hasNotesSearchText', 'item.hasNote', function () {
      return this.get('isInProgressCall') || this.get('hasNotesSearchText') && this.get('item.hasNote');
    }),
    enableNotes: computed.not('disableNotes'),
    showInProgressCallNotes: computed.and('isInProgressCall', 'enableNotes'),
    agentItem: computed.or('currentAgent', 'item'),
    showNotesIcon: computed('disableNotes', 'isInProgressCall', 'item.hasNote', function () {
      return !this.get('disableNotes') && (this.get('isInProgressCall') || this.get('item.hasNote') || this.get('item.hasTag'));
    }),
    directionStatusClass: computed('item.directionStatusClass', 'item.type', function () {
      var currentCallDirection = this.get('item.isOutgoing') ? "outgoing" : "incoming";
      return this.get('item.directionStatusClass') ? this.get('item.directionStatusClass') : 'icon-call-' + currentCallDirection;
    }),
    unKnownContact: computed.or('item.from.name', 'item.from'),
    hasCallTaggingFeature: computed(function () {
      return this.checkFeature('call_tagging');
    }),
    actions: {
      toggleNotes: function toggleNotes() {
        this.toggleProperty('showNotesContainer');
      },
      onFocus: function onFocus() {
        if (!this.get('item.tags.length')) {
          this.set('showInput', false);
        }
      }
    }
  });
});