define('freshfone-ember/components/message-components/new-message/component', ['exports', 'ember-validations', 'freshfone-ember/constants/message', 'ember', 'freshfone-ember/mixins/errorfield-focus-tabs', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/utils/message-types'], function (exports, _emberValidations, _freshfoneEmberConstantsMessage, _ember, _freshfoneEmberMixinsErrorfieldFocusTabs, _emberComponentInboundActionsInboundActions, _freshfoneEmberUtilsMessageTypes) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  var service = _ember['default'].inject.service;
  var MESSAGE = _freshfoneEmberConstantsMessage.LABELS.MESSAGE;
  var VOICEMAIL = _freshfoneEmberConstantsMessage.LABELS.VOICEMAIL;
  exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _emberComponentInboundActionsInboundActions['default'], {
    isVoicemail: computed.equal('scope', 1),
    isActiveRecording: computed.equal('message.messageType', 1),
    isActiveAttachment: computed.equal('message.messageType', 2),
    isActiveMessage: computed.alias('message.isActiveMessage'),
    hasSaveCallBack: computed.notEmpty('saveCallBack'),
    hasCloseCallBack: computed.notEmpty('closeCallBack'),
    fromExternal: computed.or('scrollTo', 'isFromModal'),
    account: computed.alias('session.account'),
    callingDisabled: computed.not('account.callingEnabled'),
    isUserScope: computed.equal('scope', _freshfoneEmberConstantsMessage.SCOPE_USER),
    analyticsService: service('analytics-tracker'),
    isNotVoicemailDropMessage: computed.not('isVoicemail'),
    shouldShowDynamicMessageField: computed.and('hasFeatureRoutingAutomation', 'isNotVoicemailDropMessage', 'isActiveMessage'),
    hasFeatureRoutingAutomation: computed(function () {
      return this.get('account').hasFeature('routing_automation');
    }),
    isOpen: computed({
      get: function get() {},
      set: function set(k, v) {
        this.setFormProps();
        this.set('formMode', v);

        return v;
      }
    }),
    analyticsEventPrefix: computed(function () {
      return this.get('scope') ? 'VM:' : '';
    }),
    recordMessageTitle: computed('callingDisabled', function () {
      if (this.get('callingDisabled')) {
        return _ember['default'].i18n.t('message_components.recordingDisabled');
      }

      return _ember['default'].i18n.t('message_components.new_message.record');
    }),
    message: computed({
      get: function get() {},
      set: function set(k, v) {
        var isMessage = v && v.id;

        if (isMessage) {
          v.set('attachment', null);
          this.get('messageSidebar') && this.get('messageSidebar').expandAccordion(false);
        } else {
          this.get('messageSidebar') && this.get('messageSidebar').expandAccordion(true);
          v = this.findOrCreateNewMessage();
        }
        v.set('messageScope', Number(!!v.get('messageScope')));
        this.set('isEditMode', isMessage);

        return v;
      }
    }),
    componentType: computed('message.messageType', function () {
      var componentType = undefined;

      if (this.get('isActiveRecording')) {
        componentType = 'record';
      } else if (this.get('isActiveAttachment')) {
        componentType = 'attachment';
      } else {
        componentType = 'text-to-speech';
      }

      return ['message-components/', componentType, '-component'].join('');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('scrollTo')) {
        scheduleOnce('afterRender', this, 'scrollToThis');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('scrollTo')) {
        this.scrollBackToParent();
      }
      this.resetMessage();
      var message = this.get('message');

      if (message && message.get('isNew')) {
        if (!this.get('isFromModal')) {
          message.destroyRecord();
        }
      }
      if (this.get('isFromModal')) {
        this.set('message', null);
      }
    },

    scrollToThis: function scrollToThis() {
      this.get('parentView').$().animate({ scrollLeft: this.$().offset().left }, 1000);
    },
    scrollBackToParent: function scrollBackToParent() {
      this.get('parent').$().animate({
        scrollLeft: this.get('parent').$().offset().left
      }, 1000);
    },
    saveMessage: function saveMessage() {
      this.set('isSaving', true);
      if (this.get('isActiveAttachment')) {
        this.saveWithAttachment();
      } else {
        this.saveWithoutAttachment();
      }
    },
    saveWithAttachment: function saveWithAttachment() {
      var _this = this;

      this.get('message').saveWithAttachment().then(function (message) {
        message.reload().then(function (message) {
          message.rollbackAttributes();
          if (!_this.get('isDestroying')) {
            _this.set('message', message);
          }
        });
        _this.afterSaveCallbacks(message);
      })['catch'](function (e) {
        console.log('server errors', e.errors);
        _this.get('message').get('errors').setProperties(e.errors);
      });
    },
    saveWithoutAttachment: function saveWithoutAttachment() {
      var _this2 = this;

      this.get('message').save().then(function (message) {
        _this2.set('message', message);
        _this2.afterSaveCallbacks(message);
      })['catch'](function (e) {
        console.log('server errors', e.errors);
        _this2.get('message').get('errors').setProperties(e.errors);
      });
    },
    afterSaveCallbacks: function afterSaveCallbacks(message) {
      if (this.get('hasSaveCallBack')) {
        this.sendAction('saveCallBack', message);
      }
      if (!this.get('message.isActiveMessage')) {
        this.set('message.dynamic', false);
      }
      this.set('isSaving', false);
      this.set('startValidate', false);
      if (this.get('fromExternal')) {
        this.resetMessage();
      }
      this.sendAction('onModify', message);
      this.send('toggleOpen');
    },
    findOrCreateNewMessage: function findOrCreateNewMessage() {
      var _this3 = this;

      var messages = this.store.peekAll('message').filter(function (message) {
        return message.get('messageScope') === _this3.get('scope');
      }),
          defaultMessageTitle = messages.mapBy('id').filter(function (id) {
        return id;
      }).length + 1,
          newMessage = messages.filterBy('isNew', true).get('lastObject') || this.store.createRecord('message', {
        messageType: 3,
        messageScope: this.get('scope') || 0
      });

      if (isEmpty(newMessage.get('name'))) {
        newMessage.set('name', _ember['default'].i18n.t(this.get('namePlaceholder')) + ' ' + defaultMessageTitle);
      }

      return newMessage;
    },
    resetMessage: function resetMessage() {
      this.set('isOpen', false);
      if (this.get('isFromModal')) {
        this.get('parentView').send('close');

        return;
      }
      var newMessage = this.findOrCreateNewMessage();

      this.set('message', newMessage);
    },
    setFormProps: function setFormProps() {
      var isUserScope = this.get('isUserScope');

      this.setProperties({
        defaultText: isUserScope ? _ember['default'].i18n.t('voicemailDrop.template').toString() : null,
        namePlaceholder: isUserScope ? VOICEMAIL.namePlaceholder : MESSAGE.namePlaceholder,
        nameLabel: isUserScope ? VOICEMAIL.nameLabel : MESSAGE.nameLabel,
        addButtonText: isUserScope ? VOICEMAIL.addButtonText : MESSAGE.addButtonText
      });
    },

    actions: {
      save: function save() {
        var _this4 = this;

        if (this.get('message.isSaving')) {
          return;
        }
        this.set('startValidate', true);
        this.validate().then(function () {
          _this4.saveMessage();
        })['catch'](function () {
          _this4.set('startValidate', true);
        });
      },

      changeType: function changeType(messageType) {
        this.get('message').set('messageType', messageType);
        this.send('updateHeap', messageType);
      },

      updateHeap: function updateHeap(messageType) {
        this.set('updateHeap', true);
        switch (messageType) {
          case 1:
            this.get('analyticsService').addEvent(this.get('analyticsEventPrefix') + 'Message Type Recorded');
            break;
          case 2:
            this.get('analyticsService').addEvent(this.get('analyticsEventPrefix') + 'Message Type Upload');
            break;
          default:
            this.get('analyticsService').addEvent(this.get('analyticsEventPrefix') + 'Message Type Text To Speech');
            break;
        }
      },

      toggleOpen: function toggleOpen() {
        this.get('analyticsService').addEvent(this.get('analyticsEventPrefix') + 'Add New Message');
        this.toggleProperty('isOpen');
        if (!this.get('isOpen')) {
          this.send('close');
        }
      },

      close: function close() {
        this.toggleProperty('open');
        this.set('startValidate', false);
        // if(this.get("isEditMode")) {
        this.get('message').rollbackAttributes();
        // }
        this.set('isEditMode', false);
        this.resetMessage();
        if (this.get('hasCloseCallBack')) {
          this.sendAction('closeCallBack');
        }
      },
      setMessageSidebarReference: function setMessageSidebarReference(childReference) {
        this.set('messageSidebar', childReference);
      },
      dynamicMessageToggled: function dynamicMessageToggled() {
        var message = this.get('message');

        if (message.get('dynamic')) {
          message.set('messageType', _freshfoneEmberUtilsMessageTypes['default'].dynamic);

          if (isEmpty(message.get('messageText'))) {
            message.set('messageText',
            // eslint-disable-next-line ember-suave/no-direct-property-access
            _ember['default'].i18n.t('message_components.new_message.dynamic_message.placeholder').toString());
          }
        } else {
          message.set('messageType', _freshfoneEmberUtilsMessageTypes['default'].text);
        }

        this.validate();
      }
    },

    validations: {
      'message.name': { presence: { message: _ember['default'].i18n.t('message.errors.name_blank') } },
      'message.messageType': { presence: { message: _ember['default'].i18n.t('message.errors.type_blank') } },
      'message.messageText': (0, _emberValidations.validator)(function () {
        var message = this.get('message');

        if (message.get('isActiveMessage') && this.get('startValidate')) {
          if (isEmpty(message.get('messageText'))) {
            // eslint-disable-next-line ember-suave/no-direct-property-access
            return _ember['default'].i18n.t('message.errors.message_text_blank');
          }
          if (this.get('isUserScope') && message.get('messageText') === this.get('defaultText')) {
            // eslint-disable-next-line ember-suave/no-direct-property-access
            return _ember['default'].i18n.t('voicemailDrop.templateError');
          }
          if (message.get('dynamic') && !message.get('hasPlaceholderText')) {
            // eslint-disable-next-line ember-suave/no-direct-property-access
            return _ember['default'].i18n.t('message.errors.missing_placeholder_text').toString();
          }
        }
      }),

      'message.attachment': {
        inline: (0, _emberValidations.validator)(function () {
          if (!this.get('message.attachmentUrl') && this.get('startValidate') && this.get('message.isActiveAttachment')) {
            if (this.get('message.hasInvalidAttachment')) {
              return _ember['default'].i18n.t('message.errors.attachment_blank');
            }
            if (!this.get('message.hasValidAttachmentType')) {
              return _ember['default'].i18n.t('message.errors.attachment_type_invalid');
            }
            if (!this.get('message.hasValidAttachmentSize')) {
              return _ember['default'].i18n.t('message.errors.attachment_size_invalid');
            }
          }
        })
      },

      'message.recordingUrl': (0, _emberValidations.validator)(function () {
        if (this.get('message.isActiveRecording') && isEmpty(this.get('message.recordingUrl'))) {
          return _ember['default'].i18n.t('message.errors.recording_blank');
        }
      })
    }

  });
});