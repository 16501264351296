define('freshfone-ember/components/business-calendar/day-entry/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var isEmpty = _ember['default'].isEmpty;
    exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], {
        disableDay: computed.and('disabledDay', 'notSelectedDay'),
        notSelectedDay: computed.not('selectedDay'),
        disabledDay: computed('days.[]', function () {
            return this.get('businessCalendar.enabledDays').includes(this.get('displayDay.value'));
        }),
        selectedDay: computed('days.[]', function () {
            return this.get('days').includes(this.get('displayDay.value'));
        }),
        displayDayInitial: computed('days', function () {
            return this.get('displayDay.shortForm.string')[0];
        }),
        init: function init() {
            this._super.apply(this, _toConsumableArray(this));
        },
        actions: {
            selectDay: function selectDay(day) {
                if (this.get('disableDay')) return;
                this.toggleProperty('selectedDay');
                if (this.get('selectedDay')) {
                    this.get('days').push(day.value);
                    this.get('businessCalendar.enabledDays').push(day.value);
                } else {
                    this.get('days').removeObject(day.value);
                    this.get('businessCalendar.enabledDays').removeObject(day.value);
                    this.set('disableDay', false);
                }
            }
        }
    });
});