define('freshfone-ember/mixins/validations/call-queue', ['exports', 'ember-validations', 'freshfone-ember/constants/call-queues', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/free-plans-caller'], function (exports, _emberValidations, _freshfoneEmberConstantsCallQueues, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsFreePlansCaller) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create(_emberValidations['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),
    session: service(),
    isWaitQueueEnhancementEnabled: computed(function () {
      return this.checkFeature('call_queue_agent_batching') && this.hasGlobalFeature('cq_and_wq_behavior_2022');
    }),
    isExternalTransferAllowed: computed(function () {
      return this.allowExternalTransfer() && this.get('session.account').hasGlobalFlag('external_number_request');
    }),
    isFreePlan: computed(function () {
      return _freshfoneEmberConstantsFreePlansCaller['default'].includes(this.get('session.account.plan_name'));
    }),
    validations: {
      name: {
        presence: { message: _ember['default'].i18n.t('call_queue.ui_validation_error.name_blank') }
      },
      message: {
        presence: true
      },

      'noAnswerAction': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('isFreePlan') && !this.model.get('hasNoAnswerAction')) {
            return _ember['default'].i18n.t('call_queue.ui_validation_error.message_blank');
          }
        })
      },

      'autoAnswerThreshold': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('autoAnswerStatus') === _freshfoneEmberConstantsCallQueues.AUTO_ANSWER.userCustomSettings) {
            if (!isNaN(this.model.get('autoAnswerThreshold')) && this.model.get('autoAnswerThreshold') !== '') {
              if (this.model.get('autoAnswerThreshold') < 0 || this.model.get('autoAnswerThreshold') > 10) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.autoAnswerThreshold');
              }
            } else {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.ringing_time.only_number');
            }
          }
        })
      },
      'message.content': {
        presence: { message: _ember['default'].i18n.t('call_queue.ui_validation_error.message_blank') }
      },
      'waitQueueSize': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (!/^\d+$/.test(this.model.get('waitQueueSize'))) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_queue_size.only_integer');
            }if (this.model.get('waitQueueSize') <= 0) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_queue_size.above_zero');
            } else if (!this.model.get('waitMaxEnabled') && this.model.get('waitQueueSize') > 20) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_queue_size.below_twenty');
            } else if (this.model.get('waitMaxEnabled') && this.model.get('waitQueueSize') > 1000) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_queue_size.below_thousand');
            }
          }
        })
      },
      'queueFullCallQueue': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (this.model.get('hasQueueFullActionQueue')) {
              if (!this.model.get('queueFullCallQueue.content')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.queue_full.another_queue_blank');
              }
            }
          }
        })
      },
      'queueFullAgentExtension': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (this.model.get('hasQueueFullAgentExtension')) {
              if (!this.model.get('queueFullAgentExtension.id')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.queue_full.agent_extension_blank');
              }
            }
          }
        })
      },
      'queueFullRoutingAutomation': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (this.model.get('hasQueueFullRoutingAutomation')) {
              if (!this.model.get('queueFullRoutingAutomation.content')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.queue_full.routing_automation_blank');
              }
            }
          }
        })
      },
      'timeThresholdCallQueue': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (this.model.get('hasTimeThresholdActionQueue')) {
              if (!this.model.get('timeThresholdCallQueue.content')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.queue_full.another_queue_blank');
              }
            }
          }
        })
      },
      'timeThresholdAgentExtension': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (this.model.get('hasTimeThresholdAgentExtension')) {
              if (!this.model.get('timeThresholdAgentExtension.id')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.queue_full.agent_extension_blank');
              }
            }
          }
        })
      },
      'timeThresholdRoutingAutomation': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (this.model.get('hasTimeThresholdRoutingAutomation')) {
              if (!this.model.get('timeThresholdRoutingAutomation.content')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.queue_full.routing_automation_blank');
              }
            }
          }
        })
      },
      'callbackFailCallQueue': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (this.model.get('hasCallbackActionQueue')) {
              if (!this.model.get('callbackFailCallQueue.content')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.callback.another_queue_blank');
              }
            }
          }
        })
      },
      'callbackFailRoutingAutomation': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (this.model.get('hasCallbackActionRoutingAutomation')) {
              if (!this.model.get('callbackFailRoutingAutomation.content')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.queue_full.routing_automation_blank');
              }
            }
          }
        })
      },

      'noAnswerCallQueue': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasNoAnswerActionQueue')) {
            if (!this.model.get('noAnswerCallQueue.content')) {
              if (!this.model.get('noAnswerWaitQueueEnabled')) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.no_answer.another_queue_blank');
              }
            } else if (this.model.get('noAnswerCallQueue.content.hasBusyWaitOverActionQueue') || this.model.get('noAnswerCallQueue.content.hasNoAnswerActionQueue') || this.model.get('noAnswerCallQueue.content.hasOfflineActionQueue')) {
              return this.get('i18n').t('call_queue.ui_validation_error.multiple_child_queues');
            }
          }
        })
      },

      noAnswerAgentExtension: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasNoAnswerAgentExtension')) {
            if (!this.model.get('noAnswerAgentExtension.id')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.no_answer.agent_extension_blank');
            }
          }
        })
      },
      noAnswerRoutingAutomation: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasNoAnswerRoutingAutomation')) {
            if (!this.model.get('noAnswerRoutingAutomation.content')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.no_answer.routing_automation_blank');
            }
          }
        })
      },
      'busyWaitOverMessage': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasFallbackFlow') && this.model.get('hasBusyWaitOverActionMessage') && !this.model.get('busyWaitOverMessage.content') && !this.model.get('hasBusyWaitOverAgentExtension') && !this.model.get('hasBusyWaitOverRoutingAutomation')) {
            if (!this.model.get('busyWaitQueueEnabled')) {
              return this.model.get('busyFallbackMessageValidationText');
            }
          }
        })
      },
      'busyWaitOverCallQueue': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasFallbackFlow') && this.model.get('hasBusyWaitOverActionQueue')) {
            if (!this.model.get('busyWaitOverCallQueue.content')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_queue_over.message_blank');
            } else if (this.model.get('busyWaitOverCallQueue.content.hasBusyWaitOverActionQueue') || this.model.get('busyWaitOverCallQueue.content.hasNoAnswerActionQueue') || this.model.get('busyWaitOverCallQueue.content.hasOfflineActionQueue')) {
              return this.get('i18n').t('call_queue.ui_validation_error.multiple_child_queues');
            }
          }
        })
      },

      busyWaitOverAgentExtension: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasBusyWaitOverAgentExtension')) {
            if (!this.model.get('busyWaitOverAgentExtension.id')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.busy.agent_extension_blank');
            }
          }
        })
      },
      busyWaitOverRoutingAutomation: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasBusyWaitOverRoutingAutomation')) {
            if (!this.model.get('busyWaitOverRoutingAutomation.content')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.busy.routing_automation_blank');
            }
          }
        })
      },

      offlineMessage: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasFallbackFlow') && this.model.get('hasOfflineActionMessage') && !this.model.get('offlineMessage.content') && !this.model.get('hasOfflineAgentExtension') && !this.model.get('hasOfflineRoutingAutomation')) {
            if (!this.model.get('offlineWaitQueueEnabled')) return _ember['default'].i18n.t('call_queue.ui_validation_error.offline.message_blank');
          }
        })
      },
      'offlineCallQueue': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasFallbackFlow') && this.model.get('hasOfflineActionQueue')) {
            if (!this.model.get('offlineCallQueue.content')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.offline.another_queue_blank');
            } else if (this.model.get('offlineCallQueue.content.hasBusyWaitOverActionQueue') || this.model.get('offlineCallQueue.content.hasNoAnswerActionQueue') || this.model.get('offlineCallQueue.content.hasOfflineActionQueue')) {
              return this.get('i18n').t('call_queue.ui_validation_error.multiple_child_queues');
            }
          }
        })
      },

      offlineAgentExtension: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasOfflineAgentExtension')) {
            if (!this.model.get('offlineAgentExtension.id')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.offline.agent_extension_blank');
            }
          }
        })
      },
      offlineRoutingAutomation: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasOfflineRoutingAutomation')) {
            if (!this.model.get('offlineRoutingAutomation.content')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.offline.routing_automation_blank');
            }
          }
        })
      },

      'quitQueueMessage': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('hasFallbackFlow') && this.model.get('waitQueueEnabled') && this.model.get('hasQuitQueueActionMessage')) {
            if (!this.model.get('quitQueueMessage.content')) {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.quit_queue.message_blank');
            }
          }
        })
      },
      'routeTypeWithObject': {
        inline: (0, _emberValidations.validator)(function () {
          if (!this.model.get('routeTypeWithObject.id')) {
            return _ember['default'].i18n.t('call_queue.ui_validation_error.route_to_blank');
          }

          if (this.model.get('routeTypeWithObject.id') === 'externalNumber') {
            if (!this.get('isExternalTransferAllowed')) {
              return this.get('i18n').t('call_queue.ui_validation_error.external_number_not_allowed');
            }
          }
        })
      },
      externalNumber: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('invalidExternalNumber')) {
            return _ember['default'].i18n.t('call_queue.validation_error.external_number.invalid_number');
          }
        })
      },
      busyQueuePositionMessage: {
        presence: {
          'if': 'queuePositionEnabled',
          message: _ember['default'].i18n.t('call_queue.ui_validation_error.queue_position_blank')
        }
      },
      'routeOptionMins': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('isTeamOrAllAgentsRoute')) {
            if (/^[0-9/-]+$/.test(this.model.get('routeOptionMins'))) {
              if (this.model.get('enhancedRingingTimeEnabled')) {
                if (this.model.get('routeOptionMins') < 5) {
                  return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.above_or_equal_five');
                } else if (this.model.get('routeOptionMins') > 600) {
                  return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.below_or_equal_nine_hundred', { maxSeconds: 600 });
                }
              } else if (this.model.get('routeOptionMins') < 30) {
                return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.above_or_equal_thirty');
              } else if (this.model.get('routeOptionMins') > 600) {
                return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.below_or_equal_nine_hundred', { maxSeconds: 600 });
              }
            } else {
              return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.only_number');
            }
          }
        })
      },
      'totalRingingTimeout': {
        inline: (0, _emberValidations.validator)(function () {
          if (/^[0-9/-]+$/.test(this.model.get('totalRingingTimeout'))) {
            if (this.model.get('isTeamOrAllAgentsRoute')) {
              if (this.model.get('totalRingingTimeout') < 30) {
                return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.above_or_equal_thirty');
              } else if (this.model.get('totalRingingTimeout') > 600) {
                return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.below_or_equal_nine_hundred', { maxSeconds: 600 });
              }
            } else if (this.model.get('totalRingingTimeout') < 5) {
              return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.above_or_equal_five_agent_ext_queue');
            } else if (this.model.get('totalRingingTimeout') > 150) {
              return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.below_or_equal_value_agent_ext_queue', { maxSeconds: 150 });
            }
          } else {
            return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.only_number');
          }
        })
      },
      'agentBatch': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('isWaitQueueEnhancementEnabled')) {
            if (/^[0-9/-]+$/.test(this.model.get('agentBatch'))) {
              if (this.model.get('routeOption') === 'all' && this.model.get('agentBatch') < 2) {
                return this.get('i18n').t('call_queue.ui_validation_error.agent_batch.minimum', { value: '2' });
              } else if (this.model.get('agentBatch') > 10) {
                return this.get('i18n').t('call_queue.ui_validation_error.agent_batch.maximum', { value: '10' });
              }
            } else {
              return this.get('i18n').t('call_queue.ui_validation_error.ringing_time.only_number');
            }
          }
        })
      },
      routeOption: {
        presence: {
          'if': 'isTeamOrAllAgentsRoute',
          message: _ember['default'].i18n.t('call_queue.ui_validation_error.route_option_blank')
        }
      },
      'busyMaxWaitTime': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.model.get('waitQueueEnabled')) {
            if (/^[0-9/-]+$/.test(this.model.get('busyMaxWaitTime'))) {
              if (this.model.get('busyMaxWaitTime') < 30) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_time.above_or_equal_thirty');
              }
              if (this.model.get('enhancedWaitTimeEnabled')) {
                if (this.model.get('busyMaxWaitTime') >= 14400) {
                  return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_time.greater_than_four_hours');
                }
              } else if (this.model.get('busyMaxWaitTime') > 900) {
                return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_time.greater_than_fifteen_minutes');
              }
            } else {
              return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_time.only_number');
            }
          }
        })
      },
      'busyMessage.content': {
        presence: {
          'if': 'waitQueueEnabled',
          message: _ember['default'].i18n.t('call_queue.ui_validation_error.busy.message_blank')
        }
      }
    }

  });
});
/* eslint-disable ember-suave/no-direct-property-access */