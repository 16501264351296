define('freshfone-ember/components/message-components/text-to-speech-component/component', ['exports', 'freshfone-ember/constants/supported-languages', 'ember'], function (exports, _freshfoneEmberConstantsSupportedLanguages, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;

  var FEMALE_VOICE = 0,
      MALE_VOICE = 1;

  exports['default'] = Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('session.account').hasFeature('message_language')) {
        this.set('message.voiceLanguage', this.get('session.account.preferredVoiceLanguage'));
      }
    },
    session: service(),
    defaultText: computed({
      get: function get() {},
      set: function set(k, v) {
        if (v && !this.get('message.messageText')) {
          this.set('message.messageText', v);
        }

        return v;
      }
    }),
    languageList: computed(function () {
      return Object.values(_freshfoneEmberConstantsSupportedLanguages.supportedMessageLanguages);
    }),
    speechLanguage: computed('message.voiceLanguage', {
      get: function get() {
        return _freshfoneEmberConstantsSupportedLanguages.supportedMessageLanguages[this.get('message.voiceLanguage')];
      },
      set: function set(key, value) {
        this.set('message.voiceLanguage', value.key);
        if (this.get('message.isMaleVoice') && this.get('noMaleVoice')) {
          this.set('message.messageVoiceType', FEMALE_VOICE);
        }

        return value;
      }
    }),
    noMaleVoice: computed('message.voiceLanguage', function () {
      return _freshfoneEmberConstantsSupportedLanguages.unsupportedMaleVoiceLanguages.includes(this.get('message.voiceLanguage'));
    }),
    unsupportedMaleVoiceTitle: computed('noMaleVoice', function () {
      if (this.get('noMaleVoice')) {
        return _ember['default'].i18n.t('message_components.text_to_speech.voiceNotSupported');
      }

      return '';
    }),

    actions: {
      setVoice: function setVoice(selectedVoiceType) {
        if (this.get('noMaleVoice') && !this.get('message.messageVoiceType')) {
          return;
        }

        this.set('message.messageVoiceType', selectedVoiceType);
        this.set('eventType', selectedVoiceType === MALE_VOICE ? 'Male Voice Message' : 'Female Voice Message');
      }
    }
  });
});