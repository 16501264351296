define('freshfone-ember/utils/freshfone-number-util', ['exports', 'freshfone-ember/utils/country-region-constants', 'ember', 'ember-data', 'freshfone-ember/constants/locality-supported-countries'], function (exports, _freshfoneEmberUtilsCountryRegionConstants, _ember, _emberData, _freshfoneEmberConstantsLocalitySupportedCountries) {

  var FreshfoneNumberUtil = {
    rates: [],
    loadNumberRates: function loadNumberRates(callback) {
      var _this = this;

      return _ember['default'].$.getJSON('/numbers/rate', function (resp) {
        _this.rates = resp;
        callback();
      });
    },
    localCountries: function localCountries() {
      var _this2 = this;

      function filterCountries(countries) {
        return _ember['default'].isPresent(countries) ? countries.filter(function (country) {
          return country.local || country.mobile;
        }) : [];
      }
      if (_ember['default'].isEmpty(this.rates)) {
        var promise = new _ember['default'].RSVP.Promise(function (resolve) {
          _this2.loadNumberRates(resolve);
        }).then(function () {
          return filterCountries(_this2.rates);
        });
        return _emberData['default'].PromiseArray.create({ promise: promise });
      } else {
        return filterCountries(this.rates);
      }
    },
    localStates: function localStates(countryCode) {
      // let states = [];
      function findByCountryCode(countryCode) {
        var states = _freshfoneEmberUtilsCountryRegionConstants['default'].findBy('countryShortCode', countryCode).regions;
        if (!states.findBy('shortCode', 'default')) {
          states.unshiftObject({ name: _ember['default'].i18n.t('numbers.purchase.purchase_form.all_states'), shortCode: 'default' });
        }
        return states;
      }
      return _freshfoneEmberConstantsLocalitySupportedCountries['default'].countries.contains(countryCode) ? findByCountryCode(countryCode) : [];
    },

    tollfreeCountries: function tollfreeCountries() {
      var tollFreeRates = this.rates.filter(function (country) {
        return country.toll_free;
      });
      var countryResult = [];
      if (tollFreeRates.length !== 0) {
        for (var i = 0; i < tollFreeRates.length; i++) {
          countryResult.push({ code: tollFreeRates[i].code, name: tollFreeRates[i].name });
        }
      }
      return countryResult;
    },
    tollfreePrefixes: function tollfreePrefixes(country, key) {
      var textAny = _ember['default'].i18n.t('numbers.purchase.any').string;
      var us_canada_toll_free_numbers = [textAny, "800", "855", "866", "877", "888"];
      var result = {
        "US": { numbers: us_canada_toll_free_numbers, 'default': textAny },
        "CA": { numbers: us_canada_toll_free_numbers, 'default': textAny },
        "GB": { numbers: [textAny], 'default': textAny },
        "AU": { numbers: [textAny], 'default': textAny },
        "JP": { numbers: [textAny], 'default': textAny },
        "NZ": { numbers: [textAny], 'default': textAny },
        "HK": { numbers: [textAny], 'default': textAny },
        "SK": { numbers: [textAny], 'default': textAny }
      };
      // TO DO - have to check Number credit - Later . Ref: Helpkit
      return result[country][key];
    },
    rate: function rate(type, countryCode) {
      var countries = this.rates,
          numberInfo = _ember['default'].isPresent(countryCode) && _ember['default'].isPresent(countries) ? countries.findBy('code', countryCode)[type] : '';

      if (numberInfo) {
        return numberInfo.price.toFixed(2);
      }
    },
    stateFullform: function stateFullform(countryCode, stateCode) {
      var country = _freshfoneEmberUtilsCountryRegionConstants['default'].findBy('countryShortCode', countryCode),
          state = country.regions.findBy('shortCode', stateCode);
      return state ? state.name : stateCode;
    }
  };

  exports['default'] = FreshfoneNumberUtil;
});