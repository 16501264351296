define('freshfone-ember/services/slm-view', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var notEmpty = _ember['default'].computed.notEmpty;
  exports['default'] = Service.extend({
    isViewSelected: notEmpty('viewId'),
    viewId: '',
    fetchSlmView: function fetchSlmView(viewId) {
      this.set('viewId', viewId);
    }
  });
});