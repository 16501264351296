define('freshfone-ember/components/custom-elements/new-bot/component', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Component.extend({
        createNewText: computed('select.searchText', function () {
            var entityList = [];
            this.store.peekAll('speech-ivr-bot').filter(function (bot) {
                entityList.push(bot.get('name'));
            });
            return entityList.indexOf(this.get('select.searchText')) < 0 ? this.get('select.searchText') : false;
        })
    });
});