define('freshfone-ember/constants/support-portal-links', ['exports'], function (exports) {
  /* eslint-disable max-len */
  var supportPortalLinks = {
    addingNewAgents: 'https://support.freshcaller.com/en/support/solutions/articles/229264-adding-new-agents',
    viewingAgentPerformanceDashboard: 'https://support.freshcaller.com/en/support/solutions/articles/50000001724-viewing-agent-performance-dashboard',
    customisingAgentStatuses: 'https://support.freshcaller.com/en/support/solutions/articles/50000000974-customizing-agent-status',
    configuringBusinessHours: 'https://support.freshcaller.com/support/solutions/articles/229260-configuring-your-business-hours',
    introductionToCallFlows: 'https://support.freshcaller.com/en/support/solutions/articles/50000002879-introduction-to-call-flows',
    settingUpCallQueues: 'https://support.freshcaller.com/en/support/solutions/articles/229261-setting-up-call-queues',
    settingUpIvr: 'https://support.freshcaller.com/en/support/solutions/articles/229262-setting-up-interactive-voice-response-ivr-',
    configuringAgentExtensionCallFlows: 'https://support.freshcaller.com/en/support/solutions/articles/50000001126-configuring-agent-extension-call-flow',
    introductionToVoiceBot: 'https://support.freshcaller.com/en/support/solutions/articles/50000001662-introduction-to-voicebot',
    configuringAndManagingSpeechEnabledIvr: 'https://support.freshcaller.com/en/support/solutions/articles/50000001702-configuring-and-managing-speech-enabled-ivr',
    overviewOfRoutingAutomation: 'https://support.freshcaller.com/en/support/solutions/articles/50000002321-overview-of-routing-automation',
    overviewOfCallMetrics: 'https://support.freshcaller.com/support/solutions/articles/229392-overview-of-call-metrics-in-freshcaller',
    savingAndImportingContacts: 'https://support.freshcaller.com/en/support/solutions/articles/240188-saving-and-importing-your-contacts',
    savingAndImportingCompanies: 'https://support.freshcaller.com/support/solutions/articles/50000003046-importing-companies',
    voicemailDrop: 'https://support.freshcaller.com/support/solutions/articles/50000000940-voicemail-drop',
    creatingCustomisedMessagesAndGreetings: 'https://support.freshcaller.com/en/support/solutions/articles/229263-how-to-create-customized-messages-and-greetings',
    buyingAPhoneNumber: 'https://support.freshcaller.com/support/solutions/articles/229256-buying-a-phone-number',
    gettingFreshcallerNumbers: 'https://support.freshcaller.com/support/solutions/articles/50000002912-getting-your-freshcaller-numbers',
    configuringServiceLevels: 'https://support.freshcaller.com/en/support/solutions/articles/239636-how-to-configure-your-account-s-service-level-',
    knowingAndEnablingSip: 'https://support.freshcaller.com/en/support/solutions/articles/239188-what-is-sip-connectivity-how-to-enable-it-from-freshcaller-',
    addingNewTeams: 'https://support.freshcaller.com/en/support/solutions/articles/50000002907-adding-new-teams',
    viewingCallersInQueue: 'https://support.freshcaller.com/en/support/solutions/articles/229385-viewing-your-callers-in-queue',
    settingUpCallTags: 'https://support.freshcaller.com/en/support/solutions/articles/50000002686-setting-up-call-tags',
    usingPowerDialer: 'https://support.freshcaller.com/support/solutions/articles/50000003163-using-power-dialer',
    learnMoreAboutCallTypes: 'https://support.freshcaller.com/support/solutions/articles/50000003494-understanding-call-types',
    scheduleBulkDelete: 'https://support.freshcaller.com/en/support/solutions',
    networkWarning: 'https://support.freshcaller.com/en/support/solutions/articles/50000005683-network-error-warning-message'
  };

  // eslint-disable-next-line one-var
  var crmSupportPortalLinks = {
    knowingAndEnablingSip: 'https://crmsupport.freshworks.com/support/solutions/articles/50000002662-what-is-sip-connectivity-how-to-enable-it-from-freshworks-crm',
    creatingCustomisedMessagesAndGreetings: 'https://crmsupport.freshworks.com/support/solutions/articles/50000002661-how-to-configure-customized-messages-and-greetings',
    introductionToCallFlows: 'https://crmsupport.freshworks.com/support/solutions/articles/50000002657-how-to-set-up-call-queues-in-freshworks-crm',
    customisingAgentStatuses: 'https://crmsupport.freshworks.com/support/solutions/articles/50000002664-how-to-set-up-custom-status-for-agents-in-freshworks-crm',
    configuringBusinessHours: 'https://crmsupport.freshworks.com/support/solutions/articles/50000002656-how-to-configure-business-hours-for-calls-in-freshworks-crm',
    buyingAPhoneNumber: 'https://crmsupport.freshworks.com/support/solutions/articles/50000002648-how-to-buy-a-phone-number-in-freshworks-crm',
    voicemailDrop: 'https://crmsupport.freshworks.com/support/solutions/articles/50000002668-how-to-add-voicemail-messages-in-freshworks-crm'
  };

  exports.supportPortalLinks = supportPortalLinks;
  exports.crmSupportPortalLinks = crmSupportPortalLinks;
});