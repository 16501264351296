define('freshfone-ember/routes/protected/contacts/index', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberRoutesProtected) {
  var isPresent = _ember['default'].isPresent;
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.contacts');
    }),
    queryParams: {
      companyId: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel() {
      if (this.hasOmniRedirectFeatureEnabled()) {
        return this.transitionTo('/dashboard');
      }
      this.checkPrivilege('view_customers');
    },

    model: function model(params) {
      if (isPresent(params.companyId)) {
        return this.store.query('contact', { filter: { company: params.companyId } });
      }
      return this.store.query('contact', {});
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('companyId', undefined);
      }
    },
    actions: {
      clearFilter: function clearFilter() {
        this.transitionTo({ queryParams: false });
      }
    }

  });
});