define('freshfone-ember/components/call-logs/call-life-cycle/component', ['exports', 'ember', 'freshfone-ember/utils/call-constants'], function (exports, _ember, _freshfoneEmberUtilsCallConstants) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({
    notification: service('toastr'),
    callId: computed.alias('call.id'),

    currentCall: computed('call.id', function () {
      return parseInt(this.get('call.id'));
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.call-life-cycle').tooltip({
        selector: '[data-toggle="tooltip"]',
        container: '.call-life-cycle'
      });
    },
    observeCallId: observer('callId', function () {
      if (this.get('callId') && this.get('call.status') === _freshfoneEmberUtilsCallConstants['default'].STATUS_HASH[1]) {
        this.fetchCallEvents();
      }
    }),

    fetchCallEvents: function fetchCallEvents() {
      var _this = this;

      this.setProperties({
        callEvents: null,
        callEventsLoading: true
      });
      var callId = undefined;
      if (this.get('isAgentLeg')) {
        callId = this.get('call.call.id');
      } else {
        callId = this.get('call.isChild') ? this.get('call.rootId') : this.get('callId');
      }

      var selectedCall = this.store.peekRecord('call', callId);
      selectedCall.getCallEvents().then(function (result) {
        _this.setProperties({
          callEvents: result.events,
          callEventsLoading: false
        });
      });
    },

    initiateExport: function initiateExport(exportType) {
      var _this2 = this;

      $.ajax({
        url: '/data_exports/',
        type: 'post',
        dataType: 'json',
        data: this.exportParams(exportType)
      }).done(function (result) {
        run(function () {
          if (result.data.id) {
            _this2.get('notification').success(_ember['default'].i18n.t('call_log.status_message.success'));
          }
        });
      });
    },

    exportParams: function exportParams(exportType) {
      var callId = this.get('call.isChild') ? this.get('call.rootId') : this.get('callId');
      var params = { export_to: exportType, send_mailer: true, type: 'events', call_id: callId };
      return Object.assign(params);
    },

    actions: {
      exportCallLifeCycleAsCSV: function exportCallLifeCycleAsCSV() {
        this.initiateExport('csv');
        this.get('closeCallDetails')();
      },
      exportCallLifeCycleAsExcel: function exportCallLifeCycleAsExcel() {
        this.initiateExport('xls');
        this.get('closeCallDetails')();
      },
      toggleChildCall: function toggleChildCall(childCallId) {
        this.$('.header-' + childCallId).find('.more-less').toggleClass('icon-subtract-circle icon-add-circle');

        this.$('.body-' + childCallId).toggleClass('expanded');
      },
      onClickExport: function onClickExport() {
        this.$('[data-toggle=tooltip]').tooltip('hide');
      }
    }

  });
});