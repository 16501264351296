define('freshfone-ember/models/contact', ['exports', 'ember', 'ember-data', 'ember-validations', 'freshfone-ember/helpers/email'], function (exports, _ember, _emberData, _emberValidations, _freshfoneEmberHelpersEmail) {

  var computed = _ember['default'].computed;
  var momentjs = window.moment;

  exports['default'] = _emberData['default'].Model.extend(_emberValidations['default'], {
    name: _emberData['default'].attr(),
    title: _emberData['default'].attr(),
    email: _emberData['default'].attr(),
    website: _emberData['default'].attr(),
    address: _emberData['default'].attr('string'),
    last_call_at: _emberData['default'].attr(),
    totalCalls: _emberData['default'].attr(),
    deleteResources: _emberData['default'].attr(),
    calls: _emberData['default'].hasMany('call', { async: true, skipDeepDirty: true }),
    company: _emberData['default'].belongsTo('company', { async: true }),
    contactNumbers: _emberData['default'].hasMany('contact-number', { async: false }),
    contactNumber: _emberData['default'].attr(),
    hasLastCallAt: computed.notEmpty('last_call_at'),

    readable: computed("name", "title", "email", "phone", "address", "website", {
      get: function get() {
        return ["name", "title", "email", "phone", "address", "website"];
      }
    }),

    format_last_call_at: computed('last_call_at', {
      get: function get() {
        if (this.get('hasLastCallAt')) {
          var dateFormat = {
            sameDay: function sameDay() {
              return '[' + this.fromNow() + ']';
            },
            lastDay: '[Yesterday] - hh:mm A',
            lastWeek: 'ddd - hh:mm A',
            someElse: 'DD/MM/YYYY - hh:mm A'
          };
          var data = momentjs(this.get('last_call_at')).calendar(null, dateFormat);

          return data;
        } else {
          return _ember['default'].i18n.t('common.no_calls');
        }
      } }),
    hasNumber: function hasNumber(number) {
      var _this = this;

      var contactHasNumber = false;
      return new Promise(function (resolve, reject) {
        _this.reload().then(function (contact) {
          (contact.get('contactNumbers') || []).forEach(function (contactNumber) {
            if (contactNumber.get('phone_number') === number) {
              resolve();
            }
          });
          reject();
        });
      });
    },
    rollbackDirtyChanges: function rollbackDirtyChanges() {
      this.saveOriginalRelations();
      this.rollbackDirtyContactNumbers();
      this.rollbackAttributes();
      this.rollbackRelationships();
    },

    rollbackDirtyContactNumbers: function rollbackDirtyContactNumbers() {
      var unsavedContactNumbers = this.store.peekAll('contactNumber').filterBy('isNew', true);
      unsavedContactNumbers.forEach(function (contactNumber) {
        contactNumber.rollbackAttributes();
      });
    },

    actions: {
      afterCustomSelect2Init: function afterCustomSelect2Init(data) {
        console.log(data);
      }
    },

    validations: {
      name: {
        presence: { message: _ember['default'].i18n.t('activemodel.errors.name_not_blank') },
        length: { maximum: 255, messages: { tooLong: _ember['default'].i18n.t('activemodel.errors.name_too_long') } }
      },
      email: {
        format: { 'with': (0, _freshfoneEmberHelpersEmail.emailRegex)(), allowBlank: true, message: _ember['default'].i18n.t('activemodel.errors.is_invalid') }
      }
    }
  });
});