define('freshfone-ember/components/integrations/entity-list/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var isPresent = _ember['default'].isPresent;
    var isEmpty = _ember['default'].isEmpty;
    var set = _ember['default'].set;
    exports['default'] = Component.extend({
        isSaving: false,
        savingEntity: '',
        showActionOnEntity: computed.notEmpty('actionOnEntity'),
        listIcon: computed('actionItem', function () {
            return 'icon-' + this.get('actionItem.integration.entityLabel');
        }),
        entities: computed.alias('results'),
        allEntities: computed(function () {
            return this.get('store').peekAll('entity');
        }),
        currentCallResources: computed(function () {
            return this.get('store').peekAll('integratedResource');
        }),
        integratedResources: computed('currentCallResources.[]', function () {
            return this.get('currentCallResources').filterBy('integratedApplication.id', this.get('integratedApplication').id);
        }),
        entityList: computed('entities.[]', 'integratedResources.[]', 'call.integratedResource', function () {
            if (this.get('call.integratedResources')) {
                var resource = this.get('call.integratedResources');
                if (resource) {
                    this.set('isSaving', false);
                    this.set('savingEntity', '');
                    var entity = this.get('allEntities').findBy('id', resource.id);
                    if (isPresent(entity)) {
                        if (isEmpty(this.get('entities'))) {
                            this.set('entities', []);
                        }
                        this.get('entities').removeObject(entity);
                        set(entity, 'selected', true);
                        this.get('entities').unshiftObject(entity);
                    }
                }
            }
            return this.get('entities') || [];
        }),
        entityAction: computed('actionOnEntity', function () {
            if (this.get('actionOnEntity')) {
                return 'actionOnEntity';
            }
            return 'openEntity';
        }),
        actions: {
            actionOnEntity: function actionOnEntity(entity) {
                this.set('isSaving', true);
                this.set('savingEntity', entity.id);
                this.sendAction('actionOnEntity', entity);
                this.sendAction('callAction', entity);
            },
            openEntity: function openEntity(entity) {
                window.open(entity.get('link'), '_blank');
            }
        }
    });
});