define("freshfone-ember/components/message-components/attachment-component/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/message-components/attachment-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "class", "note");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["message_components.attachment_component.description"], [], ["loc", [null, [4, 25], [4, 84]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/message-components/attachment-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "message-components/audio-player", [], ["classNames", "btn btn-cancel", "label", ["subexpr", "@mut", [["get", "fileName", ["loc", [null, [8, 73], [8, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "message", ["subexpr", "@mut", [["get", "attachmentUrl", ["loc", [null, [8, 90], [8, 103]]], 0, 0, 0, 0]], [], [], 0, 0], "cancelAction", ["subexpr", "action", ["cancel"], [], ["loc", [null, [8, 117], [8, 134]]], 0, 0]], ["loc", [null, [8, 5], [9, 7]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 5
              },
              "end": {
                "line": 18,
                "column": 5
              }
            },
            "moduleName": "freshfone-ember/components/message-components/attachment-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("     ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "upload-attachment-btn");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["element", "action", ["triggerUpload"], [], ["loc", [null, [12, 40], [12, 66]]], 0, 0], ["inline", "t", ["message_components.attachment_component.upload"], [], ["loc", [null, [13, 6], [13, 60]]], 0, 0], ["inline", "custom-elements/file-input", [], ["file", ["subexpr", "@mut", [["get", "message.attachment", ["loc", [null, [15, 40], [15, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "accept", ["subexpr", "@mut", [["get", "acceptFileTypes", ["loc", [null, [15, 66], [15, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "isVisible", false, "classNames", "file-upload", "fileName", ["subexpr", "@mut", [["get", "fileName", ["loc", [null, [17, 16], [17, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "fileSize", ["subexpr", "@mut", [["get", "fileSize", ["loc", [null, [17, 34], [17, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "blobURL", ["subexpr", "@mut", [["get", "blobURL", ["loc", [null, [17, 51], [17, 58]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 6], [17, 61]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/message-components/attachment-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "text-field", [], ["customElem", true, "errors", ["subexpr", "@mut", [["get", "errors.attachment", ["loc", [null, [11, 42], [11, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "for", "attachment", "animate", false, "classNames", "attachment-btn-container", "rightAlign", true], 0, null, ["loc", [null, [11, 5], [18, 20]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 7
          }
        },
        "moduleName": "freshfone-ember/components/message-components/attachment-component/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "header");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["message-label-container ", ["subexpr", "if", [["get", "hasAttachment", ["loc", [null, [1, 41], [1, 54]]], 0, 0, 0, 0], "hasAttachment"], [], ["loc", [null, [1, 36], [1, 72]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["message_components.attachment_component.message"], [], ["loc", [null, [2, 25], [2, 80]]], 0, 0], ["block", "unless", [["get", "hasAttachment", ["loc", [null, [3, 12], [3, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [5, 13]]]], ["block", "if", [["get", "hasValidAttachment", ["loc", [null, [7, 6], [7, 24]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [7, 0], [19, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});