define('freshfone-ember/mixins/purchase-form.mixin', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    account: computed.alias('session.account'),
    currentUser: computed.alias('session.user'),
    checkIsUserOrgAdmin: function checkIsUserOrgAdmin() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        $.ajax({
          url: '/org_admin',
          type: 'GET',
          dataType: 'json',
          data: { id: _this.get('currentUser.id') }
        }).done(function (data) {
          resolve(data.response);
        }).fail(function (err) {
          reject(err);
        });
      });
    },
    isTrial: computed.alias('account.isTrial'),
    lowCredits: computed('rate', 'currentAccount', function () {
      return this.get('account.credit.availableCredit') < this.get('rate');
    }),

    disablePurchase: computed('disableBuying', 'purchaseDisabled', 'isCrm', function () {
      return this.get('isCrm') && this.get('disableBuying') || this.get('purchaseDisabled');
    }),

    crmOnboardingFeature: computed('isCrm', 'lowCredits', 'purchaseDisabled', 'user_org_admin', function () {
      var crmOnboardingFeature = this.hasGlobalFeature('crm_onboarding') && this.checkFeature('crm_onboarding');

      return crmOnboardingFeature && this.get('isCrm');
    }),

    showToolTip: computed.and('crmOnboardingFeature', 'disableBuying'),
    disableBuying: computed('lowCredits', 'purchaseDisabled', 'user_org_admin', function () {
      return this.get('lowCredits') && (!this.get('user_org_admin') || this.get('isTrial'));
    })
  });
});