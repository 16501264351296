define('freshfone-ember/components/business-calendar/calendar-entry/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/get-selected-days-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/utils/business-calendar-constants'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsGetSelectedDaysMixin, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberUtilsBusinessCalendarConstants) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var defaultStartTime = _freshfoneEmberUtilsBusinessCalendarConstants['default'].defaultStartTime;
    var defaultEndTime = _freshfoneEmberUtilsBusinessCalendarConstants['default'].defaultEndTime;
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var isEmpty = _ember['default'].isEmpty;
    exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsGetSelectedDaysMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
        validationError: null,
        workingDays: null,
        dropDownCount: 0,
        hoverEntry: computed('isEditable', 'allowEdit', function () {
            return !this.get('isEditable') && !this.get('allowEdit') && !this.get('isOmniBusinessHours');
        }),
        isEditable: computed.or('editMode', 'isDaysEmpty'),
        isDaysEmpty: computed.empty('hour.days'),
        validationErrorEmpty: computed.empty('validationError'),
        allowEdit: computed.or('editMode', 'businessCalendar.editEnabled'),
        hasMulitpleTimeSlots: computed.gt('hour.timeSlots.length', 1),
        disableAddSlots: computed.gt('hour.timeSlots.length', 19),
        keyPress: function keyPress(e) {
            var key = e.keyCode || e.which;
            if (key === 13) {
                var count = parseInt($(event.target).parents('.time-input').attr('data-index')) + 1;
                var nextElement = $('.start-end-time').find('[data-index=' + count + ']').find('.ember-power-select-selected-item')[0];
                if (nextElement) {
                    event = new window.Event('mousedown', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    });
                    nextElement.dispatchEvent(event);
                }
            }
        },
        workHourSlotHash: computed({
            get: function get() {
                return this;
            },
            set: function set(key, value) {
                var captureCleanHour = [];
                value.map(function (slot) {
                    var startTime = slot.startTime;
                    var endTime = slot.endTime;

                    captureCleanHour.push({ startTime: startTime, endTime: endTime });
                });
                return captureCleanHour;
            }
        }),
        displayDays: computed('hour.days', function () {
            var _this = this;

            var days = [];
            this.get('hour.days').sort().forEach(function (day) {
                days.push(_this.get('businessCalendar.daysInWeek').filterBy('value', day)[0].shortForm.string);
            });
            return days;
        }),
        init: function init() {
            this._super.apply(this, _toConsumableArray(this));
            this.set('workHourSlotHash', this.get('hour.timeSlots'));
        },
        setChanges: function setChanges(hour) {
            var filteredTime = this.getSelectedDays(this.get('businessCalendar.workingHours'), hour['timeSlots']);
            this.set('workingDays', filteredTime);
        },
        validateWorkingHours: function validateWorkingHours(hour) {
            var _this2 = this;

            var workingDays = this.get('businessCalendar.workingDays');
            var sortedDayObject = [];
            var errorFlag = undefined;

            (function () {
                for (var outerIndex = 0; outerIndex < workingDays.length; outerIndex++) {
                    var dayObject = workingDays[outerIndex];

                    if (dayObject['days'].length === 0) {
                        errorFlag = 1;
                        break;
                    } else if (dayObject['timeSlots'].length > 1) {
                        sortedDayObject = _this2.sortTimeSlots(dayObject);
                    } else if (dayObject['timeSlots'].length == 1) {
                        sortedDayObject = dayObject['timeSlots'];
                    }

                    errorFlag = _this2.setValidationMessage(sortedDayObject);
                }
            })();

            switch (errorFlag) {
                case 1:
                    this.set('validationError', _ember['default'].i18n.t('business_calendar.validation_errors.weekdays'));
                    break;

                case 2:
                    this.set('validationError', _ember['default'].i18n.t('business_calendar.validation_errors.working_hours'));
                    break;

                case 3:
                    this.set('validationError', _ember['default'].i18n.t('business_calendar.validation_errors.incorrectTimeSlot'));
                    break;
            }

            return errorFlag ? false : true;
        },

        setValidationMessage: function setValidationMessage(sortedDayObject) {
            var hasMultipleSlots = sortedDayObject.length > 1 ? true : false;

            for (var index = 0; index < sortedDayObject.length; index++) {
                var currentSlot = sortedDayObject[index];
                var currentSlotStartTime = this.extractHourAndMinute(currentSlot.startTime);
                var currentSlotEndTime = this.extractHourAndMinute(currentSlot.endTime);

                if (currentSlotEndTime <= currentSlotStartTime) {
                    return 2;
                } else if (hasMultipleSlots) {
                    var nextSlot = sortedDayObject[index + 1];
                    if (nextSlot) {
                        var nextSlotStartTime = this.extractHourAndMinute(nextSlot.startTime);
                        if (nextSlotStartTime < currentSlotEndTime) return 3;
                    }
                }
            }
            return;
        },

        sortTimeSlots: function sortTimeSlots(dayObject) {
            var _this3 = this;

            return dayObject['timeSlots'].sort(function (slotTwo, slotOne) {
                var slotOneStartTime = _this3.extractHourAndMinute(slotOne.startTime);
                var slottwoStartTime = _this3.extractHourAndMinute(slotTwo.startTime);
                return slottwoStartTime - slotOneStartTime;
            });
        },

        extractHourAndMinute: function extractHourAndMinute(time) {
            return Number(time.match(/[0-9]{2}/g).join(''));
        },
        deletedays: function deletedays(day) {
            day.deleteRecord();
            day.unloadRecord();
        },
        actions: {

            addTimeSlot: function addTimeSlot() {
                var timeSlot = { 'startTime': defaultStartTime, 'endTime': defaultEndTime };
                this.get('hour.timeSlots').pushObject(timeSlot);
            },

            editWorkingHour: function editWorkingHour(hour) {
                this.set('isEditable', true);
                this.set('businessCalendar.editEnabled', true);
                this.setChanges(hour);
            },
            deleteWorkingHour: function deleteWorkingHour(hour) {
                var _this4 = this;

                // #todo, instead of filtering and deleting the hours one bye one, to check if we can delete 'hour'
                var filteredTime = this.get('businessCalendar.workingHours').filterBy('startTime', hour.startTime).filterBy('endTime', hour.endTime);
                filteredTime.forEach(function (item) {
                    if (item) {
                        if (hour.days.includes(item.get('day'))) {
                            _this4.deletedays(item);
                            _this4.get('businessCalendar.enabledDays').removeObject(item.get('day'));
                        }
                    }
                });
                this.get('businessCalendar.workingDays').removeObject(hour);
            },
            cancelWorkingHour: function cancelWorkingHour(hour) {
                var _this5 = this;

                // #todo, to check if we can simply rollback the model
                var days = [];
                this.set('isEditable', false);
                this.set('businessCalendar.editEnabled', false);
                hour.days.forEach(function (day) {
                    _this5.get('businessCalendar.enabledDays').removeObject(day);
                });
                if (isEmpty(this.get('workingDays'))) {
                    var calendar = this.get('businessCalendar.workingHours').filterBy('isNew', true);
                    calendar.forEach(function (slot) {
                        if (!slot.get('day')) {
                            _this5.deletedays(slot);
                        }
                    });
                    return;
                }
                this.get('workingDays').forEach(function (item) {
                    var currentTimeSlot = JSON.stringify(item.get('timeSlots'));
                    var capturedTimeSlot = JSON.stringify(_this5.get('workHourSlotHash'));
                    if (currentTimeSlot !== capturedTimeSlot) {
                        item.set('timeSlots', _this5.get('workHourSlotHash'));
                    }
                    _this5.get('businessCalendar.enabledDays').push(item.get('day'));
                });
                this.set('hour.days', days);
                this.set('businessCalendar.customIsDirty', false);
            },
            confirmWorkingHour: function confirmWorkingHour(hour) {
                var _this6 = this;

                var timeSlots = hour.timeSlots;
                if (!this.validateWorkingHours(hour)) {
                    return;
                }
                this.set('validationError', null);
                this.set('isEditable', false);
                this.set('businessCalendar.editEnabled', false);
                this.get('businessCalendar.workingHours').filterBy('isDeleted', false).forEach(function (day) {
                    if (day && !_this6.get('businessCalendar.enabledDays').includes(day.get('day'))) {
                        _this6.deletedays(day);
                    }
                });
                hour.days.forEach(function (day) {
                    var params = { timeSlots: timeSlots, day: day };
                    var calendar = _this6.get('businessCalendar');
                    var workingDay = calendar.get('workingHours').filterBy('day', day)[0];
                    if (isEmpty(workingDay) || workingDay.get('isDeleted')) {
                        workingDay = _this6.store.createRecord('working-hour', {
                            'calendar': calendar
                        });
                    }
                    workingDay.setProperties(params);
                });
                this.set('businessCalendar.customIsDirty', true);
                this.set('workHourSlotHash', timeSlots);
            },

            removeTimeSlot: function removeTimeSlot(index) {
                this.get('hour.timeSlots').removeAt(index);
            }
        }
    });
});