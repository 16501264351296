define('freshfone-ember/routes/protected/admin', ['exports', 'freshfone-ember/routes/protected', 'ember'], function (exports, _freshfoneEmberRoutesProtected, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.Admin');
    }),
    breadCrumb: computed(function () {
      return {
        title: _ember['default'].i18n.t('navigation.Admin').string,
        externalLinkable: this.get('session.account.isUnifiedAppAccount'),
        externalPath: window.location.protocol + '//' + this.get('session.current.bundleInfo.domains.freshsales') + '/settings'
      };
    }),
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_admin');
    }
  });
});