define('freshfone-ember/constants/live-agents-dashboard', ['exports'], function (exports) {
  exports['default'] = Object.freeze({
    fromUser: 'from_user',
    queueSelected: 'selected_queues',
    statusId: 'browser_status_id',
    queryType: 'get_agents_status_v2',
    allAgents: 'All Agents',
    defaultStatuses: ['online', 'busy', 'acw', 'offline'],
    agentStatus: {
      online: 'Available',
      busy: 'Busy',
      acw: 'After Call Work',
      offline: 'Offline'
    },
    agentsCount: 'agent_status_counts',
    viewId: 'slm_view_id',
    agentsCountPollTimer: 3600000
  });
});