define('freshfone-ember/components/deflection-bot/deflection-bot-root/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/message-default-types'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersMessageDefaultTypes) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _ember['default'].Evented, _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    session: service(),

    nameEmpty: computed.empty('deflectionBot.name'),

    init: function init() {
      this._super.apply(this, arguments);
      this.createDefaultActions();
      this.getbotName();
    },

    didInsertElement: function didInsertElement() {
      if (this.get('nameEmpty')) {
        var deflectionBotRecord = this.store.peekAll('deflectionBot').content.length;
        this.set('deflectionBot.name', 'Smart Answer Flow ' + deflectionBotRecord);
      }
    },

    getbotName: function getbotName() {
      var _this = this;

      /* As of now backend send only one bot name */
      _ember['default'].$.ajax({
        url: '/deflection_bots/bot_list',
        type: 'GET',
        dataType: 'json'
      }).then(function (data) {
        _this.set('botName', data[0].name);
      });
    },
    createDefaultActions: function createDefaultActions() {
      var _this2 = this;

      var deflectionBotId = this.get('deflectionBot.id'),
          getDefaultMessages = this.get('store').query('message', {
        defaultMessageType: [_freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].invalidInputMessage],
        paginate: false
      });

      if (!deflectionBotId) {
        getDefaultMessages.then(function () {
          _this2.setDefaultMsg();
        });
      } else {
        this.checkInvalidInputMessage();
      }
      this.get('deflectionBot.content').saveOriginalRelations();
    },

    checkInvalidInputMessage: function checkInvalidInputMessage() {
      if (!this.get('deflectionBot.invalidInputMessage.id')) {
        var messages = this.store.peekAll('message');

        this.set('deflectionBot.invalidInputMessage', messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].invalidInputMessage)[0]);
      }
    },

    setDefaultMsg: function setDefaultMsg() {
      var deflectionBot = this.get('deflectionBot'),
          messages = this.store.peekAll('message'),
          hangup = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage)[0],
          voicemail = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage)[0],
          welcome = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage)[0],
          invalidInput = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].invalidInputMessage)[0];

      deflectionBot.setProperties({
        message: welcome,
        invalidInputMessage: invalidInput,
        conversationEndActionId: voicemail.id,
        agentDeflectActionId: voicemail.id,
        botFailureActionId: hangup.id,
        invalidInputActionId: hangup.id
      });
    },

    actions: {
      showNewMessage: function showNewMessage(messageFor) {
        var defaultTitle = this.store.peekAll('message').content.length + 1,
            newObject = this.store.createRecord('message', { name: _ember['default'].i18n.t('message_components.record_component.message') + ' ' + defaultTitle });

        this.set('showMessage', true);
        this.set('messageHolder', newObject);
        this.set('messageFor', messageFor);
      },
      closeMessage: function closeMessage(message) {
        if (!message) {
          // closed without saving
          this.get('messageHolder').unloadRecord();
          return;
        }

        this.get('deflectionBot').set(this.get('messageFor'), this.get('messageHolder'));
        this.set('messageFor', null);
      }
    }
  });
});