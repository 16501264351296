define('freshfone-ember/components/number-component/numbers-list/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var empty = _Ember$computed.empty;
  var not = _Ember$computed.not;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    tagName: '',
    BYOC: 'BYOC',
    noNumbers: empty('model'),
    session: service(),
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    hasCallMaskingFeature: computed(function () {
      return this.checkFeature('call_masking');
    }),
    showDropdown: computed.and('hasWhitelistFeature', 'hasCallMaskingFeature'),
    hasNoNumberPurchased: not('hasFreshfoneNumber'),
    hasFreshfoneNumber: computed('findallFreshfoneNumbers.[]', function () {
      return this.get('findallFreshfoneNumbers.length') > 0;
    }),
    findallFreshfoneNumbers: computed(function () {
      return this.store.findAll('number');
    }),
    hasWhitelistFeature: computed('session.user', function () {
      return this.hasGlobalFeature('whitelist_numbers') && (this.get('currentUser.isAdmin') || this.get('currentUser.isAccountAdmin'));
    }),

    actions: {
      manageCallerIds: function manageCallerIds() {
        this.get('manageCallerIds')();
      },
      closeCountriesModal: function closeCountriesModal() {
        this.set('showWhitelistCountries', false);
      },
      toggleCountriesList: function toggleCountriesList() {
        this.toggleProperty('showWhitelistCountries');
      },
      edit: function edit(item) {
        this.get('edit')(item);
      },
      toggleDelete: function toggleDelete(item) {
        this.get('toggleDelete')(item);
      },
      afterDelete: function afterDelete(item) {
        this.get('afterDelete')(item);
      },
      buy: function buy() {
        this.get('buy')();
      }
    }
  });
});