define('freshfone-ember/models/plan', ['exports', 'ember-data', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _emberData, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),
    name: attr(),
    trialDays: attr(),
    freeQuantity: attr(),
    price: attr(),
    free: attr(),
    'default': attr(),
    classic: attr(),
    features: attr(),
    maxQuantity: attr(),
    totalFeatures: attr(),
    year: attr(),

    planFeatures: computed('plan.name', function () {
      var namespace = '';

      if (this.hasGlobalFeature('credit_sku_split_global') && this.checkFeature('credit_sku_split')) {
        namespace = 'sku_changes.';
      }

      return this.get('i18n').t('subscription.' + namespace + this.get('name') + '.plan_features').string.split(',');
    })
  });
});