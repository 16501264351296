define('freshfone-ember/models/survey-question', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    primary: attr(),
    question: attr(),
    questionType: attr(),
    surveyQuestionOptions: hasMany('survey-question-option'),
    survey: belongsTo('survey')
  });
});