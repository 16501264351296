define('freshfone-ember/models/external-contact', ['exports', 'ember-data', 'ember', 'ember-validations', 'ember-data/model'], function (exports, _emberData, _ember, _emberValidations, _emberDataModel) {
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;
  exports['default'] = _emberDataModel['default'].extend(_emberValidations['default'], {
    name: attr(),
    phone_number: attr(),
    mobile: attr(),
    email: attr(),
    avatar: attr(),
    type: attr(),
    companyName: attr(),
    phoneNumberOrMobile: computed.or('phone_number', 'mobile'),
    link: attr(),
    mcrId: attr()
  });
});
/* eslint-disable camelcase */