define('freshfone-ember/components/number-component/number-assignees/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var equal = _Ember$computed.equal;
  var alias = _Ember$computed.alias;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    tagName: '',
    session: service(),
    currentAccount: alias('session.account'),
    planName: alias('currentAccount.plan_name'),
    onSproutPlan: equal('planName', 'Sprout'),
    agents: computed(function () {
      return this.store.query('user', { paginate: false });
    }),
    teams: computed(function () {
      return this.store.query('team', { paginate: false });
    }),
    assignees: computed('onSproutPlan', function () {
      var assignees = [this.get('agents')];

      if (!this.get('onSproutPlan')) {
        assignees.push(this.get('teams'));
      }

      return assignees;
    }),
    agentsAndTeams: computed('assignees', function () {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _ember['default'].RSVP.Promise.all(_this.get('assignees')).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2);

          var users = _ref2[0];
          var teams = _ref2[1];

          var response = users.filterBy('deleted', false).toArray();

          if (!_this.get('onSproutPlan')) {
            response = response.concat(teams.filter(function (team) {
              return team.get('id');
            }).toArray());
          }
          resolve(response);
        });
      });
    })
  });
});