define('freshfone-ember/components/call-logs/transcription-modal/component', ['exports', 'ember'], function (exports, _ember) {
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var empty = _Ember$computed.empty;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    transcriptionContent: alias('transcript.text'),
    fetchingTranscription: empty('transcript.text'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var transcriptionID = this.get('transcriptionID');

      if (transcriptionID) {
        this.fetchTranscription(transcriptionID);
      }
    },
    fetchTranscription: function fetchTranscription(transcriptionID) {
      var transcription = this.store.peekRecord('transcription', transcriptionID) || this.store.findRecord('transcription', transcriptionID);

      this.set('transcript', transcription);
    },
    actions: {
      closeCallBack: function closeCallBack() {
        this.sendAction('onClose');
      }
    }
  });
});