define('freshfone-ember/services/translator', ['exports', 'ember', 'ember-ajax/request', 'freshfone-ember/config/environment', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/lang-urls'], function (exports, _ember, _emberAjaxRequest, _freshfoneEmberConfigEnvironment, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsLangUrls) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Service = _ember['default'].Service;
  var service = _ember['default'].inject.service;
  var merge = _ember['default'].merge;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var copy = _ember['default'].copy;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = Service.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),

    /**
     * This function is used to fetch the locale assets using Ember.ajax.request
     * If the userLocale is en only one request is sent for the en.json otherwise
     * Two requests, one for en and other for the userLocale respectively for fallback capabilities.
     * @public
     * @param {} userLocale
     * @returns {}
     */

    fetch: function fetch(userLocale) {
      set(this, 'userLocale', userLocale);
      var enLocalePromise = (0, _emberAjaxRequest['default'])(_freshfoneEmberConstantsLangUrls['default']['en']);
      if (userLocale === 'en') {
        return enLocalePromise.then(this._addEnTranslations.bind(this));
      } else {
        if (userLocale === 'test') {
          return enLocalePromise.then(this._addTestUITranslations.bind(this));
        } else {
          var userLocalePromise = (0, _emberAjaxRequest['default'])(_freshfoneEmberConstantsLangUrls['default'][userLocale]);
          return Promise.all([enLocalePromise, userLocalePromise]).then(this._mergeTranslations.bind(this));
        }
      }
    },

    _getDomainUrlPath: function _getDomainUrlPath(emberGenPath) {
      return '/' + emberGenPath.substring(_freshfoneEmberConfigEnvironment['default'].OPTIONS.fingerprint.prepend.length);
    },

    /**
     * This is where we add the translations to the i18n object based on the locale
     * @private
     * @param {} locale
     * @param {} translations
     */
    _addTranslations: function _addTranslations(locale, translations) {
      var i18n = get(this, 'i18n');

      i18n.addTranslations(locale, translations);
    },

    /**
     * This is just for adding en translations - Default Language set as en
     * @private
     * @param {} json
     */
    _addEnTranslations: function _addEnTranslations(json) {
      this._addTranslations('en', json);
    },

    _addTestUITranslations: function _addTestUITranslations(_translations) {
      var testUITranslation = {};
      Object.keys(_translations).forEach(function (string) {
        var additionalString = 'This string is to test ui breakage';
        var Stringlength = _translations[string].length;
        var newLength = Math.round(Stringlength + Stringlength * 0.4);
        var additionalStringLength = additionalString.length;
        var newI18nstring = _translations[string];
        var i = 0;

        while (Stringlength < newLength) {
          newI18nstring = newI18nstring + additionalString[i];
          Stringlength++;
          i++;
          if (i >= additionalStringLength) {
            i = 0;
          }
        }
        testUITranslation[string] = newI18nstring;
      });

      this._addTranslations('test-ui', testUITranslation);
    },

    /**
     * Here we will merge the other language keys with en keys for fallback
     * Hence if a particular item is not translated it will fallback to English
     * @private
     * @param {} jsonArray
     */
    _mergeTranslations: function _mergeTranslations(jsonArray) {
      var _userLanguage = get(this, 'userLocale');

      var _jsonArray = _slicedToArray(jsonArray, 2);

      var en = _jsonArray[0];
      var userLocale = _jsonArray[1];

      var _translations = merge(copy(en), userLocale);
      this._addTranslations(_userLanguage, _translations);
    }
  });
});
/**
 * @fileOverview
 * @public
 * TranslationsFetcherService is used to load the translations at runtime.
 * It assumes you have a locale asset as json, GET /a/assets/locale-json/<locale>.json

 * @name translations-fetcher.js
 * @author Rajasegar Chandran
 */