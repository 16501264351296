define('freshfone-ember/mixins/errorfield-focus-tabs', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      errorFieldFocus: function errorFieldFocus() {
        var _self = this,
            tab,
            $firstErrorField;

        _ember['default'].run.next(function () {
          // To run after the form validation has been executed.
          $firstErrorField = _self.$('.has-error:eq(0)');
          tab = $firstErrorField.closest('.tab-pane').attr('id');

          _self.$('a[href="#' + tab + '"]').tab('show');

          $firstErrorField.find('.form-control').focus();
        });
      }
    }
  });
});