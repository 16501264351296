define('freshfone-ember/components/custom-elements/star-rating/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    starRatings: computed('starCount', function () {
      var count = this.get('starCount'),
          ratings = [];

      for (var i = count; i > 0; i--) {
        ratings.push(String(i));
      }

      return ratings;
    }),
    actions: {
      setRating: function setRating() {
        this.set('rating', event.target.dataset.rating);
      }
    }
  });
});