define('freshfone-ember/components/billing-base/credit-base/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var testing = _ember['default'].testing;
  exports['default'] = Component.extend({
    isNotChangedMode: computed.not('isChangeMode'),
    testing: testing,
    canShowCard: computed.and('isNotChangedMode', 'subscription.hasSavedCard'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('isChangeMode', false);
    },
    actions: {
      unEdit: function unEdit() {
        this.set('isChangeMode', false);
      },
      onSalesTaxInfoCaptureFormToggle: function onSalesTaxInfoCaptureFormToggle() {
        this.toggleProperty('isSalesTaxInfoCaptureFormOpen');
      }
    }
  });
});