define('freshfone-ember/components/call-quality-component/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.call-quality-container').tooltip({
        selector: '[data-toggle="tooltip"]',
        container: '.call-quality-container'
      });
    },
    rating: 0,

    placeholder: computed(function () {
      return _ember['default'].i18n.t('call_quality_rating.reason_placeholder');
    }),
    reasons: computed(function () {
      return {
        0: _ember['default'].i18n.t('call_quality_rating.reasons.0'),
        1: _ember['default'].i18n.t('call_quality_rating.reasons.1'),
        2: _ember['default'].i18n.t('call_quality_rating.reasons.2')
      };
    }),
    ratingTexts: computed(function () {
      return _ember['default'].i18n.t('call_quality_rating.rating_texts').string.split('|').map(function (ratingText) {
        return ratingText.trim();
      });
    }),
    ratingText: computed('rating', function () {
      return this.get('ratingTexts')[this.get('rating')];
    }),
    isValidQualityRating: computed('rating', 'selectedReason', function () {
      return this.get('rating') > 3 || this.get('selectedReason');
    }),
    submitDisabled: computed('showError', 'rating', function () {
      return this.get('rating') <= 3 && this.get('showError');
    }),

    actions: {
      setReason: function setReason(reasonId) {
        this.set('selectedReason', reasonId);
        this.set('showError', false);
      },
      submitCallRating: function submitCallRating(reasonId) {
        this.set('selectedReason', reasonId);
        this.set('showError', false);

        if (this.get('isValidQualityRating')) {
          this.get('submitCallRating')(this.get('rating'), reasonId);
        } else {
          this.set('showError', true);
        }
      },
      close: function close() {
        this.get('closeModule')();
      }
    }
  });
});