define("freshfone-ember/routes/protected/power-dialer", ["exports", "ember", "freshfone-ember/mixins/feature-check-mixin", "freshfone-ember/routes/promotable"], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberRoutesPromotable) {
  var computed = _ember["default"].computed;
  exports["default"] = _freshfoneEmberRoutesPromotable["default"].extend(_freshfoneEmberMixinsFeatureCheckMixin["default"], {
    account: computed.alias('session.account'),
    breadCrumb: computed(function () {
      return { title: _ember["default"].i18n.t("navigation.power_dialer") };
    }),
    titleToken: computed(function () {
      return _ember["default"].i18n.t("navigation.power_dialer");
    }),
    //showPowerDialer - account is not shared or the account is shared but the user is standalone user or the power_dialer_for_shared_user flag is enabled
    showPowerDialer: computed(function () {
      return !this.get('session.account.isSharedBundleAccount') || this.get('session.user.isStandAloneUser') || this.hasGlobalFeature('power_dialer_for_shared_user');
    }),

    beforeModel: function beforeModel() {
      if (this.get('showPowerDialer')) {
        this.checkPrivilege('manage_power_dialer');

        return this.hasGlobalFeature('global_power_dialer') && this.get('account').hasFeature('power_dialer');
      }

      this.transitionTo('protected.error-page');
    },

    getPowerDialerList: function getPowerDialerList() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.store.query("power-dialer-list", {}).then(function (contacts) {
          return resolve({
            powerDialerContacts: contacts.toArray(),
            totalContactsCount: contacts.meta["total-count"],
            refreshModel: function refreshModel() {
              return _this.refresh();
            }
          });
        })["catch"](function (err) {
          return reject(err);
        });
      });
    },
    model: function model() {
      return this._super([], this.get('account').hasFeature('power_dialer') ? this.getPowerDialerList() : [], 'power_dialer');
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */