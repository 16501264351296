define("freshfone-ember/components/power-dialer/contact/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/power-dialer/contact/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "btn-maximise");
          dom.setAttribute(el1, "target", "_blank");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "icon-maximise curser-pointer");
          dom.setAttribute(el2, "data-toggle", "tooltip");
          dom.setAttribute(el2, "data-placement", "bottom");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [5]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'href');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[3] = dom.createAttrMorph(element1, 'data-original-title');
          return morphs;
        },
        statements: [["attribute", "href", ["subexpr", "concat", [["get", "omniContactRedirectionUrl", ["loc", [null, [10, 42], [10, 67]]], 0, 0, 0, 0], ["get", "contact.id", ["loc", [null, [10, 68], [10, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [10, 80]]], 0, 0], 0, 0, 0, 0], ["inline", "custom-elements/avatar", [], ["type", "user", "model", ["subexpr", "@mut", [["get", "contact", ["loc", [null, [11, 49], [11, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [11, 6], [11, 68]]], 0, 0], ["content", "contact.name", ["loc", [null, [12, 12], [12, 28]]], 0, 0, 0, 0], ["attribute", "data-original-title", ["subexpr", "t", ["power_dialer.view_in_desk"], [], ["loc", [null, [null, null], [13, 145]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 4
              },
              "end": {
                "line": 19,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/power-dialer/contact/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            return morphs;
          },
          statements: [["inline", "custom-elements/avatar", [], ["type", "user", "model", ["subexpr", "@mut", [["get", "contact", ["loc", [null, [17, 49], [17, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [17, 6], [17, 68]]], 0, 0], ["content", "contact.name", ["loc", [null, [18, 12], [18, 28]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 20,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/power-dialer/contact/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["protected.contacts.show", ["get", "contact", ["loc", [null, [16, 41], [16, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [16, 4], [19, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/power-dialer/contact/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "select");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "contact-name");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "contact-phone");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "contact-email");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "contact-queued-on");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "pull-right col-icons");
        var el2 = dom.createTextNode("\n   ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "data-toggle", "tooltip");
        dom.setAttribute(el2, "data-placement", "top");
        dom.setAttribute(el2, "data-analytics-action-name", "Delete Powerdialer Contact");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [10, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [6]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [8]), 0, 0);
        morphs[5] = dom.createAttrMorph(element2, 'class');
        morphs[6] = dom.createAttrMorph(element2, 'title');
        morphs[7] = dom.createElementMorph(element2);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "isChecked", ["loc", [null, [4, 12], [4, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "change", ["subexpr", "action", ["onChange"], [], ["loc", [null, [5, 11], [5, 30]]], 0, 0]], ["loc", [null, [2, 2], [6, 4]]], 0, 0], ["block", "if", [["get", "isUcrEnabledOmniFlow", ["loc", [null, [9, 8], [9, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [9, 2], [20, 9]]]], ["content", "contact.contactNumber", ["loc", [null, [22, 27], [22, 52]]], 0, 0, 0, 0], ["content", "contact.email", ["loc", [null, [23, 27], [23, 44]]], 0, 0, 0, 0], ["inline", "moment-from-now", [["get", "powerDialerContact.createdAt", ["loc", [null, [24, 49], [24, 77]]], 0, 0, 0, 0]], ["interval", ["subexpr", "@mut", [["get", "momentInterval", ["loc", [null, [24, 87], [24, 101]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [24, 31], [24, 103]]], 0, 0], ["attribute", "class", ["concat", ["icon-delete ", ["subexpr", "if", [["subexpr", "or", [["get", "isAllContactsSelected", ["loc", [null, [27, 33], [27, 54]]], 0, 0, 0, 0], ["get", "selectedContacts.length", ["loc", [null, [27, 55], [27, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 29], [27, 79]]], 0, 0], " v-hidden"], [], ["loc", [null, [27, 24], [27, 93]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "t", ["common.delete"], [], ["loc", [null, [null, null], [30, 32]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["onDelete"], [], ["loc", [null, [26, 6], [26, 27]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});