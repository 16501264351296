define("freshfone-ember/components/billing-base/credit-actions/purchase-info/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/purchase-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "title-text");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element4, 'title');
          morphs[1] = dom.createMorphAt(element4, 0, 0);
          return morphs;
        },
        statements: [["attribute", "title", ["subexpr", "t", ["billing.purchase_type_header.crm_agents"], ["crm", ["get", "crmPurchaseType", ["loc", [null, [4, 87], [4, 102]]], 0, 0, 0, 0]], ["loc", [null, [null, null], [4, 104]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["billing.purchase_type_header.crm_agents"], ["crm", ["subexpr", "@mut", [["get", "crmPurchaseType", ["loc", [null, [4, 155], [4, 170]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 105], [4, 172]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/purchase-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "title-text");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element3, 'title');
          morphs[1] = dom.createMorphAt(element3, 0, 0);
          return morphs;
        },
        statements: [["attribute", "title", ["subexpr", "t", [["subexpr", "concat", ["billing.purchase_type_header.", ["get", "purchaseType", ["loc", [null, [6, 81], [6, 93]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 41], [6, 94]]], 0, 0]], ["productName", ["get", "currentAccount.productName", ["loc", [null, [6, 107], [6, 133]]], 0, 0, 0, 0]], ["loc", [null, [null, null], [6, 135]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat", ["billing.purchase_type_header.", ["get", "purchaseType", ["loc", [null, [6, 180], [6, 192]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 140], [6, 193]]], 0, 0]], ["productName", ["subexpr", "@mut", [["get", "currentAccount.productName", ["loc", [null, [6, 206], [6, 232]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 136], [6, 234]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 10,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/purchase-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "icon-help");
          dom.setAttribute(el1, "data-toggle", "tooltip");
          dom.setAttribute(el1, "data-container", "body");
          dom.setAttribute(el1, "data-placement", "top");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element2, 'data-original-title');
          return morphs;
        },
        statements: [["attribute", "data-original-title", ["subexpr", "t", ["billing.credits.phoneCreditsNeeded"], [], ["loc", [null, [null, null], [9, 157]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 6
              },
              "end": {
                "line": 17,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/billing-base/credit-actions/purchase-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "credits-worth-usd");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["billing.credits.credit_worth_usd"], ["credit", ["subexpr", "@mut", [["get", "availableCreditsUSD", ["loc", [null, [16, 86], [16, 105]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 40], [16, 107]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/purchase-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "credits");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 0, 0);
          morphs[1] = dom.createMorphAt(element1, 2, 2);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "currencySymbol", ["loc", [null, [14, 28], [14, 46]]], 0, 0, 0, 0], ["content", "availableCredits", ["loc", [null, [14, 47], [14, 67]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "eq", [["get", "purchaseType", ["loc", [null, [15, 21], [15, 33]]], 0, 0, 0, 0], "credits"], [], ["loc", [null, [15, 17], [15, 44]]], 0, 0], ["subexpr", "not", [["get", "isDefaultCurrency", ["loc", [null, [15, 50], [15, 67]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 45], [15, 68]]], 0, 0]], [], ["loc", [null, [15, 12], [15, 69]]], 0, 0]], [], 0, null, ["loc", [null, [15, 6], [17, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 20,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/purchase-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "agent-license-count");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "numberOfAgents", ["loc", [null, [19, 40], [19, 58]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 2
            },
            "end": {
              "line": 26,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/purchase-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["purchase-modal-trigger font-bold ellipsis ", ["subexpr", "if", [["get", "isAccountBlocked", ["loc", [null, [23, 63], [23, 79]]], 0, 0, 0, 0], "not-allowed"], [], ["loc", [null, [23, 58], [23, 95]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleAddCardPurchase"], [], ["loc", [null, [23, 97], [23, 131]]], 0, 0], ["inline", "if", [["subexpr", "eq", [["get", "purchaseType", ["loc", [null, [24, 15], [24, 27]]], 0, 0, 0, 0], "credits"], [], ["loc", [null, [24, 11], [24, 38]]], 0, 0], ["subexpr", "t", ["subscription.add_credit"], [], ["loc", [null, [24, 39], [24, 68]]], 0, 0], ["subexpr", "t", ["billing.agent_license.buy_license"], [], ["loc", [null, [24, 69], [24, 108]]], 0, 0]], [], ["loc", [null, [24, 6], [24, 110]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/billing-base/credit-actions/purchase-info/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "title ellipsis");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "credits-value-wrapper");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createMorphAt(element6, 1, 1);
        morphs[2] = dom.createMorphAt(element6, 2, 2);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
        morphs[4] = dom.createMorphAt(element5, 5, 5);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["purchase-wrapper ", ["subexpr", "if", [["subexpr", "eq", [["get", "purchaseType", ["loc", [null, [1, 38], [1, 50]]], 0, 0, 0, 0], "credits"], [], ["loc", [null, [1, 34], [1, 61]]], 0, 0], "credits-wrapper"], [], ["loc", [null, [1, 29], [1, 81]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "purchaseType", ["loc", [null, [3, 14], [3, 26]]], 0, 0, 0, 0], "crm_agents"], [], ["loc", [null, [3, 10], [3, 40]]], 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [7, 11]]]], ["block", "if", [["subexpr", "and", [["subexpr", "eq", [["get", "purchaseType", ["loc", [null, [8, 19], [8, 31]]], 0, 0, 0, 0], "credits"], [], ["loc", [null, [8, 15], [8, 42]]], 0, 0], ["subexpr", "not", [["get", "hasSavedCard", ["loc", [null, [8, 48], [8, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 43], [8, 61]]], 0, 0]], [], ["loc", [null, [8, 10], [8, 62]]], 0, 0]], [], 2, null, ["loc", [null, [8, 4], [10, 11]]]], ["block", "if", [["subexpr", "eq", [["get", "purchaseType", ["loc", [null, [13, 14], [13, 26]]], 0, 0, 0, 0], "credits"], [], ["loc", [null, [13, 10], [13, 37]]], 0, 0]], [], 3, 4, ["loc", [null, [13, 4], [20, 11]]]], ["block", "unless", [["get", "noAction", ["loc", [null, [22, 12], [22, 20]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [22, 2], [26, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});