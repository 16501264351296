define('freshfone-ember/components/autofaq-component/component', ['exports', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!(this.get('session.account.isBundleAccount') || this.checkFeature('frontend_beta'))) {
        (function () {
          var iframe = _this.element.querySelector('iframe');

          iframe.addEventListener('load', function () {
            iframe.contentWindow.postMessage({
              type: 'old_color_scheme'
            }, '*');
          });
        })();
      }
    },

    autofaqURL: computed(function () {
      if (_freshfoneEmberConfigEnvironment['default'].environment === 'development') {
        return _freshfoneEmberConfigEnvironment['default'].autoFaqURL;
      }

      return window.location.protocol + '//' + this.get('session.account.full_domain') + '/autofaq_page/';
    })
  });
});