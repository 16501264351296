define('freshfone-ember/components/plan-modal/agent-license-form/component', ['exports', 'ember-concurrency', 'ember', 'freshfone-ember/constants/subscription-constants'], function (exports, _emberConcurrency, _ember, _freshfoneEmberConstantsSubscriptionConstants) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('billingCycle', _freshfoneEmberConstantsSubscriptionConstants.billingCycles[this.get('subscription.renewalPeriod')]);
      this.setPerAgentCost();
      this.set('agentLicenseCost', {
        total: 0,
        payable: 0
      });
    },
    currency: computed.reads('subscription.content.currency'),
    addonName: computed('currency', function () {
      return this.get('currency') === 'USD' ? 'Support360 Standalone User' : 'Support360 Standalone User ' + this.get('currency').toLowerCase().capitalize();
    }),
    planEntityId: computed('currency', function () {
      return this.get('currency') === 'USD' ? 'support360_standalone_user' : 'support360_standalone_user_' + this.get('currency').toLowerCase();
    }),
    addonUsersLimit: computed.readOnly('session.account.addonUsersLimit'),

    setPerAgentCost: function setPerAgentCost() {
      var _this = this;

      var subscription = this.get('subscription.content');

      var params = {
        addon_id: this.get('addonName'),
        addon_quantity: 1,
        plan_id: subscription.get('planId'),
        renewal_period: subscription.get('renewalPeriod'),
        quantity: 1
      };

      subscription.planEstimate(params).then(function (response) {
        var planEstimate = response.estimate ? response.estimate.invoice_estimate || response.estimate.next_invoice_estimate : null;

        var addonAgentCostItem = planEstimate.values.line_items.find(function (lineItem) {
          return lineItem.entity_id === _this.get('planEntityId');
        });

        if (addonAgentCostItem) {
          _this.set('perAgentCost', (addonAgentCostItem.unit_amount / subscription.get('renewalPeriod') / 100).toFixed(2));
        } else {
          _this.set('perAgentCost', 0);
        }
      })['catch'](function (error) {
        return console.log('error fetching perAgentCost', error);
      });
    },
    fetchLicenseCost: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var numberOfAgents, subscription, params, totalCost, payableCost, planEstimateData, planEstimate, invoiceEstimateData, invoiceEstimate;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            numberOfAgents = Number(this.get('numberOfAgents')), subscription = this.get('subscription.content');

            if (!(isEmpty(this.get('numberOfAgents')) || isNaN(numberOfAgents) || numberOfAgents < 0 || numberOfAgents === this.get('addonUsersLimit'))) {
              context$1$0.next = 4;
              break;
            }

            this.showAgentLicenseDowngrade(false);

            return context$1$0.abrupt('return', this.set('agentLicenseCost', {
              total: 0,
              payable: 0
            }));

          case 4:
            context$1$0.next = 6;
            return (0, _emberConcurrency.timeout)(250);

          case 6:
            if (!(numberOfAgents < this.get('addonUsersLimit'))) {
              context$1$0.next = 9;
              break;
            }

            this.showAgentLicenseDowngrade(true);

            return context$1$0.abrupt('return', this.set('agentLicenseCost', {
              total: 0,
              payable: 0
            }));

          case 9:

            this.showAgentLicenseDowngrade(false);

            params = {
              addon_id: this.get('addonName'),
              addon_quantity: numberOfAgents,
              plan_id: subscription.get('planId'),
              renewal_period: subscription.get('renewalPeriod'),
              quantity: numberOfAgents
            };
            totalCost = undefined, payableCost = undefined;
            context$1$0.prev = 12;
            context$1$0.next = 15;
            return subscription.planEstimate(params);

          case 15:
            planEstimateData = context$1$0.sent;
            planEstimate = planEstimateData.estimate ? planEstimateData.estimate.invoice_estimate || planEstimateData.estimate.next_invoice_estimate : null;

            if (planEstimate) {
              totalCost = planEstimate.values.total / 100;
            } else {
              totalCost = 0;
            }

            context$1$0.next = 20;
            return subscription.invoiceEstimate(params);

          case 20:
            invoiceEstimateData = context$1$0.sent;
            invoiceEstimate = invoiceEstimateData.estimate ? invoiceEstimateData.estimate.invoice_estimate || invoiceEstimateData.estimate.next_invoice_estimate : null;

            if (isEmpty(invoiceEstimateData.estimate)) {
              payableCost = 0;
            } else if (invoiceEstimate) {
              payableCost = invoiceEstimate.values.amount_due / 100;
            } else {
              payableCost = totalCost;
            }
            context$1$0.next = 30;
            break;

          case 25:
            context$1$0.prev = 25;
            context$1$0.t0 = context$1$0['catch'](12);

            // eslint-disable-next-line no-console
            console.log('error fetching estimate', context$1$0.t0);
            totalCost = 0;
            payableCost = 0;

          case 30:

            this.set('agentLicenseCost', {
              total: totalCost,
              payable: payableCost
            });

          case 31:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[12, 25]]);
    })).restartable()
  });
});
/* eslint-disable sort-vars */
/* eslint-disable one-var */

// for input debounce