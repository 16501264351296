define('freshfone-ember/components/contact-items/component', ['exports', 'ember-concurrency', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'ember-component-inbound-actions/inbound-actions'], function (exports, _emberConcurrency, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _emberComponentInboundActionsInboundActions) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _emberComponentInboundActionsInboundActions['default'], {
    currentAccount: computed.alias('session.account'),
    items: computed('currentAccount.omnichannelOwnerDomain', function () {
      return [{
        title: 'contact',
        link: 'protected.contacts',
        dataAnalyticsActionName: 'Contacts Page Load',
        iconUrl: 'assets/images/icons/admin-numbers.svg',
        redirectUrl: this.hasOmniRedirectFeatureEnabled() ? this.get('currentAccount.omnichannelOwnerDomain') + '/a/contacts' : null
      }, {
        title: 'companies',
        link: 'protected.companies',
        dataAnalyticsActionName: 'Companies Page Load',
        iconUrl: 'assets/images/icons/admin-numbers.svg',
        redirectUrl: this.hasOmniRedirectFeatureEnabled() ? this.get('currentAccount.omnichannelOwnerDomain') + '/a/companies' : null
      }];
    })
  });
});