define('freshfone-ember/serializers/contact', ['exports', 'ember-data', 'freshfone-ember/serializers/application'], function (exports, _emberData, _freshfoneEmberSerializersApplication) {
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend({
    attrs: {
      contactNumbers: { serialize: true }
    },
    serialize: function serialize(snapshot, options) {
      var _this = this;

      var json = this._super(snapshot, options);
      json.data.relationships = json.data.relationships || {};
      json.data.relationships.address = {
        data: {
          id: null,
          type: 'address',
          attributes: {
            address1: json.data.attributes.address
          }
        }
      };

      json.data['contact_numbers_attributes'] = [];

      (snapshot._hasManyRelationships.contactNumbers || []).forEach(function (contactNumberSnapshot) {
        var contactNumber = _this._super(contactNumberSnapshot, options);
        var contactNumberFormat = {
          id: contactNumber.data.id,
          phone_number: contactNumber.data.attributes['phone-number']
        };
        if (contactNumber.data.attributes['phone-number']) {
          json.data['contact_numbers_attributes'].push(contactNumberFormat);
        }
      });

      delete json.data.relationships['contact-numbers'];
      delete json.data.attributes.address;
      delete json.data.attributes['last-call-at'];
      delete json.data.attributes['total-calls'];
      delete json.data.attributes['contact-number'];
      return json;
    }

  });
});