define('freshfone-ember/components/custom-elements/new-queue-option/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.element.addEventListener('mousedown', function (e) {
        e.stopPropagation(); // this prevents the open event
        return false;
      });
    }
  });
});