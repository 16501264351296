define('freshfone-ember/components/mkp-gallery/component', ['exports', 'ember', 'freshfone-ember/config/environment', 'freshfone-ember/constants/mkp-config'], function (exports, _ember, _freshfoneEmberConfigEnvironment, _freshfoneEmberConstantsMkpConfig) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var galleryUrlPattern = _freshfoneEmberConfigEnvironment['default'].Freshparent.galleryUrlPattern;
  var galleryEventsApiEndpoint = _freshfoneEmberConstantsMkpConfig['default'].galleryEventsApiEndpoint;
  var allowedMkpApps = _freshfoneEmberConstantsMkpConfig['default'].allowedMkpApps;
  exports['default'] = Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var adapter = {
        eventHandler: function eventHandler(eventData) {
          var appExtension = eventData.extension_display_name,
              data = {
            type: 'marketplace-gallery',
            attributes: eventData
          };

          if (allowedMkpApps.includes(appExtension)) {
            $.ajax({
              url: galleryEventsApiEndpoint,
              type: 'POST',
              dataType: 'json',
              data: JSON.stringify({ data: data }),
              contentType: 'application/json'
            }).then(function () {
              // eslint-disable-next-line no-console
              console.log('Gallery Event sent to backend for app ', appExtension);
            });
          }
        },
        iframeId: 'mkp-gallery',
        type: 'galleryEventExchange'
      };

      this.set('mkpManager', new MarketplaceManager(adapter));
    },
    mkpGallerySrc: computed({
      get: function get() {
        var model = this.get('model');

        return galleryUrlPattern.replace(/{tenant}|{account}|{pod}|{params}/g, function (match) {
          return model[/\{([^}]+)\}/.exec(match).pop()];
        });
      }
    })
  });
});