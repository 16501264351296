define('freshfone-ember/constants/call-type', ['exports'], function (exports) {
  exports['default'] = Object.freeze({
    AgentConference: 'agent_conference',
    Incoming: 'incoming',
    Outgoing: 'outgoing',
    Transfer: 'transfer',
    WarmTransfer: 'warm_transfer',
    ParallelCall: 'parallel_call',
    QueueTransfer: 'queue_transfer',
    Monitor: 'monitor',
    Barge: 'barge',
    CustomerConnected: 'customer_connected'
  });
});