define('freshfone-ember/components/manage-intents/create-intent-modal/create-phrase/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: 'create-phrase-item',

    actions: {
      onChange: function onChange() {
        var phrase = this.get('phrase');
        var phrases = this.get('phraseList').filter(function (item) {
          return item.get('name') !== phrase.get('name');
        });
        this.set('parent.phraseList', [].concat(_toConsumableArray(phrases), [this.store.createRecord('phrase', { 'name': event.target.value })]));
      },

      'delete': function _delete() {
        this.sendAction('openDeleteModal', this.get('phrase'));
      }
    }
  });
});