define('freshfone-ember/components/mkp-apps/mkp-app/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNameBindings: ['isOpen'],
    classNames: ['mkp-app'],
    isOpen: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(key, value) {
        if (value) {
          this.get('app').trigger({ type: 'app.activated' });
        } else {
          this.get('app').trigger({ type: 'app.deactivated' });
        }

        return value;
      }
    })
  });
});