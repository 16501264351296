define("freshfone-ember/components/common/custom-power-calendar/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["class", "date-text-field", "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [4, 10], [4, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "readonly", ["subexpr", "@mut", [["get", "readonly", ["loc", [null, [5, 13], [5, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [6, 16], [6, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [7, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 6
                },
                "end": {
                  "line": 26,
                  "column": 6
                }
              },
              "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-cancel month");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-cancel year");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "moment-format", [["get", "calendar.center", ["loc", [null, [21, 26], [21, 41]]], 0, 0, 0, 0], "MMM"], ["locale", ["subexpr", "@mut", [["get", "calendar.locale", ["loc", [null, [21, 55], [21, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 10], [21, 72]]], 0, 0], ["inline", "moment-format", [["get", "calendar.center", ["loc", [null, [24, 26], [24, 41]]], 0, 0, 0, 0], "YYYY"], ["locale", ["subexpr", "@mut", [["get", "calendar.locale", ["loc", [null, [24, 56], [24, 71]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [24, 10], [24, 73]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 4
              },
              "end": {
                "line": 28,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "calendar.nav", [], [], 0, null, ["loc", [null, [19, 6], [26, 23]]]], ["inline", "calendar.days", [], ["maxDate", ["subexpr", "@mut", [["get", "maxDate", ["loc", [null, [27, 30], [27, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [27, 6], [27, 39]]], 0, 0]],
          locals: ["calendar"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 29,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "power-calendar-range", [], ["locale", ["subexpr", "@mut", [["get", "locale", ["loc", [null, [12, 15], [12, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "selectedRange", ["loc", [null, [13, 17], [13, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "center", ["subexpr", "@mut", [["get", "center", ["loc", [null, [14, 15], [14, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "onCenterChange", ["subexpr", "action", ["changeMonth"], [], ["loc", [null, [15, 23], [15, 45]]], 0, 0], "maxRange", ["subexpr", "@mut", [["get", "oneMonthMaxRange", ["loc", [null, [16, 17], [16, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelect", ["subexpr", "action", ["updateRange"], [], ["loc", [null, [17, 17], [17, 39]]], 0, 0]], 0, null, ["loc", [null, [11, 4], [28, 29]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 37,
                    "column": 8
                  },
                  "end": {
                    "line": 39,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "moment-format", [["get", "calendar.center", ["loc", [null, [38, 26], [38, 41]]], 0, 0, 0, 0], "MMMM"], ["locale", ["subexpr", "@mut", [["get", "calendar.locale", ["loc", [null, [38, 56], [38, 71]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [38, 10], [38, 73]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 39,
                    "column": 8
                  },
                  "end": {
                    "line": 46,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-cancel month");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-cancel year");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                return morphs;
              },
              statements: [["inline", "moment-format", [["get", "calendar.center", ["loc", [null, [41, 28], [41, 43]]], 0, 0, 0, 0], "MMM"], ["locale", ["subexpr", "@mut", [["get", "calendar.locale", ["loc", [null, [41, 57], [41, 72]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [41, 12], [41, 74]]], 0, 0], ["inline", "moment-format", [["get", "calendar.center", ["loc", [null, [44, 28], [44, 43]]], 0, 0, 0, 0], "YYYY"], ["locale", ["subexpr", "@mut", [["get", "calendar.locale", ["loc", [null, [44, 58], [44, 73]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [44, 12], [44, 75]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 6
                },
                "end": {
                  "line": 47,
                  "column": 6
                }
              },
              "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "dayMonthPicker", ["loc", [null, [37, 14], [37, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [37, 8], [46, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 4
              },
              "end": {
                "line": 49,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "calendar.nav", [], [], 0, null, ["loc", [null, [36, 6], [47, 23]]]], ["content", "calendar.days", ["loc", [null, [48, 6], [48, 23]]], 0, 0, 0, 0]],
          locals: ["calendar"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 2
            },
            "end": {
              "line": 50,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "power-calendar", [], ["locale", ["subexpr", "@mut", [["get", "locale", ["loc", [null, [31, 15], [31, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "center", ["subexpr", "@mut", [["get", "center", ["loc", [null, [32, 15], [32, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "onCenterChange", ["subexpr", "action", ["changeMonth"], [], ["loc", [null, [33, 23], [33, 45]]], 0, 0], "onSelect", ["subexpr", "action", ["updateValue"], [], ["loc", [null, [34, 17], [34, 39]]], 0, 0]], 0, null, ["loc", [null, [30, 4], [49, 23]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/common/custom-power-calendar/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "datepicker-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "unless", [["get", "noInputField", ["loc", [null, [1, 10], [1, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [8, 11]]]], ["block", "if", [["get", "rangeSelector", ["loc", [null, [10, 8], [10, 21]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [10, 2], [50, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});