define('freshfone-ember/models/call-flow', ['exports', 'ember', 'ember-data', 'ember-validations'], function (exports, _ember, _emberData, _emberValidations) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend(_emberValidations['default'], {
    name: attr('string'),
    typeName: attr('string'),
    'default': attr('boolean'),
    flowTypeCategory: attr('string'),
    callQueue: belongsTo('callQueue', { async: true }),
    ivr: belongsTo('ivr', { async: true }),
    deflectionBot: belongsTo('deflectionBot', { async: true }),
    agentExtension: belongsTo('agentExtension', { async: true }),
    routingAutomation: belongsTo('routingAutomation', { async: true }),

    isIvr: computed.equal('typeName', 'Ivr'),
    isSpeechIvr: computed.equal('flowTypeCategory', 'voice'),
    isCallQueue: computed.equal('typeName', 'CallQueue'),
    isDeflectionBot: computed.equal('typeName', 'DeflectionBot'),
    isAgentExtension: computed.equal('typeName', 'AgentExtension'),
    isRoutingAutomation: computed.equal('typeName', 'RoutingAutomation'),

    child: computed(function () {
      return this.get(this.get('typeName').camelize());
    }),
    description: computed('typeName', function () {
      if (this.get('isSpeechIvr')) {
        return _ember['default'].i18n.t('call_flow.speech_ivr_menu');
      } else if (this.get('isIvr')) {
        return _ember['default'].i18n.t('call_flow.ivr_menu');
      } else if (this.get('isCallQueue')) {
        return _ember['default'].i18n.t('call_queue.name');
      } else if (this.get('isDeflectionBot')) {
        return _ember['default'].i18n.t('deflection_bot.name');
      } else if (this.get('isAgentExtension')) {
        return _ember['default'].i18n.t('agent_extension.name');
      } else if (this.get('isRoutingAutomation')) {
        return _ember['default'].i18n.t('data_automated_routing.name');
      }
    }),
    redirectionPath: computed(function () {
      if (this.get('isIvr')) {
        return 'protected.admin.ivr.edit';
      } else if (this.get('isDeflectionBot')) {
        return 'protected.admin.deflection-bot.edit';
      } else if (this.get('isAgentExtension')) {
        return 'protected.admin.agent-extension.edit';
      } else if (this.get('isRoutingAutomation')) {
        return 'protected.admin.data-automated-routing.edit';
      } else {
        return 'protected.admin.callflows.edit';
      }
    }),
    validations: {}

  });
});