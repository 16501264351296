define('freshfone-ember/components/custom-elements/avatar/component', ['exports', 'ember'], function (exports, _ember) {
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['data-toggle', 'data-placement', 'title'],
    classNames: 'avatar-wrap',
    size: '30', // Options '30' || '50' || '80'
    tagName: 'span',
    isUnknown: computed.empty('avatarText'),

    charLimit: computed('avatarShape', {
      get: function get() {
        if (this.get('avatarModel.modelType') === "salesAccount") {
          return 2;
        }
        return 1;
      }
    }),
    avatarCharacter: computed('avatarText', {
      get: function get() {
        return this.get('avatarText') ? this.get('avatarText').substring(0, 1).toUpperCase() : "";
      },
      set: function set() {
        assert('You can\'t set \'avatarCharacter\'');
      }
    }),

    getInitials: function getInitials(name) {
      return name.trim().split(' ').map(function (s) {
        return s && s[0].toUpperCase();
      }).join('').slice(0, this.get('charLimit'));
    },
    // TODO: Remove this once the deprecation is removed.
    avatarFromName: computed('text', {
      get: function get() {
        var avatarText = this.get('text');
        if (avatarText) {
          return this.getInitials(avatarText || "-");
        }
        return '';
      }
    }),
    avatarText: computed('avatarModel.name', 'text', {
      get: function get() {
        if (_ember['default'].testing) return;
        var _model = this.get('avatarModel');
        if (_model && _model.get) {
          var _displayName = _model.get('flowName') || _model.get('name');
          if (_displayName) {
            return this.getInitials(_displayName);
          } else {
            if (this.get('type') === 'bot') {
              this.set('avatarShape', 'user');
            } else {
              this.set('avatarShape', 'unknown');
            }
          }
        } else {
          return this.get('avatarFromName');
        }
      }
    }),
    avatarShape: computed('avatarText', {
      get: function get() {
        var _model = this.get('avatarModel');
        var shape = this.get('type');
        if (!shape) {
          shape = _model ? _model.get('modelType') : 'disc';
        }
        return shape;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    avatarImage: computed('image', 'avatarModel.avatar', {
      get: function get() {
        if (_ember['default'].testing) return;
        var _model = this.get('avatarModel');

        if (_model && _model.get && _model.get('avatar')) {
          return 'background-image: url(' + _model.get('avatar') + ')';
        } else if (this.get('image')) {
          return 'background-image: url(' + this.get('image') + ')';
        } else {
          return null;
        }
      }
    }),

    resolveModel: (function () {
      var _this = this;

      if (this.get('model')) {
        if (this.get('model').isFulfilled) {
          return this.get('model').then(function (model) {
            _this.set('avatarModel', model);
          });
        } else {
          this.set('avatarModel', this.get('model'));
        }
      }
    }).observes('model'),
    //.on('didInsertElement')
    didRender: function didRender() {
      _ember['default'].run.scheduleOnce('afterRender', this, 'resolveModel');
      // this.resolveModel();
    }

  });
});