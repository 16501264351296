define('freshfone-ember/components/live-calls/queue-list/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    classNames: ["queues-dropdown-wrapper"],
    analyticsService: service('analytics-tracker'),

    removeDeselectedQueues: function removeDeselectedQueues(options) {
      return this.get('queues').filter(function (queue) {
        return queue.teamName !== options[0].teamName;
      });
    },

    isOptionSelected: function isOptionSelected(option) {
      return this.get('queues').some(function (queue) {
        return queue.id === option.id;
      });
    },

    actions: {
      closeViewsList: function closeViewsList() {
        this.set('showViews', false);
      },
      selectAll: function selectAll(optionsList) {
        var _this = this;

        optionsList.forEach(function (option) {
          if (_this.get('queues') && !_this.isOptionSelected(option)) {
            _this.get('queues').pushObject(option);
          }
        });
        this.set('queues', [].concat(_toConsumableArray(this.get('queues'))));
        this.set('showSaveView', true);
        this.send('setSelectedQueues');
      },
      deSelect: function deSelect(optionsList) {
        var filteredQueues = this.removeDeselectedQueues(optionsList);

        this.set('queues', filteredQueues);
        this.set('showSaveView', this.get('queues').length > 0);
        this.send('setSelectedQueues');
      },
      setSelectedQueues: function setSelectedQueues() {
        var _this2 = this;

        this.get('analyticsService').addEvent('Queue dropdown');
        var mappedData = [];
        this.get('queues').forEach(function (ele) {
          mappedData.push({ id: ele.id, name: ele.name });
        });
        this.set('showSaveView', this.get('queues').length > 0);

        _ember['default'].$.ajax({
          url: '/call_queues/set_selected_queues',
          type: 'post',
          dataType: 'json',
          data: { queues: mappedData }
        }).done(function (response) {
          _this2.set('selectedQueues', _this2.get('queues'));
          _this2.sendAction('refreshAggregate', _this2.get('intervalOption'));
          _this2.sendAction('showSave');
        });
      }
    }
  });
});