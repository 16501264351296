define('freshfone-ember/components/data-automated-routing/form/invalid-input-rules/component', ['exports', 'ember', 'freshfone-ember/helpers/message-default-types', 'freshfone-ember/utils/call-flow-constants'], function (exports, _ember, _freshfoneEmberHelpersMessageDefaultTypes, _freshfoneEmberUtilsCallFlowConstants) {
  var messageTypes = _freshfoneEmberUtilsCallFlowConstants['default'].messageTypes;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    defaultMessage: computed('actionType', function () {
      if (this.get('actionType') === 'hangup') {
        return this.store.peekAll('message').findBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage);
      }

      return this.store.peekAll('message').findBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage);
    }),
    optionAction: computed('actionType', {
      get: function get() {
        var actionOptions = this.get('model.actionOptions') || [];

        return actionOptions.findBy('name', this.get('actionType'));
      },
      set: function set(key, value) {
        this.set('actionType', value.name);
        this.get('model').set(this.get('actionAttribute'), null);
        this.setProperties({
          actionId: null,
          isChildOpen: false,
          isChildFormOpen: false
        });
        if (messageTypes.includes(value.name)) {
          this.set('actionId', this.get('defaultMessage.id'));
        }

        return value;
      }
    })
  });
});