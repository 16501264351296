define('freshfone-ember/routes/application', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/utils/signup-app-constants', 'freshfone-ember/mixins/url-mixin', 'freshfone-ember/constants/cloud-type', 'moment', 'freshfone-ember/constants/moment-code'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberUtilsSignupAppConstants, _freshfoneEmberMixinsUrlMixin, _freshfoneEmberConstantsCloudType, _moment, _freshfoneEmberConstantsMomentCode) {
  var NProgress = window.NProgress;

  var get = _ember['default'].get;
  var Route = _ember['default'].Route;
  var service = _ember['default'].inject.service;
  var i18n = _ember['default'].i18n;
  var Promise = _ember['default'].RSVP.Promise;
  var later = _ember['default'].run.later;
  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;

  var analyticsInterface = window.analyticsInterface;

  NProgress.configure({
    minimum: 0.75,
    template: '<div class="page-load"><div class="page-load-progress" role="bar"></div></div>'
  });

  exports['default'] = Route.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], _freshfoneEmberMixinsUrlMixin['default'], {
    titleDelimiter: ' : ',
    baseTitle: 'Freshcaller',
    freshchatTitle: 'Freshchat',
    freshsalesSuite: 'Freshsales Suite',
    cxSuite: 'Freshworks Customer Service Suite',
    translator: service(),
    interactivityTracking: service(),
    accountConfiguration: service('account-configuration'),
    freshsalesPlanName: computed.alias('session.current.bundleInfo.domains.freshsales-plan-name'),
    queryParams: {
      bundle: {
        replace: true,
        refreshModel: true
      },
      isEmbedMode: {
        replace: true,
        refreshModel: true
      }
    },
    title: function title(tokens) {
      var titleDelimiter = this.get('titleDelimiter'),
          baseTitle = this.get('baseTitle'),
          freshchatTitle = this.get('freshchatTitle'),
          freshsalesSuite = this.get('freshsalesSuite'),
          cxSuite = this.get('cxSuite');

      if (this.get('freshsalesPlanName') && this.get('freshsalesPlanName').includes('sales360_clc')) {
        return 'Freshsales Suite';
      }

      if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.telephony) {
        return tokens.join(titleDelimiter) + titleDelimiter + baseTitle;
      } else if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.freshdeskMessagingCloud) {
        return tokens.join(titleDelimiter) + titleDelimiter + freshchatTitle;
      } else if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.customerForLifeCloud) {
        return tokens.join(titleDelimiter) + titleDelimiter + freshsalesSuite;
      } else if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.cxsuite) {
        return tokens.join(titleDelimiter) + titleDelimiter + cxSuite;
      }

      if (_freshfoneEmberUtilsSignupAppConstants.SIGNUP_APPS.includes(this.get('session.account.signupApp'))) {
        return this.get('session.account.crmName');
      }

      return isEmpty(tokens) ? baseTitle : tokens.join(titleDelimiter) + titleDelimiter + baseTitle;
    },

    model: function model(params, transition) {
      var _this = this;

      var transitionPath = transition.targetName;

      if (transitionPath === 'un-authorized') {
        this.set('session.redirectPath', 'un-authorized');

        // redirection takes place only if promise returned from model hook is resolved
        return Promise.resolve();
      }

      if (transitionPath === 'daypass-expired') {
        this.set('session.redirectPath', 'daypass-expired');

        return Promise.resolve();
      }

      this.set('session.redirectPath', 'protected.dashboard');

      return this.loadCurrentSession().then(function () {
        return _this.get('accountConfiguration').fetchConfig();
      });
    },

    redirect: function redirect(model, transition) {
      var currentRoute = transition.targetName,
          isProtected = currentRoute.startsWith('protected');

      if (!isProtected || !this.checkFeature('warning_message_debug') && currentRoute.includes('debug')) {
        this.transitionTo(this.get('session.redirectPath'));
      }
    },

    postSessionRedirect: function postSessionRedirect() {
      if (this.get('has1CXFeature') && this.get('isCrmView')) {
        this.set('session.redirectPath', 'protected.admin.numbers');
      }

      if (this.get('session.account.cloudType') === 'messaging') {
        this.transitionTo('un-authorized');
        this.set('session.redirectPath', 'un-authorized');
      }
    },

    has1CXFeature: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),

    loadCurrentSession: function loadCurrentSession() {
      var _this2 = this;

      return new Promise(function (resolve) {
        _this2.get('session').loadSession().then(function () {
          var locale = _this2.get('session.current.user.language') || _this2.get('session.current.account.language') || 'en',
              payload = {
            type: 'freshcaller_logged_in',
            domain: window.location.protocol + '//' + _this2.get('session.account.full_domain')
          };

          // notify listeners
          if (!_this2.get('session.account.isUnifiedAppAccount')) {
            window.postMessage(payload, '*');
            if (window.opener) {
              window.opener.postMessage(payload, '*');
            }
          }

          _this2.postSessionRedirect();

          // set locales
          if (!(i18n.isDestroyed || i18n.isDestroying)) {
            i18n.set('locale', locale);
          }
          _moment['default'].locale(_freshfoneEmberConstantsMomentCode['default'][locale]);

          // fetch translations
          _this2.get('translator').fetch(locale).then(resolve);
        })['finally'](function () {
          var interactivityTracking = get(_this2, 'interactivityTracking');

          // eslint-disable-next-line max-len
          if (interactivityTracking && get(interactivityTracking, 'analyticsEnabled') && !get(interactivityTracking, 'analyticsInitialized')) {
            analyticsInterface.trackEvent({ logName: 'AppLoaded' });
            interactivityTracking.initializeMetricsCollector({ eventSource: 'freshfone-ember' });
          }
        });
      });
    },

    actions: {
      activate: function activate() {
        NProgress.start();
        later(function () {
          NProgress.inc();
        }, 500);
      },
      willTransition: function willTransition() {
        NProgress.inc();
      },
      didTransition: function didTransition() {
        later(function () {
          NProgress.done();
        }, 500);
      }
    }
  });
});