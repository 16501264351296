define("freshfone-ember/components/number-component/number-assignees/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/number-component/number-assignees/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "option-value novatar");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "assignee.name", ["loc", [null, [14, 37], [14, 54]]], 0, 0, 0, 0]],
        locals: ["assignee"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 33
          }
        },
        "moduleName": "freshfone-ember/components/number-component/number-assignees/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "custom-elements/power-select", [], ["class", ["subexpr", "@mut", [["get", "class", ["loc", [null, [2, 7], [2, 12]]], 0, 0, 0, 0]], [], [], 0, 0], "isFixedTopLabel", true, "renderInPlace", true, "multiSelect", true, "options", ["subexpr", "@mut", [["get", "agentsAndTeams", ["loc", [null, [6, 9], [6, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "attributeName", "name", "label", ["subexpr", "@mut", [["get", "label", ["loc", [null, [8, 7], [8, 12]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["numbers.everyone"], [], ["loc", [null, [9, 13], [9, 35]]], 0, 0], "searchEnabled", true, "searchField", "name", "value", ["subexpr", "@mut", [["get", "freshfoneNumber.assignees", ["loc", [null, [12, 7], [12, 32]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [15, 33]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});