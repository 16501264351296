define('freshfone-ember/utils/alert', ['exports'], function (exports) {
  var alertInfo = {
    type: '',
    text: '',
    isDismissable: true
  },
      alertTypes = {
    danger: 'danger',
    info: 'info',
    success: 'success',
    warning: 'warning'
  },
      alertIcons = {
    danger: 'icon-warning',
    info: 'icon-info-alert',
    success: 'icon-success-alert',
    warning: 'icon-warning-alert'
  },
      getAlertInfo = function getAlertInfo(type, text) {
    var isDismissable = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

    if (!type || !alertTypes.hasOwnProperty(type) || !text) {
      return alertInfo;
    }

    var icon = alertIcons[type];

    return {
      icon: icon,
      isDismissable: isDismissable,
      text: text,
      type: type
    };
  };

  exports.alertIcons = alertIcons;
  exports.alertTypes = alertTypes;
  exports.getAlertInfo = getAlertInfo;
});