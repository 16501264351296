define('freshfone-ember/components/manage-intents/create-intent-modal/component', ['exports', 'ember', 'freshfone-ember/helpers/is-equal-array', 'ember-validations'], function (exports, _ember, _freshfoneEmberHelpersIsEqualArray, _emberValidations) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  var run = _ember['default'].run;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend(_emberValidations['default'], {
    notification: service('toastr'),

    isEditModal: observer('intent', function () {
      var _this = this;

      if (!isEmpty(this.get('intent'))) {
        this.set('intentName', this.get('intent.name'));

        var phraseNames = this.get('phraseList').map(function (phrase) {
          return phrase.get('name');
        });
        this.set('phraseList', []);
        phraseNames.map(function (name) {
          _this.set('phraseList', [].concat(_toConsumableArray(_this.get('phraseList')), [_this.store.peekAll('phrase').filterBy('name', name)[0]]));
        });
      } else {
        this.set('intentName', '');
      }
    }),

    showValidationErrors: function showValidationErrors(error) {
      this.setProperties({
        "intentError": isEmpty(error.intentName) ? '' : error.intentName[0].toString(),
        "phraseError": isEmpty(error.newPhrase) ? '' : error.newPhrase[0].toString(),
        "startValidate": true
      });
    },

    actions: {
      keyDown: function keyDown() {
        if (this.get('phraseList.content.length')) this.set('phraseList', this.get('phraseList').filterBy('name'));
        var key = event.keyCode || event.which;
        var value = event.target ? event.target.value : '';
        if (value && key === 13) {
          event.target.value = '';
          this.set('phraseList', [].concat(_toConsumableArray(this.get('phraseList')), [this.store.createRecord('phrase', { 'name': value })]));
        }
      },

      closeCreateModal: function closeCreateModal() {
        this.send('cancel');
        this.sendAction('closeModal');
      },

      cancel: function cancel() {
        this.setProperties({
          'phraseList': [],
          'intentName': this.get('isEditMode') ? this.get('intentName') : '',
          'newPhrase': '',
          'phraseError': '',
          'intentError': '',
          'isSaving': false,
          'startValidate': false
        });
      },

      edit: function edit() {
        var _this2 = this;

        this.validate().then(function () {
          _this2.set('isSaving', true);
          var editedIntent = {},
              params = {},
              phraseList = _this2.get('phraseList').map(function (phrase) {
            return phrase.get('name');
          }).filter(Boolean),
              intentName = _this2.get('oldIntentName');

          if (_this2.get('newPhrase')) {
            phraseList.push(_this2.get('newPhrase'));
          }
          editedIntent[intentName] = { "new_data": phraseList, "request_type": "overwrite" };
          params = { "bot_type": _this2.get('bot.name'), "examples": editedIntent };

          _this2.get('bot').updateBot(params).then(function (response) {
            run(function () {
              if (response.message && response.message.model_retraining) {
                _this2.get('intent').destroyRecord();
                _this2.sendAction('createIntentModel', intentName, phraseList);
                _this2.send('renameIntent', phraseList);
              }
            });
          })['catch'](function (error) {
            _this2.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.edit_phrases_error_message'));
            _this2.set('isSaving', false);
          });
        })['catch'](function (error) {
          _this2.showValidationErrors(error);
        });
      },

      renameIntent: function renameIntent(phraseList) {
        var _this3 = this;

        if (this.get('intentName') === this.get('oldIntentName')) {
          this.set('isSaving', false);
          this.send('closeCreateModal');
          return;
        }
        var editedIntent = {},
            params = {},
            intentName = this.get('intentName');
        editedIntent[this.get('oldIntentName')] = { "data": phraseList, "request_type": "delete" };
        editedIntent[intentName] = { "data": phraseList, "request_type": "append" };
        params = { "bot_type": this.get('bot.name'), "examples": editedIntent };

        this.get('bot').updateBot(params).then(function (response) {
          run(function () {
            if (response.message && response.message.model_retraining) {
              var deletedRecord = _this3.store.peekAll('intent').filterBy('name', _this3.get('oldIntentName'))[0];
              _this3.send('closeCreateModal');
              deletedRecord.unloadRecord();
              _this3.sendAction('createIntentModel', intentName, phraseList);
            }
          });
        })['catch'](function (error) {
          _this3.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.edit_intent_error_message'));
        })['finally'](function () {
          _this3.set('isSaving', false);
        });
      },

      save: function save() {
        var _this4 = this;

        this.validate().then(function () {
          _this4.set('isSaving', true);
          var newIntent = {},
              params = {},
              phrasesList = _this4.get('phraseList').map(function (phrase) {
            return phrase.get('name');
          }).filter(Boolean),
              intentName = _this4.get('intentName');
          if (_this4.get('newPhrase')) {
            phrasesList.push(_this4.get('newPhrase'));
          }
          newIntent[intentName] = { "data": phrasesList, "request_type": "append" };
          params = { "bot_type": _this4.get('bot.name'), "examples": newIntent };

          _this4.get('bot').updateBot(params).then(function (response) {
            run(function () {
              if (response.message && response.message.model_retraining) {
                _this4.send('closeCreateModal');
                _this4.sendAction('createIntentModel', intentName, phrasesList);
              }
            });
          })['catch'](function (error) {
            _this4.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.error_message'));
          })['finally'](function () {
            _this4.set('isSaving', false);
          });
        })['catch'](function (error) {
          _this4.showValidationErrors(error);
        });
      },

      openDeleteModal: function openDeleteModal(phrase) {
        this.setProperties({
          'showDeleteModal': true,
          'phraseToDelete': phrase
        });
      },

      closeDeleteModal: function closeDeleteModal() {
        this.set('showDeleteModal', false);
      },

      deletePhrase: function deletePhrase() {
        this.sendAction('deletePhraseCallback', this.get('intent'), this.get('phraseToDelete'));
      },

      deleteIntent: function deleteIntent() {
        this.sendAction('deleteIntentCallback', this.get('intent'));
      }
    },
    validations: {
      'intentName': {
        inline: (0, _emberValidations.validator)(function () {
          if (_ember['default'].isBlank(this.get('intentName')) && _ember['default'].isBlank(this.get('intent.name'))) {
            return _ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.intent_validation_message');
          }
        })
      },
      'newPhrase': {
        inline: (0, _emberValidations.validator)(function () {
          if (isEmpty(this.get('phraseList')) && _ember['default'].isEmpty(this.get('newPhrase'))) {
            return _ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.phrase_valiation_message');
          }
        })
      }
    }
  });
});