define('freshfone-ember/serializers/routing-rule', ['exports', 'freshfone-ember/serializers/application', 'freshfone-ember/mixins/serializers/embedded-records'], function (exports, _freshfoneEmberSerializersApplication, _freshfoneEmberMixinsSerializersEmbeddedRecords) {
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.data.attributes['action-type'] = json.data.attributes['action-type'].decamelize();

      return json;
    },

    normalize: function normalize(store, primaryModelClass, payload, id, requestType) {

      if (primaryModelClass && primaryModelClass.attributes) {
        primaryModelClass.attributes['action-type'] = primaryModelClass.attributes['action-type'].camelize();
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});
/* eslint-disable sort-vars */