define('freshfone-ember/components/company-components/component', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, _ember, _emberComponentInboundActionsInboundActions) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_emberComponentInboundActionsInboundActions['default'], {
    currentUser: computed.alias('session.user'),
    actions: {
      toggleEdit: function toggleEdit() {
        this.toggleProperty('editCompany');
      }
    }
  });
});