define('freshfone-ember/components/manage-intents/view-edit-intent/phrase-list/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      openDeleteModal: function openDeleteModal() {
        this.set('showDeleteModal', true);
      },

      closeDeleteModal: function closeDeleteModal() {
        this.set('showDeleteModal', false);
      },

      edit: function edit() {
        this.sendAction('editPhrase');
      },

      'delete': function _delete() {
        this.sendAction('deletePhraseCallback', this.get('intent'), this.get('phrase'));
      }
    }
  });
});