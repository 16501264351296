define('freshfone-ember/helpers/formatted-date', ['exports', 'ember'], function (exports, _ember) {
  exports.formattedDate = formattedDate;

  var momentjs = window.moment;

  function formattedDate(date) {
    var dateFormat = {
      sameDay: function sameDay() {
        return '[' + this.fromNow() + ']';
      },
      lastDay: '[Yesterday]',
      lastWeek: 'ddd',
      sameElse: 'DD/MM/YYYY' //this should follow account's timezone format
    };
    return momentjs(date[0]).calendar(null, dateFormat);
  }

  exports['default'] = _ember['default'].Helper.helper(formattedDate);
});