define("freshfone-ember/serializers/slm-view", ["exports", "freshfone-ember/serializers/application"], function (exports, _freshfoneEmberSerializersApplication) {
  exports["default"] = _freshfoneEmberSerializersApplication["default"].extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options),
          queues = json.data.attributes["queues"],
          qeuesIds = queues.map(function (callQueue) {
        return callQueue.id;
      });

      json.data.attributes["queues"] = qeuesIds;

      return json;
    }
  });
});