define('freshfone-ember/components/number-component/purchase-form/mail-to-link/component', ['exports', 'ember', 'freshfone-ember/constants/purchase-form'], function (exports, _ember, _freshfoneEmberConstantsPurchaseForm) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    session: service(),
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    mailToLink: computed('currentUser.{name,email}', 'currentAccount.{id,orgUrl}', 'phoneNumber', 'isCrm', 'country', 'price', function () {
      var rootURL = localStorage.getItem('rootURL');

      if (this.get('isCrm') && rootURL && rootURL !== '/') {
        var _rootURL$split = rootURL.split('/');

        var _rootURL$split2 = _slicedToArray(_rootURL$split, 2);

        var crmPath = _rootURL$split2[1];
        var mailToDetails = {
          phoneNumber: this.get('phoneNumber.number'),
          price: this.get('price'),
          country: this.get('country.name'),
          accountId: this.get('currentAccount.id'),
          orgUrl: this.get('currentAccount.orgUrl') + '/' + crmPath + '/sales',
          userEmail: this.get('currentUser.email'),
          userName: this.get('currentUser.name'),
          supportEmailId: _freshfoneEmberConstantsPurchaseForm['default'].crmSupportEmail
        };

        return _freshfoneEmberConstantsPurchaseForm['default'].getMailToLink(mailToDetails);
      }

      return 'mailto:' + _freshfoneEmberConstantsPurchaseForm['default'].freshcallerSupportEmail;
    })
  });
});