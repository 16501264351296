define("freshfone-ember/components/live-calls/queue-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 16,
                "column": 1
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/queue-list/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "option-value ellipsis");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "queue.name", ["loc", [null, [15, 45], [15, 59]]], 0, 0, 0, 0]],
          locals: ["queue"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/queue-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "queues-dropdown");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
          return morphs;
        },
        statements: [["block", "custom-elements/power-select", [], ["multiSelect", true, "highlight", true, "icon", "icon-queue", "searchField", "name", "options", ["subexpr", "@mut", [["get", "queueOptions", ["loc", [null, [7, 12], [7, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "queues", ["loc", [null, [8, 10], [8, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "followUpAction", ["subexpr", "action", ["setSelectedQueues"], [], ["loc", [null, [9, 19], [9, 47]]], 0, 0], "searchEnabled", true, "renderInPlace", true, "placeholder", ["subexpr", "t", ["calls_dashboard.all_queues"], [], ["loc", [null, [12, 18], [12, 50]]], 0, 0], "noMatchesMessage", ["subexpr", "t", ["calls_dashboard.no_queue_found"], [], ["loc", [null, [13, 21], [13, 57]]], 0, 0]], 0, null, ["loc", [null, [3, 2], [16, 34]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 2
              },
              "end": {
                "line": 38,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/queue-list/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("       ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "option-value ellipsis");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "queue.name", ["loc", [null, [37, 44], [37, 58]]], 0, 0, 0, 0]],
          locals: ["queue"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/queue-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "queues-dropdown");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
          return morphs;
        },
        statements: [["block", "custom-elements/power-select", [], ["multiSelect", true, "highlight", true, "icon", "icon-queue", "searchField", "name", "options", ["subexpr", "@mut", [["get", "queueOptions", ["loc", [null, [24, 12], [24, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "queues", ["loc", [null, [25, 10], [25, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "click", ["subexpr", "action", ["closeViewsList"], [], ["loc", [null, [26, 10], [26, 35]]], 0, 0], "followUpAction", ["subexpr", "action", ["setSelectedQueues"], [], ["loc", [null, [27, 19], [27, 47]]], 0, 0], "searchEnabled", true, "renderInPlace", true, "placeholder", ["subexpr", "t", ["calls_dashboard.all_queues"], [], ["loc", [null, [30, 18], [30, 50]]], 0, 0], "optionsComponent", ["subexpr", "component", ["custom-elements/queue-list"], ["selectedQueues", ["subexpr", "@mut", [["get", "queues", ["loc", [null, [32, 20], [32, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "selectAll", ["subexpr", "action", ["selectAll"], [], ["loc", [null, [33, 14], [33, 34]]], 0, 0], "deselectAll", ["subexpr", "action", ["deSelect"], [], ["loc", [null, [34, 16], [34, 35]]], 0, 0]], ["loc", [null, [31, 23], [34, 36]]], 0, 0], "noMatchesMessage", ["subexpr", "t", ["calls_dashboard.no_queue_found"], [], ["loc", [null, [35, 21], [35, 57]]], 0, 0]], 0, null, ["loc", [null, [20, 2], [38, 37]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 11
          }
        },
        "moduleName": "freshfone-ember/components/live-calls/queue-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "hasAccessToEnhancedLiveDashBoard", ["loc", [null, [1, 10], [1, 42]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [43, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});