define("freshfone-ember/components/dashboard-component/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/dashboard-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "agent-performance-metrics", [], ["class", "agent-performance-component"], ["loc", [null, [4, 6], [4, 71]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/dashboard-component/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "dashboard-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-8 dashboard-main-content");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-4 dashboard-sidebar");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "showAgentMetrics", ["loc", [null, [3, 10], [3, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 4], [5, 11]]]], ["inline", "conversation/conversations-list", [], ["class", ["subexpr", "if", [["get", "showAgentMetrics", ["loc", [null, [7, 16], [7, 32]]], 0, 0, 0, 0], "conversations-container"], [], ["loc", [null, [7, 12], [7, 59]]], 0, 0], "type", "user", "showPagination", true, "typeId", ["subexpr", "@mut", [["get", "currentUser.id", ["loc", [null, [10, 13], [10, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholderText", ["subexpr", "t", ["navigation.dashboard_search_placeholder"], [], ["loc", [null, [11, 22], [11, 67]]], 0, 0]], ["loc", [null, [6, 4], [12, 6]]], 0, 0], ["inline", "interactivity-beacon", [], ["routeInteractive", true], ["loc", [null, [14, 2], [14, 48]]], 0, 0], ["inline", "todo-component", [], ["model", ["subexpr", "@mut", [["get", "currentUser.todos", ["loc", [null, [16, 27], [16, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "user", ["subexpr", "@mut", [["get", "currentUser", ["loc", [null, [16, 50], [16, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "todosLoaded", ["subexpr", "@mut", [["get", "todosLoaded", ["loc", [null, [16, 74], [16, 85]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 4], [16, 87]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});