define('freshfone-ember/components/integrations/add-to-existing-ticket/component', ['exports', 'ember', 'freshfone-ember/config/environment', 'freshfone-ember/mixins/feature-check-mixin', 'ember-concurrency', 'freshfone-ember/mixins/integration-check-mixin'], function (exports, _ember, _freshfoneEmberConfigEnvironment, _freshfoneEmberMixinsFeatureCheckMixin, _emberConcurrency, _freshfoneEmberMixinsIntegrationCheckMixin) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var isEmpty = _ember['default'].isEmpty;
    exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
        DEFAULT_SEARCH_FIELDS: [{ id: "requester", name: "Requester", t_label: "integration.freshdesk.requester_search" }, { id: "display_id", name: "Ticket Id", t_label: "integration.freshdesk.ticket_id_search" }, { id: 'subject', name: "Subject", t_label: "integration.freshdesk.ticket_subject_search" }],
        searchString: "",
        searchTimeout: _freshfoneEmberConfigEnvironment['default'].environment === "test" ? 1 : 200,
        noSearchString: computed.empty('searchString'),
        showRecentEntities: computed.and('noSearchResults', 'noSearchString'),
        searchFields: computed.or('actionItem.searchFields', 'DEFAULT_SEARCH_FIELDS'),
        isAutocompleteSearch: computed.alias('searchField.auto-complete'),

        noSearchResults: computed('searchTask.lastSuccessful.value', function () {
            return isEmpty(this.get('searchTask.lastSuccessful.value')) || this.get('searchTask.lastSuccessful.value.data');
        }),
        searchResults: computed('recentEntities', function () {
            return this.get('recentEntities.data') || [];
        }),
        searchField: computed('searchFields', function () {
            return this.get('searchFields')[0];
        }),

        placeholderText: computed('searchField', function () {
            var _this = this;

            var defaultSearchFields = this.get('DEFAULT_SEARCH_FIELDS');
            var searchItem = defaultSearchFields.filter(function (item) {
                return item.id === _this.get('searchField').id;
            });
            return _ember['default'].i18n.t(searchItem[0].t_label).string;
        }),
        is1ClickIntegratedAccount: computed(function () {
            return this.hasGlobalFeature('integrated_desk_with_1cx') && this.checkFeature('integrated_desk_with_1cx');
        }),

        getEntities: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(searchString, searchField, requesterId) {
            var params;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        params = { term: searchString,
                            field: searchField
                        };

                        if (this.hasOmniRedirectFeatureEnabled()) {
                            params.orgContactId = requesterId;
                        } else {
                            params.contactId = requesterId;
                        }

                        if (this.get('is1ClickIntegratedAccount')) {
                            params.version = 'v2';
                        }

                        context$1$0.next = 5;
                        return this.get('integratedApplication').searchEntities(params);

                    case 5:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        searchTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(query) {
            var result, searchString, searchField, _entities, entities;

            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        this.set('isSearchingEntities', false);
                        context$1$0.next = 3;
                        return (0, _emberConcurrency.timeout)(this.get('searchTimeout'));

                    case 3:
                        result = [];
                        searchString = this.get('searchString');
                        searchField = this.get('searchField').id;

                        this.set('searchResults', []);

                        if (!this.get('noSearchString')) {
                            context$1$0.next = 11;
                            break;
                        }

                        context$1$0.next = 10;
                        return [];

                    case 10:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 11:
                        if (!this.get('isAutocompleteSearch')) {
                            context$1$0.next = 22;
                            break;
                        }

                        if (this.get('requesterId')) {
                            context$1$0.next = 16;
                            break;
                        }

                        context$1$0.next = 15;
                        return [];

                    case 15:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 16:
                        context$1$0.next = 18;
                        return this.get('getEntities').perform(null, searchField, this.get('requesterId'));

                    case 18:
                        result = context$1$0.sent;
                        _entities = this.get('store').pushPayload(result);

                        this.set('searchResults', result.data);
                        return context$1$0.abrupt('return', result.data);

                    case 22:
                        context$1$0.next = 24;
                        return this.get('getEntities').perform(searchString, searchField);

                    case 24:
                        result = context$1$0.sent;
                        entities = this.get('store').pushPayload(result);

                        this.set('searchResults', result.data);
                        return context$1$0.abrupt('return', result.data);

                    case 28:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            closeAddTicket: function closeAddTicket() {
                this.sendAction('closeCallback');
            },
            actionOnEntity: function actionOnEntity(entity) {
                this.sendAction('createCallback', entity);
            },
            setSearchField: function setSearchField(searchField) {
                this.set('searchField', searchField);
            },
            searchTask: function searchTask(text) {
                this.set('searchString', text);
                this.get('searchTask').perform();
            },
            setRequester: function setRequester(requester) {
                if (requester) {
                    this.set('requesterId', this.hasOmniRedirectFeatureEnabled() ? requester.get('mcrId') : requester.get('id'));
                } else {
                    this.set('requesterId', null);
                }
                this.set('searchString', this.get('requesterId'));
                this.get('searchTask').perform();
            }
        }
    });
});