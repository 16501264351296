define('freshfone-ember/components/call-logs/call-log-row/component', ['exports', 'freshfone-ember/utils/call-constants', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/mixins/integration-mixin', 'ember-authority/helpers/has-privilege'], function (exports, _freshfoneEmberUtilsCallConstants, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberMixinsIntegrationMixin, _emberAuthorityHelpersHasPrivilege) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var next = _ember['default'].run.next;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], _freshfoneEmberMixinsIntegrationMixin['default'], {
    i18n: service(),
    classNameBindings: ['showDetails:expanded:contracted'],
    isAgentLeg: computed.equal('type', 'AGENT_LEG'),

    hasSearchString: computed.notEmpty('searchString'),
    showDetails: computed.or('hasSearchString', 'parentShowDetails'),
    currentUser: computed.alias('session.user'),
    hasVoicemailTranscriptionFeature: computed(function () {
      return this.hasFeatures(['voicemail_transcription']);
    }),
    hasCallTaggingFeature: computed(function () {
      return this.checkFeature('call_tagging');
    }),
    has1CXFeature: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),
    showDownloadRecording: computed.and('call.hasRecording', 'canDownloadRecording'),
    showDeleteRecording: computed.and('call.hasRecording', 'canDeleteRecording'),
    showDeleteNote: computed.and('call.conversation', 'canDeleteNote'),
    showDownloadCallTranscription: computed.and('hasCallTranscriptionFeature', 'isCallTranscription', 'canDownloadTranscription'),
    showMoreOptions: computed.or('showDownloadRecording', 'showDeleteRecording', 'showDeleteNote', 'showDownloadCallTranscription'),
    showMoreTagsCount: computed(function () {
      return this.get('call.tags.length') ? this.get('call.tags.length') - 1 : null;
    }),
    hasCallTranscriptionFeature: computed(function () {
      return this.hasGlobalFeature('call_transcription') && this.checkFeature('postcall_transcription');
    }),
    hasVoicemailTranscription: computed('hasVoicemailTranscriptionFeature', 'hasVoicemailTranscription', function () {
      return this.get('hasVoicemailTranscriptionFeature') && this.get('call.status') === _freshfoneEmberUtilsCallConstants['default'].STATUS_REVERSE_HASH[10] && this.get('call.transcriptionID');
    }),
    currentAccount: computed.alias('session.account'),
    isToRedirectDeskUrl: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    enableNumberBlockFeature: computed('isUCREnabledAccount', function () {
      var hasAccess = (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_customers',
        user: this.get('currentUser')
      });

      return hasAccess && !this.get('isUCREnabledAccount');
    }),
    isUCREnabledAccount: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    redirectUrl: computed('currentAccount.omnichannelOwnerDomain', function () {
      return this.get('currentAccount.omnichannelOwnerDomain') + '/a/org/contacts';
    }),
    hasCallTranscription: function hasCallTranscription() {
      var _this = this;

      this.set('isCallTranscription', false);
      if (this.get('call.transcriptionID')) {
        this.store.findRecord('transcription', this.get('call.transcriptionID')).then(function (transcription) {
          if (transcription.get('url')) {
            _this.setProperties({
              callTranscription: transcription,
              isCallTranscription: true
            });
          }
        });
      }
    },

    didRender: function didRender() {
      var _this2 = this;

      this._super.apply(this, arguments);

      next(function () {
        if (_this2.get('showDetails')) {
          var transferCollection = document.getElementsByClassName('child-one'),
              widthOfCustomerContainer = document.getElementsByClassName('caller-details-container')[0].offsetWidth;

          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = transferCollection[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var transfer = _step.value;

              transfer.style.width = widthOfCustomerContainer + 'px';
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator['return']) {
                _iterator['return']();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }
        if (_this2.get('isRedirection')) {
          $('.call-log-container .call-log-row .call-info-list .call-row').trigger('click');
        }
      });
    },

    actions: {
      toggleCallDetails: function toggleCallDetails(call) {
        var showDetails = !this.get('showDetails') && (call.get('hasChildren') || call.get('hasAgentLegs'));

        this.set('showDetails', showDetails);
      },
      toggleBlock: function toggleBlock(caller) {
        this.sendAction('toggleBlock', caller);
      },
      setTranscriptionID: function setTranscriptionID(transcriptionID) {
        this.set('transcriptionID', transcriptionID);
      },
      ontranscriptionModalClose: function ontranscriptionModalClose() {
        this.set('transcriptionID', null);
      },

      downloadRecording: function downloadRecording() {
        window.open(this.get('call.recordingUrl'), '_self');
      },
      toggleDeletionPopup: function toggleDeletionPopup(resourceType, call) {
        this.get('toggleDeletionPopup')(resourceType, call);
      },
      selectCall: function selectCall(call, isAgentLeg, event) {
        this.get('selectCall')(call, isAgentLeg, event);
      },
      fetchTranscriptionDetails: function fetchTranscriptionDetails() {
        this.hasCallTranscription();
      }
    }
  });
});