define('freshfone-ember/components/outgoing-widget/numbers-list/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/utils/search-util'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberUtilsSearchUtil) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    currentAccount: computed.alias('session.account'),
    classNameBindings: ['isSearchEnabled:with-search-bar'],
    currentAgent: computed.alias('session.user.agent'),
    telephony: service(),
    isSearchEnabled: computed.gt('freshfoneNumbers.length', 5),
    hasOptimizedNumberSerializer: computed(function () {
      return this.hasGlobalFeature('optimized_number_serializer');
    }),
    options: computed('searchTerm', 'searchedNumbersList.[]', 'freshfoneNumbers.[]', function () {
      return this.get('searchTerm') ? this.get('searchedNumbersList') : this.get('freshfoneNumbers');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initPreferredNumber(this.get('freshfoneNumbers'));
    },

    getCompleteModel: function getCompleteModel(numberId) {
      var _this = this;

      $.ajax({
        type: 'GET',
        url: '/numbers/' + numberId,
        dataType: 'json'
      }).done(function (number) {
        return run(function () {
          return _this.set('callerNumber', number.data);
        });
      });
    },
    callerNumber: computed({
      set: function set(key, value) {
        var callerId = this.get('currentAgent.outgoingNumberId');

        if (!value) {
          this.getCompleteModel(callerId);

          return;
        }
        if (this.get('hasOptimizedNumberSerializer') && !value.relationships) {
          this.getCompleteModel(value.get('id'));
        }
        if (value && value.get && value.get('id') !== callerId) {
          var currentAgent = this.get('currentAgent');

          this.set('searchTerm', null);
          currentAgent.set('outgoingNumberId', value.get('id'));
          currentAgent.save();
        }

        return value;
      },
      get: function get() {
        return this;
      }
    }),

    initPreferredNumber: function initPreferredNumber(numbers) {
      /*
       * localStorage is used so as to not disturb the number that the user has already selected.
       *Now that we're storing outgoingNumberId in DB, we have to get rid of localStorage someday.
       */
      var callerId = this.get('currentAgent.outgoingNumberId') || localStorage.getItem('callerId'),
          number = callerId ? numbers.findBy('id', callerId) : numbers.get('firstObject');

      this.set('callerNumber', number);
    },

    formatSearchTerm: function formatSearchTerm(searchTerm) {
      return _freshfoneEmberUtilsSearchUtil['default'].isAlphabetPresent(searchTerm) ? _freshfoneEmberUtilsSearchUtil['default'].escapeSpecialChar(searchTerm).toLowerCase() : _freshfoneEmberUtilsSearchUtil['default'].formatNumber(searchTerm);
    },

    actions: {
      search: function search(searchTerm) {
        if (this.hasGlobalFeature('accessible_numbers_v2')) {
          this.sendAction('fetchFreshfoneNumbers', false, {
            filter: { queryString: searchTerm },
            page_token: 0
          });

          return;
        }
        var formattedSearchTerm = this.formatSearchTerm(searchTerm),
            regex = new RegExp(formattedSearchTerm, 'gi');

        return this.get('freshfoneNumbers').filter(function (number) {
          var attrs = number.get('attributes');

          return regex.test(attrs.name) || regex.test(attrs.number);
        });
      },
      onInput: function onInput(searchTerm) {
        this.set('searchTerm', searchTerm);
      }
    }
  });
});
/* eslint-disable camelcase */