define('freshfone-ember/adapters/call-flow', ['exports', 'ember', 'freshfone-ember/mixins/adapters/multi-word-underscore', 'freshfone-ember/constants/call-queues'], function (exports, _ember, _freshfoneEmberMixinsAdaptersMultiWordUnderscore, _freshfoneEmberConstantsCallQueues) {
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _freshfoneEmberMixinsAdaptersMultiWordUnderscore['default'].extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (isPresent(query) && query.type === _freshfoneEmberConstantsCallQueues.recentCallQueues.recentCallFlows) {
        delete query.type;

        return '/users/recently_used_callflows';
      } else if (isPresent(query) && isPresent(query['filter[queryString]'])) {

        return '/call_flows/search';
      }

      return this._super(modelName, id, snapshot, requestType, query);
    }
  });
});