define('freshfone-ember/components/search-container/component', ['exports', 'ember', 'freshfone-ember/utils/search-util'], function (exports, _ember, _freshfoneEmberUtilsSearchUtil) {
  var later = _ember['default'].run.later;
  var service = _ember['default'].inject.service;

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    showActionBar: computed.alias('enableActionMenu'),
    hasEmptyDropdown: computed.not('showDropDown'),
    currentStatus: computed('toggleActionMenu', function () {
      return this.get('toggleActionMenu') ? 'Cancel' : 'Edit';
    }),
    analyticsService: service('analytics-tracker'),
    actions: {
      setFocusOnSearchField: function setFocusOnSearchField() {
        var _this = this;

        this.get('analyticsService').addEvent('Search ' + this.get('title'));
        later(function () {
          return _this.$('.search-field').focus();
        }, 100);
      },

      executeCallBackAction: function executeCallBackAction() {
        this.sendAction('callBackFunction', this.get('currentStatus'));
      },

      dropDownCallback: function dropDownCallback(item) {
        this.sendAction('dropDownCallback', item);
      },

      callbackOnToggle: function callbackOnToggle() {
        this.sendAction('callbackOnToggle');
      }
    }

  });
});