define('freshfone-ember/helpers/can-edit', ['exports', 'ember', 'ember-authority/helpers/has-privilege'], function (exports, _ember, _emberAuthorityHelpersHasPrivilege) {
  exports.canEdit = canEdit;

  function canEdit(params) {
    var currentUser = params[0];
    var targetUser = params[1];
    if (targetUser.get('deleted')) return false;
    if (currentUser.get('isAccountAdmin')) return true;
    if (currentUser.get('isAdmin') && !targetUser.get('isAccountAdmin')) return true;
    return false;
  }

  exports['default'] = _ember['default'].Helper.helper(canEdit);
});