define('freshfone-ember/helpers/has-feature', ['exports', 'ember-features/helpers/has-feature'], function (exports, _emberFeaturesHelpersHasFeature) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFeaturesHelpersHasFeature['default'];
    }
  });
  Object.defineProperty(exports, 'hasFeature', {
    enumerable: true,
    get: function get() {
      return _emberFeaturesHelpersHasFeature.hasFeature;
    }
  });
});