define('freshfone-ember/components/conversation/recent-conversations-list/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ["conversations-list"],
    showCurrentCall: computed('searchString', 'currentCall', function () {
      return this.get('currentCall') && !this.get('searchString');
    }),
    hasNotesSearchText: computed.notEmpty('searchString'),
    isExpand: true,
    isInProgressCall: computed.and('currentAgent', 'currentCall'),
    isCallsEmpty: computed.empty('recentCalls'),
    placeholderText: _ember['default'].i18n.t('conversation.conversation_list.placeholder_text'),
    account: _ember['default'].computed.alias('session.account'),
    isAgent: computed.equal('session.user.role.name', 'Agent'),
    hasCallLogsRestrictionFeature: computed('session', function () {
      return this.get('session.account').hasFeature('restrict_call_logs');
    }),
    restrictCallLogs: computed.and('isAgent', 'hasCallLogsRestrictionFeature'),
    actions: {
      selectAgent: function selectAgent(agent) {
        this.sendAction('selectAgent', agent);
      },
      loadMore: function loadMore() {
        this.sendAction('loadMore');
      }
    }
  });
});