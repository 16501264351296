define('freshfone-ember/components/nav-link-item/component', ['exports', 'ember', 'ember-cli-active-link-wrapper/components/active-link'], function (exports, _ember, _emberCliActiveLinkWrapperComponentsActiveLink) {
  var computed = _ember['default'].computed;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  var capitalize = _ember['default'].String.capitalize;
  exports['default'] = _emberCliActiveLinkWrapperComponentsActiveLink['default'].extend({
    classNames: ['navbar-primary-item'],
    //linkClass: 'navbar-primary-item-link',
    routing: _ember['default'].inject.service('-routing'),
    attributeBindings: ['data-analytics-action-name'],
    isToRedirectToAnotherUrl: computed.notEmpty('redirectUrl'),

    checkMyRouteName: _ember['default'].computed('routing.currentRouteName', function () {
      return this.get('routing.currentRouteName');
    }),
    linkClass: computed('checkMyRouteName', function () {
      var activeLinkChk = this.get('otherActiveLink');
      if (activeLinkChk && this.get('checkMyRouteName').includes(activeLinkChk)) {
        return 'navbar-primary-item-link active';
      }
      return 'navbar-primary-item-link';
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('[data-toggle=tooltip]').tooltip({ container: this.$() });
    },
    hasIcon: true,

    navAnalytics: computed('link', 'title', {
      get: function get() {
        return capitalize(this.get('title') || this.get('link')) + ' Page Load';
      }
    }),

    sideBarTitle: computed('title', 'componentName', function () {
      return this.get('componentName') + '_components.' + this.get('title');
    }),
    mainNavbarTitle: computed('title', function () {
      return 'navigation.' + this.get('title');
    }),
    iconClassBinding: computed('link', {
      get: function get() {
        var item = this.get('item');
        if (!item) {
          return;
        }
        return 'icon-nav-' + item + ' navbar-primary-item-icon';
      }
    })
  });
});