define('freshfone-ember/components/billing-base/credit-base/sales-tax-info-capture-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/utils/chargebee-country-region-constants'], function (exports, _ember, _emberValidations, _freshfoneEmberUtilsChargebeeCountryRegionConstants) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_emberValidations['default'], {
    classNames: ['box-style'],
    classNameBindings: ['isFormOpen:opened:closed', 'noBorder'],
    noBorder: computed.or('showingAddressCard', 'isInfoLoading'),
    showingAddressCard: computed('hasInfoAlreadyBeenCaptured', 'isFormOpen', function () {
      return this.get('hasInfoAlreadyBeenCaptured') && !this.get('isFormOpen');
    }),
    countries: computed(function () {
      return _freshfoneEmberUtilsChargebeeCountryRegionConstants['default'];
    }),
    shippingAddressCountry: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(key, value) {
        if (value) {
          this.set('shippingAddressStates', value.regions);

          if (value.regions && !value.regions.includes(this.get('shippingAddressState'))) {
            this.set('shippingAddressState', value.regions[0]);
          }
        }

        return value;
      }
    }),
    billingAddressCountry: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(key, value) {
        if (value) {
          this.set('billingAddressStates', value.regions);

          if (value.regions && !value.regions.includes(this.get('billingAddressState'))) {
            this.set('billingAddressState', value.regions[0]);
          }
        }

        return value;
      }
    }),
    shippingIsSameAsBilling: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(key, value) {
        if (value) {
          this.copyBillingInShipping();
        }

        return value;
      }
    }),
    copyBillingInShipping: function copyBillingInShipping() {
      this.setProperties({
        shippingAddressFirstName: this.get('billingAddressFirstName'),
        shippingAddressLastName: this.get('billingAddressLastName'),
        shippingAddressLine1: this.get('billingAddressLine1'),
        shippingAddressLine2: this.get('billingAddressLine2'),
        shippingAddressCity: this.get('billingAddressCity'),
        shippingAddressState: this.get('billingAddressState'),
        shippingAddressZip: this.get('billingAddressZip'),
        shippingAddressCountry: this.get('billingAddressCountry')
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        startValidate: false,
        shippingAddressCountry: this.get('countries.firstObject'),
        billingAddressCountry: this.get('countries.firstObject'),
        shippingIsSameAsBilling: true,
        isInfoLoading: true
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('session.account.subscription').getBillingInformation().done(function (_ref) {
        var vat_number = _ref.vat_number;
        var billing_address = _ref.billing_address;
        var shipping_address = _ref.shipping_address;

        _this.set('hasInfoAlreadyBeenCaptured', !!shipping_address);

        if (_this.get('hasInfoAlreadyBeenCaptured')) {
          var billingAddressCountry = _freshfoneEmberUtilsChargebeeCountryRegionConstants['default'].findBy('countryShortCode', billing_address.country),
              replacer = function replacer(k, v) {
            return k === 'object' ? undefined : v;
          },
              shippingIsSameAsBilling = JSON.stringify(shipping_address.values, replacer) === JSON.stringify(billing_address.values, replacer),
              shippingAddressCountry = shippingIsSameAsBilling ? billingAddressCountry : _freshfoneEmberUtilsChargebeeCountryRegionConstants['default'].findBy('countryShortCode', shipping_address.country);

          _this.setProperties({
            shippingIsSameAsBilling: shippingIsSameAsBilling,
            customerVatNumber: vat_number,
            billingAddressFirstName: billing_address.first_name,
            billingAddressLastName: billing_address.last_name,
            billingAddressLine1: billing_address.line1,
            billingAddressLine2: billing_address.line2,
            billingAddressCity: billing_address.city,
            billingAddressState: billing_address.state,
            billingAddressZip: billing_address.zip,
            billingAddressCountry: billingAddressCountry,
            shippingAddressFirstName: shipping_address.first_name,
            shippingAddressLastName: shipping_address.last_name,
            shippingAddressLine1: shipping_address.line1,
            shippingAddressLine2: shipping_address.line2,
            shippingAddressCity: shipping_address.city,
            shippingAddressState: shipping_address.state,
            shippingAddressZip: shipping_address.zip,
            shippingAddressCountry: shippingAddressCountry
          });
        }
      }).always(function () {
        return _this.set('isInfoLoading', false);
      });
    },
    actions: {
      submitForm: function submitForm() {
        var _this2 = this;

        this.setProperties({
          startValidate: true,
          serverError: undefined
        });

        this.validate().then(function () {
          var data = {
            subscription: {
              billing_address: {
                first_name: _this2.get('billingAddressFirstName'),
                last_name: _this2.get('billingAddressLastName'),
                line1: _this2.get('billingAddressLine1'),
                line2: _this2.get('billingAddressLine2'),
                city: _this2.get('billingAddressCity'),
                state: _this2.get('billingAddressState'),
                zip: _this2.get('billingAddressZip'),
                country: _this2.get('billingAddressCountry.countryShortCode')
              },
              customer: { vat_number: _this2.get('customerVatNumber') }
            }
          };

          if (!_this2.get('shippingIsSameAsBilling')) {
            data.subscription.shipping_address = {
              first_name: _this2.get('shippingAddressFirstName'),
              last_name: _this2.get('shippingAddressLastName'),
              line1: _this2.get('shippingAddressLine1'),
              line2: _this2.get('shippingAddressLine2'),
              city: _this2.get('shippingAddressCity'),
              state: _this2.get('shippingAddressState'),
              zip: _this2.get('shippingAddressZip'),
              country: _this2.get('shippingAddressCountry.countryShortCode')
            };
          }

          _this2.get('session.account.subscription').postBillingInformation(data).done(function () {
            if (_this2.get('shippingIsSameAsBilling')) {
              _this2.copyBillingInShipping();
            }
            _this2.set('hasInfoAlreadyBeenCaptured', true);
            _this2.send('toggleForm');
          }).fail(function (_ref2) {
            var errors = _ref2.responseJSON.errors;

            _this2.set('serverError', errors);
          });
        });
      },
      toggleForm: function toggleForm() {
        this.toggleProperty('isFormOpen');
        this.get('onToggle')();
      }
    },
    validations: {
      billingAddressFirstName: { presence: true },
      billingAddressLastName: { presence: true },
      billingAddressLine1: { presence: true },
      billingAddressCity: { presence: true },
      billingAddressState: { presence: true },
      billingAddressZip: { presence: true },
      billingAddressCountry: { presence: true },
      shippingAddressFirstName: { presence: { unless: 'shippingIsSameAsBilling' } },
      shippingAddressLastName: { presence: { unless: 'shippingIsSameAsBilling' } },
      shippingAddressLine1: { presence: { unless: 'shippingIsSameAsBilling' } },
      shippingAddressCity: { presence: { unless: 'shippingIsSameAsBilling' } },
      shippingAddressState: { presence: { unless: 'shippingIsSameAsBilling' } },
      shippingAddressZip: { presence: { unless: 'shippingIsSameAsBilling' } },
      shippingAddressCountry: { presence: { unless: 'shippingIsSameAsBilling' } }
    }
  });
});
/* eslint-disable camelcase */