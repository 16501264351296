define('freshfone-ember/components/common/call-flow-child-view/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/click-outside-listener'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsClickOutsideListener) {
  var Evented = _ember['default'].Evented;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var Component = _ember['default'].Component;
  var isEqual = _ember['default'].isEqual;
  exports['default'] = Component.extend(_emberValidations['default'], Evented, _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsClickOutsideListener['default'], {
    isChildFormOpen: false,
    outSideClickOnInit: false,
    ivrAncestorList: computed(function () {
      return [this.get('selectedAction')];
    }),
    isMessageSelected: computed('optionAction', function () {
      var messages = ['hangup', 'vm', 'message'];

      return messages.includes(this.get('optionAction.name'));
    }),
    isOtherChildOpen: computed('isChildOpen', 'isChildFormOpen', function () {
      return this.get('isChildOpen') && !this.get('isThisChildOpen');
    }),
    onOutsideClick: function onOutsideClick() {
      this.send('toggleChild');
      this.unbindOutsideClick();
    },
    closeAllChildNodes: function closeAllChildNodes(element) {
      element.closest('.app-action-wrapper').children().find('.closeChild').first().trigger('click');
    },
    isChildAFlow: computed.and('isChildFormOpen', 'isActionAFlow'),
    isChildPresent: computed.notEmpty('selectedAction'),
    showError: computed.not('isChildPresent'),
    isIvrSelected: computed.equal('optionAction.name', 'ivr'),
    isCallQueueSelected: computed.equal('optionAction.name', 'callQueue'),
    isRoutingAutomationSelected: computed.equal('optionAction.name', 'routingAutomation'),
    isActionAFlow: computed.or('isIvrSelected', 'isCallQueueSelected', 'isRoutingAutomationSelected'),
    customFilter: computed(function () {
      var parentContent = isEmpty(this.get('parentRecord.content')) ? this.get('parentRecord') : this.get('parentRecord.content');

      return function (modelData) {
        return modelData.filter(function (callflow) {
          return !isEqual(callflow, parentContent);
        });
      };
    }),
    createNewEntityLabel: computed('modelName', function () {
      var key = ['call_ivr', 'labels', 'create', this.get('modelName').decamelize()].join('.');

      return _ember['default'].i18n.t(key);
    }),
    selectedAction: computed('actionId', 'actionType', {
      get: function get() {
        var actionId = this.get('actionId');

        if (isEmpty(actionId)) {
          return this.get('modelRecord').get(this.get('actionAttribute'));
        }

        return this.findOrFetch(this.get('modelName'), actionId, ['routingAutomation', 'callQueue'].includes(this.get('modelName')));
      },

      set: function set(key, value) {
        this.send('onChanged', value);

        return value;
      }
    }),
    modelName: computed('actionType', function () {
      var actionType = this.get('actionType'),
          messages = ['hangup', 'vm'];

      if (messages.includes(actionType) || !actionType) {
        return 'message';
      }

      return actionType;
    }),
    handleEmptyAction: function handleEmptyAction() {
      if (!this.get('isActionAFlow')) {
        return;
      }
      if (this.get('isIvrSelected')) {
        return this.get('actionType.actionIvr');
      } else if (this.get('isActionCallQueue')) {
        return this.get('actionType.actionQueue');
      }
    },
    findOrFetch: function findOrFetch(modelName, recordId, shouldReload) {
      var record = this.store.peekRecord(modelName, recordId);

      if (shouldReload || !record) {
        return this.store.findRecord(modelName, recordId);
      }

      return record;
    },
    createNewChildFlow: function createNewChildFlow(model) {
      var newEntity = this.store.createRecord(model);

      if (model === 'routing-automation') {
        newEntity.createDefaultRoutingRule();
      }

      this.set('selectedAction', newEntity);
      this.send('toggleChild');
    },
    actions: {
      toggleChild: function toggleChild() {
        this.toggleProperty('isChildFormOpen');
        if (this.get('isChildFormOpen')) {
          this.set('isChildOpen', true);
          this.set('isThisChildOpen', true);
          this.closeAllChildNodes(this.$());
          this.bindOutsideClick();
        } else {
          this.set('isChildOpen', false);
          this.set('isThisChildOpen', false);
          this.unbindOutsideClick();
        }
      },
      createNew: function createNew() {
        this.createNewChildFlow(this.get('optionAction.name').dasherize());
      },
      onChanged: function onChanged(changedValue) {
        this.get('modelRecord').set(this.get('actionAttribute') + 'Id', changedValue.get('id') ? Number(changedValue.id) : null);
        this.get('modelRecord').set(this.get('actionAttribute'), changedValue);
      },
      toggleOtherChildOpen: function toggleOtherChildOpen() {
        this.toggleProperty('isThisChildOpen');
        this.toggleProperty('isChildOpen');
      },
      showChild: function showChild(action) {
        this.send('toggleChild');
        if (action.id) {
          action.reload();
        }
        this.set('action', action);
      }
    }
  });
});