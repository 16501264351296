define("freshfone-ember/components/live-calls/queued-calls-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 4
                },
                "end": {
                  "line": 24,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ui-list-header-item", [], ["class", "list-header-element col-management-header"], ["loc", [null, [23, 6], [23, 79]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 25,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["queued_call_list.customer"], [], ["loc", [null, [18, 32], [18, 63]]], 0, 0], "class", "list-header-element col-name-header"], ["loc", [null, [18, 4], [18, 109]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["queued_call_list.queue"], [], ["loc", [null, [19, 32], [19, 60]]], 0, 0], "class", "list-header-element col-metric-header col-queue-header"], ["loc", [null, [19, 4], [19, 125]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["queued_call_list.wait_time"], [], ["loc", [null, [20, 32], [20, 64]]], 0, 0], "class", "list-header-element col-wait-time-header"], ["loc", [null, [20, 4], [20, 115]]], 0, 0], ["inline", "ui-list-header-item", [], ["class", "list-header-element col-callback-header"], ["loc", [null, [21, 4], [21, 75]]], 0, 0], ["block", "if", [["get", "showMoreOptions", ["loc", [null, [22, 10], [22, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 4], [24, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 2
              },
              "end": {
                "line": 31,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "loading-wrapper");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "spinner");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 42,
                        "column": 16
                      },
                      "end": {
                        "line": 46,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "contact-company-detail");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                    return morphs;
                  },
                  statements: [["content", "item.contact.company.name", ["loc", [null, [44, 20], [44, 49]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 36,
                      "column": 12
                    },
                    "end": {
                      "line": 48,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "contact-name ellipsis");
                  var el3 = dom.createTextNode("\n                  ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [3]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createAttrMorph(element5, 'class');
                  morphs[2] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
                  morphs[3] = dom.createMorphAt(element5, 3, 3);
                  return morphs;
                },
                statements: [["inline", "custom-elements/avatar", [], ["type", "user", "charLimit", 1, "model", ["subexpr", "@mut", [["get", "item.contact", ["loc", [null, [37, 69], [37, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "32"], ["loc", [null, [37, 14], [37, 93]]], 0, 0], ["attribute", "class", ["concat", ["contact-info ", ["subexpr", "if", [["get", "item.contact.company.name", ["loc", [null, [38, 44], [38, 69]]], 0, 0, 0, 0], "has-company"], [], ["loc", [null, [38, 39], [38, 85]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "text-highlight", [["get", "searchString", ["loc", [null, [40, 35], [40, 47]]], 0, 0, 0, 0], ["get", "item.contact.name", ["loc", [null, [40, 48], [40, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 18], [40, 67]]], 0, 0], ["block", "if", [["get", "item.contact.company.name", ["loc", [null, [42, 22], [42, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [42, 16], [46, 23]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 48,
                      "column": 10
                    },
                    "end": {
                      "line": 55,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "icon-unknown");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "contact-info");
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2, "class", "contact-name");
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "text-highlight", [["get", "searchString", ["loc", [null, [52, 33], [52, 45]]], 0, 0, 0, 0], ["get", "item.from", ["loc", [null, [52, 46], [52, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 16], [52, 57]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 63,
                      "column": 12
                    },
                    "end": {
                      "line": 65,
                      "column": 12
                    }
                  },
                  "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "icon-call-api");
                  dom.setAttribute(el1, "data-toggle", "tooltip");
                  dom.setAttribute(el1, "data-placement", "top");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element4, 'data-original-title');
                  return morphs;
                },
                statements: [["attribute", "data-original-title", ["concat", [["subexpr", "t", ["queued_call_list.api_call"], [], ["loc", [null, [64, 103], [64, 136]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child3 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 68,
                      "column": 12
                    },
                    "end": {
                      "line": 70,
                      "column": 12
                    }
                  },
                  "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "icon-waiting-callback");
                  dom.setAttribute(el1, "data-toggle", "tooltip");
                  dom.setAttribute(el1, "data-placement", "top");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element3, 'data-original-title');
                  return morphs;
                },
                statements: [["attribute", "data-original-title", ["concat", [["subexpr", "t", ["queued_call_list.waiting_callback"], [], ["loc", [null, [69, 111], [69, 152]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child4 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    var child0 = (function () {
                      var child0 = (function () {
                        var child0 = (function () {
                          return {
                            meta: {
                              "revision": "Ember@2.8.3",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 82,
                                  "column": 26
                                },
                                "end": {
                                  "line": 84,
                                  "column": 26
                                }
                              },
                              "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                            },
                            isEmpty: false,
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                            ");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode("\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                              return morphs;
                            },
                            statements: [["inline", "t", ["live_dashboard.wqm.on_top"], [], ["loc", [null, [83, 28], [83, 61]]], 0, 0]],
                            locals: [],
                            templates: []
                          };
                        })();
                        var child1 = (function () {
                          return {
                            meta: {
                              "revision": "Ember@2.8.3",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 84,
                                  "column": 26
                                },
                                "end": {
                                  "line": 86,
                                  "column": 26
                                }
                              },
                              "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                            },
                            isEmpty: false,
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                            ");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode("\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                              return morphs;
                            },
                            statements: [["inline", "t", ["live_dashboard.wqm.move_to_top"], [], ["loc", [null, [85, 28], [85, 66]]], 0, 0]],
                            locals: [],
                            templates: []
                          };
                        })();
                        return {
                          meta: {
                            "revision": "Ember@2.8.3",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 80,
                                "column": 22
                              },
                              "end": {
                                "line": 88,
                                "column": 22
                              }
                            },
                            "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                          },
                          isEmpty: false,
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                        ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("li");
                            var el2 = dom.createTextNode("\n");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createComment("");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("                        ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element1 = dom.childAt(fragment, [1]);
                            var morphs = new Array(3);
                            morphs[0] = dom.createAttrMorph(element1, 'class');
                            morphs[1] = dom.createAttrMorph(element1, 'onclick');
                            morphs[2] = dom.createMorphAt(element1, 1, 1);
                            return morphs;
                          },
                          statements: [["attribute", "class", ["concat", ["list-item move-to-top ", ["subexpr", "if", [["subexpr", "eq", [["get", "index", ["loc", [null, [81, 66], [81, 71]]], 0, 0, 0, 0], 0], [], ["loc", [null, [81, 62], [81, 74]]], 0, 0], "disabled"], [], ["loc", [null, [81, 57], [81, 87]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "unless", [["subexpr", "eq", [["get", "index", ["loc", [null, [81, 110], [81, 115]]], 0, 0, 0, 0], 0], [], ["loc", [null, [81, 106], [81, 118]]], 0, 0], ["subexpr", "action", ["moveToTop", ["get", "item", ["loc", [null, [81, 139], [81, 143]]], 0, 0, 0, 0]], [], ["loc", [null, [81, 119], [81, 144]]], 0, 0]], [], ["loc", [null, [null, null], [81, 146]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "index", ["loc", [null, [82, 36], [82, 41]]], 0, 0, 0, 0], 0], [], ["loc", [null, [82, 32], [82, 44]]], 0, 0]], [], 0, 1, ["loc", [null, [82, 26], [86, 33]]]]],
                          locals: [],
                          templates: [child0, child1]
                        };
                      })();
                      var child1 = (function () {
                        var child0 = (function () {
                          return {
                            meta: {
                              "revision": "Ember@2.8.3",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 90,
                                  "column": 24
                                },
                                "end": {
                                  "line": 92,
                                  "column": 24
                                }
                              },
                              "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                            },
                            isEmpty: false,
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                          ");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode(" ");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createElement("span");
                              var el2 = dom.createTextNode("›");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode("\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                              return morphs;
                            },
                            statements: [["inline", "t", ["live_dashboard.wqm.assign_to_agent"], [], ["loc", [null, [91, 26], [91, 68]]], 0, 0]],
                            locals: [],
                            templates: []
                          };
                        })();
                        var child1 = (function () {
                          var child0 = (function () {
                            var child0 = (function () {
                              return {
                                meta: {
                                  "revision": "Ember@2.8.3",
                                  "loc": {
                                    "source": null,
                                    "start": {
                                      "line": 97,
                                      "column": 30
                                    },
                                    "end": {
                                      "line": 101,
                                      "column": 30
                                    }
                                  },
                                  "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                                },
                                isEmpty: false,
                                arity: 1,
                                cachedFragment: null,
                                hasRendered: false,
                                buildFragment: function buildFragment(dom) {
                                  var el0 = dom.createDocumentFragment();
                                  var el1 = dom.createTextNode("                                ");
                                  dom.appendChild(el0, el1);
                                  var el1 = dom.createElement("dd");
                                  dom.setAttribute(el1, "class", "list-item");
                                  var el2 = dom.createTextNode("\n                                  ");
                                  dom.appendChild(el1, el2);
                                  var el2 = dom.createComment("");
                                  dom.appendChild(el1, el2);
                                  var el2 = dom.createTextNode(" ");
                                  dom.appendChild(el1, el2);
                                  var el2 = dom.createComment("");
                                  dom.appendChild(el1, el2);
                                  var el2 = dom.createTextNode("\n                                ");
                                  dom.appendChild(el1, el2);
                                  dom.appendChild(el0, el1);
                                  var el1 = dom.createTextNode("\n");
                                  dom.appendChild(el0, el1);
                                  return el0;
                                },
                                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                  var element0 = dom.childAt(fragment, [1]);
                                  var morphs = new Array(3);
                                  morphs[0] = dom.createElementMorph(element0);
                                  morphs[1] = dom.createMorphAt(element0, 1, 1);
                                  morphs[2] = dom.createMorphAt(element0, 3, 3);
                                  return morphs;
                                },
                                statements: [["element", "action", ["assignCall", ["get", "item", ["loc", [null, [98, 76], [98, 80]]], 0, 0, 0, 0], ["subexpr", "if", [["subexpr", "not-eq", [["get", "agent.userId", ["loc", [null, [98, 93], [98, 105]]], 0, 0, 0, 0], ["get", "session.user.id", ["loc", [null, [98, 106], [98, 121]]], 0, 0, 0, 0]], [], ["loc", [null, [98, 85], [98, 122]]], 0, 0], ["get", "agent", ["loc", [null, [98, 123], [98, 128]]], 0, 0, 0, 0]], [], ["loc", [null, [98, 81], [98, 129]]], 0, 0]], [], ["loc", [null, [98, 54], [98, 131]]], 0, 0], ["inline", "custom-elements/avatar", [], ["type", "user", "model", ["subexpr", "@mut", [["get", "agent", ["loc", [null, [99, 77], [99, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [99, 34], [99, 94]]], 0, 0], ["content", "agent.name", ["loc", [null, [99, 95], [99, 109]]], 0, 0, 0, 0]],
                                locals: ["agent"],
                                templates: []
                              };
                            })();
                            return {
                              meta: {
                                "revision": "Ember@2.8.3",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 94,
                                    "column": 26
                                  },
                                  "end": {
                                    "line": 103,
                                    "column": 26
                                  }
                                },
                                "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                              },
                              isEmpty: false,
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("                            ");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createElement("dt");
                                var el2 = dom.createComment("");
                                dom.appendChild(el1, el2);
                                dom.appendChild(el0, el1);
                                var el1 = dom.createTextNode("\n                            ");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createElement("dl");
                                var el2 = dom.createTextNode("\n");
                                dom.appendChild(el1, el2);
                                var el2 = dom.createComment("");
                                dom.appendChild(el1, el2);
                                var el2 = dom.createTextNode("                            ");
                                dom.appendChild(el1, el2);
                                dom.appendChild(el0, el1);
                                var el1 = dom.createTextNode("\n");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var morphs = new Array(2);
                                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                                return morphs;
                              },
                              statements: [["inline", "t", ["agent_availability.available_agents"], ["count", ["subexpr", "@mut", [["get", "assignableAgents.length", ["loc", [null, [95, 80], [95, 103]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [95, 32], [95, 105]]], 0, 0], ["block", "each", [["get", "assignableAgents", ["loc", [null, [97, 38], [97, 54]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [97, 30], [101, 39]]]]],
                              locals: [],
                              templates: [child0]
                            };
                          })();
                          var child1 = (function () {
                            return {
                              meta: {
                                "revision": "Ember@2.8.3",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 103,
                                    "column": 26
                                  },
                                  "end": {
                                    "line": 105,
                                    "column": 26
                                  }
                                },
                                "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                              },
                              isEmpty: false,
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("                            ");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createComment("");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createTextNode("\n");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var morphs = new Array(1);
                                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                                return morphs;
                              },
                              statements: [["inline", "t", ["live_dashboard.wqm.no_assignable_agents"], [], ["loc", [null, [104, 28], [104, 75]]], 0, 0]],
                              locals: [],
                              templates: []
                            };
                          })();
                          return {
                            meta: {
                              "revision": "Ember@2.8.3",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 93,
                                  "column": 24
                                },
                                "end": {
                                  "line": 106,
                                  "column": 24
                                }
                              },
                              "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                            },
                            isEmpty: false,
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [["block", "if", [["get", "assignableAgents.length", ["loc", [null, [94, 32], [94, 55]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [94, 26], [105, 33]]]]],
                            locals: [],
                            templates: [child0, child1]
                          };
                        })();
                        return {
                          meta: {
                            "revision": "Ember@2.8.3",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 89,
                                "column": 22
                              },
                              "end": {
                                "line": 107,
                                "column": 22
                              }
                            },
                            "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                          },
                          isEmpty: false,
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(2);
                            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [["block", "rl-dropdown-toggle", [], ["tagName", "span"], 0, null, ["loc", [null, [90, 24], [92, 47]]]], ["block", "rl-dropdown", [], ["tagName", "dl", "class", ["subexpr", "concat", ["dropdown-menu ", ["subexpr", "unless", [["get", "assignableAgents.length", ["loc", [null, [93, 91], [93, 114]]], 0, 0, 0, 0], "empty"], [], ["loc", [null, [93, 83], [93, 123]]], 0, 0]], [], ["loc", [null, [93, 58], [93, 124]]], 0, 0]], 1, null, ["loc", [null, [93, 24], [106, 40]]]]],
                          locals: [],
                          templates: [child0, child1]
                        };
                      })();
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 79,
                              "column": 20
                            },
                            "end": {
                              "line": 108,
                              "column": 20
                            }
                          },
                          "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(2);
                          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [["block", "if", [["get", "hasMoveToTopFeature", ["loc", [null, [80, 28], [80, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [80, 22], [88, 29]]]], ["block", "rl-dropdown-container", [], ["tagName", "li", "class", "list-item assign-to-agent dropdown-submenu", "onOpen", ["subexpr", "action", ["onWqmAgentListOpen", ["get", "item", ["loc", [null, [89, 147], [89, 151]]], 0, 0, 0, 0]], [], ["loc", [null, [89, 118], [89, 152]]], 0, 0]], 1, null, ["loc", [null, [89, 22], [107, 48]]]]],
                        locals: [],
                        templates: [child0, child1]
                      };
                    })();
                    var child1 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 111,
                              "column": 22
                            },
                            "end": {
                              "line": 113,
                              "column": 22
                            }
                          },
                          "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                        (");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode(")\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                          return morphs;
                        },
                        statements: [["inline", "t", ["live_dashboard.wqm.current_agent_offline"], [], ["loc", [null, [112, 25], [112, 73]]], 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 78,
                            "column": 18
                          },
                          "end": {
                            "line": 115,
                            "column": 18
                          }
                        },
                        "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("li");
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("                    ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element2 = dom.childAt(fragment, [2]);
                        var morphs = new Array(5);
                        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                        morphs[1] = dom.createAttrMorph(element2, 'onclick');
                        morphs[2] = dom.createAttrMorph(element2, 'class');
                        morphs[3] = dom.createMorphAt(element2, 1, 1);
                        morphs[4] = dom.createMorphAt(element2, 3, 3);
                        dom.insertBoundary(fragment, 0);
                        return morphs;
                      },
                      statements: [["block", "unless", [["get", "isAgent", ["loc", [null, [79, 30], [79, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [79, 20], [108, 31]]]], ["attribute", "onclick", ["subexpr", "if", [["get", "isCurrentAgentAvailable", ["loc", [null, [109, 37], [109, 60]]], 0, 0, 0, 0], ["subexpr", "action", ["takeCall", ["get", "item", ["loc", [null, [109, 80], [109, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [109, 61], [109, 85]]], 0, 0]], [], ["loc", [null, [null, null], [109, 87]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["take-call list-item ", ["subexpr", "unless", [["get", "isCurrentAgentAvailable", ["loc", [null, [109, 124], [109, 147]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [109, 115], [109, 160]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["live_dashboard.wqm.take_call"], [], ["loc", [null, [110, 22], [110, 58]]], 0, 0], ["block", "unless", [["get", "isCurrentAgentAvailable", ["loc", [null, [111, 32], [111, 55]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [111, 22], [113, 33]]]]],
                      locals: [],
                      templates: [child0, child1]
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 76,
                          "column": 16
                        },
                        "end": {
                          "line": 116,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["inline", "rl-dropdown-toggle", [], ["tagName", "div", "class", "icon-more-actions"], ["loc", [null, [77, 18], [77, 80]]], 0, 0], ["block", "rl-dropdown", [], ["tagName", "ul", "class", "dropdown-menu wqm-actions", "closeOnChildClick", ".list-item:not(.assign-to-agent)"], 0, null, ["loc", [null, [78, 18], [115, 34]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 75,
                        "column": 14
                      },
                      "end": {
                        "line": 117,
                        "column": 14
                      }
                    },
                    "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "rl-dropdown-container", [], ["class", "wqm-actions-container", "onOpen", ["subexpr", "action", ["positionWqmActions"], [], ["loc", [null, [76, 78], [76, 107]]], 0, 0]], 0, null, ["loc", [null, [76, 16], [116, 42]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 121,
                          "column": 16
                        },
                        "end": {
                          "line": 123,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "t", ["live_dashboard.wqm.call_being_assigned_to"], ["agentName", ["subexpr", "get", [["get", "telephony", ["loc", [null, [122, 81], [122, 90]]], 0, 0, 0, 0], ["subexpr", "concat", ["attemptedWqmCallAssignments.", ["get", "item.id", ["loc", [null, [122, 130], [122, 137]]], 0, 0, 0, 0], ".name"], [], ["loc", [null, [122, 91], [122, 146]]], 0, 0]], [], ["loc", [null, [122, 76], [122, 147]]], 0, 0]], ["loc", [null, [122, 18], [122, 149]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 123,
                          "column": 16
                        },
                        "end": {
                          "line": 125,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "t", ["live_dashboard.wqm.call_being_assigned"], [], ["loc", [null, [124, 18], [124, 64]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 119,
                        "column": 12
                      },
                      "end": {
                        "line": 127,
                        "column": 12
                      }
                    },
                    "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "call-being-assigned ellipsis");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "get", [["get", "telephony", ["loc", [null, [121, 27], [121, 36]]], 0, 0, 0, 0], ["subexpr", "concat", ["attemptedWqmCallAssignments.", ["get", "item.id", ["loc", [null, [121, 76], [121, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [121, 37], [121, 84]]], 0, 0]], [], ["loc", [null, [121, 22], [121, 85]]], 0, 0]], [], 0, 1, ["loc", [null, [121, 16], [125, 23]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 10
                    },
                    "end": {
                      "line": 128,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("td");
                  dom.setAttribute(el1, "class", "content-col management");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "unless", [["get", "item.isQueuedRinging", ["loc", [null, [75, 24], [75, 44]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [75, 14], [117, 25]]]], ["block", "if", [["get", "item.isQueuedRinging", ["loc", [null, [119, 18], [119, 38]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [119, 12], [127, 19]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 6
                  },
                  "end": {
                    "line": 130,
                    "column": 6
                  }
                },
                "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
              },
              isEmpty: false,
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "content-col contact");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "content-col queue ellipsis");
                dom.setAttribute(el2, "data-toggle", "tooltip");
                dom.setAttribute(el2, "data-placement", "right");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "content-col wait-time");
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "call-category");
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("td");
                dom.setAttribute(el3, "class", "content-col api-call");
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("          ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("td");
                dom.setAttribute(el3, "class", "content-col callback");
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("          ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var element7 = dom.childAt(element6, [3]);
                var element8 = dom.childAt(element6, [7]);
                var morphs = new Array(9);
                morphs[0] = dom.createAttrMorph(element6, 'class');
                morphs[1] = dom.createAttrMorph(element6, 'id');
                morphs[2] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
                morphs[3] = dom.createAttrMorph(element7, 'data-original-title');
                morphs[4] = dom.createMorphAt(element7, 0, 0);
                morphs[5] = dom.createMorphAt(dom.childAt(element6, [5]), 1, 1);
                morphs[6] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
                morphs[7] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
                morphs[8] = dom.createMorphAt(element6, 9, 9);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["content-row ", ["subexpr", "if", [["get", "item.isFlash", ["loc", [null, [34, 36], [34, 48]]], 0, 0, 0, 0], "flashing"], [], ["loc", [null, [34, 31], [34, 61]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "item.id", ["loc", [null, [34, 68], [34, 75]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "item.contact", ["loc", [null, [36, 18], [36, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [36, 12], [55, 17]]]], ["attribute", "data-original-title", ["get", "item.flowName", ["loc", [null, [57, 116], [57, 129]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "item.flowName", ["loc", [null, [57, 132], [57, 149]]], 0, 0, 0, 0], ["inline", "seconds-format", [["get", "item.waitTime", ["loc", [null, [59, 29], [59, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 12], [59, 44]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "item.category", ["loc", [null, [63, 22], [63, 35]]], 0, 0, 0, 0], "api_call"], [], ["loc", [null, [63, 18], [63, 47]]], 0, 0]], [], 2, null, ["loc", [null, [63, 12], [65, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "item.category", ["loc", [null, [68, 22], [68, 35]]], 0, 0, 0, 0], "callback"], [], ["loc", [null, [68, 18], [68, 47]]], 0, 0]], [], 3, null, ["loc", [null, [68, 12], [70, 19]]]], ["block", "if", [["subexpr", "and", [["get", "showMoreOptions", ["loc", [null, [73, 21], [73, 36]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isCallBarging1CxDisabled", ["loc", [null, [73, 42], [73, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [73, 37], [73, 67]]], 0, 0]], [], ["loc", [null, [73, 16], [73, 68]]], 0, 0]], [], 4, null, ["loc", [null, [73, 10], [128, 17]]]]],
              locals: ["item", "index"],
              templates: [child0, child1, child2, child3, child4]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 4
                },
                "end": {
                  "line": 132,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "ui-list-item", [], ["class", "list-body", "items", ["subexpr", "@mut", [["get", "pagedCallList", ["loc", [null, [33, 46], [33, 59]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [33, 6], [130, 23]]]], ["inline", "page-numbers", [], ["content", ["subexpr", "@mut", [["get", "pagedCallList", ["loc", [null, [131, 29], [131, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "showFL", true], ["loc", [null, [131, 6], [131, 56]]], 0, 0]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 132,
                  "column": 4
                },
                "end": {
                  "line": 136,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "empty-calls");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "content-middle");
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["queued_call_list.no_calls"], [], ["loc", [null, [134, 37], [134, 70]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 2
              },
              "end": {
                "line": 137,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasCalls", ["loc", [null, [32, 10], [32, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [32, 4], [136, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 138,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ui-list-header", [], ["class", "list-header"], 0, null, ["loc", [null, [17, 2], [25, 21]]]], ["block", "if", [["get", "listLoading", ["loc", [null, [27, 8], [27, 19]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [27, 2], [137, 9]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 141,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/live-calls/queued-calls-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "count");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "metric-label");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "metric-value");
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [3]);
        var element12 = dom.childAt(element9, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element11, 'class');
        morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]), 1, 1);
        morphs[3] = dom.createAttrMorph(element12, 'class');
        morphs[4] = dom.createMorphAt(dom.childAt(element12, [1]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element12, [3]), 1, 1);
        morphs[6] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "t", ["queued_call_list.in_queue"], [], ["loc", [null, [4, 12], [4, 45]]], 0, 0], ["attribute", "class", ["concat", ["live-count ", ["subexpr", "unless", [["get", "hasCalls", ["loc", [null, [5, 36], [5, 44]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [5, 27], [5, 53]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "queuedCallsCount", ["loc", [null, [7, 8], [7, 28]]], 0, 0, 0, 0], ["attribute", "class", ["concat", ["avg-wait-time ", ["subexpr", "unless", [["get", "hasCalls", ["loc", [null, [11, 37], [11, 45]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [11, 28], [11, 54]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["queued_call_list.avg_wait_time"], [], ["loc", [null, [12, 33], [12, 71]]], 0, 0], ["inline", "seconds-format", [["get", "avgWaitTime", ["loc", [null, [13, 48], [13, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 31], [13, 61]]], 0, 0], ["block", "ui-list", [], ["class", "list-container"], 0, null, ["loc", [null, [16, 0], [138, 12]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});