define('freshfone-ember/components/ui-modal/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var getWithDefault = _ember['default'].getWithDefault;
  var isEqual = _ember['default'].isEqual;
  var run = _ember['default'].run;
  var testing = _ember['default'].testing;
  var typeOf = _ember['default'].typeOf;
  exports['default'] = Component.extend({
    testing: testing,
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('animateClose')) {
        this.set('animatedCloseOnEscapeHandler', this.get('handleAnimatedCloseOnEscape').bind(this));
        this.set('animatedBackdropClickHandler', this.get('handleAnimatedBackdropClick').bind(this));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.createModal();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.destroyModal();
    },

    // When component is rendered in the DOM, trigger modal open using bootstrap $.modal() approach.
    createModal: function createModal() {
      var _this = this;

      var $modal = this.$();

      var closeOnBackdropClick = 'static',
          closeOnEscape = false;

      // we add our own event listeners for closeOnBackdropCLick & closeOnEsc in case of slider-modal
      if (!this.get('animateClose') || testing) {
        closeOnBackdropClick = isEqual(this.get('closeOnBackdropClick'), false) ? 'static' : true;
        closeOnEscape = this.get('closeOnEscape');
      }

      run.next(function () {
        $modal.modal({
          keyboard: closeOnEscape,
          backdrop: closeOnBackdropClick
        }).one('hidden.bs.modal', function () {
          if (_this.get('isModalOpen')) {
            _this.send('close');
          }
        });

        if (!_this.get('isModalOpen') && _this.$()) {
          _this.$().modal('hide');
        }
      });
    },
    // Event that destroys the modal from the service stack and destroys the modal object. This'll hide the modal
    destroyModal: function destroyModal(triggerAction, params) {
      var modal = getWithDefault(this, 'modal', false);

      if (modal) {
        return modal.deleteFromStack(triggerAction, params);
      }

      this.$() && this.$().modal('hide').data('bs.modal', null);
    },

    classNames: ['modal'],
    classNameBindings: ['flavour'],
    attributeBindings: ['role', 'tabindex'],
    tabindex: '-1',
    role: 'dialog',
    isModalOpen: true,
    hasBlock: computed.bool('template').readOnly(),

    size: computed('modal.size', 'size', function () {
      return this.get('modal.size') || this.get('size');
    }),
    // Computes the type of modal class that needs to be appended in the modal DOM.
    flavour: computed('modal.type', {
      get: function get() {
        return this.get('modal.type') + ' modal-' + this.get('modal.type');
      }
    }),
    // needs refactoring
    modalClasses: computed(function () {
      var modalClasses = '';

      if (this.get('filterModal')) {
        modalClasses += ' filter-modal';
      }
      if (this.get('callDetailsModal')) {
        modalClasses += ' call-details-modal';
      }
      if (this.get('exportDetailsModal')) {
        modalClasses += ' export-details-modal';
      }
      if (this.get('sliderModal')) {
        modalClasses += ' slider-modal';
      }

      return modalClasses.trim();
    }),
    // Holds value of closeOnEscape from defaults/options.
    closeOnEscape: computed('modal.closeOnEscape', 'closeOnEscape', function () {
      return this.get('modal.closeOnEscape') || false;
    }),
    // Holds value of closeOnBackdropClick from defaults/options.
    closeOnBackdropClick: computed('modal.closeOnBackdropClick', 'closeOnBackdropClick', function () {
      return this.get('modal.closeOnBackdropClick') || false;
    }),

    // this'll change the modal from hidden state
    modalOpen: computed('isModalOpen', function () {
      if (this.get('isModalOpen') && this.$()) {
        this.showModal();
      } else {
        this.hideModal();
      }

      return this.get('isModalOpen');
    }),

    showModal: function showModal() {
      this.$().modal('show');
      if (!testing && this.get('animateClose')) {
        this.addEventListeners();
      }
    },
    hideModal: function hideModal() {
      var _this2 = this;

      var isModalBeingShown = this.$() && this.$().hasClass('in');

      if (!testing && this.get('animateClose')) {
        this.removeEventListeners();
        if (isModalBeingShown) {
          setTimeout(function () {
            return _this2.$().modal('hide');
          }, 200);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isModalBeingShown) {
          this.$().modal('hide');
        }
      }
    },
    addEventListeners: function addEventListeners() {
      var _this3 = this;

      run.later(function () {
        if (_this3.get('closeOnBackdropClick')) {
          _this3.element.addEventListener('click', _this3.get('animatedBackdropClickHandler'));
        }
        if (_this3.get('closeOnEscape')) {
          _this3.element.addEventListener('keydown', _this3.get('animatedCloseOnEscapeHandler'));
        }
      }, 1);
    },
    removeEventListeners: function removeEventListeners() {
      var _this4 = this;

      run.later(function () {
        _this4.element.removeEventListener('click', _this4.get('animatedBackdropClickHandler'));
        _this4.element.removeEventListener('keydown', _this4.get('animatedCloseOnEscapeHandler'));
      }, 1);
    },
    handleAnimatedCloseOnEscape: function handleAnimatedCloseOnEscape(e) {
      var code = e.keyCode || e.which;

      if (code === 27) {
        this.send('close');
      }
    },
    handleAnimatedBackdropClick: function handleAnimatedBackdropClick(e) {
      var _$ = this.$('.modal-dialog');

      var _$2 = _slicedToArray(_$, 1);

      var modalDialog = _$2[0];
      var modalDialogStartPosition = modalDialog.getBoundingClientRect().x;

      if (e.detail && e.isTrusted && modalDialog && modalDialogStartPosition && e.clientX < modalDialogStartPosition) {
        var onBackdropClick = this.get('onBackdropClick');

        if (typeOf(onBackdropClick) === 'function') {
          return onBackdropClick(e);
        }

        this.send('closeSliderModal');
      }
    },

    actions: {
      // Capture confirm action and destroy the modal.
      confirm: function confirm(triggerAction) {
        for (var _len = arguments.length, params = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          params[_key - 1] = arguments[_key];
        }

        return this.destroyModal(triggerAction, params);
      },

      // Capture close action and destroy the modal.
      close: function close(triggerAction) {
        for (var _len2 = arguments.length, params = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          params[_key2 - 1] = arguments[_key2];
        }

        this.destroyModal(triggerAction, params);
        this.set('isModalOpen', false);
        if (this.get('closeCallback')) {
          this.get('closeCallback')();
        }
      },
      closeSliderModal: function closeSliderModal() {
        if (!this.get('isSlideToggleReadOnly')) {
          this.set('isModalOpen', false);
        }
        if (this.get('onClose')) {
          this.get('onClose')();
        }
      }
    }
  });
});