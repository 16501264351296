define('freshfone-ember/components/custom-elements/lazy-load/component', ['exports', 'ember', 'freshfone-ember/mixins/safe-set'], function (exports, _ember, _freshfoneEmberMixinsSafeSet) {
  var ajax = _ember['default'].$.ajax;
  var Component = _ember['default'].Component;
  var assert = _ember['default'].assert;
  var isArray = _ember['default'].isArray;
  var run = _ember['default'].run;
  var typeOf = _ember['default'].typeOf;
  var notEmpty = _ember['default'].computed.notEmpty;
  var SENITEL_ID = '#senitel';exports['default'] = Component.extend(_freshfoneEmberMixinsSafeSet['default'], {
    elementId: 'lazy-load',

    hasModel: notEmpty('model'),
    hasURL: notEmpty('url'),

    didInsertElement: function didInsertElement() {
      this.bindIntersectionObserver();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.bindIntersectionObserver();
    },
    bindIntersectionObserver: function bindIntersectionObserver() {
      if (!this.get('hasModel') && !this.get('hasURL')) {
        assert('err, need either a `model` or `url` to fetch items lazily -_-');
      }

      var intersectionObserverOptions = {
        root: this.element.querySelector('#lazy-load'),
        rootMargin: '0px',
        threshold: 1.0
      },
          intesectionObserver = new IntersectionObserver(this.intersectionHandler.bind(this), intersectionObserverOptions),
          senitel = this.element.querySelector(SENITEL_ID);

      intesectionObserver.observe(senitel);

      this.setProperties({
        intesectionObserver: intesectionObserver,
        loading: false,
        thresholdReached: false
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('intesectionObserver').disconnect();
    },

    intersectionHandler: function intersectionHandler(entries) {
      var _this = this;

      if (!this.get('thresholdReached') && !this.get('loading') && entries[0].isIntersecting) {
        run(function () {
          _this.set('loading', true);
          _this.loadItems().then(function (res) {
            return _this.onSuccess(res);
          })['catch'](function (err) {
            // eslint-disable-next-line no-console
            console.trace(err);
            _this.onFailed(err);
          })['finally'](function () {
            return _this.set('loading', false);
          });
        });
      }
    },
    loadItems: function loadItems() {
      if (this.get('hasModel')) {
        return this.store.query(this.get('model'), this.get('queryParams') || {});
      }

      return ajax(this.get('url'), this.get('ajaxOptions') || {});
    },
    onSuccess: function onSuccess(res) {
      var _this2 = this;

      /**
       * for now we are pushing records directly into the `records` array
       * we can add a callback here incase we wanna do some preprocessing on the records
       * instead of pushing directly
       */
      if (isArray(this.get('records'))) {
        run(function () {
          _this2.get('records').pushObjects(res.toArray());
        });
      }

      var meta = res.meta;

      if (meta) {
        if (meta.current >= meta['total-pages']) {
          this.set('thresholdReached', true);
        } else if (this.get('queryParams')) {
          this.set('queryParams.page', meta.current + 1);
        }
      }
    },
    onFailed: function onFailed() {
      if (typeOf(this.get('onFailed')) === 'function') {
        this.get('onFailed')();
      }
    }
  });
});