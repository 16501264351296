define('freshfone-ember/models/todo', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    body: attr(),
    user: belongsTo('user'),
    created_at: attr(),
    completed: attr(),

    isCompleted: computed('completed', {
      get: function get() {
        return this.get('completed');
      },
      set: function set(key, value) {
        this.set('completed', value);
        this.save(); // model.save() on change
        return value;
      }

    })
  });
});