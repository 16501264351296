define('freshfone-ember/components/outgoing-widget/recent-calls/power-dialer/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    hasPoweDialerContacts: computed.gt('totalContactsCount', 0),
    powerDialerService: service('power-dialer-list'),
    telephony: service(),
    isCallsInParkedState: computed.alias('telephony.isCallsInParkedState'),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.get('powerDialerService.startPowerDialer')) this.send('startPowerDialer');
    },
    actions: {
      dial: function dial(call) {
        this.sendAction('dial', call);
      },
      startPowerDialer: function startPowerDialer() {
        var _this = this;

        if (this.get('isCallsInParkedState')) {
          return;
        }

        this.store.peekAll('power-dialer-list').get('lastObject').start().then(function () {
          _this.get('powerDialerService').setPowerDialer(false);
          _this.sendAction('fetchNext');
        });
      }
    }
  });
});