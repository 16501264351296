define('freshfone-ember/components/billing-base/credit-actions/credit-autorecharge-threshold/component', ['exports', 'freshfone-ember/mixins/currency-mixin', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember'], function (exports, _freshfoneEmberMixinsCurrencyMixin, _emberRlDropdownMixinsRlDropdownComponent, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_emberRlDropdownMixinsRlDropdownComponent['default'], _freshfoneEmberMixinsCurrencyMixin['default'], {
    classNames: ['credit-autorecharge-threshold'],
    classNameBindings: ['purchased:flash'],
    isAccountBlocked: computed.reads('session.account.blocked'),
    thresholdAmountInUSD: computed('thresholdAmount', 'subscription', function () {
      return Math.round(this.get('thresholdAmount') / this.get('currencyConversion')[this.get('subscription.currency')] * 100) / 100;
    }),
    resetPurchaseAction: function resetPurchaseAction() {
      this.setProperties({
        isSaving: true,
        purchased: false,
        autoRechargeThresholdError: null
      });
    },
    resetDropdown: function resetDropdown() {
      this.setProperties({
        dropdownExpanded: false,
        isSaving: false
      });
    },
    enableAutoRechargeThreshold: function enableAutoRechargeThreshold() {
      var _this = this;

      /* eslint-disable camelcase */
      this.get('credits.content').enableAutoRechargeThreshold({ auto_recharge_threshold: this.get('thresholdAmount') }).then(function (response) {
        _this.get('credits').set('autoRechargeThreshold', response.data.attributes['auto-recharge-threshold']);
        _this.set('purchased', true);
      })['catch'](function (error) {
        return _this.set('autoRechargeThresholdError', error.message || error.responseText);
      })['finally'](function () {
        return _this.resetDropdown();
      });
    },
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('dropdownExpanded');
        this.set('autoRechargeThresholdError', null);
      },
      saveAutoRechargeThreshold: function saveAutoRechargeThreshold() {
        this.resetPurchaseAction();
        this.enableAutoRechargeThreshold();
      }
    }
  });
});