define('freshfone-ember/components/agent-performance-metrics/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    currentUser: computed.alias('session.user'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('timeRange', ['day', 'month']);
      this.set('selectedRange', 'day');
      this.set('agentCallMetrics', {
        incoming: {
          total: 0,
          answered: 0,
          missed: 0
        },
        outgoing: {
          total: 0,
          answered: 0,
          missed: 0
        }
      });
      this.fetchAgentCallMetrics();
    },

    fetchAgentCallMetrics: function fetchAgentCallMetrics() {
      var _this = this;

      var range = arguments.length <= 0 || arguments[0] === undefined ? 'day' : arguments[0];

      this.set('agentCallMetricsLoading', true);
      this.get('currentUser').fetchCallsStats({ type: range }).then(function (callStats) {
        _this.set('agentCallMetrics', _this.constructAgentCallMetrics(callStats));
        _this.set('selectedRange', range);
      })['catch'](function (err) {
        // eslint-disable-next-line no-console
        console.log('fetching agent calls metrics failed', err);
      })['finally'](function () {
        _this.set('agentCallMetricsLoading', false);
      });
    },
    constructAgentCallMetrics: function constructAgentCallMetrics(callStats) {
      return {
        incoming: {
          total: callStats.incoming_total,
          answered: callStats.incoming_answered,
          missed: callStats.incoming_missed,
          voicemail: callStats.incoming_voicemail
        },
        outgoing: {
          total: callStats.outgoing_total,
          answered: callStats.outgoing_answered,
          missed: callStats.outgoing_missed
        }
      };
    },
    actions: {
      getAgentCallMetrics: function getAgentCallMetrics(timeRange) {
        this.fetchAgentCallMetrics(timeRange);
      }
    }
  });
});