define('freshfone-ember/components/custom-elements/date-range-v1/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/constants/call-logs-filter'], function (exports, _ember, _emberValidations, _freshfoneEmberConstantsCallLogsFilter) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var service = _ember['default'].inject.service;
  var moment = window.moment;
  exports['default'] = Component.extend(_emberValidations['default'], {
    i18n: service(),
    classNames: ['date-range-picker'],
    today: new Date(),
    calendarToggler: '.date-options .input-group.custom-range',

    dateRangeOptions: {
      'custom_elements.date_range.today': {
        startDate: moment(),
        endDate: '',
        timePeriod: _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodToday,
        label: 'Today-g'
      },
      'custom_elements.date_range.yesterday': {
        startDate: moment().subtract(1, 'days'),
        endDate: '',
        timePeriod: _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodYesterday,
        label: 'Yesterday-g'
      },
      'custom_elements.date_range.last_7_days': {
        startDate: moment().subtract(6, 'days'),
        endDate: moment(),
        timePeriod: _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodLastSevenDays,
        label: 'Last 7-g'
      },
      'custom_elements.date_range.last_30_days': {
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
        timePeriod: _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodLastThirtyDays,
        label: 'Last 30-g'
      }
    },

    formatedDate: computed('selectedRange.{start,end,timeperiod}', function () {
      var endDate = this.formatDateValue(this.get('selectedRange.end')),
          startDate = this.formatDateValue(this.get('selectedRange.start')),
          formattedDateString = isPresent(endDate) ? [startDate, endDate].join(' - ') : startDate;

      if (this.get('selectedRange.timeperiod') !== _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodCustomRange) {
        return this.get('i18n').t(this.get('selectedOption')) + '(' + formattedDateString + ')';
      }

      return formattedDateString;
    }),
    rangeValue: computed('selectedRange.{start,end,timeperiod}', {
      get: function get() {
        var endDate = this.get('selectedRange.end') || '',
            startDate = this.get('selectedRange.start'),
            timeperiod = this.get('selectedRange.timeperiod');

        return [startDate, endDate, timeperiod];
      },

      set: function set(key, value) {
        if (!value.length) {
          this.setLastSevenDaysDefault();
        } else {
          var timePeriodLabel = value[2];

          if (timePeriodLabel !== _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodCustomRange) {
            var selectedOptionKey = this.savedFilterKey(timePeriodLabel);

            this.setProperties({
              selectedOption: selectedOptionKey,
              activeDateRangeLabel: selectedOptionKey ? this.get('dateRangeOptions')[selectedOptionKey].label : ''
            });
          }
          this.set('selectedRange', {
            start: value[0],
            end: value[1],
            timeperiod: value[2]
          });
        }
      }
    }),

    setLastSevenDaysDefault: function setLastSevenDaysDefault() {
      var lastSevenDaysKey = 'custom_elements.date_range.last_7_days',
          selectedOption = this.get('dateRangeOptions')[lastSevenDaysKey],
          start = selectedOption.startDate,
          end = selectedOption.endDate,
          timeperiod = selectedOption.timePeriod;

      this.setProperties({
        selectedRange: {
          start: start,
          end: end,
          timeperiod: timeperiod
        },
        activeDateRangeLabel: '',
        selectedOption: lastSevenDaysKey
      });
    },

    savedFilterKey: function savedFilterKey(timePeriodLabel) {
      for (var key in this.get('dateRangeOptions')) {
        if (this.get('dateRangeOptions')[key].timePeriod === timePeriodLabel) {
          return key;
        }
      }
    },

    toggleDateRangeFromOptions: function toggleDateRangeFromOptions(key) {
      var selectedOption = this.get('dateRangeOptions')[key],
          start = selectedOption.startDate,

      // eslint-disable-next-line sort-vars
      end = selectedOption.endDate,
          timeperiod = selectedOption.timePeriod;

      this.set('selectedOption', key);

      if (selectedOption.label === this.get('activeDateRangeLabel')) {
        this.setLastSevenDaysDefault();

        return;
      }

      this.set('selectedRange', {
        start: start,
        end: end,
        timeperiod: timeperiod
      });
      this.set('activeDateRangeLabel', selectedOption.label);
    },
    formatDateValue: function formatDateValue(date) {
      if (isPresent(date) && typeof date === 'object') {
        return date.format('DD MMM YYYY');
      }

      return date;
    },
    closeCalendar: function closeCalendar() {
      if ($('.datepicker-wrapper').is(':visible')) {
        $(this.get('calendarToggler')).trigger('click');
      }
    },

    actions: {
      selectOption: function selectOption(key) {
        this.toggleDateRangeFromOptions(key);
        this.set('isOpen', false);
      },
      resetActiveRange: function resetActiveRange() {
        this.set('activeDateRangeLabel', '');
      },
      openHandler: function openHandler() {
        this.closeCalendar();
      }
    }
  });
});
/* eslint-disable ember-suave/prefer-destructuring */
/* eslint-disable prefer-destructuring */