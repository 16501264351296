define('freshfone-ember/initializers/xhr-interceptor', ['exports'], function (exports) {
  exports.initialize = initialize;
  /* eslint-disable sort-vars */

  function initialize() {
    var originalOpen = XMLHttpRequest.prototype.open,
        rootURL = localStorage.getItem('rootURL');

    if (rootURL) {
      XMLHttpRequest.prototype.open = function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var url = args[1];

        if (url.startsWith('/')) {
          args[1] = rootURL + url.slice(1);
        }

        return originalOpen.apply(this, args);
      };
    }
  }

  exports['default'] = { initialize: initialize };
});