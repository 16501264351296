define('freshfone-ember/initializers/mkp-data', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    ['component'].forEach(function (entityType) {
      application.inject(entityType, 'mkpData', 'service:mkp-data');
    });
  }

  exports['default'] = {
    name: 'mkp-data',
    initialize: initialize
  };
});