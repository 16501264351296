define('freshfone-ember/routes/protected/admin/configure-bot', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('deflection_bot.lables.configure_bot');
    }),
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('deflection_bot.lables.configure_bot') };
    }),
    features: ['deflection_bot'],
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.checkFeature('deflection_bot');
    }
  });
});