define('freshfone-ember/adapters/subscription', ['exports', 'freshfone-ember/adapters/application', 'ember-data'], function (exports, _freshfoneEmberAdaptersApplication, _emberData) {
  exports['default'] = _freshfoneEmberAdaptersApplication['default'].extend({
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 422 && payload.errors) {
        var _ret = (function () {
          var errors = [];
          Object.keys(payload.errors).forEach(function (key) {
            var jsonError = {};
            jsonError.detail = payload.errors[key][0];
            jsonError.source = { pointer: '/data/attributes/' + key };
            errors.pushObject(jsonError);
          });
          return {
            v: new _emberData['default'].AdapterError(errors)
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
      return this._super.apply(this, arguments);
    }
  });
});