define('freshfone-ember/services/modal-stack-manager', ['exports', 'ember', 'freshfone-ember/modal/object'], function (exports, _ember, _freshfoneEmberModalObject) {
  var emberArray = _ember['default'].A;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var getWithDefault = _ember['default'].getWithDefault;
  var isNone = _ember['default'].isNone;
  var Logger = _ember['default'].Logger;
  var merge = _ember['default'].merge;
  var on = _ember['default'].on;
  var Service = _ember['default'].Service;
  var typeOf = _ember['default'].typeOf;
  var assert = Logger.assert;
  exports['default'] = Service.extend({
    modalStackCount: computed.readOnly('modalStack.length'),
    isEmpty: computed.equal('modalStackCount', 0).readOnly(),
    stack: computed('modalStack', {
      get: function get() {
        return this.get('modalStack');
      },
      set: function set() {
        assert('You cannot add data to this stack!');
      }
    }),

    _setInitialState: on('init', function () {
      this._setDefaults();
      this.closeAllModals();
    }),

    _setDefaults: function _setDefaults() {
      var defaults = getWithDefault(this, 'modalDefaults', {});
      var types = defaults.types;
      var _self = this;

      types.forEach(function (key) {
        _self._registerType(key, defaults[key]);
      });
    },

    _registerType: function _registerType(type, defaults) {
      var _self = this;
      this[type] = function (options) {
        _self[type].defaults = defaults;
        return _self.add(defaults, options);
      };
    },

    add: function add(defaults) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var modal = this._newModal(defaults, options);
      return this._addToStack(modal);
    },

    _newModal: function _newModal(defaults) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      for (var key in options) {
        if (typeOf(options[key]) === 'undefined') {
          options[key] = defaults[key];
        }
      }
      var modalService = this;
      var extendedModalOptions = merge(merge(copy(defaults), options), { modalService: modalService });
      return _freshfoneEmberModalObject['default'].create(extendedModalOptions);
    },

    _addToStack: function _addToStack(modalInstance) {
      var modalStack = this.get('modalStack');
      modalStack.pushObject(modalInstance);
      return modalInstance;
    },

    closeAllModals: function closeAllModals() {
      var modalStack = this.get('modalStack');
      if (isNone(modalStack)) {
        this.set('modalStack', emberArray([]));
      } else {
        modalStack.clear();
      }

      return modalStack;
    }
  });
});