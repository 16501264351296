define('freshfone-ember/components/call-tags/custom-tags/component', ['exports', 'ember', 'freshfone-ember/constants/tag'], function (exports, _ember, _freshfoneEmberConstantsTag) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = Component.extend({
    tag: {},
    maxLimit: _freshfoneEmberConstantsTag['default']['customTagLimit'],
    maxCharacterLimit: _freshfoneEmberConstantsTag['default']['customTagCharacterLimit'],
    maxCharLimitReached: computed.gt('customTag.length', 32),
    enabledTags: computed.filterBy('model', 'disabled', false),
    allTagsDisabled: computed.empty('enabledTags'),

    availableLength: computed('customTag', function () {
      var charactersLeft = this.get('maxCharacterLimit') - this.get('customTag.length');
      return charactersLeft >= 0 ? charactersLeft : 0;
    }),

    customTags: computed('model.[]', function () {
      return this.get('model').filterBy('default', false);
    }),

    createCustomTag: function createCustomTag(value) {
      var customTag = this.store.createRecord('tag', {
        name: value,
        'default': false
      });
      this.set('tag', customTag);
      this.send('save');
    },

    showValidationMessage: function showValidationMessage(status, message) {
      this.setProperties({
        status: status,
        validationMessage: message
      });
    },

    validateAndCreate: function validateAndCreate(event) {
      this.showValidationMessage();
      var key = event.keyCode || event.which;
      var value = event.target ? event.target.value : '';
      if (value && key === 13) {
        var tagNames = this.get('customTags').map(function (tag) {
          return tag.get('name').toLowerCase();
        });
        if (tagNames.contains(value.toLowerCase())) {
          this.showValidationMessage('validation', _ember['default'].i18n.t('call_tags.custom_tags.tag_already_exists'));
          return;
        } else if (this.get('maxCharLimitReached')) {
          return;
        }
        event.target.value = '';
        this.createCustomTag(value);
      }
    },

    actions: {
      keyDown: function keyDown(event) {
        debounce(this, this.validateAndCreate, event, 200);
      },
      onModify: function onModify() {
        this.set('modifyModel', true);
      },
      save: function save() {
        var _this = this;

        this.get('tag').save().then(function (response) {
          _this.send('onModify');
        })['catch'](function (error) {
          console.log('Tag Creation Failed', error);
        });
      },
      deleteTag: function deleteTag(tag) {
        this.setProperties({
          showDeleteModal: true,
          itemToDelete: tag
        });
      },
      closeCallback: function closeCallback() {
        this.setProperties({
          showDeleteModal: false,
          itemToDelete: null
        });
        if (this.get('allTagsDisabled') && this.get('isTaggingMandatory')) {
          this.sendAction('disableMandatoryCallTagging', true, true);
        }
      },
      deleteCallback: function deleteCallback() {
        var _this2 = this;

        this.get('itemToDelete').destroyRecord().then(function () {
          _this2.send('closeCallback');
          _this2.send('onModify');
        })['catch'](function (error) {
          console.log('Tag Deletion Failed', error);
        });
      }
    }
  });
});