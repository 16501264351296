define('freshfone-ember/routes/promotable', ['exports', 'freshfone-ember/routes/protected', 'freshfone-ember/utils/promotion-data'], function (exports, _freshfoneEmberRoutesProtected, _freshfoneEmberUtilsPromotionData) {
	exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
		promote: function promote(promotionModel, feature) {
			// Promotion data is different for different routes
			return _freshfoneEmberUtilsPromotionData['default'][feature](promotionModel);
		},

		setupController: function setupController(controller) {
			this._super.apply(this, arguments);

			if (this.get('_shouldPromote')) {
				controller.set('shouldPromote', true);
				controller.set('featureSymbol', this.get('_featureSymbol'));
			}
		},

		model: function model(promotionModel, theActualModel, feature, globalFlag) {
			// If the feature isn't there, promote it with mock data
			if (globalFlag) {
				this.set('_shouldPromote', !this.get('session.account').hasFeature(feature) || this.get('session.account.globalFlags').indexOf(globalFlag) < 0);
			} else {
				this.set('_shouldPromote', !this.get('session.account').hasFeature(feature));
			}
			this.set('_featureSymbol', feature);

			return this.get('_shouldPromote') ? this.promote(promotionModel, feature) : theActualModel;
		}
	});
});
/* If you wanna promote your route, follow the below steps. Don't modify anything in this file.
1. Extend this route as opposed to ProtectedRoute
2. The model hook of your route should return a call to the super class' model hook with params -> promotion data, actual data and the feature symbol
3. Design your promotion data and create an entry in utils/promotion-data.js with your_feature_symbol as key
4. Wrap your main component in your-route.hbs by "promotion-wrapper"
5. Create an entry under "features" in en.json your_feature_symbol key with corresponding data
6. Create an entry in constants/promotion-image-urls with your_feature_symbol as key */