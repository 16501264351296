define('freshfone-ember/services/telephony', ['exports', 'freshfone-ember/constants/active-call-type', 'freshfone-ember/constants/call-category', 'freshfone-ember/utils/call-constants', 'freshfone-ember/constants/call-state', 'freshfone-ember/constants/call-status', 'freshfone-ember/constants/call-type', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/constants/park-state', 'freshfone-ember/constants/status-getter-map', 'ember-authority/helpers/has-privilege'], function (exports, _freshfoneEmberConstantsActiveCallType, _freshfoneEmberConstantsCallCategory, _freshfoneEmberUtilsCallConstants, _freshfoneEmberConstantsCallState, _freshfoneEmberConstantsCallStatus, _freshfoneEmberConstantsCallType, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberConstantsParkState, _freshfoneEmberConstantsStatusGetterMap, _emberAuthorityHelpersHasPrivilege) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /* global FC */

  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var Evented = _ember['default'].Evented;
  var isEqual = _ember['default'].isEqual;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  var Service = _ember['default'].Service;
  var $ = _ember['default'].$;
  var Object = _ember['default'].Object;
  var run = _ember['default'].run;
  var _Ember$computed = _ember['default'].computed;
  var equal = _Ember$computed.equal;
  var alias = _Ember$computed.alias;
  var observer = _ember['default'].observer;
  var RSVP = _ember['default'].RSVP;
  var EmberPromise = RSVP.Promise;
  exports['default'] = Service.extend(Evented, _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    mkpData: service(),
    mkpAdapter: service(),
    parallelCall: service(),
    session: service(),
    modalPopup: service('modal-stack-manager'),
    i18n: service(),
    transition: service('state-transition'),
    parkedCallsService: service('parked-calls'),
    accountConfigurationService: service('account-configuration'),
    hangupService: service('hangup-event'),
    accountConfigurations: alias('accountConfigurationService.config'),
    hasCallConnected: false,
    isRecordingOrOutgoing: false,
    isReconnect: false,
    currentCall: {},
    incomingConnection: null,
    contact: computed.alias('currentCall.contact'),
    parallelCalls: computed.alias('parallelCall.parallelCalls'),
    parallelGroupIds: computed.alias('parallelCall.parallelGroupIds'),
    attemptedWqmCallAssignments: {},
    shouldFetchliveAgents: true,
    store: service(),
    notification: service('toastr'),
    hasActiveCall: computed.notEmpty('currentCall.id'),
    successfulTransfer: computed.equal('currentCall.status', _freshfoneEmberConstantsCallStatus['default'].Transferred),
    callCompleted: computed.equal('currentCall.status', _freshfoneEmberConstantsCallStatus['default'].Completed),
    callEnded: computed.or('callCompleted', 'successfulTransfer'),
    subscription: computed.alias('session.account.subscription'),
    routing: service('-routing'),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    account: computed.alias('session.account'),
    enhancedLiveDashboardAgent: computed(function () {
      return this.get('currentUser.isAgent') && this.hasGlobalFeature('enhanced_live_dashboard') && this.checkFeature('agent_restricted_live_dashboard');
    }),
    isTeamScoped: computed.equal('currentUser.scope.name', 'team'),
    shouldShowScopedAgentStatuses: computed(function () {
      return this.get('isTeamScoped') && (this.get('currentUser.isSupervisor') || this.get('enhancedLiveDashboardAgent'));
    }),
    scopedTeamsIds: computed(function () {
      return this.get('currentUser.teams').mapBy('id');
    }),
    primaryCallActive: computed('currentCall.callState', function () {
      var callState = this.get('currentCall.callState');

      return callState !== _freshfoneEmberConstantsCallState['default'].Ended && callState !== _freshfoneEmberConstantsCallState['default'].Removed && callState !== _freshfoneEmberConstantsCallState['default'].CloseAndResume;
    }),
    parallelConferenceStatus: computed('parallelCalls.@each.conferenceState', function () {
      var parallelCall = this.get('parallelCalls').length > 0 ? this.get('parallelCalls').get('firstObject') : null;

      return parallelCall && parallelCall.get('conferenceState');
    }),
    primaryConferenceInitiated: computed('currentCall.{callType,conferenceState}', function () {
      return this.get('parallelCall').checkPrimaryConferenceStatus(_freshfoneEmberConstantsCallState['default'].Initiated, this.get('currentCall.callType'), this.get('currentCall.conferenceState'));
    }),
    parallelConferenceInitiated: computed('currentCall.callType', 'parallelConferenceStatus', function () {
      return this.get('parallelCall').checkParallelConferenceStatus(_freshfoneEmberConstantsCallState['default'].Initiated, this.get('currentCall.callType'), this.get('parallelConferenceStatus'));
    }),
    conferenceInitiated: computed.or('parallelConferenceInitiated', 'primaryConferenceInitiated'),
    primaryConferenceActive: computed('currentCall.{callType,conferenceState}', function () {
      return this.get('parallelCall').checkPrimaryConferenceStatus(_freshfoneEmberConstantsCallState['default'].Active, this.get('currentCall.callType'), this.get('currentCall.conferenceState'));
    }),
    parallelConferenceActive: computed('currentCall.callType', 'parallelConferenceStatus', function () {
      return this.get('parallelCall').checkParallelConferenceStatus(_freshfoneEmberConstantsCallState['default'].Active, this.get('currentCall.callType'), this.get('parallelConferenceStatus'));
    }),
    conferenceActive: computed.or('primaryConferenceActive', 'parallelConferenceActive'),
    primaryConferenceEnded: computed('currentCall.{callType,conferenceState}', function () {
      return this.get('parallelCall').checkPrimaryConferenceStatus(_freshfoneEmberConstantsCallState['default'].Ended, this.get('currentCall.callType'), this.get('currentCall.conferenceState'));
    }),
    parallelConferenceEnded: computed('currentCall.callType', 'parallelConferenceStatus', function () {
      return this.get('parallelCall').checkParallelConferenceStatus(_freshfoneEmberConstantsCallState['default'].Ended, this.get('currentCall.callType'), this.get('parallelConferenceStatus'));
    }),
    conferenceEnded: computed.or('primaryConferenceEnded', 'parallelConferenceEnded'),
    primaryConferenceRemoved: computed('currentCall.{callType,conferenceState}', function () {
      return this.get('parallelCall').checkPrimaryConferenceStatus(_freshfoneEmberConstantsCallState['default'].Removed, this.get('currentCall.callType'), this.get('currentCall.conferenceState'));
    }),
    parallelConferenceRemoved: computed('currentCall.callType', 'parallelConferenceStatus', function () {
      return this.get('parallelCall').checkParallelConferenceStatus(_freshfoneEmberConstantsCallState['default'].Removed, this.get('currentCall.callType'), this.get('parallelConferenceStatus'));
    }),
    conferenceRemoved: computed.or('primaryConferenceRemoved', 'parallelConferenceRemoved'),
    primaryConferenceUnavailable: computed('currentCall.{callType,conferenceState}', function () {
      return this.get('parallelCall').checkPrimaryConferenceStatus(_freshfoneEmberConstantsCallState['default'].Unavailable, this.get('currentCall.callType'), this.get('currentCall.conferenceState'));
    }),
    parallelConferenceUnavailable: computed('currentCall.callType', 'parallelConferenceStatus', function () {
      return this.get('parallelCall').checkParallelConferenceStatus(_freshfoneEmberConstantsCallState['default'].Unavailable, this.get('currentCall.callType'), this.get('parallelConferenceStatus'));
    }),
    conferenceUnavailable: computed.or('primaryConferenceUnavailable', 'parallelConferenceUnavailable'),
    primaryConferenceCloseAndResume: computed('currentCall.{callType,conferenceState}', function () {
      return this.get('parallelCall').checkPrimaryConferenceStatus(_freshfoneEmberConstantsCallState['default'].CloseAndResume, this.get('currentCall.callType'), this.get('currentCall.conferenceState'));
    }),
    parallelConferenceCloseAndResume: computed('currentCall.callType', 'parallelConferenceStatus', function () {
      return this.get('parallelCall').checkParallelConferenceStatus(_freshfoneEmberConstantsCallState['default'].CloseAndResume, this.get('currentCall.callType'), this.get('parallelConferenceStatus'));
    }),
    conferenceCloseAndResume: computed.or('primaryConferenceCloseAndResume', 'parallelConferenceCloseAndResume'),
    primaryConferenceOnHold: computed('currentCall.conferenceState', function () {
      return this.get('currentCall.conferenceState') === _freshfoneEmberConstantsCallState['default'].Hold;
    }),
    activePrimaryConferenceOnHold: computed('currentCall.callType', 'primaryConferenceOnHold', function () {
      return this.get('currentCall.callType') !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && this.get('primaryConferenceOnHold');
    }),
    parallelConferenceOnHold: computed('parallelConferenceStatus', function () {
      return this.get('parallelConferenceStatus') === _freshfoneEmberConstantsCallState['default'].Hold;
    }),
    activeParallelConferenceOnHold: computed('currentCall.callType', 'parallelConferenceOnHold', function () {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel && this.get('parallelConferenceOnHold');
    }),
    conferenceOnHold: computed.or('primaryConferenceOnHold', 'parallelConferenceOnHold'),
    activeConferenceOnHold: computed.or('activePrimaryConferenceOnHold', 'activeParallelConferenceOnHold'),
    isConferenceInProgress: computed.or('conferenceInitiated', 'conferenceActive', 'conferenceEnded', 'conferenceUnavailable', 'activeConferenceOnHold'),
    activeFreshfoneNumber: computed({
      get: function get() {
        return this.get('store').peekRecord('number', this.get('session.user.agent.outgoingNumberId'));
      }
    }),
    primaryCallRemoved: computed('currentCall.callState', function () {
      return this.get('currentCall.callState') === _freshfoneEmberConstantsCallState['default'].Removed;
    }),
    parallelCallRemoved: computed.alias('parallelCall.parallelCallRemoved'),
    mainCallsRemoved: computed('currentCall.callType', 'parallelCallRemoved', 'primaryCallRemoved', function () {
      var callType = this.get('currentCall.callType');

      if (!callType) {
        return false;
      }

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('parallelCallRemoved') : this.get('primaryCallRemoved');
    }),
    primaryCallOrConferenceEnded: computed('currentCall.callType', 'currentCall.{callState,conferenceState}', function () {
      var callType = this.get('currentCall.callType'),
          primaryCallState = this.get('currentCall.callState'),
          primaryConferenceState = this.get('currentCall.conferenceState');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel && (primaryCallState === _freshfoneEmberConstantsCallState['default'].Ended || primaryConferenceState === _freshfoneEmberConstantsCallState['default'].Ended);
    }),
    parallelCallOrConferenceEnded: computed('currentCall.callType', 'parallelCalls.@each.{state,conferenceState}', function () {
      var callType = this.get('currentCall.callType'),
          parallelCall = this.get('parallelCalls').length > 0 ? this.get('parallelCalls').get('firstObject') : null;

      return parallelCall && callType === _freshfoneEmberConstantsActiveCallType['default'].Primary && (parallelCall.state === _freshfoneEmberConstantsCallState['default'].Ended || parallelCall.conferenceState === _freshfoneEmberConstantsCallState['default'].Ended);
    }),
    primaryCallOrConferenceResume: computed('currentCall.{callType,callState,conferenceState}', function () {
      return this.get('currentCall.callType') !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && (this.get('currentCall.callState') === _freshfoneEmberConstantsCallState['default'].CloseAndResume || this.get('currentCall.conferenceState') === _freshfoneEmberConstantsCallState['default'].CloseAndResume);
    }),
    parallelCallOrConferenceResume: computed('currentCall.callType', 'parallelCalls.@each.{state,conferenceState}', function () {
      var parallelCall = this.get('parallelCalls').length > 0 ? this.get('parallelCalls').get('firstObject') : null;

      return parallelCall && this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel && (parallelCall.get('state') === _freshfoneEmberConstantsCallState['default'].CloseAndResume || parallelCall.get('conferenceState') === _freshfoneEmberConstantsCallState['default'].CloseAndResume);
    }),
    closeAndResume: computed.or('primaryCallOrConferenceResume', 'parallelCallOrConferenceResume'),
    callType: Object.create({
      isIncoming: false,
      isOutgoing: false
    }),
    checkIsIncoming: computed('callType.isIncoming', function () {
      return this.checkFeature('csat_ivr_inbound') && this.get('callType.isIncoming');
    }),
    checkIsOutgoing: computed('callType.isOutgoing', function () {
      return this.checkFeature('csat_ivr_outbound') && this.get('callType.isOutgoing');
    }),
    shouldShowCsatIvr: computed('checkIsIncoming', 'checkIsOutgoing', function () {
      return this.get('checkIsIncoming') || this.get('checkIsOutgoing');
    }),
    callInProgress: computed.not('isEnded'),
    currentCallIsParked: equal('currentCall.parkState', _freshfoneEmberConstantsParkState['default'].Parked),
    isCallsInParkedState: computed.or('hasActiveCall', 'parkedCallsLength'),
    hasCallParkingFeatureFlagsEnabled: computed('session.account', function () {
      return this.featureCheckWithRolloutFlag('call_parking', 'call_parking', 'call_parking_rollout');
    }),
    hasParkingEnabled: computed('session.user', 'hasCallParkingFeatureFlagsEnabled', function () {
      return this.get('hasCallParkingFeatureFlagsEnabled') && this.get('session.user.callParkingEnabled');
    }),
    currentCallCompleted: alias('currentCall.isCompleted'),
    currentCallCompletedObserver: observer('currentCallCompleted', function () {
      this.get('transition').stop();
      this.set('parking', false);
    }),
    parallelCallActive: computed('parallelCalls.[]', function () {
      return this.get('parallelCalls').length;
    }),
    parking: alias('parkedCallsService.parking'),
    callSwitched: alias('parkedCallsService.callSwitched'),
    hasCallTaggingFeature: computed(function () {
      return this.checkFeature('call_tagging');
    }),
    primaryConferenceCaller: computed('currentCall.conferenceTarget', function () {
      var target = this.get('currentCall.conferenceTarget');

      if (target && target.name) {
        return {
          hasContactForSideBar: false,
          displayName: target.name,
          contactForSideBar: target,
          text: target.name
        };
      }
    }),
    parallelConferenceCaller: alias('parallelCall.parallelConferenceCaller'),
    conferenceCallerInWidget: computed('currentCall.callType', 'parallelConferenceCaller', 'primaryConferenceCaller', function () {
      var callType = this.get('currentCall.callType');

      return callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('primaryConferenceCaller') : this.get('parallelConferenceCaller');
    }),
    isParallelCallResumed: computed('currentCall.callType', function () {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel;
    }),
    customerContact: computed('currentCall.callerName', 'currentCall.contact.name', function () {
      var _model = this.get('currentCall.contact');

      if (_model && _model.get) {
        if (_model.get('name')) {
          return _model.get('name');
        }
        if (_model.get('isNew')) {
          return this.get('i18n').t('contact.new_contact');
        }
      }

      return this.get('currentCall.callerName');
    }),
    callCompletedObserver: observer('currentCall.status', function () {

      var getCallPropertyNameByStatus = _freshfoneEmberConstantsStatusGetterMap['default'].get(this.get('currentCall.status'));

      if (getCallPropertyNameByStatus) {
        this.set('currentCall.' + getCallPropertyNameByStatus, true);
      }
    }),
    parkedCallsLength: alias('parkedCallsService.parkedCallsLength'),
    parkCallLimit: alias('session.user.maxParkedCalls'),
    allowNewCalls: computed('parkCallLimit', 'parkedCallsLength', 'hasActiveCall', function () {
      var activeCallsCount = this.get('hasActiveCall') ? 1 : 0,
          totalCallsCount = activeCallsCount + this.get('parkedCallsLength');

      return totalCallsCount < this.get('parkCallLimit');
    }),
    enableIncomingCall: computed('hasParkingEnabled', 'allowNewCalls', function () {
      return !this.get('hasParkingEnabled') || this.get('allowNewCalls');
    }),
    isTransferIncomingCall: computed('currentCall.{type,status,isParallelCall}', function () {
      var callTypes = [_freshfoneEmberConstantsCallType['default'].AgentConference, _freshfoneEmberConstantsCallType['default'].WarmTransfer];

      return this.get('currentCall.isParallelCall') || callTypes.includes(this.get('currentCall.type')) || this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferred;
    }),
    isMonitorOrBargeCall: computed('currentCall.type', function () {
      var callTypes = [_freshfoneEmberConstantsCallType['default'].Monitor, _freshfoneEmberConstantsCallType['default'].Barge];

      return this.get('currentCall.type') && callTypes.includes(this.get('currentCall.type'));
    }),
    showCallUnparkTooltip: alias('parkedCallsService.showCallUnparkTooltip'),
    isParallelCallActive: alias('parallelCall.isParallelCallActive'),
    isLoading: computed.or('parallelCall.isLoading', 'transferringToCsatIvr'),
    isAttemptingTransferOrConference: computed('currentCall.{status,attemptingTransferOrConference}', 'isLoading', 'conferenceInitiated', function () {
      var callStatus = [_freshfoneEmberConstantsCallStatus['default'].Transferring, _freshfoneEmberConstantsCallStatus['default'].WarmTransferring, _freshfoneEmberConstantsCallStatus['default'].ParallelCallInitiated, _freshfoneEmberConstantsCallStatus['default'].ParallelCallCancelling];

      return callStatus.includes(this.get('currentCall.status')) || this.get('conferenceInitiated') || this.get('currentCall.attemptingTransferOrConference') || this.get('isLoading');
    }),
    disableAction: computed('currentCall.status', 'isAttemptingTransferOrConference', function () {
      var callStatus = [_freshfoneEmberConstantsCallStatus['default'].TransferUnavailable, _freshfoneEmberConstantsCallStatus['default'].WarmTransferUnavailable];

      return this.get('isAttemptingTransferOrConference') || callStatus.includes(this.get('currentCall.status'));
    }),
    recordingInProgress: false,
    acceptedCallId: undefined,
    currentCallId: undefined,
    isQueueTransferInitiated: false,
    shouldLoadSDKV210: computed(function () {
      return this.hasGlobalFeature('twilio_sdk_v210') && this.checkFeature('twilio_sdk_v210');
    }),
    getActiveFreshfoneNumber: function getActiveFreshfoneNumber() {
      return this.get('store').peekRecord('number', this.get('session.user.agent.outgoingNumberId'));
    },
    checkPrimaryConferenceStatus: function checkPrimaryConferenceStatus(status) {
      return this.get('currentCall.callType') !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && this.get('currentCall.conferenceState') === status;
    },
    checkParallelConferenceStatus: function checkParallelConferenceStatus(status) {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel && this.get('parallelConferenceStatus') === status;
    },
    isAgentPartOfScopedTeams: function isAgentPartOfScopedTeams(agentTeams) {
      return this.get('scopedTeamsIds').some(function (scopedTeamId) {
        return agentTeams.includes(parseInt(scopedTeamId));
      });
    },
    register: function register() {
      var _this = this;

      $.getScript(this.get('shouldLoadSDKV210') ? _freshfoneEmberConfigEnvironment['default'].FreshcallerSdkV210Url : _freshfoneEmberConfigEnvironment['default'].FreshcallerSdkV21Url).then(function () {
        _this.bindClients();
        _this.bindFreshCallerEvents();
        _this.get('parallelCall').bindParallelCallEvents();
        _this.get('parkedCallsService').bindCallParkingEvents();
        FC.init({ use_proxy: false });
      });
    },

    bindClients: function bindClients() {
      this.set('telephony', FC.Telephony);
      this.set('freshcallerNumber', FC.FreshcallerNumber);
      this.set('agent', FC.Agent);
      this.set('event', FC.Event);
      this.set('util', FC.Util);
      this.set('message', FC.Message);
      this.set('getCurrentCall', FC.getCurrentCall);
      this.set('getParkedCalls', FC.getParkedCalls);
    },

    bindFreshCallerEvents: function bindFreshCallerEvents() {
      var _this2 = this;

      /*
       * If the user is / becomes active in a tab and has seen the in-conversation widget of
       * a call, close the in-conversation widget
       * of that call in the other tabs.
       */
      if (!this.get('session.account.isUnifiedAppAccount')) {
        FC.Event.subscribe('connect', this.connectCallback.bind(this));
        FC.Event.subscribe('disconnect', this.disconnectCallback.bind(this));
        FC.Event.subscribe('error', this.errorCallback.bind(this));
        FC.Event.subscribe('agent_presence_info', this.agentInfoCallback.bind(this));
        FC.Event.subscribe('profile_updated', this.profileUpdatedCallback.bind(this));
        FC.Event.subscribe('account_status_change', this.accountStatusCallback.bind(this));
        FC.Event.subscribe('customer_connected', this.customerConnectedCallback.bind(this));
        FC.Event.subscribe('numbers_updated', this.numbersUpdatedCallback.bind(this));
        FC.Event.subscribe('socket_connect', this.socketConnectCallback.bind(this));
        FC.Event.subscribe('socket_disconnect', this.socketDisconnectCallback.bind(this));
        FC.Event.subscribe('provider_initialized', this.providerInitialized.bind(this));
        FC.Event.subscribe('provider_warning', this.providerWarning.bind(this));
        FC.Event.subscribe('provider_warning_cleared', this.providerWarningCleared.bind(this));
        FC.Event.subscribe('close_conversation', function (data) {
          return scheduleOnce('afterRender', function () {
            return (_this2.get('currentCall.id') === data.callId || data.closeAutoAnswerTab) && _this2.set('currentCall', {});
          });
        });
        FC.Event.subscribe('save_conversation', function () {
          return _this2.trigger('save_conversation');
        });
        FC.Event.subscribe('auto_answer_browser_notification', this.showAutoAnswerDesktopNotification.bind(this));
      }
      FC.Event.subscribe('assignment_status_update', this.callAssignmentStatusUpdateCallback.bind(this));
      FC.Event.subscribe('ready', this.readyCallback.bind(this));
      FC.Event.subscribe('sip_enabled_all', this.enabledSIPForAllCallback.bind(this));
      FC.Event.subscribe('agent_status_change', this.agentStatusChangeCallback.bind(this));
      FC.Event.subscribe('custom_status_updated', this.modifyModelCustomAgentStatus.bind(this));
      FC.Event.subscribe('customer_call_completed', this.customerCallCompletedCallback.bind(this));
      FC.Event.subscribe('status_update', this.callStatusUpdatedCallback.bind(this));
      FC.Event.subscribe('twilio_disconnect', this.disconnectConnectingCall.bind(this));
      FC.Event.subscribe('twilio_error', this.disconnectConnectingCall.bind(this));
    },

    showAutoAnswerDesktopNotification: function showAutoAnswerDesktopNotification() {
      if ('Notification' in window) {
        if (Notification.permission === 'granted') {
          // eslint-disable-next-line max-len
          var notification = new Notification(this.get('i18n').t('browser_notification.auto_answer.title'), {
            body: this.get('i18n').t('browser_notification.auto_answer.body'),
            icon: 'assets/images/fd-contact-center.svg'
          });

          notification.onclick = function () {
            window.focus();
            this.close();
          };
        }
      } else {
        console.warn('Browser does not support notifications.');
      }
    },

    makeCall: function makeCall(params) {
      var _this3 = this;

      return FC.Telephony.makeCall(params).then(function () {
        _this3.set('hasCallConnected', 'true');
      })['catch'](function (err) {
        _this3.logError(err, '- Failed to initiate outgoing call');
        _this3.get('notification').error(_this3.get('i18n').t('outgoing_widget.failed_initiate'));
      });
    },

    joinCall: function joinCall(params, contact_id, number) {
      var _this4 = this;

      return FC.Telephony.joinCall(params).then(function () {
        _this4.registerCall(params);
        _this4.buildContactInfo(contact_id, number);
      })['catch'](function (err) {
        _this4.logError(err, '- Failed to initiate outgoing call');
        _this4.get('notification').error(_this4.get('i18n').t('inprogress_calls_list.failed_initiate'));
      });
    },

    startRecording: function startRecording() {
      this.registerCall({});
      this.set('recordingInProgress', true);
      FC.Message.startRecording();
    },

    stopRecording: function stopRecording() {
      FC.Message.stopRecording();
    },

    initiateVoicemailDrop: function initiateVoicemailDrop(data) {
      return FC.Message.initiateVoicemailDrop(data);
    },

    recordOnCall: function recordOnCall(params) {
      return FC.Telephony.recordOnCall(params);
    },

    handleInprogressRecording: function handleInprogressRecording(params) {
      return FC.Telephony.handleInprogressRecording(params);
    },

    hangup: function hangup(message) {
      FC.getCurrentCall().end(message);
    },

    freshcallerNumber: function freshcallerNumber() {
      return FC.FreshcallerNumber;
    },

    registerCall: function registerCall(params) {
      var currentCall = Object.extend(Evented, {
        callDuration: 0,
        parallelCallDuration: 0,
        primaryHoldDuration: 0,
        secondaryHoldDuration: 0
      }).create();

      this.get('parallelCall').clearParallelCalls();
      this.set('currentCall', currentCall);
      this.get('currentCall').setProperties(params);
    },

    socketConnectCallback: function socketConnectCallback() {
      if (this.get('isReconnect')) {
        this.trigger('reconnect');
        FC.Util.reInitSdk();
        this.set('isReconnect', false);
      }
    },

    socketDisconnectCallback: function socketDisconnectCallback() {
      this.set('isReconnect', true);
      if (this.hasGlobalFeature('socket_disconnect_notify') && this.get('currentAgent.isAvailable')) {
        this.trigger('socket_disconnected');
      }
    },

    readyCallback: function readyCallback() {
      if (this.get('isCrmView')) {
        this.set('isSdkLoadedFromCrm', true);
        this.trigger('sdkLoadedFromCrm');
      } else {
        this.set('isLoaded', true);
        this.trigger('loaded');
        this.updateSDKAgent();
        this.updateSDKAccount();
        FC.Logger.initializeLogger('app');
      }
    },

    updateSDKAccount: function updateSDKAccount() {
      var flagsAndConfigurations = {
        globalFlags: this.get('session.account.globalFlags').toArray(),
        rolloutGlobalFlag: this.get('session.account.rolloutGlobalFlags').toArray(),
        accountConfigurations: this.get('accountConfigurations.data')
      };

      FC.Account.updateFlags(flagsAndConfigurations);
    },
    updateSDKAgent: function updateSDKAgent() {
      var config = {
        callParkingEnabled: this.get('session.user.callParkingEnabled'),
        maxParkedCalls: this.get('session.user.maxParkedCalls'),
        ringWhenBusy: this.get('session.user.ringWhenBusy')
      };

      FC.Agent.updateConfiguration(config);
    },
    hasToFetchNoteAndTags: function hasToFetchNoteAndTags() {
      return this.get('currentCall.isTransferCall') || this.get('currentCall.category') === _freshfoneEmberConstantsCallType['default'].QueueTransfer;
    },

    connectCallback: function connectCallback(currentCall) {
      var _this5 = this;

      var call = currentCall.properties;

      /*
       * If the in-conversation widget of a completed call different from the current call is open in another tab,
       * close it immediately
       * (save stuff in background) and then render the in-conversation widget for the current call.
       ***The user can choose to become active in any tab***
       */
      if (call.isSIPCall && this.get('callEnded')) {
        this.trigger('save_and_close');
      }

      scheduleOnce('afterRender', function () {
        if (!call.type) {
          _this5.updateCurrentCall(currentCall);

          return;
        }

        var callTypes = [_freshfoneEmberConstantsCallType['default'].Monitor, _freshfoneEmberConstantsCallType['default'].Barge],
            isNotMonitorOrBarge = call.type && !callTypes.includes(call.type);

        _this5.set('showCallUnparkTooltip', false);

        if (_this5.get('hasParkingEnabled') && isNotMonitorOrBarge) {
          _this5.get('parkedCallsService').checkAndUpdateParkedCall(_this5.getParkedCalls(), true);

          _this5.setCurrentCallContext(call.contactId, call.type === _freshfoneEmberConstantsCallType['default'].Outgoing ? call.phone_number || call.caller.to : call.caller.from, call.type, call.powerDialer).then(function () {
            _this5.updateCallInfo(currentCall, call);
            _this5.get('transition').stop();
            _this5.set('parking', false);
            _this5.set('currentCallId', undefined);
          });
        } else {
          if (call.isSIPCall || call.powerDialer) {
            _this5.setCurrentCallContext(call.contactId, call.type === _freshfoneEmberConstantsCallType['default'].Outgoing ? call.phone_number : call.caller.from, call.type, call.powerDialer);
          }
          _this5.updateCallInfo(currentCall, call);
        }
      });
    },

    updateCallInfo: function updateCallInfo(currentCall, call) {
      this.updateCurrentCall(currentCall);
      this.updateCallerInfo(currentCall);

      if (this.get('currentCall.isOutgoing') && !call.powerDialer && !this.get('currentCall.contact.id')) {
        this.setCurrentCallContact();
      }

      if (this.hasToFetchNoteAndTags) {
        this.fetchCallNote();
        this.fetchCallTags();
      }
      this.fetchOtherContacts();
    },

    updateCallerInfo: function updateCallerInfo(currentCall, contactId) {
      var callerName = this.callerName(),
          callerNumber = this.callerNumber();

      this.set('currentCall.callerNumber', callerNumber);
      this.set('currentCall.callerName', callerName);

      currentCall.set('callerNumber', callerNumber);
      currentCall.set('callerName', callerName);

      if (contactId) {
        currentCall.set('contactId', contactId);
      }
    },

    fetchOtherContacts: function fetchOtherContacts() {
      var _this6 = this;

      if (!this.get('currentCall.callerNumber')) {
        return;
      }

      var number = this.get('currentCall.callerNumber').replace(/\D/g, '');

      return $.getJSON('/contacts/search', { filter: { queryString: number } }).then(function (contacts) {
        run(function () {
          return _this6.get('store').pushPayload(contacts);
        });
        _this6.set('currentCall.otherContactsIds', contacts.data.map(function (contact) {
          return contact.id;
        }));
      });
    },

    fetchCallNote: function fetchCallNote() {
      var _this7 = this;

      var callId = this.get('currentCall.call_id');

      FC.Telephony.fetchCallNotes(callId).then(function (data) {
        if (data.notes) {
          _this7.set('currentCall.notes', data.notes);
          _this7.set('currentCall.noteFromSourceAgent', true);
        } else {
          _this7.set('currentCall.notes', undefined);
        }
      });
    },

    fetchCallTags: function fetchCallTags() {
      var _this8 = this;

      var callId = this.get('currentCall.call_id');

      FC.Telephony.fetchCallTags(callId).then(function (response) {
        var tags = response.data.relationships.tags;

        if (tags && tags.data) {
          _this8.set('currentCall.tagIds', tags.data.mapBy('id'));
        } else {
          _this8.set('currentCall.tagIds', []);
        }
      });
    },

    setCurrentCallContact: function setCurrentCallContact() {
      var _this9 = this;

      _ember['default'].$.getJSON('/contacts/call_contact', { call_id: this.get('currentCall.id') }).then(function (contact) {
        if (contact) {
          _this9.get('store').pushPayload(contact);
          var storedContact = _this9.get('store').peekRecord('contact', contact.data.id);

          _this9.set('currentCall.contact', storedContact);
          _this9.set('currentCall.initialContact', storedContact);
        }
      });
    },

    disconnectCallback: function disconnectCallback(currentCall) {
      if (this.get('hasActiveCall')) {
        if (['monitor', 'barge'].includes(this.get('currentCall.type'))) {
          this.set('currentCall', {});
        } else {
          this.updateCurrentCall(currentCall);
          this.get('mkpAdapter').triggerEvent({
            type: 'call.ended',
            data: this.get('mkpData').getData('currentCall')
          });
        }
      }
    },

    customerCallCompletedCallback: function customerCallCompletedCallback(callId) {
      var targetDisconnect = undefined;

      if (this.get('parallelCall').isCurrentCall(callId)) {
        this.updateCurrentCall(this.getCurrentCall());

        return;
      }

      var parallelCallList = this.get('parkedCallsService').getParallelCall(callId);

      var _ref = parallelCallList && parallelCallList.length ? parallelCallList : [];

      var _ref2 = _slicedToArray(_ref, 1);

      var parallelCall = _ref2[0];

      this.get('parallelCall').updateParkedCallsState();

      var isVendorDrop = parallelCall && (parallelCall.state === _freshfoneEmberConstantsCallState['default'].Active || parallelCall.state === _freshfoneEmberConstantsCallState['default'].Hold);

      if (isVendorDrop) {
        var _get$getParallelCall = this.get('parkedCallsService').getParallelCall(callId);

        var _get$getParallelCall2 = _slicedToArray(_get$getParallelCall, 1);

        var _parallelCall = _get$getParallelCall2[0];
        var callType = this.get('parallelCall').getCallProperty(callId, 'callType');

        targetDisconnect = _parallelCall.get('target').name;

        if (callType === _freshfoneEmberConstantsActiveCallType['default'].Primary) {
          this.get('parallelCall').removeParkedParallelCall(callId);
        } else {
          _parallelCall.set('conferenceState', _freshfoneEmberConstantsCallState['default'].Removed);
        }
      } else {
        targetDisconnect = this.get('parallelCall').getCustomerDisplayName(callId);
      }

      targetDisconnect && this.get('parallelCall').addDisconnectMessage(targetDisconnect);
    },

    errorCallback: function errorCallback(error) {
      console.log('error: ', error);
      this.trigger('sdkError', error);
    },

    agentInfoCallback: function agentInfoCallback(data) {
      var currentAgent = this.get('session.user.agent');

      currentAgent.set('loggedIn', data.logged_in);
    },

    agentStatusChangeCallback: function agentStatusChangeCallback(data) {
      var _this10 = this;

      var agent = this.get('store').peekRecord('agent', data.agent_id),
          customAgentStatus = this.get('store').peekRecord('agent-status', data.browser_status),
          previousBrowserStatus = this.get('store').peekRecord('agent-status', data.previous_browser_status);

      agent && this.updateAgentStatus(agent, data, customAgentStatus, previousBrowserStatus);
      var dashBoardAgent = this.get('store').peekRecord('live-agent-status', data.agent_id);

      if (this.get('session.user.isAgent') && !this.get('enhancedLiveDashboardAgent')) {
        if (!(0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
          privilege: 'view_enhanced_live_dashboard',
          user: this.get('session.user')
        })) {
          return;
        }
      } else if (!this.get('enhancedLiveDashboardAgent') && !(0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_live_dashboard',
        user: this.get('session.user')
      })) {
        return;
      }

      if (dashBoardAgent) {
        this.updateAgentStatus(dashBoardAgent, data, customAgentStatus, previousBrowserStatus);
        dashBoardAgent.set('browserStatusId', data.browser_status);
        this.trigger('agent_status_change', data.agent_id);
      } else {
        if (this.get('shouldShowScopedAgentStatuses') && !this.isAgentPartOfScopedTeams(data.teams)) {
          return;
        }
        _ember['default'].$.getJSON('/live-agent-statuses?version=v1', { agent_id: data.agent_id }).then(function (agentsResponse) {
          _ember['default'].run(function () {
            _this10.get('store').pushPayload(agentsResponse);
            var updatedDashboardAgent = _this10.get('store').peekRecord('live-agent-status', data.agent_id);

            _this10.updateAgentStatus(updatedDashboardAgent, data, customAgentStatus, previousBrowserStatus);
            _this10.trigger('agent_status_change', data.agent_id);
          });
        });
      }
    },

    updateAgentStatus: function updateAgentStatus(agent, data, customAgentStatus, previousBrowserStatus) {

      var currentAgent = this.get('session.user.agent');

      currentAgent.set('showLoader', false);

      agent.setProperties({
        lastCallAt: data.last_call_at,
        lastSeenAt: data.last_seen_at,
        mobileStatus: data.mobile_preference,
        loggedIn: data.logged_in,
        status: data.status,
        browserStatus: customAgentStatus,
        previousBrowserStatus: previousBrowserStatus,
        lastStatusChangeAt: data.last_status_change_at
      });

      this.get('mkpAdapter').triggerEvent({
        type: 'agent.statusChanged',
        data: this.get('mkpData').getData('loggedInAgent')
      });
    },

    customerConnectedCallback: function customerConnectedCallback(currentCall) {
      this.updateCurrentCall(currentCall);
      this.updateCallerInfo(currentCall);
      this.fetchCallNote();
      this.fetchCallTags();
    },

    profileUpdatedCallback: function profileUpdatedCallback(data) {
      var currentUser = this.get('session.user'),
          payload = {
        data: {
          id: currentUser.get('id'),
          type: 'user',
          attributes: {
            'name': data.name,
            'phone': data.phone,
            'mobile': data.mobile,
            'ring-on-speakers': data.ring_on_speakers
          }
        }
      };

      this.get('store').pushPayload(payload);
    },

    accountStatusCallback: function accountStatusCallback(data) {
      this.set('session.account.callingEnabled', data.callingEnabled);
      this.set('subscription.status', data.status);
      this.set('session.account.credit.isCreditLow', data.creditsLow);
      this.trigger('account_status_change', data);
    },

    numbersUpdatedCallback: function numbersUpdatedCallback(data) {
      this.trigger('numbers_updated');
    },

    modifyModelCustomAgentStatus: function modifyModelCustomAgentStatus() {
      var _this11 = this;

      var currentAgentStatusModel = this.get('store').peekAll('agent-status');

      this.get('store').findAll('agent-status').then(function (agentStatusPayload) {
        currentAgentStatusModel.forEach(function (storeAgentStatus) {
          if (storeAgentStatus.get('id') && !agentStatusPayload.includes(storeAgentStatus)) {
            storeAgentStatus.unloadRecord();
          }
        });
      })['finally'](function () {
        return _this11.trigger('custom_status_updated');
      });
    },

    providerInitialized: function providerInitialized() {
      this.trigger('provider_initialized');
    },

    providerWarning: function providerWarning(data) {
      this.trigger('provider_warning', data);
    },
    providerWarningCleared: function providerWarningCleared(message) {
      this.trigger('provider_warning_cleared', message);
    },
    accept: function accept(callId) {
      var isAutoAnswered = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      FC.Telephony.acceptCall(callId, isAutoAnswered);
    },

    ignore: function ignore(callId, acceptingCallback, reason) {
      FC.Telephony.ignoreCall(callId, acceptingCallback, reason);
    },

    ignoreAllCalls: function ignoreAllCalls(reason) {
      FC.Telephony.ignoreAllCalls(reason);
    },

    toggleHold: function toggleHold() {
      return FC.getCurrentCall().toggleHold();
    },

    saveConversation: function saveConversation(params) {
      return FC.Telephony.saveConversation(params);
    },
    showErrorNotification: function showErrorNotification(error, msg) {
      var message = error && error.errors ? error.errors : msg;

      this.get('notification').error(message);
    },

    saveNotesIfPresent: function saveNotesIfPresent() {
      var _this12 = this;

      if (!this.get('currentCall.notes')) {
        return EmberPromise.resolve();
      }

      var callId = this.get('currentCall.id'),
          callNote = this.get('currentCall.notes');

      return this.saveConversation({
        call_id: callId,
        note: callNote
      })['catch'](function (err) {
        run(function () {
          _this12.logError(err, '- Failed to save notes');
        });
      });
    },
    saveTagsIfPresent: function saveTagsIfPresent() {
      var _this13 = this;

      if (!(this.get('hasCallTaggingFeature') && this.get('currentCall.tags'))) {
        return EmberPromise.resolve();
      }

      var callId = this.get('currentCall.id'),
          callTags = this.get('currentCall.tags').mapBy('id');

      return this.saveCallTags({
        call_id: callId,
        tag_ids: callTags
      })['catch'](function (err) {
        _this13.logError(err, '- Failed to save tags');
      });
    },

    saveCallTags: function saveCallTags(params) {
      return FC.Telephony.saveCallTags(params);
    },

    initializeCurrentCall: function initializeCurrentCall(connectionType) {
      var currentCall = Object.extend(Evented, {
        callDuration: 0,
        parallelCallDuration: 0,
        primaryHoldDuration: 0,
        secondaryHoldDuration: 0,
        callParkedTimer: 0,
        type: connectionType
      }).create();

      this.maximizeWidget();

      this.set('currentCall', currentCall);
    },
    setCurrentCallContext: function setCurrentCallContext(contactId, phoneNumber, connectionType, powerDialer) {
      this.initializeCurrentCall(connectionType);
      this.get('parallelCall').clearParallelCalls();

      if (isEqual(connectionType, 'transfer')) {
        this.set('currentCall.isTransferCall', true);
      }
      if (isEqual(connectionType, 'parallel_call')) {
        this.set('currentCall.isParallelCall', true);
      }

      return this.buildContactInfo(contactId, phoneNumber);
    },

    updateCurrentCall: function updateCurrentCall(currentCall) {
      var _this14 = this;

      run(function () {
        _this14.get('currentCall').setProperties(currentCall.properties);
        _this14.get('currentCall').trigger(currentCall.get('status'));
      });
    },
    callerName: function callerName() {
      return this.get('currentCall.contact.name') || this.callerNumber();
    },

    callerNumber: function callerNumber() {
      var from = this.get('currentCall.caller.from'),
          to = this.get('currentCall.caller.to'),
          number = this.get('currentCall.caller.number');

      return from || to || number;
    },

    buildContactInfo: function buildContactInfo(contactId, phoneNumber) {
      var _this15 = this;

      if (contactId) {
        if (this.hasOmniRedirectFeatureEnabled()) {
          var params = {};

          params['filter[id]'] = contactId.toString();

          return this.get('store').queryRecord('external-contact', params).then(function (contacts) {
            var contact = contacts.get('firstObject');
            _this15.set('currentCall.contact', contact);
            _this15.set('currentCall.initialContact', contact);
            _this15.get('currentCall').trigger('contact_loaded');
          });
        } else {

          return this.get('store').findRecord('contact', contactId).then(function (contact) {
            _this15.set('currentCall.contact', contact);
            _this15.set('currentCall.initialContact', contact);
            _this15.get('currentCall').trigger('contact_loaded');
          });
        }
      } else {
        var queryParams = { filter: { queryString: phoneNumber } };

        if (this.hasOmniRedirectFeatureEnabled()) {
          queryParams.product = this.get('account.signupApp');
          queryParams.version = _freshfoneEmberUtilsCallConstants['default'].CONTACT_SEARCH_API_VERISON;
        }

        return this.get('store').query('contact', queryParams).then(function (contacts) {
          var contact = contacts.get('firstObject');
          _this15.set('currentCall.contact', contact);
          _this15.set('currentCall.initialContact', contact);
          _this15.get('currentCall').trigger('contact_loaded');
        });
      }
    },

    sendDigits: function sendDigits(digit) {
      FC.getCurrentCall().sendDigits(digit);
    },

    toggleMute: function toggleMute() {
      FC.getCurrentCall().toggleMute();
      var currentCallProperties = FC.getCurrentCall().properties;

      this.set('currentCall.muted', currentCallProperties.muted);
    },

    enableRingOnSpeakers: function enableRingOnSpeakers() {
      this.get('agent').enableRingOnSpeakers();
    },

    disableRingOnSpeakers: function disableRingOnSpeakers() {
      this.get('agent').disableRingOnSpeakers();
    },

    enabledSIPForAllCallback: function enabledSIPForAllCallback() {
      var options = {
        closeButton: true,
        progressBar: false
      };

      localStorage.setItem('initialisedEnableAll', false);
      this.trigger('sip_enabled_all');

      this.get('notification').success(_ember['default'].i18n.t('sip.enabledAll'));
    },

    transferToCsatIvr: function transferToCsatIvr(source) {
      var _this16 = this;

      this.set('transferringToCsatIvr', true);
      this.getCurrentCall().transferToCsatIvr(source, this.get('isParallelCallActive'))['catch'](function () {
        return run(function () {
          if (_this16.get('callInProgress')) {
            _this16.get('notification').error(_this16.get('i18n').t('csat_ivr.errors.transfer'));
          }
        });
      })['finally'](function () {
        run(function () {
          _this16.set('transferringToCsatIvr', false);
          _this16.updateCurrentCall(_this16.getCurrentCall());
        });
      });
    },
    callAssignmentStatusUpdateCallback: function callAssignmentStatusUpdateCallback(assignment) {
      var _this17 = this;

      if (assignment.success) {
        this.get('notification').success(_ember['default'].i18n.t('live_dashboard.wqm.call_assignment_successful', { agentName: assignment.assignee }), _ember['default'].i18n.t('toastr.success'), { onclick: function onclick() {
            return _this17.get('routing').transitionTo('protected.live');
          } });
      } else {
        this.get('notification').error(_ember['default'].i18n.t('live_dashboard.wqm.call_assignment_failed', { agentName: assignment.assignee }), _ember['default'].i18n.t('toastr.error'), { onclick: function onclick() {
            return _this17.get('routing').transitionTo('protected.live');
          } });
      }

      delete this.get('attemptedWqmCallAssignments')[assignment.call_id];
    },

    log: function log(type, name, message, data) {
      var logData = {
        type: type,
        name: name,
        message: message,
        meta: {
          accountId: this.get('session.account.id'),
          userId: this.get('session.user.id'),
          source: 'app'
        }
      },
          loggerInstance = window.Object.assign({ data: data }, logData);

      FC.Logger.log(loggerInstance);
    },
    updateCurrentCallCallback: function updateCurrentCallCallback() {
      var _this18 = this;

      run(function () {
        return _this18.updateCurrentCall(_this18.getCurrentCall());
      });
    },
    updateCallCompleted: function updateCallCompleted() {
      this.set('currentCall.isCompleted', true);
    },
    logError: function logError(err, message) {
      // eslint-disable-next-line no-console
      console.error(message, err);
    },
    switchCall: function switchCall(callId) {
      FC.Telephony.switchCurrentCall(callId);

      return this.updateCurrentAndParkedCalls(callId);
    },
    updateCurrentAndParkedCalls: function updateCurrentAndParkedCalls(callId) {
      var _this19 = this;

      this.set('callSwitched', false);
      this.set('currentCall.tags', undefined);

      var currentCallId = this.get('currentCall.id'),
          currentCall = this.getCurrentCall(),
          parkTimer = this.get('parkedCallsService').getParkDurationById(parseInt(callId));

      run(function () {
        _this19.get('parallelCall').switchParallelCall(currentCallId, callId);
        _this19.get('parkedCallsService').checkAndUpdateParkedCall(_this19.getParkedCalls());

        if (!currentCallId) {
          _this19.initializeCurrentCall(currentCall.type);
        }

        _this19.updateCurrentCall(currentCall);
        _this19.updateCallTimers(currentCall, parkTimer);
        _this19.set('showCallUnparkTooltip', true);
      });

      return this.fetchCallInfo();
    },
    updateCallTimers: function updateCallTimers(currentCall, callParkedTimer) {
      var _this20 = this;

      var callDuration = currentCall.get('ongoingCallDuration');

      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];
      var parallelCallDuration = parallelCall && parallelCall.get('timer.ongoing') || 0;
      var secondaryHoldDuration = parallelCall && parallelCall.get('timer.hold') || 0;

      run(function () {
        _this20.get('currentCall').setProperties({
          callDuration: callDuration,
          parallelCallDuration: parallelCallDuration,
          secondaryHoldDuration: secondaryHoldDuration,
          callParkedTimer: callParkedTimer
        });
      });
    },
    fetchCallInfo: function fetchCallInfo() {
      var _this21 = this;

      var currentCall = this.get('currentCall'),
          contactId = currentCall.get('contactId'),
          phoneNumber = currentCall.get('type') === _freshfoneEmberConstantsCallType['default'].Outgoing ? currentCall.get('phone_number') || currentCall.get('caller.to') : currentCall.get('caller.from'),
          apis = [this.buildContactInfo(contactId, phoneNumber).then(function () {
        return _this21.set('callSwitched', true);
      }), this.fetchOtherContacts(), this.fetchCallNote(), this.fetchCallTags()];

      return Promise.allSettled(apis).then(function () {
        run(function () {
          _this21.maximizeWidget();
          _this21.stopTransition();
        });
      });
    },
    close: function close() {
      FC.Telephony.close();
    },
    maximizeWidget: function maximizeWidget() {
      if (this.get('currentCall.minimized')) {
        this.set('currentCall.minimized', false);
        $('.modal-backdrop').show();
      }
    },
    setIncomingConnection: function setIncomingConnection(connection) {
      this.set('incomingConnection', connection);
    },
    clearIncomingConnection: function clearIncomingConnection() {
      this.set('incomingConnection', null);
    },
    stopTransition: function stopTransition() {
      this.get('transition').stop();
      this.set('parking', false);
    },
    showWarningNotificationForConcurrency: function showWarningNotificationForConcurrency(callData) {
      var status = callData.attributes.status;
      var warningMessage = status === _freshfoneEmberConstantsCallStatus['default'].Completed || status === _freshfoneEmberConstantsCallStatus['default'].Abandoned ? 'notification_widget.customer_abandoned_warning' : 'notification_widget.ringing_timout_warning';

      this.get('notification').info(this.get('i18n').t(warningMessage), '');
    },
    hangUpParams: function hangUpParams(reason) {
      var callId = this.get('acceptedCallId') || this.get('currentCall.id'),
          params = {
        id: callId,
        userId: this.get('session.user.id'),
        reason: reason,
        type: 'system'
      };

      return params;
    },
    callStatusUpdatedCallback: function callStatusUpdatedCallback(data) {
      var _JSON$parse = JSON.parse(data);

      var callData = _JSON$parse.call.data;
      var callId = callData.id;

      if (String(callId) === String(this.get('acceptedCallId')) && _freshfoneEmberConstantsCallStatus['default'].CallEndedStatus.includes(callData.attributes.status)) {

        var isNotCallbackConcurrency = callData.attributes.category === _freshfoneEmberConstantsCallCategory['default'].Callback && callData.attributes.status === _freshfoneEmberConstantsCallStatus['default'].Completed;

        console.log('Is callback call', isNotCallbackConcurrency, callId);

        /*
         * Make sure current call id is not set,
         * that proves the user is in connecting state and has not connected to the call yet...
         */
        if (this.get('currentCall') && this.get('currentCall.id') === undefined && !isNotCallbackConcurrency) {
          console.log('Call has been abandoned by customer or call moved back to the call queue, Ending the call');
          // Sending concurrency call log to SDK
          this.log('info', 'Standalone Concurrency Accept/Decline/Timeout', 'Concurrent scenario handled for user with ' + callData.attributes.status, {});

          this.showWarningNotificationForConcurrency(callData);

          // End the call.
          if (this.getCurrentCall()) {
            var params = this.hangUpParams(_freshfoneEmberConstantsCallStatus['default'].Concurrent);

            this.get('hangupService').registerHangUpEvent(params);
            this.getCurrentCall().end();
          }
          this.set('currentCall', null);
          this.set('acceptedCallId', null);
        }
      }

      if (!this.get('acceptedCallId') || this.get('acceptedCallId') !== callId || !this.get('currentCallIsParked')) {
        return;
      }

      this.stopTransition();
      this.set('acceptedCallId', undefined);
    },
    disconnectConnectingCall: function disconnectConnectingCall(callParams) {
      if (String(callParams.call_id) === String(this.get('acceptedCallId')) && this.get('currentCall') && this.get('currentCall.id') === undefined) {
        console.log('Disconnect call back triggered', callParams);
        // End the call.
        if (this.getCurrentCall()) {
          this.getCurrentCall().end();
        }
        this.set('currentCall', null);
        this.set('acceptedCallId', null);
      }
    },
    callNotificationExist: function callNotificationExist(callId) {
      return FC.Telephony.callNotificationExist(callId);
    },
    triggerWarning: function triggerWarning(warning) {
      FC.Telephony.triggerWarning(warning);
    },
    clearWarning: function clearWarning(warning) {
      FC.Telephony.clearWarning(warning);
    },
    redirectToCrmPages: function redirectToCrmPages() {
      var rootURL = localStorage.getItem('rootURL');

      var _rootURL$split = rootURL.split('/');

      var _rootURL$split2 = _slicedToArray(_rootURL$split, 2);

      var crmPath = _rootURL$split2[1];
      var url = this.get('currentAccount.orgUrl') + '/' + crmPath + '/sales/contacts';

      window.open(url, '_self');
    }
  });
});
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-else-return */