define('freshfone-ember/router', ['exports', 'ember', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/config/environment', 'ember-unified-navbar-360/mixins/recent-history', 'ember-unified-navbar-360/mixins/highlight-current-menu'], function (exports, _ember, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberConfigEnvironment, _emberUnifiedNavbar360MixinsRecentHistory, _emberUnifiedNavbar360MixinsHighlightCurrentMenu) {
  var _Ember$run = _ember['default'].run;
  var later = _Ember$run.later;
  var scheduleOnce = _Ember$run.scheduleOnce;
  var Router = _ember['default'].Router;

  var Route = Router.extend(_emberUnifiedNavbar360MixinsHighlightCurrentMenu['default'], _emberUnifiedNavbar360MixinsRecentHistory['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    location: _freshfoneEmberConfigEnvironment['default'].locationType,
    rootURL: _freshfoneEmberConfigEnvironment['default'].rootURL,

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      if (this.get('isCrmView')) {
        this.capture();
      }
      scheduleOnce('afterRender', this, function () {
        later(this, function () {
          this.addActiveClass(this.get('url').includes('admin') ? 's360_fsa_settings' : undefined);
        });
      });
    }
  });

  Route.map(function () {
    this.route('protected', { path: '/' }, function () {
      // added route for getStarted modal
      this.route('get-started', { path: '/getstarted' });
      this.route('dashboard');
      // route for number purchase page
      this.route('get-number', { path: '/getnumber' });
      this.route('calls');
      this.route('live', function () {});
      this.route('contacts', function () {
        this.route('show', { path: '/:contact_id' }, function () {
          this.route('conversations', { resetNamespace: true }, function () {});
        });
      });
      this.route('companies', function () {
        this.route('show', { path: '/:company_id' });
      });
      this.route('users', function () {
        this.route('show', { path: '/:user_id' });
      });
      this.route('call-logs', function () {});
      this.route('admin', function () {
        this.route('numbers', function () {});
        this.route('rules', function () {});
        this.route('businesshour', function () {});
        this.route('callflows', function () {
          this.route('edit', { path: '/:flow_id' });
          this.route('new');
        });
        this.route('ivr', function () {
          this.route('edit', { path: '/:flow_id' });
          this.route('new');
          this.route('manage-intents', function () {});
        });
        this.route('configure-bot', function () {});
        this.route('deflection-bot', function () {
          this.route('edit', { path: '/:flow_id' });
          this.route('new');
        });
        this.route('agent-extension', function () {
          this.route('edit', { path: '/:flow_id' });
          this.route('new');
        });
        this.route('data-automated-routing', function () {
          this.route('edit', { path: '/:flow_id' });
          this.route('new');
        });
        this.route('messages', function () {});
        this.route('teams', function () {});
        this.route('agents', function () {});
        this.route('agent-extensions');
        this.route('service-level', function () {});
        this.route('billing', function () {});
        this.route('sip', function () {});
        this.route('agent-status');
        this.route('mkp-gallery', function () {});
        this.route('call-tags', function () {});
        this.route('account-settings', function () {
          // eslint-disable-next-line no-console
          console.log('Account Settings route initialised');
        });
        this.route('bulk-data-delete');
      });
      this.route('reports', function () {});
      this.route('error-page');
      this.route('account-suspended');
      this.route('download', { path: 'download_file/:export_type/:token' }, function () {});
      this.route('voicemail-drop', function () {});
      this.route('power-dialer', function () {});
      this.route('debug');
    });
    this.route('un-authorized');
    this.route('daypass-expired');
    this.route('not-found', { path: '/*path' });
  });

  exports['default'] = Route;
});
/* eslint-disable no-empty-function */
/* eslint-disable array-callback-return */