define("freshfone-ember/components/common/custom-checkbox/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/common/custom-checkbox/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "checkbox-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "checkbox-view");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createAttrMorph(element1, 'for');
        morphs[3] = dom.createMorphAt(element1, 0, 0);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "checkbox", "disabled", ["subexpr", "if", [["get", "disableSelect", ["loc", [null, [2, 40], [2, 53]]], 0, 0, 0, 0], true], [], ["loc", [null, [2, 36], [2, 59]]], 0, 0], "checked", ["subexpr", "@mut", [["get", "checkOption", ["loc", [null, [2, 68], [2, 79]]], 0, 0, 0, 0]], [], [], 0, 0], "change", ["subexpr", "action", ["callBackAction", ["get", "key", ["loc", [null, [2, 112], [2, 115]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 87], [2, 116]]], 0, 0], "class", "custom-checkbox", "id", ["subexpr", "@mut", [["get", "checkboxElementId", ["loc", [null, [2, 144], [2, 161]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 164]]], 0, 0], ["attribute", "class", ["concat", ["label-checkbox ", ["subexpr", "if", [["get", "disableSelect", ["loc", [null, [4, 36], [4, 49]]], 0, 0, 0, 0], "not-allowed-pointer", "cursor-pointer"], [], ["loc", [null, [4, 31], [4, 90]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "for", ["get", "checkboxElementId", ["loc", [null, [4, 98], [4, 115]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "message", ["loc", [null, [4, 118], [4, 129]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});