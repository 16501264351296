define('freshfone-ember/components/live-calls/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/mixins/push-payload', 'freshfone-ember/mixins/event-bind-mixin', 'freshfone-ember/mixins/safe-set', 'freshfone-ember/constants/views'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberMixinsPushPayload, _freshfoneEmberMixinsEventBindMixin, _freshfoneEmberMixinsSafeSet, _freshfoneEmberConstantsViews) {

  var WEB_WORKER_TIMER_INTERVAL = 300000; //5 minutes in ms
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], _freshfoneEmberMixinsPushPayload['default'], _freshfoneEmberMixinsSafeSet['default'], _freshfoneEmberMixinsEventBindMixin['default'], {
    classNames: ["live-dashboard"],
    telephony: service(),
    session: service(),
    timer: service(),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    agentBusy: computed.equal('currentAgent.browserStatus.statusType', 'busy'),
    currentCall: computed.alias('telephony.currentCall'),
    isAgent: computed.alias('currentUser.isAgent'),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    isSupervisor: computed.alias('currentUser.isSupervisor'),
    hasDataScopingFeature: computed(function () {
      return this.checkFeature('data_scoping');
    }),
    hasMultipleQueues: computed.bool('model.queueOptions.length'),
    i18n: service(),
    metricsLoaded: true,
    selectedQueueIds: computed.mapBy('model.selectedQueues', 'id'),
    isMonitorOrBarge: computed('currentCall.type', 'agentBusy', function () {
      return ['monitor', 'barge'].includes(this.get('currentCall.type'));
    }),
    isCrmCxView: computed('isCrmView', function () {
      return this.get('isCrmView') && this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),
    isCallBarging1CxDisabled: computed('isCrmCxView', function () {
      return this.get('isCrmCxView') && !this.hasGlobalFeature('call_barging_widget_v2');
    }),
    serviceLevelRefreshInterval: computed.alias('session.account.serviceLevelRefreshInterval'),
    webWorkerTimerInterval: computed('serviceLevelRefreshInterval', function () {
      return this.get('serviceLevelRefreshInterval') ? Number(this.get('serviceLevelRefreshInterval')) * 1000 : WEB_WORKER_TIMER_INTERVAL;
    }),
    hasSLMFeature: computed('session', function () {
      return this.checkFeature('service_level_monitoring');
    }),
    isActiveCall: computed('hasActiveCall', 'currentCall', function () {
      if (this.get('currentCall.type') && this.get('isMonitorOrBarge')) {
        return false;
      }
      return this.get('hasActiveCall');
    }),
    isMonitoredCall: computed('currentCall', 'agentBusy', function () {
      return this.get('isMonitorOrBarge') && this.get('agentBusy');
    }),

    hasViewCreated: computed.gt('session.account.slmViewCount', 0),

    hasAccessToEnhancedLiveDashBoard: computed(function () {
      return this.hasGlobalFeature("enhanced_live_dashboard");
    }),

    maxLimit: _freshfoneEmberConstantsViews['default']['viewsLimit'],
    viewsCount: computed.alias('views.length'),
    isMaxViewCountReached: computed('views.[]', 'maxLimit', function () {
      return this.get('viewsCount') >= this.get('maxLimit') || this.get('session.account.slmViewCount') >= this.get('maxLimit');
    }),
    hasLiveAgentsEnhancedFeature: computed(function () {
      return this.hasGlobalFeature('live_agents_enhanced_global') && this.checkFeature('paginated_live_dashboard');
    }),
    init: function init() {
      var self = this;
      this._super.apply(this, arguments);

      this.set('statusUpdate', this.get('statusUpdate').bind(this));

      if (this.get('telephony.isLoaded') || this.get('telephony.isSdkLoadedFromCrm')) {
        this.subcribeEvents();
      } else {
        scheduleOnce('afterRender', this, 'bindNodeEvents', 350);
      }

      this.get('timer').create('webWorkerTimer', this.get('webWorkerTimerInterval'), function () {
        _ember['default'].run(function () {
          self.set('refreshSlmComponent', false);
          if (self.get('hasSLMFeature')) {
            self.set('refreshSlmComponent', true);
          }
          self.loadCalls();
        });
      });
    },

    bindNodeEvents: function bindNodeEvents() {
      var event = !this.get('isCrmView') ? 'loaded' : 'sdkLoadedFromCrm',
          that = this;

      this.get('telephony').on(event, function () {
        that.subcribeEvents();
      });
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      scheduleOnce('afterRender', this, 'loadCalls');
    },

    willDestroyElement: function willDestroyElement() {
      this.get('timer').destroy('webWorkerTimer');
      this.get('telephony.event').unsubscribe('status_update', this.get('statusUpdate'));
    },

    statusUpdate: function statusUpdate(data) {
      var _this = this;

      var parsedData = typeof data === 'string' ? JSON.parse(data) : data;

      if (parsedData.notification_type && parsedData.notification_type === 'simultaneous_accept') return;

      /* Filtering only what's in the agent's scope is currently done on the frontend.
      Moving forward, this event will be sent to respective rooms, eg., number room, team room. */
      var callPayload = parsedData.call,
          userScopeName = this.get('currentUser.scope.name'),
          checkScope = function checkScope(prop) {
        prop = prop.toLowerCase() + 's';

        return parsedData.notification_scope && parsedData.notification_scope[prop] && parsedData.notification_scope[prop].length && parsedData.notification_scope[prop].some(function (entity) {
          if (prop === 'teams') {
            return _this.get('currentUser.teams').mapBy('id').includes(entity.toString());
          } else if (prop === 'numbers') {
            return _this.get('currentUser.numbersInScope').mapBy('number').includes(entity);
          }
        });
      };

      if (this.get('hasDataScopingFeature') && userScopeName && userScopeName.toLowerCase() !== 'global') {
        if (checkScope(userScopeName)) {
          return this.pushPayload(callPayload);
        }
      } else {
        return this.pushPayload(callPayload);
      }
    },

    subcribeEvents: function subcribeEvents() {
      this.get('telephony.event').subscribe('status_update', this.get('statusUpdate'));
    },

    intervalFilter: computed(function () {
      return [{ name: this.hourInterval(), value: 'hour' }, { name: _ember['default'].i18n.t('calls_dashboard.today'), value: 'day' }];
    }),
    interval: computed('intervalFilter', function () {
      return this.get('model.intervalOption') ? this.getInterval(this.get('model.intervalOption')) : this.getInterval('hour');
    }),
    queueIds: computed('model.selectedQueues', function () {
      return isEmpty(this.get('selectedQueueIds')) ? 'all' : this.get('selectedQueueIds');
    }),

    getInterval: function getInterval(value) {
      return this.get('intervalFilter').findBy('value', value);
    },

    loadCalls: function loadCalls() {
      this.send('setIntervalChange', this.get('interval'));
    },

    hourInterval: function hourInterval() {
      return this.get('i18n').t('calls_dashboard.last_hour', { hour_interval: this.getHoursInterval() });
    },

    getHoursInterval: function getHoursInterval() {
      var m = moment();
      var currentHour = m.format('ha');
      var nextHour = m.add(1, 'hour').format('ha');
      return currentHour + ' - ' + nextHour;
    },

    setCount: function setCount(count_split) {
      var queues = this.get('selectedQueueIds');
      for (var call_type in count_split) {
        this[call_type] = 0;
        for (var queue_id in count_split[call_type]) {
          if (isEmpty(queues) || queues.includes(queue_id)) {
            this[call_type] += count_split[call_type][queue_id];
          }
        }
      }

      if (!(this.isDestroyed || this.isDestroying)) {
        this.setProperties({
          outgoingCount: queues.length ? 0 : count_split['outgoing'],
          totalCount: this['total'],
          incomingCount: this['incoming'],
          missedCount: this['missed'],
          missedIncomingCount: this['missed_incoming'],
          missedOutgoingCount: this['missed_outgoing'],
          voicemailCount: this['voicemail'],
          abandonedCount: this['abandoned']
        });
      }
    },
    metricsUnavailable: computed.equal('totalCount', 0),
    shouldShowScopingDisclaimer: computed.and('hasDataScopingFeature', 'metricsUnavailable'),

    filterQueues: function filterQueues() {
      var _this2 = this;

      var selectQueues = [];
      this.get('model.queueOptions').forEach(function (queueOption) {
        queueOption.options.forEach(function (queue) {
          _this2.get('viewQueuesList').forEach(function (viewQueue) {
            if (viewQueue.id == queue.id) {
              selectQueues.push(queue);
            }
          });
        });
      });

      return selectQueues;
    },

    actions: {
      toggleFullscreen: function toggleFullscreen() {
        var _this3 = this;

        this.set('fullscreenMode', false);
        var elem = this.element.querySelector('.full-screen-container');
        if (!this.get('fullscreenMode')) {
          this.set('fullscreenMode', true);
          elem.requestFullscreen().then({})['catch'](function (err) {
            alert(_this3.get('i18n').t('calls_dashboard.full_screen_mode.error_message', { message: err.message, name: err.name }));
          });
          return;
        } else {
          document.exitFullscreen();
        }
      },
      setIntervalChange: function setIntervalChange(interval) {
        var _this4 = this;

        this.toggleProperty('metricsLoaded');
        this.set('interval', interval);
        _ember['default'].$.ajax({
          url: '/calls/live_dashboard_stats',
          type: 'get',
          dataType: 'json',
          data: { type: interval.value }
        }).then(function (data) {
          _ember['default'].run(function () {
            _this4.setCount(data);
            _this4.toggleProperty('metricsLoaded');
          });
        });
      },
      refreshCallMetrics: function refreshCallMetrics(interval) {
        this.send('setIntervalChange', interval);
        _ember['default'].$.ajax({
          url: '/calls/set_filter_interval',
          type: 'post',
          dataType: 'json',
          data: { type: interval.value }
        });
      },
      setSelectedQueues: function setSelectedQueues(queues) {
        this.set('model.selectedQueues', queues);
      },
      onAgentStatusHashUpdate: function onAgentStatusHashUpdate(agentsGroupedByStatus) {
        var onlineGroup = agentsGroupedByStatus.get('online');

        this.set('onlineAgents', onlineGroup ? onlineGroup.get('dashboardAgents') : []);
      },
      setSelectedView: function setSelectedView(view) {
        this.set('viewQueuesList', view.get('queues'));
        this.set('selectedView', view);
        this.send('setSelectedQueues', this.filterQueues());
        this.send('setIntervalChange', this.get('interval'));
        this.set('showSaveView', true);
      }
    }
  });
});