define('freshfone-ember/constants/default-agent-statuses', ['exports', 'ember'], function (exports, _ember) {

  var agentStatuses = [{
    name: _ember['default'].i18n.t('agentStatusWidget.defaultStatuses.afterCallWork'),
    type: 'acw',
    icon: 'icon-acw',
    isACW: true
  }, {
    name: _ember['default'].i18n.t('agentStatusWidget.defaultStatuses.busy'),
    type: 'busy',
    icon: 'icon-busy'
  }, {
    name: _ember['default'].i18n.t('agentStatusWidget.defaultStatuses.offline'),
    type: 'offline',
    icon: 'icon-offline'
  }, {
    name: _ember['default'].i18n.t('agentStatusWidget.defaultStatuses.availableOnMobile'),
    type: 'online',
    icon: 'icon-on-mobile'
  }, {
    name: _ember['default'].i18n.t('agentStatusWidget.defaultStatuses.availableOnBrowser'),
    type: 'online',
    icon: 'icon-browser'
  }];

  exports.agentStatuses = agentStatuses;
});