define("freshfone-ember/components/custom-elements/queue-list/component", ["exports", "ember"], function (exports, _ember) {
  var computed = _ember["default"].computed;
  var Component = _ember["default"].Component;
  exports["default"] = Component.extend({
    options: computed({
      set: function set(k, value) {
        if (value.length) {
          return value;
        }
      },
      // eslint-disable-next-line no-empty-function
      get: function get() {}
    }),

    actions: {
      selectAll: function selectAll(groupName) {
        this.sendAction("selectAll", groupName);
      },

      deselectAll: function deselectAll(groupName) {
        this.sendAction("deselectAll", groupName);
      }
    }
  });
});