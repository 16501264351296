define('freshfone-ember/adapters/service-level-metric', ['exports', 'ember', 'freshfone-ember/mixins/adapters/multi-word-underscore'], function (exports, _ember, _freshfoneEmberMixinsAdaptersMultiWordUnderscore) {
  exports['default'] = _freshfoneEmberMixinsAdaptersMultiWordUnderscore['default'].extend({
    handleResponse: function handleResponse(status, headers, payload) {
      if ((status === 422 || status === 417) && payload.errors) {
        return new DS.AdapterError(payload.errors);
      }

      return this._super.apply(this, arguments);
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var isPresent = _ember['default'].isPresent;

      if (isPresent(query) && isPresent(query.id)) {
        var queryId = query.id;

        return '/service_level_metrics/' + queryId + '/';
      }

      return this._super(modelName, id, snapshot, requestType, query);
    }
  });
});