define('freshfone-ember/helpers/s360-display-count', ['exports', 'ember-unified-navbar-360/helpers/s360-display-count'], function (exports, _emberUnifiedNavbar360HelpersS360DisplayCount) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberUnifiedNavbar360HelpersS360DisplayCount['default'];
    }
  });
  Object.defineProperty(exports, 's360DisplayCount', {
    enumerable: true,
    get: function get() {
      return _emberUnifiedNavbar360HelpersS360DisplayCount.s360DisplayCount;
    }
  });
});