define('freshfone-ember/constants/routing-automation', ['exports'], function (exports) {
  /* eslint-disable one-var */

  var RULE_TYPES = Object.freeze({
    create: 1,
    'import': 2
  });

  exports.RULE_TYPES = RULE_TYPES;
  var IMPORT_MODAL_PARAMS = {
    showImportModal: false,
    /**
     * default behaviour of `import_service` is to upload file as soon as the user selects
     * to perform actions before upload you can set `passive` to true
     * which will upload file only when `upload_file` event is triggered
     * @module import-service
     * @event upload_file
     */
    passive: true,
    fileModel: 'routing_rules',
    close: 'importRulesModalClose'
  };
  exports.IMPORT_MODAL_PARAMS = IMPORT_MODAL_PARAMS;
});