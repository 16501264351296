define('freshfone-ember/components/custom-elements/message-list/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    options: computed({
      set: function set(k, value) {
        if (value.length) {
          return value[0].get('constructor.modelName') === 'message' ? value.filterBy('messageScope', 0) : value;
        }
      },
      // eslint-disable-next-line no-empty-function
      get: function get() {}
    })
  });
});