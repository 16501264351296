define('freshfone-ember/components/billing-base/subscription-base/account-settings/component', ['exports', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/utils/timezone-constants', 'freshfone-ember/helpers/list-timezone', 'freshfone-ember/constants/supported-languages'], function (exports, _emberRlDropdownMixinsRlDropdownComponent, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberUtilsTimezoneConstants, _freshfoneEmberHelpersListTimezone, _freshfoneEmberConstantsSupportedLanguages) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_emberRlDropdownMixinsRlDropdownComponent['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    currentAccount: computed.alias('session.account'),
    accountTimezone: computed('currentAccount.timeZone', function () {
      var timezone = this.get('session.account.timeZone');

      return (0, _freshfoneEmberHelpersListTimezone.listTimezone)().filterBy('id', timezone)[0];
    }),
    accountLanguage: computed('currentAccount.language', function () {
      var language = this.get('session.account.language');
      return _freshfoneEmberConstantsSupportedLanguages.supportedLanguages[language];
    }),
    isOmnichannelAccount: computed.alias('currentAccount.isOmnichannelEnabled'),
    noTimezoneLabel: _ember['default'].i18n.t('account_settings.timezone_unavailable'),
    noLanguageLabel: _ember['default'].i18n.t('account_settings.language_unavailable'),
    timezonesList: (0, _freshfoneEmberHelpersListTimezone.listTimezone)(),
    languageList: Object.values(_freshfoneEmberConstantsSupportedLanguages.supportedLanguages),
    deletionScheduled: computed.alias('session.account.deletionScheduled'),
    subscription: computed.alias('session.account.subscription'),

    createdAtDate: computed(function () {
      return moment(this.get('currentAccount.created_at')).format('MMM DD, YYYY');
    }),
    actions: {
      closeCallback: function closeCallback() {
        this.set('isCancelAccount', false);
        if (this.get('isDeleteRequested')) {
          this.set('cancelAccountAcknowledged', true);
          this.set('session.account.deletionScheduled', true);
        }
      },
      openModal: function openModal() {
        this.set('isCancelAccount', true);
      },

      toggleOpenCancelModal: function toggleOpenCancelModal() {
        this.set('openCancelModal', true);
      },
      selectLanguage: function selectLanguage() {
        this.toggleProperty('dropdownExpanded');
      },
      setTimezone: function setTimezone(changedValue) {
        var _this = this;

        var selectedTimezone = undefined;
        _freshfoneEmberUtilsTimezoneConstants['default'].timezones.forEach(function (code) {
          if (code.id == changedValue.id) selectedTimezone = code.id;
        });
        this.set('currentAccount.timeZone', selectedTimezone);
        this.get('session.account').updateAccount({ account: { time_zone: selectedTimezone } }).then(function () {
          run.later(_this, function () {
            window.location.reload();
          }, 1000);
        });
      },
      setLanguage: function setLanguage(changedValue) {
        var _this2 = this;

        var selectedCode = undefined;

        this.set('languageChanged', false);
        Object.keys(_freshfoneEmberConstantsSupportedLanguages.supportedLanguages).forEach(function (code) {
          if (_freshfoneEmberConstantsSupportedLanguages.supportedLanguages[code]['name'] === changedValue.name) selectedCode = code;
        });
        this.set('currentAccount.language', selectedCode);
        this.get('session.account').updateAccount({ account: { language: selectedCode } }).then(function () {
          run.later(_this2, function () {
            window.location.reload();
          }, 1000);
        });
      }
    }
  });
});