define('freshfone-ember/services/mkp-data', ['exports', 'ember', 'freshfone-ember/utils/mkp-data-formatter', 'freshfone-ember/constants/mkp-config'], function (exports, _ember, _freshfoneEmberUtilsMkpDataFormatter, _freshfoneEmberConstantsMkpConfig) {
  var Service = _ember['default'].Service;
  var service = _ember['default'].inject.service;
  var merge = _ember['default'].merge;
  var availableDataApis = _freshfoneEmberConstantsMkpConfig['default'].availableDataApis;
  exports['default'] = Service.extend({
    mkpAdapter: service(),
    session: service(),
    telephony: service(),

    getLoggedInAgent: function getLoggedInAgent() {
      var agent = this.get('session.user.agent').toJSON({ includeId: true }),
          user = this.get('session.user').toJSON({ includeId: true });

      return (0, _freshfoneEmberUtilsMkpDataFormatter['default'])(merge(user, agent), 'agent');
    },
    getDomainName: function getDomainName() {
      return window.location.origin;
    },

    /*
     * "currentCall" and "currentCaller" are polymorphic
     * In "notification_card", it's the first call and in "conversation_card", it's the call in progress
     */
    getCurrentCall: function getCurrentCall() {

      switch (this.get('mkpAdapter.page')) {
        case 'conversation_card':
          this.set('currentCall', this.get('telephony.currentCall'));
          break;
      }

      return (0, _freshfoneEmberUtilsMkpDataFormatter['default'])(this.get('currentCall'), 'call');
    },
    getCurrentCaller: function getCurrentCaller() {
      switch (this.get('mkpAdapter.page')) {
        case 'conversation_card':
          this.set('currentCaller', this.get('telephony.currentCall.contact'));
          break;
        case 'notification_card':
          this.set('currentCaller', this.get('currentCall.associatedContact'));
      }

      return (0, _freshfoneEmberUtilsMkpDataFormatter['default'])(this.get('currentCaller'), 'contact');
    },
    getCurrentNumber: function getCurrentNumber() {
      return (0, _freshfoneEmberUtilsMkpDataFormatter['default'])(this.get('telephony.activeFreshfoneNumber'));
    },
    getData: function getData(entity) {
      console.log('Marketplace data entity', entity);
      if (!this.get('mkpAdapter.canShowMkp')) {
        return;
      }

      if (availableDataApis.includes(entity)) {
        console.log('Marketplace data entity includes', 'get' + entity.capitalize());

        return this['get' + entity.capitalize()]();
      }

      return { error: 'No such entity. Available entities are ' + availableDataApis };
    }
  });
});
/* eslint-disable camelcase, no-console */