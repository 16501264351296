define('freshfone-ember/components/billing-base/component', ['exports', 'ember-data', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/utils/subscription-constants', 'freshfone-ember/mixins/safe-set'], function (exports, _emberData, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberUtilsSubscriptionConstants, _freshfoneEmberMixinsSafeSet) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var $ = _ember['default'].$;
  var PromiseObject = _emberData['default'].PromiseObject;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsSafeSet['default'], {
    init: function init() {
      this._super.apply(this, arguments);
      if (typeof ChargeBee === 'undefined') {
        $.getScript('https://js.chargebee.com/v1/chargebee.js');
      }
    },
    planInitiated: false,
    currentAccount: computed.alias('session.account'),
    isOmnichannelAccount: computed.alias('currentAccount.isOmnichannelEnabled'),
    hasCreditSplitFeature: computed(function () {
      return this.hasGlobalFeature('credit_sku_split_global') && this.checkFeature('credit_sku_split');
    }),
    subscription: computed(function () {
      var _this = this;

      return PromiseObject.create({
        promise: this.store.queryRecord('subscription', {}).then(function (subscription) {
          _this.set('subscriptionLoaded', true);

          return subscription;
        })
      });
    }),
    plans: computed(function () {
      var _this2 = this;

      return PromiseObject.create({
        promise: this.store.queryRecord('plan', {}).then(function (plans) {
          _this2.set('planInitiated', true);

          return plans.filter(function (plan) {
            if (plan.get('classic')) {
              return false;
            }
            if (_this2.get('isOmnichannelAccount')) {
              return _this2.get('currentAccount.hasPricingRebrandingFeature') ? _freshfoneEmberUtilsSubscriptionConstants['default'].REBRANDED_OMNICHANNEL_PLANS.includes(plan.get('name')) : _freshfoneEmberUtilsSubscriptionConstants['default'].OMNICHANNEL_PLANS.includes(plan.get('name'));
            }

            return true;
          });
        })
      });
    }),
    scheduledCustomer: computed(function () {
      return this.store.peekAll('downgradeCustomer').filterBy('status', _freshfoneEmberUtilsSubscriptionConstants['default'].DOWNGRADE_CUSTOMER_STATUS.SCHEDULED)[0];
    })
  });
});