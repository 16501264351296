define('freshfone-ember/models/deflection-bot', ['exports', 'ember', 'ember-data', 'ember-validations'], function (exports, _ember, _emberData, _emberValidations) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;

  // Possible actions the deflection bot can take
  var actionTypes = ['conversationEndAction', 'agentDeflectAction', 'botFailureAction', 'invalidInputAction'];

  exports.actionTypes = actionTypes;
  exports['default'] = Model.extend(_emberValidations['default'], {
    // attributes
    name: attr('string'),
    callFlow: belongsTo('callFlow'),
    message: belongsTo('message', { async: true }),
    options: attr({ defaultValue: {
        'gather_language': 'en-US',
        'voice_language': 'en-US',
        'voice': 'woman'
      } }),

    // Rules
    conversationEndActionType: attr({ defaultValue: 'vm' }),
    conversationEndActionId: attr('number'),

    agentDeflectActionType: attr({ defaultValue: 'vm' }),
    agentDeflectActionId: attr('number'),

    botFailureActionType: attr({ defaultValue: 'hangup' }),
    botFailureActionId: attr('number'),

    invalidInputMessage: belongsTo('message', { async: true }),
    invalidInputThreshold: attr('number', { defaultValue: 3 }),
    // If invalid input threshold exceeded
    invalidInputActionType: attr({ defaultValue: 'hangup' }),
    invalidInputActionId: attr('number'),

    // computed props
    // possible actions from deflection bot
    actionOptions: computed(function () {
      return [{ id: 1, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_ivr'), desc: _ember['default'].i18n.t('call_ivr.action_types.ivr'), name: 'ivr' }, { id: 1, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_callQueue'), desc: _ember['default'].i18n.t('call_ivr.action_types.call_queue'), name: 'callQueue' }, { id: 2, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.hangup'), desc: _ember['default'].i18n.t('call_ivr.action_types.hangup'), name: 'hangup', helpText: _ember['default'].i18n.t('call_queue.edit.fallback.hangup.help_text') }, { id: 3, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.voicemail'), desc: _ember['default'].i18n.t('call_ivr.action_types.voicemail'), name: 'vm', helpText: _ember['default'].i18n.t('call_queue.edit.fallback.vm.help_text') }];
    }),

    // methods
    blankActionError: function blankActionError(property) {
      if (!this.get(property + 'Id')) {
        if (this.get(property + 'Type') === 'callQueue') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_callqueue');
        } else if (this.get(property + 'Type') === 'ivr') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_ivr');
        }
      }
    },

    // validations
    validations: {
      name: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.name')
        }
      },
      invalidInputThreshold: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.retries.blank')
        },
        numericality: {
          onlyInteger: true, greaterThanOrEqualTo: 1, lessThanOrEqualTo: 10,
          messages: {
            numericality: _ember['default'].i18n.t('call_ivr.validation_errors.retries.number'),
            onlyInteger: _ember['default'].i18n.t('call_ivr.validation_errors.retries.integer'),
            greaterThanOrEqualTo: _ember['default'].i18n.t('call_ivr.validation_errors.retries.greater'),
            lessThanOrEqualTo: _ember['default'].i18n.t('call_ivr.validation_errors.retries.lesser')
          }
        }
      },
      conversationEndActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('conversationEndAction');
        })
      },
      agentDeflectActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('agentDeflectAction');
        })
      },
      botFailureActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('botFailureAction');
        })
      },
      invalidInputActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('invalidInputAction');
        })
      }
    }
  });
});