define('freshfone-ember/components/call-logs/network-log/component', ['exports', 'ember', 'freshfone-ember/helpers/warning-name-unit'], function (exports, _ember, _freshfoneEmberHelpersWarningNameUnit) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  var PER_PAGE_RECORDS = 50;
  var ZINDEX_DEFAULT = 1040;
  var ZINDEX_MAX = 1055;
  var moment = window.moment;
  exports['default'] = Component.extend({
    i18n: service(),
    notification: service('toastr'),
    currentPage: 1,
    totalPages: 1,
    networkWarnings: [],
    isFetchingRecords: false,
    showLearnMoreModal: false,
    hasMore: computed('currentPage', 'totalPages', function () {
      return this.get('currentPage') < this.get('totalPages');
    }),
    hasNoRecords: computed('networkWarnings.[]', function () {
      return this.get('networkWarnings').length === 0;
    }),
    showNoResultMessage: computed('isFetchingRecords', 'hasNoRecords', function () {
      return !this.get('isFetchingRecords') && this.get('hasNoRecords');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchNetworkWarnings(this.get('currentPage'));
    },
    logMessage: function logMessage(type, message, data) {
      // eslint-disable-next-line no-console
      console[type](message + ' : Network Log - ', data);
    },
    fetchNetworkWarnings: function fetchNetworkWarnings(page) {
      var _this = this;

      var callId = this.get('isAgentLeg') ? this.get('call.call.id') : this.get('call.id');

      this.set('isFetchingRecords', true);

      this.logMessage('info', 'Fetching records for ' + page + ' page, call Id - ' + this.get('callId'));

      this.store.query('network-warning', {
        callId: callId,
        page: page,
        per_page: PER_PAGE_RECORDS
      }).then(function (data) {
        run(function () {
          _this.logMessage('info', 'Received success response for call Id - ' + callId);

          var newWarnings = _this.mapNetworkWarning(data),
              existingWarning = _this.get('networkWarnings');

          _this.set('currentPage', parseInt(data.meta.current));
          _this.set('totalPages', parseInt(data.meta['total-pages']));

          _this.logMessage('info', 'Received success response for page - ' + _this.get('currentPage') + ' and total pages are ' + _this.get('totalPages'));

          _this.set('networkWarnings', existingWarning.concat(newWarnings));
        });
      })['catch'](function (err) {
        run(function () {
          _this.logMessage('error', 'Some error occured ', err);
          _this.get('notification').error(_this.get('i18n').t('call_log_v1.network_log.api_failure'));
        });
      })['finally'](function () {
        run(function () {
          _this.set('isFetchingRecords', false);
        });
      });
    },
    mapNetworkWarning: function mapNetworkWarning(warnings) {
      var _this2 = this;

      return warnings.map(function (warning) {

        var agent = _this2.store.peekRecord('user', warning.get('userId')),
            warningInfo = _freshfoneEmberHelpersWarningNameUnit['default'][warning.get('warningMessage')],
            eventStartTime = warning.get('eventStartTime');

        return {
          title: warningInfo.name,
          subTitle: eventStartTime && moment(eventStartTime).format('LTS'),
          properties: _this2.mapWarningProperties(warning, warningInfo, agent)
        };
      });
    },
    mapWarningProperties: function mapWarningProperties(warning, warningInfo, agent) {
      var eventStartTime = warning.get('eventStartTime'),
          eventEndTime = warning.get('eventEndTime');

      return [{
        key: 'call_log_v1.network_log.warning',
        value: warning.get('warningData') ? warningInfo.name + ' (' + warning.get('warningData') + ' ' + warningInfo.unit + ')' : warningInfo.name
      }, {
        key: 'call_log_v1.network_log.message_time',
        value: eventStartTime ? moment(eventStartTime).format('DD MMMM\'YY, hh:mm:ss A') : ' - '
      }, {
        key: 'call_log_v1.network_log.duration',
        value: eventEndTime && eventStartTime ? moment.duration(moment(eventEndTime).diff(moment(eventStartTime))).asSeconds() + ' sec' : ' - '
      }, {
        key: 'call_log_v1.network_log.snoozed',
        value: this.get('i18n').t(warning.get('snoozed') ? 'call_log_v1.network_log.yes' : 'call_log_v1.network_log.no')
      }, {
        key: 'call_log_v1.network_log.agent_name',
        value: agent ? agent.get('name') : ' - '
      }];
    },
    actions: {
      fetchMore: function fetchMore() {
        var currentPage = this.get('currentPage') + 1;

        this.fetchNetworkWarnings(currentPage);
      },
      openModal: function openModal() {
        this.set('showLearnMoreModal', true);
        /*
         * We are changing the z-index of modal backdrop when the modal is open because this is on top of call metrics slider
         * and the modal backdrop should cover the slider as well
         * So we have increased the z-index of backdrop to make it more than slider's z-index
         */
        var backdrop = $('.modal-backdrop.in');

        backdrop.css('z-index', ZINDEX_MAX);
      },
      closeModal: function closeModal() {
        this.set('showLearnMoreModal', false);
        /*
         * We are changing the z-index of modal backdrop back to normal once the modal is closed
         * So that slider is accessible properly
         */
        var backdrop = $('.modal-backdrop.in');

        backdrop.css('z-index', ZINDEX_DEFAULT);
      }
    }
  });
});
/* eslint-disable camelcase */