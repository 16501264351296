define('freshfone-ember/helpers/icon-url', ['exports', 'ember', 'freshfone-ember/constants/icon-urls'], function (exports, _ember, _freshfoneEmberConstantsIconUrls) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.iconUrl = iconUrl;
  var Helper = _ember['default'].Helper;

  function iconUrl(params) {
    var _params = _slicedToArray(params, 1);

    var iconIdentifier = _params[0];

    return _freshfoneEmberConstantsIconUrls['default'][iconIdentifier] || '';
  }

  exports['default'] = Helper.helper(iconUrl);
});