define('freshfone-ember/models/user', ['exports', 'ember-data', 'ember', 'ember-api-actions'], function (exports, _emberData, _ember, _emberApiActions) {
  var momentjs = window.moment;

  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var PromiseObject = _emberData['default'].PromiseObject;

  var USER_TYPE = Object.freeze({
    standaloneUser: 0,
    omnichannelUser: 1,
    crmUser: 2,
    sharedUser: 3
  });exports['default'] = Model.extend({
    session: service(),
    account_creator: attr('boolean'),
    name: attr(),
    timeZone: attr(),
    title: attr(),
    email: attr(),
    phone: attr(),
    mobile: attr(),
    lastCallAt: attr(),
    abilities: attr(),
    confirmed: attr(),
    ns: attr(),
    email_confirmed: attr(),
    restoreId: attr(),
    timeZoneAsTzinfo: attr('string'),
    deleted: attr('boolean'),
    language: attr(),
    ringOnSpeakers: attr(),
    exportColumns: attr(),
    emailHash: attr('string'),
    freshidUuid: attr(),
    sourceAppId: attr(),
    callParkingEnabled: attr(),
    maxParkedCalls: attr(),
    source: attr(),
    created_at: attr(),

    scope: belongsTo('accessScope', { inverse: null }),
    numbersInScope: hasMany('number', { inverse: null }),
    teams: hasMany('team'),
    calls: hasMany('call', { async: true }),
    todos: hasMany('todo', { async: true }),
    agent: belongsTo('agent', { async: false }),
    role: belongsTo('role', { async: true }),
    isConfirmed: computed.and('confirmed', 'email_confirmed'),
    userConfiguration: belongsTo('user-configuration'),
    omnichannelUser: attr('boolean'),
    bundleUserType: attr(),

    teamNames: computed('teams.[]', function () {
      return PromiseObject.create({ promise: this.get('teams').then(function (teams) {
          return teams.mapBy('name').join(', ');
        }) });
    }),
    hasAvatar: computed.notEmpty('name'),
    isAccountAdmin: computed.equal('role.name', 'Account Admin'),
    isAdmin: computed.equal('role.name', 'Admin'),
    isSupervisor: computed.equal('role.name', 'Supervisor'),
    isAgent: computed.equal('role.name', 'Agent'),
    hasPhoneNumber: computed.notEmpty('phone'),
    hasMobileNumber: computed.notEmpty('mobile'),
    hasContactNumber: computed.or('hasMobileNumber', 'hasPhoneNumber'),
    invalidNumber: false,
    extension: attr(),
    extensionEnabled: attr(),
    lastStatusChangeAt: attr(),
    format_last_call_at: computed('lastCallAt', {
      get: function get() {
        return momentjs(this.get('lastCallAt')).format('DD MMMM YYYY - hh:mm A');
      }
    }),
    fetchCallsStats: (0, _emberApiActions.memberAction)({
      type: 'GET',
      path: 'agent_calls_stats'
    }),
    isCrmUser: computed.equal('bundleUserType', USER_TYPE.crmUser),
    isSharedUser: computed.equal('bundleUserType', USER_TYPE.sharedUser),
    isStandAloneUser: computed.empty('sourceAppId'),
    currentAccount: computed.alias('session.account'),

    /*
     * powerDialerAllowed - account is not shared or the account is shared but the user is standalone user or
     * the power_dialer_for_shared_user flag is enabled
     */
    powerDialerAllowed: computed('currentAccount', function () {

      return this.get('currentAccount').get('powerDialerAllowed')
      // eslint-disable-next-line no-extra-parens
       || this.get('isStandAloneUser') && this.get('currentAccount').hasGlobalFlag('global_power_dialer');
    })
  });
});
/* eslint-disable camelcase */