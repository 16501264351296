define('freshfone-ember/components/number-component/purchase-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/locality-supported-countries'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsLocalitySupportedCountries) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  var service = _ember['default'].inject.service;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var set = _ember['default'].set;
  var RSVP = _ember['default'].RSVP;
  var i18n = _ember['default'].i18n;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], {
    isLocalVisible: computed.equal('type', 'local'),
    isTollfreeVisible: computed.equal('type', 'tollFree'),
    local: {},
    tollFree: {},
    telephony: service('telephony'),
    routing: service('-routing'),
    params: computed.alias('routing.router.currentState.routerJsState.fullQueryParams'),
    isLowCredit: computed.alias('account.credit.isCreditLow'),
    analyticsService: service('analytics-tracker'),
    account: computed.alias('session.account'),
    isTrial: computed.alias('account.isTrial'),
    availableCredits: computed.alias('account.credit.availableCredit'),
    freeCredits: computed.alias('account.subscription.freeCredits'),
    freeNumberAllowed: computed.and('freeCredits', 'noNumbers'),
    notEnoughCredit: false,
    ubxBillingUrl: computed.readOnly('account.ubxBillingUrl'),
    errorFetchingNumbers: false,
    isCrm: computed('params', function () {
      var queryParams = this.get('params'),
          isCrmUser = this.store.peekRecord('session', 'current').get('user.isCrmUser'),
          isSales360ParamPresent = queryParams && queryParams.bundle && queryParams.bundle === 'sales360';

      return isCrmUser || isSales360ParamPresent;
    }),
    accountLowCreditWarning: computed('isLowCredit', 'isCrm', 'hasCrmOnboardingFeature', function () {
      return this.get('isLowCredit') && this.get('isCrm') && this.get('hasCrmOnboardingFeature');
    }),
    is1CXCRMAccount: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),
    purchaseDisabled: false,
    translationTexts: {
      confirm: i18n.t('numbers.purchase.confirm'),
      buy: i18n.t('numbers.purchase.buy'),
      localities: i18n.t('numbers.purchase.purchase_form.all_localities'),
      lowCreditsWarning: i18n.t('numbers.purchase.purchase_form.low_credits_warning'),
      updatedLowCreditsWarning: i18n.t('numbers.purchase.purchase_form.updated_low_credits_warning'),
      goToSubscriptions: i18n.t('numbers.purchase.purchase_form.go_to_subscriptions'),
      tryAgainAfterSubscriptions: i18n.t('numbers.purchase.purchase_form.try_again_after_subscriptions'),
      mySubscription: i18n.t('numbers.purchase.purchase_form.my_subscription')
    },
    buyLabel: computed('freeNumberAllowed', function () {
      return this.get('freeNumberAllowed') ? this.get('translationTexts.confirm') : this.get('translationTexts.buy');
    }),
    tabs: [{
      name: i18n.t('numbers.local_tab'),
      type: 'local',
      isActive: true
    }, {
      name: i18n.t('numbers.toll_free_tab'),
      type: 'tollFree'
    }],

    init: function init() {
      var _this = this;

      this.set('freshfoneNumber', null);
      this._super.apply(this, arguments);
      if (this.get('scrollTo')) {
        scheduleOnce('afterRender', this, 'scrollToThis');
      }
      if (this.get('showBuy')) {
        scheduleOnce('afterRender', function () {
          _this.set('slideToggle', true);
        });
      }
      scheduleOnce('afterRender', this, 'validateCallingState');
      this.bindNodeEvents();
    },

    scrollToThis: function scrollToThis() {
      this.get('parentView').$().animate({ scrollLeft: this.$().offset().left }, 1000);
    },

    scrollBackToParent: function scrollBackToParent() {
      this.get('parent').$().animate({
        scrollLeft: this.get('parent').$().offset().left
      }, 1000);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unsubscribeStateChangeCallback();
      if (this.get('scrollTo')) {
        this.scrollBackToParent();
      }
      if (this.get('searchApi')) {
        this.get('searchApi').abort();
      }
    },

    slideToggle: computed({
      get: function get() {
        if (typeof this === 'object') {
          return false;
        }

        return this;
      },

      set: function set(key, value) {
        if (value) {
          this.send('toggleTab', 'local');
          this.set('local.isResultLoading', true);
        } else {
          this.resetAll();
        }

        return value;
      }
    }),

    hasCopyChangeFeature: computed(function () {
      return this.hasGlobalFeature('frontend_copy_updates') && this.checkFeature('frontend_copy_updates');
    }),

    isAddressRequired: function isAddressRequired(params, callback) {
      return callback(params.address_required);
    },

    resetAll: function resetAll() {
      this.set('showRate', false);
      this.set('purchaseError', false);
      this.set('notEnoughCredit', false);
      if (isPresent(this.get('freshfoneNumber'))) {
        this.set('freshfoneNumber', null);
      }
    },

    validateCallingState: function validateCallingState() {
      this.updatePurchaseDisabled(!this.get('account.callingEnabled'));
    },

    bindNodeEvents: function bindNodeEvents() {
      var statusCallBack = function statusCallBack(data) {
        this.buyStatusCallback(data);
      };

      this.get('telephony').on('account_status_change', statusCallBack.bind(this));
    },

    buyStatusCallback: function buyStatusCallback(data) {
      this.updatePurchaseDisabled(!data.callingEnabled);
    },

    updatePurchaseDisabled: function updatePurchaseDisabled(isCallingDisabled) {
      if (!this.get('isCrm')) {
        this.set('purchaseDisabled', isCallingDisabled);
      }
    },

    unsubscribeStateChangeCallback: function unsubscribeStateChangeCallback() {
      this.get('telephony').off('account_status_change');
    },

    hasCrmOnboardingFeature: computed(function () {
      return this.hasGlobalFeature('crm_onboarding') && this.checkFeature('crm_onboarding');
    }),

    searchLocalities: function searchLocalities(params) {
      var _this2 = this;

      var typeName = params.type === 'toll_free' ? 'tollFree' : 'local';

      params.locality = '';
      $.ajax({
        url: '/numbers/available',
        type: 'GET',
        dataType: 'json',
        // eslint-disable-next-line camelcase
        data: { search_options: params }
      }).done(function (resp) {
        run(function () {
          var response = resp.data ? resp.data : resp,
              localities = [];

          if (_freshfoneEmberConstantsLocalitySupportedCountries['default'].countries.contains(params.country)) {
            response.filterBy('locality').forEach(function (number) {
              localities.push({
                name: number.locality,
                shortCode: number.locality
              });
            });
            localities.unshiftObject({
              name: _this2.get('translationTexts.localities'),
              shortCode: 'default'
            });
          }
          _this2.set(typeName + '.localities', localities.uniqBy('name'));
        });
      }).fail(function (error) {
        console.log('Error! status: ' + error.status + ' ; msg: ' + error.responseText);
        _this2.set(typeName + '.localities', []);
      }).always(function () {
        run(function () {
          return _this2.set(typeName + '.isResultLoading', false);
        });
      });
    },

    actions: {
      toggleTab: function toggleTab(input) {
        if (typeof input === 'object') {
          input = input.string;
        }
        var typeName = input;

        this.set('type', typeName);
        if (!this.get(typeName + '.isRenderTemplate')) {
          set(this.get(typeName), 'isRenderTemplate', true);
        }
      },
      search: function search(params) {
        var _this3 = this;

        this.set('errorFetchingNumbers', false);
        var typeName = params.type === 'toll_free' ? 'tollFree' : 'local';

        this.set(typeName + '.isResultLoading', true);
        this.set('searchApi', $.ajax({
          url: '/numbers/available',
          type: 'GET',
          dataType: 'json',
          // eslint-disable-next-line camelcase
          data: { search_options: params }
        }));

        this.get('searchApi').done(function (resp) {
          run(function () {
            _this3.set(typeName + '.availableNumbers', resp.data ? resp.data : resp);
            _this3.searchLocalities(params);
          });
        }).fail(function (error) {
          console.log('Error! status: ' + error.status + ' ; msg: ' + error.responseText);
          if (_this3.get('isCrm') && _this3.get('hasCrmOnboardingFeature')) {
            run(function () {
              return _this3.set('errorFetchingNumbers', true);
            });
          }
          run(function () {
            return _this3.set(typeName + '.availableNumbers', []);
          });
        }).always(function () {
          run(function () {
            return _this3.set(typeName + '.isResultLoading', false);
          });
        });
      },

      checkCreditsBeforePurchase: function checkCreditsBeforePurchase(params) {
        if (this.get('isCrm') && this.get('hasCrmOnboardingFeature')) {
          if (this.get('availableCredits') < Number(params.rate)) {
            this.set('notEnoughCredit', true);
          } else {
            this.send('showAddressRateCard', params);
          }
        } else {
          this.send('showAddressRateCard', params);
        }
      },
      showAddressRateCard: function showAddressRateCard(params) {
        var _this4 = this;

        var addressCallback = function addressCallback(resolve) {
          this.isAddressRequired(params, resolve);
        };

        this.set('freshfoneNumber', params);
        new RSVP.Promise(addressCallback.bind(this)).then(function (resp) {
          _this4.set('freshfoneNumber.address_required', resp);
          _this4.set('showRate', true);
        });
      },
      cancelPurchase: function cancelPurchase() {
        this.resetAll();
        this.send('toggleTab', 'local');
      },
      purchase: function purchase() {
        var _this5 = this;

        this.get('analyticsService').addEvent('Number Purchased');

        var callBuyAction = function callBuyAction() {
          _this5.sendAction('buy', _this5.get('freshfoneNumber'));
        };

        if (this.get('freshfoneNumber.address_required')) {
          this.validate().then(function () {
            callBuyAction();
          })['catch'](function () {
            _this5.set('startValidate', true);
            _this5.set('purchaseError', false);
          });
        } else {
          callBuyAction();
        }
      },
      closeForm: function closeForm() {
        this.sendAction('closeForm');
      }
    },

    validations: {
      'freshfoneNumber.address': { presence: { message: i18n.t('activemodel.errors.not_empty') } },
      'freshfoneNumber.address.first_name': { presence: { message: i18n.t('activemodel.errors.not_empty') } },
      'freshfoneNumber.address.address1': { presence: { message: i18n.t('activemodel.errors.not_empty') } },
      'freshfoneNumber.address.city': { presence: { message: i18n.t('activemodel.errors.not_empty') } },
      'freshfoneNumber.address.state': { presence: { message: i18n.t('activemodel.errors.not_empty') } },
      'freshfoneNumber.address.zip': { presence: { message: i18n.t('activemodel.errors.not_empty') } },
      'freshfoneNumber.country': { presence: { message: i18n.t('activemodel.errors.not_empty') } }
    }

  });
});