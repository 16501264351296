define('freshfone-ember/promotion-data/agent-status', ['exports'], function (exports) {
  exports['default'] = {
    data: [{
      id: 1,
      type: 'agent-status',
      name: 'Lunch',
      emoji: '🍱',
      enabled: true
    }, {
      id: 2,
      type: 'agent-status',
      name: 'Break',
      emoji: '☕',
      enabled: true
    }, {
      id: 3,
      type: 'agent-status',
      name: 'Meeting',
      emoji: '👨‍💻',
      enabled: true
    }, {
      id: 4,
      type: 'agent-status',
      name: 'Training',
      emoji: '🏋️',
      enabled: true
    }]
  };
});