define('freshfone-ember/components/call-logs/call-details/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-mixin', 'freshfone-ember/constants/integrated-applications', 'ember-concurrency', 'freshfone-ember/utils/call-constants'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationMixin, _freshfoneEmberConstantsIntegratedApplications, _emberConcurrency, _freshfoneEmberUtilsCallConstants) {
  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationMixin['default'], {
    i18n: service(),
    accountConfigService: service('account-configuration'),
    mkpAppsFilter: { location: 'call_metrics_card' },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      $('.main-content').scroll(this.toggleShadow);
      if (this.get('callId')) {
        this.get('getExtractedCallDetails').perform(this.get('callId'));
      }
    },
    toggleShadow: function toggleShadow() {
      var scroll = $('.main-content').scrollTop();

      if (scroll > 0) {
        $('.main-content').addClass('scrolled');
      } else {
        $('.main-content').removeClass('scrolled');
      }
    },

    observeCallId: observer('callId', function () {
      if (this.get('callId')) {
        this.get('getExtractedCallDetails').perform(this.get('callId'), this.get('isAgentLeg'));
      }
    }),

    fetchCallTranscription: function fetchCallTranscription() {
      var _this = this;

      this.set('isCallTranscription', false);
      if (this.get('call.transcriptionID')) {
        this.store.findRecord('transcription', this.get('call.transcriptionID')).then(function (transcription) {
          if (transcription.get('url')) {
            _this.set('isCallTranscription', true);
          }
        });
      }
    },

    displayCallLifeCycle: computed(function () {
      return this.hasGlobalFeature('call_lifecycle_global') && this.checkFeature('call_lifecycle');
    }),
    showNetworkLog: computed('accountConfigService.config.networkWarningLogs', function () {
      return this.hasGlobalFeature('network_warning_logs') && this.get('accountConfigService.config.networkWarningLogs');
    }),

    tabs: computed('call.id', 'isCallTranscription', 'mkpApps.[]', function () {
      var tabs = new Map();

      tabs.set('callSummary', {
        key: 'callSummary',
        title: 'Call Summary',
        iconName: 'call-summary'
      });

      if (this.get('displayCallTransription')) {
        tabs.set('PostCallTranscript', {
          key: 'PostCallTranscript',
          title: _ember['default'].i18n.t('call_log_v1.call_details_tabs.post_call_transcription'),
          iconName: 'post-call-transcript'
        });
      }

      if (this.get('call.bot_conversation')) {
        tabs.set('callTranscript', {
          key: 'callTranscript',
          title: 'Call Transcript',
          iconName: 'call-transcript'
        });
      }

      if (this.get('displayCallLifeCycle')) {
        tabs.set('callLifeCycle', {
          key: 'callLifeCycle',
          title: _ember['default'].i18n.t('call_log_v1.call_details_tabs.call_lifecycle'),
          iconName: 'call-path'
        });
      }

      if (!this.get('isAgentLeg')) {
        this.get('integratedApplications').forEach(function (application) {
          tabs.set('' + application.get('name'), _freshfoneEmberConstantsIntegratedApplications.integratedAppsSection[application.get('name')]);
        });
      }

      if (this.get('mkpAdapter.hasMkpFeature') && this.get('mkpApps.length')) {
        this.get('mkpApps').forEach(function (mkpApp, appIndex) {
          tabs.set('' + appIndex, {
            key: appIndex,
            isMkpApp: true,
            title: get(mkpApp, 'displayName'),
            iconURL: get(mkpApp, 'iconURL')
          });
        });
      }

      if (this.get('hasPcipalFeature') && this.get('call.hasPcipalStatus')) {
        tabs.set('pcipalStatus', {
          key: 'pcipalStatus',
          title: this.get('i18n').t('call_log_v1.call_details_tabs.pcipal'),
          iconName: 'pcipal'
        });
      }

      if (this.get('showNetworkLog')) {
        tabs.set('networkLog', {
          key: 'networkLog',
          title: this.get('i18n').t('call_log_v1.call_details_tabs.network_log'),
          iconName: 'network-log'
        });
      }

      return tabs;
    }),
    showIntegratedApplications: computed('currentTabName', function () {
      return this.isIntegratedApplication(this.get('currentTabName'));
    }),
    showMarketPlaceContainers: computed('currentTabName', function () {
      return this.isMarketPlaceApplication(this.get('currentTabName'));
    }),
    currentIntegratedApplication: computed('currentTabName', function () {
      var _this2 = this;

      if (this.isIntegratedApplication(this.get('currentTabName'))) {
        return this.get('freshcallerUiEnabledApplications').find(function (integratedApplication) {
          return integratedApplication.get('name') === _this2.get('currentTabName');
        });
      }
    }),
    callSummaryVisible: computed('currentTabName', 'modalOpen', function () {
      return this.get('currentTabName') === 'callSummary' && this.get('modalOpen');
    }),

    isIntegratedApplication: function isIntegratedApplication(tabName) {
      return this.get('tabs').has(tabName) && this.get('tabs').get(tabName).isIntegratedApplication;
    },
    isMarketPlaceApplication: function isMarketPlaceApplication(tabName) {
      return this.get('tabs').has(tabName) && this.get('tabs').get(tabName).isMkpApp;
    },
    getExtractedCallDetails: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id, isAgentLeg) {
      var agentLeg, callRecord;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this3 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!isAgentLeg) {
              context$1$0.next = 5;
              break;
            }

            agentLeg = this.store.peekRecord('agent-leg', id);

            this.set('call', agentLeg);
            this.set('rootCallId', this.get('call.call.id'));
            return context$1$0.abrupt('return');

          case 5:

            this.set('mkpData.currentCall', { id: id });
            context$1$0.next = 8;
            return this.store.peekRecord('call', id) || this.store.findRecord('call', id);

          case 8:
            callRecord = context$1$0.sent;

            this.set('fetchingCallDetails', true);
            callRecord.getCallDetails().then(function (data) {
              run(function () {
                _this3.store.pushPayload(data);
                _this3.set('call', callRecord);
                _this3.set('fetchingCallDetails', false);
                _this3.fetchCallTranscription();
              });
            });

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    hasCallTranscriptionFeature: computed(function () {
      return this.hasGlobalFeature('call_transcription') && this.checkFeature('postcall_transcription');
    }),

    displayCallTransription: computed.and('call.transcriptionID', 'hasCallTranscriptionFeature', 'isCallTranscription'),

    fetchCallEvents: function fetchCallEvents(isAgentLeg) {
      var _this4 = this;

      this.setProperties({
        callEvents: null,
        callEventsLoading: true
      });

      var selectedCall = undefined;
      if (isAgentLeg && this.get('rootCallId')) {
        selectedCall = this.store.peekRecord('call', this.get('rootCallId'));
      } else {
        var callId = this.get('call.isChild') ? this.get('call.rootId') : this.get('callId');
        selectedCall = this.store.peekRecord('call', callId);
      }

      selectedCall.getCallEvents().then(function (result) {
        _this4.setProperties({
          callEvents: result.events,
          callEventsLoading: false
        });
      });
    },

    actions: {
      changeCurrentCall: function changeCurrentCall(callId, isAgentLeg) {
        this.set('isAgentLeg', isAgentLeg);
        this.set('callId', callId);
      },
      selectTab: function selectTab(tabName) {
        tabName = String(tabName);
        var tab = this.get('tabs').get(tabName);

        if (tabName === 'callLifeCycle' && this.get('call.status') !== _freshfoneEmberUtilsCallConstants['default'].STATUS_HASH[1]) {
          this.fetchCallEvents(this.get('isAgentLeg'));
        }

        if (tab.isIntegratedApplication) {
          this.set('showNativeIntegrations', true);
        } else if (tab.isMkpApp) {
          this.set('openMkpAppIndex', tab.key);
        }

        this.set('currentTabName', tab.key);
      }
    }
  });
});