define('freshfone-ember/routes/protected/admin/ivr/new', ['exports', 'ember', 'freshfone-ember/utils/ivr-constants', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberUtilsIvrConstants, _freshfoneEmberRoutesProtected) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    breadCrumb: {},
    callFlow: service('call-flow'),
    i18n: service(),
    features: ['ivr'],
    titleToken: computed(function () {
      return this.get('i18n').t('navigation.new_flow');
    }),
    queryParams: {
      editNumber: {},
      actionFor: {},
      ivrType: {}
    },
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.checkFeature('ivr');
    },
    newIVR: true,
    model: function model(params) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {

        _this.get('callFlow').getIvrCount().then(function (count) {
          var newFlow = _this.store.peekRecord('callFlow', { isNew: true });

          run(function () {
            newFlow = newFlow || _this.store.createRecord('callFlow');
            newFlow.set('ivr', _this.store.createRecord('ivr'));

            var ivrTitle = _freshfoneEmberUtilsIvrConstants.IVR_TYPES.speechEnabledIVR ? _this.get('i18n').t('entityNames.speech_input_ivr') : _this.get('i18n').t('entityNames.ivr');

            newFlow.set('name', ivrTitle + ' ' + (count + 1));

            if (params.rule) {
              _this.set('rule', params.rule);
            }
            if (params.ivrType) {
              newFlow.set('ivr.ivrType', Number(params.ivrType));
            }
          });
          resolve(newFlow);
        });
      });
    },

    afterModel: function afterModel(model) {
      var title = get(model, 'name');
      set(this, 'breadCrumb.title', title);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('ivrType', undefined);
        controller.set('rule', undefined);
      }
    }

  });
});