define("freshfone-ember/components/voicemail-drop/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 2
              },
              "end": {
                "line": 38,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/voicemail-drop/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "message-components/new-message", [], ["scope", ["subexpr", "@mut", [["get", "scope", ["loc", [null, [31, 12], [31, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "isOpen", ["subexpr", "@mut", [["get", "isOpen", ["loc", [null, [32, 13], [32, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "message", ["subexpr", "@mut", [["get", "message", ["loc", [null, [33, 14], [33, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "onModify", ["subexpr", "action", ["onModify"], [], ["loc", [null, [34, 15], [34, 34]]], 0, 0], "edit", ["subexpr", "action", ["edit"], [], ["loc", [null, [35, 11], [35, 26]]], 0, 0], "actionReceiver", ["subexpr", "@mut", [["get", "messageForm", ["loc", [null, [36, 21], [36, 32]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 4], [37, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/voicemail-drop/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ui-modal", [], ["class", "sidebar-content-modal", "size", "md", "animateClose", true, "isModalOpen", ["subexpr", "@mut", [["get", "isOpen", ["loc", [null, [23, 16], [23, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "showCloseOnTopLeft", true, "closeOnEscape", true, "closeOnBackdropClick", true, "sliderModal", true, "onClose", ["subexpr", "action", [["subexpr", "send", [["get", "messageForm", ["loc", [null, [28, 26], [28, 37]]], 0, 0, 0, 0], "toggleOpen"], [], ["loc", [null, [28, 20], [28, 51]]], 0, 0]], [], ["loc", [null, [28, 12], [28, 52]]], 0, 0]], 0, null, ["loc", [null, [19, 2], [38, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 19
          }
        },
        "moduleName": "freshfone-ember/components/voicemail-drop/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-12 admin-main-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "admin-main-content-list");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "message-components/messages-list", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 12], [4, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "description", ["subexpr", "t", ["voicemailDrop.formDescription"], [], ["loc", [null, [5, 18], [5, 53]]], 0, 0], "scope", ["subexpr", "@mut", [["get", "scope", ["loc", [null, [6, 12], [6, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "modifyModel", ["subexpr", "@mut", [["get", "modifyModel", ["loc", [null, [7, 18], [7, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "promoting", ["subexpr", "@mut", [["get", "promoting", ["loc", [null, [8, 16], [8, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onEdit", ["subexpr", "action", ["editMessage"], [], ["loc", [null, [9, 13], [9, 35]]], 0, 0], "onDelete", ["subexpr", "action", ["toggleDelete"], [], ["loc", [null, [10, 15], [10, 38]]], 0, 0], "onAfterDelete", ["subexpr", "action", ["afterDelete"], [], ["loc", [null, [11, 20], [11, 42]]], 0, 0], "onOpenForm", ["subexpr", "action", ["openForm"], [], ["loc", [null, [12, 17], [12, 36]]], 0, 0], "class", "messages-list-wrapper"], ["loc", [null, [3, 4], [14, 6]]], 0, 0], ["block", "ember-wormhole", [], ["to", "modal-wormhole"], 0, null, ["loc", [null, [18, 0], [39, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});