define('freshfone-ember/components/model-viewport/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    currentAttr: null,
    links: ["website"],
    init: (function () {
      // this.get('model').reload();
      this._super.apply(this, arguments);
    }).on('init'),
    modelAttributes: _ember['default'].computed('model', 'model.isReloading', 'model.readable', function () {
      var attributes = this.get('model').toJSON({ includeId: true }),
          keys = this.get("readableAttributes"),
          self = this;
      console.log('attributes', attributes);
      if (!keys) {
        return [];
      }
      return keys.map(function (key) {
        self.set("currentAttr", key);
        return {
          key: key.capitalize(),
          value: attributes[key],
          isLink: self.get("isLink")
        };
      });
    }),

    readableAttributes: _ember['default'].computed('model.readable', function () {
      return this.get('model.readable');
    }),

    isLink: _ember['default'].computed('currentAttr', {
      get: function get() {
        var links = [];
        links = this.get("links");
        return links.includes(this.get('currentAttr')); //es6
      }
    })
  });
});