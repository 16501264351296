define('freshfone-ember/components/pagination-component/component', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    totalCount: computed.alias('model.meta.total-count'),
    page: computed.alias('model.meta.current'),
    totalPages: computed.alias('model.meta.total-pages'),
    needToPaginate: computed('totalCount', function () {
      return this.get('totalCount') > this.get('perPage');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.setMetaData();
    },

    setMetaData: function setMetaData() {
      this.sendAction('listChange');
    },

    actions: {
      change: function change(page) {
        this.sendAction('paginate', page);
      }
    }

  });
});