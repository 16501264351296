define('freshfone-ember/components/team-component/team-form/component', ['exports', 'ember-validations', 'ember', 'freshfone-ember/mixins/errorfield-focus-tabs', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/constants/team-setting-config-properties'], function (exports, _emberValidations, _ember, _freshfoneEmberMixinsErrorfieldFocusTabs, _emberComponentInboundActionsInboundActions, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberConstantsTeamSettingConfigProperties) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var i18n = _ember['default'].i18n;
  var Object = _ember['default'].Object;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  var testing = _ember['default'].testing;
  exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsModelFormMixin['default'], _emberComponentInboundActionsInboundActions['default'], {
    currentAccount: computed.alias('session.account'),
    telephony: service(),
    i18n: service(),
    currentPage: 0,
    records: [],
    isToLimitIncomingCalls: computed('team.teamSetting', {
      get: function get() {
        return this.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].ConcurrentIncomingCalls, false);
      },
      set: function set(key, value) {
        this.set('team.teamSetting.configurations', this.createConfigurationsObject([_freshfoneEmberConstantsTeamSettingConfigProperties['default'].ConcurrentIncomingCalls], [value ? '10' : '']));

        return value;
      }
    }),
    concurrentIncomingCallLimit: computed('team.teamSetting.configurations', {
      get: function get() {
        return this.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].ConcurrentIncomingCalls, '');
      },
      set: function set(key, value) {
        this.set('team.teamSetting.configurations', this.createConfigurationsObject([_freshfoneEmberConstantsTeamSettingConfigProperties['default'].ConcurrentIncomingCalls], [value]));

        return value;
      }
    }),
    isCallParkingEnabled: computed('team.teamSetting.configurations', {
      get: function get() {
        return this.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].CallParking, false);
      },
      set: function set(key, value) {
        this.set('team.teamSetting.configurations', this.createConfigurationsObject([_freshfoneEmberConstantsTeamSettingConfigProperties['default'].CallParking, _freshfoneEmberConstantsTeamSettingConfigProperties['default'].RingBusyAgents], [value, false]));

        return value;
      }
    }),
    allowIncomingCallsInParkingMode: computed('team.teamSetting.configurations', {
      get: function get() {
        return this.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].RingBusyAgents, false);
      },
      set: function set(key, value) {
        this.set('team.teamSetting.configurations', this.createConfigurationsObject([_freshfoneEmberConstantsTeamSettingConfigProperties['default'].RingBusyAgents], [value]));

        return value;
      }
    }),
    maxCallsAgentCanPark: computed('team.teamSetting.configurations', {
      get: function get() {
        return this.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].MaxParkedCalls, '');
      },
      set: function set(key, value) {
        this.set('team.teamSetting.configurations', this.createConfigurationsObject([_freshfoneEmberConstantsTeamSettingConfigProperties['default'].MaxParkedCalls], [value]));

        return value;
      }
    }),

    getTeamSettingConfigPropValue: function getTeamSettingConfigPropValue(property, defaultValue, isToInitialize) {
      if (isToInitialize) {
        return defaultValue;
      }

      return this.get('team.teamSetting.content') ? this.get('team.teamSetting.configurations')[property] : defaultValue;
    },

    createConfigurationsObject: function createConfigurationsObject(keys, values) {
      var isToInitialize = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var that = this,
          configObj = {
        'call-parking': this.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].CallParking, false, isToInitialize),
        'ring-busy-agents': that.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].RingBusyAgents, false, isToInitialize),
        'concurrent-incoming-calls': that.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].ConcurrentIncomingCalls, '', false),
        'default-parked-calls': that.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].DefaultParkedCalls, 3, isToInitialize),
        'max-parked-calls': that.getTeamSettingConfigPropValue(_freshfoneEmberConstantsTeamSettingConfigProperties['default'].MaxParkedCalls, 3, isToInitialize)
      };

      keys.forEach(function (key, index) {
        configObj[key] = values[index];
      });

      return Object.create(configObj);
    },

    team: computed({
      set: function set(key, value) {
        this.set('startValidate', false);
        if (value && value.get('id')) {
          this.set('slideToggle', true);
          if (!value.get('teamSetting.content')) {
            value.set('teamSetting', this.store.createRecord('team-setting'));
          }

          this.set('originalTeamSettingConfigurations', Object.create(value.get('teamSetting.configurations')));

          return value;
        }

        return this.store.createRecord('team', { teamSetting: this.store.createRecord('team-setting') });
      }
    }),

    nonDeletedUsers: computed('records.[]', 'records.@each.isLoaded', function () {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return [];
      }

      return this.get('records').filter(function (user) {
        return !user.get('deleted') && !user.get('isNew') && !user.get('isDestroying');
      });
    }),

    isDirty: computed('team.hasDirtyAttributes', 'team.users.[]', 'team.users.@each.*', 'isCallParkingEnabled', 'team.teamSetting.configurations', {
      get: function get() {
        return this.get('team').isDeepDirty(['teamSetting']) || this.isDirtyTeamSetting();
      }
    }).readOnly(),

    isDirtyTeamSetting: function isDirtyTeamSetting() {
      for (var key in this.get('originalTeamSettingConfigurations')) {
        if (this.keyPresentInConfigKey(key)) {
          var oldValue = this.getValueFromObject('originalTeamSettingConfigurations', key),
              newValue = this.getValueFromObject('team.teamSetting.configurations', key);

          if (oldValue.toString() !== newValue.toString()) {
            return true;
          }
        }
      }

      return false;
    },

    getValueFromObject: function getValueFromObject(objectName, key) {
      return this.get(objectName)[key] === null ? '' : this.get(objectName)[key];
    },

    keyPresentInConfigKey: function keyPresentInConfigKey(key) {
      return key === _freshfoneEmberConstantsTeamSettingConfigProperties['default'].CallParking || key === _freshfoneEmberConstantsTeamSettingConfigProperties['default'].RingBusyAgents || key === _freshfoneEmberConstantsTeamSettingConfigProperties['default'].ConcurrentIncomingCalls || key === _freshfoneEmberConstantsTeamSettingConfigProperties['default'].DefaultParkedCalls || key === _freshfoneEmberConstantsTeamSettingConfigProperties['default'].MaxParkedCalls;
    },

    submitLabel: computed('team.isNew', function () {
      if (this.get('team.isSaving')) {
        return this.get('i18n').t('team.form.saving');
      }

      return this.get('team.isNew') ? this.get('i18n').t('team.form.add') : this.get('i18n').t('team.form.save');
    }),

    title: computed('team.isNew', function () {
      return this.get('team.isNew') ? this.get('i18n').t('team.form.new') : this.get('i18n').t('team.form.details');
    }),

    showSaveButton: computed('isDirty', 'team.isDeleted', function () {
      return this.get('isDirty') && !this.get('team.isDeleted');
    }),
    showNonEditableOcrView: computed.and('isOcrTeam', 'isOmniTeam'),

    isOcrTeam: computed('team.omniChannel', {
      get: function get() {
        return this.get('team.omniChannel');
      },
      set: function set(key, value) {
        this.setProperties({
          'team.omniChannel': value,
          'team.teamSetting.configurations': this.createConfigurationsObject([], [], true)
        });

        return value;
      }
    }),
    isNonOcrTeam: computed.not('isOcrTeam'),
    isTeamPresent: computed.not('team.isNew'),
    nonOcrTeamOmniView: computed.and('isNonOcrTeam', 'sharedNonCrmView', 'isTeamPresent'),
    ocrEnabled: computed.alias('session.account.ocrEnabled'),
    notSharedAccount: computed.not('currentAccount.isSharedBundleAccount'),
    omniNotSharedAccount: computed.and('isOmniTeam', 'notSharedAccount'),
    showReadOnlySharedView: computed.or('omniNotSharedAccount', 'showNonEditableOcrView', 'nonOcrTeamOmniView'),
    showReadOnlyView: computed('isOmniTeam', 'showReadOnlySharedView', 'currentAccount.isSharedBundleAccount', 'isTeamPresent', function () {
      if (this.get('currentAccount.isSharedBundleAccount')) {
        return this.get('showReadOnlySharedView') && this.get('isTeamPresent');
      }

      return this.get('isOmniTeam');
    }),
    showOmniEditText: computed('isOmniTeam', 'isOcrTeam', 'currentAccount.isSharedBundleAccount', function () {
      if (this.get('currentAccount.isSharedBundleAccount')) {
        return this.get('isOcrTeam') && this.get('isOmniTeam');
      }

      return this.get('isOmniTeam');
    }),
    isAgentsPresent: computed.bool('records.length'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      run.later(this, function () {
        if (this.get('slideToggle')) {
          this.setProperties({
            searchTerm: '',
            currentPage: 0,
            hasMore: true
          });
          this.fetchAgentsList();
        }
      }, 1);
    },

    fetchAgentsList: function fetchAgentsList() {
      var _this = this;

      this.set('isFetching', true);
      var params = { page: this.get('currentPage') + 1 },
          searchTerm = this.get('searchTerm'),

      /*
       * This is a workaround   to prevent the model's users array from resetting because of ember data behaviour.
       * We have to explore a technically compatible solution in the future.
       */
      currentTeamAgents = this.get('team.users').toArray();

      if (searchTerm) {
        params.filter = { queryString: searchTerm };
      }

      this.store.query('user', params).then(function (response) {
        /* Restore agents as they may have been reset */
        if (!testing) {
          _this.set('team.users', currentTeamAgents);
        }

        if (response.meta.current === 1) {
          _this.get('records').clear();
          _this.set('records', response.toArray());
        } else {
          _this.get('records').pushObjects(response.toArray());
        }
        _this.setHasMoreToscroll(response.meta['total-pages'], response.meta.current);
        _this.set('isFetching', false);
      })['catch'](function () {
        if (!_this.get('currentPage')) {
          _this.get('records').clear();
        }
        _this.set('isFetching', false);
      });
    },

    setHasMoreToscroll: function setHasMoreToscroll(total) {
      var current = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];

      this.setProperties({
        totalPages: total,
        currentPage: current,
        hasMore: true
      });
    },

    noMoreResultsToscroll: function noMoreResultsToscroll() {
      if (this.get('currentPage') + 1 > this.get('totalPages')) {
        this.setProperties({
          isFetching: false,
          hasMore: false
        });

        return true;
      }
    },

    loadMoreAgents: function loadMoreAgents() {
      this.set('isFetching', true);
      if (this.noMoreResultsToscroll()) {
        return;
      }
      this.fetchAgentsList();
    },

    clearPreviousSearchTask: function clearPreviousSearchTask() {
      var previousTask = this.get('searchTask');

      if (previousTask) {
        run.cancel(previousTask);
      }
    },

    performSearch: function performSearch(searchTerm) {
      this.setProperties({
        searchTerm: searchTerm,
        currentPage: 0,
        hasMore: true
      });
      this.clearPreviousSearchTask();
      var searchTask = run.debounce(this, this.fetchAgentsList, 700);

      this.set('searchTask', searchTask);
    },

    actions: {
      save: function save() {
        var _this2 = this;

        this.validate().then(function () {
          _this2.save('team', _this2.get('team')).then(function () {
            if (isEmpty(_this2.get('team.errors')[0])) {
              _this2.sendAction('onModify');
              _this2.send('toggleTeamForm');
              _this2.get('telephony').numbersUpdatedCallback();
            }
          });
        })['catch'](function () {
          _this2.set('startValidate', true);
        });
      },
      toggleTeamForm: function toggleTeamForm() {
        this.rollbackDirtyChanges('team');
        this.toggleProperty('slideToggle');
      },
      onInput: function onInput(searchTerm) {
        if (!searchTerm) {
          this.performSearch('');
        }
      },
      onSearch: function onSearch(searchTerm) {
        if (searchTerm !== this.get('searchTerm')) {
          this.performSearch(searchTerm);
        }
      },
      fetchMoreRes: function fetchMoreRes() {
        this.loadMoreAgents();
      }
    },

    validations: {
      'team.name': {
        format: {
          presence: true,
          message: i18n.t('activemodel.errors.not_empty')
        }
      },
      'concurrentIncomingCallLimit': {
        inline: (0, _emberValidations.validator)(function () {
          if (!this.get('isToLimitIncomingCalls')) {
            return;
          }
          if (isEmpty(this.get('concurrentIncomingCallLimit'))) {
            return this.get('i18n').t('activemodel.errors.not_empty');
          } else if (this.get('concurrentIncomingCallLimit') <= 0) {
            return this.get('i18n').t('team.form.ui_validations_error.min_concurrent_incoming_call_limit');
          } else if (this.get('concurrentIncomingCallLimit') > 10) {
            return this.get('i18n').t('team.form.ui_validations_error.max_concurrent_incoming_call_limit');
          } else if (!Number.isInteger(+this.get('concurrentIncomingCallLimit'))) {
            return this.get('i18n').t('team.form.ui_validations_error.invalidNumber');
          }
        })
      },
      'maxCallsAgentCanPark': {
        inline: (0, _emberValidations.validator)(function () {
          if (!this.get('isCallParkingEnabled')) {
            return;
          }
          if (isEmpty(this.get('maxCallsAgentCanPark'))) {
            return this.get('i18n').t('activemodel.errors.not_empty');
          } else if (this.get('maxCallsAgentCanPark') <= 0) {
            return this.get('i18n').t('team.form.ui_validations_error.min_calls_agent_can_park_limit');
          } else if (this.get('maxCallsAgentCanPark') > 5) {
            return this.get('i18n').t('team.form.ui_validations_error.max_calls_agent_can_park_limit');
          } else if (!Number.isInteger(+this.get('maxCallsAgentCanPark'))) {
            return this.get('i18n').t('team.form.ui_validations_error.invalidNumber');
          }
        })
      }
    }
  });
});