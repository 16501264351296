define('freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/component', ['exports'], function (exports) {
  var momentjs = window.moment;
  var _Ember = Ember;
  var computed = _Ember.computed;
  var Component = _Ember.Component;
  exports['default'] = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('secondaryQuestionAnswered', []);
    },
    currentAccount: computed.alias('session.account'),
    isPrimaryQuestionCompleted: computed.gt('secondaryQuestion.length', 0),

    isRequiredQuestionsAnswered: computed('secondaryQuestion.[]', 'secondaryQuestionAnswered.[]', function () {
      var answeredRequiredQuestions = this.get('secondaryQuestionAnswered').filterBy('required', true),
          requiredQuestions = this.get('secondaryQuestion').filterBy('required', true);

      return requiredQuestions.length === answeredRequiredQuestions.length;
    }),

    subscription: computed.alias('currentAccount.subscription'),

    accountCancelDate: computed('subscription.cancellationDate', function () {
      return '' + momentjs(this.get('subscription.cancellationDate')).format('DD MMMM YYYY');
    }),

    modalTitle: computed('showQuestions', function () {
      return this.get('showQuestions') ? Ember.i18n.t('billing.cancel_account.reason_for_cancellation') : Ember.i18n.t('billing.cancel_account.cancel_request_header');
    }),

    heading: computed('showSecondaryQuestion', function () {
      return this.get('showSecondaryQuestion') ? Ember.i18n.t('billing.cancel_account.secondary_question_heading') : Ember.i18n.t('billing.cancel_account.primary_question_heading', { productName: this.get('currentAccount.productName') });
    }),

    actions: {
      toggleOpenCancelModal: function toggleOpenCancelModal() {
        this.toggleProperty('openCancelModal');
      },

      showQuestions: function showQuestions() {
        this.set('showQuestions', true);
      },

      showSecondaryQuestions: function showSecondaryQuestions() {
        this.set('showSecondaryQuestion', true);
      },

      showMissYouModal: function showMissYouModal() {
        this.set('showMissYouModal', true);
      }
    }
  });
});