define('freshfone-ember/instance-initializers/ember-i18n', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize(applicationInstance) {
    var i18n = applicationInstance.lookup('service:i18n');
    _ember['default'].i18n = i18n;
  }

  exports['default'] = {
    name: 'ember-i18n-instance',
    initialize: initialize
  };
});