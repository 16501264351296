define('freshfone-ember/components/common/custom-power-calendar/component', ['exports', 'ember', 'freshfone-ember/constants/call-logs-filter', 'moment'], function (exports, _ember, _freshfoneEmberConstantsCallLogsFilter, _moment) {
  var Component = _ember['default'].Component;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  var set = _ember['default'].set;
  var oneMonthMaxRange = 30;

  // eslint-disable-next-line one-var
  var DatePicker = Component.extend({
    readonly: true,
    oneMonthMaxRange: oneMonthMaxRange,

    init: function init() {
      this._super.apply(this, arguments);
      scheduleOnce('afterRender', this, 'initCalendar');
    },

    initCalendar: function initCalendar() {
      var _this = this;

      var powerCalendarNav = $('.ember-power-calendar-nav'),
          toggler = this.get('noInputField') ? $(this.get('toggler')) : $('.date-text-field');

      /*
       * removing existing listeners on powerCalendarNamespace if any,
       * so that only one listener is active at a time even if this datepicker is being used multiple times
       */
      $(document).off('click.powerCalendarNamespace');
      $(document).on('click.powerCalendarNamespace', function (event) {
        if ($(event.target).is(toggler)) {
          if ($('.datepicker-wrapper').is(':visible')) {
            $('.datepicker-wrapper').hide();
          } else {
            var datepickerWrapper = $(event.target).parent().find('.datepicker-wrapper');

            _this.sendAction('onOpen', datepickerWrapper);
            datepickerWrapper.show();
            _this.setCurrentMonth();
          }
        } else if (!_this.get('rangeSelector') && !powerCalendarNav.has(event.target).length) {
          $('.datepicker-wrapper').hide();
        }
      });
    },

    setCurrentMonth: function setCurrentMonth() {
      var center = this.get('selectedRange.start') || this.get('value') || this.get('center');

      this.set('center', center);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      $(document).off('click.powerCalendarNamespace');
    },

    actions: {
      changeMonth: function changeMonth(_ref) {
        var date = _ref.date;

        set(this, 'center', date);
      },

      updateValue: function updateValue(_ref2) {
        var date = _ref2.date;

        set(this, 'value', (0, _moment['default'])(date).startOf('day').locale(this.get('locale')).format(this.get('format')));
      },

      updateRange: function updateRange(_ref3) {
        var moment = _ref3.moment;

        set(this, 'selectedRange', {
          start: moment.start,
          end: moment.end,
          timeperiod: _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodCustomRange
        });
        this.get('resetActiveRange')();
      },

      onOpenAction: function onOpenAction() {
        this.sendAction('onOpen', this, this.get('trackId'));
      }
    }
  });

  DatePicker.reopenClass({ positionalParams: ['value'] });

  exports['default'] = DatePicker;
});