define('freshfone-ember/mixins/url-mixin', ['exports', 'freshfone-ember/config/environment', 'ember'], function (exports, _freshfoneEmberConfigEnvironment, _ember) {
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    // TODO we can get this url from environment file
    currentRootURL: function currentRootURL() {
      if (_freshfoneEmberConfigEnvironment['default'].environment === 'development' || _freshfoneEmberConfigEnvironment['default'].environment === 'test') {
        return '/';
      }
      var rootURL = localStorage.getItem('rootURL');

      return window.location.origin + (rootURL ? rootURL.slice(0, rootURL.length - 1) : '');
    },
    currentUIURL: function currentUIURL() {
      if (_freshfoneEmberConfigEnvironment['default'].environment === 'development' || _freshfoneEmberConfigEnvironment['default'].environment === 'test') {
        return '/';
      }

      return this.currentRootURL();
    },
    authorizeURL: function authorizeURL() {
      return this.currentRootURL() + '/sessions/authorize';
    },
    authenticateURL: function authenticateURL() {
      return this.currentRootURL() + '/sessions/freshid_authenticate';
    },
    logoutURL: function logoutURL() {
      return this.currentRootURL() + '/sign_out';
    },
    freshidLogoutURL: function freshidLogoutURL() {
      return this.currentRootURL() + '/sessions/freshid_logout';
    },
    freshidProfileURL: function freshidProfileURL() {
      return encodeURI(this.getfreshidUrl() + '/profile?redirect_uri=' + this.currentUIURL());
    },
    freshidLoginURL: function freshidLoginURL() {
      if (_freshfoneEmberConfigEnvironment['default'].environment === 'test') {
        return 'http://localhost:3200';
      }

      return this.currentRootURL() + '/sessions/login';
    },
    getfreshidUrl: function getfreshidUrl() {
      return this.get('session.account.organisationDomain') ? 'https://' + this.get('session.account.organisationDomain') : _freshfoneEmberConfigEnvironment['default'].FRESHID.freshidUIUrl;
    }
  });
});