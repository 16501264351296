define('freshfone-ember/models/service-level-metric', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var _Ember = Ember;
  var computed = _Ember.computed;
  exports['default'] = _emberData['default'].Model.extend({
    metricPercentage: attr(),
    averageWaitTime: attr(),
    averageTimeToAnswer: attr(),
    averageHandleTime: attr(),
    maxWaitTime: attr(),
    averageTalkTime: attr(),
    callQueueId: attr(),
    callQueueName: attr(),
    targetPercent: attr(),
    ttaThreshold: attr(),
    relativeMetricPercentage: computed('metricPercentage', function () {
      var metricPercentage = Number(Math.round(this.get('metricPercentage') / this.get('targetPercent') * 100));
      return metricPercentage > 100 ? 100 : metricPercentage;
    })
  });
});