define('freshfone-ember/components/live-calls/save-queues-view/confirm-delete-view/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      closeModal: function closeModal() {
        this.toggleProperty('showDeleteConfirmModal');
      },

      deleteView: function deleteView() {
        var _this = this;

        this.get('selectedView').destroyRecord().then(function () {
          _this.send('closeModal');
          _this.sendAction('setSelectedQueues', []);
          _this.set('session.account.slmViewCount', _this.get('session.account.slmViewCount') - 1);
          _this.set('selectedView', null);
          _this.set('showSaveView', false);
        });
      }
    }

  });
});