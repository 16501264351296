define('freshfone-ember/components/number-component/edit-form/rule-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/validations/server-errors', 'freshfone-ember/utils/business-calendar-constants'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsValidationsServerErrors, _freshfoneEmberUtilsBusinessCalendarConstants) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var notEmpty = _Ember$computed.notEmpty;
  var sort = _Ember$computed.sort;
  var service = _ember['default'].inject.service;

  var defaultAbacusCategory = _freshfoneEmberUtilsBusinessCalendarConstants['default'].defaultAbacusCategory;
  exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsValidationsServerErrors['default'], {
    classNames: 'rule-form',
    _routing: service('-routing'),
    sortDefinition: ['rule_id:asc'],
    sortedNumbersList: sort('numbers', 'sortDefinition'),
    isEditMode: notEmpty('rule.id'),
    hasCallFlow: notEmpty('callFlow'),
    account: alias('session.account'),
    isEligibleForCategoryUpdate: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm') && this.checkFeature('unified_business_hour');
    }),
    hasFeatureOutsideHolidayCallFlow: computed(function () {
      return this.hasFeatures(['outside_holiday_call_flow']);
    }),
    hasFeatureBusinessHour: computed(function () {
      return this.hasFeatures(['business_hours']);
    }),
    selectedBusinessHour: computed('rule.businessCalendarId', {
      get: function get() {
        var calendar = this.store.peekRecord('business-calendar', this.get('rule.businessCalendarId'));

        return isEmpty(calendar) ? this.get('rule.defaultBusinessCalendar') : calendar;
      },
      set: function set(key, value) {
        this.set('rule.businessCalendarId', value.id);
        this.set('rule.businessCalendar', value);
        if (this.get('isEligibleForCategoryUpdate')) {
          this.set('rule.businessCalendarCategory', value.get('category'));
        }

        return value;
      }
    }),

    noUnifiedBusinessHour: computed(function () {
      return !(this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm') && this.checkFeature('unified_business_hour'));
    }),

    businessHours: computed(function () {
      var businessCalendars = this.store.peekAll('business-calendar');

      return businessCalendars ? _ember['default'].RSVP.Promise.resolve(businessCalendars) : this.get('store').findAll('business-calendar');
    }),
    businessHoursList: computed('rule.businessCalendarId', 'businessHours.[]', function () {
      var _this = this;

      var businessHours = [];

      if (!this.get('hasFeatureBusinessHour')) {
        return this.get('rule.defaultBusinessCalendar');
      }

      new _ember['default'].RSVP.Promise(function (resolve) {
        _this.get('businessHours').then(function (allBusinessHours) {
          businessHours.pushObjects(allBusinessHours.filterBy('isDeleted', false).toArray());
          resolve(businessHours);
        });
      });

      return businessHours;
    }),
    numbers: computed(function () {
      return this.store.findAll('number');
    }),
    numbersList: computed('numbers', 'numbers.@each.hasRule', function () {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        var numbers = _this2.get('numbers');

        numbers.then(function (numbers) {
          var activeNumbers = [],
              disabledNumber = [],
              optionList = [];

          numbers.forEach(function (e) {
            if (e.get('hasRule')) {
              disabledNumber.push(e);
            } else {
              activeNumbers.push(e);
            }
          });
          if (isPresent(activeNumbers)) {
            optionList.push({
              groupName: _ember['default'].i18n.t('rule.without_rule'),
              options: activeNumbers
            });
          }
          if (isPresent(disabledNumber)) {
            optionList.push({
              groupName: _ember['default'].i18n.t('rule.with_rule'),
              disabled: true,
              options: disabledNumber
            });
          }

          resolve(optionList);
        });
      });
    }),
    submitLabel: computed('rule.isSaving', function () {
      return this.get('rule.isSaving') ? 'Saving...' : 'Save Changes';
    }),
    isNotDefaultBusinessHour: computed('selectedBusinessHour', function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm') && this.checkFeature('unified_business_hour') && this.get('selectedBusinessHour.category') === defaultAbacusCategory;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('setRuleFormRef')(this);
    },

    validate: function validate() {
      var _this3 = this;

      var rule = this.get('rule');

      this.cleanUpRule();

      return new Promise(function (resolve, reject) {
        rule.validate().then(resolve)['catch'](function (e) {
          _this3.set('startValidate', true);
          reject(e);
        });
      });
    },

    cleanUpRule: function cleanUpRule() {
      var rule = this.get('rule');

      if (!rule.get('canHaveNonBusinessHour')) {
        rule.setProperties({
          nonBusinessHourActionType: null,
          nonBusinessHourAction: null,
          nonBusinessHourActionMessage: null,
          nonBusinessHourActionCallflow: null
        });
      }
      if (!rule.get('hasBHWithHolidays')) {
        rule.setProperties({
          holidayActionType: null,
          holidayAction: null,
          holidayActionMessage: null,
          holidayActionCallflow: null
        });
      }
    },

    actions: {
      buyNumber: function buyNumber() {
        this.get('buyNumber')();
      },
      showBusinessHour: function showBusinessHour() {
        this.get('showBusinessHour')();
      },
      save: function save() {
        this.get('update')();
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */