define('freshfone-ember/serializers/subscription', ['exports', 'freshfone-ember/serializers/application', 'freshfone-ember/mixins/serializers/embedded-records', 'ember'], function (exports, _freshfoneEmberSerializersApplication, _freshfoneEmberMixinsSerializersEmbeddedRecords, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var isArray = _ember['default'].isArray;
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    normalizeResponse: function normalizeResponse(store, primaryModelClass) {
      var payload = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      if (isArray(payload.data)) {
        payload.data.forEach(function (item) {
          // Eg., "Sprout 2020" -> "Sprout"
          if (typeof item.attributes['plan-name'] === 'string') {
            var _item$attributes$planName$split = item.attributes['plan-name'].split(' ');

            var _item$attributes$planName$split2 = _slicedToArray(_item$attributes$planName$split, 1);

            item.attributes['plan-name'] = _item$attributes$planName$split2[0];
          }
        });
      } else if (payload.data && payload.data.attributes) {
        if (typeof payload.data.attributes['plan-name'] === 'string') {
          var _payload$data$attributes$planName$split = payload.data.attributes['plan-name'].split(' ');

          var _payload$data$attributes$planName$split2 = _slicedToArray(_payload$data$attributes$planName$split, 1);

          payload.data.attributes['plan-name'] = _payload$data$attributes$planName$split2[0];
        }
      }

      return this._super.apply(this, arguments);
    }
  });
});