define('freshfone-ember/routes/protected/admin/data-automated-routing/edit', ['exports', 'ember', 'freshfone-ember/routes/protected', 'freshfone-ember/mixins/titleize'], function (exports, _ember, _freshfoneEmberRoutesProtected, _freshfoneEmberMixinsTitleize) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsTitleize['default'], {
    breadCrumb: {},
    isFromModel: false,
    queryParams: {
      routingAutomation: { refreshModel: true },
      editNumber: {}
    },

    titleToken: function titleToken(model) {
      return [model.get('name'), this.titleize(model._internalModel.modelName)].join(':');
    },

    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.checkFeature('routing_automation');
    },

    model: function model(params) {
      if (params.rule) {
        this.set('rule', params.rule);
      }

      return this.fetchCallFlow(params);
    },

    fetchCallFlow: function fetchCallFlow(params) {
      var _this = this;

      if (isPresent(params.routingAutomation)) {
        return new Promise(function (resolve) {
          _this.store.find('routingAutomation', params.routingAutomation).then(function (routingAutomation) {
            routingAutomation.get('callFlow').then(function (callFlow) {
              resolve(callFlow);
            });
          });
        });
      }

      return this.store.findRecord('callFlow', params.flow_id, { reload: true });
    },
    afterModel: function afterModel(model) {
      var title = get(model, 'name');

      set(this, 'breadCrumb.title', title);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          rule: undefined,
          editNumber: undefined
        });
      }
    }

  });
});