define('freshfone-ember/components/agent-extension/agent-extension-form/agent-extension-case/component', ['exports', 'ember', 'freshfone-ember/utils/message-constants'], function (exports, _ember, _freshfoneEmberUtilsMessageConstants) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var next = _ember['default'].run.next;
    var dasherize = _ember['default'].String.dasherize;
    exports['default'] = Component.extend({

        caseClassName: computed('extensionAction', function () {
            return 'case-' + dasherize(this.get('extensionAction'));
        }),

        eventNotSelected: computed.empty('optionAction'),
        classNames: ['list-row', 'list-content'],
        classNameBindings: ['hasThreshold'],

        messageTypeActionIds: computed('model.actionOptions', function () {
            var messageTypes = ['vm', 'hangup'],
                messageIdArray = [];

            this.get('model.actionOptions').forEach(function (option) {
                if (messageTypes.includes(option.name)) messageIdArray.push(option.id);
            });
            return messageIdArray;
        }),

        optionAction: computed('actionType', {
            get: function get() {
                return this.get('model.actionOptions').findBy('name', this.get('actionType'));
            },
            set: function set(key, value) {
                this.set('actionType', value.name);
                this.resetActionObject();
                return value;
            }
        }),

        resetActionObject: function resetActionObject() {
            this.setProperties({
                actionId: null,
                action: null
            });
            this.closeChild();
        },

        closeChild: function closeChild() {
            this.set('isChildOpen', false);
            this.set('isChildFormOpen', false);
        },

        getMessageId: function getMessageId(defaultMessageId) {
            var messages = this.store.peekAll('message');
            return messages.filterBy('defaultMessageType', defaultMessageId)[0];
        },

        closeAllChildNodes: function closeAllChildNodes(elem) {
            var ivrWrapper = elem.closest('.ivr-wrapper');
            ivrWrapper.children().find('.closeChild').first().trigger('click');
        },

        actions: {
            onChanged: function onChanged(changedValue) {
                var _this = this;

                this.set('optionAction', changedValue);
                if (this.get('messageTypeActionIds').includes(changedValue.id)) {
                    (function () {
                        var message = _this.getMessageId(_freshfoneEmberUtilsMessageConstants['default'][changedValue.name]);
                        next(_this, function () {
                            this.set('actionId', message.get('id'));
                        });
                    })();
                }
                this.closeAllChildNodes(this.$());
            }
        }
    });
});