define('freshfone-ember/mixins/titleize', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    titleize: function titleize(stringToChange) {
      var string_array = stringToChange.split(/_|-| /);
      string_array = string_array.map(function (str) {
        return _ember['default'].String.capitalize(str);
      });
      return string_array.join(' ');
    }
  });
});
//Converts a dasherized String to Titleized String with Spaces replacing dash
//Eg: 'call-flow' to 'Call Flow' , 'call_queue' to 'Call Queue'