define('freshfone-ember/services/parked-calls', ['exports', 'freshfone-ember/constants/call-state', 'ember', 'freshfone-ember/constants/park-type'], function (exports, _freshfoneEmberConstantsCallState, _ember, _freshfoneEmberConstantsParkType) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Service = _ember['default'].Service;
  var Evented = _ember['default'].Evented;
  var service = _ember['default'].inject.service;
  var alias = _ember['default'].computed.alias;
  var computed = _ember['default'].computed;
  var Object = _ember['default'].Object;
  exports['default'] = Service.extend({
    telephony: service(),
    parallelCall: service(),
    transition: service('state-transition'),
    notification: service('toastr'),

    currentCall: alias('telephony.currentCall'),
    parallelCalls: alias('parallelCall.parallelCalls'),
    parallelCallActive: alias('telephony.parallelCallActive'),

    parkedParallelCallsMap: new Map(),
    parkedCalls: [],
    parking: false,
    callSwitched: false,
    parkedCallsLength: computed('parkedCalls.[]', function () {
      return this.get('parkedCalls').length;
    }),
    showCallUnparkTooltip: false,

    bindCallParkingEvents: function bindCallParkingEvents() {
      FC.Event.subscribe('call_parked', this.callParkedCallback.bind(this));
      FC.Event.subscribe('call_park_failed', this.callParkFailedCallback.bind(this));
      FC.Event.subscribe('call_unparked', this.callUnparkedCallback.bind(this));
      FC.Event.subscribe('call_unpark_failed', this.callUnparkFailedCallback.bind(this));
    },

    setParallelCall: function setParallelCall(callId, parallelCalls) {
      this.get('parkedParallelCallsMap').set(parseInt(callId), parallelCalls);
    },
    deleteParallelCall: function deleteParallelCall(callId) {
      this.get('parkedParallelCallsMap')['delete'](parseInt(callId));
    },
    getParallelCall: function getParallelCall(callId) {
      return this.get('parkedParallelCallsMap').get(parseInt(callId));
    },
    checkAndUpdateParkedCall: function checkAndUpdateParkedCall(parkedCallsMap, updateParallelCall) {
      var parkedCallIds = parkedCallsMap.size && Array.from(parkedCallsMap.keys());

      if (!parkedCallIds) {
        this.set('parkedCalls', []);

        return;
      }

      if (this.get('parallelCallActive') && updateParallelCall) {
        this.setParallelCall(this.get('currentCall.id'), this.get('parallelCalls'));
      }

      this.updateParkedCall(parkedCallIds, parkedCallsMap);
    },
    updateParkedCall: function updateParkedCall(parkedCallIds, parkedCallsMap) {
      this.set('parkedCalls', this.mapParkCallsToList(parkedCallIds, parkedCallsMap));
    },
    getParkedCall: function getParkedCall(callId) {
      return this.get('parkedCalls').length && this.get('parkedCalls').find(function (call) {
        return parseInt(call.get('id')) === parseInt(callId);
      });
    },

    mapParkCallsToList: function mapParkCallsToList(parkedCallIds, parkedCallsMap) {
      var _this = this;

      return parkedCallIds.map(function (id) {
        var parsedId = parseInt(id),
            parkedCall = parkedCallsMap.get(parsedId),
            call = Object.extend(Evented, {}).create(),
            parallelCalls = _this.getParallelCall(parsedId) || [];

        call.setProperties(parkedCall);
        call.set('minimized', true);
        call.set('parallelCalls', parallelCalls);
        call.set('callParkedTimer', _this.getParkDurationById(parsedId));

        return call;
      });
    },

    getParkDurationById: function getParkDurationById(callId) {
      if (parseInt(this.get('currentCall.id')) === callId) {
        return this.get('currentCall.callParkedTimer');
      }

      var parkedCall = this.get('parkedCalls').find(function (call) {
        return parseInt(call.get('id')) === callId;
      });

      if (parkedCall) {
        return parkedCall.callParkedTimer;
      }

      return 0;
    },
    saveAllTimersBeforePark: function saveAllTimersBeforePark() {
      var currentCallSDK = this.get('telephony').getCurrentCall();

      currentCallSDK && currentCallSDK.set && currentCallSDK.set('ongoingCallDuration', this.get('currentCall.callDuration'));

      if (!this.get('parallelCallActive')) {
        return;
      }

      // eslint-disable-next-line one-var

      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];

      parallelCall.set('timer', {
        ongoing: this.get('currentCall.parallelCallDuration'),
        hold: this.get('currentCall.secondaryHoldDuration')
      });
    },
    getParkCallAPIs: function getParkCallAPIs(parkType, callIdToBeSwitched) {
      var _this2 = this;

      return [this.get('telephony').getCurrentCall().park(parkType, callIdToBeSwitched).then(function () {
        _this2.saveAllTimersBeforePark();
      })['catch'](function (err) {
        _this2.get('transition').stop();
        _this2.set('parking', false);

        if (parkType === _freshfoneEmberConstantsParkType['default'].IncomingCall) {
          _this2.get('telephony').clearIncomingConnection();
        }

        _this2.get('telephony').logError(err, '- Failed to park call');
        _this2.get('notification').error(_ember['default'].i18n.t('call_park.park_fail'));
      }), this.get('telephony').saveTagsIfPresent(), this.get('telephony').saveNotesIfPresent()];
    },
    parkCall: function parkCall(parkType, callIdToBeSwitched) {
      return Promise.allSettled(this.getParkCallAPIs(parkType, callIdToBeSwitched));
    },
    switchCallWithoutPark: function switchCallWithoutPark(callId) {
      var _this3 = this;

      var apis = [this.get('telephony').saveTagsIfPresent(), this.get('telephony').saveNotesIfPresent()];

      this.saveAllTimersBeforePark();

      return Promise.allSettled(apis).then(function () {
        return _this3.get('telephony').switchCall(callId);
      });
    },
    callParkedCallback: function callParkedCallback(_ref) {
      var call_id = _ref.call_id;
      var park_type = _ref.park_type;

      this.get('parallelCall').parkParallelCall();

      if (park_type === _freshfoneEmberConstantsParkType['default'].ParkedCall) {
        this.get('telephony').updateCurrentAndParkedCalls(call_id);

        return;
      }

      if (park_type !== _freshfoneEmberConstantsParkType['default'].IncomingCall) {
        this.get('telephony').updateCurrentCall(this.get('telephony').getCurrentCall());
        this.get('transition').stop();
        this.set('parking', false);

        return;
      }
      this.get('telephony').updateCurrentCall(this.get('telephony').getCurrentCall());
    },
    callParkFailedCallback: function callParkFailedCallback() {
      this.get('transition').stop();
      this.set('parking', false);
      // eslint-disable-next-line ember-suave/no-direct-property-access
      this.get('notification').error(_ember['default'].i18n.t('call_park.park_fail'));
    },
    callUnparkedCallback: function callUnparkedCallback() {
      this.get('parallelCall').unparkParallelCall();
      this.get('telephony').updateCurrentCall(this.get('telephony').getCurrentCall());
      this.set('currentCall.callParkedTimer', 0);
      this.get('transition').stop();
      this.set('parking', false);
      this.set('showCallUnparkTooltip', false);
      if (!this.get('parallelCall').isParallelCallAndConferenceEnded()) {
        this.get('parallelCall').holdCall(_freshfoneEmberConstantsCallState['default'].Active);
      }
    },
    callUnparkFailedCallback: function callUnparkFailedCallback() {
      this.get('transition').stop();
      this.set('parking', false);
      this.get('notification').error(_ember['default'].i18n.t('call_park.unpark_fail'));
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */
/* eslint-disable camelcase */