define('freshfone-ember/components/agent-availability/component', ['exports', 'ember', 'freshfone-ember/constants/external-redirect-urls', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/url-mixin'], function (exports, _ember, _freshfoneEmberConstantsExternalRedirectUrls, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsUrlMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsUrlMixin['default'], {
    telephony: service(),
    classNames: ["agent-presence"],
    freshidProfile: computed(function () {
      return this.freshidProfileURL();
    }),
    freshidLogout: computed(function () {
      return this.logoutURL();
    }),
    session: service(),
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    currentAgent: computed.alias('currentUser.agent'),
    loading: computed(function () {
      return _ember['default'].i18n.t('common.loading');
    }),
    browserStatus: computed('currentAgent.browserStatus.name', 'currentAgent.browserStatus.id', 'currentAgent.showLoader', function () {
      return this.get('currentAgent.showLoader') ? this.get('loading') : this.get('currentAgent.browserStatus.statusType');
    }),

    actions: {
      afterUserSave: function afterUserSave() {
        this.send("setStatus", 'phone');
      },
      toggleSettings: function toggleSettings(focusPhoneNumber) {
        if (this.get('is1CXCrmView')) {
          window.open(_freshfoneEmberConstantsExternalRedirectUrls.externalRedirectLinks.crmProfileSettings, '_self');
        } else {
          this.toggleProperty('showProfileSettings');
          if (this.get("showProfileSettings")) {
            this.set('focusPhoneNumber', focusPhoneNumber);
          }
          this.set('goMobile', focusPhoneNumber ? true : false);
        }
      },
      busyResolve: function busyResolve() {
        this.get('telephony.agent').resolveBusy();
      },
      sendLogoutMessageEvent: function sendLogoutMessageEvent() {
        if (!this.get('session.account.isUnifiedAppAccount')) {
          window.postMessage({ type: 'freshcaller_logged_out', domain: window.location.origin }, '*');
        }
      }
    }
  });
});