define('freshfone-ember/utils/call-action-constants', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = function () {
    var callActionsConfiguration = [{
      id: 1,
      name: _ember['default'].i18n.t('call_ivr.action_types.call_queue'),
      isACallFlow: true,
      abbr: 'CallQueue',
      label: _ember['default'].i18n.t('call_queue.name'),
      createLabel: _ember['default'].i18n.t('entityNames.new_queue'),
      route: 'protected.admin.callflows'
    }, {
      id: 2,
      name: _ember['default'].i18n.t('call_ivr.action_types.ivr'),
      isACallFlow: true,
      abbr: 'Ivr',
      label: _ember['default'].i18n.t('call_flow.ivr_menu'),
      createLabel: _ember['default'].i18n.t('entityNames.new_queue'),
      route: 'protected.admin.ivr'
    }, {
      id: 5,
      name: _ember['default'].i18n.t('call_ivr.action_types.deflection_bot'),
      isACallFlow: true,
      abbr: 'DeflectionBot',
      label: _ember['default'].i18n.t('deflection_bot.name'),
      createLabel: _ember['default'].i18n.t('entityNames.deflection_bot'),
      route: 'protected.admin.deflection-bot'
    }, {
      id: 6,
      name: _ember['default'].i18n.t('call_ivr.action_types.agent_extension'),
      isACallFlow: true,
      abbr: 'AgentExtension',
      label: _ember['default'].i18n.t('agent_extension.name'),
      createLabel: _ember['default'].i18n.t('entityNames.new_agent_extension'),
      route: 'protected.admin.agent-extension'
    }, {
      id: 7,
      name: _ember['default'].i18n.t('call_ivr.action_types.routing_automation'),
      isACallFlow: true,
      abbr: 'RoutingAutomation',
      label: _ember['default'].i18n.t('data_automated_routing.name'),
      createLabel: _ember['default'].i18n.t('entityNames.new_routing_automation'),
      route: 'protected.admin.data-automated-routing'
    }, {
      id: 3,
      name: _ember['default'].i18n.t('call_ivr.action_types.voicemail'),
      isACallFlow: false,
      abbr: 'vm',
      label: _ember['default'].i18n.t('entityNames.message')
    }, {
      id: 4,
      name: _ember['default'].i18n.t('call_ivr.action_types.hangup'),
      isACallFlow: false,
      abbr: 'hangup',
      label: _ember['default'].i18n.t('entityNames.message')
    }];

    return callActionsConfiguration;
  };
});
/* eslint-disable ember-suave/no-direct-property-access */