define("freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 9,
                    "column": 32
                  },
                  "end": {
                    "line": 9,
                    "column": 113
                  }
                },
                "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [0]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element5, 'class');
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["icon-warning ", ["subexpr", "if", [["get", "isChildFormOpen", ["loc", [null, [9, 80], [9, 95]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [9, 75], [9, 104]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 10,
                    "column": 32
                  },
                  "end": {
                    "line": 10,
                    "column": 113
                  }
                },
                "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "sidebar-title-action icon-close closeChild");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 15,
                      "column": 36
                    },
                    "end": {
                      "line": 32,
                      "column": 36
                    }
                  },
                  "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "option.name", ["loc", [null, [31, 40], [31, 55]]], 0, 0, 0, 0]],
                locals: ["option"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 24
                  },
                  "end": {
                    "line": 36,
                    "column": 24
                  }
                },
                "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "content-form with-content-border");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "content-row drop-down");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "help-text");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
                return morphs;
              },
              statements: [["block", "custom-elements/power-select", [], ["label", ["subexpr", "t", ["entityNames.message"], [], ["loc", [null, [16, 46], [16, 71]]], 0, 0], "mandatory", true, "model", "message", "value", ["subexpr", "@mut", [["get", "deflectionBotAction", ["loc", [null, [19, 46], [19, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["onChanged"], [], ["loc", [null, [20, 49], [20, 69]]], 0, 0], "hasCreateOption", true, "attributeName", "name", "searchField", "name", "afterOptionsComponent", "custom-elements/new-message", "onNewObjectCreate", ["subexpr", "action", ["createNew", "message"], [], ["loc", [null, [25, 58], [25, 88]]], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [26, 47], [26, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "isOpened", ["subexpr", "@mut", [["get", "isOpened", ["loc", [null, [27, 49], [27, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "optionsComponent", "custom-elements/message-list"], 0, null, ["loc", [null, [15, 36], [32, 69]]]], ["content", "optionAction.helpText", ["loc", [null, [34, 55], [34, 80]]], 0, 0, 0, 0]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 20
                },
                "end": {
                  "line": 37,
                  "column": 24
                }
              },
              "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "header");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createElementMorph(element6);
              morphs[1] = dom.createMorphAt(element6, 1, 1);
              morphs[2] = dom.createMorphAt(element6, 3, 3);
              morphs[3] = dom.createMorphAt(element6, 5, 5);
              morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["element", "action", ["toggleCloseChild"], [], ["loc", [null, [7, 44], [7, 73]]], 0, 0], ["content", "optionAction.desc", ["loc", [null, [8, 32], [8, 53]]], 0, 0, 0, 0], ["block", "if", [["get", "showError", ["loc", [null, [9, 38], [9, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 32], [9, 120]]]], ["block", "if", [["get", "isChildFormOpen", ["loc", [null, [10, 38], [10, 53]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [10, 32], [10, 120]]]], ["block", "if", [["get", "isChildFormOpen", ["loc", [null, [12, 30], [12, 45]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [12, 24], [36, 31]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 28
                  },
                  "end": {
                    "line": 60,
                    "column": 28
                  }
                },
                "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "option.name", ["loc", [null, [59, 32], [59, 47]]], 0, 0, 0, 0]],
              locals: ["option"],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 32
                    },
                    "end": {
                      "line": 64,
                      "column": 96
                    }
                  },
                  "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "icon-warning");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 28
                  },
                  "end": {
                    "line": 67,
                    "column": 28
                  }
                },
                "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1, "class", "child-queue-name ellipsis");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element2);
                morphs[1] = dom.createMorphAt(element2, 1, 1);
                morphs[2] = dom.createMorphAt(element2, 3, 3);
                return morphs;
              },
              statements: [["element", "action", ["showChild", ["get", "deflectionBotAction", ["loc", [null, [63, 90], [63, 109]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 69], [63, 111]]], 0, 0], ["block", "if", [["get", "deflectionBotAction.anyError", ["loc", [null, [64, 38], [64, 66]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [64, 32], [64, 103]]]], ["content", "deflectionBotAction.name", ["loc", [null, [65, 32], [65, 60]]], 0, 0, 0, 0]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 24
                },
                "end": {
                  "line": 69,
                  "column": 20
                }
              },
              "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "child-queue");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "drop-down");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(element3, 3, 3);
              return morphs;
            },
            statements: [["block", "custom-elements/power-select", [], ["placeholder", ["subexpr", "@mut", [["get", "optionAction.chooseDesc", ["loc", [null, [41, 44], [41, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "child-queue-dropdown", "value", ["subexpr", "@mut", [["get", "deflectionBotAction", ["loc", [null, [43, 38], [43, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "optionList", ["subexpr", "@mut", [["get", "callFlowList", ["loc", [null, [44, 43], [44, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "actionErrors", ["loc", [null, [45, 39], [45, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "hasCreateOption", true, "attributeName", "name", "searchField", "name", "onChange", ["subexpr", "action", ["onChanged"], [], ["loc", [null, [49, 41], [49, 61]]], 0, 0], "afterOptionsComponent", "custom-elements/create-option", "afterOptionLabelText", ["subexpr", "@mut", [["get", "createNewEntityLabel", ["loc", [null, [51, 53], [51, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "onNewObjectCreate", ["subexpr", "action", ["createNew"], [], ["loc", [null, [52, 50], [52, 70]]], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [53, 39], [53, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "toggleAction", ["subexpr", "action", ["toggleOtherChildOpen"], [], ["loc", [null, [54, 45], [54, 76]]], 0, 0], "optionsComponent", "custom-elements/message-list", "noMatchesMessage", ["subexpr", "@mut", [["get", "noMatchesMessage", ["loc", [null, [56, 49], [56, 65]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [40, 28], [60, 61]]]], ["block", "if", [["get", "isChildPresent", ["loc", [null, [62, 34], [62, 48]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [62, 28], [67, 35]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 8
              },
              "end": {
                "line": 71,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createMorphAt(element7, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["child-view deflect flow-cover not-child-form ", ["get", "optionAction.name", ["loc", [null, [5, 71], [5, 88]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isMessageSelected", ["loc", [null, [6, 26], [6, 43]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 20], [69, 27]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 74,
                  "column": 16
                },
                "end": {
                  "line": 80,
                  "column": 16
                }
              },
              "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "component", ["ivr-component/ivr-form"], ["ivr", ["subexpr", "@mut", [["get", "deflectionBotAction", ["loc", [null, [76, 28], [76, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "ivrAncestorList", ["subexpr", "@mut", [["get", "ivrAncestorList", ["loc", [null, [77, 40], [77, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "startValidate", ["subexpr", "@mut", [["get", "startValidate", ["loc", [null, [78, 38], [78, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [75, 20], [79, 22]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 81,
                  "column": 16
                },
                "end": {
                  "line": 86,
                  "column": 16
                }
              },
              "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "component", ["call-queue"], ["parentQueue", ["subexpr", "@mut", [["get", "deflectionBotAction", ["loc", [null, [83, 36], [83, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "startValidate", ["subexpr", "@mut", [["get", "startValidate", ["loc", [null, [84, 38], [84, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [82, 20], [85, 22]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 8
              },
              "end": {
                "line": 88,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "sidebar-title-action icon-close closeChild");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("     \n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element1);
            morphs[2] = dom.createMorphAt(element0, 3, 3);
            morphs[3] = dom.createMorphAt(element0, 4, 4);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["child-view deflect flow-cover child-form ", ["get", "optionAction.name", ["loc", [null, [72, 67], [72, 84]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["get", "isChildFormOpen", ["loc", [null, [72, 92], [72, 107]]], 0, 0, 0, 0], "expanded"], [], ["loc", [null, [72, 87], [72, 120]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleCloseChild"], [], ["loc", [null, [73, 70], [73, 99]]], 0, 0], ["block", "if", [["get", "isIvrSelected", ["loc", [null, [74, 22], [74, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [74, 16], [80, 23]]]], ["block", "if", [["get", "isCallQueueSelected", ["loc", [null, [81, 22], [81, 41]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [81, 16], [86, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 92,
                    "column": 8
                  },
                  "end": {
                    "line": 100,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "component", ["message-components/new-message"], ["message", ["subexpr", "@mut", [["get", "messageHolder", ["loc", [null, [94, 20], [94, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "isFromModal", true, "isOpen", ["subexpr", "@mut", [["get", "showMessage", ["loc", [null, [96, 19], [96, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "closeCallBack", ["subexpr", "action", ["closeMessage"], [], ["loc", [null, [97, 26], [97, 49]]], 0, 0], "saveCallBack", ["subexpr", "action", ["closeMessage"], [], ["loc", [null, [98, 25], [98, 48]]], 0, 0]], ["loc", [null, [93, 12], [99, 14]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 91,
                  "column": 8
                },
                "end": {
                  "line": 101,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "ui-modal", [], ["size", "sm", "closeOnEscape", false, "closeOnBackdropClick", false], 0, null, ["loc", [null, [92, 8], [100, 21]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 90,
                "column": 4
              },
              "end": {
                "line": 102,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ember-wormhole", [], ["to", "modal-wormhole"], 0, null, ["loc", [null, [91, 8], [101, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 103,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "branch-connector deflect");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element8, 'class');
          morphs[1] = dom.createMorphAt(element8, 3, 3);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["child-wrapper ", ["get", "optionAction.name", ["loc", [null, [2, 32], [2, 49]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "unless", [["get", "isChildAFlow", ["loc", [null, [4, 18], [4, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 8], [88, 19]]]], ["block", "if", [["get", "showMessage", ["loc", [null, [90, 10], [90, 21]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [90, 4], [102, 11]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 104,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "isOtherChildOpen", ["loc", [null, [1, 10], [1, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [103, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});