define('freshfone-ember/components/live-calls/inprogress-calls-list/component', ['exports', 'ember', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _emberCliPaginationComputedPagedArray) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  var throttle = _ember['default'].run.throttle;
  exports['default'] = Component.extend({
    timer: service(),
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 10,
    pagedCallList: (0, _emberCliPaginationComputedPagedArray['default'])('sortedCallList', { pageBinding: "page", perPageBinding: "perPage" }),
    progressCallsCount: computed.alias('progressCalls.length'),
    calls: computed(function () {
      return this.store.peekAll('call');
    }),
    selectedQueueIds: computed.mapBy('selectedQueues', 'id'),
    sortedCallList: computed.sort('progressCalls', 'sortDefinition'),
    sortDefinition: ['answeredAt:desc'],
    hasCalls: computed.notEmpty('progressCalls'),
    telephony: service('telephony'),
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    currentAgent: computed.alias('currentUser.agent'),
    agentBusy: computed.equal('currentAgent.browserStatus.statusType', 'busy'),
    agentAcw: computed.equal('currentAgent.browserStatus.statusType', 'acw'),
    isAgentOnMobile: computed.equal('currentAgent.browserStatus.statusSubType', 'online_phone'),

    isAgentBusy: computed('agentBusy', 'agentAcw', function () {
      return this.get('agentBusy') || this.get('agentAcw');
    }),

    isAgentOnline: computed('isAgentBusy', function () {
      return !this.get('isAgentBusy');
    }),

    isCallMonitored: computed('isMonitoredCall', 'isAgentOnline', function () {
      return this.get('isMonitoredCall') || this.get('isAgentOnline');
    }),

    currentCallId: computed('telephony.currentCall.id', function () {
      if (this.get('telephony.currentCall.id')) {
        return this.get('telephony.currentCall.id').toString();
      }
    }),

    isCurrentCall: computed('isActiveCall', 'isAgentBusy', function () {
      return this.get('isActiveCall') && this.get('isAgentBusy');
    }),

    progressCalls: computed('calls.@each.hasPrimaryOrParallelCallActive', 'selectedQueueIds.[]', 'calls.@each.csatTransferMade', function () {
      var _this = this;

      var queues = this.get('selectedQueueIds') || [];

      return this.get('calls').filter(function (call) {
        return call.get('hasPrimaryOrParallelCallActive') && (isEmpty(queues) || _this.hasValidQueue(call, queues)) && !call.get('csatTransferMade');
      });
    }),

    talkTimes: computed.mapBy('progressCalls', 'talkTime'),
    totalTalkTime: computed.sum('talkTimes'),
    avgTalkTime: computed('totalTalkTime', function () {
      return this.get('totalTalkTime') / this.get('progressCallsCount');
    }),

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.get('pagedCallList').on('invalidPage', function (_ref) {
        var page = _ref.page;
        var totalPages = _ref.totalPages;

        if (page < 1 || totalPages < page) {
          _this2.set('pagedCallList.page', totalPages);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);
      this.get('timer').create('inProgressCallsTimer', 1000, function () {
        _ember['default'].run(function () {
          _this3.get('progressCalls').forEach(function (queuedCall) {
            queuedCall.notifyPropertyChange('answeredAt');
          });
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('timer').destroy('inProgressCallsTimer');
    },

    hasValidQueue: function hasValidQueue(call, queues) {
      if (isEmpty(call.get("callQueueId"))) return;
      return queues.includes(call.get("callQueueId").toString());
    },

    postBargeCallEvent: function postBargeCallEvent(currentCall) {
      var payload = {
        callId: currentCall.get('id'),
        customer: {
          id: currentCall.get('contact.id') || '',
          name: currentCall.get('contact.name') || currentCall.get('from'),
          companyName: currentCall.get('contact.company.name')
        },
        agentName: currentCall.get('user.name') || currentCall.get('externalNumber'),
        conversationId: currentCall.get('inboxConversationId'),
        isCallOnHold: currentCall.get('isOnHold'),
        callInitiatedNumber: currentCall.get('number')
      };

      window.parent.postMessage({
        type: 'barge_call',
        payload: payload
      }, this.get('currentAccount.orgUrl'));
    },

    actions: {
      joinCall: function joinCall(currentCall) {
        if (this.get('isCrmCxView')) {
          throttle(this, this.postBargeCallEvent, currentCall, 2000);
        } else {
          var type = currentCall.get('isOnHold') ? 'barge' : 'monitor';
          var params = { call_id: currentCall.get('id'), type: type, contact_id: currentCall.get('contact.id'), FCNumber: currentCall.get('number') };

          if (!this.get('telephony').getCurrentCall()) {
            this.get('telephony').joinCall(params);
            this.get('telephony').ignoreAllCalls('monitor');
          }
        }
      },
      leaveCall: function leaveCall() {
        if (this.get('telephony').getCurrentCall()) {
          this.get('telephony').hangup('live-calls/inprogress-calls-list/component.js#actions.leaveCall');
          this.get('telephony').close();
        }
      }
    }
  });
});