define('freshfone-ember/models/agent-leg', ['exports', 'freshfone-ember/constants/call-status', 'ember-data', 'ember', 'moment'], function (exports, _freshfoneEmberConstantsCallStatus, _emberData, _ember, _moment) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var equal = _Ember$computed.equal;
  var or = _Ember$computed.or;
  exports['default'] = Model.extend({
    duration: attr(),
    cost: attr(),
    connectedType: attr(),
    status: attr(),
    // eslint-disable-next-line camelcase
    created_at: attr(),
    externalNumber: attr(),
    contactName: attr(),
    parallelCallHoldDuration: attr(),
    call: belongsTo('call'),
    user: belongsTo('user'),

    entityName: or('user.name', 'contactName', 'externalNumber'),
    transferredAt: computed('call.created_at', function () {
      return (0, _moment['default'])(this.get('created_at')).diff((0, _moment['default'])(this.get('call.created_at')), 'seconds');
    }),
    isConferenceLeg: equal('connectedType', 'agent_conference'),
    isWarmTransferLeg: equal('connectedType', 'warm_transfer'),
    isParallelCallLeg: equal('connectedType', 'parallel_call'),
    isCallCompleted: equal('status', _freshfoneEmberConstantsCallStatus['default'].Completed),
    isOngoing: equal('status', _freshfoneEmberConstantsCallStatus['default'].InProgress),
    isOnHold: equal('status', _freshfoneEmberConstantsCallStatus['default'].Onhold),
    isActive: or('isOngoing', 'isOnHold'),

    directionStatusClass: computed('status', function () {
      if (this.get('isConferenceLeg')) {
        return 'icon-conference-' + (this.get('isCallCompleted') ? 'success' : 'missed');
      }

      return 'icon-warm-transfer-' + (this.get('isCallCompleted') ? 'success' : 'fail');
    }),
    displayStatus: computed('status', function () {
      if (this.get('status') === _freshfoneEmberConstantsCallStatus['default'].InProgress) {
        return _ember['default'].i18n.t('transfer.inprogress');
      }

      if (this.get('isConferenceLeg')) {
        return this.get('isCallCompleted') ? _ember['default'].i18n.t('conference.success') : _ember['default'].i18n.t('conference.missed');
      }

      if (this.get('isParallelCallLeg')) {
        return this.get('isCallCompleted') ? _ember['default'].i18n.t('parallel_call.log_success') : _ember['default'].i18n.t('parallel_call.log_failed');
      }

      return this.get('isCallCompleted') ? _ember['default'].i18n.t('transfer.success') : _ember['default'].i18n.t('transfer.failedTransfer');
    })
  });
});
/* eslint-disable ember-suave/no-direct-property-access */