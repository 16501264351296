define('freshfone-ember/components/data-automated-routing/form/data-routing-rules/import-rules/rules-form/rules-table/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      this.set('queryParams', {
        // eslint-disable-next-line camelcase
        routing_automation_id: this.get('routingAutomationId'),
        page: 2
      });
    },

    actions: {
      deleteRule: function deleteRule(rule) {
        var _this = this;

        rule.destroyRecord().then(function () {
          return _this.get('rules').removeObject(rule);
        });
      }
    }
  });
});