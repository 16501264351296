define("freshfone-ember/components/agent-component/agent-form/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 6
              },
              "end": {
                "line": 7,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("small");
            dom.setAttribute(el1, "class", "special-denotation");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["agent_form.standalone_agent"], ["productName", ["subexpr", "@mut", [["get", "currentAccount.productName", ["loc", [null, [6, 88], [6, 114]]], 0, 0, 0, 0]], [], [], 0, 0], "deskName", ["subexpr", "@mut", [["get", "currentAccount.deskName", ["loc", [null, [6, 124], [6, 147]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 42], [6, 149]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          dom.setAttribute(el1, "class", "sidebar-title");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element7, 1, 1);
          morphs[1] = dom.createMorphAt(element7, 3, 3);
          return morphs;
        },
        statements: [["content", "title", ["loc", [null, [4, 6], [4, 15]]], 0, 0, 0, 0], ["block", "if", [["get", "currentAccount.isOmnichannelEnabled", ["loc", [null, [5, 12], [5, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 6], [7, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 16,
                      "column": 14
                    },
                    "end": {
                      "line": 18,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["user.errors.admin_license_text.omnichannel"], ["productName", ["subexpr", "@mut", [["get", "currentAccount.productName", ["loc", [null, [17, 77], [17, 103]]], 0, 0, 0, 0]], [], [], 0, 0], "deskName", ["subexpr", "@mut", [["get", "currentAccount.deskName", ["loc", [null, [17, 113], [17, 136]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 16], [17, 138]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 18,
                        "column": 14
                      },
                      "end": {
                        "line": 20,
                        "column": 14
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "t", ["user.errors.supervisor_license_text.omnichannel"], ["productName", ["subexpr", "@mut", [["get", "currentAccount.productName", ["loc", [null, [19, 82], [19, 108]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 16], [19, 110]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 20,
                        "column": 14
                      },
                      "end": {
                        "line": 22,
                        "column": 14
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n              ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "agentLimitText", ["loc", [null, [21, 16], [21, 34]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 18,
                      "column": 14
                    },
                    "end": {
                      "line": 22,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "session.user.isSupervisor", ["loc", [null, [18, 24], [18, 49]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [18, 14], [22, 14]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 12
                  },
                  "end": {
                    "line": 23,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "session.user.isAdmin", ["loc", [null, [16, 20], [16, 40]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [16, 14], [22, 21]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 25,
                        "column": 16
                      },
                      "end": {
                        "line": 27,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "t", ["user.errors.no_license_purchased.trial_admin"], ["productName", ["subexpr", "@mut", [["get", "currentAccount.productName", ["loc", [null, [26, 81], [26, 107]]], 0, 0, 0, 0]], [], [], 0, 0], "deskName", ["subexpr", "@mut", [["get", "currentAccount.deskName", ["loc", [null, [26, 117], [26, 140]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [26, 18], [26, 142]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 27,
                        "column": 16
                      },
                      "end": {
                        "line": 29,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "t", ["user.errors.no_license_purchased.active_admin"], ["link", "/admin/billing", "productName", ["subexpr", "@mut", [["get", "currentAccount.productName", ["loc", [null, [28, 104], [28, 130]]], 0, 0, 0, 0]], [], [], 0, 0], "deskName", ["subexpr", "@mut", [["get", "currentAccount.deskName", ["loc", [null, [28, 140], [28, 163]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 18], [28, 165]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 24,
                      "column": 14
                    },
                    "end": {
                      "line": 30,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "isTrial", ["loc", [null, [25, 22], [25, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [25, 16], [29, 23]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 14
                    },
                    "end": {
                      "line": 32,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["user.errors.no_license_purchased.supervisor"], ["productName", ["subexpr", "@mut", [["get", "currentAccount.productName", ["loc", [null, [31, 78], [31, 104]]], 0, 0, 0, 0]], [], [], 0, 0], "deskName", ["subexpr", "@mut", [["get", "currentAccount.deskName", ["loc", [null, [31, 114], [31, 137]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [31, 16], [31, 139]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 12
                  },
                  "end": {
                    "line": 33,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "session.user.isAccountAdmin", ["loc", [null, [24, 20], [24, 47]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [24, 14], [32, 21]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 10
                },
                "end": {
                  "line": 34,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "addonUsersLimit", ["loc", [null, [15, 18], [15, 33]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [15, 12], [33, 19]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 12
                  },
                  "end": {
                    "line": 37,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["user.errors.admin_license_text.standalone"], [], ["loc", [null, [36, 14], [36, 63]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 12
                    },
                    "end": {
                      "line": 39,
                      "column": 12
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["user.errors.supervisor_license_text.standalone"], [], ["loc", [null, [38, 14], [38, 68]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 39,
                      "column": 12
                    },
                    "end": {
                      "line": 41,
                      "column": 12
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "agentLimitText", ["loc", [null, [40, 14], [40, 32]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 37,
                    "column": 12
                  },
                  "end": {
                    "line": 41,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "session.user.isSupervisor", ["loc", [null, [37, 22], [37, 47]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [37, 12], [41, 12]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 10
                },
                "end": {
                  "line": 42,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "session.user.isAdmin", ["loc", [null, [35, 18], [35, 38]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [35, 12], [41, 19]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 4
              },
              "end": {
                "line": 45,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "content-form agent-form with-content-border");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "license");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "isOmnichannelEnabled", ["loc", [null, [14, 16], [14, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [14, 10], [42, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 10
                  },
                  "end": {
                    "line": 51,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "readonly-field user-name");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "readonly-field");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                return morphs;
              },
              statements: [["content", "user.name", ["loc", [null, [49, 48], [49, 61]]], 0, 0, 0, 0], ["content", "user.email", ["loc", [null, [50, 38], [50, 52]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 10
                  },
                  "end": {
                    "line": 64,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "email content-row");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "text-field", [], ["mandatory", true, "class", "text-field", "label", ["subexpr", "t", ["agent_form.email"], [], ["loc", [null, [56, 22], [56, 44]]], 0, 0], "for", "user", "value", ["subexpr", "@mut", [["get", "user.email", ["loc", [null, [58, 22], [58, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "errors.user.email", ["loc", [null, [59, 23], [59, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [60, 23], [60, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "eq", [["get", "session.user.id", ["loc", [null, [61, 29], [61, 44]]], 0, 0, 0, 0], ["get", "user.id", ["loc", [null, [61, 45], [61, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [61, 25], [61, 53]]], 0, 0]], ["loc", [null, [53, 14], [62, 16]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 10
                  },
                  "end": {
                    "line": 67,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "readonly-field");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(": ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element5, 0, 0);
                morphs[1] = dom.createMorphAt(element5, 2, 2);
                return morphs;
              },
              statements: [["inline", "t", ["agent_form.roles"], [], ["loc", [null, [66, 38], [66, 62]]], 0, 0], ["content", "user.role.name", ["loc", [null, [66, 64], [66, 82]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 69,
                      "column": 14
                    },
                    "end": {
                      "line": 78,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "text-field", [], ["class", "text-field", "label", ["subexpr", "t", ["agent_form.roles"], [], ["loc", [null, [72, 24], [72, 46]]], 0, 0], "for", "user", "value", ["subexpr", "@mut", [["get", "user.role.name", ["loc", [null, [74, 24], [74, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [75, 25], [75, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", true], ["loc", [null, [70, 16], [77, 18]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 79,
                        "column": 16
                      },
                      "end": {
                        "line": 81,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "option-value");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "role.label", ["loc", [null, [80, 45], [80, 59]]], 0, 0, 0, 0]],
                  locals: ["role"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 78,
                      "column": 14
                    },
                    "end": {
                      "line": 82,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "custom-elements/power-select", [], ["class", "roles", "options", ["subexpr", "@mut", [["get", "roles", ["loc", [null, [79, 70], [79, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "label", ["subexpr", "t", ["agent_form.roles"], [], ["loc", [null, [79, 82], [79, 104]]], 0, 0], "value", ["subexpr", "@mut", [["get", "user.role", ["loc", [null, [79, 111], [79, 120]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [79, 16], [81, 49]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 67,
                    "column": 10
                  },
                  "end": {
                    "line": 84,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element4, 'class');
                morphs[1] = dom.createMorphAt(element4, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["role content-row ", ["subexpr", "unless", [["subexpr", "eq", [["get", "session.user.id", ["loc", [null, [68, 54], [68, 69]]], 0, 0, 0, 0], ["get", "user.id", ["loc", [null, [68, 70], [68, 77]]], 0, 0, 0, 0]], [], ["loc", [null, [68, 50], [68, 78]]], 0, 0], "drop-down"], [], ["loc", [null, [68, 41], [68, 92]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "session.user.id", ["loc", [null, [69, 24], [69, 39]]], 0, 0, 0, 0], ["get", "user.id", ["loc", [null, [69, 40], [69, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [69, 20], [69, 48]]], 0, 0]], [], 0, 1, ["loc", [null, [69, 14], [82, 21]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child4 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 87,
                      "column": 14
                    },
                    "end": {
                      "line": 95,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "option-name");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "option-description");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                  return morphs;
                },
                statements: [["content", "scope.localeName", ["loc", [null, [93, 41], [93, 61]]], 0, 0, 0, 0], ["content", "scope.description", ["loc", [null, [94, 48], [94, 69]]], 0, 0, 0, 0]],
                locals: ["scope"],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 97,
                        "column": 16
                      },
                      "end": {
                        "line": 99,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    dom.setAttribute(el1, "class", "info-text");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["inline", "t", ["agent_form.teams_in_scope"], ["size", ["subexpr", "@mut", [["get", "user.teams.length", ["loc", [null, [98, 76], [98, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "names", ["subexpr", "@mut", [["get", "user.teamNames.content", ["loc", [null, [98, 100], [98, 122]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [98, 39], [98, 124]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 99,
                        "column": 16
                      },
                      "end": {
                        "line": 105,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "warning-info-wrapper");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "class", "error-msg");
                    var el3 = dom.createTextNode("\n                      ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                    return morphs;
                  },
                  statements: [["inline", "t", ["agent_form.not_part_of_any_team"], [], ["loc", [null, [102, 22], [102, 61]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 96,
                      "column": 14
                    },
                    "end": {
                      "line": 106,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "user.teams.length", ["loc", [null, [97, 22], [97, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [97, 16], [105, 23]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child2 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 121,
                          "column": 20
                        },
                        "end": {
                          "line": 123,
                          "column": 20
                        }
                      },
                      "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(" ( ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(" )\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                      return morphs;
                    },
                    statements: [["content", "number.name", ["loc", [null, [122, 22], [122, 37]]], 0, 0, 0, 0], ["content", "number.number", ["loc", [null, [122, 40], [122, 57]]], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 123,
                          "column": 20
                        },
                        "end": {
                          "line": 125,
                          "column": 20
                        }
                      },
                      "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["content", "number.number", ["loc", [null, [124, 22], [124, 39]]], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 110,
                        "column": 16
                      },
                      "end": {
                        "line": 127,
                        "column": 16
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element2, 'class');
                    morphs[1] = dom.createMorphAt(element2, 1, 1);
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["option-value ", ["subexpr", "if", [["get", "number.deleted", ["loc", [null, [120, 49], [120, 63]]], 0, 0, 0, 0], "deleted"], [], ["loc", [null, [120, 44], [120, 75]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "number.name", ["loc", [null, [121, 26], [121, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [121, 20], [125, 27]]]]],
                  locals: ["number"],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 108,
                      "column": 12
                    },
                    "end": {
                      "line": 129,
                      "column": 12
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "content-row multi-select-drop-down scoped-numbers");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "custom-elements/power-select", [], ["model", "number", "queryParams", ["subexpr", "@mut", [["get", "numberQueryParams", ["loc", [null, [112, 30], [112, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "multiSelect", true, "value", ["subexpr", "@mut", [["get", "user.numbersInScope", ["loc", [null, [114, 24], [114, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "renderInPlace", true, "searchField", "number", "placeholder", ["subexpr", "t", ["call_log.no_numbers"], [], ["loc", [null, [117, 30], [117, 55]]], 0, 0]], 0, null, ["loc", [null, [110, 16], [127, 49]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 85,
                    "column": 10
                  },
                  "end": {
                    "line": 130,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "access-scope-wrapper content-row drop-down");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element3, 1, 1);
                morphs[1] = dom.createMorphAt(element3, 2, 2);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "custom-elements/power-select", [], ["class", "access-scope options-described", "label", ["subexpr", "t", ["agent_form.scope"], [], ["loc", [null, [89, 22], [89, 44]]], 0, 0], "options", ["subexpr", "@mut", [["get", "availableUserScopes", ["loc", [null, [90, 24], [90, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "user.scope", ["loc", [null, [91, 22], [91, 32]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [87, 14], [95, 47]]]], ["block", "if", [["get", "isUserTeamScoped", ["loc", [null, [96, 20], [96, 36]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [96, 14], [106, 21]]]], ["block", "if", [["subexpr", "eq", [["get", "user.scope.name", ["loc", [null, [108, 22], [108, 37]]], 0, 0, 0, 0], "number"], [], ["loc", [null, [108, 18], [108, 47]]], 0, 0]], [], 2, null, ["loc", [null, [108, 12], [129, 19]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          })();
          var child5 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 133,
                      "column": 14
                    },
                    "end": {
                      "line": 137,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "error");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["content", "error.message", ["loc", [null, [135, 18], [135, 35]]], 0, 0, 0, 0]],
                locals: ["error"],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 138,
                      "column": 14
                    },
                    "end": {
                      "line": 142,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "error");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["content", "error.message", ["loc", [null, [140, 18], [140, 35]]], 0, 0, 0, 0]],
                locals: ["error"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 131,
                    "column": 10
                  },
                  "end": {
                    "line": 144,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "server-errors");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element1, 1, 1);
                morphs[1] = dom.createMorphAt(element1, 2, 2);
                return morphs;
              },
              statements: [["block", "each", [["get", "user.errors.email", ["loc", [null, [133, 22], [133, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [133, 14], [137, 23]]]], ["block", "each", [["get", "user.errors.base", ["loc", [null, [138, 22], [138, 38]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [138, 14], [142, 23]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 145,
                    "column": 10
                  },
                  "end": {
                    "line": 152,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "omnichannel-redirect");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "icon-edit");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "special-denotation");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
                return morphs;
              },
              statements: [["inline", "t", ["agent_form.agent_edit"], ["account", "Omnichannel", "product", ["subexpr", "@mut", [["get", "currentAccount.deskName", ["loc", [null, [149, 74], [149, 97]]], 0, 0, 0, 0]], [], [], 0, 0], "agentEditRedirectUrl", ["subexpr", "concat", [["get", "currentAccount.omnichannelOwnerDomain", ["loc", [null, [149, 127], [149, 164]]], 0, 0, 0, 0], "/a/admin/agents"], [], ["loc", [null, [149, 119], [149, 183]]], 0, 0]], ["loc", [null, [149, 16], [149, 185]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child7 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 153,
                    "column": 10
                  },
                  "end": {
                    "line": 160,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "sales-redirect");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "icon-edit");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "special-denotation");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
                return morphs;
              },
              statements: [["inline", "t", ["agent_form.agent_edit"], ["account", ["subexpr", "@mut", [["get", "crmAccountName", ["loc", [null, [157, 52], [157, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "product", ["subexpr", "@mut", [["get", "crmAccountName", ["loc", [null, [157, 75], [157, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "agentEditRedirectUrl", ["subexpr", "concat", [["get", "currentAccount.orgUrl", ["loc", [null, [157, 119], [157, 140]]], 0, 0, 0, 0], ["get", "crmAgentEdit", ["loc", [null, [157, 141], [157, 153]]], 0, 0, 0, 0]], [], ["loc", [null, [157, 111], [157, 154]]], 0, 0]], ["loc", [null, [157, 16], [157, 156]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child8 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 160,
                      "column": 10
                    },
                    "end": {
                      "line": 167,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "sales-redirect");
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2, "class", "icon-edit");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "special-denotation");
                  var el3 = dom.createTextNode("\n                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n          ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "t", ["agent_form.agent_edit"], ["account", "CRM", "product", "FreshCRM", "agentEditRedirectUrl", ["subexpr", "concat", [["get", "currentAccount.orgUrl", ["loc", [null, [164, 106], [164, 127]]], 0, 0, 0, 0], ["get", "crmAgentEdit", ["loc", [null, [164, 128], [164, 140]]], 0, 0, 0, 0]], [], ["loc", [null, [164, 98], [164, 141]]], 0, 0]], ["loc", [null, [164, 16], [164, 143]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 160,
                    "column": 10
                  },
                  "end": {
                    "line": 167,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "user.isCrmUser", ["loc", [null, [160, 20], [160, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [160, 10], [167, 10]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child9 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 168,
                    "column": 10
                  },
                  "end": {
                    "line": 172,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "button-row");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "data-analytics-action-name", "Save Agent");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createElementMorph(element0);
                morphs[2] = dom.createMorphAt(element0, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["btn btn-save btn-md btn-block btn-primary ", ["subexpr", "if", [["get", "user.isSaving", ["loc", [null, [170, 76], [170, 89]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [170, 71], [170, 102]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["save"], [], ["loc", [null, [170, 104], [170, 121]]], 0, 0], ["content", "submitLabel", ["loc", [null, [170, 162], [170, 177]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 8
                },
                "end": {
                  "line": 173,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "isOmniOrCrmOrSharedUser", ["loc", [null, [48, 16], [48, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [48, 10], [64, 17]]]], ["block", "if", [["get", "isOmniOrCrmOrSharedUser", ["loc", [null, [65, 16], [65, 39]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [65, 10], [84, 17]]]], ["block", "if", [["get", "shouldAllowScoping", ["loc", [null, [85, 16], [85, 34]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [85, 10], [130, 17]]]], ["block", "if", [["get", "user.errors", ["loc", [null, [131, 16], [131, 27]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [131, 10], [144, 17]]]], ["block", "if", [["subexpr", "or", [["get", "user.omnichannelUser", ["loc", [null, [145, 21], [145, 41]]], 0, 0, 0, 0], ["get", "user.isSharedUser", ["loc", [null, [145, 42], [145, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [145, 16], [145, 60]]], 0, 0]], [], 6, null, ["loc", [null, [145, 10], [152, 17]]]], ["block", "if", [["get", "user.isSharedUser", ["loc", [null, [153, 16], [153, 33]]], 0, 0, 0, 0]], [], 7, 8, ["loc", [null, [153, 10], [167, 17]]]], ["block", "if", [["get", "showSaveButton", ["loc", [null, [168, 16], [168, 30]]], 0, 0, 0, 0]], [], 9, null, ["loc", [null, [168, 10], [172, 17]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 4
              },
              "end": {
                "line": 175,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createMorphAt(element6, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["content-form agent-form with-content-border ", ["subexpr", "if", [["get", "isOmniOrCrmOrSharedUser", ["loc", [null, [46, 67], [46, 90]]], 0, 0, 0, 0], "omnichannel-user"], [], ["loc", [null, [46, 62], [46, 111]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "ui-form", [], ["for", ["subexpr", "@mut", [["get", "user", ["loc", [null, [47, 23], [47, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "onValidationFailed", "errorFieldFocus", "startValidate", ["subexpr", "@mut", [["get", "startValidate", ["loc", [null, [47, 79], [47, 92]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [47, 8], [173, 20]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 176,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "agentlimitExceeded", ["loc", [null, [11, 15], [11, 33]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "user.id", ["loc", [null, [11, 39], [11, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 34], [11, 47]]], 0, 0]], [], ["loc", [null, [11, 10], [11, 48]]], 0, 0]], [], 0, 1, ["loc", [null, [11, 4], [175, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 177,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/agent-component/agent-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element8, 'class');
        morphs[1] = dom.createMorphAt(element8, 1, 1);
        morphs[2] = dom.createMorphAt(element8, 2, 2);
        return morphs;
      },
      statements: [["attribute", "class", ["subexpr", "if", [["get", "slideToggle", ["loc", [null, [1, 16], [1, 27]]], 0, 0, 0, 0], "opened", "closed"], [], ["loc", [null, [null, null], [1, 47]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "title", ["loc", [null, [2, 8], [2, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [9, 9]]]], ["block", "if", [["get", "slideToggle", ["loc", [null, [10, 8], [10, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [10, 2], [176, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});