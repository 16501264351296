define('freshfone-ember/components/plan-modal/component', ['exports', 'ember', 'ember-data', 'freshfone-ember/mixins/validations/server-errors', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'freshfone-ember/helpers/currency-types', 'freshfone-ember/utils/subscription-constants'], function (exports, _ember, _emberData, _freshfoneEmberMixinsValidationsServerErrors, _emberRlDropdownMixinsRlDropdownComponent, _freshfoneEmberHelpersCurrencyTypes, _freshfoneEmberUtilsSubscriptionConstants) {
    var computed = _ember['default'].computed;
    var PromiseObject = _emberData['default'].PromiseObject;
    exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsValidationsServerErrors['default'], _emberRlDropdownMixinsRlDropdownComponent['default'], {
        isSaving: false,
        isSproutIsCurrentPlan: computed.equal('selectedPlan.name', 'Sprout'),
        availableCredits: computed.reads('credits.availableCredit'),
        currentAccount: computed.alias('session.account'),
        canDisplayPaymentResult: computed.or('planChanged', 'purchaseTriggered', 'downgradeInitiated'),
        currentCurrency: computed.alias('subscription.currency'),
        isActiveAccount: computed(function () {
            return this.get('subscription.data.status') === _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.ACTIVE || this.get('subscription.data.status') === _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.FREE;
        }),
        credits: computed(function () {
            return PromiseObject.create({
                promise: this.store.queryRecord('credit', {}).then(function (credits) {
                    return credits;
                })
            });
        }),
        currencyNotSupported: computed('currentCurrency', function () {
            return this.get('currentAccount.hasPricingRebrandingFeature') && _freshfoneEmberHelpersCurrencyTypes['default'].rebrandedSupportedCurrencies[this.get('currentCurrency')].disabled;
        }),
        isnewSubscriptionPolicyCustomer: computed.alias('currentAccount.newSubscriptionPolicyCustomer'),
        downgradeConditions: computed('subscription.quantity', 'subscription.renewalPeriod', function () {
            return this.get('subscription.quantity') < this.get('currentAccount.users_limit') || parseInt(this.get('subscription.renewalPeriod')) < parseInt(this.get('subscription.data.renewalPeriod')) || parseInt(_freshfoneEmberUtilsSubscriptionConstants['default'].DEFAULT_PLANS[this.get('currentAccount.plan_name')]) > parseInt(_freshfoneEmberUtilsSubscriptionConstants['default'].DEFAULT_PLANS[this.get('selectedPlan.name')]);
        }),
        isDowngradeRequested: computed('downgradeConditions', function () {
            return this.get('currentAccount').hasGlobalFlag('request_downgrade') && this.get('isnewSubscriptionPolicyCustomer') && this.get('isActiveAccount') && parseInt(_freshfoneEmberUtilsSubscriptionConstants['default'].DEFAULT_PLANS[this.get('selectedPlan.name')]) && this.get('downgradeConditions');
        }),
        addonUsersLimit: computed.alias('session.account.addonUsersLimit'),
        editMode: computed('selectedPlan', 'subscription', function () {
            return this.get('selectedPlan.id') == this.get('subscription.planId');
        }),
        currencySymbol: computed('currencySymbol', 'subscription', function () {
            return _freshfoneEmberHelpersCurrencyTypes['default'].currencySymbols[this.get('subscription.currency')];
        }),
        purchaseButtonDisabled: computed('numberOfAgents', function () {
            if (this.get('purchaseType') !== 'agents' || !this.get('numberOfAgents')) {
                return false;
            }

            return !(Number(this.get('numberOfAgents')) > this.get('addonUsersLimit'));
        }),
        didInsertElement: function didInsertElement() {
            // temp fix
            $('.modal-dialog.modal-sm').width('540px');
        },

        actions: {
            close: function close() {
                this.sendAction('close');
            },
            continueToPay: function continueToPay() {
                this.toggleProperty('showPayment');
                this.set('showDowngradeBanner', this.get('isDowngradeRequested'));
            },
            cardAddedCallback: function cardAddedCallback() {
                var _this = this;

                this.set('isSaving', true);
                if (this.get('addAndPay') || ['credits, agents'].includes(this.get('purchaseType'))) {
                    // Credit purchase through modal
                    this.get('purchaseCallback')(this.get('purchaseType'));
                    this.set('isSaving', false);
                    return;
                }
                if (this.get('subscription.isSuspended')) {
                    this.get('subscription.content').makeActive();
                }
                if (this.get('isDowngradeRequested')) {
                    this.get('subscription.content').requestDowngrade({
                        'plan-id': this.get('selectedPlan').id,
                        'renewal-period': this.get('subscription.renewalPeriod'),
                        quantity: this.get('subscription.quantity')
                    }).then(function () {
                        _this.setProperties({
                            downgradeRequestSuccess: true,
                            downgradeInitiated: true,
                            downgradeFailed: false
                        });
                    })['catch'](function (error) {
                        _this.setProperties({
                            downgradeInitiated: true,
                            downgradeFailed: true
                        });
                    });
                } else {
                    this.get('subscription.content').save().then(function () {
                        _this.set('paymentStatusSuccess', true);
                        _this.set('planChanged', true);
                        _this.set('session.account.users_limit', _this.get('subscription.content.quantity'));
                    })['catch'](function (error) {
                        _this.handleError(error);
                        if ('quantity' in _this.get('serverErrorText')) {
                            _this.set('showPayment', false);
                        } else {
                            _this.set('paymentFailed', true);
                            _this.set('planChanged', true);
                        }
                        _this.get('session').loadSession();
                        _this.set('isSaving', false);
                    });
                }
            },
            selectCredits: function selectCredits() {
                this.toggleProperty('dropdownExpanded');
            },
            selectCallBack: function selectCallBack(selectedValue) {
                this.set('dropdownExpanded', false);
                this.set('selectedValue', selectedValue);
            },
            showAgentLicenseDowngrade: function showAgentLicenseDowngrade(value) {
                this.set('agentLicenseDowngrade', value);
            }
        }
    });
});
/* eslint-disable */