define('freshfone-ember/mixins/serializers/embedded-records', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  //patch for inserting or updating embedded-records
  //https://github.com/NullVoxPopuli/aeonvera-ui/blob/master/app/mixins/serializers/embedded-records.js
  exports['default'] = _ember['default'].Mixin.create(_emberData['default'].EmbeddedRecordsMixin, {
    // fix: move array to the data section
    //
    _serializeEmbeddedBelongsTo: function _serializeEmbeddedBelongsTo(snapshot, json, relationship) {
      this._super(snapshot, json, relationship);
      this._belongsToEmbedded(json, relationship);
    },

    _serializeEmbeddedHasMany: function _serializeEmbeddedHasMany(snapshot, json, relationship) {
      this._super(snapshot, json, relationship);
      this._hasManyDataEmbedded(json, relationship);
    },

    _belongsToEmbedded: function _belongsToEmbedded(json, relationship) {
      if (relationship.kind !== 'belongsTo') {
        return;
      }

      var key = relationship.key.dasherize();
      var jsonForKey = json[key] || {};
      var data = jsonForKey.data;
      delete json[key];
      if (!json.relationships) {
        json.relationships = {};
      }

      json.relationships[key] = {
        data: data
      };
    },

    _hasManyDataEmbedded: function _hasManyDataEmbedded(json, relationship) {
      if (relationship.kind !== 'hasMany') {
        return;
      }

      var key = relationship.key.dasherize();
      var data = json[key].mapBy('data');
      delete json[key];
      if (!json.relationships) {
        json.relationships = {};
      }

      json.relationships[key] = {
        data: data
      };
    }
  });
});