define('freshfone-ember/components/number-component/call-mask-component/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    successfullyVerifiedText: _ember['default'].i18n.t('caller_mask.successfully_verified'),
    enterCodeText: _ember['default'].i18n.t('caller_mask.enter_code'),
    callingText: _ember['default'].i18n.t('caller_mask.calling'),
    retryLabel: _ember['default'].i18n.t('caller_mask.retry'),
    closeLabel: _ember['default'].i18n.t('caller_mask.close'),
    cancelLabel: _ember['default'].i18n.t('caller_mask.cancel'),
    hasCallVerificationStarted: computed.alias('newCallerID.hasCallVerificationStarted'),
    hasVerificationErrors: computed.alias('newCallerID.hasVerificationErrors'),
    hasValidationCode: computed.alias('newCallerID.hasValidationCode'),
    isValidationSuccessfull: computed.alias('newCallerID.isValidationSuccessfull'),
    validationCode: computed.alias('newCallerID.validationCode'),
    errorMessage: computed.alias('newCallerID.errorMessage'),

    hasValidation: computed('hasValidationCode', 'hasVerificationErrors', function () {
      return this.get('newCallerID.hasValidationCode') && !this.get('newCallerID.hasVerificationErrors');
    }),

    numberString: computed('textValue', function () {
      return this.get('textValue');
    }),

    verifyLabel: computed('validatingNumber', 'hasCallVerificationStarted', function () {
      return this.get('validatingNumber') && !this.get('newCallerID.hasCallVerificationStarted') ? _ember['default'].i18n.t('caller_mask.verifying') : _ember['default'].i18n.t('caller_mask.verify');
    }),

    actions: {
      verify: function verify() {
        var _this = this;

        var self = this;
        this.set('validatingNumber', true);
        _ember['default'].$.ajax({
          url: '/caller_ids/validate_number',
          type: 'GET',
          dataType: "json",
          data: { 'number': this.get('numberString') }
        }).done(function (resp) {
          _ember['default'].run(function () {
            _this.set('validatingNumber', false);
            _this.sendAction("verify", _this.get('numberString'));
          });
        }).fail(function (error) {
          _ember['default'].run(function () {
            self.set('newCallerID.error', error.responseText);
            self.set('validatingNumber', false);
          });
        });
      },
      retry: function retry() {
        this.sendAction('retry');
      },
      close: function close() {
        this.sendAction("closeCallBack");
      },
      cancel: function cancel() {
        this.sendAction("closeCallBack");
      }
    }

  });
});