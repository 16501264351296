define("freshfone-ember/components/conversation/conversation-item/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 16
                  },
                  "end": {
                    "line": 13,
                    "column": 75
                  }
                },
                "moduleName": "freshfone-ember/components/conversation/conversation-item/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("protected.companies.show");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 14
                },
                "end": {
                  "line": 14,
                  "column": 14
                }
              },
              "moduleName": "freshfone-ember/components/conversation/conversation-item/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", [["get", "item.contact.company", ["loc", [null, [13, 53], [13, 73]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 16], [13, 75]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 10
              },
              "end": {
                "line": 16,
                "column": 10
              }
            },
            "moduleName": "freshfone-ember/components/conversation/conversation-item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "contact-name ellipsis");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
            morphs[2] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["contact-info ", ["subexpr", "if", [["get", "item.contact.company.name", ["loc", [null, [8, 42], [8, 67]]], 0, 0, 0, 0], "has-company"], [], ["loc", [null, [8, 37], [8, 83]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "text-highlight", [["get", "searchString", ["loc", [null, [10, 33], [10, 45]]], 0, 0, 0, 0], ["get", "item.contact.name", ["loc", [null, [10, 46], [10, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 16], [10, 65]]], 0, 0], ["block", "if", [["get", "item.contact.company.name", ["loc", [null, [12, 20], [12, 45]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 14], [14, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 18,
              "column": 6
            }
          },
          "moduleName": "freshfone-ember/components/conversation/conversation-item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "filter-action");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["inline", "custom-elements/avatar", [], ["type", "user", "model", ["subexpr", "@mut", [["get", "item.contact", ["loc", [null, [5, 51], [5, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [5, 8], [5, 75]]], 0, 0], ["element", "action", ["selectContact", ["get", "item.contact", ["loc", [null, [6, 60], [6, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 35], [6, 74]]], 0, 0], ["block", "link-to", ["protected.contacts.show", ["get", "item.contact", ["loc", [null, [7, 47], [7, 59]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 10], [16, 22]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 6
            },
            "end": {
              "line": 23,
              "column": 6
            }
          },
          "moduleName": "freshfone-ember/components/conversation/conversation-item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "icon-unknown");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "contact-name");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "text-highlight", [["get", "searchString", ["loc", [null, [21, 27], [21, 39]]], 0, 0, 0, 0], ["get", "item.from", ["loc", [null, [21, 40], [21, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 10], [21, 51]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 6
              },
              "end": {
                "line": 41,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/conversation/conversation-item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "audio-player", [], ["url", ["subexpr", "@mut", [["get", "item.recordingUrl", ["loc", [null, [40, 27], [40, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "duration", ["subexpr", "@mut", [["get", "item.recordingDuration", ["loc", [null, [40, 54], [40, 76]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [40, 8], [40, 78]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 2
            },
            "end": {
              "line": 43,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/conversation/conversation-item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "conversation-col col-md-2");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "item.hasRecording", ["loc", [null, [39, 17], [39, 34]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "restrictCallLogs", ["loc", [null, [39, 40], [39, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 35], [39, 57]]], 0, 0]], [], ["loc", [null, [39, 12], [39, 58]]], 0, 0]], [], 0, null, ["loc", [null, [39, 6], [41, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 50,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/conversation/conversation-item/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "conversation-row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "conversation-col col-md-4");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "conversation-col col-md-1");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "call-direction");
        dom.setAttribute(el3, "data-toggle", "tooltip");
        dom.setAttribute(el3, "data-placement", "top");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "conversation-col col-md-3");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "conversation-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "conversation-col col-md-2");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "call-created-at");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(element2, [3, 1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element2, [5]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element2, 'id');
        morphs[1] = dom.createAttrMorph(element3, 'class');
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createAttrMorph(element4, 'data-original-title');
        morphs[4] = dom.createAttrMorph(element5, 'class');
        morphs[5] = dom.createMorphAt(element6, 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
        morphs[7] = dom.createMorphAt(element2, 7, 7);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [9, 1]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "id", ["get", "item.id", ["loc", [null, [1, 35], [1, 42]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["contact-details ", ["subexpr", "unless", [["get", "item.contact", ["loc", [null, [3, 41], [3, 53]]], 0, 0, 0, 0], "unknown"], [], ["loc", [null, [3, 32], [3, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "item.contact", ["loc", [null, [4, 12], [4, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 6], [23, 13]]]], ["attribute", "data-original-title", ["get", "item.displayStatus", ["loc", [null, [27, 97], [27, 115]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "item.directionStatusClass", ["loc", [null, [28, 20], [28, 45]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "call-logs/call-log-row/agent-details", [], ["call", ["subexpr", "@mut", [["get", "item", ["loc", [null, [32, 48], [32, 52]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [32, 4], [32, 54]]], 0, 0], ["inline", "text-highlight", [["get", "searchString", ["loc", [null, [34, 23], [34, 35]]], 0, 0, 0, 0], ["get", "item.conversation", ["loc", [null, [34, 36], [34, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 6], [34, 55]]], 0, 0], ["block", "if", [["subexpr", "has-feature", [], ["feature", "call_recording", "account", ["get", "session.account", ["loc", [null, [37, 54], [37, 69]]], 0, 0, 0, 0]], ["loc", [null, [37, 8], [37, 70]]], 0, 0]], [], 2, null, ["loc", [null, [37, 2], [43, 9]]]], ["inline", "better-moments", [], ["created-at", ["subexpr", "@mut", [["get", "item.created_at", ["loc", [null, [46, 34], [46, 49]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [46, 6], [46, 51]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});