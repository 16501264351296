define('freshfone-ember/components/common/inline-list/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    exports['default'] = Component.extend({
        displayValue: computed('value', function () {
            return this.get('options').findBy(this.get('matcher'), this.get('value')).label;
        }),

        actions: {
            onSelect: function onSelect(option) {
                this.sendAction('selectCallback', option.value);
            }
        }
    });
});