define('freshfone-ember/models/number', ['exports', 'ember-data', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/number-type', 'freshfone-ember/mixins/flash-mixin'], function (exports, _emberData, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsNumberType, _freshfoneEmberMixinsFlashMixin) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var schedule = _ember['default'].run.schedule;
  var service = _ember['default'].inject.service;
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend(_freshfoneEmberMixinsFlashMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberConstantsNumberType['default'], {
    session: service(),
    telephony: service('telephony'),
    name: _emberData['default'].attr("string"),
    number: _emberData['default'].attr(),
    displayNumber: _emberData['default'].attr(),
    numberType: _emberData['default'].attr(),
    country: _emberData['default'].attr(),
    region: _emberData['default'].attr(),
    state: _emberData['default'].attr(),
    recordType: _emberData['default'].attr(),
    address_required: _emberData['default'].attr(),
    address: _emberData['default'].attr(),
    deleted: _emberData['default'].attr('boolean'),
    disabled: computed.bool('deleted'),
    transcription: _emberData['default'].attr(),
    transcriptionEnabled: computed.alias('transcription'),
    rate: _emberData['default'].attr('number'),
    nextRenewalAt: _emberData['default'].attr('string'),
    shortAbandonTimeout: _emberData['default'].attr('number', { defaultValue: 5 }),
    source: attr(),
    providerName: _emberData['default'].attr('string'),
    rule: _emberData['default'].belongsTo('rule', { async: true }),
    users: _emberData['default'].hasMany('user', { async: true }),
    teams: _emberData['default'].hasMany('team', { async: true }),
    hasRule: _ember['default'].computed.notEmpty('rule.id'),
    holdMessage: _emberData['default'].belongsTo('message', { async: true }),
    ringtoneMessage: _emberData['default'].belongsTo('message', { async: true }),
    recordingOptInMessage: _emberData['default'].belongsTo('message'),
    callerId: _emberData['default'].belongsTo('callerId', { async: true }),
    maskingEnabled: computed.notEmpty('callerId.id'),
    recordingOptIn: _emberData['default'].attr(),
    numberTranscriptionFlag: _emberData['default'].belongsTo('number-transcription-flag'),
    voicemailTranscription: computed.alias('numberTranscriptionFlag.voicemail'),
    callTranscription: computed.alias('numberTranscriptionFlag.postcall'),
    liveTranscription: computed.alias('numberTranscriptionFlag.livecall'),
    nextRenewalDate: computed('nextRenewalAt', function () {
      var nextRenewalAt = this.get('nextRenewalAt');
      if (isEmpty(nextRenewalAt)) {
        return nextRenewalAt;
      }
      return window.moment(nextRenewalAt).format('D-MMM-YYYY');
    }),
    assignees: computed('users', 'teams', {
      get: function get(key) {
        return [].concat(_toConsumableArray(this.get('users').toArray()), _toConsumableArray(this.get('teams').toArray()));
      },
      set: function set(key, value) {
        var _this = this;

        schedule('sync', function () {
          _this.set('teams', value.filterBy('constructor.modelName', 'team'));
          _this.set('users', value.filterBy('constructor.modelName', 'user'));
        });
      }
    }),
    init: function init() {
      this.on('didCreate', function () {
        this.get('telephony').numbersUpdatedCallback();
      });
      this.on('didDelete', function () {
        this.get('telephony').numbersUpdatedCallback();
      });
    },
    isSipButSipDisabled: computed('numberType', function () {
      if (this.get('numberType') === this.get('EXTERNAL_NUMBER') && !this.checkFeature('sip_forwarding')) {
        return true;
      }
      return false;
    })
  });
});