define("freshfone-ember/components/power-dialer/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 8
            },
            "end": {
              "line": 9,
              "column": 8
            }
          },
          "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          (");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(")\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "totalContactsCount", ["loc", [null, [8, 11], [8, 33]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 8
              },
              "end": {
                "line": 16,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "power-dialer-animation");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn btn-cancel pull-right end_power_dialer");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createAttrMorph(element11, 'disabled');
            morphs[2] = dom.createElementMorph(element11);
            morphs[3] = dom.createMorphAt(element11, 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["power_dialer.power_dialer_progress"], [], ["loc", [null, [14, 44], [14, 86]]], 0, 0], ["attribute", "disabled", ["get", "disableEndPowerDialer", ["loc", [null, [15, 80], [15, 101]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["stopPowerDialerMode"], [], ["loc", [null, [15, 104], [15, 136]]], 0, 0], ["inline", "t", ["power_dialer.end_power_dialer"], [], ["loc", [null, [15, 137], [15, 174]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 10
                },
                "end": {
                  "line": 21,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "data-toggle", "tooltip");
              dom.setAttribute(el1, "data-placement", "bottom");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element9, 'class');
              morphs[1] = dom.createAttrMorph(element9, 'data-original-title');
              morphs[2] = dom.createElementMorph(element9);
              morphs[3] = dom.createMorphAt(element9, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["btn btn-primary ", ["subexpr", "if", [["get", "disableStartPowerDialer", ["loc", [null, [18, 48], [18, 71]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [18, 43], [18, 84]]], 0, 0], " ", ["subexpr", "if", [["get", "micPermDenied", ["loc", [null, [18, 90], [18, 103]]], 0, 0, 0, 0], "disable-icon"], [], ["loc", [null, [18, 85], [18, 120]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-original-title", ["subexpr", "if", [["get", "isCallsInParkedState", ["loc", [null, [18, 194], [18, 214]]], 0, 0, 0, 0], ["subexpr", "t", ["power_dialer.close_calls"], [], ["loc", [null, [18, 215], [18, 245]]], 0, 0], ""], [], ["loc", [null, [null, null], [18, 250]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["startPowerDialer"], [], ["loc", [null, [18, 251], [18, 280]]], 0, 0], ["inline", "t", ["power_dialer.start_dialing"], [], ["loc", [null, [19, 14], [19, 48]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 10
                },
                "end": {
                  "line": 33,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("             ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-cancel add_from_contacts");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element8, 'onclick');
              morphs[1] = dom.createMorphAt(element8, 1, 1);
              return morphs;
            },
            statements: [["attribute", "onclick", ["subexpr", "action", ["redirectToContacts"], [], ["loc", [null, [null, null], [30, 55]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["power_dialer.add_from_contacts"], [], ["loc", [null, [31, 16], [31, 54]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 12
                  },
                  "end": {
                    "line": 39,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "target", "_blank");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "icon-maximise curser-pointer");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element7, 'class');
                morphs[1] = dom.createAttrMorph(element7, 'href');
                morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["btn btn-cancel ", ["subexpr", "if", [["get", "importInProgress", ["loc", [null, [35, 44], [35, 60]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [35, 39], [35, 73]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["get", "contactUrlForOmniUser", ["loc", [null, [35, 98], [35, 119]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["power_dialer.import_list"], [], ["loc", [null, [36, 22], [36, 54]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 39,
                    "column": 12
                  },
                  "end": {
                    "line": 46,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element6, 'class');
                morphs[1] = dom.createAttrMorph(element6, 'onclick');
                morphs[2] = dom.createMorphAt(element6, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["btn btn-cancel ", ["subexpr", "if", [["get", "importInProgress", ["loc", [null, [41, 43], [41, 59]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [41, 38], [41, 72]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "if", [["subexpr", "not", [["get", "importInProgress", ["loc", [null, [42, 34], [42, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 29], [42, 51]]], 0, 0], ["subexpr", "action", [["subexpr", "mut", [["get", "showImportModal", ["loc", [null, [42, 65], [42, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 60], [42, 81]]], 0, 0], true], [], ["loc", [null, [42, 52], [42, 87]]], 0, 0]], [], ["loc", [null, [null, null], [42, 89]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["power_dialer.import_list"], [], ["loc", [null, [44, 16], [44, 48]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 10
                },
                "end": {
                  "line": 47,
                  "column": 10
                }
              },
              "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "isUcrEnabledOmniUser", ["loc", [null, [34, 18], [34, 38]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [34, 12], [46, 19]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 8
              },
              "end": {
                "line": 49,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "data-toggle", "tooltip");
            dom.setAttribute(el1, "data-placement", "top");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [2]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createAttrMorph(element10, 'data-original-title');
            morphs[2] = dom.createMorphAt(element10, 1, 1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "unless", [["get", "currentAgent.isBusy", ["loc", [null, [17, 20], [17, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 10], [21, 21]]]], ["attribute", "data-original-title", ["subexpr", "if", [["get", "importInProgress", ["loc", [null, [25, 37], [25, 53]]], 0, 0, 0, 0], ["subexpr", "t", ["power_dialer.import_in_progress"], [], ["loc", [null, [25, 54], [25, 91]]], 0, 0]], [], ["loc", [null, [null, null], [25, 93]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "hasCallerPagesInCRM", ["loc", [null, [27, 16], [27, 35]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [27, 10], [47, 17]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 6
            },
            "end": {
              "line": 51,
              "column": 6
            }
          },
          "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "power-dialer-options");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "isAgentInPowerDialerMode", ["loc", [null, [13, 14], [13, 38]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [13, 8], [49, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 4
            },
            "end": {
              "line": 55,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "promotion-content");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 59,
                    "column": 10
                  },
                  "end": {
                    "line": 66,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "input", [], ["type", "checkbox", "class", "todo-task-done", "checked", ["subexpr", "@mut", [["get", "isAllContactsSelected", ["loc", [null, [63, 22], [63, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "change", ["subexpr", "action", ["selectAllContactsHandler"], [], ["loc", [null, [64, 21], [64, 56]]], 0, 0]], ["loc", [null, [60, 12], [65, 14]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 68,
                      "column": 12
                    },
                    "end": {
                      "line": 72,
                      "column": 12
                    }
                  },
                  "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1, "class", "btn btn-cancel");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element5, 'onClick');
                  morphs[1] = dom.createMorphAt(element5, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "onClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDeleteModal", ["loc", [null, [69, 67], [69, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [69, 62], [69, 83]]], 0, 0], true], [], ["loc", [null, [null, null], [69, 90]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "if", [["get", "isAllContactsSelected", ["loc", [null, [70, 24], [70, 45]]], 0, 0, 0, 0], "power_dialer.delete_all_selected", "power_dialer.delete_selected_count"], [], ["loc", [null, [70, 20], [70, 118]]], 0, 0]], ["count", ["subexpr", "@mut", [["get", "selectedContacts.length", ["loc", [null, [70, 125], [70, 148]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [70, 16], [70, 150]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 67,
                    "column": 10
                  },
                  "end": {
                    "line": 73,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "ui-list-header-item", [], ["class", "pd-l-55 list-header-element"], 0, null, ["loc", [null, [68, 12], [72, 36]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 74,
                    "column": 10
                  },
                  "end": {
                    "line": 80,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
                morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
                return morphs;
              },
              statements: [["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["power_dialer.customer_name"], [], ["loc", [null, [75, 40], [75, 72]]], 0, 0], "class", "pd-l-55 list-header-element contact-name"], ["loc", [null, [75, 12], [75, 123]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["power_dialer.phone_number"], [], ["loc", [null, [76, 40], [76, 71]]], 0, 0], "class", "pd-l-55 list-header-element contact-phone"], ["loc", [null, [76, 12], [76, 123]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["power_dialer.email_id"], [], ["loc", [null, [77, 40], [77, 67]]], 0, 0], "class", "pd-l-55 list-header-element contact-email"], ["loc", [null, [77, 12], [77, 119]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["power_dialer.queued_on"], [], ["loc", [null, [78, 40], [78, 68]]], 0, 0], "class", "pd-l-55 list-header-element contact-queued-on"], ["loc", [null, [78, 12], [78, 124]]], 0, 0], ["inline", "ui-list-header-item", [], ["class", "pd-l-55 list-header-element item-center action"], ["loc", [null, [79, 12], [79, 90]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 8
                },
                "end": {
                  "line": 81,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "ui-list-header-item", [], ["class", "pd-l-55 list-header-element select"], 0, null, ["loc", [null, [59, 10], [66, 34]]]], ["block", "if", [["get", "showShowDeleteButton", ["loc", [null, [67, 16], [67, 36]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [67, 10], [73, 17]]]], ["block", "unless", [["get", "showShowDeleteButton", ["loc", [null, [74, 20], [74, 40]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [74, 10], [80, 21]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 89,
                    "column": 10
                  },
                  "end": {
                    "line": 101,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                dom.setAttribute(el1, "class", "record");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("hr");
                dom.setAttribute(el1, "class", "form-border");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "power-dialer/contact", [], ["contact", ["subexpr", "@mut", [["get", "powerDialerContact.contact", ["loc", [null, [92, 24], [92, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "powerDialerContact", ["subexpr", "@mut", [["get", "powerDialerContact", ["loc", [null, [93, 35], [93, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedContacts", ["subexpr", "@mut", [["get", "selectedContacts", ["loc", [null, [94, 33], [94, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "isAllContactsSelected", ["subexpr", "@mut", [["get", "isAllContactsSelected", ["loc", [null, [95, 38], [95, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "showDeleteModal", ["subexpr", "@mut", [["get", "showDeleteModal", ["loc", [null, [96, 32], [96, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["selectContactHandler"], [], ["loc", [null, [97, 25], [97, 56]]], 0, 0]], ["loc", [null, [91, 14], [98, 16]]], 0, 0]],
              locals: ["powerDialerContact"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 8
                },
                "end": {
                  "line": 102,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "powerDialerContacts", ["loc", [null, [89, 18], [89, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [89, 10], [101, 19]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 6
              },
              "end": {
                "line": 103,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ui-list-header", [], ["class", ["subexpr", "concat", ["list-header", " ", ["subexpr", "if", [["get", "showShowDeleteButton", [], 0, 0, 0, 0], "p-0"], [], [], 0, 0], " "], [], [], 0, 0]], 0, null, ["loc", [null, [58, 8], [81, 27]]]], ["block", "custom-elements/lazy-load", [], ["tagName", "tbody", "class", "list-body", "model", "power-dialer-list", "queryParams", ["subexpr", "@mut", [["get", "queryParams", ["loc", [null, [86, 22], [86, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "records", ["subexpr", "@mut", [["get", "powerDialerContacts", ["loc", [null, [87, 18], [87, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [82, 8], [102, 38]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 4
            },
            "end": {
              "line": 104,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ui-list", [], ["class", "list-container table-hover has-stretchable-rows"], 0, null, ["loc", [null, [57, 6], [103, 18]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 118,
                    "column": 12
                  },
                  "end": {
                    "line": 124,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-primary goto_contacts");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element2, 'onclick');
                morphs[1] = dom.createMorphAt(element2, 1, 1);
                return morphs;
              },
              statements: [["attribute", "onclick", ["subexpr", "action", ["redirectToContacts"], [], ["loc", [null, [null, null], [121, 55]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["power_dialer.go_to_contacts"], [], ["loc", [null, [122, 16], [122, 51]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 125,
                      "column": 14
                    },
                    "end": {
                      "line": 129,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "target", "_blank");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element1, 'class');
                  morphs[1] = dom.createAttrMorph(element1, 'href');
                  morphs[2] = dom.createMorphAt(element1, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["btn btn-primary ", ["subexpr", "if", [["get", "importInProgress", ["loc", [null, [126, 49], [126, 65]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [126, 44], [126, 78]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "href", ["get", "contactUrlForOmniUser", ["loc", [null, [126, 103], [126, 124]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["power_dialer.import_contacts_title"], [], ["loc", [null, [127, 20], [127, 62]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 129,
                      "column": 14
                    },
                    "end": {
                      "line": 135,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element0, 'class');
                  morphs[1] = dom.createAttrMorph(element0, 'onclick');
                  morphs[2] = dom.createMorphAt(element0, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["btn btn-primary ", ["subexpr", "if", [["get", "importInProgress", ["loc", [null, [131, 46], [131, 62]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [131, 41], [131, 75]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "if", [["subexpr", "not", [["get", "importInProgress", ["loc", [null, [132, 36], [132, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [132, 31], [132, 53]]], 0, 0], ["subexpr", "action", [["subexpr", "mut", [["get", "showImportModal", ["loc", [null, [132, 67], [132, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [132, 62], [132, 83]]], 0, 0], true], [], ["loc", [null, [132, 54], [132, 89]]], 0, 0]], [], ["loc", [null, [null, null], [132, 91]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["power_dialer.import_contacts_title"], [], ["loc", [null, [133, 18], [133, 60]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 124,
                    "column": 12
                  },
                  "end": {
                    "line": 136,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "isUcrEnabledOmniUser", ["loc", [null, [125, 20], [125, 40]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [125, 14], [135, 21]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 107,
                  "column": 8
                },
                "end": {
                  "line": 139,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "action-container");
              dom.setAttribute(el1, "data-toggle", "tooltip");
              dom.setAttribute(el1, "data-placement", "top");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "learn-more");
              dom.setAttribute(el2, "rel", "noopener");
              dom.setAttribute(el2, "target", "_blank");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var element4 = dom.childAt(element3, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element3, 'data-original-title');
              morphs[1] = dom.createMorphAt(element3, 1, 1);
              morphs[2] = dom.createAttrMorph(element4, 'href');
              morphs[3] = dom.createMorphAt(element4, 0, 0);
              return morphs;
            },
            statements: [["attribute", "data-original-title", ["subexpr", "if", [["get", "importInProgress", ["loc", [null, [117, 39], [117, 55]]], 0, 0, 0, 0], ["subexpr", "t", ["power_dialer.import_in_progress"], [], ["loc", [null, [117, 56], [117, 93]]], 0, 0]], [], ["loc", [null, [null, null], [117, 95]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "hasCallerPagesInCRM", ["loc", [null, [118, 18], [118, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [118, 12], [136, 19]]]], ["attribute", "href", ["subexpr", "support-portal-link", ["usingPowerDialer"], [], ["loc", [null, [null, null], [137, 112]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["power_dialer.learn_more"], [], ["loc", [null, [137, 113], [137, 144]]], 0, 0]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 106,
                "column": 6
              },
              "end": {
                "line": 140,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "empty-state-cover", [], ["title", ["subexpr", "t", ["power_dialer.create_list"], [], ["loc", [null, [108, 16], [108, 46]]], 0, 0], "imgSrc", "assets/images/empty-states/power-dialer.svg", "description", ["subexpr", "t", ["power_dialer.create_list_desc"], [], ["loc", [null, [110, 22], [110, 57]]], 0, 0], "containerClass", "flex-row"], 0, null, ["loc", [null, [107, 8], [139, 30]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 104,
              "column": 4
            },
            "end": {
              "line": 141,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "promoting", ["loc", [null, [106, 16], [106, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [106, 6], [140, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 147,
                  "column": 4
                },
                "end": {
                  "line": 161,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "confirm-delete-modal", [], ["modelItem", ["subexpr", "@mut", [["get", "deleteItem", ["loc", [null, [155, 18], [155, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", ["closeCallback"], [], ["loc", [null, [156, 14], [156, 38]]], 0, 0], "delete", ["subexpr", "action", ["deleteContacts"], [], ["loc", [null, [157, 15], [157, 40]]], 0, 0], "deleteTitle", ["subexpr", "t", ["power_dialer.delete_selected"], [], ["loc", [null, [158, 20], [158, 54]]], 0, 0], "deleteMessage", ["subexpr", "t", ["power_dialer.delete_selected_content"], [], ["loc", [null, [159, 22], [159, 64]]], 0, 0]], ["loc", [null, [154, 6], [160, 8]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 146,
                "column": 2
              },
              "end": {
                "line": 162,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ui-modal", [], ["class", "delete-agent-wrapper", "size", "sm", "closeOnEscape", false, "closeOnBackdropClick", false, "closeCallBack", ["subexpr", "action", [["subexpr", "mut", [["get", "showDeleteModal", ["loc", [null, [152, 33], [152, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [152, 28], [152, 49]]], 0, 0], false], [], ["loc", [null, [152, 20], [152, 56]]], 0, 0]], 0, null, ["loc", [null, [147, 4], [161, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 145,
              "column": 0
            },
            "end": {
              "line": 163,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ember-wormhole", [], ["to", "modal-wormhole"], 0, null, ["loc", [null, [146, 2], [162, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 164,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/power-dialer/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "power-dialer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "power-dialer-main");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "power-dialer-header");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "page-title");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    \n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [1, 1]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element13, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element14, 1, 1);
        morphs[1] = dom.createMorphAt(element14, 3, 3);
        morphs[2] = dom.createMorphAt(element13, 3, 3);
        morphs[3] = dom.createMorphAt(element12, 3, 3);
        morphs[4] = dom.createMorphAt(element12, 4, 4);
        morphs[5] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["power_dialer.list"], [], ["loc", [null, [6, 8], [6, 33]]], 0, 0], ["block", "if", [["get", "totalContactsCount", ["loc", [null, [7, 14], [7, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 8], [9, 15]]]], ["block", "if", [["subexpr", "and", [["get", "totalContactsCount", ["loc", [null, [11, 17], [11, 35]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "promoting", ["loc", [null, [11, 41], [11, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 36], [11, 51]]], 0, 0]], [], ["loc", [null, [11, 12], [11, 52]]], 0, 0]], [], 1, null, ["loc", [null, [11, 6], [51, 13]]]], ["block", "if", [["get", "promoting", ["loc", [null, [53, 10], [53, 19]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [53, 4], [55, 11]]]], ["block", "if", [["get", "totalContactsCount", ["loc", [null, [56, 10], [56, 28]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [56, 4], [141, 11]]]], ["block", "if", [["get", "showDeleteModal", ["loc", [null, [145, 6], [145, 21]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [145, 0], [163, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});