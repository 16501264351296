define('freshfone-ember/components/call-logs/call-transcript/component', ['exports', 'ember-data', 'ember', 'freshfone-ember/constants/avatar-size'], function (exports, _emberData, _ember, _freshfoneEmberConstantsAvatarSize) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var PromiseArray = _emberData['default'].PromiseArray;
  exports['default'] = Component.extend({
    callTranscripts: computed('call', function () {
      return PromiseArray.create({
        promise: this.get('call').getCallTranscript().then(function (result) {
          return result.data;
        })
      });
    }),
    size: computed(function () {
      return this.get('fromIncoversation') ? _freshfoneEmberConstantsAvatarSize['default'].inconversation : _freshfoneEmberConstantsAvatarSize['default']['default'];
    })
  });
});