define("freshfone-ember/components/ongoing-widget/transfer/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 4
                },
                "end": {
                  "line": 7,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon-call-transfer");
              dom.setAttribute(el1, "data-toggle", "tooltip");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element22 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element22, 'title');
              return morphs;
            },
            statements: [["attribute", "title", ["subexpr", "t", ["maximizedWidget.transfer_call"], [], ["loc", [null, [null, null], [6, 101]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 4
                },
                "end": {
                  "line": 9,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon-call-transfer");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 11,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasCallActionsTooltipFeature", ["loc", [null, [5, 10], [5, 38]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [5, 4], [9, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 27,
                      "column": 10
                    },
                    "end": {
                      "line": 29,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "agent-loading");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "t", ["transfer.invalid_number"], [], ["loc", [null, [28, 38], [28, 69]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 29,
                        "column": 10
                      },
                      "end": {
                        "line": 31,
                        "column": 10
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    dom.setAttribute(el1, "class", "agent-loading error");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "errorMessage", ["loc", [null, [30, 44], [30, 60]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 35,
                          "column": 16
                        },
                        "end": {
                          "line": 37,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "hold-loader");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 41,
                            "column": 18
                          },
                          "end": {
                            "line": 45,
                            "column": 18
                          }
                        },
                        "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("a");
                        dom.setAttribute(el1, "class", "transfer-agent");
                        dom.setAttribute(el1, "data-toggle", "tooltip");
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("i");
                        dom.setAttribute(el2, "class", "icon-warm-transfer trigger-list-close");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element16 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createAttrMorph(element16, 'title');
                        morphs[1] = dom.createElementMorph(element16);
                        return morphs;
                      },
                      statements: [["attribute", "title", ["subexpr", "t", ["transfer.warmTransfer"], [], ["loc", [null, [null, null], [42, 131]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["transferExternal", "WARM", ["get", "searchString", ["loc", [null, [42, 58], [42, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 23], [42, 72]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 37,
                          "column": 16
                        },
                        "end": {
                          "line": 46,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1, "class", "transfer-agent");
                      dom.setAttribute(el1, "data-toggle", "tooltip");
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("i");
                      dom.setAttribute(el2, "class", "icon-call-transfer trigger-list-close");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element17 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createAttrMorph(element17, 'title');
                      morphs[1] = dom.createElementMorph(element17);
                      morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["attribute", "title", ["subexpr", "t", ["transfer.transfer_tooltip"], [], ["loc", [null, [null, null], [38, 134]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["transferExternal", "BLIND", ["get", "searchString", ["loc", [null, [38, 57], [38, 69]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 21], [38, 71]]], 0, 0], ["block", "if", [["get", "warmTransferEnabled", ["loc", [null, [41, 24], [41, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [41, 18], [45, 25]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 31,
                        "column": 10
                      },
                      "end": {
                        "line": 49,
                        "column": 10
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    dom.setAttribute(el1, "class", "trigger-list");
                    var el2 = dom.createTextNode("\n              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "external-number-detail");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "transfer-controls trigger-list-close");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("              ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n          ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element18 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(dom.childAt(element18, [1]), 0, 0);
                    morphs[1] = dom.createMorphAt(dom.childAt(element18, [3]), 1, 1);
                    return morphs;
                  },
                  statements: [["content", "searchString", ["loc", [null, [33, 50], [33, 66]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "or", [["get", "validatingNumberForBlindTransfer", ["loc", [null, [35, 26], [35, 58]]], 0, 0, 0, 0], ["get", "validatingNumberForWarmTransfer", ["loc", [null, [35, 59], [35, 90]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 22], [35, 91]]], 0, 0]], [], 0, 1, ["loc", [null, [35, 16], [46, 23]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 29,
                      "column": 10
                    },
                    "end": {
                      "line": 49,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "hasErrorMessage", ["loc", [null, [29, 20], [29, 35]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [29, 10], [49, 10]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 8
                  },
                  "end": {
                    "line": 50,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "isNumberInvalid", ["loc", [null, [27, 16], [27, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [27, 10], [49, 17]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 51,
                      "column": 10
                    },
                    "end": {
                      "line": 53,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "agent-loading");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "t", ["transfer.no_agents_in_list"], [], ["loc", [null, [52, 38], [52, 72]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    var child0 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 57,
                              "column": 18
                            },
                            "end": {
                              "line": 69,
                              "column": 18
                            }
                          },
                          "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1, "class", "agent-detail ellipsis team-name");
                          var el2 = dom.createTextNode("\n                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("i");
                          dom.setAttribute(el2, "class", "icon-group");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("span");
                          dom.setAttribute(el2, "class", "team-name");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("span");
                          dom.setAttribute(el2, "class", "agents-count");
                          var el3 = dom.createTextNode("(");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode(")");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                    ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1, "class", "transfer-controls trigger-list-close");
                          var el2 = dom.createTextNode("\n                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("a");
                          dom.setAttribute(el2, "href", "#");
                          dom.setAttribute(el2, "class", "transfer-agent");
                          dom.setAttribute(el2, "data-toggle", "tooltip");
                          var el3 = dom.createTextNode("\n                        ");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createElement("i");
                          dom.setAttribute(el3, "class", "icon-call-transfer");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                      ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                    ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("span");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element12 = dom.childAt(fragment, [1]);
                          var element13 = dom.childAt(fragment, [3]);
                          var element14 = dom.childAt(element13, [1]);
                          var element15 = dom.childAt(fragment, [5]);
                          var morphs = new Array(5);
                          morphs[0] = dom.createMorphAt(dom.childAt(element12, [3]), 0, 0);
                          morphs[1] = dom.createMorphAt(dom.childAt(element12, [5]), 1, 1);
                          morphs[2] = dom.createElementMorph(element13);
                          morphs[3] = dom.createAttrMorph(element14, 'title');
                          morphs[4] = dom.createAttrMorph(element15, 'class');
                          return morphs;
                        },
                        statements: [["content", "team.name", ["loc", [null, [60, 46], [60, 59]]], 0, 0, 0, 0], ["content", "team.agents.length", ["loc", [null, [61, 50], [61, 72]]], 0, 0, 0, 0], ["element", "action", ["teamTransfer", ["get", "team", ["loc", [null, [63, 94], [63, 98]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 70], [63, 100]]], 0, 0], ["attribute", "title", ["subexpr", "t", ["transfer.transfer_tooltip"], [], ["loc", [null, [null, null], [64, 118]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["icon-dropdown_arrow ", ["subexpr", "if", [["get", "teamDropdownExpanded", ["loc", [null, [68, 58], [68, 78]]], 0, 0, 0, 0], "dropdown-expanded"], [], ["loc", [null, [68, 53], [68, 100]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    var child1 = (function () {
                      var child0 = (function () {
                        var child0 = (function () {
                          return {
                            meta: {
                              "revision": "Ember@2.8.3",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 81,
                                  "column": 26
                                },
                                "end": {
                                  "line": 85,
                                  "column": 26
                                }
                              },
                              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                            },
                            isEmpty: false,
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                            ");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createElement("a");
                              dom.setAttribute(el1, "href", "#");
                              dom.setAttribute(el1, "class", "transfer-agent");
                              dom.setAttribute(el1, "data-toggle", "tooltip");
                              dom.setAttribute(el1, "data-placement", "top");
                              var el2 = dom.createTextNode("\n                              ");
                              dom.appendChild(el1, el2);
                              var el2 = dom.createElement("i");
                              dom.setAttribute(el2, "class", "icon-warm-transfer trigger-list-close");
                              dom.appendChild(el1, el2);
                              var el2 = dom.createTextNode("\n                            ");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode("\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var element7 = dom.childAt(fragment, [1]);
                              var morphs = new Array(2);
                              morphs[0] = dom.createAttrMorph(element7, 'data-original-title');
                              morphs[1] = dom.createElementMorph(element7);
                              return morphs;
                            },
                            statements: [["attribute", "data-original-title", ["subexpr", "t", ["transfer.warmTransfer"], [], ["loc", [null, [null, null], [82, 155]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["warmTransfer", ["get", "agent", ["loc", [null, [82, 180], [82, 185]]], 0, 0, 0, 0]], [], ["loc", [null, [82, 156], [82, 187]]], 0, 0]],
                            locals: [],
                            templates: []
                          };
                        })();
                        var child1 = (function () {
                          return {
                            meta: {
                              "revision": "Ember@2.8.3",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 86,
                                  "column": 26
                                },
                                "end": {
                                  "line": 90,
                                  "column": 26
                                }
                              },
                              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                            },
                            isEmpty: false,
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("                            ");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createElement("a");
                              dom.setAttribute(el1, "href", "#");
                              dom.setAttribute(el1, "class", "transfer-agent");
                              dom.setAttribute(el1, "data-toggle", "tooltip");
                              dom.setAttribute(el1, "data-placement", "top");
                              var el2 = dom.createTextNode("\n                              ");
                              dom.appendChild(el1, el2);
                              var el2 = dom.createElement("i");
                              dom.setAttribute(el2, "class", "icon-add-agent trigger-list-close");
                              dom.appendChild(el1, el2);
                              var el2 = dom.createTextNode("\n                            ");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode("\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var element6 = dom.childAt(fragment, [1]);
                              var morphs = new Array(2);
                              morphs[0] = dom.createAttrMorph(element6, 'data-original-title');
                              morphs[1] = dom.createElementMorph(element6);
                              return morphs;
                            },
                            statements: [["attribute", "data-original-title", ["subexpr", "t", ["agent_conference.agent_conference_tooltip"], [], ["loc", [null, [null, null], [87, 175]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["addAgent", ["get", "agent", ["loc", [null, [87, 196], [87, 201]]], 0, 0, 0, 0]], [], ["loc", [null, [87, 176], [87, 203]]], 0, 0]],
                            locals: [],
                            templates: []
                          };
                        })();
                        return {
                          meta: {
                            "revision": "Ember@2.8.3",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 71,
                                "column": 20
                              },
                              "end": {
                                "line": 93,
                                "column": 20
                              }
                            },
                            "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                          },
                          isEmpty: false,
                          arity: 1,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                      ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createElement("li");
                            dom.setAttribute(el1, "class", "agent");
                            var el2 = dom.createTextNode("\n                        ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2, "class", "agent-detail ellipsis");
                            var el3 = dom.createTextNode("\n                          ");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                          ");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n                        ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                        ");
                            dom.appendChild(el1, el2);
                            var el2 = dom.createElement("div");
                            dom.setAttribute(el2, "class", "transfer-controls trigger-list-close");
                            var el3 = dom.createTextNode("\n                          ");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createElement("a");
                            dom.setAttribute(el3, "href", "#");
                            dom.setAttribute(el3, "class", "transfer-agent");
                            dom.setAttribute(el3, "data-toggle", "tooltip");
                            dom.setAttribute(el3, "data-placement", "top");
                            var el4 = dom.createTextNode("\n                            ");
                            dom.appendChild(el3, el4);
                            var el4 = dom.createElement("i");
                            dom.setAttribute(el4, "class", "icon-call-transfer trigger-list-close");
                            dom.appendChild(el3, el4);
                            var el4 = dom.createTextNode("\n                          ");
                            dom.appendChild(el3, el4);
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("\n");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createComment("");
                            dom.appendChild(el2, el3);
                            var el3 = dom.createTextNode("                        ");
                            dom.appendChild(el2, el3);
                            dom.appendChild(el1, el2);
                            var el2 = dom.createTextNode("\n                      ");
                            dom.appendChild(el1, el2);
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var element8 = dom.childAt(fragment, [1]);
                            var element9 = dom.childAt(element8, [1]);
                            var element10 = dom.childAt(element8, [3]);
                            var element11 = dom.childAt(element10, [1]);
                            var morphs = new Array(6);
                            morphs[0] = dom.createMorphAt(element9, 1, 1);
                            morphs[1] = dom.createMorphAt(element9, 3, 3);
                            morphs[2] = dom.createAttrMorph(element11, 'data-original-title');
                            morphs[3] = dom.createElementMorph(element11);
                            morphs[4] = dom.createMorphAt(element10, 3, 3);
                            morphs[5] = dom.createMorphAt(element10, 4, 4);
                            return morphs;
                          },
                          statements: [["inline", "custom-elements/avatar", [], ["type", "user", "text", ["subexpr", "@mut", [["get", "agent.name", ["loc", [null, [74, 68], [74, 78]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [74, 26], [74, 90]]], 0, 0], ["content", "agent.name", ["loc", [null, [75, 26], [75, 40]]], 0, 0, 0, 0], ["attribute", "data-original-title", ["subexpr", "t", ["transfer.transfer_tooltip"], [], ["loc", [null, [null, null], [78, 157]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["agentTransfer", ["get", "agent", ["loc", [null, [78, 183], [78, 188]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 158], [78, 190]]], 0, 0], ["block", "if", [["get", "warmTransferEnabled", ["loc", [null, [81, 32], [81, 51]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [81, 26], [85, 33]]]], ["block", "if", [["get", "showAgentConferenceButton", ["loc", [null, [86, 32], [86, 57]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [86, 26], [90, 33]]]]],
                          locals: ["agent"],
                          templates: [child0, child1]
                        };
                      })();
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 70,
                              "column": 18
                            },
                            "end": {
                              "line": 94,
                              "column": 18
                            }
                          },
                          "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [["block", "each", [["get", "filteredAgentsWithinSelectedTeam", ["loc", [null, [71, 28], [71, 60]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [71, 20], [93, 29]]]]],
                        locals: [],
                        templates: [child0]
                      };
                    })();
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 56,
                            "column": 16
                          },
                          "end": {
                            "line": 95,
                            "column": 16
                          }
                        },
                        "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                      },
                      isEmpty: false,
                      arity: 1,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [["block", "rl-dropdown-toggle", [], ["tagName", "div", "class", "team-list-header cursor-pointer"], 0, null, ["loc", [null, [57, 18], [69, 41]]]], ["block", "rl-dropdown", [], ["tagName", "ul", "class", "agents-within-team"], 1, null, ["loc", [null, [70, 18], [94, 34]]]]],
                      locals: ["teamDropdownExpanded"],
                      templates: [child0, child1]
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 55,
                          "column": 14
                        },
                        "end": {
                          "line": 96,
                          "column": 14
                        }
                      },
                      "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                    },
                    isEmpty: false,
                    arity: 1,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "rl-dropdown-container", [], ["tagName", "li", "class", "trigger-list team agent", "onOpen", ["subexpr", "action", ["setCurrentTeam", ["get", "team", ["loc", [null, [56, 118], [56, 122]]], 0, 0, 0, 0]], [], ["loc", [null, [56, 93], [56, 123]]], 0, 0]], 0, null, ["loc", [null, [56, 16], [95, 42]]]]],
                    locals: ["team"],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 54,
                        "column": 12
                      },
                      "end": {
                        "line": 97,
                        "column": 12
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "each", [["get", "filteredTeams", ["loc", [null, [55, 22], [55, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [55, 14], [96, 23]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 104,
                          "column": 16
                        },
                        "end": {
                          "line": 106,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "ongoing-widget/transfer/transfer-options", [], ["options", ["subexpr", "@mut", [["get", "transferOptions", ["loc", [null, [105, 69], [105, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "optionSelected", ["subexpr", "action", ["optionSelectedCallback"], [], ["loc", [null, [105, 100], [105, 133]]], 0, 0], "agent", ["subexpr", "@mut", [["get", "agent", ["loc", [null, [105, 140], [105, 145]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [105, 18], [105, 148]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 111,
                            "column": 20
                          },
                          "end": {
                            "line": 115,
                            "column": 20
                          }
                        },
                        "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                      ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("a");
                        dom.setAttribute(el1, "href", "#");
                        dom.setAttribute(el1, "class", "transfer-agent");
                        dom.setAttribute(el1, "data-toggle", "tooltip");
                        dom.setAttribute(el1, "data-placement", "top");
                        var el2 = dom.createTextNode("\n                        ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("i");
                        dom.setAttribute(el2, "class", "icon-warm-transfer trigger-list-close");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element1 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createAttrMorph(element1, 'data-original-title');
                        morphs[1] = dom.createElementMorph(element1);
                        return morphs;
                      },
                      statements: [["attribute", "data-original-title", ["subexpr", "t", ["transfer.warmTransfer"], [], ["loc", [null, [null, null], [112, 149]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["warmTransfer", ["get", "agent", ["loc", [null, [112, 174], [112, 179]]], 0, 0, 0, 0]], [], ["loc", [null, [112, 150], [112, 181]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 116,
                            "column": 20
                          },
                          "end": {
                            "line": 120,
                            "column": 20
                          }
                        },
                        "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                      ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("a");
                        dom.setAttribute(el1, "href", "#");
                        dom.setAttribute(el1, "class", "transfer-agent");
                        dom.setAttribute(el1, "data-toggle", "tooltip");
                        dom.setAttribute(el1, "data-placement", "top");
                        var el2 = dom.createTextNode("\n                        ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("i");
                        dom.setAttribute(el2, "class", "icon-add-agent trigger-list-close");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element0 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createAttrMorph(element0, 'data-original-title');
                        morphs[1] = dom.createElementMorph(element0);
                        return morphs;
                      },
                      statements: [["attribute", "data-original-title", ["subexpr", "t", ["agent_conference.agent_conference_tooltip"], [], ["loc", [null, [null, null], [117, 169]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["addAgent", ["get", "agent", ["loc", [null, [117, 190], [117, 195]]], 0, 0, 0, 0]], [], ["loc", [null, [117, 170], [117, 197]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 106,
                          "column": 16
                        },
                        "end": {
                          "line": 122,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "transfer-controls");
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("a");
                      dom.setAttribute(el2, "href", "#");
                      dom.setAttribute(el2, "class", "transfer-agent");
                      dom.setAttribute(el2, "data-toggle", "tooltip");
                      dom.setAttribute(el2, "data-placement", "top");
                      var el3 = dom.createTextNode("\n                      ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("i");
                      dom.setAttribute(el3, "class", "icon-call-transfer trigger-list-close");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                    ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("                  ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element2 = dom.childAt(fragment, [1]);
                      var element3 = dom.childAt(element2, [1]);
                      var morphs = new Array(4);
                      morphs[0] = dom.createAttrMorph(element3, 'data-original-title');
                      morphs[1] = dom.createElementMorph(element3);
                      morphs[2] = dom.createMorphAt(element2, 3, 3);
                      morphs[3] = dom.createMorphAt(element2, 4, 4);
                      return morphs;
                    },
                    statements: [["attribute", "data-original-title", ["subexpr", "t", ["transfer.transfer_tooltip"], [], ["loc", [null, [null, null], [108, 151]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["agentTransfer", ["get", "agent", ["loc", [null, [108, 177], [108, 182]]], 0, 0, 0, 0]], [], ["loc", [null, [108, 152], [108, 184]]], 0, 0], ["block", "if", [["get", "warmTransferEnabled", ["loc", [null, [111, 26], [111, 45]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [111, 20], [115, 27]]]], ["block", "if", [["get", "showAgentConferenceButton", ["loc", [null, [116, 26], [116, 51]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [116, 20], [120, 27]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 98,
                        "column": 12
                      },
                      "end": {
                        "line": 124,
                        "column": 12
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    dom.setAttribute(el1, "class", "agent");
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "agent-detail ellipsis");
                    var el3 = dom.createTextNode("\n                  ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                  ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var element5 = dom.childAt(element4, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createMorphAt(element5, 1, 1);
                    morphs[1] = dom.createMorphAt(element5, 3, 3);
                    morphs[2] = dom.createMorphAt(element4, 3, 3);
                    return morphs;
                  },
                  statements: [["inline", "custom-elements/avatar", [], ["type", "user", "text", ["subexpr", "@mut", [["get", "agent.name", ["loc", [null, [101, 60], [101, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [101, 18], [101, 82]]], 0, 0], ["content", "agent.name", ["loc", [null, [102, 18], [102, 32]]], 0, 0, 0, 0], ["block", "if", [["get", "parallelCallEnabled", ["loc", [null, [104, 22], [104, 41]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [104, 16], [122, 23]]]]],
                  locals: ["agent"],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 53,
                      "column": 10
                    },
                    "end": {
                      "line": 125,
                      "column": 10
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "hasTeamFeature", ["loc", [null, [54, 18], [54, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [54, 12], [97, 19]]]], ["block", "each", [["get", "filteredAgents", ["loc", [null, [98, 20], [98, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [98, 12], [124, 21]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 50,
                    "column": 8
                  },
                  "end": {
                    "line": 126,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "hasEmptyList", ["loc", [null, [51, 16], [51, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [51, 10], [125, 17]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 128,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "search-container");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "search-title");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "pull-right icon-close");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "search-list");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "icon-search");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "transfer-list");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element19 = dom.childAt(fragment, [1]);
              var element20 = dom.childAt(element19, [1]);
              var element21 = dom.childAt(element20, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element20, 1, 1);
              morphs[1] = dom.createElementMorph(element21);
              morphs[2] = dom.createMorphAt(dom.childAt(element19, [3]), 3, 3);
              morphs[3] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["transfer.title"], [], ["loc", [null, [17, 10], [17, 32]]], 0, 0], ["element", "action", ["closeTransfer"], [], ["loc", [null, [18, 43], [18, 69]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "searchString", ["loc", [null, [22, 24], [22, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "search-string", "placeholder", ["subexpr", "@mut", [["get", "searchPlaceholder", ["loc", [null, [22, 71], [22, 88]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [22, 10], [22, 90]]], 0, 0], ["block", "if", [["get", "isExternalTransferAllowed", ["loc", [null, [26, 14], [26, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [26, 8], [126, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 128,
                  "column": 4
                },
                "end": {
                  "line": 132,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "agent-loading");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["transfer.loading"], [], ["loc", [null, [130, 8], [130, 32]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 2
              },
              "end": {
                "line": 134,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "caret");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "isAgentsLoaded", ["loc", [null, [14, 10], [14, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [14, 4], [132, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 135,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "rl-dropdown-toggle", [], ["tagName", "button", "class", ["subexpr", "concat", [["subexpr", "if", [["get", "disabled", ["loc", [null, [4, 59], [4, 67]]], 0, 0, 0, 0], "none-pointer transferdisabled "], [], ["loc", [null, [4, 55], [4, 101]]], 0, 0], "transfer-button dropdown-toggle call-control-button"], [], ["loc", [null, [4, 47], [4, 156]]], 0, 0]], 0, null, ["loc", [null, [4, 2], [11, 25]]]], ["block", "rl-dropdown", [], ["tagName", "div", "class", "transfer-list-container dropdown-menu", "closeOnChildClick", ".transfer-controls.trigger-list-close"], 1, null, ["loc", [null, [13, 2], [134, 18]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 136,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/ongoing-widget/transfer/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "rl-dropdown-container", [], ["class", ["subexpr", "concat", [["subexpr", "if", [["get", "disabled", ["loc", [null, [2, 20], [2, 28]]], 0, 0, 0, 0], "not-allowed transferdisabled "], [], ["loc", [null, [2, 16], [2, 61]]], 0, 0], "dropdown transfer-container"], [], ["loc", [null, [2, 8], [2, 92]]], 0, 0], "dropdownExpanded", ["subexpr", "@mut", [["get", "dropdownExpanded", ["loc", [null, [3, 19], [3, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [135, 26]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});