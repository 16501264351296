define('freshfone-ember/components/call-queue/route-types/extnumber-forward-request/component', ['exports', 'ember'], function (exports, _ember) {
	var service = _ember['default'].inject.service;
	exports['default'] = _ember['default'].Component.extend({
		notifications: service('toastr'),
		alreadySubmitted: false,
		actions: {
			close: function close() {
				this.sendAction('close');
			},
			submitRequest: function submitRequest() {
				this.get('notifications').success('Request has been submitted successfully');
			}
		}
	});
});