define('freshfone-ember/components/number-component/edit-form/rule-form/action-component/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/find-or-fetch-mixin', 'freshfone-ember/mixins/remove-feature-actiontype'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsFindOrFetchMixin, _freshfoneEmberMixinsRemoveFeatureActiontype) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFindOrFetchMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], {
    router: service('-routing'),
    modelLabel: computed.alias('selectedActionConfig.createLabel'),
    allowedOptionList: computed('optionList', 'actionFor', function () {
      var defaultActionTypes = [].concat(_toConsumableArray(this.get('optionList')));

      if (this.get('actionFor') === 'nbh' && !this.hasFeatures(['outside_business_hour_call_queue'])) {
        return defaultActionTypes.filterBy('isACallFlow', false);
      }

      return defaultActionTypes;
    }),
    selectedActionType: computed('actionType', {
      set: function set(key, value) {
        this.set('actionType', value.id);
        this.set('action', null);

        return value;
      },
      get: function get() {
        var actionType = this.get('actionType');

        return this.get('optionList').findBy('id', actionType);
      }
    }),
    selectedActionLabel: computed('actionType', 'selectedActionConfig', function () {
      if (this.get('selectedActionConfig.isACallFlow')) {
        return this.get('selectedActionConfig.label');
      }

      return _ember['default'].i18n.t('entityNames.message');
    }),

    selectedActionModelName: computed('action.id', 'selectedActionConfig', function () {
      if (this.get('selectedActionConfig.isACallFlow')) {
        return 'callFlow';
      }

      return 'message';
    }),
    callFlowOptionList: computed('selectedActionConfig', function () {
      var allCallFlows = this.store.peekAll('callFlow');

      return allCallFlows.filterBy('typeName', this.get('selectedActionConfig.abbr'));
    }),
    selectedAction: computed('action', 'actionType', {
      set: function set(key, value) {
        this.set('action', value && value.id);

        return value;
      },
      get: function get() {
        var actionID = this.get('action');

        return isEmpty(actionID) ? {} : this.store.peekRecord(this.get('selectedActionModelName'), actionID);
      }
    }),
    actions: {
      createNewAction: function createNewAction() {
        if (this.get('selectedActionConfig.isACallFlow')) {
          var createNewRoute = this.get('selectedActionConfig.route') + '.new';

          this.get('router').transitionTo(createNewRoute, [], {
            editNumber: this.get('rule.number.id'),
            actionFor: this.get('actionFor')
          });
        }
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */