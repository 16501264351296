define('freshfone-ember/mixins/get-selected-days-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    getSelectedDays: function getSelectedDays(workingHour, selectedTimeSlots) {
      var selectedDays = [];
      workingHour.forEach(function (workHourday) {
        if (JSON.stringify(workHourday.get('timeSlots')) == JSON.stringify(selectedTimeSlots)) selectedDays.push(workHourday);
      });
      return selectedDays;
    }
  });
});