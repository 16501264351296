define('freshfone-ember/mixins/validations/service-level', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
    exports['default'] = _ember['default'].Mixin.create(_emberValidations['default'], {
        validations: {
            'slConfig.ttaThreshold': {
                format: { presence: true, message: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.threshold.blank') },
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0,
                    lessThanOrEqualTo: 3600,
                    messages: {
                        onlyInteger: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.threshold.onlyInteger'),
                        numericality: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.threshold.onlyNumber'),
                        greaterThan: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.threshold.aboveZero'),
                        lessThanOrEqualTo: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.threshold.below36k')
                    }
                }
            },
            'slConfig.targetPercent': {
                format: { presence: true, message: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.targetPercent.blank') },
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0,
                    lessThanOrEqualTo: 100,
                    messages: {
                        onlyInteger: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.targetPercent.onlyInteger'),
                        numericality: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.targetPercent.onlyNumber'),
                        greaterThan: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.targetPercent.aboveZero'),
                        lessThanOrEqualTo: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.targetPercent.belowHundred')
                    }
                }
            },
            'slConfig.selectedQueue': {
                presence: {
                    message: _ember['default'].i18n.t('serviceLevel.uiValidationMessages.queue.blank'),
                    'if': function _if(component) {
                        return !component.get('slConfig.entityId');
                    }
                }
            }
        }
    });
});