define("freshfone-ember/components/call-tags/custom-tags/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 20
                },
                "end": {
                  "line": 31,
                  "column": 20
                }
              },
              "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["subexpr", "concat", ["icon-validation-alert"], [], ["loc", [null, [null, null], [30, 67]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 20
                },
                "end": {
                  "line": 37,
                  "column": 20
                }
              },
              "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "data-toggle", "tooltip");
              dom.setAttribute(el1, "data-placement", "top");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createAttrMorph(element0, 'title');
              return morphs;
            },
            statements: [["attribute", "class", ["subexpr", "concat", ["icon-validation-alert"], [], ["loc", [null, [null, null], [36, 67]]], 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "t", ["call_tags.custom_tags.max_char_limit_reached_Tooltip"], [], ["loc", [null, [null, null], [36, 177]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 8
              },
              "end": {
                "line": 41,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "validation-message");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "validation-message char-limit");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [3]);
            var element4 = dom.childAt(element2, [5]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(element3, 1, 1);
            morphs[3] = dom.createMorphAt(element3, 3, 3);
            morphs[4] = dom.createMorphAt(element4, 1, 1);
            morphs[5] = dom.createMorphAt(element4, 3, 3);
            morphs[6] = dom.createMorphAt(element4, 4, 4);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["new-tag ", ["subexpr", "if", [["get", "status", ["loc", [null, [18, 37], [18, 43]]], 0, 0, 0, 0], ["get", "status", ["loc", [null, [18, 44], [18, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 32], [18, 52]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "text-field", [], ["placeholder", ["subexpr", "t", ["call_tags.custom_tags.placeholder"], [], ["loc", [null, [20, 32], [20, 71]]], 0, 0], "value", ["subexpr", "@mut", [["get", "customTag", ["loc", [null, [21, 26], [21, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "for", "customTag", "id", "customTag", "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [24, 27], [24, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "autocomplete", "off", "keyDown", ["subexpr", "action", ["keyDown"], [], ["loc", [null, [26, 28], [26, 46]]], 0, 0]], ["loc", [null, [19, 16], [27, 18]]], 0, 0], ["block", "if", [["get", "validationMessage", ["loc", [null, [29, 26], [29, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [29, 20], [31, 27]]]], ["content", "validationMessage", ["loc", [null, [32, 20], [32, 41]]], 0, 0, 0, 0], ["block", "if", [["get", "maxCharLimitReached", ["loc", [null, [35, 26], [35, 45]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [35, 20], [37, 27]]]], ["inline", "if", [["get", "customTag", ["loc", [null, [38, 25], [38, 34]]], 0, 0, 0, 0], ["get", "availableLength", ["loc", [null, [38, 35], [38, 50]]], 0, 0, 0, 0], ["get", "maxCharacterLimit", ["loc", [null, [38, 51], [38, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 20], [38, 70]]], 0, 0], ["inline", "t", ["call_tags.custom_tags.characters"], [], ["loc", [null, [38, 70], [38, 110]]], 0, 0]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 8
              },
              "end": {
                "line": 43,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "max-limit-title");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["call_tags.custom_tags.max_limit_title"], ["maxLimit", ["subexpr", "@mut", [["get", "maxLimit", ["loc", [null, [42, 94], [42, 102]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [42, 41], [42, 104]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 8
              },
              "end": {
                "line": 48,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "loading-wrapper");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "spinner");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 8
                },
                "end": {
                  "line": 54,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "empty-state-cover", [], ["imgSrc", "assets/images/empty-states/no-result.svg", "description", ["subexpr", "t", ["call_tags.custom_tags.no_record"], [], ["loc", [null, [51, 26], [51, 63]]], 0, 0], "containerClass", "flex-col"], ["loc", [null, [49, 12], [53, 14]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 56,
                    "column": 16
                  },
                  "end": {
                    "line": 63,
                    "column": 16
                  }
                },
                "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "call-tags/custom-tags/custom-tag-row", [], ["tag", ["subexpr", "@mut", [["get", "tag", ["loc", [null, [58, 28], [58, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [59, 30], [59, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "onModify", ["subexpr", "action", ["onModify"], [], ["loc", [null, [60, 33], [60, 52]]], 0, 0], "openDeleteTagModal", ["subexpr", "action", ["deleteTag"], [], ["loc", [null, [61, 43], [61, 63]]], 0, 0]], ["loc", [null, [57, 20], [62, 22]]], 0, 0]],
              locals: ["tag"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 8
                },
                "end": {
                  "line": 65,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "tags-list");
              dom.setAttribute(el1, "id", "tags-list");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "customTags", ["loc", [null, [56, 24], [56, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [56, 16], [63, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 8
              },
              "end": {
                "line": 65,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["get", "hasEmptyList", ["loc", [null, [48, 23], [48, 35]]], 0, 0, 0, 0], ["get", "entitiesExistInTheFirstPlace", ["loc", [null, [48, 36], [48, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 18], [48, 65]]], 0, 0]], [], 0, 1, ["loc", [null, [48, 8], [65, 8]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 16
                  },
                  "end": {
                    "line": 83,
                    "column": 12
                  }
                },
                "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "confirm-delete-modal", [], ["modelItem", ["subexpr", "@mut", [["get", "itemToDelete", ["loc", [null, [76, 30], [76, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", ["closeCallback"], [], ["loc", [null, [77, 26], [77, 50]]], 0, 0], "delete", ["subexpr", "action", ["deleteCallback"], [], ["loc", [null, [78, 27], [78, 52]]], 0, 0], "deleteTitle", ["subexpr", "t", ["call_tags.custom_tags.delete_tag.title"], [], ["loc", [null, [79, 32], [79, 76]]], 0, 0], "deleteMessage", ["subexpr", "t", ["call_tags.custom_tags.delete_tag.body"], ["tag", ["get", "itemToDelete.name", ["loc", [null, [80, 81], [80, 98]]], 0, 0, 0, 0]], ["loc", [null, [80, 34], [80, 99]]], 0, 0], "noteMessage", ["subexpr", "t", ["call_tags.custom_tags.delete_tag.note_message"], [], ["loc", [null, [81, 32], [81, 83]]], 0, 0]], ["loc", [null, [75, 16], [82, 18]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 12
                },
                "end": {
                  "line": 84,
                  "column": 12
                }
              },
              "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "ui-modal", [], ["class", "delete-tag-wrapper", "size", "sm", "closeOnEscape", true, "closeOnBackdropClick", false, "closeCallBack", ["subexpr", "action", ["closeCallback"], [], ["loc", [null, [73, 34], [73, 58]]], 0, 0]], 0, null, ["loc", [null, [68, 16], [83, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 8
              },
              "end": {
                "line": 85,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ember-wormhole", [], ["to", "modal-wormhole"], 0, null, ["loc", [null, [67, 12], [84, 31]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 86,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
        },
        isEmpty: false,
        arity: 4,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "title");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "sub-title");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["call_tags.custom_tags.title"], ["maxLimit", ["subexpr", "@mut", [["get", "maxLimit", ["loc", [null, [14, 59], [14, 67]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 16], [14, 69]]], 0, 0], ["block", "if", [["subexpr", "lt", [["get", "customTags.length", ["loc", [null, [17, 18], [17, 35]]], 0, 0, 0, 0], ["get", "maxLimit", ["loc", [null, [17, 36], [17, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 14], [17, 45]]], 0, 0]], [], 0, 1, ["loc", [null, [17, 8], [43, 15]]]], ["block", "if", [["get", "listLoading", ["loc", [null, [44, 14], [44, 25]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [44, 8], [65, 15]]]], ["block", "if", [["get", "showDeleteModal", ["loc", [null, [66, 14], [66, 29]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [66, 8], [85, 15]]]]],
        locals: ["listLoading", "hasEmptyList", "isSearching", "entitiesExistInTheFirstPlace"],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 87,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/call-tags/custom-tags/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "admin-main-content-list custom-tags");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "paginate-search", [], ["title", ["subexpr", "if", [["get", "customTags.length", ["loc", [null, [3, 18], [3, 35]]], 0, 0, 0, 0], ["subexpr", "t", ["call_tags.custom_tags.name_with_count"], ["count", ["get", "customTags.length", ["loc", [null, [3, 84], [3, 101]]], 0, 0, 0, 0], "maxLimit", ["get", "maxLimit", ["loc", [null, [3, 111], [3, 119]]], 0, 0, 0, 0]], ["loc", [null, [3, 36], [3, 120]]], 0, 0], ["subexpr", "t", ["call_tags.custom_tags.name"], [], ["loc", [null, [3, 121], [3, 153]]], 0, 0]], [], ["loc", [null, [3, 14], [3, 154]]], 0, 0], "placeholder", ["subexpr", "t", ["call_tags.custom_tags.search_placeholder"], [], ["loc", [null, [4, 20], [4, 66]]], 0, 0], "visibilityCount", 30, "searchDisabled", false, "modelName", "tag", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [8, 14], [8, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "modifyModel", ["subexpr", "@mut", [["get", "modifyModel", ["loc", [null, [9, 20], [9, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "isPaginatefalse", true], 0, null, ["loc", [null, [2, 4], [86, 24]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});