define('freshfone-ember/components/manage-intents/view-edit-intent/delete-modal/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    exports['default'] = Component.extend({
        actions: {
            closeCallback: function closeCallback() {
                this.sendAction('close');
            },

            deleteCallback: function deleteCallback() {
                this.sendAction('delete');
            }
        }
    });
});