define('freshfone-ember/initializers/analytics-tracker', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    ['component', 'controller', 'route'].forEach(function (entityType) {
      application.inject(entityType, 'analyticsTracker', 'service:analytics-tracker');
    });
  }

  exports['default'] = {
    name: 'analytics-tracker',
    initialize: initialize
  };
});