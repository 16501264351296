define('freshfone-ember/services/account-configuration', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var service = _ember['default'].inject.service;
  exports['default'] = Service.extend({
    store: service(),
    fetchConfig: function fetchConfig() {
      var _this = this;

      this.get('store').queryRecord('accountConfiguration', {}).then(function () {
        var accountConfig = _this.get('store').peekAll('account-configuration').get('firstObject');

        _this.set('config', accountConfig);
      });
    },
    updateConfig: function updateConfig() {
      return this.get('config').save();
    }
  });
});