define('freshfone-ember/mixins/adapters/delete-with-payload', ['exports', 'ember', 'freshfone-ember/adapters/application'], function (exports, _ember, _freshfoneEmberAdaptersApplication) {
  exports['default'] = _freshfoneEmberAdaptersApplication['default'].extend({
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var id = snapshot.id;
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);

      return this.ajax(this.buildURL(type.modelName, id, snapshot, 'deleteRecord'), "DELETE", {
        data: data
      });
    }
  });
});