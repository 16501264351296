define("freshfone-ember/components/call-queue/branch-options/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 19,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "anotherCallQueue.name", ["loc", [null, [18, 4], [18, 29]]], 0, 0, 0, 0]],
          locals: ["anotherCallQueue"],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 8
                },
                "end": {
                  "line": 22,
                  "column": 58
                }
              },
              "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon-warning");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 25,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createElementMorph(element4);
            morphs[2] = dom.createMorphAt(element4, 1, 1);
            morphs[3] = dom.createMorphAt(element4, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["child-queue-name ", ["get", "showQueueClass", ["loc", [null, [21, 39], [21, 53]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["showQueue", ["get", "queue", ["loc", [null, [21, 78], [21, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 57], [21, 85]]], 0, 0], ["block", "if", [["get", "queue.anyError", ["loc", [null, [22, 14], [22, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 8], [22, 65]]]], ["content", "queue.name", ["loc", [null, [23, 8], [23, 22]]], 0, 0, 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "child-queue");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element5, 1, 1);
          morphs[1] = dom.createMorphAt(element5, 2, 2);
          return morphs;
        },
        statements: [["block", "custom-elements/power-select", [], ["class", ["subexpr", "@mut", [["get", "callQueueClassName", ["loc", [null, [4, 12], [4, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "searchField", "name", "value", ["subexpr", "@mut", [["get", "queue", ["loc", [null, [6, 12], [6, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "attrName", "CallQueue", "placeholder", ["subexpr", "t", ["call_queue.edit.placeholders.choose_queue"], [], ["loc", [null, [8, 18], [8, 65]]], 0, 0], "optionList", ["subexpr", "@mut", [["get", "childLessQueue", ["loc", [null, [9, 17], [9, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "errorCallQueue", ["loc", [null, [10, 13], [10, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [11, 13], [11, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "hasCreateOption", ["subexpr", "has-feature", [], ["feature", "no_call_queues_restrictions", "account", ["get", "session.account", ["loc", [null, [12, 81], [12, 96]]], 0, 0, 0, 0]], ["loc", [null, [12, 22], [12, 97]]], 0, 0], "attributeName", "name", "afterOptionsComponent", "custom-elements/new-queue-option", "onNewObjectCreate", ["subexpr", "action", ["createNewQueue"], [], ["loc", [null, [15, 24], [15, 49]]], 0, 0]], 0, null, ["loc", [null, [3, 4], [19, 37]]]], ["block", "if", [["get", "queue", ["loc", [null, [20, 10], [20, 15]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [20, 4], [25, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 4
                },
                "end": {
                  "line": 45,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "routingAutomationFallBack.name", ["loc", [null, [44, 6], [44, 40]]], 0, 0, 0, 0]],
            locals: ["routingAutomationFallBack"],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 8
                  },
                  "end": {
                    "line": 48,
                    "column": 70
                  }
                },
                "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "icon-warning");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 4
                },
                "end": {
                  "line": 51,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element2, 'class');
              morphs[1] = dom.createElementMorph(element2);
              morphs[2] = dom.createMorphAt(element2, 1, 1);
              morphs[3] = dom.createMorphAt(element2, 3, 3);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["child-queue-name ", ["get", "showQueueClass", ["loc", [null, [47, 39], [47, 53]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["showCallFlow", ["get", "routingAutomation", ["loc", [null, [47, 81], [47, 98]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 57], [47, 100]]], 0, 0], ["block", "if", [["get", "routingAutomation.anyError", ["loc", [null, [48, 14], [48, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [48, 8], [48, 77]]]], ["content", "routingAutomation.name", ["loc", [null, [49, 8], [49, 34]]], 0, 0, 0, 0]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 0
              },
              "end": {
                "line": 54,
                "column": 0
              }
            },
            "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "child-queue routingAutomation");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n \n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element3, 1, 1);
            morphs[1] = dom.createMorphAt(element3, 2, 2);
            return morphs;
          },
          statements: [["block", "custom-elements/power-select", [], ["class", ["subexpr", "@mut", [["get", "callQueueClassName", ["loc", [null, [30, 12], [30, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "searchField", "name", "model", "routing-automation", "value", ["subexpr", "@mut", [["get", "routingAutomation", ["loc", [null, [33, 12], [33, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["call_queue.edit.placeholders.choose_routing_automation"], [], ["loc", [null, [34, 18], [34, 78]]], 0, 0], "hasCreateOption", true, "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [36, 13], [36, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "errorRoutingAutomation", ["loc", [null, [37, 13], [37, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "attributeName", "name", "afterOptionsComponent", "custom-elements/create-option", "afterOptionLabelText", ["subexpr", "t", ["custom_elements.new_routing_automation"], [], ["loc", [null, [40, 27], [40, 71]]], 0, 0], "onNewObjectCreate", ["subexpr", "action", ["createNewRoutingAutomation"], [], ["loc", [null, [41, 24], [41, 61]]], 0, 0]], 0, null, ["loc", [null, [29, 4], [45, 37]]]], ["block", "if", [["get", "routingAutomation", ["loc", [null, [46, 10], [46, 27]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [46, 4], [51, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 56,
                    "column": 4
                  },
                  "end": {
                    "line": 70,
                    "column": 4
                  }
                },
                "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "extension.name", ["loc", [null, [69, 6], [69, 24]]], 0, 0, 0, 0]],
              locals: ["extension"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 0
                },
                "end": {
                  "line": 72,
                  "column": 0
                }
              },
              "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "child-queue");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "custom-elements/power-select", [], ["class", ["subexpr", "@mut", [["get", "callQueueClassName", ["loc", [null, [57, 12], [57, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "searchField", "name", "value", ["subexpr", "@mut", [["get", "agentExtension", ["loc", [null, [59, 12], [59, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "attrName", "AgentExtension", "placeholder", ["subexpr", "t", ["call_queue.edit.placeholders.choose_agent_extension"], [], ["loc", [null, [61, 18], [61, 75]]], 0, 0], "hasCreateOption", false, "optionList", ["subexpr", "@mut", [["get", "agentExtensionList", ["loc", [null, [63, 17], [63, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [64, 13], [64, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "errorAgentExtension", ["loc", [null, [65, 13], [65, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "attributeName", "name"], 0, null, ["loc", [null, [56, 4], [70, 37]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 76,
                    "column": 6
                  },
                  "end": {
                    "line": 76,
                    "column": 53
                  }
                },
                "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "icon-warning");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 72,
                  "column": 0
                },
                "end": {
                  "line": 79,
                  "column": 0
                }
              },
              "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "title");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createElementMorph(element0);
              morphs[2] = dom.createMorphAt(element1, 0, 0);
              morphs[3] = dom.createMorphAt(element1, 2, 2);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["child-message  ", ["get", "actionOption.abbr", ["loc", [null, [73, 31], [73, 48]]], 0, 0, 0, 0], " ", ["get", "branchClassName", ["loc", [null, [73, 53], [73, 68]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["showMessage"], [], ["loc", [null, [74, 4], [74, 28]]], 0, 0], ["content", "actionOption.desc", ["loc", [null, [75, 25], [75, 46]]], 0, 0, 0, 0], ["block", "if", [["get", "showWarning", ["loc", [null, [76, 12], [76, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [76, 6], [76, 60]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 0
              },
              "end": {
                "line": 79,
                "column": 0
              }
            },
            "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isAgentExtension", ["loc", [null, [54, 10], [54, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [54, 0], [79, 0]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 79,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "isRoutingAutomation", ["loc", [null, [27, 10], [27, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [27, 0], [79, 0]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/call-queue/branch-options/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isQueue", ["loc", [null, [1, 6], [1, 13]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [79, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});