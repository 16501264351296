define('freshfone-ember/routes/protected/admin/agent-extension/new', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    breadCrumb: {},
    features: ['agent_extension'],
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.new_flow');
    }),
    queryParams: {
      editNumber: {},
      actionFor: {}
    },
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.checkFeature('agent_extension');
    },
    model: function model(params) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this.store.findAll('agentExtension').then(function (agentExtensionFlows) {
          var newFlow = _this.store.peekRecord('callFlow', { isNew: true });
          newFlow = newFlow || _this.store.createRecord('callFlow');
          newFlow.set('agentExtension', _this.store.createRecord('agentExtension'));
          newFlow.set('name', _ember['default'].i18n.t('agent_extension.new') + ' ' + (agentExtensionFlows.get('length') + 1));

          if (params.rule) {
            _this.set("rule", params.rule);
          }
          resolve(newFlow);
        });
      });
    },

    afterModel: function afterModel(model) {
      var title = get(model, 'name');
      set(this, 'breadCrumb.title', title);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('rule', undefined);
      }
    }

  });
});