define('freshfone-ember/components/contact-component/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var A = _ember['default'].A;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], {
    currentUser: computed.alias('session.user'),
    sortedContactList: computed.alias('model'),
    hasEmptyContactList: computed.empty('sortedContactList'),
    hasCompanyFilter: computed.notEmpty('companyId'),
    isEmptyContact: computed.empty('contact'),
    selectedContacts: A(),
    store: service('store'),
    notification: service('toastr'),
    importService: service('import-service'),
    importServiceParams: computed.alias('importService.importParams'),
    showShowDeleteButton: computed.gt('selectedContacts.length', 0),
    notifications: service('toastr'),
    isPowerDialerEnabled: computed(function () {

      return this.get('session.account').hasFeature('power_dialer') && this.get('currentUser.powerDialerAllowed');
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('selectedContacts').clear();
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('importServiceParams.fileModel', 'power_dialer');
    },

    company: computed('companyId', function () {
      if (this.get('hasCompanyFilter')) {
        return this.get("store").peekRecord('company', this.get('companyId'));
      }
    }),
    filterParams: computed('companyId', function () {
      return { companyId: this.get('companyId') };
    }),
    addToPowerDialerArray: function addToPowerDialerArray(contactId) {
      if (this.get('selectedContacts').includes(contactId)) this.get('selectedContacts').removeObject(contactId);else this.get('selectedContacts').pushObject(contactId);
    },
    actions: {
      closeCallback: function closeCallback() {
        this.set('isDelete', false);
      },
      deleteCallback: function deleteCallback(contact, options) {
        var _this = this;

        contact.setProperties(options);
        contact.destroyRecord().then(function () {
          _this.send('onModify');
          _this.send('afterDelete', contact);
          _this.send('closeCallback');
        });
      },
      toggleDelete: function toggleDelete(contact) {
        this.set('isDelete', true);
        this.set('deleteItem', contact);
      },
      onModify: function onModify() {
        this.set('modifyModel', true);
      },
      edit: function edit(contact) {
        this.setProperties({
          slideToggle: true,
          formMode: true,
          contact: contact
        });
      },
      afterDelete: function afterDelete(item) {
        if (item.id === this.get('contact.id') || this.get("isEmptyContact")) {
          this.set('contact', null);
          this.set('slideToggle', false);
          this.set('formMode', false);
        }
      },
      selectAllContactsHandler: function selectAllContactsHandler(e) {
        var _this2 = this;

        this.set('isAllContactsSelected', e.target.checked);
        if (!this.get('isAllContactsSelected')) this.set('selectedContacts', []);else this.get('sortedContactList.content').forEach(function (contact) {
          return _this2.addToPowerDialerArray(contact.id);
        });
      },
      onChange: function onChange(contactId) {
        this.addToPowerDialerArray(contactId);
      },
      addToPowerDialerList: function addToPowerDialerList() {
        var _this3 = this;

        _ember['default'].$.ajax({
          url: 'power_dialer_lists', type: 'POST', dataType: 'json',
          data: { 'contact_ids': this.get('selectedContacts') }
        }).done(function () {
          _this3.get('selectedContacts').clear();
          _this3.set('isAllContactsSelected', false);
          _this3.get('notifications').info(_this3.get('importService').getToastrMessage('inProgress'));
          _this3.get('importService').bindImportEvents();
          _this3.get('importService').trigger('import_progress');
        });
      }
    }
  });
});