define('freshfone-ember/routes/protected/admin/bulk-data-delete', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    i18n: service(),

    titleToken: computed(function () {
      return this.i18n.t('bulk_data_delete.title').string;
    }),
    breadCrumb: computed(function () {
      return { title: this.i18n.t('bulk_data_delete.title').string };
    }),
    beforeModel: function beforeModel() {
      this.checkFeature('bulk_data_delete');
      this.checkPrivilege('manage_schedules');
      this.store.unloadAll('schedule');
    },
    model: function model() {
      return [];
    }
  });
});