define('freshfone-ember/components/ivr-component/ivr-keypress/component', ['exports', 'ember', 'freshfone-ember/utils/message-constants'], function (exports, _ember, _freshfoneEmberUtilsMessageConstants) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var _set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    notification: service('toastr'),
    eventNotSelected: computed.empty('optionAction'),
    classNames: ["list-row", "list-content", "keypress-row"],
    isChildOpen: false,

    optionKey: computed('optionObj.optionKey', 'model.keyOptions', {
      get: function get() {
        var value = this.get('optionObj.optionKey');
        var option = this.get('model.keyOptions').findBy('name', value);
        if (!option) {
          return this.get('optionObj.optionKey');
        }
        if (!option.disabled) {
          _set(option, 'disabled', true);
        }
        return option;
      },
      set: function set(k, v) {
        if (typeof v === 'string') {
          // value is string for voice IVR - intent
          this.set('optionObj.optionKey', v);
          return v;
        } else if (v) {
          this.resetPreviousKey();
          _set(v, 'disabled', true);
          this.set('optionObj.optionKey', v.name);
          return v;
        }
      }
    }),
    filteredActionOptions: computed(function () {
      if (this.get('level') >= 2) {
        return this.get('fallbackActionOptions');
      } else {
        return this.get('model.actionOptions');
      }
    }),

    fallbackActionOptions: computed('model.actionOptions.[]', function () {
      var actions = this.get('model.actionOptions');

      return actions.reject(function (action) {
        return !['vm', 'hangup', 'callQueue', 'agentExtension', 'routingAutomation'].includes(action.name);
      });
    }),

    resetActionObject: function resetActionObject() {
      this.set('optionObj.actionId', null);
      this.set('optionObj.actionIvr', null);
      this.set('optionObj.actionQueue', null);
      this.closeChild();
    },

    closeChild: function closeChild() {
      this.set('isChildOpen', false);
      this.set('IVRChildOpen', false);
    },

    resetPreviousKey: function resetPreviousKey() {
      var value = this.get('optionObj.optionKey');
      if (value) {
        if (this.get('model.keyOptions').length && this.get('model.keyOptions').findBy('name', value)) _set(this.get('model.keyOptions').findBy('name', value), 'disabled', false);
      }
    },

    optionAction: computed('optionObj.actionType', 'model.keyOptions', {
      get: function get() {
        var value = this.get('optionObj.actionType');
        return this.get('model.actionOptions').findBy('name', value);
      },
      set: function set(k, v) {
        this.set('optionObj.actionType', v.name);
        this.resetActionObject();
        return v;
      }
    }),
    closeAllChildNodes: function closeAllChildNodes(elem) {
      //#todo, hack to be removed by changing the OutsideClickListener
      var ivrWrapper = elem.closest('.ivr-wrapper');
      ivrWrapper.children().find('.closeChild').first().trigger('click');
    },
    getMessageId: function getMessageId(defaultMessageId) {
      var messages = this.store.peekAll('message');
      return messages.filterBy('defaultMessageType', defaultMessageId)[0];
    },

    getBot: function getBot() {
      var ivr = this.get('model');
      var bot = this.store.peekAll('speech-ivr-bot').filter(function (bot) {
        return bot.get('name') === ivr.get('botConfig.name');
      })[0];
      return bot;
    },

    messageTypeActionIds: computed('model.actionOptions', function () {
      var messageTypes = ['vm', 'hangup'],
          messageIdArray = [];

      this.get('model.actionOptions').forEach(function (option) {
        if (messageTypes.includes(option.name)) messageIdArray.push(option.id);
      });
      return messageIdArray;
    }),

    actions: {
      onChanged: function onChanged(changedValue) {
        this.set('optionAction', changedValue);
        if (this.get('messageTypeActionIds').includes(changedValue.id)) {
          var message = this.getMessageId(_freshfoneEmberUtilsMessageConstants['default'][changedValue.name]);
          this.set('optionObj.actionId', message ? message.get('id') : null);
        }
        this.closeAllChildNodes(this.$());
      },
      removeKeypress: function removeKeypress(keypress) {
        this.resetPreviousKey();
        this.sendAction('removeKeypress', keypress);
        this.closeChild();
        this.closeAllChildNodes(this.$());
      },
      createNewIntent: function createNewIntent(searchText) {
        var _this = this;

        var newIntent = {},
            params = {},
            ivr = this.get('model'),
            bot = this.getBot();
        newIntent[searchText] = { "request_type": "append" };
        params = { "bot_type": ivr.get('botConfig.name'), "examples": newIntent };
        if (!bot) return this.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.create_bot.select_bot'));
        bot.updateBot(params).then(function (response) {
          run(function () {
            if (response.message && response.message.model_retraining) {
              var intentOption = { "name": searchText, disabled: false };
              _this.set('model.keyOptions', [].concat(_toConsumableArray(_this.get('model.keyOptions')), [intentOption]));
              _this.set('optionKey', intentOption);
            }
          });
        })['catch'](function (error) {
          _this.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.create_intent.error_message'));
        });
      }
    }
  });
});