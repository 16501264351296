define("freshfone-ember/components/call-logs/call-details/call-summary/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 6
              },
              "end": {
                "line": 13,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "child-call");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "call-logs/call-details/main-call-details", [], ["call", ["subexpr", "@mut", [["get", "agentLeg", ["loc", [null, [8, 17], [8, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "isAgentLeg", true, "changeCurrentCall", ["subexpr", "@mut", [["get", "changeCurrentCall", ["loc", [null, [10, 30], [10, 47]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 10], [11, 12]]], 0, 0]],
          locals: ["agentLeg"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 6
              },
              "end": {
                "line": 21,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "child-call");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "call-logs/call-details/main-call-details", [], ["call", ["subexpr", "@mut", [["get", "childCall", ["loc", [null, [17, 17], [17, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "isChildCall", true, "changeCurrentCall", ["subexpr", "@mut", [["get", "changeCurrentCall", ["loc", [null, [19, 30], [19, 47]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 10], [19, 49]]], 0, 0]],
          locals: ["childCall"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "child-calls-container call-details-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sub-section-title child-calls-header");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" (");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "child-calls");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var element12 = dom.childAt(element11, [1]);
          var element13 = dom.childAt(element11, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element12, 0, 0);
          morphs[1] = dom.createMorphAt(element12, 2, 2);
          morphs[2] = dom.createMorphAt(element13, 1, 1);
          morphs[3] = dom.createMorphAt(element13, 2, 2);
          return morphs;
        },
        statements: [["inline", "t", ["call_log_v1.call_summary.related_calls"], [], ["loc", [null, [3, 55], [3, 101]]], 0, 0], ["content", "call.childCount", ["loc", [null, [3, 103], [3, 122]]], 0, 0, 0, 0], ["block", "each", [["get", "call.agentLegs", ["loc", [null, [5, 14], [5, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 6], [13, 15]]]], ["block", "each", [["get", "call.descendants", ["loc", [null, [14, 14], [14, 30]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [14, 6], [21, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 6
              },
              "end": {
                "line": 31,
                "column": 6
              }
            },
            "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon-redact");
            dom.setAttribute(el1, "data-toggle", "tooltip");
            dom.setAttribute(el1, "data-placement", "top");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element6, 'data-original-title');
            return morphs;
          },
          statements: [["attribute", "data-original-title", ["subexpr", "t", ["call_log_v1.call_details.redact_message"], [], ["loc", [null, [null, null], [30, 141]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon-download cursor-pointer");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [["element", "action", ["downloadRecording"], [], ["loc", [null, [37, 50], [37, 80]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 8
              },
              "end": {
                "line": 41,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon-delete cursor-pointer");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["element", "action", ["deleteRecording", "record"], [], ["loc", [null, [40, 48], [40, 85]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 0
            },
            "end": {
              "line": 45,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "call-recording-container call-details-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "audio-player-container");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "player-actions");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [1]);
          var element9 = dom.childAt(element7, [3]);
          var element10 = dom.childAt(element9, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element8, 'class');
          morphs[1] = dom.createMorphAt(element8, 1, 1);
          morphs[2] = dom.createMorphAt(element8, 3, 3);
          morphs[3] = dom.createMorphAt(element9, 1, 1);
          morphs[4] = dom.createMorphAt(element10, 1, 1);
          morphs[5] = dom.createMorphAt(element10, 2, 2);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["sub-section-title call-recording-header ", ["subexpr", "if", [["get", "call.voicemailDrop", ["loc", [null, [27, 62], [27, 80]]], 0, 0, 0, 0], "voicemail-drop-recording"], [], ["loc", [null, [27, 57], [27, 109]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "if", [["get", "call.voicemailDrop", ["loc", [null, [28, 11], [28, 29]]], 0, 0, 0, 0], ["subexpr", "t", ["call_log_v1.call_summary.voicemail_drop"], [], ["loc", [null, [28, 30], [28, 75]]], 0, 0], ["subexpr", "t", ["call_log_v1.call_summary.call_recording"], [], ["loc", [null, [28, 76], [28, 121]]], 0, 0]], [], ["loc", [null, [28, 6], [28, 123]]], 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "has-feature", [], ["feature", "redact", "account", ["get", "session.account", ["loc", [null, [29, 55], [29, 70]]], 0, 0, 0, 0]], ["loc", [null, [29, 17], [29, 71]]], 0, 0], ["subexpr", "not", [["get", "call.voicemailDrop", ["loc", [null, [29, 77], [29, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 72], [29, 96]]], 0, 0], ["get", "call.recordingRedacted", ["loc", [null, [29, 97], [29, 119]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 12], [29, 120]]], 0, 0]], [], 0, null, ["loc", [null, [29, 6], [31, 13]]]], ["inline", "audio-player", [], ["showFullProgressBar", true, "url", ["subexpr", "@mut", [["get", "call.recordingUrl", ["loc", [null, [34, 50], [34, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "duration", ["subexpr", "@mut", [["get", "call.recordingDuration", ["loc", [null, [34, 77], [34, 99]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [34, 6], [34, 101]]], 0, 0], ["block", "if", [["get", "canDownloadRecording", ["loc", [null, [36, 14], [36, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [36, 8], [38, 15]]]], ["block", "if", [["get", "canDeleteRecording", ["loc", [null, [39, 14], [39, 32]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [39, 8], [41, 15]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "call-notes-container call-details-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sub-section-title call-notes-header");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "call-notes");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "call-notes-content");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["call_log_v1.call_summary.call_notes"], [], ["loc", [null, [48, 54], [48, 97]]], 0, 0], ["content", "call.conversation", ["loc", [null, [50, 36], [50, 57]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 0
            },
            "end": {
              "line": 61,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "voicemail-transcription-container call-details-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sub-section-title voicemail-transcription-header");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "voicemail-transcription");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "voicemail-transcription-content");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["call_log_v1.call_summary.voicemail_transcription"], [], ["loc", [null, [56, 67], [56, 123]]], 0, 0], ["content", "transcriptionContent", ["loc", [null, [58, 49], [58, 73]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 4
              },
              "end": {
                "line": 69,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "tag-container ellipsis");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "tag ellipsis");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
            return morphs;
          },
          statements: [["content", "tag.name", ["loc", [null, [67, 37], [67, 49]]], 0, 0, 0, 0]],
          locals: ["tag"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 0
            },
            "end": {
              "line": 71,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "call-tags-container call-details-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "sub-section-title call-tags-header");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["call_log_v1.call_summary.call_tags"], [], ["loc", [null, [64, 52], [64, 94]]], 0, 0], ["block", "each", [["get", "call.tags", ["loc", [null, [65, 12], [65, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [65, 4], [69, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 0
            },
            "end": {
              "line": 77,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "call-metrics-container call-details-container");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sub-section-title");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "call-metric-value");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", [["subexpr", "concat", ["call_log_v1.call_metrics.", ["get", "property", ["loc", [null, [74, 76], [74, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [74, 40], [74, 85]]], 0, 0]], [], ["loc", [null, [74, 36], [74, 87]]], 0, 0], ["content", "value", ["loc", [null, [75, 36], [75, 45]]], 0, 0, 0, 0]],
        locals: ["property", "value"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 78,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/call-logs/call-details/call-summary/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "or", [["get", "call.descendants.length", ["loc", [null, [1, 10], [1, 33]]], 0, 0, 0, 0], ["get", "call.hasAgentLegs", ["loc", [null, [1, 34], [1, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 6], [1, 52]]], 0, 0]], [], 0, null, ["loc", [null, [1, 0], [24, 7]]]], ["block", "if", [["get", "showCallRecording", ["loc", [null, [25, 6], [25, 23]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [25, 0], [45, 7]]]], ["block", "if", [["get", "call.conversation", ["loc", [null, [46, 6], [46, 23]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [46, 0], [53, 7]]]], ["block", "if", [["get", "showVoiceMailTranscription", ["loc", [null, [54, 6], [54, 32]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [54, 0], [61, 7]]]], ["block", "if", [["subexpr", "and", [["get", "hasCallTaggingFeature", ["loc", [null, [62, 11], [62, 32]]], 0, 0, 0, 0], ["get", "call.tags.length", ["loc", [null, [62, 33], [62, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [62, 6], [62, 50]]], 0, 0]], [], 4, null, ["loc", [null, [62, 0], [71, 7]]]], ["block", "each-in", [["get", "callMetrics", ["loc", [null, [72, 11], [72, 22]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [72, 0], [77, 12]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});