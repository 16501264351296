define("freshfone-ember/components/audio-player/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 11,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/audio-player/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "slider-container max-width");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "class", "slider");
          dom.setAttribute(el2, "type", "range");
          dom.setAttribute(el2, "min", "0");
          dom.setAttribute(el2, "step", "1");
          dom.setAttribute(el2, "value", "0");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'max');
          morphs[1] = dom.createAttrMorph(element2, 'oninput');
          return morphs;
        },
        statements: [["attribute", "max", ["get", "totalTime", ["loc", [null, [9, 55], [9, 64]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", ["onChange"], [], ["loc", [null, [null, null], [9, 116]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/audio-player/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "class", "slider");
          dom.setAttribute(el2, "type", "range");
          dom.setAttribute(el2, "min", "0");
          dom.setAttribute(el2, "step", "1");
          dom.setAttribute(el2, "value", "0");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element1, 'max');
          morphs[2] = dom.createAttrMorph(element1, 'oninput');
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["slider-container ", ["subexpr", "unless", [["get", "showAudioSeek", ["loc", [null, [17, 40], [17, 53]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [17, 31], [17, 62]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "max", ["get", "totalTime", ["loc", [null, [18, 53], [18, 62]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", ["onChange"], [], ["loc", [null, [null, null], [18, 114]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/audio-player/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "btn-container");
        dom.setAttribute(el2, "data-analytics-action-name", "Play Audio");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "play-arrow");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "duration");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element3, 'class');
        morphs[1] = dom.createElementMorph(element4);
        morphs[2] = dom.createAttrMorph(element5, 'class');
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
        morphs[5] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["player-container ", ["subexpr", "if", [["get", "showFullProgressBar", ["loc", [null, [1, 34], [1, 53]]], 0, 0, 0, 0], "max-width", "btn btn-cancel"], [], ["loc", [null, [1, 29], [1, 84]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["togglePlay", ["get", "this", ["loc", [null, [2, 51], [2, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 29], [2, 57]]], 0, 0], ["attribute", "class", ["concat", ["play ", ["subexpr", "if", [["get", "isPlaying", ["loc", [null, [4, 29], [4, 38]]], 0, 0, 0, 0], "icon-pause", "icon-play"], [], ["loc", [null, [4, 24], [4, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "showFullProgressBar", ["loc", [null, [7, 8], [7, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 2], [11, 9]]]], ["content", "formattedDuration", ["loc", [null, [13, 4], [13, 25]]], 0, 0, 0, 0], ["block", "unless", [["get", "showFullProgressBar", ["loc", [null, [16, 10], [16, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 0], [20, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});