define('freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/live-agents-dashboard'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsLiveAgentsDashboard) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var $ = _ember['default'].$;
  var A = _ember['default'].A;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var alias = _ember['default'].computed.alias;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var _Ember$run = _ember['default'].run;
  var debounce = _Ember$run.debounce;
  var later = _Ember$run.later;
  var throttle = _Ember$run.throttle;
  var testing = _ember['default'].testing;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    telephony: service('telephony'),
    session: service(),
    slmViewService: service('slm-view'),
    currentAccount: alias('session.account'),
    isOmnichannelEnabled: alias('currentAccount.isOmnichannelEnabled'),
    hasOmnichannelAgentStatusFeature: computed('session', function () {
      return this.checkFeature('omnichannel_agent_status') && this.get('isOmnichannelEnabled');
    }),
    hasSelectedQueues: computed('selectedQueues', function () {
      return this.get('selectedQueues').length > 0;
    }),
    selectedQueuesModified: observer('selectedQueues', function () {
      debounce(this, this.invokeFetchAgents, testing ? 0 : 500);
    }),
    isSlmViewSelected: alias('slmViewService.isViewSelected'),
    offset: 0,
    fetchedAgents: A(),
    initialFetchAgent: observer('searchComplete', 'statusChanged', 'selectedQueues', function () {
      this.set('offset', 0);
    }),
    statusChanged: false,
    liveAgentsCount: observer('fetchedAgents', function () {
      throttle(this, this.throttleLiveAgentsCount, 500, false);
    }),
    totalLiveAgentsCount: 0,
    selectedStatusTitle: computed('selectedStatus', function () {
      var status = this.get('selectedStatus');

      if (this.isAgentStatus(status)) {
        if (status.get('default')) {
          return status.get('dashboardStatusTitle');
        }

        return status.get('name');
      }

      return status;
    }),

    isAgentListEmpty: computed('fetchedAgents', function () {
      return this.get('fetchedAgents.length') === 0;
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      // using this global variable in order to avaoid multiple dom updates and run loop priority mismatches
      window.selectedStatusAgentsCount = 0;
      this.set('selectedStatus', this.store.peekAll('agent-status').findBy('statusSubType', 'online'));
      this.loadEnabledAgentStatuses();
      this.invokeFetchAgents(false);

      this.get('telephony').on('custom_status_updated', function () {
        later(_this, _this.loadEnabledAgentStatuses);
      });

      this.get('telephony').on('agent_status_change', function (agentId) {
        run(function () {
          if (_this.get('searchComplete')) {
            return;
          }
          var agentData = _this.get('store').peekRecord('live-agent-status', agentId);

          agentData.set('browserStatus', _this.store.peekRecord('agent-status', agentData.get('browserStatusId')));
          _this.isAgentStatusDifferent(agentData);
          if (agentData.get('status') !== _this.get('selectedStatus.statusSubType')) {
            return;
          }
          if ((!_this.get('hasSelectedQueues') || _this.get('hasSelectedQueues') && _this.doesAgentBelongToSelectedQueues(agentData)) && _this.shouldAgentStatusChangeNotify(agentData)) {
            if (_this.isAgentInDashboard(String(agentId))) {
              return;
            }
            _this.set('fetchedAgents', [].concat(_toConsumableArray(_this.get('fetchedAgents').toArray()), [agentData]));
            window.selectedStatusAgentsCount += 1;
          }
        });
      });
      this.pollUsersCount();
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var selectedQueues = arguments[0].newAttrs.selectedQueues;

      this.set('selectedQueues', selectedQueues.value);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony').off('agent_status_change');
      this.get('telephony').off('custom_status_updated');
      clearInterval(this.get('agentsCountPollingTimerId'));
    },

    throttleLiveAgentsCount: function throttleLiveAgentsCount() {
      var _this2 = this;

      run(function () {
        if (_this2.get('isDestroyed') || _this2.get('isDestroying')) {
          return;
        }
        if (!_this2.get('isLoadMore')) {
          _this2.set('totalLiveAgentsCount', window.selectedStatusAgentsCount);
        }
      });
    },

    pollUsersCount: function pollUsersCount() {
      var timeIntervalForPolling = _freshfoneEmberConstantsLiveAgentsDashboard['default'].agentsCountPollTimer;

      this.set('agentsCountPollingTimerId', this.getAgentsCountPollingTimerId(timeIntervalForPolling));
    },

    getAgentsCountPollingTimerId: function getAgentsCountPollingTimerId(pollingTime) {
      var _this3 = this;

      return setInterval(function () {
        return run(function () {
          return _this3.getAgentsCount();
        });
      }, pollingTime);
    },

    isAgentInDashboard: function isAgentInDashboard(agentId) {
      return this.get('fetchedAgents').find(function (agent) {
        return agent.id === agentId;
      });
    },

    shouldAgentStatusChangeNotify: function shouldAgentStatusChangeNotify(agent) {
      if (this.get('selectedStatusTitle') === _freshfoneEmberConstantsLiveAgentsDashboard['default'].allAgents || (this.get('hasOmnichannelAgentStatusFeature') || agent.get('browserStatus.default')) && this.get('selectedStatus.id') === String(agent.get('browserStatusId')) || this.get('selectedStatusTitle') === agent.get('browserStatus.name')) {
        return true;
      }

      return false;
    },

    doesAgentBelongToSelectedQueues: function doesAgentBelongToSelectedQueues(agent) {
      var selectedQueueIds = this.get('selectedQueues').mapBy('id');

      return agent.get('callQueueIds').some(function (queueId) {
        return selectedQueueIds.includes(String(queueId));
      });
    },

    isAgentStatusDifferent: function isAgentStatusDifferent(agent) {
      if (this.get('selectedStatusTitle') === _freshfoneEmberConstantsLiveAgentsDashboard['default'].allAgents) {
        return;
      }
      var agentsInDashboard = this.get('fetchedAgents').toArray();

      if (this.isAgentInDashboard(agent.id) && (agent.browserStatus.id !== this.get('selectedStatus.id') || agent.get('status') !== this.get('selectedStatus.statusSubType'))) {
        var indexToPop = agentsInDashboard.findIndex(function (dashboardAgent) {
          return dashboardAgent.id === agent.id;
        });

        agentsInDashboard.splice(indexToPop, 1);
        this.set('fetchedAgents', agentsInDashboard);
        window.selectedStatusAgentsCount -= 1;
      }
    },

    loadEnabledAgentStatuses: function loadEnabledAgentStatuses() {
      var _this4 = this;

      var customStatus = [],
          defaultSortedStatus = [],
          defaultStatus = [],
          defaultStatuses = Object.values(_freshfoneEmberConstantsLiveAgentsDashboard['default'].defaultStatuses);

      this.store.peekAll('agent-status').filterBy('isNew', false).sort(function (statusA, statusB) {
        return statusA.get('id') - statusB.get('id');
      }).forEach(function (status) {
        if (status.get('default') || _this4.get('hasOmnichannelAgentStatusFeature')) {
          status.set('dashboardStatusTitle', _freshfoneEmberConstantsLiveAgentsDashboard['default'].agentStatus[status.get('statusType')]);
          if (defaultStatuses.includes(status.get('statusType')) && status.get('statusSubType') !== 'online_phone') {
            defaultStatus.pushObject(status);
          }
        } else {
          status.get('enabled') && customStatus.pushObject(status);
        }
      });

      defaultStatuses.forEach(function (statusDefault) {
        return defaultStatus.findBy('statusType', statusDefault) && defaultSortedStatus.pushObject(defaultStatus.findBy('statusType', statusDefault));
      });

      this.set('enabledStatuses', [].concat(defaultSortedStatus, customStatus));
    },

    dropDownClickAction: function dropDownClickAction(status) {
      this.toggleProperty('statusChanged');
      this.set('selectedStatus', status);

      return this.invokeFetchAgents(false);
    },

    dropDownList: function dropDownList() {

      return {
        items: [_freshfoneEmberConstantsLiveAgentsDashboard['default'].allAgents].concat(_toConsumableArray(this.get('enabledStatuses'))),
        action: this.dropDownClickAction.bind(this)
      };
    },

    isAgentStatus: function isAgentStatus(status) {

      return typeof status === 'object';
    },

    queryParamsForFetchAgents: function queryParamsForFetchAgents() {
      var params = { type: _freshfoneEmberConstantsLiveAgentsDashboard['default'].queryType };

      params[_freshfoneEmberConstantsLiveAgentsDashboard['default'].fromUser] = this.get('offset');

      if (this.get('selectedStatusTitle') !== _freshfoneEmberConstantsLiveAgentsDashboard['default'].allAgents) {
        params[_freshfoneEmberConstantsLiveAgentsDashboard['default'].statusId] = this.get('selectedStatus.id');
      }

      if (this.get('hasSelectedQueues')) {
        params[_freshfoneEmberConstantsLiveAgentsDashboard['default'].queueSelected] = true;
      }

      if (this.get('isSlmViewSelected')) {
        params[_freshfoneEmberConstantsLiveAgentsDashboard['default'].viewId] = this.get('slmViewService.viewId');
      }

      return params;
    },

    browserStatusOfAgents: function browserStatusOfAgents(liveAgents) {
      var _this5 = this;

      var agentsHavingBrowserStatusId = liveAgents.filterBy('browserStatusId');

      agentsHavingBrowserStatusId.forEach(function (agentWithBrowserStatusId) {
        agentWithBrowserStatusId.set('browserStatus', _this5.store.peekRecord('agent-status', agentWithBrowserStatusId.get('browserStatusId')));
      });
    },

    fetchAgentsForStatus: function fetchAgentsForStatus(isLoadMore) {
      var _this6 = this;

      var params = this.queryParamsForFetchAgents();

      this.store.query('agent', params).then(function (fetchedAgents) {
        if (fetchedAgents.meta.last_query_id) {
          _this6.set('offset', fetchedAgents.meta.last_query_id);
        }
        _this6.browserStatusOfAgents(fetchedAgents);
        _this6.getAgentsCount();
        if (isLoadMore) {
          _this6.set('fetchedAgents', [].concat(_toConsumableArray(_this6.get('fetchedAgents').toArray()), _toConsumableArray(_this6.uniqueAgentsFromFetchedMore(fetchedAgents.toArray()))));
        } else {
          _this6.set('fetchedAgents', fetchedAgents);
        }
        _this6.set('hasMore', fetchedAgents.meta.has_more);
      })['finally'](function () {
        _this6.setProperties({
          loadingAgents: false,
          isLoadMore: false
        });
      });
    },

    getAgentsCount: function getAgentsCount() {
      var _this7 = this;

      if (testing) {
        clearInterval(this.get('agentsCountPollingTimerId'));
      }

      if (this.get('selectedStatusTitle') === _freshfoneEmberConstantsLiveAgentsDashboard['default'].allAgents) {
        return;
      }
      $.getJSON('/agents/availability_count').then(function (agentsCount) {
        window.selectedStatusAgentsCount = agentsCount[_freshfoneEmberConstantsLiveAgentsDashboard['default'].agentsCount][_this7.get('selectedStatus.id')];
        _this7.set('totalLiveAgentsCount', window.selectedStatusAgentsCount);
      });
    },

    uniqueAgentsFromFetchedMore: function uniqueAgentsFromFetchedMore(agentsFetched) {
      var _this8 = this;

      return agentsFetched.filter(function (agent) {
        return _this8.get('fetchedAgents').indexOf(agent) < 0;
      });
    },

    invokeFetchAgents: function invokeFetchAgents() {
      var isLoadMore = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      if (isLoadMore) {
        this.set('isLoadMore', true);
      } else {
        this.set('loadingAgents', true);
      }
      this.fetchAgentsForStatus(isLoadMore);
    },

    actions: {
      loadStatusDropdown: function loadStatusDropdown() {
        this.set('dropDownObj', this.dropDownList());
      },

      dropDownCallback: function dropDownCallback(status) {
        this.get('dropDownObj').action(status);
      },
      modelCallback: function modelCallback(newAgentsSet) {
        if (this.get('model.query.filter.queryString.length') > 0) {
          this.browserStatusOfAgents(newAgentsSet);
          this.setProperties({
            fetchedAgents: newAgentsSet,
            searchComplete: true,
            hasMore: false
          });
        } else {
          this.set('searchComplete', false);
          this.invokeFetchAgents(false);
        }
      },
      fetchMore: function fetchMore() {
        this.invokeFetchAgents(true);
      }
    }

  });
});