define('freshfone-ember/components/live-calls/views-list/component', ['exports', 'ember', 'freshfone-ember/constants/views'], function (exports, _ember, _freshfoneEmberConstantsViews) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    slmView: service(),
    maxLimit: _freshfoneEmberConstantsViews['default']['viewsLimit'],
    viewsCount: computed.alias('views.length'),
    actions: {
      toogleDropDown: function toogleDropDown() {
        var _this = this;

        if (!this.get('showViews')) {
          _ember['default'].$.ajax({
            url: '/slm_views/fetch_views',
            type: 'get'
          }).done(function (response) {
            _ember['default'].run(function () {
              _this.set('views', response.data);
              _this.set('showViews', true);
            });
          });
        } else {
          this.toggleProperty('showViews');
        }
      },
      setSelectedView: function setSelectedView(view) {
        var _this2 = this;

        this.get('slmView').fetchSlmView(view.id);
        var record = this.store.peekRecord('slm-view', view.id);

        record ? this.send('postViewFectActions', record) : this.store.findRecord('slm-view', view.id).then(function (response) {
          _this2.send('postViewFectActions', response);
        });
      },

      postViewFectActions: function postViewFectActions(view) {
        this.sendAction('setSelectedView', view);
        this.toggleProperty('showViews');
      }
    }
  });
});