define('freshfone-ember/components/omnibar-placeholder/component', ['exports', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/mixins/url-mixin'], function (exports, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberMixinsUrlMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsUrlMixin['default'], {

    omniBarReady: false,
    omniBar: _freshfoneEmberConfigEnvironment['default'].OmniBar,
    freshidV2Config: _freshfoneEmberConfigEnvironment['default'].FRESHIDV2,
    sessionstate: computed('session', 'current', function () {
      return this.store.peekRecord('session', 'current').get('freshidSessionState');
    }),
    sessionToken: computed('session', 'current', function () {
      return this.store.peekRecord('session', 'current').get('freshidSessionToken');
    }),
    organisationDomain: computed('session', 'current', function () {
      return this.store.peekRecord('session', 'current').get('account.organisationDomain');
    }),
    freshidVersion2: computed.notEmpty('organisationDomain'),
    roleName: computed('session', 'current', function () {
      return this.store.peekRecord('session', 'current').get('user.role.name');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.createOmniBar();
      window.freshworksOmnibar = { onSessionStateChanged: this.get('session.redirectToAuthorization') };
    },
    createOmniBar: function createOmniBar() {
      var _this = this;

      var link = document.createElement('link');

      link.setAttribute('rel', 'stylesheet');
      link.type = 'text/css';
      link.href = this.get('omniBar.styleUrl');
      link.href && document.head.appendChild(link);

      this.get('omniBar.scriptUrl') && $.getScript(this.get('omniBar.scriptUrl')).then(function () {
        _this.set('omniBarReady', true);
      });
    }
  });
});