define('freshfone-ember/components/call-queue/route-types/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'ember-features/mixins/feature-route-mixin', 'freshfone-ember/utils/subscription-constants'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _emberFeaturesMixinsFeatureRouteMixin, _freshfoneEmberUtilsSubscriptionConstants) {
  var $ = _ember['default'].$;
  var A = _ember['default'].A;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = Component.extend(_emberFeaturesMixinsFeatureRouteMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),
    selectedRouteType: computed.alias('queue.selectedRouteType'),
    isNumeric: computed.match('searchTerm', /^([+]|[1-9])([1-9]\d*)$/),
    startValidate: computed.alias('parent.startValidate'),
    extNumTooltip: true,
    anyErrors: computed('serverErrorText', 'errors', function () {
      if (this.get('serverErrorText')) {
        return this.get('serverErrorText');
      }
      if (this.get('errors')) {
        return this.get('errors').map(function (arr) {
          return arr.join(', ');
        }).filter(function (word) {
          return word;
        }).join(', ');
      }
    }),
    agentsList: computed(function () {
      return this.store.findAll('user');
    }),
    errorPrefix: computed('session.account.plan_name', function () {
      return [_freshfoneEmberUtilsSubscriptionConstants['default'].DEFAULT_PLAN_NAMES.Free, _freshfoneEmberUtilsSubscriptionConstants['default'].DEFAULT_PLAN_NAMES.Sprout, _freshfoneEmberUtilsSubscriptionConstants['default'].DEFAULT_PLAN_NAMES.Sprout2020].includes(this.get('session.account.plan_name')) ? 'call_queue.enf_request.free_plan' : 'call_queue.enf_request';
    }),
    teamsList: computed(function () {
      return this.store.findAll('team');
    }),
    showExtNumberRouteRequestError: false,
    addTransparentandHeightDropdown: false,
    isExternalTransferAllowed: computed(function () {
      return this.allowExternalTransfer() && this.get('session.account').hasGlobalFlag('external_number_request');
    }),
    routeTypes: computed('agentsList.[]', 'teamsList.[]', 'isOpened', {
      get: function get() {
        var _this = this;

        if (typeof this.get('isOpened') === 'undefined') {
          return [];
        }

        return new Promise(function (resolve) {
          var options = A(),
              users = _this.get('agentsList');

          options.pushObject(_this.get('queue.allAgents'));
          if (_this.hasFeatures(['team'])) {
            var teams = _this.get('teamsList');

            teams.then(function (teams) {
              options.pushObjects(teams.filterBy('isNew', false).toArray());
              users.then(function (users) {
                options.pushObjects(users.filterBy('isNew', false).filter(function (user) {
                  return !user.get('deleted');
                }).toArray());
                resolve(options);
              });
            });
          } else {
            users.then(function (users) {
              options.pushObjects(users.filterBy('isNew', false).filter(function (user) {
                return !user.get('deleted');
              }).toArray());
              resolve(options);
            });
          }
        });
      }
    }),

    routeTypesPlaceholder: computed(function () {
      return this.get('i18n').t('call_queue.edit.placeholders.all_route_types');
    }),

    externalNumber: function externalNumber() {
      return {
        name: this.get('searchTerm'),
        id: 'externalNumber',
        constructor: { modelName: 'externalNumber' }
      };
    },

    actions: {
      onRouteTypeSearch: function onRouteTypeSearch(text) {
        var _this2 = this;

        if (isEmpty(text)) {
          return;
        }
        var searchTerm = encodeURIComponent(text.toLowerCase());

        this.set('searchTerm', decodeURIComponent(searchTerm));
        var result = [];

        return new Promise(function (resolve) {
          _this2.get('routeTypes').then(function (options) {
            result = options.filter(function (option) {
              var searchField = option.get ? option.get('name').toLowerCase() : option.name.toLowerCase();

              if (searchField.match(searchTerm)) {
                return true;
              }
            });
            if (_this2.get('isNumeric')) {
              result.pushObject(_this2.externalNumber());
              _this2.set('addTransparentandHeightDropdown', true);
            } else {
              _this2.set('addTransparentandHeightDropdown', false);
            }

            return resolve(result);
          });
        });
      },
      onRouteTypeChange: function onRouteTypeChange(changedOption) {
        var _this3 = this;

        if (changedOption.constructor.modelName === 'externalNumber' && !this.get('isExternalTransferAllowed')) {
          this.toggleProperty('showExtNumberRouteRequestError');
        } else {
          this.set('addTransparentandHeightDropdown', false);
        }

        if (!changedOption) {
          this.set('selectedRouteType', null);

          return;
        }
        this.set('queue.invalidExternalNumber', false);
        switch (changedOption.constructor.modelName) {
          case 'user':
            this.set('selectedRouteType', this.get('queue.routeTypes').find(function (option) {
              return option.abbr === 'agent';
            }));
            this.set('queue.agent', changedOption);
            this.set('showExtNumberRouteRequestError', false);
            break;
          case 'team':
            this.set('selectedRouteType', this.get('queue.routeTypes').find(function (option) {
              return option.abbr === 'team';
            }));
            this.set('queue.team', changedOption);
            this.set('showExtNumberRouteRequestError', false);
            break;
          case 'externalNumber':
            {
              var _ret = (function () {
                _this3.set('startValidate', true);
                var encodeNumber = encodeURIComponent(changedOption.name),
                    that = _this3;

                if (_this3.get('advancedWaitQueueEnabled') && _this3.get('queue.noAnswerWaitQueueEnabled')) {
                  _this3.set('queue.noAnswerAction', 'hangup');
                  _this3.toggleProperty('queue.noAnswerWaitQueueEnabled');
                }
                $.getJSON('/caller_ids/validate_number?number=' + encodeNumber, function () {
                  that.set('queue.invalidExternalNumber', false);
                }).fail(function () {
                  run(function () {
                    return that.set('queue.invalidExternalNumber', true);
                  });
                }).always(function () {
                  run(function () {
                    that.set('queue.externalNumberObject', changedOption);
                    that.set('queue.externalNumber', changedOption.name);
                    that.set('selectedRouteType', that.get('queue.routeTypes').find(function (option) {
                      return option.abbr === 'externalNumber';
                    }));
                  });
                });
                return 'break';
              })();

              if (_ret === 'break') break;
            }
          case 'allAgents':
            this.set('selectedRouteType', this.get('queue.routeTypes').find(function (option) {
              return option.abbr === 'allAgents';
            }));
            this.set('showExtNumberRouteRequestError', false);
            break;
        }
        this.sendAction('routeTypeChanged');
      },
      selectionNotAllowed: function selectionNotAllowed() {
        this.toggleProperty('showExtNumberRouteRequestError');
      }
    }
  });
});