define("freshfone-ember/helpers/date-to-string", ["exports", "ember"], function (exports, _ember) {
  exports.dateToSting = dateToSting;

  function dateToSting(params) {
    var dateParam = params[0];
    if (dateParam.length === 1) {
      return dateParam[0].format("DD MMMM YYYY").toString();
    } else {
      var dateRange = dateParam.map(function (m) {
        return m.format("DD MMMM YYYY").toString();
      });
      return dateRange.join(" - ");
    }
  }

  exports["default"] = _ember["default"].Helper.helper(dateToSting);
});