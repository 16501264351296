define('freshfone-ember/components/ui-list-header-item/component', ['exports', 'ember', 'ember-data-table/components/ui-list-header-item/component'], function (exports, _ember, _emberDataTableComponentsUiListHeaderItemComponent) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberDataTableComponentsUiListHeaderItemComponent['default'].extend({
    isActiveColumn: computed({
      get: function get() {
        return this.get('parentView.activeColumn').contains(this);
      }
    }),
    // Actions
    actions: {
      sortBy: function sortBy() {
        this.get('parentView').send('onSort', this);
      }
    }
  });
});