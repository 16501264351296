define('freshfone-ember/mixins/integration-check-mixin', ['exports', 'ember', 'freshfone-ember/constants/cloud-type', 'freshfone-ember/constants/product-names'], function (exports, _ember, _freshfoneEmberConstantsCloudType, _freshfoneEmberConstantsProductNames) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    session: service(),
    currentAccount: computed.alias('session.account'),
    currentUser: computed.alias('session.user'),
    routing: service('-routing'),
    params: computed.alias('routing.router.currentState.routerJsState.fullQueryParams'),
    isSales360ParamPresent: computed('params', function () {
      var queryParams = this.get('params');

      return queryParams && queryParams.bundle && queryParams.bundle === 'sales360';
    }),
    isEmbedParamPresent: computed('params', function () {
      var queryParams = this.get('params');

      return queryParams && queryParams.isEmbedMode && queryParams.isEmbedMode === 'true';
    }),
    hasMessagingCloudType: computed(function () {
      return this.get('session.account.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.freshdeskMessagingCloud;
    }),
    isCrmView: computed.or('currentUser.isCrmUser', 'isSales360ParamPresent'),
    isFullView: computed.alias('isEmbedParamPresent'),
    isNavOff: computed.and('isCrmView', 'isFullView'),
    isSharedBundleAccount: computed.alias('currentAccount.isSharedBundleAccount'),
    sharedNonCrmView: computed('isSharedBundleAccount', 'isCrmView', function () {
      return this.get('isSharedBundleAccount') && !this.get('isCrmView');
    }),
    isCrmOrSharedUser: computed.or('currentUser.isCrmUser', 'currentUser.isSharedUser'),
    isOmniOrSharedUser: computed.or('currentUser.omnichannelUser', 'currentUser.isSharedUser'),
    freshsalesPlanName: computed.alias('session.current.bundleInfo.domains.freshsales-plan-name'),
    freshSalesPlan: computed('freshsalesPlanName', function () {
      // return the crm account type
      return this.get('freshsalesPlanName') && this.get('freshsalesPlanName').includes('sales360_clc') ? _freshfoneEmberConstantsProductNames.rebrandedProductNames.crmSuite : _freshfoneEmberConstantsProductNames.rebrandedProductNames.crm;
    }),
    isStandaloneView: computed('currentAccount.organisationDomain', function () {
      return window.location.hostname !== this.get('currentAccount.organisationDomain');
    })
  });
});