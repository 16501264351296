define('freshfone-ember/models/call-queue', ['exports', 'freshfone-ember/constants/call-queues', 'ember', 'ember-data', 'freshfone-ember/mixins/validations/call-queue', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _freshfoneEmberConstantsCallQueues, _ember, _emberData, _freshfoneEmberMixinsValidationsCallQueue, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var computed = _ember['default'].computed;
  var notEmpty = _ember['default'].computed.notEmpty;
  var defineProperty = _ember['default'].defineProperty;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;

  var ActionTypes = ["noAnswerAction", "busyWaitOverAction", "offlineAction", "queueFullAction", 'quitQueueAction', 'timeThresholdAction', 'callbackFailAction'];

  exports['default'] = Model.extend(_freshfoneEmberMixinsValidationsCallQueue['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    session: service(),
    name: attr('string'),
    totalRingingTimeout: attr('number', { defaultValue: 150 }),
    agentBatch: attr('number', { defaultValue: 3 }),
    waitQueueSize: attr('number', { defaultValue: 5 }),
    externalNumber: attr('string'),
    routeType: attr(),
    routeOption: attr({ defaultValue: 'all' }),
    autoAnswerStatus: attr('string'),
    autoAnswerThreshold: attr('number', { defaultValue: 0 }),
    routeOptionMins: attr('number', { defaultValue: 30 }),
    busyQueuePositionMessage: attr('string', { defaultValue: "Your queue position is {{queue.position}}" }),
    queuePositionEnabled: attr('boolean'),
    waitQueueEnabled: attr('boolean'),
    busyMaxWaitTime: attr('number', { defaultValue: 60 }),
    busyWaitOverAction: attr({ defaultValue: 'hangup' }),
    queueFullAction: attr({ defaultValue: 'hangup' }),
    noAnswerAction: attr({ defaultValue: 'hangup' }),
    offlineAction: attr({ defaultValue: 'hangup' }),
    quitQueueAction: attr({ defaultValue: 'vm' }),
    timeThresholdAction: attr({ defaultValue: 'hangup' }),
    noAnswerWaitQueueEnabled: attr('boolean'),
    offlineWaitQueueEnabled: attr('boolean'),
    busyWaitQueueEnabled: attr('boolean'),
    callbackEnabled: attr('boolean'),
    callbackFailAction: attr({ defaultValue: 'hangup' }),
    callbackMessageId: attr('number'),
    callbackConfirmationMessageId: attr('number'),

    account: belongsTo('account'),
    busyMessage: belongsTo('message', { async: true }),
    callbackMessage: belongsTo('message'),
    callbackConfirmationMessage: belongsTo('message'),
    team: belongsTo('team', { async: true }),
    agent: belongsTo('user', { async: true }),
    message: belongsTo('message', { async: true }),
    queueFullCallQueue: belongsTo('callQueue', { async: true, inverse: null }),
    queueFullAgentExtension: belongsTo('agentExtension', { async: true, inverse: null }),
    queueFullRoutingAutomation: belongsTo('routingAutomation', { async: true, inverse: null }),
    timeThresholdCallQueue: belongsTo('callQueue', { async: true, inverse: null }),
    timeThresholdAgentExtension: belongsTo('agentExtension', { async: true, inverse: null }),
    timeThresholdRoutingAutomation: belongsTo('routingAutomation', { async: true, inverse: null }),
    callbackFailCallQueue: belongsTo('callQueue', { async: true, inverse: null }),
    callbackFailRoutingAutomation: belongsTo('routingAutomation', { async: true, inverse: null }),
    noAnswerCallQueue: belongsTo('callQueue', { async: true, inverse: null }),
    busyWaitOverCallQueue: belongsTo('callQueue', { async: true, inverse: null }),
    offlineCallQueue: belongsTo('callQueue', { async: true, inverse: null }),
    noAnswerAgentExtension: belongsTo('agentExtension', { async: true, inverse: null }),
    busyWaitOverAgentExtension: belongsTo('agentExtension', { async: true, inverse: null }),
    offlineAgentExtension: belongsTo('agentExtension', { async: true, inverse: null }),
    noAnswerRoutingAutomation: belongsTo('routingAutomation', { async: true, inverse: null }),
    busyWaitOverRoutingAutomation: belongsTo('routingAutomation', { async: true, inverse: null }),
    offlineRoutingAutomation: belongsTo('routingAutomation', { async: true, inverse: null }),
    busyWaitOverMessage: belongsTo('message', { async: true, inverse: null }),
    noAnswerMessage: belongsTo('message', { async: true, inverse: null }),
    queueFullMessage: belongsTo('message', { async: true, inverse: null }),
    timeThresholdMessage: belongsTo('message', { async: true, inverse: null }),
    callbackFailMessage: belongsTo('message', { async: true, inverse: null }),
    offlineMessage: belongsTo('message', { async: true, inverse: null }),
    quitQueueMessage: belongsTo('message', { async: true, inverse: null }),
    callFlow: belongsTo('callFlow'),
    isACallQueue: computed(function () {
      return this.constructor.modelName === 'call-queue';
    }),
    hasMessage: computed.notEmpty('activeChildMessage'),
    hasNoAnswerAction: notEmpty('noAnswerAction'),
    hasNoAnswerActionQueue: computed.equal('noAnswerAction', 'call_queue'),
    hasNoAnswerAgentExtension: computed.equal('noAnswerAction', 'agent_extension'),
    hasNoAnswerRoutingAutomation: computed.equal('noAnswerAction', 'routing_automation'),
    hasNoAnswerActionMessage: computed.not('hasNoAnswerActionQueue'),
    hasBusyWaitOverActionQueue: computed.equal('busyWaitOverAction', 'call_queue'),
    hasBusyWaitOverActionMessage: computed.not('hasBusyWaitOverActionQueue'),
    hasBusyWaitOverAgentExtension: computed.equal('busyWaitOverAction', 'agent_extension'),
    hasBusyWaitOverRoutingAutomation: computed.equal('busyWaitOverAction', 'routing_automation'),
    hasOfflineActionQueue: computed.equal('offlineAction', 'call_queue'),
    hasOfflineActionMessage: computed.not('hasOfflineActionQueue'),
    hasOfflineAgentExtension: computed.equal('offlineAction', 'agent_extension'),
    hasOfflineRoutingAutomation: computed.equal('offlineAction', 'routing_automation'),
    hasQueueFullActionQueue: computed.equal('queueFullAction', 'call_queue'),
    hasQueueFullAgentExtension: computed.equal('queueFullAction', 'agent_extension'),
    hasQueueFullRoutingAutomation: computed.equal('queueFullAction', 'routing_automation'),
    hasTimeThresholdActionQueue: computed.equal('timeThresholdAction', 'call_queue'),
    hasTimeThresholdAgentExtension: computed.equal('timeThresholdAction', 'agent_extension'),
    hasTimeThresholdRoutingAutomation: computed.equal('timeThresholdAction', 'routing_automation'),
    hasCallbackActionQueue: computed.equal('callbackFailAction', 'call_queue'),
    hasCallbackActionRoutingAutomation: computed.equal('callbackFailAction', 'routing_automation'),
    hasQueueFullActionMessage: computed.not('hasQueueFullActionQueue'),
    hasTimeThresholdActionMessage: computed.not('hasTimeThresholdActionQueue'),
    hasCallbackActionMessage: computed.not('callbackFailCallQueue'),
    hasQuitQueueVoicmail: computed.equal('quitQueueAction', 'vm'),
    hasQuitQueueHangup: computed.equal('quitQueueAction', 'hangup'),
    hasQuitQueueActionMessage: computed.or('hasQuitQueueHangup', 'hasQuitQueueVoicmail'),
    isTeamRouteType: computed.equal('selectedRouteType.abbr', 'team'),
    isAllAgentsRouteType: computed.equal('selectedRouteType.abbr', 'allAgents'),
    isAgentRouteType: computed.equal('selectedRouteType.abbr', 'agent'),
    isMostIdleAgentRouteSelected: computed.equal('selectedRouteOption.abbr', _freshfoneEmberConstantsCallQueues.ROUTE_OPTIONS.leastIdle),
    isEmptyRouteType: computed.empty('routeType'),
    isTeamOrAllAgentsRoute: computed.or('isTeamRouteType', 'isAllAgentsRouteType'),
    isTeamOrAgentsRoute: computed.or('isTeamOrAllAgentsRoute', 'isAgentRouteType'),

    isValidateQueueFullCallQueue: computed.and('waitQueueEnabled', 'hasQueueFullActionQueue', 'queueFullCallQueue.content'),
    isValidateThresholdTimeCallQueue: computed.and('waitQueueEnabled', 'hasTimeThresholdActionQueue', 'timeThresholdCallQueue.content'),
    isValidateCallbackCallQueue: computed.and('waitQueueEnabled', 'hasCallbackActionQueue', 'callbackFailCallQueue.content'),
    isValidateNoAnswerCallQueue: computed.and('hasNoAnswerActionQueue', 'noAnswerCallQueue.content'),
    isValidateBusyWaitOverCallQueue: computed.and('hasBusyWaitOverActionMessage', 'busyWaitOverCallQueue.content'),
    isValidateOfflineCallQueue: computed.and('hasOfflineActionMessage', 'offlineCallQueue.content'),
    anyError: computed.not('isValid'),
    validChildQueues: computed.and('isValidNoAnswerCallQueue', 'isValidOfflineCallQueue', 'isValidBusyWaitOverCallQueue', 'isValidQueueFullCallQueue', 'isValidTimeThresholdCallQueue', 'isValidCallbackFailCallQueue'),
    validRoutingAutomations: computed.and('isValidNoAnswerRoutingAutomation', 'isValidOfflineRoutingAutomation', 'isValidBusyWaitOverRoutingAutomation', 'isValidQueueFullRoutingAutomation', 'isValidTimeThresholdRoutingAutomation', 'isValidCallbackFailRoutingAutomation'),
    hasChildQueue: computed.or('hasNoAnswerActionQueue', 'hasBusyWaitOverActionQueue', 'hasOfflineActionQueue', 'hasQueueFullActionQueue', 'hasTimeThresholdActionQueue', 'hasCallbackActionQueue'),
    hasFallbackFlow: computed.and('isTeamOrAgentsRoute', 'hasFallbackFlowFeature'),
    waitMaxEnabled: computed(function () {
      return this.checkFeature('max_queue_size');
    }),
    isACallQueue: computed(function () {
      return this.constructor.modelName === 'call-queue';
    }),
    enhancedWaitTimeEnabled: computed(function () {
      return this.checkFeature('max_wait_time_enhanced');
    }),
    routingAutomationAsFallback: computed(function () {
      return this.checkFeature('routing_automation') && this.get('session.account').hasGlobalFlag('routing_automation_fallback');
    }),
    enhancedRingingTimeEnabled: computed(function () {
      return this.checkFeature('ringing_time_enhanced');
    }),

    busyFallbackMessageValidationText: computed('waitQueueEnabled', function () {
      if (this.get('waitQueueEnabled')) {
        return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_queue_over.message_blank');
      } else {
        return _ember['default'].i18n.t('call_queue.ui_validation_error.busy.message_blank');
      }
    }),
    busyFallbackQueueValidationText: computed('waitQueueEnabled', function () {
      if (this.get('waitQueueEnabled')) {
        return _ember['default'].i18n.t('call_queue.ui_validation_error.wait_queue_over.another_queue_blank');
      } else {
        return _ember['default'].i18n.t('call_queue.ui_validation_error.busy.another_queue_blank');
      }
    }),

    routeOptions: computed(function () {
      return [{
        id: 1,
        name: _ember['default'].i18n.t('call_queue.edit.route_options.all'),
        abbr: _freshfoneEmberConstantsCallQueues.ROUTE_OPTIONS.all
      }, {
        id: 2,
        name: _ember['default'].i18n.t('call_queue.edit.route_options.idle_first'),
        abbr: _freshfoneEmberConstantsCallQueues.ROUTE_OPTIONS.leastIdle
      }];
    }),

    autoAnswerOptions: computed(function () {
      return [{
        id: 1,
        name: _ember['default'].i18n.t('call_queue.edit.auto_answer.off'),
        abbr: _freshfoneEmberConstantsCallQueues.AUTO_ANSWER.off
      }, {
        id: 2,
        name: _ember['default'].i18n.t('call_queue.edit.auto_answer.global_auto_answer_settings.label'),
        abbr: _freshfoneEmberConstantsCallQueues.AUTO_ANSWER.useGlobalSettings,
        desc: _ember['default'].i18n.t('call_queue.edit.auto_answer.global_auto_answer_settings.label'),
        helpText: _ember['default'].i18n.t('call_queue.edit.auto_answer.global_auto_answer_settings.help_text', { acountSettingsUrl: '/admin/account-settings' })
      }, {
        id: 3,
        name: _ember['default'].i18n.t('call_queue.edit.auto_answer.custom_auto_answer_settings.label'),
        abbr: _freshfoneEmberConstantsCallQueues.AUTO_ANSWER.userCustomSettings,
        desc: _ember['default'].i18n.t('call_queue.edit.auto_answer.custom_auto_answer_settings.label'),
        helpText: _ember['default'].i18n.t('call_queue.edit.auto_answer.custom_auto_answer_settings.help_text')
      }];
    }),

    routeTypes: [{ id: 1, name: 'Agent', abbr: 'agent' }, { id: 2, name: 'Team', abbr: 'team' }, { id: 3, name: 'External Number', abbr: 'externalNumber' }, { id: 4, name: 'All Agents', abbr: 'allAgents' }],

    init: function init() {
      this._super.apply(this, arguments);
      this.defineActionMacros();
      this.defineRouteTypeMacros();
      this.defineActionOptionsMacros();
      this.defineChildrenValidationMacros();
      this.set('currentAccount', this.getCurrentAccount());
    },

    getCurrentAccount: function getCurrentAccount() {
      if (this.get('currentAccount')) {
        return this.get('currentAccount');
      }
      var session = this.store.peekRecord('session', 'current');
      return session.get('account');
    },

    defineActionMacros: function defineActionMacros() {
      var _this = this;

      //isNoAnswerAction, isBusyWaitOverAction, isOfflineAction, isQueueFullAction
      ActionTypes.forEach(function (actionType) {
        var propName = ["is_", actionType].join("").camelize();
        defineProperty(_this, propName, _ember['default'].computed.equal('actionType', actionType));
      });
    },

    defineRouteTypeMacros: function defineRouteTypeMacros() {
      var _this2 = this;

      //isAgentHunt, isTeamHunt, isExternalNumberHunt, isAllAgentsHunt
      var routeTypes = this.get('routeTypes');
      routeTypes.forEach(function (routeType) {
        var propName = ["is_", routeType.abbr, "_hunt"].join("").camelize();
        defineProperty(_this2, propName, _ember['default'].computed('routeType', function () {
          var route = this.get('routeType');
          if (route) {
            return _ember['default'].isEqual(route.camelize(), routeType.abbr);
          } else {
            return _ember['default'].isEqual(route, routeType.abbr);
          }
        }));
      });
    },

    defineActionOptionsMacros: function defineActionOptionsMacros() {
      // noAnswerActionOption, busyWaitOverActionOption , offlineActionOption, queueFullActionOption
      var self = this;
      ActionTypes.forEach(function (action) {
        var propName = [action, "_option"].join("").camelize();
        defineProperty(self, propName, computed(action, {
          get: function get() {
            return self.getActionByAbbr(self.get(action));
          },
          set: function set(k, v) {
            self.set(action, v.abbr);
            return v;
          }
        }));
      });
    },

    defineChildrenValidationMacros: function defineChildrenValidationMacros() {
      var _this3 = this;

      var childFallbackActions = ['noAnswer', 'offline', 'busyWaitOver', 'queueFull', 'timeThreshold', 'callbackFail'],
          models = ['CallQueue', 'RoutingAutomation'];

      childFallbackActions.forEach(function (fallbackAction) {
        models.forEach(function (model) {
          var propName = ('isValid_' + fallbackAction + model).camelize(),
              property = '' + fallbackAction + model;

          defineProperty(_this3, propName, computed(property, property + '.isValid', function () {
            if (!this.get(property) || !this.get(property).get('content')) {
              return true;
            }

            return model === 'CallQueue' ? this.get(property).get('isDeepValid') : this.get(property).get('content.isValid');
          }));
        });
      });
    },

    activeChildMessage: computed({
      get: function get() {
        if (this.get('isNoAnswerAction')) {
          return this.get('noAnswerMessage');
        } else if (this.get('isBusyWaitOverAction')) {
          return this.get('busyWaitOverMessage');
        } else if (this.get('isOfflineAction')) {
          return this.get('offlineMessage');
        } else if (this.get('isQueueFullAction')) {
          return this.get('queueFullMessage');
        } else if (this.get('isTimeThresholdAction')) {
          return this.get('timeThresholdMessage');
        } else if (this.get('isQuitQueueAction')) {
          return this.get('quitQueueMessage');
        } else if (this.get('isCallbackFailAction')) {
          return this.get('callbackFailMessage');
        }
      },
      set: function set(k, v) {
        if (isEmpty(v)) {
          return;
        }
        if (this.get('isNoAnswerAction')) {
          this.set('noAnswerMessage', v);
        } else if (this.get('isBusyWaitOverAction')) {
          this.set('busyWaitOverMessage', v);
        } else if (this.get('isOfflineAction')) {
          this.set('offlineMessage', v);
        } else if (this.get('isQueueFullAction')) {
          this.set('queueFullMessage', v);
        } else if (this.get('isTimeThresholdAction')) {
          this.set('timeThresholdMessage', v);
        } else if (this.get('isQuitQueueAction')) {
          this.set('quitQueueMessage', v);
        } else if (this.get('isCallbackFailAction')) {
          this.set('callbackFailMessage', v);
        }
        return v;
      }
    }),

    activeChildMessageType: computed('activeChildMessage', {
      get: function get() {
        if (this.get('isNoAnswerAction')) {
          return 'noAnswerMessage';
        } else if (this.get('isBusyWaitOverAction')) {
          return 'busyWaitOverMessage';
        } else if (this.get('isOfflineAction')) {
          return 'offlineMessage';
        } else if (this.get('isQueueFullAction')) {
          return 'queueFullMessage';
        } else if (this.get('isTimeThresholdAction')) {
          return 'timeThresholdMessage';
        } else if (this.get('isQuitQueueAction')) {
          return 'quitQueueMessage';
        } else if (this.get('isCallbackFailAction')) {
          return 'callbackFailMessage';
        } else {
          return 'message';
        }
      }
    }),

    externalNumberObject: computed('selectedRouteType', 'routeType', 'externalNumber', function () {
      return {
        name: this.get('externalNumber'),
        id: 'externalNumber',
        constructor: {
          modelName: 'externalNumber'
        }
      };
    }),

    routeTypeWithObject: computed('routeType', 'selectedRouteType', 'agent', 'team', 'externalNumber', function () {
      var route = this.get('routeType');
      route = route === 'externalNumber' ? 'externalNumberObject' : route;
      if (route) {
        return this.get(route);
      }
    }),
    selectedRouteOption: computed({
      set: function set(k, v) {
        this.set('routeOption', this.get('routeOptions').findBy('id', v.id).abbr);
        return v;
      },
      get: function get() {
        return this.get('routeOptions').findBy('abbr', this.get('routeOption'));
      }
    }),
    selectedRouteType: computed('routeType', {
      get: function get() {
        return this.get('routeTypes').findBy('abbr', this.get('routeType'));
      },
      set: function set(k, v) {
        this.set('routeType', v.abbr || v);
        return v;
      }
    }),
    selectedAutoAnswerOption: computed({
      set: function set(k, v) {
        this.set('autoAnswerStatus', v && this.get('autoAnswerOptions').findBy('id', v.id).abbr);

        return v;
      },
      get: function get() {
        return this.get('autoAnswerOptions').findBy('abbr', this.get('autoAnswerStatus'));
      }
    }),

    getAllValidationErrors: computed('routeTypeWithObject', function () {
      return [this.get('errors.routeTypeWithObject'), this.get('errors.externalNumber')];
    }),

    allAgents: computed(function () {
      return { name: 'All Agents', id: 'allAgents', isAllAgents: true, constructor: { modelName: 'allAgents' } };
    }),

    getActionByAbbr: function getActionByAbbr(actionAbbr) {
      return this.get('actionOptions').findBy('abbr', actionAbbr);
    },

    actionOptions: computed('isChild', function () {
      var options = [{ id: 1, desc: _ember['default'].i18n.t('call_queue.edit.fallback.vm.name'), abbr: 'vm', helpText: _ember['default'].i18n.t('call_queue.edit.fallback.vm.help_text') }, { id: 2, desc: _ember['default'].i18n.t('call_queue.edit.fallback.hangup.name'), abbr: 'hangup', helpText: _ember['default'].i18n.t('call_queue.edit.fallback.hangup.help_text') }];

      if (this.getCurrentAccount().hasFeature('no_call_queues_restrictions')) {
        options.push({ id: 3, desc: _ember['default'].i18n.t('call_queue.edit.fallback.another_queue.name'), abbr: 'call_queue' });
      }

      if (this.getCurrentAccount().hasFeature('wait_queue') && this.getCurrentAccount().hasFeature('voicemail_and_status_fallback') && this.getCurrentAccount().hasFeature('advanced_wait_queue')) {
        options.push({ id: 4, desc: _ember['default'].i18n.t('call_queue.edit.fallback.wait_queue.name'), abbr: 'wait_queue' });
      }
      if (this.get('isChild') || !this.getCurrentAccount().hasFeature('smart_escalation')) {
        options = options.rejectBy('id', 3);
      }

      if (this.checkFeature('agent_extension') && this.hasGlobalFeature('agent_extension_fallback')) {
        options.push({
          id: 5,
          desc: _ember['default'].i18n.t('call_queue.edit.fallback.agent_extension.name'),
          abbr: 'agent_extension'
        });
      }

      if (this.get('routingAutomationAsFallback')) {
        options.push({
          id: 7,
          desc: _ember['default'].i18n.t('call_queue.edit.fallback.routing_automation.name'),
          abbr: 'routing_automation'
        });
      }
      return options;
    }),

    validateAndSaveChildFlows: function validateAndSaveChildFlows(parentRoutingAutomationId) {
      var _this4 = this;

      var promiseArray = [];

      ActionTypes.forEach(function (actionType) {
        var action = _this4.get(actionType.replace(/Action/, _this4.get(actionType) && _this4.get(actionType).camelize().capitalize()));

        if (action && action.get('content') && action.get('id') !== parentRoutingAutomationId) {
          promiseArray.push(action.then(function (action) {
            return action.validate().then(function () {
              return _ember['default'].run(function () {
                return action.save();
              });
            });
          }));
        }
      });

      return Promise.all(promiseArray);
    },
    validate: function validate(parentRoutingAutomationId) {
      var validateSuper = this._super.bind(this);

      return this.validateAndSaveChildFlows(parentRoutingAutomationId).then(function () {
        return new Promise(function (resolve, reject) {
          _ember['default'].run(function () {
            validateSuper().then(function () {
              return resolve();
            })['catch'](reject);
          });
        });
      });
    },
    quitQueueOptions: computed(function () {
      var _this5 = this;

      if (this.hasGlobalFeature("voicemail_and_hangup_fallback")) {
        return this.get('actionOptions').filter(function (actionOption) {
          return _this5.get('hasFallbackFlowFeature') ? actionOption.abbr === 'vm' || actionOption.abbr === 'hangup' : actionOption.abbr === 'hangup';
        });
      }
      return this.get('actionOptions').filterBy('abbr', this.getCurrentAccount().hasFeature('voicemail_and_status_fallback') ? 'vm' || 'hangup' : 'hangup');
    }),

    isDeepValid: computed('isValid', function () {
      this.validate()['catch'](function () {});
      return this.get("isValid") && this.get('validChildQueues');
    }),

    hasFallbackFlowFeature: computed(function () {
      return this.getCurrentAccount().hasFeature('voicemail_and_status_fallback');
    })
  });
});
/* eslint-disable ember-suave/no-direct-property-access */