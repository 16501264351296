define('freshfone-ember/routes/protected/admin/sip', ['exports', 'ember', 'freshfone-ember/routes/promotable'], function (exports, _ember, _freshfoneEmberRoutesPromotable) {
    var computed = _ember['default'].computed;
    exports['default'] = _freshfoneEmberRoutesPromotable['default'].extend({
        titleToken: computed(function () {
            return _ember['default'].i18n.t('admin_components.SipPhone');
        }),
        breadCrumb: computed(function () {
            return { title: _ember['default'].i18n.t('admin_components.SipPhone') };
        }),

        beforeModel: function beforeModel() {
            // Load all users into the store, as agent's name depends on user
            return this.checkPrivilege('manage_sip') && this.store.findAll('user');
        },
        model: function model() {
            return this._super(this.store.findAll('agent', { reload: true }), this.store.query('agent', { sip: true }), 'answering_on_sip');
        }
    });
});