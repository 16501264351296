define('freshfone-ember/utils/call-constants', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var callConstants = {
    STATUS_HASH: {
      0: 'default',
      1: 'completed',
      2: 'in-progress',
      3: 'transferring',
      5: 'hold',
      19: 'bot_inprogress'
    },
    STATUS_STR_HASH: {
      'default': 0,
      'completed': 1,
      'in-progress': 2,
      'transferring': 3,
      'hold': 5,
      'recently-completed': 15,
      'bot_inprogress': 19
    },

    TYPE_HASH: {
      incoming: {
        id: 1,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.incoming');
        })
      },
      outgoing: {
        id: 2,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.outgoing');
        })
      }
    },

    BUSINESS_HOUR_CALL_HASH: {
      within: {
        id: 1,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.within');
        })
      },
      outside: {
        id: 0,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.outside');
        })
      }
    },

    STATUS_TYPE_HASH: {
      'answered': {
        id: 1,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.answered');
        })
      },
      'missed': {
        id: 3,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.missed');
        })
      },
      'call failed': {
        id: 4,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.failed');
        })
      },
      'queued': {
        id: 6,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.queued');
        })
      },
      'queued-ringing': {
        id: 7,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.queued');
        })
      },
      'waitqueue-timedout': {
        id: 18,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.queued');
        })
      },
      'in-progress': {
        id: 8,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.in-progress');
        })
      },
      'bot_inprogress': {
        id: 19,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.in-progress');
        })
      },
      'voicemail': {
        id: 10,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.voicemail');
        })
      },
      'on-hold': {
        id: 12,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.on-hold');
        })
      },
      'blocked': {
        id: 9,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.blocked');
        })
      },
      'abandoned': {
        id: 17,
        name: computed(function () {
          return _ember['default'].i18n.t('call_log.call_filters.abandoned');
        })
      }
    },

    STATUS_REVERSE_HASH: {
      1: 'answered',
      3: 'missed',
      6: 'queued',
      7: 'queued-ringing',
      18: 'waitqueue-timedout',
      8: 'in-progress',
      10: 'voicemail',
      12: 'on-hold',
      17: 'abandoned',
      19: 'bot_inprogress'
    },

    TYPE_REVERSE_HASH: {
      1: 'incoming',
      2: 'outgoing'
    },
    INCOMPLETED_CALL_STATUS_HASH: {
      0: 'in-progress',
      1: 'on-hold',
      2: 'queued',
      3: 'voicemail_inprogress',
      4: 'transferring',
      5: 'connecting',
      6: 'default',
      7: 'canceled',
      8: 'cancelled',
      9: 'queued-ringing',
      10: 'waitqueue-timedout',
      11: 'bot_inprogress'
    },

    INCOMPLETED_CALL_STATUS: ['in-progress', 'on-hold', 'queued', 'voicemail_inprogress', 'transferring', 'connecting', 'default', 'canceled', 'cancelled', 'queued-ringing', 'waitqueue-timedout'],

    WAIT_QUEUE_ACTIONS_HASH: [{
      id: 1,
      name: computed(function () {
        return _ember['default'].i18n.t('call_log_v1.call_filters.wait_queue_supervisor');
      })
    }, {
      id: 2,
      name: computed(function () {
        return _ember['default'].i18n.t('call_log_v1.call_filters.wait_queue_agent');
      })
    }],

    CONTACT_SEARCH_API_VERISON: 2,
    ONE_CLICK_INTEGRATION_API_VERSION: 'v2'
  };exports['default'] = callConstants;
});