define('freshfone-ember/components/billing-base/subscription-base/request-downgrade-info/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend({
    notification: service('toastr'),
    cancelDowngradeRequest: function cancelDowngradeRequest() {
      var _this = this;

      this.get('scheduledCustomer').cancelDowngrade().then(function (response) {
        window.location.reload();
      })['catch'](function (error) {
        _this.get('notification').error(_ember['default'].i18n.t('billing.request_downgrade.failed_cancel_downgrade'));
      });
    },

    actions: {
      openModal: function openModal() {
        this.set('isCancelDowngrade', true);
      },
      closeCallback: function closeCallback() {
        this.set('isCancelDowngrade', false);
      },
      deleteCallback: function deleteCallback() {
        this.set('isCancelDowngrade', false);
        this.get('notification').info(_ember['default'].i18n.t('billing.request_downgrade.processing_cancel_downgrade'));
        this.cancelDowngradeRequest();
      }
    }
  });
});