define('freshfone-ember/routes/protected/admin/callflows/index', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.call_workflows');
    }),
    features: ['ivr'],
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');

      return this.store.findAll('message');
    },
    model: function model() {
      return this.store.query('callFlow', {});
    },
    actions: {
      'delete': function _delete(callFlow) {
        callFlow.destroyRecord();
      }
    }
  });
});