define('freshfone-ember/components/company-component/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/model-form-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsModelFormMixin) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var isEqual = _ember['default'].isEqual;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    currentUser: computed.alias('session.user'),
    sortedCompaniesList: computed.sort('model', 'sortDefinition'),
    sortDefinition: ['name:asc', 'isNew:asc'],
    isEmptyCompany: computed.empty('company'),
    hasCompanyCallsLimitedFeature: computed(function () {

      return this.hasGlobalFeature('limit_company_calls');
    }),
    actions: {
      closeCallback: function closeCallback() {
        this.set('isDelete', false);
      },
      toggleDelete: function toggleDelete(company) {
        this.set('isDelete', true);;
        this.set('deleteItem', company);
      },
      deleteCallback: function deleteCallback(company, options) {
        var _this = this;

        company.setProperties(options);
        company.destroyRecord().then(function () {
          _this.send('onModify');
          _this.send('afterDelete', company);
          _this.send('closeCallback');
        });
      },
      onModify: function onModify() {
        this.set('modifyModel', true);
      },
      afterDelete: function afterDelete(item) {
        if (isEqual(item.id, this.get('company.id')) || this.get("isEmptyCompany")) {
          this.set('company', null);
          this.set('slideToggle', false);
          this.set('formMode', false);
        }
      },
      edit: function edit() {
        this._super.apply(this, arguments);
        this.set('slideToggle', true);
        this.set('formMode', true);
      }
    }
  });
});