define('freshfone-ember/components/number-component/purchase-form/tollfree-purchase/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/purchase-form.mixin', 'freshfone-ember/utils/freshfone-number-util'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsPurchaseFormMixin, _freshfoneEmberUtilsFreshfoneNumberUtil) {
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsPurchaseFormMixin['default'], {
    countries: _freshfoneEmberUtilsFreshfoneNumberUtil['default'].tollfreeCountries(),
    country: computed('countries.@each.code', function () {
      return this.get('countries').findBy('code', 'US');
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },

    prefixes: computed('country.code', function () {
      var country = this.get('country.code'),
          prefixes = _freshfoneEmberUtilsFreshfoneNumberUtil['default'].tollfreePrefixes(country, 'numbers'),
          prefix = _freshfoneEmberUtilsFreshfoneNumberUtil['default'].tollfreePrefixes(country, 'default');
      this.send('setPrefixChange', prefix);
      return prefixes;
    }),

    showPrefixes: computed('prefixes', function () {
      return this.get('prefixes').length > 1;
    }),

    rate: computed('country.code', function () {
      var country = this.get('country.code');
      return _freshfoneEmberUtilsFreshfoneNumberUtil['default'].rate('toll_free', country);
    }),

    buildSearchParams: function buildSearchParams() {
      var prefix = this.get('prefix');
      return {
        type: 'toll_free',
        country: this.get('country.code'),
        area_code: isEmpty(prefix) || prefix === _ember['default'].i18n.t('numbers.purchase.any').string ? '' : prefix,
        contains: this.get('contains') || ''
      };
    },

    actions: {
      setPrefixChange: function setPrefixChange(prefix) {
        this.set('prefix', prefix);
        this.send('search');
      },
      search: function search() {
        var params = this.buildSearchParams();
        this.sendAction('search', params);
      },
      buy: function buy(params) {
        params['rate'] = this.get('rate');
        params['address'] = {};
        this.sendAction('buy', params);
      }
    }

  });
});