define('freshfone-ember/components/search-results/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['search-results'],
    isCompanyExpanded: true,
    isContactExpanded: true,
    pushToStore: _ember['default'].observer('results', function () {
      var results = this.get('results');
      console.log('results', results);
      if (_ember['default'].isEmpty(results)) {
        this.set('contacts', []);
        return;
      }
      this.setContacts(results);
    }),

    setContacts: function setContacts(contactRecords) {
      if (_ember['default'].isEmpty(contactRecords)) {
        return;
      }
      this.store.pushPayload('contact', contactRecords);
      var resultantContactIds = contactRecords.data.mapBy('id');
      var filteredContacts = this.store.peekAll('contact').filter(function (contactObject) {
        return resultantContactIds.contains(contactObject.get('id'));
      });
      this.set('contacts', filteredContacts);
    },
    noResults: _ember['default'].computed('contacts', 'companies', function () {
      var self = this;
      return _ember['default'].isEmpty(self.get('contacts')) && _ember['default'].isEmpty(self.get('companies'));
    }),
    actions: {
      toggleCompanyList: function toggleCompanyList() {
        this.toggleProperty('isCompanyExpanded');
      },
      toggleContactList: function toggleContactList() {
        this.toggleProperty('isContactExpanded');
      },
      mergeCompany: function mergeCompany(company) {
        console.log('mergeCompany');
        this.get('parent').send('mergeCompany', company);
      },
      selectedContact: function selectedContact(contact) {
        this.sendAction('pickedContact', contact);
        this.set('results', null);
        this.set('contactSelectedFlag', false);
        // pushing the selected contact to array
        // and hope it works
        var _selectedContact = this.get('contactArray') || [];
        _selectedContact.push(contact);
        if (_selectedContact.length > 3) {
          _selectedContact.shift();
        }
        this.set('contactArray', _selectedContact);
      }
    }
  });
});