define('freshfone-ember/helpers/is-equal-array', ['exports', 'ember'], function (exports, _ember) {
  exports.isEqualArray = isEqualArray;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function isEqualArray(params) {
    return [].concat(_toConsumableArray(params[0])).sort().toString() == [].concat(_toConsumableArray(params[1])).sort().toString();
  }

  exports['default'] = _ember['default'].Helper.helper(isEqualArray);
});