define('freshfone-ember/components/trial-component/component', ['exports', 'ember', 'ember-data', 'freshfone-ember/mixins/feature-check-mixin', 'ember-authority/helpers/has-privilege'], function (exports, _ember, _emberData, _freshfoneEmberMixinsFeatureCheckMixin, _emberAuthorityHelpersHasPrivilege) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    init: function init() {
      this._super.apply(this, _toConsumableArray(this));
      this.bindNodeEvents();
    },

    currentUser: computed.alias('session.user'),
    classNames: ["trial-box"],
    classNameBindings: ['session.account.isUnifiedAppAccount:unified-app-nav-top'],
    trialText: _ember['default'].i18n.t('trial.trial_text'),
    telephony: service('telephony'),
    subscription: computed.alias('session.account.subscription'),
    remainingTrialDays: computed.alias('subscription.remainingTrialDays'),

    planName: computed(function () {
      return _ember['default'].i18n.t('trial.plan_name', { plan_name: this.get('subscription.planName') });
    }),
    showDemo: computed(function () {
      return 'You can always Request a demo from here';
    }),
    trialExpired: computed.equal('subscription.statusText', 'trial_expired'),
    showTrialBox: computed('subscription.statusText', function () {
      var statusText = this.get('subscription.statusText');
      return ['trial', 'trial_expired'].includes(statusText);
    }),
    hasTestConnectionFeature: computed(function () {
      return this.hasGlobalFeature("test_connection");
    }),
    showTrialWarning: computed.alias('trialExpired'),
    demoRequestStatus: computed.alias('session.account.demoRequested'),
    hasRequestDemoPrivilage: computed('currentUser', function () {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, { privilege: 'request_demo', user: this.get('currentUser') });
    }),
    statusText: computed('trialExpired', 'subscription.remainingTrialDays', function () {
      if (this.get('trialExpired')) {
        return _ember['default'].i18n.t('trial.expired_text');
      }
      return "- " + this.get('subscription.remainingTrialDays') + _ember['default'].i18n.t('trial.days_remaining');
    }),
    hasRequestDemoFeature: computed(function () {
      return this.checkFeature('request_demo');
    }),
    bindNodeEvents: function bindNodeEvents() {
      var self = this;
      self.get('telephony').on('loaded', function () {
        self.get('telephony.event').subscribe('account_status_change', self.accountStatusCallback.bind(self));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unsubscribeStatusChangeCallback();
    },
    accountStatusCallback: function accountStatusCallback(data) {
      this.set('showTrialBox', data.isTrial || data.isTrialExpired);
    },
    unsubscribeStatusChangeCallback: function unsubscribeStatusChangeCallback() {
      this.get('telephony.event').unsubscribe('account_status_change', this.accountStatusCallback.bind(this));
    },
    actions: {
      toggleDemoRequest: function toggleDemoRequest(demoRequestSubmitted) {
        this.toggleProperty('openDemoRequest');
      }
    }
  });
});