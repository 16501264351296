define('freshfone-ember/helpers/currency-types', ['exports'], function (exports) {
  var supportedCurrencies = {
    USD: {
      currencyName: 'USD',
      id: 1
    },
    INR: {
      currencyName: 'INR',
      id: 2
    },
    EUR: {
      currencyName: 'EUR',
      id: 3
    },
    GBP: {
      currencyName: 'GBP',
      id: 4
    },
    AUD: {
      currencyName: 'AUD',
      id: 5
    },
    ZAR: {
      currencyName: 'ZAR',
      id: 6
    },
    BRL: {
      currencyName: 'BRL',
      id: 7
    }
  },
      rebrandedSupportedCurrencies = {
    USD: {
      currencyName: 'USD',
      id: 1
    },
    INR: {
      currencyName: 'INR',
      id: 2
    },
    EUR: {
      currencyName: 'EUR',
      id: 3
    },
    GBP: {
      currencyName: 'GBP',
      id: 4
    },
    AUD: {
      currencyName: 'AUD',
      id: 5
    },
    ZAR: {
      currencyName: 'ZAR',
      id: 6,
      disabled: true
    },
    BRL: {
      currencyName: 'BRL',
      id: 7,
      disabled: true
    }
  },
      defaultCurrency = 'USD',
      currencyConversion = {
    USD: 1,
    INR: 72.09,
    EUR: 0.88,
    GBP: 0.75,
    AUD: 1.44,
    ZAR: 14.98,
    BRL: 5.2
  },
      currencyConversionV2 = {
    USD: 1,
    INR: 81.39,
    EUR: 0.97,
    GBP: 0.83,
    AUD: 1.49,
    ZAR: 16.95,
    BRL: 5.27
  },
      currencySymbols = {
    USD: '$',
    INR: '₹',
    EUR: '€',
    GBP: '£',
    AUD: 'A$',
    ZAR: 'R',
    BRL: 'R$'
  };
  exports.supportedCurrencies = supportedCurrencies;
  exports.rebrandedSupportedCurrencies = rebrandedSupportedCurrencies;
  exports.defaultCurrency = defaultCurrency;
  exports.currencyConversion = currencyConversion;
  exports.currencyConversionV2 = currencyConversionV2;
  exports.currencySymbols = currencySymbols;
});