define('freshfone-ember/routes/protected/admin/deflection-bot/new', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    breadCrumb: {},
    features: ['deflection_bot'],
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.new_flow');
    }),
    queryParams: {
      editNumber: {},
      actionFor: {}
    },
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.checkFeature('deflection_bot');
    },
    model: function model(params) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this.store.findAll('deflectionBot').then(function () {
          // load into store for count increment in default name
          var newFlow = _this.store.peekRecord('callFlow', { isNew: true });
          newFlow = newFlow || _this.store.createRecord('callFlow');
          newFlow.set('deflectionBot', _this.store.createRecord('deflectionBot'));
          if (params.rule) {
            _this.set("rule", params.rule);
          }
          resolve(newFlow);
        });
      });
    },

    afterModel: function afterModel(model) {
      var deflectionBotRecord = this.store.peekAll('deflectionBot').content.length;
      set(this, 'breadCrumb.title', 'Smart Answer Flow ' + deflectionBotRecord);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('rule', undefined);
      }
    }

  });
});