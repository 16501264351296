define('freshfone-ember/constants/search-query-constants', ['exports'], function (exports) {
  exports['default'] = {
    source: {
      dialler: 'dialler',
      dropdown: 'dropdown'
    },
    version: 2,
    searchModel: {
      externalContact: 'external-contact',
      contact: 'contact'
    }
  };
});