define('freshfone-ember/components/search-bar-dropdown/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['search-input'],
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        query: null,
        searchType: "notes",
        searchIcon: "notes-search",
        placeholderText: _ember['default'].i18n.t('common.notes_search')
      });
    },

    actions: {
      loadOptions: function loadOptions() {
        var searchOptions = _ember['default'].A();
        var searchSelect = { icon: 'notes-search', desc: 'Notes', type: 'notes' };
        searchOptions.pushObject(searchSelect);

        searchSelect = { icon: 'post-call-transcript', desc: 'Call Transcript', type: 'transcript' };
        searchOptions.pushObject(searchSelect);
        this.set('searchOptions', searchOptions);
      },
      setSearch: function setSearch(searchType) {
        this.setProperties({
          searchType: searchType.type,
          searchIcon: searchType.icon,
          placeholderText: _ember['default'].i18n.t('common.' + searchType.type + '_search')
        });
      },
      searchCalls: function searchCalls() {
        if (this.get('searchType') === "notes") this.sendAction('searchNotes');else this.sendAction('searchTranscript');
      }
    }
  });
});