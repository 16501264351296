define('freshfone-ember/components/request-demo/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/validations/number'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsValidationsNumber) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsValidationsNumber['default'], _emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsModelFormMixin['default'], {
    telephony: service(),
    currentAccount: computed.alias('session.account'),
    userName: computed.alias('session.user.name'),
    userPhone: computed.alias('session.user.phone'),
    headerOnboardComplete: computed('callerNumber', function () {
      return _ember['default'].i18n.t('onboarding.demo_requested_header_onboard_complete', { phone_number: this.get('callerNumber'), productName: this.get('currentAccount.productName') });
    }),
    invalidNumberErrorMessage: _ember['default'].i18n.t('activemodel.errors.invalid_number').string,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('demo.firstName', this.get('userName'));
      if (this.get('userPhone') && !this.get('callerNumber')) {
        this.set('demo.phoneNumber', this.get('userPhone'));
      }
      if (this.get('callerNumber')) {
        this.set('demo.phoneNumber', this.get('callerNumber'));
      }
    },
    demo: computed({
      set: function set(key, value) {
        this.set('startValidate', false);
        if (value && value.get('id')) {
          this.set('slideToggle', true);
          return value;
        }
      },
      get: function get() {
        return this;
      }
    }),
    isDirty: computed('demo.hasDirtyAttributes', 'demo.[]', 'demo.@each.*', {
      get: function get() {
        return this.get('demo').isDeepDirty();
      }
    }).readOnly(),

    email: computed.alias('session.user.email'),
    numberError: false,
    validateForm: function validateForm() {
      var _this = this;

      this.validate().then(function () {
        if (_this.numberError) {
          _this.set('errors.demo.phoneNumber', _this.get('invalidNumberErrorMessage'));
          _this.set('startValidate', true);
          _this.set('disabled', false);
        } else {
          _ember['default'].$.ajax({ url: '/accounts/request_demo', type: 'POST', dataType: 'json',
            data: _this.contructDemoRequestParams()
          }).then(function (data) {
            _this.afterDemoRequestSuccess();
          }).fail(function (errorResponse) {
            _this.set('requestDemoError', errorResponse.responseJSON.errors[0]);
            _this.set('disabled', false);
          });
        }
      })['catch'](function (error) {
        if (_this.numberError) _this.set('errors.demo.phoneNumber', _this.get('invalidNumberErrorMessage'));
        _this.set('startValidate', true);
        _this.set('disabled', false);
      });
    },
    contructDemoRequestParams: function contructDemoRequestParams() {
      return { "first_name": this.get('demo.firstName'), "last_name": this.get('demo.lastName'),
        "company": this.get('demo.companyName'), "email": this.get('email'),
        "work_number": this.get('demo.phoneNumber'), "current_phone_system": this.get('demo.pbx') };
    },
    afterDemoRequestSuccess: function afterDemoRequestSuccess() {
      this.set('disabled', false);
      this.set('requestDemoSubmitted', true);
      this.set('openDemoRequest', false);
      this.set('currentAccount.demoRequested', true);
    },
    actions: {
      closeRequestDemoModal: function closeRequestDemoModal() {
        this.toggleProperty('openDemoRequest');
      },
      save: function save() {
        var _this2 = this;

        this.set('disabled', true);
        if (this.get('demo.phoneNumber')) {
          this.get('telephony.util').isValidNumber(this.get('demo.phoneNumber'), null, { isRequestDemo: true }).then(function (res) {
            _this2.set('numberError', false);
            if (_this2.get('errors.demo.phoneNumber')) _this2.set('errors.demo.phoneNumber', null);
            _this2.validateForm();
          })['catch'](function (error) {
            _this2.set('numberError', true);
            _this2.validateForm();
          });
        } else {
          this.validateForm();
        }
      },
      setCallerNumber: function setCallerNumber() {
        if (this.get('callerNumber')) {
          this.set('callerNumber', this.get('demo.phoneNumber'));
        }
      }
    },
    validations: {
      'demo.firstName': {
        format: { presence: true, message: _ember['default'].i18n.t('activemodel.errors.not_empty') },
        length: { maximum: 128,
          messages: { tooLong: _ember['default'].i18n.t('onboarding.validation_error.name_long') }
        }
      },
      'demo.lastName': {
        format: { presence: true, message: _ember['default'].i18n.t('activemodel.errors.not_empty') },
        length: { maximum: 128,
          messages: { tooLong: _ember['default'].i18n.t('onboarding.validation_error.name_long') }
        }
      },
      'demo.companyName': {
        format: { presence: true, message: _ember['default'].i18n.t('activemodel.errors.not_empty') },
        length: { maximum: 128,
          messages: { tooLong: _ember['default'].i18n.t('onboarding.validation_error.company_name_long') }
        }
      },
      'demo.phoneNumber': {
        format: { presence: true, message: _ember['default'].i18n.t('activemodel.errors.not_empty') }
      },
      'demo.pbx': {
        length: { maximum: 128,
          messages: { tooShort: _ember['default'].i18n.t('onboarding.validation_error.pbx_long') }
        }
      }
    }
  });
});