define('freshfone-ember/components/live-calls/slm-container/gauge-chart/component', ['exports', 'ember', 'freshfone-ember/constants/gauge-chart'], function (exports, _ember, _freshfoneEmberConstantsGaugeChart) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var assign = _ember['default'].assign;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({

    didReceiveAttrs: function didReceiveAttrs() {
      this.updateChart();
    },

    updateChart: function updateChart() {
      var chart = _ember['default'].$('.solid-gauge-chart .chart-container').highcharts();
      if (chart && this.get('selectedQueueMetricId') === this.get('metricId')) {
        this.redrawChart(chart);
      }
      if (this.get('metricId')) {
        var queueListChart = _ember['default'].$('.solid-gauge-chart-' + this.get('metricId') + ' .chart-container').highcharts();
        if (queueListChart) {
          this.redrawChart(queueListChart);
          this.redrawDataLables(queueListChart);
        }
      }
    },

    redrawChart: function redrawChart(chart) {
      chart.series[0].update({
        borderColor: this.gaugeColor()
      });
      chart.yAxis[0].setExtremes(1, this.get('target'));
      chart.series[0].setData([this.get('percentage')], true);
      chart.redraw();
    },

    redrawDataLables: function redrawDataLables(chart) {
      chart.series[0].update({
        dataLabels: {
          format: '<div style="position:relative;' + this.getLabelStyle() + '"><span class="label-value">' + this.get('percentage') + '%</span></div>'
        }
      });
    },

    getLabelStyle: function getLabelStyle() {
      if (this.get('percentage') < 10) {
        return 'right: -5px';
      } else if (this.get('percentage') == 100) {
        return 'right: 5px';
      }
    },

    chartContent: computed(function () {
      var labelStyle = this.getLabelStyle();
      return {
        max: this.get('target'),
        series: [{
          data: [this.get('percentage')],
          dataLabels: {
            format: '<div style="position:relative;' + labelStyle + '"><span class="label-value">{y}%</span></div>'
          }
        }]
      };
    }),

    plotData: computed('chartContent.series', function () {
      return {
        series: get(this, 'chartContent.series')
      };
    }),

    gaugeColor: function gaugeColor() {
      var range = this.get('relativeMetricPercentage');
      range = range ? range : 0;
      if (range < 33) {
        return '#FF5C28';
      } else if (range < 66) {
        return '#E5A250';
      } else {
        return '#47CC79';
      }
    },

    plotOptions: computed(function () {
      var color = this.gaugeColor() || '#47CC79';
      return {
        plotOptions: {
          solidgauge: {
            borderColor: color,
            borderWidth: 13,
            radius: 88,
            innerRadius: '90%',
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true
            }
          }
        }
      };
    }),

    maxValue: computed('chartContent.max', function () {
      return {
        yAxis: {
          min: 0,
          max: this.get('chartContent.max'),
          lineWidth: 0,
          tickWidth: 0,
          minorTickLength: 0,
          minTickInterval: 500,
          labels: {
            enabled: false
          }
        }
      };
    }),

    chartValues: computed('plotData', function () {
      return assign(get(this, 'plotData'), get(this, 'maxValue'), get(this, 'plotOptions'), _freshfoneEmberConstantsGaugeChart['default'].gaugeOptions);
    })
  });
});