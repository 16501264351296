define('freshfone-ember/components/ivr-component/speech-ivr-root/component', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    botsList: [],
    store: service('store'),
    notification: service('toastr'),
    importService: service('import-service'),
    showImportModal: computed.alias('importService.importParams.showImportModal'),
    params: computed.alias('importService.importParams.params'),
    fileModel: computed.alias('importService.importParams.fileModel'),
    searchTerm: computed.alias('select.searchText'),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('getBotsList').perform();
    },

    getBotsList: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var data;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('ivr.keyOptions', []);
            context$1$0.next = 3;
            return this.get('ivr').getBotsList();

          case 3:
            data = context$1$0.sent;

            this.set('botsList', data.bot_type);
            data.bot_type.forEach(function (botName) {
              _this.store.createRecord('speech-ivr-bot', { 'name': botName });
            });
            this.send('getIntents', this.get('botName'));

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    botName: computed('botsList', 'ivr.botConfig', function () {
      if (this.get('ivr.botConfig.name')) {
        return this.get('ivr.botConfig.name');
      } else if (this.get('botsList').length) {
        this.set('ivr.botConfig', { 'name': this.get('botsList')[0] });
        return this.get('botsList')[0];
      }
    }),

    resetKeyPressOptions: function resetKeyPressOptions() {
      var _this2 = this;

      this.get('ivr.ivrKeyPressOptions').forEach(function (keypress) {
        _this2.sendAction('removeKeypress', keypress);
      });
    },

    actions: {
      setBotConfig: function setBotConfig(changedValue) {
        this.setProperties({
          'isBotChanged': true,
          'botName': changedValue,
          'ivr.botConfig': { 'name': changedValue }
        });
        this.resetKeyPressOptions();
        this.send('getIntents', changedValue);
      },

      createNewBot: function createNewBot(searchText) {
        var _this3 = this;

        var params = { "bot_type": searchText };
        return new _ember['default'].RSVP.Promise(function () {
          _this3.get('ivr').createNewBot(params).then(function (response) {
            if (response.message) {
              _this3.get('notification').success('Bot Created');
              _this3.store.createRecord('speech-ivr-bot', { 'name': searchText });
              _this3.setProperties({
                'botsList': [].concat(_toConsumableArray(_this3.get('botsList')), [searchText])
              });
              _this3.send('setBotConfig', searchText);
            }
          })['catch'](function (error) {
            _this3.get('notification').error(_ember['default'].i18n.t('speech_ivr.manage_intents.create_bot.error_message'));
          });
        });
      },
      getIntents: function getIntents(changedValue) {
        var _this4 = this;

        var bot = this.get('store').peekAll('speech-ivr-bot').filterBy('name', changedValue)[0];
        var isNewIvr = this.get('ivr.isNew');
        var isBotChanged = this.get('isBotChanged');
        bot.getIntentsList({ "bot_type": changedValue }).then(function (response) {
          var options = [];
          if (response.intent) {
            Object.keys(response.intent).forEach(function (intent) {
              options.push({ "name": intent, disabled: false });
            });
            _this4.set('ivr.keyOptions', options);
            if (isNewIvr || isBotChanged) _this4.sendAction('addNewKeyPress', true);
          }
        });
      },
      showNewMsg: function showNewMsg(message) {
        this.sendAction('showNewMessage', message);
      },
      showImport: function showImport() {
        this.setProperties({
          fileModel: 'bot_intents',
          params: { bot_type: this.get('botName') },
          showImportModal: true
        });
      }
    }
  });
});