define('freshfone-ember/models/pcipal-status', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    call: belongsTo('call'),
    status_text: attr(),
    call_id: attr(),
    ended_at: attr(),
    initiated_at: attr(),
    updated_at: attr()
  });
});