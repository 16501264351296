define('freshfone-ember/components/agent-status-component/agent-status-form/status-content/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    excludedCategories: ['flag'],
    emojiVersion: '9',
    actions: {
      toggleEmojiPicker: function toggleEmojiPicker() {
        this.toggleProperty('showEmojiPalette');
      },
      closeEmojiPicker: function closeEmojiPicker() {
        this.set('showEmojiPalette', false);
      }
    }
  });
});