define('freshfone-ember/components/conversation/conversation-filter/component', ['exports', 'ember', 'freshfone-ember/mixins/click-outside-listener', 'freshfone-ember/utils/call-constants'], function (exports, _ember, _freshfoneEmberMixinsClickOutsideListener, _freshfoneEmberUtilsCallConstants) {
  var computed = _ember['default'].computed;
  var Evented = _ember['default'].Evented;
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  var observer = _ember['default'].observer;
  var i18n = _ember['default'].i18n;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], Evented, {
    classNameBindings: ['isEditMode:conversation-filter-edit-mode'],
    currentConnection: service('current-connection'),
    currentCall: computed.alias('currentConnection.call'),
    currentContact: computed.alias('currentCall.contact'),
    isEmptyAgent: computed.empty('agents'),
    isEmptyDirection: computed.empty('direction'),
    isEmptyTimePeriod: computed.empty('timePeriod'),
    isEmptyCallTypes: computed.empty('callTypes'),
    emptyOptions: computed.and('isEmptyAgent', 'isEmptyDirection', 'isEmptyTimePeriod', 'isEmptyCallTypes'),
    isAgentsApplied: computed.notEmpty('appliedAgents'),
    isCallTypeApplied: computed.notEmpty('appliedCallTypes'),
    isTimePeriodApplied: computed.notEmpty('appliedTimePeriod'),
    isDirectionApplied: computed.notEmpty('appliedDirection'),
    hasAppliedOptions: computed.or('isAgentsApplied', 'isCallTypeApplied', 'isTimePeriodApplied', 'isDirectionApplied'),
    agents: _ember['default'].A(),
    callTypes: _ember['default'].A(),
    timePeriod: _ember['default'].A(),
    direction: null,
    appliedCallTypes: _ember['default'].A(),
    appliedAgents: _ember['default'].A(),
    appliedTimePeriod: _ember['default'].A(),
    appliedDirection: null,
    appliedTimePeriodFormatted: computed('timePeriod', function () {
      if (this.get('timePeriod').length == 0) {
        return;
      }
      if (this.get('appliedTimePeriod')[1] == '') return this.get('appliedTimePeriod')[0];
      return this.get('appliedTimePeriod')[0] + ' - ' + this.get('appliedTimePeriod')[1];
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set("isEditMode", true);
    },
    callTypeCollection: [_freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.answered, _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.missed, _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.voicemail],
    callDirectionCollection: [_freshfoneEmberUtilsCallConstants['default'].TYPE_HASH.incoming, _freshfoneEmberUtilsCallConstants['default'].TYPE_HASH.outgoing],
    filterDetails: computed('appliedAgents', 'appliedCallTypes', 'appliedTimePeriod', 'appliedDirection', function () {
      return [{ key: 'Type',
        value: (this.get('appliedCallTypes') || []).mapBy('name').join(", ")
      }, {
        key: 'Period',
        value: this.get('appliedTimePeriodFormatted')
      }, {
        key: 'Direction',
        value: this.get('appliedDirection.name')
      }, {
        key: 'Agents',
        value: (this.get('appliedAgents') || []).mapBy('name').join(", ")
      }];
    }),
    isEditMode: computed({
      get: function get() {
        return this || false;
      },
      set: function set(key, value) {
        var filterEvent = value ? 'filterIsOpen' : 'filterIsClosed';
        _ember['default'].sendEvent(this, filterEvent);
        return value;
      }
    }),
    isAgentsChanged: observer('agents', function () {
      if (!isEmpty(this.get('agents'))) {
        this.set('isEditMode', true);
      }
    }),
    canClearFilter: computed({
      set: function set(k, v) {
        if (v) {
          this.send('clearFilter');
        }
        return v;
      }
    }),
    buildParams: function buildParams() {
      return {
        agent_id: (this.get('agents') || []).mapBy('id'),
        direction: this.get('direction.id'),
        date_range: this.get('timePeriod'),
        status: (this.get('callTypes') || []).mapBy('id')
      };
    },

    sendFilterParams: function sendFilterParams() {
      this.set('isEditMode', false);
      var params = this.buildParams();
      this.set('appliedParams', params);
      this.sendAction('applyFilter', params);
    },

    onFilterOpen: _ember['default'].on('filterIsOpen', function () {
      this.bindOutsideClick();
    }),

    onFilterClose: _ember['default'].on('filterIsClosed', function () {
      this.setFilterFromAppliedParams();
      this.unbindOutsideClick();
    }),

    onOutsideClick: function onOutsideClick() {
      if (this.get('hasAppliedOptions')) {
        this.set('isEditMode', false);
      } else {
        this.send('hideFilter');
      }
    },
    setFilterFromAppliedParams: function setFilterFromAppliedParams() {
      this.setProperties({
        agents: this.get('appliedAgents'),
        callTypes: this.get('appliedCallTypes'),
        timePeriod: this.get('appliedTimePeriod'),
        direction: this.get('appliedDirection')
      });
    },
    setAppliedParams: function setAppliedParams() {
      this.setProperties({
        appliedAgents: this.get('agents'),
        appliedCallTypes: this.get('callTypes'),
        appliedTimePeriod: this.get('timePeriod'),
        appliedDirection: this.get('direction')
      });
    },

    actions: {
      applyFilter: function applyFilter() {
        this.setAppliedParams();
        if (!this.get('hasAppliedOptions')) {
          this.toggleProperty('isFilterOpen');
          this.send('clearFilter');
          return;
        }
        this.sendFilterParams();
      },

      hideFilter: function hideFilter() {
        this.set('isFilterOpen', false);
        this.setFilterFromAppliedParams();
      },

      editFilter: function editFilter() {
        this.set('isEditMode', true);
      },

      clearFilter: function clearFilter() {
        this.send('clearSelection');
        this.set('appliedParams', null);
        this.sendAction('applyFilter');
        this.send('hideFilter');
      },

      clearSelection: function clearSelection() {
        if (this.get('hasAppliedOptions')) {
          this.setProperties({
            agents: [], callTypes: [], timePeriod: [], direction: null,
            appliedAgents: [], appliedCallTypes: [], appliedTimePeriod: [],
            appliedDirection: null
          });
        }
      }
    }
  });
});