define('freshfone-ember/components/plan-modal/plan-switch/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    currentAccount: computed.alias('session.account'),
    anyMissingFeatures: computed.notEmpty('missingFeatures'),

    didInsertElement: function didInsertElement() {
      if (!this.get('anyMissingFeatures')) {
        this.set('subscription.planId', this.get('selectedPlan.id'));
        this.set('editMode', true);
      }
    },

    currentFeatures: computed('subscription.content.planId', function () {
      var planId = this.get('subscription.content.planId'),
          plan = this.store.peekRecord('plan', planId);

      return plan.get('planFeatures');
    }),

    missingFeatures: computed('currentFeatures', 'selectedPlan', function () {
      if (this.get('isClassicPlan')) {
        return true;
      }
      var currentFeatures = this.get('currentFeatures'),
          selectedPlanFeature = this.get('selectedPlan.planFeatures'),
          features = currentFeatures.filter(function (item) {
        return selectedPlanFeature.indexOf(item) === -1;
      });

      if (selectedPlanFeature.length > currentFeatures.length) {
        return;
      }

      return features.map(function (feature) {
        return feature.trim();
      }).sort();
    }),

    actions: {
      continueToPay: function continueToPay() {
        this.set('isSaving', true);
        this.set('subscription.planId', this.get('selectedPlan.id'));
        this.sendAction('continueToPay');
      },
      toggleEdit: function toggleEdit() {
        this.set('subscription.planId', this.get('selectedPlan.id'));
        this.set('editMode', true);
      }
    }
  });
});