define('freshfone-ember/routes/protected/contacts/show', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/routes/protected', 'freshfone-ember/mixins/titleize'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberRoutesProtected, _freshfoneEmberMixinsTitleize) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsTitleize['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    breadCrumb: {},
    titleToken: function titleToken(model) {
      return [model.get('name'), this.titleize(model._internalModel.modelName)].join(" : ");
    },
    conversations: null,
    modelFullfilled: false,
    beforeModel: function beforeModel() {
      if (this.hasOmniRedirectFeatureEnabled()) {
        return this.transitionTo('/dashboard');
      }
      this.checkPrivilege('view_customers');
    },
    model: function model(params) {
      set(this, 'modelFullfilled', true);
      return this.store.findRecord('contact', params.contact_id);
    },
    hasMorePages: computed.gt('model.totalCalls', 10),
    afterModel: function afterModel(model) {
      if (!this.get("modelFullfilled")) {
        model.reload();
      }
      var contactName = get(model, 'name');
      set(this, 'breadCrumb.title', contactName);
    }
  });
});