define('freshfone-ember/routes/protected/call-logs', ['exports', 'ember', 'freshfone-ember/routes/protected', 'freshfone-ember/constants/call-filter-configurations', 'freshfone-ember/utils/format-user-configuration'], function (exports, _ember, _freshfoneEmberRoutesProtected, _freshfoneEmberConstantsCallFilterConfigurations, _freshfoneEmberUtilsFormatUserConfiguration) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var computed = _ember['default'].computed;
  var hash = _ember['default'].RSVP.hash;
  var fetchModelsInFilterSettings = _freshfoneEmberConstantsCallFilterConfigurations['default'].fetchModelsInFilterSettings;
  var filterSettingsObject = _freshfoneEmberConstantsCallFilterConfigurations['default'].filterSettingsObject;
  var filterSettingsConstants = _freshfoneEmberConstantsCallFilterConfigurations['default'].filterSettingsConstants;
  var formatFilterConfiguration = _freshfoneEmberUtilsFormatUserConfiguration['default'].formatFilterConfiguration;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.call_metrics');
    }),
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('navigation.call_metrics') };
    }),
    queryParams: {
      showDetailsOf: {},
      user: {},
      contact: {},
      org_contact_id: {},
      company: {},
      selectedQueues: {},
      status: {},
      direction: {},
      call_id: {},
      isFromDashboard: false
    },
    ignoredExternalQueryParams: ['showDetailsOf', 'isFromDashboard'],

    // check if any filter is applied in query-param
    hasExternalFilterApplied: function hasExternalFilterApplied(queryParams) {
      var externalParams = this.get('queryParams');

      for (var key in externalParams) {
        if (this.get('ignoredExternalQueryParams').indexOf(key) > -1) {
          continue;
        }

        if (externalParams.hasOwnProperty(key) && queryParams[key]) {
          return true;
        }
      }

      return false;
    },

    beforeModel: function beforeModel(_ref) {
      var _this = this;

      var queryParams = _ref.queryParams;

      var controller = this.controller || this.generateController('protected/call-logs/index'),
          currentUser = this.store.peekRecord('session', 'current').get('user'),
          urlForRequest = '/users/' + currentUser.get('id') + '/user_configuration';

      if (this.get('session.account').hasFeature('restrict_call_logs') && currentUser.get('role.name') === 'Agent') {
        this.transitionTo('protected.error-page');
      }

      if (this.get('session.account').hasFeature('call_tagging')) {
        this.store.findAll('tag');
      }

      if (queryParams.showDetailsOf) {
        controller.set('showDetailsOf', queryParams.showDetailsOf);
      }

      return new Promise(function (resolve, reject) {
        return _ember['default'].$.ajax({
          url: urlForRequest,
          type: 'GET',
          dataType: 'json'
        }).done(function (data) {
          if (data.data.attributes) {
            delete data.data.attributes['call-filter']['by-conversation'];
            _this.set('callFilters', formatFilterConfiguration(data.data.attributes['call-filter']));
            _this.set('savedExportConfiguration', formatFilterConfiguration(data.data.attributes['export-config']));
          }
          resolve(data);
        }).fail(reject);
      });
    },

    returnValidFilterParamters: function returnValidFilterParamters(filter) {
      var entitiesForRequest = Object.keys(fetchModelsInFilterSettings),
          entitiesForFilterObject = Object.keys(filterSettingsObject);

      Object.keys(filter).forEach(function (key) {
        if (entitiesForFilterObject.includes(key)) {
          if (entitiesForRequest.includes(key) && !Array.isArray(filter[key][fetchModelsInFilterSettings[key].key])) {
            delete filter[key];
          }
        } else {
          delete filter[key];
        }
      });

      return filter;
    },

    loadFilterConfiguration: function loadFilterConfiguration(filterConfigObject) {
      var _this2 = this;

      var networkKeys = [],
          primitiveKeys = [],
          fetchModelsInFilterSettingsKeys = Object.keys(fetchModelsInFilterSettings),
          entitiesForFilterObject = Object.keys(filterSettingsObject);

      Object.keys(filterConfigObject).forEach(function (key) {
        if (entitiesForFilterObject.includes(key)) {
          if (fetchModelsInFilterSettingsKeys.includes(key)) {
            networkKeys.push(key);
          } else {
            primitiveKeys.push(key);
          }
        }
      });

      return new Promise(function (resolve, reject) {
        return _this2.fetchModels(networkKeys, _this2.updatePrimitveKeys(primitiveKeys, filterConfigObject)).then(function (filterConfigObject) {
          return resolve(filterConfigObject);
        })['catch'](function (error) {
          return reject(error);
        });
      });
    },

    updatePrimitveKeys: function updatePrimitveKeys(primeKeysArray, filterConfigObject) {
      primeKeysArray.forEach(function (key) {
        if (key !== 'by_service_level_breached' && key !== 'by_date') {
          if (filterSettingsConstants[key].isArray) {
            (function () {
              var arr = [],
                  iterableArray = filterConfigObject[key][filterSettingsConstants[key].key];

              if (Array.isArray(iterableArray)) {
                iterableArray.forEach(function (arrVal) {
                  if (filterSettingsConstants[key][arrVal]) {
                    arr.push(filterSettingsConstants[key][arrVal]);
                  }
                });
              }
              filterConfigObject[key][filterSettingsConstants[key].key] = arr;
            })();
          } else {
            filterConfigObject[key] = filterSettingsConstants[key][filterConfigObject[key]];
          }
        } else if (key === 'by_service_level_breached' || key === 'by_power_dialer') {
          filterConfigObject[key] = filterConfigObject[key] === 'true';
        }
      });

      return filterConfigObject;
    },

    fetchModels: function fetchModels(modelFetchKeys, filterConfigObject) {
      var _this3 = this;

      var promiseObject = [];

      return new Promise(function (resolve) {
        if (modelFetchKeys.length === 0) {
          resolve(filterConfigObject);
        }

        modelFetchKeys.forEach(function (modelKey) {
          var arr = filterConfigObject[modelKey][fetchModelsInFilterSettings[modelKey].key];

          if (Array.isArray(arr) && arr.length) {
            promiseObject.push(_this3.fetchAndLoadData(arr, modelKey, fetchModelsInFilterSettings[modelKey]));
          }
        });

        if (promiseObject.length) {
          return Promise.all(promiseObject).then(function (resolvedPromiseArray) {

            resolvedPromiseArray.forEach(function (resolvedPromise) {
              var _resolvedPromise = _slicedToArray(resolvedPromise, 2);

              var modelKey = _resolvedPromise[0];
              var resolvedArr = _resolvedPromise[1];

              filterConfigObject[modelKey][fetchModelsInFilterSettings[modelKey].key] = resolvedArr;
            });

            resolve(filterConfigObject);
          })['catch'](function (error) {
            return Promise.reject(error);
          });
        }

        resolve(filterConfigObject);
      });
    },

    fetchAndLoadData: function fetchAndLoadData(modelArray, modelKey, _ref2) {
      var _this4 = this;

      var model = _ref2.model;

      var newArr = [],
          mapping = [];

      if (modelKey === 'by_org_contact') {
        var params = {};

        var filterIdStr = '';

        modelArray.forEach(function (id) {
          filterIdStr += id + ',';
        });
        params['filter[id]'] = filterIdStr.slice(0, filterIdStr.length - 1);
        model = 'external-contact';

        return this.store.query(model, params).then(function (externalContacts) {
          mapping.push(modelKey);
          mapping.push(externalContacts);

          return mapping;
        });
      }

      modelArray.forEach(function (id) {
        newArr.push(_this4.store.findRecord(model, Number(id)));
      });

      return Promise.allSettled(newArr).then(function (resolvedArr) {
        mapping.push(modelKey);
        mapping.push(resolvedArr.map(function (promise) {
          if (promise.status !== 'rejected') {
            return promise.value;
          }
        }).filter(function (mappedArr) {
          return mappedArr;
        }));

        return Promise.resolve(mapping);
      })['catch'](function (error) {
        return Promise.reject(error);
      });
    },

    model: function model(queryParams) {
      var _this5 = this;

      var filterConfigObject = {},
          callFilterParams = { load_preferred_attributes: true },
          userConfiguration = undefined;

      // removed the dependency on isFromDashboard flag
      if (this.hasExternalFilterApplied(queryParams)) {
        callFilterParams = Object.assign({}, callFilterParams, this.paramsFromDashboard(queryParams));

        filterConfigObject = this.formatParams(callFilterParams);
      } else if (this.get('callFilters') && Object.keys(this.get('callFilters')).length) {
        filterConfigObject = this.returnValidFilterParamters(this.get('callFilters'));
        callFilterParams = filterConfigObject;
        callFilterParams.isFormatted = true;
      }

      // eslint-disable-next-line one-var
      var copiedObj = JSON.parse(JSON.stringify(filterSettingsObject)),
          fetchCallsPromise = new Promise(function (resolve) {
        return _this5.store.query('call', callFilterParams).then(function (calls) {
          return resolve(calls);
        });
      }),
          fetchmodelsInFilterPromise = new Promise(function (resolve, reject) {
        userConfiguration = _this5.store.peekAll('user-configuration').get('firstObject');

        if (!userConfiguration) {
          userConfiguration = _this5.store.createRecord('user-configuration');
        }

        userConfiguration.set('callFilter', Object.assign({}, copiedObj));

        if (Object.keys(_this5.get('savedExportConfiguration')).length) {
          userConfiguration.set('exportConfig', _this5.get('savedExportConfiguration'));
        }

        if (Object.keys(filterConfigObject).length) {

          _this5.loadFilterConfiguration(filterConfigObject).then(function () {
            _ember['default'].run(function () {
              userConfiguration.set('callFilter', Object.assign({}, copiedObj, filterConfigObject));
              resolve(userConfiguration.get('callFilter'));
            });
          })['catch'](function (error) {
            return reject(error);
          });
        } else {
          resolve(userConfiguration.get('callFilter'));
        }
      });

      return hash({
        calls: fetchCallsPromise,
        filterOptions: fetchmodelsInFilterPromise,
        callFilterParams: callFilterParams
      });
    },

    paramsFromDashboard: function paramsFromDashboard(params) {
      var filterParams = { load_preferred_attributes: true };

      if (params.user) {
        filterParams.agent_id = params.user;
      } else if (params.contact) {
        filterParams.contact_id = params.contact;
      } else if (params.org_contact_id) {
        filterParams.org_contact_id = params.org_contact_id;
      } else if (params.company) {
        filterParams.company_id = params.company;
      } else if (params.selectedQueues !== undefined) {
        if (params.selectedQueues && params.selectedQueues !== 'all') {
          filterParams.queue_id = params.selectedQueues.split(',');
        }
        if (params.status) {
          filterParams.status = [params.status];
        }
        if (params.direction) {
          filterParams.direction = params.direction;
        }
        filterParams.date_range = [moment().format('DD MM YYYY')];
      } else if (params.call_id) {
        filterParams.call_id = params.call_id;
      }

      return filterParams;
    },

    formatParams: function formatParams(params) {
      var agentArray = [],
          contactArray = [],
          start_date = null,
          end_date = null,
          queueArray = [];

      var callIdArray = [];

      if (params.date_range) {
        start_date = moment(params.date_range[0], 'DD MM YYYY').format('DD-MM-YYYY');
        end_date = moment(params.date_range[0], 'DD MM YYYY').format('DD-MM-YYYY');
      }
      if (params.agent_id) {
        agentArray.push(params.agent_id);
      }
      if (params.contact_id) {
        contactArray.push(params.contact_id);
      }

      if (params.org_contact_id) {
        contactArray.push(params.org_contact_id);
      }

      if (params.queue_id) {
        queueArray = params.queue_id;
      }

      if (params.call_id) {
        callIdArray.push(params.call_id);
      }

      return {
        by_date: {
          start_date: start_date,
          end_date: end_date
        },
        by_direction: params.direction,
        by_call_id: { call_id: callIdArray },
        by_queue: { queue_id: queueArray },
        by_status: { status: params.status },
        by_agent: { agent_id: agentArray },
        by_contact: { contact_id: contactArray },
        by_org_contact: { org_contact_id: contactArray }
      };
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('showDetailsOf', undefined);
        controller.set('user', undefined);
        controller.set('contact', undefined);
        controller.set('org_contact_id', undefined);
        controller.set('company', undefined);
        controller.set('selectedQueues', undefined);
        controller.set('status', undefined);
        controller.set('direction', undefined);
        controller.set('isFromDashboard', undefined);
        controller.set('call_id', undefined);
      }
    }
  });
});
/* eslint-disable prefer-object-spread */
/* eslint-disable ember-suave/no-direct-property-access */
/* eslint-disable camelcase */
/* eslint-disable sort-vars */