define('freshfone-ember/serializers/rule', ['exports', 'freshfone-ember/serializers/application', 'freshfone-ember/mixins/serializers/embedded-records'], function (exports, _freshfoneEmberSerializersApplication, _freshfoneEmberMixinsSerializersEmbeddedRecords) {
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    attrs: {
      businessHourActionMessage: { serialize: false },
      businessHourActionCallFlow: { serialize: false },
      nonBusinessHourActionMessage: { serialize: false },
      nonBusinessHourActionCallFlow: { serialize: false },
      holidayActionMessage: { serialize: false },
      holidayActionCallFlow: { serialize: false },
      businessCalendar: { serialize: false }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (Ember.isArray(payload.data)) {
        payload.data.forEach(function (item) {
          if (!item.attributes['holiday-action'] && !item.attributes['holiday-action-type']) {
            delete item.relationships['holiday-action-call-flow'];
            delete item.relationships['holiday-action-message'];
          }
        });
      } else {
        if (!payload.data.attributes['holiday-action'] && !payload.data.attributes['holiday-action-type']) {
          delete payload.data.relationships['holiday-action-call-flow'];
          delete payload.data.relationships['holiday-action-message'];
        }
      }

      return this._super.apply(this, arguments);
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      json.data.attributes['on_business_hours'] = {
        type: json.data.attributes["business-hour-action-type"],
        action: json.data.attributes["business-hour-action"]
      };
      json.data.attributes['non_business_hours'] = {
        type: json.data.attributes["non-business-hour-action-type"],
        action: json.data.attributes["non-business-hour-action"]
      };
      json.data.attributes['on_holidays'] = {
        type: json.data.attributes["holiday-action-type"],
        action: json.data.attributes["holiday-action"]
      };
      delete json.data.attributes["business-hour-action-type"];
      delete json.data.attributes["business-hour-action"];
      delete json.data.attributes["non-business-hour-action-type"];
      delete json.data.attributes["non-business-hour-action"];
      delete json.data.attributes["holiday-action-type"];
      delete json.data.attributes["holiday-action"];
      return json;
    }
  });
});