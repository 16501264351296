define('freshfone-ember/components/call-queue/branch-options/component', ['exports', 'ember', 'freshfone-ember/mixins/click-outside-listener', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/child-call-queue-position'], function (exports, _ember, _freshfoneEmberMixinsClickOutsideListener, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersChildCallQueuePosition) {
  var computed = _ember['default'].computed;
  var isEqual = _ember['default'].isEqual;
  var _set = _ember['default'].set;
  var defineProperty = _ember['default'].defineProperty;
  var dasherize = _ember['default'].String.dasherize;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberHelpersChildCallQueuePosition['default'], _ember['default'].Evented, {
    classNames: ["branch-option"],
    classNameBindings: ["showMessage:open", "showChild:hide"],
    showChild: computed.alias('parent.queue.showChild'),
    isQueue: computed.equal('actionOption.abbr', 'call_queue'),
    isAgentExtension: computed.equal('actionOption.abbr', 'agent_extension'),
    isRoutingAutomation: computed.equal('actionOption.abbr', 'routing_automation'),
    startValidate: computed.alias('parent.startValidate'),
    filteredQueueList: computed.filter('queueList', function (queue) {
      return !queue.get("isNew") && (queue.get("isChild") || queue.id !== this.get('parent.queue.id')) && queue.get('isLoaded');
    }),
    branchClassName: computed('branchName', function () {
      return dasherize(this.get('branchName')) + '-branch';
    }),
    callQueueClassName: computed('branchName', function () {
      return dasherize(this.get('branchName')) + '-call-queue';
    }),
    showQueueClass: computed('branchName', function () {
      return dasherize(this.get('branchName')) + '-show-queue';
    }),
    childLessQueue: computed.filter('filteredQueueList', function (queue) {
      return !queue.get('hasChildQueue');
    }),

    branchList: [{ name: 'queueFullAction', isOpen: false }, { name: 'noAnswerAction', isOpen: false }, { name: 'BusyWaitOverAction', isOpen: false }, { name: 'offlineAction', isOpen: false }, { name: 'timeThresholdAction', isOpen: false }, { name: 'callbackFailAction', isOpen: false }],
    hasAdvancedWaitQueue: computed(function () {
      return this.checkFeature('advanced_wait_queue');
    }),
    ancestorNotCallQueue: computed(function () {
      return !$('.call-queue-component').length;
    }),
    isOpen: computed('branchList.@each.isOpen', {
      get: function get() {
        var name = this.get('branchName');
        var branchList = this.get('branchList');
        return branchList.filterBy('name', name).isOpen;
      },
      set: function set(key, value) {
        var name = this.get('branchName');
        var branchList = this.get('branchList');
        branchList.forEach(function (option) {
          if (isEqual(option.name, name)) {
            _set(option, 'isOpen', value);
          } else {
            _set(option, 'isOpen', false);
          }
        });
        return value;
      }
    }),
    routingAutomation: computed({
      get: function get() {},
      set: function set(key, value) {
        value = value.hasOwnProperty('content') && value.get('content') ? value.get('content') : value;

        if (value.get('id') && !value.get('routingRules.length')) {
          this.store.findRecord('routing-automation', value.get('id'));
        }

        return value;
      }

    }),
    showWarning: computed('message', function () {
      return this.get('message') && !this.get('message.isValid');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.defineBranchErrorMacro();
    },
    defineBranchErrorMacro: function defineBranchErrorMacro() {
      var _this = this;

      var actionTypes = ['CallQueue', 'AgentExtension', 'RoutingAutomation'];

      actionTypes.forEach(function (actionType) {
        var action = _this.get('branchName') && _this.get('branchName').replace('Action', actionType),
            propName = 'parent.queue.errors.' + action;

        defineProperty(_this, 'error' + actionType, _ember['default'].computed(propName, function () {
          return _this.get(propName);
        }));
      });
    },

    queueList: computed(function () {
      return this.store.findAll('call-queue');
    }),

    actions: {
      createNewQueue: function createNewQueue() {
        var newObject = this.store.createRecord('call-queue', { name: _ember['default'].i18n.t('entityNames.new_sub_queue'), isChild: true });
        if (this.get('hasAdvancedWaitQueue')) {
          newObject.childPosY = this.chilQueuePlacement(this.get('branchClassName'), this.get('showRouteOptions'), this.get('queuePositionEnabled'));
        }
        this.set('queue', newObject);
      },
      createNewRoutingAutomation: function createNewRoutingAutomation() {
        var newRoutingAutomation = this.store.createRecord('routing-automation', {
          name: _ember['default'].i18n.t('entityNames.new_routing_automation_child'),
          isChild: true
        });

        newRoutingAutomation.createDefaultRoutingRule();

        this.set('routingAutomation', newRoutingAutomation);
      },

      showQueue: function showQueue(queue) {
        this.get('parent').sendAction('pushChildQueue', queue);
        this.set('parent.queue.showChild', true);
        this.set('clicked', true);
        var _posY = this.$('.call-queue-component').scrollTop() + this.$().children().offset().top;
        this.set('queue.posY', _posY);
        if (this.get('hasAdvancedWaitQueue')) {
          this.set('queue.childPosY', this.chilQueuePlacement(this.get('branchClassName'), this.get('showRouteOptions'), this.get('queuePositionEnabled')));
        }
        //scroll the screen to top, use case is when the parent is at the bottom and the new call queue appears in the top
        this.$('.call-queue-component').animate({ scrollTop: 0 });
        //#todo ember way of scrolling ??
      },

      showCallFlow: function showCallFlow(callFlowToShow) {
        var hasRoutingAutomationFix = this.checkFeature('circular_call_flow_loop_fix');

        if (hasRoutingAutomationFix && this.get('parentRoutingAutomation.id') === callFlowToShow.id) {
          return false;
        }
        this.set('parent.queue.showChild', true);
        this.set('clicked', true);
        this.get('parent').sendAction('pushChildQueue', callFlowToShow);
      },

      showMessage: function showMessage() {
        this.set('parent.queue.actionOption', this.get('actionOption'));
        this.set('parent.queue.actionType', this.get('branchName'));
        this.get('message') && this.set('parent.queue.activeChildMessage', this.get('message'));
        this.set('parent.queue.showChild', true);
        this.set('clicked', true);
        // since .call-queue-component wraps the content with scrollbar, actual location
        // is the pixels scrolled vertically + current offset
        var _posY = $('.flow-container').scrollTop() + this.$().children().offset().top;
        if (this.get('ancestorNotCallQueue')) {
          _posY = this.$().offset().top - $('.flow_wrapper').offset().top + 72;
        } else {
          if (this.get('hasAdvancedWaitQueue')) {
            if (this.get('isChild')) {
              _posY = $('.flow-container').scrollTop() + this.$().children().offset().top - this.get('childPosY');
            }
          }
        }
        this.set('parent.queue.posY', _posY);
        // #todo, calc in js, remove the calc from  showQueue method, remove inline from hbs
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */