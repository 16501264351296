define("freshfone-ember/components/live-calls/save-queues-view/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 4,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "icon-tick");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element5);
            morphs[1] = dom.createMorphAt(element5, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["toggleSaveView"], [], ["loc", [null, [3, 28], [3, 55]]], 0, 0], ["inline", "t", ["service_level_metrics.view_actions.save_view"], [], ["loc", [null, [3, 57], [3, 110]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "isMaxViewCountReached", ["loc", [null, [2, 12], [2, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [4, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 2
              },
              "end": {
                "line": 9,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "icon-edit");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "icon-delete");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createMorphAt(element3, 1, 1);
            morphs[2] = dom.createElementMorph(element4);
            morphs[3] = dom.createMorphAt(element4, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["toggleSaveView"], [], ["loc", [null, [7, 28], [7, 55]]], 0, 0], ["inline", "t", ["service_level_metrics.view_actions.edit_name"], [], ["loc", [null, [7, 57], [7, 110]]], 0, 0], ["element", "action", ["deleteView"], [], ["loc", [null, [8, 30], [8, 53]]], 0, 0], ["inline", "t", ["service_level_metrics.view_actions.delete_view"], [], ["loc", [null, [8, 55], [8, 110]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 4
                },
                "end": {
                  "line": 13,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "icon-tick-as");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element1);
              morphs[1] = dom.createMorphAt(element1, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["toggleSaveView", "saveViewAs"], [], ["loc", [null, [12, 33], [12, 73]]], 0, 0], ["inline", "t", ["service_level_metrics.view_actions.save_view_as"], [], ["loc", [null, [12, 74], [12, 129]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 16,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "icon-tick");
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element0);
              morphs[1] = dom.createMorphAt(element0, 1, 1);
              return morphs;
            },
            statements: [["element", "action", ["saveView"], [], ["loc", [null, [15, 30], [15, 51]]], 0, 0], ["inline", "t", ["service_level_metrics.view_actions.save_view"], [], ["loc", [null, [15, 53], [15, 106]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 2
              },
              "end": {
                "line": 17,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "icon-close discard-changes");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element2);
            morphs[1] = dom.createMorphAt(element2, 0, 0);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["element", "action", ["discardChanges"], [], ["loc", [null, [10, 45], [10, 72]]], 0, 0], ["inline", "t", ["service_level_metrics.view_actions.discard_changes"], [], ["loc", [null, [10, 73], [10, 131]]], 0, 0], ["block", "unless", [["get", "isMaxViewCountReached", ["loc", [null, [11, 14], [11, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 4], [13, 15]]]], ["block", "unless", [["subexpr", "and", [["get", "isMaxViewCountReached", ["loc", [null, [14, 19], [14, 40]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "viewModified", ["loc", [null, [14, 46], [14, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 41], [14, 59]]], 0, 0]], [], ["loc", [null, [14, 14], [14, 60]]], 0, 0]], [], 1, null, ["loc", [null, [14, 4], [16, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "viewModified", ["loc", [null, [6, 12], [6, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 2], [17, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  \n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "live-calls/save-queues-view/confirm-save-view-modal", [], ["showSaveViewModal", ["subexpr", "@mut", [["get", "showSaveViewModal", ["loc", [null, [22, 22], [22, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedQueues", ["subexpr", "@mut", [["get", "selectedQueues", ["loc", [null, [23, 21], [23, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedView", ["subexpr", "@mut", [["get", "selectedView", ["loc", [null, [24, 17], [24, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "isSaveViewAs", ["subexpr", "@mut", [["get", "isSaveViewAs", ["loc", [null, [25, 17], [25, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "viewQueuesList", ["subexpr", "@mut", [["get", "viewQueuesList", ["loc", [null, [26, 19], [26, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "viewModified", ["subexpr", "@mut", [["get", "viewModified", ["loc", [null, [27, 17], [27, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "viewName", ["subexpr", "@mut", [["get", "selectedView.name", ["loc", [null, [28, 13], [28, 30]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 2], [28, 32]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 7
          }
        },
        "moduleName": "freshfone-ember/components/live-calls/save-queues-view/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "selectedView", ["loc", [null, [1, 10], [1, 22]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [18, 11]]]], ["block", "if", [["get", "showSaveViewModal", ["loc", [null, [20, 6], [20, 23]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [20, 0], [29, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});