define('freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/cancellation-modal/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    notification: service('toastr'),
    subscription: computed.alias('session.account.subscription'),
    constructSurveyResponse: function constructSurveyResponse() {
      var surveyResposne = [];

      surveyResposne.push({
        questionId: this.get('primaryQuestionOptions.question'),
        options: [].concat(_toConsumableArray(this.get('primaryQuestionOptions.options'))),
        othersText: this.get('primaryQuestionOptions.othersText')
      });

      this.get('secondaryQuestionAnswered').forEach(function (question) {
        surveyResposne.push({
          questionId: question.get('id'),
          options: question.get('option') ? [].concat(_toConsumableArray(question.get('option'))) : [],
          othersText: question.get('othersText')
        });
      });

      return surveyResposne;
    },
    actions: {
      cancelAccount: function cancelAccount() {
        var _this = this;

        var surveyResponse = this.constructSurveyResponse();

        this.get('session.account').deleteAccount({
          feedback: surveyResponse
        }).then(function (response) {
          _this.set('subscription.nextRenewalAt', response.next_renewal_at);
          _this.set('session.account.deletionScheduled', true);
          _this.get('notification').success(_ember['default'].i18n.t('billing.cancel_account.cancel_toaster_message'));
          _this.sendAction('toggleOpenCancelModal');
        });
      },
      toggleOpenCancelModal: function toggleOpenCancelModal() {
        this.sendAction('toggleOpenCancelModal');
      }
    }
  });
});