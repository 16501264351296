define('freshfone-ember/components/call-queue/queue-form/advanced-wait-queue-settings/queue-callback/component', ['exports', 'ember', 'freshfone-ember/helpers/message-default-types', 'ember-features/mixins/feature-route-mixin'], function (exports, _ember, _freshfoneEmberHelpersMessageDefaultTypes, _emberFeaturesMixinsFeatureRouteMixin) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend(_emberFeaturesMixinsFeatureRouteMixin['default'], {
    currentAccount: computed.alias('session.account'),
    notifications: service('toastr'),
    analyticsService: service('analytics-tracker'),
    queueCallbackEnabled: computed.alias('queue.callbackEnabled'),
    init: function init() {
      this._super.apply(this, arguments);
      this.getQueueCallbackDefaults();
    },
    hasCallbackFeature: computed('currentAccount', function () {
      return this.get('currentAccount').hasFeature('callback_request');
    }),

    nonFallbackIdsForCallBackOption: computed('queue.actionOptions', function () {
      var nonFallbackOptions = ['wait_queue', 'agent_extension', 'routing_automation'],
          nonFallbackOptionsIds = [];

      this.get('queue.actionOptions').forEach(function (option) {
        if (nonFallbackOptions.includes(option.abbr)) nonFallbackOptionsIds.push(option.id);
      });
      return nonFallbackOptionsIds;
    }),

    callBackOptions: computed('queue.actionOptions', function () {
      var _this = this;

      return this.get('queue.actionOptions').reject(function (item) {
        return _this.get('nonFallbackIdsForCallBackOption').includes(item.id);
      });
    }),
    callbackMessage: computed('messages', function () {
      if (this.get('queue.callbackMessageId')) return this.get('messages').filterBy('id', this.get('queue.callbackMessageId').toString())[0];
    }),
    callbackConfirmationMessage: computed('messages', function () {
      if (this.get('queue.callbackConfirmationMessageId')) return this.get('messages').filterBy('id', this.get('queue.callbackConfirmationMessageId').toString())[0];
    }),
    messages: computed('queue.actionOptions', function () {
      return this.store.peekAll('message');
    }),

    getQueueCallbackDefaults: function getQueueCallbackDefaults() {
      var msjObj = this.getAllDefaultMessages();
      this.setProperties({
        'queue.callbackMessage': this.get('callbackMessage') ? this.get('callbackMessage') : msjObj.callbackMessage ? msjObj.callbackMessage : '',
        'queue.callbackConfirmationMessage': this.get('callbackConfirmationMessage') ? this.get('callbackConfirmationMessage') : msjObj.callbackConfirmationMessage ? msjObj.callbackConfirmationMessage : '',
        'queue.callbackMessageId': this.get('queue.callbackMessageId') ? this.get('queue.callbackMessageId') : msjObj.callbackMessage ? msjObj.callbackMessage.id : '',
        'queue.callbackConfirmationMessageId': this.get('queue.callbackConfirmationMessageId') ? this.get('queue.callbackConfirmationMessageId') : msjObj.callbackConfirmationMessage ? msjObj.callbackConfirmationMessage.id : '',
        'queue.callbackEnabled': this.get('queue.callbackEnabled') ? this.get('queue.callbackEnabled') : this.get('queueCallbackEnabled'),
        'queue.callbackFailMessage': this.get('queue.callbackFailMessage.content') ? this.get('queue.callbackFailMessage') : msjObj.hangup,
        'queue.callbackFailAction': this.get('queue.callbackFailAction') ? this.get('queue.callbackFailAction') : "hangup"
      });
    },

    actions: {
      queueCallBackToggled: function queueCallBackToggled() {
        this.get('analyticsService').addEvent('Toggle Queue Call Back');
        if (!this.get('hasCallbackFeature')) {
          this.get('notifications').error(_ember['default'].i18n.t('call_queue.edit.placeholders.callback_disabled_error'));
          this.set('queueCallbackEnabled', false);
          return;
        }
        this.set('queue.callbackEnabled', this.get('queueCallbackEnabled'));
      },
      setDefaultMsg: function setDefaultMsg(queueType, value) {
        this.sendAction('setDefaultMsg', queueType, value);
      },
      showNewMessage: function showNewMessage(messageType) {
        this.sendAction('showNewMessage', messageType);
      }
    }
  });
});