define('freshfone-ember/components/billing-base/credit-actions/purchase-info/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    currentAccount: computed.alias('session.account'),
    isAccountBlocked: computed.reads('currentAccount.blocked'),
    classNames: ['credit-action-purchase'],
    classNameBindings: ['purchased:flash'],
    actions: {
      toggleAddCardPurchase: function toggleAddCardPurchase() {
        this.get('toggleAddCardPurchase')(this.get('purchaseType'));
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */