define('freshfone-ember/components/request-demo/request-acknowledged/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    didRender: function didRender() {
      var self = this;
      setTimeout(function () {
        self.set('requestDemoSubmitted', false);
      }, 3000);
    }
  });
});