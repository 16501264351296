define('freshfone-ember/components/mkp-apps/component', ['exports', 'ember', 'freshfone-ember/mixins/mkp-apps-manager', 'freshfone-ember/constants/mkp-config'], function (exports, _ember, _freshfoneEmberMixinsMkpAppsManager, _freshfoneEmberConstantsMkpConfig) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var priorityList = _freshfoneEmberConstantsMkpConfig['default'].priorityList;
  exports['default'] = Component.extend(_freshfoneEmberMixinsMkpAppsManager['default'], {
    classNames: ['mkp-apps'],
    /**
     * `hasEnoughPriorityToRender` is employed for two purposes,
     *  One to sort out the location with highest priority for the marketplace apps to get rendered.
     *  Two to reset the location to default/ or with least priority after a location of higer priority
     *  completes rendering and to ensure this doesn't block rendering in low priority locations.
     */

    hasEnoughPriorityToRender: computed('mkpAdapter.page', function () {
      if (this.get('mkpAdapter.page')) {
        var currentMkpLocation = this.get('mkpAdapter.page');

        console.log('Marketplace app priority', priorityList[currentMkpLocation], priorityList[this.get('filter.location')]);

        return priorityList[currentMkpLocation] >= priorityList[this.get('filter.location')];
      }

      return true;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('resetMkpAppsCallback', this.get('resetMkpAppsCallback').bind(this));
      this.get('mkpAdapter').on('resetMkpApps', this.get('resetMkpAppsCallback'));
      console.log('Market Place app initialized', this.get('filter.location'));
    },
    resetMkpAppsCallback: function resetMkpAppsCallback(location) {
      if (location !== this.get('filter.location') && (!this.get('isDestroying') || !this.get('isDestroyed'))) {
        console.log('Reset Marketplace app', this.get('filter.location'));
        this.set('mkpApps', []);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('hasEnoughPriorityToRender')) {
        console.log('Updating Marketplace adapter Location', this.get('filter.location'));
        this.set('mkpAdapter.page', this.get('filter.location'));
        this.get('mkpAdapter').trigger('resetMkpApps', this.get('mkpAdapter.page'));
        this.initMkpApps(this.get('filter')).then(function (apps) {
          console.log('Marketplace Apps', apps);
          if (apps.length && !_this.get('deferActivation')) {
            _this.activateMkpApps();
          }
        });
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.deactivateMkpApps();

      if (this.get('hasEnoughPriorityToRender')) {
        this.set('mkpAdapter.page', undefined);
      }

      this.get('mkpAdapter').off('resetMkpApps', this, this.get('resetMkpAppsCallback'));

      this.set('mkpApps', []);
      this.set('mkpAdapter.mkpInitialized', false);
    }
  });
});
/* eslint-disable no-console */