define('freshfone-ember/components/custom-elements/list-search/component', ['exports', 'ember'], function (exports, _ember) {
  var TextField = _ember['default'].TextField;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = TextField.extend({
    tagName: 'input',
    isEmptyValue: computed.empty('value'),
    isActive: computed('isEmptyValue', {
      get: function get() {
        return !this.get('isEmptyValue');
      },
      set: function set() {}
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var intialList = this.get("results");
      this.set("intialList", intialList);
    },

    handleSearch: function handleSearch() {
      var searchTask = run.debounce(this, this.search, 700);
      this.clearpreviousTask();
      this.set('searchTask', searchTask);
    },

    clearpreviousTask: function clearpreviousTask() {
      var previousTask = this.get('searchTask');
      if (previousTask) {
        run.cancel(previousTask);
      }
    },

    keyUp: function keyUp() {
      if (this.get('isEmptyValue')) {
        this.resetResults();
        this.sendAction('searchText', "");
        return;
      }
      this.handleSearch();
    },

    resetResults: function resetResults() {
      this.clearpreviousTask();
      this.set('results', this.get('intialList'));
    },

    search: function search() {
      var queryString = this.get("value");
      this.sendAction('searchText', queryString);
    }

  });
});