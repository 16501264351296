define('freshfone-ember/models/rule', ['exports', 'ember', 'ember-data', 'ember-validations', 'freshfone-ember/mixins/find-or-fetch-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/utils/call-action-constants'], function (exports, _ember, _emberData, _emberValidations, _freshfoneEmberMixinsFindOrFetchMixin, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberUtilsCallActionConstants) {
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var notEmpty = _Ember$computed.notEmpty;
  var or = _Ember$computed.or;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = Model.extend(_emberValidations['default'], _freshfoneEmberMixinsFindOrFetchMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    session: service(),
    businessCalendarId: attr(),
    businessCalendarCategory: attr(),
    businessHourActionType: attr(),
    businessHourAction: attr(),
    nonBusinessHourActionType: attr(),
    nonBusinessHourAction: attr(),
    holidayActionType: attr(),
    holidayAction: attr(),
    hasHolidayCallFlowDefined: computed.and('holidayActionType', 'holidayAction'),
    hasBHWithHolidays: computed('businessCalendarId', function () {
      if (!this.checkFeature('outside_holiday_call_flow')) return;
      var holidays = this.get('hasBusinessHour') ? this.fetchBusinessCalendar() && this.fetchBusinessCalendar().get('holidays') : this.get('defaultBusinessCalendar.holidays');
      return !isEmpty(holidays);
    }),

    businessCalendar: belongsTo('business-calendar', { async: true }),
    businessHourActionMessage: belongsTo('message', { async: true }),
    businessHourActionCallFlow: belongsTo('callFlow', { async: true }),
    nonBusinessHourActionMessage: belongsTo('message', { async: true }),
    nonBusinessHourActionCallFlow: belongsTo('callFlow', { async: true }),
    holidayActionMessage: belongsTo('message', { async: true }),
    holidayActionCallFlow: belongsTo('callFlow', { async: true }),
    number: belongsTo('number', { async: true }),
    actionConfigForBH: computed('businessHourActionType', function () {
      var actionType = Number(this.get('businessHourActionType'));

      return (0, _freshfoneEmberUtilsCallActionConstants['default'])().findBy('id', actionType);
    }),
    actionConfigForNonBH: computed('nonBusinessHourActionType', function () {
      var actionType = Number(this.get('nonBusinessHourActionType'));

      return (0, _freshfoneEmberUtilsCallActionConstants['default'])().findBy('id', actionType);
    }),
    actionConfigForHoliday: computed('holidayActionType', function () {
      var actionType = Number(this.get('holidayActionType'));

      return (0, _freshfoneEmberUtilsCallActionConstants['default'])().findBy('id', actionType);
    }),
    hasNumber: computed.notEmpty('number.id'),
    hasBusinessHour: computed.notEmpty('businessCalendarId'),
    isDefaultBusinessCalendar: computed('businessCalendarId', function () {
      if (!this.checkFeature('business_hours') || !this.get('businessCalendarId')) return true;
      var businessCalendar = this.fetchBusinessCalendar();
      return businessCalendar && businessCalendar.get('isDefault') && isEmpty(businessCalendar.get('externalId'));
    }),
    defaultBusinessCalendar: computed(function () {
      return !this.checkFeature('business_hours') ? { name: '24 x 7', id: null, constructor: { modelName: '24x7' } } : this.store.peekAll('business-calendar').filterBy('isDefault', true).get('firstObject');
    }),
    canHaveNonBusinessHour: computed('businessCalendarId', function () {
      return this.checkFeature('caller_pages_in_crm') && this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('unified_business_hour') || this.checkFeature('business_hours') && !this.get('isDefaultBusinessCalendar');
    }),
    businessHourActionName: computed('businessHourActionType', 'businessHourAction', 'businessHourActionCallFlow', function () {
      if (this.get('businessHourActionType')) {
        if (this.get('actionConfigForBH.isACallFlow')) {
          return this.callFlowName(this.get('businessHourActionCallFlow'));
        }

        return this.messageType(this.get('businessHourActionType'));
      }
    }),
    nonBusinessHourActionName: computed('nonBusinessHourActionType', 'nonBusinessHourAction', 'nonBusinessHourActionCallFlow', function () {
      if (this.get('nonBusinessHourActionType')) {
        if (this.get('actionConfigForNonBH.isACallFlow')) {
          return this.callFlowName(this.get('nonBusinessHourActionCallFlow'));
        }

        return this.messageType(this.get('nonBusinessHourActionType'));
      }
    }),
    holidayActionName: computed('holidayActionType', 'holidayAction', 'holidayActionCallFlow', function () {
      if (this.get('holidayActionType')) {
        if (this.get('actionConfigForHoliday.isACallFlow')) {
          return this.callFlowName(this.get('holidayActionCallFlow'));
        }

        return this.messageType(this.get('holidayActionType'));
      }
    }),
    routeForBusinessHourAction: computed('businessHourActionCallFlow', function () {
      return (0, _freshfoneEmberUtilsCallActionConstants['default'])().findBy('abbr', this.get('businessHourActionCallFlow.typeName')).route + '.edit';
    }),
    routeForNonBusinessHourAction: computed('nonBusinessHourActionCallFlow', function () {
      return (0, _freshfoneEmberUtilsCallActionConstants['default'])().findBy('abbr', this.get('nonBusinessHourActionCallFlow.typeName')).route + '.edit';
    }),
    routeForHolidayAction: computed('holidayActionCallFlow', function () {
      return (0, _freshfoneEmberUtilsCallActionConstants['default'])().findBy('abbr', this.get('holidayActionCallFlow.typeName')).route + '.edit';
    }),
    isBusinessHourQueue: computed.equal('businessHourActionCallFlow.typeName', 'CallQueue'),
    isBusinessHourBotFlow: computed.equal('businessHourActionCallFlow.typeName', 'DeflectionBot'),
    isRoutingAutomation: computed.equal('businessHourActionCallFlow.typeName', 'RoutingAutomation'),
    isAgentExtensionFlow: computed.equal('businessHourActionCallFlow.typeName', 'AgentExtension'),
    isNonBusinessHourQueue: computed.equal('nonBusinessHourActionCallFlow.typeName', 'CallQueue'),
    isHolidayQueue: computed.equal('holidayActionCallFlow.typeName', 'CallQueue'),
    businessHourDisplayName: computed('businessCalendar', function () {
      if (isEmpty(this.get('businessCalendar.name'))) {
        return '24 x 7';
      }
      return this.get('businessCalendar.name');
    }),
    callFlowName: function callFlowName(callFlow) {
      if (isEmpty(callFlow.get("content"))) {
        return "";
      }
      return _emberData['default'].PromiseObject.create({
        promise: new _ember['default'].RSVP.Promise(function (resolve) {
          var action = callFlow.then(function (action) {
            var flowName = "";
            if (isPresent(action)) {
              flowName = action.get("name");
            }
            return resolve(flowName);
          });
        })
      });
    },
    messageType: function messageType(actionType) {
      var action = (0, _freshfoneEmberUtilsCallActionConstants['default'])().findBy('id', actionType).name.string;

      return action;
    },

    // This is used to fetch business calendar details for use in rules-list, as rule.businessCalendar is not available then
    fetchBusinessCalendar: function fetchBusinessCalendar() {
      if (this.checkFeature('business_hours') && this.get('hasBusinessHour')) return this.store.peekRecord('business-calendar', this.get('businessCalendarId'));
    },
    numberDisplayName: computed('number', 'number.name', function () {
      if (this.get("hasNumber")) {
        return this.get("number.displayNumber");
      }
      return _ember['default'].i18n.t('rule.any_number');
    }),

    isBusinessHourActionLoaded: or('businessHourActionCallFlow.isLoaded', 'businessHourActionMessage.isLoaded'),
    isNonBusinessHourActionLoaded: or('nonBusinessHourActionCallFlow.isLoaded', 'nonBusinessHourActionMessage.isLoaded'),
    isHolidayActionLoaded: or('holidayActionCallFlow.isLoaded', 'holidayActionMessage.isLoaded'),
    isAnyCallFlowLoaded: or('isBusinessHourActionLoaded', 'isNonBusinessHourActionLoaded', 'isHolidayActionLoaded'),

    validations: {
      number: {
        inline: (0, _emberValidations.validator)(function () {
          var numbers = this.model.store.peekAll("number");
          if (numbers.length !== 0 && !this.model.get('hasNumber')) {
            return _ember['default'].i18n.t('rule.errors.number');
          }
        })
      },
      businessHourActionType: {
        presence: { message: _ember['default'].i18n.t('rule.errors.select_option') }
      },
      businessHourAction: {
        presence: { message: _ember['default'].i18n.t('rule.errors.select_action') }
      },
      nonBusinessHourActionType: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('canHaveNonBusinessHour') && isEmpty(this.get('nonBusinessHourActionType'))) {
            return _ember['default'].i18n.t('rule.errors.select_option');
          }
        })
      },
      nonBusinessHourAction: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('canHaveNonBusinessHour') && isEmpty(this.get('nonBusinessHourAction'))) {
            return _ember['default'].i18n.t('rule.errors.select_action');
          }
        })
      },
      holidayActionType: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('hasBHWithHolidays') && isEmpty(this.get('holidayActionType'))) return _ember['default'].i18n.t('rule.errors.select_option');
        })
      },
      holidayAction: {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('hasBHWithHolidays') && isEmpty(this.get('holidayAction'))) return _ember['default'].i18n.t('rule.errors.select_option');
        })
      }
    }
  });
});