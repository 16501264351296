define('freshfone-ember/serializers/call-queue', ['exports', 'freshfone-ember/serializers/application', 'freshfone-ember/mixins/serializers/embedded-records'], function (exports, _freshfoneEmberSerializersApplication, _freshfoneEmberMixinsSerializersEmbeddedRecords) {
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    attrs: {
      queueFullCallQueue: {
        embedded: 'always'
      },
      busyWaitOverCallQueue: {
        embedded: 'always'
      },
      offlineCallQueue: {
        embedded: 'always'
      },
      noAnswerCallQueue: {
        embedded: 'always'
      },
      timeThresholdCallQueue: {
        embedded: 'always'
      },
      callbackFailCallQueue: {
        embedded: 'always'
      },
      queueFullRoutingAutomation: {
        embedded: 'always'
      },
      busyWaitRoutingAutomation: {
        embedded: 'always'
      },
      offlineRoutingAutomation: {
        embedded: 'always'
      },
      noAnswerRoutingAutomation: {
        embedded: 'always'
      },
      timeThresholdRoutingAutomation: {
        embedded: 'always'
      },
      callbackFailRoutingAutomation: {
        embedded: 'always'
      }
    },

    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var dirtyItemIds = store.peekAll('call-queue').filterBy('hasDirtyAttributes', true).mapBy('id');
      payload.data = payload.data.reject(function (item) {
        return dirtyItemIds.includes(item.id);
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});