define('freshfone-ember/routes/protected/admin/data-automated-routing/new', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    breadCrumb: {},
    features: ['data_automated_routing'],
    queryParams: {
      editNumber: {},
      actionFor: {}
    },
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.checkFeature('agent_extension');
    },
    model: function model() {
      return this.createNewCallFlow();
    },
    afterModel: function afterModel(model) {
      var title = get(model, 'name');

      set(this, 'breadCrumb.title', title);
    },
    createNewCallFlow: function createNewCallFlow() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var newFlow = _this.store.createRecord('callFlow');

        _this.store.findAll('routingAutomation').then(function (routingAutomationFlows) {
          newFlow.set('routingAutomation', _this.store.createRecord('routingAutomation'));
          newFlow.set('name', _ember['default'].i18n.t('data_automated_routing.title') + ' ' + (routingAutomationFlows.get('length') + 1));

          resolve(newFlow);
        })['catch'](function (error) {
          console.log('Error occured in routing automation: ' + error);
          reject();
        });
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('rule', undefined);
      }
    }
  });
});
/* eslint-disable no-console */