define('freshfone-ember/components/debug-component/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    telephony: service(),
    warnings: computed(function () {
      return [{
        key: 'low-mos',
        label: 'Low Mos'
      }, {
        key: 'high-jitter',
        label: 'High Jitter'
      }, {
        key: 'high-rtt',
        label: 'High RTT'
      }, {
        key: 'high-packet-loss',
        label: 'High Packet Loss'
      }, {
        key: 'constant-audio-input-level',
        label: 'No Constant Audio Input Level'
      }, {
        key: 'constant-audio-output-level',
        label: 'No Constant Audio Output Level'
      }];
    }),
    actions: {
      triggerWarning: function triggerWarning(index) {
        var warning = this.get('warnings')[index];

        this.get('telephony').triggerWarning(warning.key);
      },
      clearWarning: function clearWarning(index) {
        var warning = this.get('warnings')[index];

        this.get('telephony').clearWarning(warning.key);
      }
    }
  });
});