define('freshfone-ember/components/base-component/component', ['exports', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/icon-urls', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/mixins/cleanup-tooltips', 'freshfone-ember/constants/static-urls', 'freshfone-ember/constants/cloud-type', 'freshfone-ember/utils/inject-resource', 'moment'], function (exports, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsIconUrls, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberMixinsCleanupTooltips, _freshfoneEmberConstantsStaticUrls, _freshfoneEmberConstantsCloudType, _freshfoneEmberUtilsInjectResource, _moment) {
  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var not = _ember['default'].computed.not;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var later = _ember['default'].run.later;
  var i18n = _ember['default'].i18n;
  var testing = _ember['default'].testing;

  var Notification = window.Notification;

  var AppCues = _freshfoneEmberConfigEnvironment['default'].AppCues;
  var Freshsurveys = _freshfoneEmberConfigEnvironment['default'].Freshsurveys;
  var FRESHIDV2 = _freshfoneEmberConfigEnvironment['default'].FRESHIDV2;
  var heap = _freshfoneEmberConfigEnvironment['default'].heap;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], _freshfoneEmberMixinsCleanupTooltips['default'], {
    authorizedRolesForFreshchat: ['Supervisor', 'Admin', 'Account Admin', 'Agent'],
    session: service(),
    routing: service('-routing'),
    params: computed.alias('routing.router.currentState.routerJsState.fullQueryParams'),
    telephony: service(),
    importService: service(),
    parkedCallsService: service('parked-calls'),
    browserService: service('browser-service'),
    importParams: computed.alias('importService.importParams'),
    isSDKLoaded: computed.alias('telephony.isLoaded'),
    isSocketDisconnected: false,
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    activePlan: computed.alias('currentAccount.plan_name'),
    isSproutPlan: computed.equal('activePlan', 'Sprout'),
    isDemoRequested: computed.alias('session.account.demoRequested'),
    isTrial: computed.alias('session.account.isTrial'),
    userRole: computed.alias('session.user.role.name'),
    subscription: computed.alias('session.account.subscription'),
    isFirstCall: computed.not('currentUser.agent.isOutgoingCallMade'),
    isAgent: computed.equal('userRole', 'Agent'),
    emailHash: computed.alias('currentUser.emailHash'),
    isAgentInPowerDialerMode: computed.alias('currentAgent.powerDialer'),
    currentAgent: computed.alias('currentUser.agent'),
    isOmnichannelEnabled: computed.alias('currentAccount.isOmnichannelEnabled'),
    freshsalesPlanName: computed.alias('session.current.bundleInfo.domains.freshsales-plan-name'),
    showPowerDialer: computed.alias('currentUser.powerDialerAllowed'),
    showPaymentDue: computed.and('isPaymentDueBannerEnabled', 'isPaymentDue'),
    isOneCxMigratedAccount: computed.alias('currentAccount.oneCxMigratedAccount'),
    organisationDomain: computed.alias('session.account.organisationDomain'),
    orgInboxUrl: computed('session.account.organisationDomain', function () {
      return 'https://' + this.get('organisationDomain') + _freshfoneEmberConstantsStaticUrls['default'].crmInbox;
    }),
    contactsRedirectUrl: computed('currentAccount.omnichannelOwnerDomain', function () {
      return this.hasOmniRedirectFeatureEnabled() ? this.get('currentAccount.omnichannelOwnerDomain') + '/a/contacts' : null;
    }),
    has1CXFeature: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm') || this.get('hasMessagingCloudType');
    }),
    is1CXCrmView: computed.and('has1CXFeature', 'isCrmView'),
    isNavOff: computed.and('isCrmView', 'isFullView'),
    isMicrophonePermissionDenied: computed.alias('browserService.isMicrophonePermissionDenied'),
    hasTestConnectionFeature: computed(function () {
      return this.hasGlobalFeature("test_connection") && this.get('isTrial');
    }),
    displayLanguage: computed('session', function () {
      return this.get('session.user.language') || this.get('session.account.language') || "en";
    }),
    supportEmail: computed('subscription.onlineCustomer', function () {
      return this.get('subscription.onlineCustomer') ? 'billing@freshworks.com' : 'receivables@freshworks.com';
    }),
    isPaymentDueBannerEnabled: computed(function () {
      return this.hasGlobalFeature('payment_due_banner') && !this.checkFeature('disable_payment_due_banner');
    }),
    isPaymentDue: computed('subscription.invoiceDue', 'userRole', function () {
      return this.get('subscription.invoiceDue') && this.get('userRole') === 'Account Admin';
    }),
    isBannerDismissed: computed(function () {
      return localStorage.getItem('bannerDismissed-7-May-2020');
    }),
    showBanner: computed('isBannerDismissed', 'isCrmView', function () {
      return new Date().getTime() <= new Date('2023-10-16').getTime();
    }),
    hasRequestDemoFeature: computed(function () {
      return this.checkFeature('request_demo');
    }),
    hasFreshchatFeature: computed('session', function () {
      return this.checkFeature('freshchat') && this.get('currentAccount.freshchatEnabled');
    }),
    hasOmnichannelAgentStatusFeature: computed('session', function () {
      return this.checkFeature('omnichannel_agent_status') && this.get('isOmnichannelEnabled');
    }),
    authorizedRoleForFreshchat: computed('session', function () {
      return this.authorizedRolesForFreshchat.includes(this.get('userRole'));
    }),
    showFreshchat: computed.and('authorizedRoleForFreshchat', 'hasFreshchatFeature'),
    hasChatWidgetv2: computed(function () {
      return this.get('session.account').hasGlobalFlag('chat_caller_widget_v2') && this.get('session.account').hasFeature('chat_caller_widget');
    }),
    isNotEmbedMode: not('isEmbedParamPresent'),
    showCallRatingModule: computed('showCallRating', 'ongoingWidgetOpen', function () {
      return this.get('showCallRating') && this.get('ongoingWidgetOpen') === false;
    }),
    hasCallRatingFeature: computed('session', function () {
      return this.checkFeature('call_quality_rating');
    }),
    hasAppcuesFeature: computed(function () {
      return this.checkFeature('appcues');
    }),
    isBundleAccount: computed.reads('currentAccount.isBundleAccount'),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    hasParkedCalls: computed('parkedCallsService.parkedCalls', function () {
      return this.get('parkedCallsService.parkedCalls').length;
    }),
    hasActiveOrParkedCalls: computed.or('hasActiveCall', 'hasParkedCalls'),
    isMicrophoneFlagEnabled: computed(function () {
      return this.featureCheckWithRolloutFlag('mic_permission', 'mic_permission', 'mic_permission_rollout');
    }),
    connectRealtime: (function () {
      if (this.get('session.user')) {
        this.get('telephony').register();
      }
      var timeZone = this.get("currentUser.timeZoneAsTzinfo") || this.get("session.account.timeZoneAsTzinfo");
      _moment['default'].tz.setDefault(timeZone);
      _moment['default'].relativeTimeThreshold('d', 30);
    }).observes('session.user').on('init'),
    init: function init() {
      this._super.apply(this, arguments);

      this.bootstrapAnalyticsTracker();
      //  Cleanup the previous key we had set
      localStorage.removeItem('bannerDismissed');

      this.bindNodeEvents();
      this.requestNotificationPermission();

      this.updateUserPropertiesToAnalyticsTracker();
      this.bindAnalyticsEvent();

      /* istanbul ignore next */
      if (!this.get('isBundleAccount') && !testing) {
        this.bootStrapFreshsurveys();
      }
    },
    bootstrapAnalyticsTracker: function bootstrapAnalyticsTracker() {
      if (!this.get('session.account.isUnifiedAppAccount') && !this.get('session.account').hasFeature('opt_out_of_heap')) {
        if (this.get('session.account.isOmnichannelEnabled') && this.hasGlobalFeature('seperate_heap_for_omni')) {
          this.get('analyticsTracker.heap').bootstrap(heap.omniProjectId);
        } else {
          this.get('analyticsTracker.heap').bootstrap();
        }
      }
    },
    updateUserPropertiesToAnalyticsTracker: function updateUserPropertiesToAnalyticsTracker() {
      var _this = this;

      this.getUserProperties().then(function (userProperties) {
        if (AppCues && _this.get('hasAppcuesFeature')) {
          _this.addAppcues(userProperties);
        }
        _this.get('analyticsTracker.heap').addUserProperties(userProperties);
      });

      if (this.get('session.account.isOmnichannelEnabled') && this.hasGlobalFeature('seperate_heap_for_omni')) {
        this.get('analyticsTracker.heap').identify(this.get('currentUser.freshidUuid'));
      } else {
        this.get('analyticsTracker.heap').identify(this.get('currentUser.id'));
      }
    },
    bindAnalyticsEvent: function bindAnalyticsEvent() {
      var _this2 = this;

      if (!testing) {
        document.addEventListener('click', function (event) {
          var closest = event.target.closest('[data-analytics-action-name]'),
              analyticsActionName = event.target.dataset.analyticsActionName || closest && closest.dataset.analyticsActionName;

          if (analyticsActionName) {
            _this2.get('analyticsTracker.heap').track(analyticsActionName);
            _this2.get('analyticsTracker.appcues').track(_this2.get('session.account.isUnifiedAppAccount') ? 'freshcaller_' + analyticsActionName : analyticsActionName);
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);
      var params = this.get('params'),
          rootURL = localStorage.getItem('rootURL');

      if (params.showImportModal) {
        this.set('importParams', Object.assign({}, this.get('importParams'), params));
        this.set('importParams.hasError', true);
      }
      this.$().tooltip({
        selector: '[data-toggle="tooltip"]',
        container: 'body'
      });

      this.handlePowerDialerMode();

      if (rootURL && rootURL === '/') {
        this.changeFavicon('assets/images/fd-contact-center.svg');
      }
      if (this.get('freshsalesPlanName') && this.get('freshsalesPlanName').includes('sales360_clc')) {
        this.changeFavicon('assets/images/freshsales-suite.svg');
      }

      // To isolate one cx favicon change from current standalone and freshsales

      if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.telephony) {
        this.changeFavicon(_freshfoneEmberConstantsIconUrls['default'].fdContactCenterIcon);
      } else if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.freshdeskMessagingCloud) {
        this.changeFavicon(_freshfoneEmberConstantsIconUrls['default'].freshchatIcon);
      } else if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.salesCloud) {
        this.changeFavicon(_freshfoneEmberConstantsIconUrls['default'].freshsalesIcon);
      } else if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.customerForLifeCloud) {
        this.changeFavicon(_freshfoneEmberConstantsIconUrls['default'].freshsalesSuiteIcon);
      } else if (this.get('currentAccount.cloudType') === _freshfoneEmberConstantsCloudType.cloudType.cxsuite) {
        this.changeFavicon(_freshfoneEmberConstantsIconUrls['default'].cxsuiteIcon);
      }

      if (this.get('isMicrophoneFlagEnabled')) {
        this.get('browserService').intializeMicrophonePermissions();
      }
      this.get('telephony').on('socket_disconnected', function () {
        _this3.set('isSocketDisconnected', true);
      });
      this.get('telephony').on('reconnect', function () {
        _this3.set('isSocketDisconnected', false);
      });
    },

    handlePowerDialerMode: function handlePowerDialerMode() {
      var _this4 = this;

      $(window).bind('beforeunload', function () {
        if (_this4.get('isAgentInPowerDialerMode')) {
          return i18n.t('power_dialer.reload_prompt');
        }
      });
    },
    stopAgentInPowerDialerMode: function stopAgentInPowerDialerMode() {
      var _this5 = this;

      var stopPowerDialerUrl = '/power_dialer_lists/stop';

      $.ajax({
        url: stopPowerDialerUrl,
        type: 'POST',
        dataType: 'json'
      }).done(function () {
        _this5.set('isAgentInPowerDialerMode', false);
      });
    },
    requestNotificationPermission: function requestNotificationPermission() {
      if (Notification.permission === 'default') Notification.requestPermission();
    },
    /* istanbul ignore next */addAppcues: function addAppcues(userProperties) {
      var _this6 = this;

      var context = document.body,
          deferScript = true;

      (0, _freshfoneEmberUtilsInjectResource.injectScript)(this.get('session.account.isUnifiedAppAccount') ? AppCues.sales360ScriptUrl : AppCues.scriptUrl, context, deferScript).then(function () {
        _this6.get('analyticsTracker.appcues').page();
        _this6.get('analyticsTracker.appcues').identify(_this6.get('currentAccount.isUnifiedAppAccount') ? _this6.get('currentUser.freshidUuid') : _this6.get('currentUser.id'), userProperties);
      });
    },

    getUserProperties: function getUserProperties() {
      var _this7 = this;

      return new Promise(function (res) {
        _this7.get('analyticsTracker').getPropsToTargetThisUser().then(function (userProps) {
          res({
            accountId: userProps.accountId,
            plan: userProps.accountPlan,
            userRole: userProps.userRole,
            status: userProps.accountStatus,
            businessAccount: userProps.businessAccount,
            integratedApps: userProps.integratedApps.toString(),
            isTrial: userProps.isTrial,
            // eslint-disable-next-line camelcase
            email_confirmed: userProps.userEmailConfirmed,
            firstDayLogin: userProps.isFirstDayLogin,
            accountCreatedDate: userProps.accountCreatedAt,
            org_user_id: userProps.orgUserId,
            org_id: userProps.orgId,
            org_domain: userProps.orgDomain,
            bundle_id: userProps.bundleId,
            bundle_name: userProps.bundleName,
            cloud_type: userProps.cloudType
          });
        });
      });
    },

    bindNodeEvents: function bindNodeEvents() {
      var _this8 = this;

      this.get('telephony').on('loaded', function () {
        if (_this8.get('isAgentInPowerDialerMode')) _this8.stopAgentInPowerDialerMode();
        FC.Event.subscribe('prompt_browser_refresh', function (data) {
          if (data.refreshWithoutConsent) {
            _this8.send('refreshBrowser');
          } else {
            _this8.set('browserRefreshPrompt', data);
          }
        });
      });
    },
    omnibarConfigJson: {
      fw_domain: '',
      fw_session_state: '',
      fw_session_token: '',
      product_client_id: FRESHIDV2.clientId
    },
    resetCallRatingParams: function resetCallRatingParams() {
      this.set('ratedCallId', null);
      this.set('showCallRating', false);
      this.set('ongoingWidgetOpen', null);
    },
    /* istanbul ignore next */bootStrapFreshsurveys: function bootStrapFreshsurveys() {
      var _this9 = this;

      (0, _freshfoneEmberUtilsInjectResource.injectScript)(Freshsurveys.scriptURL).then(function () {
        return _this9.initFreshsurveys();
      })['catch'](function (err) {
        return err;
      });
    },
    /* istanbul ignore next */initFreshsurveys: function initFreshsurveys() {
      var currentAccount = this.get('currentAccount'),
          currentUser = this.get('currentUser'),
          subscription = this.get('subscription'),
          userCreatedAt = currentUser.get('created_at');

      window.SurveyWidget.init({
        type: 'default',
        delay: 2,
        include_unsubscribe: true,
        survey_id: Freshsurveys.survey_id,
        survey_collector_id: Freshsurveys.survey_collector_id,
        contact: {
          first_name: currentUser.get('name'),
          work_email: currentUser.get('email'),
          product: Freshsurveys.productName,
          account_identifier: currentAccount.get('id'),
          prod_acc_id: Freshsurveys.productName.toLowerCase() + ' - ' + currentAccount.get('id'),
          plan: subscription.get('planName'),
          account_status: 'active',
          user_id: currentUser.get('id'),
          role: currentUser.get('role.name'),
          user_created_date: userCreatedAt ? new Date(userCreatedAt).toISOString() : ''
        }
      });
    },

    changeFavicon: function changeFavicon(faviconUrl) {
      var callerFavicon = document.getElementById('fav-icon'),
          deskFavicon = document.createElement('link');

      Object.assign(deskFavicon, {
        id: 'fav-icon',
        href: faviconUrl,
        rel: 'icon'
      });
      if (callerFavicon) {
        document.head.removeChild(callerFavicon);
        document.head.appendChild(deskFavicon);
      }
    },

    actions: {
      resendEmail: function resendEmail() {
        var _this10 = this;

        var self = this;
        $.ajax({
          url: '/send_activation_link', type: 'GET', dataType: 'json',
          data: { "id": this.get('currentUser.id') }
        }).done(function () {
          run(function () {
            self.set('mailSent', true);
            later(_this10, function () {
              self.set('mailSent', false);
            }, 10000);
          });
        }).fail(function () {
          return run(function () {
            return self.set('mailError', true);
          });
        });
      },
      importCloseCallback: function importCloseCallback() {
        this.get('importService').closeModal();
      },
      importUploadCallback: function importUploadCallback(_, uploadData) {
        var importService = this.get('importService');

        if (this.get('importParams.passive')) {
          importService.trigger('before_upload', uploadData);
        } else {
          importService.apiAction(uploadData);
        }
      },
      callCompleted: function callCompleted(callerNumber) {
        var _this11 = this;

        if (!this.get('isAgentInPowerDialerMode')) {
          this.set('ongoingWidgetOpen', false);
          if (this.get('hasRequestDemoFeature')) {
            if (!this.get('isDemoRequested') && this.get('isTrial')) {
              this.set('callerNumber', callerNumber);
              this.store.query('call', {}).then(function (calls) {
                if (calls.meta['total-count'] <= 2) {
                  _this11.set('openDemoRequest', true);
                }
              });
            }
          }
        }
      },
      setCallRatingParams: function setCallRatingParams(callId) {
        if (!this.get('ratedCallId')) {
          this.set('ratedCallId', callId);
          this.set('showCallRating', true);
        }
      },
      submitCallRating: function submitCallRating(rating, reason) {
        var _this12 = this;

        var data = {
          account_id: this.get('currentAccount.id'),
          rating: rating
        },
            url = '/calls/' + this.get('ratedCallId') + '/quality_metrics';

        if (rating <= 3 && reason) {
          data.reason = reason;
        }

        $.ajax({
          url: url,
          type: 'post',
          dataType: 'json',
          data: data
        }).always(function () {
          run(function () {
            return _this12.resetCallRatingParams();
          });
        });
      },
      closeCallRatingModule: function closeCallRatingModule() {
        this.resetCallRatingParams();
      },

      // For browser refresh prompt modal
      cancelBrowserRefresh: function cancelBrowserRefresh() {
        this.set('browserRefreshPrompt', undefined);
      },
      refreshBrowser: function refreshBrowser() {
        window.location.reload();
      }
    }
  });
});
/* eslint-disable camelcase */