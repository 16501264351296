define('freshfone-ember/services/mkp-interface', ['exports', 'ember', 'freshfone-ember/constants/mkp-config'], function (exports, _ember, _freshfoneEmberConstantsMkpConfig) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  var Service = _ember['default'].Service;
  var overlays = _freshfoneEmberConstantsMkpConfig['default'].overlays;
  var showConfirmTimeout = _freshfoneEmberConstantsMkpConfig['default'].showConfirmTimeout;
  var defaults = _freshfoneEmberConstantsMkpConfig['default'].defaults;
  var generateModalTemplate = function generateModalTemplate(config, instanceId) {
    return '\n      <div id="mkp-modal" class="modal mkp" tabindex="-1" role="dialog" \n        data-backdrop="static" data-instance-id=' + instanceId + ' data-type=' + safeStr(config.type) + '>\n        <div class="modal-dialog modal-' + (safeStr(config.size) || 'lg') + '" role="document">\n          <div class="modal-content">\n            ' + (config.type !== 'confirm' ? '\n                <div class="modal-header">\n                  <span class="modal-title">' + safeStr(config.title, 'showModal.title') + '</span>\n                  <i class="pull-right icon-close x"></i>\n                </div>\n              ' : '') + '\n            <div class="modal-body"><!-- Content injected via showModal --></div>\n          </div>\n        </div>\n      </div>\n    ';
  };

  function safeStr(str, entity) {
    var htmlSafeStr = function htmlSafeStr(str) {
      return str && String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    };

    if (entity) {
      var _entity$split = entity.split('.');

      var _entity$split2 = _slicedToArray(_entity$split, 2);

      var interfaceType = _entity$split2[0];
      var item = _entity$split2[1];

      if (!str) {
        return defaults[interfaceType][item];
      }

      return htmlSafeStr(str).slice(0, defaults[interfaceType].characterLimit[item]);
    }

    return htmlSafeStr(str);
  }

  exports['default'] = Service.extend({
    toastr: service(),

    showModal: function showModal(options, instance) {
      var _this = this;

      var instanceId = instance.appInstance.instanceId;
      var instanceSelector = '[data-instance-id=' + instanceId + ']';

      $('#modal-wormhole').append(generateModalTemplate(options, instanceId));

      $(instanceSelector + ' .modal-body', '#modal-wormhole').append(instance.element);
      $('' + instanceSelector).modal('show');
      $(instanceSelector + ' .x').click(function () {
        return _this.closeInstance({
          instanceId: instanceId,
          instance: instance
        });
      });

      return { success: 'success' };
    },

    showConfirm: function showConfirm(options, instance) {
      var _this2 = this;

      var confirmTemplate = '\n        <p>' + safeStr(options.message, 'showConfirm.message') + '</p>\n        <div class="buttons text-right">\n          <button class="btn btn-cancel">' + safeStr(options.cancelLabel, 'showConfirm.cancel') + '</button>&nbsp;\n          <button class="btn btn-primary">' + safeStr(options.saveLabel, 'showConfirm.ok') + '</button>\n        </div>\n      ',

      // eslint-disable-next-line no-mixed-operators
      instanceId = instance ? instance.appInstance.instanceId : Math.ceil(Date.now() + Math.random() * 1000),
          instanceSelector = '[data-instance-id=' + instanceId + ']';

      // eslint-disable-next-line no-console
      console.log('generate modal templated');
      $('#modal-wormhole').append(generateModalTemplate({
        size: 'sm',
        type: 'confirm'
      }, instanceId));

      $(instanceSelector + ' .modal-body', '#modal-wormhole').append(confirmTemplate);
      $('' + instanceSelector).modal('show');

      return new Promise(function (resolve, reject) {
        var timeout = setTimeout(function () {
          reject('Request timed out!');

          _this2.closeInstance({
            instanceId: instanceId,
            instance: instance
          });
        }, showConfirmTimeout);

        $('.buttons', instanceSelector).click(function (event) {
          var target = $(event.target);

          if (target.is('button')) {
            clearTimeout(timeout);

            if (target.is('.btn-primary')) {
              resolve(target.text());
            } else {
              reject(target.text());
            }

            _this2.closeInstance({
              instanceId: instanceId,
              instance: instance
            });
          }
        });
      });
    },

    showNotify: function showNotify(options) {
      this.get('toastr')[options.type](safeStr(options.message, 'showNotify.message'));

      return { success: 'success' };
    },

    closeInstance: function closeInstance(attrs) {
      var instanceSelector = '[data-instance-id=' + attrs.instanceId + ']';

      if (!attrs.instanceId) {
        return { error: 'An instance with that ID could not be found' };
      }

      $(instanceSelector).on('hidden.bs.modal', function () {
        $(this).remove();
        attrs.instance && attrs.instance.trigger({ type: 'app.derender' });
      }).modal('hide');
      $('.x, .buttons', instanceSelector).off('click');

      return { success: 'success' };
    },

    invokeInterface: function invokeInterface(args) {
      if (![].concat(_toConsumableArray(overlays), ['closeInstance', 'showConfirm', 'showNotify']).includes(args.method)) {
        return { error: 'No such interface. Available interfaces are ' + overlays };
      }
      // eslint-disable-next-line no-console
      console.log('interface invoked');

      return this[args.method](args.options, args.instance);
    }
  });
});
/* eslint-disable max-len */