define('freshfone-ember/components/data-automated-routing/component', ['exports', 'freshfone-ember/constants/routing-automation', 'ember', 'ember-validations', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/utils/call-flow-constants', 'freshfone-ember/mixins/safe-set'], function (exports, _freshfoneEmberConstantsRoutingAutomation, _ember, _emberValidations, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberUtilsCallFlowConstants, _freshfoneEmberMixinsSafeSet) {
  var callFlowTypes = _freshfoneEmberUtilsCallFlowConstants['default'].callFlowTypes;
  var Component = _ember['default'].Component;
  var assert = _ember['default'].assert;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  var typeOf = _ember['default'].typeOf;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var gt = _Ember$computed.gt;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsSafeSet['default'], {
    importService: service(),
    routing: service('-routing'),

    importModalParams: alias('importService.importParams'),
    routingAutomation: computed('callFlow.routingAutomation.isLoaded', function () {
      if (this.get('isAFallBack')) {
        return this.get('callFlow');
      }

      if (this.get('callFlow.routingAutomation.isLoaded') && this.get('importModalParams.showImportModal')) {
        this.set('callFlow.routingAutomation.routingRuleType', _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import']);
      }

      return this.get('callFlow.routingAutomation');
    }),
    hasCustomApps: gt('routingAutomation.content.customApps.length', 0),
    redirectionURL: computed('editNumber', function () {
      if (this.get('editNumber')) {
        return 'protected.admin.numbers';
      }

      return 'protected.admin.callflows';
    }),
    showLoader: computed('routingAutomation.isFulfilled', function () {
      return !this.get('routingAutomation.isFulfilled') && !this.get('isAFallBack');
    }),
    isFlowIncomplete: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(_, v) {
        /**
         * if the rules are imported and the form is invalid
         * close the modal and display errors
         */
        if (v && this.get('fileToBeUploaded')) {
          this.set('importModalParams.showImportModal', false);
        }

        return v;
      }
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var routingAutomation = this.get('routingAutomation.content') || this.get('routingAutomation'),
          routingRuleImportInProgress = this.get('importService').getImportProgress('routing_rules');

      this.bindImportServiceEvents();
      this.setProperties({
        rulesFieldDisabled: false,
        importDisabled: !!routingRuleImportInProgress || this.get('isAFallBack'),
        importInProgress: String(routingRuleImportInProgress) === this.get('routingAutomation.id'),
        fileToBeUploaded: null,
        importModalParams: Object.assign({}, this.get('importModalParams'), _freshfoneEmberConstantsRoutingAutomation.IMPORT_MODAL_PARAMS)
      });

      routingAutomation.loadCustomApps().then(function (apps) {
        routingAutomation.set('customApps', apps);
        _this.set('isAppLoaded', true);
      });
    },
    bindImportServiceEvents: function bindImportServiceEvents() {
      var _this2 = this;

      var importService = this.get('importService');

      importService.on('import_progress', function () {
        return _this2.set('importInProgress', true);
      });
      importService.on('import_failed', function () {
        return _this2.set('importInProgress', false);
      });
      importService.on('import_complete', function () {
        return _this2.set('importDisabled', false);
      });
      importService.on('import_success', function (data) {
        if (_this2.get('routingAutomation.isLoaded') && data.routing_automation_id === _this2.get('routingAutomation.id')) {
          _this2.get('routingAutomation.content').reload().then(function () {
            _this2.get('routingAutomation.content').rollbackRoutingRulesCount();
            _this2.set('importInProgress', false);
          })['catch'](function () {
            return _this2.saveCallbacks();
          });
        }
      });
      importService.on('closed_user', function () {
        if (_this2.get('routingAutomation.routingRulesCount') === 0) {
          _this2.set('routingAutomation.routingRuleType', _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES.create);
        }
      });
      // uploading a file should always save the callflow
      importService.on('before_upload', function (data) {
        assert('upload data should be an Object', typeOf(data) === 'object');

        _this2.set('fileToBeUploaded', data);
        _this2.send('save');
      });
    },
    uploadRoutingRules: function uploadRoutingRules() {
      var fileToBeUploaded = this.get('fileToBeUploaded');

      if (fileToBeUploaded) {
        // eslint-disable-next-line camelcase
        var params = { routing_automation_id: this.get('routingAutomation.id') };

        this.set('routingAutomation.content.routingRuleType', _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import']);
        this.setProperties({
          'fileToBeUploaded.params': params,
          'importModalParams.params': params
        });
        this.get('importService').setImportProgress('routing_rules', this.get('routingAutomation.id'));
        this.get('importService').apiAction(fileToBeUploaded);
      }
    },
    saveCallFlow: function saveCallFlow() {
      var _this3 = this;

      this.set('callFlow.name', this.get('callFlow.routingAutomation.name'));

      return this.get('callFlow').save().then(function (callFlow) {
        return callFlow.get('routingAutomation.content').save();
      }).then(function () {
        _this3.get('callFlow').reload();
        _this3.set('isSaving', false);
      });
    },
    actions: {
      save: function save() {
        var _this4 = this;

        if (this.get('importModalParams.showImportModal')) {
          this.set('routingAutomation.content.routingRuleType', _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import']);
        }

        this.set('startValidate', false);
        this.set('isSaving', true);
        this.get('callFlow.routingAutomation').then(function (routingAutomation) {
          return routingAutomation.validate();
        }).then(function () {
          return _this4.saveCallFlow();
        }).then(function () {
          _this4.uploadRoutingRules();
          _this4.get('routing').transitionTo(_this4.get('editNumber') ? 'protected.admin.numbers' : 'protected.admin.callflows', [], {
            callFlowID: _this4.get('callFlow.id'),
            editNumber: _this4.get('editNumber'),
            actionFor: _this4.get('actionFor')
          });
        })['catch'](function () {
          _this4.set('startValidate', true);
          _this4.set('isFlowIncomplete', true);
          _this4.set('isSaving', false);
        });
      },
      onRuleTypeChange: function onRuleTypeChange(ruleType) {
        assert('ruleType should be a number', typeOf(ruleType) === 'number');

        this.set('routingAutomation.routingRuleType', ruleType);
        /**
         * show import modal if ruleType is import
         * the default behavior of import modal is to upload file as soon as user clicks on upload file
         * setting passive will defer the upload of the file at a later time manaully
         */
        if (_freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import'] === ruleType) {
          this.set('importModalParams.showImportModal', true);
          this.set('importModalParams.passive', true);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this5 = this;

      this._super.apply(this, arguments);

      // unsubscribe `import-service` events

      var importService = this.get('importService');

      if (this.get('isAFallBack') && this.get('parentQueue.content')) {
        this.set('parentQueue.showChild', false);
      }

      importService.off('closed_user');
      importService.off('before_upload');
      importService.off('import_success');
      importService.off('import_progress');
      importService.off('import_failed');

      this.setProperties({
        'importModalParams.showImportModal': false,
        'importModalParams.passive': false,
        'fileToBeUploaded': false
      });

      if (!this.get('isAFallBack')) {
        this.store.peekAll('call-flow').forEach(function (record) {
          if (record.get('isSaving')) {
            return;
          }

          if (record.get('isNew')) {
            return _this5.store.unloadRecord(record);
          }
          if (record.get('hasDirtyAttributes')) {
            record.rollbackAttributes();
            record.rollbackRelationships();
          }
        });

        /**
         * TODO: find a better way to release memory
         * reason: unloadAll schedules the unloading to happen in the next cycle which causes an error when trying
         * to edit another callflow immediately after exiting from RA.
         *
         * run(() => {
         *  callFlowTypes.forEach(model => this.store.unloadAll(model));
         * });
         */
        ['routingAutomation', 'routingRule'].forEach(function (model) {
          return _this5.store.unloadAll(model);
        });
      }
    }
  });
});
/* eslint-disable no-console */
/* eslint-disable sort-vars */