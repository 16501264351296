define('freshfone-ember/components/call-tags/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend({
    enabledTags: computed.filterBy('model', 'disabled', false),
    allTagsDisabled: computed.empty('enabledTags'),
    accountConfigurationService: service('account-configuration'),
    accountConfiguration: computed.alias('accountConfigurationService.config'),
    isTaggingMandatory: computed('accountConfiguration', function () {
      return this.get('accountConfiguration.mandateCallTagging');
    }),
    validate: function validate(isTaggingMandatory, isDeletingLastTag) {
      var _this = this;

      if (this.get('allTagsDisabled') && this.get('isTaggingMandatory')) {
        this.setProperties({
          isTaggingMandatory: isTaggingMandatory,
          validationMessage: _ember['default'].i18n.t('call_tags.mandatory_call_tagging.error_message')
        });
        later(function () {
          return _this.set('validationMessage', null);
        }, 3000);
        if (isDeletingLastTag) return true;
        return;
      }
      return true;
    },

    actions: {
      saveConfiguration: function saveConfiguration(isTaggingMandatory, isDeletingLastTag) {
        var _this2 = this;

        if (this.validate(isTaggingMandatory, isDeletingLastTag)) {
          this.get('accountConfiguration').set('mandateCallTagging', !isTaggingMandatory);
          this.get('accountConfigurationService').updateConfig().then(function (response) {
            var isMandatory = response.get('mandateCallTagging');

            _this2.set('isTaggingMandatory', isMandatory);
          })['catch'](function (e) {
            _this2.get('accountConfiguration').rollbackAttributes();
            console.log('Mandate Call Tagging changes failed', e);
          });
        }
      }
    }
  });
});