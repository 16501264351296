define('freshfone-ember/components/ongoing-widget/transfer-status/new/component', ['exports', 'freshfone-ember/constants/active-call-type', 'freshfone-ember/constants/call-state', 'freshfone-ember/constants/call-status', 'ember', 'freshfone-ember/constants/transfer-types'], function (exports, _freshfoneEmberConstantsActiveCallType, _freshfoneEmberConstantsCallState, _freshfoneEmberConstantsCallStatus, _ember, _freshfoneEmberConstantsTransferTypes) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var testing = _ember['default'].testing;
  var later = _ember['default'].run.later;
  var or = _ember['default'].computed.or;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({
    isCancelling: false,
    isResuming: false,
    telephony: service(),
    parallelCall: service(),
    notifications: service('toastr'),
    currentCall: computed.alias('telephony.currentCall'),
    parallelCalls: computed.alias('telephony.parallelCalls'),
    isTransferUnavailable: computed('currentCall.status', {
      get: function get() {
        var blindTransferUnavailable = this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].TransferUnavailable,
            warmTransferUnavailable = this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferUnavailable;

        if (blindTransferUnavailable) {
          this.set('isBlindTransferUnavailable', true);
        } else if (warmTransferUnavailable) {
          this.set('isWarmTransferUnavailable', true);
        }

        return blindTransferUnavailable || warmTransferUnavailable;
      }
    }),
    isTransferring: computed('currentCall.status', function () {
      return this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].Transferring || this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferring;
    }),
    resuming: computed.alias('parallelCall.loadingStatus.resuming'),
    merging: computed.alias('parallelCall.loadingStatus.merging'),
    transferType: computed.alias('currentCall.transferType'),
    primaryConferenceType: computed.alias('currentCall.conferenceType'),
    parallelConferenceType: computed('parallelCalls.@each.conferenceType', function () {
      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];

      return parallelCall && parallelCall.get('conferenceType');
    }),
    conferenceType: computed('currentCall.callType', 'primaryConferenceType', 'parallelConferenceType', function () {
      return this.get('currentCall.callType') !== _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('primaryConferenceType') : this.get('parallelConferenceType');
    }),
    transferAgentName: computed('resuming', 'merging', 'currentCall.callType', 'currentCall.transferTarget.name', 'currentCall.callerName', 'currentCall.contact', 'parallelCalls.[]', 'parallelCallActive', 'primaryCallActive', function () {
      var primaryContact = this.get('currentCall.contact.name') || this.get('currentCall.callerName'),
          parallelContact = this.get('currentCall.transferTarget.name');

      if (!(this.get('resuming') || this.get('merging'))) {
        return parallelContact;
      }

      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Primary ? this.get('parallelCallActive') && parallelContact : this.get('primaryCallActive') && primaryContact;
    }),
    isCsatIvrTransfer: computed.alias('telephony.transferringToCsatIvr'),
    isTeamTransfer: computed.equal('transferType', _freshfoneEmberConstantsTransferTypes['default'].Team),
    isAgentTransfer: computed.equal('transferType', _freshfoneEmberConstantsTransferTypes['default'].Agent),
    isExternalNumberTransfer: computed.equal('transferType', _freshfoneEmberConstantsTransferTypes['default'].ExternalTransfer),
    targetAgentUnavailable: computed.and('isAgentTransfer', 'isTransferUnavailable'),
    targetTeamUnavailable: computed.and('isTeamTransfer', 'isTransferUnavailable'),
    targetExternalNumberUnavailable: computed.and('isExternalNumberTransfer', 'isTransferUnavailable'),
    callCompleted: computed.equal('currentCall.status', 'completed'),
    showConferenceMessage: or('conferenceUnavailable', 'conferenceEnded'),
    conferenceStatusMessage: computed('conferenceUnavailable', 'conferenceEnded', 'isCancelling', function () {
      if (this.get('conferenceUnavailable')) {
        return _ember['default'].i18n.t('agent_conference.not_available');
      }
      if (this.get('conferenceEnded')) {
        return _ember['default'].i18n.t('agent_conference.exit');
      }
      if (this.get('isCancelling')) {
        return _ember['default'].i18n.t('agent_conference.cancelling_conference');
      }
    }),
    isAgentParallelCallActive: computed.alias('currentCall.isAgentParallelCallActive'),
    isParallelCallUnavailable: computed.alias('currentCall.isParallelCallUnavailable'),
    isConnectingParallelCall: computed('currentCall.status', 'isParallelCallUnavailable', function () {
      return this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].ParallelCallInitiated && !this.get('isParallelCallUnavailable');
    }),
    isCancellingParallelCall: computed('currentCall.status', function () {
      return this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].ParallelCallCancelling;
    }),
    showParallelCallStatusMessage: computed('isAgentParallelCallActive', 'isConnectingParallelCall', 'isCancellingParallelCall', 'resuming', 'isParallelCallUnavailable', 'merging', 'showConferenceMessage', 'isCsatIvrTransfer', function () {
      if (this.get('isCsatIvrTransfer')) {
        return true;
      }

      return this.get('isAgentParallelCallActive') && !this.get('showConferenceMessage') && (this.get('isConnectingParallelCall') || this.get('isCancellingParallelCall') || this.get('resuming') || this.get('isParallelCallUnavailable') || this.get('merging'));
    }),
    disconnectingFromBanner: computed.alias('parallelCall.loadingStatus.disconnectingFromBanner'),
    disconnectingFromControls: computed.alias('parallelCall.loadingStatus.disconnectingFromControls'),
    isLoading: computed.or('resuming', 'merging', 'disconnectingFromBanner', 'disconnectingFromControls'),
    primaryCallGroupId: computed.alias('currentCall.groupId'),
    isConferenceInProgress: computed.alias('telephony.isConferenceInProgress'),
    conferenceInitiated: computed.alias('telephony.conferenceInitiated'),
    conferenceEnded: computed.alias('telephony.conferenceEnded'),
    conferenceUnavailable: computed.alias('telephony.conferenceUnavailable'),
    primaryConferenceTargetName: computed('currentCall.conferenceTarget', function () {
      var target = this.get('currentCall.conferenceTarget');

      return target && target.name;
    }),
    parallelConferenceTargetName: computed('parallelCalls.@each.conferenceTarget', function () {
      var parallelCall = this.get('parallelCalls').length > 0 ? this.get('parallelCalls').get('firstObject') : null,
          conferenceTarget = parallelCall && parallelCall.get('conferenceTarget');

      return conferenceTarget && conferenceTarget.name;
    }),
    conferenceTargetName: computed('currentCall.callType', 'primaryConferenceTargetName', 'parallelConferenceTargetName', function () {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('parallelConferenceTargetName') : this.get('primaryConferenceTargetName');
    }),
    inactiveConferenceTargetName: computed('currentCall.callType', 'primaryConferenceTargetName', 'parallelConferenceTargetName', function () {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('primaryConferenceTargetName') : this.get('parallelConferenceTargetName');
    }),
    primaryCallActive: computed('currentCall.callState', function () {
      var callState = this.get('currentCall.callState'),
          callActive = callState !== _freshfoneEmberConstantsCallState['default'].Ended && callState !== _freshfoneEmberConstantsCallState['default'].Removed && callState !== _freshfoneEmberConstantsCallState['default'].CloseAndResume;

      return !callState || callActive;
    }),
    parallelCallActive: computed('parallelCalls.@each.state', function () {
      var _get3 = this.get('parallelCalls');

      var _get32 = _slicedToArray(_get3, 1);

      var parallelCall = _get32[0];
      var state = parallelCall && parallelCall.get('state');

      return state !== _freshfoneEmberConstantsCallState['default'].Ended && state !== _freshfoneEmberConstantsCallState['default'].Removed && state !== _freshfoneEmberConstantsCallState['default'].CloseAndResume;
    }),
    primaryHoldDuration: computed.alias('currentCall.primaryHoldDuration'),
    secondaryHoldDuration: computed.alias('currentCall.secondaryHoldDuration'),
    init: function init() {
      this._super.apply(this, arguments);
      this.bindNodeEvents();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unBindNodeEvents();
    },

    bindNodeEvents: function bindNodeEvents() {
      var _this = this;

      this.get('telephony.event').subscribe('transfer_unavailable', function () {
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.resetTransferStates();
        _this.set('isBlindTransferUnavailable', true);
      });
      this.get('telephony.event').subscribe('warm_transfer_accepted', function () {
        return _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
      });
      this.get('telephony.event').subscribe('parallel_call_accepted', function (groupIds) {
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.get('parallelCall').initializeParallelCall(groupIds);
        run(function () {
          return _this.set('secondaryHoldDuration', _this.get('primaryHoldDuration'));
        });
      });
      this.get('telephony.event').subscribe('warm_transfer_unavailable', function () {
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.resetTransferStates();
        _this.set('isWarmTransferUnavailable', true);
      });
      ['transfer_resume', 'warm_transfer_resume'].forEach(function (event) {
        _this.get('telephony.event').subscribe(event, function () {
          _this.resetTransferStates();
          _this.setProperties({
            isCancelling: false,
            isResuming: false
          });
          _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        });
      });

      ['agent_conference_accepted', 'external_conference_accepted'].forEach(function (event) {
        _this.get('telephony.event').subscribe(event, function () {
          _this.get('parallelCall').startConference();
        });
      });
      ['agent_conference_unavailable', 'external_conference_unavailable'].forEach(function (event) {
        _this.get('telephony.event').subscribe(event, function () {
          _this.get('parallelCall').updateConference(_freshfoneEmberConstantsCallState['default'].Unavailable);
        });
      });
      ['agent_conference_ended', 'external_conference_ended'].forEach(function (event) {
        _this.get('telephony.event').subscribe(event, function (_ref) {
          var endPrimaryConference = _ref.endPrimaryConference;
          var callId = _ref.callId;

          _this.get('parallelCall').endConference(endPrimaryConference, callId);
        });
      });
    },

    resetTransferStates: function resetTransferStates() {
      this.setProperties({
        isBlindTransferUnavailable: false,
        isWarmTransferUnavailable: false
      });
    },

    unBindNodeEvents: function unBindNodeEvents() {
      var _this2 = this;

      ['transfer_unavailable', 'warm_transfer_unavailable', 'transfer_resume', 'warm_transfer_resume', 'agent_conference_unavailable', 'agent_conference_accepted', 'agent_conference_ended', 'external_conference_unavailable', 'external_conference_accepted', 'external_conference_ended', 'warm_transfer_accepted', 'parallel_call_accepted'].forEach(function (event) {
        return _this2.get('telephony.event').unsubscribe(event, function () {});
      });
    },
    conferenceSuccessful: function conferenceSuccessful() {
      var _this3 = this;

      this.setProperties({
        'currentCall.isAddingToCall': false,
        'isConferenceStarted': true
      });
      later(function () {
        return _this3.set('isConferenceStarted', false);
      }, testing ? 0 : 5000);
    },
    resetConference: function resetConference() {
      var _this4 = this;

      this.set('currentCall.isAddingToCall', false);
      later(function () {
        return _this4.set('isTargetUnavailable', false);
      }, testing ? 0 : 5000);
    },
    alertConferenceEnded: function alertConferenceEnded() {
      var _this5 = this;

      this.set('isConferenceCompleted', true);
      later(function () {
        return _this5.set('isConferenceCompleted', false);
      }, testing ? 0 : 5000);
    },

    cancelTransfer: function cancelTransfer() {
      var _this6 = this;

      (this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].Transferring ? this.get('telephony').getCurrentCall().cancelTransfer() : this.get('telephony').getCurrentCall().cancelWarmTransfer()).then(function () {
        return _this6.get('telephony').updateCurrentCall(_this6.get('telephony').getCurrentCall());
      })['catch'](function () {
        return _this6.toggleProperty('isCancelling');
      });
    },

    cancelConference: function cancelConference() {
      var _this7 = this;

      var cancelConferenceCallbacks = {
        agent_conference: 'cancelAgentConference',
        external_conference: 'cancelExternalConference'
      };

      this.get('telephony').getCurrentCall()[cancelConferenceCallbacks[this.get('conferenceType')]]().then(function () {
        run(function () {
          _this7.set('isCancelling', false);
          _this7.get('parallelCall').removeConference();
        });
      })['catch'](function (error) {
        run(function () {
          _this7.set('isCancelling', false);
          _this7.showErrorMsg(error, _this7.get('i18n').t('agent_conference.failed_to_cancel'));
          _this7.get('parallelCall').initializeConference(_this7.get('conferenceType'));
        });
      });
    },
    showErrorMsg: function showErrorMsg(error, msg) {
      var message = error ? error.errors : msg;

      this.get('notifications').error(message);
    },
    logError: function logError(err, message) {
      // eslint-disable-next-line no-console
      console.error(message, err);
    },
    actions: {
      resumeCall: function resumeCall() {
        var _this8 = this;

        if (this.get('isResuming')) {
          return;
        }
        this.toggleProperty('isResuming');

        (this.get('isBlindTransferUnavailable') ? this.get('telephony').getCurrentCall().cancelTransfer() : this.get('telephony').getCurrentCall().cancelWarmTransfer()).then(function () {
          return _this8.get('telephony').updateCurrentCall(_this8.get('telephony').getCurrentCall());
        })['catch'](function () {
          return _this8.toggleProperty('isResuming');
        });
      },
      cancelCall: function cancelCall() {
        this.toggleProperty('isCancelling');

        if (this.get('conferenceInitiated')) {
          this.cancelConference();
        } else {
          this.cancelTransfer();
        }
      },
      cancelParallelCall: function cancelParallelCall() {
        this.sendAction('cancelParallelCall');
      },
      resumePrimaryCall: function resumePrimaryCall() {
        var _this9 = this;

        if (this.get('isLoading')) {
          return;
        }
        this.set('resuming', true);
        this.get('telephony').getCurrentCall().resumeParallelCall(this.get('primaryCallGroupId'))['catch'](function (error) {
          run(function () {
            _this9.set('resuming', false);
            _this9.logError(error, '- Failed to resume ' + _freshfoneEmberConstantsActiveCallType['default'].Customer + ' call');
            _this9.showErrorMsg(error, _this9.get('i18n').t('parallel_call.resume_failed'));
          });
        });
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */
/* eslint-disable camelcase */