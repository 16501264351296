define('freshfone-ember/components/number-component/edit-form/number-form/component', ['exports', 'ember', 'freshfone-ember/mixins/errorfield-focus-tabs', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/mixins/validations/number', 'freshfone-ember/helpers/message-default-types', 'freshfone-ember/helpers/record-default-types'], function (exports, _ember, _freshfoneEmberMixinsErrorfieldFocusTabs, _emberComponentInboundActionsInboundActions, _freshfoneEmberMixinsValidationsNumber, _freshfoneEmberHelpersMessageDefaultTypes, _freshfoneEmberHelpersRecordDefaultTypes) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsErrorfieldFocusTabs['default'], _emberComponentInboundActionsInboundActions['default'], _freshfoneEmberMixinsValidationsNumber['default'], {
    classNames: 'number-form',

    recordTypeList: computed(function () {
      return [{
        id: 0,
        value: _ember['default'].i18n.t('recording.record_not')
      }, {
        id: 1,
        value: _ember['default'].i18n.t('recording.record_all')
      }, {
        id: 2,
        value: _ember['default'].i18n.t('recording.record_incoming')
      }, {
        id: 3,
        value: _ember['default'].i18n.t('recording.record_outgoing')
      }, {
        id: 4,
        value: _ember['default'].i18n.t('recording.record_manual')
      }];
    }),
    submitLabel: computed('freshfoneNumber.isSaving', function () {
      return this.get('freshfoneNumber.isSaving') ? 'Saving...' : 'Save Changes';
    }),
    recordType: computed('freshfoneNumber.recordType', {
      get: function get() {
        return this.get('recordTypeList')[this.get('freshfoneNumber.recordType')];
      },
      set: function set(key, value) {
        this.set('freshfoneNumber.recordType', value.id);
        if (this.get('canOptInRecording')) this.set('freshfoneNumber.recordingOptIn', false);
        /* 
          freshfoneNumber.callTranscription can be null for some number which were created previosly and never been updated. 
          For this situation, an additional check this.get('freshfoneNumber.callTranscription') has been put.
        */
        if (this.get('freshfoneNumber.recordType') === _freshfoneEmberHelpersRecordDefaultTypes['default'].doNotRecord && this.get('freshfoneNumber.callTranscription')) this.set('freshfoneNumber.callTranscription', false);
        return value;
      }
    }),
    canOptInRecording: computed('recordType.id', function () {
      return this.get('recordType.id') === 1 || this.get('recordType.id') === 2 || this.get('recordType.id') === 4;
    }),
    selectedRecordingOptInMessage: computed('canOptInRecording', {
      get: function get() {
        if (this.get('freshfoneNumber.recordingOptInMessage.content')) {
          return this.get('freshfoneNumber.recordingOptInMessage');
        }
        var messages = this.store.peekAll('message');

        var _messages$filterBy = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].recordingOptInMessage);

        var _messages$filterBy2 = _slicedToArray(_messages$filterBy, 1);

        var recordingOptInMessage = _messages$filterBy2[0];

        this.set('freshfoneNumber.recordingOptInMessage', recordingOptInMessage);
        return recordingOptInMessage;
      },
      set: function set(key, value) {
        this.set('freshfoneNumber.recordingOptInMessage', value);
        return value;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('setNumberFormRef')(this);
    },

    evaluate: function evaluate() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.validate().then(resolve)['catch'](function (e) {
          _this.set('startValidate', true);
          reject(e);
        });
      });
    },

    actions: {
      showNewMessage: function showNewMessage(attribute) {
        this.get('showNewMessage')('freshfoneNumber.' + attribute);
      },
      update: function update() {
        this.get('update')();
      },
      toggleCallMask: function toggleCallMask() {
        this.get('toggleCallMask')();
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */