define('freshfone-ember/routes/protected/dashboard', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('common.home');
    }),
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('common.home') };
    })
  });
});