define('freshfone-ember/constants/message', ['exports'], function (exports) {
  var SCOPE_ADMIN = 0;
  exports.SCOPE_ADMIN = SCOPE_ADMIN;
  var SCOPE_USER = 1;

  exports.SCOPE_USER = SCOPE_USER;
  var LABELS = {
    MESSAGE: {
      tableHeader: 'message_components.message_list.message_name',
      searchPlaceholder: 'message_components.message_list.search_placeholder',
      pageTitle: 'admin_components.Messages',
      namePlaceholder: 'message_components.record_component.message',
      nameLabel: 'message_components.new_message.name',
      addButtonText: 'message_components.new_message.add_message'
    },
    VOICEMAIL: {
      tableHeader: 'voicemailDrop.voicemailName',
      searchPlaceholder: 'voicemailDrop.searchVoicemails',
      pageTitle: 'voicemailDrop.voicemail',
      namePlaceholder: 'voicemailDrop.voicemail',
      nameLabel: 'voicemailDrop.voicemailName',
      addButtonText: 'voicemailDrop.addVoicemail'
    }
  };
  exports.LABELS = LABELS;
});