define('freshfone-ember/components/live-calls/save-queues-view/confirm-save-view-modal/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/constants/views'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberConstantsViews) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsModelFormMixin['default'], {
    maxLimit: _freshfoneEmberConstantsViews['default']['viewsLimit'],
    maxCharacterLimit: _freshfoneEmberConstantsViews['default']['viewsCharacterLimit'],
    maxCharLimitReached: computed.gt('viewName.length', 'maxCharacterLimit'),
    availableLength: computed('viewName', function () {
      var charactersLeft = this.get('maxCharacterLimit') - this.get('viewName.length');
      return charactersLeft >= 0 ? charactersLeft : 0;
    }),
    actions: {
      closeModal: function closeModal() {
        this.set('showSaveViewModal', false);
      },

      saveView: function saveView() {
        var _this = this;

        this.validate().then(function () {
          if (_this.get('selectedView') && !_this.get('isSaveViewAs')) {
            _this.get('selectedView').save().then(function (view) {
              _this.set('selectedView', view);
              _this.set('viewQueuesList', view.get('queues'));
              _this.send('closeModal');
            });
          } else {
            (function () {
              var view = _this.store.createRecord('slm-view', {
                name: _this.get('viewName'),
                queues: _this.get('selectedQueues')
              });

              view.save().then(function (response) {
                // Rollback attributes as it makes changes to the present model
                _this.rollbackDirtyChanges('selectedView');
                _this.set('selectedView', view);
                _this.set('viewQueuesList', view.get('queues'));
                _this.set('session.account.slmViewCount', _this.get('session.account.slmViewCount') + 1);
                _this.send('closeModal');
                if (_this.get('isSaveViewAs')) {
                  _this.toggleProperty('isSaveViewAs');
                }
                _this.set('startValidate', false);
              })['catch'](function (error) {
                _this.set('startValidate', true);
                _this.set('viewNameError', error.errors[0].detail);
              });
            })();
          }
        })['catch'](function (error) {
          _this.set('startValidate', true);
          _this.set('viewNameError', error.viewName[0].string);
        });
      }
    },
    validations: {
      'viewName': {
        inline: (0, _emberValidations.validator)(function () {
          if (_ember['default'].isBlank(this.get('viewName'))) {
            return _ember['default'].i18n.t("service_level_metrics.blank_view_name");
          } else if (this.get('viewName.length') > this.get('maxCharacterLimit')) {
            return _ember['default'].i18n.t("service_level_metrics.max_char_limit");
          }
        })
      }
    }
  });
});