define('freshfone-ember/routes/protected/get-started', ['exports', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/routes/protected', 'freshfone-ember/constants/account'], function (exports, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberRoutesProtected, _freshfoneEmberConstantsAccount) {
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    beforeModel: function beforeModel() {
      var renderEligibilty = this.hasSignInTypeEligibilty() && !this.get('session.account.onboarding_completed'),
          emailSignUpConfirmed = this.hasGlobalFeature('plg_onboarding_email_signup') && this.get('session.account.signup_type') === _freshfoneEmberConstantsAccount['default'].signInType.email && this.get('session.user.email_confirmed');

      if (!renderEligibilty) {
        this.transitionTo('protected.dashboard');
      }

      if (emailSignUpConfirmed) {
        this.transitionTo('protected.get-number');
      }
    }
  });
});