define('freshfone-ember/components/number-component/purchase-form/local-purchase/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/purchase-form.mixin', 'freshfone-ember/utils/freshfone-number-util', 'freshfone-ember/constants/locality-supported-countries'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsPurchaseFormMixin, _freshfoneEmberUtilsFreshfoneNumberUtil, _freshfoneEmberConstantsLocalitySupportedCountries) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsPurchaseFormMixin['default'], {
    session: service(),
    isFirstClick: true,
    countries: computed({
      get: function get() {
        return this.get('isVisible') ? _freshfoneEmberUtilsFreshfoneNumberUtil['default'].localCountries() : [];
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },

    isSearchResultChanged: observer('searchResult.availableNumbers.[]', function () {
      var country = this.get('country.code');
      var states = _freshfoneEmberUtilsFreshfoneNumberUtil['default'].localStates(country);
      var locality = this.get('locality');
      var state = this.get('searchResult.availableNumbers').find(function (obj) {
        return obj.locality === locality.name;
      });
      if (state) {
        this.set('state', states.findBy('shortCode', state.region));
      }
    }),

    isLocalitySupported: computed('country.code', function () {
      return _freshfoneEmberConstantsLocalitySupportedCountries['default'].countries.contains(this.get('country.code'));
    }),

    country: computed('countries.@each.code', function () {
      return this.get('countries').findBy('code', 'US');
    }),

    mobileSelected: computed.equal('type', _ember['default'].i18n.t('numbers.purchase.mobile').string),

    localAndMobile: computed.and('country.local', 'country.mobile'),

    states: computed('country.code', function () {
      var country = this.get('country.code');
      if (country) {
        var states = _freshfoneEmberUtilsFreshfoneNumberUtil['default'].localStates(country);
        var state = states.findBy('shortCode', 'default');
        this.send('setStateChange', state);
        return states;
      }
    }),

    localTabTypes: computed('country.code', function () {
      var country = this.get('country.code');
      if (country) {
        return [_ember['default'].i18n.t('numbers.purchase.local').string, _ember['default'].i18n.t('numbers.purchase.mobile').string];
      }
    }),

    localRate: computed('country.code', function () {
      var country = this.get('country.code');
      return _freshfoneEmberUtilsFreshfoneNumberUtil['default'].rate('local', country);
    }),

    mobileRate: computed('country.code', function () {
      var country = this.get('country.code');
      return _freshfoneEmberUtilsFreshfoneNumberUtil['default'].rate('mobile', country);
    }),

    rate: computed('country.code', function () {
      return this.get('mobileSelected') ? this.get('mobileRate') : this.get('localRate');
    }),

    buildSearchParams: function buildSearchParams() {
      var stateCode = this.get('state.shortCode');
      var localityCode = this.get('locality.shortCode');
      var numType = this.get('mobileSelected') ? 'mobile' : 'local';
      return {
        type: numType,
        country: this.get('country.code'),
        region: _ember['default'].isEmpty(stateCode) || stateCode === 'default' ? '' : stateCode,
        locality: _ember['default'].isEmpty(localityCode) || localityCode === 'default' ? '' : localityCode,
        contains: this.get('contains') || ''
      };
    },

    numberRate: function numberRate(type) {
      return type == 'local' ? this.get('localRate') : this.get('mobileRate');
    },

    numberRateTypeText: function numberRateTypeText() {
      if (this.get('country.local')) {
        return _ember['default'].i18n.t('numbers.purchase.local').string;
      } else {
        return _ember['default'].i18n.t('numbers.purchase.mobile').string;
      }
    },

    actions: {
      setStateChange: function setStateChange(state) {
        this.set('state', state);
        this.set('locality', { name: _ember['default'].i18n.t('numbers.purchase.purchase_form.all_localities'), shortCode: 'default' });
        this.send('setTypeChange', this.numberRateTypeText());
      },
      setTypeChange: function setTypeChange(type) {
        this.set('type', type);
        this.send('search');
      },
      search: function search() {
        var params = this.buildSearchParams();
        this.sendAction('search', params);
      },
      buy: function buy(params) {
        params['rate'] = this.numberRate(params.numberType);
        params['address'] = {};
        this.sendAction('buy', params);
      },
      setLocalityChange: function setLocalityChange(locality) {
        this.set('locality', locality);
        this.send('setTypeChange', this.numberRateTypeText());
      }
    }
  });
});