define('freshfone-ember/components/call-queue/child-message/component', ['exports', 'freshfone-ember/constants/call-queues', 'ember', 'freshfone-ember/mixins/click-outside-listener'], function (exports, _freshfoneEmberConstantsCallQueues, _ember, _freshfoneEmberMixinsClickOutsideListener) {
  var computed = _ember['default'].computed;
  var defineProperty = _ember['default'].defineProperty;
  var service = _ember['default'].inject.service;
  var dasherize = _ember['default'].String.dasherize;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], _ember['default'].Evented, {
    accountConfigService: service('account-configuration'),
    accountConfiguration: computed.alias('accountConfigService.config'),
    classNames: ["child-message"],
    startValidate: computed.alias('parent.startValidate'),
    classNameBindings: ['messageType', 'isChildOpen:child-message-open'],
    isChildOpen: computed.alias('parentQueue.showChild'),
    childMessage: computed.alias('parentQueue.activeChildMessage'),
    actionOption: computed.alias('parentQueue.actionOption'),
    actionType: computed.alias('parentQueue.actionType'),
    messageType: computed.alias('actionOption.abbr'),
    autoAnswerOptions: _freshfoneEmberConstantsCallQueues.AUTO_ANSWER,
    actionMessageClass: computed('actionMessage', function () {
      return dasherize(this.get('actionMessage'));
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.setActionMessage();
      this.defineErrorComputedMacro();
      this.bindOutsideClick();
    },
    onOutsideClick: function onOutsideClick() {
      this.send('closeChild');
    },
    setActionMessage: function setActionMessage() {
      var actionMessage = this.get('actionType').replace("Action", "Message");
      this.set('actionMessage', actionMessage);
    },

    defineErrorComputedMacro: function defineErrorComputedMacro() {
      var _this = this;

      var actionMessage = this.get('actionMessage');
      var propName = ['parentQueue.errors.', actionMessage].join("");
      defineProperty(this, 'errors', _ember['default'].computed(function () {
        return _this.get(propName);
      }).property(propName, 'childMessage'));
    },

    actions: {
      showNewMessage: function showNewMessage(attr) {
        this.sendAction("showNewMessage", attr, true);
      },
      closeChild: function closeChild() {
        this.set('parentQueue.showChild', false);
        this.set('parentQueue.activeChildMessage', null);
        var clicked = 'parentQueue.' + this.get('actionType') + 'Clicked';
        this.set(clicked, false);
        this.unbindOutsideClick();
      }
    }
  });
});