define('freshfone-ember/components/common/sheet-that-covers/component', ['exports', 'ember'], function (exports, _ember) {
	var Component = _ember['default'].Component;
	var computed = _ember['default'].computed;
	exports['default'] = Component.extend({
		isPurposePromotion: computed.equal('purpose', 'PROMOTION'),
		classNameBindings: ['isPurposePromotion:purpose-promotion', 'isFullCover:full-cover'],
		isAdmin: computed.equal('session.user.isAdmin', true),

		actions: {
			onClick: function onClick() {
				this.sendAction('clickCallback');
			}
		}
	});
});