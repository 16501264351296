define('freshfone-ember/components/call-flow/component', ['exports', 'freshfone-ember/constants/call-queues', 'ember', 'freshfone-ember/mixins/validations/server-errors'], function (exports, _freshfoneEmberConstantsCallQueues, _ember, _freshfoneEmberMixinsValidationsServerErrors) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var Promise = _ember['default'].RSVP.Promise;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_freshfoneEmberMixinsValidationsServerErrors['default'], {
    classNames: ['call-queue-component'],
    isCallQueueFlow: computed.notEmpty('callFlow.callQueue'),
    isIVRFlow: computed.notEmpty('ivrModel'),
    isEditMode: computed.or('isCallQueueFlow', 'isIVRFlow'),
    isFlowNameEmpty: computed.empty('callFlow.name'),
    routing: service('-routing'),
    showLoader: computed('callFlow.callQueue.isFulfilled', function () {
      return !this.get('callFlow.callQueue.isFulfilled');
    }),
    notifications: service('toastr'),
    waitQueueEnabled: computed('session', function () {
      return this.get('session.account').hasFeature('wait_queue');
    }),
    hasRingingTimeEnhanced: computed('session', function () {
      return this.get('session.account').hasFeature('ringing_time_enhanced');
    }),
    redirectionURL: computed('editNumber', function () {
      if (this.get('editNumber')) {
        return 'protected.admin.numbers';
      }

      return 'protected.admin.callflows';
    }),
    init: function init() {
      this._super.apply(this, _toConsumableArray(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.send('cancel');
      this.rollbackCallQueueChanges();
      this.rollbackRoutingAutomationChanges();
      // Clearing old records from DS
    },

    rollbackRoutingAutomationChanges: function rollbackRoutingAutomationChanges() {
      var _this = this;

      ['routingAutomation', 'routingRule'].forEach(function (model) {
        return run(function () {
          return _this.store.unloadAll(model);
        });
      });
    },

    saveFlow: function saveFlow(callFlow) {
      var _this2 = this;

      var callQueueDirtyItems = callFlow.get('callQueue.content').changedAttributes();

      if (callFlow.get('hasDirtyAttributes') || callQueueDirtyItems.name) {
        callFlow.set('name', callFlow.get('callQueue.name'));
        callFlow.save().then(function () {
          _this2.saveQueue(callFlow);
        });
      } else {
        this.saveQueue(callFlow);
      }
    },
    saveQueue: function saveQueue(callFlow) {
      var _this3 = this;

      // reset queue relationships based on route type
      var callQueue = callFlow.get('callQueue');

      switch (callQueue.get('routeType')) {
        case 'agent':
          callQueue.set('team', null);
          break;
        case 'team':
          callQueue.set('agent', null);
          break;
        default:
          callQueue.setProperties({
            agent: null,
            team: null
          });
          break;
      }

      // reset auto answer values
      if (callQueue.get('routeOption') !== 'least_idle') {
        callQueue.setProperties({ autoAnswerStatus: null });
      }

      if (!this.get('waitQueueEnabled')) {
        callQueue.set('waitQueueEnabled', false);
      }
      callFlow.get('callQueue.content').save().then(function (callQueue) {
        if (callQueue.get('routeOptionMins') < 15 && callQueue.get('routeOptionMins') !== null) {
          if (_this3.get('hasRingingTimeEnhanced')) {
            _this3.get('notifications').warning(_this3.get('i18n').t('call_queue.edit.placeholders.routing_mins_warning'));
          }
        }
        callFlow.reload().then(function () {
          _this3.set('startValidate', false);
          _this3.set('isSaving', false);
          if (_this3.get('editNumber')) {
            _this3.get('routing').transitionTo('protected.admin.numbers', [], {
              editNumber: _this3.get('editNumber'),
              callFlowID: callFlow.id,
              actionFor: _this3.get('actionFor')
            });
          } else {
            _this3.get('routing').transitionTo('protected.admin.callflows');
          }
        });
      })['catch'](function (reason) {
        var callQueue = callFlow.get('callQueue.content');

        _this3.set('isFlowIncomplete', true);
        _this3.set('isSaving', false);
        _this3.handleError(reason);
        _this3.handleAsModelError(reason.errors, callQueue);
      });
    },
    rollbackModelChanges: function rollbackModelChanges() {
      if (!this.get('callFlow.isDirty')) {
        return;
      }
      this.get('callFlow').rollbackAttributes();
    },
    rollbackCallQueueChanges: function rollbackCallQueueChanges() {
      if (!this.get('callFlow.callQueue')) {
        return;
      }
      var unsavedCallQueue = this.store.peekAll('callQueue').filterBy('hasDirtyAttributes', true);

      unsavedCallQueue.forEach(function (callQueue) {
        callQueue.rollbackAttributes();
      });
    },
    rollbackAssociationChanges: function rollbackAssociationChanges() {
      var _this4 = this;

      var model = this.get('callFlow');

      if (!model.eachRelationship) {
        model.eachRelationship(function (key) {
          return _this4.send('cancel', model.get(key));
        });
      }
    },
    validateQueue: function validateQueue(parentQueue, childQueue) {
      if (parentQueue) {
        return parentQueue.get(childQueue).then(function (queue) {
          return queue.validate();
        });
      }
    },
    validateChildQueues: function validateChildQueues(callQueue) {
      var childQueuesValidation = [];

      if (callQueue.get('isValidateQueueFullCallQueue')) {
        childQueuesValidation.push(this.validateQueue(callQueue, 'queueFullCallQueue'));
      }
      if (callQueue.get('isValidateNoAnswerCallQueue')) {
        childQueuesValidation.push(this.validateQueue(callQueue, 'noAnswerCallQueue'));
      }
      if (callQueue.get('isValidateBusyWaitOverCallQueue')) {
        childQueuesValidation.push(this.validateQueue(callQueue, 'busyWaitOverCallQueue'));
      }
      if (callQueue.get('isValidateOfflineCallQueue')) {
        childQueuesValidation.push(this.validateQueue(callQueue, 'offlineCallQueue'));
      }
      if (callQueue.get('isValidateThresholdTimeCallQueue')) {
        childQueuesValidation.push(this.validateQueue(callQueue, 'timeThresholdCallQueue'));
      }
      if (callQueue.get('isValidateCallbackCallQueue')) {
        childQueuesValidation.push(this.validateQueue(callQueue, 'callbackFailCallQueue'));
      }

      return childQueuesValidation;
    },
    validateCallQueue: function validateCallQueue(callFlow) {
      var _this5 = this;

      var callQueue = callFlow.get('callQueue');

      callQueue.then(function (queue) {
        queue.validate().then(function () {
          var childrenValidations = _this5.validateChildQueues(callQueue);

          if (isEmpty(childrenValidations)) {
            _this5.saveFlow(callFlow);

            return;
          }
          Promise.all(childrenValidations).then(function () {
            _this5.saveFlow(callFlow);
          });
        });
      });
    },

    actions: {

      save: function save() {
        var _this6 = this;

        var callFlow = this.get('callFlow');

        this.set('startValidate', true);
        this.set('isSaving', true);
        callFlow.validate().then(function () {
          return callFlow.get('callQueue.content').validate();
        }).then(function () {
          _this6.set('isFlowIncomplete', false);
          _this6.saveFlow(callFlow);
        })['catch'](function () {
          _this6.set('isFlowIncomplete', true);
          _this6.set('isSaving', false);
        });
      },

      cancel: function cancel() {
        this.rollbackModelChanges();
        this.rollbackAssociationChanges();
      }
    }
  });
});