define('freshfone-ember/components/manage-intents/view-edit-intent/intents-list/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    showViewMore: computed('intent.phrases', function () {
      var phrasesCount = this.get('intent.phrases.length');
      if (phrasesCount - 5 > 0) {
        return phrasesCount - 5;
      }
    }),

    actions: {
      toggleViewMore: function toggleViewMore() {
        this.toggleProperty('expanded');
      },

      openDeleteModal: function openDeleteModal() {
        this.set('showDeleteModal', true);
      },

      closeDeleteModal: function closeDeleteModal() {
        this.set('showDeleteModal', false);
      },

      edit: function edit() {
        this.sendAction('openCreateModal', this.get('intent'));
      },

      'delete': function _delete() {
        this.sendAction('deleteIntentCallback', this.get('intent'));
      }
    }

  });
});