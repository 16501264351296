define('freshfone-ember/instance-initializers/analytics-collector', ['exports', 'ember-analytics-collector/instance-initializers/analytics-collector'], function (exports, _emberAnalyticsCollectorInstanceInitializersAnalyticsCollector) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberAnalyticsCollectorInstanceInitializersAnalyticsCollector['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberAnalyticsCollectorInstanceInitializersAnalyticsCollector.initialize;
    }
  });
});