define("freshfone-ember/components/conversation/recent-conversations-list/conversations/call-tags/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 8
              },
              "end": {
                "line": 19,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/conversation/recent-conversations-list/conversations/call-tags/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "ellipsis selected-tag");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element3, 'value');
            morphs[1] = dom.createMorphAt(element3, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["get", "tag.name", ["loc", [null, [18, 58], [18, 66]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "tag.name", ["loc", [null, [18, 69], [18, 81]]], 0, 0, 0, 0]],
          locals: ["tag"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/conversation/recent-conversations-list/conversations/call-tags/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "add-call-tags dropdown");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "data-toggle", "tooltip");
          dom.setAttribute(el2, "data-placement", "top");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element4, 1, 1);
          morphs[1] = dom.createAttrMorph(element5, 'class');
          morphs[2] = dom.createAttrMorph(element5, 'title');
          return morphs;
        },
        statements: [["block", "power-select-multiple", [], ["class", "tags-list", "placeholder", ["subexpr", "t", ["ongoing_widget.type_here"], [], ["loc", [null, [5, 24], [5, 54]]], 0, 0], "searchPlaceholder", ["subexpr", "t", ["ongoing_widget.type_here"], [], ["loc", [null, [6, 30], [6, 60]]], 0, 0], "noMatchesMessage", "", "options", ["subexpr", "@mut", [["get", "tags", ["loc", [null, [8, 20], [8, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "currentCall.tags", ["loc", [null, [9, 21], [9, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", ["setSelectedTag"], [], ["loc", [null, [10, 21], [10, 46]]], 0, 0], "renderInPlace", true, "searchField", "name", "triggerClass", ["subexpr", "if", [["subexpr", "or", [["get", "hasReachedMaxLimit", ["loc", [null, [13, 33], [13, 51]]], 0, 0, 0, 0], ["get", "noOptionsLeft", ["loc", [null, [13, 52], [13, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 29], [13, 66]]], 0, 0], "reached-limit"], [], ["loc", [null, [13, 25], [13, 83]]], 0, 0], "dropdownClass", ["subexpr", "if", [["get", "hasReachedMaxLimit", ["loc", [null, [14, 30], [14, 48]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [14, 26], [14, 56]]], 0, 0], "calculatePosition", ["subexpr", "action", ["calculatePosition"], [], ["loc", [null, [15, 30], [15, 58]]], 0, 0]], 0, null, ["loc", [null, [3, 8], [19, 34]]]], ["attribute", "class", ["subexpr", "if", [["get", "noCallTagsAdded", ["loc", [null, [20, 22], [20, 37]]], 0, 0, 0, 0], "icon-warning flash", "hide"], [], ["loc", [null, [null, null], [20, 67]]], 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "t", ["ongoing_widget.call_tags_required"], [], ["loc", [null, [null, null], [20, 158]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/conversation/recent-conversations-list/conversations/call-tags/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "add-call-tags btn");
          dom.setAttribute(el1, "data-analytics-action-name", "Add Call Tags");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "data-toggle", "tooltip");
          dom.setAttribute(el2, "data-placement", "top");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element0, [5]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          morphs[2] = dom.createAttrMorph(element1, 'class');
          morphs[3] = dom.createMorphAt(element1, 0, 0);
          morphs[4] = dom.createAttrMorph(element2, 'class');
          morphs[5] = dom.createAttrMorph(element2, 'title');
          return morphs;
        },
        statements: [["element", "action", ["showInput"], [], ["loc", [null, [23, 78], [23, 100]]], 0, 0], ["inline", "t", ["ongoing_widget.add_call_tags"], [], ["loc", [null, [24, 8], [24, 44]]], 0, 0], ["attribute", "class", ["concat", ["mandatory ", ["subexpr", "unless", [["get", "isTaggingMandatory", ["loc", [null, [25, 40], [25, 58]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [25, 31], [25, 67]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["ongoing_widget.mandatory"], [], ["loc", [null, [25, 69], [25, 101]]], 0, 0], ["attribute", "class", ["subexpr", "if", [["get", "noCallTagsAdded", ["loc", [null, [26, 22], [26, 37]]], 0, 0, 0, 0], "icon-warning", "hide"], [], ["loc", [null, [null, null], [26, 61]]], 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "t", ["ongoing_widget.call_tags_required"], [], ["loc", [null, [null, null], [26, 152]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/conversation/recent-conversations-list/conversations/call-tags/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "or", [["get", "showInput", ["loc", [null, [1, 10], [1, 19]]], 0, 0, 0, 0], ["get", "selectedTagsCount", ["loc", [null, [1, 20], [1, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 6], [1, 38]]], 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [28, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});