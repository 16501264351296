define('freshfone-ember/models/integrated-resource', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    integratedApplication: belongsTo('integrated-application'),
    link: attr(),
    resourceId: attr(),
    resourceType: attr(),
    remoteResourceType: attr(),
    remoteResourceId: attr()
  });
});