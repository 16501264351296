define('freshfone-ember/constants/mkp-data-keys', ['exports'], function (exports) {
  exports['default'] = (function () {
    /*
     * Keys are camelized keys as in the respective models
     * Values are respective keys' aliases
     */
    var agent = {
      id: '',
      abilities: '',
      name: '',
      timeZone: '',
      email: '',
      phone: '',
      mobile: '',
      lastCallAt: '',
      confirmed: '',
      language: '',
      ringOnSpeakers: '',
      browserStatus: 'browserStatusId',
      role: 'roleId',
      mobileStatus: '',
      sipStatus: '',
      sipEnabled: '',
      lastSeenAt: '',
      outgoingNumberId: '',
      isOutgoingCallMade: ''
    },
        call = { // SDK obj
      callDuration: '',
      caller: '',
      id: '',
      isBeingRecorded: '',
      isCompleted: '',
      isInProgress: '',
      isOnHold: '',
      isOutgoing: '',
      muted: 'isMuted',
      notes: '',
      notMonitorCall: '',
      previousStatus: '',
      status: '',
      type: ''
    },
        contact = {
      id: '',
      address: '',
      company: 'companyId',
      email: '',
      lastCallAt: '',
      name: '',
      title: '',
      totalCalls: ''
    },
        number = {
      id: '',
      address: '',
      addressRequired: '',
      callerId: '',
      country: '',
      displayNumber: '',
      holdMessage: 'holdMessageId',
      name: '',
      nextRenewalAt: '',
      number: '',
      numberType: '',
      rate: '',
      recordType: '',
      region: '',
      ringtoneMessage: 'ringtoneMessageId',
      rule: 'ruleId',
      shortAbandonTimeout: '',
      state: '',
      teams: 'teamIds',
      transcription: 'transcriptionEnabled',
      users: 'agentIds'
    };

    return {
      call: call,
      agent: agent,
      number: number,
      contact: contact
    };
  })();
});