define("freshfone-ember/components/custom-elements/accordion/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/custom-elements/accordion/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "fc-accordion");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "fc-accordion__header");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "fc-accordion__header__title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "fc-accordion__header__subtitle");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [5]);
        var element3 = dom.childAt(element0, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[3] = dom.createAttrMorph(element2, 'class');
        morphs[4] = dom.createAttrMorph(element3, 'class');
        morphs[5] = dom.createMorphAt(element3, 1, 1);
        return morphs;
      },
      statements: [["element", "action", ["toggleAccordion"], [], ["loc", [null, [2, 38], [2, 66]]], 0, 0], ["content", "title", ["loc", [null, [3, 49], [3, 58]]], 0, 0, 0, 0], ["content", "subTitle", ["loc", [null, [4, 53], [4, 65]]], 0, 0, 0, 0], ["attribute", "class", ["concat", ["icon-arrow-down ", ["subexpr", "if", [["subexpr", "not", [["get", "expanded", ["loc", [null, [5, 44], [5, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 39], [5, 53]]], 0, 0], "rotate-180"], [], ["loc", [null, [5, 34], [5, 68]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["fc-accordion__body ", ["subexpr", "if", [["get", "expanded", ["loc", [null, [7, 40], [7, 48]]], 0, 0, 0, 0], "hight-auto"], [], ["loc", [null, [7, 35], [7, 63]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [8, 8], [8, 17]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});