define('freshfone-ember/models/agent-extension', ['exports', 'ember', 'ember-data', 'ember-validations', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _emberData, _emberValidations, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var agentActionTypes = ['offline', 'noAnswer', 'busy', 'noInput', 'invalidInput', 'noExtension'];

  exports.agentActionTypes = agentActionTypes;
  exports['default'] = Model.extend(_emberValidations['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    session: service(),
    name: attr('string'),
    callFlow: belongsTo('callFlow'),
    message: belongsTo('message', { async: true }),
    offlineActionType: attr({ defaultValue: 'hangup' }),
    offlineActionId: attr('number'),
    ringingTimeout: attr('number', { defaultValue: 30 }),
    noAnswerActionType: attr({ defaultValue: 'hangup' }),
    noAnswerActionId: attr('number'),
    busyActionType: attr({ defaultValue: 'hangup' }),
    busyActionId: attr('number'),
    noExtensionActionType: attr({ defaultValue: 'hangup' }),
    noExtensionActionId: attr('number'),
    noInputMessage: belongsTo('message', { async: true }),
    noInputRetries: attr('number', { defaultValue: 3 }),
    noInputActionType: attr({ defaultValue: 'hangup' }),
    noInputActionId: attr('number'),
    invalidInputMessage: belongsTo('message', { async: true }),
    invalidInputRetries: attr('number', { defaultValue: 3 }),
    invalidInputActionType: attr({ defaultValue: 'hangup' }),
    invalidInputActionId: attr('number'),

    actionOptions: computed(function () {
      var options = [{ id: 0, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_ivr'), desc: _ember['default'].i18n.t('call_ivr.action_types.ivr'), name: 'ivr' }, { id: 1, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_callQueue'), desc: _ember['default'].i18n.t('call_ivr.action_types.call_queue'), name: 'callQueue' }, { id: 2, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.hangup'), desc: _ember['default'].i18n.t('call_ivr.action_types.hangup'), name: 'hangup', helpText: _ember['default'].i18n.t('call_queue.edit.fallback.hangup.help_text') }, { id: 3, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.voicemail'), desc: _ember['default'].i18n.t('call_ivr.action_types.voicemail'), name: 'vm', helpText: _ember['default'].i18n.t('call_queue.edit.fallback.vm.help_text') }];

      if (this.hasGlobalFeature('agent_extension_fallback')) {
        options.push({ id: 4, desc: _ember['default'].i18n.t('call_queue.edit.fallback.agent_extension.name'), abbr: 'agent_extension', name: 'agentExtension', chooseDesc: _ember['default'].i18n.t('call_queue.edit.placeholders.choose_agent_extension') });
      }

      return options;
    }),

    blankActionError: function blankActionError(property) {
      if (!this.get(property + 'Id')) {
        if (this.get(property + 'Type') === 'callQueue') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_callqueue');
        } else if (this.get(property + 'Type') === 'ivr') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_ivr');
        } else if (this.get(property + 'Type') === 'agentExtension') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_agent_extension');
        }
      }
    },
    validations: {
      name: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.name')
        }
      },
      noInputRetries: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.retries.blank')
        },
        numericality: {
          onlyInteger: true, greaterThanOrEqualTo: 1, lessThanOrEqualTo: 3,
          messages: {
            numericality: _ember['default'].i18n.t('call_ivr.validation_errors.retries.number'),
            onlyInteger: _ember['default'].i18n.t('call_ivr.validation_errors.retries.integer'),
            greaterThanOrEqualTo: _ember['default'].i18n.t('call_ivr.validation_errors.retries.greater'),
            lessThanOrEqualTo: _ember['default'].i18n.t('agent_extension.validation_error.retries')
          }
        }
      },

      ringingTimeout: {
        presence: {
          message: _ember['default'].i18n.t('agent_extension.validation_error.ringing_timeout.blank')
        },
        numericality: {
          onlyInteger: true, greaterThanOrEqualTo: 30, lessThanOrEqualTo: 900,
          messages: {
            numericality: _ember['default'].i18n.t('call_ivr.validation_errors.retries.number'),
            onlyInteger: _ember['default'].i18n.t('agent_extension.validation_error.number'),
            greaterThanOrEqualTo: _ember['default'].i18n.t('agent_extension.validation_error.ringing_timeout.greater_than_equal'),
            lessThanOrEqualTo: _ember['default'].i18n.t('agent_extension.validation_error.ringing_timeout.less_than_equal')
          }
        }
      },

      invalidInputRetries: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.retries.blank')
        },
        numericality: {
          onlyInteger: true, greaterThanOrEqualTo: 1, lessThanOrEqualTo: 3,
          messages: {
            numericality: _ember['default'].i18n.t('call_ivr.validation_errors.retries.number'),
            onlyInteger: _ember['default'].i18n.t('call_ivr.validation_errors.retries.integer'),
            greaterThanOrEqualTo: _ember['default'].i18n.t('call_ivr.validation_errors.retries.greater'),
            lessThanOrEqualTo: _ember['default'].i18n.t('agent_extension.validation_error.retries')
          }
        }
      },
      invalidInputMessage: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.message')
        }
      },
      offlineActionType: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('offlineAction');
        })
      },
      noAnswerActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('noAnswerAction');
        })
      },
      busyActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('busyAction');
        })
      },
      noExtensionActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('noExtensionAction');
        })
      },
      noInputActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('noInputAction');
        })
      },
      invalidInputActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('invalidInputAction');
        })
      }
    }
  });
});