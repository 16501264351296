define('freshfone-ember/models/agent-status', ['exports', 'ember-data', 'ember', 'freshfone-ember/mixins/model-proxy'], function (exports, _emberData, _ember, _freshfoneEmberMixinsModelProxy) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  var hasMany = _emberData['default'].hasMany;

  var computed = _ember['default'].computed;
  exports['default'] = Model.extend(_freshfoneEmberMixinsModelProxy['default'], {
    name: attr('string'),
    emoji: attr('string', { defaultValue: '😀' }),
    enabled: attr('boolean', { defaultValue: true }),
    statusType: attr('string', { defaultValue: 'offline' }),
    statusSubType: attr('string', { defaultValue: 'offline' }),
    'default': attr('boolean'),
    agents: hasMany('agent', { inverse: 'browserStatus' }),
    isDefaultOnlineStatus: computed('statusType', 'default', function () {
      return this.get('statusType') === 'online' && this.get('default');
    })
  });
});