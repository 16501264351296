define('freshfone-ember/components/live-time-stamp/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEqual = _ember['default'].isEqual;
  var testing = _ember['default'].testing;
  var _Ember$run = _ember['default'].run;
  var later = _Ember$run.later;
  var cancel = _Ember$run.cancel;

  var momentjs = window.moment;exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    classNames: ['live-time-stamp'],
    interval: 0,
    hasEmptyTimestamp: computed.empty('timeStamp'),
    fallbackMessage: 'N/A',

    getTimeSince: function getTimeSince() {
      if (!this.get('timeStamp')) {
        return '';
      }
      var timeStamp = momentjs(this.get('timeStamp')).local();

      return momentjs().diff(timeStamp, 'seconds');
    },
    timeSince: computed('timeStamp', function () {
      return this.getTimeSince();
    }),

    timeStampInWords: computed('timeStamp', 'timeSince', function () {
      if (!this.get('timeStamp')) {
        return '';
      }
      var timeStamp = momentjs(this.get('timeStamp')).local();

      return this.hasGlobalFeature('enhanced_live_dashboard') ? timeStamp.fromNow().replace('ago', '') : timeStamp.fromNow();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.run();
    },

    run: function run() {
      var _this = this;

      if (testing) {
        return;
      }

      if (this.get('hasEmptyTimestamp')) {
        return;
      }
      var interval = this.get('interval') * 1000,
          timer = later(this, function () {
        _this.updateLiveStamp();
        _this.run();
      }, interval);

      this.set('timer', timer);
    },

    updateLiveStamp: function updateLiveStamp() {
      var timeSince = this.getTimeSince();

      this.set('timeSince', timeSince);
      this.updateInterval();
    },

    updateInterval: function updateInterval() {
      var timeSince = this.getTimeSince();

      if (timeSince < 60) {
        this.setAdditionData();
      } else if (timeSince < 3600) {
        this.setAdditionData(60, 'mm', 'minutes');
      } else if (timeSince < 86400) {
        this.setAdditionData(3600, 'HH', 'hours');
      } else {
        this.setAdditionData(86400, 'DD', 'days');
      }
    },

    setAdditionData: function setAdditionData() {
      var interval = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];
      var format = arguments.length <= 1 || arguments[1] === undefined ? 'ss' : arguments[1];
      var unit = arguments.length <= 2 || arguments[2] === undefined ? 'seconds' : arguments[2];

      this.set('interval', interval);
      this.set('unit', unit);
      this.set('format', format);
    },

    secondsFormat: function secondsFormat() {
      var seconds = this.get('timeSince'),
          format = this.get('format');

      if (isEqual(format, 'DD')) {
        return Math.round(seconds / 86400);
      }

      return momentjs().startOf('day').add(seconds * 1000).format(format);
    },

    formattedTimeStamp: computed('timeSince', 'timeStampInWords', 'unit', 'inWords', 'timeStamp', function () {
      if (this.get('inWords')) {
        return this.get('timeStampInWords');
      }
      var unit = this.get('unit');

      var formattedSeconds = this.secondsFormat();

      unit = formattedSeconds <= 1 ? unit.singularize() : unit;

      return formattedSeconds + ' ' + unit;
    }),

    willDestroyElement: function willDestroyElement() {
      if (this.get('timer')) {
        cancel(this.get('timer'));
      }
    }

  });
});
/*
 * time stamp < 60 seconds =>  (interval  = 1 second)
 * time stamp < 1 hr =>  (interval = 60 seconds)
 * time stamp < 1 day =>  (interval = 60 minutes)
 * time stamp > 1 day =>  (interval = 1 day)
 */