define('freshfone-ember/adapters/network-warning', ['exports', 'freshfone-ember/mixins/adapters/multi-word-underscore'], function (exports, _freshfoneEmberMixinsAdaptersMultiWordUnderscore) {
  exports['default'] = _freshfoneEmberMixinsAdaptersMultiWordUnderscore['default'].extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var page = query.page;
      var per_page = query.per_page;
      var callId = query.callId;
      var queryParamStr = new URLSearchParams({
        page: page,
        per_page: per_page
      }).toString();
      var prefix = '/calls/' + callId + '/network_warning?';

      this.deleteQueryParams(query);

      return '' + prefix + queryParamStr;
    },
    deleteQueryParams: function deleteQueryParams(params) {
      Object.keys(params).forEach(function (key) {
        return delete params[key];
      });
    }
  });
});
/* eslint-disable camelcase */