define('freshfone-ember/components/service-level/service-level-form/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/errorfield-focus-tabs', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/mixins/validations/service-level'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsErrorfieldFocusTabs, _emberComponentInboundActionsInboundActions, _freshfoneEmberMixinsValidationsServiceLevel) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend(_freshfoneEmberMixinsValidationsServiceLevel['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _emberComponentInboundActionsInboundActions['default'], _freshfoneEmberMixinsModelFormMixin['default'], {
    init: function init() {
      this._super.apply(this, arguments);
      this.invalidateResetToGlobal = this.invalidateResetToGlobal.bind(this);
      this.populateQueues = this.populateQueues.bind(this);
    },
    title: computed('slideToggle', 'slConfig.isNew', function () {
      return !this.get('slideToggle') || this.get('slConfig.isNew') ? _ember['default'].i18n.t('serviceLevel.form.new') : _ember['default'].i18n.t('serviceLevel.form.edit');
    }),
    savingOrDeleting: computed.or('slConfig.isSaving', 'slConfig.isDeleting'),
    submitLabel: computed('savingOrDeleting', function () {
      return this.get('savingOrDeleting') ? _ember['default'].i18n.t('serviceLevel.saving') : _ember['default'].i18n.t('serviceLevel.save');
    }),
    counterShouldAlert: computed.not('shouldAlert'),
    showTooltip: computed.and('counterShouldAlert', 'isEqualToGlobal'),
    selectedConfigId: computed({
      set: function set(key, value) {
        var _this = this;

        if (value && !this.get('slConfig')) {
          this.set('slConfig', this.store.createRecord('serviceLevelConfiguration'));
          this.get('slConfig').fetchUnconfiguredCallQueues().then(function (queue) {
            _this.populateQueues(queue);
            _this.populateConfig(value);
          });
        }
        return value;
      }
    }),
    slConfig: computed({
      set: function set(key, value) {
        if (value && value.get('id')) {
          if (value.get('id') !== this.get('slConfig.id')) this.set('shouldAlert', false);
        }
        return value;
      },
      get: function get() {
        return this;
      }
    }),
    resetForm: function resetForm() {
      this.rollbackDirtyChanges('slConfig');
      this.setProperties({
        slideToggle: false,
        startValidate: false,
        resetToGlobal: false,
        isEditingGlobal: false,
        shouldAlert: false,
        isEditingQueueDirty: false
      });
      this.removeSelectedConfigId();
    },
    afterSave: function afterSave(slConfig) {
      var resetToGlobal = this.get('resetToGlobal');
      this.setProperties({
        slConfig: resetToGlobal ? null : slConfig,
        isEditingQueueDirty: false
      });
      this.removeSelectedConfigId();
      this.sendAction('onUpdate');
      this.send('toggleSidecard');
    },
    removeSelectedConfigId: function removeSelectedConfigId() {
      if (this.get('selectedConfigId')) this.sendAction('onRemoveSelectedConfigId');
    },
    invalidateResetToGlobal: function invalidateResetToGlobal(e) {
      var BACKSPACE = 8;
      if (!isNaN(e.key) || e.keyCode === BACKSPACE) this.set('resetToGlobal');
    },
    populateQueues: function populateQueues(queue) {
      this.store.unloadAll('callQueue');
      this.store.pushPayload('callQueue', queue);
      this.set('options', this.store.peekAll('callQueue'));
    },
    populateConfig: function populateConfig(selectedConfigId) {
      var selectedQueue = this.get('options').filter(function (callQueue) {
        return callQueue.id === selectedConfigId && !callQueue.isDestroying;
      }).get('firstObject');
      if (selectedQueue) {
        this.sendAction('onResetToGlobal', this.get('slConfig'));
        this.set('slideToggle', true);
        this.set('slConfig.selectedQueue', selectedQueue);
      }
    },
    getSaveOrDelete: function getSaveOrDelete() {
      if (this.get('resetToGlobal')) return 'destroyRecord';
      return 'save';
    },
    setEntityID: function setEntityID() {
      var slConfig = this.get('slConfig');
      slConfig.set('entityId', slConfig.get('entityId') || slConfig.get('selectedQueue.id'));
      delete slConfig.selectedQueue;
    },
    highlightFields: function highlightFields() {
      var _this2 = this;

      _ember['default'].run(function () {
        _this2.set('highlight', true);
        later(function () {
          return _this2.set('highlight', false);
        }, 500);
      });
    },
    actions: {
      save: function save() {
        var _this3 = this;

        this.validate().then(function () {
          var slConfig = _this3.get('slConfig');
          _this3.setEntityID();
          _this3[_this3.getSaveOrDelete()]('serviceLevelConfiguration', slConfig).then(function (slConfig) {
            return _this3.afterSave(slConfig);
          });
        })['catch'](function () {
          return _this3.set('startValidate', true);
        });
      },
      onResetToGlobal: function onResetToGlobal() {
        this.sendAction('onResetToGlobal', this.get('slConfig'));
        this.set('shouldAlert', true);
        this.set('resetToGlobal', true);
        this.get('slConfig').send('becomeDirty');
        this.highlightFields();
      },
      toggleSidecard: function toggleSidecard() {
        var _this4 = this;

        if (this.get('isEditingQueueDirty') && !window.confirm(_ember['default'].i18n.t('serviceLevel.confirmDialogMessage'))) return;
        if (!this.get('slConfig')) this.set('slConfig', this.store.createRecord('serviceLevelConfiguration'));
        this.toggleProperty('slideToggle');
        if (this.get('slideToggle') && this.get('slConfig.isNew')) this.get('slConfig').fetchUnconfiguredCallQueues().then(function (queue) {
          return _this4.populateQueues(queue);
        });
        if (!this.get('slideToggle')) this.resetForm();
      }
    }
  });
});