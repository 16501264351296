define('freshfone-ember/components/business-calendar/holiday-calendar/component', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var notEmpty = _Ember$computed.notEmpty;
    exports['default'] = Component.extend({
        holidays: alias('businessCalendar.holidaysAsArray'),
        holidaysToDisplay: computed('holidays.@each.isDeleted', function () {
            return this.get('holidays').filterBy('isDeleted', false);
        }),
        areHolidaysDeclared: notEmpty('holidaysToDisplay'),
        showModal: false,
        currentView: 'CUSTOM_HOLIDAY_LIST',

        actions: {
            toggleModalVisibility: function toggleModalVisibility(save) {
                if (save) this.sendAction('save');
                this.toggleProperty('showModal');
                if (this.get('toggleHolidayCalendar')) this.get('toggleHolidayCalendar')();
            },
            toggleCurrentView: function toggleCurrentView() {
                this.set('currentView', this.get('currentView') === 'CUSTOM_HOLIDAY_LIST' ? 'PREDEFINED_HOLIDAYS' : 'CUSTOM_HOLIDAY_LIST');
            }
        }
    });
});