define('freshfone-ember/utils/init-heap-analytics', ['exports'], function (exports) {
  exports['default'] = loadHeapAnalytics;
  //Redact URL before sending to heap
  var pathsToRedact = ['invite', 'reset-password', 'unlock', 'set-password'];
  var REDACT_STR = '********';
  var regExp = new RegExp('\/+(' + pathsToRedact.join('|') + ')+\/');
  var PROPS_TO_REDACT = {
    pageview_properties: ['path', 'previous_page'],
    session_properties: ['path', 'referrer'],
    event_properties: ['href']
  };

  function getStringFromMatches(str, matches) {
    return str.substring(0, matches.index) + matches[0] + REDACT_STR;
  }

  function redactedValueIfRequired(payload, prop) {
    var str = payload[prop];
    var matches = str.match(regExp);
    if (matches && matches.length) {
      payload[prop] = getStringFromMatches(str, matches);
    }
  }

  function redactUrlHandler(payload) {
    for (var prop in PROPS_TO_REDACT) {
      var attributes = PROPS_TO_REDACT[prop];
      for (var i = 0; i < attributes.length; i++) {
        if (payload[prop] && payload[prop][attributes[i]]) {
          redactedValueIfRequired(payload[prop], [attributes[i]]);
        }
      }
    }
    return payload;
  }

  function loadHeapAnalytics(projectID) {
    window.heap = window.heap || [], heap.load = function (e, t) {
      window.heap.appid = e, window.heap.config = t = t || {};var r = document.createElement("script");r.type = "text/javascript", r.async = !0, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";var a = document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r, a);for (var n = function n(e) {
        return function () {
          heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++) heap[p[o]] = n(p[o]);
    };heap.load(projectID, { rewrite: redactUrlHandler, disableTextCapture: true });
  }
});