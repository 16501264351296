define('freshfone-ember/components/sip-component/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/constants/all-agents-options', 'freshfone-ember/mixins/send-sip-credential-mixin'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberConstantsAllAgentsOptions, _freshfoneEmberMixinsSendSipCredentialMixin) {
	var computed = _ember['default'].computed;
	var later = _ember['default'].run.later;
	var service = _ember['default'].inject.service;
	var Promise = _ember['default'].RSVP.Promise;
	exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsSendSipCredentialMixin['default'], {
		telephony: service(),
		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);
			this.get('telephony').on('sip_enabled_all', function () {
				if (!(_this.isDestroyed || _this.isDestroying)) {
					_this.send('onModify');
					_this.set('initialisedEnableAll', false);
				}
			});
		},

		initialisedEnableAll: computed({
			set: function set(key, value) {
				localStorage.setItem(key, value);
				return value;
			},
			get: function get() {
				return localStorage.getItem('initialisedEnableAll') === 'true';
			}
		}),
		currentAccount: computed.alias('session.account'),
		searchDisabled: computed.or('currentAccount.subscription.isSuspended', 'initialisedEnableAll', 'promoting'),
		filterParams: { sip: true },

		actions: {
			updateSIPStatus: function updateSIPStatus(agent, value) {
				agent.set('sipStatus', value);
				agent.save();
			},
			resendSIPCredential: function resendSIPCredential(agent, isFromSIPForm) {
				var _this2 = this;

				this.resendSIPCredential(agent).done(function () {
					_ember['default'].run(function () {
						agent.set('credentialsSent', true);
						later(_this2, function () {
							return agent.set('credentialsSent', false);
						}, 5000);
					});
				}).fail(function () {
					_ember['default'].run(function () {
						agent.set('mailError', true);
						later(_this2, function () {
							return agent.set('mailError', false);
						}, 5000);
					});
				});
			},

			enableAllAdmin: function enableAllAdmin(item) {
				this.set('agent', item);
			},

			edit: function edit(item) {
				this.rollbackDirtyChanges('agent');
				item.saveOriginalRelations();
				this.set('agent', item);
				this.set('slideToggle', true);
			},

			toggleDelete: function toggleDelete(agent) {
				agent.set('isSoftDelete', true);
				agent.set('isDeleting', true);
			},

			disableSIP: function disableSIP(agent) {
				var _this3 = this;

				return new Promise(function (resolve) {
					agent.set('sipEnabled', false);
					later(_this3, function () {
						return agent.set('isDeleting', false);
					}, 2500);
					_this3.set('agent', null);
					agent.save().then(resolve);
				});
			},

			onModify: function onModify() {
				this.set('modifyModel', true);
			},

			clearError: function clearError(item) {
				item.set('mailError', false);
			},
			enableAllAgents: function enableAllAgents() {
				this.set('slideToggle', true);
				this.rollbackDirtyChanges('agent');
				this.set('agent', _freshfoneEmberConstantsAllAgentsOptions['default']);
			}
		}
	});
});