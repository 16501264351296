define('freshfone-ember/helpers/seconds-format', ['exports', 'ember'], function (exports, _ember) {
    exports.secondsFormat = secondsFormat;

    var momentjs = window.moment;

    function secondsFormat(seconds) {
        var timeFormat = seconds > 3600 ? 'HH:mm:ss' : 'mm:ss';
        return momentjs().startOf('day').add(seconds * 1000).format(timeFormat);
    }

    exports['default'] = _ember['default'].Helper.helper(secondsFormat);
});