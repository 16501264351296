define('freshfone-ember/components/call-logs/call-log-row/call-transfer-information/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var and = _Ember$computed.and;
  var or = _Ember$computed.or;
  exports['default'] = Component.extend({
    userName: or('agentLeg.entityName', 'call.entityName'),
    canShowTeam: and('call.isChild', 'call.teamName'),
    callStatus: computed.or('call.displayStatus', 'agentLeg.displayStatus')
  });
});