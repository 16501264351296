define('freshfone-ember/promotion-data/tag', ['exports'], function (exports) {
  exports['default'] = {
    data: [{
      id: 1,
      type: 'tag',
      name: 'Interested',
      'default': true
    }, {
      id: 2,
      type: 'tag',
      name: 'Not interested',
      'default': true
    }, {
      id: 3,
      type: 'tag',
      name: 'Left message',
      'default': true
    }, {
      id: 4,
      type: 'tag',
      name: 'No response',
      'default': true
    }, {
      id: 5,
      type: 'tag',
      name: 'Follow up',
      'default': true
    }, {
      id: 6,
      type: 'tag',
      name: 'Invalid number',
      'default': true
    }, {
      id: 7,
      type: 'tag',
      name: 'Do not call',
      'default': true
    }, {
      id: 8,
      type: 'tag',
      name: 'Complaint',
      'default': true
    }, {
      id: 8,
      type: 'tag',
      name: 'Inquiry',
      'default': true
    }]
  };
});