define('freshfone-ember/constants/call-filter-configurations', ['exports', 'freshfone-ember/utils/call-constants'], function (exports, _freshfoneEmberUtilsCallConstants) {
  exports['default'] = (function () {

    var fetchModelsInFilterSettings = {
      by_number: {
        model: 'number',
        key: 'number_id'
      },
      by_contact: {
        model: 'contact',
        key: 'contact_id'
      },
      by_org_contact: {
        model: 'contact',
        key: 'org_contact_id'
      },
      by_queue: {
        model: 'call-queue',
        key: 'queue_id'
      },
      by_team: {
        model: 'team',
        key: 'team_id'
      },
      by_agent: {
        model: 'user',
        key: 'agent_id'
      },
      by_tags: {
        model: 'tag',
        key: 'tag_id'
      },
      by_call_id: {
        model: 'call',
        key: 'id'
      }
    },
        filterSettingsConstants = {
      by_service_level_breached: false,
      by_power_dialer: false,
      by_direction: {
        1: _freshfoneEmberUtilsCallConstants['default'].TYPE_HASH.incoming,
        2: _freshfoneEmberUtilsCallConstants['default'].TYPE_HASH.outgoing
      },
      by_status: {
        1: _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.answered,
        3: _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.missed,
        17: _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.abandoned,
        10: _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.voicemail,
        isArray: true,
        key: 'status'
      },
      by_business_hour_call: {
        0: _freshfoneEmberUtilsCallConstants['default'].BUSINESS_HOUR_CALL_HASH.outside,
        1: _freshfoneEmberUtilsCallConstants['default'].BUSINESS_HOUR_CALL_HASH.within
      },
      by_call_action: {
        1: _freshfoneEmberUtilsCallConstants['default'].WAIT_QUEUE_ACTIONS_HASH[0],
        2: _freshfoneEmberUtilsCallConstants['default'].WAIT_QUEUE_ACTIONS_HASH[1],
        isArray: true,
        key: 'category'
      }
    },
        filterSettingsObject = {
      by_service_level_breached: false,
      by_number: { number_id: [] },
      by_date: {
        start_date: null,
        end_date: null,
        timeperiod: null
      },
      by_status: { status: [] },
      by_direction: null,
      by_call_id: { call_id: [] },
      by_contact: { contact_id: [] },
      by_org_contact: { org_contact_id: [] },
      by_agent: { agent_id: [] },
      by_queue: { queue_id: [] },
      by_team: { team_id: [] },
      by_business_hour_call: null,
      by_tags: { tag_id: [] },
      by_call_action: { category: [] }
    };

    return {
      fetchModelsInFilterSettings: fetchModelsInFilterSettings,
      filterSettingsObject: filterSettingsObject,
      filterSettingsConstants: filterSettingsConstants
    };
  })();
});
/* eslint-disable camelcase */