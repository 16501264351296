define('freshfone-ember/components/custom-elements/select2/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['custom-select-2'],
    optionDescriptionPath: 'desc',
    actions: {
      query: function query(q, deferred) {
        var _this = this;

        var model = this.get('model'),
            requestUrl = this.get('url');
        console.log('q', q);
        if (requestUrl) {
          _ember['default'].$.getJSON(requestUrl, {
            filter: { queryString: q.term
            } }).then(function (filteredResults) {
            _this.store.pushPayload(model, filteredResults);
            var resultIds = filteredResults.data.mapBy('id');
            var results = _this.store.peekAll(model).filter(function (item) {
              return resultIds.contains(item.get('id'));
            });
            deferred.resolve({ data: results, more: false });
          }, deferred.reject);
        }
      }
    }
  });
});