define('freshfone-ember/components/integrations/action-list/component', ['exports', 'freshfone-ember/utils/call-constants', 'ember-data', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin'], function (exports, _freshfoneEmberUtilsCallConstants, _emberData, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  var PromiseObject = _emberData['default'].PromiseObject;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    classNames: ['action-list'],
    notifications: service('toastr'),
    telephony: service(),
    isSearchingEntities: true,
    is1ClickIntegratedAccount: computed(function () {
      return this.hasGlobalFeature('integrated_desk_with_1cx') && this.checkFeature('integrated_desk_with_1cx');
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var resource = this.get('newResource');

      if (resource && isEmpty(resource.get('id'))) {
        resource.destroyRecord();
      }
    },

    externalContactQueryParams: function externalContactQueryParams() {
      var call = this.get('call'),
          callerNumber = call.get('from') || call.get('to'),
          selectedProduct = this.get('actionItem.integration.name');

      // eslint-disable-next-line no-console
      console.log('Call instance - caller number', callerNumber);

      // eslint-disable-next-line one-var
      var queryParams = {
        filter: { queryString: callerNumber },
        product: selectedProduct,
        version: _freshfoneEmberUtilsCallConstants['default'].CONTACT_SEARCH_API_VERISON
      };

      // eslint-disable-next-line no-console
      console.log('External number fetch - query params', queryParams);

      return queryParams;
    },

    externalContact: computed('call', function () {
      // eslint-disable-next-line consistent-this
      var self = this;

      return PromiseObject.create({
        promise: this.store.queryRecord('external-contact', this.externalContactQueryParams()).then(function (response) {
          self.getEntities(response[0]);

          return response[0];
        })
      });
    }),

    getEntities: function getEntities(externalContact) {
      var _this = this;

      var recentEntities = [];

      if (!externalContact) {
        this.set('isSearchingEntities', false);
        this.set('recentEntities', recentEntities);

        return;
      }

      // eslint-disable-next-line one-var
      var contactId = externalContact.id,
          type = externalContact.get('type'),

      // eslint-disable-next-line sort-vars
      params = {
        // eslint-disable-next-line camelcase
        type: type
      },
          mcrId = externalContact.get('mcrId');

      if (this.hasOmniRedirectFeatureEnabled()) {
        params.org_contact_id = mcrId;
      } else {
        params.contact_id = contactId;
      }
      if (this.get('integratedApplication')) {
        this.get('integratedApplication').getRecentEntities(params).then(function (response) {
          run(function () {
            _this.get('store').pushPayload(response);
            _this.set('isSearchingEntities', false);
            _this.set('recentEntities', response);
          });
        })['catch'](function () {
          _this.set('isSearchingEntities', false);
        });
      }

      return recentEntities;
    },

    callParams: function callParams() {
      var callId = this.get('call.id'),
          externalContact = this.get('externalContact'),

      // eslint-disable-next-line camelcase
      params = { call_id: callId };

      // eslint-disable-next-line no-console
      console.log('External contact - action list', externalContact);

      if (externalContact && externalContact.content) {
        if (this.hasOmniRedirectFeatureEnabled()) {
          params.org_contact_id = externalContact.content.get('mcrId');
        } else {
          params.contact_id = externalContact.content.id;
        }
      }

      return params;
    },

    buildEntityParams: function buildEntityParams(params) {
      if (this.get('entity.id')) {
        // eslint-disable-next-line camelcase
        params.entity_id = this.get('entity.id');
      }
      if (this.get('entity.subject')) {
        params.subject = this.get('entity.subject').string ? this.get('entity.subject').string : this.get('entity.subject');
      }

      return params;
    },

    createNewIntegratedResource: function createNewIntegratedResource(response) {
      var callId = this.get('call.id'),
          newResource = this.get('store').createRecord('integrated-resource', {
        remoteResourceId: response.data[0].id,
        link: response.data[0].attributes.link,
        remoteResourceType: response.data[0].attributes.type,
        resourceId: callId,
        integratedApplication: this.get('integratedApplication')
      });

      return newResource;
    },

    apiAction: function apiAction(actionItem) {
      var _this2 = this;

      var params = this.callParams();

      var notifications = this.get('notifications');

      this.set('showAddModal', false);
      this.set('showCreateModal', false);
      // eslint-disable-next-line ember-suave/no-direct-property-access
      notifications.info(_ember['default'].i18n.t('integration.' + this.get('actionItem.integration.name') + '.creation_in_progress'));
      this.set('spinner', true);
      this.set('integrationName', this.get('actionItem.integration.name'));
      params.apiAction = actionItem.get('action');
      params.fc_create_ticket = true;

      if (this.get('entity')) {
        params = this.buildEntityParams(params);
      }
      this.get('integratedApplication').callAction(params).then(function (response) {
        run(function () {
          _this2.get('store').pushPayload(response);
          var newResource = _this2.createNewIntegratedResource(response);

          _this2.get('call.integratedResources').pushObject(newResource);
          _this2.set('newResource', newResource);
          // eslint-disable-next-line ember-suave/no-direct-property-access
          notifications.success(_ember['default'].i18n.t('integration.' + _this2.get('actionItem.integration.name') + '.created', {
            link: newResource.get('link'),
            remoteResourceId: newResource.get('remoteResourceId')
          }));
          _this2.set('spinner', false);
        });
      })['catch'](function (error) {
        var errorMessage = '';

        if (error.errors === 'duplicate_item') {
          // eslint-disable-next-line ember-suave/no-direct-property-access
          errorMessage = _ember['default'].i18n.t('integration.' + _this2.get('actionItem.integration.name') + '.already_created');
        } else {
          // eslint-disable-next-line ember-suave/no-direct-property-access
          errorMessage = _ember['default'].i18n.t('integration.' + _this2.get('actionItem.integration.name') + '.failed');
        }
        notifications.error(errorMessage);
        _this2.set('spinner', false);
      });
    },

    apiActionV2: function apiActionV2(actionItem) {
      var _this3 = this;

      var params = this.callParams();

      var notifications = this.get('notifications'),
          callActioUrl = '/integrated_applications/' + this.get('integratedApplication').id + '/call_action?version=v2';

      this.set('showAddModal', false);
      this.set('showCreateModal', false);
      // eslint-disable-next-line ember-suave/no-direct-property-access
      notifications.info(_ember['default'].i18n.t('integration.' + this.get('actionItem.integration.name') + '.creation_in_progress'));
      this.set('spinner', true);
      this.set('integrationName', this.get('actionItem.integration.name'));
      params.apiAction = actionItem.get('action');

      params = this.buildEntityParams(params);

      params.org_contact_id = this.get('call.contact.id');

      $.ajax({
        url: callActioUrl,
        type: 'POST',
        dataType: 'json',
        data: params
      }).done(function () {
        var subscriptionHandler = function subscriptionHandler(data) {
          if (String(data.callId) === String(_this3.get('call.id'))) {
            var callId = _this3.get('call.id'),
                newResource = _this3.get('store').createRecord('integrated-resource', {
              remoteResourceId: data.id,
              link: data.link,
              remoteResourceType: data.type,
              resourceId: callId,
              integratedApplication: _this3.get('integratedApplication')
            });

            _this3.get('call.integratedResources').pushObject(newResource);
            _this3.set('newResource', newResource);
            // eslint-disable-next-line ember-suave/no-direct-property-access
            notifications.success(_ember['default'].i18n.t('integration.' + _this3.get('actionItem.integration.name') + '.created', {
              link: newResource.get('link'),
              remoteResourceId: newResource.get('remoteResourceId')
            }));
            _this3.set('spinner', false);
            _this3.get('telephony.event').unsubscribe('resource_details', subscriptionHandler);
          }
        };

        _this3.get('telephony.event').subscribe('resource_details', subscriptionHandler);
      });
    },

    actions: {
      callAction: function callAction(actionItem, entity) {
        if (entity) {
          this.set('entity', entity);
        }

        if (this.get('is1ClickIntegratedAccount')) {
          this.apiActionV2(actionItem);
        } else {
          this.apiAction(actionItem);
        }
      },
      // eslint-disable-next-line camelcase
      create_or_add_ticket: function create_or_add_ticket(actionItem, integration) {
        this.set('integratedApplication', integration);
        this.set('actionItem', actionItem);
        if (actionItem.get('actionLabel').indexOf('Create') !== -1) {
          this.toggleProperty('showCreateModal');
        } else if (actionItem.get('actionLabel').indexOf('Add') !== -1) {
          this.toggleProperty('showAddModal');
        }
        if (this.get('shouldCloseCallDetails')) {
          this.get('closeCallDetails')();
        }
      },
      toggleCreateModalVisibility: function toggleCreateModalVisibility(call) {
        if (call) {
          this.get('store').pushPayload('call', call);
        }
        this.toggleProperty('showCreateModal');
      }
    }
  });
});
/* eslint-disable camelcase */