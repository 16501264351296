define('freshfone-ember/serializers/routing-automation', ['exports', 'freshfone-ember/serializers/application', 'freshfone-ember/mixins/serializers/embedded-records', 'ember'], function (exports, _freshfoneEmberSerializersApplication, _freshfoneEmberMixinsSerializersEmbeddedRecords, _ember) {
  var valuesToChange = ['invalid-input-action-type', 'app-failure-action-type'];
  var testing = _ember['default'].testing;
  var isArray = _ember['default'].isArray;
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    attrs: { routingRules: { embedded: 'always' } },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options),
          selectedApp = snapshot.record.get('appNameDisplayValue');

      if (selectedApp) {
        var mkpData = {};

        mkpData = {
          'app-id': selectedApp.app_id,
          'installed-app-id': selectedApp.installed_app_id,
          'version-id': selectedApp.version_id,
          'app-name': selectedApp.app_name
        };

        json.data.attributes['mkp-data'] = mkpData;
      }

      valuesToChange.forEach(function (key) {
        json.data.attributes[key] = json.data.attributes[key].decamelize();
      });

      delete json.data.attributes['app-id'];
      delete json.data.attributes['app-name'];

      return json;
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {

      if (!testing) {
        if (isArray(payload.data)) {
          payload.data.forEach(function (routingAutomation) {
            valuesToChange.forEach(function (key) {
              routingAutomation.attributes[key] = routingAutomation.attributes[key].camelize();
            });
          });
        } else {
          valuesToChange.forEach(function (key) {
            payload.data.attributes[key] = payload.data.attributes[key].camelize();
          });
        }
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});
/* eslint-disable sort-vars */