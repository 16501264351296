define('freshfone-ember/models/account', ['exports', 'freshfone-ember/utils/signup-app-constants', 'ember-api-actions', 'freshfone-ember/constants/product-names', 'ember-data', 'ember'], function (exports, _freshfoneEmberUtilsSignupAppConstants, _emberApiActions, _freshfoneEmberConstantsProductNames, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    name: attr('string'),
    full_domain: attr('string'),
    timeZone: attr('string'),
    timeZoneAsTzinfo: attr('string'),
    language: attr('string'),
    callingEnabled: attr('boolean'),
    free_credits: attr('boolean'),
    acw_enabled: attr('boolean'),
    acw_timeout: attr('number'),
    features: attr(),
    rolloutGlobalFlags: attr(),
    globalFlags: attr(),
    users_limit: attr('number'),
    active_users_count: attr('number'),
    customerDocumentVerified: attr('boolean'),
    plan_name: attr('string'),
    serviceLevelRefreshInterval: attr(),
    isTrial: attr('boolean'),
    restrictedTrial: attr('boolean'),
    blocked: attr('boolean'),
    freshidEnabled: attr('boolean'),
    freshchatEnabled: attr('boolean'),
    call_forwarding_enabled: attr('boolean'),
    allow_external_transfer: attr('boolean'),
    freshdeskIntegrated: attr('boolean'),
    isOmnichannelBundled: attr('boolean'),
    oneCxMigratedAccount: attr('boolean'),
    omnichannelOwnerDomain: attr('string'),
    enabledBundles: attr(),
    organisationDomain: attr('string'),
    organisationId: attr(),
    onboarding_completed: attr('boolean'),
    signupApp: attr('string'),
    signup_type: attr(),
    mainPod: attr('boolean'),
    created_at: attr(),
    preferredVoiceLanguage: attr('string'),
    subscription: belongsTo('subscription', { async: false }),
    credit: belongsTo('credit', { async: false }),
    callingDisabled: computed.not('callingEnabled'),
    noFreeCredits: computed.not('free_credits'),
    updateWelcomeStatus: (0, _emberApiActions.memberAction)({
      type: 'PUT',
      path: 'onboarding_completed'
    }),
    updateAccount: (0, _emberApiActions.memberAction)({
      type: 'PUT',
      path: '/'
    }),
    deleteAccount: (0, _emberApiActions.collectionAction)({
      type: 'POST',
      path: 'delete'
    }),
    isSproutPlan: computed.equal('plan_name', 'Sprout'),
    demoRequested: attr('boolean', { defaultValue: false }),
    deletionScheduled: attr('boolean', { defaultValue: false }),
    newDeletionEnabled: attr('boolean'),
    ocrEnabled: attr('boolean'),
    newSubscriptionPolicyCustomer: attr('boolean'),
    slmViewCount: attr(),
    downgradeScheduled: attr(),
    isSproutOrTrial: computed.or('isSproutPlan', 'isTrial'),
    addonUsersLimit: attr('number'),
    bundleId: attr('number'),
    cloudType: attr('string'),
    heapBundleName: attr('string'),
    standaloneUsersCount: attr('number'),
    businessAccount: attr('boolean'),
    sandboxAccount: attr('boolean'),

    hasFeature: function hasFeature(feature) {
      return this.get('features').indexOf(feature) !== -1;
    },
    hasGlobalFlag: function hasGlobalFlag(flag) {
      return this.get('globalFlags').includes(flag);
    },
    isIncomingIgnoreOptionDisabled: computed(function () {
      return this.hasFeature('disable_incoming_ignore_option');
    }),
    isOmnichannelEnabled: computed('isOmnichannelBundled', function () {
      return this.get('isOmnichannelBundled');
    }),
    isUnifiedAppAccount: computed(function () {
      return _freshfoneEmberUtilsSignupAppConstants.SIGNUP_APPS.includes(this.get('signupApp'));
    }),
    windowProtocol: computed(function () {
      return window.location.protocol;
    }),
    isBundleAccount: computed('signupApp', function () {
      return _freshfoneEmberUtilsSignupAppConstants.BUNDLES.includes(this.get('signupApp'));
    }),
    isSharedBundleAccount: computed('signupApp', function () {
      return this.hasGlobalFlag('shared_freshcaller') && _freshfoneEmberUtilsSignupAppConstants.SHARED_BUNDLES.includes(this.get('signupApp'));
    }),
    hasPricingRebrandingFeature: computed(function () {
      return this.hasGlobalFlag('pricing_rebranding') && this.hasFeature('rebranding');
    }),
    hasCxRebrandingFeature: computed(function () {
      return this.hasGlobalFlag('rebranding') && this.hasFeature('rebranding');
    }),
    productName: computed(function () {
      return this.get('hasCxRebrandingFeature') ? _freshfoneEmberConstantsProductNames.rebrandedProductNames.freshcaller : _freshfoneEmberConstantsProductNames.defaultProductNames.freshcaller;
    }),
    chatName: computed(function () {
      return this.get('hasCxRebrandingFeature') ? _freshfoneEmberConstantsProductNames.rebrandedProductNames.freshchat : _freshfoneEmberConstantsProductNames.defaultProductNames.freshchat;
    }),
    deskName: computed(function () {
      return this.get('hasCxRebrandingFeature') ? _freshfoneEmberConstantsProductNames.rebrandedProductNames.freshdesk : _freshfoneEmberConstantsProductNames.defaultProductNames.freshdesk;
    }),
    crmName: computed(function () {
      return _freshfoneEmberConstantsProductNames.defaultProductNames.crm;
    }),
    addonAgentsCount: computed('isOmnichannelBundled', 'standaloneUsersCount', function () {
      return this.get('isOmnichannelBundled') ? this.get('standaloneUsersCount') : null;
    }),
    orgUrl: computed('organisationDomain', function () {
      return 'https://' + this.get('organisationDomain');
    }),
    ubxBillingUrl: computed('isUnifiedAppAccount', 'orgUrl', function () {
      return this.get('isUnifiedAppAccount') ? this.get('orgUrl') + '/subscriptions' : null;
    }),

    /*
     * powerDialerAllowed - account is not shared or the account is shared but the user is standalone user or
     * the power_dialer_for_shared_user flag is enabled
     */
    powerDialerAllowed: computed(function () {
      /* eslint no-extra-parens: ["error", "all", { "nestedBinaryExpressions": false }] */
      return !this.get('isSharedBundleAccount') && this.hasGlobalFlag('global_power_dialer') || this.hasGlobalFlag('power_dialer_for_shared_user') && this.hasGlobalFlag('global_power_dialer');
    })
  });
});
/* eslint-disable camelcase */