define('freshfone-ember/initializers/modal-stack', ['exports', 'freshfone-ember/config/environment'], function (exports, _freshfoneEmberConfigEnvironment) {
  exports.initialize = initialize;

  function initialize(application) {
    var modalDefaults = _freshfoneEmberConfigEnvironment['default'].modalDefaults;

    application.register('config:modal-stack-manager', modalDefaults, { instantiate: false });
    application.inject('service:modal-stack-manager', 'modalDefaults', 'config:modal-stack-manager');

    application.inject('route', 'modalPopup', 'service:modal-stack-manager');
    application.inject('controller', 'modalPopup', 'service:modal-stack-manager');
    application.inject('component', 'modalPopup', 'service:modal-stack-manager');
    application.inject('view', 'modalPopup', 'service:modal-stack-manager');
  }

  exports['default'] = {
    name: 'modal-stack',
    initialize: initialize
  };
});