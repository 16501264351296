define('freshfone-ember/components/company-components/company-detail-view/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({

    hasMoreContacts: computed.gt('model.contacts.length', 3),

    contacts: computed('model.contacts', function () {
      var contacts = this.get('model.contacts');
      if (this.get('hasMoreContacts')) {
        return [contacts.objectAt(0), contacts.objectAt(1), contacts.objectAt(2)];
      }
      return contacts;
    }),

    moreContactsLength: computed('model.contacts.length', function () {
      return this.get('model.contacts.length') - 3;
    })
  });
});