define('freshfone-ember/components/plan-modal/plan-summary/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({

    amountsEqual: computed('planAmount', 'amountDue', function () {
      return this.get('planAmount') === this.get('amountDue');
    }),
    init: function init() {
      var _this = this;

      run.scheduleOnce('afterRender', function () {
        _this.estimate(0);
      });
      this._super.apply(this, arguments);
    },
    planPrice: computed('selectedPlan', 'subscription.content.renewalPeriod', function () {
      var renewalPeriod = this.get('subscription.content.billingCycle');
      this.estimate();
      return this.get('selectedPlan.price')[renewalPeriod];
    }),
    agentSeats: computed('subscription.quantity', function () {
      if (isPresent(this.get("subscription.quantity"))) {
        this.estimate();
      }
      return this.get("subscription.quantity");
    }),
    willDestroyElement: function willDestroyElement() {
      this.clearpreviousTask();
      this._super.apply(this, arguments);
    },
    estimate: function estimate() {
      var delay = arguments.length <= 0 || arguments[0] === undefined ? 700 : arguments[0];

      var estimateTask = run.debounce(this, this.planEstimate, delay);
      this.clearpreviousTask();
      this.set('estimateTask', estimateTask);
    },
    clearpreviousTask: function clearpreviousTask() {
      var previousTask = this.get('estimateTask');
      if (previousTask) {
        run.cancel(previousTask);
      }
    },
    planEstimate: function planEstimate() {
      var _this2 = this;

      if (isEmpty(this.get("subscription.quantity"))) {
        return false;
      }
      this.set('loadingEstimate', true);
      var params = this.newSubscriptionParams();
      this.get('subscription.content').planEstimate(params).then(function (data) {
        var estimate = data.estimate;
        if (estimate && estimate.next_invoice_estimate) {
          _this2.set('planAmount', Number(estimate.next_invoice_estimate.values.total) / 100);
        } else {
          _this2.set('planAmount', 0);
        }
        _this2.invoiceEstimate();
      });
    },
    invoiceEstimate: function invoiceEstimate() {
      var _this3 = this;

      var params = this.newSubscriptionParams();
      this.get('subscription.content').invoiceEstimate(params).then(function (data) {
        var estimate = data.estimate;
        _this3.set('amountDue', _this3.getAmountDue(estimate));
        _this3.set('loadingEstimate', false);
      });
    },

    getAmountDue: function getAmountDue(estimate) {
      if (isEmpty(estimate)) {
        return 0;
      }
      if (isPresent(estimate.invoice_estimate)) {
        // For active accounts
        return estimate.invoice_estimate.values.amount_due / 100;
      } else if (isPresent(estimate.next_invoice_estimate)) {
        // For non-active accounts (Trial, Trial expired, cancelled)
        return estimate.next_invoice_estimate.values.amount_due / 100;
      }
      return this.get('planAmount');
    },

    newSubscriptionParams: function newSubscriptionParams() {
      var subscription = this.get('subscription.content');
      var plan = this.get('selectedPlan');
      return {
        'plan_id': plan.id,
        'renewal_period': subscription.get('renewalPeriod'),
        quantity: subscription.get('quantity')
      };
    }

  });
});