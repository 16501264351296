define("freshfone-ember/components/billing-base/credit-actions/currency-dropdown/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/currency-dropdown/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "currency.currencyName", ["loc", [null, [15, 2], [15, 27]]], 0, 0, 0, 0]],
        locals: ["currency"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/currency-dropdown/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "switching-in-progress");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 7
          }
        },
        "moduleName": "freshfone-ember/components/billing-base/credit-actions/currency-dropdown/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "custom-elements/power-select", [], ["label", ["subexpr", "t", ["profile_settings.currency"], [], ["loc", [null, [2, 11], [2, 42]]], 0, 0], "options", ["subexpr", "@mut", [["get", "currencyList", ["loc", [null, [3, 13], [3, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "currentCurrency", ["loc", [null, [4, 11], [4, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "searchField", "currencyName", "attributeName", "currencyName", "renderInPlace", true, "noMatchesMessage", ["subexpr", "@mut", [["get", "noLanguageLabel", ["loc", [null, [8, 21], [8, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "currency", ["loc", [null, [9, 13], [9, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "if", [["get", "startValidate", ["loc", [null, [10, 16], [10, 29]]], 0, 0, 0, 0], ["get", "rule.errors.number", ["loc", [null, [10, 30], [10, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 12], [10, 49]]], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [11, 12], [11, 16]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["setCurrency"], [], ["loc", [null, [12, 14], [12, 36]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [16, 33]]]], ["block", "if", [["get", "switchingCurrency", ["loc", [null, [17, 6], [17, 23]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [17, 0], [19, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});