define('freshfone-ember/constants/all-agents-options', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Object.create({
        name: _ember['default'].i18n.t('call_log.all_agents'),
        isAllAgents: true,
        id: 'allAgents',
        sipStatusText: {
            value: 0,
            label: _ember['default'].i18n.t('sip.status.never')
        },
        sipStatuses: [{ value: 0, label: _ember['default'].i18n.t('sip.status.never') }, { value: 1, label: _ember['default'].i18n.t('sip.status.whenOnBrowser') }],

        // dummy
        rollbackAttributes: function rollbackAttributes() {}
    });
});