define("freshfone-ember/helpers/slick-helper", ["exports"], function (exports) {
  var slickId = Object.freeze({
    Sprout: 1,
    Blossom: 2,
    Garden: 3,
    Estate: 4,
    Forest: 5,
    Free: 1,
    Growth: 2,
    Pro: 3,
    Enterprise: 4
  });
  exports.slickId = slickId;
});