define('freshfone-ember/components/ui-list-header/component', ['exports', 'ember-data-table/components/ui-list-header/component'], function (exports, _emberDataTableComponentsUiListHeaderComponent) {
  exports['default'] = _emberDataTableComponentsUiListHeaderComponent['default'].extend({
    activeColumn: [],

    resetSort: (function () {
      var children = this.get('childViews'),
          component = this;

      this.$(children).each(function (idx, elm) {
        if (component.get('activeColumn') !== elm) {
          elm.set('sortType', null);
        }
      });
    }).observes('activeColumn'),

    actions: {
      onSort: function onSort(column) {
        this.get('activeColumn').pushObject(column);
        this.sendAction('onSort', {
          sort: column.sort,
          sortType: column.sortType
        });
      }
    }
  });
});