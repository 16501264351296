define('freshfone-ember/components/autoplay-blocked/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend({
    isChrome: !!window.chrome,
    isAutoPlayBlocked: computed('audioContext.state', function () {
      return this.get('isChrome') && this.get('audioContext.state') === 'suspended';
    }),
    session: service(),
    telephony: service(),
    currentUser: computed.alias('session.user'),
    init: function init() {
      this._super.apply(this, arguments);
      window.AudioContext = window.AudioContext || window.webkitAudioContext; // eslint-disable-line no-global-assign
      window.AudioContext && this.set('audioContext', new AudioContext()); // eslint-disable-line no-global-assign
      // To access audioContext in SDK, adding this as global
      window.fcAudioContext = this.get('audioContext');

      this.get('audioContext').onstatechange = function () {
        if (fcAudioContext.state === 'running') {
          // Emit this event to enable provider
          document.dispatchEvent(new CustomEvent("activateTwilio", {}));
        }
      };

      if (this.get('isAutoPlayBlocked')) {
        document.addEventListener('click', this.handleAudioCxtResume.bind(this), {
          once: true
        });
      } else {
        this.get('audioContext').resume();
      }
    },
    handleAudioCxtResume: function handleAudioCxtResume() {
      var _this = this;

      if (_ember['default'].testing) return;

      this.get('audioContext').resume();
      this.set('resumeAutoplay', true);
      _ember['default'].run.later(this, function () {
        return _this.set('isAutoPlayBlocked', false);
      }, 1000);
    },
    willDestroyElement: function willDestroyElement() {
      if (_ember['default'].testing) return;
      this.get('audioContext').close();
      document.removeEventListener('click', this.handleAudioCxtResume);
    }
  });
});