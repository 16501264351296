define('freshfone-ember/components/business-calendar/holiday-calendar/custom-holiday-list/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/utils/duplicate-indices'], function (exports, _ember, _emberValidations, _freshfoneEmberUtilsDuplicateIndices) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var _Ember$computed = _ember['default'].computed;
    var alias = _Ember$computed.alias;
    var notEmpty = _Ember$computed.notEmpty;
    var isEmpty = _ember['default'].isEmpty;
    var service = _ember['default'].inject.service;
    exports['default'] = Component.extend(_emberValidations['default'], {
        i18n: service(),
        holidays: alias('businessCalendar.holidaysAsArray'),
        holidaysToDisplay: computed('holidays.@each.isDeleted', function () {
            return this.get('holidays').filterBy('isDeleted', false);
        }),
        areHolidaysDeclared: notEmpty('holidaysToDisplay'),
        holidayListContainsDuplicates: false,
        canStartValidation: false,
        enableSave: computed('holidays.@each.hasDirtyAttributes', function () {
            return this.get('holidays').any(function (holiday) {
                return holiday.get('hasDirtyAttributes');
            });
        }),

        actions: {
            closeHolidayCalendar: function closeHolidayCalendar(revert) {
                var _this = this;

                if (revert) {
                    this.get('holidays').forEach(function (holiday) {
                        return holiday.rollbackAttributes();
                    });
                } else {
                    this.set('canStartValidation', true);
                }

                this.validate().then(function () {
                    _this.set('holidayListContainsDuplicates', false);
                    _this.sendAction('closeCallback', !revert);
                })['catch'](function (errors) {
                    _this.set('holidayListContainsDuplicates', true);
                });
            },
            gotoPredefinedHolidays: function gotoPredefinedHolidays() {
                this.sendAction('toggleCurrentView');
            },
            addHoliday: function addHoliday() {
                this.store.createRecord('holiday', { calendar: this.get('businessCalendar') });
                this.$('.holiday-list-wrapper').animate({ scrollTop: $('.holiday-list-wrapper').prop('scrollHeight') }, 500);
                this.set('businessCalendar.customIsDirty', true);
            },
            removeHoliday: function removeHoliday(holiday) {
                holiday.deleteRecord();
            }
        },

        validations: {
            holidays: {
                inline: (0, _emberValidations.validator)(function () {
                    var _this2 = this;

                    var preventSave = false;

                    // Deferring validation until save button is clicked
                    if (this.model.get('canStartValidation')) {
                        this.get('holidaysToDisplay').forEach(function (item) {
                            item.set('errors.date', []);
                            item.set('errors.occasion', []);
                        });

                        // Validate for emptiness of date or occasion
                        this.get('holidaysToDisplay').forEach(function (holiday) {
                            if (isEmpty(holiday.get('date'))) {
                                holiday.get('errors.date').pushObject(_this2.get('i18n').t('business_calendar.holidayCalendar.errors.empty'));
                                preventSave = true;
                            }
                            if (isEmpty(holiday.get('occasion'))) {
                                holiday.get('errors.occasion').pushObject(_this2.get('i18n').t('business_calendar.holidayCalendar.errors.empty'));
                                preventSave = true;
                            }
                        });

                        // Validate for duplicate dates
                        var indicesOfDuplicateDates = [];
                        var months = this.get('holidaysToDisplay').mapBy('date');
                        indicesOfDuplicateDates = (0, _freshfoneEmberUtilsDuplicateIndices['default'])(months);
                        if (indicesOfDuplicateDates.length) {
                            indicesOfDuplicateDates.forEach(function (index) {
                                _this2.get('holidaysToDisplay').objectAt(index).get('errors.date').pushObject(_this2.get('i18n').t('business_calendar.holidayCalendar.errors.duplicateDates'));
                            });
                            preventSave = true;
                        }
                    }
                    this.model.set('canStartValidation', false);

                    // Return something to mark that the validation failed
                    if (preventSave) return preventSave;
                })
            }
        }
    });
});