define('freshfone-ember/services/analytics-tracker', ['exports', 'ember', 'freshfone-ember/config/environment', 'freshfone-ember/utils/init-heap-analytics'], function (exports, _ember, _freshfoneEmberConfigEnvironment, _freshfoneEmberUtilsInitHeapAnalytics) {

  var enabled = Object.defineProperties({}, {
    heap: {
      get: function get() {
        return window.heap;
      },
      configurable: true,
      enumerable: true
    },
    appcues: {
      get: function get() {
        return window.Appcues;
      },
      configurable: true,
      enumerable: true
    }
  });

  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = Service.extend({
    store: service(),
    session: service(),
    user: computed.alias('session.user'),
    account: computed.alias('session.account'),
    subscription: computed.alias('session.account.subscription'),

    addEvent: function addEvent(event) {
      if (enabled.heap) {
        window.heap.track(event);
      }
      if (enabled.appcues) {
        window.Appcues.track(event);
      }
    },
    heap: {
      bootstrap: function bootstrap() {
        var projectId = arguments.length <= 0 || arguments[0] === undefined ? _freshfoneEmberConfigEnvironment['default'].heap.projectId : arguments[0];

        if (!_freshfoneEmberConfigEnvironment['default'].heap.development) {
          (0, _freshfoneEmberUtilsInitHeapAnalytics['default'])(projectId);
        }
      },
      track: function track() {
        if (enabled.heap) {
          var _window$heap;

          (_window$heap = window.heap).track.apply(_window$heap, arguments);
        }
      },
      addUserProperties: function addUserProperties() {
        if (enabled.heap) {
          var _window$heap2;

          (_window$heap2 = window.heap).addUserProperties.apply(_window$heap2, arguments);
        }
      },
      identify: function identify() {
        if (enabled.heap) {
          var _window$heap3;

          (_window$heap3 = window.heap).identify.apply(_window$heap3, arguments);
        }
      }
    },
    appcues: {
      track: function track() {
        var _window$Appcues;

        if (!enabled.appcues) return;
        (_window$Appcues = window.Appcues).track.apply(_window$Appcues, arguments);
      },
      identify: function identify() {
        var _window$Appcues2;

        if (!enabled.appcues) return;
        (_window$Appcues2 = window.Appcues).identify.apply(_window$Appcues2, arguments);
      },
      page: function page() {
        var _window$Appcues3;

        if (!enabled.appcues) return;
        (_window$Appcues3 = window.Appcues).page.apply(_window$Appcues3, arguments);
      }
    },
    getPropsToTargetThisUser: function getPropsToTargetThisUser() {
      var _this = this;

      return new Promise(function (res, rej) {
        if (_this.get('propsToTargetThisUser')) {
          res(_this.get('propsToTargetThisUser'));
        }

        var today = new Date(),
            timeSinceAccCreation = today.getTime() - new Date(_this.get('account.created_at')).getTime(),
            differenceInDays = timeSinceAccCreation / (1000 * 3600 * 24),
            isFirstDayLogin = differenceInDays < 1,
            userProps = {
          // account_device_preference: '',
          // account_type: 'MM | SMB',
          billingCycle: _this.get('subscription.billingCycle'),
          accountStatus: _this.get('subscription.status'),
          accountStatusText: _this.get('subscription.statusText'),

          accountId: _this.get('account.isUnifiedAppAccount') ? _this.get('account.organisationId') : _this.get('account.id'),
          accountPlan: _this.get('account.plan_name'),
          accountRegion: _this.get('account.timeZone'),
          accountLanguage: _this.get('account.language'),
          isTrial: _this.get('account.isTrial'),
          accountCreatedAt: _this.get('account.created_at'),
          associatedFreshworksProducts: _this.get('account.enabledBundles') ? _this.get('account.enabledBundles') : [],

          userId: _this.get('account.isUnifiedAppAccount') ? _this.get('user.freshidUuid') : _this.get('user.id'),
          userRole: _this.get('user.role.name'),
          userEmail: _this.get('user.email'),
          userEmailConfirmed: _this.get('user.confirmed'),
          userName: _this.get('user.name'),

          orgUserId: _this.get('user.freshidUuid'),
          orgId: _this.get('account.organisationId'),
          orgDomain: _this.get('account.orgUrl'),
          bundleId: _this.get('account.bundleId'),
          bundleName: _this.get('account.heapBundleName'),
          cloudType: _this.get('account.cloudType'),
          businessAccount: _this.get('account.businessAccount'),

          isFirstDayLogin: isFirstDayLogin,
          integratedApps: []
        };

        if (_this.get('account').hasFeature('integration_actions')) {
          _this.get('store').findAll('integratedApplication').then(function (apps) {
            var integratedApps = [];

            apps.forEach(function (app) {
              var appName = app.get('integration.name');

              if (_this.get('account.isOmnichannelEnabled') && (appName === 'freshdesk' || appName === 'support360')) {
                _this.set('account.omnichannelOwnerDomain', app.get('domain'));
              }

              integratedApps.push(appName);
            });

            userProps.integratedApps = integratedApps;
            _this.set('propsToTargetThisUser', userProps);
            res(userProps);
          });
        } else {
          _this.set('propsToTargetThisUser', userProps);
          res(userProps);
        }
      });
    }

  });
});