define("freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "loading-wrapper");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "spinner");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 6
                },
                "end": {
                  "line": 11,
                  "column": 6
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "component", ["live-calls/live-agents-enhanced-dashboard/enhanced-agents-list"], ["noResult", true, "status", "no_agents"], ["loc", [null, [10, 8], [10, 119]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 6
                },
                "end": {
                  "line": 21,
                  "column": 6
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "component", ["live-calls/live-agents-enhanced-dashboard/enhanced-agents-list"], ["agents", ["subexpr", "@mut", [["get", "fetchedAgents", ["loc", [null, [12, 94], [12, 107]]], 0, 0, 0, 0]], [], [], 0, 0], "status", ["subexpr", "@mut", [["get", "selectedStatus", ["loc", [null, [12, 115], [12, 129]]], 0, 0, 0, 0]], [], [], 0, 0], "agentsCount", ["subexpr", "@mut", [["get", "totalLiveAgentsCount", ["loc", [null, [12, 142], [12, 162]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedStatusTitle", ["subexpr", "@mut", [["get", "selectedStatusTitle", ["loc", [null, [12, 183], [12, 202]]], 0, 0, 0, 0]], [], [], 0, 0], "searchResults", ["subexpr", "@mut", [["get", "searchComplete", ["loc", [null, [12, 217], [12, 231]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 10], [12, 233]]], 0, 0], ["inline", "custom-elements/vertical-infinite-scroll", [], ["content", ["subexpr", "@mut", [["get", "fetchedAgents", ["loc", [null, [14, 20], [14, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "hasMore", ["subexpr", "@mut", [["get", "hasMore", ["loc", [null, [15, 20], [15, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "isFetching", ["subexpr", "@mut", [["get", "isLoadMore", ["loc", [null, [16, 23], [16, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "centerLoader", true, "fetchMore", ["subexpr", "action", ["fetchMore"], [], ["loc", [null, [18, 22], [18, 42]]], 0, 0], "scrollable", "#live-agents-status-list"], ["loc", [null, [13, 10], [20, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 23,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "agents-enhanced-list-wrapper");
            dom.setAttribute(el1, "id", "live-agents-status-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "isAgentListEmpty", ["loc", [null, [9, 12], [9, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [9, 6], [21, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/template.hbs"
        },
        isEmpty: false,
        arity: 3,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["get", "loadingAgents", ["loc", [null, [3, 12], [3, 25]]], 0, 0, 0, 0], ["get", "listLoading", ["loc", [null, [3, 26], [3, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 8], [3, 38]]], 0, 0]], [], 0, 1, ["loc", [null, [3, 2], [23, 9]]]]],
        locals: ["listLoading", "hasEmptyList", "isSearching"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/live-calls/live-agents-enhanced-dashboard/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "paginate-search", [], ["title", ["subexpr", "@mut", [["get", "selectedStatusTitle", ["loc", [null, [1, 25], [1, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "showDropDown", true, "callbackOnToggle", ["subexpr", "action", ["loadStatusDropdown"], [], ["loc", [null, [1, 80], [1, 109]]], 0, 0], "placeholder", ["subexpr", "t", ["agent.name_search"], [], ["loc", [null, [1, 122], [1, 145]]], 0, 0], "modelName", "live-agent-status", "version", "v1", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 195], [1, 200]]], 0, 0, 0, 0]], [], [], 0, 0], "modelCallback", ["subexpr", "action", ["modelCallback"], [], ["loc", [null, [1, 215], [1, 239]]], 0, 0], "dropDownCallback", ["subexpr", "action", ["dropDownCallback"], [], ["loc", [null, [1, 257], [1, 284]]], 0, 0], "blockEmptyLoad", true, "dropDownObj", ["subexpr", "@mut", [["get", "dropDownObj", ["loc", [null, [1, 317], [1, 328]]], 0, 0, 0, 0]], [], [], 0, 0], "hasExpandableSearchBar", true, "alwaysShowSearchbar", true], 0, null, ["loc", [null, [1, 0], [24, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});