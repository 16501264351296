define('freshfone-ember/helpers/s360-eql', ['exports', 'ember-unified-navbar-360/helpers/s360-eql'], function (exports, _emberUnifiedNavbar360HelpersS360Eql) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberUnifiedNavbar360HelpersS360Eql['default'];
    }
  });
  Object.defineProperty(exports, 's360Eql', {
    enumerable: true,
    get: function get() {
      return _emberUnifiedNavbar360HelpersS360Eql.s360Eql;
    }
  });
});