define('freshfone-ember/services/power-dialer-list', ['exports', 'freshfone-ember/constants/call-status', 'ember', 'freshfone-ember/constants/power-dialer-state', 'freshfone-ember/constants/widget-status'], function (exports, _freshfoneEmberConstantsCallStatus, _ember, _freshfoneEmberConstantsPowerDialerState, _freshfoneEmberConstantsWidgetStatus) {
  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var alias = _ember['default'].computed.alias;
  var run = _ember['default'].run;
  exports['default'] = Service.extend({
    store: service(),
    notification: service('toastr'),
    session: service(),
    totalContactsInPowerDialer: computed.alias('customPowerDialer.totalContacts'),
    leftContactsInPowerDialer: computed.alias('customPowerDialer.leftContacts'),
    lastPowerDialerContact: computed.equal('leftContactsInPowerDialer', 1),
    dialedContacts: computed('totalContactsInPowerDialer', 'leftContactsInPowerDialer', function () {
      return this.get('totalContactsInPowerDialer') - this.get('leftContactsInPowerDialer') + 1;
    }),
    removeContact: false,
    agent: alias('session.user.agent'),
    widgetCallInProgress: true,
    organisationDomain: alias('session.account.organisationDomain'),
    enableStartPowerDialer: false,
    setCustomPowerDialer: function setCustomPowerDialer(customPowerDialer) {
      this.set('customPowerDialer', customPowerDialer);
    },
    getCustomPowerDialer: function getCustomPowerDialer() {
      return this.get('store').peekAll('custom-power-dialer').get('lastObject');
    },
    closeWidget: function closeWidget(toggleProperty) {
      this.set('closeOngoingWidget', toggleProperty);
    },
    setPowerDialer: function setPowerDialer(mode) {
      this.set('startPowerDialer', mode);
    },
    listenPowerDialerEvents: function listenPowerDialerEvents() {
      window.addEventListener('message', this.powerDialerEventHandler.bind(this));
    },
    removePowerDialerEventListener: function removePowerDialerEventListener() {
      window.removeEventListener('message', this.powerDialerEventHandler.bind(this));
    },
    removeContactFromList: function removeContactFromList(eventData) {
      var payload = eventData.payload;

      if (!(payload && payload.customer && payload.customer.powerDialerId)) {
        return;
      }

      this.set('contactId', payload.customer.powerDialerId);
      this.set('removeContact', true);
    },
    handleCallStatus: function handleCallStatus(eventData) {
      var _this = this;

      var isCallInProgress = eventData.status === _freshfoneEmberConstantsCallStatus['default'].CallInProgress;

      run(function () {
        _this.set('widgetCallInProgress', isCallInProgress);

        if (isCallInProgress) {
          return;
        }

        _this.removeContactFromList(eventData);
      });
    },
    powerDialerEventHandler: function powerDialerEventHandler(event) {
      var _this2 = this;

      var origin = window.location.protocol + '//' + this.get('organisationDomain');

      if (this.get('isDestroying') || this.get('isDestroyed')) {
        // eslint-disable-next-line no-console
        console.log('Service is already destroyed or destroying');

        return;
      }

      // please comment out this check if running in local
      if (event.origin !== origin) {
        // eslint-disable-next-line no-console
        console.log('Event received from unexpected origin', event.origin);

        return;
      }

      run(function () {
        var data = event.data;

        if (!data.type) {
          return;
        }

        switch (data.type) {
          case _freshfoneEmberConstantsPowerDialerState['default'].PowerDialerEnded:
            _this2.set('agent.powerDialer', false);
            break;
          case _freshfoneEmberConstantsPowerDialerState['default'].PowerDialerCallErrored:
            _this2.removeContactFromList(data);
            break;
          case _freshfoneEmberConstantsPowerDialerState['default'].CallStatus:
            _this2.handleCallStatus(data);
            break;
          case _freshfoneEmberConstantsWidgetStatus['default'].WidgetReady:
            _this2.set('enableStartPowerDialer', true);
            break;
        }
      });
    }
  });
});