define("freshfone-ember/transitions", ["exports"], function (exports) {
  exports["default"] = function () {
    var duration = 600;

    this.transition(this.fromRoute("protected.admin.callflows.index"), this.toRoute("protected.admin.callflows.edit"), this.use("custom-to-left", { duration: duration }), this.reverse("to-right", { duration: duration }));

    this.transition(this.fromRoute("protected.admin.callflows.index"), this.toRoute("protected.admin.callflows.new"), this.use("custom-to-left", { duration: duration }), this.reverse("to-right", { duration: duration }));

    this.transition(this.hasClass('queue-child'), this.toValue(true), this.includingInitialRender(), this.use('to-up', { duration: duration }), this.reverse('to-down', { duration: duration }));

    this.transition(this.hasClass('fadeIn'), this.toValue(true), this.includingInitialRender(), this.useAndReverse('fade', { duration: duration }));

    this.transition(this.hasClass('slide-down'), this.toValue(true), this.includingInitialRender(), this.use('to-down', { duration: duration }), this.reverse('to-up', { duration: duration }));

    this.transition(this.hasClass('toLeft'), this.useAndReverse('scroll-to-right', { duration: duration }));

    this.transition(this.hasClass('slide-up'), this.toValue(true), this.includingInitialRender(), this.use('to-up', { duration: duration }), this.reverse('to-down', { duration: duration }));
  };
});