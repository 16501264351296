define('freshfone-ember/constants/purchase-form', ['exports'], function (exports) {
  exports['default'] = Object.freeze({
    freshcallerSupportEmail: 'support@freshcaller.com',
    crmSupportEmail: 'crm-support@freshworks.com',
    getMailToLink: function getMailToLink(mailDetails) {
      var phoneNumber = mailDetails.phoneNumber;
      var price = mailDetails.price;
      var country = mailDetails.country;
      var accountId = mailDetails.accountId;
      var orgUrl = mailDetails.orgUrl;
      var userEmail = mailDetails.userEmail;
      var userName = mailDetails.userName;
      var supportEmailId = mailDetails.supportEmailId;
      var mailToString = ('mailto:' + supportEmailId + '?subject=Need to buy a phone number\n    &body=Hello team,%0D%0A%0D%0AI would like to buy this number:%0D%0A\n            %E2%80%A2 Phone number: ' + phoneNumber + ' %0D%0A\n            %E2%80%A2 Price: $' + price + ' %0D%0A\n            %E2%80%A2 Country: ' + country + ' %0D%0A\n          %0D%0A\n          My Details are:%0D%0A\n            %E2%80%A2 Account ID: ' + accountId + ' %0D%0A\n            %E2%80%A2 Account URL: ' + orgUrl + ' %0D%0A\n            %E2%80%A2 User email: ' + userEmail + ' %0D%0A\n          %0D%0A\n          Thanks%0D%0A\n          ' + userName).replace(/\s+/g, ' ').trim();

      return mailToString;
    }
  });
});