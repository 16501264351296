define('freshfone-ember/services/import-service', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var typeOf = _ember['default'].typeOf;
  var Evented = _ember['default'].Evented;
  exports['default'] = _ember['default'].Service.extend(Evented, {
    notifications: service('toastr'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('importProgress', {});
      this.reset();
    },

    getToastrMessage: function getToastrMessage(status) {
      var fileModel = {};
      if (this.get('importParams.fileModel')) {
        fileModel = {
          'contacts': 'contact',
          'companies': 'company',
          'bot_intents': 'bot_intents',
          routing_rules: 'data_automated_routing.import_rules',
          power_dialer: 'power_dialer'
        };
      }

      return _ember['default'].i18n.t(fileModel[this.get('importParams.fileModel')] + '.notifications.' + status);
    },

    getToastrMessageWithNumericInfo: function getToastrMessageWithNumericInfo(fileModel, _ref) {
      var appended_count = _ref.appended_count;
      var duplicates_count = _ref.duplicates_count;
      var contacts_without_numbers_count = _ref.contacts_without_numbers_count;

      var totalCount = appended_count + contacts_without_numbers_count + duplicates_count;
      if (appended_count && contacts_without_numbers_count) {
        var message = _ember['default'].i18n.t(fileModel + '.notifications.appended_count_without_phoneNumber', { appendedCount: appended_count, totalCount: totalCount, noPhoneNumber: contacts_without_numbers_count });
        this.get('notifications').info(message);
      }
      if (duplicates_count) {
        var message = _ember['default'].i18n.t(fileModel + '.notifications.duplicate_count', { duplicateCount: duplicates_count });
        this.get('notifications').info(message);
      }
      if (!contacts_without_numbers_count) {
        var message = _ember['default'].i18n.t(fileModel + '.notifications.appended_count', { appendedCount: appended_count });
        this.get('notifications').success(message);
      }

      if (!appended_count && contacts_without_numbers_count) {
        var message = _ember['default'].i18n.t(fileModel + '.notifications.contacts_without_phone_number', { noPhoneNumber: contacts_without_numbers_count });
        this.get('notifications').info(message);
      }
    },

    bindImportEvents: function bindImportEvents() {
      this.set('importCallback', this.importCallback.bind(this));
      FC.Event.subscribe('data_import_status', this.importCallback);
    },

    importCallback: function importCallback(data) {
      var _this = this;

      if (data.status === 'success') {
        if (data.ignored_rows) {
          this.get('notifications').info(this.getToastrMessage('ignored_rows'));
        } else if (!data.additional_info) {
          this.get('notifications').success(this.getToastrMessage('success'));
        } else {
          this.getToastrMessageWithNumericInfo(this.get('importParams.fileModel'), data.additional_info);
        }
        this.set('importingFileName', null);
        this.trigger('import_success', data);
      } else if (data.status === 'error') {
        this.get('notifications').error(this.getToastrMessage('error') + ' <span style="color: #4A90E2;">' + _ember['default'].i18n.t('common.knowMore') + '</span>', '', {
          onclick: function onclick() {
            _this.setProperties({
              'importParams.errMsg': data.message,
              'importParams.fileName': _this.get('importingFileName'),
              'importParams.hasError': true,
              'importParams.showImportModal': true
            });
          }
        });
        this.trigger('import_failed', data);
      }

      // TODO remove this statement once import service is refactored to have its own context for each upload
      this.set('importProgress', {});
      this.trigger('import_complete', data);
      FC.Event.unsubscribe('data_import_status', this.importCallback);
    },

    reset: function reset() {
      this.set('importParams', {
        showImportModal: false,
        fileModel: '',
        fileName: '',
        errMsg: '',
        hasError: false,
        params: {}
      });
    },
    setImportProgress: function setImportProgress(key, value) {
      this.set('importProgress.' + key, value);
    },
    getImportProgress: function getImportProgress(key) {
      return this.get('importProgress.' + key);
    },

    apiAction: function apiAction() {
      var _this2 = this;

      var importData = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      this.closeModal('closed');
      this.get('notifications').info(this.getToastrMessage('inProgress'));

      var fd = new FormData();
      fd.append('fname', importData.fileName);
      fd.append('data', importData.fileData);
      fd.append('type', importData.fileType);

      var params = importData.params;

      if (typeOf(params) === 'object') {
        Object.keys(params).forEach(function (key) {
          return fd.append(key, params[key]);
        });
      }

      this.set('importingFileName', importData.fileName);

      this.bindImportEvents();
      this.trigger('import_progress');

      _ember['default'].$.ajax({
        url: '/data_imports',
        type: 'POST',
        data: fd,
        processData: false,
        contentType: false
      }).fail(function () {
        return _this2.trigger('import_failed');
      });
    },

    /**
     * Event types: `closed` and `closed_user`
     * @param {string} eventType
     */

    closeModal: function closeModal() {
      var eventType = arguments.length <= 0 || arguments[0] === undefined ? 'closed_user' : arguments[0];

      this.trigger(eventType);

      this.setProperties({
        'importParams.errMsg': '',
        'importParams.fileName': '',
        'importParams.hasError': false,
        'importParams.showImportModal': false
      });
    }
  });
});