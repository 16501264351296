define('freshfone-ember/modal/object', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Evented = _ember['default'].Evented;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Object.extend(Evented, {
    modalStack: computed.readOnly('modalService.modalStack'),

    // Object to be destroyed from the service stack.
    deleteFromStack: function deleteFromStack(triggerAction, params) {
      var stack = get(this, 'modalStack');

      if (stack) {
        stack.removeObject(this);
      }

      this.destroy(); // Destroy current modal object.

      // 'didModalClose' or a trigger required for an event is fired on modal destroy. Could be useful for modals that require to handle operations after modal closes.
      return this.trigger(triggerAction || 'didModalClose', params);
    }
  });
});