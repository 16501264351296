define('freshfone-ember/models/caller', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    number: _emberData['default'].attr(),
    callerType: _emberData['default'].attr(),
    calls: _emberData['default'].hasMany('call', { async: true }),
    blocked: _ember['default'].computed.equal('callerType', 'blocked'),
    notBlocked: _ember['default'].computed.not('blocked'),

    toggleBlock: function toggleBlock() {
      var type = this.get("blocked") ? 'normal' : 'blocked';
      this.set('callerType', type);
      return this.save();
    }
  });
});