define('freshfone-ember/utils/promotion-data', ['exports'], function (exports) {
  /* eslint-disable camelcase */
  exports['default'] = Object.freeze({
    answering_on_sip: function answering_on_sip(promotionDataRequest) {
      var possibleSIPStatuses = [0, 1];
      var Math = window.Math;

      var promotionData = promotionDataRequest.then(function (agents) {
        return agents.map(function (agent) {
          var randomStatus = possibleSIPStatuses[Math.floor(Math.random() * possibleSIPStatuses.length)];
          agent.set('sipStatus', randomStatus);
          return agent;
        });
      });

      return promotionData;
    },
    service_level_monitoring: function service_level_monitoring(promotionDataRequest) {
      promotionDataRequest.globalQueue.map(function (queue) {
        queue.set('__modelProxy', {
          targetPercent: 80,
          ttaThreshold: 30
        });
      });

      return promotionDataRequest;
    },

    custom_agent_status: function custom_agent_status(promotionDataRequest) {
      return promotionDataRequest;
    },

    voicemail_drop: function voicemail_drop(promotionDataRequest) {
      return promotionDataRequest;
    },

    call_tagging: function call_tagging(promotionDataRequest) {
      return promotionDataRequest;
    },

    power_dialer: function power_dialer(promotionDataRequest) {
      return promotionDataRequest;
    }
  });
});