define('freshfone-ember/helpers/s360-totitlecase', ['exports', 'ember-unified-navbar-360/helpers/s360-totitlecase'], function (exports, _emberUnifiedNavbar360HelpersS360Totitlecase) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberUnifiedNavbar360HelpersS360Totitlecase['default'];
    }
  });
  Object.defineProperty(exports, 's360Totitlecase', {
    enumerable: true,
    get: function get() {
      return _emberUnifiedNavbar360HelpersS360Totitlecase.s360Totitlecase;
    }
  });
});