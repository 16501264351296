define('freshfone-ember/models/call', ['exports', 'freshfone-ember/constants/call-category', 'freshfone-ember/constants/call-category-tooltips', 'ember', 'ember-data', 'ember-api-actions', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/flash-mixin'], function (exports, _freshfoneEmberConstantsCallCategory, _freshfoneEmberConstantsCallCategoryTooltips, _ember, _emberData, _emberApiActions, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsFlashMixin) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;

  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;

  var momentjs = window.moment;

  exports['default'] = Model.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsFlashMixin['default'], {
    i18n: service(),
    session: service(),
    total_duration: attr(),
    direction: attr(),
    status: attr(),
    in_transfer: attr(),
    abandonedType: attr(),
    from: attr(),
    created_at: attr(),
    callQueueId: attr(),
    conversation: attr(),
    businessHourCall: attr(),
    hideAgentName: attr(),
    transcriptionID: attr('number'),
    user: belongsTo('user'),
    contact: belongsTo('contact', { async: false }),
    transferredContactName: attr('string'),
    caller: belongsTo('caller'),
    descendants: hasMany('call', { async: true, inverse: null }),
    agentLegs: hasMany('agent-leg'), // Only warm transfer legs are being sent now from back-end
    call_metrics: belongsTo('call-metric', { async: false }),
    bot_conversation: attr(),
    integratedResources: hasMany('integrated-resource', { async: true }),
    tags: hasMany('tag', { async: false }),
    pcipalStatuses: hasMany('pcipal-status'),
    searchString: attr(),
    hasChildren: computed.or('descendants.length', 'agentLegs.length'),
    childCount: computed('hasChildren', function () {
      if (this.get('isRoot') && this.get('hasChildren')) {
        return this.get('descendants.length') + this.get('agentLegs.length');
      }
    }),
    number: attr(),
    deducted_cost: attr(),
    numberName: attr(),
    ancestry: attr(),
    transfered_at: attr(),
    recordingUrl: attr(),
    recordingDuration: attr(),
    flowName: attr('string'),
    externalNumber: attr(),
    total_cost: attr(),
    supervised_agent: attr(),
    teamName: attr(),
    bill_duration: attr(),
    parallelCall: attr(),
    callParked: attr(),
    category: attr(),
    messageId: attr(),
    recordingRedacted: attr(),
    voicemailDrop: attr('boolean'),
    csatTransferMade: attr('boolean'),
    waitQueuePosition: attr('number'),
    attendedStatuses: ['in-progress', 'hold', 'completed'],
    contactName: computed.alias('transferredContactName'), // to maintain uniformity between call and agent-leg
    isChild: computed.notEmpty('ancestry'),
    isRoot: computed.empty('ancestry'),
    showChildCount: computed.and('isRoot', 'hasChildren'),
    showChildren: computed.and('showNotes', 'hasChildren'),
    hasAgentLegs: computed.notEmpty('agentLegs'),
    inboxConversationId: attr('string'),
    isOngoing: computed('status', function () {
      return this.get('status') === 'in-progress' || this.get('status') === 'bot_inprogress';
    }),
    isQueued: computed.equal('status', 'queued'),
    isQueuedRinging: computed.equal('status', 'queued-ringing'),
    isWaitQueueTimedout: computed.equal('status', 'waitqueue-timedout'),
    isOnHold: computed.equal('status', 'on-hold'),
    isActive: computed.or('isOngoing', 'isOnHold'),
    isOutgoing: computed.equal('direction', 'outgoing'),
    hasRecording: computed.notEmpty('recordingUrl'),
    hasNote: computed.notEmpty('conversation'),
    hasTag: computed.notEmpty('tags'),
    hasPcipalStatus: computed.alias('pcipalStatuses.length'),
    answeredAt: attr(),
    enqueuedAt: attr(),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    entityName: computed.or('user.name', 'contactName', 'externalNumber'),
    hasMonitoringAndBarging: computed(function () {
      return this.hasFeatures(['call_monitoring', 'call_barging']);
    }),
    hasMonitoring: computed(function () {
      return this.hasFeatures(['call_monitoring']);
    }),
    isAgentBusy: computed('currentAgent.browserStatus.statusType', function () {
      return ['busy', 'acw'].includes(this.get('currentAgent.browserStatus.statusType'));
    }),
    canSupervise: computed('currentAgent.browserStatus.statusType', function () {
      return this.get('supervised_agent') && this.get('currentUser.id') == this.get('supervisorId') || !this.get('isAgentBusy') && this.get('hasMonitoringAndBarging');
    }),

    canMonitorCall: computed('currentAgent.browserStatus.statusType', function () {
      return !this.get('isAgentBusy') && (this.get('hasMonitoring') && !this.get('isOnHold') || this.get('hasMonitoringAndBarging') && this.get('isOnHold')) && !this.get('bot_conversation') && !this.get('currentUser.isAgent');
    }),

    showDuration: computed('status', function () {
      return this.get("status") === 'completed' || this.get("status") === 'voicemail';
    }),
    rootId: computed('ancestry', function () {
      var ancestryId = this.get("ancestry").split("/");
      return ancestryId[0];
    }),
    supervisorId: computed('supervised_agent', function () {
      if (this.get('supervised_agent')) {
        return this.get('supervised_agent.user_id').toString();
      }
    }),
    format_last_created_at: computed('created_at', {
      get: function get() {
        var dateFormat = {
          sameDay: function sameDay() {
            return '[' + this.fromNow() + ']';
          },
          lastDay: '[Yesterday] - hh:mm A',
          lastWeek: 'ddd - hh:mm A',
          someElse: 'DD/MM/YYYY - hh:mm A'
        };
        return momentjs(this.get('created_at')).calendar(null, dateFormat);
      } }),

    duration: computed('total_duration', {
      get: function get() {
        var timeFormat = this.get('total_duration') > 3600 ? 'HH:mm:ss' : 'mm:ss';
        return momentjs().startOf('day').add(this.get('total_duration') * 1000).format(timeFormat);
      } }),

    talkTime: computed('answeredAt', function () {
      return momentjs().diff(momentjs(this.get('answeredAt')), 'seconds') || 0;
    }),

    holdTime: computed('call_metrics.holdAt', 'status', function () {
      if (this.get('holdAt')) {
        return 0;
      }
      var holdAt = this.get("call_metrics.holdAt");
      this.set('holdAt', holdAt);
      var seconds = momentjs().diff(momentjs(holdAt), 'seconds');
      return seconds || 0;
    }),

    waitTime: computed('enqueuedAt', function () {
      return momentjs().diff(momentjs(this.get('enqueuedAt')), 'seconds') || 0;
    }),

    directionStatusClass: computed('direction', {
      get: function get() {
        var callWithDirection;
        var callback_call = "";

        if (this.get('category') === _freshfoneEmberConstantsCallCategory['default'].QueueTransfer) {
          callWithDirection = ['icon', 'call-queue-forward'].join('-');
        } else if (this.get('status') === 'voicemail') {
          callWithDirection = ["icon", "call-voicemail"].join("-");
        } else if (this.get('voicemailDrop')) {
          callWithDirection = ["icon", "voicemail-outgoing-icon"].join("-");
        } else if (this.get('category') === _freshfoneEmberConstantsCallCategory['default'].ApiCall) {
          callWithDirection = ['icon', 'call-api'].join('-');
        } else {
          callWithDirection = ["icon", "call", this.get('direction') || "incoming"].join("-");
          if (this.get("category") === "callback") {
            callback_call = "icon-callback-arrow";
          }
        }
        if (!this.get('voicemailDrop') && ['no-answer', 'blocked', 'failed', 'canceled', 'abandoned'].contains(this.get("status"))) {
          callWithDirection = [callWithDirection, "missed"].join("-");
          if (this.get("category") === "callback") {
            callback_call = "icon-callback-arrow-missed";
          }
        }

        return [callWithDirection, this.get("status"), callback_call].join(" ");
      }
    }),

    displayStatus: computed('status', 'abandonType', {
      get: (function (_get) {
        function get() {
          return _get.apply(this, arguments);
        }

        get.toString = function () {
          return _get.toString();
        };

        return get;
      })(function () {
        var status = get(this, 'status');
        var category = get(this, 'category');
        if (category === "callback") {
          /* This condition occurs only when the callback is trigerred by defelection bot call */
          if (this.get('ancestry')) {
            var ancestryId = this.get("ancestry").split("/");
            /* There can be only two ancestors for callback via deflection bot */
            var _parent = this.store.peekRecord('call', ancestryId.length > 1 ? ancestryId[1] : ancestryId[0]);
            if (_parent.get('category') === 'deflection_bot') {
              return this.displayStatusForCallback();
            }
          } else if (!this.get('isChild')) {
            return this.displayStatusForCallback();
          }
        }
        if (category !== "callback" && status === 'abandoned' && _ember['default'].isPresent(get(this, 'abandonedType'))) {
          status = get(this, 'abandonedType').replace(/ /g, "").toLowerCase();
        }
        if (this.get('voicemailDrop')) {
          status = 'voicemailDrop';
        } else if (status === 'no-answer') {
          status = "missed";
        }
        var callStatus = this.get('i18n').t('call_direction.' + status, {
          category: Object.keys(_freshfoneEmberConstantsCallCategoryTooltips['default']).includes(category) ? _freshfoneEmberConstantsCallCategoryTooltips['default'][category] : ''
        }).string;
        return callStatus ? callStatus.replace(/\s?$/, '') : null;
      })
    }),
    activeParallelLeg: computed('agentLegs.[]', 'agentLegs.@each.{isParallelCallLeg,isActive}', function () {
      return this.get('agentLegs').find(function (leg) {
        return leg.get('isParallelCallLeg') && leg.get('isActive');
      });
    }),
    hasPrimaryOrParallelCallActive: computed('isActive', 'parallelCall', function () {
      return !!(this.get('isActive') || this.get('parallelCall'));
    }),
    isPrimaryOrParallelCallOnHold: computed('isActive', 'isOnHold', 'activeParallelLeg.isOnHold', function () {
      return this.get('isActive') ? this.get('isOnHold') : this.get('activeParallelLeg.isOnHold');
    }),
    oldParkedStatus: false,

    parkedStatus: computed('status', 'callParked', function () {
      if (!this.get('isOnHold')) {
        this.set('oldParkedStatus', false);

        return false;
      }

      if (this.get('callParked')) {
        this.set('oldParkedStatus', true);

        return true;
      }

      return this.get('oldParkedStatus');
    }),

    displayStatusForCallback: function displayStatusForCallback() {
      if (['no-answer', 'blocked', 'failed', 'canceled', 'abandoned'].contains(this.get('status'))) {
        return _ember['default'].i18n.t('call_direction.unsuccesfull_callback');
      } else if (this.get('isQueued') || this.get('isQueuedRinging') || this.get('isWaitQueueTimedout')) {
        return _ember['default'].i18n.t('call_direction.queued_callback');
      } else {
        return _ember['default'].i18n.t('call_direction.succesfull_callback');
      }
    },

    deleteConversation: (0, _emberApiActions.memberAction)({
      type: 'DELETE',
      path: 'conversation'
    }),

    deleteRecording: (0, _emberApiActions.memberAction)({
      type: 'DELETE',
      path: 'recording'
    }),
    getCallTranscript: (0, _emberApiActions.memberAction)({
      type: 'GET',
      path: 'transcript'
    }),
    getCallDetails: (0, _emberApiActions.memberAction)({
      type: 'GET',
      path: 'extract'
    }),
    getCallEvents: (0, _emberApiActions.memberAction)({
      type: 'GET',
      path: 'fetch_events'
    }),

    // Errors should follow JSON API. Since they're not, unable to make use of memberAction.
    assign: function assign(data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        return $.ajax({
          url: '/calls/' + _this.get('id') + '/assign',
          type: 'POST',
          dataType: 'json',
          data: data
        }).then(resolve, reject);
      });
    },
    moveToTop: function moveToTop() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        return $.ajax({
          url: '/calls/' + _this2.get('id') + '/move_to_top',
          type: 'POST',
          dataType: 'json'
        }).then(resolve, reject);
      });
    },
    takeCall: function takeCall(data) {
      return $.ajax({
        url: '/calls/' + this.get('id') + '/pick',
        type: 'POST',
        dataType: 'json',
        data: data
      });
    }
  });
});