define('freshfone-ember/error-monitor', ['exports', 'freshfone-ember/config/environment'], function (exports, _freshfoneEmberConfigEnvironment) {
  exports.startErrorMonitor = startErrorMonitor;
  var Sentry = window.Sentry;
  var enabled = {
    sentry: Sentry && JSON.parse(localStorage.getItem('accountFeatures')).includes('frontend_error_monitoring')
  };
  function startErrorMonitor() {
    if (enabled.sentry) {
      Sentry.init({
        dsn: _freshfoneEmberConfigEnvironment['default'].sentry.dsn,
        integrations: [new Sentry.Integrations.Ember()],
        release: document.querySelector('meta[name="sentry:revision"]').getAttribute('content'),
        environment: _freshfoneEmberConfigEnvironment['default'].fcaEnv,
        beforeSend: function beforeSend(event) {
          // ignore aborted route transitions from the Ember.js router
          if (event.extra && event.extra.reason && event.extra.reason.name === 'TransitionAborted') {
            return null;
          }

          return event;
        }
      });
    }
  }
});