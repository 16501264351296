define('freshfone-ember/services/state-transition', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  var $ = _ember['default'].$;
  exports['default'] = Service.extend({
    generateHtml: function generateHtml(message) {
      return '\n    <div class="transition-container">\n      <div class="transition-bg"></div>\n      <div class="call-transition">\n          <div class="transition-loader"></div>\n          <div class="transition-text">' + message + '</div>\n      </div>\n    </div>\n    ';
    },
    start: function start(message) {
      var modalEle = $('#ongoing-call-modal');

      modalEle.append(this.generateHtml(message));
    },
    stop: function stop() {
      var transitionEle = $('#ongoing-call-modal .transition-container');

      if (!transitionEle.length) {
        return;
      }
      transitionEle.remove();
    }
  });
});