define('freshfone-ember/components/call-tags/default-tags/default-tag-row/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend({
    enabledTags: computed.filterBy('model', 'disabled', false),
    allTagsDisabled: computed.empty('enabledTags'),

    showValidationMessage: function showValidationMessage(status, message) {
      this.setProperties({
        status: status,
        validationMessage: message
      });
    },

    afterSaveCallback: function afterSaveCallback(tag) {
      var _this = this;

      if (this.get('allTagsDisabled') && this.get('isTaggingMandatory')) {
        this.sendAction('disableMandatoryCallTagging', true, true);
      }
      this.showValidationMessage('success', _ember['default'].i18n.t('call_tags.custom_tags.changes_saved'));
      later(function () {
        return _this.showValidationMessage();
      }, 3000);
    },

    rollBackFailedChanges: function rollBackFailedChanges(tag) {
      var _this2 = this;

      tag.set('disabled', !tag.get('disabled'));
      this.showValidationMessage('error', _ember['default'].i18n.t('call_tags.custom_tags.failed_to_update'));
      later(function () {
        return _this2.showValidationMessage();
      }, 3000);
    },

    actions: {
      restoreTag: function restoreTag(tag) {
        var _this3 = this;

        tag.set('disabled', false);
        tag.save().then(function (response) {
          _this3.afterSaveCallback(tag);
        })['catch'](function (errors) {
          _this3.rollBackFailedChanges(tag);
        });
      },
      deleteTag: function deleteTag(tag) {
        var _this4 = this;

        tag.set('disabled', true);
        tag.save().then(function (response) {
          _this4.afterSaveCallback(tag);
        })['catch'](function (error) {
          _this4.rollBackFailedChanges(tag);
        });
      }
    }
  });
});