define('freshfone-ember/mixins/find-or-fetch-mixin', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('syncedOptions', []);
    },

    findOrFetch: function findOrFetch(modelName, recordId) {
      return this.store.peekRecord(modelName, recordId) || this.store.findRecord(modelName, recordId);
    },
    findOrFetchAll: function findOrFetchAll(modelName) {
      if (this.get('syncedOptions').includes(modelName)) {
        return this.store.peekAll(modelName).filterBy('isNew', false);
      }

      this.get('syncedOptions').push(modelName);

      return this.store.findAll(modelName);
    }
  });
});