define('freshfone-ember/components/common/promotion-wrapper/component', ['exports', 'ember', 'freshfone-ember/constants/promotion-image-urls'], function (exports, _ember, _freshfoneEmberConstantsPromotionImageUrls) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    router: service('-routing'),
    analyticsService: service('analytics-tracker'),
    classNameBindings: ['shouldPromote:promoting'],
    promotionImageURL: computed(function () {
      return _freshfoneEmberConstantsPromotionImageUrls.promotionImageUrls[this.get('featureSymbol')];
    }),
    isPromotionTypeScreenshots: computed.equal('promotionType', 'SCREENSHOTS'),
    isUnifiedAppAccount: computed.readOnly('session.account.isUnifiedAppAccount'),
    ubxBillingUrl: computed.readOnly('session.account.ubxBillingUrl')
  });
});