define('freshfone-ember/components/common/message-manager/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['messages-dropdown'],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('options', this.store.peekAll('message'));
    },
    actions: {
      closeMessage: function closeMessage() {
        this.set('showMessageModal', false);
      },
      createNewMessage: function createNewMessage() {
        var defaultTitle = this.store.peekAll('message').content.length + 1,
            newMessage = this.store.createRecord('message', { name: _ember['default'].i18n.t('message_components.record_component.message') + ' ' + defaultTitle });

        this.set('showMessageModal', true);
        this.set('messageHolder', newMessage);
        this.set('messageFor', this.get('extensionaction'));
      },
      onChanged: function onChanged(changedValue) {
        if (this.get('onChanged')) {
          this.get('onChanged')(changedValue);
        } else {
          this.set('value', changedValue);
        }
      },
      setNewMessage: function setNewMessage(message) {
        if (this.get('saveCallBack')) {
          this.get('saveCallBack')(message);
        } else {
          this.set('value', message);
        }
      }
    }
  });
});