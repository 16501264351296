define('freshfone-ember/components/ongoing-widget/maximized-widget/display-contact-search-result/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    init: function init() {
      this._super.apply(this, arguments);
    },

    hasSearchResults: computed.notEmpty('results.data'),
    isUCREnabledAccount: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    pushToStore: _ember['default'].observer('results', function () {
      var results = this.get('results');
      if (_ember['default'].isEmpty(results)) {
        this.set('contacts', []);
        return;
      }
      this.setContacts(results);
    }),

    setContacts: function setContacts(contactRecords) {
      if (_ember['default'].isEmpty(contactRecords)) {
        return;
      }
      var model = this.hasOmniRedirectFeatureEnabled() ? 'external-contact' : 'contact';

      this.store.pushPayload(model, contactRecords);
      // eslint-disable-next-line one-var
      var resultantContactIds = contactRecords.data.mapBy('id'),
          filteredContacts = this.store.peekAll(model).filter(function (contactObject) {
        return resultantContactIds.contains(contactObject.get('id'));
      });
      this.set('contacts', filteredContacts);
    },

    actions: {
      mergeContactShortCut: function mergeContactShortCut(contactSelectedToMerge) {
        this.set('linkToCallShortcut', true);
        this.sendAction('mergeCallToContact', contactSelectedToMerge);
      },

      createNewContact: function createNewContact() {
        this.sendAction('createNewContact');
      }
    }
  });
});