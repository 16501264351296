define('freshfone-ember/components/sip-component/sip-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/constants/sip-status', 'freshfone-ember/mixins/send-sip-credential-mixin'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _emberComponentInboundActionsInboundActions, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberConstantsSipStatus, _freshfoneEmberMixinsSendSipCredentialMixin) {
	var computed = _ember['default'].computed;
	var debounce = _ember['default'].run.debounce;
	exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsSendSipCredentialMixin['default'], _emberComponentInboundActionsInboundActions['default'], {
		classNames: ['sip-form'],
		filterParams: computed(function () {
			return { 'page': 1, sip: 'disabled' };
		}),
		displayFormSaveButton: computed('agent.sipEnabled', 'agent.hasDirtyAttributes', 'agent.isSaving', 'initialisedEnableAll', {
			get: function get() {
				return !this.get('initialisedEnableAll') && !this.get('agent.isSaving') && (this.get('agent.hasDirtyAttributes') || !this.get('agent.sipEnabled'));
			}
		}),
		isDisclaimerVisible: computed.equal('agent.sipStatus', _freshfoneEmberConstantsSipStatus['default'].onBrowser),
		agent: computed({
			set: function set(key, value) {
				this.set('startValidate', false);

				value ? this.setProperties({ slideToggle: true, isEditing: true }) : this.set('isEditing', false);

				return value;
			}
		}),

		title: computed('isEditing', function () {
			return this.get('isEditing') ? _ember['default'].i18n.t('sip.sipDetails') : _ember['default'].i18n.t('sip.newSIP');
		}),

		searchSIPDisabledAgents: function searchSIPDisabledAgents(text, resolve) {
			var _this = this;

			_ember['default'].$.getJSON("/agents/search", { filter: { queryString: text }, page: '1', sip: 'disabled' }).then(function (response, data) {
				_ember['default'].run(function () {
					var agentList = response.data.mapBy('id');
					_this.store.pushPayload(response);
					var sipDisabledAgents = _this.store.peekAll('agent').filter(function (modelObj) {
						return agentList.contains(modelObj.get('id'));
					});
					resolve(sipDisabledAgents);
				});
			});
		},

		saveAgent: function saveAgent() {
			var _this2 = this;

			this.validate().then(function () {
				var agent = _this2.get('agent');
				var saveAgent = function saveAgent(shouldAlert) {
					_this2.save('agent', agent).then(function () {
						_this2.set('shouldAlert', shouldAlert);
						_this2.sendAction('onModify');
						_this2.send('toggleSidecard');
					});
				};

				if (agent.get('sipEnabled')) {
					saveAgent();
				} else {
					agent.set('sipEnabled', true);
					saveAgent(true);
				}
			})['catch'](function (error) {
				_this2.set('startValidate', true);
			});
		},

		saveAllAgents: function saveAllAgents() {
			var _this3 = this;

			var sipStatus = this.get('agent.sipStatusText.value');
			_ember['default'].$.ajax({
				url: "/sip/enable_all_users",
				type: "POST", dataType: 'json',
				data: { sipStatus: sipStatus }
			}).done(function () {
				_this3.set('initialisedEnableAll', true);
				_this3.set('agent', null);
				_this3.send('toggleSidecard');
			});
		},

		actions: {
			findSIPDisabledAgents: function findSIPDisabledAgents(text) {
				var _this4 = this;

				if (this.get('agent.isAllAgents')) this.set('agent', null);

				return new _ember['default'].RSVP.Promise(function (resolve) {
					debounce(_this4, _this4.searchSIPDisabledAgents, text, resolve, 1000);
				});
			},

			save: function save() {
				this.get('agent.isAllAgents') ? this.saveAllAgents() : this.saveAgent();
			},

			toggleSidecard: function toggleSidecard() {
				if (this.get('agent')) this.get('agent').rollbackAttributes();
				if (!this.get('slideToggle')) {
					this.set('agent', null);
				}
				// On close, nullify agent in this form
				this.toggleProperty('slideToggle');
				this.set('isEditing', false);
			},

			resendSIPCredentials: function resendSIPCredentials() {
				return this.resendSIPCredential(this.get('agent'));
			}
		},

		validations: {
			'agent': {
				presence: true,
				message: _ember['default'].i18n.t('sip.blankWarningMessage')
			},
			'agent.sipStatus': {
				presence: true,
				message: _ember['default'].i18n.t('sip.blankWarningMessage')
			}
		}
	});
});