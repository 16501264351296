define('freshfone-ember/components/custom-elements/ui-toggle/component', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ui-toggle'],
    classNameBindings: ['disabled'],
    tagName: 'span',
    click: function click() {
      if (!this.get('disabled')) {
        var debounce = this.get('debounce');

        if (debounce) {
          run.cancel(debounce);
        }

        debounce = run.debounce(this, function () {
          var value = this.get('checked');

          this.sendAction('willChangeValue', value, !value);
          this.toggleProperty('checked');
          this.sendAction('didChangeValue', this.get('checked'));
          this.set('debounce', null);
          if (typeof this.get('followUpAction') === 'function') {
            this.sendAction('followUpAction');
          }
        }, 50);

        this.set('debounce', debounce);
      }
    }
  });
});