define("freshfone-ember/components/agent-status-widget/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 4
                },
                "end": {
                  "line": 6,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "status-icon default-status busy icon-busy");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "status-name");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["agentStatusWidget.defaultStatuses.busy"], [], ["loc", [null, [5, 90], [5, 136]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 4
                  },
                  "end": {
                    "line": 8,
                    "column": 4
                  }
                },
                "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "spinner");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "status-name");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["common.loading_dots"], [], ["loc", [null, [7, 56], [7, 83]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 8,
                      "column": 4
                    },
                    "end": {
                      "line": 10,
                      "column": 4
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "status-icon default-status acw icon-acw");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "status-name");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "t", ["agentStatusWidget.defaultStatuses.afterCallWork"], [], ["loc", [null, [9, 88], [9, 143]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 10,
                        "column": 4
                      },
                      "end": {
                        "line": 12,
                        "column": 4
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "status-name");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element3, 'class');
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["status-icon default-status ", ["get", "currentStatus.icon", ["loc", [null, [11, 45], [11, 63]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "currentStatus.name", ["loc", [null, [11, 98], [11, 120]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 12,
                        "column": 4
                      },
                      "end": {
                        "line": 14,
                        "column": 4
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "status-icon");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "status-name");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n    ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "currentStatus.emoji", ["loc", [null, [13, 32], [13, 55]]], 0, 0, 0, 0], ["content", "currentStatus.name", ["loc", [null, [13, 89], [13, 111]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 10,
                      "column": 4
                    },
                    "end": {
                      "line": 14,
                      "column": 4
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "currentStatus.default", ["loc", [null, [10, 14], [10, 35]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [10, 4], [14, 4]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 8,
                    "column": 4
                  },
                  "end": {
                    "line": 14,
                    "column": 4
                  }
                },
                "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "isACWActive", ["loc", [null, [8, 14], [8, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [8, 4], [14, 4]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 4
                },
                "end": {
                  "line": 14,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "currentAgent.showLoader", ["loc", [null, [6, 14], [6, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 4], [14, 4]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 16,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "icon-dropdown_arrow");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "agentBusy", ["loc", [null, [4, 10], [4, 19]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 4], [14, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 23,
                        "column": 12
                      },
                      "end": {
                        "line": 25,
                        "column": 12
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element1, 'class');
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["item-icon disable-user-select ", ["get", "optValue.icon", ["loc", [null, [24, 56], [24, 69]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 25,
                        "column": 12
                      },
                      "end": {
                        "line": 27,
                        "column": 12
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "item-icon disable-user-select");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "optValue.emoji", ["loc", [null, [26, 58], [26, 76]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child2 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 29,
                        "column": 12
                      },
                      "end": {
                        "line": 29,
                        "column": 129
                      }
                    },
                    "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("span");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [0]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element0, 'class');
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["icon-tick ", ["subexpr", "if", [["get", "isACWActive", ["loc", [null, [29, 94], [29, 105]]], 0, 0, 0, 0], "acw-active"], [], ["loc", [null, [29, 89], [29, 120]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 8
                    },
                    "end": {
                      "line": 31,
                      "column": 8
                    }
                  },
                  "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
                },
                isEmpty: false,
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2, "class", "item-name disable-user-select ellipsis");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element2, 'class');
                  morphs[1] = dom.createElementMorph(element2);
                  morphs[2] = dom.createMorphAt(element2, 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
                  morphs[4] = dom.createMorphAt(element2, 5, 5);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["menu-item ", ["subexpr", "if", [["get", "optValue.default", ["loc", [null, [22, 36], [22, 52]]], 0, 0, 0, 0], "default-status", "custom-status"], [], ["loc", [null, [22, 31], [22, 87]]], 0, 0], " ", ["get", "optValue.statusSubType", ["loc", [null, [22, 90], [22, 112]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["get", "callingDisabled", ["loc", [null, [22, 120], [22, 135]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [22, 115], [22, 148]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["setStatus", ["get", "optValue", ["loc", [null, [22, 171], [22, 179]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 150], [22, 181]]], 0, 0], ["block", "if", [["get", "optValue.default", ["loc", [null, [23, 18], [23, 34]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [23, 12], [27, 19]]]], ["content", "optValue.name", ["loc", [null, [28, 65], [28, 82]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "optValue.id", ["loc", [null, [29, 22], [29, 33]]], 0, 0, 0, 0], ["get", "currentAgent.browserStatus.id", ["loc", [null, [29, 34], [29, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 18], [29, 64]]], 0, 0]], [], 2, null, ["loc", [null, [29, 12], [29, 136]]]]],
                locals: ["option", "optValue"],
                templates: [child0, child1, child2]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 6
                  },
                  "end": {
                    "line": 32,
                    "column": 6
                  }
                },
                "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "each-in", [["get", "customStatusOptions", ["loc", [null, [21, 19], [21, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [21, 8], [31, 20]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 4
                },
                "end": {
                  "line": 33,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "has-privilege", [], ["privilege", "view_agent_status", "user", ["get", "currentUser", ["loc", [null, [20, 62], [20, 73]]], 0, 0, 0, 0]], ["loc", [null, [20, 12], [20, 74]]], 0, 0]], [], 0, null, ["loc", [null, [20, 6], [32, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 2
              },
              "end": {
                "line": 34,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "disableAgentStatusToggle", ["loc", [null, [19, 14], [19, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 4], [33, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 36,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "rl-dropdown-toggle", [], ["tagName", "div", "class", ["subexpr", "if", [["get", "disableAgentStatusToggle", ["loc", [null, [3, 48], [3, 72]]], 0, 0, 0, 0], "status-toggle disabled", "status-toggle"], [], ["loc", [null, [3, 44], [3, 114]]], 0, 0]], 0, null, ["loc", [null, [3, 2], [16, 25]]]], ["block", "rl-dropdown", [], ["tagName", "ul", "class", "status-menu", "closeOnChildClick", "li"], 1, null, ["loc", [null, [18, 2], [34, 18]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 4
                },
                "end": {
                  "line": 45,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "freshid-redirect", [], ["currentUser", ["subexpr", "@mut", [["get", "currentUser", ["loc", [null, [41, 39], [41, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "focusPhoneNumber", ["subexpr", "@mut", [["get", "focusPhoneNumber", ["loc", [null, [42, 27], [42, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "afterUserSave", ["subexpr", "action", ["afterUserSave"], [], ["loc", [null, [43, 26], [43, 50]]], 0, 0], "toggleSettings", ["subexpr", "action", ["toggleSettings"], [], ["loc", [null, [44, 25], [44, 50]]], 0, 0]], ["loc", [null, [41, 8], [44, 52]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 2
              },
              "end": {
                "line": 46,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ui-modal", [], ["size", "md profile-setting", "closeOnEscape", true, "closeOnBackdropClick", true], 0, null, ["loc", [null, [40, 4], [45, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 0
            },
            "end": {
              "line": 47,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ember-wormhole", [], ["to", "modal-wormhole"], 0, null, ["loc", [null, [39, 2], [46, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/agent-status-widget/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "rl-dropdown-container", [], ["onOpen", ["subexpr", "action", ["busyResolve"], [], ["loc", [null, [1, 32], [1, 54]]], 0, 0], "class", ["subexpr", "concat", ["agent-status-widget status-dropdown ", ["get", "currentAgent.browserStatus.statusType", ["loc", [null, [1, 108], [1, 145]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 61], [1, 146]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [36, 26]]]], ["block", "if", [["get", "showProfileSettings", ["loc", [null, [38, 6], [38, 25]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [38, 0], [47, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});