define('freshfone-ember/constants/subscription-constants', ['exports', 'ember'], function (exports, _ember) {
  var i18n = _ember['default'].i18n;

  var billingCycles = {
    1: i18n.t('subscription.monthly').string,
    3: i18n.t('subscription.quarterly').string,
    6: i18n.t('subscription.half_yearly').string,
    12: i18n.t('subscription.annual').string
  };
  var topFeaturesVisibleCount = {
    forOldPlans: 7,
    forRebrandingPlans: 10
  };
  var creditConstants = { defaultRechargeOption: 10 };exports.billingCycles = billingCycles;
  exports.topFeaturesVisibleCount = topFeaturesVisibleCount;
  exports.creditConstants = creditConstants;
});