define('freshfone-ember/components/business-calendar/calendar-entry/time-slot-component/component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('errors', []);
    },
    noNumberLabel: computed(function () {
      return _ember['default'].i18n.t('business_calendar.validation_errors.no_number_found');
    }),
    timeHour: computed('startTimeHour', {
      get: function get() {
        return this.fetchTime(this.get('startTimeHour'), "HH", this.get('businessCalendar.hoursOption'));
      },
      set: function set(k, v) {
        if (v) {
          this.set('startTimeHour', this.setMinute(this.get('startTimeHour'), v));
          return v;
        } else {
          var value = this.defaultHourValue();
          this.set('startTimeHour', this.setMinute(this.get('startTimeHour'), value));
          return value;
        }
      }
    }),
    timeMinute: computed('startTimeHour', {
      get: function get() {
        return this.fetchTime(this.get('startTimeHour'), "mm", this.get('businessCalendar.minuteOption'));
      },
      set: function set(k, v) {
        if (v) {
          this.set('startTimeHour', this.setHour(this.get('startTimeHour'), v));
          return v;
        } else {
          var value = this.defaultMinuteValue();
          this.set('startTimeHour', this.setHour(this.get('startTimeHour'), value));
          return value;
        }
      }
    }),

    fetchTime: function fetchTime(time, format, options) {
      var hour = Number((0, _moment['default'])(time.replace(':', ''), "hmm").format(format));
      return options[hour];
    },

    setHour: function setHour(time, value) {
      var formatted_time = (0, _moment['default'])(time.replace(':', ''), "hmm").format("HH");
      return (0, _moment['default'])(formatted_time + value, "hmm").format("HH:mm");
    },

    setMinute: function setMinute(time, value) {
      var formatted_time = (0, _moment['default'])(time.replace(':', ''), "hmm").format("mm");
      return (0, _moment['default'])(value + formatted_time, "hmm").format("HH:mm");
    },
    defaultHourValue: function defaultHourValue() {
      var length = this.get('businessCalendar.hoursOption').length;
      return this.get('businessCalendar.hoursOption')[length - 1];
    },
    defaultMinuteValue: function defaultMinuteValue() {
      var length = this.get('businessCalendar.minuteOption').length;
      return this.get('businessCalendar.minuteOption')[length - 1];
    }

  });
});