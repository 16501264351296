define('freshfone-ember/serializers/user', ['exports', 'freshfone-ember/serializers/application', 'ember-data'], function (exports, _freshfoneEmberSerializersApplication, _emberData) {
  var EmbeddedRecordsMixin = _emberData['default'].EmbeddedRecordsMixin;
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(EmbeddedRecordsMixin, {
    attrs: {
      role: { serialize: 'id' },
      ns: { serialize: false },
      abilities: { serialize: false },
      lastCallAt: { serialize: false },
      emailHash: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.data.relationships = json.data.relationships || {};
      json.data.relationships.user_emails = {
        data: [{
          id: null,
          type: 'user_emails',
          attributes: {
            email: json.data.attributes.email,
            primary_email: true
          }
        }]
      };
      json.data.relationships.roles = {
        data: [{
          id: json.data.role,
          type: 'roles'
        }]
      };

      json.source = json.data.attributes.source;

      delete json.data.attributes.source;
      delete json.data.relationships['user-configuration'];
      delete json.data.attributes['export-columns'];
      delete json.data.attributes.email;
      delete json.data.relationships.agent;
      delete json.data.attributes['time-zone-as-tzinfo'];
      delete json.data.attributes['omnichannel-user'];
      delete json.data.attributes['account-creator'];

      return json;
    }
  });
});
/* eslint-disable camelcase */