define('freshfone-ember/components/call-logs/call-log-row/agent-details/component', ['exports', 'freshfone-ember/constants/call-category', 'ember', 'freshfone-ember/constants/avatar-size'], function (exports, _freshfoneEmberConstantsCallCategory, _ember, _freshfoneEmberConstantsAvatarSize) {
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var and = _Ember$computed.and;
  var not = _Ember$computed.not;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    accountName: computed.alias('session.account.name'),
    attendedStatuses: ['in-progress', 'hold', 'completed'],
    isAPICall: computed.equal('call.category', _freshfoneEmberConstantsCallCategory['default'].ApiCall),
    isOutgoingRoot: computed('call.isRoot', 'call.isOutgoing', 'isAPICall', function () {
      return !this.get('isAPICall') && this.get('call.isRoot') && this.get('call.isOutgoing');
    }),

    isAttended: computed('call.status', function () {
      return this.get('attendedStatuses').contains(this.get('call.status'));
    }),
    size: computed(function () {
      return this.get('fromIncoversation') ? _freshfoneEmberConstantsAvatarSize['default'].inconversation : _freshfoneEmberConstantsAvatarSize['default']['default'];
    }),
    hasEntity: computed.bool('call.entityName'),
    childWithAgentOrExternalNumber: computed.and('call.isChild', 'hasEntity'),
    isShowAgentName: not('call.hideAgentName'),
    isAgentAssigned: computed.or('isOutgoingRoot', 'isAttended', 'childWithAgentOrExternalNumber', 'inProgressCall'),
    showAgentDetails: and('isShowAgentName', 'isAgentAssigned'),
    canShowTeam: computed.and('call.isChild', 'call.teamName'),
    callbackCall: computed.equal('call.category', 'callback'),
    showConversation: computed.and('fromCallLogs', 'call.conversation'),
    inConversationAgent: computed.and('fromInConversation', 'call.user.id'),
    hasCallFlow: computed.notEmpty('call.flowName'),

    actions: {
      selectAgent: function selectAgent() {
        if (this.get('inConversationAgent')) {
          this.sendAction('selectAgent', this.get('call.user.agent'));
        }
      },
      toggleCallDetails: function toggleCallDetails() {
        this.toggleProperty('showConversationNotes');
      }
    }
  });
});