define('freshfone-ember/components/call-logs/call-details/call-summary/component', ['exports', 'freshfone-ember/utils/call-constants', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/formatted-duration', 'ember-authority/helpers/has-privilege'], function (exports, _freshfoneEmberUtilsCallConstants, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersFormattedDuration, _emberAuthorityHelpersHasPrivilege) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    account: computed.alias('session.account'),
    canDeleteNote: computed('call.conversation', function () {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_call_conversation',
        user: this.get('currentUser')
      }) && this.get('call.hasNote');
    }),
    showCallRecording: computed('call.hasRecording', 'callSummaryVisible', 'fetchingCallDetails', function () {
      return this.get('call.hasRecording') && this.get('callSummaryVisible') && !this.get('fetchingCallDetails');
    }),

    hasCallTaggingFeature: computed(function () {
      return this.checkFeature('call_tagging');
    }),

    showVoiceMailTranscription: computed.and('hasVoicemailTranscriptionFeature', 'hasVoicemailTranscription'),
    callMetrics: computed('call.id', function () {
      var callMetrics = this.get('call.call_metrics') ? this.get('call.call_metrics') : this.get('call');

      var callDetails = undefined;

      if (callMetrics) {
        callDetails = {
          call_duration: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([this.get('call.total_duration')]),
          wait_time: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('wait_time')]),
          handle_time: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('handle_time')]),
          billing_duration: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([this.get('call.bill_duration')]),
          short_abandon: callMetrics.get('shortAbandoned'),
          cost: this.get('call.total_cost'),
          deducted_cost: this.get('call.deducted_cost')
        };

        if (this.get('account').hasFeature('call_metrics')) {
          var advancedCallDetails = {
            ivr_time: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('ivr_time')]),
            queue_time: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('queue_duration')]),
            ringing_time: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('total_ringing_time')]),
            answering_speed: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('answering_speed')]),
            talk_time: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('talk_time')]),
            hold_time: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('hold_duration')]),
            acw_time: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('call_work_time')])
          };

          Object.assign(callDetails, advancedCallDetails);
        }
        if (this.get('account').hasFeature('time_to_answer')) {
          var timeToAnswer = { time_to_answer: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('time_to_answer')]) };

          Object.assign(callDetails, timeToAnswer);
        }
        if (this.get('account').hasFeature('callback_request')) {
          var timeToCallback = { time_to_callback: (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([callMetrics.get('time_to_callback')]) };

          Object.assign(callDetails, timeToCallback);
        }

        if (this.get('isAgentLeg') && !this.get('call.call_metrics')) {

          callDetails.call_duration = (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([this.get('call.duration')]);
          callDetails.handle_time = (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([this.get('call.duration')]);
          callDetails.billing_duration = (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([this.get('call.duration')]);
          callDetails.talk_time = (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([this.get('call.duration')]);
          callDetails.cost = this.get('call.cost');

          if (this.get('call.isParallelCallLeg')) {
            callDetails.hold_time = (0, _freshfoneEmberHelpersFormattedDuration.formattedDuration)([this.get('call.parallelCallHoldDuration')]);
          }
        }
      }

      return callDetails;
    }),
    hasVoicemailTranscriptionFeature: computed(function () {
      return this.get('session.account').hasFeature('voicemail_transcription');
    }),
    hasVoicemailTranscription: computed('call.transcriptionID', 'call.status', function () {
      var _this = this;

      if (this.get('call.transcriptionID')) {
        this.store.findRecord('transcription', this.get('call.transcriptionID')).then(function (transcription) {
          _this.set('transcriptionContent', transcription.get('content'));
        });

        return this.get('call.status') === _freshfoneEmberUtilsCallConstants['default'].STATUS_REVERSE_HASH[10];
      }
    }),

    actions: {
      downloadRecording: function downloadRecording() {
        window.open(this.get('call.recordingUrl'), '_self');
      },
      deleteRecording: function deleteRecording(resourceType) {
        this.get('toggleDeletionPopup')(resourceType, this.get('call'));
      }
    }

  });
});
/* eslint-disable camelcase */