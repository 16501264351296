define('freshfone-ember/components/confirm-delete-modal/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    isCheckbox: computed.notEmpty('checkMessage'),
    actions: {
      close: function close() {
        this.sendAction('close');
      },
      'delete': function _delete() {
        this.sendAction('delete', this.get('modelItem'), { 'deleteResources': this.get('deleteResources') });
      }
    }
  });
});