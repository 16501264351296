define('freshfone-ember/components/admin-component/component', ['exports', 'ember', 'ember-features/mixins/feature-route-mixin'], function (exports, _ember, _emberFeaturesMixinsFeatureRouteMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_emberFeaturesMixinsFeatureRouteMixin['default'], {

    session: service(),
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    isOmnichannelEnabled: computed.alias('currentAccount.isOmnichannelEnabled'),

    hasTeamFeature: computed('session', function () {
      return this.get('session.account').hasFeature('team');
    }),
    hasSLMFeature: computed('session', function () {
      return this.get('session.account').hasFeature('service_level_monitoring');
    }),
    hasOmnichannelAgentStatusFeature: computed('session', function () {
      return this.get('session.account').hasFeature('omnichannel_agent_status') && this.get('isOmnichannelEnabled');
    })
  });
});