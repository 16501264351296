define("freshfone-ember/components/common/promotion-wrapper/promotion-content/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 11,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/common/promotion-wrapper/promotion-content/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "btn btn-primary");
            dom.setAttribute(el1, "target", "_blank");
            dom.setAttribute(el1, "rel", "noopener");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'href');
            morphs[1] = dom.createAttrMorph(element2, 'data-analytics-action-name');
            morphs[2] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["attribute", "href", ["get", "externalLink", ["loc", [null, [8, 14], [8, 26]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-analytics-action-name", ["get", "trackEvent", ["loc", [null, [8, 113], [8, 123]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["promotionWrapper.buttonText"], [], ["loc", [null, [9, 6], [9, 41]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 2
                },
                "end": {
                  "line": 15,
                  "column": 2
                }
              },
              "moduleName": "freshfone-ember/components/common/promotion-wrapper/promotion-content/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "btn btn-primary");
              dom.setAttribute(el1, "target", "_blank");
              dom.setAttribute(el1, "rel", "noopener");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'href');
              morphs[1] = dom.createAttrMorph(element1, 'data-analytics-action-name');
              morphs[2] = dom.createMorphAt(element1, 1, 1);
              return morphs;
            },
            statements: [["attribute", "href", ["get", "ubxBillingUrl", ["loc", [null, [12, 14], [12, 27]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-analytics-action-name", ["get", "trackEvent", ["loc", [null, [12, 114], [12, 124]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["promotionWrapper.buttonText"], [], ["loc", [null, [13, 6], [13, 41]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 6
                  },
                  "end": {
                    "line": 19,
                    "column": 6
                  }
                },
                "moduleName": "freshfone-ember/components/common/promotion-wrapper/promotion-content/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["promotionWrapper.buttonText"], [], ["loc", [null, [18, 8], [18, 43]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 2
                },
                "end": {
                  "line": 21,
                  "column": 2
                }
              },
              "moduleName": "freshfone-ember/components/common/promotion-wrapper/promotion-content/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-primary");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n  ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'data-analytics-action-name');
              morphs[1] = dom.createMorphAt(element0, 1, 1);
              return morphs;
            },
            statements: [["attribute", "data-analytics-action-name", ["get", "trackEvent", ["loc", [null, [16, 65], [16, 75]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", ["protected.admin.billing"], [], 0, null, ["loc", [null, [17, 6], [19, 18]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/common/promotion-wrapper/promotion-content/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "session.account.isUnifiedAppAccount", ["loc", [null, [11, 12], [11, 47]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [11, 2], [21, 2]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/common/promotion-wrapper/promotion-content/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "externalLink", ["loc", [null, [7, 8], [7, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [7, 2], [21, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 22
          }
        },
        "moduleName": "freshfone-ember/components/common/promotion-wrapper/promotion-content/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "empty-state-cover", [], ["imgSrc", ["subexpr", "@mut", [["get", "promotionImageURL", ["loc", [null, [2, 9], [2, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "description", ["subexpr", "t", [["subexpr", "concat", ["features.", ["get", "featureSymbol", ["loc", [null, [3, 37], [3, 50]]], 0, 0, 0, 0], ".promotionContent.benefit"], [], ["loc", [null, [3, 17], [3, 79]]], 0, 0]], [], ["loc", [null, [3, 14], [3, 80]]], 0, 0], "containerClass", "flex-row", "title", ["subexpr", "t", [["subexpr", "concat", ["features.", ["get", "featureSymbol", ["loc", [null, [5, 31], [5, 44]]], 0, 0, 0, 0], ".promotionContent.title"], [], ["loc", [null, [5, 11], [5, 71]]], 0, 0]], [], ["loc", [null, [5, 8], [5, 72]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [22, 22]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});