define("freshfone-ember/components/contact-form/phone-number/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/contact-form/phone-number/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "icon-locked");
          dom.setAttribute(el1, "data-toggle", "tooltip");
          dom.setAttribute(el1, "data-placement", "top");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element2, 'title');
          return morphs;
        },
        statements: [["attribute", "title", ["subexpr", "t", ["contact.contact_form.ongoing_call_edit_warning"], [], ["loc", [null, [null, null], [8, 109]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 4
              },
              "end": {
                "line": 13,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/contact-form/phone-number/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "icon-call-hollow");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["clickToCall", ["get", "contactNumber.phone_number", ["loc", [null, [12, 60], [12, 86]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 37], [12, 88]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 2
            },
            "end": {
              "line": 14,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/contact-form/phone-number/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "icon-add as-close");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["element", "action", ["deleteNumber", ["get", "contactNumber", ["loc", [null, [10, 57], [10, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 33], [10, 72]]], 0, 0], ["block", "if", [["get", "showCallIcon", ["loc", [null, [11, 10], [11, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 4], [13, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/contact-form/phone-number/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "phone-number");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(element3, 3, 3);
        return morphs;
      },
      statements: [["inline", "input", [], ["class", "phone-number-field", "value", ["subexpr", "@mut", [["get", "contactNumber.phone_number", ["loc", [null, [3, 10], [3, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "readonly", ["subexpr", "and", [["subexpr", "eq", [["get", "contactNumber.phone_number", ["loc", [null, [4, 22], [4, 48]]], 0, 0, 0, 0], ["get", "callerNumber", ["loc", [null, [4, 49], [4, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 18], [4, 62]]], 0, 0], ["get", "callerNumber", ["loc", [null, [4, 63], [4, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 13], [4, 76]]], 0, 0], "placeholder", ["subexpr", "t", ["contact.contact_form.placeholder"], [], ["loc", [null, [5, 16], [5, 54]]], 0, 0]], ["loc", [null, [2, 2], [6, 4]]], 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "eq", [["get", "contactNumber.phone_number", ["loc", [null, [7, 17], [7, 43]]], 0, 0, 0, 0], ["get", "callerNumber", ["loc", [null, [7, 44], [7, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 13], [7, 57]]], 0, 0], ["get", "callerNumber", ["loc", [null, [7, 58], [7, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 8], [7, 71]]], 0, 0]], [], 0, 1, ["loc", [null, [7, 2], [14, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});