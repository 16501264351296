define("freshfone-ember/components/call-flow/call-flows-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 7
                },
                "end": {
                  "line": 17,
                  "column": 7
                }
              },
              "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              return morphs;
            },
            statements: [["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["call_flow.name"], [], ["loc", [null, [14, 36], [14, 56]]], 0, 0], "class", "col-name-header"], ["loc", [null, [14, 8], [14, 82]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["call_flow.type"], [], ["loc", [null, [15, 36], [15, 56]]], 0, 0], "class", "col-type-header"], ["loc", [null, [15, 8], [15, 82]]], 0, 0], ["inline", "ui-list-header-item", [], ["class", "list-header-element col-icons-header"], ["loc", [null, [16, 8], [16, 76]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 7
                },
                "end": {
                  "line": 22,
                  "column": 7
                }
              },
              "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "loading-wrapper");
              var el2 = dom.createTextNode("\n           ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "spinner");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 7
                  },
                  "end": {
                    "line": 28,
                    "column": 7
                  }
                },
                "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "empty-state-cover", [], ["imgSrc", "assets/images/empty-states/no-result.svg", "description", ["subexpr", "if", [["get", "hasSearchText", ["loc", [null, [25, 28], [25, 41]]], 0, 0, 0, 0], ["subexpr", "t", ["call_flow.none"], [], ["loc", [null, [25, 42], [25, 62]]], 0, 0], ["subexpr", "t", ["call_flow.yet_to_create"], [], ["loc", [null, [25, 63], [25, 92]]], 0, 0]], [], ["loc", [null, [25, 24], [25, 93]]], 0, 0], "containerClass", "flex-col"], ["loc", [null, [23, 10], [27, 12]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 34,
                          "column": 17
                        },
                        "end": {
                          "line": 36,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                  ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["content", "callFlow.name", ["loc", [null, [35, 18], [35, 35]]], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 43,
                          "column": 17
                        },
                        "end": {
                          "line": 45,
                          "column": 17
                        }
                      },
                      "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                   ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1, "class", "icon-edit option-icon");
                      dom.setAttribute(el1, "data-analytics-action-name", "Edit Call Flow");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                var child2 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 46,
                          "column": 17
                        },
                        "end": {
                          "line": 48,
                          "column": 17
                        }
                      },
                      "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1, "class", "icon-delete option-icon");
                      dom.setAttribute(el1, "data-analytics-action-name", "Delete Call Flow");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element0 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createElementMorph(element0);
                      return morphs;
                    },
                    statements: [["element", "action", ["toggleDelete", ["get", "callFlow", ["loc", [null, [47, 79], [47, 87]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 55], [47, 89]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child3 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 50,
                          "column": 15
                        },
                        "end": {
                          "line": 56,
                          "column": 16
                        }
                      },
                      "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "component", ["custom-elements/delete-model"], ["model", ["subexpr", "@mut", [["get", "callFlow", ["loc", [null, [52, 24], [52, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "child", ["subexpr", "@mut", [["get", "callFlow.child", ["loc", [null, [53, 24], [53, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "onModify", ["subexpr", "action", ["onModify"], [], ["loc", [null, [54, 27], [54, 46]]], 0, 0]], ["loc", [null, [51, 16], [55, 18]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 30,
                        "column": 11
                      },
                      "end": {
                        "line": 60,
                        "column": 11
                      }
                    },
                    "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("             ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("tr");
                    dom.setAttribute(el1, "class", "record");
                    var el2 = dom.createTextNode("\n               ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2, "class", "col-name ellipsis");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("              ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n               ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2, "class", "col-type");
                    var el3 = dom.createTextNode("\n                 ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n              ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("td");
                    dom.setAttribute(el2, "class", "col-icons");
                    var el3 = dom.createTextNode("\n               ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    var el4 = dom.createTextNode("\n");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("               ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("              ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n             ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n             ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("hr");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var element2 = dom.childAt(element1, [5]);
                    var element3 = dom.childAt(element2, [1]);
                    var morphs = new Array(6);
                    morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
                    morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
                    morphs[2] = dom.createAttrMorph(element3, 'class');
                    morphs[3] = dom.createMorphAt(element3, 1, 1);
                    morphs[4] = dom.createMorphAt(element3, 2, 2);
                    morphs[5] = dom.createMorphAt(element2, 3, 3);
                    return morphs;
                  },
                  statements: [["block", "link-to", [["get", "callFlow.redirectionPath", ["loc", [null, [34, 28], [34, 52]]], 0, 0, 0, 0], ["get", "callFlow.id", ["loc", [null, [34, 53], [34, 64]]], 0, 0, 0, 0]], ["class", "name"], 0, null, ["loc", [null, [34, 17], [36, 28]]]], ["content", "callFlow.description", ["loc", [null, [39, 17], [39, 41]]], 0, 0, 0, 0], ["attribute", "class", ["concat", ["option-buttons ", ["subexpr", "if", [["get", "callFlow.isDeleting", ["loc", [null, [42, 47], [42, 66]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [42, 42], [42, 75]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", [["get", "callFlow.redirectionPath", ["loc", [null, [43, 28], [43, 52]]], 0, 0, 0, 0], ["get", "callFlow.id", ["loc", [null, [43, 53], [43, 64]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [43, 17], [45, 29]]]], ["block", "unless", [["get", "callFlow.default", ["loc", [null, [46, 27], [46, 43]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [46, 17], [48, 28]]]], ["block", "if", [["get", "callFlow.isDeleting", ["loc", [null, [50, 21], [50, 40]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [50, 15], [56, 23]]]]],
                  locals: [],
                  templates: [child0, child1, child2, child3]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 29,
                      "column": 9
                    },
                    "end": {
                      "line": 61,
                      "column": 9
                    }
                  },
                  "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "unless", [["get", "callFlow.isNew", ["loc", [null, [30, 21], [30, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [30, 11], [60, 22]]]]],
                locals: ["callFlow"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 7
                  },
                  "end": {
                    "line": 62,
                    "column": 7
                  }
                },
                "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("       ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "ui-list-item", [], ["class", "list-body", "items", ["subexpr", "@mut", [["get", "sortedFlowList", ["loc", [null, [29, 49], [29, 63]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [29, 9], [61, 26]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 7
                },
                "end": {
                  "line": 62,
                  "column": 7
                }
              },
              "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "hasEmptyList", ["loc", [null, [22, 17], [22, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [22, 7], [62, 7]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 4
              },
              "end": {
                "line": 63,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ui-list-header", [], ["class", "list-header"], 0, null, ["loc", [null, [13, 7], [17, 26]]]], ["block", "if", [["get", "listLoading", ["loc", [null, [18, 13], [18, 24]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [18, 7], [62, 14]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 64,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ui-list", [], ["class", "list-container has-stretchable-rows"], 0, null, ["loc", [null, [12, 4], [63, 16]]]]],
        locals: ["listLoading", "hasEmptyList"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 66,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/call-flow/call-flows-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "admin-main-content-list");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        return morphs;
      },
      statements: [["block", "paginate-search", [], ["title", ["subexpr", "t", ["navigation.call_workflows"], [], ["loc", [null, [4, 10], [4, 41]]], 0, 0], "supportPortalLink", ["subexpr", "support-portal-link", ["introductionToCallFlows"], [], ["loc", [null, [5, 22], [5, 69]]], 0, 0], "modelName", "callFlow", "placeholder", ["subexpr", "t", ["common.search"], [], ["loc", [null, [7, 16], [7, 35]]], 0, 0], "searchstring", ["subexpr", "@mut", [["get", "searchString", ["loc", [null, [8, 17], [8, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [9, 10], [9, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "modifyModel", ["subexpr", "@mut", [["get", "modifyModel", ["loc", [null, [10, 16], [10, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [3, 2], [64, 22]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});