define('freshfone-ember/components/integrations/create-ticket/component', ['exports', 'ember', 'freshfone-ember/utils/timezone-constants'], function (exports, _ember, _freshfoneEmberUtilsTimezoneConstants) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = Component.extend({
        isSaving: false,
        session: service(),
        currentUser: computed.alias('session.user'),
        userTimezone: computed('currentUser.timeZone', function () {
            var timezone = this.get('currentUser.timeZone') || this.get('session.account.timeZone');
            if (timezone) {
                timezone = _freshfoneEmberUtilsTimezoneConstants['default'].timezones.filterBy('id', timezone)[0];
                return timezone.displayName;
            }
            return;
        }),
        callerNumber: computed('call', function () {
            var call = this.get('call');
            return call.get('from') ? call.get('from') : call.get('to');
        }),
        requesterName: computed.or('externalContact.name', 'callerNumber'),
        submitLabel: _ember['default'].computed('isSaving', function () {
            return this.get('isSaving') ? _ember['default'].i18n.t('freshdesk_actions.saving') : _ember['default'].i18n.t('freshdesk_actions.create_ticket');
        }),
        callStatusString: computed('call', function () {
            var call = this.get('call');
            return call.get('status').replace(/-/g, "");
        }),
        subject: computed('call', 'callerNumber', 'callStatusString', 'userTimezone', function () {
            var call = this.get('call');
            var callStatus = call.get('status');
            var callDate = moment(call.get('created_at')).format('ddd, MMM DD');
            var callTime = moment(call.get('created_at')).format('hh:mm:ss A') + ' ' + this.get('userTimezone');
            var callType = call.get('direction');
            var capitalizeCallType = callType ? _ember['default'].String.capitalize(callType) : '';

            if (callStatus === 'no-answer') {
                return _ember['default'].i18n.t('integration.freshdesk.' + callType + '_' + this.get('callStatusString') + '_title', {
                    type: capitalizeCallType,
                    customer: this.get('requesterName'),
                    date: callDate,
                    time: callTime
                });
            } else if (_ember['default'].i18n.exists('integration.freshdesk.' + this.get('callStatusString') + '_title')) {
                return _ember['default'].i18n.t('integration.freshdesk.' + this.get('callStatusString') + '_title', {
                    type: capitalizeCallType,
                    customer: this.get('requesterName'),
                    date: callDate,
                    time: callTime
                });
            }
        }),
        actions: {
            closeCreateTicket: function closeCreateTicket() {
                this.sendAction('closeCallback');
            },
            setSubjectField: function setSubjectField(value) {
                this.set('subject', value);
            },
            submitCreateTicekt: function submitCreateTicekt() {
                this.set('isSaving', true);
                this.sendAction('createCallback', { "subject": this.get('subject') });
            }
        }
    });
});
/* eslint-disable ember-suave/no-direct-property-access */