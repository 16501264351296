define('freshfone-ember/components/custom-elements/vertical-infinite-scroll/component', ['exports', 'ember'], function (exports, _ember) {

  var TOP_EPSILON = 100;
  var BOTTOM_EPSILON = 150;

  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    action: 'fetchMore',
    topEpsilon: TOP_EPSILON,
    bottomEpsilon: BOTTOM_EPSILON,
    isFetching: false,
    hasMore: null,
    content: null,
    scrollable: null,
    scrollableTo: null,
    isScrollToWindow: true,
    direction: 'bottom',
    centerLoader: false,

    didInsertElement: function didInsertElement() {
      var scrollable = get(this, 'scrollable');
      var scrollableTo = undefined;
      if (scrollable) {
        set(this, 'isScrollToWindow', false);
        scrollableTo = document.querySelector(scrollable);
      } else {
        scrollableTo = window;
      }

      set(this, 'scrollableTo', scrollableTo);
      scrollableTo.addEventListener('scroll', run.bind(this, this.didScroll));
    },

    willDestroyElement: function willDestroyElement() {
      var scrollableTo = get(this, 'scrollableTo');
      scrollableTo.removeEventListener('scroll', run.bind(this, this.didScroll));
    },

    didScroll: function didScroll() {
      if (!get(this, 'isFetching') && get(this, 'hasMore') && this.calculateEpsilon()) {
        this.safeSet('isFetching', true);
        this.sendAction('action', run.bind(this, this.handleFetch), get(this, 'direction'));
      }
    },

    handleFetch: function handleFetch(newContent) {
      var action = get(this, 'direction') === 'top' ? 'unshiftObjects' : 'pushObjects';
      var content = get(this, 'content');
      this.safeSet('isFetching', false);
      if (content && newContent) {
        content[action](newContent);
      }
    },

    calculateEpsilon: function calculateEpsilon() {
      return get(this, 'direction') === 'top' ? this.isNearTop() : this.isNearBottom();
    },

    getScrollSize: function getScrollSize() {
      var scrollableTo = get(this, 'scrollableTo');
      var viewPortTop = undefined,
          bottomTop = undefined;

      if (get(this, 'isScrollToWindow')) {
        viewPortTop = document.documentElement.scrollTop || window.pageYOffset, bottomTop = document.documentElement.scrollHeight - window.innerHeight;
      } else {
        viewPortTop = scrollableTo.scrollTop, bottomTop = scrollableTo.scrollHeight - scrollableTo.clientHeight;
      }
      return { viewPortTop: viewPortTop, bottomTop: bottomTop };
    },

    isNearTop: function isNearTop() {
      var _getScrollSize = this.getScrollSize();

      var viewPortTop = _getScrollSize.viewPortTop;

      return viewPortTop < get(this, 'topEpsilon');
    },

    isNearBottom: function isNearBottom() {
      var _getScrollSize2 = this.getScrollSize();

      var viewPortTop = _getScrollSize2.viewPortTop;
      var bottomTop = _getScrollSize2.bottomTop;

      return viewPortTop && bottomTop - viewPortTop < get(this, 'bottomEpsilon');
    },

    safeSet: function safeSet(key, value) {
      if (!this.isDestroyed && !this.isDestroying) {
        set(this, key, value);
      }
    }
  });
});