define('freshfone-ember/components/number-component/numbers-list/number-info/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    tagName: '',

    actions: {
      edit: function edit(item) {
        this.get('edit')(item);
      }
    }
  });
});