define('freshfone-ember/components/call-logs/network-log/warning-details/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    i18n: service(),
    warnings: computed('i18n', function () {
      return [{
        name: 'High RTT',
        description: this.get('i18n').t('call_log_v1.network_log.high_rtt_desc')
      }, {
        name: 'Low MoS',
        description: this.get('i18n').t('call_log_v1.network_log.low_mos_desc')
      }, {
        name: 'High Jitter',
        description: this.get('i18n').t('call_log_v1.network_log.high_jitter_desc')
      }, {
        name: 'High Packet Loss',
        description: this.get('i18n').t('call_log_v1.network_log.high_packet_loss_desc')
      }, {
        name: 'Constant Audio Input Level',
        description: this.get('i18n').t('call_log_v1.network_log.constant_audio_input_level_desc')
      }, {
        name: 'Constant Audio Output Level',
        description: this.get('i18n').t('call_log_v1.network_log.constant_audio_output_level_desc')
      }];
    }),
    actions: {
      closeWarningModal: function closeWarningModal() {
        this.sendAction('closeModal');
      }
    }
  });
});
/* eslint-disable camelcase */