define('freshfone-ember/models/integration-action', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;

  var ACTION_TYPE = {
    'api': 1,
    'ui': 2,
    'search': 3
  };

  exports['default'] = Model.extend({
    integrationId: attr(),
    actionLabel: attr(),
    tLabel: attr(),
    actionType: attr(),
    actionParams: attr(),
    actionTriggerEvent: attr(),
    action: attr(),
    config: attr(),
    searchFields: attr(),
    searchByLabel: attr(),
    freshcallerUiEnabled: attr(),
    redirectionUrl: attr(),
    integration: belongsTo('integration'),
    isApiAction: computed.equal('actionType', ACTION_TYPE['api']),
    isSearchAction: computed.equal('actionType', ACTION_TYPE['search'])
  });
});