define('freshfone-ember/routes/protected/admin/numbers', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    titleToken: computed(function () {
      return _ember['default'].i18n.t('numbers.title').string;
    }),
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('admin_components.' + (this.get('session.account.isUnifiedAppAccount') ? 'Numbers_unified_app' : 'Numbers')).string };
    }),
    features: ['call_masking'],
    queryParams: {
      editNumber: { refreshModel: true },
      showBuy: {},
      callFlowID: {},
      actionFor: {}
    },

    beforeModel: function beforeModel() {
      this.checkPrivilege('view_numbers');
    },
    model: function model(params) {
      this.setProperties({
        editNumber: params.editNumber,
        showBuy: params.showBuy,
        callFlowID: params.callFlow,
        actionFor: params.actionFor
      });

      this.store.unloadAll('rule');

      return _ember['default'].RSVP.hash({
        numbers: this.store.query('number', {}),
        rules: this.fetchRules()
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          showBuy: undefined,
          editNumber: undefined,
          callFlowID: undefined,
          actionFor: undefined
        });
      }
    },

    fetchRules: function fetchRules() {
      var _this = this;

      return new Promise(function (resolve) {
        var promises = [_this.store.findAll('callFlow'), _this.store.findAll('message')];

        if (_this.checkFeature('business_hours')) {
          promises.push(_this.store.findAll('businessCalendar'));
        }
        Promise.all(promises).then(function () {
          return resolve(_this.store.findAll('rule'));
        });
      });
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */