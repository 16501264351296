define('freshfone-ember/routes/protected/live', ['exports', 'freshfone-ember/utils/call-constants', 'ember', 'freshfone-ember/routes/protected', 'freshfone-ember/mixins/normalized-json-object', 'freshfone-ember/mixins/event-bind-mixin', 'freshfone-ember/helpers/support-portal-link'], function (exports, _freshfoneEmberUtilsCallConstants, _ember, _freshfoneEmberRoutesProtected, _freshfoneEmberMixinsNormalizedJsonObject, _freshfoneEmberMixinsEventBindMixin, _freshfoneEmberHelpersSupportPortalLink) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsEventBindMixin['default'], _freshfoneEmberMixinsNormalizedJsonObject['default'], {
    telephony: service(),
    features: ['call_monitoring', 'call_barging', 'real_time_dashboard'],
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.LiveDashboard');
    }),
    breadCrumb: computed(function () {
      return {
        title: _ember['default'].i18n.t('navigation.LiveDashboard'),
        supportPortalLink: (0, _freshfoneEmberHelpersSupportPortalLink.supportPortalLink)(['viewingCallersInQueue'])
      };
    }),
    hasAgentStatusFeature: function hasAgentStatusFeature() {
      return this.get('session.account').hasFeature('custom_agent_status') && this.get('session.account.globalFlags').indexOf('custom_agent_status') > -1;
    },

    hasAgentViewLishDashBoardView: function hasAgentViewLishDashBoardView() {
      return this.get('session.account').hasGlobalFlag('enhanced_live_dashboard') && this.get('session.account').hasFeature('agent_restricted_live_dashboard');
    },
    hasEnhancedAgentViewLishDashBoardView: function hasEnhancedAgentViewLishDashBoardView() {
      return this.get('session.account').hasGlobalFlag('live_agents_enhanced_global') && this.get('session.account').hasFeature('paginated_live_dashboard');
    },

    beforeModel: function beforeModel() {
      this.store.findAll('agent-status');
      if (this.hasAgentViewLishDashBoardView() && this.get('session.current.user.isAgent')) {
        this.checkPrivilege('view_enhanced_live_dashboard');
      } else {
        this.checkPrivilege('view_live_dashboard');
      }

      this.checkFeature('real_time_dashboard');
      this.bindEvent(this.get('telephony'), 'reconnect', this, function (entity) {
        entity.model();
      });
    },
    groupQueues: function groupQueues(queues) {
      var groupedQueues = {},
          otherQueues = [];

      queues.forEach(function (queue) {
        if (queue.teamName) {
          (groupedQueues[queue.teamName] = groupedQueues[queue.teamName] || []).push(queue);
        } else {
          otherQueues.push(queue);
        }
      });

      groupedQueues['Other Queues'] = otherQueues;

      return groupedQueues;
    },
    fetchAgentsList: function fetchAgentsList() {
      var _this = this;

      return new Promise(function (resolve) {
        if (!_this.hasEnhancedAgentViewLishDashBoardView()) {
          $.getJSON('/agents/status_list?version=v1').then(function (agentsResponse) {
            _this.store.unloadAll('live-agent-status');
            run(function () {
              _this.set('liveAgentMeta', agentsResponse.meta);
              _this.store.pushPayload('live-agent-status', agentsResponse);
              resolve();
            });
          });
        } else {
          resolve();
        }
      });
    },
    model: function model() {
      var _this2 = this;

      return $.getJSON('/call_queues/list').then(function (callQueuesList) {
        var queues = callQueuesList.data.map(function (ele) {
          return {
            id: ele.id,
            name: ele.attributes.name,
            teamName: ele.attributes['team-name']
          };
        }),

        // eslint-disable-next-line sort-vars
        groupedQueues = _this2.groupQueues(queues),

        // eslint-disable-next-line sort-vars
        queueOptions = [];

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.entries(groupedQueues)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _step$value = _slicedToArray(_step.value, 2);

            var groupName = _step$value[0];
            var options = _step$value[1];

            queueOptions.push({
              groupName: groupName,
              options: options
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        _this2.set('queueOptions', queueOptions);

        return _this2.fetchAgentsList().then(function () {
          return $.getJSON('/call_queues/selected_queues').then(function (queuesSelected) {
            var selectedQueues = queuesSelected.data,
                selectedQueuesOptions = queues.filter(function (queue) {
              return selectedQueues.filter(function (selectedQueue) {
                return selectedQueue.id === queue.id;
              }).length;
            });

            return $.getJSON('/calls/filter_interval').then(function (filterIntervals) {
              _this2.set('intervalOption', filterIntervals.data.value);
              _this2.set('selectedQueues', selectedQueuesOptions);
              _this2.store.peekAll('call').filter(function (call) {
                return ['queued', 'queued-ringing', 'in-progress', 'on-hold'].indexOf(call.get('status')) > -1;
              }).forEach(function (call) {
                return call.unloadRecord();
              });

              return _this2.store.query('call', {
                status: [_freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.queued.id, _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH['queued-ringing'].id, _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH['on-hold'].id, _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.bot_inprogress.id, _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH['in-progress'].id],
                limit: 300,
                ancestry: true,
                live_calls: true
              }).then(function () {
                return {
                  liveAgentMeta: _this2.get('liveAgentMeta'),
                  queueOptions: _this2.get('queueOptions'),
                  selectedQueues: _this2.get('selectedQueues'),
                  intervalOption: _this2.get('intervalOption')
                };
              });
            });
          });
        });
      });
    }
  });
});
/* eslint-disable camelcase */