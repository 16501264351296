define('freshfone-ember/components/ongoing-widget/search-contact/component', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    telephony: service(),
    currentCall: computed.alias('telephony.currentCall'),
    isNewCaller: computed.alias('currentCall.isNewCaller'),
    classNameBindings: ['isNewCaller:new-caller', 'shouldHide:hide'],
    hasValidContact: computed.not('hasNewContact'),
    hasNewContact: computed.or('currentCall.hasEmptyContactName', 'isNewCaller'),

    toggleSearch: false,

    shouldHide: computed('hasValidContact', 'toggleSearch', function () {
      return this.get('hasValidContact') && !this.get('toggleSearch');
    }),

    actions: {
      createNewContact: function createNewContact() {
        var newContact = this.store.createRecord('contact', { phone: this.get('currentCall.callerNumber') });
        this.set('currentCall.contact', newContact);
        this.set('toggleSearch', false);
      },

      selectedContact: function selectedContact(contact) {
        this.set('currentCall.contact', contact);
        this.set('toggleSearch', false);
        this.set('mergeState', true);
      }
    }

  });
});