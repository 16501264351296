define('freshfone-ember/components/billing-base/credit-actions/credit-autorecharge/component', ['exports', 'freshfone-ember/mixins/currency-mixin', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember'], function (exports, _freshfoneEmberMixinsCurrencyMixin, _emberRlDropdownMixinsRlDropdownComponent, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_emberRlDropdownMixinsRlDropdownComponent['default'], _freshfoneEmberMixinsCurrencyMixin['default'], {
    AutoRechargeError: null,
    purchaseFlag: false,
    dropdownExpanded: false,
    purchased: false,
    isAccountBlocked: computed.reads('session.account.blocked'),
    autoRechargeOff: computed('rechargeAmount', function () {
      if (this.get('rechargeAmount') === 'Off') {
        return true;
      }

      return false;
    }),
    rechargeAmountUSD: computed('rechargeAmount', 'subscription', function () {
      return typeof this.get('rechargeAmount') === 'string' ? 0 : Math.round(this.get('rechargeAmount') / this.get('currencyConversion')[this.get('subscription.currency')] * 100) / 100;
    }),
    classNames: ['credit-action-autorecharge'],
    classNameBindings: ['purchased:flash'],
    setRechargeAmount: function setRechargeAmount(creditValue) {
      this.resetPurchaseAction();
      if (creditValue === 'Off') {
        this.disableAutoRecharge();
      } else {
        this.enableAutoRecharge(creditValue);
      }
    },
    disableAutoRecharge: function disableAutoRecharge() {
      var _this = this;

      $.ajax({
        url: '/credits/disable_auto_recharge',
        type: 'POST',
        dataType: 'json'
      }).done(function (response) {
        run(function () {
          _this.setPurchaseState(response);
          _this.set('rechargeAmount', _this.get('credits.disableAutoRechargeAmount'));
        });
      }).fail(function (error) {
        return run(function () {
          return _this.setAutoRechargeErrors(error);
        });
      });
    },
    enableAutoRecharge: function enableAutoRecharge(creditValue) {
      var _this2 = this;

      $.ajax({
        url: '/credits/enable_auto_recharge',
        type: 'POST',
        dataType: 'json',
        data: { credit: creditValue }
      }).done(function (response) {
        run(function () {
          _this2.setPurchaseState(response);
          _this2.set('rechargeAmount', response.data.attributes['recharge-quantity']);
        });
      }).fail(function (error) {
        return run(function () {
          return _this2.setAutoRechargeErrors(error);
        });
      });
    },
    setAutoRechargeErrors: function setAutoRechargeErrors(error) {
      this.set('AutoRechargeError', JSON.parse(error.responseText).error);
      this.resetDropdown();
    },
    setPurchaseState: function setPurchaseState(response) {
      this.get('credits').set('autoRecharge', response.data.attributes['auto-recharge']);
      this.set('purchased', true);
      this.resetDropdown();
    },
    resetPurchaseAction: function resetPurchaseAction() {
      this.set('setAutoRecharge', true);
      this.set('AutoRechargeError', null);
      this.set('purchased', false);
    },
    resetDropdown: function resetDropdown() {
      this.set('dropdownExpanded', false);
      this.set('setAutoRecharge', false);
    },
    toggleDropdown: function toggleDropdown() {
      this.toggleProperty('dropdownExpanded');
      this.set('AutoRechargeError', null);
    },
    actions: {
      selectAutoRechargeAmount: function selectAutoRechargeAmount() {
        if (this.get('isAccountBlocked')) {
          return;
        }
        if (this.get('setAutoRecharge')) {
          return;
        }
        this.toggleDropdown();
      },
      setAutoRecharge: function setAutoRecharge(creditValue) {
        if (this.get('isAccountBlocked')) {
          return;
        }
        if (this.get('setAutoRecharge')) {
          return;
        }
        this.setRechargeAmount(creditValue);
      }
    }
  });
});