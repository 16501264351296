define('freshfone-ember/components/conversation/recent-conversations-list/conversations/call-tags/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var observer = _ember['default'].observer;

  var MAX_LIMIT = 5;

  exports['default'] = _ember['default'].Component.extend({
    selectedTagsCount: computed.alias('currentCall.tags.length'),
    hasReachedMaxLimit: computed.gte('selectedTagsCount', MAX_LIMIT),

    init: function init() {
      this._super.apply(this, arguments);
      this.getTags();
    },

    isTagsTransferred: observer('currentCall.tagIds.[]', 'tags.[]', function () {
      var _this = this;

      if (isEmpty(this.get('currentCall.tags')) && this.get('currentCall.tagIds.length') && this.get('tags.length')) {
        this.set('currentCall.tags', this.get('tags').filter(function (tag) {
          return _this.get('currentCall.tagIds').includes(tag.id);
        }));
      }
    }),

    getTags: function getTags() {
      var _this2 = this;

      this.store.query('tag', { paginate: false }).then(function (tags) {
        _this2.set('tags', tags.filterBy('disabled', false));
      });
    },

    noOptionsLeft: computed('tags', 'selectedTagsCount', function () {
      return this.get('selectedTagsCount') && this.get('selectedTagsCount') === this.get('tags.length');
    }),

    actions: {
      showInput: function showInput() {
        this.setProperties({
          'showInput': true
        });
      },
      setSelectedTag: function setSelectedTag(values) {
        this.setProperties({
          'noCallTagsAdded': false,
          'showInput': false,
          'currentCall.tags': values
        });
        this.$('.ember-power-select-trigger-multiple-input').focus().attr("placeholder", _ember['default'].i18n.t("ongoing_widget.type_here"));
      },
      calculatePosition: function calculatePosition(trigger) {
        var left = this.$(trigger).find('input')[0].offsetLeft;
        var style = {
          left: left
        };
        return { style: style };
      }
    }
  });
});