define('freshfone-ember/components/contact-display-card/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var A = _ember['default'].A;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    init: function init() {
      this._super.apply(this, arguments);
    },
    isExternalContact: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    phoneNumbers: computed(function () {
      var numbers = A();

      if (this.get('contact.phone_number')) {
        // eslint-disable-next-line camelcase
        numbers.push({ phone_number: this.get('contact.phone_number') });
      }

      if (this.get('contact.mobile')) {
        // eslint-disable-next-line camelcase
        numbers.push({ phone_number: this.get('contact.mobile') });
      }

      return numbers;
    }),
    actions: {
      mergeContact: function mergeContact() {
        this.sendAction('mergeContact', this.get('contact'));
      },

      toggleContactAction: function toggleContactAction() {
        this.sendAction('toggleContactAction', 'Edit');
      }
    }
  });
});