define('freshfone-ember/components/deflection-bot/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/remove-feature-actiontype', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/models/deflection-bot'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsRemoveFeatureActiontype, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberModelsDeflectionBot) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    routing: service('-routing'),
    showLoader: computed('callFlow.deflectionBot.isFulfilled', function () {
      return !this.get('callFlow.deflectionBot.isFulfilled');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var defaultActionTypes = this.get('callFlow.deflectionBot.actionOptions');

      if (!this.hasFeatures(['voicemail_and_status_fallback'])) {
        var filteredActionTypes = this.removeObjFromArray(defaultActionTypes, 'name', 'vm');

        this.set('callFlow.deflectionBot.actionOptions', filteredActionTypes);
      }
      if (!this.hasFeatures(['ivr'])) {
        var filteredActionTypes = this.removeObjFromArray(defaultActionTypes, 'name', 'ivr');

        this.set('callFlow.deflectionBot.actionOptions', filteredActionTypes);
      }
    },
    saveCallFlow: function saveCallFlow() {
      var _this = this;

      this.set('callFlow.name', this.get('callFlow.deflectionBot.name'));
      this.get('callFlow').save().then(function (callFlow) {
        var id = callFlow.id;

        callFlow.get('deflectionBot.content').save().then(function () {
          _this.saveCallbacks(id);
        })['catch'](function (e) {
          // handle server errors
          console.log('e =>', e);
        });
      })['catch'](function (e) {
        // handle server errors
        console.log('e =>', e);
      });
    },

    saveCallbacks: function saveCallbacks(id) {
      var _this2 = this;

      this.get('callFlow').reload().then(function () {
        _this2.set('startValidate', false);
        _this2.set('isSaving', false);
        if (_this2.get('editNumber')) {
          _this2.get('routing').transitionTo('protected.admin.numbers', [], {
            editNumber: _this2.get('editNumber'),
            callFlowID: _this2.get('callFlow.id'),
            actionFor: _this2.get('actionFor')
          });
        } else {
          _this2.get('routing').transitionTo('protected.admin.callflows');
        }
      });
    },

    // housekeeping
    willDestroyElement: function willDestroyElement() {
      var _this3 = this;

      // unloading orphan messages are taken care of, in-place
      this._super.apply(this, arguments);

      ['ivr', 'deflection-bot', 'call-flow'].forEach(function (recordType) {
        _this3.store.peekAll(recordType).forEach(function (record) {
          if (record.get('isSaving')) {
            return;
          }
          if (record.get('isNew')) {
            return _this3.store.unloadRecord(record);
          }
          if (record.get('hasDirtyAttributes')) {
            record.rollbackAttributes();
            record.rollbackRelationships();
          }
        });
      });

      // call-queue's children are embedded relationships; associations affected by stale DS records; force re-association
      ['call-queue', 'routing-automation', 'routing-rule'].forEach(function (model) {
        return _this3.store.unloadAll(model);
      });
    },
    redirectionURL: computed('editNumber', function () {
      if (this.get('editNumber')) {
        return 'protected.admin.numbers';
      }

      return 'protected.admin.callflows';
    }),

    actions: {
      save: function save() {
        var _this4 = this;

        var that = this;

        this.get('callFlow.deflectionBot').then(function (deflectionBot) {
          _this4.set('startValidate', true);
          _this4.set('isSaving', true);

          // save child flows and then save the call flow, followed by deflection bot
          Promise.all(_freshfoneEmberModelsDeflectionBot.actionTypes.map(function (actionType) {
            var action = deflectionBot.get('' + actionType),
                actionId = deflectionBot.get(actionType + 'Id'),
                botActionType = deflectionBot.get(actionType + 'Type');

            // saving formula specific to the action
            saveBasedOnAction(botActionType, actionId, _this4.store);

            if (botActionType === 'callQueue' && (!actionId || action && action.get('hasDirtyAttributes'))) {
              return action.validate().then(function () {
                return action.save();
              });
            } else if (botActionType === 'ivr' && (!actionId || action && action.get('hasDirtyAttributes'))) {
              if (_this4.get('callFlow.deflectionBot.' + actionType + '.isValid')) {
                return action.validateAndSaveKeyPress().then(function () {
                  return action.validate().then(function () {
                    return action.save();
                  });
                });
              }

              return Promise.reject();
            }
          })).then(function () {
            Array.prototype.forEach.call(arguments[0], function (action, i) {
              return action && deflectionBot.set(_freshfoneEmberModelsDeflectionBot.actionTypes[i] + 'Id', action.id);
            });

            return deflectionBot.validate().then(function () {
              that.set('isFlowIncomplete', false);
              that.saveCallFlow();
            });
          })['catch'](function () {
            _this4.set('isFlowIncomplete', true);
            _this4.set('isSaving', false);
          });
        })['catch'](function () {
          _this4.set('isFlowIncomplete', true);
          _this4.set('isSaving', false);
        });
      }
    }
  });

  function saveBasedOnAction(botActionType, actionId, store) {
    if ((botActionType === 'callQueue' || botActionType === 'ivr') && actionId) {
      var _ret = (function () {
        var childCallFlow = store.peekRecord(botActionType === 'callQueue' ? 'call-queue' : botActionType, actionId);

        if (childCallFlow.get('hasDirtyAttributes')) {
          if (botActionType === 'callQueue') {
            return {
              v: childCallFlow.validate().then(function () {
                return childCallFlow.save();
              })
            };
          }
          if (childCallFlow.get('isValid')) {
            return {
              v: childCallFlow.validateAndSaveKeyPress().then(function () {
                return childCallFlow.validate().then(function () {
                  return childCallFlow.save();
                });
              })
            };
          }

          return {
            v: Promise.reject()
          };
        }
      })();

      if (typeof _ret === 'object') return _ret.v;
    }
  }
});