define('freshfone-ember/serializers/session', ['exports', 'freshfone-ember/serializers/application'], function (exports, _freshfoneEmberSerializersApplication) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      if (payload.included && payload.included.length) {
        var currentAccount = payload.included.findBy('type', 'account');

        // Eg., "Sprout 2020" -> "Sprout"
        if (currentAccount && currentAccount.attributes && currentAccount.attributes['plan-name']) {
          var _currentAccount$attributes$planName$split = currentAccount.attributes['plan-name'].split(' ');

          var _currentAccount$attributes$planName$split2 = _slicedToArray(_currentAccount$attributes$planName$split, 1);

          currentAccount.attributes['plan-name'] = _currentAccount$attributes$planName$split2[0];
        }
      }

      return this._super.apply(this, arguments);
    }
  });
});