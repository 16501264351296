define('freshfone-ember/components/business-calendar/calendar-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/feature-check-mixin', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/validations/server-errors', 'freshfone-ember/utils/business-calendar-constants'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsFeatureCheckMixin, _emberComponentInboundActionsInboundActions, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsValidationsServerErrors, _freshfoneEmberUtilsBusinessCalendarConstants) {
  var defaultStartTime = _freshfoneEmberUtilsBusinessCalendarConstants['default'].defaultStartTime;
  var defaultEndTime = _freshfoneEmberUtilsBusinessCalendarConstants['default'].defaultEndTime;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsValidationsServerErrors['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _emberComponentInboundActionsInboundActions['default'], {
    i18n: service(),
    workingHoursErrors: computed.and('startValidate', 'businessCalendar.errors.workingHours'),
    disableSaveButton: computed.or('businessCalendar.isSaving', 'businessCalendar.editEnabled'),
    hasErrors: computed.or('workingHoursErrors', 'serverErrorAttribute'),
    hasSaveCallBack: computed.notEmpty('saveCallBack'),
    currentAccount: computed.alias('session.account'),
    nameLabel: computed('businessCalendar', function () {
      return this.get('i18n').t('business_calendar.name');
    }),
    timezoneLabel: computed('businessCalendar', function () {
      return this.get('i18n').t('business_calendar.timezone');
    }),
    noTimezoneLabel: computed(function () {
      return this.get('i18n').t('business_calendar.validation_errors.no_timezone');
    }),
    businessCalendar: computed({
      set: function set(key, value) {
        var _this = this;

        this.resetErrors();
        run(function () {
          if (value && value.get('id') && !value.get('isFlash')) {
            _this.set('slideToggle', true);
          }
        });

        return value;
      },
      get: function get() {
        return this;
      }
    }),
    calendarTimezone: computed('businessCalendar.timeZone', {
      set: function set(k, v) {
        this.set('businessCalendar.timeZone', v.id);

        return v;
      },
      get: function get() {
        var timezone = this.get('businessCalendar.timeZone');

        return this.get('businessCalendar.timezonesList').filterBy('id', timezone)[0];
      }
    }),
    isBusinessHoursPresent: computed.not('businessCalendar.isNew'),
    readOnlyBusinessHoursView: computed('sharedWithoutOmniBusinessCalendar', 'showReadOnlyView', function () {
      return this.get('showReadOnlyView') && !this.get('sharedWithoutOmniBusinessCalendar');
    }),
    showOmniEditText: computed('businessCalendar.externalId', 'isOmniBusinessHours', 'currentAccount.isSharedBundleAccount', function () {
      if (this.get('currentAccount.isSharedBundleAccount')) {
        return this.get('businessCalendar.externalId') && this.get('isOmniBusinessHours');
      }

      return this.get('isOmniBusinessHours');
    }),
    showCrmEditText: computed('sharedNonCrmView', 'sharedWithoutOmniBusinessCalendar', function () {
      return this.get('sharedNonCrmView') && !this.get('sharedWithoutOmniBusinessCalendar');
    }),
    showReadOnlyView: computed('currentAccount.isSharedBundleAccount', 'businessCalendar', 'isOmniBusinessHours', 'sharedNonCrmView', function () {
      if (this.get('currentAccount.isSharedBundleAccount')) {
        return this.get('businessCalendar.externalId') || this.get('sharedNonCrmView');
      }

      return this.get('isOmniBusinessHours');
    }),
    title: computed('businessCalendar.isNew', function () {
      return this.get('slideToggle') && !this.get('businessCalendar.isNew') ? this.get('i18n').t('business_calendar.business_hour_details') : this.get('i18n').t('business_calendar.new_business_hour');
    }),
    isCalendarDirty: computed('businessCalendar.hasDirtyAttributes', 'businessCalendar.workingHours.@each.day', 'businessCalendar.workingHours.@each.TimeSlots.@each.startTime', 'businessCalendar.workingHours.@each.TimeSlots.@each.endTime', 'businessCalendar.workingHours.@each.isDeleted', 'businessCalendar.holidaysAsArray.@each.isDeleted', 'businessCalendar.holidaysAsArray.@each.date', 'businessCalendar.holidaysAsArray.@each.occasion', 'businessCalendar.customIsDirty', function () {
      return this.get('businessCalendar.customIsDirty') || this.get('businessCalendar').isDeepDirty();
    }).readOnly(),
    submitLabel: computed('businessCalendar.isNew', 'businessCalendar.isSaving', function () {
      var saveLabel = this.get('businessCalendar.isNew') ? this.get('i18n').t('business_calendar.add_business_hour') : this.get('i18n').t('business_calendar.save');

      return this.get('businessCalendar.isSaving') ? this.get('i18n').t('business_calendar.saving') : saveLabel;
    }),
    hasFeatureHolidayCalendar: computed('businessCalendar.isDefault', 'businessCalendar.id', function () {
      return this.get('businessCalendar.isDefault') ? this.checkFeature('outside_holiday_call_flow') : this.checkFeature('holiday_calendar');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('fromModal')) {
        this.createBusinessHour();
        this.createDefaultWorkingHours();
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.get('fromModal')) {
        run.scheduleOnce('afterRender', this, 'scrollToThis');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('businessCalendar', undefined);
    },
    scrollToThis: function scrollToThis() {
      this.get('parentView').$().animate({ scrollLeft: this.$().offset().left }, 1000);
    },
    createDefaultWorkingHours: function createDefaultWorkingHours() {
      var _this2 = this;

      this.get('businessCalendar.daysInWeek').forEach(function (day) {
        if (_this2.get('businessCalendar.defaultDays').includes(day.value)) {
          var timeSlots = [],
              startTime = defaultStartTime,
              endTime = defaultEndTime;

          timeSlots.push({
            startTime: startTime,
            endTime: endTime
          });

          // eslint-disable-next-line one-var
          var params = {
            calendar: _this2.get('businessCalendar'),
            day: day.value,
            timeSlots: timeSlots
          };

          _this2.store.createRecord('working-hour', params);
        }
      });
    },
    createBusinessHour: function createBusinessHour() {
      var count = this.store.peekAll('business-calendar').mapBy('name').length + 1,
          calendar = this.store.peekAll('business-calendar').find(function (i) {
        return !i.id;
      });

      if (calendar) {
        this.store.unloadRecord(calendar);
        count -= 1;
      }

      calendar = this.store.createRecord('business-calendar', { name: this.get('i18n').t('entityNames.business_hour') + ' ' + count });

      this.set('businessCalendar', calendar);
    },
    afterSaveCallbacks: function afterSaveCallbacks(calendar) {
      if (this.get("hasSaveCallBack")) {
        this.sendAction("saveCallBack", calendar);
      }
      this.set('businessCalendar.customIsDirty', false);
      this.resetErrors();
      calendar.set('isFlash', true);
      calendar.saveOriginalRelations();
      this.sendAction('onModify');
      calendar.resetFlash();
      this.send('toggleSidecard');
    },
    resetErrors: function resetErrors() {
      this.set('startValidate', false);
      this.set('serverErrors', null);
    },
    actions: {
      updateCustomIsDirty: function updateCustomIsDirty(updatedTimeZone) {
        this.set('businessCalendar.timeZone', updatedTimeZone.id);
        this.set('businessCalendar.customIsDirty', true);
      },

      toggleSidecard: function toggleSidecard() {
        this.toggleProperty('slideToggle');
        if (this.get('slideToggle')) {
          this.createBusinessHour();
          this.createDefaultWorkingHours();
          this.set('businessCalendar.customIsDirty', true);
        } else {
          this.get('businessCalendar').rollbackModel();
          this.set('businessCalendar.customIsDirty', false);
          this.sendAction('clearParams');
        }
      },
      save: function save() {
        var _this3 = this;

        if (this.get('businessCalendar.editEnabled')) return;
        var calendar = this.get('businessCalendar');

        calendar.validate().then(function () {
          _this3.set('startValidate', false);
          calendar.save().then(function (calendarItem) {
            _this3.afterSaveCallbacks(calendarItem);
          })['catch'](function (error) {
            _this3.handleError(error);
          });
        })['catch'](function () {
          _this3.set('startValidate', true);
        });
      }
    },
    validations: { 'businessCalendar.name': { presence: true } }
  });
});