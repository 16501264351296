define('freshfone-ember/components/billing-base/credit-actions/component', ['exports', 'freshfone-ember/mixins/currency-mixin', 'ember-data', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/currency-types', 'freshfone-ember/constants/free-incoming-calling-minutes', 'freshfone-ember/constants/product-names'], function (exports, _freshfoneEmberMixinsCurrencyMixin, _emberData, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersCurrencyTypes, _freshfoneEmberConstantsFreeIncomingCallingMinutes, _freshfoneEmberConstantsProductNames) {
  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var gt = _ember['default'].computed.gt;
  var run = _ember['default'].run;
  var later = _ember['default'].run.later;
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  var testing = _ember['default'].testing;
  var PromiseObject = _emberData['default'].PromiseObject;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsCurrencyMixin['default'], {
    currentAccount: computed.alias('session.account'),
    isAccountBlocked: computed.reads('currentAccount.blocked'),
    freshsalesPlanName: computed.alias('session.current.bundleInfo.domains.freshsales-plan-name'),
    isTrial: computed.equal('subscription.statusText', 'trial'),
    isAgentLicensePurchaseSuccess: false,
    isOmnichannelAccount: computed.alias('currentAccount.isOmnichannelEnabled'),
    isUCREnabledAccount: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    isSharedBundleAccount: computed.alias('currentAccount.isSharedBundleAccount'),
    billingService: service('billing'),
    standaloneUsersCount: computed.alias('billingService.standaloneCount'),
    sharedUsersCount: computed.alias('billingService.sharedCount'),
    crmBillingCount: computed.alias('billingService.crmCount'),
    omniBillingCount: computed.alias('billingService.omniCount'),
    crmUsersCount: computed('crmBillingCount', 'sharedUsersCount', function () {
      return this.get('crmBillingCount') === undefined ? 0 : Number(this.get('crmBillingCount')) + Number(this.get('sharedUsersCount'));
    }),
    omniUsersCount: computed('omniBillingCount', 'sharedUsersCount', function () {
      if (this.get('omniBillingCount') === undefined) {
        return 0;
      }

      return this.get('isSharedBundleAccount') ? Number(this.get('omniBillingCount')) + Number(this.get('sharedUsersCount')) : Number(this.get('omniBillingCount'));
    }),

    purchaseError: null,
    purchaseFlag: false,
    purchaseCreditModal: false,
    accountWalletsErrorModal: false,
    purchaseType: 'credits',
    notifications: service('toastr'),

    currency: computed.reads('subscription.currency'),
    currencySymbol: computed('currency', function () {
      return _freshfoneEmberHelpersCurrencyTypes['default'].currencySymbols[this.get('currency')];
    }),
    isDefaultCurrency: computed.equal('currency', _freshfoneEmberHelpersCurrencyTypes['default'].defaultCurrency),

    credits: computed(function () {
      var _this = this;

      return PromiseObject.create({
        promise: this.store.queryRecord('credit', {}).then(function (credits) {
          _this.setCreditValues(credits);

          return credits;
        })
      });
    }),
    availableCredits: computed.alias('credits.availableCredit'),
    accountWallets: computed.alias('credits.accountWallets'),
    isCreditSplitSkuEnabledAccount: computed(function () {
      return this.hasGlobalFeature('credit_sku_split_global') && this.checkFeature('credit_sku_split');
    }),
    isAccountWalletsAvailable: gt('accountWallets.length', 0),
    isAccountWalletsNotAssociated: computed('accountWallets', 'isAccountWalletsAvailable', function () {
      return this.get('isCreditSplitSkuEnabledAccount') && !this.get('isAccountWalletsAvailable');
    }),
    showAccountWalletsNotAssociated: computed('isAccountWalletsNotAssociated', 'accountWalletsErrorModal', function () {
      return this.get('isAccountWalletsNotAssociated') && this.get('accountWalletsErrorModal');
    }),
    availableCreditsFormatted: computed('currency', 'availableCredits', function () {
      // eslint-disable-next-line max-len
      return Math.round(this.get('availableCredits') / this.get('currencyConversion')[this.get('currency')] * 100) / 100;
    }),
    showCreditsModal: computed('subscription.isFulfilled', 'purchaseCreditModal', function () {
      return this.get('subscription.isFulfilled') && this.get('purchaseCreditModal') && JSON.parse(this.get('purchaseCreditModal'));
    }),

    showCallingCardMinutes: computed('plans.content', 'subscription.content.planId', 'credits.callingCardRemainingMinutes', function () {
      var currentPlan = {};

      if (this.get('plans.content') && this.get('subscription.content') && this.get('plans.content.length') && parseInt(this.get('credits.callingCardRemainingMinutes')) >= 0) {
        this.set('totalCallingCardMinutes', _freshfoneEmberConstantsFreeIncomingCallingMinutes['default'][this.get('subscription.planName').decamelize()]);
        currentPlan = this.get('plans.content').findBy('id', this.get('subscription.content.planId').toString());
        if (currentPlan) {
          return !currentPlan.get('classic') && !currentPlan.get('free');
        }
      }

      return isEmpty(currentPlan);
    }),

    addonName: computed('currency', function () {
      return this.get('currency') === 'USD' ? 'Support360 Standalone User' : 'Support360 Standalone User ' + this.get('currency').toLowerCase().capitalize();
    }),
    totalAgentsCount: computed.alias('currentAccount.active_users_count'),
    addonAgentsCount: computed.alias('currentAccount.addonAgentsCount'),
    usedCallingCardMinutes: computed('credits.callingCardRemainingMinutes', 'totalCallingCardMinutes', function () {
      return this.get('totalCallingCardMinutes') - this.get('credits.callingCardRemainingMinutes');
    }),
    crmPurchaseType: computed('freshsalesPlanName', function () {
      return this.get('freshsalesPlanName') && this.get('freshsalesPlanName').includes('sales360_clc') ? _freshfoneEmberConstantsProductNames.rebrandedProductNames.crmSuite : _freshfoneEmberConstantsProductNames.rebrandedProductNames.crm;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('isOmnichannelAccount') || this.get('isSharedBundleAccount')) {
        this.get('billingService').loadUserCount();
      }
    },
    setCreditValues: function setCreditValues(credits) {
      var rechargeAmount = credits.get('autoRecharge') ? credits.get('rechargeQuantity') : credits.get('disableAutoRechargeAmount'),
          thresholdAmount = credits.get('autoRechargeThreshold'),
          selectedCreditValue = credits.get('defaultRechargeOption');

      this.setProperties({
        rechargeAmount: rechargeAmount,
        thresholdAmount: thresholdAmount,
        selectedCreditValue: selectedCreditValue
      });
    },
    purchaseCredits: function purchaseCredits(creditValue) {
      var _this2 = this;

      $.ajax({
        url: '/credits/purchase',
        type: 'POST',
        dataType: 'json',
        data: { credit: creditValue }
      }).done(function (response) {
        run(function () {
          var successMessage = _this2.get('hasCreditSplitFeature') ? 'phone_credits_added_credit_split' : 'phone_credits_added';

          _this2.store.pushPayload('credit', response);
          _this2.afterPurchaseAction();
          _this2.get('analyticsTracker').addEvent('Credit Purchased');
          _this2.get('notifications').success(_ember['default'].i18n.t('subscription.' + successMessage));
        });
      }).fail(function (error) {
        run(function () {
          var errorCode = JSON.parse(error.responseText).error_code;

          _this2.set('purchaseError', _ember['default'].i18n.t('billing.credits.purchase_error.' + errorCode));
          _this2.set('paymentFailed', true);
          _this2.afterPurchaseAction();
          _this2.get('session').loadSession();
        });
      });
    },
    validateCreditsPurchase: function validateCreditsPurchase() {
      if (!this.get('credits.rechargeAllowed')) {
        var namespace = this.get('hasCreditSplitFeature') ? 'sku_changes.' : '';

        // eslint-disable-next-line ember-suave/no-direct-property-access
        this.set('purchaseError', _ember['default'].i18n.t('billing.credits.' + namespace + 'purchaseNotAllowed', { maxRechargeCount: this.get('credits.maxRechargeCount') }));
        this.set('paymenFailed', true);
        this.afterPurchaseAction();
      }

      return this.get('credits.rechargeAllowed');
    },

    purchaseAgents: function purchaseAgents(addonAgentsCount) {
      var _this3 = this;

      var addonName = this.get('addonName');

      this.get('subscription.content').purchaseAddonAgents({
        name: addonName,
        quantity: addonAgentsCount
      }).then(function (response) {
        run(function () {
          // setting this flag to true for payment success
          _this3.set('isAgentLicensePurchaseSuccess', true);
          _this3.afterPurchaseAction();
          _this3.get('analyticsTracker').addEvent('Agent License Purchased');
          _this3.get('notifications').success(_ember['default'].i18n.t('subscription.bought_agent_license'));
          _this3.store.pushPayload('subscription', response);
        });
      })['catch'](function () {
        run(function () {
          _this3.set('purchaseError', _ember['default'].i18n.t('subscription.error_buying_agent_license'));
          _this3.set('paymentFailed', true);
          _this3.afterPurchaseAction();
          _this3.get('session').loadSession();
        });
      });
    },
    validateAgentLicensePurchase: function validateAgentLicensePurchase() {
      var givenAgentLicenseCount = Number(this.get('numberOfAgents')),
          addonAgentsCount = this.get('addonAgentsCount');

      if (!(givenAgentLicenseCount > 0)) {
        this.set('purchaseError', _ember['default'].i18n.t('billing.agent_license.not_valid_number'));
        this.set('paymentFailed', true);
        this.afterPurchaseAction();

        return false;
      }
      if (givenAgentLicenseCount < addonAgentsCount) {
        this.set('purchaseError', _ember['default'].i18n.t('billing.agent_license.more_agents_error', {
          currentAgentsCount: addonAgentsCount,
          extraAgentsCount: addonAgentsCount - givenAgentLicenseCount
        }));
        this.set('paymentFailed', true);
        this.afterPurchaseAction();

        return false;
      }

      return true;
    },

    resetPurchaseAction: function resetPurchaseAction() {
      this.set('paymentFailed', false);
      // setting this flag to default false value
      this.set('isAgentLicensePurchaseSuccess', false);
      this.set('purchaseError', null);
      this.set('purchaseTriggered', false);
    },
    afterPurchaseAction: function afterPurchaseAction() {
      this.set('purchaseTriggered', true);
      this.set('dropdownExpanded', false);
      this.set('isPurchasing', false);
    },

    actions: {
      purchase: function purchase(purchaseType) {
        if (this.get('isAccountBlocked') || this.get('isPurchasing')) {
          return;
        }
        this.resetPurchaseAction();
        this.set('isPurchasing', true);
        if (purchaseType === 'credits' && this.validateCreditsPurchase()) {
          this.purchaseCredits(this.get('selectedCreditValue'));
        } else if (purchaseType === 'agents' && this.validateAgentLicensePurchase()) {
          this.purchaseAgents(this.get('numberOfAgents'));
        }
      },
      toggleAddCardPurchase: function toggleAddCardPurchase() {
        var purchaseType = arguments.length <= 0 || arguments[0] === undefined ? 'credits' : arguments[0];

        if (this.get('isAccountBlocked')) {
          return;
        }
        if (this.get('isAccountWalletsNotAssociated')) {
          this.set('accountWalletsErrorModal', true);

          return;
        }
        this.set('numberOfAgents', null);
        this.set('purchaseType', purchaseType);
        this.set('purchaseCreditModal', !(this.get('purchaseCreditModal') === 'true' || this.get('purchaseCreditModal') === true));
        // reload the page if modal closed after payment success
        if (this.get('isAgentLicensePurchaseSuccess')) {
          later(function () {
            return !testing && window.location.reload();
          });
        }
        this.resetPurchaseAction();
      },
      closeErrorModal: function closeErrorModal() {
        this.set('accountWalletsErrorModal', false);
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */