define('freshfone-ember/models/ivr', ['exports', 'ember', 'ember-data', 'ember-validations', 'ember-api-actions', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _emberData, _emberValidations, _emberApiActions, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;

  var IVR_TYPE = {
    'keypress': 1,
    'voice': 2
  };
  exports['default'] = Model.extend(_emberValidations['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    session: service(),
    name: attr('string'),
    ivrType: attr('number', { defaultValue: IVR_TYPE['keypress'] }),
    retries: attr('number', { defaultValue: 3 }),
    message: belongsTo('message', { async: true }),
    botRetryMessage: belongsTo('message', { async: true }),
    ivrOptions: hasMany('ivr-option', { inverse: 'ivr' }),
    ivrInputs: hasMany('ivr-input'),
    callFlow: belongsTo('callFlow'),
    botConfig: attr(),
    routingAutomationAsFallback: computed(function () {
      return this.checkFeature('routing_automation') && this.get('session.account').hasGlobalFlag('routing_automation_fallback');
    }),

    getBotsList: (0, _emberApiActions.collectionAction)({
      type: 'GET',
      path: 'list_bots'
    }),

    createNewBot: (0, _emberApiActions.collectionAction)({
      type: 'POST',
      path: 'create_bot'
    }),

    reviewCustomerPhrases: (0, _emberApiActions.memberAction)({
      type: 'Get',
      path: 'review_customer_phrases'
    }),

    ivrKeyPressOptions: computed('ivrOptions.[]', function () {
      return this.get("ivrOptions").rejectBy('optionKey', 'fallback').rejectBy('optionKey', 'bot-fallback');
    }),

    isSpeechEnabledIvr: computed(function () {
      return this.checkFeature('speech_enabled_ivr') && this.get('isVoiceIVR');
    }),

    actionOptions: computed('isChild', function () {
      var options = [{ id: 1, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_ivr'), desc: _ember['default'].i18n.t('call_ivr.action_types.ivr'), name: 'ivr' }, { id: 1, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_callQueue'), desc: _ember['default'].i18n.t('call_ivr.action_types.call_queue'), name: 'callQueue' }, { id: 2, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.hangup'), desc: _ember['default'].i18n.t('call_ivr.action_types.hangup'), name: 'hangup', helpText: _ember['default'].i18n.t('call_queue.edit.fallback.hangup.help_text') }, { id: 3, chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.voicemail'), desc: _ember['default'].i18n.t('call_ivr.action_types.voicemail'), name: 'vm', helpText: _ember['default'].i18n.t('call_queue.edit.fallback.vm.help_text') }, { id: 5, desc: _ember['default'].i18n.t('call_queue.edit.fallback.agent_extension.name'), abbr: 'agent_extension', name: 'agentExtension', chooseDesc: _ember['default'].i18n.t('call_queue.edit.placeholders.choose_agent_extension') }];

      if (this.get('routingAutomationAsFallback') && !this.get('isVoiceIVR')) {
        var option = {
          id: 7,
          chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_routing_automation'),
          desc: _ember['default'].i18n.t('call_ivr.action_types.routing_automation'),
          name: 'routingAutomation',
          helpText: _ember['default'].i18n.t('call_queue.edit.fallback.routing_automation.name')
        };

        options.pushObject(option);
      }
      return options;
    }),

    keyOptions: computed('isChild', function () {
      var options = [{ id: 1, desc: '0', name: '0', disabled: false }, { id: 2, desc: '1', name: '1', disabled: false }, { id: 4, desc: '2', name: '2', disabled: false }, { id: 5, desc: '3', name: '3', disabled: false }, { id: 6, desc: '4', name: '4', disabled: false }, { id: 7, desc: '5', name: '5', disabled: false }, { id: 8, desc: '6', name: '6', disabled: false }, { id: 9, desc: '7', name: '7', disabled: false }, { id: 10, desc: '8', name: '8', disabled: false }, { id: 11, desc: '9', name: '9', disabled: false }, { id: 12, desc: '*', name: '*', disabled: false }, { id: 13, desc: '#', name: '#', disabled: false }];
      return options;
    }),

    anyAvailableKeyPress: computed('keyOptions.@each.disabled', function () {
      var availableKey = this.get("keyOptions").filterBy('disabled', false);
      return isPresent(availableKey);
    }),

    singleKeyPressSelected: computed('ivrKeyPressOptions.[]', function () {
      return this.get("ivrKeyPressOptions").length <= 1;
    }),

    fallbackOption: computed('ivrOptions.[]', function () {
      return this.get("ivrOptions").filterBy('optionKey', 'fallback')[0];
    }),

    botFallbackOption: computed('ivrOptions.[]', function () {
      return this.get("ivrOptions").filterBy('optionKey', 'bot-fallback')[0];
    }),

    isVoiceIVR: computed('ivrType', function () {
      return this.get('ivrType') === IVR_TYPE['voice'];
    }),
    isKeyPressIVR: computed('ivrType', function () {
      return this.get('ivrType') === IVR_TYPE['keypress'];
    }),
    validateAndSaveKeyPress: function validateAndSaveKeyPress() {
      var _this = this;

      var promises = [];
      if (!this.get('isVoiceIVR')) {
        this.get("ivrOptions").forEach(function (option) {
          if (option && option.get('optionKey') === 'bot-fallback') {
            _this.store.unloadRecord(option);
          }
        });
      }
      this.get("ivrOptions").forEach(function (option) {
        if (option) {
          var promise = option.validate().then(function () {
            return _this.saveValidKeyPress(option);
          });
          promises.push(promise);
        }
      });
      return new _ember['default'].RSVP.all(promises).then(function (result) {
        return result;
      });
    },

    saveValidKeyPress: function saveValidKeyPress(option) {
      if (!option.get("isACallFlow")) {
        return true;
      }
      if (option.get("isIvr")) {
        return this.saveChildIvr(option);
      }
      if (option.get('isRoutingAutomation')) {
        return option.validateAndSaveChildAction(option.get('actionRoutingAutomation.content'), 'action');
      }
      return this.saveChildQueue(option);
    },

    saveChildIvr: function saveChildIvr(option) {
      var ivr = option.get("actionIvr.content");
      return ivr.validateAndSaveKeyPress().then(function () {
        return ivr.validate().then(function () {
          return option.get("actionIvr.content").save().then(function (childIvr) {
            option.set("actionId", childIvr.id);
          })['catch'](function (e) {
            // handle server errors
            console.log('e =>', e);
            throw new Error(e.errors[0].detail);
          });
        });
      });
    },

    saveChildQueue: function saveChildQueue(option) {
      var queue = option.get("actionQueue.content");
      return queue.validate().then(function () {
        return _ember['default'].run(function () {
          return option.get("actionQueue.content").save().then(function (childQueue) {
            option.set("actionId", childQueue.id);
          });
        })['catch'](function (e) {
          console.log('e =>', e);
          throw new Error(e.errors[0].detail);
          // handle server errors
        });
      });
    },
    rollbackIvr: function rollbackIvr() {
      if (isEmpty(this) || this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      this.rollbackAttributes();
      this.rollbackRelationships();
    },

    validations: {
      //TODO: i18n
      name: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.name')
        }
      },
      message: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.message')
        }
      },
      'message.content': {
        presence: { message: _ember['default'].i18n.t('call_queue.ui_validation_error.message_blank') }
      },
      retries: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.retries.blank')
        },
        numericality: { onlyInteger: true, greaterThanOrEqualTo: 1, lessThanOrEqualTo: 10,
          messages: { numericality: _ember['default'].i18n.t('call_ivr.validation_errors.retries.number'),
            onlyInteger: _ember['default'].i18n.t('call_ivr.validation_errors.retries.integer'),
            greaterThanOrEqualTo: _ember['default'].i18n.t('call_ivr.validation_errors.retries.greater'),
            lessThanOrEqualTo: _ember['default'].i18n.t('call_ivr.validation_errors.retries.lesser') }
        }
      }
    }
  });
});