define('freshfone-ember/mixins/validations/number', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {

  var MIN_SHORT_ABANDON_TIMEOUT = 1;
  var MAX_SHORT_ABANDON_TIMEOUT = 3600; // 1 HOUR

  exports['default'] = _ember['default'].Mixin.create(_emberValidations['default'], {

    validations: {
      'freshfoneNumber.callerId': {
        inline: (0, _emberValidations.validator)(function () {
          if (!this.get('freshfoneNumber.callerId.content') && this.get('freshfoneNumber.maskingEnabled')) {
            return _ember['default'].i18n.t('caller_mask.blank_caller_id');
          }
        })
      },
      'freshfoneNumber.recordingOptInMessage': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('freshfoneNumber.recordingOptIn') && !this.get('freshfoneNumber.recordingOptInMessage.content')) {
            return _ember['default'].i18n.t('recording.blank_opt_in_message');
          }
        })
      },
      'freshfoneNumber.shortAbandonTimeout': {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: MIN_SHORT_ABANDON_TIMEOUT,
          lessThanOrEqualTo: MAX_SHORT_ABANDON_TIMEOUT,
          messages: {
            numericality: _ember['default'].i18n.t('numbers.shortAbandon.validations.onlyNumber'),
            onlyInteger: _ember['default'].i18n.t('numbers.shortAbandon.validations.onlyInteger'),
            greaterThanOrEqualTo: _ember['default'].i18n.t('numbers.shortAbandon.validations.greaterThanZero'),
            lessThanOrEqualTo: _ember['default'].i18n.t('numbers.shortAbandon.validations.lessThanAnHour')
          }
        }
      }
    }
  });
});