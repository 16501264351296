define('freshfone-ember/services/error-monitor', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var computed = _ember['default'].computed;
  var Service = _ember['default'].Service;
  var get = _ember['default'].get;
  var Sentry = window.Sentry;
  exports['default'] = Service.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    enabled: computed(function () {
      return Sentry && this.checkFeature('frontend_error_monitoring');
    }),

    setUser: function setUser(user) {
      if (!this.get('enabled')) {
        return;
      }

      Sentry.setUser({ email: get(user, 'email') });
    },

    report: function report(error) {
      var context = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      if (!this.get('enabled')) {
        return;
      }

      Sentry.withScope(function (scope) {
        scope.setExtras(context.extras);
        scope.setTags(context.tags);
        Sentry.captureException(error);
      });
    }
  });
});