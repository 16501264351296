define('freshfone-ember/components/common/custom-checkbox/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('checkboxElementId', 'checkboxId\'' + this.elementId);
    },

    actions: {
      callBackAction: function callBackAction(index) {
        this.toggleProperty('checkOption');

        if (this.get('callBackAction')) {
          this.get('callBackAction')(index, event.target.checked);
        }
      }
    }
  });
});