define('freshfone-ember/adapters/account-configuration', ['exports', 'freshfone-ember/mixins/adapters/multi-word-underscore'], function (exports, _freshfoneEmberMixinsAdaptersMultiWordUnderscore) {
  exports['default'] = _freshfoneEmberMixinsAdaptersMultiWordUnderscore['default'].extend({
    handleResponse: function handleResponse(status, headers, payload) {
      if ((status === 422 || status === 417) && payload.errors) {
        return new DS.InvalidError(payload.errors);
      }

      return this._super.apply(this, arguments);
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {},
          serializer = store.serializerFor(type.modelName),
          url = '/account_configurations';

      serializer.serializeIntoHash(data, type, snapshot);

      return this.ajax(url, 'PUT', { data: data });
    }
  });
});