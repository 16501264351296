define('freshfone-ember/components/outgoing-widget/component', ['exports', 'freshfone-ember/constants/call-status', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember', 'freshfone-ember/constants/event-type', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/number-mixin', 'freshfone-ember/constants/park-state', 'freshfone-ember/constants/account', 'freshfone-ember/constants/search-query-constants', 'freshfone-ember/utils/search-util'], function (exports, _freshfoneEmberConstantsCallStatus, _emberRlDropdownMixinsRlDropdownComponent, _ember, _freshfoneEmberConstantsEventType, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsNumberMixin, _freshfoneEmberConstantsParkState, _freshfoneEmberConstantsAccount, _freshfoneEmberConstantsSearchQueryConstants, _freshfoneEmberUtilsSearchUtil) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var Object = _ember['default'].Object;
  var run = _ember['default'].run;
  var _Ember$run = _ember['default'].run;
  var debounce = _Ember$run.debounce;
  var scheduleOnce = _Ember$run.scheduleOnce;
  var $ = _ember['default'].$;
  var testing = _ember['default'].testing;
  var TargetActionSupport = _ember['default'].TargetActionSupport;
  var _Ember$computed = _ember['default'].computed;
  var not = _Ember$computed.not;
  var equal = _Ember$computed.equal;
  var alias = _Ember$computed.alias;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend(TargetActionSupport, _emberRlDropdownMixinsRlDropdownComponent['default'], _freshfoneEmberMixinsNumberMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    account: computed.alias('session.account'),
    credit: computed.alias('account.credit'),
    subscription: computed.alias('account.subscription'),
    currentCall: computed.alias('telephony.currentCall'),
    telephony: service('telephony'),
    routing: service('-routing'),
    analyticsService: service('analytics-tracker'),
    parkedCallsService: service('parked-calls'),
    searchTerm: '',
    activeFreshfoneNumber: '',
    freshfoneNumbersList: [],
    searchedNumbersList: [],
    browserService: service('browser-service'),
    recentCalls: _ember['default'].A(),
    isNumeric: computed.match('searchTerm', /[0-9]{1,15}/),
    currentAgent: computed.alias('currentUser.agent'),
    agentBusy: computed.equal('currentAgent.browserStatus.statusType', 'busy'),
    agentAcw: computed.equal('currentAgent.browserStatus.statusType', 'acw'),
    noNumbers: computed.empty('freshfoneNumbersList'),
    //standaloneUserOrNotBundled - the account is not shared or the user is standalone even if it's a shared account
    standaloneUserOrNotBundled: computed(function () {
      return !this.get('currentAccount.isSharedBundleAccount') || this.get('currentUser.isStandAloneUser');
    }),
    //isBundledStandaloneUserOrNotBundled - not a shared account or the user is either standalone user or the power_dialer_for_shared_user flag is enabled in a shared account
    isBundledStandaloneUserOrNotBundled: computed(function () {
      return !this.get('currentAccount.isSharedBundleAccount') || this.get('currentUser.isStandAloneUser') || this.hasGlobalFeature('power_dialer_for_shared_user');
    }),
    eligibleForFreeOnboarding: computed('session.account.onboarding_completed', function () {
      return this.hasSignInTypeEligibilty() && !this.get('session.account.onboarding_completed');
    }),
    showNewOnboardingFlow: computed('session.account.onboarding_completed', 'session.isOnboardingFlowInitiated', function () {
      return this.get('session.account.onboarding_completed') && this.get('session').get('isOnboardingFlowInitiated');
    }),
    // show call icon in dashboard if account is not unified account and isBundledStandaloneUserOrNotBundled is true
    showCallIcon: computed('isCallbackCall', function () {
      return this.get('isBundledStandaloneUserOrNotBundled') && !this.get('account.isUnifiedAppAccount') || this.get('isCallbackCall');
    }),
    // check whether current call is a callback call
    isCurrentCallBackCall: computed('isCallbackCall', 'currentAccount.isSharedBundleAccount', function () {
      return this.get('currentAccount.isSharedBundleAccount') && this.get('isCallbackCall');
    }),
    // isCurrentCallBackCall or standaloneUserOrNotBundled - this is  used to enabled callback call
    isCallBackStandaloneUserOrNotBundled: computed('isCurrentCallBackCall', 'standaloneUserOrNotBundled', function () {
      return this.get('standaloneUserOrNotBundled') || this.get('isCurrentCallBackCall');
    }),
    dropdownExpanded: computed('session.account.onboarding_completed', 'session.isOnboardingFlowInitiated', function () {
      return this.hasSignInTypeEligibilty() && this.get('session.account.onboarding_completed') && this.get('session').get('isOnboardingFlowInitiated');
    }),
    hasOmniFeatureEnabled: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    disableCallIcon: computed.alias('browserService.isMicrophonePermissionDenied'),
    micPermPromptState: computed.alias('browserService.isMicrophonePermissionPrompted'),
    zeroCredits: computed.equal('account.credit.availableCredit', 0),
    trialNoCredits: computed.and('subscription.isTrail', 'account.noFreeCredits', 'zeroCredits'),
    showMessage: computed('account.callingDisabled', 'noNumbers', 'eligibleForFreeOnboarding', function () {
      return this.get('account.callingDisabled') || this.get('noNumbers') || this.get('eligibleForFreeOnboarding');
    }),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    allowNewCalls: computed.alias('telephony.allowNewCalls'),
    currentCallNotActive: not('hasActiveCall'),
    showDialer: computed('currentCallNotActive', 'currentCall.status', 'currentCallIsParked', 'allowNewCalls', function () {
      var callCompleted = this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].Completed || this.get('currentCallNotActive'),
          currentCallEndedOrParked = callCompleted || this.get('currentCallIsParked');

      return currentCallEndedOrParked && this.get('allowNewCalls');
    }),
    closeDialer: computed('agentBusy', 'hasActiveCall', 'hasParkingEnabled', 'showDialer', function () {
      return !this.get('hasParkingEnabled') ? this.get('agentBusy') || this.get('hasActiveCall') : this.get('agentBusy') || !this.get('showDialer');
    }),
    isAgentInPowerDialerMode: computed.alias('currentAgent.powerDialer'),
    powerDialerService: service('power-dialer-list'),
    recordTypeService: service('record-type'),
    lastPowerDialerContact: computed.alias('powerDialerService.lastPowerDialerContact'),
    disableDialer: computed('closeDialer', function () {
      if (this.get('closeDialer')) {
        this.closeDialPad();
      }
      return this.get('closeDialer');
    }),
    adminCallingDisabledMsg: computed('account.callingDisabled', 'subscription.status', 'trialNoCredits', 'account.credit.isCreditLow', function () {
      var role = this.get('currentUser.isAccountAdmin') ? 'account_admin' : 'admin';
      switch (true) {
        case this.get('subscription.isTrailExpired'):
          return _ember['default'].i18n.t('outgoing_widget.trial_expired.' + role, { productName: this.get('currentAccount.productName') });
        case this.get('subscription.isPaymentFailed'):
        case this.get('subscription.isCancelled'):
          return _ember['default'].i18n.t('outgoing_widget.account_suspended.' + role);
        case this.get('trialNoCredits'):
          return _ember['default'].i18n.t('outgoing_widget.no_onboarding_credits.' + role);
        case this.get('account.credit.isCreditLow'):
          if (this.get('currentUser.isAccountAdmin') && !this.get('credit.rechargeAllowed')) {
            return _ember['default'].i18n.t('outgoing_widget.credit_low.recharge_not_allowed', { maxRechargeCount: this.get('credit.maxRechargeCount') });
          }
          return _ember['default'].i18n.t('outgoing_widget.credit_low.' + role);
        default:
          return _ember['default'].i18n.t('outgoing_widget.account_suspended.' + role);
      }
    }),

    adminNoNumbersMsg: computed('noNumbersHistory', 'noNumbers', function () {
      if (this.get('noNumbersHistory')) {
        return this.get('account.isUnifiedAppAccount') ? _ember['default'].i18n.t('outgoing_widget.no_numbers_history.admin_crm') : _ember['default'].i18n.t('outgoing_widget.no_numbers_history.admin', { productName: this.get('currentAccount.productName') });
      }

      return _ember['default'].i18n.t('outgoing_widget.no_numbers.admin');
    }),

    agentWidgetMsg: computed('account.callingDisabled', 'subscription.status', 'trialNoCredits', 'account.credit.isCreditLow', function () {
      if (this.get('account.callingDisabled')) {
        switch (true) {
          case this.get('subscription.isSuspended'):
            return _ember['default'].i18n.t('outgoing_widget.account_suspended.agent');
          case this.get('trialNoCredits'):
            return _ember['default'].i18n.t('outgoing_widget.no_onboarding_credits.agent');
          case this.get('account.credit.isCreditLow'):
            return _ember['default'].i18n.t('outgoing_widget.credit_low.agent');
          default:
            return _ember['default'].i18n.t('outgoing_widget.account_suspended.agent');
        }
      }
      return _ember['default'].i18n.t('outgoing_widget.no_numbers.agent');
    }),

    isSearch: computed('searchTerm', function () {
      return isPresent(this.get('searchTerm'));
    }),

    directDialNumber: computed('searchTerm', 'selectedCountryData.dialCode', function () {
      var selectedCountry = this.get('selectedCountryData');
      var number = this.get('searchTerm');
      return number.startsWith('+') ? number : ['+', selectedCountry.dialCode, number].join('');
    }),
    hasParkingEnabled: alias('telephony.hasParkingEnabled'),
    hasParkedCalls: computed('parkedCallsService.parkedCalls', function () {
      return this.get('parkedCallsService.parkedCalls').length;
    }),
    currentCallEndedWithParkedCalls: computed('currentCall.status', 'hasParkingEnabled', 'currentCallNotActive', 'hasParkedCalls', function () {
      var callCompleted = this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].Completed;

      return this.get('hasParkingEnabled') && (callCompleted || this.get('currentCallNotActive') && this.get('hasParkedCalls'));
    }),
    currentCallIsParked: equal('currentCall.parkState', _freshfoneEmberConstantsParkState['default'].Parked),
    currentCallIsNotParked: not('currentCallIsParked'),
    callInProgressObserver: observer('currentCall.status', function () {
      var status = this.get('currentCall.status');

      if (status && status === _freshfoneEmberConstantsCallStatus['default'].CallInProgress) {
        this.closeDialPad();
      }
    }),
    micPermissionObserver: observer('browserService.{isMicrophonePermissionDenied,isMicrophonePermissionPrompted}', function () {
      if (this.get('browserService.isMicrophonePermissionDenied')) {
        this.closeDialPad();
      }
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var self = this;
      scheduleOnce('afterRender', this, 'bindClickToCall', 200);
      this.get('telephony').on('numbers_updated', function () {
        self.numbersUpdatedCallback();
      });
      this.fetchFreshfoneNumbers();
      if (this.get('currentUser.ringOnSpeakers')) this.get('telephony').enableRingOnSpeakers();
      this.get('telephony').on('provider_initialized', function () {
        _this.send('resetDialPad');
      });
      document.addEventListener(_freshfoneEmberConstantsEventType['default'].SharedCallbackEvent, this.enableCallBack.bind(this));
      this.set('activeTab', this.get('isCallBackStandaloneUserOrNotBundled') ? 'recentCalls' : 'powerDialer');
    },

    enableCallBack: function enableCallBack() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.set('isCallbackCall', true);
      this.set('activeTab', 'recentCalls');
    },

    willDestroyElement: function willDestroyElement() {
      this.get('telephony').off('numbers_updated');
      document.removeEventListener(_freshfoneEmberConstantsEventType['default'].SharedCallbackEvent, this.enableCallBack);
    },

    bindClickToCall: function bindClickToCall() {
      var _this2 = this;

      document.addEventListener('click_to_call', function (event) {
        _this2.set('dropdownExpanded', true);
        _this2.set('searchTerm', event.detail.phoneNumber);
        _this2.search();
      });
    },
    searchContacts: function searchContacts() {
      var _this3 = this;

      this.set('isLoading', true);
      var searchTerm = this.get('searchTerm'),
          searchModel = undefined;

      var isAlphabetPresent = _freshfoneEmberUtilsSearchUtil['default'].isAlphabetPresent(searchTerm),
          queryParams = { filter: {} };

      if (!isAlphabetPresent) {
        searchTerm = _freshfoneEmberUtilsSearchUtil['default'].formatNumber(searchTerm);
      }
      queryParams.filter.queryString = searchTerm;

      if (this.get('hasOmniFeatureEnabled')) {
        queryParams.product = this.get('account.signupApp');
        queryParams.version = _freshfoneEmberConstantsSearchQueryConstants['default'].version;
        searchModel = _freshfoneEmberConstantsSearchQueryConstants['default'].searchModel.externalContact;
      } else {
        queryParams.source = _freshfoneEmberConstantsSearchQueryConstants['default'].source.dialler;
        searchModel = _freshfoneEmberConstantsSearchQueryConstants['default'].searchModel.contact;
      }
      this.store.query(searchModel, queryParams).then(function (contacts) {
        _this3.set('filteredContactList', contacts);
        debounce(_this3, _this3.bindHover, 300);
        _this3.set('isLoading', false);
      });
    },

    bindHover: function bindHover() {
      $('.row:first').toggleClass('hover');
      $('.row').hover(function () {
        isPresent($('.row.hover')) && $('.row.hover').removeClass('hover');
        $(this).addClass('hover');
      }, function () {
        $(this).removeClass('hover');
      });
    },

    makeCall: function makeCall(recordType) {
      this.set('isDialing', true);
      var callParams = this.get('callParams');

      var callParamsHash = {
        callerId: callParams.callerId,
        location: callParams.location,
        phone_number: callParams.phone_number,
        recipientName: callParams.recipientName,
        type: callParams.type,
        record_type: recordType ? Number(recordType) : this.get('activeFreshfoneNumber').attributes['record-type'],
        FCNumber: this.get('activeFreshfoneNumber').attributes['number']
      };

      if (callParams.callId) {
        callParamsHash["callId"] = callParams.callId;
      }
      if (callParams.contactId) {
        callParamsHash["contactId"] = callParams.contactId;
      }
      if (callParams.powerDialerListId) {
        callParamsHash["powerDialerListId"] = callParams.powerDialerListId;
        callParamsHash["powerDialer"] = true;
      }
      if (!this.get('hasParkingEnabled')) {
        this.get('telephony').setCurrentCallContext(callParams.contactId, callParams.phone_number);
      }
      this.get('telephony').makeCall(callParamsHash);
      this.bindCallEvent();
    },

    bindCallEvent: function bindCallEvent() {
      var _this4 = this;

      this.get('telephony.event').subscribe('status_update', function (data) {
        var parsedata = JSON.parse(data);

        if (_this4.get('isDialing') && parsedata.notification_type && parsedata.notification_type === 'simultaneous_accept') {
          _this4.closeDialPad();
        }
      });
    },

    closeDialPad: function closeDialPad() {
      this.set('isDialing', false);
      this.set('dropdownExpanded', false);
      this.set('showNewOnboardingFlow', false);
      this.set('searchTerm', '');
    },

    clickToCall: function clickToCall(phoneNumber) {
      this.set('searchTerm', phoneNumber);
    },

    getPhoneLocation: function getPhoneLocation() {
      var selectedCountry = this.getSelectedCountryData();
      return selectedCountry.name;
    },

    setDialNumber: function setDialNumber(number) {
      $('#searchTerm').intlTelInput('setNumber', number);
      this.set('searchTerm', number);
    },

    buildCallParams: function buildCallParams(callerName, callerNumber, contactId, callId, powerDialerListId) {
      var formattedNumber = this.get('telephony.util').formatNumber(callerNumber);
      var params = {
        recipientName: callerName,
        location: this.getPhoneLocation(),
        callerId: this.get('activeFreshfoneNumber.id'),
        phone_number: formattedNumber,
        type: 'outgoing'
      };
      if (callId) {
        params.callId = callId;
      }
      if (contactId) {
        params.contactId = contactId;
      }
      if (powerDialerListId) {
        params.powerDialer = true;
        params.powerDialerListId = powerDialerListId;
      }
      return params;
    },

    getSelectedCountryData: function getSelectedCountryData() {
      return $('.intl-tel-input input').intlTelInput('getSelectedCountryData');
    },

    initializeParams: function initializeParams(contactName, contactNumber, contactId, callId, powerDialerListId) {
      this.setDialNumber(contactNumber);
      var callParams = this.buildCallParams(contactName, contactNumber, contactId, callId, powerDialerListId);
      this.set('callParams', callParams);
    },

    search: function search() {
      this.set('isInvalidNumber', false);
      return this.get('isSearch') && debounce(this, this.searchContacts, 400);
    },

    numbersUpdatedCallback: function numbersUpdatedCallback() {
      this.fetchFreshfoneNumbers();
    },

    fetchNumbersHistory: function fetchNumbersHistory() {
      var _this5 = this;

      $.ajax({
        url: '/numbers/get_all_numbers',
        type: 'GET',
        dataType: 'json'
      }).then(function (numbers) {
        _this5.set('noNumbersHistory', numbers.data && numbers.data.length === 0);
      });
    },

    fetchListCount: function fetchListCount() {
      var _this6 = this;

      this.get('telephony.freshcallerNumber').fetchListCount().then(function (response) {
        return _this6.set('allNumbersCount', response.data.count);
      });
    },

    setMetaData: function setMetaData(numbers, isSearching) {
      var metaData = numbers.meta;

      if (metaData && isSearching) {
        this.set('searchMeta', metaData);
      } else if (metaData) {
        metaData.total_count = this.get('allNumbersCount');
        this.set('totalMeta', metaData);
      }
    },

    fetchFreshfoneNumbers: function fetchFreshfoneNumbers(isFetchingMore) {
      var _this7 = this;

      var queryParams = arguments.length <= 1 || arguments[1] === undefined ? { page_token: 0 } : arguments[1];

      var isFirstPage = queryParams.page_token === 0,
          isSearching = isPresent(queryParams.filter),
          isIntialLoading = isFirstPage && !isSearching;

      this.set('numbersLoaded', false);
      if (this.hasGlobalFeature('accessible_numbers_v2') && isIntialLoading) {
        this.fetchListCount();
      }
      if ((this.get('currentUser.isAccountAdmin') || this.get('currentUser.isAdmin')) && isIntialLoading) {
        this.fetchNumbersHistory();
      }
      this.get('telephony.freshcallerNumber').fetchList(queryParams).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1);

        var numbers = _ref2[0];

        run(function () {
          var numbersAsEmberObjects = numbers.data.map(function (number) {
            return Object.create(number);
          });

          if (isSearching) {
            var searchResults = isFetchingMore ? [].concat(_toConsumableArray(_this7.get('searchedNumbersList')), _toConsumableArray(numbersAsEmberObjects)) : numbersAsEmberObjects;

            _this7.set('searchedNumbersList', searchResults);
          } else {
            numbersAsEmberObjects = isFetchingMore ? [].concat(_toConsumableArray(_this7.get('freshfoneNumbersList')), _toConsumableArray(numbersAsEmberObjects)) : numbersAsEmberObjects;

            _this7.updateActiveNumbers(numbersAsEmberObjects);
          }
          if (numbers.data.length) {
            _this7.store.pushPayload({ data: numbers.data });
          }
          _this7.setMetaData(numbers, isSearching);
          _this7.set('numbersLoaded', true);
        });
      });
    },

    updateActiveNumbers: function updateActiveNumbers(numbersAsEmberObjects) {
      var _this8 = this;

      if (numbersAsEmberObjects && numbersAsEmberObjects.length) {
        this.set('freshfoneNumbersList', numbersAsEmberObjects);

        var outgoingNumberId = this.get('currentAgent.outgoingNumberId'),
            activeNumber = numbersAsEmberObjects.findBy('id', outgoingNumberId);

        if (activeNumber) {
          this.set('activeFreshfoneNumber', activeNumber);
        } else {
          this.store.findRecord('number', outgoingNumberId).then(function (number) {
            _this8.set('activeFreshfoneNumber', number);
          });
        }
      }
    },
    checkIfNoTestConnectionEligibilty: function checkIfNoTestConnectionEligibilty() {
      // return true if onboarding skipped
      if (!this.get('session.user.sourceAppId') && this.get('session.user.account_creator') && this.get('session').get('isOnboardingFlowInitiated') && !this.get('session.account.onboarding_completed')) {
        return true;
      }

      var emailSignUpEligibilty = this.hasGlobalFeature('plg_onboarding_email_signup') && this.get('session.account.signup_type') === _freshfoneEmberConstantsAccount['default'].signInType.email,
          gmailSignUpEligibilty = this.hasGlobalFeature('plg_onboarding_google_signup') && this.get('session.account.signup_type') === _freshfoneEmberConstantsAccount['default'].signInType.gmail,
          signInEligibilty = emailSignUpEligibilty || gmailSignUpEligibilty;

      return !this.get('session.user.sourceAppId') && signInEligibilty;
    },

    validateNumberAndMakeCall: function validateNumberAndMakeCall(number, callSource, callId, powerDialerParams, recordType) {
      var _this9 = this;

      var additionalParams = { fcNumberId: this.get('activeFreshfoneNumber').attributes.id },
          params = this.get('isAgentInPowerDialerMode') ? powerDialerParams : callId;
      this.set('isDialing', true);
      this.get('analyticsService').addEvent('Call Made');
      this.get('telephony.util').isValidNumber(number, params, additionalParams).then(function () {
        run(function () {
          _this9.makeCall(recordType);
        });
      })['catch'](function (error) {
        _this9.set('isInvalidNumber', true);
        _this9.set('errorMessage', error.responseJSON['error']);
        if (_this9.get('isAgentInPowerDialerMode')) {
          _this9.set('invalidPowerDialerContact', true);
          _this9.set('powerDialerService.removeContact', true);
          if (!_this9.get('lastPowerDialerContact')) {
            _this9.send('fetchNext', true);
          } else {
            _this9.set('dialedContacts', _this9.get('dialedContacts') + 1);
            var customPowerDialer = _this9.get('powerDialerService').getCustomPowerDialer();
            customPowerDialer.set('leftContacts', customPowerDialer.get('leftContacts') - 1);
            _this9.get('powerDialerService').set('customPowerDialer', customPowerDialer);
          }
        }
        _this9.get('recordTypeService').setCallBackCall(false);
      });
      if (this.get('isCallbackCall')) {
        this.set('isCallbackCall', false);
      }
      this.set('activeTab', this.get('isCallBackStandaloneUserOrNotBundled') ? 'recentCalls' : 'powerDialer');
    },

    actions: {
      directDial: function directDial() {
        this.send('initiateCall', '', this.get('directDialNumber'));
      },

      makeCallByClick: function makeCallByClick(name, phone) {
        var contactId = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
        var powerDialerParams = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];

        this.send('initiateCall', name, phone, contactId, null, powerDialerParams);
      },

      initiateCall: function initiateCall(name, number, contactId, callSource, powerDialerParams) {
        var callId = '',
            recordType = undefined;

        var inputOutgoing = $('.input-outgoing #searchTerm');

        if (inputOutgoing.length && inputOutgoing.attr('data-callId')) {
          callId = inputOutgoing.attr('data-callId');
          inputOutgoing.removeAttr("data-callId");
          this.get('recordTypeService').setCallBackCall(true);
          recordType = inputOutgoing.attr('data-recordType');
          inputOutgoing.removeAttr('data-recordType');
        }
        // eslint-disable-next-line max-len
        this.initializeParams(name, number.trim(), contactId, callId, powerDialerParams ? powerDialerParams.powerDialerListId : null);
        this.validateNumberAndMakeCall(number.trim(), callSource, callId, powerDialerParams, recordType);
      },

      bindSearch: function bindSearch() {
        this.search();
      },

      resetDialPad: function resetDialPad() {
        this.set('isDialing', false);
        this.set('isInvalidNumber', false);
        this.set('searchTerm', '');
        debounce(this, function () {
          $('#searchTerm').focus();
        }, 300);
      },

      toggleOutgoingCard: function toggleOutgoingCard() {
        if (this.get('disableCallIcon')) {
          return;
        }

        if (this.get('micPermPromptState') && !this.get('dropdownExpanded')) {
          this.get('browserService').getMicrophonePermission();
        }

        this.get('analyticsService').addEvent('Dialer clicked');
        this.get('closeCallRatingModule')();
        this.toggleProperty('dropdownExpanded');
        this.set('showNewOnboardingFlow', false);
        if (this.get('isFirstCall') && this.get('dropdownExpanded') && this.hasGlobalFeature('test_connection') && !this.checkIfNoTestConnectionEligibilty()) {
          this.set('testYourConnection', true);
        }

        return this.get('dropdownExpanded') && debounce(this, function () {
          $('#searchTerm').focus();
        }, 300);
      },

      buyPhoneCredits: function buyPhoneCredits() {
        this.toggleProperty('dropdownExpanded');
        this.set('showNewOnboardingFlow', false);
        this.get('routing').transitionTo('protected.admin.billing', [], { purchaseCreditModal: true });
      },

      buyNumbers: function buyNumbers() {
        this.toggleProperty('dropdownExpanded');
        this.set('showNewOnboardingFlow', false);
        this.set('numberPurchase', true);
      },

      closeNumbersTab: function closeNumbersTab() {
        this.set('numberPurchase', false);
      },

      fetchNext: function fetchNext(preventMakingCall) {
        var _this10 = this;

        if (this.get('invalidPowerDialerContact') && !preventMakingCall) {
          this.set('invalidPowerDialerContact', false);
          this.set('isInvalidNumber', false);
          this.set('errorMessage', null);
        }
        this.store.peekAll('power-dialer-list').get('lastObject').fetchNext().then(function (response) {
          var contactId = response.included[0].id,
              contact = _this10.get('store').peekRecord('contact', contactId);
          _this10.set('powerDialerService.contactId', response.data.id);
          _this10.store.pushPayload(response);
          var powerDialerContact = _this10.get('store').peekRecord('custom-power-dialer', response.data.id);
          _this10.get('powerDialerService').setCustomPowerDialer(powerDialerContact);
          _this10.setProperties({
            call: powerDialerContact,
            customPowerDialer: powerDialerContact,
            'currentAgent.powerDialer': true,
            dialedContacts: _this10.get('powerDialerService.totalContactsInPowerDialer') - _this10.get('powerDialerService.leftContactsInPowerDialer'),
            nextContact: contact
          });
          var contactPhone = _this10.get("call.from") ? _this10.get("call.from") : _this10.get('call.contact.contactNumber');
          var powerDialerParams = { powerDialer: true, powerDialerListId: _this10.get('call.id') };

          if (!preventMakingCall && !testing) {
            _this10.send('makeCallByClick', _this10.get("call.contact.name"), contactPhone, _this10.get('call.contact.id'), powerDialerParams);
          }
        });
      },

      getFreeNumber: function getFreeNumber() {
        this.set('getFreeNumber', true);
        this.set('dropdownExpanded', false);
      },

      closeNumberModal: function closeNumberModal(status) {
        this.set('getFreeNumber', false);

        // only show drowdown when status is true(call back is success)
        if (status) {
          this.set('dropdownExpanded', true);
          this.set('showNewOnboardingFlow', true);
        }
      },

      closeDialer: function closeDialer() {
        this.set('dropdownExpanded', false);
      },

      buyNumber: function buyNumber(freshfoneNumber) {
        var _this11 = this;

        this.set('intermediateNumber', freshfoneNumber);
        this.set('freshfoneNumber', this.store.createRecord('number', freshfoneNumber));
        this.saveNumber('freshfoneNumber', this.get('freshfoneNumber')).then(function () {
          _this11.set('numberPurchase', false);
        });
      },

      loadMoreResults: function loadMoreResults(isFetchingMore, queryParams) {
        this.fetchFreshfoneNumbers(isFetchingMore, queryParams);
      }
    }
  });
});
/* eslint-disable camelcase */
/* eslint no-extra-parens: ["error", "all", { "nestedBinaryExpressions": false }] */