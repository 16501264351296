define("freshfone-ember/components/sip-component/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 18,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("					");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-primary create-sip");
              var el2 = dom.createTextNode("\n						");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n					");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element10);
              morphs[1] = dom.createMorphAt(element10, 1, 1);
              return morphs;
            },
            statements: [["element", "action", [["subexpr", "send", [["get", "sipForm", ["loc", [null, [15, 63], [15, 70]]], 0, 0, 0, 0], "toggleSidecard"], [], ["loc", [null, [15, 57], [15, 88]]], 0, 0]], [], ["loc", [null, [15, 48], [15, 90]]], 0, 0], ["inline", "t", ["sip.newSIP"], [], ["loc", [null, [16, 6], [16, 24]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 3
              },
              "end": {
                "line": 19,
                "column": 3
              }
            },
            "moduleName": "freshfone-ember/components/sip-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ember-wormhole", [], ["to", "miscellaneous-item"], 0, null, ["loc", [null, [14, 4], [18, 23]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 4
                },
                "end": {
                  "line": 25,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("					");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n					");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n					");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              return morphs;
            },
            statements: [["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["agent_component.agent_name"], [], ["loc", [null, [22, 33], [22, 65]]], 0, 0], "class", "list-header-element col-name-header"], ["loc", [null, [22, 5], [22, 111]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "t", ["sip.sipStatus"], [], ["loc", [null, [23, 34], [23, 53]]], 0, 0], "class", "list-header-element col-sip-header"], ["loc", [null, [23, 5], [23, 98]]], 0, 0], ["inline", "ui-list-header-item", [], ["class", "list-header-element col-icons-header"], ["loc", [null, [24, 5], [24, 73]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 4
                },
                "end": {
                  "line": 31,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("					");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "loading-wrapper");
              var el2 = dom.createTextNode("\n						");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "spinner");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n					");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 5
                  },
                  "end": {
                    "line": 34,
                    "column": 5
                  }
                },
                "moduleName": "freshfone-ember/components/sip-component/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("						");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "id", "promotion-content");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 35,
                        "column": 6
                      },
                      "end": {
                        "line": 41,
                        "column": 6
                      }
                    },
                    "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("							");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "empty-state-cover", [], ["imgSrc", "assets/images/roller.svg", "description", ["subexpr", "t", ["sip.initialisedEnableAll"], [], ["loc", [null, [38, 20], [38, 50]]], 0, 0], "containerClass", "flex-col"], ["loc", [null, [36, 7], [40, 9]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 41,
                          "column": 6
                        },
                        "end": {
                          "line": 47,
                          "column": 6
                        }
                      },
                      "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("							");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "empty-state-cover", [], ["imgSrc", "assets/images/empty-states/no-result.svg", "description", ["subexpr", "t", ["sip.noSIPAgentsFound"], [], ["loc", [null, [44, 28], [44, 54]]], 0, 0], "containerClass", "flex-col"], ["loc", [null, [42, 7], [46, 16]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 48,
                            "column": 7
                          },
                          "end": {
                            "line": 56,
                            "column": 14
                          }
                        },
                        "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("button");
                        dom.setAttribute(el1, "class", "btn btn-primary create-sip");
                        var el2 = dom.createTextNode("\n                  ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element9 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createElementMorph(element9);
                        morphs[1] = dom.createMorphAt(element9, 1, 1);
                        return morphs;
                      },
                      statements: [["element", "action", ["enableAllAgents", ["get", "allAgent", ["loc", [null, [53, 51], [53, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 24], [53, 61]]], 0, 0], ["inline", "t", ["sip.newSIP"], [], ["loc", [null, [54, 18], [54, 36]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 47,
                          "column": 6
                        },
                        "end": {
                          "line": 57,
                          "column": 6
                        }
                      },
                      "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("						");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      return morphs;
                    },
                    statements: [["block", "empty-state-cover", [], ["imgSrc", "assets/images/empty-states/no-sip.svg", "description", ["subexpr", "t", ["sip.no_sip_created"], [], ["loc", [null, [50, 28], [50, 52]]], 0, 0], "containerClass", "flex-col"], 0, null, ["loc", [null, [48, 7], [56, 36]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 41,
                        "column": 6
                      },
                      "end": {
                        "line": 57,
                        "column": 6
                      }
                    },
                    "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "isSearching", ["loc", [null, [41, 16], [41, 27]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [41, 6], [57, 6]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 34,
                      "column": 5
                    },
                    "end": {
                      "line": 58,
                      "column": 5
                    }
                  },
                  "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "initialisedEnableAll", ["loc", [null, [35, 12], [35, 32]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [35, 6], [57, 13]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 58,
                        "column": 5
                      },
                      "end": {
                        "line": 64,
                        "column": 5
                      }
                    },
                    "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("						");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "empty-state-cover", [], ["imgSrc", "assets/images/roller.svg", "description", ["subexpr", "t", ["sip.initialisedEnableAll"], [], ["loc", [null, [61, 19], [61, 49]]], 0, 0], "containerClass", "flex-col"], ["loc", [null, [59, 6], [63, 8]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    var child0 = (function () {
                      var child0 = (function () {
                        var child0 = (function () {
                          return {
                            meta: {
                              "revision": "Ember@2.8.3",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 79,
                                  "column": 13
                                },
                                "end": {
                                  "line": 81,
                                  "column": 13
                                }
                              },
                              "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                            },
                            isEmpty: false,
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createTextNode("														");
                              dom.appendChild(el0, el1);
                              var el1 = dom.createElement("span");
                              dom.setAttribute(el1, "class", "activation-mail-sent");
                              var el2 = dom.createComment("");
                              dom.appendChild(el1, el2);
                              dom.appendChild(el0, el1);
                              var el1 = dom.createTextNode("\n");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                              return morphs;
                            },
                            statements: [["inline", "t", ["user.mail_sent"], [], ["loc", [null, [80, 49], [80, 71]]], 0, 0]],
                            locals: [],
                            templates: []
                          };
                        })();
                        var child1 = (function () {
                          var child0 = (function () {
                            return {
                              meta: {
                                "revision": "Ember@2.8.3",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 81,
                                    "column": 13
                                  },
                                  "end": {
                                    "line": 83,
                                    "column": 13
                                  }
                                },
                                "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                              },
                              isEmpty: false,
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("														");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createElement("a");
                                dom.setAttribute(el1, "class", "icon-warning");
                                dom.setAttribute(el1, "data-toggle", "tooltip");
                                dom.setAttribute(el1, "data-placement", "top");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createTextNode("\n");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var element1 = dom.childAt(fragment, [1]);
                                var morphs = new Array(2);
                                morphs[0] = dom.createAttrMorph(element1, 'title');
                                morphs[1] = dom.createElementMorph(element1);
                                return morphs;
                              },
                              statements: [["attribute", "title", ["concat", [["subexpr", "t", ["user.mail_send_failed"], [], ["loc", [null, [82, 88], [82, 117]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["clearError", ["get", "item", ["loc", [null, [82, 141], [82, 145]]], 0, 0, 0, 0]], [], ["loc", [null, [82, 119], [82, 147]]], 0, 0]],
                              locals: [],
                              templates: []
                            };
                          })();
                          var child1 = (function () {
                            return {
                              meta: {
                                "revision": "Ember@2.8.3",
                                "loc": {
                                  "source": null,
                                  "start": {
                                    "line": 83,
                                    "column": 13
                                  },
                                  "end": {
                                    "line": 85,
                                    "column": 13
                                  }
                                },
                                "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                              },
                              isEmpty: false,
                              arity: 0,
                              cachedFragment: null,
                              hasRendered: false,
                              buildFragment: function buildFragment(dom) {
                                var el0 = dom.createDocumentFragment();
                                var el1 = dom.createTextNode("														");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createElement("a");
                                dom.setAttribute(el1, "class", "icon-send-activation-mail");
                                dom.setAttribute(el1, "data-toggle", "tooltip");
                                dom.setAttribute(el1, "data-placement", "top");
                                dom.appendChild(el0, el1);
                                var el1 = dom.createTextNode("\n													");
                                dom.appendChild(el0, el1);
                                return el0;
                              },
                              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                                var element0 = dom.childAt(fragment, [1]);
                                var morphs = new Array(2);
                                morphs[0] = dom.createAttrMorph(element0, 'title');
                                morphs[1] = dom.createElementMorph(element0);
                                return morphs;
                              },
                              statements: [["attribute", "title", ["concat", [["subexpr", "t", ["sip.sipForm.resendSIPCredentials"], [], ["loc", [null, [84, 101], [84, 141]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["resendSIPCredential", ["get", "item", ["loc", [null, [84, 174], [84, 178]]], 0, 0, 0, 0]], [], ["loc", [null, [84, 143], [84, 180]]], 0, 0]],
                              locals: [],
                              templates: []
                            };
                          })();
                          return {
                            meta: {
                              "revision": "Ember@2.8.3",
                              "loc": {
                                "source": null,
                                "start": {
                                  "line": 81,
                                  "column": 13
                                },
                                "end": {
                                  "line": 85,
                                  "column": 13
                                }
                              },
                              "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                            },
                            isEmpty: false,
                            arity: 0,
                            cachedFragment: null,
                            hasRendered: false,
                            buildFragment: function buildFragment(dom) {
                              var el0 = dom.createDocumentFragment();
                              var el1 = dom.createComment("");
                              dom.appendChild(el0, el1);
                              return el0;
                            },
                            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                              var morphs = new Array(1);
                              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                              dom.insertBoundary(fragment, 0);
                              dom.insertBoundary(fragment, null);
                              return morphs;
                            },
                            statements: [["block", "if", [["get", "item.mailError", ["loc", [null, [81, 23], [81, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [81, 13], [85, 13]]]]],
                            locals: [],
                            templates: [child0, child1]
                          };
                        })();
                        return {
                          meta: {
                            "revision": "Ember@2.8.3",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 78,
                                "column": 12
                              },
                              "end": {
                                "line": 86,
                                "column": 12
                              }
                            },
                            "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                          },
                          isEmpty: false,
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                            dom.insertBoundary(fragment, 0);
                            dom.insertBoundary(fragment, null);
                            return morphs;
                          },
                          statements: [["block", "if", [["get", "item.credentialsSent", ["loc", [null, [79, 19], [79, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [79, 13], [85, 20]]]]],
                          locals: [],
                          templates: [child0, child1]
                        };
                      })();
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 77,
                              "column": 11
                            },
                            "end": {
                              "line": 89,
                              "column": 11
                            }
                          },
                          "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("												");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("a");
                          dom.setAttribute(el1, "class", "icon-edit");
                          dom.setAttribute(el1, "data-toggle", "tooltip");
                          dom.setAttribute(el1, "data-placement", "top");
                          dom.setAttribute(el1, "data-analytics-action-name", "Edit SIP");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n													");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("a");
                          dom.setAttribute(el1, "class", "icon-delete");
                          dom.setAttribute(el1, "data-toggle", "tooltip");
                          dom.setAttribute(el1, "data-placement", "top");
                          dom.setAttribute(el1, "data-analytics-action-name", "Delete SIP");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element2 = dom.childAt(fragment, [2]);
                          var element3 = dom.childAt(fragment, [4]);
                          var morphs = new Array(5);
                          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                          morphs[1] = dom.createAttrMorph(element2, 'title');
                          morphs[2] = dom.createElementMorph(element2);
                          morphs[3] = dom.createAttrMorph(element3, 'title');
                          morphs[4] = dom.createElementMorph(element3);
                          dom.insertBoundary(fragment, 0);
                          return morphs;
                        },
                        statements: [["block", "unless", [["get", "item.isConfirmed", ["loc", [null, [78, 22], [78, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [78, 12], [86, 23]]]], ["attribute", "title", ["subexpr", "t", ["common.edit"], [], ["loc", [null, [null, null], [87, 101]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["edit", ["get", "item", ["loc", [null, [87, 118], [87, 122]]], 0, 0, 0, 0]], [], ["loc", [null, [87, 102], [87, 124]]], 0, 0], ["attribute", "title", ["subexpr", "t", ["common.delete"], [], ["loc", [null, [null, null], [88, 106]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleDelete", ["get", "item", ["loc", [null, [88, 131], [88, 135]]], 0, 0, 0, 0]], [], ["loc", [null, [88, 107], [88, 137]]], 0, 0]],
                        locals: [],
                        templates: [child0]
                      };
                    })();
                    var child1 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 91,
                              "column": 10
                            },
                            "end": {
                              "line": 93,
                              "column": 19
                            }
                          },
                          "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                  		");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                          return morphs;
                        },
                        statements: [["inline", "component", ["custom-elements/delete-model"], ["deleteCallback", ["subexpr", "action", ["disableSIP", ["get", "item", ["loc", [null, [92, 99], [92, 103]]], 0, 0, 0, 0]], [], ["loc", [null, [92, 78], [92, 104]]], 0, 0], "model", ["subexpr", "@mut", [["get", "item", ["loc", [null, [92, 111], [92, 115]]], 0, 0, 0, 0]], [], [], 0, 0], "onModify", ["subexpr", "action", ["onModify"], [], ["loc", [null, [92, 125], [92, 144]]], 0, 0]], ["loc", [null, [92, 20], [92, 146]]], 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 66,
                            "column": 7
                          },
                          "end": {
                            "line": 97,
                            "column": 7
                          }
                        },
                        "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("								");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("tr");
                        var el2 = dom.createTextNode("\n									");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("td");
                        dom.setAttribute(el2, "class", "col-name");
                        var el3 = dom.createTextNode("\n										");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n										");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("a");
                        dom.setAttribute(el3, "class", "name");
                        var el4 = dom.createComment("");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n									");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n									");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("td");
                        dom.setAttribute(el2, "class", "col-sip");
                        var el3 = dom.createTextNode("\n										");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n									");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n									");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("td");
                        dom.setAttribute(el2, "class", "col-icons");
                        var el3 = dom.createTextNode("\n										");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("div");
                        var el4 = dom.createTextNode("\n");
                        dom.appendChild(el3, el4);
                        var el4 = dom.createComment("");
                        dom.appendChild(el3, el4);
                        var el4 = dom.createTextNode("										");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("									");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n								");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n								");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("hr");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element4 = dom.childAt(fragment, [1]);
                        var element5 = dom.childAt(element4, [1]);
                        var element6 = dom.childAt(element5, [3]);
                        var element7 = dom.childAt(element4, [5]);
                        var element8 = dom.childAt(element7, [1]);
                        var morphs = new Array(8);
                        morphs[0] = dom.createAttrMorph(element4, 'class');
                        morphs[1] = dom.createMorphAt(element5, 1, 1);
                        morphs[2] = dom.createElementMorph(element6);
                        morphs[3] = dom.createMorphAt(element6, 0, 0);
                        morphs[4] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
                        morphs[5] = dom.createAttrMorph(element8, 'class');
                        morphs[6] = dom.createMorphAt(element8, 1, 1);
                        morphs[7] = dom.createMorphAt(element7, 3, 3);
                        return morphs;
                      },
                      statements: [["attribute", "class", ["concat", ["record ", ["subexpr", "if", [["get", "item.isFlash", ["loc", [null, [67, 31], [67, 43]]], 0, 0, 0, 0], "flash"], [], ["loc", [null, [67, 26], [67, 53]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "custom-elements/avatar", [], ["class", "avatar", "type", "user", "charLimit", 1, "model", ["subexpr", "@mut", [["get", "item", ["loc", [null, [69, 80], [69, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [69, 10], [69, 96]]], 0, 0], ["element", "action", ["edit", ["get", "item", ["loc", [null, [70, 42], [70, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 26], [70, 48]]], 0, 0], ["content", "item.name", ["loc", [null, [70, 49], [70, 62]]], 0, 0, 0, 0], ["inline", "common/inline-list", [], ["value", ["subexpr", "@mut", [["get", "item.sipStatus", ["loc", [null, [73, 37], [73, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "item.sipStatuses", ["loc", [null, [73, 60], [73, 76]]], 0, 0, 0, 0]], [], [], 0, 0], "matcher", "value", "selectCallback", ["subexpr", "action", ["updateSIPStatus", ["get", "item", ["loc", [null, [73, 134], [73, 138]]], 0, 0, 0, 0]], [], ["loc", [null, [73, 108], [73, 139]]], 0, 0]], ["loc", [null, [73, 10], [73, 141]]], 0, 0], ["attribute", "class", ["concat", ["option-buttons  ", ["subexpr", "if", [["get", "item.isDeleting", ["loc", [null, [76, 43], [76, 58]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [76, 38], [76, 67]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "can-edit", [["get", "session.user", ["loc", [null, [77, 27], [77, 39]]], 0, 0, 0, 0], ["get", "item", ["loc", [null, [77, 40], [77, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [77, 17], [77, 45]]], 0, 0]], [], 0, null, ["loc", [null, [77, 11], [89, 18]]]], ["block", "if", [["get", "item.isDeleting", ["loc", [null, [91, 16], [91, 31]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [91, 10], [93, 26]]]]],
                      locals: [],
                      templates: [child0, child1]
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 65,
                          "column": 6
                        },
                        "end": {
                          "line": 98,
                          "column": 6
                        }
                      },
                      "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                    },
                    isEmpty: false,
                    arity: 1,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "unless", [["subexpr", "and", [["get", "item.isNew", ["loc", [null, [66, 22], [66, 32]]], 0, 0, 0, 0], ["get", "item.deleted", ["loc", [null, [66, 33], [66, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [66, 17], [66, 46]]], 0, 0]], [], 0, null, ["loc", [null, [66, 7], [97, 18]]]]],
                    locals: ["item"],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 64,
                        "column": 5
                      },
                      "end": {
                        "line": 99,
                        "column": 5
                      }
                    },
                    "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("					");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["block", "ui-list-item", [], ["class", "list-body", "items", ["subexpr", "@mut", [["get", "model", ["loc", [null, [65, 46], [65, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [65, 6], [98, 23]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 5
                    },
                    "end": {
                      "line": 99,
                      "column": 5
                    }
                  },
                  "moduleName": "freshfone-ember/components/sip-component/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "initialisedEnableAll", ["loc", [null, [58, 15], [58, 35]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [58, 5], [99, 5]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 5
                  },
                  "end": {
                    "line": 99,
                    "column": 5
                  }
                },
                "moduleName": "freshfone-ember/components/sip-component/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "hasEmptyList", ["loc", [null, [34, 15], [34, 27]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [34, 5], [99, 5]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 4
                },
                "end": {
                  "line": 100,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/sip-component/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "promoting", ["loc", [null, [32, 11], [32, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [32, 5], [99, 12]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 3
              },
              "end": {
                "line": 101,
                "column": 3
              }
            },
            "moduleName": "freshfone-ember/components/sip-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ui-list-header", [], ["class", "list-header"], 0, null, ["loc", [null, [21, 4], [25, 23]]]], ["block", "if", [["get", "listLoading", ["loc", [null, [27, 10], [27, 21]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [27, 4], [100, 11]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 102,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/sip-component/template.hbs"
        },
        isEmpty: false,
        arity: 4,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "promoting", ["loc", [null, [13, 19], [13, 28]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 14], [13, 29]]], 0, 0], ["get", "entitiesExistInTheFirstPlace", ["loc", [null, [13, 30], [13, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 9], [13, 59]]], 0, 0]], [], 0, null, ["loc", [null, [13, 3], [19, 10]]]], ["block", "ui-list", [], ["class", "list-container has-stretchable-rows"], 1, null, ["loc", [null, [20, 3], [101, 15]]]]],
        locals: ["listLoading", "hasEmptyList", "isSearching", "entitiesExistInTheFirstPlace"],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 2
              },
              "end": {
                "line": 126,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/sip-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "sip-component/sip-form", [], ["initialisedEnableAll", ["subexpr", "@mut", [["get", "initialisedEnableAll", ["loc", [null, [119, 27], [119, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "agent", ["subexpr", "@mut", [["get", "agent", ["loc", [null, [120, 12], [120, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "resendSIP", ["subexpr", "action", ["resendSIPCredential"], [], ["loc", [null, [121, 16], [121, 46]]], 0, 0], "onModify", ["subexpr", "action", ["onModify"], [], ["loc", [null, [122, 15], [122, 34]]], 0, 0], "slideToggle", ["subexpr", "@mut", [["get", "slideToggle", ["loc", [null, [123, 18], [123, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "actionReceiver", ["subexpr", "@mut", [["get", "sipForm", ["loc", [null, [124, 21], [124, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [118, 4], [125, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 0
            },
            "end": {
              "line": 127,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/sip-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ui-modal", [], ["class", "sidebar-content-modal", "size", "md", "animateClose", true, "isModalOpen", ["subexpr", "@mut", [["get", "slideToggle", ["loc", [null, [111, 16], [111, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "showCloseOnTopLeft", true, "closeOnEscape", true, "closeOnBackdropClick", true, "sliderModal", true, "onClose", ["subexpr", "action", [["subexpr", "send", [["get", "sipForm", ["loc", [null, [116, 26], [116, 33]]], 0, 0, 0, 0], "toggleSidecard"], [], ["loc", [null, [116, 20], [116, 51]]], 0, 0]], [], ["loc", [null, [116, 12], [116, 52]]], 0, 0]], 0, null, ["loc", [null, [107, 2], [126, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 128,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/sip-component/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-12 admin-main-content full-width");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "admin-main-content-list");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "paginate-search", [], ["title", ["subexpr", "t", ["admin_components.SipPhone"], [], ["loc", [null, [4, 9], [4, 40]]], 0, 0], "supportPortalLink", ["subexpr", "support-portal-link", ["knowingAndEnablingSip"], [], ["loc", [null, [5, 21], [5, 66]]], 0, 0], "searchDisabled", ["subexpr", "@mut", [["get", "searchDisabled", ["loc", [null, [6, 18], [6, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["sip.search_placeholder"], [], ["loc", [null, [7, 15], [7, 43]]], 0, 0], "modelName", "agent", "filter", ["subexpr", "@mut", [["get", "filterParams", ["loc", [null, [9, 10], [9, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [10, 9], [10, 14]]], 0, 0, 0, 0]], [], [], 0, 0], "modifyModel", ["subexpr", "@mut", [["get", "modifyModel", ["loc", [null, [11, 15], [11, 26]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [3, 2], [102, 22]]]], ["block", "ember-wormhole", [], ["to", "modal-wormhole"], 1, null, ["loc", [null, [106, 0], [127, 19]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});