define('freshfone-ember/components/get-number/component', ['exports', 'ember', 'freshfone-ember/mixins/number-mixin', 'freshfone-ember/constants/account', 'freshfone-ember/utils/freshfone-number-util'], function (exports, _ember, _freshfoneEmberMixinsNumberMixin, _freshfoneEmberConstantsAccount, _freshfoneEmberUtilsFreshfoneNumberUtil) {
  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var later = _ember['default'].run.later;
  var set = _ember['default'].set;
  exports['default'] = Component.extend(_freshfoneEmberMixinsNumberMixin['default'], {
    i18n: service(),
    notification: service('toastr'),
    routing: service('-routing'),
    defaultCode: '+1',
    isResultLoading: false,
    isNumberListDisabled: false,
    countries: _freshfoneEmberUtilsFreshfoneNumberUtil['default'].localCountries(),
    country: computed('countries', function () {
      return this.get('countries').findBy('code', 'US');
    }),
    localRate: computed('country.code', function () {
      var country = this.get('country.code');

      return _freshfoneEmberUtilsFreshfoneNumberUtil['default'].rate('local', country);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchNumberList('/numbers/available');
      this.get('session').setOnboardinFlowStatus(true);
    },
    // building params to get the numbers available
    buildSearchParams: function buildSearchParams() {
      return {
        type: 'local',
        country: 'US'
      };
    },
    // store/save number to be purchased
    purchaseNumber: function purchaseNumber(freshfoneNumber, index) {
      this.setNumberPurchaseState(index, { loading: true });
      this.setProperties({ freshfoneNumber: this.store.createRecord('number', freshfoneNumber) });
      this.purchaseAction(this.get('freshfoneNumber'), index);
    },

    updateOnboardingStatusAndRedirect: function updateOnboardingStatusAndRedirect(status) {
      var _this = this;

      var accounts = this.store.peekAll('account'),
          accountDetails = accounts.objectAt(0);

      accountDetails.set('onboarding_completed', status);

      this.get('session').setMicrophonePermissionRequired(true);

      if (this.get('isRequestFromWidget')) {
        later(function () {
          return _this.sendAction('closeCallback', true);
        }, 500);
      }

      this.get('routing').transitionTo('protected.call-logs.index');
    },

    purchaseAction: function purchaseAction(freshfoneNumber, index) {
      var _this2 = this;

      return this.saveNumber('number', freshfoneNumber, true).then(function () {
        run(function () {
          _this2.setNumberPurchaseState(index, {
            loading: false,
            purchaseSuccess: true
          });
          _this2.updateOnboardingStatusAndRedirect(true);
        });
      })['catch'](function () {
        _this2.setNumberPurchaseState(index, {
          loading: false,
          purchaseError: true
        });
        _this2.get('notification').error(_this2.get('i18n').t('onboarding.purchase_error_message'));
      })['finally'](function () {
        return _this2.set('isNumberListDisabled', false);
      });
    },

    // prepare ajax call for available numbers
    getNumbers: function getNumbers(url, params) {
      return $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        // eslint-disable-next-line camelcase
        data: { search_options: params }
      });
    },

    resetDisabledState: function resetDisabledState() {
      for (var i = 0; i < this.get('availableNumbers').length; i++) {
        this.setNumberPurchaseState(i, { purchaseError: false });
      }
    },

    setNumberPurchaseState: function setNumberPurchaseState(id, params) {
      var item = this.get('availableNumbers')[id];

      // iteration to support multiple key update
      for (var key in params) {
        set(item, key, params[key]);
      }
    },

    // fetch the list of numbers available from numbers endpoint
    fetchNumberList: function fetchNumberList(url) {
      var _this3 = this;

      var params = this.buildSearchParams();

      this.set('isResultLoading', true);

      this.getNumbers(url, params).done(function (resp) {
        // run loop is implemented for testing environment
        run(function () {
          var numbers = Array.isArray(resp) ? resp.slice(0, 3) : [];

          _this3.set('availableNumbers', numbers);
        });
      }).fail(function () {
        return _this3.set('availableNumbers', []);
      }).always(function () {
        return run(function () {
          return _this3.set('isResultLoading', false);
        });
      });
    },

    // set the extra params to be sent for purchasing numbers
    setPurchaseParams: function setPurchaseParams(params) {
      params.rate = this.get('localRate');
      params.address = {};
      params.source = _freshfoneEmberConstantsAccount['default'].source.onboarding;
    },

    actions: {
      purchase: function purchase(params, index) {
        this.set('isNumberListDisabled', true);
        this.resetDisabledState();
        this.setPurchaseParams(params);
        this.purchaseNumber(params, index);
      },

      closeAction: function closeAction() {
        this.sendAction('closeCallback');
      }
    }

  });
});