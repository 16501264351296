define('freshfone-ember/components/call-queue/queue-form/advanced-wait-queue-settings/component', ['exports', 'ember', 'freshfone-ember/helpers/message-default-types'], function (exports, _ember, _freshfoneEmberHelpersMessageDefaultTypes) {
  var computed = _ember['default'].computed;
  var $ = _ember['default'].$;

  var QUEUE_DEFAULTS = {
    routeOptionMins: 30,
    routeOption: 'all',
    waitQueueSize: 5,
    busyMaxWaitTime: 60
  };

  exports['default'] = _ember['default'].Component.extend({
    waitQueueOption: computed('queue.actionOptions', function () {
      return this.get('queue.actionOptions').rejectBy('id', 4);
    }),
    getAllDefaultMessages: function getAllDefaultMessages() {
      var messages = this.store.peekAll('message');
      var welcome = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage)[0];
      var hangup = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage)[0];
      var voicemail = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage)[0];
      var callbackMessage = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].callbackMessage)[0];
      var callbackConfirmationMessage = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].callbackConfirmationMessage)[0];
      return {
        welcome: welcome,
        hangup: hangup,
        voicemail: voicemail,
        callbackMessage: callbackMessage,
        callbackConfirmationMessage: callbackConfirmationMessage
      };
    },

    actions: {
      showNewMessage: function showNewMessage(attr) {
        this.sendAction('showNewMessage', attr);
      },
      waitQueueMessageToggled: function waitQueueMessageToggled() {
        if (this.get('queue.queuePositionEnabled') && !this.get('queue.busyQueuePositionMessage')) {
          this.set('queue.busyQueuePositionMessage', 'Your queue position is {{queue.position}}');
        }
      },
      setDefaultMsg: function setDefaultMsg(queueType, id) {
        this.sendAction('setDefaultMsg', queueType, id);
      },
      showWaitQueueSettings: function showWaitQueueSettings() {
        this.toggleProperty('showWaitQueueSettings');
        if (this.get('showWaitQueueSettings')) {
          if (this.get('queue.isChild')) {
            $(".flow-container").animate({ scrollTop: 500 + this.get('queue.childPosY') }, 500);
          } else {
            $(".flow-container").animate({ scrollTop: 1500 }, 500);
          }
        }
        if (!this.get('queue.waitQueueEnabled')) {
          this.send('getWaitQueueDefaults');
        }
      },
      getWaitQueueDefaults: function getWaitQueueDefaults() {
        if (!this.get('queue.waitQueueEnabled')) {
          var msjObj = this.getAllDefaultMessages();
          this.setProperties({
            'queue.queueFullMessage': msjObj.hangup,
            'queue.timeThresholdMessage': msjObj.hangup,
            'queue.quitQueueMessage': msjObj.voicemail,
            'queue.busyMessage': msjObj.welcome,
            'queue.waitQueueSize': QUEUE_DEFAULTS.waitQueueSize,
            'queue.busyMaxWaitTime': QUEUE_DEFAULTS.busyMaxWaitTime,
            'queue.callbackFailMessage': msjObj.hangup
          });
        }
      }
    }
  });
});