define("freshfone-ember/components/deflection-bot/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 4,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/deflection-bot/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "loading-wrapper");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "spinner");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["deflection_bot.loading"], [], ["loc", [null, [3, 57], [3, 87]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 12
              },
              "end": {
                "line": 18,
                "column": 12
              }
            },
            "moduleName": "freshfone-ember/components/deflection-bot/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["common.cancel"], [], ["loc", [null, [17, 21], [17, 42]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 10
              },
              "end": {
                "line": 22,
                "column": 10
              }
            },
            "moduleName": "freshfone-ember/components/deflection-bot/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["common.saving"], [], ["loc", [null, [21, 12], [21, 33]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 10
              },
              "end": {
                "line": 24,
                "column": 10
              }
            },
            "moduleName": "freshfone-ember/components/deflection-bot/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["common.save"], [], ["loc", [null, [23, 12], [23, 31]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 12
              },
              "end": {
                "line": 31,
                "column": 12
              }
            },
            "moduleName": "freshfone-ember/components/deflection-bot/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "warning-info-wrapper");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "icon-warning");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  \n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "validation-msg ");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["deflection_bot.flow_incomplete"], [], ["loc", [null, [29, 50], [29, 88]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 33,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/deflection-bot/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "flow-actions");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          morphs[2] = dom.createAttrMorph(element1, 'class');
          morphs[3] = dom.createElementMorph(element1);
          morphs[4] = dom.createMorphAt(element1, 1, 1);
          morphs[5] = dom.createMorphAt(element0, 5, 5);
          return morphs;
        },
        statements: [["inline", "deflection-bot/deflection-bot-root", [], ["deflectionBot", ["subexpr", "@mut", [["get", "callFlow.deflectionBot", ["loc", [null, [7, 26], [7, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "startValidate", ["subexpr", "@mut", [["get", "startValidate", ["loc", [null, [8, 26], [8, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "conversationEndAction", ["subexpr", "@mut", [["get", "conversationEndAction", ["loc", [null, [10, 34], [10, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "agentDeflectAction", ["subexpr", "@mut", [["get", "agentDeflectAction", ["loc", [null, [11, 31], [11, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "botFailureAction", ["subexpr", "@mut", [["get", "botFailureAction", ["loc", [null, [12, 29], [12, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "invalidInputAction", ["subexpr", "@mut", [["get", "invalidInputAction", ["loc", [null, [13, 31], [13, 49]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 8], [14, 10]]], 0, 0], ["block", "link-to", [["get", "redirectionURL", ["loc", [null, [16, 24], [16, 38]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["callFlow", ["subexpr", "if", [["get", "rule", ["loc", [null, [16, 66], [16, 70]]], 0, 0, 0, 0], true], [], ["loc", [null, [16, 62], [16, 76]]], 0, 0]], ["loc", [null, [16, 39], [16, 77]]], 0, 0]], ["class", "btn btn-cancel"], 0, null, ["loc", [null, [16, 12], [18, 24]]]], ["attribute", "class", ["concat", ["btn btn-primary btn-submit ", ["subexpr", "if", [["get", "isSaving", ["loc", [null, [19, 52], [19, 60]]], 0, 0, 0, 0], "disabled none-pointer"], [], ["loc", [null, [19, 47], [19, 86]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["save"], [], ["loc", [null, [19, 88], [19, 105]]], 0, 0], ["block", "if", [["get", "isSaving", ["loc", [null, [20, 16], [20, 24]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [20, 10], [24, 17]]]], ["block", "if", [["get", "isFlowIncomplete", ["loc", [null, [26, 18], [26, 34]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [26, 12], [31, 19]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/deflection-bot/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "flow-container root");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "showLoader", ["loc", [null, [2, 10], [2, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 4], [33, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});