define('freshfone-ember/serializers/number', ['exports', 'freshfone-ember/serializers/application', 'freshfone-ember/mixins/serializers/embedded-records'], function (exports, _freshfoneEmberSerializersApplication, _freshfoneEmberMixinsSerializersEmbeddedRecords) {
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    attrs: {
      deleted: { serialize: false },
      numberTranscriptionFlag: { embedded: 'always' }
      // eslint-disable-next-line object-curly-newline
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.source = json.data.attributes.source;

      delete json.data.attributes.source;

      return json;
    }
  });
});