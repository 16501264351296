define('freshfone-ember/components/admin-items/component', ['exports', 'ember-concurrency', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'ember-component-inbound-actions/inbound-actions', 'ember-authority/helpers/has-privilege'], function (exports, _emberConcurrency, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _emberComponentInboundActionsInboundActions, _emberAuthorityHelpersHasPrivilege) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _emberComponentInboundActionsInboundActions['default'], {
    currentUser: computed.alias('session.current.user'),
    isUnifiedAppAccount: computed.alias('session.account.isUnifiedAppAccount'),
    isOmnichannelEnabled: computed.alias('session.account.isOmnichannelEnabled'),
    items: computed(function () {
      return [].concat(_toConsumableArray(this.checkNumbersEnabled()), _toConsumableArray(this.checkFeature('business_hours') && (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_business_hours',
        user: this.get('currentUser')
      }) ? [{
        title: this.get('isUnifiedAppAccount') ? 'BusinessHours_unified_app' : 'BusinessHours',
        link: 'protected.admin.businesshour',
        dataAnalyticsActionName: 'Admin Business Hours Page Load',
        iconUrl: 'assets/images/icons/admin-business-hours.svg'
      }] : []), _toConsumableArray((0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_call_queues',
        user: this.get('currentUser')
      }) ? [{
        title: 'CallWorkflows',
        link: 'protected.admin.callflows',
        dataAnalyticsActionName: 'Admin Call Queues Page Load',
        iconUrl: 'assets/images/icons/admin-call-flows.svg'
      }] : []), _toConsumableArray((0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_tags',
        user: this.get('currentUser')
      }) ? [{
        title: 'CallTags',
        link: 'protected.admin.call-tags',
        dataAnalyticsActionName: 'Admin Call Tags Page Load',
        iconUrl: 'assets/images/icons/admin-call-tags.svg'
      }] : []), _toConsumableArray((0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_messages',
        user: this.get('currentUser')
      }) ? [{
        title: this.get('isUnifiedAppAccount') ? 'Messages_unified_app' : 'Messages',
        link: 'protected.admin.messages',
        dataAnalyticsActionName: 'Admin Message Page Load',
        iconUrl: 'assets/images/icons/admin-messages.svg'
      }] : []), _toConsumableArray((0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_sip',
        user: this.get('currentUser')
      }) ? [{
        title: 'SipPhone',
        link: 'protected.admin.sip',
        dataAnalyticsActionName: 'Admin Sip Page Load',
        iconUrl: 'assets/images/icons/admin-sip.svg'
      }] : []), _toConsumableArray(this.checkAgentsEnabled()), _toConsumableArray(!(this.checkFeature('omnichannel_agent_status') && this.get('isOmnichannelEnabled')) && (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_agent_status',
        user: this.get('currentUser')
      }) && this.hasGlobalFeature('custom_agent_status') ? [{
        title: 'AgentStatuses',
        link: 'protected.admin.agent-status',
        dataAnalyticsActionName: 'Admin Agent Status Page Load',
        iconUrl: 'assets/images/icons/admin-custom-statuses.svg'
      }] : []), _toConsumableArray(this.checkTeamsEnabled()), _toConsumableArray(this.checkServiceLevelsEnabled()), _toConsumableArray(this.checkAnswerBotEnabled()), _toConsumableArray(this.checkMakpAppsEnabled()), _toConsumableArray(this.checkBillingEnabled()), [{
        title: 'account_settings',
        link: 'protected.admin.account-settings',
        dataAnalyticsActionName: 'Account Settings',
        iconUrl: 'assets/images/icons/account-settings.svg'
      }], _toConsumableArray(this.checkFeature('bulk_data_delete') && (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_schedules',
        user: this.get('currentUser')
      }) ? [{
        title: 'BulkDataDeletion',
        link: 'protected.admin.bulk-data-delete',
        dataAnalyticsActionName: 'Bulk Data Delete Page Load',
        iconUrl: 'assets/images/icons/admin-bulk-data-deletion.svg'
      }] : []));
    }),
    filteredItems: computed(function () {
      return [].concat(_toConsumableArray(this.get('items')));
    }),

    search: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(query) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(500);

          case 2:

            this.set('filteredItems', query
            // eslint-disable-next-line ember-suave/no-direct-property-access
            ? this.get('items').filter(function (item) {
              return _ember['default'].i18n.t('admin_components.' + item.title).string.toLowerCase().includes(query.toLowerCase());
            }) : this.get('items'));

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),
    checkBillingEnabled: function checkBillingEnabled() {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_billing',
        user: this.get('currentUser')
      }) && !this.get('isUnifiedAppAccount') ? [{
        title: 'Billing',
        link: 'protected.admin.billing',
        dataAnalyticsActionName: 'Admin Billing Page Load',
        iconUrl: 'assets/images/icons/admin-billing.svg'
      }] : [];
    },
    checkMakpAppsEnabled: function checkMakpAppsEnabled() {
      return this.checkFeature('marketplace_integration') && (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_mkp_apps',
        user: this.get('currentUser')
      }) && !this.get('isUnifiedAppAccount') ? [{
        title: 'MkpApps',
        link: 'protected.admin.mkp-gallery',
        dataAnalyticsActionName: 'Admin Market Place Page Load',
        iconUrl: 'assets/images/icons/admin-apps.svg'
      }] : [];
    },
    checkAnswerBotEnabled: function checkAnswerBotEnabled() {
      return this.checkFeature('deflection_bot') && (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_call_queues',
        user: this.get('currentUser')
      }) ? [{
        title: 'AnswerBot',
        link: 'protected.admin.configure-bot',
        dataAnalyticsActionName: 'Admin Configure Bot',
        iconUrl: 'assets/images/icons/admin-bots.svg'
      }] : [];
    },
    checkServiceLevelsEnabled: function checkServiceLevelsEnabled() {
      return this.checkFeature('service_level_monitoring') && (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_service_level_configurations',
        user: this.get('currentUser')
      }) && !this.get('isUnifiedAppAccount') ? [{
        title: 'ServiceLevels',
        link: 'protected.admin.service-level',
        dataAnalyticsActionName: 'Admin SLM Page Load',
        iconUrl: 'assets/images/icons/admin-service-levels.svg'
      }] : [];
    },
    checkTeamsEnabled: function checkTeamsEnabled() {
      return this.checkFeature('team') && (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_teams',
        user: this.get('currentUser')
      }) ? [{
        title: 'Teams',
        link: 'protected.admin.teams',
        dataAnalyticsActionName: 'Admin Team Page Load',
        iconUrl: 'assets/images/icons/admin-teams.svg'
      }] : [];
    },
    checkAgentsEnabled: function checkAgentsEnabled() {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_agents',
        user: this.get('currentUser')
      }) ? [{
        title: 'Agents',
        link: 'protected.admin.agents',
        dataAnalyticsActionName: 'Admin Agent Page Load',
        iconUrl: 'assets/images/icons/admin-users.svg'
      }] : [];
    },
    checkNumbersEnabled: function checkNumbersEnabled() {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'view_numbers',
        user: this.get('currentUser')
      }) ? [{
        title: this.get('isUnifiedAppAccount') ? 'Numbers_unified_app' : 'Numbers',
        link: 'protected.admin.numbers',
        dataAnalyticsActionName: 'Admin Number Page Load',
        iconUrl: 'assets/images/icons/admin-numbers.svg'
      }] : [];
    },

    actions: {
      filter: function filter(text) {
        this.get('search').perform(text);
      }
    }
  });
});