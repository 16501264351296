define('freshfone-ember/components/call-logs/post-call-transcript/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({

    transcriptionUrl: computed.alias('callTranscription.url'),
    callTranscriptionId: computed.alias('call.transcriptionID'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('call.transcriptionID')) {
        this.fetchTranscriptionDetails(transcriptionID);
      }
    },
    fetchTranscriptionDetails: function fetchTranscriptionDetails(transcriptionID) {
      var _this = this;

      this.store.findRecord('transcription', transcriptionID).then(function (transcription) {
        _this.set('callTranscription', transcription);
        _this.getCallTranscriptionDtls(transcription.get('url'));
      });
    },
    showDownloadTranscription: computed.and('canDownloadTranscription', 'data_available'),
    observeTranscriptionId: observer('callTranscriptionId', function () {
      if (this.get('callTranscriptionId')) {
        this.set('callTranscriptionContent', null);
        this.set('data_available', false);
        this.fetchTranscriptionDetails(this.get('callTranscriptionId'));
      }
    }),

    getCallTranscriptionDtls: function getCallTranscriptionDtls(transcriptionUrl) {
      var _this2 = this;

      if (transcriptionUrl) {
        _ember['default'].$.ajax({
          url: transcriptionUrl,
          type: 'GET',
          dataType: 'json'
        }).then(function (data) {
          _ember['default'].run(function () {
            _this2.setProperties({
              callTranscriptionContent: data,
              data_available: true
            });
          });
        });
      }
    }
  });
});