define('freshfone-ember/components/custom-elements/load-more/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  exports['default'] = Component.extend({
    classNames: ['text-center', 'load-more-container'],
    searchTerm: alias('select.searchText'),
    currentPage: computed('searchTerm', function () {
      return this.get('searchTerm') ? this.get('searchMeta.page_token') : this.get('totalMeta.page_token');
    }),
    hasMore: computed('searchTerm', function () {
      return this.get('searchTerm') ? this.get('searchMeta.has_more') : this.get('totalMeta.has_more');
    }),
    totalCount: computed('searchTerm', function () {
      return this.get('searchTerm') ? this.get('searchMeta.total_count') : this.get('totalMeta.total_count');
    }),

    getRequestParams: function getRequestParams() {
      var params = {};

      if (this.get('searchTerm')) {
        params = Object.assign({ filter: { queryString: this.get('searchTerm') } }, params);
      }
      params.page_token = this.get('currentPage');

      return params;
    },

    actions: {
      loadMoreRecords: function loadMoreRecords() {
        this.sendAction('loadMore', true, this.getRequestParams());
      }
    }
  });
});
/* eslint-disable camelcase */