define('freshfone-ember/components/billing-base/subscription-base/invoice/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      payInvoice: function payInvoice() {
        this.set('retryProgress', true);
        var self = this;
        $.ajax({
          url: '/subscriptions/pay_invoice',
          type: 'POST',
          dataType: "json"
        }).done(function (response) {
          _ember['default'].run(function () {
            self.set('subscription.unpaidInvoice', false);
          });
        }).fail(function (error) {
          _ember['default'].run(function () {
            self.set('paymentFailed', true);
          });
        });
      }
    }
  });
});