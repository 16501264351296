define('freshfone-ember/components/call-tags/custom-tags/custom-tag-row/component', ['exports', 'ember', 'freshfone-ember/constants/tag'], function (exports, _ember, _freshfoneEmberConstantsTag) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend({
    maxCharacterLimit: _freshfoneEmberConstantsTag['default']['customTagCharacterLimit'],

    showValidationMessage: function showValidationMessage(status, message) {
      this.setProperties({
        status: status,
        validationMessage: message
      });
    },

    afterSaveCallback: function afterSaveCallback() {
      var _this = this;

      this.showValidationMessage('success', _ember['default'].i18n.t('call_tags.custom_tags.changes_saved'));
      this.sendAction('onModify');
      later(function () {
        return _this.showValidationMessage();
      }, 3000);
    },

    handleErrors: function handleErrors(tag) {
      var _this2 = this;

      var tagNames = this.get('model').map(function (tag) {
        return tag.get('name').toLowerCase();
      });
      if (tag.get('name.length') > this.get('maxCharacterLimit')) {
        this.showValidationMessage('validation', _ember['default'].i18n.t('call_tags.custom_tags.max_char_limit_reached'));
      } else if (tagNames.contains(tag.get('name').toLowerCase())) {
        this.showValidationMessage('validation', _ember['default'].i18n.t('call_tags.custom_tags.tag_already_exists'));
      } else {
        this.showValidationMessage('error', _ember['default'].i18n.t('call_tags.custom_tags.failed_to_update'));
      }
      later(function () {
        return _this2.showValidationMessage();
      }, 3000);
    },

    actions: {
      keyDown: function keyDown() {
        this.showValidationMessage('validation', _ember['default'].i18n.t('call_tags.custom_tags.changes_applied_for_all_calls'));
      },
      onBlur: function onBlur() {
        this.showValidationMessage();
      },
      deleteTag: function deleteTag(tag) {
        this.sendAction('openDeleteTagModal', tag);
      },
      change: function change(tag) {
        var _this3 = this;

        this.set('validationMessage', null);
        tag.save().then(function (response) {
          _this3.afterSaveCallback();
        })['catch'](function (error) {
          _this3.handleErrors(tag);
          tag.rollbackAttributes();
        });
      }
    }
  });
});