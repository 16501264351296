define('freshfone-ember/components/billing-base/credit-actions/currency-dropdown/component', ['exports', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember', 'freshfone-ember/helpers/currency-types'], function (exports, _emberRlDropdownMixinsRlDropdownComponent, _ember, _freshfoneEmberHelpersCurrencyTypes) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_emberRlDropdownMixinsRlDropdownComponent['default'], {
    switchingCurrency: false,
    isAccountBlocked: computed.reads('session.account.blocked'),
    telephony: service(),
    analyticsService: service('analytics-tracker'),
    classNames: ['currency-dropdown'],
    classNameBindings: ['purchased:flash'],
    currentAccount: computed.alias('session.account'),
    currencyList: computed(function () {
      return this.get('currentAccount.hasPricingRebrandingFeature') ? Object.values(_freshfoneEmberHelpersCurrencyTypes['default'].rebrandedSupportedCurrencies) : Object.values(_freshfoneEmberHelpersCurrencyTypes['default'].supportedCurrencies);
    }),
    currentCurrency: computed('subscription.currency', function () {
      return _freshfoneEmberHelpersCurrencyTypes['default'].supportedCurrencies[this.get('subscription.currency')];
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.bindNodeEvents();
    },

    bindNodeEvents: function bindNodeEvents() {
      this.get('telephony').on('account_status_change', function (data) {
        if (data.hasOwnProperty('currencySwitched')) {
          window.location.reload();
        }
      });
    },
    actions: {
      setCurrency: function setCurrency(changedValue) {
        this.set('switchingCurrency', true);
        this.set('currentCurrency', _freshfoneEmberHelpersCurrencyTypes['default'].supportedCurrencies[changedValue.currencyName]);
        this.get('subscription.content').changeCurrency({ currency: changedValue.currencyName });
        this.get('analyticsService').addEvent('Swtich Currency');
      }
    }
  });
});