define('freshfone-ember/components/number-component/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/number-mixin', 'freshfone-ember/mixins/purchase-form.mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsNumberMixin, _freshfoneEmberMixinsPurchaseFormMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  var _Ember$run = _ember['default'].run;
  var later = _Ember$run.later;
  var cancel = _Ember$run.cancel;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsNumberMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsPurchaseFormMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    telephony: service(),
    analyticsService: service('analytics-tracker'),

    toggleCallMask: false,
    showCallerIds: false,
    currentUser: computed.alias('session.user'),
    hasDataScopeFeature: computed(function () {
      return this.checkFeature('data_scoping');
    }),
    isUserOrgAdmin: false,
    noNumbers: computed.empty('model'),
    hasNoNumberPurchased: computed.not('hasFreshfoneNumber'),
    freshfoneNumber: computed({
      set: function set(key, value) {
        if (value && this.get('selectedNumber') !== value.id) {
          this.set('callFlowID');
        }
        this.set('isEditMode', isPresent(value && value.id));

        return value;
      },
      /* eslint-disable no-empty-function */
      get: function get() {}
    }),
    isSidebarDocked: computed.and('has1CXFeature', 'isCrmView'),
    findallFreshfoneNumbers: computed(function () {
      return this.store.findAll('number');
    }),
    hasFreshfoneNumber: computed('findallFreshfoneNumbers.[]', function () {
      return this.get('findallFreshfoneNumbers.length') > 0;
    }),
    callerIds: computed(function () {
      return this.store.findAll('caller-id');
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var number = this.store.peekRecord('number', this.get('selectedNumber'));

      this.get('telephony').on('numbers_updated', function () {
        return _this.numbersUpdatedCallback();
      });
      number && this.send('edit', 'freshfoneNumber', number);
      this.checkOrgAdminAccess();
    },
    has1CXFeature: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),
    hasCrmOnboardingFeature: computed(function () {
      return this.hasGlobalFeature('crm_onboarding') && this.checkFeature('crm_onboarding');
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony').off('numbers_updated');
    },

    numbersUpdatedCallback: function numbersUpdatedCallback() {
      var _this2 = this;

      if (this.get('numberPurchaseState')) {
        var hasPromise = this.get('numberPurchaseState').next().value;

        hasPromise && hasPromise.then(function () {
          _this2.set('freshfoneNumber.saving', null);
          _this2.set('modifyModel', true);
        });
      } else {
        this.set('modifyModel', true);
      }
    },
    getNewCallerId: function getNewCallerId() {
      var callers = this.store.peekAll('caller-id');

      return callers.filterBy('isNew', true).get('lastObject');
    },
    createNewCallMask: function createNewCallMask() {
      var newObject = this.getNewCallerId() || this.store.createRecord('caller-id');

      this.set('newCallerID', newObject);
      this.toggleProperty('showCallMaskSettings');
    },
    checkOrgAdminAccess: function checkOrgAdminAccess() {
      var _this3 = this;

      if (this.get('hasCrmOnboardingFeature')) {
        this.checkIsUserOrgAdmin().then(function (isUserOrgAdmin) {
          run(function () {
            _this3.set('isUserOrgAdmin', isUserOrgAdmin);
          });
        })['catch'](function (err) {
          // eslint-disable-next-line no-console
          console.log('Error checking Org admind access', err);
          run(function () {
            _this3.set('isUserOrgAdmin', false);
          });
        });
      }
    },
    outgoingCallerValidation: function outgoingCallerValidation(number) {
      var _this4 = this;

      $.ajax({
        url: '/caller_ids/validation',
        type: 'POST',
        dataType: 'json',
        data: { number: number }
      }).done(function (data) {
        run(function () {
          if (data.code) {
            _this4.set('newCallerID.validationCode', data.code);
            _this4.set('newCallerID.hasValidationCode', true);
            _this4.checkIfCallerIdVerified(number);
          }
          if (data.error_message) {
            _this4.setErrorMessage(data.error_message);
          }
        });
      });
    },
    outgoingCallerVerification: function outgoingCallerVerification(number) {
      var _this5 = this;

      $.ajax({
        url: '/caller_ids/verify',
        type: 'POST',
        dataType: 'json',
        data: { number: number }
      }).done(function (data) {
        run(function () {
          if (data.number) {
            _this5.setVerificationSuccess(data);

            return true;
          }
        });
      });
    },
    checkIfCallerIdVerified: function checkIfCallerIdVerified(number) {
      var timer = later(this, function () {
        if (!this.isVerificationCompleted(number)) {
          this.checkIfCallerIdVerified(number);
        }
      }, 3000);

      this.incrementProperty('newCallerID.verificationCount');
      this.set('verificationInterval', timer);
    },
    isVerificationCompleted: function isVerificationCompleted(number) {
      if (this.get('newCallerID.verificationCount') <= 20) {
        this.outgoingCallerVerification(number);
      } else {
        this.setVerificationFailure();

        return true;
      }
    },
    setErrorMessage: function setErrorMessage(message) {
      this.set('newCallerID.errorMessage', message);
      this.set('newCallerID.hasVerificationErrors', true);
    },
    setVerificationSuccess: function setVerificationSuccess(data) {
      var _this6 = this;

      this.store.findRecord('caller-id', data.id).then(function (newcallerid) {
        if (_this6.get('freshfoneNumber')) {
          _this6.set('freshfoneNumber.callerId', newcallerid);
        }
      });
      this.set('newCallerID.isValidationSuccessfull', true);
      this.set('newCallerID.verificationCount', 0);
      cancel(this.get('verificationInterval'));
    },
    setVerificationFailure: function setVerificationFailure() {
      this.set('newCallerID.hasCallVerificationStarted', true);
      this.setErrorMessage(_ember['default'].i18n.t('caller_mask.twilio_errors.not_allowed'));
      this.set('newCallerID.verificationCount', 0);
      cancel(this.get('verificationInterval'));
    },
    purchaseNumber: regeneratorRuntime.mark(function purchaseNumber(freshfoneNumber) {
      return regeneratorRuntime.wrap(function purchaseNumber$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.setProperties({
              showRate: false,
              freshfoneNumber: this.store.createRecord('number', freshfoneNumber),
              intermediateNumber: freshfoneNumber
            });
            context$1$0.next = 3;
            return this.saveNumberCallback('number', this.get('freshfoneNumber'));

          case 3:
            context$1$0.next = 5;
            return this.store.queryRecord('rule', { id: this.get('freshfoneNumber.rule.id') });

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, purchaseNumber, this);
    }),

    saveNumberCallback: function saveNumberCallback(model, number) {
      var _this7 = this;

      // change onbaording status as complete if its success,
      return this.saveNumber(model, number).then(function () {
        var accounts = _this7.store.peekAll('account'),
            accountDetails = accounts.objectAt(0);

        _this7.store.queryRecord('credit', { reload: true });

        if (_this7.hasSignInTypeEligibilty() && !accountDetails.get('onboarding_completed')) {
          accountDetails.set('onboarding_completed', true);
        }
      });
    },

    actions: {
      openAddNewModal: function openAddNewModal() {
        this.set('isEditMode', false);
        this.send('toggleModal', true);
      },
      edit: function edit() {
        this._super.apply(this, arguments);
        this.send('toggleModal', true);
      },
      create: function create(freshfoneNumber) {
        this.set('numberPurchaseState', this.purchaseNumber(freshfoneNumber));
        this.get('numberPurchaseState').next();
      },
      update: function update() {
        this.send('closeForm');
        this.get('freshfoneNumber').flash();
      },
      toggleDelete: function toggleDelete(freshfoneNumber) {
        this.set('showDeleteConfirm', true);
        this.set('deletingFreshfoneNumber', freshfoneNumber);
      },
      afterDelete: function afterDelete(item) {
        if (item.id === this.get('freshfoneNumber.id')) {
          this.set('freshfoneNumber', null);
        }
      },
      closeCallback: function closeCallback() {
        this.set('showDeleteConfirm', false);
      },
      deleteCallback: function deleteCallback(item) {
        var _this8 = this;

        this.get('analyticsService').addEvent('number delete confirmation');
        this.send('closeCallback');
        this.destroyRecord('number', item).then(function () {
          return _this8.send('afterDelete', item);
        });
      },
      verfiy: function verfiy(number) {
        this.set('newCallerID.hasCallVerificationStarted', true);
        if (this.get('newCallerID.verificationCount') < 1) {
          this.outgoingCallerValidation(number);
        } else {
          this.setErrorMessage(_ember['default'].i18n.t('caller_mask.simultaneous_verification_error'));
        }
      },
      retry: function retry() {
        this.set('newCallerID.hasCallVerificationStarted', false);
        this.set('newCallerID.hasValidationCode', false);
        this.set('newCallerID.hasVerificationErrors', false);
      },
      manageCallerIds: function manageCallerIds() {
        this.toggleProperty('showCallerIds');
        if (this.get('isEditMode')) {
          this.send('closeForm');
        }
      },
      toggleCallMask: function toggleCallMask() {
        this.createNewCallMask();
      },
      toggleModal: function toggleModal(option) {
        this.set('slideToggle', option);
      },
      closeCallMask: function closeCallMask() {
        this.get('newCallerID').rollbackAttributes();
        if (this.get('newCallerID.isValidationSuccessfull')) {
          this.set('newCallerID.hasCallVerificationStarted', false);
          this.set('newCallerID', null);
        }
        this.toggleProperty('showCallMaskSettings');
      },
      addCallerIds: function addCallerIds() {
        this.toggleProperty('showCallerIds');
        this.createNewCallMask();
      },
      closeCallerIdList: function closeCallerIdList() {
        this.toggleProperty('showCallerIds');
      },
      listRefreshed: function listRefreshed() {
        if (this.get('numberPurchaseState')) {
          this.setProperties({
            purchaseError: false,
            freshfoneNumber: this.get('freshfoneNumber')
          });
          this.send('toggleModal');
          this.get('freshfoneNumber').flash();
          this.set('numberPurchaseState', undefined);
        }
      },
      closeForm: function closeForm() {
        this.rollbackDirtyChanges('freshfoneNumber');
        this.rollbackDirtyChanges('rule');
        this.send('toggleModal', false);
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */