define('freshfone-ember/components/live-calls/queued-calls-list/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/live-agents-dashboard', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsLiveAgentsDashboard, _emberCliPaginationComputedPagedArray) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var alias = _ember['default'].computed.alias;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    telephony: service(),
    timer: service(),
    toastr: service(),
    accountConfigurationService: service('account-configuration'),
    accountConfiguration: alias('accountConfigurationService.config'),
    hasCustomWQFeature: alias('accountConfiguration.hasCustomWQFeature'),
    customiseWqEnabled: alias('accountConfiguration.customiseWqEnabled'),
    queryParams: ["page", "perPage"],
    routing: service('-routing'),
    page: 1,
    perPage: 10,
    pagedCallList: (0, _emberCliPaginationComputedPagedArray['default'])('sortedCallList', { pageBinding: "page", perPageBinding: "perPage" }),

    store: service(),
    calls: computed(function () {
      return this.get("store").peekAll('call');
    }),
    isCurrentAgentAvailable: computed.alias('session.user.agent.isAvailable'),
    selectedQueueIds: computed.mapBy('selectedQueues', 'id'),
    hasCalls: computed.notEmpty('queuedCalls'),
    queuedCallsCount: computed.alias('queuedCalls.length'),
    hasMoveToTopFeature: computed(function () {
      return this.hasGlobalFeature('move_to_top') && this.checkFeature('move_to_top');
    }),
    userRole: computed.alias('session.user.role.name'),
    isAgent: computed.equal('userRole', 'Agent'),
    hasMoreAvailableAgents: false,
    hasEnhancedLiveDashboardQueueOptions: computed(function () {
      return this.hasGlobalFeature('enhanced_live_dashboard') && this.checkFeature('agent_restricted_live_dashboard') && this.checkFeature('agent_live_dashboard_pick_calls');
    }),
    canPickCalls: computed(function () {
      return this.get('isAgent') ? this.get('hasEnhancedLiveDashboardQueueOptions') : true;
    }),
    showMoreOptions: computed(function () {
      return this.get('hasWaitQueueManagementFeature') ? this.get('canPickCalls') : false;
    }),
    hasWaitQueueManagementFeature: computed(function () {
      return this.hasGlobalFeature('wait_queue_management') && this.checkFeature('wait_queue_management');
    }),
    sortByWaitQueuePositionEnabled: computed('hasCustomWQFeature', 'customiseWqEnabled', function () {
      return this.get('hasCustomWQFeature') && this.get('customiseWqEnabled');
    }),
    queuedCalls: computed('calls.@each.status', 'selectedQueueIds.[]', 'sortByWaitQueuePositionEnabled', 'hasMoveToTopFeature', function () {
      var _this = this;

      var sortedCalls = undefined,
          sortedWithInAQueue = undefined;

      var queuedCalls = this.get('calls').filter(function (call) {
        return _this.callBelongsHere(call);
      });

      if (this.get('sortByWaitQueuePositionEnabled')) {
        sortedCalls = queuedCalls.slice(0).sort(function (a, b) {
          return a.get('waitQueuePosition') - b.get('waitQueuePosition');
        });
      } else {
        sortedCalls = queuedCalls.slice(0).sort(function (a, b) {
          return new Date(a.get('enqueuedAt')).getTime() - new Date(b.get('enqueuedAt')).getTime();
        });

        if (this.get('hasMoveToTopFeature')) {
          sortedWithInAQueue = sortedCalls.sort(function (a, b) {
            if (a.get('callQueueId') === b.get('callQueueId')) {
              return a.get('waitQueuePosition') - b.get('waitQueuePosition');
            }

            return 0;
          });
        }
      }

      run(function () {
        _this.set('sortedCallList', !_this.get('sortByWaitQueuePositionEnabled') && _this.get('hasMoveToTopFeature') ? sortedWithInAQueue : sortedCalls);
      });

      return this.get('sortedCallList');
    }),
    waitTimes: computed.mapBy('queuedCalls', 'waitTime'),
    totalWaitTime: computed.sum('waitTimes'),
    avgWaitTime: computed('totalWaitTime', function () {
      return this.get('totalWaitTime') / this.get('queuedCallsCount');
    }),
    availableAgentStatus: computed(function () {
      return this.store.peekAll('agent-status').findBy('statusSubType', 'online');
    }),
    shouldEvaluateAvaliableAgents: false,
    assignableAgents: computed('onlineAgents.[]', 'onlineAgents.length', 'selectedCall.id', 'shouldEvaluateAvaliableAgents', function () {
      var _this2 = this;

      if (!this.get('selectedCall')) {
        return [];
      }

      if (this.get('hasLiveAgentsEnhancedFeature')) {
        var onlineAgents = this.store.peekAll('live-agent-status').filter(function (agent) {
          return String(agent.get('browserStatusId')) === _this2.get('availableAgentStatus.id') && agent.get('status') === 'online';
        });

        return onlineAgents.filter(function (agent) {
          return agent.get('callQueueIds').includes(_this2.get('selectedCall.callQueueId'));
        });
      }

      return this.get('onlineAgents').filter(function (agent) {
        return agent.get('callQueueIds').includes(_this2.get('selectedCall.callQueueId'));
      });
    }),
    fetchAllAvailableAgents: function fetchAllAvailableAgents() {
      this.getAgents(this.queryParamsForAvailableAgents(0));
    },
    queryParamsForAvailableAgents: function queryParamsForAvailableAgents(fromUser) {
      var params = { type: _freshfoneEmberConstantsLiveAgentsDashboard['default'].queryType },
          availableStatusId = this.get('availableAgentStatus.id');

      params[_freshfoneEmberConstantsLiveAgentsDashboard['default'].fromUser] = fromUser;
      params[_freshfoneEmberConstantsLiveAgentsDashboard['default'].statusId] = availableStatusId;

      return params;
    },
    getAgents: function getAgents(params) {
      var _this3 = this;

      this.store.query('agent', params).then(function (fetchedAgents) {
        _this3.set('hasMoreAvailableAgents', fetchedAgents.meta.has_more);
        if (_this3.get('hasMoreAvailableAgents')) {
          _this3.getAgents(_this3.queryParamsForAvailableAgents(fetchedAgents.meta.last_query_id));
        }
      });
    },

    rearrangeCallsByPosition: function rearrangeCallsByPosition(calls, updatedCall) {
      var bag = undefined,
          numberOfCallsFound = 0,
          targetCallReached = undefined;

      for (var i = 0; !targetCallReached && i < calls.get('length'); i++) {
        if (calls[i].get('callQueueId') === updatedCall.get('callQueueId')) {
          if (numberOfCallsFound === 0) {
            bag = calls[i];
            calls[i] = updatedCall;
          } else {
            var tempBag = calls[i];

            calls[i] = bag;
            bag = tempBag;
            targetCallReached = bag.get('id') === updatedCall.get('id');
          }

          calls[i].set('waitQueuePosition', numberOfCallsFound++);
        }
      }
    },
    onPositionUpdate: function onPositionUpdate(data) {
      var updatedCall = this.get('pushCallToStore')(data);

      if (updatedCall) {
        var sortedCallList = this.get('sortedCallList').slice(0);

        this.rearrangeCallsByPosition(sortedCallList, updatedCall);
        this.set('sortedCallList', sortedCallList);

        if (this.get('lastAttemptedMoveToTop')) {
          this.set('lastAttemptedMoveToTop', undefined);

          run.later(this, function () {
            var indexOfUpdatedCall = this.get('pagedCallList.content').findIndex(function (call) {
              return call.get('id') === updatedCall.get('id');
            }),
                pageOfUpdatedCall = Math.ceil((indexOfUpdatedCall + 1) / this.get('perPage'));

            this.set('pagedCallList.page', pageOfUpdatedCall);
            this.get('pagedCallList.content')[indexOfUpdatedCall].flash();
          });
        }
      }
    },
    init: function init() {
      var _this4 = this;

      this._super.apply(this, arguments);

      this.setProperties({
        sortedCallList: [],
        onPositionUpdate: this.get('onPositionUpdate').bind(this)
      });
      var loadedEventName = !this.get('isCrmView') ? 'loaded' : 'sdkLoadedFromCrm';

      /**
       * When live dashboard is the landing page, wait for "loaded" event. Else,
       * directly subscribe, as "loaded" would've already been fired.
       */
      try {
        this.get('telephony.event').subscribe('position_update', this.get('onPositionUpdate'));
      } catch (error) {
        this.get('telephony').on(loadedEventName, function () {
          return _this4.get('telephony.event').subscribe('position_update', _this4.get('onPositionUpdate'));
        });
      }

      this.get('pagedCallList').on('invalidPage', function (_ref) {
        var page = _ref.page;
        var totalPages = _ref.totalPages;

        if (page < 1 || totalPages < page) {
          _this4.set('pagedCallList.page', totalPages);
        }
      });

      if (this.get('hasLiveAgentsEnhancedFeature')) {
        this.fetchAllAvailableAgents();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this5 = this;

      this._super.apply(this, arguments);
      this.get('timer').create('queuedCallsTimer', 1000, function () {
        _ember['default'].run(function () {
          _this5.get('queuedCalls').forEach(function (queuedCall) {
            queuedCall.notifyPropertyChange('enqueuedAt');
          });
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('timer').destroy('queuedCallsTimer');
      this.get('telephony.event').unsubscribe('position_update', this.get('onPositionUpdate'));
      var loadedEventName = !this.get('isCrmView') ? 'loaded' : 'sdkLoadedFromCrm';

      this.get('telephony').off(loadedEventName);
    },
    callBelongsHere: function callBelongsHere(call) {
      var queues = this.get('selectedQueueIds') || [];

      return (call.get('isQueued') || call.get('isQueuedRinging')) && (isEmpty(queues) || this.hasValidQueue(call, queues));
    },
    hasValidQueue: function hasValidQueue(call, queues) {
      return queues.includes(call.get("callQueueId").toString());
    },
    actions: {
      onWqmAgentListOpen: function onWqmAgentListOpen(call) {
        if (this.get('hasLiveAgentsEnhancedFeature')) {
          this.toggleProperty('shouldEvaluateAvaliableAgents');
        }
        this.set('selectedCall', call);
      },
      moveToTop: function moveToTop(call) {
        var _this6 = this;

        call.moveToTop().then(function () {
          _this6.set('lastAttemptedMoveToTop', call.get('id'));
        });
      },
      assignCall: function assignCall(call, agent) {
        var _this7 = this;

        call.assign(agent ? { to: agent.get('userId') } : { to: this.get('session.user.id') }).then(function () {
          if (agent) {
            _this7.set('telephony.attemptedWqmCallAssignments.' + call.get('id'), agent);
          }
        })['catch'](function () {
          if (agent) {
            _this7.get('toastr').error(_ember['default'].i18n.t('live_dashboard.wqm.call_assignment_failed', { agentName: agent.get('name') }), _ember['default'].i18n.t('toastr.error'), { onclick: function onclick() {
                return _this7.get('routing').transitionTo('protected.live');
              } });
          }
        });
      },
      takeCall: function takeCall(call) {
        call.takeCall({ to: this.get('session.user.id') });
      },
      positionWqmActions: function positionWqmActions() {
        var _this8 = this;

        setTimeout(function () {
          var thisDropdownContainer = _this8.$('.wqm-actions-container.dropdown-expanded'),
              thisDropdown = thisDropdownContainer.find('.wqm-actions'),
              toggler = thisDropdownContainer.find('.icon-more-actions'),
              togglerOffset = toggler.offset();

          thisDropdown.css({
            top: togglerOffset.top - thisDropdown.height() + toggler.height() + 'px',
            left: togglerOffset.left - thisDropdown.width() + 'px'
          });
        });
      }
    }
  });
});