define('freshfone-ember/services/session', ['exports', 'ember', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/mixins/url-mixin'], function (exports, _ember, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberMixinsUrlMixin) {
  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  var run = _ember['default'].run;
  exports['default'] = Service.extend(_freshfoneEmberMixinsUrlMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    store: service(),
    errorMonitor: service(),
    user: computed.alias('current.user'),
    account: computed.alias('current.account'),
    isAuthenticated: false,
    isOnboardingFlowInitiated: false,
    isMicrophonePermissionNeeded: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('redirectToAuthorization', this.get('redirectToAuthorization').bind(this));

      $(document).ajaxError(function (event, data) {
        if (data.status === 403) {
          if (!_this.get('isEmbedParamPresent')) {
            // eslint-disable-next-line no-console
            console.log('Loading session again since we received 403 status code');
            run(function () {
              _this.loadSession();
            });
          }
        }
      });
    },
    loadSession: function loadSession() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        _this2.get('store').findRecord('session', 'current', {
          reload: true,
          adapterOptions: { query: { version: 'v1' } }
        }).then(function (session) {
          if (session.get('user')) {
            _this2.set('current', session);
            _this2.get('errorMonitor').setUser(session.get('user'));
            _this2.set('isAuthenticated', true);
            resolve();
          } else {
            _this2.redirectToAuthorization();
            reject();
          }
        }, reject);
      });
    },
    redirectToAuthorization: function redirectToAuthorization() {
      var currentPath = window.location.href;
      var url = new URL(this.authorizeURL());
      var searchParams = url.searchParams;

      if (currentPath) {
        searchParams.set('srcUrl', currentPath);
      }

      window.location.replace(url.href);
    },

    setOnboardinFlowStatus: function setOnboardinFlowStatus(status) {
      this.set('isOnboardingFlowInitiated', status);
    },
    setMicrophonePermissionRequired: function setMicrophonePermissionRequired(status) {
      this.set('isMicrophonePermissionNeeded', status);
    }
  });
});