define('freshfone-ember/components/live-calls/save-queues-view/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },

    selectedQueueIds: computed.mapBy('selectedQueues', 'id'),
    viewQueuesListIds: computed.mapBy('viewQueuesList', 'id'),

    viewModified: computed('selectedQueueIds', 'viewQueuesListIds', 'selectedQueues.[]', function () {
      return this.get('selectedQueues') && this.get('viewQueuesList') ? this.get('selectedQueueIds').sort().toString() !== this.get('viewQueuesListIds').sort().toString() : false;
    }),

    filterQueues: function filterQueues() {
      var _this = this;

      var selectQueues = [];

      this.get('queueOptions').forEach(function (queueOption) {
        queueOption.options.forEach(function (queue) {
          _this.get('viewQueuesList').forEach(function (viewQueue) {
            if (viewQueue.id == queue.id) {
              selectQueues.push(queue);
            }
          });
        });
      });

      return selectQueues;
    },

    actions: {
      toggleSaveView: function toggleSaveView(saveViewAs) {
        if (saveViewAs) {
          this.set('isSaveViewAs', true);
        }
        this.set('showSaveViewModal', true);
      },

      deleteView: function deleteView() {
        this.toggleProperty('showDeleteConfirmModal');
      },

      discardChanges: function discardChanges() {
        this.set('selectedQueues', this.filterQueues());
        this.sendAction('refreshCallMetrics', this.get('interval'));
      },

      saveView: function saveView() {
        var _this2 = this;

        this.get('selectedView').set('queues', this.get('selectedQueues'));
        this.get('selectedView').save().then(function (view) {
          _this2.set('viewQueuesList', view.get('queues'));
        });
      }
    }
  });
});