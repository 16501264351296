define("freshfone-ember/components/agent-status-component/agent-status-form/status-content/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/agent-status-component/agent-status-form/status-content/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "emoji-palette");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "emoji-palette", [], ["selectedEmoji", ["subexpr", "@mut", [["get", "agentStatus.emoji", ["loc", [null, [10, 26], [10, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "excludedCategories", ["subexpr", "@mut", [["get", "excludedCategories", ["loc", [null, [11, 31], [11, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "emojiVersion", ["subexpr", "@mut", [["get", "emojiVersion", ["loc", [null, [12, 25], [12, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "hideCategories", true, "onClose", ["subexpr", "action", ["closeEmojiPicker"], [], ["loc", [null, [14, 20], [14, 47]]], 0, 0], "closeOnBackdropClick", true, "closeOnEsc", false], ["loc", [null, [9, 8], [16, 30]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/agent-status-component/agent-status-form/status-content/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content-row form-items");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-item emoji-selector emoji-picker-dropdown disable-user-select");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "status-icon");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "icon-dropdown_arrow");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-item status-name");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element2, 'class');
        morphs[1] = dom.createElementMorph(element2);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["emoji-picker-toggle ", ["subexpr", "if", [["get", "showEmojiPalette", ["loc", [null, [3, 41], [3, 57]]], 0, 0, 0, 0], "open"], [], ["loc", [null, [3, 36], [3, 66]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleEmojiPicker"], [], ["loc", [null, [3, 68], [3, 98]]], 0, 0], ["content", "agentStatus.emoji", ["loc", [null, [4, 32], [4, 53]]], 0, 0, 0, 0], ["block", "if", [["get", "showEmojiPalette", ["loc", [null, [7, 10], [7, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 4], [18, 11]]]], ["inline", "text-field", [], ["mandatory", true, "class", "name-text-field", "label", ["subexpr", "t", ["custom_agent_status.form.name"], [], ["loc", [null, [24, 14], [24, 49]]], 0, 0], "for", "name", "value", ["subexpr", "@mut", [["get", "agentStatus.name", ["loc", [null, [26, 14], [26, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "errors.agentStatus.name", ["loc", [null, [27, 15], [27, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "parent", ["subexpr", "@mut", [["get", "parent", ["loc", [null, [28, 15], [28, 21]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 4], [28, 23]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});