define('freshfone-ember/services/billing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    loadUserCount: function loadUserCount() {
      var _this = this;

      $.ajax({
        url: '/users/count',
        type: 'get',
        dataType: 'json'
      }).done(function (data) {
        return _ember['default'].run(function () {
          return _this.setCount(data);
        });
      })
      // eslint-disable-next-line no-console
      .fail(function (error) {
        return _ember['default'].run(function () {
          return console.log('Error! status: ' + error.status + ' ; msg: ' + error.responseText);
        });
      });
    },
    setCount: function setCount(count) {
      this.setProperties({
        standaloneCount: count.standalone_users,
        omniCount: count.support360_users,
        crmCount: count.sales360_users,
        sharedCount: count.shared360_users
      });
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */