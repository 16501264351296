define('freshfone-ember/components/call-queue/queue-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/message-default-types', 'freshfone-ember/mixins/click-outside-listener', 'freshfone-ember/constants/call-queues', 'freshfone-ember/mixins/remove-feature-actiontype', 'freshfone-ember/constants/free-plans-caller'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersMessageDefaultTypes, _freshfoneEmberMixinsClickOutsideListener, _freshfoneEmberConstantsCallQueues, _freshfoneEmberMixinsRemoveFeatureActiontype, _freshfoneEmberConstantsFreePlansCaller) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var htmlSafe = _ember['default'].String.htmlSafe;
  var escapeExpression = _ember['default'].Handlebars.Utils.escapeExpression;
  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var Evented = _ember['default'].Evented;
  var isEqual = _ember['default'].isEqual;
  var service = _ember['default'].inject.service;

  var QUEUE_DEFAULTS = {
    routeOptionMins: 30,
    routeOption: 'all',
    waitQueueSize: 5,
    busyMaxWaitTime: 60,
    callQueueOptionName: 'call_queue'
  };exports['default'] = Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], _emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], Evented, {
    classNames: ['call-queue-form'],
    classNameBindings: ['call-queue-form-parent'],
    accountConfigService: service('account-configuration'),
    accountConfiguration: computed.alias('accountConfigService.config'),
    toggleChildContent: true,
    showRouteOptions: computed.bool('queue.isTeamOrAllAgentsRoute'),
    showOfflineOption: computed.bool('queue.isTeamOrAgentsRoute'),
    showPresenceOptions: computed.or('queue.agent.id', 'queue.team.id', 'queue.externalNumber', 'queue.isAllAgentsHunt', 'queue.id'),
    // showMessage         : computed.notEmpty('newMessage')
    advancedWaitQueueEnabled: computed(function () {
      return this.checkFeature('advanced_wait_queue');
    }),
    isWaitQueueEnhancementEnabled: computed(function () {
      return this.checkFeature('call_queue_agent_batching') && this.hasGlobalFeature('cq_and_wq_behavior_2022');
    }),
    showAdvancedWaitQueueSettings: computed.and('queue.hasFallbackFlow', 'advancedWaitQueueEnabled'),
    showAutoAnswerSelect: computed('queue.isMostIdleAgentRouteSelected', function () {
      return this.get('accountConfiguration.hasGlobalAnswerFeature') && this.get('queue.isMostIdleAgentRouteSelected');
    }),
    isFreePlan: computed(function () {
      return _freshfoneEmberConstantsFreePlansCaller['default'].includes(this.get('session.account.plan_name'));
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('queue.isChild')) {
        this.set('call-queue-form-parent', true);
      }
      this.get('store').query('message', {
        defaultMessageType: [_freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage]
      }).then(function () {
        if (_this.get('queue.message') && !_this.get('queue.message.content')) {
          _this.set('queue.message', _this.getAllDefaultMessages().welcome);
        }
        if (_this.get('isFreePlan') && !_this.get('queue.noAnswerActionOption')) {
          _this.get('queue').set('noAnswerAction', null);
          _this.get('queue').set('noAnswerMessage', null);
        }
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.get('queue.isChild')) {
        $('.call-queue-form-parent').css({ top: this.get('queue.childPosY') });
      }
    },
    queue: computed({
      set: function set(k, v) {
        if (v) {
          this.set('editMode', true);
          if (v.id) {
            if (!v.get('routeTypeWithObject.id')) {
              this.set('startValidate', true);
            }
            v.saveOriginalRelations();
          }
        }

        return v;
      },
      get: function get() {
        return this.get('modal.queue');
      }
    }),

    getAllDefaultMessages: function getAllDefaultMessages() {
      var messages = this.store.peekAll('message');

      var _messages$filterBy = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage);

      var _messages$filterBy2 = _slicedToArray(_messages$filterBy, 1);

      var welcome = _messages$filterBy2[0];

      var _messages$filterBy3 = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage);

      var _messages$filterBy32 = _slicedToArray(_messages$filterBy3, 1);

      var hangup = _messages$filterBy32[0];

      var _messages$filterBy4 = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage);

      var _messages$filterBy42 = _slicedToArray(_messages$filterBy4, 1);

      var voicemail = _messages$filterBy42[0];

      var callbackMessage = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].callbackMessage);

      var _messages$filterBy5 = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].callbackConfirmationMessage);

      var _messages$filterBy52 = _slicedToArray(_messages$filterBy5, 1);

      var callbackConfirmationMessage = _messages$filterBy52[0];

      return {
        welcome: welcome,
        hangup: hangup,
        voicemail: voicemail,
        callbackMessage: callbackMessage,
        callbackConfirmationMessage: callbackConfirmationMessage
      };
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var queue = this.get('queue');

      if (isEmpty(queue) || queue.get('isDestroyed') || queue.get('isDestroying')) {
        return;
      }
      if (this.get('queue.isChild')) {
        this.set('parentQueue.showChild', false);
      }

      if (this.get('queue.showChild')) {
        this.set('queue.showChild', false);
      }
    },

    agentExtensionList: computed('hasAgentExtensions', 'actionOptions', function () {
      var _this2 = this;

      return this.store.findAll('agent-extension').then(function (agentExtensions) {
        if (agentExtensions.content.length > 0) {
          _this2.set('hasAgentExtensions', true);
        } else {
          _this2.removeObjFromArray(_this2.get('actionOptions'), 'abbr', 'agent_extension');
        }
        return agentExtensions;
      });
    }),
    actionOptions: computed('queue.actionOptions', 'queue.routeType', 'actionOptions', 'hasAgentExtensions', function () {
      var waitQueueOption = {
        id: 4,
        desc: this.get('i18n').t('call_queue.edit.fallback.wait_queue.name'),
        abbr: 'wait_queue'
      };

      if (!this.hasFeatures(['voicemail_and_status_fallback'])) {
        this.removeObjFromArray(this.get('queue.actionOptions'), 'abbr', 'vm');
      }

      if (!this.get('queue.hasFallbackFlow')) {
        this.removeObjFromArray(this.get('queue.actionOptions'), 'abbr', 'wait_queue');
      }
      if (!this.get('queue.actionOptions').find(function (obj) {
        return obj.id === 4;
      }) && this.get('queue.routeType') !== 'externalNumber' && this.hasFeatures(['advanced_wait_queue'])) {
        this.get('queue.actionOptions').pushObject(waitQueueOption);
      } else if (this.get('queue.routeType') === 'externalNumber' && this.hasFeatures(['advanced_wait_queue'])) {
        this.removeObjFromArray(this.get('queue.actionOptions'), 'abbr', 'wait_queue');
      }

      return this.get('queue.actionOptions');
    }),

    notAnsweringLabel: computed('queue.isAgentHunt', 'queue.isTeamHunt', 'queue.agent.id', 'queue.team.id', 'queue.externalNumber', 'queue.isAllAgentsHunt', function () {
      if (this.get('queue.externalNumber')) {
        return this.get('i18n').t('call_queue.queue_form.not_answering');
      } else if (!this.get('queue.hasFallbackFlowFeature')) {
        return htmlSafe(this.get('i18n').t('call_queue.edit.badges.not_answered'));
      }
      var labelPrefix = this.get('i18n').t('call_queue.queue_form.online_not_answering');

      if (this.get('queue.isAgentHunt')) {
        var agentName = escapeExpression(this.get('queue.agent.name'));

        labelPrefix = this.get('i18n').t('call_queue.queue_form.online_not_answering_agentName', { name: agentName });
      }

      return labelPrefix;
    }),

    busyLabel: computed('queue.isAgentHunt', 'queue.isTeamHunt', 'queue.agent.id', 'queue.team.id', 'queue.externalNumber', 'queue.isAllAgentsHunt', function () {
      var labelPrefix = this.get('i18n').t('call_queue.queue_form.online_busy');

      if (this.get('queue.isAgentHunt')) {
        var agentName = escapeExpression(this.get('queue.agent.name'));

        labelPrefix = this.get('i18n').t('call_queue.queue_form.online_busy_agentName', { name: agentName });
      }

      return labelPrefix;
    }),

    actions: {
      toggleContent: function toggleContent() {
        this.sendAction('toggleContent', this.get('queue'));
        this.unbindOutsideClick();
      },

      toggleNotAnswering: function toggleNotAnswering() {
        this.toggleProperty('hideNotAnsweringContainer');
      },
      toggleBusyContainer: function toggleBusyContainer() {
        this.toggleProperty('hideAllAgentsBusyContainer');
      },
      toggleOfflineContainer: function toggleOfflineContainer() {
        this.toggleProperty('hideOfflineContainer');
      },
      pushChildMessage: function pushChildMessage(message) {
        this.set('queue.activeChildMessage', message);
        this.set('queue.showChild', true);
      },
      showNewMessage: function showNewMessage(attribute, isActiveChild) {
        var defaultMsjTitle = this.store.peekAll('message').content.length + 1;

        var messageName = this.get('i18n').t('message_components.record_component.message') + ' ' + defaultMsjTitle;

        var newObject = this.store.createRecord('message', { name: messageName });

        this.get('queue').set(attribute, newObject);
        if (isActiveChild) {
          this.set('queue.activeChildMessage', newObject);
        }
        this.set('newMessage', newObject);
        this.set('showMessage', true);
      },
      closeMessage: function closeMessage() {
        var relationship = this.get('queue.activeChildMessageType');

        var original = this.get('queue._originalRelations');

        if (this.get('queue.' + relationship).get('isNew')) {
          if (relationship && original) {
            if (original[relationship]) {
              this.get('queue').set(relationship, original[relationship]);
            }
          }
        }
      },
      routeTypeChanged: function routeTypeChanged() {
        var msjObj = this.getAllDefaultMessages();

        if (!this.get('queue.noAnswerMessage').content) {
          this.set('queue.noAnswerMessage', msjObj.hangup);
          this.set('queue.busyWaitOverMessage', msjObj.hangup);
          this.set('queue.offlineMessage', msjObj.hangup);
          this.set('queue.noAnswerMessage', msjObj.hangup);
        }
        if (this.get('queue.isTeamOrAllAgentsRoute') && !this.get('queue.routeOptionMins')) {
          this.set('queue.routeOptionMins', QUEUE_DEFAULTS.routeOptionMins);
          this.set('queue.routeOption', QUEUE_DEFAULTS.routeOption);
        }

        if (!this.get('queue.isTeamOrAllAgentsRoute')) {
          this.set('queue.totalRingingTimeout', QUEUE_DEFAULTS.routeOptionMins);
        }
      },
      setDefaultMsg: function setDefaultMsg(queueType, id) {
        if (this.get('queue.busyWaitOverActionOption.abbr') !== QUEUE_DEFAULTS.callQueueOptionName) {
          this.set('queue.busyWaitOverCallQueue', null);
        } else {
          this.set('queue.busyWaitOverMessage', null);
        }

        if (this.get('advancedWaitQueueEnabled')) {
          var queueTypeAbbr = 'queue.' + id + '.abbr';

          if (this.get(queueTypeAbbr) === 'wait_queue') {
            this.set('queue.waitQueueEnabled', true);
            this.send('setWaitQueueActionOptions', id);
          } else {
            var actionType = 'queue.' + id.replace(/(ActionOption|WaitOver)/g, '') + 'WaitQueueEnabled';
            this.set(actionType, false);
          }
          if (this.get('queue.noAnswerActionOption.abbr') !== 'wait_queue' && this.get('queue.offlineActionOption.abbr') !== 'wait_queue' && this.get('queue.busyWaitOverActionOption.abbr') !== 'wait_queue') {
            this.set('queue.waitQueueEnabled', false);
          } else {
            this.set('queue.waitQueueEnabled', true);
          }
        }
        if (!this.get('queue.isChild')) {
          this.$().parent().children().find('.icon-close').first().click();
        }
        var messages = this.store.peekAll('message');

        var typeId = this.get('queue.' + id + '.id');

        if (typeId > 2) {
          // skip if call Q
          return;
        }

        var _messages$filterBy6 = messages.filterBy('defaultMessageType', typeId + 1);

        var _messages$filterBy62 = _slicedToArray(_messages$filterBy6, 1);

        var message = _messages$filterBy62[0];

        // Messages are welcome,vm,hangup; types are vm, hangup,callqueue
        this.set('queue.' + queueType, message);
      },
      waitQueueToggled: function waitQueueToggled() {
        if (this.get('queue.waitQueueEnabled')) {
          var msjObj = this.getAllDefaultMessages();

          !this.get('queue.queueFullMessage.id') && this.set('queue.queueFullMessage', msjObj.hangup);
          !this.get('queue.timeThresholdMessage.id') && this.set('queue.timeThresholdMessage', msjObj.hangup);
          !this.get('queue.callbackFailMessage.id') && this.set('queue.callbackFailMessage', msjObj.hangup);
          !this.get('queue.quitQueueMessage.id') && this.set('queue.quitQueueMessage', msjObj.voicemail);
          !this.get('queue.busyMessage.id') && this.set('queue.busyMessage', msjObj.welcome);
          !this.get('queue.callbackMessage.id') && this.set('queue.callbackMessage', msjObj.callbackMessage);
          !this.get('queue.callbackConfirmationMessage.id') && this.set('queue.callbackConfirmationMessage', msjObj.callbackConfirmationMessage);
        }
        if (this.get('queue.waitQueueEnabled') && !this.get('queue.waitQueueSize')) {
          this.set('queue.waitQueueSize', this.get('queue.waitQueueSize') || QUEUE_DEFAULTS.waitQueueSize);
          this.set('queue.busyMaxWaitTime', this.get('queue.busyMaxWaitTime') || QUEUE_DEFAULTS.busyMaxWaitTime);
        }
      },

      setWaitQueueActionOptions: function setWaitQueueActionOptions(id) {
        if (isEqual(id, 'noAnswerActionOption')) {
          this.set('queue.noAnswerWaitQueueEnabled', true);
        }
        if (isEqual(id, 'offlineActionOption')) {
          this.set('queue.offlineWaitQueueEnabled', true);
        }
        if (isEqual(id, 'busyWaitOverActionOption')) {
          this.set('queue.busyWaitQueueEnabled', true);
        }
        if (this.get('queue.busyWaitQueueEnabled') || this.get('queue.offlineWaitQueueEnabled') || this.get('queue.noAnswerWaitQueueEnabled')) {
          this.send('waitQueueToggled');
        }
      },

      onRouteCallsByChange: function onRouteCallsByChange(value) {
        this.set('queue.selectedRouteOption', value);
        this.get('accountConfiguration.hasGlobalAnswerFeature') && this.setAutoAnswerValue(value.abbr);
      }
    },
    setAutoAnswerValue: function setAutoAnswerValue(routeOption) {
      if (routeOption === _freshfoneEmberConstantsCallQueues.ROUTE_OPTIONS.leastIdle) {
        var autoAnswerStatus = this.get('queue.autoAnswerStatus'),
            valueToSet = autoAnswerStatus ? this.get('autoAnswerOptions').findBy('abbr', autoAnswerStatus) : this.get('queue.autoAnswerOptions')[1];

        this.set('queue.selectedAutoAnswerOption', valueToSet);
      }
    }
  });
});