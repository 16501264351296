define('freshfone-ember/services/mkp-adapter', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/config/environment', 'freshfone-ember/constants/mkp-config'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberConfigEnvironment, _freshfoneEmberConstantsMkpConfig) {
  var Service = _ember['default'].Service;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  var _ajax = _ember['default'].$.ajax;
  var computed = _ember['default'].computed;
  var Evented = _ember['default'].Evented;
  var events = _freshfoneEmberConstantsMkpConfig['default'].events;
  var interceptTimeout = _freshfoneEmberConstantsMkpConfig['default'].interceptTimeout;
  var interceptableEvents = _freshfoneEmberConstantsMkpConfig['default'].interceptableEvents;
  var locations = _freshfoneEmberConstantsMkpConfig['default'].locations;
  var overlays = _freshfoneEmberConstantsMkpConfig['default'].overlays;
  var product = _freshfoneEmberConstantsMkpConfig['default'].product;
  var MarketplaceManager = window.MarketplaceManager;
  exports['default'] = Service.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], Evented, {
    session: service(),
    mkpData: service(),
    mkpInterface: service(),
    csrf: service(),
    hasMkpFeature: computed({
      get: function get() {
        return this.checkFeature('marketplace_integration');
      }
    }),
    canShowMkp: computed.and('hasMkpFeature', 'mkpInitialized'),
    // Re-initialize MarketplaceManager on "page" update
    page: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(key, value) {
        if (value) {
          this.initializeMarketplace();
        }

        return value;
      }
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('session.account') && !this.get('isNavOff') && this.get('session.account').hasGlobalFlag('public_api_token')) {
        this.getAPIToken().then(function (token) {
          return _this.set('publicAPIToken', token);
        });
      }
    },

    getAPIToken: function getAPIToken() {
      var _this2 = this;

      return new Promise(function (resolve) {
        return _this2.fetchOrGenerate('GET').then(resolve)['catch'](_this2.fetchOrGenerate).then(resolve);
      });
    },

    fetchOrGenerate: function fetchOrGenerate(type) {
      return new Promise(function (resolve, reject) {
        $.ajax({
          url: '/token/',
          type: type,
          dataType: 'json'
        }).done(function (response) {
          return resolve(response.data.attributes.key);
        }).fail(function (error) {
          return reject(type === 'GET' ? 'POST' : _ember['default'].i18n.t('profile_settings.apiToken.failedReset' + (error.responseJSON ? error.responseJSON.errors : '')));
        });
      });
    },

    initializeMarketplace: function initializeMarketplace() {
      var _this3 = this;

      console.log('Initializing Marketplace config');
      var mkpConfig = {
        Promise: Promise,
        ajax: function ajax(options) {
          return new Promise(function (resolve, reject) {
            var request = _ajax(options);

            request.done(function (res) {
              return resolve(res);
            });
            request.fail(function (res) {
              return reject(res);
            });
          });
        },
        csrfToken: this.get('csrf.data.token'),
        apiKey: this.get('session.current.mkpApiKey'),
        authToken: this.get('session.current.mkpAuthToken'),
        region: _freshfoneEmberConfigEnvironment['default'].Freshparent.region,
        page: this.get('page'),
        product: product,
        locations: locations,
        accountID: this.get('session.account.id'),
        domain: window.location.origin,
        getAttribute: function getAttribute(attribute) {
          console.log('Updating Marketplace attribute');
          var response = _this3.get('mkpData').getData(attribute);

          if (response && typeof response.then === 'function') {
            return response;
          } else if (response.error) {
            return Promise.reject({ message: response.error });
          }

          return Promise.resolve(response);
        },
        events: events,
        interceptableEvents: interceptableEvents,
        interceptTimeout: interceptTimeout,
        overlays: overlays,
        'interface': function _interface(args) {
          console.log('Updating Marketplace interface');
          var response = _this3.get('mkpInterface').invokeInterface(args);

          if (response && typeof response.then === 'function') {
            return response;
          } else if (response.error) {
            return Promise.reject({ message: response.error });
          }

          return Promise.resolve(response);
        }
      };

      try {
        console.log('Initializing Marketplace apps');
        this.setProperties({
          mkpManager: new MarketplaceManager(mkpConfig),
          mkpInitialized: true
        });
        console.log('Finished Initializing Marketplace apps');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Market place initialization failed');
        // eslint-disable-next-line no-console
        console.log('Market place initialization failed error', error);

        if (error) {
          console.log(JSON.stringify(error, Object.getOwnPropertyNames(error)));
          if (error.stack) {
            console.log(error.stack);
          }
        }
        this.set('mkpInitialized', false);
      }
    },

    // MarketplaceManager public methods
    getApps: function getApps(filter) {
      return this.get('mkpManager').getApps(filter);
    },
    triggerEvent: function triggerEvent(event) {
      if (!this.get('canShowMkp')) {
        return Promise.resolve();
      }

      return this.get('mkpManager').trigger(event);
    }
  });
});
/* eslint-disable camelcase, no-console */