define('freshfone-ember/components/live-calls/call-metrics-card/component', ['exports', 'freshfone-ember/utils/call-constants', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/mixins/event-bind-mixin'], function (exports, _freshfoneEmberUtilsCallConstants, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberMixinsEventBindMixin) {
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_freshfoneEmberMixinsEventBindMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    classNames: ['calls-summary'],
    i18n: service(),
    currentUser: alias('session.user'),
    selectedQueueIds: computed.mapBy('selectedQueues', 'id'),
    queueIds: computed('selectedQueues', function () {
      return isEmpty(this.get('selectedQueueIds')) ? 'all' : this.get('selectedQueueIds');
    }),
    missedCallStatus: computed(function () {
      return _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.missed.id;
    }),
    has1CXFeature: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),
    isHyperlinkOff: computed('has1CXFeature', 'currentUser.isAccountAdmin', function () {
      var isAdmin = this.get('currentUser.isAccountAdmin');

      return this.get('has1CXFeature') && !isAdmin;
    }),
    metricsLoaded: true,

    init: function init() {
      this._super.apply(this, arguments);
    },

    hourInterval: function hourInterval() {
      return this.get('i18n').t('calls_dashboard.last_hour', { hour_interval: this.getHoursInterval() });
    },

    getHoursInterval: function getHoursInterval() {
      var m = moment();
      var currentHour = m.format('ha');
      var nextHour = m.add(1, 'hour').format('ha');

      return currentHour + ' - ' + nextHour;
    }
  });
});