define('freshfone-ember/adapters/call', ['exports', 'ember', 'freshfone-ember/adapters/application'], function (exports, _ember, _freshfoneEmberAdaptersApplication) {
  exports['default'] = _freshfoneEmberAdaptersApplication['default'].extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (_ember['default'].isPresent(query) && _ember['default'].isPresent(query["type"])) {
        var type = query["type"];
        if (type === 'users') {
          delete query.id;
        }
        delete query.type;
        return "/" + type + "/recent_calls";
      } else if (this.isFindRequest(requestType)) {
        return "/calls/filter";
      } else {
        return this._super(modelName, id, snapshot, requestType, query);
      }
    },
    isFindRequest: function isFindRequest(requestType) {
      var types = ['query', 'queryRecord'];
      return types.includes(requestType);
    },

    query: function query(store, type, _query, recordArray) {
      var formattedParams = _query;

      if (_ember['default'].isEmpty(_query["type"]) && !formattedParams.isFormatted) {
        formattedParams = this.formatCallParams(_query);
      }

      formattedParams.save_filter = _query.save_filter;
      delete formattedParams.isFormatted;

      return this._super(store, type, formattedParams, recordArray);
    },

    formatCallParams: function formatCallParams(params) {
      params["date_range"] = params["date_range"] || {};

      if (Array.isArray(params['date_range'])) {
        params['date_range'] = this.formatDateRange(params['date_range']);
      }

      return {
        by_contact: {
          contact_id: params['contact_id'],
          caller_id: params['caller_id']
        },
        by_org_contact: { org_contact_id: params['org_contact_id'] },
        by_company: {
          company_id: params['company_id']
        },
        by_agent: {
          agent_id: params['agent_id']
        },
        by_date: {
          start_date: params['date_range'][0],
          end_date: params['date_range'][1] || params['date_range'][0],
          timeperiod: params['date_range'][2]
        },
        by_status: {
          status: params["status"]
        },
        by_number: {
          number_id: params['number_id']
        },
        by_call_action: {
          category: params['call_action']
        },
        by_queue: {
          queue_id: params['queue_id']
        },
        by_team: {
          team_id: params['team_id']
        },
        by_conversation: {
          search_string: params['search_string']
        },
        by_transcription: {
          search_string: params['search_text']
        },
        by_tags: {
          tag_id: params['tag_id']
        },
        by_call_id: { call_id: params['call_id'] },
        by_service_level_breached: params["serviceLevelBreached"],
        by_power_dialer: params["powerDialerCalls"],
        by_direction: params["direction"],
        by_business_hour_call: params["business_hour_call"],
        page: params["page"],
        per_page: params["limit"],
        load_preferred_attributes: params["load_preferred_attributes"],
        has_ancestry: params['ancestry'],
        by_live_calls: params['live_calls']
      };
    },

    formatDateRange: function formatDateRange(dateRange) {
      var _this = this;

      return dateRange.map(function (date) {
        return moment.isMoment(date) && date.isValid() ? date.format('DD-MM-YYYY') : _this.getDasherisedDateString(date);
      });
    },
    // ToDo: remove when removing old call-logs code
    getDasherisedDateString: function getDasherisedDateString(date) {
      date = date ? date.replace(/\s+/g, '-') : '';
      var momentDate = moment(date, 'DD-MMM-YYYY', true);

      return momentDate.isValid() ? momentDate.format('DD-MM-YYYY') : date;
    }
  });
});
/* eslint-disable camelcase */
/* eslint-disable dot-notation */