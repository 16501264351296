define('freshfone-ember/helpers/has-privilege', ['exports', 'ember-authority/helpers/has-privilege'], function (exports, _emberAuthorityHelpersHasPrivilege) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberAuthorityHelpersHasPrivilege['default'];
    }
  });
  Object.defineProperty(exports, 'hasPrivilege', {
    enumerable: true,
    get: function get() {
      return _emberAuthorityHelpersHasPrivilege.hasPrivilege;
    }
  });
});