define('freshfone-ember/models/agent', ['exports', 'ember', 'ember-data', 'ember-validations'], function (exports, _ember, _emberData, _emberValidations) {
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;

  var momentjs = window.moment;
  exports['default'] = _emberData['default'].Model.extend(_emberValidations['default'], {
    type: _emberData['default'].attr('string'),
    user: _emberData['default'].belongsTo('user', { async: true }),
    name: computed.alias('user.name'),
    mobileStatus: _emberData['default'].attr(),
    browserStatus: _emberData['default'].belongsTo('agent-status', { inverse: 'agents' }), // Since preference will also be made part of agent status, preference will be removed in the future.
    previousBrowserStatus: _emberData['default'].belongsTo('agent-status'),
    sipStatus: _emberData['default'].attr('number'),
    sipEnabled: _emberData['default'].attr('boolean'),
    lastCallAt: _emberData['default'].attr(),
    lastStatusChangeAt: _emberData['default'].attr(),
    lastSeenAt: _emberData['default'].attr(),
    deleted: _emberData['default'].attr(),
    loggedIn: _emberData['default'].attr(),
    outgoingNumberId: _emberData['default'].attr('string'), // We're comparing this with Ember model IDs which are always stored as strings
    canRestoreAgent: _emberData['default'].attr('boolean'),
    createAttempt: _emberData['default'].attr('boolean'),
    callQueueIds: _emberData['default'].attr(),
    isBusy: computed.equal('browserStatus.statusType', 'busy'),
    isAcw: computed.equal('browserStatus.statusType', 'acw'),
    isBusyOrAcw: computed.or('isBusy', 'isAcw'),
    hasAvatar: computed.notEmpty('user'),
    isOutgoingCallMade: _emberData['default'].attr(),
    powerDialer: _emberData['default'].attr(),
    isAvailable: computed('browserStatus.statusType', 'mobileStatus', function () {
      return !this.get('isBusyOrAcw') && [this.get('browserStatus.statusType'), this.get('mobileStatus')].includes('online');
    }),
    isOffline: computed('browserStatus.statusType', 'mobileStatus', function () {
      return [this.get('browserStatus.statusType'), this.get('mobileStatus')].every(function (state) {
        return state === 'offline';
      });
    }),
    isOnPhoneOrMobileApp: computed('browserStatus.statusType', 'mobileStatus', function () {
      return this.get('browserStatus.statusType') === 'online' && this.get('browserStatus.statusSubType') === 'online_phone' || this.get('mobileStatus') === 'online';
    }),

    isBot: computed.equal('type', 'BOT'),

    idleTime: computed('lastCallAt', function () {
      if (isEmpty(this.get('lastCallAt'))) {
        return _ember['default'].i18n.t('agent_availability.no_calls');
      }
      var lastCallAt = momentjs(this.get('lastCallAt')).local();
      var timeSince = lastCallAt.from();
      return timeSince.replace(/\b(ago|in)\b/g, "");
    }),

    statusText: computed('browserStatus.id', 'browserStatus.name', 'showLoader', function () {

      if (this.get('showLoader')) {
        return _ember['default'].i18n.t('agent_availability.loading');
      }

      if (this.get('browserStatus.default')) {
        switch (this.get('browserStatus.statusSubType')) {
          case 'online':
            return _ember['default'].i18n.t('agent_availability.on_browser');
          case 'online_phone':
            return _ember['default'].i18n.t('agent_availability.on_mobile');
          case 'offline':
            return _ember['default'].i18n.t('agent_availability.offline');
        }
      }

      return this.get('browserStatus.name');
    }),

    sipStatuses: computed(function () {
      return [{ value: 0, label: _ember['default'].i18n.t('sip.status.never') }, { value: 1, label: _ember['default'].i18n.t('sip.status.whenOnBrowser') }];
    }),

    sipStatusText: computed('sipStatus', {
      get: function get() {
        return this.get('sipStatuses').findBy('value', this.get('sipStatus'));
      },
      set: function set(key, value) {
        this.set('sipStatus', this.get('sipStatuses').findBy('value', value.value).value);
        return value;
      }
    }),

    statusToggleAllowed: computed('browserStatus.statusType', 'loggedIn', 'mobileStatus', function () {
      return this.get('isAvailable') || this.get('browserStatus.statusType') === "offline" && this.get('loggedIn');
    })
  });
});