define('freshfone-ember/components/data-automated-routing/form/component', ['exports', 'ember', 'freshfone-ember/helpers/message-default-types', 'freshfone-ember/constants/routing-automation'], function (exports, _ember, _freshfoneEmberHelpersMessageDefaultTypes, _freshfoneEmberConstantsRoutingAutomation) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    session: service(),
    isNewFlow: computed.alias('routingAutomation.isNew'),
    init: function init() {
      this._super.apply(this, arguments);
      this.setDefaults();
    },
    setDefaults: function setDefaults() {
      var _this = this;

      if (this.get('isNewFlow')) {
        var routingAutomationRecord = this.store.peekAll('routingAutomation').content.length;

        this.set('routingAutomation.name', 'Routing Automation Flow ' + routingAutomationRecord);

        this.get('store').query('message', {
          defaultMessageType: [_freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage],
          paginate: false
        }).then(function (messages) {
          var _messages$filterBy = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage);

          var _messages$filterBy2 = _slicedToArray(_messages$filterBy, 1);

          var welcomeMessage = _messages$filterBy2[0];

          var _messages$filterBy3 = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage);

          var _messages$filterBy32 = _slicedToArray(_messages$filterBy3, 1);

          var hangupMessage = _messages$filterBy32[0];

          _this.get('routingAutomation').setProperties({
            message: welcomeMessage,
            holdMessage: welcomeMessage,
            invalidInputMessage: welcomeMessage,
            invalidInputActionType: _this.get('routingAutomation.actionOptions')[2].name,
            invalidInputActionId: hangupMessage.get('id'),
            appFailureActionType: _this.get('routingAutomation.actionOptions')[2].name,
            appFailureActionId: hangupMessage.get('id'),
            routingRuleType: _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES.create
          });
        });
      }
    },
    actions: {
      toggleCallQueueContent: function toggleCallQueueContent(routingAutomation) {
        this.get('toggleCallQueueContent')(routingAutomation);
      }
    }
  });
});