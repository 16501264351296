define('freshfone-ember/mixins/remove-feature-actiontype', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    removeObjFromArray: function removeObjFromArray(arr, k, v) {
      var index = arr.findIndex(function (ele) {
        if (ele[k] === v) {
          return true;
        }
      });
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    }
  });
});