define('freshfone-ember/adapters/session', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].RESTAdapter.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);
      var query = _ember['default'].get(snapshot, 'adapterOptions.query');
      if (query && query.version === 'v1') {
        url += '?' + _ember['default'].$.param(query);
      }
      return url;
    }
  });
});