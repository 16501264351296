define('freshfone-ember/components/message-components/messages-list/component', ['exports', 'ember', 'freshfone-ember/constants/message', 'freshfone-ember/mixins/model-form-mixin'], function (exports, _ember, _freshfoneEmberConstantsMessage, _freshfoneEmberMixinsModelFormMixin) {
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var not = _Ember$computed.not;
  var empty = _Ember$computed.empty;
  var Component = _ember['default'].Component;
  var MESSAGE = _freshfoneEmberConstantsMessage.LABELS.MESSAGE;
  var VOICEMAIL = _freshfoneEmberConstantsMessage.LABELS.VOICEMAIL;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], {
    messages: computed('model.[]', function () {
      var _this = this;

      return this.get('model').filter(function (message) {
        return _this.filterMessagesByScope(message, _this.get('scope'));
      });
    }),
    filterParams: computed(function () {
      return { scope: this.get('scope') };
    }),
    pageTitle: computed(function () {
      return this.get('session.account.isUnifiedAppAccount') && Number(this.get('scope')) === 0 ? _ember['default'].i18n.t('admin_components.Messages_unified_app') : _ember['default'].i18n.t([MESSAGE.pageTitle, VOICEMAIL.pageTitle][Number(this.get('scope'))]);
    }),
    analyticsEventPrefix: computed(function () {
      return this.get('scope') ? 'VM:' : '';
    }),
    searchPlaceholder: computed(function () {
      return _ember['default'].i18n.t([MESSAGE.searchPlaceholder, VOICEMAIL.searchPlaceholder][+this.get('scope')]);
    }),
    tableHeader: computed(function () {
      return _ember['default'].i18n.t([MESSAGE.tableHeader, VOICEMAIL.tableHeader][+this.get('scope')]);
    }),
    notPromoting: not('promoting'),
    notSearching: empty('searchstring'),
    noVoicemails: computed('model.[]', function () {
      return this.get('scope') && this.get('notSearching') && this.get('notPromoting') && !this.get('messages').length;
    }),
    searchEmptyMessage: computed(function () {
      return _ember['default'].i18n.t(this.get('scope') ? 'voicemailDrop.searchEmpty' : 'message_components.message_list.no_message');
    }),

    filterMessagesByScope: function filterMessagesByScope(message, scope) {
      return !message.get('isNew') && message.get('messageScope') === scope;
    },

    actions: {
      editMessage: function editMessage(message) {
        this.sendAction('onEdit', message);
      },
      toggleDelete: function toggleDelete(message) {
        this.sendAction('onDelete', message);
      },
      afterDelete: function afterDelete(message) {
        this.sendAction('onAfterDelete', message);
      },
      onModify: function onModify() {
        this.sendAction('onModify');
      },
      openForm: function openForm() {
        this.sendAction('onOpenForm');
      },
      onModify: function onModify() {
        this.set('modifyModel', true);
      }
    }
  });
});