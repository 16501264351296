define('freshfone-ember/components/team-component/load-more-agents/component', ['exports', 'ember'], function (exports, _ember) {
  var TOP_EPSILON = 100;
  var BOTTOM_EPSILON = 150;
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    topEpsilon: TOP_EPSILON,
    bottomEpsilon: BOTTOM_EPSILON,
    isFetching: false,
    hasMore: null,
    scrollable: null,
    scrollableTo: null,

    didInsertElement: function didInsertElement() {
      _ember['default'].run.later(this, function () {
        if (!this.isDestroyed && !this.isDestroying) {
          var scrollable = get(this, 'scrollable');

          var scrollableTo = undefined;

          if (scrollable) {
            scrollableTo = document.querySelector(scrollable);
            set(this, 'scrollableTo', scrollableTo);
            scrollableTo.addEventListener('scroll', _ember['default'].run.bind(this, this.didScroll));
          }
        }
      }, 1);
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].run.later(this, function () {
        var scrollableTo = get(this, 'scrollableTo');

        scrollableTo.removeEventListener('scroll', _ember['default'].run.bind(this, this.didScroll));
      }, 1);
    },

    didScroll: function didScroll() {
      if (!get(this, 'isFetching') && get(this, 'hasMore') && this.isNearBottom()) {
        this.safeSet('isFetching', true);
        get(this, 'fetchMoreRes')();
        this.safeSet('isFetching', false);
      }
    },

    getScrollSize: function getScrollSize() {
      var scrollableTo = get(this, 'scrollableTo'),
          viewPortTop = scrollableTo.scrollTop,
          bottomTop = scrollableTo.scrollHeight - scrollableTo.clientHeight;

      return {
        viewPortTop: viewPortTop,
        bottomTop: bottomTop
      };
    },

    isNearBottom: function isNearBottom() {
      var _getScrollSize = this.getScrollSize();

      var viewPortTop = _getScrollSize.viewPortTop;
      var bottomTop = _getScrollSize.bottomTop;

      return viewPortTop && bottomTop - viewPortTop < get(this, 'bottomEpsilon');
    },

    safeSet: function safeSet(key, value) {
      if (!this.isDestroyed && !this.isDestroying) {
        set(this, key, value);
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */