define('freshfone-ember/components/feedback-widget/component', ['exports', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember'], function (exports, _emberRlDropdownMixinsRlDropdownComponent, _ember) {
  var _this = this;

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend(_emberRlDropdownMixinsRlDropdownComponent['default'], {
    i18n: service(),
    currentAccount: computed.alias('session.account'),
    productName: computed.alias('currentAccount.productName'),
    showFeedback: 'showFeedback',
    // remove after video
    articlesLink: 'https://support.freshcaller.com/',
    dropdownExpanded: false,
    showInput: true,
    isEmptyMessage: computed('feedBackMessage', function () {
      return this.get('feedBackMessage') === undefined || this.get('feedBackMessage').trim() === '';
    }),
    videoParams: computed(function () {
      return {
        videoTitle: _this.get('i18n').t('onboarding.video.title', { productName: _this.get('productName') }),
        videoDuration: '5:32',
        videoUrl: 'https://www.youtube.com/embed/gAUfEQXMVWM?rel=0&amp;showinfo=0',
        width: 560,
        height: 315
      };
    }),

    toggleInput: function toggleInput() {
      var _this2 = this;

      this.set('showInput', false);
      this.set('feedBackMessage', '');
      later(function () {
        /*
         *this.set('showFeedback', false);
         * uncomment after video
         */
        _this2.set('showInput', true);
        _this2.toggleProperty('dropdownExpanded');
      }, 2000);
    },

    actions: {
      toggleDropDown: function toggleDropDown() {
        this.toggleProperty('dropdownExpanded');
      },
      toggleShowFeedback: function toggleShowFeedback() {
        this.toggleProperty('showFeedback');
      },
      toggleShowVideo: function toggleShowVideo() {
        this.toggleProperty('showVideo');
      },
      submitFeedBack: function submitFeedBack() {
        var _this3 = this;

        $.ajax({
          url: '/feedback',
          type: 'POST',
          dataType: 'json',
          data: { feedback: this.get('feedBackMessage').trim() }
        }).then(function () {
          return run(function () {
            return _this3.toggleInput();
          });
        });
      }
    }
  });
});