define("freshfone-ember/templates/protected/call-logs/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/templates/protected/call-logs/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "call-logs", [], ["filterCalls", ["subexpr", "@mut", [["get", "model.calls", ["loc", [null, [2, 14], [2, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "filterConfiguration", ["subexpr", "@mut", [["get", "model.filterOptions", ["loc", [null, [3, 22], [3, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [4, 7], [4, 11]]], 0, 0, 0, 0]], [], [], 0, 0], "contact", ["subexpr", "@mut", [["get", "contact", ["loc", [null, [5, 10], [5, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "company", ["subexpr", "@mut", [["get", "company", ["loc", [null, [6, 10], [6, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedCallId", ["subexpr", "@mut", [["get", "showDetailsOf", ["loc", [null, [7, 17], [7, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "initialFilterParams", ["subexpr", "@mut", [["get", "model.callFilterParams", ["loc", [null, [8, 22], [8, 44]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [9, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});