define('freshfone-ember/components/freshid-redirect/component', ['exports', 'ember', 'freshfone-ember/mixins/url-mixin'], function (exports, _ember, _freshfoneEmberMixinsUrlMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var _Ember$run = _ember['default'].run;
  var once = _Ember$run.once;
  var later = _Ember$run.later;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = Component.extend(_freshfoneEmberMixinsUrlMixin['default'], {
    telephony: service(),
    session: service(),
    currentUser: computed.alias('session.user'),
    freshidProfile: computed(function () {
      return this.freshidProfileURL();
    }),
    showInvalidNumberError: computed('currentUser.invalidNumber', function () {
      return this.get('currentUser.invalidNumber');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('telephony.isLoaded')) {
        this.subcribeEvents();
      }
    },
    subcribeEvents: function subcribeEvents() {
      this.get('telephony.event').subscribe('profile_updated', this.profileUpdatedCallback.bind(this));
    },
    profileUpdatedCallback: function profileUpdatedCallback(data) {
      var _this = this;

      if (this.get('currentUser')) {
        this.validateNumber(data.valid_number);
      }
      if (this.get('currentUser.hasContactNumber') && this.get('focusPhoneNumber') && data.valid_number) {
        this.sendAction('afterUserSave');
        later(this, function () {
          _this.send('toggleSettings');
        }, 1000);
      }
    },
    validateNumber: function validateNumber(validNumber) {
      var isInvalid = this.get('currentUser.hasContactNumber') && !validNumber;
      this.set('currentUser.invalidNumber', isInvalid);
    },
    actions: {
      toggleSettings: function toggleSettings() {
        this.sendAction('toggleSettings');
      }
    }
  });
});