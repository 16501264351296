define('freshfone-ember/mixins/model-proxy', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        init: function init() {
            this._createOrUpdateProxy();
            this.on('didCreate', this._createOrUpdateProxy);
            this.on('didUpdate', this._createOrUpdateProxy);
        },
        _createOrUpdateProxy: function _createOrUpdateProxy() {
            this.set('__modelProxy', Object.assign({}, this.get('_internalModel._data')));
        }
    });
});