define('freshfone-ember/routes/protected/admin/service-level', ['exports', 'ember', 'freshfone-ember/routes/promotable'], function (exports, _ember, _freshfoneEmberRoutesPromotable) {
  var computed = _ember['default'].computed;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _freshfoneEmberRoutesPromotable['default'].extend({
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('serviceLevel.title').string };
    }),
    queryParams: {
      editQueue: {}
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('isFormDirty', false);
      controller.set('selectedConfigId', this.get('selectedConfigId'));
      controller.set('actions', this.controllerActions);
    },
    beforeModel: function beforeModel() {
      this.checkFeature('service_level_monitoring'); // to be removed once slm dashboard is live
      this.checkPrivilege('manage_service_level_configurations');

      var queryMap = {};

      if (this.get('session.account').hasFeature('service_level_monitoring')) {
        queryMap.globalQueue = this.queryStore({ filter_by_global: true });
        queryMap.slmQueues = this.queryStore();
        this.set('queues', [null, RSVP.hash(queryMap)]);
        return;
      }

      this.store.createRecord('serviceLevelConfiguration');
      queryMap.globalQueue = this.store.peekAll('serviceLevelConfiguration');

      this.set('queues', [queryMap, null]);
    },
    model: function model(params) {
      if (params.editQueue) {
        this.set('selectedConfigId', params.editQueue);
      }
      this.get('queues').push('service_level_monitoring');
      return this._super.apply(this, this.get('queues'));
    },
    queryStore: function queryStore() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      return this.store.query('serviceLevelConfiguration', params);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('editQueue', undefined);
        controller.set('selectedConfigId', undefined);
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('isFormDirty') && !window.confirm(_ember['default'].i18n.t('serviceLevel.confirmDialogMessage'))) return transition.abort();
        this.set('editQueue', undefined);
        this.set('selectedConfigId', undefined);
        return true;
      }
    },
    controllerActions: {
      setFormState: function setFormState(state) {
        this.set('isFormDirty', state);
      }
    }
  });
});