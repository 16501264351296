define('freshfone-ember/serializers/business-calendar', ['exports', 'freshfone-ember/serializers/application', 'freshfone-ember/mixins/serializers/embedded-records'], function (exports, _freshfoneEmberSerializersApplication, _freshfoneEmberMixinsSerializersEmbeddedRecords) {
  var _Ember = Ember;
  var isEmpty = _Ember.isEmpty;
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    attrs: {
      workingHours: { embedded: 'always' },
      holidays: { embedded: 'always' }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      var businessTimings = {},
          holidays = [],
          store = this.store;
      var workingHours = snapshot.record.get("workingHours");

      json.data.relationships["working-hours"].data.forEach(function (option) {
        var day = {};
        var timeSlots = Ember.A();
        option.attributes['time-slots'].forEach(function (slot) {
          timeSlots.push({
            "beginning-slot": slot.startTime,
            "end-slot": slot.endTime
          });
        });
        day[option.attributes['day']] = timeSlots;
        Object.assign(businessTimings, day);
      });
      json.data.relationships['holidays'].data.forEach(function (item) {
        var holiday = [];

        holiday[0] = item.attributes['date'];
        holiday[1] = item.attributes['occasion'];

        holidays.push(holiday);
      });

      json.data.attributes['business-time-data'] = businessTimings;
      json.data.attributes['holiday-data'] = holidays;

      delete json.data.relationships["working-hours"];
      delete json.data.relationships['holidays'];

      return json;
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var workingHours = [],
          holidays = [];

      if (!payload.included) {
        payload.included = [];
      }
      payload.data.relationships = {};

      if (Ember.isArray(payload.data)) {
        payload.data.forEach(function (calendar) {
          calendar.relationships = {};

          if (calendar.attributes['business-time-data']) {
            workingHours = _this._normalizeWorkingHours(calendar.id, calendar.attributes['business-time-data']);
            calendar.relationships.workingHours = workingHours;
            workingHours.data.forEach(function (option) {
              payload.included.push(option);
            });
          }

          if (calendar.attributes['holiday-data']) {
            holidays = _this._normalizeHolidays(calendar.id, calendar.attributes['holiday-data']);

            if (holidays) {
              calendar.relationships.holidays = holidays;
              holidays.data.forEach(function (option) {
                payload.included.push(option);
              });
            }
          }
        });
        return this._super(store, primaryModelClass, payload, id, requestType);
      } else {
        workingHours = this._normalizeWorkingHours(payload.data.id, payload.data.attributes['business-time-data']);
        holidays = this._normalizeHolidays(payload.data.id, payload.data.attributes['holiday-data']);
      }

      if (workingHours) {
        payload.data.relationships.workingHours = workingHours;
        workingHours.data.forEach(function (item) {
          return payload.included.push(item);
        });
      }
      if (holidays) {
        payload.data.relationships.holidays = holidays;
        holidays.data.forEach(function (item) {
          return payload.included.push(item);
        });
      }

      // Flushing holidays to repopulate afresh on normalize
      store.peekAll('holiday').filterBy('calendar.id', id).forEach(function (holiday) {
        return holiday.unloadRecord();
      });
      store.peekAll('workingHour').filterBy('calendar.id', id).forEach(function (workingHour) {
        return workingHour.unloadRecord();
      });

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    _normalizeWorkingHours: function _normalizeWorkingHours(businessHrId, workingHrData) {
      var _this2 = this;

      var options = [];
      if (isEmpty(workingHrData)) {
        return;
      }
      var keys = Object.keys(workingHrData);
      keys.forEach(function (k) {
        var option = {
          id: [businessHrId, k].join('_'),
          type: 'working-hour',
          attributes: _this2._normalizeHours(k, workingHrData[k]),
          relationships: {
            calendar: {
              data: {
                id: businessHrId,
                type: "business-calendar"
              }
            }
          }
        };
        options.push(option);
      });
      return { data: options };
    },
    _normalizeHours: function _normalizeHours(id, attr) {
      var timeSlots = Ember.A();
      attr.map(function (slot) {
        timeSlots.push({
          startTime: slot["beginning-slot"],
          endTime: slot["end-slot"]
        });
      });
      return {
        day: id,
        timeSlots: timeSlots
      };
    },

    _normalizeHolidays: function _normalizeHolidays(calendarId, holidayData) {
      var holidays = [];
      if (isEmpty(holidayData)) return;

      holidayData.forEach(function (item, index) {
        holidays.push({
          id: calendarId + '_' + index,
          type: 'holiday',
          attributes: {
            date: item[0],
            occasion: item[1]
          },
          relationships: {
            calendar: {
              data: {
                id: calendarId,
                type: 'business-calendar'
              }
            }
          }
        });
      });

      return { data: holidays };
    }
  });
});