define('freshfone-ember/components/better-moments/component', ['exports', 'ember'], function (exports, _ember) {

  // helpers:default => moment-interval: 1 minutes, moment-cap-month: 30 days

  exports['default'] = _ember['default'].Component.extend({
    capCrossedDate: _ember['default'].computed('created-at', 'moment-cap-month', function () {
      var createdAt = this.get('created-at'),
          dateDiff = moment().diff(createdAt, 'days');

      return dateDiff >= 365 ? moment(createdAt).format('MMM-DD-YYYY') : null;
    }),

    momentInterval: _ember['default'].computed('moment-interval', function () {
      return this.get('moment-interval') || 60000;
    })

  });
});