define('freshfone-ember/models/integrated-application', ['exports', 'ember', 'ember-data', 'ember-api-actions'], function (exports, _ember, _emberData, _emberApiActions) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend({
    integration: belongsTo('integration'),
    integrationActions: hasMany('integration-action'),
    name: computed.alias('integration.name'),
    authInfo: attr(),
    additionalInfo: attr(),
    enabled: attr(),
    getRecentEntities: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'entities_recent' }),
    searchEntities: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'entities_search' }),
    callAction: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'call_action' }),
    domain: computed('authInfo', function () {
      return this.get('authInfo')['domain-url'];
    }),
    getContactUrl: function getContactUrl(contact) {
      return [this.get('domain'), 'contacts', contact.id].join('/');
    },
    isCustomCallLog: computed('additionalInfo', function () {
      return this.get('additionalInfo')['custom-call-log'];
    }),
    contactBaseUrl: function contactBaseUrl() {
      return '' + this.get('domain') + this.get('integration.contactsListUrl');
    }
  });
});