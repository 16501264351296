define('freshfone-ember/components/power-dialer/component', ['exports', 'freshfone-ember/constants/call-status', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _freshfoneEmberConstantsCallStatus, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var ajax = _ember['default'].$.ajax;
  var A = _ember['default'].A;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var or = _Ember$computed.or;
  var not = _Ember$computed.not;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),
    importService: service(),
    importServiceParams: alias('importService.importParams'),
    browserService: service('browser-service'),
    micPermDenied: computed.alias('browserService.isMicrophonePermissionDenied'),
    powerDialerService: service('power-dialer-list'),
    telephony: service(),
    currentCall: alias('telephony.currentCall'),
    successfulTransfer: computed.equal('currentCall.status', 'transferred'),
    isNotTransferredCall: computed('successfulTransfer', 'currentCall.previousStatus', function () {
      return !(this.get('successfulTransfer') || this.get('currentCall.previousStatus') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferred);
    }),
    callTaggingPending: computed.and('isTaggingMandatory', 'hasCallTaggingFeature', 'hasNoCallTags', 'isNotTransferredCall'),
    hasCallTaggingFeature: computed(function () {
      return this.checkFeature('call_tagging');
    }),
    hasNoCallTags: computed.empty('currentCall.tags'),
    isCallsInParkedState: alias('telephony.isCallsInParkedState'),
    enableStartPowerDialer: alias('powerDialerService.enableStartPowerDialer'),
    disableStartPowerDialer: computed('isCallsInParkedState', 'hasCallerPagesInCRM', 'enableStartPowerDialer', function () {
      return this.get('hasCallerPagesInCRM') ? !this.get('enableStartPowerDialer') : this.get('isCallsInParkedState');
    }),
    currentCallActive: not('currentCall.isCompleted'),
    disableEndPowerDialer: computed('currentCallActive', 'powerDialerService.widgetCallInProgress', 'hasCallerPagesInCRM', 'callTaggingPending', function () {
      return this.get('hasCallerPagesInCRM') ? this.get('powerDialerService.widgetCallInProgress') : this.get('currentCallActive') || this.get('callTaggingPending');
    }),
    accountConfigurationService: service('account-configuration'),
    accountConfiguration: computed.alias('accountConfigurationService.config'),
    isTaggingMandatory: computed('accountConfiguration', function () {
      return this.get('accountConfiguration.mandateCallTagging');
    }),
    showImportModal: alias('importServiceParams.showImportModal'),
    scrollTop: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(_, v) {
        return v;
      }
    }),
    currentUser: alias('session.user'),
    currentAgent: alias('currentUser.agent'),
    isAgentInPowerDialerMode: alias('currentAgent.powerDialer'),
    account: computed.alias('session.account'),
    agentBusy: computed.equal('currentAgent.browserStatus.statusType', 'busy'),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    isLowCredit: computed.alias('account.credit.isCreditLow'),
    hasNoActiveNumber: computed.empty('telephony.activeFreshfoneNumber'),
    removeContactFromList: observer('powerDialerService.removeContact', function () {
      if (this.get('powerDialerService.removeContact')) {
        this.send('deleteContact', this.get('powerDialerService.contactId'));
        this.set('powerDialerService.removeContact', false);
      }
    }),
    contactUrlForOmniUser: computed('account.omnichannelOwnerDomain', function () {
      return this.get('account.omnichannelOwnerDomain') + '/a/contacts';
    }),
    isUcrEnabledOmniUser: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    findallFreshfoneNumbers: computed(function () {
      return this.store.findAll('number');
    }),
    isHostAppHasPowerDialerDisabled: computed(function () {
      return this.get('account.isUnifiedAppAccount') && !this.hasGlobalFeature('host_has_power_dialer');
    }),
    noNumbers: computed.empty('findallFreshfoneNumbers'),
    callingDisabled: computed.alias('account.callingDisabled'),
    disablePowerDialer: computed.or('callingDisabled', 'isLowCredit', 'agentBusy', 'hasActiveCall', 'noNumbers', 'hasNoActiveNumber', 'isHostAppHasPowerDialerDisabled', 'isCallsInParkedState'),
    hasCallerPagesInCRM: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),
    currentAccount: computed.alias('session.account'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('hasCallerPagesInCRM')) {
        this.get('powerDialerService').listenPowerDialerEvents();
      }

      var importService = this.get('importService');

      this.setProperties({
        'isAllContactsSelected': false,
        'importServiceParams.fileModel': 'power_dialer',
        'importInProgress': false,
        'queryParams': { page: 2 },
        'showDeleteModal': false,
        'selectedContacts': A(),
        'powerDialerService.removeContact': false
      });
      importService.on('import_progress', function () {
        return _this.set('importInProgress', true);
      });
      importService.on('import_success', function () {
        _this.set('queryParams', { page: 2 });
        _this.get('refreshModel')();
      });
      importService.on('import_complete', function () {
        return _this.set('importInProgress', false);
      });
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('hasCallerPagesInCRM')) {
        this.get('powerDialerService').removePowerDialerEventListener();
      }
    },
    isAllContactsSelected: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(_, value) {
        this.set('selectedContacts', []);

        return value;
      }
    }),
    showShowDeleteButton: or('isAllContactsSelected', 'selectedContacts.length'),

    hasFreshcallerWidgetInstance: function hasFreshcallerWidgetInstance(api) {
      return window.FreshcallerWidgetInstance && window.FreshcallerWidgetInstance[api];
    },

    invokeStartPowerDialerWidgetAPI: function invokeStartPowerDialerWidgetAPI() {
      if (this.hasFreshcallerWidgetInstance('startPowerDialer')) {
        this.set('isAgentInPowerDialerMode', true);
        window.FreshcallerWidgetInstance.startPowerDialer();
      } else {
        // eslint-disable-next-line no-console
        console.error('Power dialer failed to start :: Seems like widget instance is not present to invoke api');
      }
    },
    invokeStopPowerDialerWidgetAPI: function invokeStopPowerDialerWidgetAPI() {
      if (this.hasFreshcallerWidgetInstance('stopPowerDialer')) {
        window.FreshcallerWidgetInstance.stopPowerDialer();
      } else {
        // eslint-disable-next-line no-console
        console.error('Power dialer failed to stop :: Seems like widget instance is not present to invoke api');
      }
    },

    actions: {
      selectContactHandler: function selectContactHandler(contactID, isChecked) {
        var shouldFlip = !isChecked && this.get('isAllContactsSelected');

        if (shouldFlip || isChecked) {
          this.get('selectedContacts').pushObject(contactID);
        } else {
          this.set('selectedContacts', this.get('selectedContacts').filter(function (id) {
            return id !== contactID;
          }));
        }

        if (shouldFlip && this.get('selectedContacts.length') === this.get('powerDialerContacts.length')) {
          this.set('isAllContactsSelected', false);
        }

        if (!shouldFlip && this.get('selectedContacts.length') === this.get('powerDialerContacts.length')) {
          this.set('isAllContactsSelected', true);
        }
      },
      selectAllContactsHandler: function selectAllContactsHandler(e) {
        this.set('isAllContactsSelected', e.target.checked);
      },
      deleteContact: function deleteContact(powerDialerContactId) {
        var filteredPowerDialerContacts = this.get('powerDialerContacts').filter(function (powerDialerContact) {
          return powerDialerContact.get('id') !== powerDialerContactId;
        });

        this.setProperties({
          powerDialerContacts: filteredPowerDialerContacts,
          totalContactsCount: this.get('totalContactsCount') - 1
        });
      },
      closeCallback: function closeCallback() {
        var _this2 = this;

        this.get('selectedContacts').forEach(function (contact) {
          return _this2.send('selectContactHandler', contact, false);
        });
        this.setProperties({
          showDeleteModal: false,
          isAllContactsSelected: false
        });
      },
      deleteContacts: function deleteContacts() {
        var _this3 = this;

        var contactsToBeDeleted = this.get('isAllContactsSelected') ? this.get('powerDialerContacts').filter(function (powerDialerContact) {
          return !_this3.get('selectedContacts').includes(powerDialerContact.get('id'));
        }).map(function (powerDialerContact) {
          return powerDialerContact.get('id');
        }) : this.get('selectedContacts'),
            data = {};

        if (this.get('isAllContactsSelected') && !this.get('selectedContacts.length')) {
          // eslint-disable-next-line camelcase
          data.destroy_all = true;
        } else {
          data['filter[id]'] = contactsToBeDeleted.toString();
        }

        ajax({
          url: '/power_dialer_lists/bulk_destroy',
          type: 'DELETE',
          dataType: 'json',
          data: data
        }).done(function () {
          var filteredPowerDialerContacts = _this3.get('powerDialerContacts').filter(function (powerDialerContact) {
            return !contactsToBeDeleted.includes(powerDialerContact.get('id'));
          });

          _this3.setProperties({
            powerDialerContacts: filteredPowerDialerContacts,
            isAllContactsSelected: false,
            selectedContacts: [],
            totalContactsCount: filteredPowerDialerContacts.length
          });
          _this3.toggleProperty('showDeleteModal');
        });
      },
      startPowerDialer: function startPowerDialer() {
        if (this.get('disableStartPowerDialer')) {
          return;
        }

        if (this.get('hasCallerPagesInCRM')) {
          this.invokeStartPowerDialerWidgetAPI();
        } else {
          this.get('powerDialerService').setPowerDialer(true);
          $('.btn-outgoing').click();
        }
      },
      stopPowerDialerMode: function stopPowerDialerMode() {
        var _this4 = this;

        if (this.get('hasCallerPagesInCRM')) {
          this.invokeStopPowerDialerWidgetAPI();
        } else {
          this.store.peekAll('power-dialer-list').get('lastObject').stop().then(function () {
            run(function () {
              _this4.set('currentAgent.powerDialer', false);
              _this4.get('powerDialerService').closeWidget(true);
            });
          });
        }
      },
      redirectToContacts: function redirectToContacts() {
        this.get('telephony').redirectToCrmPages();
      }
    }
  });
});