define('freshfone-ember/models/routing-automation', ['exports', 'ember-validations', 'ember-data', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/message-default-types', 'freshfone-ember/constants/routing-automation', 'ember-api-actions', 'freshfone-ember/mixins/remove-feature-actiontype', 'freshfone-ember/mixins/call-flow-child-validation-mixin'], function (exports, _emberValidations, _emberData, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersMessageDefaultTypes, _freshfoneEmberConstantsRoutingAutomation, _emberApiActions, _freshfoneEmberMixinsRemoveFeatureActiontype, _freshfoneEmberMixinsCallFlowChildValidationMixin) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Model.extend(_emberValidations['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsCallFlowChildValidationMixin['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], {
    session: service(),
    name: attr('string'),
    appId: attr(),
    appName: attr(),
    methodName: attr(),
    inputType: attr(),
    appFailureActionType: attr(),
    appFailureActionId: attr(),
    invalidInputThreshold: attr('number', { defaultValue: 3 }),
    invalidInputActionType: attr(),
    invalidInputActionId: attr(),
    routingRuleType: attr('number', { defaultValue: 1 }),
    routingRulesCount: attr('number', { defaultValue: 0 }),
    callFlow: belongsTo('callFlow'),
    message: belongsTo('message', { async: true }),
    holdMessage: belongsTo('message', { async: true }),
    invalidInputMessage: belongsTo('message', { async: true }),
    routingRules: hasMany('routingRule', { async: false }),
    anyError: computed.not('isValid'),
    isARoutingAutomation: computed(function () {
      return this.constructor.modelName === 'routing-automation';
    }),
    emptyAppsForAccount: computed.not('customApps'),
    notSaveRoutingAutomationFallback: computed.and('id', 'emptyAppsForAccount', 'isARoutingAutomation'),
    actionOptions: computed(function () {
      var options = [{
        id: 0,
        chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_ivr'),
        desc: _ember['default'].i18n.t('call_ivr.action_types.ivr'),
        name: 'ivr'
      }, {
        id: 1,
        chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_callQueue'),
        desc: _ember['default'].i18n.t('call_ivr.action_types.call_queue'),
        name: 'callQueue'
      }, {
        id: 2,
        chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.hangup'),
        desc: _ember['default'].i18n.t('call_ivr.action_types.hangup'),
        name: 'hangup',
        helpText: _ember['default'].i18n.t('call_queue.edit.fallback.hangup.help_text')
      }, {
        id: 3,
        chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.voicemail'),
        desc: _ember['default'].i18n.t('call_ivr.action_types.voicemail'),
        name: 'vm',
        helpText: _ember['default'].i18n.t('call_queue.edit.fallback.vm.help_text')
      }, {
        id: 7,
        chooseDesc: _ember['default'].i18n.t('call_ivr.action_types.choose_routing_automation'),
        desc: _ember['default'].i18n.t('call_ivr.action_types.routing_automation'),
        name: 'routingAutomation'
      }],
          featuresToValidate = [{
        feature: 'voicemail_and_status_fallback',
        actionType: 'vm'
      }, {
        feature: 'routing_automation',
        actionType: 'routingAutomation',
        globalFlag: 'routing_automation_fallback'
      }];

      return this.getFilteredActionTypes(featuresToValidate, options);
    }),
    getFilteredActionTypes: function getFilteredActionTypes(featuresToBeFiltered, options) {
      var filteredActionTypes = options;

      featuresToBeFiltered.forEach((function validateAndRemove(_ref) {
        var feature = _ref.feature;
        var actionType = _ref.actionType;
        var globalFlag = _ref.globalFlag;

        if (!this.hasFeatures([feature]) || globalFlag && !this.get('session.account').hasGlobalFlag(globalFlag)) {
          filteredActionTypes = this.removeObjFromArray(options, 'name', actionType);
        }
      }).bind(this));

      return filteredActionTypes;
    },
    inputTypeOptions: computed(function () {
      return [{
        name: _ember['default'].i18n.t('data_automated_routing.form.input_type_options.single_digit'),
        value: 0
      }, {
        name: _ember['default'].i18n.t('data_automated_routing.form.input_type_options.multiple_digits'),
        value: 1
      }, {
        name: _ember['default'].i18n.t('data_automated_routing.form.input_type_options.speech'),
        value: 2
      }, {
        name: _ember['default'].i18n.t('data_automated_routing.form.input_type_options.phone_number'),
        value: 3
      }];
    }),
    appNameDisplayValue: computed('customApps', {
      get: function get() {
        var selectedApp = undefined;

        if (this.get('customApps') && this.get('customApps').length) {
          if (this.get('appId')) {
            selectedApp = this.get('customApps').findBy('app_id', '' + this.get('appId'));
            if (selectedApp) {
              this.loadAppActions(selectedApp);
              this.set('appName', selectedApp.app_name);
            }
          }
        }

        return selectedApp;
      },
      set: function set(key, selectedApp) {
        this.set('functionNameDisplayValue', {});
        this.loadAppActions(selectedApp);
        this.set('appName', selectedApp.app_name);

        return selectedApp;
      }
    }),
    functionNameDisplayValue: computed('methodName', {
      get: function get() {
        return;
      },
      set: function set(key, appAction) {
        if (!isEmpty(appAction)) {
          this.set('methodName', appAction.name);
        } else {
          this.set('methodName', '');
        }

        return appAction;
      }
    }),
    inputTypeDisplayValue: computed('inputType', {
      get: function get() {
        if (this.get('inputType')) {
          return this.get('inputTypeOptions').findBy('value', this.get('inputType'));
        }

        this.set('inputType', this.get('inputTypeOptions')[0].value);

        return this.get('inputTypeOptions')[0];
      },
      set: function set(key, inputObject) {
        this.set('inputType', inputObject.value);

        return inputObject;
      }
    }),

    loadCustomApps: (0, _emberApiActions.collectionAction)({
      type: 'GET',
      path: 'list_apps'
    }),
    loadAppActions: function loadAppActions(_ref2) {
      var _this = this;

      var appId = _ref2.app_id;
      var versionId = _ref2.version_id;

      var path = appId + '/app_actions?version_id=' + versionId,
          getAppActions = (0, _emberApiActions.collectionAction)({
        type: 'GET',
        path: path
      });

      return getAppActions.call(this).then(function (actions) {
        var appAction = undefined;

        _this.set('selectedAppActions', actions);
        if (Array.isArray(actions) && actions.length) {
          if (_this.get('methodName')) {
            var _get$filterBy = _this.get('selectedAppActions').filterBy('name', _this.get('methodName'));

            var _get$filterBy2 = _slicedToArray(_get$filterBy, 1);

            appAction = _get$filterBy2[0];
          } else {
            var _get = _this.get('selectedAppActions');

            var _get2 = _slicedToArray(_get, 1);

            appAction = _get2[0];
          }
        }

        _this.set('functionNameDisplayValue', appAction);
      })
      // eslint-disable-next-line no-console
      ['catch'](function (error) {
        return console.log(error);
      });
    },
    rollbackRoutingRulesCount: function rollbackRoutingRulesCount() {
      this.set('routingRulesCount', (this.changedAttributes().routingRulesCount || [this.get('routingRulesCount')])[0]);
    },
    validateRoutingRules: function validateRoutingRules() {
      var _this2 = this;

      var rules = this.get('routingRules'),
          routingAutomationId = this.checkFeature('circular_call_flow_loop_fix') ? this.get('id') : undefined,
          promiseArray = [],
          uniqueResponseCollection = [];

      rules.forEach(function (rule) {
        if (!uniqueResponseCollection.includes(rule.get('response'))) {
          if (rule.get('response')) {
            uniqueResponseCollection.push(rule.get('response'));
          }
          rule.set('errors.response', []);
          promiseArray.push(rule.saveChildActions(['action'], routingAutomationId).then(function () {
            return rule.validate();
          }));
        } else {
          rule.set('errors.response', [_this2.get('i18n').t('data_automated_routing.validation_errors.duplicate_response')]);
          promiseArray.push(Promise.reject());
        }
      });

      return Promise.all(promiseArray);
    },
    validate: function validate() {
      var _this3 = this;

      if (this.get('routingRuleType') === _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES['import']) {
        this.set('routingRules', this.get('routingRules').filterBy('isNew', false));
      }

      var validateSuper = this._super,
          rulePromise = this.get('routingRuleType') === _freshfoneEmberConstantsRoutingAutomation.RULE_TYPES.create ? this.validateRoutingRules() : Promise.resolve(),
          routingAutomationPromise = this.saveChildActions(['invalidInputAction', 'appFailureAction']);

      return Promise.all([rulePromise, routingAutomationPromise]).then(function () {
        return validateSuper.call(_this3);
      }).then(function () {
        return _this3.set('isValid', true);
      })['catch'](function (error) {
        _this3.set('isValid', false);
        throw error;
      });
    },
    blankActionError: function blankActionError(property) {
      if (!this.get(property + 'Id')) {
        if (this.get(property + 'Type') === 'callQueue') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_callqueue');
        } else if (this.get(property + 'Type') === 'ivr') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_ivr');
        } else if (this.get(property + 'Type') === 'routingAutomation') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_routing_automation');
        }
      }
    },
    createDefaultRoutingRule: function createDefaultRoutingRule() {
      var _store$peekAll$filterBy = this.store.peekAll('message').filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage);

      var _store$peekAll$filterBy2 = _slicedToArray(_store$peekAll$filterBy, 1);

      var defaultMessage = _store$peekAll$filterBy2[0];
      var newRoutingRule = this.store.createRecord('routing-rule');

      newRoutingRule.setProperties({
        actionId: defaultMessage.get('id'),
        actionType: this.get('actionOptions')[2].name,
        routingAutomation: this
      });

      this.get('routingRules').pushObject(newRoutingRule);
    },

    validations: {
      name: { presence: { message: _ember['default'].i18n.t('call_ivr.validation_errors.name') } },
      appName: { presence: { message: _ember['default'].i18n.t('data_automated_routing.validation_errors.app_name') } },
      methodName: { presence: { message: _ember['default'].i18n.t('data_automated_routing.validation_errors.function_name') } },
      message: { presence: { message: _ember['default'].i18n.t('call_ivr.validation_errors.message') } },
      holdMessage: { presence: { message: _ember['default'].i18n.t('call_ivr.validation_errors.message') } },
      invalidInputMessage: { presence: { message: _ember['default'].i18n.t('call_ivr.validation_errors.message') } },
      invalidInputThreshold: {
        presence: { message: _ember['default'].i18n.t('call_ivr.validation_errors.retries.blank') },
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 10,
          messages: {
            numericality: _ember['default'].i18n.t('call_ivr.validation_errors.retries.number'),
            onlyInteger: _ember['default'].i18n.t('call_ivr.validation_errors.retries.integer'),
            greaterThanOrEqualTo: _ember['default'].i18n.t('call_ivr.validation_errors.retries.greater'),
            lessThanOrEqualTo: _ember['default'].i18n.t('call_ivr.validation_errors.retries.lesser')
          }
        }
      },
      invalidInputActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('invalidInputAction');
        })
      },
      appFailureActionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('appFailureAction');
        })
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */
/* eslint-disable sort-vars */