define('freshfone-ember/components/custom-elements/delete-model/component', ['exports', 'ember', 'freshfone-ember/mixins/click-outside-listener'], function (exports, _ember, _freshfoneEmberMixinsClickOutsideListener) {
	var computed = _ember['default'].computed;
	exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], {
		isDeleting: computed.alias('model.isDeleting'),
		isSoftDelete: computed.alias('model.isSoftDelete'),
		init: function init() {
			this._super.apply(this, arguments);
			//if(this.get('isDeleting')){
			this.bindOutsideClick();
			//}
		},
		onOutsideClick: function onOutsideClick() {
			this.send('toggleDelete');
			this.unbindOutsideClick();
		},
		deleteChild: function deleteChild() {
			if (this.get("child")) {
				this.get("child").then(function (child) {
					if (child) {
						child.destroyRecord();
					}
				});
			}
		},
		setAgentLimit: function setAgentLimit() {
			if (this.get('activeUsersCount')) {
				this.decrementProperty('activeUsersCount');
			}
			if (this.get('slideToggle')) {
				this.sendAction('slideToggle');
			}
		},

		actions: {
			toggleDelete: function toggleDelete() {
				this.set('isDeleting', false);
			},
			'delete': function _delete() {
				var _this = this;

				if (this.get('isSoftDelete')) {
					return this.send('softDelete');
				}
				var model = this.get('model');
				this.deleteChild();
				model.destroyRecord().then(function () {
					if (_this.get("deleteCallback")) {
						_this.get("deleteCallback")();
					}
					if (_this.get('onModify')) {
						_this.sendAction('onModify');
					}
					_this.setAgentLimit();
				});
			},
			softDelete: function softDelete() {
				var _this2 = this;

				var model = this.get('model');
				if (this.get("deleteCallback")) this.get("deleteCallback")().then(function () {
					return _this2.get('onModify') && _this2.sendAction('onModify');
				});
			}
		}
	});
});