define('freshfone-ember/mixins/model-form-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    save: function save(modelName, model) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        model.set('isFlash', false);
        model.save().then(function () {
          _this.set(modelName, model);
          model.set('isFlash', true);
          /* flash class has to be removed after flash effect 2secs*/
          _ember['default'].run.later(_this, function () {
            model.set('isFlash', false);
          }, 2000);
          model.saveOriginalRelations();
          resolve(model);
        })['catch'](function (e) {
          model.get('errors').setProperties(e.errors);
          resolve(model);
        });
      });
    },
    destroyRecord: function destroyRecord(modelName, model) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        model.set('isDeleting', true);
        _ember['default'].run.later(_this2, function () {
          model.destroyRecord().then(function () {
            _this2.set(modelName, model);
            model.saveOriginalRelations();
            resolve(model);
          })['catch'](function (e) {
            model.set('isDeleting', false);
            model.get('errors').setProperties(e.errors);
            resolve(model);
          });
        }, 1000);
      });
    },
    rollbackDirtyChanges: function rollbackDirtyChanges(modelKey) {
      if (!this.get(modelKey)) {
        return;
      }

      if (this.get(modelKey + '.hasDirtyAttributes')) {
        this.get(modelKey).rollbackAttributes();
      }

      if (this.get(modelKey).isDeepDirty()) {
        this.get(modelKey).rollbackRelationships();
      }

      this.set(modelKey, null);
    },

    actions: {
      edit: function edit(modelKey, item) {
        this.rollbackDirtyChanges(modelKey);
        item.saveOriginalRelations();
        this.set(modelKey, item);
      }
    }

  });
});