define('freshfone-ember/routes/protected/voicemail-drop', ['exports', 'ember', 'freshfone-ember/constants/message', 'freshfone-ember/routes/promotable'], function (exports, _ember, _freshfoneEmberConstantsMessage, _freshfoneEmberRoutesPromotable) {
    var computed = _ember['default'].computed;
    var alias = _ember['default'].computed.alias;
    exports['default'] = _freshfoneEmberRoutesPromotable['default'].extend({
        account: alias('session.account'),
        hasVoicemailDropFeature: computed(function () {
            return this.get('account').hasFeature('voicemail_drop');
        }),
        titleToken: computed(function () {
            return _ember['default'].i18n.t('navigation.voicemailDrop');
        }),
        breadCrumb: computed(function () {
            return { title: _ember['default'].i18n.t('navigation.voicemailDrop') };
        }),

        beforeModel: function beforeModel() {
            return this.get('hasVoicemailDropFeature') || this.checkPrivilege('manage_voicemail_drop');
        },
        model: function model() {
            return this._super([], this.get('hasVoicemailDropFeature') ? this.store.query('message', { scope: _freshfoneEmberConstantsMessage.SCOPE_USER }) : [], 'voicemail_drop');
        }
    });
});