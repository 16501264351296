define('freshfone-ember/components/call-logs/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/call-logs-filter', 'freshfone-ember/constants/map-by-key-name-contants', 'ember-authority/helpers/has-privilege'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsCallLogsFilter, _freshfoneEmberConstantsMapByKeyNameContants, _emberAuthorityHelpersHasPrivilege) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var isEqual = _ember['default'].isEqual;
  var isPresent = _ember['default'].isPresent;
  var later = _ember['default'].run.later;
  var testing = _ember['default'].testing;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    platform: service('browser-service'),
    currentUser: computed.alias('session.user'),
    account: computed.alias('session.account'),
    accountName: computed.alias('session.account.name'),
    callQueus: computed.alias('filterConfiguration.by_queue.queue_id'),
    searchNote: false,
    searchString: '',
    filterParams: { load_preferred_attributes: true },
    filterDetails: [],
    openFilterModal: false,
    showFilters: false,
    callsLoading: true,
    perPage: 10,
    hasFilterCalls: computed.notEmpty('filterCalls'),
    hasPages: computed.gt('totalPages', 1),
    serviceToggle: computed.alias('filterConfiguration.by_service_level_breached'),
    powerDialerCalls: computed.alias('filterConfiguration.by_power_dialer'),
    numbers: computed.alias('filterConfiguration.by_number.number_id'),
    callStatuses: computed.alias('filterConfiguration.by_status.status'),
    callType: computed.alias('filterConfiguration.by_direction'),
    callId: computed.alias('filterConfiguration.by_call_id.call_id'),
    contacts: computed(function () {
      if (this.isUcrSearchEnabled()) {
        return this.get('filterConfiguration.by_org_contact.org_contact_id');
      }

      return this.get('filterConfiguration.by_contact.contact_id');
    }),
    agents: computed.alias('filterConfiguration.by_agent.agent_id'),
    callQueues: computed.alias('filterConfiguration.by_queue.queue_id'),
    teams: computed.alias('filterConfiguration.by_team.team_id'),
    businessHourCall: computed.alias('filterConfiguration.by_business_hour_call'),
    tags: computed.alias('filterConfiguration.by_tags.tag_id'),
    waitQueueActions: computed.alias('filterConfiguration.by_call_action.category'),
    checkIfMicrophoneNeeded: computed('session.isMicrophonePermissionNeeded', function () {
      if (!this.get('platform').checkIfMicrophoneAllowed() && this.get('session.isMicrophonePermissionNeeded')) {
        this.set('showMicrophoneNotification', true);
        this.triggerMicrophonePermission();
      }

      return !this.get('platform').checkIfMicrophoneAllowed() && this.get('session.isMicrophonePermissionNeeded');
    }),
    hasSearchTranscriptionFeature: computed(function () {
      return this.hasGlobalFeature('search_transcription') && this.checkFeature('postcall_transcription');
    }),
    isDefaultFilters: computed.equal('filtersCount', 0),
    filtersCount: computed('serviceToggle', 'powerDialerCalls', 'numbers.[]', 'selectedTimePeriod.[]', 'selectedTimePeriod', 'callType', 'callStatuses.[]', 'businessHourCall', 'contacts.[]', 'agents.[]', 'waitQueueActions.[]', 'callQueues.[]', 'teams.[]', 'tags.[]', function () {
      var count = 0;

      if (this.get('serviceToggle')) {
        count += 1;
      }
      if (this.get('powerDialerCalls')) {
        count += 1;
      }
      if (this.get('callType')) {
        count += 1;
      }
      if (this.get('businessHourCall')) {
        count += 1;
      }
      if (this.get('selectedTimePeriod.length')) {
        count += 1;
      }
      count += (this.get('numbers.length') || 0) + (this.get('callStatuses.length') || 0) + (this.get('contacts.length') || 0) + (this.get('agents.length') || 0) + (this.get('waitQueueActions.length') || 0) + (this.get('callQueues.length') || 0) + (this.get('teams.length') || 0) + (this.get('tags.length') || 0);

      return count;
    }),

    callFilterParams: computed('serviceToggle', 'powerDialerCalls', 'numbers.[]', 'selectedTimePeriod', 'callStatuses.[]', 'callType', 'businessHourCall', 'contacts.[]', 'agents.[]', 'waitQueueActions.[]', 'callQueues.[]', 'teams.[]', 'tags.[]', 'callId', 'initialFilterParams', 'searchString', function () {
      var filterParams = {
        serviceLevelBreached: this.get('serviceToggle') || [],
        powerDialerCalls: this.get('powerDialerCalls') || [],
        number_id: (this.get('numbers') || []).mapBy('id'),
        date_range: this.get('selectedTimePeriod'),
        status: (this.get('callStatuses') || []).mapBy('id'),
        direction: this.get('callType.id'),
        call_id: this.get('callId'),
        business_hour_call: this.get('businessHourCall.id'),
        agent_id: (this.get('agents') || []).mapBy('id'),
        call_action: (this.get('waitQueueActions') || []).mapBy('id'),
        queue_id: (this.get('callQueues') || []).mapBy('id'),
        team_id: (this.get('teams') || []).mapBy('id'),
        tag_id: (this.get('tags') || []).mapBy('id')
      };

      if (this.isUcrSearchEnabled()) {
        filterParams.org_contact_id = this.get('contacts').mapBy(_freshfoneEmberConstantsMapByKeyNameContants['default'].withoutAttribute.mcrId);
        var callIds = this.get('callId');

        if (callIds && callIds.length) {
          filterParams.call_id = callIds;
          this.set('isRedirection', true);
        }
      } else {
        filterParams.contact_id = this.get('contacts').mapBy(_freshfoneEmberConstantsMapByKeyNameContants['default'].withoutAttribute.id);
      }

      return this.appendSearchParams(filterParams);
    }),

    appendSearchParams: function appendSearchParams(filterParams) {
      if (isPresent(this.get('searchString'))) {
        var searchString = this.get('searchString');

        if (this.get('searchInfoType') === _freshfoneEmberConstantsCallLogsFilter['default'].callTranscripts) {
          filterParams.search_text = searchString;
        } else {
          filterParams.search_string = searchString;
        }
      }

      return filterParams;
    },
    showDeletionConfirmation: computed('recordDeletionConfirmation', 'noteDeletionConfirmation', {
      get: function get() {
        return this.get('recordDeletionConfirmation') || this.get('noteDeletionConfirmation');
      },
      set: function set(key, value) {
        if (value === false) {
          this.setProperties({
            recordDeletionConfirmation: false,
            noteDeletionConfirmation: false
          });
        }

        return value;
      }
    }),

    recordDeleteButtonText: computed('isRecordDeleting', function () {
      // eslint-disable-next-line ember-suave/no-direct-property-access
      return this.get('isRecordDeleting') ? _ember['default'].i18n.t('common.deleting') : _ember['default'].i18n.t('common.delete');
    }),
    currentCallDetailSection: 'callSummary',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('filterParams', { load_preferred_attributes: true });
      this._loadMetaAndRender(this.get('filterCalls'));
      this.set('selectedTimePeriod', []);
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('selectedCallId')) {
        later(this, function () {
          return _this.set('showCallDetails', true);
        }, 400);
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('selectedCallId', undefined);
      this.set('callId', undefined);
      this.set('isRedirection', false);
    },

    triggerMicrophonePermission: function triggerMicrophonePermission() {
      var _this2 = this;

      // hide the notification once the permission promise is resolved/rejected
      this.get('platform').getMicrophonePermission()['finally'](function () {
        return _this2.set('showMicrophoneNotification', false);
      });
    },
    queuesSelectedInDashboard: function queuesSelectedInDashboard() {
      var _this3 = this;

      return this.store.peekAll('call-queue').filter(function (queue) {
        return _this3.get('filterCalls').query.queue_id.includes(queue.id);
      });
    },

    canDownloadRecording: computed(function () {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'download_recording',
        user: this.get('currentUser')
      });
    }),
    canDeleteRecording: computed(function () {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_call_recording',
        user: this.get('currentUser')
      });
    }),
    canDeleteNote: computed(function () {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'manage_call_conversation',
        user: this.get('currentUser')
      });
    }),
    canDownloadTranscription: computed(function () {
      return (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: 'download_call_transcription',
        user: this.get('currentUser')
      });
    }),

    hasPcipalFeature: computed(function () {
      return this.checkFeature('pcipal_agentassist') && this.hasGlobalFeature('pcipal_agentassist');
    }),

    _loadCalls: function _loadCalls(shouldSaveConfig) {
      var _this4 = this;

      this.set('callsLoading', true);
      shouldSaveConfig && this.set('filterParams.save_filter', shouldSaveConfig);
      var defaultFilters = Object.assign({}, this.get('initialFilterParams')),
          consolidatedFilterParams = Object.assign(defaultFilters, this.get('filterParams'));

      this.store.query('call', consolidatedFilterParams).then(function (calls) {
        _this4.set('filterCalls', calls);
        _this4._loadMetaAndRender(calls);
      });
      this.set('searchInfoText', this.get('searchString'));
    },

    _loadMetaAndRender: function _loadMetaAndRender(calls) {
      this.setProperties({
        totalCount: calls.meta['total-count'],
        page: calls.meta.current,
        totalPages: calls.meta['total-pages'],
        callsLoading: false
      });
    },
    triggerSearch: function triggerSearch(searchString) {
      delete this.get('filterParams').search_string;
      this.setProperties({
        'searchInfoType': 'Call Transcripts',
        'filterParams.page': '1',
        'filterParams.search_text': searchString
      });
      this._loadCalls();
    },

    _clearFiltersAndReload: function _clearFiltersAndReload() {
      this.set('filterParams', {});
      this.set('filterDetails', []);
      if (isPresent(this.get('searchString'))) {
        if (this.get('searchInfoType') === 'Call Transcripts') {
          this.set('filterParams.search_text', this.get('searchString'));
        } else {
          this.set('filterParams.search_string', this.get('searchString'));
        }
      }
      this._loadCalls(true);
    },

    _applySearch: function _applySearch(searchString) {
      delete this.get('filterParams').search_text;
      this.setProperties({
        'searchInfoType': 'Call Notes',
        'filterParams.page': '1',
        'filterParams.search_string': searchString
      });
      this._loadCalls();
    },

    actions: {
      searchNotes: function searchNotes() {
        var searchString = this.get('searchString');

        if (isPresent(searchString)) {
          if (!isEqual(searchString, this.get('filterParams.search_string'))) {
            this.set('filterParams.ancestry', true);
            this._applySearch(searchString);
          }
        } else {
          delete this.get('filterParams').ancestry;
          if (isPresent(this.get('filterParams.search_string'))) {
            this._applySearch(searchString);
          }
        }
      },
      searchTranscript: function searchTranscript() {
        var searchString = this.get('searchString');

        if (isPresent(searchString)) {
          if (!isEqual(searchString, this.get('filterParams.search_text'))) {
            this.set('filterParams.ancestry', true);
            this.triggerSearch(searchString);
          }
        } else {
          delete this.get('filterParams').ancestry;
          if (isPresent(this.get('filterParams.search_text'))) {
            this.triggerSearch(searchString);
          }
        }
      },
      pageChanged: function pageChanged(current) {
        var params = this.get('callFilterParams');

        params.page = current;
        this.set('filterParams', params);
        this._loadCalls();
      },

      change: function change(page) {
        var params = this.get('callFilterParams');

        params.page = page;
        this.set('filterParams', params);
        this._loadCalls();
      },

      toggleBlock: function toggleBlock(caller) {
        var _this5 = this;

        caller.then(function (caller) {
          if (caller.get('blocked')) {
            caller.toggleBlock();
          } else {
            _this5.set('showBlockConfirmation', true);
            _this5.set('blacklistedCaller', caller);
          }
        });
      },

      block: function block() {
        var _this6 = this;

        var caller = this.get('blacklistedCaller');

        caller.toggleBlock().then(function () {
          _this6.set('showBlockConfirmation', false);
          _this6.set('blacklistedCaller', null);
        });
      },

      cancelBlacklist: function cancelBlacklist() {
        this.set('showBlockConfirmation', false);
        this.set('blacklistedCaller', null);
      },

      openCallFilter: function openCallFilter() {
        this.set('openFilterModal', true);
      },
      closeCallFilter: function closeCallFilter() {
        this.set('openFilterModal', false);
      },

      filterCallLogs: function filterCallLogs() {
        var params = !this.get('isDefaultFilters') ? this.get('callFilterParams') : null;

        this.set('openFilterModal', false);
        if (params) {
          this.set('filterParams', params);
          if (isPresent(this.get('searchString'))) {
            if (this.get('searchInfoType') === 'Call Transcripts') {
              this.set('filterParams.search_text', this.get('searchString'));
            } else {
              this.set('filterParams.search_string', this.get('searchString'));
            }
          }
          this._loadCalls(true);
        } else {
          this._clearFiltersAndReload();
        }
      },
      clearFilters: function clearFilters() {
        this.set('openFilterModal', false);
        this._clearFiltersAndReload();
      },

      setLastCursorPosition: function setLastCursorPosition(event) {
        this.set('lastMousePosition', {
          X: event.clientX,
          Y: event.clientY
        });
      },

      handleCallLogRowClick: function handleCallLogRowClick(selectedCall, isAgentLeg, event) {
        var lastMousePosition = this.get('lastMousePosition');

        if (this.element.querySelector('.integrations.dropdown-expanded') || this.element.querySelector('.show-more-option.dropdown-expanded')) {
          return;
        }

        if (isPresent(lastMousePosition) && !testing && (Math.abs(event.clientX - lastMousePosition.X) > 20 || Math.abs(event.clientY - lastMousePosition.Y) > 20)) {
          return;
        }

        if (event.target.closest('.prevent-call-selection')) {
          return;
        }

        if (event.target.closest('.dropdown-actions.call-transcript')) {
          this.set('currentCallDetailSection', 'callTranscript');
        } else {
          this.set('currentCallDetailSection', 'callSummary');
        }

        this.set('selectedCallId', selectedCall.get('id'));
        this.set('isAgentLeg', isAgentLeg);
        this.set('showCallDetails', true);
      },

      closeCallDetails: function closeCallDetails() {
        this.set('showCallDetails', false);
        this.set('isRedirection', false);
      },

      downloadRecording: function downloadRecording(call) {
        window.open(call.recordingUrl, '_self');
      },
      deleteRecordingOrNote: function deleteRecordingOrNote(call) {
        var _this7 = this;

        this.set('isRecordDeleting', true);

        if (this.get('recordDeletionConfirmation')) {
          call.deleteRecording().then(function (result) {
            _this7.set('isRecordDeleting', false);
            _this7.get('store').pushPayload('call', result);
            _this7.set('recordDeletionConfirmation', false);
          });
        } else {
          call.deleteConversation().then(function (result) {
            _this7.set('isRecordDeleting', false);
            _this7.get('store').pushPayload('call', result);
            _this7.set('noteDeletionConfirmation', false);
          });
        }
      },
      toggleDeletionPopup: function toggleDeletionPopup(resourceType, call) {
        if (call) {
          this.set('selectedCall', call);
        }
        if (this.get('showCallDetails')) {
          this.set('showCallDetails', false);
        }
        if (resourceType === 'record') {
          this.set('recordDeletionConfirmation', true);
        } else {
          this.set('noteDeletionConfirmation', true);
        }
      },
      closeDeletionPopup: function closeDeletionPopup() {
        this.set('showDeletionConfirmation', false);
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */
/* eslint-disable camelcase */