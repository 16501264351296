define("freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 9,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon-queue-transfer");
            dom.setAttribute(el1, "data-toggle", "tooltip");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element9, 'title');
            return morphs;
          },
          statements: [["attribute", "title", ["subexpr", "t", ["transfer.queue_transfer_feature"], [], ["loc", [null, [null, null], [8, 102]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 34,
                        "column": 14
                      },
                      "end": {
                        "line": 38,
                        "column": 14
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "queue-users");
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
                    return morphs;
                  },
                  statements: [["inline", "t", ["transfer.ivr_queue"], [], ["loc", [null, [36, 24], [36, 50]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 38,
                          "column": 14
                        },
                        "end": {
                          "line": 42,
                          "column": 14
                        }
                      },
                      "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "queue-users");
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n              ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                      return morphs;
                    },
                    statements: [["inline", "concat", [["subexpr", "get", [["get", "availableUsersCountDetails", ["loc", [null, [40, 32], [40, 58]]], 0, 0, 0, 0], ["get", "queue.id", ["loc", [null, [40, 59], [40, 67]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 27], [40, 68]]], 0, 0], " ", ["subexpr", "t", ["transfer.agents_available"], [], ["loc", [null, [40, 73], [40, 104]]], 0, 0]], [], ["loc", [null, [40, 18], [40, 106]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 38,
                        "column": 14
                      },
                      "end": {
                        "line": 42,
                        "column": 14
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["subexpr", "get", [["get", "availableUsersCountDetails", ["loc", [null, [38, 37], [38, 63]]], 0, 0, 0, 0], ["get", "queue.id", ["loc", [null, [38, 64], [38, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 32], [38, 73]]], 0, 0], 0], [], ["loc", [null, [38, 28], [38, 76]]], 0, 0], ["subexpr", "get", [["get", "availableUsersCountDetails", ["loc", [null, [38, 82], [38, 108]]], 0, 0, 0, 0], ["get", "queue.id", ["loc", [null, [38, 109], [38, 117]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 77], [38, 118]]], 0, 0]], [], ["loc", [null, [38, 24], [38, 119]]], 0, 0]], [], 0, null, ["loc", [null, [38, 14], [42, 14]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 8
                    },
                    "end": {
                      "line": 48,
                      "column": 8
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "queue-row");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "queue-details");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "data-toggle", "tooltip");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "forward-button");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("button");
                  dom.setAttribute(el3, "class", "queue-controls");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var element2 = dom.childAt(element1, [1]);
                  var element3 = dom.childAt(element2, [1]);
                  var element4 = dom.childAt(element1, [3, 1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element3, 'data-original-title');
                  morphs[1] = dom.createMorphAt(element3, 0, 0);
                  morphs[2] = dom.createMorphAt(element2, 3, 3);
                  morphs[3] = dom.createElementMorph(element4);
                  morphs[4] = dom.createMorphAt(element4, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "data-original-title", ["get", "queue.name", ["loc", [null, [33, 64], [33, 74]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "queue.name", ["loc", [null, [33, 77], [33, 91]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "queue.typeName", ["loc", [null, [34, 24], [34, 38]]], 0, 0, 0, 0], "Ivr"], [], ["loc", [null, [34, 20], [34, 45]]], 0, 0]], [], 0, 1, ["loc", [null, [34, 14], [42, 21]]]], ["element", "action", ["forwardToQueue", ["get", "queue.id", ["loc", [null, [45, 71], [45, 79]]], 0, 0, 0, 0], ["get", "queue.name", ["loc", [null, [45, 80], [45, 90]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 45], [45, 92]]], 0, 0], ["inline", "t", ["transfer.forward_queue"], [], ["loc", [null, [45, 93], [45, 123]]], 0, 0]],
                locals: ["queue"],
                templates: [child0, child1]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 51,
                        "column": 12
                      },
                      "end": {
                        "line": 55,
                        "column": 12
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "loading-wrapper");
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("i");
                    dom.setAttribute(el2, "class", "spinner");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 55,
                        "column": 12
                      },
                      "end": {
                        "line": 57,
                        "column": 12
                      }
                    },
                    "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "class", "load-more-button");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element0);
                    morphs[1] = dom.createMorphAt(element0, 0, 0);
                    return morphs;
                  },
                  statements: [["element", "action", ["fetchMoreQueues"], [], ["loc", [null, [56, 47], [56, 76]]], 0, 0], ["inline", "t", ["transfer.load_more"], [], ["loc", [null, [56, 77], [56, 103]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 49,
                      "column": 8
                    },
                    "end": {
                      "line": 59,
                      "column": 8
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "load-more");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "if", [["get", "isMoreQueuesLoading", ["loc", [null, [51, 18], [51, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [51, 12], [57, 19]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 8
                  },
                  "end": {
                    "line": 60,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "search-results");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "if", [["get", "isSearched", ["loc", [null, [29, 39], [29, 49]]], 0, 0, 0, 0], ["subexpr", "t", ["transfer.search_results"], [], ["loc", [null, [29, 50], [29, 79]]], 0, 0], ["subexpr", "t", ["transfer.all_queues"], [], ["loc", [null, [29, 80], [29, 105]]], 0, 0]], [], ["loc", [null, [29, 34], [29, 107]]], 0, 0], ["block", "each", [["get", "callQueueDetails", ["loc", [null, [30, 16], [30, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [30, 8], [48, 17]]]], ["block", "if", [["subexpr", "lt", [["get", "currentPage", ["loc", [null, [49, 18], [49, 29]]], 0, 0, 0, 0], ["get", "totalPages", ["loc", [null, [49, 30], [49, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 14], [49, 41]]], 0, 0]], [], 1, null, ["loc", [null, [49, 8], [59, 15]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 8
                  },
                  "end": {
                    "line": 63,
                    "column": 8
                  }
                },
                "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "no-results");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["transfer.no_results"], [], ["loc", [null, [62, 32], [62, 59]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 4
                },
                "end": {
                  "line": 65,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "queue-transfer-list");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element5, 1, 1);
              morphs[1] = dom.createMorphAt(element5, 2, 2);
              return morphs;
            },
            statements: [["block", "unless", [["get", "noSearchResults", ["loc", [null, [28, 18], [28, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [28, 8], [60, 19]]]], ["block", "if", [["get", "noSearchResults", ["loc", [null, [61, 14], [61, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [61, 8], [63, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 4
                },
                "end": {
                  "line": 69,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "loading-wrapper");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "spinner");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 2
              },
              "end": {
                "line": 71,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "search-container");
            dom.setAttribute(el1, "id", "queue-search-container");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "search-title");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "pull-right icon-close");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "search-list");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "icon-search");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "caret");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var element7 = dom.childAt(element6, [1]);
            var element8 = dom.childAt(element7, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element7, 1, 1);
            morphs[1] = dom.createElementMorph(element8);
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [3]), 3, 3);
            morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["transfer.queue_transfer"], [], ["loc", [null, [18, 8], [18, 39]]], 0, 0], ["element", "action", ["hideList"], [], ["loc", [null, [19, 41], [19, 62]]], 0, 0], ["inline", "input", [], ["placeholder", ["subexpr", "@mut", [["get", "searchPlaceholder", ["loc", [null, [23, 28], [23, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "searchString", ["loc", [null, [23, 52], [23, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "search-string"], ["loc", [null, [23, 8], [23, 88]]], 0, 0], ["block", "if", [["get", "isCallQueuesLoaded", ["loc", [null, [26, 10], [26, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [26, 4], [69, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 72,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "rl-dropdown-toggle", [], ["tagName", "button", "class", ["subexpr", "concat", [["subexpr", "if", [["get", "disabled", ["loc", [null, [6, 22], [6, 30]]], 0, 0, 0, 0], "none-pointer transferdisabled "], [], ["loc", [null, [6, 18], [6, 64]]], 0, 0], "queue-transfer-button dropdown-toggle call-control-button"], [], ["loc", [null, [6, 10], [6, 125]]], 0, 0]], 0, null, ["loc", [null, [4, 2], [9, 25]]]], ["block", "rl-dropdown", [], ["tagName", "div", "class", "queue-transfer-list-container dropdown-menu", "closeOnChildClick", ["subexpr", "@mut", [["get", "hideCallQueueList", ["loc", [null, [14, 22], [14, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [11, 2], [71, 18]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 26
          }
        },
        "moduleName": "freshfone-ember/components/ongoing-widget/transfer/queue-transfer/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "rl-dropdown-container", [], ["class", ["subexpr", "concat", [["subexpr", "if", [["get", "disabled", ["loc", [null, [2, 20], [2, 28]]], 0, 0, 0, 0], "not-allowed transferdisabled "], [], ["loc", [null, [2, 16], [2, 61]]], 0, 0], "dropdown transfer-container"], [], ["loc", [null, [2, 8], [2, 92]]], 0, 0], "onOpen", ["subexpr", "action", ["onDropDownOpen"], [], ["loc", [null, [3, 9], [3, 34]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [72, 26]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});