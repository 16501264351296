define('freshfone-ember/routes/protected/admin/agent-statuses', ['exports', 'ember', 'freshfone-ember/routes/promotable', 'freshfone-ember/mirage/fixtures/agent-statuses'], function (exports, _ember, _freshfoneEmberRoutesPromotable, _freshfoneEmberMirageFixturesAgentStatuses) {
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesPromotable['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('admin_components.AgentStatuses');
    }),
    beforeModel: function beforeModel() {
      return this.checkPrivilege('manage_custom_agent_status');
    },
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('admin_components.AgentStatuses') };
    }),

    model: function model() {
      return this._super(_freshfoneEmberMirageFixturesAgentStatuses['default'].data, _freshfoneEmberMirageFixturesAgentStatuses['default'].data, 'custom_agent_statuses');
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */