define('freshfone-ember/models/power-dialer-list', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    createdAt: attr(),
    position: attr('number'),
    status: attr('number'),
    contact: belongsTo('contact', { async: false }),
    start: (0, _emberApiActions.collectionAction)({ type: 'POST', path: 'start' }),
    stop: (0, _emberApiActions.collectionAction)({ type: 'POST', path: 'stop' }),
    fetchNext: (0, _emberApiActions.collectionAction)({ type: 'GET', path: 'fetch_next' })
  });
});