define("freshfone-ember/templates/protected/power-dialer", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/templates/protected/power-dialer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "power-dialer", [], ["powerDialerContacts", ["subexpr", "@mut", [["get", "model.powerDialerContacts", ["loc", [null, [8, 24], [8, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "totalContactsCount", ["subexpr", "@mut", [["get", "model.totalContactsCount", ["loc", [null, [9, 23], [9, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "refreshModel", ["subexpr", "@mut", [["get", "model.refreshModel", ["loc", [null, [10, 17], [10, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "promoting", ["subexpr", "@mut", [["get", "shouldPromote", ["loc", [null, [11, 14], [11, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "powerDialerList", ["subexpr", "@mut", [["get", "model", ["loc", [null, [12, 22], [12, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 2], [13, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 29
          }
        },
        "moduleName": "freshfone-ember/templates/protected/power-dialer.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "common/promotion-wrapper", [], ["shouldPromote", ["subexpr", "@mut", [["get", "shouldPromote", ["loc", [null, [2, 16], [2, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "featureSymbol", ["subexpr", "@mut", [["get", "featureSymbol", ["loc", [null, [3, 16], [3, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "promotionMessagePath", "promotionWrapper.mainMessage", "trackEvent", "power-dialer:upgrade account"], 0, null, ["loc", [null, [1, 0], [14, 29]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});