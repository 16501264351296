define('freshfone-ember/adapters/team', ['exports', 'freshfone-ember/mixins/adapters/multi-word-underscore'], function (exports, _freshfoneEmberMixinsAdaptersMultiWordUnderscore) {
  exports['default'] = _freshfoneEmberMixinsAdaptersMultiWordUnderscore['default'].extend({
    createRecord: function createRecord(store, type, snapshot) {
      var data = {},
          serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);
      data.included = [{
        data: { attributes: this.getTeamSetting(snapshot) },
        id: snapshot._belongsToRelationships.teamSetting.id,
        type: 'team-settings'
      }];

      return this.ajax(this.buildURL(type.modelName, '', 'createRecord'), 'POST', { data: data });
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var id = snapshot.id;
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);
      data.included = [{
        data: { attributes: this.getTeamSetting(snapshot) },
        id: snapshot._belongsToRelationships.teamSetting.id,
        type: 'team-settings'
      }];

      return this.ajax(this.buildURL(type.modelName, id, snapshot, 'updateRecord'), 'PUT', { data: data });
    },

    getTeamSetting: function getTeamSetting(snapshot) {
      var teamSetting = snapshot._belongsToRelationships.teamSetting._attributes,
          concurrentIncomingCalls = teamSetting.configurations['concurrent-incoming-calls'],
          maxParkedCalls = teamSetting.configurations['max-parked-calls'];

      teamSetting.configurations['concurrent-incoming-calls'] = parseInt(concurrentIncomingCalls);
      teamSetting.configurations['max-parked-calls'] = parseInt(maxParkedCalls);

      return teamSetting;
    }
  });
});