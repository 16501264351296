define('freshfone-ember/components/common/custom-radio-button/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    attributeBindings: ['data-toggle', 'data-placement', 'data-original-title'],
    classNameBindings: ['isChecked:checked'],

    actions: {
      onClickCallBack: function onClickCallBack(checkedValue) {
        if (this.get('preventDefault')) {
          event.preventDefault();
        }

        this.sendAction('callBackaction', checkedValue);
      }
    }
  });
});