define('freshfone-ember/components/changelog-widget/component', ['exports', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/utils/inject-resource'], function (exports, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberUtilsInjectResource) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    exports['default'] = Component.extend({
        init: function init() {
            this._super();
            if (_ember['default'].testing) {
                return;
            }
            this.injectBeamer();
        },
        injectBeamer: function injectBeamer() {
            var beamerApp = _freshfoneEmberConfigEnvironment['default'].BeamerApp;

            this.getBeamerConfig().then(function (beamerConfig) {
                window.beamer_config = beamerConfig;
                (0, _freshfoneEmberUtilsInjectResource.injectScript)(beamerApp.scriptUrl, document.body, 'defer');
            });
        },
        getUserDataConfig: function getUserDataConfig() {
            return this.get('analyticsTracker').getPropsToTargetThisUser().then(function (userProps) {
                return {
                    account_billing_cycle: userProps.billingCycle,
                    account_status: userProps.accountStatusText,
                    account_id: userProps.accountId,
                    account_plan: userProps.accountPlan,
                    account_region: userProps.accountRegion,
                    account_language: userProps.accountLanguage,
                    account_is_trial: userProps.isTrial,
                    account_created_at: userProps.accountCreatedAt,
                    agent_role: userProps.userRole,
                    associated_freshworks_products: userProps.associatedFreshworksProducts.join(';'),
                    user_id: userProps.userId,
                    user_email: userProps.userEmail,
                    user_email_confirmed: userProps.userEmailConfirmed,
                    user_name: userProps.userName
                };
            });
        },
        getBeamerConfig: function getBeamerConfig() {
            var baseBeamerConfig = {
                product_id: _freshfoneEmberConfigEnvironment['default'].BeamerApp.account,
                selector: ".changelog-widget, .beamerTrigger",
                right: -5,
                top: -5
            };

            return this.getUserDataConfig().then(function (userDataConfig) {
                return Object.assign(baseBeamerConfig, userDataConfig);
            });
        }
    });
});