define('freshfone-ember/components/service-level/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var _Ember$run = _ember['default'].run;
  var scheduleOnce = _Ember$run.scheduleOnce;
  var next = _Ember$run.next;
  exports['default'] = Component.extend({
    searchActive: computed.gt('searchstring.length', 0),
    isEqualToGlobal: computed('slConfig.targetPercent', 'slConfig.ttaThreshold', 'slConfig.selectedQueue', function () {
      if (!this.get('slConfig')) return;
      this.set('isEditingGlobal', this.get('globalQueue') === this.get('slConfig'));
      this.set('isEditingQueueDirty', this.get('slConfig').isConfigDirty());
      return this.compareQueues();
    }),
    isEditingQueueDirty: computed({
      set: function set(key, value) {
        this.sendAction('onSetFormState', !!value);
        return value;
      },
      get: function get() {}
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.beforeUnloadHandler = this.beforeUnloadHandler.bind(this);
      scheduleOnce('afterRender', function () {
        $(window).bind('beforeunload', _this.beforeUnloadHandler);
      });
      var selectedId = this.get('selectedConfigId');
      if (selectedId) {
        var configuredQueue = selectedId == 0 ? this.get('globalQueue') : this.get('slmQueues').filterBy('entityId', selectedId).get('firstObject');
        if (configuredQueue) {
          this.set('slConfig', configuredQueue);
          this.setIsEditingGlobal();
          next(function () {
            return _this.set('slideToggle', true);
          });
        } else this.findQueue(selectedId);
      }
    },
    beforeUnloadHandler: function beforeUnloadHandler() {
      if (this.get('isEditingQueueDirty')) return true;
    },
    compareQueues: function compareQueues() {
      var globalQueue = this.get('globalQueue'),
          editingQueue = this.get('slConfig');
      return globalQueue.compareProperties(editingQueue);
    },
    handleUnsavedEdits: function handleUnsavedEdits(item) {
      var yes = window.confirm(_ember['default'].i18n.t('serviceLevel.confirmDialogMessage'));
      if (yes) {
        if (this.get('slConfig')) this.get('slConfig').rollbackAttributes();
        this.set('isEditingQueueDirty', false);
        this.send('edit', item);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('slConfig')) this.get('slConfig').rollbackAttributes();
      this.set('isEditingQueueDirty', false);
      $(window).unbind('beforeunload', this.beforeUnloadHandler);
    },
    findQueue: function findQueue(selectedId) {
      var _this2 = this;

      this.store.findRecord('serviceLevelConfiguration', selectedId).then(function (config) {
        _this2.set('slConfig', config);
        _this2.setIsEditingGlobal();
      })['catch'](function () {});
    },
    setIsEditingGlobal: function setIsEditingGlobal() {
      this.set('isEditingGlobal', this.get('globalQueue') === this.get('slConfig'));
    },
    actions: {
      edit: function edit(item) {
        if (this.get('isEditingQueueDirty')) return this.handleUnsavedEdits(item);
        this.set('slConfig', item);
        this.set('slideToggle', true);
      },
      resetToGlobal: function resetToGlobal(slm) {
        var globalQueue = this.get('globalQueue');
        this.set('slConfig', slm);
        slm.setProperties({
          targetPercent: globalQueue.get('targetPercent'),
          ttaThreshold: globalQueue.get('ttaThreshold')
        });
      },
      updateQueue: function updateQueue() {
        this.set('modifyModel', true);
      },
      removeSelectedConfigId: function removeSelectedConfigId() {
        this.set('selectedConfigId', null);
      }
    }
  });
});