define('freshfone-ember/components/test-your-connection/check-network-connection/component', ['exports', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var momentjs = window.moment;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    init: function init() {
      this._super.apply(this, arguments);
      if (localStorage.getItem('lastConnectionTested')) {
        this.set('lastTested', _ember['default'].i18n.t('test_connection.last_tested', { testedDate: this.get('dateFormatter') }));
      }
    },
    dateFormatter: computed('lastTested', function () {
      return '' + momentjs(new Date(localStorage.getItem('lastConnectionTested'))).format('ddd, DD MMM YYYY');
    }),
    notifications: service('toastr'),
    telephony: service(),
    session: service(),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    isTrial: computed.alias('session.account.isTrial'),
    useTestRTC: computed(function () {
      return !this.get('isTrial') && this.checkFeature('testrtc');
    }),
    newNetworkTestEnabled: computed(function () {
      return this.hasGlobalFeature('new_network_tool') && this.checkFeature('new_network_tool');
    }),
    testConnectionUrl: computed('useTestRTC', function () {
      if (this.get('newNetworkTestEnabled')) {
        return _freshfoneEmberConfigEnvironment['default'].NetworkTestUrl;
      }

      return this.get('useTestRTC') ? 'https://freshworks.testrtc.com/' : 'https://networktest.twilio.com/';
    }),
    actions: {
      checkConnection: function checkConnection() {
        this.set('lastTested', '');

        var toasterOptions = {
          closeButton: true,
          progressBar: false,
          timeOut: 0,
          extendedTimeOut: 0
        },
            url = new URL(this.get('testConnectionUrl'));

        localStorage.setItem('lastConnectionTested', new Date());
        this.set('lastTested', _ember['default'].i18n.t('test_connection.last_tested', { testedDate: this.get('dateFormatter') }));

        if (this.get('newNetworkTestEnabled')) {
          url.searchParams.set('region', this.get('session.current.region'));
        }

        window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        this.get('notifications').info(_ember['default'].i18n.t('test_connection.toaster_messsage'), '', toasterOptions);
      }
    }
  });
});