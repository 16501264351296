define('freshfone-ember/components/text-field/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    attributeBindings: ["maxlength"],
    classNames: ['ff-input'],
    classNameBindings: ['isActive:active', 'animate:animate', 'isFocused:focused', 'anyError:hasError', 'disabled:disabled', 'isFixedTopLabel:fixedTopLabel', 'subText:multiline'],
    animate: true,
    isActive: computed.notEmpty('value'),
    hasErrors: computed.notEmpty('errors'),
    hasParent: computed.bool('parent'),
    canStartValidate: computed.and('hasParent', 'parent.startValidate'),
    parentHasErrors: computed.and('canStartValidate', 'hasErrors'),
    errorMessages: computed.alias('errors'),
    firstError: computed('errors', function () {
      var error = this.get('errors');
      if (_ember['default'].typeOf(error) === 'string') {
        return error;
      } else {
        return this.get('errors')[0];
      }
    }),
    anyError: computed('canStartValidate', 'errors', function () {
      if (this.get('hasParent')) {
        return this.get('parentHasErrors');
      }
      return this.get('hasErrors');
    }),

    actions: {
      onFocus: function onFocus() {
        this.set('isFocused', true);
      },
      onBlur: function onBlur() {
        this.set('isFocused', false);
        this.sendAction('onBlur');
      },
      onKeyDown: function onKeyDown(e) {
        if (this.get('isNumberField')) {
          var key = e.charCode || e.keyCode || 0;
          return key === 13 || key === 8 || key === 9 || key === 46 || key >= 35 && key <= 40 || key >= 48 && key <= 57 || key >= 96 && key <= 105;
        }
        this.sendAction('keyDown', e);
      },
      onChange: function onChange() {
        this.sendAction('change');
      }
    }
  });
});