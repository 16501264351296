define('freshfone-ember/mixins/normalized-json-object', ['exports', 'ember'], function (exports, _ember) {
  var later = _ember['default'].run.later;
  exports['default'] = _ember['default'].Mixin.create({
    normalizeJSONData: function normalizeJSONData(responseData, type) {
      var normalizedJSONData = {},
          normalizedJSONObject = [];

      responseData.forEach(function (dataObject, index) {
        var attributes = {};
        Object.keys(dataObject).forEach(function (key) {
          return attributes[key.dasherize()] = dataObject[key];
        });
        normalizedJSONObject.push({
          id: String(dataObject.id),
          type: type,
          attributes: attributes
        });
      });

      normalizedJSONData.data = normalizedJSONObject;
      return normalizedJSONData;
    }
  });
});