define("freshfone-ember/components/mkp-apps/mkp-app/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/mkp-apps/mkp-app/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "mkp-app-name");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("img");
        dom.setAttribute(el1, "class", "mkp-app-icon");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "mkp-app-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createAttrMorph(element0, 'alt');
        morphs[2] = dom.createAttrMorph(element0, 'src');
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        return morphs;
      },
      statements: [["content", "app.displayName", ["loc", [null, [1, 27], [1, 46]]], 0, 0, 0, 0], ["attribute", "alt", ["get", "app.displayName", ["loc", [null, [2, 32], [2, 47]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["get", "app.iconURL", ["loc", [null, [2, 56], [2, 67]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "app.element", ["loc", [null, [4, 2], [4, 17]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});