define('freshfone-ember/components/ongoing-widget/maximized-widget/network-warning-notification/component', ['exports', 'ember', 'freshfone-ember/constants/snooze-type'], function (exports, _ember, _freshfoneEmberConstantsSnoozeType) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    snoozeOptions: computed(function () {
      return [{
        key: _freshfoneEmberConstantsSnoozeType['default'].FOR_THIS_CALL,
        label: 'ongoing_widget.for_call'
      }, {
        key: _freshfoneEmberConstantsSnoozeType['default'].FOR_ONE_HOUR,
        label: 'ongoing_widget.for_one_hour'
      }, {
        key: _freshfoneEmberConstantsSnoozeType['default'].FOR_THREE_HOURS,
        label: 'ongoing_widget.for_three_hours'
      }, {
        key: _freshfoneEmberConstantsSnoozeType['default'].FOR_TODAY,
        label: 'ongoing_widget.for_today'
      }];
    }),
    actions: {
      onSnoozeSelect: function onSnoozeSelect(snoozeType) {
        this.sendAction('onSnoozed', snoozeType);
      },
      openDropdown: function openDropdown() {
        this.sendAction('onDropdownOpen');
      },
      closeDropdown: function closeDropdown() {
        this.sendAction('onDropdownclose');
      }
    }
  });
});