define('freshfone-ember/components/profile-settings/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/errorfield-focus-tabs', 'ember-validations', 'freshfone-ember/helpers/list-timezone', 'freshfone-ember/constants/supported-languages', 'freshfone-ember/constants/sip-status', 'freshfone-ember/mixins/send-sip-credential-mixin', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsErrorfieldFocusTabs, _emberValidations, _freshfoneEmberHelpersListTimezone, _freshfoneEmberConstantsSupportedLanguages, _freshfoneEmberConstantsSipStatus, _freshfoneEmberMixinsSendSipCredentialMixin, _freshfoneEmberMixinsFeatureCheckMixin) {
  var EMAIL_REGEX = /^[-a-zA-Z0-9.'’&_%+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var _Ember$run = _ember['default'].run;
  var once = _Ember$run.once;
  var later = _Ember$run.later;
  var service = _ember['default'].inject.service;
  var isEmpty = _ember['default'].isEmpty;
  var isEqual = _ember['default'].isEqual;
  var isPresent = _ember['default'].isPresent;
  var testing = _ember['default'].testing;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsSendSipCredentialMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    telephony: service(),
    session: service(),
    currentAgent: computed.alias('currentUser.agent'),
    notification: service('toastr'),

    ringOnSpeakers: computed.alias('currentUser.ringOnSpeakers'),
    agentExtensionEnabled: computed.alias('currentUser.extensionEnabled'),
    isDisclaimerVisible: computed.equal('currentAgent.sipStatus', _freshfoneEmberConstantsSipStatus['default'].onBrowser),
    languageList: computed(function () {
      return Object.values(_freshfoneEmberConstantsSupportedLanguages.supportedLanguages);
    }),
    userLanguage: computed('currentUser.language', function () {
      var language = this.get('currentUser.language') || this.get('session.account.language');
      return _freshfoneEmberConstantsSupportedLanguages.supportedLanguages[language];
    }),
    userTimezone: computed('currentUser.timeZone', function () {
      var timezone = this.get('currentUser.timeZone') || this.get('session.account.timeZone');

      return (0, _freshfoneEmberHelpersListTimezone.listTimezone)().filterBy('id', timezone)[0];
    }),
    extension: computed.alias('currentUser.extension'),
    disabelAgentExtensionField: computed.not('agentExtensionEnabled'),
    agentExtensionFeatureEnabled: computed('session.account', function () {
      return this.get('session.account').hasGlobalFlag('agent_extension') && this.hasFeatures(["agent_extension"]);
    }),
    noTimezoneLabel: _ember['default'].i18n.t('profile_settings.no_timezone_available'),
    timezonesList: (0, _freshfoneEmberHelpersListTimezone.listTimezone)(),
    isDirty: computed('currentUser.hasDirtyAttributes', 'currentUser.{ringOnSpeakers,language}', 'userLanguage.name', 'extension', {
      get: function get() {
        return this.get('currentUser').isDeepDirty();
      }
    }).readOnly(),

    submitLabel: computed('currentUser.isSaving', function () {
      return this.get('currentUser.isSaving') ? _ember['default'].i18n.t('profile_settings.saving_state') : _ember['default'].i18n.t('profile_settings.save');
    }),

    validationErrors: computed.or('currentUser.errors.email', 'currentUser.errors.base'),
    apiToken: computed.alias('mkpAdapter.publicAPIToken'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get("currentUser").rollbackAttributes();
      if (isEmpty(this.get("currentUser").get("role.content"))) {
        this.get("currentUser").reload().then(function (user) {
          user.saveOriginalRelations();
          _this.set('currentUser', user);
        });
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.get("focusPhoneNumber")) {
        _ember['default'].run.later(this, function () {
          $('.user-form .phone input').focus();
        }, 100);
      }
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get("currentUser").rollbackAttributes();
    },

    actions: {
      save: function save() {
        var _this2 = this;

        this.validate().then(function () {
          _this2.set('user', _this2.get('currentUser'));
          _this2.get('user').save().then(function (user) {
            _this2.sendAction('onModify');
            if (isPresent(user.get("phone")) && _this2.get("focusPhoneNumber")) {
              _this2.sendAction('afterUserSave');
            }
            _this2.get('telephony.agent').updateMobileNumber(user.get("phone"), true);
            _this2.set("userSaved", true);
            later(_this2, function () {
              _this2.send('close');
            }, 1000);
          })['catch'](function (error) {
            _this2.set('startValidate', true);
            if (error.errors[0]) {
              _this2.set("errors.currentUser.phone", error.errors[0].detail);
            }
          });
        })['catch'](function (errors) {
          _this2.set("errors", errors);
          _this2.set('startValidate', true);
        });
      },
      saveSettings: function saveSettings() {
        var _this3 = this;

        // This function should be de-coupled
        if (!this.get('currentUser.hasDirtyAttributes') && !this.get('currentAgent.hasDirtyAttributes')) {
          this.sendAction("closeCallBack");
        }
        this.validate().then(function () {
          _this3.set('isSaving', true);
          var selectedTimezone = undefined;
          var language = _this3.get('userLanguage');
          var timezone = _this3.get('userTimezone');
          var selectedCode = Object.keys(_freshfoneEmberConstantsSupportedLanguages.supportedLanguages).find(function (key) {
            return _freshfoneEmberConstantsSupportedLanguages.supportedLanguages[key] === language;
          });
          var changedAgentAttrs = _this3.get('currentAgent').changedAttributes();

          var afterSave = function afterSave() {
            _this3.set('isSaving', false);
            _this3.sendAction('closeCallBack');
          };

          if (timezone) {
            (0, _freshfoneEmberHelpersListTimezone.listTimezone)().forEach(function (code) {
              if (code.id == timezone.id) selectedTimezone = code.id;
            });
            if (_this3.get('currentUser.timeZone') !== selectedTimezone) _this3.set('currentUser.timeZone', selectedTimezone);
          }

          if (_this3.get('currentUser.language') !== selectedCode) _this3.set('currentUser.language', selectedCode);
          var changedUserAttrs = _this3.get('currentUser').changedAttributes();

          if (!$.isEmptyObject(changedUserAttrs)) {
            _this3.get('currentUser').save().then(function () {
              var telephony = _this3.get('telephony');
              if (changedUserAttrs['ringOnSpeakers']) telephony[changedUserAttrs['ringOnSpeakers'][1] ? 'enableRingOnSpeakers' : 'disableRingOnSpeakers']();
              if (changedUserAttrs['language'] || changedUserAttrs['timeZone']) {
                return !_ember['default'].testing && window.location.reload();
              }
              _this3.set('startValidate', false);
              afterSave();
            })['catch'](function (error) {
              _this3.set('isSaving', false);
              if (_this3.get('currentUser.hasDirtyAttributes')) {
                if (_this3.get('agentExtensionEnabled')) {
                  _this3.set('startValidate', true);
                  _this3.set("extensionError", error.errors[0].detail);
                } else {
                  afterSave();
                }
              }
            });
          }

          if (!$.isEmptyObject(changedAgentAttrs)) {
            _this3.get('currentAgent').save();
            afterSave();
          }
        })['catch'](function (error) {
          _this3.set("extensionError", error.extension[0].toString());
          _this3.set('startValidate', true);
        });
      },
      close: function close() {
        if (this.get('currentAgent')) this.get('currentAgent').rollbackAttributes();
        this.sendAction("closeCallBack");
      },
      resendSIPCredentials: function resendSIPCredentials() {
        return this.resendSIPCredential(this.get('currentAgent'), true);
      },

      resetAPIToken: function resetAPIToken() {
        var _this4 = this;

        if (confirm(_ember['default'].i18n.t('profile_settings.apiToken.resetConfirmationMessage'))) {
          this.get('mkpAdapter.fetchOrGenerate')('PUT').then(function () {
            _this4.get('notification').success(_ember['default'].i18n.t('profile_settings.apiToken.successfulReset'));

            if (!testing) {
              setTimeout(window.location.reload.bind(window.location), 2000);
            }
          })['catch'](function (error) {
            return _this4.get('notification').error(error);
          });
        }
      }
    },
    validations: {
      'extension': {
        inline: (0, _emberValidations.validator)(function () {
          if (this.get('agentExtensionFeatureEnabled') && this.get('agentExtensionEnabled')) {
            if (_ember['default'].isBlank(this.get('extension'))) {
              return _ember['default'].i18n.t('profile_settings.agent_extension_error.blank_warning_message');
            }
            if (this.get('extension') && this.get('extension').toString().length < 4) {
              return _ember['default'].i18n.t('profile_settings.agent_extension_error.extension_too_short');
            }
            if (this.get('extension') < 0) {
              return _ember['default'].i18n.t('profile_settings.agent_extension_error.extension_too_short');
            }
          }
        })
      }
    }
  });
});