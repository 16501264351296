define('freshfone-ember/components/message-component/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/constants/message'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberConstantsMessage) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], {
    scope: _freshfoneEmberConstantsMessage.SCOPE_ADMIN,

    actions: {
      editMessage: function editMessage(message) {
        if (message.get('isUpdatable')) {
          this.send('edit', 'message', message);
          this.set('message', message);
          this.set('isOpen', true);
        }
      },
      toggleDelete: function toggleDelete(message) {
        $('.text-cancel').trigger('click');
        message.toggleProperty('isDeleting');
      },
      afterDelete: function afterDelete(message) {
        if (message.id === this.get('message.id')) {
          this.set('isOpen', false);
          this.set('message', null);
        }
      },
      onModify: function onModify(message) {
        this.set('message', message);
        this.set('modifyModel', true);
      }
    }
  });
});