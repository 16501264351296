define('freshfone-ember/components/custom-elements/date-range/component', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {

  var momentJs = window.moment;

  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var set = _ember['default'].set;
  var i18n = _ember['default'].i18n;
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], {
    classNames: ["date-range-picker"],
    startDate: '',
    endDate: '',
    today: new Date(),

    date_range_options: {
      "custom_elements.date_range.today": { startDate: momentJs(), endDate: '', isActive: false, label: "Today-g" },
      "custom_elements.date_range.yesterday": { startDate: momentJs().subtract(1, 'days'), endDate: '', isActive: false, label: "Yesterday-g" },
      "custom_elements.date_range.last_7_days": { startDate: momentJs().subtract(6, 'days'), endDate: momentJs(), isActive: false, label: "Last 7-g" },
      'custom_elements.date_range.last_30_days': { startDate: momentJs().subtract(29, 'days'), endDate: momentJs(), isActive: false, label: "Last 30-g" }
    },

    formatDateValue: function formatDateValue(date) {
      if (isPresent(date) && typeof date === "object") {
        return date.format("DD MMM YYYY");
      }
      return date;
    },

    formatedDate: computed('startDate', 'endDate', function () {
      var startDate = this.formatDateValue(this.get('startDate'));
      var endDate = this.formatDateValue(this.get('endDate'));
      if (isPresent(endDate)) {
        return [startDate, endDate].join(' - ');
      }
      return startDate;
    }),

    activeElement: function activeElement(key) {
      var selectedOption = this.get('date_range_options')[key];
      if (selectedOption.isActive) {
        this.set('startDate', '');
        this.set('endDate', '');
        set(selectedOption, "isActive", false);
        return;
      }
      this.resetActiveElement();
      set(selectedOption, "isActive", true);
      this.set('startDate', selectedOption.startDate);
      this.set('endDate', selectedOption.endDate);
    },

    resetActiveElement: function resetActiveElement() {
      var _this = this;

      Object.keys(this.get('date_range_options')).forEach(function (key) {
        set(_this.get('date_range_options')[key], "isActive", false);
      });
    },

    rangeValue: computed('startDate', 'endDate', {
      get: function get() {
        var startDate = this.formatDateValue(this.get('startDate'));
        var endDate = this.formatDateValue(this.get('endDate'));
        return [startDate, endDate];
      },

      set: function set(key, dateRange) {
        if (dateRange[0]) {
          this.set('startDate', dateRange[0]);
        } else {
          this.resetActiveElement();
        }
        this.set('endDate', dateRange[1]);
      }
    }),

    actions: {
      selectOption: function selectOption(key) {
        this.activeElement(key);
        _ember['default'].run.debounce(this, function () {
          this.set('isOpen', false);
        }, 150);
      },

      toggleOpen: function toggleOpen() {
        this.toggleProperty('isOpen');
      },

      openCalender: function openCalender() {
        this.resetActiveElement();
        this.$('.date-range .daterangepicker').removeClass('dropdown-menu');
        this.$('.daterangepicker-input').trigger('click');
      }
    }
  });
});