define('freshfone-ember/models/routing-rule', ['exports', 'ember-validations', 'ember-data', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/call-flow-child-validation-mixin'], function (exports, _emberValidations, _emberData, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsCallFlowChildValidationMixin) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var service = _ember['default'].inject.service;
  exports['default'] = Model.extend(_emberValidations['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsCallFlowChildValidationMixin['default'], {
    session: service(),
    routingAutomationId: attr('number'),
    response: attr(),
    actionType: attr('string'),
    actionId: attr('number'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // save backend request for each delete
      this.on('didDelete', function () {
        var routingAutomation = _this.store.peekRecord('routingAutomation', _this.get('routingAutomationId'));

        routingAutomation.decrementProperty('routingRulesCount');
      });
    },

    blankActionError: function blankActionError(property) {
      if (!this.get(property + 'Id')) {
        if (this.get(property + 'Type') === 'callQueue') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_callqueue');
        } else if (this.get(property + 'Type') === 'ivr') {
          return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_ivr');
        }
      }
    },
    validations: {
      response: { presence: { message: _ember['default'].i18n.t('routing_rules.validation_errors.response') } },
      actionId: {
        inline: (0, _emberValidations.validator)(function () {
          return this.model.blankActionError('action');
        })
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */
/* eslint-disable camelcase */
/* eslint-disable sort-vars */