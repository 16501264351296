define('freshfone-ember/constants/supported-languages', ['exports', 'freshfone-ember/config/environment', 'freshfone-ember/helpers/supported-env'], function (exports, _freshfoneEmberConfigEnvironment, _freshfoneEmberHelpersSupportedEnv) {
  var supportedLanguages = {
    'en': { name: 'English' },
    'de': { name: 'German' },
    'ca': { name: 'Catalan' },
    'cs': { name: 'Czech' },
    'da': { name: 'Danish' },
    'es-ES': { name: 'Spanish' },
    'es-MX': { name: 'Spanish, Mexico' },
    'et': { name: 'Estonian' },
    'fi': { name: 'Finnish' },
    'fr': { name: 'French' },
    'hu': { name: 'Hungarian' },
    'id': { name: 'Indonesian' },
    'it': { name: 'Italian' },
    'ja': { name: 'Japanese' },
    'ko': { name: 'Korean' },
    'lv': { name: 'Latvian' },
    'nl': { name: 'Dutch' },
    'no': { name: 'Norwegian' },
    'pl': { name: 'Polish' },
    'pt-BR': { name: 'Portuguese, Brasil' },
    'pt-PT': { name: 'Portuguese' },
    'ro': { name: 'Romanian' },
    'ru': { name: 'Russian' },
    'sk': { name: 'Slovak' },
    'sl': { name: 'Slovenian' },
    'sv-SE': { name: 'Swedish' },
    'th': { name: 'Thai' },
    'tr': { name: 'Turkish' },
    'uk': { name: 'Ukrainian' },
    'vi': { name: 'Vietnamese' },
    'zh-CN': { name: 'Chinese, Simplified' },
    'zh-TW': { name: 'Chinese, Traditional' }
  },
      supportedMessageLanguages = {
    'ca': {
      name: 'Catalan',
      key: 'ca'
    },
    'da': {
      name: 'Danish',
      key: 'da'
    },
    'de': {
      name: 'German',
      key: 'de'
    },
    'en-AU': {
      name: 'English (Australian)',
      key: 'en-AU'
    },
    'en-CA': {
      name: 'English (Canadian)',
      key: 'en-CA'
    },
    'en-GB': {
      name: 'English (British)',
      key: 'en-GB'
    },
    'en-IN': {
      name: 'English (Indian)',
      key: 'en-IN'
    },
    'en': {
      name: 'English (American)',
      key: 'en'
    },
    'es-ES': {
      name: 'Spanish (Castilian)',
      key: 'es-ES'
    },
    'es-MX': {
      name: 'Spanish (Latin American)',
      key: 'es-MX'
    },
    'fi': {
      name: 'Finnish',
      key: 'fi'
    },
    'fr-CA': {
      name: 'French (Canadian)',
      key: 'fr-CA'
    },
    'fr': {
      name: 'French (European)',
      key: 'fr'
    },
    'it': {
      name: 'Italian',
      key: 'it'
    },
    'ja': {
      name: 'Japanese',
      key: 'ja'
    },
    'ko': {
      name: 'Korean',
      key: 'ko'
    },
    'no': {
      name: 'Norwegian',
      key: 'no'
    },
    'nl': {
      name: 'Dutch',
      key: 'nl'
    },
    'pl': {
      name: 'Polish',
      key: 'pl'
    },
    'pt-BR': {
      name: 'Portuguese (Brazilian)',
      key: 'pt-BR'
    },
    'pt-PT': {
      name: 'Portuguese (European)',
      key: 'pt-PT'
    },
    'ru': {
      name: 'Russian',
      key: 'ru'
    },
    'sv-SE': {
      name: 'Swedish',
      key: 'sv-SE'
    },
    'zh-CN': {
      name: 'Chinese (Mandarin)',
      key: 'zh-CN'
    },
    'zh-TW': {
      name: 'Chinese (Taiwanese Mandarin)',
      key: 'zh-TW'
    },
    'tr': {
      name: 'Turkish',
      key: 'tr'
    },
    'ro': {
      name: 'Romanian',
      key: 'ro'
    }
  },
      unsupportedMaleVoiceLanguages = ['ca', 'en-IN', 'en-AU', 'en-CA', 'en-GB', 'fi', 'ko', 'no', 'ro', 'sv-SE', 'tr', 'zh-CN', 'zh-TW'];

  if (_freshfoneEmberHelpersSupportedEnv['default'].stacks.contains(_freshfoneEmberConfigEnvironment['default'].environment)) {
    supportedLanguages.test = { name: 'Test UI' };
  }

  exports.supportedLanguages = supportedLanguages;
  exports.supportedMessageLanguages = supportedMessageLanguages;
  exports.unsupportedMaleVoiceLanguages = unsupportedMaleVoiceLanguages;
});