define('freshfone-ember/components/notification-widget/component', ['exports', 'freshfone-ember/constants/call-action-category', 'freshfone-ember/constants/call-category', 'freshfone-ember/constants/call-type', 'ember', 'freshfone-ember/constants/event-type', 'freshfone-ember/constants/park-type'], function (exports, _freshfoneEmberConstantsCallActionCategory, _freshfoneEmberConstantsCallCategory, _freshfoneEmberConstantsCallType, _ember, _freshfoneEmberConstantsEventType, _freshfoneEmberConstantsParkType) {
  var _slice = Array.prototype.slice;

  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var isPresent = _ember['default'].isPresent;
  var later = _ember['default'].run.later;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var not = _Ember$computed.not;
  var or = _Ember$computed.or;
  exports['default'] = Component.extend({
    classNames: ['notification-widget'],
    i18n: service(),
    classNameBindings: ['incomingCallActive:notification-active'],
    currentConnection: service('current-connection'),
    browserService: service('browser-service'),
    calls: _ember['default'].A(),
    incomingCallActive: computed.gt('calls.length', 0),
    currentUserID: computed.alias('session.user.id'),
    telephony: service('telephony'),
    transition: service('state-transition'),
    parkedCallsService: service('parked-calls'),
    incomingConnection: computed.alias('telephony.incomingConnection'),
    currentCall: computed.alias('telephony.currentCall'),
    callCompleted: computed.equal('currentCall.status', 'completed'),
    isIncomingIgnoreOptionDisabled: computed.alias('session.account.isIncomingIgnoreOptionDisabled'),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    currentCallIsParked: computed.alias('telephony.currentCallIsParked'),
    parking: computed.alias('parkedCallsService.parking'),
    micPermPromptState: computed.alias('browserService.isMicrophonePermissionPrompted'),
    showMicPermissionBanner: computed.or('browserService.isMicrophonePermissionDenied', 'browserService.isMicrophonePermissionPrompted'),
    autoAnswerableCallsLength: computed('calls.[]', function () {
      return this.get('calls').filter(function (call) {
        return call.autoAnswerEnabled;
      }).length;
    }),
    isListNotification: computed('calls.[]', 'isShowingAllCalls', 'autoAnswerableCallsLength', {
      get: function get() {
        // Reset
        !this.get('calls.length') && this.set('isShowingAllCalls', false);

        if (this.get('calls.length') > 1 && this.get('autoAnswerableCallsLength')) {
          this.set('isShowingAllCalls', true);
        }

        return this.get('calls.length') > 1 && this.get('isShowingAllCalls');
      }
    }),
    showIgnoreAllCall: computed('isIncomingIgnoreOptionDisabled', 'calls.[]', 'autoAnswerableCallsLength', function () {

      return !this.get('isIncomingIgnoreOptionDisabled') && this.get('autoAnswerableCallsLength') !== this.get('calls').length;
    }),
    showLimitBanner: true,
    enableIncomingCall: alias('telephony.enableIncomingCall'),
    enableIncomingCallLimitBanner: not('enableIncomingCall'),
    enableCallWillBeParkedBanner: computed('callCompleted', 'hasActiveCall', 'currentCallIsParked', 'enableRecurringBanner', 'firstCall', function () {
      var currentCallActive = this.get('hasActiveCall') && !this.get('callCompleted') && !this.get('currentCallIsParked'),
          showBanner = !localStorage.getItem('closedCallParkingBanner');

      if (this.get('enableRecurringBanner')) {
        return false;
      }

      return currentCallActive && showBanner && !this.get('firstCall.isSIPCall');
    }),
    enableRecurringBanner: or('enableIncomingCallLimitBanner', 'isTransferIncomingCall', 'isMonitorOrBargeCall', 'actionInProgress', 'isAgentInPowerDialerMode'),
    showRecurringBanner: computed('showLimitBanner', 'enableRecurringBanner', function () {
      return this.get('showLimitBanner') && this.get('enableRecurringBanner');
    }),
    showBanner: computed('enableCallWillBeParkedBanner', 'showRecurringBanner', function () {
      return this.get('showRecurringBanner') || this.get('enableCallWillBeParkedBanner');
    }),
    hasParkingEnabled: alias('telephony.hasParkingEnabled'),
    currentUser: alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    isAgentInPowerDialerMode: computed.alias('currentAgent.powerDialer'),
    recordingOutgoingChanged: on('init', observer('currentConnection.isRecordingOrOutgoing', function () {
      if (this.get('currentConnection.isRecordingOrOutgoing') && isPresent(this.get('calls'))) {
        this.send('ignoreAll');
      }
    })),
    firstCall: computed('calls.[]', {
      get: function get() {
        return this.get('calls.firstObject');
      }
    }),
    callsMinusOne: computed('calls.[]', {
      get: function get() {
        return this.get('calls.length') - 1;
      }
    }),
    parkCallLimit: alias('telephony.parkCallLimit'),
    bannerMessage: computed('enableCallWillBeParkedBanner', 'enableIncomingCallLimitBanner', 'parkCallLimit', 'isTransferIncomingCall', 'isMonitorOrBargeCall', 'actionInProgress', 'showRecurringBanner', 'isAgentInPowerDialerMode', function () {
      if (this.get('enableCallWillBeParkedBanner')) {
        return _ember['default'].i18n.t('notification_widget.park_current_call');
      }

      if (this.get('isTransferIncomingCall')) {
        return _ember['default'].i18n.t('notification_widget.transfer_call_limit');
      }

      if (this.get('isMonitorOrBargeCall')) {
        return _ember['default'].i18n.t('notification_widget.monitor_call_limit');
      }

      if (this.get('enableIncomingCallLimitBanner')) {
        return _ember['default'].i18n.t('notification_widget.park_call_limit', { count: this.get('parkCallLimit') });
      }

      if (this.get('isAgentInPowerDialerMode')) {
        return this.get('i18n').t('notification_widget.power_dialer_active');
      }

      if (this.get('actionInProgress')) {
        return _ember['default'].i18n.t('notification_widget.ongoing_action');
      }
    }),
    isTransferIncomingCall: alias('telephony.isTransferIncomingCall'),
    isMonitorOrBargeCall: alias('telephony.isMonitorOrBargeCall'),
    recordingInProgress: alias('telephony.recordingInProgress'),
    actionInProgress: or('telephony.disableAction', 'parkedCallsService.parking', 'recordingInProgress'),
    init: function init() {
      this._super.apply(this, arguments);
      this.bindNodeEvents();
    },
    bindNodeEvents: function bindNodeEvents() {
      var _this = this;

      if (!this.get('session.account.isUnifiedAppAccount')) {
        this.get('telephony.event').subscribe('incoming', function (data) {
          console.log('Incoming Notifiation received on Notification widget', data);
          var updates = document.getElementById('HW_frame_cont');
          if (updates) updates.classList.remove('HW_visible');
          _this.incoming(data);
        });
      }

      this.get('telephony.event').subscribe('call_parked', function (_ref) {
        var park_type = _ref.park_type;

        if (park_type !== _freshfoneEmberConstantsParkType['default'].IncomingCall) {
          return;
        }

        if (_this.get('telephony').callNotificationExist(_this.get('incomingConnection.id'))) {
          _this.send('accept', _this.get('incomingConnection'));
          _this.get('telephony').clearIncomingConnection();

          return;
        }

        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.get('telephony').stopTransition();
        _this.get('telephony').clearIncomingConnection();
        _this.set('telephony.acceptedCallId', undefined);
      });

      this.get('telephony').on('queue_callback', function () {
        if (!(_this.isDestroyed || _this.isDestroying)) {
          var updates = document.getElementById('HW_frame_cont');
          if (updates) updates.classList.remove('HW_visible');
          _this.incoming(data);
        }
      });

      this.get('telephony.event').subscribe('ignored', function (data) {
        _this.ignored(data);
      });

      this.get('telephony.event').subscribe('accepted', function (data) {
        _this.accept(data);
      });

      this.get('telephony.event').subscribe('cancel', function (data) {
        _this.ignored(data);
      });
    },

    incoming: function incoming(incomingCallsList) {
      var askNicelyClose = document.getElementById('asknicely_close');
      var pickedCallIndex = incomingCallsList.findIndex(function (call) {
        return call.actionCategory === _freshfoneEmberConstantsCallActionCategory.CallActionCategory.PickedForSelf;
      });

      var _ref2 = pickedCallIndex === -1 ? [] : incomingCallsList.splice(pickedCallIndex, 1);

      var _ref22 = _slicedToArray(_ref2, 1);

      var pickedCall = _ref22[0];

      this.set('showLimitBanner', true);

      if (askNicelyClose) {
        askNicelyClose.click();
      }
      console.log('Incoming calls set', incomingCallsList);

      this.set('calls', incomingCallsList);

      if (incomingCallsList.length > 0 && this.get('micPermPromptState')) {
        this.get('browserService').getMicrophonePermission();
      }

      if (pickedCall) {
        this.send('accept', pickedCall);
      }
    },

    ignored: function ignored(incomingCallsList) {
      console.log('Incoming calls set from ignored/cancelled event', incomingCallsList);
      this.set('calls', incomingCallsList);
    },

    accept: function accept(incomingCallsList) {
      console.log('Incoming calls set from accepted event', incomingCallsList);
      this.set('calls', incomingCallsList);
    },

    buildCallParams: function buildCallParams(connection) {
      var contactId = connection.contact ? connection.contact.id : undefined;
      var formattedNumber = this.get('telephony.util').formatNumber(connection.caller.from);
      var params = {
        recipientName: connection.contact.name,
        caller: connection.to,
        phone_number: formattedNumber,
        type: 'outgoing',
        callId: connection.id
      };
      if (contactId) {
        params.contactId = contactId;
      }
      return params;
    },

    makeCallViaDialer: function makeCallViaDialer(connection) {
      this.send('ignore', connection, true); //need not make connetion for queue-callback call flow via dialer
      var formattedNumber = this.get('telephony.util').formatNumber(connection.caller.from);
      var dialerButton = $('.btn-outgoing');
      var outgoingDialerWidget = $('.card-outgoing');

      if (dialerButton.length) {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          if (!outgoingDialerWidget.length) {
            dialerButton.trigger('click');
          }
          resolve();
        }).then(function () {
          _ember['default'].run.later(function () {
            var inputOutgoing = $('.input-outgoing #searchTerm');
            var searchTerm = $('.ff-outgoing .row:first .ph-no');
            inputOutgoing.val(formattedNumber);
            searchTerm.text(formattedNumber);
            inputOutgoing.attr('data-callId', connection.id);
            inputOutgoing.attr('data-recordType', connection.record_type);
            inputOutgoing.focus();
            var e = $.Event('keyup', { keyCode: 13 });
            inputOutgoing.trigger(e);
          }, 500);
        });
      }
    },

    checkIfElIsPresent: function checkIfElIsPresent(connection) {
      var _this2 = this;

      var dialerButton = $('.btn-outgoing');

      if (dialerButton.length) {
        this.makeCallViaDialer(connection);
      } else {
        later(function () {
          return _this2.checkIfElIsPresent(connection);
        }, 5);
      }
    },

    isApiVirtualCall: function isApiVirtualCall(category, virtual_call) {
      return category === _freshfoneEmberConstantsCallCategory['default'].ApiCall && virtual_call;
    },

    actions: {
      ignore: function ignore(connection, acceptingCallback) {
        var callsList = this.get('telephony').ignore(connection.id, acceptingCallback, 'ignore');
        this.set('calls', callsList);
        console.log.apply(console, ['Ignore Button Clicked'].concat(_slice.call(arguments)));
      },
      accept: function accept(connection) {
        var _this3 = this;

        var isAutoAnswered = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        console.log.apply(console, ['Answer Button Clicked'].concat(_slice.call(arguments)));
        var contactId = connection.contact ? connection.contact.id : undefined;
        this.get('closeCallRatingModule')();
        if (connection.callback_call && connection.type === _freshfoneEmberConstantsCallType['default'].Incoming) {
          console.log('Accepted callback call', connection);
          if (this.get('session.account.isSharedBundleAccount')) {

            document.dispatchEvent(new CustomEvent(_freshfoneEmberConstantsEventType['default'].SharedCallbackEvent));
            later(function () {
              return _this3.checkIfElIsPresent(connection);
            }, 5);

            return;
          }

          this.makeCallViaDialer(connection);
          return;
        }

        if (this.isApiVirtualCall(connection.category, connection.virtual_call)) {
          this.makeCallViaDialer(connection);

          return;
        }

        if (!this.get('hasParkingEnabled')) {
          this.get('telephony').setCurrentCallContext(contactId, connection.caller.from, connection.type);
        }
        this.get('telephony').accept(connection.id, isAutoAnswered);

        if (connection.type === 'agent_conference' || connection.type === 'transfer') {
          this.get('setCallRatingParams')(connection.call_id);
        }
        this.get('calls').clear();

        console.log('Accept', 'Current incoming calls list cleared as user accepted call', connection.call_id);
      },
      ignoreAll: function ignoreAll(reason) {
        console.log('IgnoreAll Button Clicked');
        this.get('telephony').ignoreAllCalls(reason);
        console.log('Current incoming calls list cleared as user ignored');
        this.get('calls').clear();
      },
      showMore: function showMore() {
        this.toggleProperty('isShowingAllCalls');
      },
      closeBanner: function closeBanner() {
        if (this.get('showRecurringBanner')) {
          this.set('showLimitBanner', false);
        } else {
          localStorage.setItem('closedCallParkingBanner', true);
          this.notifyPropertyChange('enableCallWillBeParkedBanner');
        }
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */
/* eslint-disable camelcase */