define("freshfone-ember/helpers/message-default-types", ["exports"], function (exports) {
  exports["default"] = {
    userMessage: 0,
    welcomeMessage: 1,
    voicemailMessage: 2,
    hangupMessage: 3,
    callbackMessage: 4,
    invalidInputMessage: 5,
    recordingOptInMessage: 6,
    callbackConfirmationMessage: 7,
    agentExtensionWelcome: 8,
    agentNotAvailable: 9,
    agentNotAnswering: 10,
    agentBusy: 11,
    invalidExtension: 12
  };
});