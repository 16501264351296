define('freshfone-ember/components/search-bar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['search-input'],
    classNameBindings: ['editMode:active', 'isExpand:animate', 'contactSearch:search-lg'],
    editMode: false,
    query: "",
    showingSearchResult: false,
    placeholderText: "Search",
    becomeFocused: (function () {
      this.$('.search-input, .searchText').focus();
    }).on('didInsertElement'),
    queryWatcher: _ember['default'].observer('query', function () {
      if (this.get('query') !== '' && this.get('query') != null) {
        this.set('editMode', true);
      } else {
        this.set('editMode', false);
        this.set("showingSearchResult", false);
      }
    }),

    search: function search(q) {
      var _this = this;

      if (_ember['default'].isEmpty(q)) {
        this.set('results', null);
        this.set('editMode', false);
        return;
      }
      this.set('editMode', true);
      if (!this.get("contactSearch")) {
        return;
      }
      _ember['default'].$.getJSON("/contacts/search", { filter: { queryString: q } }).then(function (data) {
        _this.set('results', data);
        console.log('search this', _this);
        console.log(_this.get('results'));
        _this.set('showingSearchResult', true);
      });
    },
    actions: {

      exitEditMode: function exitEditMode() {
        this.set('editMode', false);
        this.set("showingSearchResult", false);
      },
      clearQuery: function clearQuery() {
        this.set("query", "");
      },
      searchNotes: function searchNotes() {
        this.sendAction('searchNotes');
      },
      startSearch: function startSearch() {
        console.log('query changed');
        this.search(this.get('query'));
      }
    }
  });
});