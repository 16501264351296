define('freshfone-ember/constants/promotion-image-urls', ['exports'], function (exports) {
  /* eslint-disable camelcase */
  /*
   * If we dynamically substitute the image name in the URL string, Ember won't fingerprint.
   * Hence this workaround.
   */

  var promotionImageUrls = Object.freeze({
    answering_on_sip: 'assets/images/promotion/sip-phone.svg',
    custom_agent_status: 'assets/images/promotion/custom-agent-status.svg',
    service_level_monitoring: ['assets/images/promotion/service-level-monitoring-1.svg', 'assets/images/promotion/service-level-monitoring-2.svg'],
    voicemail_drop: 'assets/images/promotion/voicemail-drop.svg',
    call_tagging: 'assets/images/promotion/call-tagging.svg',
    power_dialer: 'assets/images/promotion/power-dialer.svg'
  });
  exports.promotionImageUrls = promotionImageUrls;
});