define("freshfone-ember/helpers/long-formatted-date", ["exports", "ember"], function (exports, _ember) {
  var momentjs = window.moment;

  exports["default"] = _ember["default"].Helper.extend({
    compute: function compute(params) {
      var date = params[0];
      var inputFormat = params[1];
      var outputFormat = params[2];
      if (date && outputFormat) {
        return "" + momentjs(date, inputFormat).format(outputFormat);
      } else if (date) {
        return "" + momentjs(date, inputFormat).format("ddd, DD MMM YYYY \\at h:mm a");
      }
      return;
    }
  });
});