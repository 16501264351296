define('freshfone-ember/components/freshchat-widget-new/component', ['exports', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/constants/freshchat-widget-constants', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberConstantsFreshchatWidgetConstants, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var alias = _ember['default'].computed.alias;
  var testing = _ember['default'].testing;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),
    session: service(),
    currentAccount: alias('session.account'),
    currentUser: alias('session.user'),

    loadFreshchatNewWidget: function loadFreshchatNewWidget() {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');

        script.onload = resolve;
        script.onerror = reject;
        script.src = testing ? '' : _freshfoneEmberConfigEnvironment['default'].FRESHCHATV2.NewFreshchatWidgetScriptUrl;
        script.setAttribute('chat', true);
        script.setAttribute('widgetId', _freshfoneEmberConfigEnvironment['default'].FRESHCHATV2.NewFreshchatWidgetId);
        document.getElementsByTagName('head')[0].appendChild(script);

        if (testing) {
          resolve();
        }
      });
    },

    loadFreshChatWidgetScript: function loadFreshChatWidgetScript() {
      var _this = this;

      var that = this;

      $.getJSON(_freshfoneEmberConstantsFreshchatWidgetConstants['default'].freshchatUserPropertiesUrl).then(function (response) {
        _this.loadFreshchatNewWidget().then(function () {
          window.fcWidgetMessengerConfig = {
            firstName: _this.get('currentUser.name'),
            email: _this.get('currentUser.email'),
            tags: _this.get('currentAccount.isTrial') ? _freshfoneEmberConstantsFreshchatWidgetConstants['default'].freshchatWidgetTagsTrialAccount : _freshfoneEmberConstantsFreshchatWidgetConstants['default'].freshchatWidgetTagsPaidAccount,
            externalId: _this.get('currentUser.email') + ':' + _this.get('session.account.full_domain')
          };
          $(window.fwcrm).on('widget:loaded', function () {
            if (window.fcWidget) {
              _freshfoneEmberConfigEnvironment['default'].FRESHCHAT.config.config.content = {
                actions: {
                  csat_no: that.get('i18n').t('freshchat.csat_no'),
                  cast_yes: that.get('i18n').t('freshchat.csat_yes')
                },
                headers: { csat_question: that.get('i18n').t('freshchat.csat_question') }
              };
              window.fcWidget.init(_freshfoneEmberConfigEnvironment['default'].FRESHCHAT.config);
              window.fcWidget.conversation.setConversationProperties(response);
            }
          });
          if (testing) {
            $(window.fwcrm).trigger('widget:loaded');
          }
        });
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.loadFreshChatWidgetScript();
    }
  });
});
/* eslint-disable camelcase */