define("freshfone-ember/components/common/custom-radio-button/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/common/custom-radio-button/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "description");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "descriptionFor", ["loc", [null, [6, 29], [6, 47]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 8
          }
        },
        "moduleName": "freshfone-ember/components/common/custom-radio-button/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "type", "radio");
        dom.setAttribute(el2, "class", "custom-radiobox cursor-pointer");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "title");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "checkmark");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element1, [], true);
        }
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'onClick');
        morphs[2] = dom.createAttrMorph(element1, 'value');
        morphs[3] = dom.createAttrMorph(element1, 'checked');
        morphs[4] = dom.createAttrMorph(element1, 'name');
        morphs[5] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
        morphs[6] = dom.createMorphAt(element0, 7, 7);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["radio-button-wrapper disable-user-select ", ["get", "classForButton", ["loc", [null, [1, 57], [1, 71]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["onClickCallBack", ["get", "value", ["loc", [null, [1, 110], [1, 115]]], 0, 0, 0, 0], ["get", "this", ["loc", [null, [1, 116], [1, 120]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [1, 122]]], 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "value", ["loc", [null, [2, 69], [2, 74]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["get", "isChecked", ["loc", [null, [2, 87], [2, 96]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["get", "name", ["loc", [null, [2, 106], [2, 110]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "labelFor", ["loc", [null, [3, 22], [3, 34]]], 0, 0, 0, 0], ["block", "if", [["get", "descriptionFor", ["loc", [null, [5, 8], [5, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 2], [7, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});