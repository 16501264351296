define('freshfone-ember/routes/protected/reports', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({

    beforeModel: function beforeModel() {
      this.checkFeature('reports');
      this.checkPrivilege('view_reports');
    },

    model: function model() {
      return _ember['default'].$.getJSON("/reports/token").then(function (data) {
        return data;
      });
    }

  });
});