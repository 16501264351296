define('freshfone-ember/utils/inject-resource', ['exports'], function (exports) {
  exports.injectScript = injectScript;
  exports.injectCss = injectCss;

  function injectScript(url) {
    var context = arguments.length <= 1 || arguments[1] === undefined ? document.body : arguments[1];
    var defer = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

    return new Promise(function (resolve) {
      var script = document.createElement('script');

      script.addEventListener('load', scriptLoadedHandler);
      script.setAttribute('src', url);
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('defer', defer);

      context.appendChild(script);

      function scriptLoadedHandler() {
        script.removeEventListener('load', scriptLoadedHandler);
        resolve(script);
      }
    });
  }

  function injectCss(url) {
    var context = arguments.length <= 1 || arguments[1] === undefined ? document.head : arguments[1];

    var link = document.createElement('link');

    link.setAttribute('href', url);
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');

    context.appendChild(link);
  }
});