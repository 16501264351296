define('freshfone-ember/mixins/click-outside-listener', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    onOutsideClick: _ember['default'].K,
    outSideClickOnInit: true,
    ignoredContainers: ['.table-condensed', '.new-message-wraper.opened', '.flow-actions', '.transfer-container'],

    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.$();
      var $target = this.$(event.target);
      if ($target && !$target.closest($element).length && this.canTriggerClick($target)) {
        this.onOutsideClick();
      }
    },

    canTriggerClick: function canTriggerClick($target) {
      var show = true;
      var containers = this.get("ignoredContainers");
      containers.forEach(function (container) {
        if ($target.parents(container).length) {
          show = false;
        }
      });
      return show;
    },

    bindOutsideClick: function bindOutsideClick() {
      var _this = this;

      this.unbindOutsideClick();
      _ember['default'].run.debounce(this, function () {
        var clickHandler = _this.get('handleOutsideClick').bind(_this);
        return _ember['default'].$(document).on('click', clickHandler);
      }, 150);
    },

    unbindOutsideClick: function unbindOutsideClick() {
      var clickHandler = this.get('handleOutsideClick').bind(this);
      return _ember['default'].$(document).off('click', _ember['default'].run.cancel(this, clickHandler));
    },

    setupOutsideClickListener: _ember['default'].on('didInsertElement', function () {
      if (this.get('outSideClickOnInit')) {
        this.bindOutsideClick();
      }
    }),

    removeOutsideClickListener: _ember['default'].on('willDestroyElement', function () {
      this.unbindOutsideClick();
    })
  });
});