define('freshfone-ember/components/ongoing-widget/call-audio-progress/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;

  var AUDIO_VOLUME_TRESHOLD = 0.25;exports['default'] = Component.extend({
    telephony: service(),
    currentCall: computed.alias('telephony.currentCall'),
    connection: computed.alias('currentCall.connection'),
    transferType: computed.alias('currentCall.type'),
    eventsToHideTalkerName: ['agent_conference_accepted', 'warm_transfer_accepted', 'disconnect'],
    eventsToShowTalkerName: ['agent_conference_ended', 'warm_transfer_unavailable', 'customer_connected'],
    startTrackingTalkingUser: function startTrackingTalkingUser() {
      var _this = this;

      this.get('connection').on('volume', function (inputVolume, outputVolume) {
        var talkingUser = undefined;

        if (inputVolume > AUDIO_VOLUME_TRESHOLD) {
          talkingUser = _ember['default'].i18n.t('ongoing_widget.talker_is_agent');
        }
        if (inputVolume < outputVolume && outputVolume > AUDIO_VOLUME_TRESHOLD) {
          talkingUser = _ember['default'].i18n.t('ongoing_widget.talker_is_customer', { displayName: _this.get('displayName') });
        }

        return _this.set('talkingUser', talkingUser);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('doShowTalkerName', this.get('doShowTalkerName').bind(this));
      this.set('doHideTalkerName', this.get('doHideTalkerName').bind(this));
      this.set('doHideTalkerNameAndUnsubscribe', this.get('doHideTalkerNameAndUnsubscribe').bind(this));
    },
    doShowTalkerName: function doShowTalkerName() {
      this.set('showTalkerName', true);
    },
    doHideTalkerName: function doHideTalkerName() {
      this.set('showTalkerName', false);
    },
    doHideTalkerNameAndUnsubscribe: function doHideTalkerNameAndUnsubscribe() {
      this.get('doShowTalkerName').call();
      this.get('telephony.event').unsubscribe('outgoing_inprogress', this.get('doHideTalkerNameAndUnsubscribe'));
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      if (!this.get('connection')) {
        return;
      }
      if (['incoming', 'transfer'].includes(this.get('transferType'))) {
        this.get('doShowTalkerName').call();
      }
      if (['outgoing'].includes(this.get('transferType'))) {
        this.get('telephony.event').subscribe('outgoing_inprogress', this.get('doHideTalkerNameAndUnsubscribe'));
      }
      this.startTrackingTalkingUser();

      this.get('eventsToHideTalkerName').forEach(function (event) {
        return _this2.get('telephony.event').subscribe(event, _this2.get('doHideTalkerName'));
      });

      this.get('eventsToShowTalkerName').forEach(function (event) {
        return _this2.get('telephony.event').subscribe(event, _this2.get('doShowTalkerName'));
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this3 = this;

      this._super.apply(this, arguments);
      this.get('eventsToHideTalkerName').forEach(function (event) {
        return _this3.get('telephony.event').unsubscribe(event, _this3.get('doHideTalkerName'));
      });
      this.get('eventsToShowTalkerName').forEach(function (event) {
        return _this3.get('telephony.event').unsubscribe(event, _this3.get('doShowTalkerName'));
      });
    }
  });
});