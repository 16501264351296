define('freshfone-ember/routes/protected', ['exports', 'ember', 'ember-features/mixins/feature-route-mixin', 'ember-authority/helpers/has-privilege'], function (exports, _ember, _emberFeaturesMixinsFeatureRouteMixin, _emberAuthorityHelpersHasPrivilege) {
  var ACCOUNT_STATUS = {
    EXPIRED: 4,
    PAYMENT_FAILED: 5,
    CANCELLED: 6
  };

  var ROLE = { ACCOUNT_ADMIN: 'Account Admin' };

  var NProgress = window.NProgress;

  var run = _ember['default'].run;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberFeaturesMixinsFeatureRouteMixin['default'], {
    breadCrumb: null,

    redirect: function redirect(model, transition) {
      var currentRoute = transition.targetName;

      if (currentRoute === 'protected.index') {
        // Redirecting to dashboard on / route.
        if (this.get('session.account.isUnifiedAppAccount')) {
          this.transitionTo('protected.admin.numbers');
        } else {
          this.transitionTo('protected.dashboard');
        }
      } else if (currentRoute !== 'protected.account-suspended') {
        this.checkAccountSuspended();
      }
    },

    checkIfRestrictedFetaure: function checkIfRestrictedFetaure(featureName) {
      if (this.get('session.account').hasFeature(featureName)) {
        this.transitionTo('protected.error-page');
      }
    },

    hasGlobalFeature: function hasGlobalFeature(feature) {
      return this.get('session.account.globalFlags').indexOf(feature) > -1;
    },

    checkPrivilege: function checkPrivilege(privilegeType) {
      var currentUser = this.store.peekRecord('session', 'current').get('user'),
          userHasPrivilege = (0, _emberAuthorityHelpersHasPrivilege.hasPrivilege)({}, {
        privilege: privilegeType,
        user: currentUser
      });

      if (!userHasPrivilege) {
        this.transitionTo('protected.error-page');
      }
    },

    checkFeature: function checkFeature(featureName) {
      if (!this.get('session.account').hasFeature(featureName)) {
        this.transitionTo('protected.error-page');
      }
    },

    checkAccountSuspended: function checkAccountSuspended() {
      if (this.isNotActiveAccount()) {
        var role = this.store.peekRecord('session', 'current').get('user.role.name');

        if (role === ROLE.ACCOUNT_ADMIN) {
          if (this.isBilling()) {
            return;
          }
          this.transitionTo('protected.admin.billing');
        } else {
          this.transitionTo('protected.account-suspended');
        }
      }
    },

    beforeModel: function beforeModel(_ref) {
      var queryParams = _ref.queryParams;

      var isCrmUser = this.store.peekRecord('session', 'current').get('user.isCrmUser'),
          isSales360ParamPresent = queryParams && queryParams.bundle && queryParams.bundle === 'sales360';

      if (isCrmUser || isSales360ParamPresent) {
        this.set('session.account.isUnifiedAppAccount', true);
      }
    },

    isNotActiveAccount: function isNotActiveAccount() {
      var subscription = this.get('session.account.subscription');

      // evading test, #todo fix this
      if (subscription !== null) {
        return subscription.get('status') === ACCOUNT_STATUS.CANCELLED || subscription.get('status') === ACCOUNT_STATUS.PAYMENT_FAILED || subscription.get('status') === ACCOUNT_STATUS.EXPIRED;
      }
    },

    isBilling: function isBilling() {
      var route = this.get('routeName');

      return route === 'protected.admin.billing';
    },
    actions: {
      didTransition: function didTransition() {
        var _this = this;

        run.scheduleOnce('afterRender', function () {
          _this.get('analyticsTracker.appcues').page();
          NProgress.done();
        });
      }
    }
  });
});