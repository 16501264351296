define('freshfone-ember/initializers/error-monitor', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    ['component', 'controller', 'model', 'route', 'adapter', 'serializer'].forEach(function (entityType) {
      application.inject(entityType, 'errorMonitor', 'service:error-monitor');
    });
  }

  exports['default'] = {
    name: 'error-monitor',
    initialize: initialize
  };
});