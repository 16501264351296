define('freshfone-ember/components/power-dialer/contact/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    tagName: '',
    isUcrEnabledOmniFlow: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    currentAccount: computed.alias('session.account'),
    omniContactRedirectionUrl: computed('currentAccount.omnichannelOwnerDomain', function () {
      return this.get('currentAccount.omnichannelOwnerDomain') + '/a/org/contacts/';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('showDeleteModal') && !this.get('isAllContactsSelected') && !this.get('selectedContacts.length')) {
        this.set('isChecked', false);
      } else {
        this.set('isChecked', this.get('isAllContactsSelected') || this.get('selectedContacts').includes(this.get('powerDialerContact.id')));
      }
    },
    actions: {
      onChange: function onChange(e) {
        this.get('onChange')(this.get('powerDialerContact.id'), e.target.checked);
      },
      onDelete: function onDelete() {
        this.setProperties({
          'showDeleteModal': true,
          'selectedContacts': [this.get('powerDialerContact.id')]
        });
      }
    }
  });
});