define('freshfone-ember/components/get-started/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend({
    currentUser: computed.alias('session.user'),
    userName: computed.alias('currentUser.name'),
    isVerifiedUser: computed.alias('currentUser.email_confirmed'),
    email: computed({
      get: function get() {
        return this.store.peekAll('user').get('firstObject').get('email');
      }
    }),
    isEmailEditMode: false,

    buildParams: function buildParams(url, id) {
      return {
        url: url,
        type: 'GET',
        dataType: 'json',
        data: { id: id }
      };
    },

    actions: {
      emailChangeAction: function emailChangeAction() {
        // open the dialog to Edit the Email Address
        this.set('isEmailEditMode', true);
      },
      closeEditModal: function closeEditModal() {
        this.set('isEmailEditMode', false);
      },
      resendEmail: function resendEmail() {
        var _this = this;

        var params = this.buildParams('/send_activation_link', this.get('currentUser.id'));

        $.ajax(params).done(function () {
          run(function () {
            _this.set('mailSent', true);
            later(_this, function () {
              _this.set('mailSent', false);
            }, 10000);
          });
        }).fail(function () {
          return run(function () {
            return _this.set('mailError', true);
          });
        });
      }
    }

  });
});