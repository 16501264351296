define('freshfone-ember/components/agent-extension/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/remove-feature-actiontype', 'ember-validations', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/models/agent-extension'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsRemoveFeatureActiontype, _emberValidations, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberModelsAgentExtension) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], {
    routing: service('-routing'),
    showLoader: computed('callFlow.agentExtension.isFulfilled', function () {
      return !this.get('callFlow.agentExtension.isFulfilled');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('hasVoiceMailFeature')) {
        var defaultActionTypes = this.get('callFlow.agentExtension.actionOptions'),
            filteredActionTypes = this.removeObjFromArray(defaultActionTypes, 'name', 'vm');

        this.set('callFlow.agentExtension.actionOptions', filteredActionTypes);
      }
    },

    saveCallFlow: function saveCallFlow() {
      var _this = this;

      this.set('callFlow.name', this.get('callFlow.agentExtension.name'));
      this.get('callFlow').save().then(function (callFlow) {
        var id = callFlow.id;

        callFlow.get('agentExtension.content').save().then(function () {
          _this.saveCallbacks(id);
        })['catch'](function (e) {
          // handle server errors
          console.error('Error in saving call flow =>', e);
        });
      })['catch'](function (e) {
        // handle server errors
        console.error('Error in saving agent extension =>', e);
      });
    },
    hasVoiceMailFeature: computed(function () {
      return this.hasFeatures(['voicemail_and_status_fallback']);
    }),

    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      ['ivr', 'agent-extension', 'call-flow'].forEach(function (recordType) {
        _this2.store.peekAll(recordType).forEach(function (record) {
          if (record.get('isSaving')) {
            return;
          }
          if (record.get('isNew')) {
            return _this2.store.unloadRecord(record);
          }
          if (record.get('hasDirtyAttributes')) {
            record.rollbackAttributes();
            record.rollbackRelationships();
          }
        });
      });
      ['call-queue', 'routing-automation', 'routing-rule'].forEach(function (model) {
        return _this2.store.unloadAll(model);
      });
    },

    redirectionURL: computed('editNumber', function () {
      if (this.get('editNumber')) {
        return 'protected.admin.numbers';
      }

      return 'protected.admin.callflows';
    }),

    saveCallbacks: function saveCallbacks(id) {
      var _this3 = this;

      this.get('callFlow').reload().then(function () {
        _this3.set('startValidate', false);
        _this3.set('isSaving', false);
        if (_this3.get('editNumber')) {
          _this3.get('routing').transitionTo('protected.admin.numbers', [], {
            editNumber: _this3.get('editNumber'),
            callFlowID: _this3.get('callFlow.id'),
            actionFor: _this3.get('actionFor')
          });
        } else {
          _this3.get('routing').transitionTo('protected.admin.callflows');
        }
      });
    },
    actions: {
      save: function save() {
        var _this4 = this;

        var that = this;

        this.get('callFlow.agentExtension').then(function (agentExtension) {
          _this4.set('startValidate', true);
          _this4.set('isSaving', true);
          Promise.all(_freshfoneEmberModelsAgentExtension.agentActionTypes.map(function (actionType) {
            var modelName = agentExtension.get(actionType + 'ActionType'),
                actionId = agentExtension.get(actionType + 'ActionId'),
                action = agentExtension.get(actionType + 'Action');

            saveCallQueueIvr(modelName, actionId, _this4.store);
            if (modelName === 'callQueue' && (!actionId || action && action.get('hasDirtyAttributes'))) {
              return action.validate().then(function () {
                return _ember['default'].run(function () {
                  return action.save();
                });
              });
            } else if (modelName === 'ivr' && (!actionId || action && action.get('hasDirtyAttributes'))) {
              if (_this4.get('callFlow.agentExtension.' + actionType + 'Action.isValid')) {
                return action.validateAndSaveKeyPress().then(function () {
                  return action.validate().then(function () {
                    return action.save();
                  });
                });
              }

              return Promise.reject();
            }
          })).then(function () {
            Array.prototype.forEach.call(arguments[0], function (action, i) {
              action && agentExtension.set(_freshfoneEmberModelsAgentExtension.agentActionTypes[i] + 'ActionId', action.id);
            });

            return agentExtension.validate().then(function () {
              that.set('isFlowIncomplete', false);
              that.saveCallFlow();
            });
          })['catch'](function () {
            _this4.set('isFlowIncomplete', true);
            _this4.set('isSaving', false);
          });
        })['catch'](function () {
          _this4.set('isFlowIncomplete', true);
          _this4.set('isSaving', false);
        });
      }
    }
  });

  function saveCallQueueIvr(modelName, actionId, store) {
    if ((modelName === 'callQueue' || modelName === 'ivr') && actionId) {
      var _ret = (function () {
        var childCallFlow = store.peekRecord(modelName === 'callQueue' ? 'call-queue' : modelName, actionId);

        if (modelName === 'callQueue' && childCallFlow.get('hasDirtyAttributes')) {
          return {
            v: childCallFlow.validate().then(function () {
              return _ember['default'].run(function () {
                return childCallFlow.save();
              });
            })
          };
        } else if (modelName === 'ivr') {
          if (childCallFlow.get('isValid')) {
            return {
              v: childCallFlow.validateAndSaveKeyPress().then(function () {
                return childCallFlow.validate().then(function () {
                  return childCallFlow.save();
                });
              })
            };
          }

          return {
            v: Promise.reject()
          };
        }
      })();

      if (typeof _ret === 'object') return _ret.v;
    }
  }
});