define('freshfone-ember/components/team-component/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    currentUser: computed.alias('session.user'),
    sortedTeamList: computed.sort('model', 'sortDefinition'),
    routing: service('-routing'),
    sortDefinition: ['name:asc'],
    params: computed.alias('routing.router.currentState.routerJsState.fullQueryParams'),
    currentAccount: computed.alias('session.account'),
    ocrEnabled: computed.alias('currentAccount.ocrEnabled'),
    isOmnichannelEnabled: computed.alias('currentAccount.isOmnichannelEnabled'),
    isOmniTeam: computed.and('isOmnichannelEnabled', 'hasOmniGroupFeature'),
    shouldShowOmniView: computed('isOmniTeam', 'isCrmView', 'isSharedBundleAccount', function () {
      if (this.get('isSharedBundleAccount')) {
        return this.get('isOmniTeam') && !this.get('isCrmView');
      }

      return this.get('isOmniTeam');
    }),
    sharedWithoutOmniGroups: computed('isSharedBundleAccount', 'hasOmniGroupFeature', function () {
      return this.get('isSharedBundleAccount') && !this.get('hasOmniGroupFeature');
    }),
    hasOmniGroupFeature: computed(function () {
      return this.hasGlobalFeature('omni_groups') && this.checkFeature('omni_groups');
    }),
    hideCallParkingAndCallLimitFields: computed('params', 'currentUser', 'isOmniTeam', function () {
      return this.get('currentAccount.signupApp') === 'sales360' || this.get('currentUser.isCrmUser') || this.get('currentAccount.isSharedBundleAccount') || this.get('isOmniTeam');
    }),
    hasLimitingIncomingCallsFeature: computed(function () {
      return this.featureCheckWithRolloutFlag('limit_ringing_calls', 'limit_ringing_calls', 'limit_ringing_calls_rollout');
    }),
    hasParkingCallsFeature: computed(function () {
      return this.featureCheckWithRolloutFlag('call_parking', 'call_parking', 'call_parking_rollout');
    }),
    actions: {
      toggleDelete: function toggleDelete(item) {
        $('.text-cancel').trigger('click');
        item.toggleProperty('isDeleting');
      },
      afterDelete: function afterDelete(item) {
        if (item.id === this.get('team.id')) {
          this.set('team', null);
        }
      },
      onModify: function onModify() {
        this.set('modifyModel', true);
      },
      edit: function edit() {
        this._super.apply(this, arguments);
        this.set('slideToggle', true);
      }
    }
  });
});