define('freshfone-ember/initializers/crumbly', ['exports', 'ember-crumbly/initializers/crumbly'], function (exports, _emberCrumblyInitializersCrumbly) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCrumblyInitializersCrumbly['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberCrumblyInitializersCrumbly.initialize;
    }
  });
});