define('freshfone-ember/components/data-automated-routing/form/data-routing-rules/import-rules/rules-form/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var typeOf = _ember['default'].typeOf;
  exports['default'] = Component.extend({
    actions: {
      showImportWarning: function showImportWarning() {
        if (typeOf(this.get('onConfirmImport')) === 'function') {
          this.get('onConfirmImport')();
        }
      }
    }
  });
});