define('freshfone-ember/mixins/multiple-sticky', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    multipleSticky: function multipleSticky(elem) {
      var wrapper = document.getElementsByClassName(elem)[0];
      var prevPos = jQuery(wrapper).scrollTop();
      jQuery(wrapper).animate({
        scrollTop: 0
      }, 500, function () {
        initMultipleSticky(prevPos);
      });

      function initMultipleSticky(prevPos) {
        var headers = wrapper.getElementsByClassName('sticky-header');
        if (!headers.length) {
          return;
        }
        var elemHeight = headers[0].clientHeight;
        var initPositions = [];
        for (var i = 0; i < headers.length; i++) {
          initPositions.push(jQuery(headers[i]).offset().top);
        }
        var firstOne = initPositions[0];
        window.initPositionsModified = initPositions.map(function (pos, index) {
          return pos - firstOne - elemHeight * index;
        });
        wrapper.removeEventListener('scroll', bindMultipleSticky);
        wrapper.addEventListener('scroll', bindMultipleSticky);
        jQuery(wrapper).animate({
          scrollTop: prevPos
        }, 500);
      }

      function bindMultipleSticky() {
        var scrollPos = jQuery(this).scrollTop();
        var headers = wrapper.getElementsByClassName('sticky-header');
        for (var i = 0; i < headers.length; i++) {
          if (scrollPos > initPositionsModified[i]) {
            headers[i].classList.add('multiple-stick');
            headers[i].style.transform = 'translateY(' + i * 100 + '%)';
          } else {
            headers[i].classList.remove('multiple-stick');
            headers[i].style.transform = 'translateY(0)';
          }
        }
      }
    }
  });
});
// Get element wrapper and implement multiple multipleSticky
// Must give class name as 'sticky-header' for headings