define('freshfone-ember/components/common/act-and-alert/component', ['exports', 'ember'], function (exports, _ember) {
	var Component = _ember['default'].Component;
	var computed = _ember['default'].computed;
	var later = _ember['default'].run.later;
	exports['default'] = Component.extend({
		attributeBindings: ['data-toggle', 'data-placement', 'data-original-title'],
		showAlert: computed.alias('shouldAlert'),
		shouldAlert: computed({
			set: function set(key, value) {
				if (value) later(this, function () {
					if (!this.isDestroyed) this.set('shouldAlert', false);
				}, 2500);
				return value;
			}
		}),
		setShouldAlert: function setShouldAlert() {
			this.set('shouldAlert', true);
		},

		actions: {
			onClick: function onClick() {
				var _this = this;

				var callback = this.get('clickCallback');
				if (callback) {
					var result = callback();
					if (result && result.then) return result.then(_ember['default'].run(function () {
						return _this.setShouldAlert();
					}));
					this.setShouldAlert();
				}
			}
		}
	});
});