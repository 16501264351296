define('freshfone-ember/routes/protected/admin/mkp-gallery', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var $ = _ember['default'].$;
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('admin_components.MkpApps').string };
    }),
    titleToken: computed(function () {
      return _ember['default'].i18n.t('admin_components.MkpApps').string;
    }),

    beforeModel: function beforeModel() {
      this.checkFeature('marketplace_integration');
      this.checkPrivilege('manage_mkp_apps');
    },
    model: function model() {
      return $.getJSON('/api/marketplace/gallery');
    }
  });
});