define('freshfone-ember/components/agent-component/agent-form/component', ['exports', 'ember-data', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/feature-check-mixin', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/helpers/email', 'freshfone-ember/constants/external-redirect-urls'], function (exports, _emberData, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsFeatureCheckMixin, _emberComponentInboundActionsInboundActions, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberHelpersEmail, _freshfoneEmberConstantsExternalRedirectUrls) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var equal = _ember['default'].computed.equal;
  var isEmpty = _ember['default'].isEmpty;
  var i18n = _ember['default'].i18n;
  var PromiseArray = _emberData['default'].PromiseArray;
  var DEFAULT_ROLE = 'Agent';exports['default'] = Component.extend(_emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsModelFormMixin['default'], _emberComponentInboundActionsInboundActions['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    classNameBindings: ['slideToggle:form-open'],
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    activeUsersCount: computed.alias('currentAccount.active_users_count'),
    usersLimit: computed.alias('currentAccount.users_limit'),
    planName: computed.alias('currentAccount.plan_name'),
    isSproutPlan: computed.equal('planName', 'Sprout'),
    isTrial: computed.alias('currentAccount.isTrial'),
    isSproutOrTrial: computed.or('isSproutPlan', 'isTrial'),
    isSaving: false,
    defaultRole: null,
    limitExceededText: '',
    isSupervisor: equal('user.role.name', 'Supervisor'),
    isAgent: equal('user.role.name', 'Agent'),
    isTeamScoped: equal('user.scope.name', 'team'),
    isScopedAgent: computed.and('isAgent', 'hasEnhancedLiveDashboard'),
    hasEnhancedLiveDashboard: computed(function () {
      return this.hasGlobalFeature('enhanced_live_dashboard') && this.checkFeature('agent_restricted_live_dashboard');
    }),
    isUserTeamScoped: computed('isTeamScoped', function () {
      return this.get('isTeamScoped') && !this.get('user.isNew');
    }),
    shouldAllowScoping: computed('isSupervisor', function () {
      if (this.get('hasEnhancedLiveDashboard')) {
        return this.checkFeature('data_scoping') && (this.get('isSupervisor') || this.get('isAgent'));
      }

      return this.get('isSupervisor') && this.checkFeature('data_scoping');
    }),
    numberScoped: equal('user.scope.name', 'number'),
    numberQueryParams: computed(function () {
      return this.checkFeature('data_scoping') ? {
        deleted: false,
        paginate: false
      } : null;
    }),
    currentAgent: computed.alias('currentUser.agent'),
    extension: computed.alias('user.extension'),
    availableUserScopes: computed(function () {
      return PromiseArray.create({ promise: this.store.findAll('access-scope') });
    }),
    user: computed({
      set: function set(key, value) {
        var _this = this;

        this.set('startValidate', false);
        if (value && value.get('id')) {
          this.set('slideToggle', true);

          return value;
        }
        var roles = this.store.peekAll('role'),
            user = this.store.createRecord('user');

        roles.forEach(function (role) {
          if (role.get('name') === DEFAULT_ROLE) {
            _this.set('defaultRole', role);
          }
        });

        if (!this.get('defaultRole')) {
          this.store.queryRecord('role', { name: DEFAULT_ROLE }).then(function (defaultRole) {
            _this.set('defaultRole', defaultRole);
          });
        }

        user.setProperties({
          role: this.get('defaultRole'),
          scope: this.get('availableUserScopes.firstObject')
        });

        return user;
      },
      get: function get() {
        return this;
      }
    }),
    roles: computed(function () {
      if (this.get('currentUser.isAccountAdmin')) {
        return this.get('store').findAll('role');
      } else {
        return this.store.findAll('role', { reload: 'true' }).then(function (results) {
          return results.filter(function (role) {
            return role.get('name') != 'Account Admin';
          });
        });
      }
    }),

    title: computed('user.isNew', function () {
      return this.get('user.isNew') ? _ember['default'].i18n.t('agent.new_agent') : _ember['default'].i18n.t('agent.agent_details');
    }),

    submitLabel: computed('user.isNew', 'user.isSaving', function () {
      return this.get('user.isSaving') ? _ember['default'].i18n.t('team.form.saving') : this.get('user.isNew') ? _ember['default'].i18n.t('agent.add') : _ember['default'].i18n.t('agent.save');
    }),

    isDirty: computed('user.hasDirtyAttributes', 'user.{name,email,role,scope,numbersInScope}', {
      get: function get() {
        return this.get('user').isDeepDirty();
      }
    }).readOnly(),

    showSaveButton: computed('isDirty', 'user.isDeleted', function () {
      return this.get('isDirty') && !this.get('user.isDeleted');
    }),
    incrementActiveUsersCount: function incrementActiveUsersCount() {
      if (this.get('canIncrementUsersCount')) {
        this.incrementProperty('activeUsersCount');
      }
    },

    addonAgentsCount: computed.alias('currentAccount.addonAgentsCount'),
    addonUsersLimit: computed.alias('currentAccount.addonUsersLimit'),
    isOmnichannelEnabled: computed.alias('currentAccount.isOmnichannelEnabled'),
    isOmniOrCrmOrSharedUser: computed.or('user.omnichannelUser', 'user.isCrmUser', 'user.isSharedUser'),
    crmAgentEdit: computed(function () {
      var rootURL = localStorage.getItem('rootURL');

      var _rootURL$split = rootURL.split('/');

      var _rootURL$split2 = _slicedToArray(_rootURL$split, 2);

      var crmPath = _rootURL$split2[1];

      return '/' + crmPath + '/sales/settings/users';
    }),
    agentlimitExceeded: computed('activeUsersCount', 'addonUsersLimit', 'usersLimit', 'addonAgentsCount', 'user.id', function () {
      return this.get('isOmnichannelEnabled') ? this.get('addonAgentsCount') >= this.get('addonUsersLimit') : this.get('activeUsersCount') >= this.get('usersLimit') && !this.get('user.id');
    }),
    agentLimitText: computed(function () {
      var accountType = this.get('currentAccount.isOmnichannelEnabled') ? 'omnichannel' : 'standalone';

      return this.get('isSproutOrTrial') ? i18n.t('user.errors.trial_licence_limit.' + accountType, {
        activeUsersCount: this.get('activeUsersCount'),
        productName: this.get('currentAccount.productName'),
        deskName: this.get('currentAccount.deskName')
      }) : i18n.t('user.errors.licence_limit.' + accountType, {
        activeUsersCount: this.get('activeUsersCount'),
        link: '/admin/billing',
        productName: this.get('currentAccount.productName')
      });
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.rollbackDirtyChanges('user');
    },
    actions: {
      save: function save() {
        var _this2 = this;

        if (!this.get('isScopedAgent') && !this.get('isSupervisor')) {
          this.set('user.scope', null);
        }
        this.validate().then(function () {
          var user = _this2.get('user');
          _this2.set('canIncrementUsersCount', isEmpty(user.id));
          _this2.save('user', user).then(function () {
            if (!_ember['default'].isEmpty(user.get('errors')[0])) {
              if (user.get('errors')[0].detail === 'Deleted Agent') {
                _this2.store.find('user', user.get('errors')[1].detail).then(function (deleted_user) {
                  deleted_user.set('createAttempt', true);
                  _this2.sendAction('restoreAgent', deleted_user);
                  _this2.send('toggleSidecard');
                });
              }
            } else {
              _this2.incrementActiveUsersCount();
              _this2.sendAction('onModify');
              _this2.send('toggleSidecard');
            }
          });
        })['catch'](function (error) {
          _this2.set('startValidate', true);
        });
      },
      toggleSidecard: function toggleSidecard() {
        this.rollbackDirtyChanges('user');
        this.toggleProperty('slideToggle');
      },
      toggleLicenceCard: function toggleLicenceCard() {
        this.set('slideToggle', false);
      }
    },

    validations: {
      'user.email': {
        format: { presence: true, 'with': (0, _freshfoneEmberHelpersEmail.emailRegex)(), message: _ember['default'].i18n.t('activemodel.errors.is_invalid') }
      }
    }
  });
});