define('freshfone-ember/models/subscription', ['exports', 'ember', 'ember-data', 'ember-validations', 'freshfone-ember/utils/subscription-constants', 'ember-api-actions'], function (exports, _ember, _emberData, _emberValidations, _freshfoneEmberUtilsSubscriptionConstants, _emberApiActions) {
  var momentjs = window.moment;

  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;

  var CARD_TYPES = {
    VISA: 'visa', MASTERCARD: 'mastercard', JCB: 'jcb', DINERS: 'diners_club',
    AMERICAN: 'american_express', DISCOVER: 'discover'
  };

  exports['default'] = Model.extend(_emberValidations['default'], {
    planId: attr(),
    status: attr(),
    cardType: attr('string'),
    paymentPage: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'hosted_page_link' }),
    subscriptionPage: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'hosted_subscription_page_link' }),
    getBillingInformation: function getBillingInformation() {
      return $.ajax('/subscriptions/' + this.get('id') + '/subscription_addresses');
    },
    postBillingInformation: function postBillingInformation(data) {
      return $.ajax({
        url: '/subscriptions/' + this.get('id') + '/subscription_addresses',
        type: 'PUT',
        dataType: 'json',
        data: data
      });
    },
    card: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'card' }),
    planEstimate: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'plan_estimate' }),
    invoiceEstimate: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'invoice_estimate' }),
    changeCurrency: (0, _emberApiActions.memberAction)({ type: 'PUT', path: 'switch_currency' }),
    purchaseAddonAgents: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'addon/purchase' }),
    requestDowngrade: (0, _emberApiActions.collectionAction)({ type: 'POST', path: 'request_downgrade' }),
    cardFirstName: attr('string'),
    cardLastName: attr('string'),
    cardExpiration: attr('string'),
    billingSiteName: attr('string'),
    cardNumber: attr('string'),
    quantity: attr('string'),
    renewalPeriod: attr('string'),
    callingEnabled: attr(),
    invoiceAmount: attr(),
    unpaidInvoice: attr('boolean'),
    remainingTrialDays: attr(),
    planName: attr(),
    callsUsage: attr(),
    statusText: attr(),
    amount: attr(),
    nextRenewalAt: attr(),
    freeCredits: attr(),
    currency: attr(),
    invoiceDue: attr('boolean'),
    onlineCustomer: attr('boolean'),
    hasSavedCard: computed.bool('isCardDetailsPresent'),
    isCardFirstNamePresent: computed.bool('cardFirstName'),
    isCardLastNamePresent: computed.bool('cardLastName'),
    isCardExpirationPresent: computed.bool('cardExpirationPresent'),
    isCardDetailsPresent: computed('isCardFirstNamePresent', 'isCardLastNamePresent', 'isCardExpirationPresent', function () {
      return this.get('isCardFirstNamePresent') || this.get('isCardLastNamePresent') || this.get('isCardExpirationPresent');
    }),
    nameOnCard: computed('cardFirstName', 'cardLastName', function () {
      return this.get('cardFirstName') + ' ' + this.get('cardLastName');
    }),
    expirtyMonthAndYear: computed('cardExpiration', function () {
      return this.get('cardExpiration').split('-').join(' ');
    }),
    cardNumberFormatted: computed('cardNumber', function () {
      return this.get('cardNumber').match(/.{1,4}/g).join(' ').replace(/\*/g, '•');
    }),

    billingCycle: computed('renewalPeriod', function () {
      switch (this.get('renewalPeriod')) {
        case '1':
          return 'monthly';
        case '3':
          return 'quarterly';
        case '6':
          return 'half-yearly';
        default:
          return 'annual';
      }
    }),
    nextRenewalDate: computed('nextRenewalAt', function () {
      var date = this.get('nextRenewalAt');
      return '' + momentjs(date).format("MMM DD YYYY");
    }),
    mrr: computed('amount', 'renewalPeriod', function () {
      return this.get('amount') / this.get('renewalPeriod');
    }),
    cancellationDate: attr(),
    isTrail: computed.equal('status', _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.TRIAL),
    isTrailExpired: computed.equal('status', _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.EXPIRED),
    isActive: computed('status', function () {
      return this.get('status') === _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.ACTIVE || this.get('status') === _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.FREE;
    }),
    isPaymentFailed: computed.equal('status', _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.PAYMENT_FAILED),
    isCancelled: computed.equal('status', _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.CANCELLED),
    showStatus: computed.or('isTrail', 'isTrailExpired', 'isCancelled'),
    isSuspended: computed.or('isTrailExpired', 'isCancelled', 'isPaymentFailed'),
    cardUrl: computed('cardType', function () {
      return "/assets/images/`${this.get('cardType')}`.svg";
    }),

    makeActive: function makeActive() {
      this.set('status', _freshfoneEmberUtilsSubscriptionConstants['default'].STATUS.ACTIVE);
    },

    validations: {
      quantity: {
        inline: (0, _emberValidations.validator)(function () {
          var quantity = Number(this.model.get('quantity'));
          if (quantity <= 0 || quantity === '' || quantity === null) {
            return _ember['default'].i18n.t('subscription.validations.quantity.cannot_blank');
          } else if (isNaN(quantity)) {
            return _ember['default'].i18n.t('subscription.validations.quantity.number');
          }
        })
      }
    }

  });
});