define("freshfone-ember/components/ongoing-widget/search-contact/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/search-contact/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "note");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" \n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["search_contact.warning"], [], ["loc", [null, [8, 6], [8, 36]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 13,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/search-contact/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "search-results", [], ["results", ["subexpr", "@mut", [["get", "searchResults", ["loc", [null, [12, 29], [12, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "searchString", ["subexpr", "@mut", [["get", "searchString", ["loc", [null, [12, 56], [12, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedContact", "selectedContact"], ["loc", [null, [12, 4], [12, 105]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/ongoing-widget/search-contact/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "search-contact");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "create-contact pull-right");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createElementMorph(element2);
        morphs[3] = dom.createMorphAt(element2, 0, 0);
        morphs[4] = dom.createMorphAt(element0, 3, 3);
        morphs[5] = dom.createMorphAt(element0, 4, 4);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["ongoing-call-header ", ["subexpr", "if", [["get", "isActiveSearch", ["loc", [null, [2, 39], [2, 53]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [2, 34], [2, 64]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "search-bar", [], ["contactSearch", true, "model", "contact", "results", ["subexpr", "@mut", [["get", "searchResults", ["loc", [null, [3, 60], [3, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "query", ["subexpr", "@mut", [["get", "searchString", ["loc", [null, [3, 80], [3, 92]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 4], [3, 94]]], 0, 0], ["element", "action", ["createNewContact"], [], ["loc", [null, [4, 46], [4, 75]]], 0, 0], ["inline", "t", ["search_contact.create"], [], ["loc", [null, [4, 76], [4, 105]]], 0, 0], ["block", "if", [["get", "hasValidContact", ["loc", [null, [6, 8], [6, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 2], [10, 9]]]], ["block", "if", [["get", "searchResults", ["loc", [null, [11, 8], [11, 21]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [11, 2], [13, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});