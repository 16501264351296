define('freshfone-ember/components/ongoing-widget/recent-conversations/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/utils/call-constants'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberUtilsCallConstants) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    conversationsLoaded: false,
    telephony: service(),
    session: service(),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    currentCall: computed.alias('telephony.currentCall'),
    inWidgetTransferring: computed.and('isFromMaximizedWidget', 'currentCall.isTransferring'),
    callerType: computed.alias('currentCall.callerType'),
    recentCalls: _ember['default'].A(),
    agents: _ember['default'].A(),
    hasContactId: computed.notEmpty('currentCall.contact.id'),
    hasPickedContact: computed.notEmpty('pickedContact'),
    hasContactForFilter: computed.or('hasContactId', 'hasPickedContact'),
    isContactAbsent: computed.empty('currentCall.contact'),
    hasSearchTextForNote: computed.notEmpty('searchTextForNote'),
    noteSearchText: computed.alias('searchTextForNote'),
    filterParams: {},
    showHighlight: false,
    classNames: ['recent-calls'],
    isCallsEmpty: computed.empty('recentCalls'),
    isPickedContactEmpty: computed.empty('pickedContact'),
    hasAppliedParams: computed.notEmpty('appliedParams'),
    hasFilterParams: computed.notEmpty('filterParams'),

    isIncomingCall: computed.notEmpty('currentCall.to'),

    triggerReload: computed({
      get: function get() {
        return this;
      },
      set: function set(k, v) {
        var _this = this;

        if (typeof v === 'boolean') {
          _ember['default'].run.once(function () {
            _this.send('reloadCallerConversations');
          });
        }
      }
    }),

    triggerReloadConversation: observer('currentCall.contact', 'pickedContact.id', function () {
      if (!this.get('currentCall.contact') && !this.get('pickedContact.id')) {
        return;
      }
      this.send('reloadCallerConversations');
    }),

    searchTextForHighlight: computed('showHighlight', function () {
      if (this.get('showHighlight')) {
        return this.get('noteSearchText');
      }
    }),

    haveMoreCalls: computed('totalPages', 'page', function () {
      return this.get('totalPages') > this.get('page');
    }),

    triggerFilter: function triggerFilter() {
      if (!this.get('triggerFlag')) {
        this.setTimerForFilter();
      }
    },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      var initAndLoadConversations = function initAndLoadConversations() {
        _this2._initParams();
        _this2._loadConversations();
      };

      initAndLoadConversations();
      this.set('parentView.conversationList', this);
    },

    _initParams: function _initParams() {
      if (!this.get('currentCall.contact') && !this.get('pickedContact.id')) {
        return;
      }
      this._setFilterParams();
      this.set('recentCalls', []);
    },

    _loadConversations: function _loadConversations() {
      var _this3 = this;

      if (!this.get('currentCall.contact') && !this.get('pickedContact.id')) {
        return;
      }
      this.set('showHighlight', false);
      var filterParams = this.get('hasFilterParams') ? this.get('filterParams') : this.getContactParams({});
      filterParams.page = this.get('page');
      this.store.query('call', filterParams).then(function (calls) {
        if (_this3.get('isDestroyed')) {
          return;
        }
        _this3.get('recentCalls').addObjects(calls);
        _this3._setMeta(calls.meta);
        _this3.set('isConversationsLoading', false);
        _this3.set('conversationsLoaded', true);
        _this3.set('showHighlight', _ember['default'].isPresent(_this3.get('noteSearchText')));
        _ember['default'].run.debounce(_this3, _this3.scrollToBottom, 150);
      });
    },

    setTimerForFilter: function setTimerForFilter() {
      var self = this;
      setTimeout(function () {
        self.send('applyFilter');
      }, 500);
    },

    _setFilterParams: function _setFilterParams() {
      var params = this.buildDefaultParams();
      this.set('filterParams', params);
    },

    _setMeta: function _setMeta(meta) {
      this.set('page', meta.current);
      this.set('totalPages', meta['total-pages']);
    },

    setContactDefaultParams: function setContactDefaultParams(params) {
      if (this.get('hasContactForFilter')) {
        var contactKey = this.hasOmniRedirectFeatureEnabled() ? 'org_contact_id' : 'contact_id';

        params[contactKey] = this.get('pickedContact.id') || this.get('currentCall.contact.id');
      } else {
        if (this.get('currentCall.contact.isNew')) {
          params.caller_id = -1;
        } else {
          params.caller_id = this.get('currentCall.caller.id');
        }
        params.contact_id = -1;
      }
    },

    getContactParams: function getContactParams(params) {
      this.checkAndSetContactParams(params);
      return params;
    },

    buildDefaultParams: function buildDefaultParams() {
      var callStatus = _freshfoneEmberUtilsCallConstants['default'].STATUS_STR_HASH;

      var params = {
        status: callStatus["recently-completed"],
        limit: 5,
        page: this.get('page')
      };

      this.setContactDefaultParams(params);

      this.setNoteSearchParams(params);

      return params;
    },

    reloadConversations: function reloadConversations() {
      this._loadConversations();
    },

    setNoteSearchParams: function setNoteSearchParams(params) {
      params.search_string = this.get('noteSearchText');
    },

    scrollToBottom: function scrollToBottom() {
      if (this.get('recentCalls') && this.get('recentCalls').length < 6) return false;

      var conversationList = this.$('.conversations-list');
      if (conversationList) conversationList.animate({
        scrollTop: conversationList.prop('scrollHeight')
      }, 200);
    },

    enableFilter: function enableFilter() {
      this.set('isFilterOpen', true);
    },

    initCallLoading: function initCallLoading() {
      this.set('recentCalls', []);
      this.set('page', 1);
      this.set('totalPages', 1);
      this.set('conversationsLoaded', false);
    },

    checkAndSetContactParams: function checkAndSetContactParams(params) {
      if ((!params.contact_id || typeof params.contact_id === 'object' && !params.contact_id.length) && !params.caller_id) {
        this.setContactDefaultParams(params);
      }
    },

    actions: {

      reloadCallerConversations: function reloadCallerConversations() {
        var _this4 = this;

        this.set('conversationsLoaded', false);
        this.set('page', 1);

        this.set('pickedContact', null);
        run.later(function () {
          if (!(_this4.isDestroyed || _this4.isDestroying)) {
            _this4._initParams();
            _this4.reloadConversations();
          }
        }, 100);
      },

      loadMore: function loadMore() {
        this.incrementProperty('page');
        this.set('isConversationsLoading', true);
        this._loadConversations();
      },
      selectAgent: function selectAgent(agent) {
        if (this.get('isContactAbsent')) {
          return;
        }
        var agents = this.get('agents');
        if (!agents.isAny('id', agent.get('id'))) {
          agents.pushObject(agent);
        }
        this.enableFilter();
      }
    }
  });
});