define('freshfone-ember/constants/call-state', ['exports'], function (exports) {
  exports['default'] = Object.freeze({
    Initiated: 'initiated',
    Unavailable: 'unavailable',
    Active: 'active',
    Hold: 'hold',
    Ended: 'ended',
    Removed: 'removed',
    CloseAndResume: 'closeandresume'
  });
});