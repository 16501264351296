define('freshfone-ember/components/ongoing-widget/voicemails-list/component', ['exports', 'ember', 'freshfone-ember/mixins/click-outside-listener'], function (exports, _ember, _freshfoneEmberMixinsClickOutsideListener) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], {
    didInsertElement: function didInsertElement() {
      this.bindOutsideClick();
    },
    onOutsideClick: function onOutsideClick() {
      this.sendAction('onClose');
    },

    actions: {
      dropVoicemail: function dropVoicemail(messageId) {
        this.sendAction('onDropVoicemail', messageId);
      }
    }
  });
});