define('freshfone-ember/models/downgrade-customer', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    status: attr('number'),
    requestedPlan: attr(),
    requestedQuantity: attr('number'),
    requestedRenewalPeriod: attr('number'),
    scheduledTime: attr(),
    createdAt: attr(),
    cancelDowngrade: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'cancel_downgrade' }),
    getscheduled: (0, _emberApiActions.collectionAction)({ type: 'GET', path: 'scheduled' })
  });
});