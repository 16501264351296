define('freshfone-ember/routes/protected/admin/billing', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('billing.title').string;
    }),
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('billing.title').string };
    }),
    queryParams: {
      purchaseCreditModal: {}
    },
    beforeModel: function beforeModel() {
      var isUnifiedAppAccount = this.get('session.account.isUnifiedAppAccount');

      if (isUnifiedAppAccount) {
        window.open(this.get('session.account.ubxBillingUrl'), '_self');
      }

      this.checkPrivilege('view_billing');
    },
    model: function model(params) {
      var _this = this;

      var promiseSchedule = new Promise(function (resolve, reject) {
        if (!_this.get('session.account.downgradeScheduled')) {
          resolve();
        } else {
          var downgradeCustomer = _this.store.createRecord('downgradeCustomer');
          downgradeCustomer.getscheduled().then(function (data) {
            _this.store.unloadAll('downgradeCustomer');
            if (Object.keys(data).length) {
              _this.store.pushPayload('downgradeCustomer', data);
            }
            resolve();
          })['catch'](function (error) {
            reject();
          });
        }
      });
      return _ember['default'].RSVP.hash({
        numbers: this.store.query('number', {}),
        subscription: this.store.findAll('subscription'),
        promiseSchedule: promiseSchedule
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('purchaseCreditModal', undefined);
      }
    }
  });
});