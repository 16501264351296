define('freshfone-ember/models/live-agent-status', ['exports', 'ember', 'ember-data', 'ember-validations'], function (exports, _ember, _emberData, _emberValidations) {
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;

  var momentjs = window.moment;
  exports['default'] = _emberData['default'].Model.extend(_emberValidations['default'], {
    name: _emberData['default'].attr(),
    status: _emberData['default'].attr(),
    mobileStatus: _emberData['default'].attr(),
    lastCallAt: _emberData['default'].attr(),
    lastStatusChangeAt: _emberData['default'].attr(),
    lastSeenAt: _emberData['default'].attr(),
    loggedIn: _emberData['default'].attr(),
    callQueueIds: _emberData['default'].attr(),
    browserStatusId: _emberData['default'].attr(),
    userId: _emberData['default'].attr('string'),
    session: service(),
    isBusy: computed.equal('browserStatus.statusType', 'busy'),
    isAcw: computed.equal('browserStatus.statusType', 'acw'),
    isBusyOrAcw: computed.or('isBusy', 'isAcw'),
    isAgent: computed.alias('session.user.isAgent'),
    isAvailable: computed('browserStatus.statusType', 'mobileStatus', function () {
      return !this.get('isBusyOrAcw') && [this.get('browserStatus.statusType'), this.get('mobileStatus')].includes('online');
    }),
    isOffline: computed('browserStatus.statusType', 'mobileStatus', function () {
      return [this.get('browserStatus.statusType'), this.get('mobileStatus')].every(function (state) {
        return state === 'offline';
      });
    }),
    isOnPhoneOrMobileApp: computed('browserStatus.statusType', 'browserStatus.statusSubType', 'mobileStatus', function () {
      return this.get('browserStatus.statusType') === 'online' && this.get('browserStatus.statusSubType') === 'online_phone' || this.get('mobileStatus') === 'online';
    }),

    idleTime: computed('lastCallAt', function () {
      if (isEmpty(this.get('lastCallAt'))) {
        return _ember['default'].i18n.t('agent_availability.no_calls');
      }
      var lastCallAt = momentjs(this.get('lastCallAt')).local();
      var timeSince = lastCallAt.from();
      return timeSince.replace(/\b(ago|in)\b/g, "");
    }),
    statusToggleAllowed: computed('browserStatus.statusType', 'loggedIn', 'mobileStatus', function () {
      return this.get('loggedIn') && (this.get('isAvailable') || this.get('browserStatus.statusType') === 'offline') && !this.get('isAgent');
    })
  });
});