define('freshfone-ember/serializers/live-agent-status', ['exports', 'freshfone-ember/serializers/application'], function (exports, _freshfoneEmberSerializersApplication) {
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend({
    normalize: function normalize(store, primaryModelClass, payload, id, requestType) {
      primaryModelClass.attributes['mobile-status'] = primaryModelClass.attributes['mobile-preference'];
      delete primaryModelClass.attributes['mobile-preference'];

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      if (json.data.attributes['browser-status-id']) {
        json.data.attributes.browserStatusId = json.data.attributes['browser-status-id'];
        delete json.data.attributes['browser-status-id'];
      }

      return json;
    }
  });
});