define('freshfone-ember/constants/integrated-applications', ['exports'], function (exports) {
  var integratedAppsSection = {
    freshdesk: {
      key: 'freshdesk',
      title: 'Freshdesk',
      iconName: 'freshdesk',
      isIntegratedApplication: true
    },
    support360: {
      key: 'support360',
      title: 'Freshdesk',
      iconName: 'freshdesk',
      isIntegratedApplication: true
    },
    freshsales: {
      key: 'freshsales',
      title: 'Freshsales',
      iconName: 'freshsales',
      isIntegratedApplication: true
    },
    sales360: {
      key: 'sales360',
      title: 'Freshsales',
      iconName: 'freshsales',
      isIntegratedApplication: true
    },
    freshservice: {
      key: 'freshservice',
      title: 'Freshservice',
      iconName: 'freshservice',
      isIntegratedApplication: true
    }
  };

  exports.integratedAppsSection = integratedAppsSection;
});