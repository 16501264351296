define('freshfone-ember/models/company', ['exports', 'ember', 'ember-data', 'ember-validations', 'freshfone-ember/helpers/email'], function (exports, _ember, _emberData, _emberValidations, _freshfoneEmberHelpersEmail) {
  var computed = _ember['default'].computed;

  var momentjs = window.moment;

  exports['default'] = _emberData['default'].Model.extend(_emberValidations['default'], {

    name: _emberData['default'].attr(),
    website: _emberData['default'].attr("string"),
    email: _emberData['default'].attr(),
    phone: _emberData['default'].attr(),
    totalCalls: _emberData['default'].attr(),
    totalContacts: _emberData['default'].attr(),
    lastCallAt: _emberData['default'].attr(),
    address: _emberData['default'].attr(),
    deleteResources: _emberData['default'].attr(),
    contacts: _emberData['default'].hasMany('contact', { async: true }),
    calls: _emberData['default'].hasMany('call', { async: true }),

    hasLastCallAt: computed.notEmpty('lastCallAt') || computed.notEmpty('calls'),

    readable: computed("name", "website", "email", "phone", "address", {
      get: function get() {
        return ["name", "website", "email", "phone", "address"];
      }
    }),

    last_call_at: computed('calls.firstObject', 'lastCallAt', function () {
      var lastCall = this.get('calls.firstObject.created_at');
      return lastCall ? lastCall : this.get('lastCallAt');
    }),

    format_last_call_at: computed('last_call_at', {
      get: function get() {
        if (this.get('hasLastCallAt')) {
          var dateFormat = {
            sameDay: function sameDay() {
              return '[' + this.fromNow() + ']';
            },
            lastDay: '[Yesterday] - hh:mm A',
            lastWeek: 'ddd - hh:mm A',
            someElse: 'DD/MM/YYYY - hh:mm A'
          };
          return momentjs(this.get('last_call_at')).calendar(null, dateFormat);
        } else {
          return _ember['default'].i18n.t('common.no_calls');
        }
      } }),

    conversations: _ember['default'].computed('calls', function () {
      return this.get('calls');
    }),

    validations: {
      name: {
        presence: { message: _ember['default'].i18n.t('activemodel.errors.name_not_blank') },
        length: { maximum: 255, messages: { tooLong: _ember['default'].i18n.t('activemodel.errors.name_too_long') } }
      },
      email: {
        format: { 'with': (0, _freshfoneEmberHelpersEmail.emailRegex)(), allowBlank: true, message: _ember['default'].i18n.t('activemodel.errors.is_invalid') }
      }
    }
  });
});