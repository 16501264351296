define('freshfone-ember/components/live-calls/call-metrics-card/card-item/component', ['exports', 'freshfone-ember/utils/call-constants', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/constants/static-urls'], function (exports, _freshfoneEmberUtilsCallConstants, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberConstantsStaticUrls) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var testing = _ember['default'].testing;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    routing: service('-routing'),
    classNames: ['card-item'],
    classNameBindings: ['type'],
    status: computed('type', function () {
      if (['missed', 'abandoned', 'voicemail'].contains(this.get('type'))) {
        return _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH[this.get('type')].id;
      }
    }),
    direction: computed('type', function () {
      if (['incoming', 'outgoing'].contains(this.get('type'))) {
        return _freshfoneEmberUtilsCallConstants['default'].TYPE_HASH[this.get('type')].id;
      }
    }),
    has1CXFeature: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),
    actions: {
      redirectToCallLogs: function redirectToCallLogs() {
        var queryParams = {
          isFromDashboard: true,
          selectedQueues: this.get('queueIds')
        };

        if (this.get('direction')) {
          queryParams.direction = this.get('direction');
        }
        if (this.get('status')) {
          queryParams.status = this.get('status');
        }

        if (this.get('has1CXFeature') && this.get('isFullView')) {
          var queryParamsStr = new URLSearchParams(queryParams).toString(),
              routeToBeRedirected = _freshfoneEmberConstantsStaticUrls['default'].crmCallLogs + '?' + queryParamsStr;

          /*
           * Keeping the check for testing variable because in testing mode assigning route to parent window
           * will stop execution of test suite
           */
          if (!testing) {
            window.top.location.href = routeToBeRedirected;
          } else {
            window.callLogsCrmUrl = routeToBeRedirected;
          }
        } else {
          this.get('routing').transitionTo('protected.call-logs', [], queryParams);
        }
      }
    }
  });
});