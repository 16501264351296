define("freshfone-ember/components/live-calls/views-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 12,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/views-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon-validation-alert");
            dom.setAttribute(el1, "data-toggle", "tooltip");
            dom.setAttribute(el1, "data-placement", "right");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'title');
            return morphs;
          },
          statements: [["attribute", "title", ["subexpr", "t", ["service_level_metrics.views_max_limit_message"], [], ["loc", [null, [null, null], [11, 147]]], 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 8
                },
                "end": {
                  "line": 20,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/views-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "icon-tick");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 4
              },
              "end": {
                "line": 22,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/views-list/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "ellipsis");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[3] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["saved-view ", ["subexpr", "if", [["subexpr", "eq", [["get", "selectedView.name", ["loc", [null, [16, 38], [16, 55]]], 0, 0, 0, 0], ["get", "view.attributes.name", ["loc", [null, [16, 56], [16, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 34], [16, 77]]], 0, 0], "selected-view"], [], ["loc", [null, [16, 29], [16, 95]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["setSelectedView", ["get", "view", ["loc", [null, [16, 124], [16, 128]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 97], [16, 130]]], 0, 0], ["content", "view.attributes.name", ["loc", [null, [17, 31], [17, 55]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "selectedView.name", ["loc", [null, [18, 18], [18, 35]]], 0, 0, 0, 0], ["get", "view.attributes.name", ["loc", [null, [18, 36], [18, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 14], [18, 57]]], 0, 0]], [], 0, null, ["loc", [null, [18, 8], [20, 15]]]]],
          locals: ["view"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/views-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "list-view-wrapper");
          var el2 = dom.createTextNode("   \n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "view-list-header");
          var el3 = dom.createTextNode(" \n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "view-count-limit");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("/");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" \n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1, 1]);
          var element4 = dom.childAt(element3, [2]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element3, 0, 0);
          morphs[1] = dom.createMorphAt(element4, 0, 0);
          morphs[2] = dom.createMorphAt(element4, 2, 2);
          morphs[3] = dom.createMorphAt(element3, 4, 4);
          morphs[4] = dom.createMorphAt(element2, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["service_level_metrics.saved_view_list"], [], ["loc", [null, [9, 12], [9, 58]]], 0, 0], ["content", "viewsCount", ["loc", [null, [9, 90], [9, 104]]], 0, 0, 0, 0], ["content", "maxLimit", ["loc", [null, [9, 105], [9, 117]]], 0, 0, 0, 0], ["block", "if", [["get", "isMaxViewCountReached", ["loc", [null, [10, 14], [10, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 8], [12, 15]]]], ["block", "each", [["get", "views", ["loc", [null, [15, 12], [15, 17]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 4], [22, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 7
          }
        },
        "moduleName": "freshfone-ember/components/live-calls/views-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "view-name");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "icon-ham");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createElementMorph(element6);
        morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["element", "action", ["toogleDropDown"], [], ["loc", [null, [2, 22], [2, 49]]], 0, 0], ["content", "selectedView.name", ["loc", [null, [3, 6], [3, 27]]], 0, 0, 0, 0], ["block", "if", [["get", "showViews", ["loc", [null, [6, 6], [6, 15]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 0], [24, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});