define('freshfone-ember/components/data-import/component', ['exports', 'ember', 'freshfone-ember/constants/template-urls'], function (exports, _ember, _freshfoneEmberConstantsTemplateUrls) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({
    fileSizeLimit: 5242880,
    hasAttachment: computed.notEmpty('fileName'),
    hasInvalidFormat: observer('fileData.type', function () {
      if (this.get('hasAttachment') && !this.isValidFileType(this.get('fileData').type)) {
        this.set('errMsg', 'Invalid file is selected');
        this.set('hasError', true);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      switch (this.get('fileModel')) {
        case 'contacts':
          this.setProperties({
            headerTitle: _ember['default'].i18n.t('contact.import_contact'),
            downloadURL: _freshfoneEmberConstantsTemplateUrls['default'].contactTemplate,
            validFileTypes: ['.csv'],
            fileType: 'csv'
          });
          break;
        case 'companies':
          this.setProperties({
            headerTitle: _ember['default'].i18n.t('company.import_company'),
            downloadURL: _freshfoneEmberConstantsTemplateUrls['default'].companyTemplate,
            validFileTypes: ['.csv'],
            fileType: 'csv'
          });
          break;
        case 'bot_intents':
          this.setProperties({
            headerTitle: _ember['default'].i18n.t('speech_ivr.upload_intents'),
            downloadURL: _freshfoneEmberConstantsTemplateUrls['default'].botIntentsTemplate,
            validFileTypes: ['.csv'],
            fileType: 'csv'
          });
          break;
        case 'routing_rules':
          this.setProperties({
            headerTitle: _ember['default'].i18n.t('data_automated_routing.import_rules.title'),
            downloadURL: _freshfoneEmberConstantsTemplateUrls['default'].routingRulesTemplate,
            validFileTypes: ['.xlsx'],
            fileType: 'xlsx'
          });
          break;
        case 'power_dialer':
          {
            this.setProperties({
              headerTitle: _ember['default'].i18n.t('power_dialer.import_contacts_title'),
              downloadURL: _freshfoneEmberConstantsTemplateUrls['default'].powerDialerTemplate,
              validFileTypes: ['.csv'],
              fileType: 'csv'
            });
            break;
          }
        default:
          this.setProperties({
            headerTitle: _ember['default'].i18n.t('import_module.default_header'),
            downloadURL: _freshfoneEmberConstantsTemplateUrls['default'].contactTemplate,
            validFileTypes: ['.csv'],
            fileType: 'csv'
          });
          break;
      }
    },
    isValidFileType: function isValidFileType(fileType) {
      var fileExtRegex = /\.[a-zA-Z0-9]*$/g,
          validMIMETypes = ['text/x-csv', 'text/csv', 'text/comma-separated-values', 'text/x-comma-separated-values', 'application/vnd.ms-excel', 'application/csv', 'application/x-csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

      return fileType.trim() ? validMIMETypes.includes(fileType.toLowerCase()) : this.get('validFileTypes').includes(this.get('fileName').match(fileExtRegex)[0]);
    },

    actions: {
      setChildReference: function setChildReference(childReference) {
        this.set('fileInputComponent', childReference);
      },
      close: function close() {
        this.sendAction('close');
      },
      dragover: function dragover(e) {
        e.preventDefault();
      },
      drop: function drop(e) {
        e.stopPropagation();
        e.preventDefault();
        var dt = e.dataTransfer;

        var _dt$files = _slicedToArray(dt.files, 1);

        var file = _dt$files[0];

        if (!this.isValidFileType(file.type)) {
          return;
        }
        this.get('fileInputComponent').setFileAttr(file);
      },
      uploadFile: function uploadFile() {
        // this instead of CSS pointer-events none, because then we cannot have not-allowed cursor
        if (!this.get('hasAttachment') || this.get('hasError')) {
          return;
        }

        this.importCallback({
          fileName: this.get('fileName'),
          fileData: this.get('fileData'),
          fileType: this.get('fileModel'),
          params: this.get('params')
        });
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */