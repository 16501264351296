define('freshfone-ember/components/outgoing-widget/intl-tel-input/component', ['exports', 'ember'], function (exports, _ember) {
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      var _this = this;

      _ember['default'].$('#searchTerm').keydown(function (e) {
        _this.onKeydown(e);
      }).keyup(function (e) {
        _this.onKeyup(e);
      });

      this.$('.search-bar').prop('placeholder', _ember['default'].i18n.t('outgoing_widget.searchCountry').string);
    },

    onKeydown: function onKeydown(e) {
      var currentEle = _ember['default'].$('.row.hover');
      switch (e.keyCode) {
        case 38:
          this.hoverPrevEle(currentEle);
          break;
        case 40:
          this.hoverNextEle(currentEle);
          break;
      }
    },

    onKeyup: function onKeyup(e) {
      switch (e.keyCode) {
        case 13:
          this.callRecipient();
          break;
        default:
          if (e.keyCode !== 38 && e.keyCode !== 40) {
            this.sendAction('bindSearch');
          }
      }
    },

    hoverPrevEle: function hoverPrevEle(currentEle) {
      var prevEle = currentEle.prev();
      return isPresent(prevEle) && this.hoverEle(currentEle, prevEle);
    },

    hoverNextEle: function hoverNextEle(currentEle) {
      var nextEle = currentEle.next();
      return isEmpty(currentEle) ? this.hoverEle(currentEle, _ember['default'].$('.row:first')) : isEmpty(nextEle) ? this.hoverEle(currentEle, _ember['default'].$('.row:first')) : this.hoverEle(currentEle, nextEle);
    },

    hoverEle: function hoverEle(currentEle, nextEle) {
      if (nextEle.attr('id')) {
        this.scrollTo(_ember['default'].$('.list'), nextEle);
      }
      currentEle.toggleClass('hover');
      nextEle.toggleClass('hover');
    },

    callRecipient: function callRecipient() {
      var _this2 = this;

      this.findRecipient().then(function (resp) {
        var recipientSource = isPresent(_ember['default'].$('.number-header.row.hover')) ? 'unknown' : '';
        _this2.sendAction('initiateCall', resp.name, resp.number, resp.id, recipientSource);
      });
    },

    findRecipient: function findRecipient() {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        var number = _ember['default'].$('.ff-outgoing .row.hover .ph-no').text() || _ember['default'].$('.ff-outgoing .row:first .ph-no').text() || _ember['default'].$('.input-outgoing #searchTerm').val();
        if (isPresent(_ember['default'].$('.ff-outgoing .number-header.row')) || isPresent(_ember['default'].$('.input-outgoing #searchTerm'))) {
          resolve(_this3.buildRecipient('', number));
        } else {
          if (_this3.get('isSearch')) {
            _this3.findFromContacts(number);
          } else {
            _this3.findFromRecentcalls(number);
          }
          resolve(_this3.get('recipient'));
        }
      });
    },

    findFromRecentcalls: function findFromRecentcalls(number) {
      var recentCalls = this.get('recentCalls');
      if (number) {
        var item = recentCalls.findBy('contact.number', number);
        this.set('recipient', this.buildRecipient(item.get('contact.name'), item.get('contact.phone'), item.get('contact.id')));
      } else {
        number = _ember['default'].$('.row.hover .name').text() || _ember['default'].$('.row:first .name').text();
        var item = recentCalls.findBy('caller.number', number);
        this.set('recipient', this.buildRecipient('', item.get('caller.number')));
      }
    },

    findFromContacts: function findFromContacts(number) {
      var item = this.get('filteredContactList').findBy('phone', number);
      this.set('recipient', this.buildRecipient(item.get('name'), item.get('phone'), item.get('id')));
    },

    buildRecipient: function buildRecipient(name, number) {
      var id = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

      return { name: name, number: number, id: id };
    },

    scrollTo: function scrollTo(container, element, middle) {
      var containerHeight = container.height(),
          containerTop = container.offset().top,
          containerBottom = containerTop + containerHeight,
          elementHeight = element.outerHeight(),
          elementTop = element.offset().top,
          elementBottom = elementTop + elementHeight,
          newScrollTop = elementTop - containerTop + container.scrollTop(),
          middleOffset = containerHeight / 2 - elementHeight / 2;

      if (elementTop < containerTop) {
        // scroll up
        if (middle) {
          newScrollTop -= middleOffset;
        }
        container.scrollTop(newScrollTop);
      } else if (elementBottom > containerBottom) {
        // scroll down
        if (middle) {
          newScrollTop += middleOffset;
        }
        var heightDifference = containerHeight - elementHeight;
        container.scrollTop(newScrollTop - heightDifference);
      }
    }

  });
});