define("freshfone-ember/components/custom-elements/date-range-v1/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 6,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/custom-elements/date-range-v1/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "formatted-date");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "dropdown-arrow");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [5]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createAttrMorph(element3, 'class');
            morphs[2] = dom.createMorphAt(element3, 0, 0);
            return morphs;
          },
          statements: [["content", "formatedDate", ["loc", [null, [3, 32], [3, 48]]], 0, 0, 0, 0], ["attribute", "class", ["concat", [["get", "label", ["loc", [null, [5, 20], [5, 25]]], 0, 0, 0, 0], "-time-label label-container ", ["subexpr", "if", [["get", "toUpperCase", ["loc", [null, [5, 60], [5, 71]]], 0, 0, 0, 0], "transformToUpper"], [], ["loc", [null, [5, 55], [5, 92]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "label", ["loc", [null, [5, 94], [5, 103]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 10
                  },
                  "end": {
                    "line": 15,
                    "column": 10
                  }
                },
                "moduleName": "freshfone-ember/components/custom-elements/date-range-v1/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "icon-tick pull-right");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 4
                },
                "end": {
                  "line": 18,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/custom-elements/date-range-v1/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "#");
              dom.setAttribute(el2, "class", "item");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
              morphs[3] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["attribute", "class", ["subexpr", "if", [["subexpr", "eq", [["get", "dateValue.label", ["loc", [null, [10, 25], [10, 40]]], 0, 0, 0, 0], ["get", "activeDateRangeLabel", ["loc", [null, [10, 41], [10, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 21], [10, 62]]], 0, 0], "active"], [], ["loc", [null, [null, null], [10, 73]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["selectOption", ["get", "label", ["loc", [null, [11, 57], [11, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 33], [11, 64]]], 0, 0], ["inline", "t", [["get", "label", ["loc", [null, [12, 21], [12, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 17], [12, 28]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "dateValue.label", ["loc", [null, [13, 20], [13, 35]]], 0, 0, 0, 0], ["get", "activeDateRangeLabel", ["loc", [null, [13, 36], [13, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 16], [13, 57]]], 0, 0]], [], 0, null, ["loc", [null, [13, 10], [15, 17]]]]],
            locals: ["label", "dateValue"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 2
              },
              "end": {
                "line": 33,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/custom-elements/date-range-v1/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "custom-date-range-option");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "input-group custom-range");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [2]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(element2, 3, 3);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "each-in", [["get", "dateRangeOptions", ["loc", [null, [9, 15], [9, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 4], [18, 16]]]], ["inline", "t", ["custom_elements.custom_range"], [], ["loc", [null, [20, 46], [20, 82]]], 0, 0], ["inline", "common/custom-power-calendar", [], ["class", "date-range-wrapper", "noInputField", true, "rangeSelector", true, "selectedRange", ["subexpr", "@mut", [["get", "selectedRange", ["loc", [null, [25, 22], [25, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "resetActiveRange", ["subexpr", "action", ["resetActiveRange"], [], ["loc", [null, [26, 25], [26, 52]]], 0, 0], "toggler", ["subexpr", "@mut", [["get", "calendarToggler", ["loc", [null, [27, 16], [27, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "locale", "en", "format", "DD MM YYYY", "maxDate", ["subexpr", "@mut", [["get", "today", ["loc", [null, [30, 16], [30, 21]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 6], [31, 8]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/custom-elements/date-range-v1/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "rl-dropdown-toggle", [], ["tagName", "div", "class", "btn btn-default"], 0, null, ["loc", [null, [2, 2], [6, 25]]]], ["block", "rl-dropdown", [], ["tagName", "ul", "class", "dropdown-menu date-options", "closeOnChildClick", "a:link"], 1, null, ["loc", [null, [8, 2], [33, 18]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/custom-elements/date-range-v1/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "rl-dropdown-container", [], ["class", "dropdown", "onOpen", ["subexpr", "action", ["openHandler"], [], ["loc", [null, [1, 49], [1, 71]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [34, 26]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});