define('freshfone-ember/models/ivr-option', ['exports', 'ember', 'ember-data', 'ember-validations', 'freshfone-ember/mixins/call-flow-child-validation-mixin'], function (exports, _ember, _emberData, _emberValidations, _freshfoneEmberMixinsCallFlowChildValidationMixin) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var i18n = _ember['default'].i18n;
  var set = _ember['default'].set;
  exports['default'] = Model.extend(_emberValidations['default'], _freshfoneEmberMixinsCallFlowChildValidationMixin['default'], {
    ivr: belongsTo({ async: true }),

    optionKey: attr('string'),
    actionType: attr(),
    actionId: attr(),
    showIVRChild: computed.and('optionKey', 'actionType'),
    actionIvr: belongsTo('ivr', { async: true }),
    actionQueue: belongsTo('callQueue', { async: true }),
    actionAgentExtension: belongsTo('agentExtension', { async: true }),
    actionRoutingAutomation: belongsTo('routingAutomation', { async: true }),
    isIvr: computed.notEmpty('actionIvr.content'),
    isCallQueue: computed.notEmpty('actionQueue.content'),
    isRoutingAutomation: computed.notEmpty('actionRoutingAutomation.content'),
    isACallFlow: computed.or('isIvr', 'isCallQueue', 'isRoutingAutomation'),
    isFallbackOption: computed.equal('optionKey', 'fallback'),
    isIvrSelected: computed.equal('actionType', 'ivr'),
    isCallQueueSelected: computed.equal('actionType', 'callQueue'),
    isMessageNotSelected: computed.or('isIvrSelected', 'isCallQueueSelected', 'isRoutingAutomationSelected'),
    isAgentExtensionSelected: computed.equal('actionType', 'agentExtension'),
    isRoutingAutomationSelected: computed.equal('actionType', 'routingAutomation'),

    unloadOption: function unloadOption() {
      var value = this.get('optionKey');
      if (value) {
        var keyOption = this.get('ivr.keyOptions');
        if (keyOption) {
          var valueOption = keyOption.findBy('name', value);
          if (valueOption) {
            set(valueOption, 'disabled', false);
          }
        }
      }
      this.store.unloadRecord(this);
    },
    validations: {
      actionType: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.actiontype')
        }
      },
      optionKey: {
        presence: {
          message: _ember['default'].i18n.t('call_ivr.validation_errors.intent')
        }
      },
      actionId: {
        inline: (0, _emberValidations.validator)(function () {
          var actionType = this.get('actionType');
          if (!this.model.get("actionId")) {
            if (!this.model.get("isACallFlow") && this.model.get('isIvrSelected')) {
              return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_ivr');
            } else if (!this.model.get("isACallFlow") && this.model.get('isCallQueueSelected')) {
              return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_callqueue');
            } else if (!this.model.get("isACallFlow") && this.model.get('isRoutingAutomationSelected')) {
              return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_routing_automation');
            } else if (this.model.get('isAgentExtensionSelected')) {
              return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_agent_extension');
            } else if (!this.model.get("isMessageNotSelected")) {
              return _ember['default'].i18n.t('call_ivr.validation_errors.keypress_options_message');
            }
          }
        })
      }
    }
  });
});