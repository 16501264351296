define('freshfone-ember/constants/status-getter-map', ['exports', 'freshfone-ember/constants/call-status'], function (exports, _freshfoneEmberConstantsCallStatus) {

  var statusGetterMap = new Map();

  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].Completed, 'isCompleted');
  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].CallInProgress, 'isInProgress');
  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].Onhold, 'isOnHold');
  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].Transferring, 'isTransferring');
  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].TransferUnavailable, 'isTransferUnavailable');
  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].WarmTransferring, 'isWarmTransferring');
  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].WarmTransferUnavailable, 'isWarmTransferUnavailable');
  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].WarmTransferred, 'isWarmTransferAccepted');
  statusGetterMap.set(_freshfoneEmberConstantsCallStatus['default'].Transferred, 'isTransferred');

  exports['default'] = statusGetterMap;
});