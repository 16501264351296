define('freshfone-ember/components/custom-elements/power-select/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/map-by-key-name-contants', 'freshfone-ember/constants/search-query-constants'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsMapByKeyNameContants, _freshfoneEmberConstantsSearchQueryConstants) {
  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isArray = _ember['default'].isArray;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  var defineProperty = _ember['default'].defineProperty;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var String = _ember['default'].String;
  var pluralize = String.pluralize;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    classNames: ["custom-power-select"],
    store: service('store'),
    renderInPlace: computed.empty('renderInPlace') || computed.equal('renderInPlace', true) ? true : false,
    allowClear: false,
    classNameBindings: ['Fv:option-component', 'isOpened:opened', 'icon:has-icon'],
    dropdownClass: 'power_select_options',
    hasMoreOptions: computed.gt('optionList.length', 5),
    searchEnabled: false,
    isSearchEnabled: computed.or('hasMoreOptions', 'searchEnabled'),
    isSearchDefined: computed.or('onSearch', 'customSearch'),
    isInputDefined: computed.alias('onInput'),
    hasModel: computed.notEmpty('model'),
    hasLabel: computed.notEmpty('label'),
    startValidate: computed.alias('parent.startValidate'),
    isActive: computed.notEmpty('displayValue'),
    verticalPosition: computed({
      get: function get(key) {},
      set: function set(key, value) {
        return value || 'auto';
      }
    }),
    hasOptionComponent: computed.notEmpty('optionsComponent'),
    sortingData: ['id'],
    filteredModelData: computed.filterBy('modelData', 'isNew', false),
    filterLoadedData: computed.filterBy('filteredModelData', 'isLoaded', true),
    sortedModelData: computed.sort('filterLoadedData', 'sortingData'),
    searchMessage: _ember['default'].i18n.t('contact.contact_form.company_search_message'),
    searchPlaceholder: computed('attrName', function () {
      var searchPlaceholder = _ember['default'].i18n.t('common.search') + '...';
      if (this.get('attrName')) {
        searchPlaceholder = _ember['default'].i18n.t('entityNames.search_entity', { entityName: this.get('attrName') });
      }
      return searchPlaceholder;
    }),
    isNumberInput: false,
    optionList: computed('options', 'sortedModelData', 'isOpened', {
      get: function get() {
        if (typeof this.get('isOpened') === 'undefined') {
          return [];
        }

        if (this.get('hasModel')) {
          if (this.get('customFilterCallBack')) {
            return this.get('customFilterCallBack')(this.get('sortedModelData'));
          }

          return this.get('sortedModelData');
        }

        return this.get('options');
      }
    }),

    modelData: computed('model', 'newOptionObject.isNew', 'customSearch', function () {
      if (this.get('customSearch')) {
        return;
      }
      var model = this.get('model');
      return this.get("store")[this.get('queryParams') ? 'query' : 'findAll'](model, this.get('queryParams'));
    }),

    newOptionObject: computed('model', function () {
      var model = this.get('model');
      var newObject = this.get("store").peekAll(model).filterBy('isNew', true).filterBy('hasDirtyAttributes', false)[0];
      if (!newObject) {
        newObject = this.get("store").createRecord(model, { name: 'Create New' });
      }
      return newObject;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.defineDisplayValue();
    },

    handleNewObjectSelection: function handleNewObjectSelection(model) {
      if (this.get('hasCreateOption') && isPresent(model) && model.get('isNew')) {
        if (this.get('onNewObjectCreate')) {
          this.get('onNewObjectCreate')(model);
        }
      }
    },

    defineDisplayValue: function defineDisplayValue() {
      var attr = this.get('attributeName');

      if (typeof this.get('value') === 'object' && !attr) {
        return;
      }

      defineProperty(this, 'displayValue', _ember['default'].computed('value', ['value', attr].join("."), 'value.[]', 'value.id', function () {
        if (isArray(this.get('value'))) {
          return this.get('value').mapBy('id');
        }
        return this.get("value.id") || this.get(['value', attr].join("."));
      }));
    },

    loadingMessage: computed('attrName', function () {
      var loadingMessage = _ember['default'].i18n.t('common.loading') + '...';
      if (this.get('attrName')) {
        loadingMessage = _ember['default'].i18n.t('entityNames.loading_entity', { entityName: this.get('attrName') });
      }
      return loadingMessage;
    }),

    noMatchesMessage: computed('attrName', function () {
      return this.get('attrName') ? _ember['default'].i18n.t('entityNames.no_match', { entityName: pluralize(this.get('attrName')).toLowerCase() }) : _ember['default'].i18n.t('entityNames.no_match_general');
    }),

    setQueryParamsForSearch: function setQueryParamsForSearch(queryParams) {
      if (this.get('isUcrEnabled')) {
        queryParams.product = this.get('signupApp');
        queryParams.version = _freshfoneEmberConstantsSearchQueryConstants['default'].version;
      } else {
        queryParams.source = _freshfoneEmberConstantsSearchQueryConstants['default'].source.dropdown;
      }

      return queryParams;
    },
    actions: {
      onInput: function onInput(e) {
        return this.get('onInput')(e.target.value);
      },
      toggleIsOpen: function toggleIsOpen() {
        this.toggleProperty("isOpened");
        if (this.get("isOpened")) {
          this.get("optionList");
        }

        if (this.get("toggleAction")) {
          this.sendAction('toggleAction');
        }
      },
      onSearch: function onSearch(text) {
        var _this = this;

        if (this.get('customSearch')) {
          if (isEmpty(text)) {
            return;
          }
          var controller = pluralize(this.get('model'));

          var queryParams = { filter: { queryString: text } };

          this.setQueryParamsForSearch(queryParams);

          return $.getJSON('/' + controller + '/search', queryParams).then(function (response) {
            var keyForMapping = _this.get('isUcrEnabled') ? _freshfoneEmberConstantsMapByKeyNameContants['default'].withAttribute.mcrId : _freshfoneEmberConstantsMapByKeyNameContants['default'].withoutAttribute.id,
                keyForModelSelector = _this.get('isUcrEnabled') ? _freshfoneEmberConstantsMapByKeyNameContants['default'].withoutAttribute.mcrId : _freshfoneEmberConstantsMapByKeyNameContants['default'].withoutAttribute.id,
                resultIds = response.data.mapBy(keyForMapping),
                model = _this.get('isUcrEnabled') && _this.get('model') === 'contact' ? 'external-contact' : _this.get('model');

            return run(function () {
              _this.store.pushPayload(response);

              return _this.store.peekAll(model).filter(function (modelObj) {
                return resultIds.contains(modelObj.get(keyForModelSelector));
              });
            });
          });
        }

        return this.get('onSearch')(text);
      },
      onChange: function onChange(changedValue) {
        this.set("isNewObjectSelected", false);
        // this.handleNewObjectSelection(changedValue);
        if (this.get('onChange')) {
          return this.get('onChange')(changedValue);
        }
        this.set('value', changedValue);
        //https://freshchannelbugs.freshdesk.com/helpdesk/tickets/64
        //#todo, try to fix the above bug sans workaround
        setTimeout(function () {
          $('.ember-power-select-trigger-multiple-input').val('');
        }, 0);
        if (typeof this.get('followUpAction') === 'function') {
          this.sendAction('followUpAction');
        }
        if (typeof this.get('getChangedValue') === 'function') {
          this.sendAction('getChangedValue', changedValue);
        }
      }
    }
  });
});