define("freshfone-ember/components/live-calls/save-queues-view/confirm-save-view-modal/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 14
              },
              "end": {
                "line": 22,
                "column": 14
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/save-queues-view/confirm-save-view-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon-validation-alert");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 30,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/save-queues-view/confirm-save-view-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content-form user-form with-content-border");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "view-name");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "validation-message char-limit");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "save");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn btn-primary save-profile");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(element0, [3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          morphs[2] = dom.createMorphAt(element2, 3, 3);
          morphs[3] = dom.createElementMorph(element3);
          morphs[4] = dom.createMorphAt(element3, 0, 0);
          return morphs;
        },
        statements: [["inline", "text-field", [], ["animate", true, "label", ["subexpr", "t", ["service_level_metrics.view_actions.view_label"], [], ["loc", [null, [9, 19], [9, 70]]], 0, 0], "mandatory", true, "value", ["subexpr", "@mut", [["get", "viewName", ["loc", [null, [11, 18], [11, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "maxlength", ["subexpr", "@mut", [["get", "maxCharacterLimit", ["loc", [null, [12, 22], [12, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabelAgentExtensionField", ["loc", [null, [13, 21], [13, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "for", "viewname", "id", "viewname", "errors", ["subexpr", "if", [["get", "startValidate", ["loc", [null, [16, 23], [16, 36]]], 0, 0, 0, 0], ["get", "viewNameError", ["loc", [null, [16, 37], [16, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 19], [16, 51]]], 0, 0], "parent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [17, 19], [17, 23]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 10], [18, 14]]], 0, 0], ["block", "if", [["get", "maxCharLimitReached", ["loc", [null, [20, 20], [20, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 14], [22, 21]]]], ["inline", "if", [["get", "viewName", ["loc", [null, [23, 19], [23, 27]]], 0, 0, 0, 0], ["get", "availableLength", ["loc", [null, [23, 28], [23, 43]]], 0, 0, 0, 0], ["get", "maxCharacterLimit", ["loc", [null, [23, 44], [23, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 14], [23, 63]]], 0, 0], ["element", "action", ["saveView"], [], ["loc", [null, [27, 55], [27, 76]]], 0, 0], ["inline", "t", ["common.save"], [], ["loc", [null, [27, 77], [27, 96]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/live-calls/save-queues-view/confirm-save-view-modal/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "save-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "view-form");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "icon-close modal-icon-close");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0, 1]);
        var element5 = dom.childAt(element4, [1]);
        var morphs = new Array(2);
        morphs[0] = dom.createElementMorph(element5);
        morphs[1] = dom.createMorphAt(element4, 3, 3);
        return morphs;
      },
      statements: [["element", "action", ["closeModal"], [], ["loc", [null, [3, 43], [3, 66]]], 0, 0], ["block", "ui-form", [], ["for", "view"], 0, null, ["loc", [null, [4, 4], [30, 16]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});