define('freshfone-ember/components/business-calendar/holiday-calendar/custom-holiday-list/holiday-component/component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    var Component = _ember['default'].Component;
    exports['default'] = Component.extend({
        actions: {
            removeHoliday: function removeHoliday(holiday) {
                this.sendAction('removeHolidayCallback', holiday);
            },

            onDatepickerOpen: function onDatepickerOpen(datepicker) {
                // Fact: The containing block for a fixed-position descendant of a transformed element is the transformed element, not the viewport
                datepicker.children('.ember-power-calendar').css({
                    top: datepicker.prev().offset().top - datepicker.parents('.modal-dialog').offset().top + 50,
                    left: datepicker.prev().offset().left - datepicker.parents('.modal-dialog').offset().left
                });
            }
        }
    });
});