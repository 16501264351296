define('freshfone-ember/initializers/model', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.initialize = initialize;
  var isEmpty = _ember['default'].isEmpty;
  var isArray = _ember['default'].isArray;
  var isPresent = _ember['default'].isPresent;

  function initialize() {

    _emberData['default'].Model.reopen({
      t: function t(key) {
        return this.get('i18n').t(key);
      },
      saveOriginalRelations: function saveOriginalRelations() {
        this._originalRelations = {};
        this.eachRelationship(function (key, relationship) {
          if (relationship.kind === 'belongsTo' && this.get(key)) {
            this._originalRelations[key] = this.get(key);
          }
          if (relationship.kind === 'hasMany' && !relationship.options.skipDeepDirty && this.get(key)) {
            this._originalRelations[key] = this.get(key).toArray();
          }
        }, this);
      },

      isDeepDirty: function isDeepDirty() {
        var keysNotToCheck = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

        if (this.get('hasDirtyAttributes')) {
          return true;
        }
        if (!this._originalRelations) {
          return false;
        }

        return Object.keys(this._originalRelations).any(function (key) {
          if (keysNotToCheck.indexOf(key) > -1) {
            return false;
          }
          if (isArray(this.get(key))) {
            return this.dirtyCheckForArrayProperty(key);
          }

          return this.get(key) && (this.get(key).get('hasDirtyAttributes') || this.get(key).get('id') !== this._originalRelations[key].get('id'));
        }, this);
      },

      dirtyCheckForArrayProperty: function dirtyCheckForArrayProperty(key) {
        if (isPresent(this.get(key).filterBy('hasDirtyAttributes')) || this.get(key).get('length') !== this._originalRelations[key].length) {
          return true;
        }

        var canonicalState = [],
            currentState = [];

        var dirty = false;

        this.get(key).forEach(function (item) {
          currentState.push(item.get('id'));
        });

        this._originalRelations[key].forEach(function (item) {
          canonicalState.push(item.get('id'));
        });

        currentState.sort();
        canonicalState.sort();

        currentState.forEach(function (item, index) {
          if (item !== canonicalState[index]) {
            dirty = true;
          }
        });

        return dirty;
      },

      rollbackRelationships: function rollbackRelationships() {
        this._super();
        if (!this._originalRelations) {
          return;
        }
        Object.keys(this._originalRelations).forEach(function (key) {
          //careful, as Ember.typeOf for ArrayProxy is 'instance'

          if (_ember['default'].isArray(this.get(key))) {
            this.get(key).setObjects(this._originalRelations[key]);
            this.get(key).forEach(function (relatedObject) {
              if (relatedObject.isDeepDirty()) {
                relatedObject.rollbackAttributes();
                relatedObject.rollbackRelationships();
              }
            });
            return;
          }
          //for belongsTo
          var model = this.get(key);
          if (_ember['default'].typeOf(this.get(key)) === 'instance') {
            new _ember['default'].RSVP.Promise(function (resolve) {
              resolve(model);
            }).then(function (relatedObject) {
              if (!isEmpty(relatedObject) && relatedObject.isDeepDirty()) {
                relatedObject.rollbackAttributes();
                relatedObject.rollbackRelationships();
              }
            });
          }
          if (model.get("id") !== this._originalRelations[key].get('id')) {
            this.set(key, this._originalRelations[key]);
          }
        }, this);
      },

      rollbackModelAttribute: function rollbackModelAttribute(attributeToRollback) {
        var _this = this;

        var changedAttributes = this.changedAttributes();

        Object.keys(changedAttributes).forEach(function (attribute) {
          if (attributeToRollback.includes(attribute)) {
            var _changedAttributes$attribute = _slicedToArray(changedAttributes[attribute], 1);

            var oldValue = _changedAttributes$attribute[0];

            _this.set(attribute, oldValue);
          }
        });
      }
    });
  }

  exports['default'] = {
    name: 'model',
    initialize: initialize
  };
});