define('freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/primary-question/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.setPrimaryQuestion();
    },

    options: computed.alias('primaryQuestion.surveyQuestionOptions'),
    otherFeedbackInput: '',

    setPrimaryQuestion: function setPrimaryQuestion() {
      var _this = this;

      this.store.findAll('survey').then(function () {
        var questions = _this.store.peekAll('survey-question');

        var _questions$filterBy = questions.filterBy('primary', true);

        var _questions$filterBy2 = _slicedToArray(_questions$filterBy, 1);

        var primaryQuestion = _questions$filterBy2[0];

        _this.set('primaryQuestion', primaryQuestion);
      });
    },

    createPrimaryQuestionAnswerModel: function createPrimaryQuestionAnswerModel(questionId) {
      return {
        question: questionId,
        options: [],
        othersText: ''
      };
    },

    isQuestionAdded: function isQuestionAdded(surveyQuestion) {
      return this.get('secondaryQuestion').some(function (question) {
        return surveyQuestion.get('id') === question.get('id');
      });
    },

    getOptionIndex: function getOptionIndex(optionId) {
      var optionIndex = 0;

      this.get('primaryQuestionOptions.options').forEach(function (option, index) {
        if (option.id === optionId) {
          optionIndex = index;
        }
      });

      return optionIndex;
    },
    checkIfFeedbackCaptured: function checkIfFeedbackCaptured(inputValue) {
      // if input is not entered by user , feedback capturing is not needed so just return true
      if (!inputValue) {
        return true;
      }
      // if no option is selected, need to capture input
      if (!this.get('primaryQuestionOptions.options').length) {
        return false;
      }
      // check in primaryOptions is some option contains input feed
      var option = this.get('primaryQuestionOptions.options').some(function (field) {
        return field.text !== '';
      });

      return !!option;
    },

    actions: {
      addOrRemoveOption: function addOrRemoveOption(key, value) {
        var _this2 = this;

        if (!this.get('secondaryQuestion')) {
          this.set('secondaryQuestion', []);
        }

        if (!this.get('primaryQuestionOptions')) {
          this.set('primaryQuestionOptions', this.createPrimaryQuestionAnswerModel(key.get('surveyQuestion.id')));
        }
        if (value) {
          /**
           * if feedbackInput is present and entry not available in primaryQuestionOptions,
           * capture it in primaryQuestionOptions option object
           */
          var isFeedbackInputCaptured = this.checkIfFeedbackCaptured(this.get('otherFeedbackInput'));

          this.set('primaryQuestionOptions.options', [].concat(_toConsumableArray(this.get('primaryQuestionOptions.options')), [{
            id: key.get('id'),
            text: !isFeedbackInputCaptured ? this.get('otherFeedbackInput') : ''
          }]));
          /**
           * secondary question is populated either when some option other than OTHER
           * is selected or OTHER is selected and user has already feed the input feedback
           */
          if (!key.get('otherOption') || this.get('otherFeedbackInput')) {
            key.get('surveyRoutings').forEach(function (routing) {
              routing.get('surveyQuestion').set('required', routing.get('required'));
              if (!_this2.isQuestionAdded(routing.get('surveyQuestion'))) {
                _this2.get('secondaryQuestion').pushObject(routing.get('surveyQuestion'));
              }
            });
          }
        } else {
          var filterOptions = this.get('primaryQuestionOptions.options').filter(function (option) {
            return option.id !== key.get('id');
          });

          this.set('primaryQuestionOptions.options', filterOptions);
          key.get('surveyRoutings').forEach(function (routing) {
            _this2.get('secondaryQuestion').removeObject(routing.get('surveyQuestion'));
          });
        }
      },

      addOthersOption: function addOthersOption(option, e) {
        var _this3 = this;

        // set the other feedback input . This is useful when user directly input the feedback without selecting the option
        var inputValue = e.target.value.trim(),
            isPrimaryQuestionAnswered = this.get('primaryQuestionOptions') && this.get('primaryQuestionOptions.options.length');

        this.set('otherFeedbackInput', inputValue);

        if (!this.get('secondaryQuestion')) {
          this.set('secondaryQuestion', []);
        }

        // if primary question option is selected , then only this is done
        if (isPrimaryQuestionAnswered) {
          this.get('primaryQuestionOptions.options')[this.getOptionIndex(option.get('id'))].text = inputValue;
        }
        // secondary question is only populated when primary option is selected
        if (inputValue && isPrimaryQuestionAnswered) {
          option.get('surveyRoutings').forEach(function (routing) {
            if (!_this3.isQuestionAdded(routing.get('surveyQuestion'))) {
              routing.get('surveyQuestion').set('required', routing.get('required'));
              _this3.get('secondaryQuestion').pushObject(routing.get('surveyQuestion'));
            } else {
              _this3.get('secondaryQuestion').forEach(function (question) {
                if (question.get('id') === option.get('surveyQuestion').get('id')) {
                  question.set('othersText', e.target.value);
                }
              });
            }
          });
        } else if (option.get('surveyRoutings.length') === this.get('secondaryQuestion').length) {
          option.get('surveyRoutings').forEach(function (routing) {
            _this3.get('secondaryQuestion').removeObject(routing.get('surveyQuestion'));
          });
        }
      }
    }
  });
});