define('freshfone-ember/components/agent-extension/agent-extension-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/helpers/message-default-types', 'freshfone-ember/mixins/remove-feature-actiontype'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberHelpersMessageDefaultTypes, _freshfoneEmberMixinsRemoveFeatureActiontype) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _ember['default'].Evented, _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], {
    session: service(),
    nameEmpty: computed.empty('agentExtension.name'),
    init: function init() {
      this._super.apply(this, arguments);
      this.createDefaultActions();
    },
    didInsertElement: function didInsertElement() {
      if (this.get('nameEmpty')) {
        var agentExtensionRecord = this.store.peekAll('agentExtension').content.length;
        this.set('agentExtension.name', 'Agent Extension Flow ' + agentExtensionRecord);
      }
      if (this.hasGlobalFeature('agent_extension_fallback')) {
        this.decideOnShowingAgentExtensionAsFallback();
      }
    },

    decideOnShowingAgentExtensionAsFallback: function decideOnShowingAgentExtensionAsFallback() {
      var _this = this;

      this.store.findAll('agent-extension', { reload: true }).then(function (agentExtensions) {

        var filteredAgentExtension = agentExtensions.content.filter(function (agentExtension) {
          return !(!agentExtension.id || agentExtension.id === _this.get('agentExtension.id'));
        });
        if (filteredAgentExtension.length > 0) {
          _this.set('agentExtensionList', agentExtensions);
        } else {
          _this.removeObjFromArray(_this.get('agentExtension.actionOptions'), 'abbr', 'agent_extension');
        }
      });
    },

    createDefaultActions: function createDefaultActions() {
      var _this2 = this;

      var agentExtensiontId = this.get('agentExtension.id'),
          getDefaultMessages = this.get('store').query('message', {
        defaultMessageType: [_freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].invalidInputMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].invalidInputMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].agentExtensionWelcome, _freshfoneEmberHelpersMessageDefaultTypes['default'].agentNotAvailable, _freshfoneEmberHelpersMessageDefaultTypes['default'].agentNotAnswering, _freshfoneEmberHelpersMessageDefaultTypes['default'].agentBusy, _freshfoneEmberHelpersMessageDefaultTypes['default'].invalidExtension],
        paginate: false
      });

      if (!agentExtensiontId) {
        getDefaultMessages.then(function () {
          _this2.setDefaultMsg();
        });
      }
      this.get('agentExtension.content').saveOriginalRelations();
    },

    setDefaultMsg: function setDefaultMsg() {
      var agentExtension = this.get('agentExtension'),
          messages = this.store.peekAll('message'),
          hangup = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage)[0],
          welcome = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].agentExtensionWelcome)[0],
          offline = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].agentNotAvailable)[0],
          noAnswer = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].agentNotAnswering)[0],
          busy = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].agentBusy)[0],
          invalidExtension = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].invalidExtension)[0];

      agentExtension.setProperties({
        message: welcome,
        invalidInputMessage: invalidExtension,
        noInputMessage: welcome,
        offlineActionId: offline.id,
        noAnswerActionId: noAnswer.id,
        busyActionId: busy.id,
        invalidInputActionId: hangup.id,
        noInputActionId: hangup.id,
        noExtensionActionId: hangup.id
      });
    },

    actions: {
      showNewMessage: function showNewMessage(messageFor) {
        var defaultTitle = this.store.peekAll('message').content.length + 1,
            newObject = this.store.createRecord('message', { name: _ember['default'].i18n.t('message_components.record_component.message') + ' ' + defaultTitle });

        this.set('showMessage', true);
        this.set('messageHolder', newObject);
        this.set('messageFor', messageFor);
      },
      closeMessage: function closeMessage(message) {
        if (!message) {
          this.get('messageHolder').unloadRecord();
          return;
        }

        this.get('agentExtension').set(this.get('messageFor'), this.get('messageHolder'));
        this.set('messageFor', null);
      }
    }
  });
});