define('freshfone-ember/components/ongoing-widget/transfer/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/transfer-options'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsTransferOptions) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    telephony: service(),
    notifications: service('toastr'),
    i18n: service(),
    classNames: ['dropdown'],
    session: service(),
    currentConnection: service('current-connection'),
    currentCall: computed.alias('telephony.currentCall'),
    showLoading: false,
    isAgentsLoaded: false,
    agentDetails: [],
    teamsDetails: [],
    emptySeachString: computed.empty('searchString'),
    hasEmptyTeamList: computed.empty('filteredTeams'),
    hasEmptyAgentList: computed.empty('filteredAgents'),
    hasNoCallTags: computed.empty('currentCall.tags'),
    hasErrorMessage: computed.notEmpty('errorMessage'),
    hasEmptyList: computed.and('hasEmptyAgentList', 'hasEmptyTeamList'),
    showAgentConferenceButton: computed('currentCall.isConferenceCall', function () {
      return this.get('session.account').hasFeature('agent_conference') && !this.get('currentCall.isConferenceCall');
    }),
    warmTransferEnabled: computed(function () {
      return this.get('session.account').hasFeature('warm_transfer');
    }),
    loadAgentsClicked: observer('dropdownExpanded', function () {
      if (!this.get('isAgentsLoaded')) {
        this.loadAgents();
      }
    }),
    parallelCallEnabled: computed('session.account', function () {
      return this.hasGlobalFeature('parallel_call') && this.checkFeature('parallel_call');
    }),
    hasTeamFeature: computed(function () {
      return this.get('session.account').hasFeature('team');
    }),

    isExternalTransferAllowed: computed('isPhoneNumberFormat', function () {
      return this.get('isPhoneNumberFormat') && this.allowExternalTransfer();
    }),

    transferOptions: computed('warmTransferEnabled', 'parallelCallEnabled', 'showAgentConferenceButton', function () {
      return [{
        id: _freshfoneEmberConstantsTransferOptions['default'].WarmTransfer,
        label: this.get('i18n').t('transfer.warmTransfer'),
        icon: 'icon-warm-transfer',
        visible: this.get('warmTransferEnabled')
      }, {
        id: _freshfoneEmberConstantsTransferOptions['default'].ColdTransfer,
        label: this.get('i18n').t('transfer.transfer_tooltip'),
        icon: 'icon-call-transfer',
        visible: true
      }, {
        id: _freshfoneEmberConstantsTransferOptions['default'].AddParticipants,
        label: this.get('i18n').t('transfer.add_participant'),
        icon: 'icon-add-agent',
        visible: this.get('showAgentConferenceButton')
      }, {
        id: _freshfoneEmberConstantsTransferOptions['default'].ParallelCall,
        label: this.get('i18n').t('transfer.parallel_call'),
        icon: 'icon-parallel-calling',
        visible: this.get('parallelCallEnabled')
      }];
    }),

    isPhoneNumberFormat: computed('searchString', function () {
      this.set('isNumberInvalid', false);
      this.set('errorMessage', null);
      if (this.get('emptySeachString')) {
        return false;
      }

      return this.get('searchString').match(/^[\x20+()0-9+-]*$/);
    }),

    searchRegex: computed('searchString', function () {
      return new RegExp(this.get('searchString'), 'i');
    }),

    searchPlaceholder: computed(function () {
      if (this.hasFeatures(['external_number_route'])) {
        return this.get('i18n').t('transfer.all_transfer_placeholder');
      }

      return this.get('i18n').t('transfer.search_placeholder');
    }),

    filteredAgents: computed('agentDetails.[]', 'searchString', function () {
      if (this.get('emptySeachString')) {
        return this.get('agentDetails');
      }
      var exp = this.get('searchRegex');

      return this.get('agentDetails').filter(function (agent) {
        return exp.test(agent.name);
      });
    }),

    filteredTeams: computed('teamsDetails.[]', 'searchString', function () {
      if (this.get('emptySeachString')) {
        return this.get('teamsDetails');
      }
      var exp = this.get('searchRegex');

      return this.get('teamsDetails').filter(function (team) {
        return exp.test(team.name);
      });
    }),

    filteredAgentsWithinSelectedTeam: computed('agentDetails.[]', 'selectedTeam.name', function () {
      var _this = this;

      return this.get('selectedTeam') ? this.get('selectedTeam').agents.map(function (agentId) {
        return _this.get('agentDetails').find(function (agent) {
          return agent.id === agentId;
        });
      }).filter(function (agent) {
        return agent;
      }) : null;
    }),

    logError: function logError(err, message) {
      // eslint-disable-next-line no-console
      console.error(message ? message : 'Some error occured', err);
    },

    transferCallback: function transferCallback() {
      this.saveCallNotes();
      this.saveCallTags();
      this.get('telephony').updateCurrentCall(this.get('telephony').getCurrentCall());
      this.set('callInfoMsg', 'Call has been transferred to agent      ' + this.get('transferAgent.name'));
    },

    saveCallNotes: function saveCallNotes() {
      var callId = this.get('currentCall.id'),
          callNote = this.get('currentCall.notes');

      this.get('telephony').saveConversation({
        call_id: callId,
        note: callNote
      });
    },

    saveCallTags: function saveCallTags() {
      if (this.get('hasNoCallTags')) {
        return;
      }
      var callId = this.get('currentCall.id'),
          callTags = this.get('currentCall.tags').mapBy('id');

      this.get('telephony').saveCallTags({
        call_id: callId,
        tag_ids: callTags
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony.event').unsubscribe('agent_status_change', this.agentStatusCallback.bind(this));
    },

    agentStatusCallback: function agentStatusCallback(data) {
      this.get('teamsDetails').setObjects(data.teams);
      this.get('agentDetails').setObjects(data.agents);
    },

    loadAgents: function loadAgents() {
      var _this2 = this;

      this.get('telephony.event').subscribe('agent_status_change', this.agentStatusCallback.bind(this));
      this.get('telephony.agent').getAvailableAgentsAndTeams().then(function (availableList) {
        run(function () {
          _this2.get('agentDetails').setObjects(availableList.agents);
          _this2.get('teamsDetails').setObjects(availableList.teams);
          _this2.toggleProperty('isAgentsLoaded');
        });
      });
    },
    initiateParallelCall: function initiateParallelCall(agent) {
      var _this3 = this;

      this.get('telephony').getCurrentCall().parallelCallToAgent(agent).then(function () {
        run(function () {
          _this3.get('telephony').updateCurrentCall(_this3.get('telephony').getCurrentCall());
        });
      })['catch'](function (error) {
        _this3.logError(error, 'Agent Parallel call Failed:');
      });
    },
    transferAgent: function transferAgent(agent) {
      var _this4 = this;

      this.set('currentCall.attemptingTransferOrConference', true);
      this.get('telephony').getCurrentCall().transferToAgent(agent).then(function () {
        return run(function () {
          return _this4.transferCallback();
        });
      })['catch'](function (error) {
        _this4.logError(error, 'Agent Cold Transfer Failed:');
      })['finally'](function () {
        return _this4.set('currentCall.attemptingTransferOrConference', false);
      });
    },
    warmTransfer: function warmTransfer(agent) {
      var _this5 = this;

      this.set('isWarmTransferring', true);
      this.set('currentCall.attemptingTransferOrConference', true);
      this.get('telephony').getCurrentCall().warmTransferToAgent(agent).then(function () {
        return run(function () {
          return _this5.transferCallback();
        });
      })['catch'](function (error) {
        _this5.logError(error, 'Agent Warm Transfer Failed:');
      })['finally'](function () {
        return _this5.set('currentCall.attemptingTransferOrConference', false);
      });
    },
    addAgent: function addAgent(agent) {
      var _this6 = this;

      this.set('currentCall.attemptingTransferOrConference', true);
      this.get('telephony').getCurrentCall().initiateAgentConference(agent).then(function () {
        run(function () {
          _this6.set('currentCall.isAddingAgent', true);
          _this6.transferCallback();
        });
      })['catch'](function (error) {
        _this6.logError(error, 'Add Agent Failed:');
      })['finally'](function () {
        return _this6.set('currentCall.attemptingTransferOrConference', false);
      });
    },

    actions: {
      setCurrentTeam: function setCurrentTeam(team) {
        this.set('selectedTeam', team);
      },
      optionSelectedCallback: function optionSelectedCallback(_ref) {
        var id = _ref.id;
        var agent = _ref.agent;

        this.set('dropdownExpanded', false);
        switch (id) {
          case _freshfoneEmberConstantsTransferOptions['default'].WarmTransfer:
            this.warmTransfer(agent);
            break;
          case _freshfoneEmberConstantsTransferOptions['default'].ColdTransfer:
            this.transferAgent(agent);
            break;
          case _freshfoneEmberConstantsTransferOptions['default'].AddParticipants:
            this.addAgent(agent);
            break;
          case _freshfoneEmberConstantsTransferOptions['default'].ParallelCall:
            this.initiateParallelCall(agent);
            break;
        }
      },
      agentTransfer: function agentTransfer(agent) {
        this.transferAgent(agent);
      },
      warmTransfer: function warmTransfer(agent) {
        this.warmTransfer(agent);
      },
      teamTransfer: function teamTransfer(team) {
        var _this7 = this;

        this.set('currentCall.attemptingTransferOrConference', true);
        this.get('telephony').getCurrentCall().transferToTeam(team).then(function () {
          return run(function () {
            return _this7.transferCallback();
          });
        })['catch'](function (error) {
          _this7.logError(error, 'Team Transfer Failed:');

          _this7.get('notifications').error(_this7.get('i18n').t('transfer.failed_transfer'));
        })['finally'](function () {
          return run(function () {
            return _this7.set('currentCall.attemptingTransferOrConference', false);
          });
        });
      },
      transferExternal: function transferExternal(type, number) {
        var _this8 = this;

        var stopValidation = function stopValidation() {
          _this8.set('validatingNumberForBlindTransfer', false);
          _this8.set('validatingNumberForWarmTransfer', false);
        };

        (function () {
          if (type === 'BLIND') {
            _this8.set('validatingNumberForBlindTransfer', true);

            return _this8.get('telephony').getCurrentCall().transferToExternalNumber(number);
          }
          _this8.set('validatingNumberForWarmTransfer', true);

          return _this8.get('telephony').getCurrentCall().warmTransferToExternalNumber(number);
        })().then(function () {
          stopValidation();
          _this8.set('dropdownExpanded', false);
          _this8.transferCallback();
        })['catch'](function (error) {
          _this8.logError(error);
          var message = error.message || error.error;

          if (message) {
            _this8.set('errorMessage', message);
          }
          _this8.set('isNumberInvalid', true);
          stopValidation();
        });
      },
      addAgent: function addAgent(agent) {
        this.addAgent(agent);
      },
      toggleActive: function toggleActive() {
        this.toggleProperty('isActive');
      },
      closeTransfer: function closeTransfer() {
        this.send('closeDropdown');
      }
    }
  });
});