define('freshfone-ember/components/ongoing-widget/transfer/transfer-options/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend({
    clientX: 0,
    clientY: 0,
    id: computed('agent', 'isTeamAgent', function () {
      var id = undefined;

      if (this.get('agent')) {
        switch (this.get('agent').category) {
          case 'Agent':
            id = this.get('isTeamAgent') ? 'team-agent-' + this.get('agent.id') : 'agent-' + this.get('agent.id');
            break;
          case 'Contact':
            id = this.get('agent').name + '-' + this.get('agent').phoneNumber;
            break;
        }
      } else {
        id = 'external-number';
      }

      return 'more-action-' + id;
    }),
    spanId: computed('id', function () {
      return 'wrapper-' + this.get('id');
    }),
    showIcon: computed('options.@each.disabled', function () {
      return this.get('options').some(function (option) {
        return !option.disabled;
      });
    }),
    didInsertElement: function didInsertElement() {
      this.set('inconversationModal', document.querySelector('.inconversation-modal'));
      this.set('transferContainer', document.querySelector('.transfer-list'));
      this.attachListener();
      this.scrollListerner();
    },
    willDestroyElement: function willDestroyElement() {
      this.removeListerner();
    },
    clickListener: function clickListener(event) {
      if (this.get('show')) {
        var transferOptionEle = document.querySelector('.call-transfer-options'),
            iconEles = document.querySelectorAll('.transfer-agent.more-actions-bg');

        if (iconEles.length) {
          var contains = Array.from(iconEles).some(function (icon) {
            return icon.contains(event.target);
          }),
              isCurrentElement = contains && (event.target.id === this.get('id') || event.target.id === this.get('spanId')),
              hasTransferOption = transferOptionEle && transferOptionEle.contains(event.target);

          if (!(isCurrentElement || hasTransferOption)) {
            this.set('show', false);
            this.set('focus', false);
          }
        }
      }
    },
    scrollListerner: function scrollListerner() {
      this.set('show', false);
      this.set('focus', false);
    },
    attachListener: function attachListener() {
      this.get('inconversationModal').addEventListener('click', this.clickListener.bind(this));
      this.get('transferContainer').addEventListener('scroll', this.scrollListerner.bind(this));
    },
    removeListerner: function removeListerner() {
      if (this.get('inconversationModal')) {
        this.get('inconversationModal').removeEventListener('click', this.clickListener.bind(this));
      }
      if (this.get('transferContainer')) {
        this.get('transferContainer').removeEventListener('scroll', this.scrollListerner.bind(this));
      }
    },
    actions: {
      onSelect: function onSelect(option) {
        if (!option.disabled) {
          this.set('show', false);
          this.set('focus', false);
          this.sendAction('optionSelected', {
            id: option.id,
            agent: this.get('agent')
          });
        }
      },
      toggle: function toggle(event) {
        var _this = this;

        later(function () {
          var modal = document.querySelector('.modal-dialog.modal-xxl');

          var _modal$getBoundingClientRect = modal.getBoundingClientRect();

          var modalTop = _modal$getBoundingClientRect.top;
          var modalLeft = _modal$getBoundingClientRect.left;

          var _event$target$getBoundingClientRect = event.target.getBoundingClientRect();

          var top = _event$target$getBoundingClientRect.top;
          var left = _event$target$getBoundingClientRect.left;

          // 160 for popover width and 30 for icon height. both are always constant
          _this.set('clientX', left - modalLeft - 175);
          _this.set('clientY', top - modalTop - 185);
          _this.set('focus', true);
        });
        this.toggleProperty('show');
      }
    }
  });
});