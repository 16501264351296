define('freshfone-ember/components/agent-component/agent-restore/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend({
    restoreInProgress: false,
    agentLimitExceededText: i18n.t('user.errors.user_limit').string,
    currentAccount: computed.alias('session.account'),
    activeUsersCount: computed.alias('currentAccount.active_users_count'),
    agentLimitText: computed('activeUsersCount', 'usersLimit', function () {
      var accountType = this.get('currentAccount.isOmnichannelEnabled') ? 'omnichannel' : 'standalone';

      return i18n.t('user.errors.trial_licence_limit.' + accountType, {
        activeUsersCount: this.get('activeUsersCount'),
        productName: this.get('currentAccount.productName'),
        deskName: this.get('currentAccount.deskName')
      });
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.closeHelper();
    },
    resetToDefault: function resetToDefault() {
      this.set('restoreInProgress', false);
      var agent = this.get('agent');

      if (isPresent(agent)) {
        agent.set('createAttempt', false);
      }
    },
    closeHelper: function closeHelper() {
      this.resetToDefault();
      if (isPresent(this.get('agent'))) {
        this.get('agent').rollbackAttributes();
      }
    },
    actions: {
      restore: function restore() {
        var _this = this;

        var agent = this.get('agent');

        if (isEmpty(agent)) {
          return;
        }

        agent.set('deleted', false);
        this.set('restoreInProgress', true);
        agent.save().then(function () {
          _this.sendAction('closeCallBack');
          _this.sendAction('onModify');
          _this.incrementProperty('activeUsersCount');
          _this.set('agent.isDeleting', false);
        })['catch'](function () {
          agent.set('deleted', true);
        })['finally'](function () {
          _this.resetToDefault();
        });
      },

      close: function close() {
        this.sendAction('closeCallBack');
      }
    }
  });
});