define("freshfone-ember/components/common/request-acknowledgement-modal/component", ["exports", "ember"], function (exports, _ember) {
    var Component = _ember["default"].Component;
    var computed = _ember["default"].computed;
    exports["default"] = Component.extend({
        acknowledgedText: computed(function () {
            if (_ember["default"].isEqual(this.get("mode"), "delete")) {
                this.set('icon', 'icon-delete-acknowledged');
                return _ember["default"].i18n.t('billing.cancel_account.cancel_acknowledgement');
            }
        }),
        actions: {
            closeModal: function closeModal() {
                this.set('cancelAccountAcknowledged', false);
            }
        }
    });
});