define('freshfone-ember/components/custom-elements/accordion/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    expanded: false,
    actions: {
      toggleAccordion: function toggleAccordion() {
        this.toggleProperty('expanded');
      }
    }
  });
});