define('freshfone-ember/constants/gauge-chart', ['exports'], function (exports) {
  var gaugeOptions = {
    chart: {
      type: 'solidgauge',
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
      width: 220,
      height: 220,
      backgroundColor: 'transparent'
    },
    title: null,
    pane: {
      size: '100%',
      center: ['50%', '55%'],
      startAngle: -82,
      endAngle: 82,
      background: {
        borderWidth: 13,
        shape: 'arc',
        borderColor: '#ECECEC',
        outerRadius: '88%',
        innerRadius: '90%'
      }
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  };

  exports.gaugeOptions = gaugeOptions;
});