define('freshfone-ember/components/outgoing-widget/recent-calls/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/message'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsMessage) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var service = _ember['default'].inject.service;
  var debounce = _ember['default'].run.debounce;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    currentUserID: computed.alias('realtime.currentUserID'),
    account: computed.alias('session.account'),
    hasVoicemailDropFeature: computed(function () {
      return this.get('account').hasFeature('voicemail_drop');
    }),

    hasPowerDailerFeature: computed(function () {
      return this.get('session.account').hasFeature('power_dialer');
    }),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    showRecentCalls: computed.equal('activeTab', 'recentCalls'),
    powerDialerService: service('power-dialer-list'),
    /*
     * showRecentCallsTab - account isn't shared or the user is standalone user
     * even if it's a shared account and doesn't have power dialer feature
     */
    showRecentCallsTab: computed('isCallBackStandaloneUserOrNotBundled', 'isCallbackCall', function () {
      return this.get('isCallBackStandaloneUserOrNotBundled') && !this.get('hasPowerDailerFeature') || this.get('isCallbackCall');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.loadRecentCalls();
      this.get('hasVoicemailDropFeature') && this.loadVoicemails();
      if (this.get('hasPowerDailerFeature')) {
        this.getPowerDialerContactList();
      }
    },

    loadVoicemails: function loadVoicemails() {
      this.store.query('message', { scope: _freshfoneEmberConstantsMessage.SCOPE_USER, paginate: false });
    },

    loadRecentCalls: function loadRecentCalls() {
      var _this = this;

      this.set('isLoading', true);
      this.store.query('call', {
        type: 'users',
        per_page: 5
      }).then(function (calls) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.set('recentCalls', calls);
        _this.set('isLoading', false);
        _this.loadFlag();
        debounce(_this, _this.bindHover, 100);
        if (_this.get('powerDialerService.startPowerDialer')) {
          _this.set('activeTab', 'powerDailer');
        }
      });
    },

    loadFlag: function loadFlag() {
      var _this2 = this;

      if (this.get('recentCalls.length') > 0) {
        (function () {
          var lastCalled = _this2.get('recentCalls.firstObject'),
              numberData = lastCalled.get('from') || lastCalled.get('caller.number'),
              number = numberData ? numberData.slice(1) : numberData;

          $.fn.intlTelInput.getCountryData().forEach(function (country) {
            if (number && number.startsWith(country.dialCode) && country.priority === 0) {
              $('#searchTerm').intlTelInput('selectCountry', country.iso2);
            }
          });
        })();
      }
    },

    bindHover: function bindHover() {
      $('.row:first').toggleClass('hover');
      $('.row').hover(function () {
        isPresent(_ember['default'].$('.row.hover')) && _ember['default'].$('.row.hover').removeClass('hover');
        _ember['default'].$(this).addClass('hover');
      }, function () {
        _ember['default'].$(this).removeClass('hover');
      });
    },

    getPowerDialerContactList: function getPowerDialerContactList() {
      var _this3 = this;

      this.store.query('power-dialer-list', {}).then(function (contacts) {
        _this3.set('powerDialerContacts', contacts.toArray());
        _this3.set('powerDialer', contacts[0]);
        _this3.set('totalContactsCount', contacts.meta['total-count']);
      });
    },

    actions: {
      dial: function dial(call, powerDialerListId) {
        var _this4 = this;

        var contactName = call.get('contact.name') || '';
        var contactPhone = call.get("from") ? call.get("from") : call.get('contact.contactNumber');

        if (!call.get('contact')) {
          this.sendAction('dial', contactName, contactPhone);

          return;
        }

        if (this.hasOmniRedirectFeatureEnabled() && call.get('contact.id')) {
          this.sendAction('dial', contactName, contactPhone, call.get('contact.id'), powerDialerListId);

          return;
        }

        call.get('contact').hasNumber(contactPhone).then(function () {
          _this4.sendAction('dial', contactName, contactPhone, call.get('contact.id'), powerDialerListId);
        })['catch'](function () {
          _this4.sendAction('dial', contactName, contactPhone);
        });
      },

      setActiveTab: function setActiveTab(activeTab) {
        this.set('activeTab', activeTab);
      },

      fetchNext: function fetchNext() {
        this.sendAction('fetchNext');
      }
    }
  });
});
/* eslint-disable camelcase */
/* eslint no-extra-parens: ["error", "all", { "nestedBinaryExpressions": false }] */