define('freshfone-ember/app', ['exports', 'ember', 'freshfone-ember/resolver', 'freshfone-ember/config/environment', 'ember-load-initializers', 'freshfone-ember/error-monitor'], function (exports, _ember, _freshfoneEmberResolver, _freshfoneEmberConfigEnvironment, _emberLoadInitializers, _freshfoneEmberErrorMonitor) {

  var App;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  if (_ember['default'].Debug && typeof _ember['default'].Debug.registerDeprecationHandler === 'function') {
    _ember['default'].Debug.registerDeprecationHandler(function (message, options, next) {
      if (_freshfoneEmberConfigEnvironment['default'].environment !== 'development') {
        return;
      }
      next(message, options);
    });
  }

  (0, _freshfoneEmberErrorMonitor.startErrorMonitor)();

  App = _ember['default'].Application.extend({
    modulePrefix: _freshfoneEmberConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _freshfoneEmberConfigEnvironment['default'].podModulePrefix,
    Resolver: _freshfoneEmberResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _freshfoneEmberConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});