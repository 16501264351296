define('freshfone-ember/helpers/formatted-duration', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.formattedDuration = formattedDuration;
  var Helper = _ember['default'].Helper;
  var moment = window.moment;

  function formattedDuration(_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var duration = _ref2[0];
    var _ref2$1 = _ref2[1];
    var format = _ref2$1 === undefined ? 'HH[h] mm[m] ss[s]' : _ref2$1;

    if (typeof duration === 'number') {
      return moment().startOf('day').add(duration * 1000)
      // eslint-disable-next-line no-useless-escape
      .format(duration < 3600 ? format.replace(/(.*)[\s:\.](.*)([\s:\.])(.*)/, '$2$3$4') : format);
    }

    return '00m 00s';
  }

  exports['default'] = Helper.helper(formattedDuration);
});