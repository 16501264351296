define('freshfone-ember/constants/mkp-config', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = (function () {
    var allowedMkpApps = ['Voicebase PCI Call Redaction App', 'PCI Pal Payments App'],
        availableDataApis = ['loggedInAgent', 'domainName', 'currentCall', 'currentCaller', 'currentNumber'],
        defaults = {
      showConfirm: {
        cancel: _ember['default'].i18n.t('common.cancel').string,
        ok: _ember['default'].i18n.t('common.ok').string,
        message: _ember['default'].i18n.t('mkp.defaults.showConfirm.message').string,
        characterLimit: {
          cancel: 11,
          ok: 11,
          message: 100
        }
      },
      showModal: {
        title: _ember['default'].i18n.t('mkp.defaults.showModal.title').string,
        characterLimit: { title: 30 }
      },
      showNotify: {
        message: _ember['default'].i18n.t('mkp.defaults.showNotify.message').string,
        characterLimit: { message: 100 }
      }
    },
        events = ['agent.statusChanged', 'call.holdToggled', 'call.muteToggled', 'call.recordingStarted', 'call.recordingPaused', 'call.recordingResumed', 'call.ended', 'call.contactEdited', 'call.linkedToAnotherContact'],
        galleryEventsApiEndpoint = '/marketplace/gallery/events/process',
        interceptTimeout = 10000,
        interceptableEvents = ['call.saveAndClose'],
        locations = {
      conversation_card: { services: ['DataAPI', 'EventAPI', 'InterfaceAPI'] },
      notification_card: { services: ['DataAPI', 'EventAPI', 'InterfaceAPI'] },
      call_metrics_card: { services: ['DataAPI', 'EventAPI', 'InterfaceAPI'] }
    },
        overlays = ['showModal'],
        priorityList = {
      notification_card: 1,
      conversation_card: 2,
      call_metrics_card: 3
    },
        product = 'freshcaller',
        showConfirmTimeout = 10000;

    return {
      availableDataApis: availableDataApis,
      defaults: defaults,
      events: events,
      interceptableEvents: interceptableEvents,
      interceptTimeout: interceptTimeout,
      locations: locations,
      overlays: overlays,
      product: product,
      priorityList: priorityList,
      showConfirmTimeout: showConfirmTimeout,
      galleryEventsApiEndpoint: galleryEventsApiEndpoint,
      allowedMkpApps: allowedMkpApps
    };
  })();
});
/* eslint-disable camelcase */
/* eslint-disable ember-suave/no-direct-property-access */