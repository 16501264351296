define('freshfone-ember/adapters/agent', ['exports', 'ember', 'freshfone-ember/constants/live-agents-dashboard', 'freshfone-ember/mixins/adapters/multi-word-underscore'], function (exports, _ember, _freshfoneEmberConstantsLiveAgentsDashboard, _freshfoneEmberMixinsAdaptersMultiWordUnderscore) {
  var isPresent = _ember['default'].isPresent;
  var liveAgentsListEnhancedUrl = '/agents/status_list?version=v2';exports['default'] = _freshfoneEmberMixinsAdaptersMultiWordUnderscore['default'].extend({
    handleResponse: function handleResponse(status, headers, payload) {
      if ((status === 422 || status === 417) && payload.errors) {
        return new DS.InvalidError(payload.errors);
      }

      return this._super.apply(this, arguments);
    },

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);

      if (isPresent(query) && query.type === _freshfoneEmberConstantsLiveAgentsDashboard['default'].queryType) {
        delete query.type;

        return liveAgentsListEnhancedUrl;
      }

      return url.concat('?version=v1');
    }
  });
});