define('freshfone-ember/components/agent-status-component/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/constants/default-agent-statuses'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberConstantsDefaultAgentStatuses) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    telephony: service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.bindNodeEvents();
    },
    bindNodeEvents: function bindNodeEvents() {
      this.get('telephony').on('custom_status_updated', this._loadModel.bind(this));
    },

    hasEmptyList: computed.empty('customAgentStatuses'),
    customAgentStatuses: computed('model', function () {
      var disabledStatusArray = [],
          enabledStatusArray = [];

      this.get('model').toArray().sort(function (statusA, statusB) {
        return _ember['default'].get(statusA, 'id') - _ember['default'].get(statusB, 'id');
      }).forEach(function (agentStatus) {
        if (!_ember['default'].get(agentStatus, 'default')) {
          agentStatus && _ember['default'].get(agentStatus, 'enabled') ? enabledStatusArray.pushObject(agentStatus) : disabledStatusArray.pushObject(agentStatus);
        }
      });

      return [].concat(enabledStatusArray, disabledStatusArray);
    }),
    defaultAgentStatuses: computed(function () {
      if (!this.checkFeature('forward_to_phone')) {
        _freshfoneEmberConstantsDefaultAgentStatuses.agentStatuses.splice(3, 1);
      }

      return _freshfoneEmberConstantsDefaultAgentStatuses.agentStatuses;
    }),
    defaultAgentStatusDescription: _ember['default'].i18n.t('custom_agent_status.default_agent_status_description'),
    _loadModel: function _loadModel() {
      var currentAgentStatusModel = this.store.peekAll('agent-status').filter(function (status) {
        return status.get('id');
      });

      this.set('model', currentAgentStatusModel);
      this.set('listLoading', false);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony').off('custom_status_updated');
    },

    actions: {
      onModify: function onModify() {
        this._loadModel();
      },
      deleteStatus: function deleteStatus(status) {
        $('.text-cancel').trigger('click');
        status.toggleProperty('isDeleting');
      },
      afterDelete: function afterDelete(item) {
        if (item.id === this.get('agentStatus.id')) {
          this.set('agentStatus', null);
        }
      },
      edit: function edit(record) {
        if (this.get('agentStatus')) {
          this.get('agentStatus').rollbackAttributes();
        }

        if (this.get('accountConfiguration')) {
          this.get('accountConfiguration').rollbackAttributes();
        }

        this.set('agentStatus', record);
        this.set('slideToggle', true);
        this.set('formMode', true);
        this.set('isACWEdit', false);
      },

      editACW: function editACW(accountConfiguration) {

        this.set('isACWEdit', true);

        if (this.get('agentStatus')) {
          this.get('agentStatus').rollbackAttributes();
        }
        if (!accountConfiguration) {
          accountConfiguration = this.store.peekAll('account-configuration').get('firstObject');
        }

        this.set('accountConfiguration', accountConfiguration);
        this.set('slideToggle', true);
        this.set('formMode', true);
      },

      openForm: function openForm() {
        this.set('slideToggle', true);
        this.set('formMode', true);
      },
      toggleAgentStatusCallBack: function toggleAgentStatusCallBack(status) {
        status.rollbackModelAttribute('name');
        status.rollbackModelAttribute('emoji');
        status.save();
      }
    }
  });
});