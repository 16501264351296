define('freshfone-ember/components/ongoing-widget/merge-call/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      mergeCall: function mergeCall() {
        this.set('dropdownExpanded', false);
        this.sendAction('mergeCall');
      }
    }
  });
});