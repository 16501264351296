define('freshfone-ember/components/agent-status-component/agent-status-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'ember-component-inbound-actions/inbound-actions', 'freshfone-ember/mixins/model-form-mixin'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _emberComponentInboundActionsInboundActions, _freshfoneEmberMixinsModelFormMixin) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _emberValidations['default'], _emberComponentInboundActionsInboundActions['default'], {
    classNames: ['agent-status-form'],
    agentStatus: computed({
      set: function set(key, value) {
        this.set('startValidate', false);

        if (value && value.get('id')) {
          this.set('editMode', true);
          if (this.get('agentStatusSidebar') && this.get('agentStatusSidebar.addMode')) {
            this.set('agentStatusSidebar.addMode', false);
          }
          this.set('slideToggle', true);

          return value;
        }

        if (this.get('agentStatusSidebar')) {
          this.set('agentStatusSidebar.addMode', true);
        }

        return this.store.createRecord('agent-status');
      },
      get: function get() {}
    }),

    accountConfiguration: computed({
      set: function set(key, value) {

        if (value && value.get('id')) {
          this.set('editMode', true);
          if (this.get('agentStatusSidebar') && this.get('agentStatusSidebar.addMode')) {
            this.set('agentStatusSidebar.addMode', false);
          }
          this.set('slideToggle', true);

          return value;
        }
      },
      get: function get() {}
    }),

    isDirty: computed('agentStatus.hasDirtyAttributes', 'accountConfiguration.hasDirtyAttributes', {
      get: function get() {
        return this.get('agentStatus.hasDirtyAttributes') && !this.get('isACWEdit') || this.get('accountConfiguration.hasDirtyAttributes');
      }
    }).readOnly(),
    showSaveButton: computed('isDirty', 'agentStatus.isDeleted', 'accountConfiguration.isDeleted', function () {
      return this.get('isDirty') && (!this.get('agentStatus.isDeleted') || !this.get('accountConfiguration.isDeleted'));
    }),
    submitLabel: computed('agentStatus.isNew', 'agentStatus.errors.name', 'accountConfiguration.isNew', function () {
      return this.get('agentStatus.isSaving') || this.get('accountConfiguration.isSaving') ? _ember['default'].i18n.t('custom_agent_status.form.saving') : this.get('agentStatus.isNew') && !this.get('isACWEdit') ? _ember['default'].i18n.t('custom_agent_status.form.add') : _ember['default'].i18n.t('custom_agent_status.form.save');
    }),
    statuses: [],

    actions: {
      toggleNewStatusForm: function toggleNewStatusForm() {
        this.get('agentStatus') && this.get('agentStatus').rollbackAttributes();
        this.get('accountConfiguration') && this.get('accountConfiguration').rollbackAttributes();
        this.set('agentStatus', null);
        this.set('accountConfiguration', null);
        this.set('isACWEdit', false);
        this.toggleProperty('slideToggle');
      },

      setStatusSidebarReference: function setStatusSidebarReference(childReference) {
        this.set('agentStatusSidebar', childReference);
      },

      save: function save() {
        var _this = this;

        this.validate().then(function () {
          _this.save('agent-status', _this.get('agentStatus')).then(function () {
            if (_this.get('agentStatusSidebar') && _this.get('agentStatusSidebar.addMode')) {
              !_this.get('agentStatus.errors')[0] && _this.set('agentStatusSidebar.addMode', false);
            }
            _this.sendAction('onModify');

            if (!_this.get('agentStatus.errors')[0]) {
              _this.send('toggleNewStatusForm');
            }
          });
        })['catch'](function (error) {
          _this.set('startValidate', true);
        });
      },

      saveConfiguration: function saveConfiguration() {
        var _this2 = this;

        this.get('accountConfiguration').validate().then(function () {
          _this2.save('account-configuration', _this2.get('accountConfiguration')).then(function () {
            if (_this2.get('agentStatusSidebar') && _this2.get('agentStatusSidebar.addMode')) {
              !_this2.get('agentStatus.errors')[0] && _this2.set('agentStatusSidebar.addMode', false);
            }
            _this2.send('toggleNewStatusForm');
          });
        })['catch'](function () {
          _this2.set('startValidate', true);
        });
      }
    },

    validations: {
      'agentStatus.name': { presence: { message: _ember['default'].i18n.t('activemodel.errors.not_empty') } }
    }
  });
});