define("freshfone-ember/helpers/child-call-queue-position", ["exports", "ember"], function (exports, _ember) {
    exports.chilQueuePlacement = chilQueuePlacement;

    var pixels = {
        "queue-full-action-branch": 140,
        "time-threshold-action-branch": 380,
        "callback-fail-action-branch": 580
    };

    function chilQueuePlacement(action, routingType, queuePositionEnabled) {
        if (pixels.hasOwnProperty(action)) {
            var pixel = pixels[action];
            if (action != "queue-full-action-branch" && queuePositionEnabled) {
                pixel = pixel + 68;
            }
            if (routingType) {
                return pixel;
            } else {
                return pixel - 80;
            }
        } else {
            return false;
        }
    }
});