define('freshfone-ember/models/survey-question-option', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    option: attr(),
    otherOption: attr(),
    surveyRoutings: hasMany('survey-routing'),
    surveyQuestion: belongsTo('survey-question')
  });
});