define('freshfone-ember/components/live-calls/slm-container/slm-queue-row/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ["list-item-container"],
    actions: {
      loadDetail: function loadDetail() {
        this.sendAction('loadLeftPane', this.get('metric'), this.get('global'));
      }
    }
  });
});