define('freshfone-ember/services/browser-service', ['exports', 'ember', 'freshfone-ember/constants/permission'], function (exports, _ember, _freshfoneEmberConstantsPermission) {
  var Service = _ember['default'].Service;
  var run = _ember['default'].run;
  var testing = _ember['default'].testing;
  exports['default'] = Service.extend({
    isMicrophonePermissionDenied: false,
    isMicrophonePermissionPrompted: false,

    intializeMicrophonePermissions: function intializeMicrophonePermissions() {
      var _this = this;

      navigator.permissions.query({ name: 'microphone' }).then(function (permissionStatus) {
        _this.updateMicrophonePermissionStatus(permissionStatus.state);

        permissionStatus.onchange = function () {
          _this.updateMicrophonePermissionStatus(permissionStatus.state);
        };
      });
    },
    // util method to prompts the user for microphone permission
    getMicrophonePermission: function getMicrophonePermission() {
      return new Promise(function (resolve, reject) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {
          localStorage.setItem('isMicrophoneAllowed', 'allowed');
          resolve(stream);
        })['catch'](function (err) {
          localStorage.setItem('isMicrophoneAllowed', 'blocked');
          reject(err);
        });
      });
    },
    checkIfMicrophoneAllowed: function checkIfMicrophoneAllowed() {
      return localStorage.getItem('isMicrophoneAllowed');
    },

    updateMicrophonePermissionStatus: function updateMicrophonePermissionStatus(status) {
      var _this2 = this;

      var _PermissionConstants$microphonePermisssionStatuses = _freshfoneEmberConstantsPermission['default'].microphonePermisssionStatuses;
      var DENIED = _PermissionConstants$microphonePermisssionStatuses.DENIED;
      var GRANTED = _PermissionConstants$microphonePermisssionStatuses.GRANTED;
      var PROMPT = _PermissionConstants$microphonePermisssionStatuses.PROMPT;

      var permissionStatus = testing ? localStorage.getItem('micPermStatus') || GRANTED : status;

      run(function () {
        switch (permissionStatus) {
          case DENIED:
            _this2.set('isMicrophonePermissionDenied', true);
            _this2.set('isMicrophonePermissionPrompted', false);
            break;
          case GRANTED:
            _this2.set('isMicrophonePermissionDenied', false);
            _this2.set('isMicrophonePermissionPrompted', false);
            break;
          case PROMPT:
            _this2.set('isMicrophonePermissionDenied', false);
            _this2.set('isMicrophonePermissionPrompted', true);
            break;
        }
      });
    }

  });
});