define('freshfone-ember/routes/protected/admin/businesscalendar', ['exports', 'ember', 'freshfone-ember/routes/protected', 'ember-authority/helpers/has-privilege'], function (exports, _ember, _freshfoneEmberRoutesProtected, _emberAuthorityHelpersHasPrivilege) {
  var Route = _ember['default'].Route;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var i18n = _ember['default'].i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('business_calendar.title').string };
    }),
    titleToken: computed(function () {
      return _ember['default'].i18n.t('business_calendar.title');
    }),
    queryParams: {
      businessHour: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_business_hours');
    },
    model: function model(params) {
      this.set("businessHour", params.businessHour);
      return this.store.query('businessCalendar', {});
    },
    afterModel: function afterModel(params) {
      this.set("businessHour", null);
    }
  });
});