define('freshfone-ember/models/team', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    name: attr(),
    totalUsers: attr(),
    isTeam: computed.match('id', /^[+][0-9]{1,15}|[0-9]{1,15}/),
    users: hasMany('user', { async: true }),
    omniChannel: attr(),
    teamSetting: belongsTo('teamSetting', { async: true })
  });
});