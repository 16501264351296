define('freshfone-ember/adapters/external-contact', ['exports', 'freshfone-ember/adapters/application', 'ember'], function (exports, _freshfoneEmberAdaptersApplication, _ember) {
  var isPresent = _ember['default'].isPresent;
  exports['default'] = _freshfoneEmberAdaptersApplication['default'].extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (isPresent(query) && isPresent(query['filter[id]'])) {
        return '/contacts/mcr';
      } else if (this.isFindRequest(requestType)) {
        return '/contacts/search';
      } else {
        return this._super(modelName, id, snapshot, requestType, query);
      }
    }
  });
});