define('freshfone-ember/components/agent-status-widget/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.bindNodeEvents();
      run(function () {
        return _this.store.findAll('agent-status');
      });
    },
    bindNodeEvents: function bindNodeEvents() {
      var _this2 = this;

      this.get('telephony').on('loaded', function () {
        _this2.set('currentAgent.showLoader', false);
      });
    },
    defaultStatusSubTypes: {
      online: { icon: 'icon-browser' },
      online_phone: { icon: 'icon-on-mobile' },
      offline: { icon: 'icon-offline' }
    },
    enabledAgentStatus: [],
    session: service(),
    telephony: service(),
    notification: service('toastr'),
    loading: _ember['default'].i18n.t('common.loading'),
    notification: service('toastr'),
    currentAccount: computed.alias('session.account'),
    currentUser: computed.alias('session.user'),
    currentAgent: computed.alias('currentUser.agent'),
    agentBusy: computed.equal('currentAgent.browserStatus.statusType', 'busy'),
    disableAgentStatusToggle: computed.or('agentBusy', 'agentInPowerDialerMode'),
    agentInPowerDialerMode: computed.alias('currentAgent.powerDialer'),
    isACWActive: computed.equal('currentAgent.browserStatus.statusType', 'acw'),
    agentBusyOrAcw: computed.or('agentBusy', 'isACWActive'),
    customStatusOptions: computed.alias('enabledAgentStatus'),
    currentStatus: computed('currentAgent.browserStatus.id', 'currentAgent.browserStatus.name', 'currentAgent.showLoader', function () {
      var status = this.get('currentAgent.browserStatus');

      if (status) {
        if (status.get('default')) {
          status.set('icon', this.get('defaultStatusSubTypes')[status.get('statusSubType')].icon);
        }

        return !status.get('statusSubType') ? this.set('currentAgent.showLoader', true) : status;
      }
    }),

    isCustomAgentStatus: function isCustomAgentStatus(status) {
      return typeof status === 'object';
    },

    selectedSameStatus: function selectedSameStatus(status) {

      return this.get('currentAgent.browserStatus.id') === status.get('id');
    },

    isValidStatus: function isValidStatus(status) {

      return status.get('statusSubType') !== 'online_phone';
    },

    setAgentStatus: function setAgentStatus(status) {
      var _this3 = this;

      return new Promise(function (resolve) {

        _this3.get('telephony.agent').setStatus(status).then(function () {
          if (!status.get('default') && status.get('statusSubType') === 'offline') {
            _this3.get('notification').warning(_ember['default'].i18n.t('custom_agent_status.toaster_message'));
          }
          resolve();
        });
      });
    },

    isValidPersonalNumber: function isValidPersonalNumber(status) {
      var _this4 = this;

      if (!this.get('currentUser.mobile')) {
        this.isValidWorkNumber(status);
      } else {
        this.get('telephony.util').isValidNumber(this.get('currentUser.mobile')).then(function () {
          return run(function () {
            return _this4.setAgentStatus(status);
          });
        })['catch'](function () {
          return _this4.isValidWorkNumber(status);
        });
      }
    },

    isValidWorkNumber: function isValidWorkNumber(status) {
      var _this5 = this;

      if (!this.get('currentUser.phone')) {
        this.showPopupBox();
      } else {
        this.get('telephony.util').isValidNumber(this.get('currentUser.phone')).then(function () {
          return run(function () {
            _this5.set('currentAgent.showLoader', true);
            _this5.setAgentStatus(status);
          });
        })['catch'](function () {
          return _this5.showPopupBox();
        });
      }
    },

    showPopupBox: function showPopupBox() {
      this.send('toggleSettings', true);
      this.set('currentUser.invalidNumber', true);
      this.set('currentAgent.status', 'online');
    },

    validateAndSetStatus: function validateAndSetStatus(status) {
      var _this6 = this;

      if (this.isValidStatus(status)) {
        return run(function () {
          var currentAgentStatus = _this6.get('currentAgent.browserStatus');

          _this6.set('currentAgent.showLoader', true);
          _this6.setAgentStatus(status)['catch'](function () {
            _this6.set('currentAgent.browserStatus', currentAgentStatus);
          });
        });
      }

      if (!this.get('currentUser.hasContactNumber')) {
        return this.send('toggleSettings', true);
      }

      this.isValidPersonalNumber(status);
    },

    loadEnabledAgentStatuses: function loadEnabledAgentStatuses() {
      var _this7 = this;

      var customStatus = [],
          defaultStatus = [],
          defaultStatusSubTypeKeys = Object.keys(this.get('defaultStatusSubTypes'));

      if (!this.callForwardingEnabled() || !this.checkFeature('forward_to_phone')) {
        defaultStatusSubTypeKeys.removeObject('online_phone');
      }

      this.store.peekAll('agent-status').filterBy('isNew', false).sort(function (statusA, statusB) {
        return statusA.get('id') - statusB.get('id');
      }).map(function (status) {
        if (status.get('default')) {
          if (defaultStatusSubTypeKeys.includes(status.get('statusSubType'))) {
            status.set('icon', _this7.get('defaultStatusSubTypes')[status.get('statusSubType')].icon);
            defaultStatus.pushObject(status);
          }
        } else {
          status.get('enabled') && customStatus.pushObject(status);
        }
      });

      this.set('enabledAgentStatus', [].concat(defaultStatus, customStatus));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony').off('loaded');
    },

    actions: {
      // The function setStatus will be moved to SDK in the future when browserStatusId is sent to SDK from backend.
      setStatus: function setStatus(status) {
        if (this.get('currentAccount').get('callingEnabled') && (this.get('isACWActive') || !this.selectedSameStatus(status))) {
          this.validateAndSetStatus(status);
        }
      },

      afterUserSave: function afterUserSave() {
        var statuses = this.store.peekAll('agent-status'),
            forwardToPhone = statuses.findBy('statusSubType', 'online_phone');

        this.send('setStatus', forwardToPhone);
      },

      toggleSettings: function toggleSettings(focusPhoneNumber) {
        this.toggleProperty('showProfileSettings');
        if (this.get('showProfileSettings')) {
          this.set('focusPhoneNumber', focusPhoneNumber);
        }
      },

      busyResolve: function busyResolve() {
        this.set('isAgentStatusLoading', true);
        this.get('telephony.agent').resolveBusy();
        this.loadEnabledAgentStatuses();
      }
    }
  });
});