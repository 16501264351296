define('freshfone-ember/components/ongoing-widget/maximized-widget/other-contacts/component', ['exports', 'ember', 'freshfone-ember/mixins/click-outside-listener'], function (exports, _ember, _freshfoneEmberMixinsClickOutsideListener) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], _ember['default'].Evented, {
    init: function init() {
      this._super.apply(this, arguments);
      this.bindOutsideClick();
    },
    onOutsideClick: function onOutsideClick() {
      this.send('outslideClose');
    },
    displayContacts: computed('contact', 'pickedContact', 'otherContactsIds', function () {
      var contactId = this.get('contact.id'),
          pickedContactId = this.get('pickedContact.id'),
          otherContactsIds = this.get('otherContactsIds');
      return this.get('store').peekAll('contact').filter(function (contact) {
        return otherContactsIds.contains(contact.id) && contact.id !== contactId && contact.id !== pickedContactId;
      });
    }),
    actions: {
      showNumConversation: function showNumConversation(contact) {
        this.sendAction('showNumConversation', contact);
      },
      outslideClose: function outslideClose() {
        this.sendAction('closeRecentContacts');
        this.unbindOutsideClick();
      }
    }

  });
});