define('freshfone-ember/components/ivr-component/ivr-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/utils/ivr-constants', 'freshfone-ember/helpers/message-default-types', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/remove-feature-actiontype'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberUtilsIvrConstants, _freshfoneEmberHelpersMessageDefaultTypes, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsRemoveFeatureActiontype) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var set = _ember['default'].set;
  var service = _ember['default'].inject.service;
  var Component = _ember['default'].Component;
  var Evented = _ember['default'].Evented;
  exports['default'] = Component.extend(_emberValidations['default'], Evented, _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], {
    classNames: ['ivr-form'],
    callFlowService: service('call-flow'),
    retryEmpty: computed.empty('ivr.retries'),
    emptyIvrName: computed.empty('ivr.name'),
    isVoiceIVR: computed.alias('ivr.isVoiceIVR'),
    ivrCount: computed.alias('callFlowService.ivrCount'),
    i18n: service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.createDefaultActions();
      this.set('level', $('.ivr-component').length);

      if (!this.hasFeatures(['agent_extension']) || !this.hasGlobalFeature('agent_extension_fallback') || this.get('ivr.ivrType') === _freshfoneEmberUtilsIvrConstants.IVR_TYPES.speechEnabledIVR) {
        this.removeAgentExtensionFallBackOption();
      }
      this.decideOnShowingAgentExtensionAsFallback();
    },

    decideOnShowingAgentExtensionAsFallback: function decideOnShowingAgentExtensionAsFallback() {
      var _this = this;

      this.store.findAll('agent-extension').then(function (agentExtensions) {
        _this.set('agentExtensionList', agentExtensions);
        if (agentExtensions.content.length < 1) {
          _this.removeAgentExtensionFallBackOption();
        }
      });
    },

    removeAgentExtensionFallBackOption: function removeAgentExtensionFallBackOption() {
      var defaultActionTypes = this.get('ivr.actionOptions'),
          filteredActionTypes = defaultActionTypes.findBy('name', 'agentExtension') ? this.removeObjFromArray(defaultActionTypes, 'name', 'agentExtension') : defaultActionTypes;

      this.set('ivr.actionOptions', filteredActionTypes);
    },
    didInsertElement: function didInsertElement() {
      if (this.get('emptyIvrName')) {
        if (this.get('hasSpeechEnabledIvrFeature') && this.get('isVoiceIVR')) {
          this.set('ivr.name', this.get('i18n').t('entityNames.speech_input_ivr') + ' ' + (this.get('ivrCount') + 1));
        } else {
          this.set('ivr.name', this.get('i18n').t('entityNames.ivr') + ' ' + (this.get('ivrCount') + 1));
        }
      }
    },
    ivr: computed({
      set: function set(k, v) {
        if (v) {
          if (v.content && v.content.id || v.id) {
            (v.content || v).saveOriginalRelations();
          }
        }

        return v.content || v;
      },
      get: function get() {
        return this.get("modal.ivr");
      }
    }),
    createNewRecord: function createNewRecord(isDefaultKeypress) {
      var ivr = this.get("ivr");
      var params = { ivr: ivr };
      if (ivr.get("isKeyPressIVR") || ivr.get("isSpeechEnabledIvr")) {
        var nextOptionKey = this.nextOptionKey();
        params = { ivr: ivr, optionKey: nextOptionKey };
        if (!ivr.get('isNew') && nextOptionKey) {
          params.id = ivr.get('id') + '_' + nextOptionKey;
        }
        if (isDefaultKeypress) {
          params["actionType"] = this.getDefaultKey();
          if (ivr.get("isSpeechEnabledIvr")) {
            params["actionId"] = this.getDefaultActionId();
          }
        }
      }
      this.store.createRecord('ivr-option', params);
      //close  only the first open child nodes in deep levels
      this.$().children().find('.closeChild').first().trigger('click');
    },
    getDefaultKey: function getDefaultKey() {
      if (this.hasFeatures(['voicemail_and_status_fallback'])) {
        return 'vm';
      }
      return 'hangup';
    },

    getDefaultActionId: function getDefaultActionId() {
      if (this.get('ivr.ivrOptions')) {
        return this.get('ivr.ivrOptions').get('firstObject').get('actionId');
      }
    },

    createFallbackOption: function createFallbackOption() {
      var ivr = this.get("ivr");
      var params = { ivr: ivr, optionKey: 'fallback', actionType: 'hangup' };
      this.store.createRecord('ivr-option', params);
    },
    createBotFallbackOption: function createBotFallbackOption() {
      var ivr = this.get("ivr");
      var params = { ivr: ivr, optionKey: 'bot-fallback', actionType: 'hangup' };
      this.store.createRecord('ivr-option', params);
    },
    createDefaultActions: function createDefaultActions() {
      var _this2 = this;

      var ivrId = this.get('ivr.id');
      this.get("ivr.ivrOptions").then(function (options) {
        if (isEmpty(options) && isEmpty(ivrId)) {
          if (!_this2.get("ivr.isSpeechEnabledIvr")) {
            _this2.createNewRecord(true);
          }
          _this2.createFallbackOption();
          _this2.createBotFallbackOption();
          _this2.get("store").query('message', { defaultMessageType: [_freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage, _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage] }).then(function () {
            _this2.setDefaultMsg();
          });
        }
      });
    },

    setDefaultMsg: function setDefaultMsg() {
      var messages = this.store.peekAll('message');
      var hangup = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage)[0];
      var voicemail = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage)[0];
      var welcome = messages.filterBy('defaultMessageType', _freshfoneEmberHelpersMessageDefaultTypes['default'].welcomeMessage)[0];
      this.set('ivr.message', welcome);
      this.get('ivr.ivrOptions').get('firstObject').set('actionId', voicemail.get('id'));
      this.set('ivr.fallbackOption.actionId', hangup.get('id'));
      this.set('ivr.botRetryMessage', welcome);
      this.set('ivr.botFallbackOption.actionId', hangup.get('id'));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      //Clean up
      // rollback unsaved changes
    },

    fallbackAction: computed('ivr.fallbackOption', {
      get: function get() {
        var value = this.get('ivr.fallbackOption.actionType');
        return this.get('ivr.actionOptions').findBy('name', value);
      },
      set: function set(k, v) {
        var _get$filterBy = this.get('ivr.ivrOptions').filterBy('optionKey', 'fallback');

        var _get$filterBy2 = _slicedToArray(_get$filterBy, 1);

        var option = _get$filterBy2[0];

        option.set('actionType', v.name);

        return v;
      }
    }),

    botFallbackAction: computed('ivr.botFallbackOption', {
      get: function get() {
        var value = this.get('ivr.botFallbackOption.actionType');
        return this.get('ivr.actionOptions').findBy('name', value);
      },
      set: function set(k, v) {
        var option = this.get("ivr.ivrOptions").filterBy('optionKey', 'bot-fallback')[0];
        option.set('actionType', v.name);
        return v;
      }
    }),

    hasSpeechEnabledIvrFeature: computed(function () {
      return this.checkFeature('speech_enabled_ivr');
    }),

    nextOptionKey: function nextOptionKey() {
      var unselected = this.get("ivr.keyOptions").filterBy('disabled', false);
      if (isEmpty(unselected)) {
        return;
      }
      set(unselected[0], 'disabled', true);
      return unselected[0].name;
    },

    fallbackActionOptions: computed('ivr.actionOptions.[]', 'optionObj.optionKey', function () {
      var actions = this.get("ivr.actionOptions");
      return actions.reject(function (action) {
        return !["vm", "hangup", "callQueue", "agentExtension", "routingAutomation"].includes(action.name);
      });
    }),

    actions: {
      showNewMessage: function showNewMessage(attribute, isActiveChild) {
        //TODO: refactor
        var defaultMsjTitle = this.store.peekAll('message').content.length + 1;
        var newObject = this.store.createRecord('message', { name: _ember['default'].i18n.t('message_components.record_component.message') + ' ' + defaultMsjTitle });
        this.set('newMessage', newObject);
        this.set('showMessage', true);
      },
      addNewKeyPress: function addNewKeyPress(isDefaultKeypress) {
        this.createNewRecord(isDefaultKeypress);
      },
      removeKeypress: function removeKeypress(keypress) {
        keypress.deleteRecord();
        keypress.destroy();
        keypress.unloadRecord();
      },
      closeMessage: function closeMessage() {
        var newObject = this.get("newMessage");
        if (newObject.get("isNew")) {
          return newObject.deleteRecord();
        }
        this.get('ivr').set('message', newObject);
      },
      clearQueueContent: function clearQueueContent(changedValue) {
        this.$('.ivr-wrapper').children().find('.closeChild').first().trigger('click');

        if (changedValue.id !== 1 && changedValue.id !== 7) {
          this.set('ivr.fallbackOption.actionQueue.content', null);
          this.set('ivr.fallbackOption.actionIvr.content', null);
          var defaultId = changedValue.name === 'vm' ? _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage : _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage;
          var messages = this.store.peekAll('message');
          var message = messages.filterBy('defaultMessageType', defaultId)[0];
          this.set('ivr.fallbackOption.actionId', message.get('id'));
        } else {
          this.set('ivr.fallbackOption.actionId', null);
        }
      },
      clearBotQueueContent: function clearBotQueueContent(changedValue) {
        this.$('.ivr-wrapper').children().find('.closeChild').first().trigger('click');
        if (changedValue.id !== 1) {
          this.set('ivr.botFallbackOption.actionQueue.content', null);
          this.set('ivr.botFallbackOption.actionIvr.content', null);
          var defaultId = changedValue.name === 'vm' ? _freshfoneEmberHelpersMessageDefaultTypes['default'].voicemailMessage : _freshfoneEmberHelpersMessageDefaultTypes['default'].hangupMessage;
          var messages = this.store.peekAll('message');
          var message = messages.filterBy('defaultMessageType', defaultId)[0];
          this.set('ivr.botFallbackOption.actionId', message.get('id'));
        } else {
          this.set('ivr.botFallbackOption.actionId', null);
        }
      }
    }
  });
});