define('freshfone-ember/components/number-component/call-mask-component/caller-list/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    noCallerIds: _ember['default'].i18n.t('caller_mask.no_caller_ids'),

    actions: {
      close: function close() {
        this.$('.text-cancel').trigger('click');
        this.sendAction("closeCallBack");
      },
      cancel: function cancel() {
        this.sendAction("closeCallBack");
      },
      toggleList: function toggleList() {
        this.sendAction('addCallerIds');
      },
      toggleDelete: function toggleDelete(item) {
        this.$('.text-cancel').trigger('click');
        item.set('isDeleting', true);
      }
    }

  });
});