define("freshfone-ember/components/account-settings/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 6
            },
            "end": {
              "line": 12,
              "column": 6
            }
          },
          "moduleName": "freshfone-ember/components/account-settings/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "account-settings/customise-wq", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [9, 19], [9, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "accountConfiguration", ["subexpr", "@mut", [["get", "accountConfiguration", ["loc", [null, [10, 31], [10, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 8], [11, 10]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 14
                },
                "end": {
                  "line": 63,
                  "column": 14
                }
              },
              "moduleName": "freshfone-ember/components/account-settings/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "error-info");
              dom.setAttribute(el1, "data-testid", "auto-answer-time-error");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "get", [["get", "autoAnswerThresholdErrors", ["loc", [null, [61, 24], [61, 49]]], 0, 0, 0, 0], "firstObject"], [], ["loc", [null, [61, 18], [61, 65]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 10
              },
              "end": {
                "line": 65,
                "column": 10
              }
            },
            "moduleName": "freshfone-ember/components/account-settings/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "configuration-item-content");
            dom.setAttribute(el1, "data-testid", "auto-answer-time");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h5");
            dom.setAttribute(el2, "class", "config-title");
            dom.setAttribute(el2, "data-testid", "auto-answer-time-title");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "config-description");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "auto-answer-time-seperator");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "auto-answer-time-unit");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [5]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
            morphs[2] = dom.createAttrMorph(element4, 'class');
            morphs[3] = dom.createMorphAt(element4, 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
            morphs[5] = dom.createMorphAt(element3, 7, 7);
            return morphs;
          },
          statements: [["inline", "t", ["account_settings.auto_answer_time_heading"], [], ["loc", [null, [44, 76], [44, 125]]], 0, 0], ["inline", "t", ["account_settings.auto_answer_time_desc"], [], ["loc", [null, [45, 46], [45, 92]]], 0, 0], ["attribute", "class", ["concat", ["auto-answer-time-input-group ", ["subexpr", "if", [["get", "autoAnswerThresholdErrors", ["loc", [null, [46, 60], [46, 85]]], 0, 0, 0, 0], "has-error"], [], ["loc", [null, [46, 55], [46, 99]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input", [], ["type", "text", "placeholder", ["subexpr", "t", ["account_settings.auto_answer_time.placeholder"], [], ["loc", [null, [49, 30], [49, 81]]], 0, 0], "class", "auto-answer-time-input", "disabled", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [51, 27], [51, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "data-testid", "auto-answer-time-input", "value", ["subexpr", "mut", [["get", "autoAnswerThreshold", ["loc", [null, [53, 29], [53, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 24], [53, 49]]], 0, 0], "required", true], ["loc", [null, [47, 16], [55, 20]]], 0, 0], ["inline", "t", ["common.seconds"], [], ["loc", [null, [57, 52], [57, 74]]], 0, 0], ["block", "if", [["get", "autoAnswerThresholdErrors", ["loc", [null, [59, 20], [59, 45]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [59, 14], [63, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 6
            },
            "end": {
              "line": 67,
              "column": 6
            }
          },
          "moduleName": "freshfone-ember/components/account-settings/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "configuration-list-item auto-answer");
          dom.setAttribute(el1, "data-testid", "auto-answer");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "configuration-item-header");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "details");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h5");
          dom.setAttribute(el4, "class", "config-title");
          dom.setAttribute(el4, "data-testid", "auto-answer-title");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "config-description");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "toggle");
          dom.setAttribute(el3, "data-testid", "auto-answer-toggle");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
          morphs[3] = dom.createMorphAt(element5, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["account_settings.auto_answer_heading"], [], ["loc", [null, [32, 71], [32, 115]]], 0, 0], ["inline", "t", ["account_settings.auto_answer_desc"], [], ["loc", [null, [33, 46], [33, 87]]], 0, 0], ["inline", "custom-elements/ui-toggle", [], ["checked", ["subexpr", "@mut", [["get", "autoAnswerEnabled", ["loc", [null, [37, 24], [37, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [38, 25], [38, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 14], [39, 16]]], 0, 0], ["block", "if", [["get", "autoAnswerEnabled", ["loc", [null, [42, 16], [42, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [42, 10], [65, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 72,
                "column": 10
              },
              "end": {
                "line": 74,
                "column": 10
              }
            },
            "moduleName": "freshfone-ember/components/account-settings/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["common.saving"], [], ["loc", [null, [73, 12], [73, 33]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 10
              },
              "end": {
                "line": 76,
                "column": 10
              }
            },
            "moduleName": "freshfone-ember/components/account-settings/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["common.save_changes"], [], ["loc", [null, [75, 12], [75, 39]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 4
            },
            "end": {
              "line": 82,
              "column": 4
            }
          },
          "moduleName": "freshfone-ember/components/account-settings/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "footer-section");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "data-testid", "save-account-settings");
          dom.setAttribute(el2, "class", "btn btn-primary save-settings");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "data-testid", "cancel-account-settings");
          dom.setAttribute(el2, "class", "btn btn-cancel");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element1, 'disabled');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 1, 1);
          morphs[3] = dom.createAttrMorph(element2, 'disabled');
          morphs[4] = dom.createElementMorph(element2);
          morphs[5] = dom.createMorphAt(element2, 1, 1);
          return morphs;
        },
        statements: [["attribute", "disabled", ["get", "disableFormActions", ["loc", [null, [71, 101], [71, 119]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["save"], [], ["loc", [null, [71, 122], [71, 139]]], 0, 0], ["block", "if", [["get", "isSaving", ["loc", [null, [72, 16], [72, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [72, 10], [76, 17]]]], ["attribute", "disabled", ["get", "disableFormActions", ["loc", [null, [78, 88], [78, 106]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["cancel"], [], ["loc", [null, [78, 109], [78, 128]]], 0, 0], ["inline", "t", ["common.cancel"], [], ["loc", [null, [79, 10], [79, 31]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 85,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/account-settings/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-12 admin-main-content full-width");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "admin-main-content-list account-settings");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "header-section");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "body-section");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "configuration-list-item network-warning");
        dom.setAttribute(el4, "data-testid", "network-warning");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "configuration-item-header");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "details");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h5");
        dom.setAttribute(el7, "class", "config-title");
        dom.setAttribute(el7, "data-testid", "network-warning-title");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "config-description");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "toggle");
        dom.setAttribute(el6, "data-testid", "network-warning-toggle");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0, 1]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(element9, [3, 1]);
        var element11 = dom.childAt(element10, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(element9, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element11, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
        morphs[5] = dom.createMorphAt(element9, 5, 5);
        morphs[6] = dom.createMorphAt(element8, 5, 5);
        return morphs;
      },
      statements: [["content", "pageTitle", ["loc", [null, [4, 10], [4, 23]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "get", [["get", "accountConfiguration", ["loc", [null, [7, 17], [7, 37]]], 0, 0, 0, 0], "hasCustomWQFeature"], [], ["loc", [null, [7, 12], [7, 59]]], 0, 0]], [], 0, null, ["loc", [null, [7, 6], [12, 13]]]], ["inline", "t", ["account_settings.warning_logs_heading"], [], ["loc", [null, [16, 73], [16, 118]]], 0, 0], ["inline", "t", ["account_settings.warning_logs_desc"], [], ["loc", [null, [17, 44], [17, 86]]], 0, 0], ["inline", "custom-elements/ui-toggle", [], ["checked", ["subexpr", "@mut", [["get", "networkWarningLogs", ["loc", [null, [21, 22], [21, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [22, 23], [22, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "followUpAction", ["subexpr", "action", ["onNetworkWarningToggle"], [], ["loc", [null, [23, 29], [23, 62]]], 0, 0]], ["loc", [null, [20, 12], [24, 14]]], 0, 0], ["block", "if", [["subexpr", "get", [["get", "accountConfiguration", ["loc", [null, [28, 17], [28, 37]]], 0, 0, 0, 0], "hasGlobalAnswerFeature"], [], ["loc", [null, [28, 12], [28, 63]]], 0, 0]], [], 1, null, ["loc", [null, [28, 6], [67, 13]]]], ["block", "if", [["get", "isSaveEnabled", ["loc", [null, [69, 10], [69, 23]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [69, 4], [82, 11]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});