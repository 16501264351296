define("freshfone-ember/components/message-component/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/message-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-primary create-message");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["element", "action", [["subexpr", "send", [["get", "messageForm", ["loc", [null, [16, 64], [16, 75]]], 0, 0, 0, 0], "toggleOpen"], [], ["loc", [null, [16, 58], [16, 89]]], 0, 0]], [], ["loc", [null, [16, 49], [16, 91]]], 0, 0], ["inline", "t", ["message_components.new_message.new"], [], ["loc", [null, [17, 4], [17, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 2
              },
              "end": {
                "line": 41,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/message-component/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "message-components/new-message", [], ["scope", ["subexpr", "@mut", [["get", "scope", ["loc", [null, [33, 12], [33, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "isOpen", ["subexpr", "@mut", [["get", "isOpen", ["loc", [null, [34, 13], [34, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "message", ["subexpr", "@mut", [["get", "message", ["loc", [null, [35, 14], [35, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "onModify", ["subexpr", "action", ["onModify"], [], ["loc", [null, [36, 15], [36, 34]]], 0, 0], "edit", ["subexpr", "action", ["edit"], [], ["loc", [null, [37, 11], [37, 26]]], 0, 0], "actionReceiver", ["subexpr", "@mut", [["get", "messageForm", ["loc", [null, [38, 21], [38, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "isNative", true], ["loc", [null, [32, 4], [40, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 0
            },
            "end": {
              "line": 42,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/message-component/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ui-modal", [], ["class", "sidebar-content-modal", "size", "md", "animateClose", true, "isModalOpen", ["subexpr", "@mut", [["get", "isOpen", ["loc", [null, [25, 16], [25, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "showCloseOnTopLeft", true, "closeOnEscape", true, "closeOnBackdropClick", true, "sliderModal", true, "onClose", ["subexpr", "action", [["subexpr", "send", [["get", "messageForm", ["loc", [null, [30, 26], [30, 37]]], 0, 0, 0, 0], "toggleOpen"], [], ["loc", [null, [30, 20], [30, 51]]], 0, 0]], [], ["loc", [null, [30, 12], [30, 52]]], 0, 0]], 0, null, ["loc", [null, [21, 2], [41, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 19
          }
        },
        "moduleName": "freshfone-ember/components/message-component/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-12 admin-main-content full-width");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "admin-main-content-list");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "message-components/messages-list", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 12], [4, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "scope", ["subexpr", "@mut", [["get", "scope", ["loc", [null, [5, 12], [5, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "modifyModel", ["subexpr", "@mut", [["get", "modifyModel", ["loc", [null, [6, 18], [6, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "onEdit", ["subexpr", "action", ["editMessage"], [], ["loc", [null, [7, 13], [7, 35]]], 0, 0], "onDelete", ["subexpr", "action", ["toggleDelete"], [], ["loc", [null, [8, 15], [8, 38]]], 0, 0], "onAfterDelete", ["subexpr", "action", ["afterDelete"], [], ["loc", [null, [9, 20], [9, 42]]], 0, 0], "class", "messages-list-wrapper"], ["loc", [null, [3, 4], [11, 6]]], 0, 0], ["block", "ember-wormhole", [], ["to", "miscellaneous-item"], 0, null, ["loc", [null, [15, 0], [19, 19]]]], ["block", "ember-wormhole", [], ["to", "modal-wormhole"], 1, null, ["loc", [null, [20, 0], [42, 19]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});