define('freshfone-ember/services/call-flow', ['exports', 'ember', 'freshfone-ember/constants/call-queues'], function (exports, _ember, _freshfoneEmberConstantsCallQueues) {
  var Service = _ember['default'].Service;
  var run = _ember['default'].run;
  exports['default'] = Service.extend({
    callQueueCount: 0,
    ivrCount: 0,
    getQueueCount: function getQueueCount() {
      var queryParams = {
        only: 'count',
        type: _freshfoneEmberConstantsCallQueues.recentCallQueues.callQueue
      };

      return this.fetchCallFlowCount(queryParams);
    },
    getIvrCount: function getIvrCount() {
      var queryParams = {
        only: 'count',
        type: _freshfoneEmberConstantsCallQueues.recentCallQueues.ivr
      };

      return this.fetchCallFlowCount(queryParams);
    },
    fetchCallFlowCount: function fetchCallFlowCount(queryParams) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        $.getJSON('/call_flows', queryParams).then(function (response) {
          var count = response.count;

          run(function () {
            switch (queryParams.type) {
              case _freshfoneEmberConstantsCallQueues.recentCallQueues.callQueue:
                _this.set('callQueueCount', count);
                break;

              case _freshfoneEmberConstantsCallQueues.recentCallQueues.ivr:
                _this.set('ivrCount', count);
                break;
            }
          });

          resolve(count);
        }, reject);
      });
    }
  });
});