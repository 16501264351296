define("freshfone-ember/components/ongoing-widget/transfer/transfer-options/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/transfer/transfer-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "transfer-agent more-actions-bg");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "icon-more-actions");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element5, 'id');
          morphs[1] = dom.createAttrMorph(element5, 'onclick');
          morphs[2] = dom.createAttrMorph(element6, 'id');
          return morphs;
        },
        statements: [["attribute", "id", ["get", "spanId", ["loc", [null, [3, 15], [3, 21]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggle"], [], ["loc", [null, [null, null], [3, 90]]], 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "id", ["loc", [null, [4, 16], [4, 18]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 12,
                      "column": 20
                    },
                    "end": {
                      "line": 17,
                      "column": 20
                    }
                  },
                  "moduleName": "freshfone-ember/components/ongoing-widget/transfer/transfer-options/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("i");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var element3 = dom.childAt(element2, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element2, 'class');
                  morphs[1] = dom.createElementMorph(element2);
                  morphs[2] = dom.createAttrMorph(element3, 'class');
                  morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["transfer-option ", ["subexpr", "if", [["get", "option.disabled", ["loc", [null, [13, 56], [13, 71]]], 0, 0, 0, 0], "row-disabled"], [], ["loc", [null, [13, 51], [13, 88]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["onSelect", ["get", "option", ["loc", [null, [13, 110], [13, 116]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 90], [13, 118]]], 0, 0], ["attribute", "class", ["concat", [["get", "option.icon", ["loc", [null, [14, 40], [14, 51]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "option.label", ["loc", [null, [15, 34], [15, 50]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 16
                  },
                  "end": {
                    "line": 18,
                    "column": 16
                  }
                },
                "moduleName": "freshfone-ember/components/ongoing-widget/transfer/transfer-options/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "showConference", ["loc", [null, [12, 26], [12, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 20], [17, 27]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 16
                  },
                  "end": {
                    "line": 23,
                    "column": 16
                  }
                },
                "moduleName": "freshfone-ember/components/ongoing-widget/transfer/transfer-options/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createElementMorph(element0);
                morphs[2] = dom.createAttrMorph(element1, 'class');
                morphs[3] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["transfer-option ", ["subexpr", "if", [["get", "option.disabled", ["loc", [null, [19, 52], [19, 67]]], 0, 0, 0, 0], "row-disabled"], [], ["loc", [null, [19, 47], [19, 84]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["onSelect", ["get", "option", ["loc", [null, [19, 106], [19, 112]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 86], [19, 114]]], 0, 0], ["attribute", "class", ["concat", [["get", "option.icon", ["loc", [null, [20, 36], [20, 47]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "option.label", ["loc", [null, [21, 30], [21, 46]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 12
                },
                "end": {
                  "line": 24,
                  "column": 12
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/transfer/transfer-options/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "option.id", ["loc", [null, [11, 26], [11, 35]]], 0, 0, 0, 0], "add_participants"], [], ["loc", [null, [11, 22], [11, 55]]], 0, 0]], [], 0, 1, ["loc", [null, [11, 16], [23, 23]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 25,
                "column": 8
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/transfer/transfer-options/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "option.visible", ["loc", [null, [10, 18], [10, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 12], [24, 19]]]]],
          locals: ["option"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/transfer/transfer-options/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element4, 'style');
          morphs[1] = dom.createAttrMorph(element4, 'class');
          morphs[2] = dom.createMorphAt(element4, 1, 1);
          return morphs;
        },
        statements: [["attribute", "style", ["concat", ["top:", ["get", "clientY", ["loc", [null, [8, 21], [8, 28]]], 0, 0, 0, 0], "px;left:", ["get", "clientX", ["loc", [null, [8, 40], [8, 47]]], 0, 0, 0, 0], "px;"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["call-transfer-options ", ["subexpr", "if", [["get", "focus", ["loc", [null, [8, 88], [8, 93]]], 0, 0, 0, 0], "option-in-focus"], [], ["loc", [null, [8, 83], [8, 113]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "options", ["loc", [null, [9, 16], [9, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 8], [25, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/ongoing-widget/transfer/transfer-options/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "if", [["get", "showIcon", ["loc", [null, [2, 6], [2, 14]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [6, 7]]]], ["block", "if", [["get", "show", ["loc", [null, [7, 6], [7, 10]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [7, 0], [27, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});