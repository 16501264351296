define('freshfone-ember/constants/free-incoming-calling-minutes', ['exports'], function (exports) {
  exports['default'] = Object.freeze({
    blossom: '1000',
    garden: '2000',
    estate: '3000',
    forest: '5000',
    growth: '2000',
    pro: '3000',
    enterprise: '5000'
  });
});