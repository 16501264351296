define('freshfone-ember/models/business-calendar', ['exports', 'ember', 'ember-data', 'ember-validations', 'freshfone-ember/helpers/list-timezone', 'freshfone-ember/mixins/get-selected-days-mixin', 'freshfone-ember/utils/business-calendar-constants'], function (exports, _ember, _emberData, _emberValidations, _freshfoneEmberHelpersListTimezone, _freshfoneEmberMixinsGetSelectedDaysMixin, _freshfoneEmberUtilsBusinessCalendarConstants) {
  var days = _freshfoneEmberUtilsBusinessCalendarConstants['default'].days;
  var defaultStartTime = _freshfoneEmberUtilsBusinessCalendarConstants['default'].defaultStartTime;
  var defaultEndTime = _freshfoneEmberUtilsBusinessCalendarConstants['default'].defaultEndTime;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = Model.extend(_emberValidations['default'], _freshfoneEmberMixinsGetSelectedDaysMixin['default'], {
    name: attr('String'),
    timeZone: attr('String', { defaultValue: 'American Samoa' }),
    isDefault: attr('boolean'),
    externalId: attr(),
    workingHours: hasMany('working-hour', { inverse: 'calendar' }),
    holidays: hasMany('holiday', { inverse: 'calendar' }),
    enabledDays: [],
    editEnabled: false,
    timezonesList: (0, _freshfoneEmberHelpersListTimezone.listTimezone)(),
    category: attr(),

    daysInWeek: computed(function () {
      return [{ 'value': '1', 'shortForm': _ember['default'].i18n.t('days.mon') }, { 'value': '2', 'shortForm': _ember['default'].i18n.t('days.tue') }, { 'value': '3', 'shortForm': _ember['default'].i18n.t('days.wed') }, { 'value': '4', 'shortForm': _ember['default'].i18n.t('days.thu') }, { 'value': '5', 'shortForm': _ember['default'].i18n.t('days.fri') }, { 'value': '6', 'shortForm': _ember['default'].i18n.t('days.sat') }, { 'value': '0', 'shortForm': _ember['default'].i18n.t('days.sun') }];
    }),
    defaultDays: computed(function () {
      return ['1', '2', '3', '4', '5'];
    }),
    workingDays: computed('workingHours.[]', 'workingDays.@each.days', function () {
      var _this = this;

      var daysInWeek = days;
      var values = _ember['default'].A();
      this.get('workingHours').forEach(function (day) {
        if (!day.get('isDeleted')) {
          if (daysInWeek.includes(day.get('day'))) {
            (function () {
              var selectedDays = _this.getSelectedDays(_this.get('workingHours'), day.get('timeSlots'));
              var days = [];
              selectedDays.forEach(function (day) {
                if (day.get('day')) {
                  daysInWeek = daysInWeek.without(day.get('day'));
                  days.push(day.get('day'));
                  if (!_this.get('enabledDays').includes(day.get('day'))) {
                    _this.get('enabledDays').push(day.get('day'));
                  }
                }
              });
              values.pushObject({ timeSlots: day.get('timeSlots'), 'days': days });
            })();
          } else if (!day.get('day')) {
            var startTime = defaultStartTime;
            var endTime = defaultEndTime;
            var timeSlots = [{ startTime: startTime, endTime: endTime }];
            values.pushObject({ timeSlots: timeSlots, 'days': [] });
          }
        }
      });
      return values;
    }),
    holidaysAsArray: computed('holidays.@each.hasDirtyAttributes', function () {
      return this.get('holidays').toArray();
    }),
    hoursOption: computed(function () {
      return ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    }),
    minuteOption: computed(function () {
      return ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
    }),

    resetFlash: function resetFlash() {
      var _this2 = this;

      _ember['default'].run.later(this, function () {
        _this2.set('isFlash', false);
      }, 2000);
    },
    rollbackWorkingHours: function rollbackWorkingHours() {
      var workingHours = this.store.peekAll('working-hour').filterBy('isNew', true);
      workingHours.forEach(function (hour) {
        hour.rollbackAttributes();
      });
    },
    rollbackModel: function rollbackModel() {
      this.rollbackWorkingHours();
      this.rollbackAttributes();
      this.rollbackRelationships();
    },
    validations: {
      name: {
        presence: {
          message: _ember['default'].i18n.t('business_calendar.validation_errors.name')
        }
      },
      workingHours: {
        presence: {
          unless: 'isDefault',
          message: _ember['default'].i18n.t('business_calendar.validation_errors.working_hours_blank')
        }
      },
      timeZone: {
        presence: {
          messgae: _ember['default'].i18n.t('business_calendar.validation_errors.time_zone')
        }
      }
    }
  });
});