define('freshfone-ember/constants/product-names', ['exports'], function (exports) {
  var defaultProductNames = Object.freeze({
    freshcaller: 'Freshcaller',
    freshdesk: 'Freshdesk',
    freshchat: 'Freshchat',
    crm: 'Freshsales'
  }),
      rebrandedProductNames = Object.freeze({
    freshcaller: 'Freshdesk Contact Center',
    freshdesk: 'Freshdesk Support Desk',
    freshchat: 'Freshdesk Messaging',
    crm: 'Freshsales',
    crmSuite: 'Freshsales Suite'
  });

  exports.defaultProductNames = defaultProductNames;
  exports.rebrandedProductNames = rebrandedProductNames;
});