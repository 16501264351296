define('freshfone-ember/models/service-level-configuration', ['exports', 'ember', 'ember-data', 'ember-api-actions', 'freshfone-ember/mixins/model-proxy'], function (exports, _ember, _emberData, _emberApiActions, _freshfoneEmberMixinsModelProxy) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var computed = _ember['default'].computed;
  exports['default'] = Model.extend(_freshfoneEmberMixinsModelProxy['default'], {
    entityId: attr('string'),
    entityType: attr('string', { defaultValue: 'CallQueue' }),
    callQueueName: attr(),
    targetPercent: attr('string'),
    ttaThreshold: attr('string'),
    targetPercentProxy: computed.readOnly('__modelProxy.targetPercent'),
    ttaThresholdProxy: computed.readOnly('__modelProxy.ttaThreshold'),
    fetchUnconfiguredCallQueues: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'unconfigured_call_queues' }),
    comparableProperties: ['targetPercent', 'ttaThreshold'],
    compareProperties: function compareProperties(config) {
      var _this = this;

      return this.comparableProperties.every(function (key) {
        return config.get(key) === _this.get(key);
      });
    },
    isConfigDirty: function isConfigDirty() {
      return Object.keys(this.changedAttributes()).length > 0 || this.get('selectedQueue');
    }
  });
});