define('freshfone-ember/components/outgoing-widget/pick-number/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    routing: service('-routing'),
    actions: {
      callbackAction: function callbackAction() {
        this.sendAction('getNumberCallback');
      },
      redirectToNumbers: function redirectToNumbers() {
        this.sendAction('closeCallback');
        this.get('routing').transitionTo('protected.admin.numbers');
      }
    }
  });
});