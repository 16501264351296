define('freshfone-ember/adapters/user', ['exports', 'freshfone-ember/adapters/application', 'ember-data'], function (exports, _freshfoneEmberAdaptersApplication, _emberData) {
  var InvalidError = _emberData['default'].InvalidError;
  var config = {
    emailErrorStatus: [422, 417],
    emailattrPathApiModel: ['/data/attributes/user-emails.email', '/data/attributes/user-emails'],
    emailAttrPathAppModel: '/data/attributes/email'
  };
  var checkEmailErrors = function checkEmailErrors(errors) {
    errors.forEach(function (error) {
      var pointer = config.emailattrPathApiModel.find(function (check) {
        return check === error.source.pointer;
      });

      if (!pointer) {
        return error;
      }

      error.source.pointer = config.emailAttrPathAppModel;
    });
  };exports['default'] = _freshfoneEmberAdaptersApplication['default'].extend({

    handleResponse: function handleResponse(status, headers, payload) {
      if (payload.errors) {
        var hasEmailErrors = config.emailErrorStatus.some(function (errorCode) {
          return errorCode === status;
        });

        if (hasEmailErrors) {
          checkEmailErrors(payload.errors);
        }

        return new InvalidError(payload.errors);
      }

      return this._super.apply(this, arguments);
    }
  });
});