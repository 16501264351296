define('freshfone-ember/routes/protected/admin/agent-extensions', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    i18n: service(),
    titleToken: computed(function () {
      return this.get('i18n').t('agent-extensions.title');
    }),
    breadCrumb: computed(function () {
      return { title: this.get('i18n').t('agent-extensions.title') };
    }),
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_agents');
    },
    model: function model() {
      if (this.checkFeature('data_scoping')) {
        this.store.findAll('team'); // deferred
      }

      return this.store.query('user', {});
    }
  });
});