define('freshfone-ember/components/agent-component/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/constants/product-names', 'freshfone-ember/helpers/support-portal-link'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberConstantsProductNames, _freshfoneEmberHelpersSupportPortalLink) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    session: service(),
    currentAccount: computed.alias('session.account'),
    searchDisabled: computed.alias('currentAccount.subscription.isSuspended'),
    activeUsersCount: computed.alias('currentAccount.active_users_count'),
    usersLimit: computed.alias('currentAccount.users_limit'),
    isOmnichannelEnabled: computed.alias('currentAccount.isOmnichannelEnabled'),
    freshsalesPlanName: computed.alias('session.current.bundleInfo.domains.freshsales-plan-name'),
    isUCREnabledAccount: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    isAgentExtensionPage: computed(function () {
      return getOwner(this).lookup('controller:application').currentPath.includes('agent-extensions');
    }),
    isSharedBundleAccount: computed(function () {
      return this.hasGlobalFeature('shared_freshcaller') && this.get('currentAccount.isSharedBundleAccount');
    }),
    freshSalesPlan: computed('freshsalesPlanName', function () {
      // return the crm account type
      return this.get('freshsalesPlanName') && this.get('freshsalesPlanName').includes('sales360_clc') ? _freshfoneEmberConstantsProductNames.rebrandedProductNames.crmSuite : _freshfoneEmberConstantsProductNames.rebrandedProductNames.crm;
    }),
    crmTitle: computed('freshSalesPlan', function () {
      // now set the text accordingly whether it is freshsales or freshsales  suite
      this.set('crmDescription', _ember['default'].i18n.t('agent_form.crm_agent_create', { freshCRM: this.get('freshSalesPlan') }));

      return _ember['default'].i18n.t('agent_form.crm_agent_title', { crm: this.get('freshSalesPlan') });
    }),
    originalRoleId: '',
    agentExtensionFeatureEnabled: computed('session.account', function () {
      return this.get('session.account').hasGlobalFlag('agent_extension') && this.hasFeatures(["agent_extension"]);
    }),

    canShowHoverOptions: computed('isAgentExtensionPage', 'isUnifiedAppAccount', function () {
      return !(this.get('isUnifiedAppAccount') || this.get('isAgentExtensionPage'));
    }),

    supportArticleLink: computed('isAgentExtensionPage', function () {
      return !this.get('isAgentExtensionPage') && (0, _freshfoneEmberHelpersSupportPortalLink.supportPortalLink)(['addingNewAgents']);
    }),

    decreaseAgentLimit: function decreaseAgentLimit() {
      this.decrementProperty('activeUsersCount');
      this.get('agentForm').send('toggleLicenceCard');
    },

    increaseAgentLimit: function increaseAgentLimit() {
      this.incrementProperty('activeUsersCount');
    },

    actions: {
      closeCallback: function closeCallback() {
        this.set('isDelete', false);
        this.set('agentToRestore', null);
      },
      toggleDelete: function toggleDelete(item) {
        this.set('isDelete', true);
        this.set('deleteItem', item);
      },
      deleteCallback: function deleteCallback(item) {
        var _this = this;

        item.set('deleted', true);
        item.save().then(function () {
          _this.send('onModify');
          _this.decreaseAgentLimit();
          item.reload();
          _this.send('closeCallback');
        });
      },
      onModify: function onModify() {
        this.set('modifyModel', true);
      },
      edit: function edit(item) {
        this.rollbackDirtyChanges('user');
        item.saveOriginalRelations();
        this.set('user', item);
        this.set('slideToggle', true);
      },
      sendMail: function sendMail(item) {
        var _this2 = this;

        var url = item.get('confirmed') ? '/send_confirmation_link' : '/send_activation_link';

        $.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          data: { id: item.get('id') }
        }).done(function (data) {
          run(function () {
            item.set('mailSent', true);
            run.later(_this2, function () {
              item.set('mailSent', false);
              if (!data.success) {
                item.set('email_confirmed', true);
              }
            }, 5000);
          });
        }).fail(function () {
          run(function () {
            item.set('mailError', true);
          });
        });
      },
      clearError: function clearError(item) {
        item.set('mailError', false);
      },
      restoreAgent: function restoreAgent(agent) {
        agent.set('canRestoreAgent', true);
        this.set('agentToRestore', agent);
      },
      redirectToOmnichannelAgentCreation: function redirectToOmnichannelAgentCreation() {
        var url = this.get('currentAccount.omnichannelOwnerDomain') + '/a/admin/agents';

        window.open(url, '_blank');
      },
      redirectToCRMAgentCreation: function redirectToCRMAgentCreation() {
        var rootURL = localStorage.getItem('rootURL');

        var _rootURL$split = rootURL.split('/');

        var _rootURL$split2 = _slicedToArray(_rootURL$split, 2);

        var crmPath = _rootURL$split2[1];
        var url = this.get('currentAccount.orgUrl') + '/' + crmPath + '/sales/settings/users';

        window.open(url, '_blank');
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */