define('freshfone-ember/initializers/mkp-adapter', ['exports', 'freshfone-ember/config/environment'], function (exports, _freshfoneEmberConfigEnvironment) {
  exports.initialize = initialize;

  function initialize(application) {
    var randomId = Math.ceil(Date.now() + Math.random() * 1000),
        scriptTag = document.createElement('script');

    scriptTag.src = _freshfoneEmberConfigEnvironment['default'].Freshparent.url + '?_=' + randomId;
    document.head.appendChild(scriptTag);

    ['component'].forEach(function (entityType) {
      application.inject(entityType, 'mkpAdapter', 'service:mkp-adapter');
    });
  }

  exports['default'] = {
    name: 'mkp-adapter',
    initialize: initialize
  };
});
/* eslint-disable no-mixed-operators */