define('freshfone-ember/components/ongoing-widget/call-controls/component', ['exports', 'freshfone-ember/constants/active-call-type', 'freshfone-ember/constants/call-state', 'freshfone-ember/constants/call-status', 'freshfone-ember/constants/call-type', 'freshfone-ember/constants/disconnect-source-type', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/park-state'], function (exports, _freshfoneEmberConstantsActiveCallType, _freshfoneEmberConstantsCallState, _freshfoneEmberConstantsCallStatus, _freshfoneEmberConstantsCallType, _freshfoneEmberConstantsDisconnectSourceType, _emberRlDropdownMixinsRlDropdownComponent, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsParkState) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /* eslint-disable ember-suave/no-direct-property-access */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var observer = _ember['default'].observer;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  var _Ember$computed = _ember['default'].computed;
  var equal = _Ember$computed.equal;
  var and = _Ember$computed.and;
  var alias = _Ember$computed.alias;
  var or = _Ember$computed.or;
  exports['default'] = Component.extend(_emberRlDropdownMixinsRlDropdownComponent['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    toastr: service(),
    telephony: service(),
    i18n: service(),
    parallelCall: service(),
    hangupService: service('hangup-event'),
    currentCall: computed.alias('telephony.currentCall'),
    parallelCalls: computed.alias('telephony.parallelCalls'),
    powerDialerService: service('power-dialer-list'),
    recordTypeService: service('record-type'),
    parkedCallsService: service('parked-calls'),
    account: computed.alias('session.account'),
    isLowCredit: computed.alias('account.credit.isCreditLow'),
    callingDisabled: computed.alias('account.callingDisabled'),
    hasCallTaggingFeature: computed(function () {
      return this.checkFeature('call_tagging');
    }),
    hasNoCallTags: computed.empty('currentCall.tags'),
    isCallQueueTransferDisabled: computed('currentCall.conferenceState', 'currentCall.isConferenceCall', 'parallelCallActive', 'isAttemptingTransferOrConference', function () {
      var conferenceState = this.get('currentCall.conferenceState'),
          isInConferenceState = conferenceState && conferenceState !== _freshfoneEmberConstantsCallState['default'].Removed;

      return isInConferenceState || this.get('currentCall.isConferenceCall') || this.get('parallelCallActive') || this.get('isAttemptingTransferOrConference');
    }),
    showQueueTransfer: computed.alias('telephony.isQueueTransferInitiated'),
    isCsatIvrTransfer: computed.alias('telephony.transferringToCsatIvr'),
    currentCallIsCompleted: computed('currentCall.isCompleted', {
      get: function get() {
        var isCompleted = this.get('currentCall.isCompleted');

        if (isCompleted) {
          this.setProperties({
            showVoicemailsList: false,
            showCsatModal: false,
            isCsatIvrTransfer: false
          });
        }

        return isCompleted;
      }
    }),
    parallelCallActive: computed.alias('telephony.parallelCallActive'),
    currentTime: computed.alias('currentCall.callDuration'),
    holdDuration: computed.alias('currentCall.holdDuration'),
    showLoader: false,
    // psst, remove isAddingAgent once enhanced search is live
    isAttemptingTransferOrConference: alias('telephony.isAttemptingTransferOrConference'),
    holdDisabled: alias('telephony.disableAction'),
    contact: computed.alias('currentCall.contact'),
    isNewCaller: computed.alias('telephony.currentCall.isNewCaller'),
    session: service(),
    isSecondaryParallelCallActive: computed.alias('currentCall.isParallelCall'),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.get('recordTypeService').setRecordingParams(this.get('currentCall'));
      this.set('showRecordingState', this.getRecordingState());
      this.set('isRecordingParamsSet', true);
    },
    didInsertElement: function didInsertElement() {
      this.set('manualRecordingStarted', false);
      this.set('existingCallId', this.get('currentCall.id'));
    },
    pauseCall: true,
    showVoicemailsList: computed({
      set: function set(k, value) {
        return value;
      },
      // eslint-disable-next-line
      get: function get() {}
    }),
    currentUser: computed.alias('session.user'),
    notifications: service('toastr'),
    isAutomaticRecording: computed.alias('recordTypeService.isAutomaticRecording'),
    isManualRecording: computed.alias('recordTypeService.isManualRecording'),
    isSecondaryAgent: computed('currentCall.type', function () {
      return this.get('currentCall.type') === _freshfoneEmberConstantsCallType['default'].AgentConference || this.get('currentCall.type') === _freshfoneEmberConstantsCallType['default'].WarmTransfer;
    }),
    isWarmTransferInvolved: computed('currentCall.status', 'currentCall.type', function () {
      return this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferred || this.get('currentCall.type') === _freshfoneEmberConstantsCallType['default'].WarmTransfer;
    }),
    restrictedMode: computed('isSecondaryAgent', 'isWarmTransferInvolved', 'isAgentParallelCallMerged', 'isSecondaryParallelCallActive', function () {
      var status = this.get('isSecondaryAgent') || this.get('isWarmTransferInvolved') || this.get('isAgentParallelCallMerged') || this.get('isSecondaryParallelCallActive');

      if (!this.get('isAgentParallelCallMerged')) {
        this.sendAction('triggerDisableNotes', status);
      }

      return status;
    }),
    showConferenceContainer: computed('restrictedMode', 'isSecondaryParallelCallActive', function () {
      return this.get('restrictedMode') && !this.get('isSecondaryParallelCallActive');
    }),
    isIncomingCall: computed.notEmpty('currentCall.to'),
    hideDialpad: computed('isSecondaryAgent', 'restrictedMode', function () {
      return this.hasGlobalFeature('warm_transfer_dialpad') ? this.get('isSecondaryAgent') : this.get('restrictedMode');
    }),
    showRecording: computed('hasFeaturePauseRecording', 'canStartRecording', function () {
      return !this.get('hasFeaturePauseRecording') && !this.get('canStartRecording');
    }),
    sourceAgent: computed('currentCall.sourceAgent', function () {
      return this.store.findRecord('user', this.get('currentCall.from.id'));
    }),

    avatarContact: computed('selectedContact', 'contact', function () {
      if (!isEmpty(this.get('selectedContact'))) {
        return this.get('selectedContact');
      }

      return this.get('contact');
    }),
    displayNameNumber: computed('displayName', 'selectedContact', function () {
      if (isEmpty(this.get('selectedContact'))) {
        return this.get('displayName');
      }

      return this.get('selectedContact.name');
    }),

    muteButtonText: computed('currentCall.muted', function () {
      // todo: i18n
      return this.get('currentCall.muted') ? 'Muted' : 'Mute';
    }),

    holdButtonText: computed('currentCall.isOnHold', function () {
      return this.get('currentCall.isOnHold') ? 'Unhold' : 'Hold';
    }),

    displayName: computed('currentCall.callerName', 'contact.name', 'selectedContact', function () {
      var _model = this.get('contact') || this.get('selectedContact');

      if (_model && _model.get) {
        if (_model.get('name')) {
          return _model.get('name');
        } else if (_model.get('isNew') || _model.get('isNewContact')) {
          return _ember['default'].i18n.t('contact.new_contact');
        }
      }

      return this.get('currentCall.callerName');
    }),

    showRecentContactInCallControl: computed('nameShown', 'lastSearchedContact', 'displayName', function () {
      return this.get('nameShown') === this.get('lastSearchedContact.name') && this.get('lastSearchedContact.name') !== this.get('displayName');
    }),

    lastSearchedContact: computed('lastSearchedContacts.[]', function () {
      var recentContacts = this.get('lastSearchedContacts');

      if (isEmpty(recentContacts)) {
        return false;
      }

      return this.get('lastSearchedContacts')[recentContacts.length - 1];
    }),
    toggleTimer: computed('currentCall.minimized', {
      get: function get() {
        if (this.get('currentCall.isInProgress') && !this.get('hasParkingEnabled')) {
          return this.get('currentCall.minimized') ? this.get('timer').send('stop') : this.get('timer').send('play');
        }
      },
      // eslint-disable-next-line
      set: function set(key, value) {}
    }),
    recordingTooltipTitle: computed('recordingActive', 'pauseCall', function () {
      return _ember['default'].i18n.t('conversation.conversation_list.' + this.recordingState());
    }),

    isRecordingParamsSet: false,
    holdLoading: false,
    recordingActive: false,

    showPauseButton: computed('recordingActive', 'pauseCall', 'isAutomaticRecording', 'isManualRecording', function () {
      var isManualRecordingActive = this.get('isManualRecording') && this.get('recordingActive'),
          showPauseButton = this.get('hasFeaturePauseRecording') && this.get('pauseCall') && (isManualRecordingActive || this.get('isAutomaticRecording'));

      this.set('telephony.currentCall.isBeingRecorded', showPauseButton);

      return showPauseButton;
    }),

    canStartRecording: computed('recordingActive', 'isManualRecording', function () {
      return this.get('isManualRecording') && !this.get('recordingActive');
    }),

    hasFeaturePauseRecording: computed(function () {
      return this.checkFeature('pause_recording');
    }),

    hasFeatureCallRecording: computed(function () {
      return this.checkFeature('call_recording');
    }),

    hasCallActionsTooltipFeature: computed(function () {
      return this.hasGlobalFeature('call_actions_tooltip') && this.checkFeature('call_actions_tooltip');
    }),
    shouldShowCsatIvr: computed.alias('telephony.shouldShowCsatIvr'),
    primaryCallActive: computed.alias('telephony.primaryCallActive'),
    primaryCallVisibleAndActive: computed('currentCall.callType', 'primaryCallActive', function () {
      var callType = this.get('currentCall.callType');

      return callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && this.get('primaryCallActive');
    }),
    restrictedModeNotActive: computed('restrictedMode', 'isAgentParallelCallMerged', function () {
      return this.get('isAgentParallelCallMerged') || !this.get('restrictedMode');
    }),
    shouldShowCsatIvrModal: computed.and('shouldShowCsatIvr', 'primaryCallVisibleAndActive', 'restrictedModeNotActive'),
    getRecordingState: function getRecordingState() {
      return this.get('hasFeatureCallRecording') && (this.get('isManualRecording') || this.get('isAutomaticRecording')) && !this.get('hideRecordingButton') && !(this.get('currentCallIsCompleted') || this.get('isWarmTransferInvolved') || this.get('currentCall.isTransferring') || this.get('isSecondaryAgent') || this.get('isSecondaryParallelCallActive'));
    },

    showRecordingState: computed('currentCall.status', 'currentCall.type', function () {
      return this.getRecordingState();
    }),

    voicemails: computed(function () {
      return this.store.peekAll('message').filter(function (message) {
        return message.get('messageScope') === 1 && !message.get('isNew');
      });
    }),
    hasVoicemails: computed('voicemails.length', 'parallelCallActive', 'isIncomingCall', function () {
      return this.get('voicemails.length') && !this.get('isIncomingCall') && !this.get('parallelCallActive');
    }),
    hasEnhancedInconversationSearchFeature: computed(function () {
      return this.hasGlobalFeature('enhanced_inconversation_search');
    }),
    hasTransferToQueueFeature: computed(function () {
      return this.hasGlobalFeature('transfer_to_queue') && this.checkFeature('queue_transfer') && (this.hasRolloutGlobalFlagFeature('transfer_to_queue') || this.checkFeature('queue_transfer_rollout'));
    }),
    isAgentInPowerDialerMode: computed.alias('currentAgent.powerDialer'),
    currentAgent: computed.alias('currentUser.agent'),

    nextContactToolTip: computed('nextContact.name', 'disableDialNext', function () {
      if (!this.get('disableDialNext')) {
        return _ember['default'].i18n.t('power_dialer.next_contact_tooltip', { name: this.get('nextContact.name') });
      }
    }),

    totalContactsInPowerDialer: computed.alias('powerDialerService.totalContactsInPowerDialer'),
    leftContactsInPowerDialer: computed.alias('powerDialerService.leftContactsInPowerDialer'),
    disablePowerDialer: computed('totalContactsInPowerDialer', 'dialedContacts', 'isLowCredit', 'callingDisabled', 'callTaggingPending', function () {
      var hasNoUndialedContacts = this.get('totalContactsInPowerDialer') - this.get('dialedContacts') === 0;

      if (hasNoUndialedContacts) {
        this.get('notifications').success(_ember['default'].i18n.t('power_dialer.notifications.power_dialer_completed'));
      }

      return hasNoUndialedContacts || this.get('isLowCredit') || this.get('callingDisabled') || this.get('callTaggingPending');
    }),
    successfulTransfer: computed.equal('currentCall.status', 'transferred'),
    isNotTransferredCall: computed('successfulTransfer', 'currentCall.previousStatus', function () {
      return !(this.get('successfulTransfer') || this.get('currentCall.previousStatus') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferred);
    }),
    callTaggingPending: computed.and('isTaggingMandatory', 'hasCallTaggingFeature', 'hasNoCallTags', 'isNotTransferredCall'),

    powerDialerMode: observer('powerDialerService.closeOngoingWidget', function () {
      if (this.get('powerDialerService.closeOngoingWidget')) {
        this.sendAction('close');
        this.get('powerDialerService').closeWidget(false);
      }
    }),

    resetRecordingParams: observer('currentCall.id', function () {
      var currentCallId = this.get('currentCall.id');

      if (!currentCallId) {
        return;
      }

      var callRecordingParams = {
        pauseCall: this.get('pauseCall'),
        manualRecordingStarted: this.get('manualRecordingStarted'),
        recordingActive: this.get('recordingActive')
      };

      this.get('recordTypeService').addCallToRecordingPreferenceMap(this.get('existingCallId'), callRecordingParams);

      var isCallRecordingParamsSet = this.get('recordTypeService').isCallRecordingParamsExists(currentCallId);

      if (!isCallRecordingParamsSet) {
        this.resetCallRecordingParams();
      } else {
        var _get$getRecordingPreferencesForCall = this.get('recordTypeService').getRecordingPreferencesForCall(currentCallId);

        var pauseCall = _get$getRecordingPreferencesForCall.pauseCall;
        var manualRecordingStarted = _get$getRecordingPreferencesForCall.manualRecordingStarted;
        var recordingActive = _get$getRecordingPreferencesForCall.recordingActive;

        this.setProperties({
          pauseCall: pauseCall,
          manualRecordingStarted: manualRecordingStarted,
          recordingActive: recordingActive
        });
      }

      this.get('recordTypeService').setRecordingParams(this.get('currentCall'));
      this.set('existingCallId', currentCallId);
      this.set('showRecordingState', this.getRecordingState());
    }),

    currentCallEnded: observer('currentCallIsCompleted', function () {
      if (!this.get('currentCall.isInProgress') && this.get('currentCallIsCompleted')) {
        this.stopTimers();
        if (this.get('isAgentInPowerDialerMode')) {
          this.set('contactFetched', false);
          this.sendAction('fetchNextContact');
          this.set('voicemailDropInProgress', false);
          this.sendAction('close');
        }
      }
    }),
    isWarmTransfered: equal('currentCall.type', _freshfoneEmberConstantsCallType['default'].CustomerConnected),
    callInProgress: observer('currentCall.isConnected', function () {
      if (!this.get('currentCall.isConnected') || this.get('currentCallIsCompleted') || this.get('currentCallIsParked') || this.get('isWarmTransfered')) {
        return;
      }
      this.get('timer').send('play');
      if (this.get('isAgentInPowerDialerMode')) {
        this.set('dialedContacts', this.get('totalContactsInPowerDialer') - this.get('leftContactsInPowerDialer') + 1);
      }
    }),
    isParallelCallUnavailable: computed.alias('currentCall.isParallelCallUnavailable'),

    // Primary or parallel call ended conditions
    showCloseAndResumeForPrimaryCall: computed.alias('telephony.primaryCallOrConferenceResume'),
    showCloseAndResumeForParallelCall: computed.alias('telephony.parallelCallOrConferenceResume'),
    primaryCallOrConferenceEnded: computed.alias('telephony.primaryCallOrConferenceEnded'),
    parallelCallOrConferenceEnded: computed.alias('telephony.parallelCallOrConferenceEnded'),
    showCloseAndResumeForPrimaryCallObserver: observer('showCloseAndResumeForPrimaryCall', function () {
      if (this.get('timer') && this.get('showCloseAndResumeForPrimaryCall')) {
        this.get('timer').send('stop');
      }
    }),
    showCloseAndResumeForParallelCallObserver: observer('showCloseAndResumeForParallelCall', function () {
      if (this.get('parallelCallTimer') && this.get('showCloseAndResumeForParallelCall')) {
        this.get('parallelCallTimer').send('stop');
      }
    }),
    primaryCallOrConferenceEndedObserver: observer('primaryCallOrConferenceEnded', function () {
      if (this.get('primaryCallOrConferenceEnded')) {
        this.stopHoldTimers('timer');
      }
    }),
    parallelCallOrConferenceEndedObserver: observer('parallelCallOrConferenceEnded', function () {
      if (this.get('parallelCallOrConferenceEnded')) {
        this.stopHoldTimers('parallelCallTimer');
      }
    }),
    hideActiveCallControls: computed('showCloseAndResumeForPrimaryCall', 'showCloseAndResumeForParallelCall', 'currentCallIsCompleted', function () {
      if (this.get('currentCallIsCompleted')) {
        this.resetLoading();

        return false;
      }

      if (this.get('showCloseAndResumeForPrimaryCall') || this.get('showCloseAndResumeForParallelCall')) {
        this.set('disconnectingFromControls', false);

        return true;
      }
    }),
    resuming: computed.alias('parallelCall.loadingStatus.resuming'),
    merging: computed.alias('parallelCall.loadingStatus.merging'),
    disconnectingFromBanner: computed.alias('parallelCall.loadingStatus.disconnectingFromBanner'),
    disconnectingFromControls: computed.alias('parallelCall.loadingStatus.disconnectingFromControls'),
    isLoading: alias('telephony.isLoading'),
    primaryHoldDuration: computed.alias('currentCall.primaryHoldDuration'),
    secondaryHoldDuration: computed.alias('currentCall.secondaryHoldDuration'),
    parallelCallTimerResetObserver: observer('parallelCalls.[]', function () {
      var parallelCall = this.get('parallelCalls').length > 0 ? this.get('parallelCalls').get('firstObject') : null;

      if (!parallelCall) {
        if (this.get('holdTimer')) {
          this.get('holdTimer').send('resetTimer');
        }
        if (this.get('parallelCallTimer')) {
          this.get('parallelCallTimer').send('resetTimer');
        }
      }
    }),
    primaryCallGroupId: computed.alias('currentCall.groupId'),
    isAgentParallelCallMerged: computed.alias('currentCall.isMerged'),
    showMergeCall: computed('currentCall.callType', 'parallelCalls.[]', 'parallelCalls.@each.state', 'currentCall.callState', 'isAgentParallelCallMerged', function () {
      if (!this.get('currentCall.callType') || this.get('isAgentParallelCallMerged')) {
        return false;
      }
      var primaryCallEnded = this.get('currentCall.callState') === _freshfoneEmberConstantsCallState['default'].Ended || this.get('currentCall.callState') === _freshfoneEmberConstantsCallState['default'].Removed,
          parallelCall = this.get('parallelCalls').length > 0 ? this.get('parallelCalls').get('firstObject') : null,
          parallelCallEnded = !parallelCall || parallelCall.state === _freshfoneEmberConstantsCallState['default'].Ended || parallelCall.state === _freshfoneEmberConstantsCallState['default'].Removed;

      return !primaryCallEnded && !parallelCallEnded;
    }),
    conferenceCloseAndResume: computed.alias('telephony.conferenceCloseAndResume'),
    conferenceActive: computed.alias('telephony.conferenceActive'),
    activeConferenceOnHold: computed.alias('telephony.activeConferenceOnHold'),
    showConferenceCaller: computed.or('conferenceActive', 'conferenceCloseAndResume', 'activeConferenceOnHold'),
    isConferenceInProgress: computed.alias('telephony.isConferenceInProgress'),
    conferenceInitiated: computed.alias('telephony.conferenceInitiated'),
    parallelCallDisabled: computed('session.account', function () {
      return !this.hasGlobalFeature('parallel_call') || !this.get('session.account').hasFeature('parallel_call');
    }),
    disableTransfer: computed('parallelCallDisabled', 'isConferenceInProgress', function () {
      return this.get('parallelCallDisabled') && this.get('isConferenceInProgress');
    }),
    primaryConferenceTargetName: computed('currentCall.conferenceTarget', function () {
      var target = this.get('currentCall.conferenceTarget');

      return target && target.name;
    }),
    parallelConferenceTargetName: computed('parallelCalls.@each.conferenceTarget', function () {
      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];
      var conferenceTarget = parallelCall && parallelCall.get('conferenceTarget');

      return conferenceTarget && conferenceTarget.name;
    }),
    secondaryContact: computed('showConferenceCaller', 'currentCall.{transferTarget,callType}', 'primaryConferenceTargetName', 'parallelConferenceTargetName', function () {
      if (!this.get('showConferenceCaller')) {
        return this.get('currentCall.transferTarget.name');
      }

      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('parallelConferenceTargetName') : this.get('primaryConferenceTargetName');
    }),
    hideAddIconOfAonference: computed.alias('telephony.mainCallsRemoved'),
    conferenceOnHold: computed.alias('telephony.conferenceOnHold'),
    primaryConferenceOnHold: computed.alias('telephony.primaryConferenceOnHold'),
    primaryCallOrConferenceOnHold: computed('currentCall.{callType,callState}', 'primaryConferenceOnHold', function () {
      var callType = this.get('currentCall.callType'),
          callState = this.get('currentCall.callState');

      return callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && (callState === _freshfoneEmberConstantsCallState['default'].Hold || this.get('primaryConferenceOnHold'));
    }),
    parallelConferenceOnHold: computed.alias('telephony.parallelConferenceOnHold'),
    parallelCallOrConferenceOnHold: computed('currentCall.callType', 'parallelCalls.[]', 'parallelCalls.@each.{state,conferenceState}', 'parallelConferenceOnHold', function () {
      var callType = this.get('currentCall.callType');

      var _get3 = this.get('parallelCalls');

      var _get32 = _slicedToArray(_get3, 1);

      var parallelCall = _get32[0];
      var state = parallelCall && parallelCall.get('state');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel && (state === _freshfoneEmberConstantsCallState['default'].Hold || this.get('parallelConferenceOnHold'));
    }),
    onHold: computed.or('primaryCallOrConferenceOnHold', 'parallelCallOrConferenceOnHold', 'currentCall.isOnHold'),
    parallelCallVisible: equal('currentCall.callType', _freshfoneEmberConstantsActiveCallType['default'].Parallel),
    showStartRecording: computed('isManualRecording', 'currentCall.isBeingRecorded', 'manualRecordingStarted', function () {
      return this.get('isManualRecording') && !this.get('currentCall.isBeingRecorded') && !this.get('manualRecordingStarted');
    }),
    hideRecordingButton: and('showStartRecording', 'parallelCallVisible'),
    hasParkingEnabled: alias('telephony.hasParkingEnabled'),
    currentCallIsParked: equal('currentCall.parkState', _freshfoneEmberConstantsParkState['default'].Parked),
    parkIconTooltip: computed('currentCallIsParked', function () {
      return this.get('currentCallIsParked') ? this.get('i18n').t('call_park.unpark_to_resume') : this.get('i18n').t('call_park.park_this_call');
    }),
    currentCallIsParkedObserver: observer('currentCallIsParked', function () {
      if (this.get('currentCallIsParked')) {
        this.stopTimers();
        this.checkAndStopHoldTimer();
      }
    }),
    showParkButton: computed('hasParkingEnabled', 'isSecondaryAgent', 'isSecondaryParallelCallActive', 'isWarmTransferInvolved', function () {
      return this.get('hasParkingEnabled') && !this.get('isSecondaryAgent') && !this.get('isSecondaryParallelCallActive') && !this.get('isWarmTransferInvolved') && !this.get('isAgentInPowerDialerMode') && !this.get('currentCall.isSIPCall');
    }),
    hasNoCallTags: computed.empty('currentCall.tags'),
    callCompletedAndTransferredObserver: observer('currentCall.status', function () {
      var status = this.get('currentCall.status');

      if (status && status === _freshfoneEmberConstantsCallStatus['default'].Completed) {
        this.endToggle();
      }
      if (status && status === _freshfoneEmberConstantsCallStatus['default'].Transferred) {
        this.sendAction('close');
      }
    }),
    showCallUnparkTooltip: alias('parkedCallsService.showCallUnparkTooltip'),
    disableParkButton: or('holdDisabled', 'currentCall.isParallelCallUnavailable'),
    init: function init() {
      this._super.apply(this, arguments);
      this.bindNodeEvents();
      this.updateCurrentCallRecordingParams(this.get('currentCall.id'));
    },

    updateCurrentCallRecordingParams: function updateCurrentCallRecordingParams(currentCallId) {
      var recordingParams = this.get('recordTypeService').getRecordingPreferencesForCall(currentCallId);

      if (recordingParams) {
        var pauseCall = recordingParams.pauseCall;
        var manualRecordingStarted = recordingParams.manualRecordingStarted;
        var recordingActive = recordingParams.recordingActive;

        this.setProperties({
          pauseCall: pauseCall,
          manualRecordingStarted: manualRecordingStarted,
          recordingActive: recordingActive
        });
      } else {
        this.get('recordTypeService').removeCallsFromRecordingPreferenceMap();
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony.event').unsubscribe('status_update', this.statusUpdateCallback.bind(this));
      this.get('telephony.event').unsubscribe('parallel_call_resumed', this.updateHoldTimer.bind(this, false));
      this.get('telephony.event').unsubscribe('parallel_call_merged', this.resetHoldTimer.bind(this, true));
      this.get('telephony.event').unsubscribe('parallel_call_unavailable_resume', this.resetHoldTimer.bind(this));
      this.get('telephony.event').unsubscribe('call_unparked', this.startActiveCallTimers.bind(this));
    },

    resetCallRecordingParams: function resetCallRecordingParams() {
      this.set('pauseCall', true);
      this.set('recordingActive', false);
      this.get('recordTypeService').setRecordingParams(this.get('currentCall'));
      this.set('showRecordingState', this.getRecordingState());
      this.set('manualRecordingStarted', false);
    },

    showErrorMsg: function showErrorMsg(error, msg) {
      var message = error ? error.errors : msg;

      this.get('notifications').error(message);
    },
    updateHoldTimer: function updateHoldTimer() {
      var _this = this;

      run(function () {
        _this.set('secondaryHoldDuration', _this.get('primaryHoldDuration'));
        _this.set('primaryHoldDuration', 0);
      });
    },
    resetHoldTimer: function resetHoldTimer(stop) {
      this.set('primaryHoldDuration', 0);
      this.set('secondaryHoldDuration', 0);
      if (this.get('holdTimer')) {
        this.get('holdTimer').send('resetTimer');
        stop && this.get('holdTimer').send('stop');
      }
    },
    stopHoldTimers: function stopHoldTimers(timer) {
      if (this.get(timer)) {
        this.get(timer).send('stop');
      }
      this.checkAndStopHoldTimer();
    },
    checkAndStopHoldTimer: function checkAndStopHoldTimer() {
      if (this.get('holdTimer')) {
        this.get('holdTimer').send('stop');
      }
    },
    stopTimers: function stopTimers() {
      this.get('timer').send('stop');
      if (this.get('parallelCallTimer')) {
        this.get('parallelCallTimer').send('stop');
      }
    },
    startActiveCallTimers: function startActiveCallTimers() {
      if (this.get('currentCallIsCompleted')) {
        return;
      }

      if (this.get('timer') && !this.get('parallelCall').hasPrimaryCallAndConferenceEnded()) {
        this.get('timer').send('play');
      }
      if (this.get('parallelCallTimer') && !this.get('parallelCall').isParallelCallAndConferenceEnded()) {
        this.get('parallelCallTimer').send('play');
      }
      if (this.get('holdTimer')) {
        this.startHoldTimerIfAvailable();
      }
    },
    startHoldTimerIfAvailable: function startHoldTimerIfAvailable() {
      var callType = this.get('currentCall.callType');

      if (callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel && !this.get('parallelCall').hasPrimaryCallAndConferenceEnded()) {
        this.get('holdTimer').send('play');

        return;
      }

      if (callType === _freshfoneEmberConstantsActiveCallType['default'].Primary && !this.get('parallelCall').isParallelCallAndConferenceEnded()) {
        this.get('holdTimer').send('play');
      }
    },
    bindNodeEvents: function bindNodeEvents() {
      this.get('telephony.event').subscribe('status_update', this.statusUpdateCallback.bind(this));
      this.get('telephony.event').subscribe('parallel_call_resumed', this.updateHoldTimer.bind(this));
      this.get('telephony.event').subscribe('parallel_call_merged', this.resetHoldTimer.bind(this, true));
      this.get('telephony.event').subscribe('parallel_call_unavailable_resume', this.resetHoldTimer.bind(this));
      this.get('telephony.event').subscribe('call_unparked', this.startActiveCallTimers.bind(this));
    },
    resetLoading: function resetLoading() {
      this.setProperties({
        disconnectingFromBanner: false,
        disconnectingFromControls: false,
        isParallelCallUnavailable: false,
        resuming: false
      });
    },
    endToggle: function endToggle() {
      if (this.get('currentCall.minimized')) {
        this.set('currentCall.minimized', false);
        $('.modal-backdrop').show();
      }
      this.get('timer').send('stop');
    },
    voicemailDropTooltipText: computed('voicemailDropInProgress', 'showVoicemailsList', function () {
      return this.get('voicemailDropInProgress') || this.get('showVoicemailsList') ? '' : _ember['default'].i18n.t('voicemailDrop.ongoingWidgetTooltip');
    }),

    statusUpdateCallback: function statusUpdateCallback(data) {
      var parsedData = JSON.parse(data);
      var userId = this.get('currentUser.id');
      var callPayload = parsedData.call;
      var direction = callPayload.data.attributes.direction;
      var userPayload = callPayload.data.relationships.user.data;

      if (parsedData.notification_type && parsedData.notification_type === 'simultaneous_accept') {
        if (userId === parsedData.user_id) {
          this.sendAction('close');
        }
      }

      if (userPayload !== null && userId === userPayload.id) {
        if (direction === 'outgoing') {
          this.set('telephony.callType.isOutgoing', true);
        } else {
          this.set('telephony.callType.isIncoming', true);
        }
      }
    },

    recordingState: function recordingState() {
      if (this.get('canStartRecording')) {
        return 'begin';
      } else if (!this.get('hasFeaturePauseRecording')) {
        return 'recording';
      }

      return this.get('pauseCall') ? 'pause' : 'resume';
    },
    getPhoneLocation: function getPhoneLocation() {
      var selectedCountry = this.getSelectedCountryData();

      return selectedCountry.name;
    },

    buildCallParams: function buildCallParams(contact) {
      var formattedNumber = this.get('telephony.util').formatNumber(contact.get('contactNumber')),
          params = {
        recipientName: contact.get('name'),
        callerId: this.get('currentAgent.outgoingNumberId'),
        phone_number: formattedNumber, // eslint-disable-line camelcase
        type: 'outgoing',
        powerDialer: true,
        powerDialerListId: this.get('customPowerDialer.id'),
        record_type: this.get('recordType'), // eslint-disable-line camelcase
        contactId: contact.get('id'),
        FCNumber: this.get('activeFreshfoneNumber').attributes.number
      };

      return params;
    },

    hangupParams: function hangupParams() {
      return {
        id: this.get('currentCall.id'),
        userId: this.get('currentUser.id'),
        type: 'agent'
      };
    },

    disconnectParallelCalls: function disconnectParallelCalls() {
      var _this2 = this;

      if (!(this.get('disconnectingFromControls') || this.get('resuming'))) {
        this.set('disconnectingFromControls', true);

        var _get4 = this.get('parallelCalls');

        var _get42 = _slicedToArray(_get4, 1);

        var parallelCall = _get42[0];
        var group = this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? parallelCall.id : this.get('primaryCallGroupId');

        this.get('telephony').getCurrentCall().disconnectParallelCall(group, this.get('currentCall.callType'), _freshfoneEmberConstantsDisconnectSourceType['default'].Controls).then(function () {
          _this2.get('parallelCall').endCall(_this2.get('currentCall.callType'), _freshfoneEmberConstantsDisconnectSourceType['default'].Controls);
        })['catch'](function (error) {
          run(function () {
            _this2.set('disconnectingFromControls', false);
            _this2.logError(error, '- Failed to disconnect ' + _this2.get('currentCall.callType') + ' call');
            _this2.showErrorMsg(error, _ember['default'].i18n.t('parallel_call.disconnect_failed'));
          });
        });
      }
    },

    disconnectParkedCall: function disconnectParkedCall() {
      var _this3 = this;

      if (!this.get('disconnectingFromControls')) {
        this.set('disconnectingFromControls', true);
        this.get('telephony').getCurrentCall().disconnectParkedCall().then(function () {
          _this3.set('manualRecordingStarted', false);
          _this3.get('recordTypeService').setCallBackCall(false);
          if (_this3.get('isAgentInPowerDialerMode')) {
            _this3.set('powerDialerService.removeContact', true);
          }
        })['catch'](function (error) {
          run(function () {
            _this3.logError(error, '- Failed to disconnect parked call');
            _this3.showErrorMsg(error, _ember['default'].i18n.t('call_park.disconnect_failed'));
          });
        })['finally'](function () {
          run(function () {
            _this3.get('telephony').updateCurrentCall(_this3.get('telephony').getCurrentCall());
            _this3.set('disconnectingFromControls', false);
          });
        });
      }
    },

    logMessage: function logMessage(message) {
      // eslint-disable-next-line no-console
      console.log(message);
    },

    logError: function logError(err, message) {
      // eslint-disable-next-line no-console
      console.error(message, err);
    },

    toggleHoldBtn: function toggleHoldBtn() {
      var _this4 = this;

      this.set('holdLoading', true);

      var _get5 = this.get('parallelCalls');

      var _get52 = _slicedToArray(_get5, 1);

      var parallelCall = _get52[0];
      var group = this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? parallelCall.id : this.get('primaryCallGroupId');

      this.get('telephony').getCurrentCall().holdCall(group).then(function (action) {
        _this4.get('parallelCall').holdCall(action);
      })['catch'](function (error) {
        _this4.logError(error, '- Failed to hold/unhold ' + _this4.get('currentCall.callType') + ' call');
      })['finally'](function () {
        run(function () {
          _this4.set('holdLoading', false);
        });
      });
    },
    saveCallTags: function saveCallTags(callId) {
      if (this.get('hasNoCallTags')) {
        return;
      }
      var callTags = this.get('currentCall.tags').mapBy('id');

      this.get('telephony').saveCallTags({
        call_id: callId,
        tag_ids: callTags
      });
    },
    saveCallNotes: function saveCallNotes(callId) {
      var callNote = this.get('currentCall.notes');

      return this.get('telephony').saveConversation({
        call_id: callId,
        note: callNote
      });
    },
    queueTransfer: function queueTransfer(id, data) {
      return $.ajax({
        url: '/transfer/' + id + '/initiate_queue_transfer',
        type: 'POST',
        dataType: 'json',
        data: data
      });
    },
    queueTransferToastNotification: function queueTransferToastNotification(resultStatus, contactName, targetQueueName) {
      if (resultStatus === 'success') {
        return this.get('notifications').success(this.get('i18n').t('transfer.queue_transfer_success', {
          contactName: contactName,
          targetQueueName: targetQueueName
        }));
      }

      return this.get('notifications').error(this.get('i18n').t('transfer.queue_transfer_failure', {
        contactName: contactName,
        targetQueueName: targetQueueName
      }));
    },
    initiateTransferCall: function initiateTransferCall(params) {
      this.saveCallNotes(params.id);
      this.saveCallTags(params.id);
      this.queueTransferToastNotification('success', params.contactName, params.targetQueueName);
    },
    initiateQueueTransfer: function initiateQueueTransfer(params) {
      var _this5 = this;

      this.queueTransfer(params.id, params.queueTransferData).done(function () {
        run(function () {
          _this5.initiateTransferCall(params);
          _this5.set('isPreviousCalltransferredToQueue', true);
        });
      }).fail(function () {
        run(function () {
          _this5.queueTransferToastNotification('error', params.contactName, params.targetQueueName);
        });
      }).always(function () {
        return run(function () {
          return _this5.set('showQueueTransfer', false);
        });
      });
    },

    actions: {
      triggerDisableNotes: function triggerDisableNotes(status) {
        this.sendAction('triggerDisableNotes', status);
      },

      close: function close() {
        var _this6 = this;

        this.get('mkpAdapter').triggerEvent({
          type: 'call.saveAndClose',
          data: this.get('mkpData').getData('currentCall')
        }).then(function () {
          return _this6.sendAction('close');
        })['catch'](function (error) {
          return _this6.get('toastr').error(error.message);
        });
      },
      toggleMinimize: function toggleMinimize() {
        this.set('currentCall.minimized', false);
      },
      toggleMute: function toggleMute() {
        this.get('telephony').toggleMute();
        this.get('mkpAdapter').triggerEvent({
          type: 'call.muteToggled',
          data: this.get('mkpData').getData('currentCall')
        });
      },
      toggleHold: function toggleHold() {
        var _this7 = this;

        if (this.get('parallelCallActive')) {
          this.toggleHoldBtn();
        } else {
          this.set('holdLoading', true);
          this.get('telephony').toggleHold().then(function () {
            _this7.toggleProperty('currentCall.isOnHold');
            _this7.get('mkpAdapter').triggerEvent({
              type: 'call.holdToggled',
              data: _this7.get('mkpData').getData('currentCall')
            });
            _this7.set('holdLoading', false);
          })['catch'](function (error) {
            _this7.logMessage(error);
            _this7.set('holdLoading', false);
          });
        }
      },
      toggleEnd: function toggleEnd() {
        var params = this.hangupParams();

        this.set('showCsatModal', false);
        this.get('hangupService').registerHangUpEvent(params);

        if (this.get('parallelCallActive')) {
          this.disconnectParallelCalls();

          return;
        }

        if (this.get('currentCallIsParked')) {
          this.disconnectParkedCall();

          return;
        }

        this.get('telephony').hangup('ongoing-widget/call-controls/component.js#actions.toggleEnd');
        this.set('manualRecordingStarted', false);
        this.get('recordTypeService').setCallBackCall(false);
        if (this.get('isAgentInPowerDialerMode')) {
          this.set('powerDialerService.removeContact', true);
        }
      },
      transferToCsatIvr: function transferToCsatIvr() {
        var params = this.hangupParams();

        this.set('showCsatModal', false);
        this.get('hangupService').registerHangUpEvent(params);
        this.get('telephony').transferToCsatIvr(_freshfoneEmberConstantsDisconnectSourceType['default'].Controls);
        if (this.get('isAgentInPowerDialerMode')) {
          this.set('currentCall.isCompleted', true);
          this.sendAction('fetchNextContact');
        }
      },
      toggleVoicemailsList: function toggleVoicemailsList() {
        this.toggleProperty('showVoicemailsList');
      },
      openCsatModal: function openCsatModal() {
        this.set('showCsatModal', true);
      },
      closeCsatModal: function closeCsatModal() {
        this.set('showCsatModal', false);
      },
      dropVoicemailAndEndCall: function dropVoicemailAndEndCall(msgId) {
        var _this8 = this;

        this.set('voicemailDropInProgress', true);
        this.get('telephony').initiateVoicemailDrop({
          id: this.get('currentCall.id'),
          messageId: msgId
        }).then(function () {
          _this8.get('notifications').info(_ember['default'].i18n.t('voicemailDrop.initiatedNotification'));
          _this8.get('telephony').hangup('ongoing-widget/call-controls/component.js#actions.dropVoicemailAndEndCall');
        })['catch'](function () {
          _this8.set('voicemailDropInProgress', false);
          _this8.get('notifications').error(_ember['default'].i18n.t('voicemailDrop.errorNotification'));
        });
        this.set('showVoicemailsList', false);
      },
      startToRecord: function startToRecord() {
        var _this9 = this;

        if (this.get('telephony').getCurrentCall() && !this.get('recordingActive')) {
          this.set('recordingActive', true);
          this.get('telephony').recordOnCall({ id: this.get('currentCall.id') }).then(function () {
            _this9.get('mkpAdapter').triggerEvent({
              type: 'call.recordingStarted',
              data: _this9.get('mkpData').getData('currentCall')
            });
            if (_this9.get('isManualRecording') && _this9.get('recordingActive')) {
              _this9.set('manualRecordingStarted', true);
            }
          })['catch'](function (error) {
            _this9.logMessage(error);
            _this9.set('recordingActive', false);
            _this9.set('manualRecordingStarted', false);
          });
        }
      },
      handleInprogressRecording: function handleInprogressRecording() {
        var _this10 = this;

        if (this.get('telephony').getCurrentCall() && this.get('hasFeaturePauseRecording')) {
          (function () {
            var status = _this10.get('pauseCall') ? 'paused' : 'in-progress';

            _this10.get('telephony').handleInprogressRecording({
              id: _this10.get('currentCall.id'),
              status: status
            }).then(function () {
              _this10.get('mkpAdapter').triggerEvent({
                type: 'call.recording' + (status === 'paused' ? 'Paused' : 'Resumed'),
                data: _this10.get('mkpData').getData('currentCall')
              });
            })['catch'](function (error) {
              _this10.logMessage(error);
              _this10.toggleProperty('pauseCall');
            });

            _this10.toggleProperty('pauseCall');
          })();
        }
      },
      updateContact: function updateContact() {
        this.sendAction('updateContact', this.get('lastSearchedContact'), false);
        this.set('pickerUsed', true);
        this.send('closeDropdown');
      },
      createNewContact: function createNewContact() {
        this.sendAction('createNewContact');
        this.set('pickerUsed', true);
        this.send('closeDropdown');
      },
      bindKeyAction: function bindKeyAction(key) {
        this.get('telephony').sendDigits(key);
      },
      noteChange: function noteChange() {
        if (this.get('currentCall.noteFromSourceAgent')) {
          this.set('currentCall.noteFromSourceAgent', false);
        }
      },
      hideVoicemailsList: function hideVoicemailsList() {
        this.set('showVoicemailsList', false);
      },
      stopPowerDialerMode: function stopPowerDialerMode() {
        var _this11 = this;

        this.store.peekAll('power-dialer-list').get('lastObject').stop().then(function () {
          _this11.set('currentAgent.powerDialer', false);
          _this11.sendAction('close');
        });
      },
      dialNext: function dialNext() {
        var _this12 = this;

        if (this.get('disablePowerDialer')) {
          this.set('powerDialerService.leftContacts', this.get('powerDialerService.leftContacts') + 1);
          this.set('contactFetched', true);
        }
        this.sendAction('dialNextAndSave');
        this.set('errorMessage', null);
        var callParams = this.buildCallParams(this.get('nextContact')),
            additionalParams = { fcNumberId: this.get('activeFreshfoneNumber').attributes.id },
            params = {
          powerDialer: callParams.powerDialer,
          powerDialerListId: callParams.powerDialerListId
        };

        this.get('telephony.util').isValidNumber(callParams.phone_number, params, additionalParams).then(function () {
          _this12.get('telephony').makeCall(callParams);
        })['catch'](function (error) {
          _this12.setProperties({
            'errorMessage': error.responseJSON.error,
            'dialedContacts': _this12.get('dialedContacts') + 1,
            'powerDialerService.removeContact': true,
            'inValidNumber': callParams.phone_number
          });
          _this12.sendAction('fetchNextContact');
        });
      },
      resumeCall: function resumeCall() {
        this.sendAction('resumeCall');
      },
      mergeCall: function mergeCall() {
        var _this13 = this;

        this.set('merging', true);
        this.get('telephony').getCurrentCall().mergeCall(this.get('primaryCallGroupId'))['catch'](function (error) {
          run(function () {
            _this13.set('merging', false);
            _this13.logError(error, '- Failed to merge ' + _this13.get('primaryCallGroupId') + ' call');
            _this13.showErrorMsg(error, _ember['default'].i18n.t('parallel_call.merge_call_failed'));
          });
        });
      },
      forwardToQueue: function forwardToQueue(callQueueId, callQueueName) {
        this.set('targetQueueName', callQueueName);
        var callId = this.get('currentCall.id'),
            queueTransferData = {
          id: callId,
          // eslint-disable-next-line camelcase
          target_id: callQueueId
        },
            params = {
          id: callId,
          contactName: this.get('displayName'),
          targetQueueName: callQueueName,
          queueTransferData: queueTransferData
        };

        this.initiateQueueTransfer(params);
      },
      parkCall: function parkCall() {
        if (this.get('disableParkButton')) {
          return;
        }

        this.sendAction('parkCall');
      }
    }
  });
});
/* eslint-disable camelcase */
/* eslint-disable one-var */