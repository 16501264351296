define('freshfone-ember/components/cx-fails-bot-widget/component', ['exports', 'ember', 'freshfone-ember/config/environment', 'freshfone-ember/utils/inject-cx-fails-bot'], function (exports, _ember, _freshfoneEmberConfigEnvironment, _freshfoneEmberUtilsInjectCxFailsBot) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    classNames: ['cx-fails-bot-container'],
    session: service(),
    currentUser: computed.alias('session.user'),
    userName: computed.alias('currentUser.name'),
    userEmail: computed.alias('currentUser.email'),

    cxFailsBotParams: computed(function () {
      return {
        userName: this.get('userName'),
        userEmail: this.get('userEmail'),
        env: _freshfoneEmberConfigEnvironment['default'].fcaEnvs.production.includes(_freshfoneEmberConfigEnvironment['default'].fcaEnv) ? 'prod' : 'test'
      };
    }),

    init: function init() {
      this._super.apply(this, arguments);
      (0, _freshfoneEmberUtilsInjectCxFailsBot.injectCxFailsBotWidget)(this.get('cxFailsBotParams'));
    }
  });
});