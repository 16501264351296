define('freshfone-ember/routes/protected/admin/ivr/manage-intents', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('navigation.call_workflows');
    }),
    breadCrumb: {},
    features: ['speech_enabled_ivr'],
    queryParams: {
      botName: {},
      ivrId: {}
    },
    model: function model(params) {
      if (isPresent(params.botName)) {
        this.set("botName", params.botName);
      }
      if (isPresent(params.ivrId)) {
        this.set("ivrId", params.ivrId);
      }
    },
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
    },
    afterModel: function afterModel() {
      var title = get(this, 'botName');
      set(this, 'breadCrumb.title', title);
    }
  });
});