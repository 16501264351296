define("freshfone-ember/components/billing-base/credit-actions/credit-autorecharge-threshold/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/credit-autorecharge-threshold/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "credits-worth-usd");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["billing.credits.credit_worth_usd"], ["credit", ["subexpr", "@mut", [["get", "thresholdAmountInUSD", ["loc", [null, [8, 82], [8, 102]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 36], [8, 104]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/credit-autorecharge-threshold/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "icon-warning");
          dom.setAttribute(el1, "data-toggle", "tooltip");
          dom.setAttribute(el1, "data-placement", "right");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'data-original-title');
          return morphs;
        },
        statements: [["attribute", "data-original-title", ["get", "autoRechargeThresholdError", ["loc", [null, [11, 98], [11, 124]]], 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 0
            },
            "end": {
              "line": 32,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/billing-base/credit-actions/credit-autorecharge-threshold/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "billing-base/credit-actions/options-dropdown", [], ["values", ["subexpr", "@mut", [["get", "credits.autoRechargeThresholdOptions", ["loc", [null, [22, 11], [22, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "isActionInprogress", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [23, 23], [23, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedValue", ["subexpr", "@mut", [["get", "thresholdAmount", ["loc", [null, [24, 18], [24, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "currencySymbol", ["subexpr", "@mut", [["get", "currencySymbol", ["loc", [null, [25, 19], [25, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "subscription", ["subexpr", "@mut", [["get", "subscription", ["loc", [null, [26, 17], [26, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "isDefaultCurrency", ["subexpr", "@mut", [["get", "isDefaultCurrency", ["loc", [null, [27, 22], [27, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "actionText", ["subexpr", "t", ["billing.autorecharge.save"], [], ["loc", [null, [28, 15], [28, 46]]], 0, 0], "savingText", ["subexpr", "t", ["billing.autorecharge.saving"], [], ["loc", [null, [29, 15], [29, 48]]], 0, 0], "callbackAction", ["subexpr", "action", ["saveAutoRechargeThreshold"], [], ["loc", [null, [30, 19], [30, 55]]], 0, 0]], ["loc", [null, [21, 2], [31, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/billing-base/credit-actions/credit-autorecharge-threshold/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "title");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "title-text");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "icon-help");
        dom.setAttribute(el2, "data-toggle", "tooltip");
        dom.setAttribute(el2, "data-placement", "top");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "autorecharge-amount");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "data-analytics-action-name", "Auto Recharge Threshold");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(fragment, [4]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[1] = dom.createAttrMorph(element2, 'data-original-title');
        morphs[2] = dom.createMorphAt(element4, 0, 0);
        morphs[3] = dom.createMorphAt(element4, 2, 2);
        morphs[4] = dom.createMorphAt(element3, 3, 3);
        morphs[5] = dom.createMorphAt(element3, 4, 4);
        morphs[6] = dom.createAttrMorph(element5, 'class');
        morphs[7] = dom.createAttrMorph(element5, 'title');
        morphs[8] = dom.createElementMorph(element5);
        morphs[9] = dom.createMorphAt(element5, 1, 1);
        morphs[10] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["billing.autorecharge.auto-recharge-threshold"], [], ["loc", [null, [2, 27], [2, 79]]], 0, 0], ["attribute", "data-original-title", ["subexpr", "t", ["billing.autorecharge.threshold_tooltip"], [], ["loc", [null, [null, null], [3, 135]]], 0, 0], 0, 0, 0, 0], ["content", "currencySymbol", ["loc", [null, [6, 36], [6, 54]]], 0, 0, 0, 0], ["content", "thresholdAmount", ["loc", [null, [6, 55], [6, 74]]], 0, 0, 0, 0], ["block", "unless", [["get", "isDefaultCurrency", ["loc", [null, [7, 12], [7, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 2], [9, 13]]]], ["block", "if", [["get", "autoRechargeThresholdError", ["loc", [null, [10, 8], [10, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [10, 2], [12, 9]]]], ["attribute", "class", ["concat", ["autorecharge-credits font-bold rl-dropdown-toggle ", ["subexpr", "if", [["subexpr", "or", [["get", "isAccountBlocked", ["loc", [null, [14, 69], [14, 85]]], 0, 0, 0, 0], ["get", "isSaving", ["loc", [null, [14, 86], [14, 94]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 65], [14, 95]]], 0, 0], "not-allowed"], [], ["loc", [null, [14, 60], [14, 111]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "t", ["billing.credits.purchase"], [], ["loc", [null, [null, null], [15, 40]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleDropdown"], [], ["loc", [null, [17, 2], [17, 29]]], 0, 0], ["inline", "t", ["billing.autorecharge.edit"], [], ["loc", [null, [18, 2], [18, 35]]], 0, 0], ["block", "if", [["get", "dropdownExpanded", ["loc", [null, [20, 6], [20, 22]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [20, 0], [32, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});