define('freshfone-ember/routes/protected/admin/callflows/new', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    breadCrumb: {},
    callFlow: service('call-flow'),
    i18n: service(),
    titleToken: computed(function () {
      return this.get('i18n').t('navigation.new_flow');
    }),
    queryParams: {
      editNumber: {},
      actionFor: {}
    },
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_call_queues');
      this.checkFeature('no_call_queues_restrictions');
    },

    model: function model(params) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {

        _this.get('callFlow').getQueueCount().then(function (count) {
          var newFlow = _this.store.peekAll('callFlow').findBy('isNew', true);

          run(function () {
            var newQueue = _this.store.peekAll('callQueue').findBy('isNew', true);

            newFlow = newFlow || _this.store.createRecord('callFlow');

            newQueue = newQueue || _this.store.createRecord('callQueue');

            newFlow.set('callQueue', newQueue);
            newFlow.set('name', _this.get('i18n').t('entityNames.new_main_queue') + ' ' + (count + 1));

            if (params.rule) {
              _this.set('rule', params.editNumber);
            }
          });

          resolve(newFlow);
        });
      });
    },

    afterModel: function afterModel(model) {
      var title = get(model, 'name');

      set(this, 'breadCrumb.title', title);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('rule', undefined);
      }
    }

  });
});