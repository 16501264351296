define('freshfone-ember/components/dashboard-component/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    currentUser: computed.alias('session.user'),
    showAgentMetrics: computed(function () {
      return this.hasGlobalFeature('agent_dashboard_enhancement');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      run.debounce(this, this.getTodos, 10);
    },

    getTodos: function getTodos() {
      var _this = this;

      this.store.query('todo', { user_id: this.get('currentUser.id') }).then(function () {
        _this.set('todosLoaded', true);
      });
    }
  });
});