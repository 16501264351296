define('freshfone-ember/components/message-components/audio-player/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/audio-player'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsAudioPlayer) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var computed = _ember['default'].computed;
  var run = _ember['default'].run;

  var soundManager = window.soundManager;
  var speechSynthesis = window.speechSynthesis;

  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    classNames: ['message-audio-player'],
    hasMessageUrl: computed.notEmpty('message.url'),
    hasMessageText: computed.notEmpty('message.text'),
    hasValidMessage: computed.or('hasMessageText', 'hasMessageUrl'),
    hasInvalidMessage: computed.not('hasValidMessage'),
    hasCancelAction: computed.notEmpty('cancelAction'),
    hasNoPreviewVoice: computed('message.lang', function () {
      if (!this.get('hasMessageText')) {
        return false;
      }
      if (this.checkFeature('message_language')) {
        if (this.get('message.lang') && !this.get('message.lang').startsWith('en')) {
          return true;
        }

        return false;
      }
      if (!this.get('session.account.preferredVoiceLanguage').startsWith('en')) {
        return true;
      }
    }),

    hasNoPreviewVoiceMessage: computed('hasPreviewVoice', function () {
      return _ember['default'].i18n.t('message.message_preview_warning');
    }),

    createAndPlay: function createAndPlay() {
      if (this.get('hasMessageUrl')) {
        this.playWithUrl();
      }
      if (this.get('hasMessageText')) {
        this.playTextMessage();
      }
    },

    playWithUrl: function playWithUrl() {
      var _this = this;

      var sound = this.createSound();
      this.set('sound', sound);
      sound.play({
        onfinish: function onfinish() {
          _this.set('isPlaying', false);
        },
        onstop: function onstop() {
          _this.set('isPlaying', false);
        }
      });
    },

    createSound: function createSound() {
      return soundManager.createSound({ url: this.get('message.url') });
    },
    playTextMessage: function playTextMessage() {
      var _this2 = this;

      var voices = window.speechSynthesis.getVoices();

      if (voices.length) {
        this.playTextThroughSpeechSynthesis(voices);
      } else {
        window.speechSynthesis.addEventListener('voiceschanged', function () {
          var updatedVoices = window.speechSynthesis.getVoices();

          _this2.playTextThroughSpeechSynthesis(updatedVoices);
        });
      }
    },

    playTextThroughSpeechSynthesis: function playTextThroughSpeechSynthesis(availableVoices) {
      var _this3 = this;

      var text = this.get('message.text'),
          utterance = new window.SpeechSynthesisUtterance(),
          voiceType = this.get('message.voice'),
          genderBasedVoices = voiceType ? _freshfoneEmberConstantsAudioPlayer['default'].MALE_VOICE_LIST : _freshfoneEmberConstantsAudioPlayer['default'].FEMALE_VOICE_LIST;

      try {
        var _availableVoices$filter = availableVoices.filter(function (_ref) {
          var availableVoiceName = _ref.name;
          return genderBasedVoices.some(function (voiceName) {
            return availableVoiceName.toLocaleLowerCase().match(voiceName);
          });
        });

        var _availableVoices$filter2 = _slicedToArray(_availableVoices$filter, 1);

        utterance.voice = _availableVoices$filter2[0];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Expected voice not available', e);
        // eslint-disable-next-line no-console
        console.log('Gender Voices to be found', genderBasedVoices);
        // eslint-disable-next-line no-console
        console.log('Available voices on browser', availableVoices);
      }

      utterance.text = text || "";
      utterance.lang = this.get('message.lang');
      utterance.onend = function () {
        run(function () {
          _this3.set('isPlaying', false);
        });
      };
      speechSynthesis.speak(utterance);
    },

    stopPlayingAudio: function stopPlayingAudio() {
      if (!this.get('isPlaying')) {
        return;
      }
      if (this.get('hasMessageUrl')) {
        this.get('sound').stop();
      } else {
        speechSynthesis.cancel();
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.stopPlayingAudio();
    },
    stopAllSounds: function stopAllSounds() {
      soundManager.stopAll();
    },

    actions: {

      togglePlay: function togglePlay() {
        var action = this.get('isPlaying') ? 'stop' : 'play';
        this.send(action);
      },

      play: function play() {
        if (this.get('hasInvalidMessage')) {
          return;
        }
        this.stopAllSounds();
        this.createAndPlay();
        this.set('isPlaying', true);
      },

      stop: function stop() {
        this.stopPlayingAudio();
        this.set('isPlaying', false);
      },

      cancel: function cancel() {
        this.get("cancelAction")();
      },

      setVoice: function setVoice() {
        var _currentVoicetype = this.get('message.messageVoiceType');
        _currentVoicetype = _currentVoicetype === 0 ? 1 : 0;
        this.set('message.messageVoiceType', _currentVoicetype);
        this.setLastUsedVoice(_currentVoicetype);
      },
      setLastUsedVoice: function setLastUsedVoice(type) {
        localStorage.setItem('last-used-voice', type);
      },
      getLastUsedVoice: function getLastUsedVoice() {
        var _lastUsed = localStorage.getItem('last-used-voice');
        return _lastUsed ? _lastUsed : 0;
        // if nothing was not, return 0 => female voice
      }
    }
  });
});