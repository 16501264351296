define('freshfone-ember/components/call-logs/call-log-export/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    notification: service('toastr'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('userConfiguration', this.store.peekAll('user-configuration').get('firstObject'));
      this.selectDefaultExport();
    },

    callLifeCycleFeature: computed(function () {
      return this.hasGlobalFeature('call_lifecycle_global') && this.checkFeature('call_lifecycle');
    }),

    selectDefaultExport: function selectDefaultExport() {
      if (this.get('exportFormat')) {
        if (this.get('exportFormat') === 'xls') {
          this.set('excelAsDefault', true);
          this.set('exportAs', 'xls');
        }
      }
    },

    initiateExport: function initiateExport(exportType, type, exportFields) {
      var _this = this;

      var params = this.paramsForExport(exportType, type, exportFields);

      $.ajax({
        url: '/data_exports/',
        type: 'post',
        dataType: 'json',
        data: params
      }).done(function (result) {
        run(function () {

          if (result.data.id) {
            _this.get('notification').success(_ember['default'].i18n.t('call_log.status_message.success'));
            _this.set('savedExportConfiguration', params);
          } else {
            _this.get('notification').failure(_ember['default'].i18n.t('call_log.status_message.failed'));
          }
        });
      }).fail(function (error) {
        if (error.status === 409) {
          run(function () {
            _this.get('notification').warning(_ember['default'].i18n.t('call_log.status_message.inprogress'));
          });
        }
      });
    },

    paramsForExport: function paramsForExport(exportType, type, exportFields) {
      var exportParams = {
        export_to: exportType,
        send_mailer: true,
        type: type,
        export_attributes: []
      },
          filterParams = this.store.adapterFor('call').formatCallParams(this.get('callFilterParams'));

      if (type === "calls") {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = exportFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _step$value = _slicedToArray(_step.value, 2);

            var key = _step$value[0];
            var value = _step$value[1];

            value.isChecked && exportParams.export_attributes.push(key);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
      return Object.assign(exportParams, filterParams);
    },

    actions: {
      openExportDetailsSlider: function openExportDetailsSlider() {
        this.set('showExportDetails', true);
        this.set('openedExportFilter', true);
      },

      closeExportDetailsSlider: function closeExportDetailsSlider() {
        this.set('showExportDetails', false);
        this.set('openedExportFilter', false);
      },
      closeCallLifeCycleExportSlider: function closeCallLifeCycleExportSlider() {
        this.set('showCallLifeCycleExport', false);
      },

      triggerExport: function triggerExport(exportType, type, exportFields) {
        this.initiateExport(exportType, type, exportFields);
      }
    }
  });
});