define('freshfone-ember/components/ongoing-widget/maximized-widget/component', ['exports', 'freshfone-ember/constants/active-call-type', 'freshfone-ember/constants/call-state', 'freshfone-ember/constants/call-status', 'freshfone-ember/constants/disconnect-source-type', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/park-state', 'freshfone-ember/constants/snooze-type', 'freshfone-ember/constants/search-query-constants', 'freshfone-ember/utils/search-util'], function (exports, _freshfoneEmberConstantsActiveCallType, _freshfoneEmberConstantsCallState, _freshfoneEmberConstantsCallStatus, _freshfoneEmberConstantsDisconnectSourceType, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsParkState, _freshfoneEmberConstantsSnoozeType, _freshfoneEmberConstantsSearchQueryConstants, _freshfoneEmberUtilsSearchUtil) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var RSVP = _ember['default'].RSVP;
  var _Ember$run = _ember['default'].run;
  var scheduleOnce = _Ember$run.scheduleOnce;
  var later = _Ember$run.later;
  var testing = _ember['default'].testing;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  var _Ember$computed = _ember['default'].computed;
  var equal = _Ember$computed.equal;
  var or = _Ember$computed.or;
  var alias = _Ember$computed.alias;
  var observer = _ember['default'].observer;
  var EmberPromise = RSVP.Promise;
  var notificationSnoozeIconEle = '.warning-snoozed-container .snooze-icon';
  var networkWarningTooltipEleId = 'networkWarningTooltip';exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    account: computed.alias('session.account'),
    mkpAppsFilter: { location: 'conversation_card' },
    telephony: service(),
    parallelCall: service(),
    i18n: service(),
    powerDialerService: service('power-dialer-list'),
    notifications: service('toastr'),
    transition: service('state-transition'),
    parkedCallsService: service('parked-calls'),

    totalContactsInPowerDialer: computed.alias('powerDialerService.totalContactsInPowerDialer'),
    leftContactsInPowerDialer: computed.alias('powerDialerService.leftContactsInPowerDialer'),
    isSearchingContact: computed.notEmpty('searchTextForContact'),
    currentCall: computed.alias('telephony.currentCall'),
    currentTime: computed.alias('currentCall.callDuration'),
    parallelCallTime: computed.alias('currentCall.parallelCallDuration'),
    isEnded: alias('telephony.callCompleted'),
    callInProgress: computed.not('isEnded'),
    callerCompanyName: computed.alias('currentCall.contact.company.name'),
    isAgentInPowerDialerMode: computed.alias('currentAgent.powerDialer'),
    hasCompanyName: computed.notEmpty('callerCompanyName'),
    hasRecentCalls: true,
    showVoicemailsList: false,
    searchString: '',
    toggleSearch: false,
    contact: computed.alias('currentCall.contact'),
    initialContact: computed.alias('currentCall.initialContact'),
    hasPickedContact: computed.notEmpty('pickedContact'),
    canShowFilter: computed.or('hasPickedContact', 'hasContact'),
    hasSearchResults: computed.notEmpty('searchResults'),
    isActiveSearch: computed.or('hasSearchResults', 'hasRecentCalls'),
    showContactSearch: computed.or('currentCall.isNewCaller', 'toggleSearch'),
    hasContact: computed.notEmpty('contact'),
    changeContact: false,
    hasNoSearchResults: computed.not('hasSearchResults'),
    isCallsEmpty: computed.alias('conversationList.isCallsEmpty'),
    hasCalls: computed.not('isCallsEmpty'),
    hasNoResultsAndCalls: computed.and('isCallsEmpty', 'hasNoSearchResults'),
    emptySearchText: computed.empty('noteSearchText'),
    hasSearchText: computed.not('emptySearchText'),
    calledWithContact: computed.readOnly('currentCall.callerName'),
    isCalledWithContact: computed.bool('calledWithContact'),
    hasContactForSideBar: computed.notEmpty('contactForSideBar'),
    isContactHaveName: computed.notEmpty('contact.name'),
    isContactHavePhone: computed.notEmpty('contact.phone'),
    isContactHaveNameAndPhone: computed.and('isContactHaveName', 'isContactHavePhone'),
    isContactDirty: computed.equal('contact.dirtyType', 'updated'),
    isCalledContactUnchanged: computed.equal('contact.id', 'calledWithContact.id'),
    isCalledContactChanged: computed.not('isCalledContactUnchanged'),
    isContactChanged: computed.or('isContactDirty', 'isCalledContactChanged'),
    isContactLinkable: computed.and('isContactChanged', 'isContactHaveNameAndPhone'),
    isSavedContact: computed.not('currentCall.contact.isNew'),
    hasUnsavedContact: computed.and('hasContact', 'isSavedContact'),
    canShowUserAvatar: computed.or('isContactLinkable', 'hasUnsavedContact'),
    isColdTransfer: computed.equal('currentCall.status', _freshfoneEmberConstantsCallStatus['default'].Transferred),
    hasNoCallTags: computed.empty('currentCall.tags'),
    isToRedirectDeskUrl: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    redirectUrl: computed('account.omnichannelOwnerDomain', function () {
      return this.get('account.omnichannelOwnerDomain') + '/a/org/contacts';
    }),
    resuming: computed.alias('parallelCall.loadingStatus.resuming'),
    merging: computed.alias('parallelCall.loadingStatus.merging'),
    parking: computed.alias('parkedCallsService.parking'),
    disconnectingFromBanner: computed.alias('parallelCall.loadingStatus.disconnectingFromBanner'),
    disconnectingFromControls: computed.alias('parallelCall.loadingStatus.disconnectingFromControls'),
    editRedirectUrl: computed('redirectUrl', 'contact.id', function () {
      return this.get('redirectUrl') + '/' + this.get('contact.id') + '?action=edit';
    }),
    isLoading: computed.or('resuming', 'merging', 'disconnectingFromBanner', 'disconnectingFromControls', 'showConferenceStatus', 'isCsatIvrTransfer', 'parking'),
    showRevert: computed('contact', 'initialContact', 'pickedContact', function () {
      return this.get('contact') !== this.get('initialContact') || this.get('selectedContact');
    }),
    otherContactsIds: computed.alias('currentCall.otherContactsIds'),
    numberHasOtherContacts: computed('otherContactsIds', function () {
      return this.get('otherContactsIds').length > 1;
    }),
    showNewContact: computed('selectedContact', function () {
      return this.get('selectedContact.isNewContact') && !this.get('selectedContact.id');
    }),
    showTalkerName: computed(function () {
      return this.hasGlobalFeature('show_talker_name');
    }),
    hasCallTaggingFeature: computed(function () {
      return this.checkFeature('call_tagging');
    }),
    contactForSideBar: computed('hasPickedContact', 'hasContact', function () {
      if (this.get('hasPickedContact')) {
        return this.get('pickedContact');
      }
      if (this.get('hasContact')) {
        return this.get('contact');
      }
    }),
    displayName: computed('currentCall.callerName', 'contact.name', 'pickedContact.name', function () {
      if (this.get('hasPickedContact')) {
        return this.get('pickedContact.name');
      }
      var _model = this.get('contact');

      if (_model && _model.get) {
        if (_model.get('name')) {
          return _model.get('name');
        }
        if (_model.get('isNew')) {
          return this.get('i18n').t('contact.new_contact');
        }
      }

      return this.get('currentCall.callerName');
    }),
    modalTitle: computed('currentCall.callerName', {
      get: function get() {
        return this.get('currentCall.callerName');
      }
    }),
    isNewContact: computed('contact', function () {
      return this.get('contact.isNew');
    }),
    isSecondaryAgent: computed.equal('currentCall.type', 'agent_conference'),
    currentUser: computed.alias('session.user'),
    currentAccount: computed.alias('session.account'),
    trialAccount: computed.alias('currentAccount.isTrial'),
    trialRestrictedAccount: computed.and('trialAccount', 'currentAccount.restrictedTrial'),
    currentAgent: computed.alias('currentUser.agent'),
    isInProgressCall: computed.and('currentAgent', 'currentCall'),
    successfulTransfer: computed.equal('currentCall.status', 'transferred'),
    isTransfer: computed('successfulTransfer', 'currentCall.previousStatus', function () {
      return this.get('successfulTransfer') || this.get('currentCall.previousStatus') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferred;
    }),
    isTransferOrConferenceCall: computed('isSecondaryAgent', 'isTransfer', function () {
      return this.get('isSecondaryAgent') || this.get('isTransfer');
    }),
    showingMkpApp: computed('openMkpAppIndex', {
      get: function get() {
        return this.get('openMkpAppIndex') !== undefined;
      }
    }),
    voicemails: computed(function () {
      return this.store.peekAll('message').filter(function (message) {
        return message.get('messageScope') === 1 && !message.get('isNew');
      }).sortBy('default').reverse();
    }),

    hasEnhancedInconversationSearchFeature: computed(function () {
      return this.hasGlobalFeature('enhanced_inconversation_search');
    }),
    parallelCalls: computed.alias('telephony.parallelCalls'),
    showOnHoldMessage: computed('currentCall.status', 'resuming', 'closeAndResume', function () {
      return this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].ParallelCallInitiated || this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].ParallelCallCancelling || this.get('resuming') && !this.get('closeAndResume');
    }),
    isSecondaryParallelCallActive: computed.alias('currentCall.isParallelCall'),
    isAgentParallelCallActive: computed.alias('currentCall.isAgentParallelCallActive'),
    isParallelCallResumed: alias('telephony.isParallelCallResumed'),
    isAgentParallelCallAcepted: computed('currentCall.status', 'isSecondaryParallelCallActive', function () {
      return this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].ParallelCallAccepted && !this.get('isSecondaryParallelCallActive');
    }),
    isAgentParallelCallMerged: computed.alias('currentCall.isMerged'),
    customerContact: computed('hasContactForSideBar', 'displayName', 'hasCompanyName', 'callerCompanyName', 'contactForSideBar', function () {
      return {
        hasContactForSideBar: this.get('hasContactForSideBar'),
        displayName: this.get('displayName'),
        hasCompanyName: this.get('hasCompanyName'),
        callerCompanyName: this.get('callerCompanyName'),
        contactForSideBar: this.get('contactForSideBar'),
        text: this.get('displayName')
      };
    }),
    secondaryContact: alias('parallelCall.parallelContact'),
    agentContact: computed('currentCall.from', function () {
      var contact = this.get('currentCall.from');

      return {
        hasContactForSideBar: false,
        displayName: contact.name,
        contactForSideBar: contact,
        text: contact.name
      };
    }),
    primaryCaller: computed('isSecondaryParallelCallActive', 'isParallelCallResumed', 'customerContact', 'secondaryContact', 'agentContact', function () {
      if (this.get('isSecondaryParallelCallActive')) {
        return this.get('agentContact');
      }

      return this.get('isParallelCallResumed') ? this.get('secondaryContact') : this.get('customerContact');
    }),
    secondaryCaller: computed('currentCall.callType', 'customerContact', 'secondaryContact', function () {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('customerContact') : this.get('secondaryContact');
    }),
    closeAndResume: computed.alias('telephony.closeAndResume'),
    conferenceCloseAndResume: computed.alias('telephony.conferenceCloseAndResume'),
    conferenceActive: computed.alias('telephony.conferenceActive'),
    activeConferenceOnHold: computed.alias('telephony.activeConferenceOnHold'),
    showConferenceMsg: computed.or('conferenceCloseAndResume', 'conferenceActive', 'activeConferenceOnHold'),
    currentCallDirection: computed('currentCall.isOutgoing', 'isParallelCallResumed', 'isSecondaryParallelCallActive', 'isAgentParallelCallMerged', 'showConferenceMsg', function () {
      var direction = this.get('currentCall.isOutgoing') ? this.get('i18n').t('call_log.call_filters.outgoing') : this.get('i18n').t('call_log.call_filters.incoming');

      if (this.get('isAgentParallelCallMerged')) {
        return this.get('i18n').t('parallel_call.conference');
      }
      if (this.get('isParallelCallResumed')) {
        if (this.get('showConferenceMsg')) {
          return this.get('i18n').t('parallel_call.parallel_conference');
        }

        return this.get('i18n').t('parallel_call.parallel');
      }
      if (this.get('isSecondaryParallelCallActive')) {
        return this.get('i18n').t('parallel_call.incoming_parallel');
      }

      if (this.get('showConferenceMsg')) {
        return direction + ' ' + this.get('i18n').t('parallel_call.conference');
      }

      return direction;
    }),
    callStatus: computed.alias('currentCall.status'),
    primaryCallOrConferenceEnded: computed.alias('telephony.primaryCallOrConferenceEnded'),
    parallelCallOrConferenceEnded: computed.alias('telephony.parallelCallOrConferenceEnded'),
    showCallEnded: computed('primaryCallOrConferenceEnded', 'parallelCallOrConferenceEnded', function () {
      if (this.get('primaryCallOrConferenceEnded') || this.get('parallelCallOrConferenceEnded')) {
        this.set('disconnectingFromBanner', false);

        return true;
      }
    }),
    hideMainContactInfoCard: computed.alias('telephony.mainCallsRemoved'),
    primaryCallAndConferenceRemoved: computed('currentCall.{callType,callState,conferenceState}', function () {
      var callType = this.get('currentCall.callType'),
          callState = this.get('currentCall.callState'),
          conferenceState = this.get('currentCall.conferenceState');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel && (!conferenceState && callState === _freshfoneEmberConstantsCallState['default'].Removed || conferenceState === _freshfoneEmberConstantsCallState['default'].Removed && callState === _freshfoneEmberConstantsCallState['default'].Removed);
    }),
    parallelCallAndConferenceRemoved: computed('currentCall.callType', 'parallelCalls.[]', 'parallelCalls.@each.{state,conferenceState}', function () {
      var callType = this.get('currentCall.callType');

      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];
      var state = parallelCall && parallelCall.get('state');
      var conferenceState = parallelCall && parallelCall.get('conferenceState');

      if (!parallelCall) {
        return true;
      }

      return callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && (!conferenceState && state === _freshfoneEmberConstantsCallState['default'].Removed || conferenceState === _freshfoneEmberConstantsCallState['default'].Removed && state === _freshfoneEmberConstantsCallState['default'].Removed);
    }),
    hideParallelCallBanner: computed.or('isAgentParallelCallMerged', 'currentCallCompleted', 'primaryCallAndConferenceRemoved', 'parallelCallAndConferenceRemoved'),
    currentCallCompleted: computed.alias('currentCall.isCompleted'),
    primaryHoldDuration: computed.alias('currentCall.primaryHoldDuration'),
    secondaryHoldDuration: computed.alias('currentCall.secondaryHoldDuration'),
    showParallelCallTimer: computed('currentCall.callType', 'parallelCall.callHasMerged', function () {
      return this.get('currentCall.callType') && this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel && !this.get('parallelCall.callHasMerged');
    }),
    parallelCallExist: computed('parallelCalls.[]', function () {
      return this.get('parallelCalls').length;
    }),
    primaryCallGroupId: computed.alias('currentCall.groupId'),
    conferenceInitiated: computed.alias('telephony.conferenceInitiated'),
    conferenceEnded: computed.alias('telephony.conferenceEnded'),
    conferenceUnavailable: computed.alias('telephony.conferenceUnavailable'),
    showConferenceStatus: computed.or('conferenceInitiated', 'conferenceEnded', 'conferenceUnavailable'),
    showParallelCallStatus: computed('isAgentParallelCallActive', 'showConferenceStatus', 'isCsatIvrTransfer', function () {
      return (this.get('isAgentParallelCallActive') || this.get('isCsatIvrTransfer')) && !this.get('showConferenceStatus');
    }),
    primaryCallInProgress: computed.alias('telephony.primaryCallActive'),
    shouldShowCsatIvr: computed.alias('telephony.shouldShowCsatIvr'),
    csatModalEnabled: computed('primaryCallInProgress', 'shouldShowCsatIvr', 'currentCall.callType', function () {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel && this.get('primaryCallInProgress') && this.get('shouldShowCsatIvr');
    }),
    primaryCallActive: computed('currentCall.callState', function () {
      var callState = this.get('currentCall.callState');

      return callState && callState !== _freshfoneEmberConstantsCallState['default'].Removed;
    }),
    parallelCallActive: computed('parallelCalls.@each.state', function () {
      var _get3 = this.get('parallelCalls');

      var _get32 = _slicedToArray(_get3, 1);

      var parallelCall = _get32[0];
      var state = parallelCall && parallelCall.get('state');

      return state && state !== _freshfoneEmberConstantsCallState['default'].Removed;
    }),
    primaryOrParallelCallActive: computed('currentCall.callType', 'primaryCallActive', 'parallelCallActive', function () {
      var callType = this.get('currentCall.callType');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('primaryCallActive') : this.get('parallelCallActive');
    }),
    parallelConferenceCaller: alias('parallelCall.parallelConferenceCaller'),
    primaryConferenceCaller: alias('telephony.primaryConferenceCaller'),
    hasParallelAndConferenceCaller: computed('secondaryContact', 'parallelConferenceCaller', function () {
      return this.get('parallelConferenceCaller') && this.get('secondaryContact');
    }),
    hasPrimaryAndConferenceCaller: computed('primaryCallActive', 'primaryConferenceCaller', function () {
      return this.get('primaryConferenceCaller') && this.get('primaryCallActive');
    }),
    showMultipleCallerInfo: computed('currentCall.callType', 'hasPrimaryAndConferenceCaller', 'hasParallelAndConferenceCaller', function () {
      var callType = this.get('currentCall.callType');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('hasPrimaryAndConferenceCaller') : this.get('hasParallelAndConferenceCaller');
    }),
    primaryCallAndConferenceEnded: computed('currentCall.callType', 'currentCall.{callState,conferenceState}', function () {
      var callType = this.get('currentCall.callType'),
          primaryCallState = this.get('currentCall.callState'),
          primaryConferenceState = this.get('currentCall.conferenceState');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel && primaryCallState === _freshfoneEmberConstantsCallState['default'].Ended && primaryConferenceState === _freshfoneEmberConstantsCallState['default'].Ended;
    }),
    parallelCallAndConferenceEnded: computed('currentCall.callType', 'parallelCalls.@each.{state,conferenceState}', function () {
      var callType = this.get('currentCall.callType');

      var _get4 = this.get('parallelCalls');

      var _get42 = _slicedToArray(_get4, 1);

      var parallelCall = _get42[0];

      return parallelCall && callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && parallelCall.state === _freshfoneEmberConstantsCallState['default'].Ended && parallelCall.conferenceState === _freshfoneEmberConstantsCallState['default'].Ended;
    }),
    showMultipleCallsEnded: computed('primaryCallAndConferenceEnded', 'parallelCallAndConferenceEnded', function () {
      if (this.get('primaryCallAndConferenceEnded') || this.get('parallelCallAndConferenceEnded')) {
        this.set('disconnectingFromBanner', false);

        return true;
      }

      return false;
    }),
    conferenceCallerInBanner: computed('currentCall.callType', 'parallelConferenceCaller', 'primaryConferenceCaller', function () {
      var callType = this.get('currentCall.callType');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('primaryConferenceCaller') : this.get('parallelConferenceCaller');
    }),
    isCsatIvrTransfer: computed.alias('telephony.transferringToCsatIvr'),
    currentCallIsParked: equal('currentCall.parkState', _freshfoneEmberConstantsParkState['default'].Parked),
    conferenceCallerInWidget: alias('telephony.conferenceCallerInWidget'),
    disableDisconnectBtn: or('parking', 'merging', 'resuming', 'disconnectingFromBanner', 'isCsatIvrTransfer'),
    isPreviousCalltransferredToQueue: false,
    callSwitchedObserver: observer('telephony.callSwitched', function () {
      if (this.get('telephony.callSwitched')) {
        this.toggleProperty('triggerReload');
        this.updateCurrentCallId(this.get('currentCall.id'));
      }
    }),
    hasParkingEnabled: computed.alias('telephony.hasParkingEnabled'),
    currentCallId: alias('telephony.currentCallId'),
    isWarningSnoozed: false,
    isWarningBannerVisible: false,
    isWarningIconVisible: false,
    snoozeDropdownExpanded: false,
    showWarningBanner: computed('isWarningBannerVisible', 'snoozeDropdownExpanded', 'isWarningSnoozed', 'currentCallCompleted', function () {
      return (this.get('isWarningBannerVisible') || this.get('snoozeDropdownExpanded')) && !this.get('isWarningSnoozed') && !this.get('currentCallCompleted');
    }),
    showWarningIcon: computed('isWarningIconVisible', 'isWarningSnoozed', 'currentCallCompleted', function () {
      return this.get('isWarningIconVisible') && this.get('isWarningSnoozed') && !this.get('currentCallCompleted');
    }),
    showSnoozeEnabledTooltipByClick: false,
    showSnoozeEnabledTooltipByHover: false,
    snoozeIconTimerExpired: false,
    isSnoozeTooltipOpen: or('showSnoozeEnabledTooltipByClick', 'showSnoozeEnabledTooltipByHover'),
    showSnoozeEnabledTooltip: computed('showWarningIcon', 'isSnoozeTooltipOpen', function () {
      return this.get('showWarningIcon') && this.get('isSnoozeTooltipOpen');
    }),
    showSnoozeEnabledTooltipObserver: observer('showSnoozeEnabledTooltip', function () {
      if (!this.get('showSnoozeEnabledTooltip') && this.get('snoozeIconTimerExpired')) {
        this.set('isWarningIconVisible', false);
        this.set('snoozeIconTimerExpired', false);
      }
    }),
    showWarningIconObserver: observer('showWarningIcon', function () {
      if (this.get('showWarningIcon')) {
        this.initiateWarningIconClearTimer();
        this.attachListener();
      } else {
        this.dettachListener();
      }
    }),
    clickListenerInconversationModal: function clickListenerInconversationModal(event) {
      if (this.get('showSnoozeEnabledTooltipByClick')) {
        if (event.target.id === networkWarningTooltipEleId || $(event.target).parents('#' + networkWarningTooltipEleId).length) {
          return;
        }
        this.setProperties({
          showSnoozeEnabledTooltipByClick: false,
          showSnoozeEnabledTooltipByHover: false
        });
      }
    },
    attachClickListenerInconversationModal: function attachClickListenerInconversationModal() {
      this.get('inconversationModal').addEventListener('click', this.clickListenerInconversationModal.bind(this));
    },
    removeClickListenerInconversationModal: function removeClickListenerInconversationModal() {
      this.get('inconversationModal').removeEventListener('click', this.clickListenerInconversationModal.bind(this));
    },
    attachListener: function attachListener() {
      var _this = this;

      later(function () {
        var notificationIconEle = $(notificationSnoozeIconEle);

        notificationIconEle.hover(function () {
          _this.set('showSnoozeEnabledTooltipByHover', true);
        }, function () {
          if (_this.get('showSnoozeEnabledTooltipByClick')) {
            return;
          }
          _this.set('showSnoozeEnabledTooltipByHover', false);
        });
      });
    },
    dettachListener: function dettachListener() {
      $(notificationSnoozeIconEle).unbind('mouseenter mouseleave');
    },
    defaultTimerClearNetworkWarning: function defaultTimerClearNetworkWarning() {
      var _this2 = this;

      run(function () {
        later(_this2, function () {
          if (_this2.get('isDestroyed') || _this2.get('isDestroying')) {
            return;
          }

          _this2.set('isWarningBannerVisible', false);
        }, testing ? 1000 : 5000);
      });
    },
    initiateWarningIconClearTimer: function initiateWarningIconClearTimer() {
      var _this3 = this;

      run(function () {
        later(_this3, function () {
          if (_this3.get('isDestroyed') || _this3.get('isDestroying')) {
            return;
          }

          if (_this3.get('showSnoozeEnabledTooltip')) {
            _this3.set('snoozeIconTimerExpired', true);

            return;
          }

          _this3.set('isWarningIconVisible', false);
        }, testing ? 1000 : 5000);
      });
    },
    notificationsCallback: function notificationsCallback(_ref) {
      var message = _ref.message;
      var isSnoozed = _ref.isSnoozed;

      if (this.hasGlobalFeature('network_warning_logs')) {
        this.setProperties({
          warningDisplayedForMessage: message,
          isWarningBannerVisible: true,
          isWarningIconVisible: true,
          isWarningSnoozed: isSnoozed
        });

        this.defaultTimerClearNetworkWarning();
      } else {
        var isAudioWarning = ['constant-audio-input-level', 'constant-audio-output-level'].includes(message),
            text = isAudioWarning ? 'ongoing_widget.provider_audio_warning' : 'ongoing_widget.provider_warning_generic';

        this.get('notifications').warning(this.get('i18n').t(text));
      }
    },
    notificationClearCallback: function notificationClearCallback(message) {
      if (this.get('warningDisplayedForMessage') !== message || !this.hasGlobalFeature('network_warning_logs')) {
        return;
      }
      this.set('isWarningBannerVisible', false);
      this.set('isWarningIconVisible', false);
      this.set('showSnoozeEnabledTooltipByClick', false);
      this.set('showSnoozeEnabledTooltipByHover', false);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('ongoingWidgetOpen', true);
      this.get('telephony').on('provider_warning', this, this.notificationsCallback);
      this.get('telephony').on('provider_warning_cleared', this, this.notificationClearCallback);
      if (this.get('currentCall.isSIPCall')) {
        this.set('_handleTabFocus', this.get('handleTabFocus').bind(this));

        !document.hidden ? FC.Util.acknowledgeConversation(this.get('currentCall.id')) : document.addEventListener('visibilitychange', this.get('_handleTabFocus'));
      }
      this.set('inconversationModal', document.querySelector('.maximi'));
      this.attachClickListenerInconversationModal();
    },

    logError: function logError(err, message) {
      // eslint-disable-next-line no-console
      console.error(message ? message : 'Some error occured', err);
    },

    init: function init() {
      var _this4 = this;

      this._super.apply(this, arguments);
      this.set('mergeState', false);
      this.set('initialContact', this.get('contact'));
      this.get('telephony').on('save_and_close', function () {
        if (!_this4.get('isAgentInPowerDialerMode')) {
          _this4.send('close', true);
        }
      });
      /**
       * this event will be sent only for transfers as soon as initiation is successfull
       */
      this.get('telephony').off('save_conversation');
      this.get('telephony').on('save_conversation', function () {
        _this4.saveConversation();
        _this4.saveCallTags();
      });
      if (this.get('currentCall.isOutgoing')) {
        this.set('currentAgent.isOutgoingCallMade', true);
      }
      if (this.get('hasCallTaggingFeature')) {
        this.getAccountConfig();
      }
      if (this.get('telephony.isLoaded')) {
        this.subcribeEvents();
      } else {
        scheduleOnce('afterRender', this, 'bindNodeEvents', 350);
      }

      if (this.get('isAgentInPowerDialerMode')) {
        var powerDialerContact = this.get('store').peekAll('custom-power-dialer');

        this.get('powerDialerService').setCustomPowerDialer(powerDialerContact.get('lastObject'));
        this.updateDialedContacts();
      }
    },
    updateDialedContacts: function updateDialedContacts() {
      if (this.get('isAgentInPowerDialerMode')) {
        this.set('dialedContacts', this.get('totalContactsInPowerDialer') - this.get('leftContactsInPowerDialer') + 1);
      }
    },
    bindNodeEvents: function bindNodeEvents() {
      var _this5 = this;

      this.get('telephony').on('loaded', function () {
        _this5.subcribeEvents();
      });
    },
    subcribeEvents: function subcribeEvents() {
      this.get('telephony.event').subscribe('status_update', this.callStatusCallback.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony.event').unsubscribe('status_update', this.callStatusCallback.bind(this));
      this.get('telephony').off('provider_warning', this, this.notificationsCallback);
      this.get('telephony').off('provider_warning_cleared', this, this.notificationClearCallback);
      document.removeEventListener('visibilitychange', this.get('_handleTabFocus'));
      this.removeClickListenerInconversationModal();
    },
    callStatusCallback: function callStatusCallback(data) {
      var _JSON$parse = JSON.parse(data);

      var call = _JSON$parse.call;
      var callId = call.data.id;
      var status = call.data.attributes.status;

      if (status === 'in-progress' && !this.get('currentCallId')) {
        this.set('currentCallId', callId);
        this.get('setCallRatingParams')(callId);
      }
    },
    updateCurrentCallId: function updateCurrentCallId(callId) {
      this.set('currentCallId', callId);
      this.set('initialContact', this.get('contact'));
      this.get('setCallRatingParams')(callId);
    },
    getAccountConfig: function getAccountConfig() {
      var _this6 = this;

      return this.get('store').findAll('accountConfiguration').then(function (response) {
        var config = response.get('firstObject');

        _this6.set('isTaggingMandatory', config.get('mandateCallTagging'));
      });
    },

    handleTabFocus: function handleTabFocus() {
      if (!document.hidden) {
        FC.Util.acknowledgeConversation(this.get('currentCall.id'));
      }
    },

    calledContactId: function calledContactId() {
      return this.get('');
    },

    saveConversation: function saveConversation() {
      var _this7 = this;

      if (this.get('isColdTransfer') || this.get('isSecondaryAgent')) {
        return EmberPromise.resolve();
      }

      return new RSVP.Promise(function () {
        return _this7.get('telephony').saveNotesIfPresent();
      });
    },

    rollbackPickedContact: function rollbackPickedContact() {
      if (this.get('pickedContact')) {
        this.get('pickedContact').rollbackDirtyChanges();
      }
    },

    rollbackContact: function rollbackContact() {
      if (this.get('contact') && this.get('contact.isNew')) {
        this.get('contact').rollbackDirtyChanges();
      }
    },

    rollbackSelectedContact: function rollbackSelectedContact() {
      if (this.get('selectedContact')) {
        this.get('selectedContact').rollbackDirtyChanges();
      }
    },

    linkContactAndUpdateCaller: function linkContactAndUpdateCaller(callId, contactId, isCallerUpdateRequired) {
      this.linkContactToCall(callId, contactId);

      isCallerUpdateRequired && this.get('telephony').updateCallerInfo(this.get('telephony').getCurrentCall(), contactId);
    },

    updateContactDetails: function updateContactDetails(contact, contactPromise, callId, inBackground, isCallerUpdateRequired) {
      var _this8 = this;

      this.set('startValidate', true);
      contact.validate().then(function () {
        run(function () {
          if (_this8.hasOmniRedirectFeatureEnabled()) {
            var contactId = contact.get('mcrId') || contact.get('id');

            _this8.linkContactAndUpdateCaller(callId, contactId, isCallerUpdateRequired);
          } else {
            _this8.addCurrentCallNumber(contact);
            contactPromise = contact.save().then(function () {
              _this8.rollbackPickedContact();
              contact.rollbackDirtyChanges();
              _this8.linkContactAndUpdateCaller(callId, contact.get('id'), isCallerUpdateRequired);
            })['catch'](function (error) {
              _this8.logError(error);
            });
          }
          _this8.set('startValidate', false);
          if (!isCallerUpdateRequired && !_this8.get('isAgentInPowerDialerMode')) {
            _this8.set('currentCall', {});
          }
        });
      })['catch'](function (error) {
        _this8.logError(error);
        _this8.set('contact', contact);
        _this8.set('pickedContact', null);
      });

      // Don't await promise
      if (inBackground && !this.get('isAgentInPowerDialerMode')) {
        this.set('currentCall', {});
      }
    },

    updateContactNumber: function updateContactNumber(inBackground) {
      var _this9 = this;

      if (this.get('showRevert') && this.get('initialContact')) {
        this.get('initialContact').rollbackDirtyChanges();
      }
      var contact = this.get('currentCall.contact') || this.get('selectedContact'),
          callId = this.get('currentCall.id');

      return new RSVP.Promise(function (resolve) {
        var contactPromise = undefined;

        if (contact) {
          _this9.updateContactDetails(contact, contactPromise, callId, inBackground);
        } else {
          _this9.rollbackPickedContact();
          if (!_this9.get('isAgentInPowerDialerMode')) {
            _this9.set('currentCall', {});
          }
        }
        resolve(contactPromise);
        if (!_this9.get('startValidate') && !_this9.get('isAgentInPowerDialerMode')) {
          _this9.set('currentCall', {});
        }
      });
    },
    addCurrentCallNumber: function addCurrentCallNumber(contact) {
      var _this10 = this;

      var numExist = false;

      contact.get('contactNumbers').forEach(function (contactNumber) {
        if (contactNumber.get('phone_number') === _this10.get('currentCall.callerNumber')) {
          numExist = true;
        }
      });
      if (!numExist) {
        run(function () {
          var number = _this10.get('store').createRecord('contactNumber', {
            phone_number: _this10.get('currentCall.callerNumber'),
            contact: contact
          });

          number.set('disabled', true);
        });
      }
    },

    linkContactToCall: function linkContactToCall(callId, contactId) {
      // eslint-disable-next-line camelcase
      var data = this.hasOmniRedirectFeatureEnabled() ? { org_contact_id: contactId } : { contact_id: contactId };

      if (contactId) {
        _ember['default'].$.ajax({
          url: '/calls/' + callId + '/link_contact',
          type: 'POST',
          dataType: 'json',
          data: data
        }).then(function () {});
      }
    },

    saveCallTags: function saveCallTags() {
      return this.get('telephony').saveTagsIfPresent();
    },

    pushToSearchedContact: function pushToSearchedContact(contact) {
      var recentContacts = this.get('lastSearchedContacts') || [],
          _arrayIDs = recentContacts.map(function (el) {
        return el.id;
      });

      if (_arrayIDs.includes(contact.id)) {
        recentContacts.removeObject(contact);
      }
      recentContacts.pushObject(contact);
      if (recentContacts.length > 4) {
        recentContacts.removeAt(0);
      }
      this.set('lastSearchedContacts', recentContacts);
      var _recentTmp = recentContacts.slice(0, recentContacts.length - 1);

      this.set('lastSearchedContactsMinus', _recentTmp);
    },

    showErrorMsg: function showErrorMsg(error, msg) {
      var message = error ? error.errors : msg;

      this.get('notifications').error(message);
    },
    getSecondaryCallGroupId: function getSecondaryCallGroupId() {
      var _get5 = this.get('parallelCalls');

      var _get52 = _slicedToArray(_get5, 1);

      var parallelCall = _get52[0];

      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Primary ? parallelCall.id : this.get('primaryCallGroupId');
    },

    getdisconnectionType: function getdisconnectionType() {
      return this.get('currentCall.callType') === _freshfoneEmberConstantsActiveCallType['default'].Primary ? _freshfoneEmberConstantsActiveCallType['default'].Parallel : _freshfoneEmberConstantsActiveCallType['default'].Primary;
    },
    checkAndGetNotes: function checkAndGetNotes() {
      return this.get('currentCall.notes') ? this.saveConversation() : EmberPromise.resolve();
    },
    isNotesAndTagsNeeded: function isNotesAndTagsNeeded() {
      return !(this.get('isTransfer') || this.get('isSecondaryAgent') || this.get('isPreviousCalltransferredToQueue') || this.get('isSecondaryParallelCallActive')) && !this.get('isAgentInPowerDialerMode');
    },
    unpark: function unpark(callerName) {
      var _this11 = this;

      this.get('transition').start(this.get('i18n').t('call_park.unparking', { name: callerName }));
      this.set('parking', true);

      return this.get('telephony').getCurrentCall().unpark()['catch'](function (err) {
        _this11.get('transition').stop();
        _this11.set('parking', false);
        _this11.logError(err, '- Failed to unpark call');
        _this11.showErrorMsg(err, _this11.get('i18n').t('call_park.unpark_fail'));
      });
    },
    isToSaveCallTags: function isToSaveCallTags() {
      return this.get('hasCallTaggingFeature') && this.get('isTaggingMandatory') && this.get('hasNoCallTags') && !(this.get('isTransferOrConferenceCall') || this.get('isPreviousCalltransferredToQueue')) && !this.get('isSecondaryParallelCallActive');
    },
    getEODEpoch: function getEODEpoch() {
      return new Date(moment().format('YYYY-MM-DD 23:59:59')).valueOf();
    },
    getDateAfterOurs: function getDateAfterOurs(hour) {
      return new Date(Date.now() + hour * (60 * 60 * 1000)).valueOf();
    },
    getDuration: function getDuration(snoozeType) {
      switch (snoozeType) {
        case _freshfoneEmberConstantsSnoozeType['default'].FOR_ONE_HOUR:
          return this.getDateAfterOurs(1);
        case _freshfoneEmberConstantsSnoozeType['default'].FOR_THREE_HOURS:
          return this.getDateAfterOurs(3);
        case _freshfoneEmberConstantsSnoozeType['default'].FOR_TODAY:
          return this.getEODEpoch();
      }
    },
    updateSnoozeSettings: function updateSnoozeSettings(snoozeType) {
      var globalSnoozeOptions = [_freshfoneEmberConstantsSnoozeType['default'].FOR_ONE_HOUR, _freshfoneEmberConstantsSnoozeType['default'].FOR_THREE_HOURS, _freshfoneEmberConstantsSnoozeType['default'].FOR_TODAY],
          snoozeSettingsString = localStorage.getItem('snoozeSettings'),
          snoozeSettings = snoozeSettingsString ? JSON.parse(snoozeSettingsString) : {};

      if (globalSnoozeOptions.includes(snoozeType)) {
        snoozeSettings.globalSnooze = this.getDuration(snoozeType);
      } else if (snoozeSettings.forCalls) {
        snoozeSettings.forCalls.push(parseInt(this.get('currentCall.id')));
      } else {
        snoozeSettings.forCalls = [parseInt(this.get('currentCall.id'))];
      }

      localStorage.setItem('snoozeSettings', JSON.stringify(snoozeSettings));
    },
    actions: {
      triggerDisableNotes: function triggerDisableNotes(status) {
        this.set('disableNotes', status);
      },
      toggleVoicemailsList: function toggleVoicemailsList() {
        this.toggleProperty('showVoicemailsList');
      },

      dialNextAndSave: function dialNextAndSave() {
        if (this.get('currentCall.notes')) {
          this.saveConversation();
        }
        this.saveCallTags();
      },
      close: function close(immediately) {
        var _this12 = this;

        if (this.get('isAgentInPowerDialerMode')) {
          this.setProperties({
            'currentCall.isCompleted': true,
            'contactFetched': true
          });
          this.send('fetchNextContact');
        }

        if (this.isToSaveCallTags()) {
          this.set('noCallTagsAdded', true);

          if (!this.get('isAgentInPowerDialerMode')) {
            return;
          }
        }

        if (this.get('parallelCalls').length) {
          this.get('parallelCall').removeParallelCall();
        }

        var promises = [this.updateContactNumber(immediately)];

        if (this.isNotesAndTagsNeeded()) {
          this.checkAndGetNotes();
          this.saveCallTags();
        }

        if (this.get('hasParkingEnabled')) {
          this.get('telephony').close();
        }

        EmberPromise.all(promises)['finally'](function () {
          // updateContactNumber ultimately clears the call
          if (!_this12.get('isAgentInPowerDialerMode')) {
            _this12.sendAction('callCompleted', _this12.get('currentCall.callerNumber'));
          }
        });
        this.set('isPreviousCalltransferredToQueue', false);
      },

      mergeContact: function mergeContact(contact) {
        var callId = this.get('currentCall.id');

        this.setProperties({
          'newContactCreated': false,
          'currentCall.contact': contact,
          'showLinkToCall': false,
          'linkToCallShortcut': false,
          'initialContact': contact
        });

        this.updateContactDetails(contact, false, callId, false, true);

        this.get('mkpAdapter').triggerEvent({
          type: 'call.linkedToAnotherContact',
          data: this.get('mkpData').getData('currentCaller')
        });

        this.toggleProperty('triggerReload');
      },

      pickedContact: function pickedContact(contact) {
        var _this13 = this;

        contact.reload().then(function (contact) {
          _this13.setProperties({
            searchFlag: false,
            pickedContact: contact,
            toggleSearch: false,
            isCalledWithContact: false
          });
          _this13.pushToSearchedContact(contact);
        });
        this.set('recentContactsOpen', false);
      },

      mergeCompany: function mergeCompany(company) {
        this.set('currentCall.company', company);
      },

      createNewContact: function createNewContact() {
        this.set('oldContact', this.get('contact'));
        var newContact = this.store.createRecord('contact');

        this.setProperties({
          contact: newContact,
          toggleSearch: true,
          searchFlag: false,
          searchString: null,
          pickedContact: null,
          startValidate: false,
          newContactCreated: true
        });

        this.send('clearSearchField', '');
        this.send('toggleContactAction', 'Edit');
      },

      confirmContactEdit: function confirmContactEdit() {
        this.get('mkpAdapter').triggerEvent({
          type: 'call.contactEdited',
          data: this.get('mkpData').getData('currentCaller')
        });

        this.send('toggleContactAction', 'Done');
      },

      toggleFilter: function toggleFilter() {
        this.set('isFilterOpen', true);
      },

      toggleMinimize: function toggleMinimize() {
        this.set('currentCall.minimized', true);
        _ember['default'].$('.modal-backdrop').hide();
        // #todo, make it generic, from a component
      },
      searchNotes: function searchNotes() {
        this.get('conversationList').send('applyFilter');
      },
      clearNotes: function clearNotes() {
        this.set('noteSearchText', null);
        this.set('conversationList.noteSearchText', null); // hack, to be removed
        this.send('searchNotes');
      },

      searchNote: function searchNote(queryString) {
        this.set('isActiveSearch', true);
        this.set('triggerReload', true);
        this.set('searchTextForNote', queryString);
      },

      searchContact: function searchContact(queryString) {
        var _this14 = this;

        this.set('isContactLoading', true);
        this.set('searchTextForContact', queryString);
        if (_ember['default'].isEmpty(queryString)) {
          return;
        }
        this.set('isActiveSearch', true);
        var queryParams = { filter: { queryString: queryString } };

        if (this.hasOmniRedirectFeatureEnabled()) {
          queryParams.product = this.get('account.signupApp');
          queryParams.version = _freshfoneEmberConstantsSearchQueryConstants['default'].version;
        }

        $.getJSON('/contacts/search', queryParams).then(function (data) {
          _ember['default'].run(function () {
            var searchResults = _this14.hasOmniRedirectFeatureEnabled() ? {
              data: data.data.filter(function (contact) {
                var contactNumber = _freshfoneEmberUtilsSearchUtil['default'].formatNumber(contact.attributes.phone_number || contact.attributes.mobile),
                    callerNumber = _freshfoneEmberUtilsSearchUtil['default'].formatNumber(_this14.get('currentCall.callerNumber'));

                return _this14.get('currentCall.contactId') !== contact.attributes.mcr_id && callerNumber.includes(contactNumber);
              })
            } : data;

            _this14.set('contactSearchResults', searchResults);
            _this14.set('isContactLoading', false);
          });
        });
      },

      clearSearchField: function clearSearchField(q) {
        this.set('isActiveSearch', false);
        this.get('isSearchingContact') ? this.set('searchTextForContact', q) : this.set('searchTextForNote', q);
      },

      openMkpApp: function openMkpApp(index) {
        this.set('openMkpAppIndex', index);
      },
      closeMkpApp: function closeMkpApp() {
        this.set('openMkpAppIndex', undefined);
      },

      toggleContactAction: function toggleContactAction(status) {
        switch (status) {
          case 'Edit':
            this.set('isEditingContactForm', true);
            break;

          case 'Done':
            this.set('isEditingContactForm', false);
            this.set('contactToLinkCall', this.get('contact'));
            this.get('newContactCreated') && this.set('showLinkToCall', true);
            this.get('telephony').updateCallerInfo(this.get('telephony').getCurrentCall());
            break;

          default:
            this.set('isEditingContactForm', false);
            this.get('contact.isNew') && this.set('contact', this.get('initialContact'));
            this.get('contact') && this.get('contact').rollbackDirtyChanges();

        }
      },

      mergeCallToContact: function mergeCallToContact(contactSelectedToMerge) {
        this.set('contactToLinkCall', contactSelectedToMerge);
        if (this.get('linkToCallShortcut')) {
          this.send('mergeContact', contactSelectedToMerge);
        } else {
          this.set('showLinkToCall', true);
        }
        this.send('clearSearchField', '');
      },

      fetchNextContact: function fetchNextContact() {
        var _this15 = this;

        this.store.peekAll('power-dialer-list').get('lastObject').fetchNext().then(function (response) {
          _this15.store.pushPayload(response);
          var nextPowerDialerId = response.data.id,
              nextCustomContact = _this15.get('store').peekRecord('custom-power-dialer', nextPowerDialerId),
              contactId = response.included[0].id,
              contact = _this15.get('store').peekRecord('contact', contactId);

          _this15.set('powerDialerService.contactId', nextPowerDialerId);
          _this15.setProperties({
            customPowerDialer: nextCustomContact,
            nextContact: contact,
            contactFetched: true
          });
          _this15.get('powerDialerService').setCustomPowerDialer(nextCustomContact);
        });
      },
      hideNumberErrorMessage: function hideNumberErrorMessage() {
        this.set('errorMessage', null);
      },
      cancelParallelCall: function cancelParallelCall() {
        var _this16 = this;

        this.set('callStatus', _freshfoneEmberConstantsCallStatus['default'].ParallelCallCancelling);
        this.get('telephony').getCurrentCall().cancelParallelCall().then(function () {
          run(function () {
            _this16.set('primaryHoldDuration', 0);
            _this16.set('secondaryHoldDuration', 0);
            _this16.get('parallelCall').removeParallelCall();
          });
        })['catch'](function (error) {
          _this16.logError(error, '- Failed to cancel Parallel call');
          _this16.showErrorMsg(error, _this16.get('i18n').t('parallel_call.cancel_failed'));
        })['finally'](function () {
          _this16.get('telephony').updateCurrentCall(_this16.get('telephony').getCurrentCall());
        });
      },
      disconnectCall: function disconnectCall() {
        var _this17 = this;

        if (!this.get('disableDisconnectBtn')) {
          this.set('showCsatModal', false);
          this.set('disconnectingFromBanner', true);
          var group = this.getSecondaryCallGroupId();

          this.get('telephony').getCurrentCall().disconnectParallelCall(group, this.getdisconnectionType(), _freshfoneEmberConstantsDisconnectSourceType['default'].Banner).then(function () {
            _this17.get('parallelCall').endCall(_this17.getdisconnectionType(), _freshfoneEmberConstantsDisconnectSourceType['default'].Banner);
          })['catch'](function (error) {
            run(function () {
              _this17.set('disconnectingFromBanner', false);
              _this17.logError(error, '- Failed to disconnect ' + _this17.getdisconnectionType() + ' call');
              _this17.showErrorMsg(error, _this17.get('i18n').t('parallel_call.disconnect_failed'));
            });
          });
        }
      },
      resumeCall: function resumeCall() {
        var _this18 = this;

        if (this.get('isLoading') || this.get('currentCallIsParked')) {
          return;
        }
        this.set('resuming', true);
        this.set('primaryHoldDuration', 0);
        var group = this.getSecondaryCallGroupId();

        this.get('telephony').getCurrentCall().resumeParallelCall(group)['catch'](function (error) {
          run(function () {
            _this18.set('resuming', false);
            _this18.logError(error, '- Failed to resume ' + group + ' call');
            _this18.showErrorMsg(error, _this18.get('i18n').t('parallel_call.resume_failed'));
          });
        });
      },
      transferToCsatIvr: function transferToCsatIvr() {
        this.set('showCsatModal', false);
        this.get('telephony').transferToCsatIvr(_freshfoneEmberConstantsDisconnectSourceType['default'].Banner);
      },
      openCsatModal: function openCsatModal() {
        this.set('showCsatModal', true);
      },
      closeCsatModal: function closeCsatModal() {
        this.set('showCsatModal', false);
      },
      parkCall: function parkCall() {
        var callerName = this.get('hideMainContactInfoCard') ? this.get('conferenceCallerInWidget.text') : this.get('primaryCaller.displayName');

        if (this.get('currentCallIsParked')) {
          return this.unpark(callerName);
        }

        this.get('transition').start(this.get('i18n').t('call_park.parking', { name: callerName }));
        this.set('parking', true);

        return this.get('parkedCallsService').parkCall();
      },
      onWarningSnooze: function onWarningSnooze(snoozeType) {
        this.set('isWarningSnoozed', true);
        this.set('snoozeDropdownExpanded', false);

        this.updateSnoozeSettings(snoozeType);
      },
      onSnoozeDDOpen: function onSnoozeDDOpen() {
        this.set('snoozeDropdownExpanded', true);
      },
      onSnoozeDDClose: function onSnoozeDDClose() {
        this.set('snoozeDropdownExpanded', false);
      },
      onSnoozeIconClick: function onSnoozeIconClick() {
        this.toggleProperty('showSnoozeEnabledTooltipByClick');
      },
      turnOnNotification: function turnOnNotification() {
        this.set('isWarningSnoozed', false);
        this.set('showSnoozeEnabledTooltipByClick', false);
        this.set('showSnoozeEnabledTooltipByHover', false);
        this.set('isWarningBannerVisible', true);
        localStorage.removeItem('snoozeSettings');
        this.defaultTimerClearNetworkWarning();
      }
    }
  });
});
/* eslint-disable no-extra-parens */