define('freshfone-ember/utils/search-util', ['exports', 'freshfone-ember/utils/regex-utils'], function (exports, _freshfoneEmberUtilsRegexUtils) {

  var searchUtil = {
    ALPHABET_REGEX: /[a-z]/gi,

    isAlphabetPresent: function isAlphabetPresent(input) {
      return input ? Boolean(input.match(this.ALPHABET_REGEX)) : false;
    },
    //+1(234)-456 converted to 1234456
    formatNumber: function formatNumber(input) {
      return input ? input.replace(/[^0-9]/g, '') : '';
    },
    escapeSpecialChar: function escapeSpecialChar(input) {
      return input ? _freshfoneEmberUtilsRegexUtils['default'].escapeSpecialChar(input) : '';
    }

  };
  exports['default'] = searchUtil;
});