define('freshfone-ember/routes/protected/admin/messages', ['exports', 'ember', 'freshfone-ember/constants/message', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberConstantsMessage, _freshfoneEmberRoutesProtected) {
  var Route = _ember['default'].Route;
  var i18n = _ember['default'].i18n;
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('admin_components.Messages');
    }),

    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('admin_components.' + (this.get('session.account.isUnifiedAppAccount') ? 'Messages_unified_app' : 'Messages')).string };
    }),

    beforeModel: function beforeModel() {
      this.checkPrivilege('view_messages');
    },

    model: function model() {
      return this.store.query('message', { scope: _freshfoneEmberConstantsMessage.SCOPE_ADMIN });
    }
  });
});