define('freshfone-ember/routes/protected/admin/businesshour', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    i18n: service(),
    breadCrumb: computed(function () {
      return {
        title: this.get('i18n').t('admin_components.' + (this.get('session.account.isUnifiedAppAccount') ? 'BusinessHours_unified_app' : 'BusinessHours')).string
      };
    }),
    titleToken: computed(function () {
      return this.get('i18n').t('business_calendar.title').string;
    }),
    queryParams: { businessHour: { refreshModel: true } },
    beforeModel: function beforeModel() {
      this.checkFeature('business_hours');
      this.checkIfRestrictedFetaure('unified_business_hour');
      this.checkPrivilege('view_business_hours');
    },
    model: function model(params) {
      this.set('businessHour', params.businessHour);

      return this.store.query('businessCalendar', {});
    },
    afterModel: function afterModel() {
      this.set('businessHour', null);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('businessHour', undefined);
      }
    }
  });
});