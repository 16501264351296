define('freshfone-ember/utils/mkp-data-formatter', ['exports', 'freshfone-ember/constants/mkp-data-keys'], function (exports, _freshfoneEmberConstantsMkpDataKeys) {

  var camelizeKeys = function camelizeKeys(obj) {
    for (var key in obj) {
      try {
        obj[key.camelize()] = obj[key];
      } catch (error) {
        continue;
      }
    }

    return obj;
  },
      getJson = function getJson(model) {
    return model.toJSON ? model.toJSON({ includeId: true }) : model;
  };

  exports['default'] = function (model, type) {
    var keyMap = _freshfoneEmberConstantsMkpDataKeys['default'][type || model.constructor.modelName],
        modelAsJSON = camelizeKeys(getJson(model)),
        ret = {};

    Object.keys(keyMap).forEach(function (key) {
      ret[keyMap[key] /* alias */ || key] = modelAsJSON[key];
    });

    return ret;
  };
});