define("freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 8
                },
                "end": {
                  "line": 11,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "title-container");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "col-name-header agent-status ellipsis");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "agent-count");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              return morphs;
            },
            statements: [["content", "agentTitle.status", ["loc", [null, [7, 64], [7, 85]]], 0, 0, 0, 0], ["content", "agentTitle.count", ["loc", [null, [7, 124], [7, 144]]], 0, 0, 0, 0], ["inline", "ui-list-header-item", [], ["title", ["subexpr", "@mut", [["get", "statusDescription", ["loc", [null, [9, 38], [9, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "list-header-element col-description-header"], ["loc", [null, [9, 10], [9, 108]]], 0, 0], ["inline", "ui-list-header-item", [], ["title", "", "class", "list-header-element more-actions"], ["loc", [null, [10, 10], [10, 83]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 13,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element5, 'class');
            morphs[1] = dom.createElementMorph(element5);
            morphs[2] = dom.createMorphAt(element5, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["list-header-container sticky-header ", ["subexpr", "unless", [["get", "status.default", ["loc", [null, [4, 63], [4, 77]]], 0, 0, 0, 0], "custom-status", "default-status"], [], ["loc", [null, [4, 54], [4, 112]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleAgentsList"], [], ["loc", [null, [4, 114], [4, 143]]], 0, 0], ["block", "ui-list-header", [], ["class", "list-header"], 0, null, ["loc", [null, [5, 8], [11, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 16
                  },
                  "end": {
                    "line": 24,
                    "column": 16
                  }
                },
                "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "icon-mobile");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 14
                  },
                  "end": {
                    "line": 30,
                    "column": 14
                  }
                },
                "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "live-time-stamp", [], ["timeStamp", ["subexpr", "unless", [["get", "isEnhancedLiveDashboard", ["loc", [null, [29, 52], [29, 75]]], 0, 0, 0, 0], ["get", "agent.lastCallAt", ["loc", [null, [29, 76], [29, 92]]], 0, 0, 0, 0], ["get", "agent.lastStatusChangeAt", ["loc", [null, [29, 93], [29, 117]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 44], [29, 118]]], 0, 0], "inWords", true, "fallbackMessage", ["subexpr", "t", ["live_dashboard.noCallsYet"], [], ["loc", [null, [29, 148], [29, 179]]], 0, 0]], ["loc", [null, [29, 16], [29, 181]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 14
                    },
                    "end": {
                      "line": 32,
                      "column": 14
                    }
                  },
                  "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n              ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "live-time-stamp", [], ["timeStamp", ["subexpr", "@mut", [["get", "agent.lastStatusChangeAt", ["loc", [null, [31, 44], [31, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "inWords", true, "fallbackMessage", ["subexpr", "t", ["live_dashboard.not_logged_in"], [], ["loc", [null, [31, 98], [31, 132]]], 0, 0]], ["loc", [null, [31, 16], [31, 134]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 14
                  },
                  "end": {
                    "line": 32,
                    "column": 14
                  }
                },
                "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "agent.browserStatus.statusType", ["loc", [null, [30, 32], [30, 62]]], 0, 0, 0, 0], "acw"], [], ["loc", [null, [30, 28], [30, 69]]], 0, 0], ["subexpr", "eq", [["get", "agent.browserStatus.statusType", ["loc", [null, [30, 74], [30, 104]]], 0, 0, 0, 0], "offline"], [], ["loc", [null, [30, 70], [30, 115]]], 0, 0]], [], ["loc", [null, [30, 24], [30, 116]]], 0, 0]], [], 0, null, ["loc", [null, [30, 14], [32, 14]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child3 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 37,
                        "column": 18
                      },
                      "end": {
                        "line": 38,
                        "column": 18
                      }
                    },
                    "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
                  },
                  isEmpty: true,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 39,
                        "column": 18
                      },
                      "end": {
                        "line": 41,
                        "column": 18
                      }
                    },
                    "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "menu-action");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element0);
                    morphs[1] = dom.createMorphAt(element0, 0, 0);
                    morphs[2] = dom.createMorphAt(element0, 2, 2);
                    return morphs;
                  },
                  statements: [["element", "action", ["toggleAgentStatus", ["get", "agent", ["loc", [null, [40, 74], [40, 79]]], 0, 0, 0, 0], ["get", "agent.isAvailable", ["loc", [null, [40, 80], [40, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 45], [40, 99]]], 0, 0], ["inline", "t", ["common.make"], [], ["loc", [null, [40, 100], [40, 119]]], 0, 0], ["inline", "t", [["subexpr", "if", [["get", "agent.isAvailable", ["loc", [null, [40, 128], [40, 145]]], 0, 0, 0, 0], "agent.offline", "agent.online"], [], ["loc", [null, [40, 124], [40, 177]]], 0, 0]], [], ["loc", [null, [40, 120], [40, 179]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 36,
                      "column": 16
                    },
                    "end": {
                      "line": 42,
                      "column": 16
                    }
                  },
                  "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "rl-dropdown-toggle", [], ["tagName", "i", "class", ["subexpr", "concat", ["icon-more-actions ", ["subexpr", "if", [["get", "dropdownExpanded", ["loc", [null, [37, 91], [37, 107]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [37, 87], [37, 117]]], 0, 0]], [], ["loc", [null, [37, 58], [37, 118]]], 0, 0]], 0, null, ["loc", [null, [37, 18], [38, 41]]]], ["block", "rl-dropdown", [], ["class", "dropdown-menu more-actions-menu", "closeOnChildClick", "div"], 1, null, ["loc", [null, [39, 18], [41, 34]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 14
                  },
                  "end": {
                    "line": 43,
                    "column": 14
                  }
                },
                "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "rl-dropdown-container", [], ["class", "dropdown-actions"], 0, null, ["loc", [null, [36, 16], [42, 42]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 8
                },
                "end": {
                  "line": 46,
                  "column": 8
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              dom.setAttribute(el1, "class", "content-row");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "content-col agent contact ellipsis");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "agent-detail");
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4, "class", "agent-name ellipsis");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("              ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "content-col content-description");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "content-col more-actions");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1, 1]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element1, 'id');
              morphs[1] = dom.createMorphAt(element2, 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
              morphs[3] = dom.createMorphAt(element2, 5, 5);
              morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
              morphs[5] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
              return morphs;
            },
            statements: [["attribute", "id", ["get", "agent.id", ["loc", [null, [17, 39], [17, 47]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "custom-elements/avatar", [], ["charLimit", 1, "type", "user", "model", ["subexpr", "@mut", [["get", "agent", ["loc", [null, [20, 71], [20, 76]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "24"], ["loc", [null, [20, 16], [20, 88]]], 0, 0], ["content", "agent.name", ["loc", [null, [21, 50], [21, 64]]], 0, 0, 0, 0], ["block", "if", [["get", "agent.isOnPhoneOrMobileApp", ["loc", [null, [22, 22], [22, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 16], [24, 23]]]], ["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "agent.browserStatus.statusType", ["loc", [null, [28, 28], [28, 58]]], 0, 0, 0, 0], "online"], [], ["loc", [null, [28, 24], [28, 68]]], 0, 0], ["get", "agent.isOnPhoneOrMobileApp", ["loc", [null, [28, 69], [28, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 20], [28, 96]]], 0, 0]], [], 1, 2, ["loc", [null, [28, 14], [32, 21]]]], ["block", "if", [["subexpr", "and", [["get", "hasPresenceToggleFeature", ["loc", [null, [35, 25], [35, 49]]], 0, 0, 0, 0], ["subexpr", "not-eq", [["get", "agent.id", ["loc", [null, [35, 58], [35, 66]]], 0, 0, 0, 0], ["get", "currentAgent.id", ["loc", [null, [35, 67], [35, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 50], [35, 83]]], 0, 0], ["subexpr", "not", [["get", "hasOmnichannelAgentStatusFeature", ["loc", [null, [35, 89], [35, 121]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 84], [35, 122]]], 0, 0], ["get", "agent.statusToggleAllowed", ["loc", [null, [35, 123], [35, 148]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 20], [35, 149]]], 0, 0]], [], 3, null, ["loc", [null, [35, 14], [43, 21]]]]],
            locals: ["agent", "index"],
            templates: [child0, child1, child2, child3]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 48,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tbody");
            dom.setAttribute(el1, "class", "list-body ember-view");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element3, 'style');
            morphs[1] = dom.createMorphAt(element3, 1, 1);
            return morphs;
          },
          statements: [["attribute", "style", ["concat", ["height: ", ["get", "boxHeight", ["loc", [null, [15, 59], [15, 68]]], 0, 0, 0, 0], "px;"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "ember-collection", [], ["items", ["subexpr", "@mut", [["get", "agents", ["loc", [null, [16, 34], [16, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "cell-layout", ["subexpr", "fixed-grid-layout", ["auto", ["get", "rowHeight", ["loc", [null, [16, 79], [16, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 53], [16, 89]]], 0, 0]], 0, null, ["loc", [null, [16, 8], [46, 29]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 4
                },
                "end": {
                  "line": 52,
                  "column": 4
                }
              },
              "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "empty-calls");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "content-middle");
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["agent_component.no_agents"], [], ["loc", [null, [50, 37], [50, 70]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 4
              },
              "end": {
                "line": 52,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "noResult", ["loc", [null, [48, 14], [48, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [48, 4], [52, 4]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 53,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["get", "noResult", ["loc", [null, [3, 14], [3, 22]]], 0, 0, 0, 0], ["get", "hasAgents", ["loc", [null, [3, 23], [3, 32]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 10], [3, 33]]], 0, 0]], [], 0, null, ["loc", [null, [3, 4], [13, 11]]]], ["block", "if", [["subexpr", "and", [["get", "showAgents", ["loc", [null, [14, 15], [14, 25]]], 0, 0, 0, 0], ["get", "hasAgents", ["loc", [null, [14, 26], [14, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 10], [14, 36]]], 0, 0]], [], 1, 2, ["loc", [null, [14, 4], [52, 11]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/live-calls/live-agents-dashboard/agents-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element6, 'class');
        morphs[1] = dom.createAttrMorph(element6, 'id');
        morphs[2] = dom.createMorphAt(element6, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["agents-status-list ", ["subexpr", "if", [["get", "status.default", ["loc", [null, [1, 36], [1, 50]]], 0, 0, 0, 0], ["get", "status.statusType", ["loc", [null, [1, 51], [1, 68]]], 0, 0, 0, 0], ["get", "status.name", ["loc", [null, [1, 69], [1, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 31], [1, 82]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["concat", ["agents-status-", ["get", "status", ["loc", [null, [1, 104], [1, 110]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "ui-list", [], ["class", "list-container live-agents-list"], 0, null, ["loc", [null, [2, 2], [53, 14]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});