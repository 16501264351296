define('freshfone-ember/helpers/support-mail', ['exports', 'ember', 'freshfone-ember/constants/support-mail'], function (exports, _ember, _freshfoneEmberConstantsSupportMail) {
  exports.supportMail = supportMail;
  var service = _ember['default'].inject.service;
  var Helper = _ember['default'].Helper;

  function supportMail(isUnifiedAppAccount) {
    var useDefault = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    if (useDefault) {
      return _freshfoneEmberConstantsSupportMail['default'].freshcaller;
    }
    if (isUnifiedAppAccount) {
      return _freshfoneEmberConstantsSupportMail['default'].crm;
    }

    return _freshfoneEmberConstantsSupportMail['default'].freshcaller;
  }

  exports['default'] = Helper.extend({
    session: service(),

    // eslint-disable-next-line no-unused-vars
    compute: function compute(params, _ref) {
      var useDefault = _ref.useDefault;

      return supportMail(this.get('session.account.isUnifiedAppAccount'), useDefault);
    }
  });
});