define("freshfone-ember/components/custom-elements/select2/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/custom-elements/select2/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "select-2", [], ["content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [2, 10], [2, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "valueAttr", ["loc", [null, [3, 8], [3, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "searchEnabled", ["subexpr", "@mut", [["get", "searchEnabled", ["loc", [null, [4, 16], [4, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [5, 14], [5, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "optionDescriptionPath", ["subexpr", "@mut", [["get", "optionDescriptionPath", ["loc", [null, [6, 24], [6, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "enabled", ["subexpr", "@mut", [["get", "enabled", ["loc", [null, [7, 10], [7, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "multiple", ["subexpr", "@mut", [["get", "multiple", ["loc", [null, [8, 11], [8, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "allowClear", ["subexpr", "@mut", [["get", "allowClear", ["loc", [null, [9, 13], [9, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "typeaheadSearchingText", ["subexpr", "t", ["custom_elements.search_text"], [], ["loc", [null, [10, 25], [10, 58]]], 0, 0], "typeaheadNoMatchesText", ["subexpr", "t", ["custom_elements.not_found"], [], ["loc", [null, [11, 25], [11, 56]]], 0, 0], "typeaheadErrorText", ["subexpr", "t", ["custom_elements.failed"], [], ["loc", [null, [12, 21], [12, 49]]], 0, 0], "minimumInputLength", 2, "query", "query", "optionLabelPath", ["subexpr", "@mut", [["get", "optionLabelPath", ["loc", [null, [15, 18], [15, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [16, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});