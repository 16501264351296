define('freshfone-ember/models/entity', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    created_at: attr(),
    name: attr(),
    subject: attr(),
    link: attr(),
    type: attr()
  });
});