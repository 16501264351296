define('freshfone-ember/initializers/inject-session', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    ['component', 'route'].forEach(function (entity) {
      application.inject(entity, 'session', 'service:session');
    });
  }

  exports['default'] = {
    name: 'inject-session',
    initialize: initialize
  };
});