define('freshfone-ember/components/conversation/conversations-list/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var defineProperty = _ember['default'].defineProperty;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    i18n: service(),
    account: computed.alias('session.account'),
    classNames: ['generic-conversations-list'],
    searchString: '',
    classNameBindings: ['isCallsEmpty:empty-calls'],
    isCallsEmpty: computed.empty('recentCalls'),
    placeholderText: _ember['default'].i18n.t('conversation.conversation_list.placeholder_text'),
    hasMoreCalls: computed.gt('totalCalls', 10),
    isAgent: computed.equal('session.user.role.name', 'Agent'),
    has1CXFeature: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm');
    }),
    isContactHyperlinkOff: computed.and('has1CXFeature', 'isCrmView'),
    hasCallLogsRestrictionFeature: computed('session', function () {
      return this.get('session.account').hasFeature('restrict_call_logs');
    }),
    restrictCallLogs: computed.and('isAgent', 'hasCallLogsRestrictionFeature'),
    showMoreCallsOption: computed('hasMoreCalls', 'showCallLog', 'restrictCallLogs', function () {
      return (this.get('hasMoreCalls') || this.get('showCallLog')) && !this.get('restrictCallLogs');
    }),
    showMoreLabel: computed('hasMoreCalls', 'showCallLog', function () {
      if (this.get('hasMoreCalls')) {
        return this.get('i18n').t('conversation.conversation_list.show_more');
      }

      return this.get('i18n').t('conversation.conversation_list.show_in_call_log');
    }),

    perPage: 10,
    hasPages: computed.gt('totalPages', 1),
    isToRedirectDeskUrl: computed(function () {
      return this.hasOmniRedirectFeatureEnabled();
    }),
    redirectUrl: computed('account.omnichannelOwnerDomain', function () {
      return this.get('account.omnichannelOwnerDomain') + '/a/org/contacts';
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.defineCallLogParam();
      this.set('page', 1);
      this.getRecentCalls();
    },

    defineCallLogParam: function defineCallLogParam() {
      var propName = this.get('type') + 'Id';

      defineProperty(this, propName, computed.alias('typeId'));
    },

    getRecentCalls: function getRecentCalls() {
      var _this = this;

      this.set('conversationsLoaded', false);

      var callParams = {
        type: this.get('type').pluralize(),
        id: this.get('typeId'),
        page: this.get('page')
      };

      this.store.query('call', callParams).then(function (calls) {
        _this.setProperties({
          recentCalls: calls,
          totalCalls: calls.meta['total-count'],
          page: calls.meta.current,
          totalPages: calls.meta['total-pages'],
          conversationsLoaded: true
        });
      });
    },

    actions: {
      selectAgent: function selectAgent(agent) {
        this.sendAction('selectAgent', agent);
      },
      selectContact: function selectContact(contact) {
        if (this.get('isToRedirectDeskUrl')) {
          var url = this.get('redirectUrl') + '/' + contact.id;

          window.open(url, '_blank');

          return;
        }
        this.sendAction('selectContact', contact);
      },
      loadMore: function loadMore() {
        this.sendAction('loadMore');
      },
      pageChanged: function pageChanged(current) {
        this.set('page', current);
        this.getRecentCalls();
      },
      change: function change(page) {
        this.set('page', page);
        this.getRecentCalls();
      },
      onEdit: function onEdit() {
        this.get('onEdit')();
      }
    }
  });
});