define('freshfone-ember/components/billing-base/credit-base/credit-form/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    canShowAndLoaded: computed.and('canShowForm', 'cardLoaded'),
    isCardDetailsPresent: computed.bool('subscription.isCardDetailsPresent'),
    isUserConfirmed: computed.reads('session.user.confirmed'),
    isAccountBlocked: computed.reads('session.account.blocked'),
    analyticsService: service('analytics-tracker'),
    title: computed('isCardDetailsPresent', function () {
      if (this.get('isCardDetailsPresent')) {
        return _ember['default'].i18n.t('common.edit');
      }

      return this.get('session.account').hasGlobalFlag('new_billing_form') ? _ember['default'].i18n.t('billing.card.add_billing') : _ember['default'].i18n.t('billing.card.add');
    }),
    slideToggle: computed({
      get: function get() {
        if (typeof this === 'object') {
          return false;
        }
        return this;
      },
      set: function set(k, v) {
        if (v) {
          this.loadCreditForm();
        } else {
          this.set('canShowForm', false);
          this.set('showUserUnconfirmationError', false);
        }
        return v;
      }
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('cardLoaded', false);
      this.set('canShowForm', false);
      if (this.get('isChangeMode')) {
        _ember['default'].run.scheduleOnce('afterRender', function () {
          _this.set('canShowForm', true);
          _this.set('slideToggle', true);
        });
      }
    },

    loadCreditForm: function loadCreditForm() {
      var _this2 = this;

      if (this.get('isAccountBlocked')) {
        return;
      }
      if (!this.get('isUserConfirmed')) {
        this.set('canShowForm', true);
        this.set('showUserUnconfirmationError', true);
        return;
      }
      if (this.get('cardLoaded')) {
        this.set('canShowForm', true);
        return;
      }
      var subscription = this.get('subscription.content'),
          site = subscription.get('billingSiteName');

      this.set('canShowForm', true);

      if (this.get('session.account').hasGlobalFlag('new_billing_form')) {
        subscription.subscriptionPage().then(function (result) {
          return _this2.renderIframe(result, site);
        })['catch'](function (e) {
          return console.log(e);
        });
      } else {
        subscription.paymentPage().then(function (result) {
          return _this2.renderIframe(result, site);
        })['catch'](function (e) {
          return console.log(e);
        });
      }
    },

    renderIframe: function renderIframe(result, site) {
      var _this3 = this;

      var url = result.url.response.hosted_page.url,
          subscription = this.get('subscription.content');

      ChargeBee.embed(url, site).load({
        addIframe: function addIframe(iframe) {
          _this3.$(".test-card").append(iframe);
        },
        onLoad: function onLoad(iframe, width, height) {
          _this3.set('cardLoaded', true);
          _this3.formStyler(iframe, width, height);
        },
        onResize: get(this, 'formStyler'),
        onSuccess: function onSuccess() {
          _this3.get('analyticsService').addEvent('Credit Card Added');
          subscription.card().then(function (response) {
            var card = response.card.values;

            subscription.setProperties({
              cardNumber: card.masked_number, cardExpiration: card.expiry_month + '-' + card.expiry_year,
              cardType: card.card_type, cardFirstName: card.first_name, cardLastName: card.last_name
            });

            if (_this3.get('successCallback')) {
              _this3.sendAction('successCallback');
            }
            _this3.set('hasDirtyAttributes', false);
            _this3.set('isChangeMode', false);
          });
        }
      });
    },

    formStyler: function formStyler(iframe, width, height) {
      var style = 'border:none;overflow:hidden;width:100%;';
      style = style + 'height:' + height + 'px;';
      iframe.setAttribute('style', style);
    },
    actions: {
      toggleSideCard: function toggleSideCard() {
        if (this.get('isChangeMode')) {
          return;
        }
        this.toggleProperty('slideToggle');
      },
      hideChangeForm: function hideChangeForm() {
        if (this.get('isChangeMode')) {
          this.set('isChangeMode', false);
          return;
        }
      }
    }
  });
});