define('freshfone-ember/components/live-calls/live-agents-dashboard/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  var isPresent = _ember['default'].isPresent;

  var LIVE_AGENT_DIV = { wrapper: 'agents-list-wrapper' };

  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    telephony: service('telephony'),
    session: service(),
    currentAccount: computed.alias('session.account'),
    isOmnichannelEnabled: computed.alias('currentAccount.isOmnichannelEnabled'),
    hasOmnichannelAgentStatusFeature: computed('session', function () {
      return this.checkFeature('omnichannel_agent_status') && this.get('isOmnichannelEnabled');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.bindNodeEvents();
    },
    defaultStatuses: ['online', 'busy', 'acw', 'offline'],
    agentStatus: { online: 'Available', busy: 'Busy', acw: 'After Call Work', offline: 'Offline' },
    statusMapping: { Available: 'online', Busy: 'busy', 'After Call Work': 'acw', Offline: 'offline' },
    selectedQueueIds: computed.mapBy('selectedQueues', 'id'),
    sortedOfflineAgents: function sortedOfflineAgents(agents) {
      var agentsWithoutLastSeen = agents.filterBy('lastSeenAt', null),
          offlineAndLoggedIn = agents.filter(function (agent) {
        return agent.get('lastSeenAt') !== null && agent.get('loggedIn');
      }).sortBy('lastSeenAt'),
          offlineAndLoggedOut = agents.filter(function (agent) {
        return agent.get('lastSeenAt') !== null && !agent.get('loggedIn');
      }).sortBy('lastSeenAt');

      return [].concat(_toConsumableArray(offlineAndLoggedIn), _toConsumableArray(offlineAndLoggedOut), _toConsumableArray(agentsWithoutLastSeen));
    },

    selectedStatusTitle: computed('selectedStatus', function () {
      var status = this.get('selectedStatus');

      return this.isAgentStatus(status) ? status.get('default') ? status.get('dashboardStatusTitle') : status.get('name') : status;
    }),

    isAgentListEmpty: computed.equal('agents.length', 0),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.loadEnabledAgentStatuses();
      this.get('liveAgentMeta.all_agents') ? this.set('selectedStatus', 'All Agents') : this.set('selectedStatus', this.store.peekAll('agent-status').findBy('statusSubType', 'online'));

      this.get('telephony').on('custom_status_updated', function () {
        _this.loadEnabledAgentStatuses();
        _this.addNewCustomStatusToHash();
        !_this.get('enabledStatuses').findBy('id', _this.get('selectedStatus.id')) && _this.loadSelectedStatusAgents(_this.get('enabledStatuses').filterBy('default', true).findBy('statusType', 'offline'));
      });

      this.get('telephony').on('agent_status_change', function (agentId) {
        _ember['default'].run(function () {
          var agentData = _this.get('store').peekRecord('live-agent-status', agentId);

          if (_this.isAgentFromSelectedQueue(agentData)) {
            _this.modifyAgentStatusHash(agentData);
          }
        });
      });
    },

    addNewCustomStatusToHash: function addNewCustomStatusToHash() {
      var agentStatusMap = this.get('shadowAgentStatusHash');

      var _get$filter = this.get('enabledStatuses').filter(function (status) {
        return !agentStatusMap.get(String(status.get('id')));
      });

      var _get$filter2 = _slicedToArray(_get$filter, 1);

      var newAgentStatus = _get$filter2[0];

      if (newAgentStatus) {
        var agentHashObject = _ember['default'].Object.create({
          status: newAgentStatus,
          dashboardAgents: []
        });

        agentStatusMap.set(newAgentStatus.get('id'), agentHashObject);
      }
    },

    loadEnabledAgentStatuses: function loadEnabledAgentStatuses() {
      var _this2 = this;

      var customStatus = [],
          defaultSortedStatus = [],
          defaultStatus = [],
          defaultStatusKeys = Object.values(this.get('defaultStatuses'));

      this.store.peekAll('agent-status').filterBy('isNew', false).sort(function (statusA, statusB) {
        return statusA.get('id') - statusB.get('id');
      }).map(function (status) {
        if (status.get('default') || _this2.get('hasOmnichannelAgentStatusFeature')) {
          if (defaultStatusKeys.includes(status.get('statusType')) && status.get('statusSubType') !== 'online_phone') {
            defaultStatus.pushObject(status);
          }
        } else {
          status.get('enabled') && customStatus.pushObject(status);
        }
      });

      defaultStatusKeys.forEach(function (key) {
        return defaultStatus.findBy('statusType', key) && defaultSortedStatus.pushObject(defaultStatus.findBy('statusType', key));
      });

      this.set('enabledStatuses', [].concat(defaultSortedStatus, customStatus));
    },

    isAgentFromSelectedQueue: function isAgentFromSelectedQueue(agent) {
      var queueIds = this.get('selectedQueueIds');

      if (isEmpty(queueIds)) {
        return agent;
      }

      return queueIds.any(function (queueId) {
        return agent.get('callQueueIds').includes(Number(queueId));
      });
    },

    modifyAgentStatusHash: function modifyAgentStatusHash(agent) {
      /**
       * The purpose of Live-agent-dashboard is to list agents based on status. Introduction of custom-agent-status
       * has resulted in a need to combine status and browserStatus(preference of the user) to display the list.
       * Map is constructed with keys that includes 'status' attribute for default and 'browserStatusId' for agents
       * with non-default status.
       */
      var agentBrowserStatusKey = agent.get('browserStatus.default') ? agent.get('status') : String(agent.get('browserStatusId')),
          agentStatusHash = this.get('shadowAgentStatusHash');

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = agentStatusHash.values()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var key = _step.value;

          var removeIndex = key.dashboardAgents.findBy('id', agent.get('id'));

          if (removeIndex) {
            key.dashboardAgents.removeObject(removeIndex);
            break;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (agentStatusHash.has(agentBrowserStatusKey)) {
        var _agentStatusHash$get = agentStatusHash.get(agentBrowserStatusKey);

        var dashboardAgents = _agentStatusHash$get.dashboardAgents;

        agent.set('browserStatus', this.store.peekRecord('agent-status', agent.get('browserStatusId')));

        dashboardAgents.unshiftObject(agent);

        if (agent.get('status') === 'online') {
          agentStatusHash.get(agentBrowserStatusKey).set('dashboardAgents', this.sortSelectedAgents(agentStatusHash.get(agentBrowserStatusKey)));

          this.get('selectedStatus') !== 'All Agents' && this.get('selectedStatus.statusType') === 'online' && this.set('agents', agentStatusHash.get('online').get('dashboardAgents'));
        }
      }

      this.get('onAgentStatusHashUpdate')(agentStatusHash);
    },

    sortSelectedAgents: function sortSelectedAgents(_ref) {
      var status = _ref.status;
      var dashboardAgents = _ref.dashboardAgents;

      switch (status.get('statusType')) {
        case 'online':
          return dashboardAgents.sortBy('lastCallAt').reverse();

        case 'offline':
          return this.sortedOfflineAgents(dashboardAgents);
      }

      return dashboardAgents;
    },

    generateAgentStatusHash: function generateAgentStatusHash(liveAgents) {
      var _this3 = this;

      var computedAgentStatusMap = new Map(),
          enabledStatuses = this.get('enabledStatuses'),
          liveAgentsWithBrowserStatus = liveAgents.filterBy('browserStatusId');

      enabledStatuses.forEach(function (agentStatus) {
        (agentStatus['default'] || _this3.get('hasOmnichannelAgentStatusFeature')) && agentStatus.set('dashboardStatusTitle', _this3.get('agentStatus')[agentStatus.get('statusType')]);

        var agentHashObject = _ember['default'].Object.create({
          status: agentStatus,
          dashboardAgents: []
        });

        computedAgentStatusMap.set(agentStatus.get('default') || _this3.get('hasOmnichannelAgentStatusFeature') ? agentStatus.get('statusType') : agentStatus.get('id'), agentHashObject);
      });

      liveAgentsWithBrowserStatus.forEach(function (agent) {

        if (_this3.isAgentFromSelectedQueue(agent)) {
          agent.set('browserStatus', _this3.store.peekRecord('agent-status', agent.get('browserStatusId')));
          computedAgentStatusMap.get(agent.get('browserStatus.default') || _this3.get('hasOmnichannelAgentStatusFeature') ? agent.get('status') : String(agent.get('browserStatusId'))).get('dashboardAgents').pushObject(agent);
        }
      });

      [].concat(_toConsumableArray(computedAgentStatusMap.values())).map(function (key) {
        return key.set('dashboardAgents', [].concat(_toConsumableArray(_this3.sortSelectedAgents(key))));
      });

      return computedAgentStatusMap;
    },

    buildAgentStatusHash: function buildAgentStatusHash() {
      var liveAgents = this.store.peekAll('live-agent-status'),
          computedAgentStatus = this.generateAgentStatusHash(liveAgents);

      this.set('shadowAgentStatusHash', computedAgentStatus);
      this.get('onAgentStatusHashUpdate')(computedAgentStatus);
    },

    customAgentStatusList: computed('enabledStatuses', 'agents.@each.browserStatusId', 'selectedQueues.[]', function () {
      var computedAgentStatusHash = !this.get('displaySearchResults') ? this.get('shadowAgentStatusHash') : this.get('customStatusAgentsFromSearch'),
          customAgentStatusList = [].concat(_toConsumableArray(computedAgentStatusHash.values()));

      return customAgentStatusList;
    }),

    dropDownList: function dropDownList() {
      return {
        items: ['All Agents'].concat(_toConsumableArray(this.get('enabledStatuses'))),
        action: this.dropDownClickAction.bind(this)
      };
    },

    isAgentStatus: function isAgentStatus(status) {
      return typeof status === 'object';
    },

    loadSelectedStatusAgents: function loadSelectedStatusAgents(status) {
      this.set('selectedStatus', status);
      if (this.isAgentStatus(status)) {
        var agentBrowserStatusKey = status.get('default') || this.get('hasOmnichannelAgentStatusFeature') ? status.get('statusType') : String(status.get('id')),
            statusFromHash = this.get('shadowAgentStatusHash').get(agentBrowserStatusKey);

        this.set('agents', statusFromHash.get('dashboardAgents'));
      } else {
        this.set('agents', null);
      }
    },

    dropDownClickAction: function dropDownClickAction(status) {
      var _this4 = this;

      var fetchStatus = undefined;

      if (this.isAgentStatus(status)) {
        fetchStatus = status.get('statusType');
      } else {
        fetchStatus = status === 'All Agents' ? 'offline' : this.statusMapping[status];
      }

      if (!this.get('liveAgentMeta.all_agents') && this.get('telephony.shouldFetchliveAgents') && fetchStatus === 'offline') {
        this.get('telephony').set('shouldFetchliveAgents', false);
        this.set('fetchingRecord', true);
        this.fetchFromBackend(fetchStatus).then(function () {
          _this4.loadSelectedStatusAgents(status);
        });
      } else {
        this.loadSelectedStatusAgents(status);
      }
    },

    fetchFromBackend: function fetchFromBackend(status) {
      var _this5 = this;

      return _ember['default'].$.getJSON('/live-agent-statuses', { version: 'v1', status: status }).then(function (agentsResponse) {
        _this5.set('fetchingRecord', false);
        _ember['default'].run(function () {
          _this5.get('store').pushPayload(agentsResponse);
          _this5.buildAgentStatusHash();
        });
      });
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var selectedQueues = arguments[0].newAttrs.selectedQueues;

      if (selectedQueues) {
        this.buildAgentStatusHash();
        this.loadSelectedStatusAgents(this.get('selectedStatus'));
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony').off('custom_status_updated');
      this.get('telephony').off('agent_status_change');
    },

    actions: {
      modelCallback: function modelCallback(newAgentsSet) {
        if (this.get('model.query.filter.queryString.length') > 0) {
          this.set('displaySearchResults', true);
          this.set('customStatusAgentsFromSearch', this.generateAgentStatusHash(newAgentsSet));

          return this.set('agents', newAgentsSet);
        }

        this.set('displaySearchResults', false);

        return this.loadSelectedStatusAgents(this.get('selectedStatus'));
      },

      loadStatusDropdown: function loadStatusDropdown() {
        this.set('dropDownObj', this.dropDownList());
      },

      dropDownCallback: function dropDownCallback(status) {
        this.get('dropDownObj').action(status);
      }
    }

  });
});
/* eslint-disable ember-suave/no-direct-property-access */

// import multipleSticky from 'freshfone-ember/mixins/multiple-sticky';