define('freshfone-ember/constants/moment-code', ['exports'], function (exports) {
  exports['default'] = {
    'ar': 'ar',
    'ca': 'ca',
    'cs': 'cs',
    'da': 'da',
    'de': 'de',
    'en': 'en',
    'es-ES': 'es',
    'es-MX': 'es',
    'et': 'et',
    'fi': 'fi',
    'fr': 'fr',
    'he': 'he',
    'hu': 'hu',
    'id': 'id',
    'it': 'it',
    'ja': 'ja',
    'ko': 'ko',
    'lv': 'lv',
    'no': 'nb',
    'nl': 'nl',
    'pl': 'pl',
    'pt-BR': 'pt-br',
    'pt-PT': 'pt',
    'ro': 'ro',
    'ru': 'ru',
    'sk': 'sk',
    'sl': 'sl',
    'sv-SE': 'sv',
    'th': 'th',
    'tr': 'tr',
    'uk': 'uk',
    'vi': 'vi',
    'zh-CN': 'zh-CN',
    'zh-TW': 'zh-TW'
  };
});