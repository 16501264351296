define("freshfone-ember/components/ongoing-widget/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 6
                },
                "end": {
                  "line": 13,
                  "column": 6
                }
              },
              "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ongoing-widget/new-minimized-widget", [], ["class", "parked-mini-widget", "widgetIndex", ["subexpr", "@mut", [["get", "index", ["loc", [null, [7, 22], [7, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "totalCount", ["subexpr", "@mut", [["get", "calls.length", ["loc", [null, [8, 21], [8, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "callDetails", ["subexpr", "@mut", [["get", "call", ["loc", [null, [9, 22], [9, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "parallelCalls", ["subexpr", "@mut", [["get", "call.parallelCalls", ["loc", [null, [10, 24], [10, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "onMaximise", ["subexpr", "action", ["switchCall"], [], ["loc", [null, [11, 21], [11, 42]]], 0, 0]], ["loc", [null, [5, 8], [12, 10]]], 0, 0]],
            locals: ["call", "index"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 14,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "calls", ["loc", [null, [4, 14], [4, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 6], [13, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "minimi");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "calls.length", ["loc", [null, [3, 10], [3, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 4], [14, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 26,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["minimi ", ["subexpr", "if", [["get", "isMaximized", ["loc", [null, [18, 28], [18, 39]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [18, 23], [18, 48]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "ongoing-widget/minimized-widget", [], ["currentCall", ["subexpr", "@mut", [["get", "currentCall", ["loc", [null, [19, 52], [19, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "toggleTimer", ["subexpr", "@mut", [["get", "toggleTimer", ["loc", [null, [20, 18], [20, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "manualRecordingStarted", ["subexpr", "@mut", [["get", "manualRecordingStarted", ["loc", [null, [21, 29], [21, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "parallelCalls", ["subexpr", "@mut", [["get", "parallelCalls", ["loc", [null, [22, 20], [22, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "callHasMerged", ["subexpr", "@mut", [["get", "callHasMerged", ["loc", [null, [23, 20], [23, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 6], [24, 8]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "isActiveCall", ["loc", [null, [17, 8], [17, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 2], [26, 9]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 4
              },
              "end": {
                "line": 39,
                "column": 4
              }
            },
            "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "ongoing-widget/maximized-widget", [], ["activeFreshfoneNumber", ["subexpr", "@mut", [["get", "activeFreshfoneNumber", ["loc", [null, [33, 30], [33, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "callCompleted", ["subexpr", "action", ["callCompleted"], [], ["loc", [null, [34, 24], [34, 48]]], 0, 0], "ongoingWidgetOpen", ["subexpr", "@mut", [["get", "ongoingWidgetOpen", ["loc", [null, [35, 28], [35, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "setCallRatingParams", ["subexpr", "action", ["setCallRatingParams"], [], ["loc", [null, [36, 30], [36, 60]]], 0, 0], "manualRecordingStarted", ["subexpr", "@mut", [["get", "manualRecordingStarted", ["loc", [null, [37, 31], [37, 53]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [32, 6], [38, 8]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["maximi ", ["subexpr", "unless", [["get", "isMaximized", ["loc", [null, [30, 30], [30, 41]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [30, 21], [30, 50]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "ui-modal", [], ["class", "inconversation-modal", "size", "xxl", "closeOnEscape", false, "closeOnBackdropClick", false], 0, null, ["loc", [null, [31, 4], [39, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 0
            },
            "end": {
              "line": 47,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ongoing-widget/monitoring-widget", [], ["mute", ["subexpr", "@mut", [["get", "mute", ["loc", [null, [45, 42], [45, 46]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [45, 2], [45, 48]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/ongoing-widget/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "hasNewMinimisedWidgetSupport", ["loc", [null, [1, 6], [1, 34]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [27, 7]]]], ["block", "if", [["get", "isActiveCall", ["loc", [null, [29, 6], [29, 18]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [29, 0], [41, 7]]]], ["block", "if", [["get", "isMonitoredCall", ["loc", [null, [43, 6], [43, 21]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [43, 0], [47, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});