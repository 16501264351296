define('freshfone-ember/serializers/ivr', ['exports', 'freshfone-ember/serializers/application', 'freshfone-ember/mixins/serializers/embedded-records'], function (exports, _freshfoneEmberSerializersApplication, _freshfoneEmberMixinsSerializersEmbeddedRecords) {
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    attrs: {
      ivrOptions: { embedded: 'always' }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      var options = [],
          store = this.store;
      var ivrOptions = snapshot.record.get("ivrOptions");
      json.data.relationships["ivr-options"].data.forEach(function (option) {
        option.attributes['action-type'] = option.attributes['action-type'].decamelize();
        options.push(option.attributes);
        ivrOptions.forEach(function (option) {
          if (option) {
            store.unloadRecord(option);
          }
        });
      });
      json.data.attributes.ivrData = options;
      delete json.data.relationships["ivr-options"];
      return json;
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      // Customize IVR payload for IVR options association
      var normailzedIvrOptions = [];
      var editingIvrs = this.store.peekAll('ivr').filter(function (ivr) {
        return ivr.isDeepDirty();
      }).map(function (ivr) {
        return ivr.id;
      });
      var filteredIvrList = Ember.isArray(payload.data) && editingIvrs.length ? payload.data.filter(function (item) {
        return !editingIvrs.includes(item.id);
      }) : payload.data;

      if (Ember.isArray(filteredIvrList)) {
        filteredIvrList.forEach(function (ivr) {
          normailzedIvrOptions = _this._normalizeData(ivr);
          ivr.relationships['ivrOptions'] = normailzedIvrOptions;
          if (!payload.included) {
            payload.included = [];
          }
          normailzedIvrOptions.data.forEach(function (ivrOption) {
            var includedOptions = payload.included.filterBy('type', 'ivr-option');
            if (!includedOptions.any(function (option) {
              return option.id == ivrOption.id;
            })) {
              payload.included.push(ivrOption);
            }
          });
        });
        return this._super(store, primaryModelClass, payload, id, requestType);
      } else {
        normailzedIvrOptions = this._normalizeData(filteredIvrList);
      }
      filteredIvrList.relationships["ivrOptions"] = normailzedIvrOptions;
      payload.included = normailzedIvrOptions.data;
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    _normalizeData: function _normalizeData(data) {
      var ivrData = data.attributes["keypress-options"] || [];
      if (data.attributes['ivr-type'] === 2) {
        ivrData = this._normalizeKeypressOptions(ivrData);
      }
      return this._normalizeIvrOptions(data.id, ivrData);
    },

    _normalizeKeypressOptions: function _normalizeKeypressOptions(ivrData) {
      Object.keys(ivrData).forEach(function (key) {
        var newKey = ivrData[key]['option-key'];
        if (newKey && newKey !== key) {
          ivrData[newKey] = ivrData[key];
          delete ivrData[key];
        }
      });
      return ivrData;
    },

    _normalizeIvrOptions: function _normalizeIvrOptions(ivrId, ivrData) {
      var _this2 = this;

      var ivrOptions = [];
      var ivrKeys = Object.keys(ivrData);
      ivrKeys.forEach(function (k) {
        var ivrOption = {
          id: [ivrId, k].join('_'),
          type: 'ivr-option',
          attributes: _this2._normalizeIvrActions(k, ivrData[k]),
          relationships: {
            ivr: {
              data: {
                id: ivrId,
                type: "ivr"
              }
            }
          }
        };
        ivrOptions.push(ivrOption);
      });
      return { data: ivrOptions };
    },

    _normalizeIvrActions: function _normalizeIvrActions(id, attr) {
      return {
        optionKey: id,
        actionType: attr["action-type"] ? attr["action-type"].camelize() : '',
        actionId: attr["action-id"]
      };
    }
  });
});