define('freshfone-ember/components/call-logs/call-details/main-call-details/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['main-call-details'],
    isOutgoingCall: computed.equal('call.direction', 'outgoing'),
    isChildCallOrAgentLeg: computed.or('isChildCall', 'isAgentLeg'),
    hasCallFlow: computed.notEmpty('call.flowName'),
    hasCompany: computed.bool('call.contact.company'),
    directToContact: computed.alias('isChildCall'),
    callDirection: computed('call.id', function () {
      var callDirection = this.get('call.direction');

      return callDirection ? callDirection.capitalize() : callDirection;
    }),
    hasNameForNumber: computed('call.id', function () {
      if (this.get('isAgentLeg')) {
        return this.get('call.call.numberName') && this.get('call.call.number') !== this.get('call.call.numberName');
      }

      return this.get('call.numberName') && this.get('call.number') !== this.get('call.numberName');
    }),
    hasContactName: computed('call.id', function () {
      return this.get('call.contact.name') !== this.get('call.caller.number');
    }),

    actions: {
      changeCurrentCall: function changeCurrentCall() {
        this.get('changeCurrentCall')(this.get('call.id'), this.get('isAgentLeg'));
      }
    }
  });
});