define('freshfone-ember/services/timer', ['exports', 'ember', 'freshfone-ember/config/environment'], function (exports, _ember, _freshfoneEmberConfigEnvironment) {
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    // each timer should have a namespace to make them independant, interval and a callback
    create: function create(namespace, interval, callback) {
      if (!this.worker) this._register();
      if (!this.registerdTimersCallbacks[namespace]) {
        // if there are no callbacks register one
        this.registerdTimersCallbacks[namespace] = callback;
        this._postMessage({
          type: 'registerTimer',
          namespace: namespace,
          interval: interval
        });
      }
      return this;
    },
    // to destroy timer under respective namespace
    destroy: function destroy(namespace) {
      delete this.registerdTimersCallbacks[namespace]; // delete registered callback under respective namespace
      this._postMessage({ // clears timer for the given namespace in web worker
        type: 'deregisterTimer',
        namespace: namespace
      });
      return this;
    },
    // terminates the worker, psst clears all timers associated and destroys worker instance
    terminate: function terminate() {
      if (this.worker) {
        for (var namespace in this.registerdTimersCallbacks) {
          this._postMessage({
            type: 'deregisterTimer',
            namespace: namespace
          });
        }
        this.worker.terminate();
        // clean up
        delete this.registerdTimersCallbacks;
        delete this.worker;
      }
      return this;
    },
    _register: function _register() {
      this.registerdTimersCallbacks = {};
      this._messageEventHandler = this._messageEventHandler.bind(this);
      var workerUrl = 'workers/timer-worker.js';
      if (_freshfoneEmberConfigEnvironment['default'].environment === "test") {
        this.worker = new Worker(workerUrl);
      } else {
        var blob = new Blob(["importScripts('" + workerUrl + "');"], { "type": 'application/javascript' });
        var url = window.URL || window.webkitURL;
        var blobUrl = url.createObjectURL(blob);
        this.worker = new Worker(blobUrl);
      }
      this.worker.addEventListener('message', this._messageEventHandler); // listens to message event from webworker
    },
    _postMessage: function _postMessage(msg) {
      this.worker.postMessage(msg);
    },
    _messageEventHandler: function _messageEventHandler(event) {
      var data = event.data;
      if (data.type === 'tick' && this.registerdTimersCallbacks[data.namespace]) this.registerdTimersCallbacks[data.namespace](); // excutes callbacks defined under respective namespace
    }
  });
});
/*
    Service to run timer in a different thread using web worker
*/