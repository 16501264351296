define('freshfone-ember/routes/protected/companies/index', ['exports', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/routes/protected'], function (exports, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberRoutesProtected) {
  var _Ember = Ember;
  var computed = _Ember.computed;
  var i18n = _Ember.i18n;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    titleToken: computed(function () {
      return Ember.i18n.t('navigation.companies');
    }),
    beforeModel: function beforeModel() {
      if (this.hasOmniRedirectFeatureEnabled()) {
        return this.transitionTo('/dashboard');
      }
      this.checkPrivilege('view_customers');
    },

    model: function model() {
      return this.store.query('company', {});
    }

  });
});