define('freshfone-ember/helpers/s360-not-eql', ['exports', 'ember-unified-navbar-360/helpers/s360-not-eql'], function (exports, _emberUnifiedNavbar360HelpersS360NotEql) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberUnifiedNavbar360HelpersS360NotEql['default'];
    }
  });
  Object.defineProperty(exports, 's360NotEql', {
    enumerable: true,
    get: function get() {
      return _emberUnifiedNavbar360HelpersS360NotEql.s360NotEql;
    }
  });
});