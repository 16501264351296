define('freshfone-ember/components/ongoing-widget/call-timer/component', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, _ember, _emberComponentInboundActionsInboundActions) {
  var computed = _ember['default'].computed;
  var _Ember$run = _ember['default'].run;
  var later = _Ember$run.later;
  var cancel = _Ember$run.cancel;

  var momentjs = window.moment;exports['default'] = _ember['default'].Component.extend(_emberComponentInboundActionsInboundActions['default'], {
    tagName: 'span',
    timer: {},
    classNameBindings: ['maximizedWidget:maxi-widget-timer'],
    current: 0,
    autoStart: true,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('autoStart')) {
        this.start();
      }
    },

    start: function start() {
      // let self = this;
      var timer = later(this, function () {
        if (!(this.isDestroyed || this.isDestroying)) {
          this.updateTimer();
          this.start();
        }
      }, 1000);

      this.set('timer', timer);
    },

    formatedTime: computed('current', function () {
      if (this.get('current')) {
        var seconds = this.get('current'),
            format = seconds >= 3600 ? 'HH:mm:ss' : 'mm:ss',
            moment = momentjs();

        return moment.startOf('day').seconds(seconds).format(format);
      }
    }),

    updateTimer: function updateTimer() {
      this.incrementProperty('current');
    },

    stopTimer: function stopTimer() {
      if (this.get('timer')) {
        cancel(this.get('timer'));
        // this.set('timer', {});
      }
    },

    resetTimer: function resetTimer() {
      this.set('current', 0);
    },

    willDestroyElement: function willDestroyElement() {
      this.stopTimer();
    },

    actions: {
      stop: function stop() {
        this.stopTimer();
      },
      play: function play() {
        this.start();
      },
      resetTimer: function resetTimer() {
        this.resetTimer();
      }
    }

  });
});