define('freshfone-ember/mixins/send-sip-credential-mixin', ['exports', 'ember'], function (exports, _ember) {
  var later = _ember['default'].run.later;
  exports['default'] = _ember['default'].Mixin.create({
    resendSIPCredential: function resendSIPCredential(agent) {
      return _ember['default'].$.ajax({
        url: "/sip/reset_credential",
        type: 'PUT',
        dataType: 'json',
        data: { 'user_id': agent.get('user.id') }
      });
    }
  });
});