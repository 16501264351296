define("freshfone-ember/components/custom-elements/new-queue-option/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/custom-elements/new-queue-option/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "new-call-queue");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "icon-add-circle");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'onclick');
        morphs[1] = dom.createAttrMorph(element0, 'onmouseup');
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["attribute", "onclick", ["subexpr", "action", [["get", "extra.passedAction", ["loc", [null, [2, 45], [2, 63]]], 0, 0, 0, 0], "callQueue"], [], ["loc", [null, [null, null], [2, 77]]], 0, 0], 0, 0, 0, 0], ["attribute", "onmouseup", ["get", "select.actions.close", ["loc", [null, [2, 90], [2, 110]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["custom_elements.new_queue"], [], ["loc", [null, [4, 2], [4, 35]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});