define('freshfone-ember/utils/format-user-configuration', ['exports'], function (exports) {
  exports.formatFilterConfiguration = formatFilterConfiguration;

  function formatFilterConfiguration(savedFilterConfiguration) {
    var filterConfiguration = {};

    Object.keys(savedFilterConfiguration).forEach(function (key) {
      if (savedFilterConfiguration[key] && typeof savedFilterConfiguration[key] === 'object' && !Array.isArray(savedFilterConfiguration[key])) {
        filterConfiguration[key.underscore()] = formatFilterConfiguration(savedFilterConfiguration[key]);
      } else {
        filterConfiguration[key.underscore()] = savedFilterConfiguration[key];
      }
    });

    return filterConfiguration;
  }
});