define('freshfone-ember/adapters/application', ['exports', 'ember-data', 'ember', 'freshfone-ember/mixins/integration-check-mixin'], function (exports, _emberData, _ember, _freshfoneEmberMixinsIntegrationCheckMixin) {
  var service = _ember['default'].inject.service;
  var JSONAPIAdapter = _emberData['default'].JSONAPIAdapter;
  exports['default'] = JSONAPIAdapter.extend(_freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    coalesceFindRequests: true,
    session: service('session'),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (this.isFindRequest(requestType) && this.isSearchAction(query)) {
        return '/' + this.pathForType(modelName) + '/search';
      } else {
        return this._super(modelName, id, snapshot, requestType, query);
      }
    },
    isFindRequest: function isFindRequest(requestType) {
      var types = ['query', 'queryRecord'];
      return types.includes(requestType);
    },
    findAll: function findAll(store, type, query) {
      query = { paginate: false };
      return this.ajax(this.buildURL(type.modelName), 'GET', { data: query });
    },
    isSearchAction: function isSearchAction(query) {
      return query.filter;
    },
    handleResponse: function handleResponse(status) {
      if (status === 403) {
        if (!this.get('isEmbedParamPresent')) {
          // eslint-disable-next-line no-console
          console.log('Reloading session since status is 403');
          this.get('session').loadSession();
        }
      }

      return this._super.apply(this, arguments);
    }
  });
});