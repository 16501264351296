define('freshfone-ember/mixins/number-mixin', ['exports', 'ember', 'freshfone-ember/helpers/support-mail'], function (exports, _ember, _freshfoneEmberHelpersSupportMail) {
  var Mixin = _ember['default'].Mixin;
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = Mixin.create({
    saveNumber: function saveNumber(modelName, model, ignoreSavingProperty) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        model.set('saving', true);
        _this.set('purchaseError', false);
        model.save().then(function () {
          model.saveOriginalRelations();
          resolve(model);
        })['catch'](function (e) {
          var errorMsg = e.errors[0],
              twilioError = e.errors[1];

          model.get('errors').setProperties(e.errors);
          // eslint-disable-next-line one-var
          var isCrm = _this.get('session.account.isUnifiedAppAccount');
          var supportMailId = (0, _freshfoneEmberHelpersSupportMail.supportMail)(isCrm);
          var i18n = _ember['default'].i18n;

          _this.set('purchaseError', i18n.t('numbers.purchase.error', {
            errorMsg: errorMsg,
            supportMailId: supportMailId
          }));

          if (!ignoreSavingProperty) {
            model.set('isSaving', false);
            _this.deleteUnsavedNumbers();
          }

          if (twilioError) {
            _this.handleAddressValidationErrors();
          }
          reject({ errors: e.errors });
        });
      });
    },

    handleAddressValidationErrors: function handleAddressValidationErrors() {
      this.set('freshfoneNumber.address_required', true);
    },

    deleteUnsavedNumbers: function deleteUnsavedNumbers() {
      var unsavedNumbers = this.store.peekAll('number').filterBy('isNew', true);

      unsavedNumbers.forEach(function (unsavedNumber) {
        return unsavedNumber.deleteRecord();
      });
    }
  });
});