define('freshfone-ember/components/company-form/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'ember-component-inbound-actions/inbound-actions'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsFeatureCheckMixin, _emberComponentInboundActionsInboundActions) {
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _emberComponentInboundActionsInboundActions['default'], {
    classNameBindings: ['slideToggle:opened:closed'],
    store: service(),
    analyticsService: service('analytics-tracker'),
    startValidate: false,
    editMode: false,
    importService: service('import-service'),
    showImportModal: computed.alias('importService.importParams.showImportModal'),
    fileModel: computed.alias('importService.importParams.fileModel'),
    unsavedContacts: computed.filterBy('company.contacts', 'id', null),
    hasUnsavedContacts: computed.notEmpty('unsavedContacts'),
    hasImportFeature: computed(function () {
      return this.checkFeature('contact_company_import');
    }),

    company: computed({
      set: function set(key, value) {
        this.set('startValidate', false);
        if (value && value.get('id')) {
          this.set('editMode', true);
          if (this.get('companySidebar') && this.get('companySidebar.addMode')) {
            this.get('companySidebar').expandAccordion(false);
          }
          this.set('slideToggle', true);
          value.reload().then(function () {
            value.saveOriginalRelations();
          });
          // value.saveOriginalRelations();
          return value;
        } else {
          this.set('slideToggle', false);
          return this.createNew();
        }
      },
      get: function get() {
        return this;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('company.id')) {
        this.set('company');
      }
    },

    isDirty: computed('company.hasDirtyAttributes', 'company.contacts.[]', 'company.contacts.length', {
      get: function get() {
        return this.get('company').isDeepDirty();
      }
    }).readOnly(),

    willDestroy: function willDestroy() {
      var model = this.get('company');
      if (model.get('isNew')) {
        model.deleteRecord();
      }
      this.rollbackDirty('company');
    },

    createNew: function createNew() {
      var company = this.get("store").peekAll('company');
      var newCompany = company.filterBy('isNew', true)[0] || this.get("store").createRecord('company');
      newCompany.saveOriginalRelations();
      return newCompany;
    },

    title: _ember['default'].computed('company.isNew', function () {
      return _ember['default'].i18n.t('company.' + (this.get('company.isNew') ? 'new_company' : 'company_details'));
    }),

    resetForm: function resetForm() {
      this.set('slideToggle', false);
      this.set('company');
      this.set('editMode', false);
    },

    isSwitchable: computed.and('editMode', 'parent'),

    saveContacts: function saveContacts() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        var contacts = _this.get('unsavedContacts');
        var lastIndex = contacts.length - 1;
        contacts.forEach(function (contact, index) {
          contact.save().then(function () {
            if (lastIndex === index) {
              resolve();
            }
          });
        });
      });
    },

    saveCompany: function saveCompany() {
      var _this2 = this;

      var self = this,
          company;
      this.set('startValidate', true);
      company = this.get('company');
      company.set('isFlash', false);
      company.save().then(function (company) {
        company.set('isFlash', true);
        self.set("company", company);
        self.sendAction('onModify');
        company.saveOriginalRelations();
        if (self.get('isSwitchable')) {
          self.get('parent').set('editCompany', false);
        } else {
          // self.resetForm();
        }

        _this2.send('toggleNewCompany');
      });
    },

    rollbackDirty: function rollbackDirty() {
      this.get("company").rollbackAttributes();
      this.get("company").rollbackRelationships();
    },
    actions: {
      setCompanySidebarReference: function setCompanySidebarReference(childReference) {
        this.set('companySidebar', childReference);
      },
      createNewContact: function createNewContact(select) {
        var contactName = select.searchText;
        var contact = this.get("store").createRecord('contact', { name: contactName });
        select.actions.choose(contact);
      },
      save: function save() {
        var _this3 = this;

        if (this.get('hasUnsavedContacts')) {
          this.saveContacts().then(function () {
            _this3.saveCompany();
          });
        } else {
          this.saveCompany();
        }
      },
      showImport: function showImport() {
        if (this.get('hasImportFeature')) {
          this.setProperties({
            fileModel: 'companies',
            showImportModal: true
          });
        }
      },

      toggleNewCompany: function toggleNewCompany() {
        this.get('analyticsService').addEvent('Add Company');
        if (this.get('slideToggle')) {
          //this.get("company").rollbackAttributes();
          this.rollbackDirtyChanges('company');
          this.resetForm();
        } else {
          this.set('slideToggle', true);
        }
      },
      onContactSearch: function onContactSearch(text) {
        var _this4 = this;

        if (isEmpty(text)) {
          return;
        }
        var searchTerm = encodeURIComponent(text.toLowerCase());
        this.set('searchTerm', decodeURIComponent(searchTerm));
        return _ember['default'].$.getJSON("/contacts/search", { filter: { queryString: searchTerm }, source: 'dropdown' }).then(function (resultContacts) {
          var resultContactIds = resultContacts.data.mapBy('id');
          _this4.store.pushPayload(resultContacts);
          var results = _this4.store.peekAll('contact').filter(function (contactObj) {
            return resultContactIds.contains(contactObj.get('id'));
          });
          return results;
        });
      }
    }
  });
});