define('freshfone-ember/components/outgoing-widget/recent-calls/power-dialer-invalid-number/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    notifications: service('toastr'),
    account: computed.alias('session.account'),
    isLowCredit: computed.alias('account.credit.isCreditLow'),
    callingDisabled: computed.alias('account.callingDisabled'),
    nextContactToolTip: computed('nextContact.name', function () {
      return _ember['default'].i18n.t('power_dialer.next_contact_tooltip', { name: this.get('nextContact.name') });
    }),
    powerDialerService: service('power-dialer-list'),
    totalContactsInPowerDialer: computed.alias('powerDialerService.totalContactsInPowerDialer'),
    leftContactsInPowerDialer: computed.alias('powerDialerService.leftContactsInPowerDialer'),
    lastPowerDialerContact: computed.alias('powerDialerService.lastPowerDialerContact'),
    dialedContacts: computed('leftContactsInPowerDialer', function () {
      return this.get('totalContactsInPowerDialer') - this.get('leftContactsInPowerDialer');
    }),
    disablePowerDialer: computed('totalContactsInPowerDialer', 'dialedContacts', 'isLowCredit', 'callingDisabled', function () {
      if (this.get('totalContactsInPowerDialer') - this.get('dialedContacts') <= 0) {
        this.get('notifications').success(_ember['default'].i18n.t('power_dialer.notifications.power_dialer_completed'));
      }
      return this.get('totalContactsInPowerDialer') - this.get('dialedContacts') <= 0 || this.get('isLowCredit') || this.get('callingDisabled');
    }),

    actions: {
      dialNext: function dialNext() {
        this.sendAction('fetchNext');
      },
      stopPowerDialerMode: function stopPowerDialerMode() {
        var _this = this;

        this.store.peekAll('power-dialer-list').get('lastObject').stop().then(function () {
          _this.setProperties({
            isInvalidNumber: false,
            isDialing: false,
            searchTerm: '',
            activeTab: 'powerDailer'
          });
          _this.set('currentAgent.powerDialer', false);
        });
      }
    }
  });
});