define("freshfone-ember/components/omnibar-placeholder/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 13,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/omnibar-placeholder/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("freshworks-omnibar");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element1, 'data-omnibar-environment');
            morphs[1] = dom.createAttrMorph(element1, 'data-omnibar-product');
            morphs[2] = dom.createAttrMorph(element1, 'data-omnibar-user-role');
            morphs[3] = dom.createAttrMorph(element1, 'data-omnibar-organisation-domain');
            morphs[4] = dom.createAttrMorph(element1, 'data-omnibar-client-id');
            morphs[5] = dom.createAttrMorph(element1, 'data-omnibar-session-state');
            morphs[6] = dom.createAttrMorph(element1, 'data-omnibar-session-token');
            morphs[7] = dom.createAttrMorph(element1, 'data-omnibar-hide');
            morphs[8] = dom.createAttrMorph(element1, 'data-omnibar-region');
            return morphs;
          },
          statements: [["attribute", "data-omnibar-environment", ["concat", [["get", "omniBar.environment", ["loc", [null, [4, 34], [4, 53]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-product", ["concat", [["get", "omniBar.productName", ["loc", [null, [5, 30], [5, 49]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-user-role", ["concat", [["get", "roleName", ["loc", [null, [6, 32], [6, 40]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-organisation-domain", ["concat", [["get", "organisationDomain", ["loc", [null, [7, 42], [7, 60]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-client-id", ["concat", [["get", "freshidV2Config.clientId", ["loc", [null, [8, 32], [8, 56]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-session-state", ["concat", [["get", "sessionstate", ["loc", [null, [9, 36], [9, 48]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-session-token", ["concat", [["get", "sessionToken", ["loc", [null, [10, 36], [10, 48]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-hide", ["get", "session.account.isUnifiedAppAccount", ["loc", [null, [11, 26], [11, 61]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-region", ["concat", [["get", "omniBar.region", ["loc", [null, [12, 29], [12, 43]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 2
              },
              "end": {
                "line": 20,
                "column": 2
              }
            },
            "moduleName": "freshfone-ember/components/omnibar-placeholder/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("freshworks-omnibar");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'data-omnibar-environment');
            morphs[1] = dom.createAttrMorph(element0, 'data-omnibar-product');
            morphs[2] = dom.createAttrMorph(element0, 'data-omnibar-user-role');
            morphs[3] = dom.createAttrMorph(element0, 'data-omnibar-hide');
            morphs[4] = dom.createAttrMorph(element0, 'data-omnibar-region');
            return morphs;
          },
          statements: [["attribute", "data-omnibar-environment", ["concat", [["get", "omniBar.environment", ["loc", [null, [15, 34], [15, 53]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-product", ["concat", [["get", "omniBar.productName", ["loc", [null, [16, 30], [16, 49]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-user-role", ["concat", [["get", "roleName", ["loc", [null, [17, 32], [17, 40]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-hide", ["get", "session.account.isUnifiedAppAccount", ["loc", [null, [18, 26], [18, 61]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-omnibar-region", ["concat", [["get", "omniBar.region", ["loc", [null, [19, 29], [19, 43]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 21,
              "column": 0
            }
          },
          "moduleName": "freshfone-ember/components/omnibar-placeholder/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "freshidVersion2", ["loc", [null, [2, 8], [2, 23]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [20, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/omnibar-placeholder/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "omniBarReady", ["loc", [null, [1, 6], [1, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [21, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});