define('freshfone-ember/components/billing-base/credit-actions/credit-history/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    showCreditUsagesHistory: false,
    creditUsagesList: computed(function () {
      return this.store.query('credit-usage', {});
    }),
    maxEntriesToShow: 2,
    showMore: computed.gt('creditUsagesList.length', 2),
    filteredUsages: computed('filterNumber', 'maxEntriesToShow', 'creditUsagesList.@each', function () {
      return this.get('creditUsagesList').slice(0, this.get('maxEntriesToShow'));
    }),
    hasCreditUsages: computed.notEmpty('creditUsagesList'),
    actions: {
      openCreditUsagesHistory: function openCreditUsagesHistory() {
        this.toggleProperty('showCreditUsagesHistory');
      },
      closeModalParent: function closeModalParent() {
        this.toggleProperty('showCreditUsagesHistory');
      }
    }
  });
});