define("freshfone-ember/components/custom-elements/star-rating/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/custom-elements/star-rating/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'data-rating');
          morphs[1] = dom.createAttrMorph(element0, 'class');
          return morphs;
        },
        statements: [["attribute", "data-rating", ["get", "starRating", ["loc", [null, [3, 21], [3, 31]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["icon-star star-", ["get", "starRating", ["loc", [null, [3, 58], [3, 68]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["subexpr", "eq", [["get", "rating", ["loc", [null, [3, 80], [3, 86]]], 0, 0, 0, 0], ["get", "starRating", ["loc", [null, [3, 87], [3, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 76], [3, 98]]], 0, 0], "active"], [], ["loc", [null, [3, 71], [3, 109]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: ["starRating"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/custom-elements/star-rating/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "star-rating-container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["element", "action", ["setRating"], [], ["loc", [null, [1, 35], [1, 57]]], 0, 0], ["block", "each", [["get", "starRatings", ["loc", [null, [2, 10], [2, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [4, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});