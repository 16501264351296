define('freshfone-ember/components/plan-modal/plan-edit/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    isEditing: true,
    isCardAdded: true,
    savingStarted: computed.not('isEditing'),
    isSproutIsCurrentPlan: computed.equal('currentPlan.name', 'Sprout'),
    optionList: computed('isSproutIsCurrentPlan', function () {
      return [{ id: '1', name: _ember['default'].i18n.t('subscription.monthly'), disabled: this.get('isSproutIsCurrentPlan') }, { id: '3', name: _ember['default'].i18n.t('subscription.quarterly') }, { id: '6', name: _ember['default'].i18n.t('subscription.half_yearly') }, { id: '12', name: _ember['default'].i18n.t('subscription.annual') }];
    }),
    currentBillingCycle: computed('subscription.renewalPeriod', 'optionList', {
      get: function get() {
        return this.get('optionList').findBy('id', this.get('subscription.renewalPeriod'));
      },
      set: function set(k, v) {
        this.set('subscription.renewalPeriod', v.id);
        return v;
      }
    }),
    agentSeats: computed('subscription.quantity', {
      get: function get() {
        return this.get('subscription.quantity');
      },
      set: function set(k, v) {
        this.set('subscription.quantity', v);
        this.set('serverErrors', null);
        return v;
      }
    }),
    currentPlan: computed('subscription.planId', function () {
      return this.store.peekRecord('plan', this.get('subscription.planId'));
    }),
    actions: {
      savePlanDetails: function savePlanDetails() {
        this.set('isEditing', false);
      },
      continueToPay: function continueToPay() {
        var _this = this;

        this.get('subscription.content').validate().then(function () {
          _this.set('isSaving', true);
          _this.sendAction('continueToPay');
        })['catch'](function (e) {
          console.log('error', e);
          _this.set('startValidate', true);
        });
      }
    }
  });
});