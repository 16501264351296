define('freshfone-ember/components/billing-base/subscription-base/cancel-account-survey-modal/secondary-question/component', ['exports'], function (exports) {
  var _Ember = Ember;
  var computed = _Ember.computed;
  var Component = _Ember.Component;
  exports['default'] = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('secondaryQuestion', this.get('secondaryQuestion').sortBy('required').reverse());
    }
  });
});