define('freshfone-ember/components/number-component/edit-form/component', ['exports', 'freshfone-ember/utils/call-action-constants', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/number-mixin', 'freshfone-ember/mixins/remove-feature-actiontype'], function (exports, _freshfoneEmberUtilsCallActionConstants, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsNumberMixin, _freshfoneEmberMixinsRemoveFeatureActiontype) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var _Ember$computed = _ember['default'].computed;
  var equal = _Ember$computed.equal;
  var or = _Ember$computed.or;
  var run = _ember['default'].run;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsNumberMixin['default'], _freshfoneEmberMixinsRemoveFeatureActiontype['default'], {
    classNameBindings: ['activeTabName'],
    tabs: [{
      displayName: 'Properties',
      name: 'general'
    }, {
      displayName: 'Call Actions',
      name: 'call-handling'
    }],
    isGeneralTabActive: equal('activeTabName', 'general'),
    hasFeatureBusinessHour: computed(function () {
      return this.hasFeatures(['business_hours']);
    }),
    freshfoneNumber: computed({
      set: function set(k, v) {
        this.set('activeTabName', 'general');
        return v;
      },
      get: function get() {}
    }),
    rule: computed('freshfoneNumber', function () {
      return this.store.peekRecord('rule', this.get('freshfoneNumber.rule.id')) || this.store.createRecord('rule', { number: this.get('freshfoneNumber') });
    }),
    isNumberDirty: computed('freshfoneNumber.hasDirtyAttributes', 'freshfoneNumber.numberTranscriptionFlag.hasDirtyAttributes', 'freshfoneNumber.{name,holdMessage,ringtoneMessage,callerId,maskingEnabled,recordType,shortAbandonTimeout,recordingOptIn,recordingOptInMessage}', 'freshfoneNumber.teams.@each.id', {
      get: function get() {
        if (!this.get('freshfoneNumber.maskingEnabled')) {
          this.set('freshfoneNumber.callerId', null);
        }
        if (this.onlyAbandonStringChanged(this.get('freshfoneNumber').changedAttributes())) {
          return false;
        }
        return this.get('freshfoneNumber').isDeepDirty();
      }
    }).readOnly(),
    isRuleDirty: computed('rule.hasDirtyAttributes', 'rule.businessCalendar', 'rule.number', 'rule.number.id', {
      get: function get() {
        return this.get('rule') && this.get('rule').isDeepDirty();
      }
    }).readOnly(),
    showSaveButton: or('isRuleDirty', 'isNumberDirty').readOnly(),
    isSaving: or('freshfoneNumber.isSaving', 'rule.isSaving'),
    actionTypes: computed(function () {
      var actionTypes = (0, _freshfoneEmberUtilsCallActionConstants['default'])();

      if (!this.hasFeatures(['ivr'])) {
        actionTypes = this.removeObjFromArray(actionTypes, 'abbr', 'Ivr');
      }
      if (!this.hasFeatures(['voicemail_and_status_fallback'])) {
        actionTypes = this.removeObjFromArray(actionTypes, 'abbr', 'vm');
      }

      if (!this.hasFeatures(['deflection_bot'])) {
        actionTypes = this.removeObjFromArray(actionTypes, 'abbr', 'DeflectionBot');
      }

      if (!this.hasFeatures(['agent_extension']) || !this.get('session.account').hasGlobalFlag('agent_extension')) {
        actionTypes = this.removeObjFromArray(actionTypes, 'abbr', 'AgentExtension');
      }

      if (!this.hasFeatures(['routing_automation'])) {
        actionTypes = this.removeObjFromArray(actionTypes, 'abbr', 'RoutingAutomation');
      }

      return actionTypes;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('freshfoneNumber.callerId.id')) {
        this.set('freshfoneNumber.maskingEnabled', false);
      }
      if (this.get('callFlowID')) {
        var businessHourAction = this.store.peekRecord('callFlow', this.get('callFlowID'));

        if (isPresent(businessHourAction)) {
          var businessHourType = this.get('actionTypes').filterBy('abbr', businessHourAction.get('typeName')).get('firstObject');

          this.setCallQueueAction(businessHourType.id, this.get('callFlowID'));
        }
      }
      this.set('activeTabName', this.get('callFlowID') ? 'call-handling' : 'general');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('contentFormElement', $('.content-form'));
    },

    defaultMessageTitle: function defaultMessageTitle() {
      return this.store.peekAll('message').content.length + 1;
    },
    defaultBusinessHourTitle: function defaultBusinessHourTitle() {
      return this.store.peekAll('business-calendar').mapBy('name').length + 1;
    },
    onlyAbandonStringChanged: function onlyAbandonStringChanged(attrs) {
      if ('shortAbandonTimeout' in attrs && Object.keys(attrs).length === 1) {
        return attrs.shortAbandonTimeout[0] === Number.parseInt(attrs.shortAbandonTimeout[1], 10);
      }

      return false;
    },
    setCallQueueAction: function setCallQueueAction(businessHourType, businessHourAction) {
      var actionFor = {
        bh: 'businessHour',
        nbh: 'nonBusinessHour',
        hol: 'holiday'
      };

      this.set('rule.' + actionFor[this.get('actionFor')] + 'ActionType', businessHourType);
      this.set('rule.' + actionFor[this.get('actionFor')] + 'Action', businessHourAction);
    },
    toggleTab: function toggleTab(tabName) {
      if (this.get('activeTabName') !== tabName) {
        this.send('setActiveTabName', tabName);
      }
    },
    handleFormErrors: function handleFormErrors(e) {
      var _this = this;

      var hasErrors = $('.hasError');

      if (e.errors) {
        return this.get('ruleForm').handleError(e);
      }
      if (e.hasOwnProperty('freshfoneNumber')) {
        this.toggleTab('general');
      } else {
        this.toggleTab('call-handling');
      }
      if (hasErrors.length) {
        later(function () {
          return _this.get('contentFormElement').animate({ scrollTop: $('.hasError').offset().top }, 500);
        });
      }
    },
    saveDirtyForms: function saveDirtyForms() {
      return Promise.all([this.get('isNumberDirty') ? this.saveNumber('number', this.get('freshfoneNumber')) : null, this.get('isRuleDirty') ? this.saveNumber('rule', this.get('rule')) : null]);
    },

    actions: {
      update: function update() {
        var _this2 = this;

        this.get('ruleForm').handleError();
        Promise.all([this.get('numberForm').evaluate(), this.get('ruleForm').validate()]).then(function () {
          return run(function () {
            return _this2.saveDirtyForms();
          });
        }).then(function () {
          return run(function () {
            return _this2.sendAction('update');
          });
        })['catch'](function (e) {
          return _this2.handleFormErrors(e);
        });
      },
      verify: function verify(number) {
        this.sendAction('verify', number);
      },
      retry: function retry() {
        this.sendAction('retry');
      },
      toggleCallMask: function toggleCallMask() {
        this.sendAction('toggleCallMask');
      },
      closeCallMask: function closeCallMask() {
        this.sendAction('closeCallMask');
      },
      showNewMessage: function showNewMessage(attribute) {
        var newObject = this.store.createRecord('message', { name: _ember['default'].i18n.t('message_components.record_component.message') + ' ' + this.defaultMessageTitle() });

        this.set('modelProp', attribute);
        this.set('newMessage', newObject);
        this.set('showMessage', true);
      },
      messageSaveCallback: function messageSaveCallback(message) {
        this.set(this.get('modelProp'), this.get('isGeneralTabActive') ? message : message.get('id'));
        this.set('modelProp');
        this.set('showMessage');
      },
      buyNumber: function buyNumber() {
        this.set('showBuyNumber', true);
      },
      showBusinessHour: function showBusinessHour() {
        this.set('showBusinessHour', true);
      },
      closeBusinessHour: function closeBusinessHour(businessCalendar) {
        this.set('freshfoneNumber.rule.businessCalendarId', businessCalendar.get('id'));
        this.set('businessCalendar');
      },
      setActiveTabName: function setActiveTabName(name) {
        this.set('activeTabName', name);
      },
      toggleHolidayCalendar: function toggleHolidayCalendar() {
        this.toggleProperty('holidayCalendarOpen');
      },
      setNumberFormRef: function setNumberFormRef(numberForm) {
        this.set('numberForm', numberForm);
      },
      setRuleFormRef: function setRuleFormRef(ruleForm) {
        this.set('ruleForm', ruleForm);
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */