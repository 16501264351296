define('freshfone-ember/components/manage-intents/view-edit-intent/component', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        store: service('store'),
        classNames: 'view-edit-container',

        intentsList: computed(function () {
            return this.store.peekAll('intent');
        })
    });
});