define('freshfone-ember/serializers/company', ['exports', 'freshfone-ember/mixins/serializers/embedded-records', 'freshfone-ember/serializers/application'], function (exports, _freshfoneEmberMixinsSerializersEmbeddedRecords, _freshfoneEmberSerializersApplication) {
  exports['default'] = _freshfoneEmberSerializersApplication['default'].extend(_freshfoneEmberMixinsSerializersEmbeddedRecords['default'], {
    attrs: {
      calls: { serialize: false },
      contacts: { embedded: 'always' }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      json.data.relationships = json.data.relationships || {};
      json.data.relationships.address = {
        data: {
          id: null,
          type: 'address',
          attributes: {
            address1: json.data.attributes.address
          }
        }
      };
      delete json.data.attributes.address;
      delete json.data.attributes['total-calls'];
      delete json.data.attributes['total-contacts'];
      return json;
    }

  });
});

// import { ActiveModelSerializer } from 'active-model-adapter';