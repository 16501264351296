define('freshfone-ember/models/network-warning', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    warningMessage: attr('string'),
    warningData: attr(),
    userId: attr('number'),
    snoozed: attr('boolean'),
    eventStartTime: attr('string'),
    eventEndTime: attr('string')
  });
});