define('freshfone-ember/components/number-component/numbers-list/show-restricted-countries/component', ['exports', 'freshfone-ember/utils/alert', 'ember', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/helpers/support-mail'], function (exports, _freshfoneEmberUtilsAlert, _ember, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberHelpersSupportMail) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    i18n: service(),
    restrictedCountryList: [],
    selectedCountryLength: 0,
    currentAccount: computed.alias('session.account'),
    isCustomerDocumentVerified: computed.alias('currentAccount.customerDocumentVerified'),
    messageInfo: (0, _freshfoneEmberUtilsAlert.getAlertInfo)(),
    countryOptionsList: computed('restrictedCountryList', function () {
      return this.get('restrictedCountryList').map(function (country) {
        return {
          name: country,
          value: country.replace(/[^a-zA-Z]/g, ''),
          checked: false
        };
      });
    }),
    showCountryCount: computed.gt('selectedCountryLength', 0),
    emptySearchString: computed.empty('searchString'),
    searchRegex: computed('searchString', function () {
      return new RegExp(this.get('searchString'), 'i');
    }),
    filteredCountryList: computed('countryOptionsList.[]', 'searchString', function () {
      if (this.get('emptySearchString')) {
        return this.get('countryOptionsList');
      }
      var searchRegex = this.get('searchRegex');

      return this.get('countryOptionsList').filter(function (country) {
        return searchRegex.test(country.name);
      });
    }),
    isSubmitDisabled: computed('isCustomerDocumentVerified', 'isRestricting', 'selectedCountryLength', function () {
      return !this.get('isCustomerDocumentVerified') || this.get('isRestricting') || this.get('selectedCountryLength') === 0;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.isVerified();
      this.fetchCountries();
    },
    isVerified: function isVerified() {
      if (this.get('isCustomerDocumentVerified')) {
        return;
      }
      this.set('messageInfo', (0, _freshfoneEmberUtilsAlert.getAlertInfo)(_freshfoneEmberUtilsAlert.alertTypes.info, this.get('i18n').t('caller_mask.avail_doc_for_whitelisting', { supportEmail: (0, _freshfoneEmberHelpersSupportMail.supportMail)(this.get('isCrmView')) })));
      this.set('isNotifyOn', true);
    },
    buildWhitelistRequestPayload: function buildWhitelistRequestPayload() {
      var allowedCountryList = this.get('countryOptionsList').filter(function (countryOption) {
        return countryOption.checked;
      });

      return allowedCountryList.map(function (allowedCountry) {
        return allowedCountry.name;
      });
    },
    fetchCountries: function fetchCountries() {
      var _this = this;

      this.set('isLoadError', false);
      this.set('isLoading', true);
      $.ajax({
        url: '/accounts/' + this.get('currentAccount').id + '/countries_list',
        type: 'GET',
        dataType: 'json'
      }).done(function (data) {
        run(function () {
          _this.set('isLoading', false);
          _this.set('restrictedCountryList', data.restricted_countries);
        });
      }).fail(function () {
        run(function () {
          _this.set('isLoading', false);
          _this.set('messageInfo', (0, _freshfoneEmberUtilsAlert.getAlertInfo)(_freshfoneEmberUtilsAlert.alertTypes.danger, _this.get('i18n').t('caller_mask.failed_country_load')));
          _this.set('isLoadError', true);
        });
      });
    },
    whitelistCountries: function whitelistCountries() {
      var _this2 = this;

      if (!this.get('isCustomerDocumentVerified')) {
        return;
      }

      var allowedCountries = this.buildWhitelistRequestPayload();

      this.set('isRestricting', true);
      this.set('isNotifyOn', false);
      $.ajax({
        url: '/accounts/' + this.get('currentAccount').id + '/whitelist_countries',
        type: 'POST',
        dataType: 'json',
        // eslint-disable-next-line camelcase
        data: { allowed_countries: allowedCountries }
      }).done(function () {
        run(function () {
          var updatedRestrictedCountryList = _this2.get('restrictedCountryList').filter(function (restrictedCountry) {
            return !allowedCountries.includes(restrictedCountry);
          });

          _this2.set('restrictedCountryList', updatedRestrictedCountryList);
          _this2.set('selectedCountryLength', 0);
          _this2.set('messageInfo', (0, _freshfoneEmberUtilsAlert.getAlertInfo)(_freshfoneEmberUtilsAlert.alertTypes.success, _this2.get('i18n').t('caller_mask.whitelisted_message')));
        });
      }).fail(function () {
        run(function () {
          _this2.set('messageInfo', (0, _freshfoneEmberUtilsAlert.getAlertInfo)(_freshfoneEmberUtilsAlert.alertTypes.danger, _this2.get('i18n').t('caller_mask.failed_whitelist_country')));
        });
      }).always(function () {
        return run(function () {
          _this2.set('isRestricting', false);
          _this2.set('isNotifyOn', true);
        });
      });
    },
    actions: {
      toggleCheckbox: function toggleCheckbox(key, value) {
        if (value) {
          this.set('selectedCountryLength', this.selectedCountryLength + 1);
        } else {
          this.set('selectedCountryLength', this.selectedCountryLength - 1);
        }
      },
      submitCallback: function submitCallback() {
        this.whitelistCountries();
      },
      closeCallback: function closeCallback() {
        this.sendAction('closeCallback');
      }
    }
  });
});