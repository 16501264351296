define('freshfone-ember/services/parallel-call', ['exports', 'freshfone-ember/constants/active-call-type', 'freshfone-ember/constants/call-state', 'freshfone-ember/constants/disconnect-source-type', 'ember', 'freshfone-ember/constants/park-state'], function (exports, _freshfoneEmberConstantsActiveCallType, _freshfoneEmberConstantsCallState, _freshfoneEmberConstantsDisconnectSourceType, _ember, _freshfoneEmberConstantsParkState) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var Evented = _ember['default'].Evented;
  var later = _ember['default'].run.later;
  var Service = _ember['default'].Service;
  var Object = _ember['default'].Object;
  var run = _ember['default'].run;
  var testing = _ember['default'].testing;
  exports['default'] = Service.extend(Evented, {
    telephony: service(),
    parkedCallsService: service('parked-calls'),
    parkedCalls: computed.alias('parkedCallsService.parkedCalls'),
    notification: service('toastr'),
    i18n: service(),
    parallelCalls: [],
    parallelGroupIds: [],
    parkedParallelCalls: null,
    currentCall: computed.alias('telephony.currentCall'),
    callHasMerged: false,
    loadingStatus: {
      resuming: false,
      disconnectingFromBanner: false,
      disconnectingFromControls: false,
      merging: false,
      parking: false
    },
    parallelCallRemoved: computed('parallelCalls.[]', 'parallelCalls.@each.state', function () {
      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];

      return !parallelCall || parallelCall.get('state') === _freshfoneEmberConstantsCallState['default'].Removed;
    }),
    parallelContact: computed('parallelCalls.[]', 'parallelCalls.@each.target', function () {
      var parallelCallTarget = this.get('parallelCalls').length > 0 ? this.get('parallelCalls').get('firstObject') : null,
          transferTypes = ['agent', 'external_number', 'team'];

      if (parallelCallTarget && parallelCallTarget.target && transferTypes.includes(parallelCallTarget.transferType.toLowerCase())) {
        return {
          hasContactForSideBar: false,
          displayName: parallelCallTarget.target.name,
          contactForSideBar: parallelCallTarget.target,
          text: parallelCallTarget.target.name
        };
      }
    }),
    parallelConferenceCaller: computed('parallelCalls.@each.conferenceTarget', function () {
      var _get3 = this.get('parallelCalls');

      var _get32 = _slicedToArray(_get3, 1);

      var parallelCall = _get32[0];
      var conferenceTarget = parallelCall && parallelCall.get('conferenceTarget');

      if (conferenceTarget && conferenceTarget.name) {
        return {
          hasContactForSideBar: false,
          displayName: conferenceTarget.name,
          contactForSideBar: conferenceTarget,
          text: conferenceTarget.name
        };
      }
    }),
    isParallelCallActive: computed('parallelCalls.[]', 'parallelCalls.@each.{state,conferenceState}', function () {
      var _get4 = this.get('parallelCalls');

      var _get42 = _slicedToArray(_get4, 1);

      var parallelCall = _get42[0];

      if (!parallelCall) {
        return false;
      }

      return !this.isParallelCallAndConferenceEnded();
    }),
    isLoading: computed.or('loadingStatus.resuming', 'loadingStatus.merging', 'loadingStatus.disconnectingFromBanner', 'loadingStatus.disconnectingFromControls'),
    checkParallelConferenceStatus: function checkParallelConferenceStatus(currentStatus, callType, conferenceStatus) {
      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel && conferenceStatus === currentStatus;
    },
    checkPrimaryConferenceStatus: function checkPrimaryConferenceStatus(currentStatus, callType, conferenceStatus) {
      return callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && conferenceStatus === currentStatus;
    },
    bindParallelCallEvents: function bindParallelCallEvents() {
      FC.Event.subscribe('customer_call_ended', this.endPrimaryCall.bind(this));
      FC.Event.subscribe('parallel_call_ended', this.endParallelCall.bind(this));
      FC.Event.subscribe('parallel_call_initiated', this.updateCall.bind(this));
      FC.Event.subscribe('parallel_call_resumed', this.parallelCallResumedCallback.bind(this));
      FC.Event.subscribe('parallel_call_unavailable', this.updateCall.bind(this));
      FC.Event.subscribe('parallel_call_unavailable_resume', this.resumeCompletedCallBack.bind(this));
      FC.Event.subscribe('parallel_call_merged', this.mergeCallback.bind(this));
      FC.Event.subscribe('parallel_call_merge_failed', this.mergeFailedCallback.bind(this));
      FC.Event.subscribe('parallel_call_resume_failed', this.resumeFailedCallback.bind(this));
      FC.Event.subscribe('primary_conference_removed', this.updateCall.bind(this));
      FC.Event.subscribe('primary_call_removed', this.updateCall.bind(this));
    },
    mergeFailedCallback: function mergeFailedCallback() {
      var _this = this;

      run(function () {
        _this.set('loadingStatus.merging', false);
        _this.set('callHasMerged', false);
        _this.get('notification').error(_this.get('i18n').t('parallel_call.merge_call_failed'));
      });
    },
    getCallProperty: function getCallProperty(callId, key) {
      var callInstance = this.isCurrentCall(callId) ? this.get('currentCall') : this.get('parkedCallsService').getParkedCall(callId);

      return callInstance ? callInstance.get(key) : '';
    },
    endParallelCall: function endParallelCall(callId) {
      var _this2 = this;

      var isCurrentCall = this.isCurrentCall(callId);

      var parallelCallsList = undefined;

      run(function () {
        if (_this2.getCallProperty(callId, 'isCompleted')) {
          return;
        }

        if (isCurrentCall) {
          parallelCallsList = _this2.get('parallelCalls');
        } else {
          parallelCallsList = _this2.get('parkedCallsService').getParallelCall(callId);
        }

        var _parallelCallsList = parallelCallsList;

        var _parallelCallsList2 = _slicedToArray(_parallelCallsList, 1);

        var parallelCall = _parallelCallsList2[0];
        var state = parallelCall && parallelCall.get('state');
        var isMerged = _this2.getCallProperty(callId, 'isMerged');
        var targetDisconnected = !isCurrentCall && parallelCall.get('target');

        if (!state || state === _freshfoneEmberConstantsCallState['default'].Ended || state === _freshfoneEmberConstantsCallState['default'].Removed || state === _freshfoneEmberConstantsCallState['default'].CloseAndResume) {
          return;
        }

        targetDisconnected && _this2.addDisconnectMessage(targetDisconnected.name);

        if (isMerged) {
          parallelCall.set('state', _freshfoneEmberConstantsCallState['default'].Removed);
          if (isCurrentCall) {
            _this2.updateCallProperties(isCurrentCall);
            _this2.removeParallelCall();
          } else {
            _this2.removeParkedParallelCall(callId);
            _this2.updateParkedCallsState();
          }
        } else {
          var callType = _this2.getCallProperty(callId, 'callType');

          if (callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel) {
            _this2.removeParallelCallWhenHold(parallelCall, isCurrentCall, callId);
          } else {
            _this2.removeParalleCallWhenActive(parallelCall, callId);
          }
        }
      });
    },
    addDisconnectMessage: function addDisconnectMessage(target) {
      this.get('notification').info(this.get('i18n').t('call_park.disconnected', { name: target }));
    },
    removeParkedParallelCall: function removeParkedParallelCall(callId) {
      var parallelCallsList = this.get('parkedCallsService').getParallelCall(callId);

      run(function () {
        parallelCallsList.popObject();
      });
    },
    updateCallProperties: function updateCallProperties(isCurrentCall) {
      if (isCurrentCall) {
        this.get('telephony').updateCurrentCall(this.get('telephony').getCurrentCall());

        return;
      }

      this.updateParkedCallsState();
    },
    removeParalleCallWhenActive: function removeParalleCallWhenActive(parallelCall, callId) {
      var _this3 = this;

      run(function () {
        if (_this3._checkIfParallelConferenceHasEnded(callId)) {
          parallelCall.set('state', _freshfoneEmberConstantsCallState['default'].CloseAndResume);
        } else {
          _this3.resetParallelCallInstance(parallelCall);
        }
      });
    },
    resumeFailedCallback: function resumeFailedCallback() {
      var _this4 = this;

      run(function () {
        _this4.set('loadingStatus.resuming', false);
        _this4.get('notification').error(_this4.get('i18n').t('parallel_call.resume_call_failed'));
      });
    },
    removeParallelCall: function removeParallelCall() {
      var _this5 = this;

      run(function () {
        _this5.get('parallelCalls').popObject();
      });
    },
    removeParallelCallWhenHold: function removeParallelCallWhenHold(parallelCall, isCurrentCall, callId) {
      var _this6 = this;

      run(function () {
        if (_this6._checkIfParallelConferenceHasEnded(callId)) {

          if (isCurrentCall) {
            parallelCall.set('state', _freshfoneEmberConstantsCallState['default'].Ended);
            setTimeout(function () {
              parallelCall.set('state', _freshfoneEmberConstantsCallState['default'].Removed);
              _this6.removeParallelCall();
            }, 10000);
          } else {
            parallelCall.set('state', _freshfoneEmberConstantsCallState['default'].Removed);
            _this6.removeParkedParallelCall(callId);
            _this6.updateParkedCallsState();
          }
        } else {
          _this6.resetParallelCallInstance(parallelCall);
        }
      });
    },
    _checkIfParallelConferenceHasEnded: function _checkIfParallelConferenceHasEnded(callId) {
      var parallelCallsList = undefined;

      if (this.getCallProperty(callId, 'isCompleted')) {
        return true;
      }

      if (this.isCurrentCall(callId)) {
        parallelCallsList = this.get('parallelCalls');
      } else {
        parallelCallsList = this.get('parkedCallsService').getParallelCall(callId);
      }

      var _parallelCallsList3 = parallelCallsList;

      var _parallelCallsList32 = _slicedToArray(_parallelCallsList3, 1);

      var parallelCall = _parallelCallsList32[0];
      var conferenceState = parallelCall && parallelCall.get('conferenceState');

      return !conferenceState || conferenceState === _freshfoneEmberConstantsCallState['default'].Ended || conferenceState === _freshfoneEmberConstantsCallState['default'].Removed || conferenceState === _freshfoneEmberConstantsCallState['default'].CloseAndResume;
    },
    resetParallelCallInstance: function resetParallelCallInstance(parallelCall) {
      run(function () {
        parallelCall.set('state', _freshfoneEmberConstantsCallState['default'].Removed);
        parallelCall.set('target', undefined);
        parallelCall.set('transferType', undefined);
      });
    },
    initializeParallelCall: function initializeParallelCall(groupIds) {
      var existingGroupIds = this.get('parallelGroupIds'),
          newGroupId = groupIds.find(function (id) {
        return !existingGroupIds.includes(id);
      });

      this.set('parallelGroupIds', groupIds);
      this.set('callHasMerged', false);
      this.get('parallelCalls').pushObject(Object.create({
        id: newGroupId,
        transferType: this.get('currentCall.transferType'),
        target: this.get('currentCall.transferTarget'),
        state: _freshfoneEmberConstantsCallState['default'].Active,
        conferenceState: undefined,
        conferenceTarget: undefined,
        conferenceType: undefined,
        parkState: undefined,
        timer: {
          ongoing: 0,
          hold: 0
        }
      }));
    },

    getCustomerDisplayName: function getCustomerDisplayName(callId) {
      var callInstance = this.isCurrentCall(callId) ? this.get('currentCall') : this.get('parkedCallsService').getParkedCall(callId),
          _model = callInstance.get('contact');

      if (_model && _model.get) {
        if (_model.get('name')) {
          return _model.get('name');
        }
        if (_model.get('isNew')) {
          return this.get('i18n').t('contact.new_contact');
        }
      }

      return callInstance.get('callerName');
    },

    endPrimaryCall: function endPrimaryCall(callId) {
      if (this.isCurrentCall(callId)) {
        this.endCurrentPrimaryCall();

        return;
      }

      var targetDisconnect = this.getCustomerDisplayName(callId);

      targetDisconnect && this.addDisconnectMessage(targetDisconnect);
      this.updateParkedCallsState();
    },
    updateParkedCallsState: function updateParkedCallsState() {
      var parkedCallsMap = this.get('telephony').getParkedCalls(),
          parkedCallIds = parkedCallsMap.size && Array.from(parkedCallsMap.keys());

      if (!parkedCallIds) {
        return;
      }

      this.get('parkedCallsService').updateParkedCall(parkedCallIds, parkedCallsMap);
    },
    endCurrentPrimaryCall: function endCurrentPrimaryCall() {
      var _this7 = this;

      run(function () {
        if (_this7.get('currentCall.isCompleted')) {
          return;
        }
        _this7.get('telephony').updateCurrentCall(_this7.get('telephony').getCurrentCall());
      });
    },
    isCurrentCall: function isCurrentCall(callId) {
      return !callId || parseInt(this.get('currentCall.id')) === parseInt(callId);
    },
    updateCall: function updateCall(eventData) {
      var _this8 = this;

      run(function () {
        return _this8.updateCallProperties(_this8.isCurrentCall(eventData.call_id));
      });
    },
    parallelCallResumedCallback: function parallelCallResumedCallback() {
      var _this9 = this;

      var callType = this.get('currentCall.callType');

      var _get5 = this.get('parallelCalls');

      var _get52 = _slicedToArray(_get5, 1);

      var parallelCall = _get52[0];

      run(function () {
        var currentParallelCallState = parallelCall.get('state'),
            parallelCallConferenceState = parallelCall.get('conferenceState'),
            nextParallelCallState = callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? _freshfoneEmberConstantsCallState['default'].Hold : _freshfoneEmberConstantsCallState['default'].Active;

        _this9.set('loadingStatus.resuming', false);

        if (currentParallelCallState === _freshfoneEmberConstantsCallState['default'].CloseAndResume || parallelCallConferenceState === _freshfoneEmberConstantsCallState['default'].CloseAndResume) {
          _this9.removeParallelCall();
        } else {
          if (parallelCall.get('state') && parallelCall.get('state') !== _freshfoneEmberConstantsCallState['default'].Removed) {
            parallelCall.set('state', nextParallelCallState);
          }
          if (parallelCall.get('conferenceState') && parallelCall.get('conferenceState') !== _freshfoneEmberConstantsCallState['default'].Removed) {
            parallelCall.set('conferenceState', nextParallelCallState);
          }
        }
        _this9.get('telephony').updateCurrentCall(_this9.get('telephony').getCurrentCall());
      });
    },
    resumeCompletedCallBack: function resumeCompletedCallBack() {
      var _this10 = this;

      run(function () {
        _this10.set('loadingStatus.resuming', false);
        _this10.get('telephony').updateCurrentCall(_this10.get('telephony').getCurrentCall());
      });
    },
    mergeCallback: function mergeCallback(groupIds) {
      var _this11 = this;

      run(function () {
        var _get6 = _this11.get('parallelCalls');

        var _get62 = _slicedToArray(_get6, 1);

        var parallelCall = _get62[0];

        parallelCall.set('id', _this11.get('currentCall.groupId'));

        _this11.set('parallelGroupIds', groupIds);
        _this11.set('callHasMerged', true);
        _this11.set('loadingStatus.merging', false);
        _this11.get('telephony').updateCurrentCall(_this11.get('telephony').getCurrentCall());
      });
    },
    clearParallelCalls: function clearParallelCalls() {
      this.set('parallelGroupIds', []);
      this.set('parallelCalls', []);
    },
    endCall: function endCall(disconnectType, source) {
      var _this12 = this;

      run(function () {
        if (_this12.get('currentCall.isMerged')) {
          _this12.get('telephony').updateCallCompleted();

          return;
        }

        if (disconnectType === _freshfoneEmberConstantsActiveCallType['default'].Parallel) {
          if (_this12._checkIfCallAndConferenceEnded(_this12.get('currentCall.callState'), _this12.get('currentCall.conferenceState'))) {
            _this12.get('telephony').updateCallCompleted();

            return;
          }

          if (source === _freshfoneEmberConstantsDisconnectSourceType['default'].Controls) {
            _this12.setParallelCallAndConferenceState(_freshfoneEmberConstantsCallState['default'].CloseAndResume);
          } else {
            _this12.setParallelCallAndConferenceState(_freshfoneEmberConstantsCallState['default'].Ended);
            later(function () {
              _this12.removeParallelCall();
            }, testing ? 0 : 10000);
          }
        } else {
          _this12.endPrimaryCallAndConference();
        }
      });
    },
    initializeConference: function initializeConference(type, target) {
      var _this13 = this;

      var callType = this.get('currentCall.callType');

      run(function () {
        if (callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel) {
          var _get7 = _this13.get('parallelCalls');

          var _get72 = _slicedToArray(_get7, 1);

          var parallelCall = _get72[0];

          parallelCall.set('conferenceState', _freshfoneEmberConstantsCallState['default'].Initiated);
          parallelCall.set('conferenceType', type);

          if (target) {
            parallelCall.set('conferenceTarget', target);
          }
        } else {
          _this13.get('telephony').updateCurrentCall(_this13.get('telephony').getCurrentCall());
        }
      });
    },
    updateParkingStatus: function updateParkingStatus(isParking) {
      this.set('loadingStatus.parking', isParking);
    },
    endPrimaryCallAndConference: function endPrimaryCallAndConference() {
      var _this14 = this;

      run(function () {
        _this14.get('telephony').updateCurrentCall(_this14.get('telephony').getCurrentCall());

        var _get8 = _this14.get('parallelCalls');

        var _get82 = _slicedToArray(_get8, 1);

        var parallelCall = _get82[0];
        var callState = parallelCall && parallelCall.get('state');
        var conferenceState = parallelCall && parallelCall.get('conferenceState');

        if (_this14._checkIfCallAndConferenceEnded(callState, conferenceState)) {
          _this14.get('telephony').updateCallCompleted();
        }
      });
    },
    _checkIfCallAndConferenceEnded: function _checkIfCallAndConferenceEnded(callState, conferenceState) {
      var primaryCallEnded = !callState || this.checkState(callState),
          conferenceCallEnded = !conferenceState || this.checkState(conferenceState);

      return primaryCallEnded && conferenceCallEnded;
    },
    checkState: function checkState(state) {
      return state === _freshfoneEmberConstantsCallState['default'].Ended || state === _freshfoneEmberConstantsCallState['default'].Removed || state === _freshfoneEmberConstantsCallState['default'].CloseAndResume;
    },
    isParallelCallAndConferenceEnded: function isParallelCallAndConferenceEnded() {
      var _get9 = this.get('parallelCalls');

      var _get92 = _slicedToArray(_get9, 1);

      var parallelCall = _get92[0];
      var callState = parallelCall && parallelCall.get('state');
      var conferenceState = parallelCall && parallelCall.get('conferenceState');

      return this._checkIfCallAndConferenceEnded(callState, conferenceState);
    },
    hasPrimaryCallAndConferenceEnded: function hasPrimaryCallAndConferenceEnded() {
      var callState = this.get('currentCall.callState'),
          conferenceState = this.get('currentCall.conferenceState'),
          mainCallEnded = this.checkState(callState),
          conferenceCallEnded = !conferenceState || this.checkState(callState);

      return mainCallEnded && conferenceCallEnded;
    },
    setParallelCallAndConferenceState: function setParallelCallAndConferenceState(newState) {
      var _this15 = this;

      run(function () {
        var _get10 = _this15.get('parallelCalls');

        var _get102 = _slicedToArray(_get10, 1);

        var parallelCall = _get102[0];
        var state = parallelCall && parallelCall.get('state');
        var conferenceState = parallelCall && parallelCall.get('conferenceState');

        if (state && state !== _freshfoneEmberConstantsCallState['default'].Removed) {
          parallelCall.set('state', newState);
        }

        if (conferenceState && conferenceState !== _freshfoneEmberConstantsCallState['default'].Removed) {
          parallelCall.set('conferenceState', newState);
        }
      });
    },
    startConference: function startConference() {
      var _this16 = this;

      run(function () {
        var callType = _this16.get('currentCall.callType');

        if (callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel) {
          _this16.get('telephony').updateCurrentCall(_this16.get('telephony').getCurrentCall());
        } else {
          var _get11 = _this16.get('parallelCalls');

          var _get112 = _slicedToArray(_get11, 1);

          var parallelCall = _get112[0];

          parallelCall.set('conferenceState', _freshfoneEmberConstantsCallState['default'].Active);
        }
      });
    },
    updateConference: function updateConference(status) {
      var _this17 = this;

      var callType = this.get('currentCall.callType');

      run(function () {
        if (callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel) {
          _this17.get('telephony').updateCurrentCall(_this17.get('telephony').getCurrentCall());
        } else {
          (function () {
            var _get12 = _this17.get('parallelCalls');

            var _get122 = _slicedToArray(_get12, 1);

            var parallelCall = _get122[0];

            parallelCall.set('conferenceState', status);
            later(function () {
              _this17.resetParallelConferenceInstance(parallelCall);
            }, testing ? 0 : 5000);
          })();
        }
      });
    },
    resetParallelConferenceInstance: function resetParallelConferenceInstance(parallelCall) {
      run(function () {
        parallelCall.set('conferenceState', _freshfoneEmberConstantsCallState['default'].Removed);
        parallelCall.set('conferenceTarget', undefined);
        parallelCall.set('conferenceType', undefined);
      });
    },
    endConference: function endConference(endPrimaryConference, callId) {
      var _this18 = this;

      var isCurrentCall = this.isCurrentCall(callId);

      var targetDisconnected = !isCurrentCall && this.getCallProperty(callId, 'conferenceTarget');

      run(function () {
        if (endPrimaryConference) {
          targetDisconnected && _this18.addDisconnectMessage(targetDisconnected.name);
          _this18.updateCallProperties(isCurrentCall);
        } else {
          if (_this18._checkIfParallelConferenceHasEnded(callId)) {
            return;
          }

          var callType = _this18.getCallProperty(callId, 'callType');

          var parallelCallsList = undefined;

          if (isCurrentCall) {
            parallelCallsList = _this18.get('parallelCalls');
          } else {
            parallelCallsList = _this18.get('parkedCallsService').getParallelCall(callId);
          }

          var _parallelCallsList4 = parallelCallsList;

          var _parallelCallsList42 = _slicedToArray(_parallelCallsList4, 1);

          var parallelCall = _parallelCallsList42[0];

          targetDisconnected = !isCurrentCall && parallelCall.get('conferenceTarget');

          targetDisconnected && _this18.addDisconnectMessage(targetDisconnected.name);

          if (callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel) {
            _this18.removeParallelConferenceWhenActive(parallelCall);
          } else {
            _this18.removeParallelConferenceWhenHold(parallelCall);
          }
        }
      });
    },
    _checkIfParallelCallIsActive: function _checkIfParallelCallIsActive(parallelCall) {
      var state = parallelCall && parallelCall.get('state');

      if (state && state !== _freshfoneEmberConstantsCallState['default'].Ended && state !== _freshfoneEmberConstantsCallState['default'].Removed) {
        return true;
      }

      return false;
    },
    removeParallelConferenceWhenActive: function removeParallelConferenceWhenActive(parallelCall) {
      var _this19 = this;

      run(function () {
        if (_this19._checkIfParallelCallIsActive(parallelCall)) {
          parallelCall.set('conferenceState', _freshfoneEmberConstantsCallState['default'].Ended);
          later(function () {
            _this19.resetParallelConferenceInstance(parallelCall);
          }, testing ? 0 : 5000);
        } else {
          parallelCall.set('conferenceState', _freshfoneEmberConstantsCallState['default'].CloseAndResume);
        }
      });
    },
    removeParallelConferenceWhenHold: function removeParallelConferenceWhenHold(parallelCall) {
      var _this20 = this;

      run(function () {
        if (_this20._checkIfParallelCallIsActive(parallelCall)) {
          _this20.resetParallelConferenceInstance(parallelCall);
        } else {
          parallelCall.set('conferenceState', _freshfoneEmberConstantsCallState['default'].Ended);
          later(function () {
            _this20.removeParallelCall();
          }, testing ? 0 : 10000);
        }
      });
    },
    removeConference: function removeConference() {
      var _this21 = this;

      run(function () {
        var callType = _this21.get('currentCall.callType');

        if (callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel) {
          _this21.get('telephony').updateCurrentCall(_this21.get('telephony').getCurrentCall());
        } else {
          var _get13 = _this21.get('parallelCalls');

          var _get132 = _slicedToArray(_get13, 1);

          var parallelCall = _get132[0];

          _this21.resetParallelConferenceInstance(parallelCall);
        }
      });
    },
    holdCall: function holdCall(action) {
      var _this22 = this;

      run(function () {
        var callType = _this22.get('currentCall.callType'),
            state = action === _freshfoneEmberConstantsCallState['default'].Hold ? _freshfoneEmberConstantsCallState['default'].Hold : _freshfoneEmberConstantsCallState['default'].Active;

        if (callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel) {
          _this22.get('telephony').updateCurrentCall(_this22.get('telephony').getCurrentCall());
        } else {
          _this22.setParallelCallAndConferenceState(state);
        }
      });
    },

    parkParallelCall: function parkParallelCall() {
      var _this23 = this;

      run(function () {
        var _get14 = _this23.get('parallelCalls');

        var _get142 = _slicedToArray(_get14, 1);

        var parallelCall = _get142[0];

        parallelCall && parallelCall.set('parkState', _freshfoneEmberConstantsParkState['default'].parked);
      });
    },

    unparkParallelCall: function unparkParallelCall() {
      var _this24 = this;

      run(function () {
        var _get15 = _this24.get('parallelCalls');

        var _get152 = _slicedToArray(_get15, 1);

        var parallelCall = _get152[0];

        parallelCall && parallelCall.set('parkState', undefined);
      });
    },

    switchParallelCall: function switchParallelCall(parkedCallId, toBeUnparkedCallId) {
      var _this25 = this;

      run(function () {
        var _get16 = _this25.get('parallelCalls');

        var _get162 = _slicedToArray(_get16, 1);

        var currentParallelCalls = _get162[0];
        var toBeUpdatedParallelCalls = _this25.get('parkedCallsService').getParallelCall(toBeUnparkedCallId);

        currentParallelCalls && _this25.get('parkedCallsService').setParallelCall(parkedCallId, _this25.get('parallelCalls'));

        if (toBeUpdatedParallelCalls && toBeUpdatedParallelCalls.length) {
          var _toBeUpdatedParallelCalls = _slicedToArray(toBeUpdatedParallelCalls, 1);

          var parallelCall = _toBeUpdatedParallelCalls[0];

          parallelCall.set('parkState', 'parked');
          _this25.set('parallelCalls', toBeUpdatedParallelCalls);
          _this25.get('parkedCallsService').deleteParallelCall(toBeUnparkedCallId);
        } else {
          _this25.set('parallelCalls', []);
        }
      });
    }

  });
});