define('freshfone-ember/components/contact-display-card/contact-card-row/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    phoneNumbers: computed(function () {
      return _ember['default'].isArray(this.get('displayValue'));
    }),

    actions: {
      mergeContact: function mergeContact() {
        this.sendAction('mergeContact');
      }
    }
  });
});