define('freshfone-ember/components/notification-widget/incoming/component', ['exports', 'freshfone-ember/constants/call-category', 'freshfone-ember/constants/call-type', 'ember', 'freshfone-ember/constants/park-type', 'freshfone-ember/utils/random-number'], function (exports, _freshfoneEmberConstantsCallCategory, _freshfoneEmberConstantsCallType, _ember, _freshfoneEmberConstantsParkType, _freshfoneEmberUtilsRandomNumber) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var Component = _ember['default'].Component;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var or = _Ember$computed.or;
  var not = _Ember$computed.not;
  var testing = _ember['default'].testing;

  var AUTO_ANSWER_DELAY_RANGE_MIN = 1;
  var AUTO_ANSWER_DELAY_RANGE_MAX = 10;exports['default'] = Component.extend({
    mkpAppsFilter: { location: 'notification_card' },
    classNames: ['incoming-widget'],
    notifications: service('incomingNotifications'),
    transition: service('state-transition'),
    parkedCallsService: service('parked-calls'),
    telephony: service(),
    browserService: service('browser-service'),
    parallelCall: service(),
    contact: computed.alias('connection.contact'),
    caller: computed.alias('connection.caller'),
    isUnknownCaller: computed('contact.name', 'caller.from', function () {
      return this.get('contact.name') === this.get('caller.from');
    }),
    callerName: computed.or('contact.name', 'caller.from'),
    companyName: computed.alias('contact.company.name'),
    ivrCall: computed.alias('connection.ivrCall'),
    callRingingOn: computed.reads('connection.to_number_display_name'),
    isCallbackCall: computed.alias('connection.callback_call'),
    isCallIncomingCallbackcall: computed('connection.callback_call', 'connection.type', function () {
      return this.get('connection.callback_call') && this.get('connection.type') !== _freshfoneEmberConstantsCallType['default'].Incoming;
    }),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    hideMainContactInfoCard: computed.alias('telephony.mainCallsRemoved'),
    conferenceCallerInWidget: alias('telephony.conferenceCallerInWidget'),
    currentCall: computed.alias('telephony.currentCall'),
    primaryCaller: computed('isParallelCallResumed', 'customerContact', 'secondaryContact', function () {
      return this.get('isParallelCallResumed') ? this.get('secondaryContact.displayName') : this.get('customerContact');
    }),
    autoAnswerRemainingSeconds: computed('autoAnswerAt', function () {
      return Math.round((this.get('autoAnswerAt') - Date.now()) / 1000);
    }),
    autoAnswerEnabled: computed.alias('connection.autoAnswerEnabled'),
    hasAutoAnswer: computed.and('autoAnswerEnabled', 'hasAutoAnswerFeatureEnabled'),
    isParallelCallResumed: alias('telephony.isParallelCallResumed'),
    customerContact: alias('telephony.customerContact'),
    secondaryContact: alias('parallelCall.parallelContact'),
    isAgentConference: computed.equal('connection.type', 'agent_conference'),
    autoAnswerAt: computed.alias('connection.autoAnswerAt'),
    isAutoAnswerAt0: computed('autoAnswerAt', function () {
      return this.get('autoAnswerAt') <= Date.now();
    }),
    showNotification: computed('hasAutoAnswer', 'isAutoAnswerAt0', function () {
      return this.get('hasAutoAnswer') ? !this.get('isAutoAnswerAt0') : true;
    }),
    closeWidgetText: computed('disableAcceptAction', function () {
      if (this.get('disableAcceptAction') && !this.get('isListNotification')) {
        return _ember['default'].i18n.t('incoming_notification.close_inconversation_tooltip');
      }
    }),
    isParallelCall: computed('connection', function () {
      return this.get('connection.type') === _freshfoneEmberConstantsCallType['default'].ParallelCall;
    }),
    isAPICall: computed('connection', function () {
      return this.get('connection.category') === _freshfoneEmberConstantsCallCategory['default'].ApiCall;
    }),
    agentName: computed('connection.from', function () {
      var contact = this.get('connection.from');

      return contact ? contact.name : '';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('hasAutoAnswer')) {
        this.autoAnswerCallTimer();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.clearAutoAnswerTimer();
    },
    currentCallIsParked: alias('telephony.currentCallIsParked'),
    hasParkingEnabled: alias('telephony.hasParkingEnabled'),
    currentCallCompleted: alias('telephony.currentCallCompleted'),
    currentCallIsParkedOrEnded: or('currentCallIsParked', 'currentCallCompleted'),
    currentCallIsNotParkedOrEnded: not('currentCallIsParkedOrEnded'),
    disableAcceptAction: computed('hasParkingEnabled', 'hasActiveCall', 'currentCallIsNotParkedOrEnded', function () {
      return !this.get('hasParkingEnabled') && this.get('hasActiveCall');
    }),
    parking: computed.alias('parkedCallsService.parking'),
    hasAutoAnswerFeatureEnabled: computed(function () {
      return this.get('session.account').hasGlobalFlag('auto_answer') && this.get('session.account').hasFeature('auto_answer');
    }),
    recordingInProgress: computed.alias('telephony.recordingInProgress'),
    actionInProgress: or('telephony.disableAction', 'parking', 'recordingInProgress'),
    micPermissionNotGranted: computed.or('browserService.isMicrophonePermissionDenied', 'browserService.isMicrophonePermissionPrompted'),
    showAcceptButton: computed('telephony.{enableIncomingCall,isTransferIncomingCall,isMonitorOrBargeCall}', 'actionInProgress', 'isAgentInPowerDialerMode', 'micPermissionNotGranted', function () {
      return this.get('telephony.enableIncomingCall') && !this.get('actionInProgress') && !this.get('telephony.isTransferIncomingCall') && !this.get('telephony.isMonitorOrBargeCall') && !this.get('isAgentInPowerDialerMode') && !this.get('micPermissionNotGranted');
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('mkpData.currentCall', this.get('connection'));
      this.createDesktopNotification({
        name: this.get('callerName'),
        number: this.get('connection.caller.from')
      });

      // TODO: Remove this request once the backend APIs are ready; the app directly should make this request
      // eslint-disable-next-line camelcase
      $.getJSON('/contacts/call_contact', { call_id: this.get('connection.id') }).then(function (contact) {
        run(function () {
          _this.store.pushPayload(contact);
          _this.set('mkpData.currentCall.associatedContact', _this.get('store').peekRecord('contact', contact.data.id));
          if (!_this.get('connection.contact.id')) {
            _this.set('mkpData.currentCall.contact', _this.get('store').peekRecord('contact', contact.data.id));
          }
        });
      });
    },
    createDesktopNotification: function createDesktopNotification(params) {
      try {
        if (Notification.permission === 'granted' && params) {
          var title = _ember['default'].i18n.t('incoming_notification.title'),
              content = {
            body: params.name + '|' + params.number,
            icon: 'assets/images/logo.png'
          },
              notification = new Notification(title, content);

          notification.onclick = function () {
            window.focus();
            this.close();
          };
        }
      } catch (err) {
        throw 'Notifications are not supported ' + err;
      }
    },

    clearAutoAnswerTimer: function clearAutoAnswerTimer() {
      if (this.get('hasAutoAnswer')) {
        console.log('Clearing out auto answer timeout');
        clearInterval(this.get('autoAnswerTimeout'));
      }
    },

    autoAnswerCallTimer: function autoAnswerCallTimer() {
      var _this2 = this;

      var callAutoAnswerAt = this.get('autoAnswerAt'),

      // eslint-disable-next-line consistent-this
      self = this,
          autoAnswerInterval = setInterval(function () {
        run(function () {
          var remainingSeconds = Math.round((callAutoAnswerAt - Date.now()) / 1000);

          _this2.set('autoAnswerRemainingSeconds', remainingSeconds);

          if (Date.now() >= _this2.get('autoAnswerAt')) {
            if (document.hidden) {
              var randomNumber = (0, _freshfoneEmberUtilsRandomNumber.getRandomNumber)(AUTO_ANSWER_DELAY_RANGE_MIN, AUTO_ANSWER_DELAY_RANGE_MAX);

              setTimeout(function () {
                run(function () {
                  self.sendAction('accept', self.get('connection'), true);
                  self.clearAutoAnswerTimer();
                });
              }, testing ? 0 : randomNumber * 100);
            } else {
              _this2.sendAction('accept', _this2.get('connection'), true);
              _this2.clearAutoAnswerTimer();
            }
          }
        });
      }, testing ? 10 : 1000);

      this.set('autoAnswerTimeout', autoAnswerInterval);
    },

    isTransferCall: computed('connection', 'connection.type', function () {
      var callType = this.get('connection').type;

      return callType === _freshfoneEmberConstantsCallType['default'].Transfer || callType === _freshfoneEmberConstantsCallType['default'].WarmTransfer;
    }),

    transferAgent: computed('connection.from', function () {
      // todo: user avatar
      return this.get('connection.from');
    }),
    initiateParkAndSwitch: function initiateParkAndSwitch(connection) {
      var callIsNotParkedOrEnded = this.get('currentCall.id') && !(this.get('currentCallIsParked') || this.get('currentCallCompleted')),
          currentCallContact = this.get('hideMainContactInfoCard') ? this.get('conferenceCallerInWidget.text') : this.get('primaryCaller'),
          connectingTo = this.get('isParallelCall') ? this.get('agentName') : this.get('callerName');

      this.set('parking', true);
      this.set('telephony.acceptedCallId', connection.id);

      if (callIsNotParkedOrEnded) {
        this.get('transition').start(this.get('i18n').t('call_park.connecting_and_parking', {
          connectingTo: connectingTo,
          parkingWith: currentCallContact
        }));

        this.get('telephony').setIncomingConnection(connection);

        return this.get('parkedCallsService').parkCall(_freshfoneEmberConstantsParkType['default'].IncomingCall, connection.id);
      }

      this.get('transition').start(this.get('i18n').t('call_park.connecting_to', { name: connectingTo }));
      this.get('telephony').saveTagsIfPresent();
      this.get('telephony').saveNotesIfPresent();
      this.sendAction('accept', connection);
    },

    actions: {
      ignore: function ignore(connection) {
        if (this.get('parking')) {
          return;
        }
        this.sendAction('ignore', connection);
      },

      accept: function accept(connection) {
        this.set('telephony.acceptedCallId', connection.id);
        if (this.get('hasParkingEnabled') && this.get('hasActiveCall')) {
          this.initiateParkAndSwitch(connection);

          return;
        }
        this.sendAction('accept', connection);
      }

    }

  });
});
/* eslint-disable no-console */