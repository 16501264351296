define('freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/component', ['exports', 'ember', 'freshfone-ember/utils/message-constants'], function (exports, _ember, _freshfoneEmberUtilsMessageConstants) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var next = _ember['default'].run.next;
  exports['default'] = Component.extend({
    eventNotSelected: computed.empty('optionAction'),
    classNames: ['list-row', 'list-content'],
    classNameBindings: ['hasThreshold'],

    resetActionObject: function resetActionObject() {
      this.setProperties({
        actionId: null,
        action: null
      });
      this.closeChild();
    },
    closeChild: function closeChild() {
      this.set('isChildOpen', false);
      this.set('isChildFormOpen', false);
    },

    optionAction: computed('actionType', {
      get: function get() {
        return this.get('model.actionOptions').findBy('name', this.get('actionType'));
      },
      set: function set(key, value) {
        this.set('actionType', value.name);
        this.resetActionObject();
        return value;
      }
    }),
    closeAllChildNodes: function closeAllChildNodes(elem) {
      //#todo, hack to be removed by changing the OutsideClickListener
      var ivrWrapper = elem.closest('.ivr-wrapper');
      ivrWrapper.children().find('.closeChild').first().trigger('click');
    },
    getMessageId: function getMessageId(defaultMessageId) {
      var messages = this.store.peekAll('message');
      return messages.filterBy('defaultMessageType', defaultMessageId)[0];
    },

    actions: {
      onChanged: function onChanged(changedValue) {
        var _this = this;

        this.set('optionAction', changedValue);
        if (changedValue.id >= 2) {
          (function () {
            var message = _this.getMessageId(_freshfoneEmberUtilsMessageConstants['default'][changedValue.name]);
            next(_this, function () {
              this.set('actionId', message.get('id'));
            });
          })();
        }
        this.closeAllChildNodes(this.$());
      }
    }
  });
});