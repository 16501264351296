define("freshfone-ember/components/search-bar/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 6
          }
        },
        "moduleName": "freshfone-ember/components/search-bar/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "icon-search");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "result");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["list-search search-bar ", ["subexpr", "if", [["get", "isActiveSearch", ["loc", [null, [1, 40], [1, 54]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [1, 35], [1, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input", [], ["type", "text", "class", "searchText", "placeholder", ["subexpr", "@mut", [["get", "placeholderText", ["loc", [null, [3, 52], [3, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "query", ["loc", [null, [3, 74], [3, 79]]], 0, 0, 0, 0]], [], [], 0, 0], "autofocus", "true", "insert-newline", ["subexpr", "@mut", [["get", "searchNotes", ["loc", [null, [3, 112], [3, 123]]], 0, 0, 0, 0]], [], [], 0, 0], "enter", "startSearch"], ["loc", [null, [3, 1], [3, 145]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});