define('freshfone-ember/components/custom-elements/file-input/component', ['exports', 'ember'], function (exports, _ember) {
  var Evented = _ember['default'].Evented;
  var testing = _ember['default'].testing;
  exports['default'] = _ember['default'].TextField.extend(Evented, {
    type: 'file',
    file: null,

    didInsertElement: function didInsertElement() {
      if (this.get('setChildReference')) {
        this.get('setChildReference')(this);
      }
    },
    change: function change(e) {
      var files = testing ? e.originalEvent.testingFiles : e.target.files;
      this.setFileAttr(files[0]);
    },
    setFileAttr: function setFileAttr(file) {
      this.set('fileName', file.name);
      if (file.size > this.get('fileSizeLimit')) {
        this.set('hasInvalidFile', true);
        this.set('errMsg', 'File size exceeds 5MB!');
      } else {
        this.set('fileSize', file.size);
        this.set('file', new Blob([file], { type: file.type }));
        this.createBlobUrl();
      }
    },
    createBlobUrl: function createBlobUrl() {
      var blobURL = URL.createObjectURL(this.get('file'));
      this.set('blobURL', blobURL);
      this.set('hasInvalidFile', false);
    }
  });
});