define('freshfone-ember/components/bootstrap-datepicker/component', ['exports', 'ember', 'ember-cli-bootstrap-datepicker/components/bootstrap-datepicker'], function (exports, _ember, _emberCliBootstrapDatepickerComponentsBootstrapDatepicker) {

  var isNone = _ember['default'].isNone,
      isEmpty = _ember['default'].isEmpty,
      run = _ember['default'].run;

  exports['default'] = _emberCliBootstrapDatepickerComponentsBootstrapDatepicker['default'].extend({
    todayHighlight: true,
    // By Default set empty string on Textfied focusout
    // so that we added the same functionality for consistency.
    // this will helps us to manage validations etc..
    setEmptyStringOnFocusOut: _ember['default'].on('didInsertElement', function () {
      var self = this;

      this.$().bind('focusout', function (event) {
        run(function () {
          var valueIsUndefined = isNone(self.get('value')),
              datepickerIsNotVisible = isEmpty(_ember['default'].$('.datepicker.datepicker-dropdown.dropdown-menu'));

          if (valueIsUndefined && datepickerIsNotVisible) {
            self._didChangeDate(event);
          }
        });
      });
    }),
    _didChangeDate: function _didChangeDate(event) {
      var value = event.date ? this.$().datepicker(this.get('multidate') ? 'getDates' : 'getDate') : '';

      this.set('value', value);
    }
  });
});