define("freshfone-ember/utils/duplicate-indices", ["exports"], function (exports) {
    exports["default"] = function (items) {
        var duplicateIndices = [];

        for (var i = 0; i < items.length; i++) {
            for (var j = i + 1; j < items.length; j++) {
                if (items[i] === items[j]) {
                    duplicateIndices.push(j);
                }
            }
        }

        return duplicateIndices;
    };
});