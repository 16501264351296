define('freshfone-ember/components/live-calls/slm-container/component', ['exports', 'freshfone-ember/config/environment', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin', 'freshfone-ember/mixins/event-bind-mixin', 'freshfone-ember/mixins/safe-set', 'freshfone-ember/helpers/seconds-to-mins-or-hrs'], function (exports, _freshfoneEmberConfigEnvironment, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin, _freshfoneEmberMixinsEventBindMixin, _freshfoneEmberMixinsSafeSet, _freshfoneEmberHelpersSecondsToMinsOrHrs) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  exports['default'] = Component.extend(_freshfoneEmberMixinsEventBindMixin['default'], _freshfoneEmberMixinsSafeSet['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    queueRollingInterval: _freshfoneEmberConfigEnvironment['default'].environment === "test" ? 1000 : 60000,
    telephony: service(),
    currentUser: computed.alias('session.user'),
    accountId: computed.alias('session.account.id'),
    metricsLoaded: true,
    hasMore: true,
    currentPage: 1,
    detailedMetrics: {},
    selectedQueueIds: [],
    canLoadCallerPagesInCrm: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm') && this.get('isCrmView') && this.get('isFullView');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('telephony.isLoaded') || this.get('telephony.isSdkLoadedFromCrm')) {
        this.subcribeEvents();
      } else {
        scheduleOnce('afterRender', this, 'bindNodeEvents', 350);
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      _ember['default'].run.later(this, function () {
        if (this.get('refreshSlmComponent')) {
          this.timerCallBack();
        }
        this.updateMetricData();
      }, 100);
    },

    updateMetricData: function updateMetricData() {
      if (!this.get('fullscreenMode') || !document.fullscreenElement) {
        if (this.get('selectedQueues.length')) {
          this.set('selectedQueueIds', this.get('selectedQueues').map(function (_ref) {
            var id = _ref.id;
            return Number(id);
          }));
        } else {
          this.set('selectedQueueIds', []);
        }
        this.fetchMetricsData();
      } else if (this.get('fullscreenMode')) {
        window.slideShow = setInterval(this.slideShow, this.get('queueRollingInterval'));
      }
    },

    timerCallBack: function timerCallBack() {
      if (this.get('queuesToRefresh.length')) {
        this.reloadActiveQueues(this.get('queuesToRefresh'));
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony.event').unsubscribe('status_update', this.statusUpdate.bind(this));
    },

    subcribeEvents: function subcribeEvents() {
      this.get('telephony.event').subscribe('status_update', this.statusUpdate.bind(this));
    },

    bindNodeEvents: function bindNodeEvents() {
      var event = !this.get('isCrmView') ? 'loaded' : 'sdkLoadedFromCrm',
          that = this;

      this.get('telephony').on(event, function () {
        that.subcribeEvents();
      });
    },

    statusUpdate: function statusUpdate(data) {
      var queuesToRefresh = this.get('queuesToRefresh.length') ? this.get('queuesToRefresh') : [];
      data = JSON.parse(data);
      var firstCallQueueId = data.first_call_queue_id ? data.first_call_queue_id : '';
      if (firstCallQueueId && queuesToRefresh.indexOf(firstCallQueueId) === -1) {
        queuesToRefresh.push(firstCallQueueId);
      }
      this.set('queuesToRefresh', this.fetchActiveQueues(queuesToRefresh));
    },

    fetchActiveQueues: function fetchActiveQueues(queuesToRefresh) {
      if (this.get('selectedQueueIds.length')) {
        return this.get('selectedQueueIds').filter(function (queueId) {
          return -1 !== queuesToRefresh.indexOf(queueId);
        });
      }
      return queuesToRefresh;
    },

    reloadActiveQueues: function reloadActiveQueues(queueList) {
      var _this = this;

      this.set('queuesToRefresh', []);
      var newQueueList = queueList;
      var params = this.buildSlmParams('', queueList);
      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this.store.query('serviceLevelMetric', params).then(function (response) {
          _this.handleReloadResponse(response, newQueueList);
          return resolve(response);
        });
      });
    },

    handleReloadResponse: function handleReloadResponse(response, newQueueList) {
      if (newQueueList.indexOf(Number(this.get('clickedId'))) !== -1) {
        var metricToLoad = this.store.peekRecord('serviceLevelMetric', this.get('clickedId'));
        this.loadDetailedMetrics(metricToLoad);
      }
      this.getSlmGlobalData();
    },

    fetchMetricsData: function fetchMetricsData() {
      this.getSlmMetricsData();
      this.getSlmGlobalData();
    },

    slideShow: function slideShow() {
      if (!document.fullscreenElement) {
        clearInterval(window.slideShow);
        return;
      }
      var firstSlide = document.querySelector('.multiple-queue-list .list-item-container');
      var activeRow = document.querySelector('.multiple-queue-list .active').parentElement;
      if (activeRow.nextElementSibling && activeRow.nextElementSibling.className.indexOf('list-item-container') !== -1) {
        activeRow.nextElementSibling.querySelector('.list-item').click();
        activeRow.nextElementSibling.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
      } else {
        firstSlide.querySelector('.list-item').click();
        firstSlide.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });
      }
    },

    getSlmGlobalData: function getSlmGlobalData() {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this2.store.query('serviceLevelMetric', {
          'filter[global]': true,
          'time_range': _this2.get('intervalOption.value')
        }).then(function (response) {
          _this2.set('slmGlobalMetrics', response);
          if (_this2.get('selectedQueueIds.length') && _this2.get('selectedQueueIds').indexOf(Number(_this2.get('clickedId'))) === -1) {
            _this2.set('clickedId', '');
          }
          if (!_this2.get('clickedId') || !_this2.get('hasMultipleQueues')) {
            if (!_this2.get('hasMultipleQueues')) {
              _this2.set('clickedId', response.content[0].id);
            }
            _this2.loadDetailedMetrics(_this2.store.peekRecord('serviceLevelMetric', response.content[0].id), true);
          }
          return resolve(response);
        });
      });
    },

    buildSlmParams: function buildSlmParams(pageToFetch, queuesToRefresh) {
      var params = {
        paginate: true,
        'time_range': this.get('intervalOption.value')
      };
      if (queuesToRefresh) {
        params['call_queue_ids'] = queuesToRefresh;
      } else if (this.get('selectedQueueIds.length')) {
        params['call_queue_ids'] = this.get('selectedQueueIds');
      }
      if (pageToFetch) {
        params['page'] = pageToFetch.page;
      }
      return params;
    },

    getSlmMetricsData: function getSlmMetricsData() {
      var _this3 = this;

      var params = this.buildSlmParams();
      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this3.store.query('serviceLevelMetric', params).then(function (response) {
          _this3.set('slmMetrics', response);
          _this3.setHasMoreToscroll(response.meta['total-pages'], response.meta['current']);
          return resolve(response);
        });
      });
    },

    setHasMoreToscroll: function setHasMoreToscroll(total, current) {
      this.setProperties({
        totalPages: total,
        currentPage: current,
        hasMore: true
      });
    },

    noMoreResultsToscroll: function noMoreResultsToscroll() {
      if (this.get('currentPage') + 1 > this.get('totalPages')) {
        this.setProperties({
          isFetching: false,
          hasMore: false
        });
        return true;
      }
    },

    getNextPageMetrics: function getNextPageMetrics() {
      var _this4 = this;

      var params = this.buildSlmParams({
        page: this.get('currentPage') + 1
      });
      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this4.store.query('serviceLevelMetric', params).then(function (response) {
          _this4.set('isFetching', false);
          _this4.get('slmMetrics').pushObjects(response.content);
          _this4.set('currentPage', response.meta.current);
          return resolve(response);
        });
      });
    },

    loadMoreQueues: function loadMoreQueues() {
      this.set('isFetching', true);
      if (this.noMoreResultsToscroll()) {
        return;
      }
      this.getNextPageMetrics();
    },

    buildSlmQueueLevelParams: function buildSlmQueueLevelParams(isGlobal, id) {
      var params = {
        'time_range': this.get('intervalOption.value')
      };
      if (isGlobal) {
        params['filter[global]'] = true;
        params['id'] = this.get('accountId');
      } else {
        params['id'] = id;
      }
      return params;
    },

    constructDetailedMetrics: function constructDetailedMetrics(metric, response, isGlobal) {
      var callQueueName = isGlobal ? _ember['default'].i18n.t('service_level_metrics.all_incoming_calls') : metric.get('callQueueName');
      var json = {
        'id': metric.get('id'),
        'metricPercentage': metric.get('metricPercentage'),
        'callQueueId': metric.get('callQueueId'),
        'relativeMetricPercentage': metric.get('relativeMetricPercentage'),
        'targetPercentage': metric.get('targetPercent'),
        queueName: callQueueName,
        threshold: metric.get('ttaThreshold')
      };
      var result = response.get('data');
      Object.keys(result).forEach(function (key) {
        if (key.indexOf('time') !== -1 || key.indexOf('Time') !== -1) {
          json[key] = _freshfoneEmberHelpersSecondsToMinsOrHrs['default'].compute(result[key]);
        }
      });
      this.set('detailedMetrics', json);
    },

    getDetailedMetrics: function getDetailedMetrics(metric, isGlobal) {
      var _this5 = this;

      var params = isGlobal ? this.buildSlmQueueLevelParams(isGlobal) : this.buildSlmQueueLevelParams(false, metric.get('id'));
      return new _ember['default'].RSVP.Promise(function () {
        _this5.store.queryRecord('serviceLevelMetric', params).then(function (response) {
          _this5.toggleProperty('metricsLoaded');
          _this5.constructDetailedMetrics(metric, response, isGlobal);
        });
      });
    },

    loadDetailedMetrics: function loadDetailedMetrics(metric, isGlobal) {
      var metricToLoad = metric.get('id') ? metric : this.get('slmGlobalMetrics');
      this.set('clickedId', metric.get('id'));
      this.toggleProperty('metricsLoaded');
      this.getDetailedMetrics(metricToLoad, isGlobal);
    },

    actions: {
      toggleFullscreen: function toggleFullscreen() {
        this.sendAction('toggleFullscreen');
      },
      loadLeftPane: function loadLeftPane(metric, isGlobal) {
        this.loadDetailedMetrics(metric, isGlobal);
      },
      fetchMore: function fetchMore(callback) {
        this.loadMoreQueues(callback);
      }
    }
  });
});