define('freshfone-ember/constants/call-queues', ['exports'], function (exports) {
  var recentCallQueues = Object.freeze({
    recentCallFlows: 'recently_used_callflows',
    typeNameIvr: 'Ivr',
    callQueue: 'call_queue',
    pollingTimeTest: 50,
    pollingTime: 120000,
    ivr: 'ivr'
  });

  exports.recentCallQueues = recentCallQueues;
  // eslint-disable-next-line one-var
  var AUTO_ANSWER = {
    off: 'off',
    useGlobalSettings: 'use_global_settings',
    userCustomSettings: 'use_custom_settings'
  };

  exports.AUTO_ANSWER = AUTO_ANSWER;
  // eslint-disable-next-line one-var
  var ROUTE_OPTIONS = {
    all: 'all',
    leastIdle: 'least_idle'
  };
  exports.ROUTE_OPTIONS = ROUTE_OPTIONS;
});