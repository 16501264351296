define('freshfone-ember/components/ongoing-widget/transfer-status/component', ['exports', 'freshfone-ember/constants/call-status', 'ember', 'freshfone-ember/constants/transfer-types'], function (exports, _freshfoneEmberConstantsCallStatus, _ember, _freshfoneEmberConstantsTransferTypes) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var later = _ember['default'].run.later;
  exports['default'] = Component.extend({
    isCancelling: false,
    isResuming: false,
    telephony: service(),
    parallelCall: service(),
    i18n: service(),
    currentCall: computed.alias('telephony.currentCall'),
    isTransferUnavailable: computed('currentCall.status', {
      get: function get() {
        var blindTransferUnavailable = this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].TransferUnavailable,
            warmTransferUnavailable = this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferUnavailable;

        if (blindTransferUnavailable) {
          this.set('isBlindTransferUnavailable', true);
        } else if (warmTransferUnavailable) {
          this.set('isWarmTransferUnavailable', true);
        }

        return blindTransferUnavailable || warmTransferUnavailable;
      }
    }),
    isTransferring: computed('currentCall.status', function () {
      return this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].Transferring || this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferring;
    }),
    isConnectingParallelCall: computed('currentCall.status', function () {
      return this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].ParallelCallInitiated;
    }),
    transferAgentName: computed.alias('currentCall.transferTarget.name'),
    transferType: computed.alias('currentCall.transferType'),
    isCsatIvrTransfer: computed.equal('transferType', _freshfoneEmberConstantsTransferTypes['default'].CsatIvr),
    isAgentConference: computed.equal('currentCall.transferType', _freshfoneEmberConstantsTransferTypes['default'].AgentConference),
    isTeamTransfer: computed.equal('transferType', _freshfoneEmberConstantsTransferTypes['default'].Team),
    isAgentTransfer: computed.equal('transferType', _freshfoneEmberConstantsTransferTypes['default'].Agent),
    isExternalNumberTransfer: computed.equal('transferType', _freshfoneEmberConstantsTransferTypes['default'].ExternalTransfer),
    targetAgentUnavailable: computed.and('isAgentTransfer', 'isTransferUnavailable'),
    targetTeamUnavailable: computed.and('isTeamTransfer', 'isTransferUnavailable'),
    targetExternalNumberUnavailable: computed.and('isExternalNumberTransfer', 'isTransferUnavailable'),
    showConferenceMessage: computed.or('isAgentConferenceStarted', 'isTargetAgentUnavailable', 'agentConferenceEnded'),
    conferenceStatusMessage: computed('isAgentConferenceStarted', 'isTargetAgentUnavailable', 'agentConferenceEnded', 'isCancelling', function () {
      if (this.get('isAgentConferenceStarted')) {
        return this.get('i18n').t('agent_conference.successfully_added');
      }
      if (this.get('isTargetAgentUnavailable')) {
        return this.get('i18n').t('agent_conference.not_available');
      }
      if (this.get('agentConferenceEnded')) {
        return this.get('i18n').t('agent_conference.exit');
      }
      if (this.get('isCancelling')) {
        return this.get('i18n').t('agent_conference.cancelling_conference');
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.bindNodeEvents();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.unBindNodeEvents();
    },

    bindNodeEvents: function bindNodeEvents() {
      var _this = this;

      this.get('telephony.event').subscribe('transfer_unavailable', function () {
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.resetTransferStates();
        _this.set('isBlindTransferUnavailable', true);
      });

      this.get('telephony.event').subscribe('warm_transfer_unavailable', function () {
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.resetTransferStates();
        _this.set('isWarmTransferUnavailable', true);
      });

      ['transfer_resume', 'warm_transfer_resume'].forEach(function (event) {
        _this.get('telephony.event').subscribe(event, function () {
          _this.resetTransferStates();
          _this.setProperties({
            isCancelling: false,
            isResuming: false
          });
          _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        });
      });

      this.get('telephony.event').subscribe('agent_conference_unavailable', function () {
        _this.set('isTargetAgentUnavailable', true);
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.resetAgentConference();
      });

      this.get('telephony.event').subscribe('agent_conference_accepted', function () {
        _this.setProperties({
          isTargetAgentUnavailable: false,
          isCancelling: false
        });
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.agentAdded();
      });

      this.get('telephony.event').subscribe('warm_transfer_accepted', function () {
        return _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
      });

      this.get('telephony.event').subscribe('parallel_call_accepted', function (groupIds) {
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
        _this.get('parallelCall').initializeParallelCall(groupIds);
      });

      this.get('telephony.event').subscribe('agent_conference_ended', function () {
        _this.showConferenceEndedAlert();
        _this.get('telephony').updateCurrentCall(_this.get('telephony').getCurrentCall());
      });
    },

    resetTransferStates: function resetTransferStates() {
      this.setProperties({
        isBlindTransferUnavailable: false,
        isWarmTransferUnavailable: false
      });
    },

    unBindNodeEvents: function unBindNodeEvents() {
      var _this2 = this;

      ['transfer_unavailable', 'warm_transfer_unavailable', 'transfer_resume', 'warm_transfer_resume', 'agent_conference_unavailable', 'agent_conference_accepted', 'agent_conference_ended', 'warm_transfer_accepted', 'parallel_call_accepted'].forEach(function (event) {
        return _this2.get('telephony.event').unsubscribe(event, function () {});
      });
    },

    agentAdded: function agentAdded() {
      var _this3 = this;

      this.set('currentCall.isAddingAgent', false);
      this.set('isAgentConferenceStarted', true);
      later(this, function () {
        _this3.set('isAgentConferenceStarted', false);
      }, 5000);
    },

    resetAgentConference: function resetAgentConference() {
      var _this4 = this;

      this.set('currentCall.isAddingAgent', false);
      later(this, function () {
        _this4.set('isTargetAgentUnavailable', false);
      }, 5000);
    },

    showConferenceEndedAlert: function showConferenceEndedAlert() {
      var _this5 = this;

      this.set('agentConferenceEnded', true);
      later(this, function () {
        _this5.set('agentConferenceEnded', false);
      }, 5000);
    },

    cancelTransfer: function cancelTransfer() {
      var _this6 = this;

      (this.get('currentCall.status') === _freshfoneEmberConstantsCallStatus['default'].Transferring ? this.get('telephony').getCurrentCall().cancelTransfer() : this.get('telephony').getCurrentCall().cancelWarmTransfer()).then(function () {
        return _this6.get('telephony').updateCurrentCall(_this6.get('telephony').getCurrentCall());
      })['catch'](function () {
        return _this6.toggleProperty('isCancelling');
      });
    },

    cancelAgentConference: function cancelAgentConference() {
      var _this7 = this;

      this.set('currentCall.isAddingAgent', false);
      this.get('telephony').getCurrentCall().cancelAgentConference().then(function () {
        _this7.get('telephony').updateCurrentCall(_this7.get('telephony').getCurrentCall());
        _this7.toggleProperty('isCancelling');
      })['catch'](function () {
        return _this7.toggleProperty('isCancelling');
      });
    },

    actions: {
      resumeCall: function resumeCall() {
        var _this8 = this;

        if (this.get('isResuming')) {
          return;
        }
        this.toggleProperty('isResuming');

        (this.get('isBlindTransferUnavailable') ? this.get('telephony').getCurrentCall().cancelTransfer() : this.get('telephony').getCurrentCall().cancelWarmTransfer()).then(function () {
          return _this8.get('telephony').updateCurrentCall(_this8.get('telephony').getCurrentCall());
        })['catch'](function () {
          return _this8.toggleProperty('isResuming');
        });
      },
      cancelCall: function cancelCall() {
        if (this.get('isCancelling')) {
          return;
        }
        this.toggleProperty('isCancelling');
        if (this.get('isAgentConference')) {
          return this.cancelAgentConference();
        }
        this.cancelTransfer();
      }
    }
  });
});