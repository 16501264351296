define('freshfone-ember/components/paginate-search/component', ['exports', 'ember', 'freshfone-ember/utils/search-util'], function (exports, _ember, _freshfoneEmberUtilsSearchUtil) {
  var later = _ember['default'].run.later;

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    searchstring: '',
    perPage: computed(function () {
      return this.get('visibilityCount') || 10;
    }),
    listLoading: false,
    records: computed.filterBy('model', 'isNew', false),
    hasEmptyModel: computed.equal('records.length', 0),
    entitiesExistInTheFirstPlace: computed('hasEmptyList', 'isSearching', function () {
      return !(this.get('hasEmptyList') && !this.get('isSearching'));
    }),
    hasNoPagination: computed.equal('model.meta.total-pages', 1),
    hasEmptyList: computed('hasEmptyModel', 'hasNoPagination', function () {
      return Object.keys(this.get('model.meta') || {}).length ? this.get('hasEmptyModel') && this.get('hasNoPagination') : this.get('hasEmptyModel');
    }),

    modifyModel: computed('modifyModel', {
      get: function get() {},
      set: function set(key, value) {
        if (value === true) {
          this._setFilterParams(this._getPage());
          this._loadModel();
          return value;
        }
      }
    }),

    _getPage: function _getPage() {
      var page = this.get('page') ? this.get('page') : 1;
      if (this.get('totalPages') > 1 && this._isLastPage() && this._isListEmpty()) {
        page = this.get('page') - 1;
      }
      return page;
    },

    _isLastPage: function _isLastPage() {
      return this.get('totalPages') == this.get('page');
    },

    _isListEmpty: function _isListEmpty() {
      return this.get('model').get('length') <= 0;
    },

    _setFilterParams: function _setFilterParams(page) {
      if (this.get('isPaginatefalse')) {
        this.set('filterParams', { paginate: false });
      } else {
        this.set('filterParams', { page: page });
      }
      if (this.get('value') && this.get('value').length > 0) {
        this.addFilterParams({ filter: { queryString: this.get('value') } });
      }

      this.get('version') && this.addFilterParams({ version: this.get('version') });

      if (this.get('filter')) {
        this.addFilterParams(this.get('filter'));
      }
    },

    addFilterParams: function addFilterParams(object) {
      if (this.get('filterParams')) {
        var filterParams = Object.assign(this.get('filterParams'), object);
        this.set('filterParams', filterParams);
      }
    },

    _loadComplete: function _loadComplete(model) {
      this.set('model', model);
      this.set('listLoading', false);
      this.set('modifyModel', false);
      if (this.get('modelCallback')) {
        this.get('modelCallback')(model);
      }
    },

    _loadModel: function _loadModel() {
      var _this = this;

      this.store.query(this.get('modelName'), this.get('filterParams')).then(function (model) {
        _this._loadComplete(model);
        _this.set('totalCount', model.meta['total-count']);
        _this.set('page', model.meta.current);
        _this.set('totalPages', model.meta['total-pages']);
      });
    },

    actions: {

      searchText: function searchText(textString) {
        this.set('listLoading', true);
        this.set('isSearching', textString);
        this.set('value', textString);
        this._setFilterParams(1);
        if (!this.get('value') && this.get('blockEmptyLoad')) {
          this._loadComplete({ content: [] });
        } else {
          this._loadModel();
        }
      },
      paginate: function paginate(page) {
        this.set('listLoading', true);
        this._setFilterParams(page);
        this._loadModel();
      },
      listChange: function listChange() {
        this.set('listLoading', false);
      },

      clearSearchField: function clearSearchField() {
        this.set('searchstring', '');
        this.set('isActiveSearch', false);
        this.send('searchText', '');
      }
    }
  });
});