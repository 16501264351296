define('freshfone-ember/mixins/call-flow-child-validation-mixin', ['exports', 'ember', 'freshfone-ember/constants/fallback-callflows'], function (exports, _ember, _freshfoneEmberConstantsFallbackCallflows) {
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    findOrFetch: function findOrFetch(modelName, recordId) {
      /** To consume findOrFetch mixin in the future after moking it as async function*/

      var callFlow = this.store.peekRecord(modelName, recordId);

      return callFlow ? Promise.resolve(callFlow) : this.store.findRecord(modelName, recordId);
    },
    saveChildActions: function saveChildActions(actionCases, routingAutomationId) {
      var _this = this;

      var promiseArray = [];

      actionCases.forEach(function (actionType) {
        var modelName = _this.get(actionType + 'Type');

        if (_freshfoneEmberConstantsFallbackCallflows['default'].includes(modelName)) {
          var action = _this.get(actionType),
              actionId = _this.get(actionType + 'Id'),
              childCallFlow = actionId ? _this.findOrFetch(modelName, actionId) : Promise.resolve(action);

          promiseArray.push(childCallFlow.then(function (childCallFlow) {
            return childCallFlow.get('isARoutingAutomation') && !childCallFlow.get('routingRules.length') ? Promise.resolve() : _this.validateAndSaveChildAction(childCallFlow, actionType, routingAutomationId);
          }));
        }
      });

      return Promise.all(promiseArray);
    },
    validateAndSaveChildAction: function validateAndSaveChildAction(childCallFlow, actionType, routingAutomationId) {
      var _this2 = this;

      /**
       * ivrKeyPressCallback is a special case for IVR alone sine IVR doesn't have a unfied validate method.
       *  This will be removed when IVR is unified in validation.
       */
      var ivrKeyPressCallback = function ivrKeyPressCallback() {
        return _this2.get(actionType + 'Type') === 'ivr' ? childCallFlow.validateAndSaveKeyPress() : Promise.resolve();
      };

      return new Promise(function (resolve, reject) {
        if (!childCallFlow) {
          reject();
        }
        /**
         * model.get('isDeepDirty') needs to be studied to see the difference in
         * behaviour in detecting dirty values for relationship data
         */

        ivrKeyPressCallback().then(function () {
          return childCallFlow.validate(routingAutomationId);
        }).then(function () {
          return childCallFlow.save();
        }).then(function (response) {
          return resolve(_this2.set(actionType + 'Id', response.get('id')));
        })['catch'](reject);
      });
    }
  });
});