define('freshfone-ember/components/ongoing-widget/monitoring-widget/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Component.extend({
    telephony: service(),
    session: service(),
    currentUser: computed.alias('session.user'),
    currentCall: computed.alias('telephony.currentCall'),

    monitorContact: computed('currentCall.contact', function () {
      return this.get('currentCall.contact');
    }),

    didRender: function didRender() {
      this._super.apply(this, arguments);
    },

    willDestroyElement: function willDestroyElement() {
      this.set("currentCall", {});
      this._super.apply(this, arguments);
    },

    actions: {
      leaveCall: function leaveCall() {
        if (this.get('telephony').getCurrentCall()) {
          this.get('telephony').hangup('ongoing-widget/monitoring-widget/component.js#actions.leaveCall');
          this.get('telephony').close();
        }
      },
      toggleMute: function toggleMute() {
        var _this = this;

        var params = { call_id: this.get('currentCall.call_id'),
          agent_id: this.get('currentUser.id')
        };
        if (this.get('currentCall.type') == 'monitor') {
          this.get('telephony').getCurrentCall().barge(params).then(function (data) {
            _this.set('currentCall.type', 'barge');
            _this.set('mute', false);
          })['catch'](function (reason) {
            console.log('Reason', reason);
          });
        } else {
          this.get('telephony').getCurrentCall().monitor(params).then(function (data) {
            _this.set('currentCall.type', 'monitor');
            _this.set('mute', true);
          })['catch'](function (reason) {
            console.log('Reason', reason);
          });
        }
      }
    }

  });
});