define('freshfone-ember/components/deflection-bot/deflection-bot-root/deflection-bot-case/deflection-branch/component', ['exports', 'ember', 'ember-validations', 'freshfone-ember/mixins/model-form-mixin', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/click-outside-listener'], function (exports, _ember, _emberValidations, _freshfoneEmberMixinsModelFormMixin, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsClickOutsideListener) {
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Component.extend(_emberValidations['default'], _ember['default'].Evented, _freshfoneEmberMixinsModelFormMixin['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsClickOutsideListener['default'], {
        init: function init() {
            this._super.apply(this, arguments);
        },

        isChildFormOpen: false,
        outSideClickOnInit: false,
        onOutsideClick: function onOutsideClick() {
            this.send('toggleCloseChild');
            this.unbindOutsideClick();
        },

        isOtherChildOpen: computed('isChildOpen', 'isChildFormOpen', function () {
            return this.get('isChildOpen') && !this.get('isThisChildOpen');
        }),

        isChildAFlow: computed.and('isChildFormOpen', 'isActionAFlow'),
        isChildPresent: computed.notEmpty('deflectionBotAction'),
        showError: computed.not('isChildPresent'),

        isIvrSelected: computed.equal('optionAction.name', 'ivr'),
        isCallQueueSelected: computed.equal('optionAction.name', 'callQueue'),

        isActionIvr: computed.equal('actionType', 'ivr'),
        isActionCallQueue: computed.equal('actionType', 'callQueue'),

        isActionAFlow: computed.or('isActionIvr', 'isActionCallQueue'),

        isMessageSelected: computed('optionAction', function () {
            var messages = ['hangup', 'vm', 'message'];
            return messages.includes(this.get('optionAction.name'));
        }),

        callFlows: computed('modelName', function () {
            return this.store.findAll(this.get('modelName'));
        }),

        callFlowList: computed.filter('callFlows', function (callflow) {
            if (!callflow.get("isNew")) {
                return callflow;
            }
        }),

        createNewEntityLabel: computed('modelName', function () {
            var key = ['call_ivr', 'labels', 'create', this.get('modelName').decamelize()].join('.');
            return _ember['default'].i18n.t(key);
        }),

        deflectionBotAction: computed('actionId', 'actionType', {
            get: function get() {
                var actionId = this.get("actionId");
                if (_ember['default'].isEmpty(actionId)) {
                    return this.handleEmptyAction();
                }
                this.store.queryRecord(this.get("modelName"), { 'id': actionId }).then(function (value) {})['catch'](function (reason) {
                    return;
                });
                return this.findOrFetch(this.get("modelName"), actionId);
            },
            set: function set(k, v) {
                return v;
            }
        }),

        ivrAncestorList: computed(function () {
            // required by ivr-form for tracking ancestry
            return [this.get('action')];
        }),

        setActionValue: function setActionValue(value) {
            if (!this.get('keyOption.isMessageNotSelected')) {
                return;
            }
            if (this.get('keyOption.isIvrSelected')) {
                return this.set('keyOption.actionIvr', value);
            } else if (this.get('keyOption.isCallQueueSelected')) {
                return this.set('keyOption.actionQueue', value);
            }
        },

        modelName: computed('actionType', function () {
            var messages = ['hangup', 'vm'],
                actionType = this.get('actionType');

            if (messages.includes(actionType)) {
                return 'message';
            }

            return actionType;
        }),

        handleEmptyAction: function handleEmptyAction() {
            if (!this.get('isActionAFlow')) {
                return;
            }
            if (this.get('isIvrSelected')) {
                return this.get('actionType.actionIvr');
            } else if (this.get('isActionCallQueue')) {
                return this.get('actionType.actionQueue');
            }
        },

        findOrFetch: function findOrFetch(modelName, recordId) {
            return this.store.peekRecord(modelName, recordId) || this.store.findRecord(modelName, recordId);
        },

        createNewMessage: function createNewMessage() {
            var defaultTitle = this.store.peekAll('message').content.length + 1,
                newObject = this.store.createRecord('message', { name: _ember['default'].i18n.t('message_components.record_component.message') + ' ' + defaultTitle });
            this.set('showMessage', true);
            this.set('messageHolder', newObject);
            this.set('messageFor', this.get('deflectionAction'));
        },

        createNewChildFlow: function createNewChildFlow() {
            var newEntity = this.store.createRecord(this.get('isIvrSelected') ? 'ivr' : 'call-queue');
            this.get('deflectionBot').set(this.get('deflectionAction'), newEntity);
            this.set('deflectionBotAction', newEntity);
            this.send('toggleCloseChild');
        },

        actions: {
            toggleCloseChild: function toggleCloseChild() {
                this.toggleProperty('isChildFormOpen');
                if (this.get('isChildFormOpen')) {
                    this.set('isChildOpen', true);
                    this.set('isThisChildOpen', true);
                    this.bindOutsideClick();
                } else {
                    this.set('isChildOpen', false);
                    this.set('isThisChildOpen', false);
                    this.unbindOutsideClick();
                }
            },
            createNew: function createNew(entityName) {
                if (entityName === 'message') {
                    this.createNewMessage(entityName);
                } else {
                    this.createNewChildFlow();
                }
            },
            closeMessage: function closeMessage(message) {
                if (!message) {
                    this.get('messageHolder').unloadRecord();
                    return;
                }

                this.get('deflectionBot').set(this.get('messageFor') + 'Id', this.get('messageHolder.id'));
                this.set('messageFor', null);
            },
            toggleOtherChildOpen: function toggleOtherChildOpen() {
                this.toggleProperty('isThisChildOpen');
                this.toggleProperty('isChildOpen');
            },
            onChanged: function onChanged(changedValue) {
                this.get('deflectionBot').set(this.get('deflectionAction') + 'Id', changedValue.id);
                this.get('deflectionBot').set(this.get('deflectionAction'), changedValue);
            },
            showChild: function showChild(action) {
                this.send('toggleCloseChild');
                if (action.id) {
                    action.reload();
                }
                this.set('action', action);
            }
        }
    });
});