define('freshfone-ember/constants/static-urls', ['exports'], function (exports) {
  exports['default'] = {
    omniTeamEditUrl: '/a/admin/groups',
    crmTeamEditUrl: '/crm/phone/admin/teams?bundle=sales360',
    crmBusinessHourEditUrl: '/crm/phone/admin/businesshour?bundle=sales360',
    chatBusinessHourUrl: '/crm/messaging/settings/bhrs/{businessId}',
    crmSales: '/crm/sales',
    crmCallLogs: '/crm/phone/call-logs',
    crmInbox: '/crm/messaging'
  };
});