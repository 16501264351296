define("freshfone-ember/components/ongoing-widget/recent-conversations/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/recent-conversations/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "conversation/recent-conversations-list", [], ["currentCall", ["subexpr", "@mut", [["get", "currentCall", ["loc", [null, [4, 18], [4, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "showingMkpApp", ["subexpr", "@mut", [["get", "showingMkpApp", ["loc", [null, [5, 20], [5, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "disableNotes", ["subexpr", "@mut", [["get", "disableNotes", ["loc", [null, [6, 19], [6, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "recentCalls", ["subexpr", "@mut", [["get", "recentCalls", ["loc", [null, [7, 18], [7, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "currentAgent", ["subexpr", "@mut", [["get", "currentAgent", ["loc", [null, [8, 19], [8, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "selectAgent", "selectAgent", "selectContact", "selectContact", "isFromMaximizedWidget", ["subexpr", "@mut", [["get", "isFromMaximizedWidget", ["loc", [null, [11, 28], [11, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "isConversationsLoading", ["subexpr", "@mut", [["get", "isConversationsLoading", ["loc", [null, [12, 29], [12, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "isConversationsLoaded", ["subexpr", "@mut", [["get", "conversationsLoaded", ["loc", [null, [13, 28], [13, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "haveMoreCalls", ["subexpr", "@mut", [["get", "haveMoreCalls", ["loc", [null, [14, 20], [14, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "searchString", ["subexpr", "@mut", [["get", "searchTextForHighlight", ["loc", [null, [15, 19], [15, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "loadMore", "loadMore", "noCallTagsAdded", ["subexpr", "@mut", [["get", "noCallTagsAdded", ["loc", [null, [17, 22], [17, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 4], [18, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 2
            }
          },
          "moduleName": "freshfone-ember/components/ongoing-widget/recent-conversations/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "conversation-loading");
          var el2 = dom.createTextNode("\n     ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["recent_conversations.loading"], [], ["loc", [null, [21, 5], [21, 41]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/ongoing-widget/recent-conversations/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["recent-conversations ", ["subexpr", "if", [["get", "inWidgetTransferring", ["loc", [null, [1, 38], [1, 58]]], 0, 0, 0, 0], "transferring"], [], ["loc", [null, [1, 33], [1, 75]]], 0, 0], " ", ["subexpr", "if", [["get", "hasAppliedParams", ["loc", [null, [1, 81], [1, 97]]], 0, 0, 0, 0], "filter-applied"], [], ["loc", [null, [1, 76], [1, 117]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "conversationsLoaded", ["loc", [null, [2, 8], [2, 27]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [23, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});