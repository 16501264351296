define('freshfone-ember/mixins/integration-mixin', ['exports', 'freshfone-ember/utils/call-constants', 'ember'], function (exports, _freshfoneEmberUtilsCallConstants, _ember) {
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    integratedApplications: computed(function () {
      return this.store.peekAll('integratedApplication');
    }),
    integratedResources: computed('call.integratedResources', 'integratedApplications.[]', function () {
      var _this = this;

      var integratedApplications = this.get('integratedApplications'),
          resources = [];

      integratedApplications.forEach(function (integratedApplication) {
        var name = integratedApplication.get('integration.name');

        var resource = undefined;

        if (_this.get('call.integratedResources')) {
          resource = _this.get('call.integratedResources').filterBy('integratedApplication.integration.name', integratedApplication.get('integration.name'));
        }
        resources.push({
          name: name,
          resource: resource
        });
      });

      return resources;
    }),
    // this has to remove after freshservice side fix
    resources: computed('call.integratedResources', 'integratedResources.[]', function () {
      var resources = [];

      if (this.get('call.integratedResources') && this.get('call.integratedResources.length')) {
        resources = this.get('call.integratedResources').filter(function (resouce) {
          return resouce.get('integratedApplication.name') !== 'freshservice';
        });
      }

      return resources;
    }),
    integratedActions: computed('call.integratedResources', 'integratedApplications.[]', function () {
      var hasActions = this.get('integratedApplications').any(function (integratedApplication) {
        var actions = [];

        if (integratedApplication.get('integration.integrationActions').length > 0) {
          actions = integratedApplication.get('integration.integrationActions');
        } else {
          actions = integratedApplication.get('integrationActions');
        }

        return actions.any(function (action) {
          return action.get('freshcallerUiEnabled') && integratedApplication.get('enabled');
        });
      });

      return hasActions;
    }),
    freshcallerUiEnabledApplications: computed('integratedApplications.[]', function () {
      var applications = this.get('integratedApplications').map(function (integratedApplication) {
        var actions = integratedApplication.get('integration.integrationActions');

        if (integratedApplication.get('integration.integrationActions').get('length') === 0) {
          actions = integratedApplication.get('integrationActions');
        }

        var freshcallerUiEnabledActions = actions.content.filter(function (action) {
          return action.get('freshcallerUiEnabled');
        }),
            uiEnabled = isEmpty(freshcallerUiEnabledActions);

        integratedApplication.set('freshcallerUiEnabled', !uiEnabled);

        return integratedApplication;
      });

      return applications;
    }),

    hasResourcesOrActions: computed('resources', 'integratedActions.[]', function () {
      // has to changet to call.integratedResources.length after freshservice side fix

      return this.get('resources.length') || this.get('integratedActions');
    }),
    isIntegrationActionsEnabled: computed(function () {
      return this.hasFeatures(['integration_actions']);
    }),
    hasIntegratedApplications: computed('integratedApplications.[]', function () {
      return this.get('integratedApplications.length');
    }),
    isCallCompleted: computed('call.status', function () {
      return !_freshfoneEmberUtilsCallConstants['default'].INCOMPLETED_CALL_STATUS.includes(this.get('call.status'));
    }),
    isNotAgentLeg: computed('call', function () {
      var callModel = this.get('call')._internalModel || this.get('call').content._internalModel;

      return callModel.modelName === 'call';
    }),
    isNotSampleCall: computed.notEmpty('call.number'),

    // eslint-disable-next-line max-len
    showIntegrationOptions: computed.and('hasIntegratedApplications', 'isIntegrationActionsEnabled', 'hasResourcesOrActions', 'isCallCompleted', 'isNotAgentLeg', 'isNotSampleCall')
  });
});