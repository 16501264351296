define('freshfone-ember/components/call-tags/default-tags/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    sortedTagList: computed.sort('defaultTags', 'sortDefinition'),
    sortDefinition: ['disabled:asc'],

    init: function init() {
      this._super.apply(this, arguments);
      this.getTags();
    },

    getTags: function getTags() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this.store.query('tag', { paginate: false }).then(function (tags) {
          _this.set('defaultTags', tags.filterBy('default').sortBy('disabled'));
          resolve();
        });
      });
    }
  });
});