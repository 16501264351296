define('freshfone-ember/components/ivr-component/ivr-child/component', ['exports', 'ember', 'freshfone-ember/mixins/click-outside-listener'], function (exports, _ember, _freshfoneEmberMixinsClickOutsideListener) {
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  var isEqual = _ember['default'].isEqual;
  exports['default'] = _ember['default'].Component.extend(_freshfoneEmberMixinsClickOutsideListener['default'], {
    IVRChildOpen: false,
    childIVR: {},
    outSideClickOnInit: false,
    onOutsideClick: function onOutsideClick() {
      this.send('toggleCloseChild');
      this.unbindOutsideClick();
    },
    isChildPresent: computed.notEmpty('actionValue'),
    showError: computed.not('isChildPresent'),
    childForm: computed.and('IVRChildOpen', 'keyOption.isACallFlow'),

    ivrSelected: computed('optionAction', function () {
      return isEqual(this.get('optionAction.name'), 'ivr');
    }),
    callQueueSelected: computed('optionAction', function () {
      return isEqual(this.get('optionAction.name'), 'callQueue');
    }),
    isRoutingAutomationSelected: computed.equal('optionAction.name', 'routingAutomation'),
    messageSelected: computed('optionAction', function () {
      var messages = ["hangup", "vm"];
      return messages.includes(this.get('optionAction.name'));
    }),
    agentExtensionSelected: computed.equal('optionAction.name', 'agentExtension'),
    routingAutomationSelected: computed.equal('optionAction.name', 'routingAutomation'),
    otherChildOpen: computed('isChildOpen', 'IVRChildOpen', function () {
      if (this.get('isChildOpen') && !this.get('isThisChildOpen')) {
        return true;
      }
    }),
    noMatchfound: computed('modelName', function () {
      var entityName = _ember['default'].i18n.t('entityNames.' + this.get('modelName'));
      return _ember['default'].i18n.t('entityNames.no_match', { entityName: entityName.string });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get("actionValue");
    },
    modelName: computed('keyOption.actionType', function () {
      var messages = ["hangup", "vm"];
      var action = this.get("keyOption.actionType");
      if (messages.includes(action)) {
        return "message";
      }
      return action;
    }),
    modelNamei18n: computed('modelName', function () {
      return _ember['default'].i18n.t('entityNames.' + this.get('modelName'));
    }),

    modelLabel: computed('modelName', function () {
      var key = ['call_ivr', 'labels', 'create', this.get('modelName').decamelize()].join('.');
      return _ember['default'].i18n.t(key);
    }),

    actionValue: computed('keyOption.actionId', 'keyOption.actionType', 'keyOption.isACallFlow', {
      get: function get() {
        var _this = this;

        var actionId = this.get("keyOption.actionId");
        if (isEmpty(actionId)) {
          return this.handleEmptyAction();
        }
        this.store.findRecord(this.get("modelName"), actionId).then(function (value) {})['catch'](function (reason) {
          _this.set("keyOption.actionId", null);
          return;
        });

        return this.findOrFetch(this.get("modelName"), actionId, ['routingAutomation'].includes(this.get('modelName')));
      },
      set: function set(k, v) {
        this.resetActionObject();
        if (v) {
          if (v.get("isNew")) {
            this.setActionValue(v);
          } else {
            this.set("keyOption.actionId", v.id);
          }
        }
        return v;
      }
    }),

    setActionValue: function setActionValue(value) {
      if (!this.get('keyOption.isMessageNotSelected')) {
        return;
      }
      if (this.get('keyOption.isIvrSelected')) {
        return this.set('keyOption.actionIvr', value);
      } else if (this.get('keyOption.isCallQueueSelected')) {
        return this.set('keyOption.actionQueue', value);
      } else {
        return this.set('keyOption.actionRoutingAutomation', value);
      }
    },

    resetActionObject: function resetActionObject() {
      this.set('keyOption.actionIvr', null);
      this.set('keyOption.actionQueue', null);
    },

    handleEmptyAction: function handleEmptyAction() {
      if (!this.get('keyOption.isACallFlow')) {
        return;
      }
      if (this.get('keyOption.isIvr')) {
        return this.get('keyOption.actionIvr');
      } else if (this.get('keyOption.isCallQueue')) {
        return this.get('keyOption.actionQueue');
      }
    },

    hasNewChildQueue: computed.and('keyOption.actionQueue.content', 'keyOption.actionQueue.isNew'),

    hasNewChildIvr: computed.and('keyOption.actionIvr.content', 'keyOption.actionIvr.isNew'),

    findOrFetch: function findOrFetch(modelName, recordId, shouldReload) {
      var record = this.store.peekRecord(modelName, recordId);

      if (shouldReload || !record) {
        return this.store.findRecord(modelName, recordId);
      }

      return record;
    },

    isParentIvrAsChild: computed('actionValue', 'parentIvr', function () {
      return _ember['default'].isEqual(this.get('actionValue'), this.get('parentIvr.content'));
    }),

    createNewChildCallFlow: function createNewChildCallFlow(model, actionFor) {
      this.set('keyOption.action' + actionFor, this.store.createRecord(model));
      this.set('keyOption.actionId', null);
    },

    createNewChild: function createNewChild() {
      if (this.get('ivrSelected')) {
        this.createNewChildCallFlow('ivr', 'Ivr');
      } else if (this.get('isRoutingAutomationSelected')) {
        this.createNewChildCallFlow('routing-automation', 'RoutingAutomation');
        this.get('keyOption.actionRoutingAutomation.content').createDefaultRoutingRule();
      } else {
        this.createNewChildCallFlow('call-queue', 'Queue');
      }
      this.send('toggleCloseChild');
    },

    createNewMessage: function createNewMessage() {
      var defaultMsjTitle = this.store.peekAll('message').content.length + 1;
      var newObject = this.store.createRecord('message', { name: _ember['default'].i18n.t('message_components.record_component.message') + ' ' + defaultMsjTitle });
      this.set('newMessage', newObject);
      this.set('showMessage', true);
    },
    ivrList: computed(function () {
      return this.store.findAll('ivr');
    }),
    filteredIvrList: computed.filter('ivrList', function (ivr) {
      var parentContent = isEmpty(this.get('parentIvr.content')) ? this.get('parentIvr') : this.get('parentIvr.content');
      if (!isEqual(ivr, parentContent) && !ivr.get("isNew")) {
        return ivr;
      }
    }),
    callQueueList: computed(function () {
      return this.store.findAll('call-queue');
    }),
    filteredQueueList: computed.filter('callQueueList', function (queue) {
      if (!queue.get("isNew")) {
        return queue;
      }
    }),
    callFlowList: computed('modelName', 'filteredIvrList', 'filteredQueueList', function () {
      var list = this.get('modelName') == 'ivr' ? this.get('filteredIvrList') : this.get('filteredQueueList');
      return list;
    }),
    isAncestorIvr: function isAncestorIvr(child) {
      var list = this.get('ivrAncestorList').filter(function (ivrModel) {
        return ivrModel == child;
      });
      return !isEmpty(list);
    },
    removeAncestorChild: function removeAncestorChild() {
      var _this2 = this;

      if (this.get('modelName') == 'ivr') {
        (function () {
          var ivrChild = _this2.get('keyOption.actionIvr.content');
          var list = _this2.get('ivrAncestorList').filter(function (ivrModel) {
            return ivrModel != ivrChild;
          });
          _this2.set('ivrAncestorList', list);
        })();
      }
    },
    ivrChildEditable: function ivrChildEditable(child) {
      if (this.get('modelName') == 'ivr') {
        var ivrChild = isEmpty(child.id) ? child.content : child;
        if (this.get('isParentIvrAsChild')) {
          return false;
        }
        if (this.isAncestorIvr(ivrChild)) {
          return false;
        }
        this.set('ivrAncestorList', this.get('ivrAncestorList').concat(ivrChild));
      }
      return true;
    },
    actions: {
      createNew: function createNew(attribute) {
        if (isEqual(attribute, 'message')) {
          return this.createNewMessage();
        }
        this.createNewChild();
      },
      activateChildIVR: function activateChildIVR() {
        if (this.get('IVRChildOpen')) {
          if (this.get('ivrSelected')) {
            this.set('childIVR', this.store.createRecord('ivr'));
          }
        }
      },
      toggleCloseChild: function toggleCloseChild() {
        this.toggleProperty('IVRChildOpen');
        if (this.get('IVRChildOpen')) {
          this.set('isChildOpen', true);
          this.set('isThisChildOpen', true);
          this.bindOutsideClick();
          var keyOption = this.get('keyOption');
          if (isEmpty(keyOption._originalRelations)) {
            keyOption.saveOriginalRelations();
          }
        } else {
          //hack
          this.removeAncestorChild();
          this.set('isChildOpen', false);
          this.set('isThisChildOpen', false);
          this.unbindOutsideClick();
        }
      },
      toggleOtherChildOpen: function toggleOtherChildOpen() {
        this.toggleProperty('isThisChildOpen');
        this.toggleProperty('isChildOpen');
      },
      showChild: function showChild(child) {
        if (!this.ivrChildEditable(child)) {
          return false;
        }
        this.send('toggleCloseChild');
        if (this.get('ivrSelected')) {
          (child.get("content") || child).reload();
          this.set('keyOption.actionIvr', child);
        } else if (this.get('callQueueSelected')) {
          (child.get("content") || child).reload();
          this.set('keyOption.actionQueue', child);
        } else if (this.get('isRoutingAutomationSelected')) {
          (child.get("content") || child).reload();
          this.set('keyOption.actionRoutingAutomation', child);
        }
      },
      closeMessage: function closeMessage() {
        var newObject = this.get("newMessage");
        if (!newObject.get("isNew")) {
          this.get('keyOption').set('actionId', newObject.id);
        } else {
          newObject.deleteRecord();
        }
      }
    }
  });
});