define('freshfone-ember/components/business-calendar/working-day-form/component', ['exports', 'ember', 'freshfone-ember/mixins/model-form-mixin', 'ember-validations', 'freshfone-ember/mixins/errorfield-focus-tabs'], function (exports, _ember, _freshfoneEmberMixinsModelFormMixin, _emberValidations, _freshfoneEmberMixinsErrorfieldFocusTabs) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    var isEmpty = _ember['default'].isEmpty;
    exports['default'] = Component.extend(_freshfoneEmberMixinsModelFormMixin['default'], _emberValidations['default'], _freshfoneEmberMixinsErrorfieldFocusTabs['default'], {
        editMode: false,
        isWorkingHoursNotEmpty: computed.gt('businessCalendar.workingHours.length', 0),
        workingDays: computed('businessCalendar.workingDays', function () {
            return this.get('businessCalendar.workingDays');
        }),
        notAllDaysSelected: computed.lt('businessCalendar.workingHours.length', 7),
        init: function init() {
            this._super.apply(this, _toConsumableArray(this));
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.get('businessCalendar.enabledDays').clear();
        },
        actions: {
            addHours: function addHours() {
                if (this.get('businessCalendar.editEnabled')) return;
                var params = {
                    calendar: this.get('businessCalendar')
                };
                this.store.createRecord('working-hour', params);
                this.set('businessCalendar.editEnabled', true);
            }
        }
    });
});