define('freshfone-ember/components/contact-form/phone-number/component', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  exports['default'] = _ember['default'].Component.extend({
    hasNoCallerNumber: computed.not('callerNumber'),
    hasContactNumber: computed.and('hasNoCallerNumber', 'contactNumber.id'),
    //shows call icon when it isn't a shared account or when the user is standalone even if it's a shared account
    showCallIcon: computed('hasContactNumber', function () {
      return !this.get('session.account.isSharedBundleAccount') && this.get('hasContactNumber') || this.get('session.user.isStandAloneUser') && this.get('hasContactNumber');
    }),

    actions: {
      deleteNumber: function deleteNumber(contactNumber) {
        this.get('contact').get('contactNumbers').removeObject(contactNumber);
        contactNumber.deleteRecord();
      },

      clickToCall: function clickToCall(phoneNumber) {
        document.dispatchEvent(new CustomEvent('click_to_call', { detail: { phoneNumber: phoneNumber } }));

        if (this.get('onClickToCall')) {
          this.get('onClickToCall')();
        }
      }
    }
  });
});