define('freshfone-ember/helpers/list-timezone', ['exports', 'ember', 'freshfone-ember/utils/timezone-constants'], function (exports, _ember, _freshfoneEmberUtilsTimezoneConstants) {
  exports.listTimezone = listTimezone;
  var Helper = _ember['default'].Helper;

  var moment = window.moment;

  function listTimezone() {
    var timeElapsed = new Date(Date.now());

    return _freshfoneEmberUtilsTimezoneConstants['default'].timezones.map(function (timezone) {
      return {
        id: timezone.id,
        displayName: '(GMT' + moment.tz(timeElapsed, timezone.tz_info).format('Z') + ') ' + timezone.id,
        tzInfo: timezone.tz_info
      };
    });
  }

  exports['default'] = Helper.helper(listTimezone);
});