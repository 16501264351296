define('freshfone-ember/components/ongoing-widget/new-minimized-widget/component', ['exports', 'freshfone-ember/constants/active-call-type', 'freshfone-ember/constants/call-state', 'freshfone-ember/constants/call-status', 'freshfone-ember/constants/call-type', 'ember-rl-dropdown/mixins/rl-dropdown-component', 'ember', 'freshfone-ember/constants/park-state'], function (exports, _freshfoneEmberConstantsActiveCallType, _freshfoneEmberConstantsCallState, _freshfoneEmberConstantsCallStatus, _freshfoneEmberConstantsCallType, _emberRlDropdownMixinsRlDropdownComponent, _ember, _freshfoneEmberConstantsParkState) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var $ = _ember['default'].$;
  var observer = _ember['default'].observer;
  var _Ember$computed = _ember['default'].computed;
  var equal = _Ember$computed.equal;
  var or = _Ember$computed.or;
  var alias = _Ember$computed.alias;
  var run = _ember['default'].run;
  var widgetOffset = 173;
  var parkedZindex = 999999;
  var charLimit = {
    defaultTextLimitMin: 14,
    defaultTextLimitMax: 16,
    substringLimitConferenceMin: 7,
    substringLimitConferenceMax: 9,
    substringLimitMin: 3,
    substringLimitMid: 4,
    substringLimitMax: 5
  };exports['default'] = Component.extend(_emberRlDropdownMixinsRlDropdownComponent['default'], {
    i18n: service(),
    telephony: service(),
    parkedCallsService: service('parked-calls'),
    currentCall: computed.alias('telephony.currentCall'),
    parallelCallOrConferenceEnded: computed.alias('telephony.parallelCallOrConferenceEnded'),
    isAgentConference: computed.equal('callDetails.type', 'agent_conference'),
    currentUser: computed.alias('session.user'),
    zIndex: 1000000,
    currentAgent: computed.alias('currentUser.agent'),
    agentContact: computed('callDetails.from', function () {
      var contact = this.get('callDetails.from');

      return contact.name;
    }),
    isCurrentCall: computed('currentCall.id', 'callDetails.id', function () {
      return this.get('currentCall.id') === this.get('callDetails.id');
    }),
    isSecondaryParallelCallActive: computed.alias('callDetails.isParallelCall'),
    parallelCallCloseAndResume: computed('parallelCalls.@each.{state}', function () {
      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];

      return parallelCall && parallelCall.state === _freshfoneEmberConstantsCallState['default'].CloseAndResume;
    }),
    isPrimaryCallCloseAndResume: computed.equal('callDetails.callState', _freshfoneEmberConstantsCallState['default'].CloseAndResume),
    parallelCallAndConferenceEnded: computed('callDetails.{callType,isCompleted}', 'parallelCalls.@each.{state,status,conferenceState}', function () {
      var callType = this.get('callDetails.callType');

      var _get3 = this.get('parallelCalls');

      var _get32 = _slicedToArray(_get3, 1);

      var parallelCall = _get32[0];
      var parallelCallEnded = parallelCall && (parallelCall.state === _freshfoneEmberConstantsCallState['default'].Ended || parallelCall.state === _freshfoneEmberConstantsCallState['default'].Removed);
      var parallelConferenceEnded = parallelCall && (!parallelCall.primaryConferenceState || parallelCall.primaryConferenceState === _freshfoneEmberConstantsCallState['default'].Ended || parallelCall.primaryConferenceState === _freshfoneEmberConstantsCallState['default'].Removed);

      return !parallelCall || callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && parallelCallEnded && parallelConferenceEnded;
    }),
    primaryConferenceCaller: computed('callDetails.conferenceTarget', function () {
      var target = this.get('callDetails.conferenceTarget');

      return target && target.name ? target.name : '';
    }),
    isParallelCallActive: computed('callDetails.callType', 'isSecondaryParallelCallActive', function () {
      return this.get('callDetails.callType') === _freshfoneEmberConstantsActiveCallType['default'].Parallel || this.get('isSecondaryParallelCallActive');
    }),
    isPrimaryCallActive: computed('callDetails.{isCompleted,callState,conferenceState}', function () {
      var callState = this.get('callDetails.callState'),
          primaryConferenceState = this.get('callDetails.conferenceState'),
          primaryCallEnded = callState === _freshfoneEmberConstantsCallState['default'].Ended || callState === _freshfoneEmberConstantsCallState['default'].Removed,
          primaryConferenceEnded = !primaryConferenceState || primaryConferenceState === _freshfoneEmberConstantsCallState['default'].Ended || primaryConferenceState === _freshfoneEmberConstantsCallState['default'].Removed,
          activeAndConferenceEnded = primaryCallEnded && primaryConferenceEnded;

      return !this.get('callDetails.isCompleted') && !activeAndConferenceEnded;
    }),
    primaryCallOnHold: computed('callDetails.{callType,callState}', function () {
      var callType = this.get('callDetails.callType'),
          callState = this.get('callDetails.callState');

      return callType !== _freshfoneEmberConstantsActiveCallType['default'].Parallel && callState === _freshfoneEmberConstantsCallState['default'].Hold;
    }),
    parallelCallOnHold: computed('callDetails.callType', 'parallelCalls.[]', 'parallelCalls.@each.{state,conferenceState}', function () {
      var callType = this.get('callDetails.callType');

      var _get4 = this.get('parallelCalls');

      var _get42 = _slicedToArray(_get4, 1);

      var parallelCall = _get42[0];
      var state = parallelCall && parallelCall.get('state');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel && state === _freshfoneEmberConstantsCallState['default'].Hold;
    }),
    onHold: computed.or('parallelCallOnHold', 'primaryCallOnHold', 'callDetails.isOnHold'),
    noCallActive: computed('parallelCallAndConferenceEnded', 'isPrimaryCallActive', function () {
      return !this.get('isPrimaryCallActive') && this.get('parallelCallAndConferenceEnded');
    }),
    callEnded: or('noCallActive', 'currentCallEnded'),
    callStatusInfo: computed('callDetails.{status,isCompleted}', 'callEnded', 'onHold', 'callIsParked', function () {
      if (this.get('callIsParked')) {
        return this.get('i18n').t('call_park.call_parked');
      }

      if (this.get('callEnded') || this.get('callDetails.isCompleted') || this.get('callDetails.status') === _freshfoneEmberConstantsCallStatus['default'].Completed) {
        return this.get('i18n').t('minimized_widget.end_call');
      }

      if (this.get('onHold')) {
        return this.get('i18n').t('minimized_widget.hold_call');
      }

      return this.get('i18n').t('minimized_widget.active_call');
    }),
    addNotesToCall: false,
    isMinimised: alias('callDetails.minimized'),
    showNotesContainer: computed('isNotesDisabled', 'isMinimised', 'isCurrentCall', function () {
      return this.get('isMinimised') && this.get('isCurrentCall') && !this.get('isNotesDisabled');
    }),
    isWarmTransfer: computed('callDetails.status', 'callDetails.type', function () {
      return this.get('callDetails.status') === _freshfoneEmberConstantsCallStatus['default'].WarmTransferred || this.get('callDetails.type') === _freshfoneEmberConstantsCallType['default'].WarmTransfer;
    }),
    isTransferOrConference: or('isAgentConference', 'isWarmTransfer'),
    isNotesDisabled: or('isTransferOrConference', 'isSecondaryParallelCallActive', 'callIsParked'),
    primaryCallerInfo: computed('callDetails.callState', 'parallelCalls.[]', 'callDetails.contact.name', 'callDetails.callerName', 'primaryConferenceCaller', function () {
      var callState = this.get('callDetails.callState'),
          conferenceCaller = this.get('primaryConferenceCaller');

      if (callState === _freshfoneEmberConstantsCallState['default'].Ended || callState === _freshfoneEmberConstantsCallState['default'].Removed) {
        return conferenceCaller;
      }

      // eslint-disable-next-line one-var
      var targetContact = this.get('callDetails.contact.name') || this.get('callDetails.callerName') || '';

      if (conferenceCaller) {
        return targetContact + ', ' + conferenceCaller;
      }

      return targetContact;
    }),
    activeCallerName: computed('isParallelCallActive', 'primaryCallerInfo', 'secondaryCallerInfo', function () {
      if (this.get('isParallelCallActive')) {
        return this.get('secondaryCallerInfo').replace(',', '+');
      }

      return this.get('primaryCallerInfo').replace(',', '+');
    }),
    contactToolTipInfo: computed('primaryCallerInfo', 'secondaryCallerInfo', 'isParallelCallActive', 'isSecondaryParallelCallActive', function () {
      if (this.get('isSecondaryParallelCallActive')) {
        return this.get('secondaryCallerInfo');
      }

      if (this.get('isParallelCallActive')) {
        return this.get('primaryCallerInfo') ? this.get('secondaryCallerInfo') + ', ' + this.get('primaryCallerInfo') : this.get('secondaryCallerInfo');
      }

      return this.get('secondaryCallerInfo') ? this.get('primaryCallerInfo') + ', ' + this.get('secondaryCallerInfo') : this.get('primaryCallerInfo');
    }),
    hasOnlyParallelConference: computed('parallelCalls.[]', 'parallelCalls.@each.state', function () {
      var _get5 = this.get('parallelCalls');

      var _get52 = _slicedToArray(_get5, 1);

      var parallelCall = _get52[0];

      return parallelCall && (parallelCall.get('state') === _freshfoneEmberConstantsCallState['default'].Ended || parallelCall.get('state') === _freshfoneEmberConstantsCallState['default'].Removed);
    }),
    parallelConferenceCaller: computed('parallelCalls.@each.conferenceTarget', function () {
      var _get6 = this.get('parallelCalls');

      var _get62 = _slicedToArray(_get6, 1);

      var parallelCall = _get62[0];
      var conferenceTarget = parallelCall && parallelCall.get('conferenceTarget');

      return conferenceTarget && conferenceTarget.name ? conferenceTarget.name : '';
    }),
    primaryCallerExistsInfo: computed('isPrimaryCallActive', function () {
      return this.get('isPrimaryCallActive') ? this.get('i18n').t('minimized_widget.added') : '';
    }),
    parallelCallerExistsInfo: computed('parallelCallOrConferenceEnded', 'parallelCalls.[]', function () {
      var _get7 = this.get('parallelCalls');

      var _get72 = _slicedToArray(_get7, 1);

      var parallelCall = _get72[0];

      return parallelCall && !this.get('parallelCallOrConferenceEnded') ? this.get('i18n').t('minimized_widget.added') : '';
    }),
    secondaryCallerInfo: computed('parallelCalls.[]', 'isSecondaryParallelCallActive', 'agentContact', 'hasOnlyParallelConference', 'parallelCalls.@each.state', 'parallelConferenceCaller', function () {
      if (this.get('isSecondaryParallelCallActive')) {
        return this.get('agentContact');
      }

      // eslint-disable-next-line one-var

      var _get8 = this.get('parallelCalls');

      var _get82 = _slicedToArray(_get8, 1);

      var parallelCall = _get82[0];
      var conferenceCaller = this.get('parallelConferenceCaller');

      if (this.get('hasOnlyParallelConference')) {
        return this.get('parallelConferenceCaller');
      }

      // eslint-disable-next-line one-var
      var parallelCallTarget = parallelCall && parallelCall.target ? parallelCall.target.name : '';

      if (conferenceCaller) {
        return parallelCallTarget + ', ' + conferenceCaller;
      }

      return parallelCallTarget;
    }),
    formatContactDetails: function formatContactDetails(contactDetails, isSecondaryCallAdded) {
      var activeContactDetailsArray = contactDetails.split(','),
          modifiedContact = isSecondaryCallAdded ? contactDetails + ' + 1' : contactDetails,
          notesIconEnabled = this.get('showNotesContainer'),
          maxTextLimit = notesIconEnabled ? charLimit.defaultTextLimitMin : charLimit.defaultTextLimitMax,
          substringLimitWithoutConference = notesIconEnabled ? charLimit.substringLimitConferenceMin : charLimit.substringLimitConferenceMax,
          substringLimitwithParallelCall = notesIconEnabled ? charLimit.substringLimitMin : charLimit.substringLimitMid,
          substringLimitWithoutParallelCall = notesIconEnabled ? charLimit.substringLimitMid : charLimit.substringLimitMax;

      if (modifiedContact.length < maxTextLimit) {
        return modifiedContact;
      }

      if (!activeContactDetailsArray[1]) {
        return isSecondaryCallAdded ? contactDetails.substring(0, substringLimitWithoutConference) + '..+ 1' : contactDetails;
      }

      return isSecondaryCallAdded ? activeContactDetailsArray[0].substring(0, substringLimitwithParallelCall) + '..,\n          ' + activeContactDetailsArray[1].substring(0, substringLimitwithParallelCall + 1) + '..+ 1' : activeContactDetailsArray[0].substring(0, substringLimitWithoutParallelCall) + '..,\n          ' + activeContactDetailsArray[1].substring(0, substringLimitWithoutParallelCall + 1) + '..';
    },
    currentCallEnded: computed('isParallelCallActive', 'parallelCallCloseAndResume', 'isPrimaryCallCloseAndResume', function () {
      return this.get('isParallelCallActive') ? this.get('parallelCallCloseAndResume') : this.get('isPrimaryCallCloseAndResume');
    }),
    contactName: computed('isParallelCallActive', 'primaryCallerInfo', 'secondaryCallerInfo', 'parallelCallerExistsInfo', 'primaryCallerExistsInfo', 'isSecondaryParallelCallActive', 'showNotesContainer', function () {
      var callerInfo = undefined,
          secondaryCallerAdded = undefined;

      if (this.get('isSecondaryParallelCallActive')) {
        return this.get('secondaryCallerInfo');
      }

      if (this.get('isParallelCallActive')) {
        callerInfo = this.get('secondaryCallerInfo');
        secondaryCallerAdded = this.get('primaryCallerExistsInfo');
      } else {
        callerInfo = this.get('primaryCallerInfo');
        secondaryCallerAdded = this.get('parallelCallerExistsInfo');
      }

      return this.formatContactDetails(callerInfo, secondaryCallerAdded);
    }),
    callIsParked: equal('callDetails.parkState', _freshfoneEmberConstantsParkState['default'].Parked),
    callIsParkedObserver: observer('callIsParked', 'callParkTimer', function () {
      if (!this.get('callParkTimer')) {
        return;
      }

      var isParked = this.get('callIsParked');

      if (isParked) {
        this.get('callParkTimer').send('play');
      } else {
        this.get('callParkTimer').send('stop');
      }
    }),
    parkTime: computed.alias('callDetails.callParkedTimer'),
    hasActiveCall: computed.alias('telephony.hasActiveCall'),
    widgetOffset: computed('widgetIndex', 'hasActiveCall', function () {
      var index = this.get('widgetIndex');

      this.set('zIndex', parkedZindex - this.get('widgetIndex'));

      return index * widgetOffset;
    }),
    parkedCallsLength: computed.alias('parkedCallsService.parkedCallsLength'),
    isLeftMostWidget: computed('totalCount', 'widgetIndex', function () {
      return this.get('widgetIndex') === this.get('totalCount') - 1;
    }),
    actionInProgress: or('telephony.disableAction', 'parkedCallsService.parking'),
    isTransferIncomingCall: alias('telephony.isTransferIncomingCall'),
    callSwitchingRestricted: or('actionInProgress', 'isTransferIncomingCall', 'telephony.isQueueTransferInitiated'),
    isCurrentCallWidget: computed('callDetails.id', 'currentCall.id', function () {
      return this.get('callDetails.id') === this.get('currentCall.id');
    }),
    disableWidget: computed('isCurrentCallWidget', 'callSwitchingRestricted', function () {
      return !this.get('isCurrentCallWidget') && this.get('callSwitchingRestricted');
    }),
    widgetMinimisedObserver: observer('callDetails.minimized', function () {
      if (this.get('callDetails.minimized')) {
        return;
      }
      this.set('addNotesToCall', false);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this, 'bindTooltip');
    },
    bindTooltip: function bindTooltip() {
      $('[data-toggle="tooltip-conatct-info ' + this.get('callDetails.id') + '"]').tooltip();
    },
    actions: {
      updateNotes: function updateNotes() {
        return false;
      },
      addNotes: function addNotes() {
        this.toggleProperty('addNotesToCall');
      },
      toggleMaximise: function toggleMaximise() {
        if (!this.get('isCurrentCallWidget')) {
          if (this.get('callSwitchingRestricted')) {
            return;
          }

          this.sendAction('onMaximise', {
            id: this.get('callDetails.id'),
            callerName: this.get('activeCallerName')
          });

          return;
        }

        var minimizedState = this.get('callDetails.minimized');

        this.set('callDetails.minimized', !minimizedState);
        this.set('addNotesToCall', false);

        if (minimizedState) {
          $('.modal-backdrop').show();
        } else {
          $('.modal-backdrop').hide();
        }
      }
    }
  });
});
/* eslint-disable no-extra-parens */