define('freshfone-ember/services/record-type', ['exports', 'ember', 'freshfone-ember/helpers/record-default-types'], function (exports, _ember, _freshfoneEmberHelpersRecordDefaultTypes) {
  var Service = _ember['default'].Service;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Service.extend({
    telephony: service(),
    activeFreshfoneNumber: computed.alias('telephony.activeFreshfoneNumber'),
    recordingPreferenceMap: new Map(),

    addCallToRecordingPreferenceMap: function addCallToRecordingPreferenceMap(callId, recordingParams) {
      this.get('recordingPreferenceMap').set(parseInt(callId), recordingParams);
    },

    removeCallsFromRecordingPreferenceMap: function removeCallsFromRecordingPreferenceMap() {
      this.get('recordingPreferenceMap').clear();
    },

    isCallRecordingParamsExists: function isCallRecordingParamsExists(callId) {
      return this.get('recordingPreferenceMap').has(parseInt(callId));
    },

    getRecordingPreferencesForCall: function getRecordingPreferencesForCall(callId) {
      return this.get('recordingPreferenceMap').get(parseInt(callId));
    },

    setRecordingParams: function setRecordingParams(currentCall) {
      var activeFreshfoneNumber = this.get('telephony').getActiveFreshfoneNumber();

      this.setProperties({
        recordType: currentCall.record_type,
        isOutgoingAutomaticRecording: this.getOutgoingAutomaticRecording(activeFreshfoneNumber),
        isIncomingAutomaticRecording: this.getIncomingAutomaticRecording(),
        isIncomingManualRecording: this.getIncomingManualRecording(),
        isOutgoingManualRecording: this.getOutgoingManualRecording(activeFreshfoneNumber),
        isAutomaticRecording: this.getAutomaticRecording(),
        isIncomingCall: currentCall.to,
        isManualRecording: this.get('isOutgoingManualRecording') || this.get('isIncomingManualRecording')
      });
    },
    setCallBackCall: function setCallBackCall(flag) {
      this.set('isCallBackCall', flag);
    },

    getAutomaticRecording: function getAutomaticRecording() {
      return this.get('isIncomingCall') || this.get('isCallBackCall') ? this.get('isIncomingAutomaticRecording') : this.get('isOutgoingAutomaticRecording');
    },

    getOutgoingAutomaticRecording: function getOutgoingAutomaticRecording(activeFreshfoneNumber) {
      return activeFreshfoneNumber && (activeFreshfoneNumber.get('recordType') === _freshfoneEmberHelpersRecordDefaultTypes['default'].recordOutgoingCalls || activeFreshfoneNumber.get('recordType') === _freshfoneEmberHelpersRecordDefaultTypes['default'].recordAllCalls);
    },

    getIncomingAutomaticRecording: function getIncomingAutomaticRecording() {
      return [_freshfoneEmberHelpersRecordDefaultTypes['default'].recordAllCalls, _freshfoneEmberHelpersRecordDefaultTypes['default'].recordIncomingCalls].includes(this.get('recordType'));
    },

    getManualRecording: function getManualRecording() {
      return this.get('isIncomingCall') ? this.get('isIncomingManualRecording') : this.get('isOutgoingManualRecording');
    },

    getIncomingManualRecording: function getIncomingManualRecording() {
      return this.get('recordType') === _freshfoneEmberHelpersRecordDefaultTypes['default'].recordManually;
    },

    getOutgoingManualRecording: function getOutgoingManualRecording(activeFreshfoneNumber) {
      return activeFreshfoneNumber && activeFreshfoneNumber.get('recordType') === _freshfoneEmberHelpersRecordDefaultTypes['default'].recordManually;
    }
  });
});