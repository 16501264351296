define('freshfone-ember/adapters/call-queue', ['exports', 'freshfone-ember/mixins/adapters/multi-word-underscore', 'ember-data'], function (exports, _freshfoneEmberMixinsAdaptersMultiWordUnderscore, _emberData) {
  exports['default'] = _freshfoneEmberMixinsAdaptersMultiWordUnderscore['default'].extend({
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 422 && payload.errors) {
        var errors = payload.errors.rejectBy('source.pointer', '/data/attributes/dynamo-options');
        return new _emberData['default'].AdapterError(errors);
      }
      return this._super.apply(this, arguments);
    }
  });
});