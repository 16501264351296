define('freshfone-ember/routes/protected/admin/teams', ['exports', 'ember', 'freshfone-ember/routes/protected'], function (exports, _ember, _freshfoneEmberRoutesProtected) {
  var computed = _ember['default'].computed;
  exports['default'] = _freshfoneEmberRoutesProtected['default'].extend({
    titleToken: computed(function () {
      return _ember['default'].i18n.t('team.title').string;
    }),
    breadCrumb: computed(function () {
      return { title: _ember['default'].i18n.t('team.title').string };
    }),
    beforeModel: function beforeModel() {
      this.checkPrivilege('view_teams');
      this.checkFeature('team');
      this.checkIfRestrictedFetaure('caller_pages_in_crm');
      this.store.unloadAll('team');
    },

    model: function model() {
      return this.store.query('team', {});
    }
  });
});