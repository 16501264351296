define("freshfone-ember/helpers/text-highlight", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.helper(function (params) {
    var searchString = params[0],
        text = _ember["default"].Handlebars.Utils.escapeExpression(params[1]) || "";
    var regex, formattedTag;
    if (searchString) {
      regex = new RegExp(searchString, "gi");
      formattedTag = text.replace(regex, "<span class='highlight'>$&</span>");
    } else {
      formattedTag = text;
    }

    return _ember["default"].String.htmlSafe(formattedTag);
  });
});