define("freshfone-ember/components/custom-elements/new-bot/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "freshfone-ember/components/custom-elements/new-bot/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "create-new ellipsis");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "icon-add-circle");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'onclick');
        morphs[2] = dom.createAttrMorph(element0, 'onmouseup');
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["new-message ", ["subexpr", "unless", [["get", "createNewText", ["loc", [null, [1, 33], [1, 46]]], 0, 0, 0, 0], "hide"], [], ["loc", [null, [1, 24], [1, 55]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", [["get", "extra.passedAction", ["loc", [null, [1, 74], [1, 92]]], 0, 0, 0, 0], ["get", "createNewText", ["loc", [null, [1, 93], [1, 106]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [1, 108]]], 0, 0], 0, 0, 0, 0], ["attribute", "onmouseup", ["get", "select.actions.close", ["loc", [null, [1, 121], [1, 141]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["custom_elements.new_bot"], ["botName", ["subexpr", "@mut", [["get", "createNewText", ["loc", [null, [2, 74], [2, 87]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 36], [2, 89]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});