define('freshfone-ember/mixins/feature-check-mixin', ['exports', 'ember', 'freshfone-ember/constants/account'], function (exports, _ember, _freshfoneEmberConstantsAccount) {
  var service = _ember['default'].inject.service;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    session: service(),

    hasFeatures: function hasFeatures(features) {
      return features.every(this.checkFeature, this);
    },
    checkFeature: function checkFeature(feature) {
      return this.get('session.account').hasFeature(feature);
    },
    callForwardingEnabled: function callForwardingEnabled() {
      return this.get('session.account.call_forwarding_enabled');
    },
    allowExternalTransfer: function allowExternalTransfer() {
      return this.get('session.account.allow_external_transfer');
    },
    hasGlobalFeature: function hasGlobalFeature(feature) {
      return this.get('session.account.globalFlags').indexOf(feature) > -1;
    },
    hasRolloutGlobalFlagFeature: function hasRolloutGlobalFlagFeature(feature) {
      return this.get('session.account.rolloutGlobalFlags').indexOf(feature) > -1;
    },
    hasOmniRedirectFeatureEnabled: function hasOmniRedirectFeatureEnabled() {
      return this.get('session.account.isOmnichannelEnabled') && this.checkFeature('ucr_contact_flow') && this.hasGlobalFeature('ucr_for_omni');
    },
    hasSignInTypeEligibilty: function hasSignInTypeEligibilty() {
      var emailSignUpEligibilty = this.hasGlobalFeature('plg_onboarding_email_signup') && this.get('session.account.signup_type') === _freshfoneEmberConstantsAccount['default'].signInType.email,
          gmailSignUpEligibilty = this.hasGlobalFeature('plg_onboarding_google_signup') && this.get('session.account.signup_type') === _freshfoneEmberConstantsAccount['default'].signInType.gmail,
          signInEligibilty = emailSignUpEligibilty || gmailSignUpEligibilty;

      return !this.get('session.user.sourceAppId') && this.get('session.user.account_creator') && signInEligibilty;
    },
    featureCheckWithRolloutFlag: function featureCheckWithRolloutFlag(globalFlag, accountFeature, accountRolloutFlag) {
      return this.hasGlobalFeature(globalFlag) && this.checkFeature(accountFeature) && (this.hasRolloutGlobalFlagFeature(globalFlag) || this.checkFeature(accountRolloutFlag));
    },
    isUcrSearchEnabled: function isUcrSearchEnabled() {
      return this.hasOmniRedirectFeatureEnabled() || this.checkFeature('caller_pages_in_crm');
    }
  });
});