define('freshfone-ember/utils/inject-cx-fails-bot', ['exports'], function (exports) {
  exports.injectCxFailsBotWidget = injectCxFailsBotWidget;

  function injectCxFailsBotWidget(clientParams) {
    (function (d, w, c) {
      if (!d.getElementById("spd-busns-spt")) {
        var n = d.getElementsByTagName('script')[0],
            s = d.createElement('script');
        var loaded = false;
        s.id = "spd-busns-spt";
        s.async = "async";
        s.setAttribute("data-self-init", "false");
        s.setAttribute("data-init-type", "opt");
        s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js';
        s.setAttribute("data-client", "96b0d980a28b7dee9dba3bd490ccf09b071cb9ed");
        s.setAttribute("data-bot-hash", "1875641acf5903160d821b37abfcc7b50c92df20");
        s.setAttribute("data-env", "prod");
        s.setAttribute("data-region", "in");
        if (c) {
          s.onreadystatechange = s.onload = function () {
            if (!loaded) {
              c();
            }
            loaded = true;
          };
        }
        n.parentNode.insertBefore(s, n);
      }
    })(document, window, function () {
      Freshbots.initiateWidget({
        autoInitChat: false,
        getClientParams: function getClientParams() {
          if ($('.mobile-chat-container').length) {
            $('.cx-fails-bot-container').append($('.mobile-chat-container')).append($('.chat-container'));
          }
          return {
            "cstmr::eml": clientParams.userEmail,
            "initiated_from": "freshcaller",
            "cstmr::nm": clientParams.userName,
            "env": clientParams.env
          };
        }
      }, function (successResponse) {}, function (errorResponse) {});
    });
  }
});