define('freshfone-ember/models/account-configuration', ['exports', 'ember-data', 'ember', 'ember-validations', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/model-proxy'], function (exports, _emberData, _ember, _emberValidations, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsModelProxy) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  var computed = _ember['default'].computed;
  var and = _ember['default'].computed.and;
  exports['default'] = Model.extend(_freshfoneEmberMixinsModelProxy['default'], _emberValidations['default'], _freshfoneEmberMixinsFeatureCheckMixin['default'], {
    acwTimeout: attr('string'),
    mandateCallTagging: attr('boolean'),
    networkWarningLogs: attr('boolean'),
    autoAnswerEnabled: attr('boolean'),
    autoAnswerThreshold: attr('number', { defaultValue: 0 }),
    canValidateAutoanswer: and('hasGlobalAnswerFeature', 'autoAnswerEnabled'),
    hasGlobalAnswerFeature: computed(function () {
      return this.hasFeatures(['auto_answer']) && this.hasGlobalFeature('auto_answer');
    }),
    hasCustomWQFeature: computed(function () {
      return this.hasFeatures(['customise_wq_behaviour']) && this.hasGlobalFeature('customise_wq_behaviour');
    }),
    customiseWqEnabled: attr('boolean'),
    customiseWqSorting: attr('string'),
    customiseWqScope: attr('string'),

    validations: {
      acwTimeout: {
        presence: { message: _ember['default'].i18n.t('activemodel.errors.not_empty') },
        format: {
          'with': /^[\d]+$/,
          message: _ember['default'].i18n.t('activemodel.errors.mustOnlyBeNumbers')
        }
      },
      autoAnswerThreshold: {
        presence: {
          'if': 'canValidateAutoanswer',
          message: _ember['default'].i18n.t('account_settings.validation_error.auto_answer_time.valid_number')
        },
        numericality: {
          'if': 'canValidateAutoanswer',
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 10,
          messages: {
            numericality: _ember['default'].i18n.t('account_settings.validation_error.auto_answer_time.valid_number'),
            onlyInteger: _ember['default'].i18n.t('account_settings.validation_error.auto_answer_time.valid_number'),
            greaterThanOrEqualTo: _ember['default'].i18n.t('account_settings.validation_error.auto_answer_time.max_limit'),
            lessThanOrEqualTo: _ember['default'].i18n.t('account_settings.validation_error.auto_answer_time.max_limit')
          }
        }
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */