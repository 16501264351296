define('freshfone-ember/models/current-call', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({

    freshfoneNumber: _emberData['default'].attr(),
    callerNumber: _emberData['default'].attr(),
    callerLocation: _emberData['default'].attr(),
    contact: _emberData['default'].belongsTo('contact', { async: true }),
    company: _emberData['default'].belongsTo('company', { async: true }),

    isCallerCompany: computed('company', 'contact', {
      get: function get() {
        return this.get('company.id') && !this.get('contact.id'); //Use macros
      }
    }),

    isCallerContact: computed('contact', {
      get: function get() {
        return this.get('contact') !== null; //Use macros
      }
    }),

    isCallerNew: computed('contact', 'company', {
      get: function get() {
        return !this.get('contact.id') && !this.get('company.id'); //Use macros
      }
    }),

    contactName: computed('isCallerNew', 'contact.name', {
      get: function get() {
        return this.get('isCallerNew') ? null : this.get('contact.name');
      }
    }),

    companyName: computed('isCallerNew', 'company.name', {
      get: function get() {
        return this.get('isCallerCompany') ? null : this.get('company.name');
      }
    }),

    callerName: computed('isCallerNew', 'isCallerCompany', 'company.name', 'contact.name', {
      get: function get() {
        var isCallerNew = this.get('isCallerNew');
        var isCallerCompany = this.get('isCallerCompany');
        if (isCallerNew) {
          return this.get('callerNumber');
        } else {
          return isCallerCompany ? this.get('company.name') : this.get('contact.name');
        }
      }
    }),

    incomingAlertOptions: computed('callerName', 'companyName', {
      get: function get() {
        return {
          name: this.get('callerName'),
          number: this.get('callerNumber'),
          company: this.get('companyName'),
          callerLocation: this.get('callerLocation'),
          freshfoneNumber: this.get('freshfoneNumber')
        };
      }
    })

  });
});