define('freshfone-ember/constants/icon-urls', ['exports'], function (exports) {
  exports['default'] = {
    parallelCallInProgressIcon: 'assets/images/parallel-call-inprogress.svg',
    powerDialerIcon: 'assets/images/power-dialer-progress.svg',
    callInProgressIcon: 'assets/images/call-in-progress.svg',
    freshcallerIcon: 'assets/images/freshcaller.svg',
    fdContactCenterIcon: 'assets/images/fd-contact-center.svg',
    freshchatIcon: 'assets/images/fresh-chat.svg',
    freshsalesIcon: 'assets/images/freshsales.svg',
    freshsalesSuiteIcon: 'assets/images/freshsales-suite.svg',
    cxsuiteIcon: 'assets/images/cxsuite.svg',
    usFlagIcon: 'assets/images/us.svg',
    loadingImage: 'assets/images/roller.svg',
    successTickIcon: 'assets/images/done.svg',
    noAccountWallet: 'assets/images/suspended.svg',
    networkWarning: 'assets/images/network-warning.svg'
  };
});