define('freshfone-ember/components/ongoing-widget/transfer/new/component', ['exports', 'freshfone-ember/constants/active-call-type', 'freshfone-ember/constants/call-state', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/transfer-options', 'freshfone-ember/constants/transfer-types', 'freshfone-ember/mixins/safe-set', 'freshfone-ember/constants/search-query-constants', 'ember-concurrency'], function (exports, _freshfoneEmberConstantsActiveCallType, _freshfoneEmberConstantsCallState, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsTransferOptions, _freshfoneEmberConstantsTransferTypes, _freshfoneEmberMixinsSafeSet, _freshfoneEmberConstantsSearchQueryConstants, _emberConcurrency) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  var testing = _ember['default'].testing;
  var service = _ember['default'].inject.service;
  var _Ember$run = _ember['default'].run;
  var debounce = _Ember$run.debounce;
  var once = _Ember$run.once;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsSafeSet['default'], {
    telephony: service(),
    parallelCall: service(),
    notifications: service('toastr'),
    classNames: ['dropdown'],
    session: service(),
    currentConnection: service('current-connection'),
    parkedCallsService: service('parked-calls'),
    currentCall: computed.alias('telephony.currentCall'),
    parallelCalls: computed.alias('telephony.parallelCalls'),
    showLoading: false,
    isAgentsLoaded: false,
    agentDetails: [],
    teamsDetails: [],
    searchString: computed({
      // eslint-disable-next-line no-empty-function
      get: function get() {},
      set: function set(_, string) {
        debounce(this, this.invokeLazySearch, testing ? 0 : 250);

        return string;
      }
    }),
    emptySeachString: computed.empty('searchString'),
    hasNoCallTags: computed.empty('currentCall.tags'),
    hasErrorMessage: computed.notEmpty('errorMessage'),
    showAgentConferenceButton: computed(function () {
      return this.get('session.account').hasFeature('agent_conference');
    }),
    warmTransferEnabled: computed(function () {
      return this.get('session.account').hasFeature('warm_transfer');
    }),
    isExternalNumberRouteEnabled: computed(function () {
      return this.get('session.account').hasFeature('external_number_route');
    }),
    externalConferenceEnabled: computed(function () {
      return this.get('session.account').hasFeature('external_number_conference');
    }),
    loadAgentsClicked: observer('dropdownExpanded', function () {
      if (!this.get('isAgentsLoaded')) {
        this.loadAgents();
      }
    }),

    hasTeamFeature: computed(function () {
      return this.get('session.account').hasFeature('team');
    }),

    isExternalTransferAllowed: computed('isPhoneNumberFormat', function () {
      return this.get('isPhoneNumberFormat') && this.allowExternalTransfer();
    }),

    isPhoneNumberFormat: computed('searchString', function () {
      this.set('isNumberInvalid', false);
      this.set('errorMessage', null);
      if (this.get('emptySeachString')) {
        return false;
      }

      return this.get('searchString').match(/^[\x20+()0-9+-]*$/);
    }),

    searchRegex: computed('searchString', function () {
      return new RegExp(this.get('searchString').replace(/[^\w\s]/gi, ''), 'i');
    }),

    searchPlaceholder: computed(function () {
      if (this.hasFeatures(['external_number_route'])) {
        return _ember['default'].i18n.t('transfer.all_transfer_placeholder');
      }

      return _ember['default'].i18n.t('transfer.search_placeholder');
    }),
    parallelCallEnabled: computed('session.account', function () {
      return this.hasGlobalFeature('parallel_call') && this.get('session.account').hasFeature('parallel_call');
    }),
    parallelConferenceStatus: computed.alias('telephony.parallelConferenceStatus'),
    hasActiveParallelCall: computed('parallelCalls.[]', function () {
      return this.get('parallelCalls').length > 0;
    }),
    primaryCallEnded: computed('currentCall.callState', function () {
      var callState = this.get('currentCall.callState');

      return callState === _freshfoneEmberConstantsCallState['default'].Ended || callState === _freshfoneEmberConstantsCallState['default'].Removed || callState === _freshfoneEmberConstantsCallState['default'].CloseAndResume;
    }),
    hasActivePrimaryConference: computed('currentCall.conferenceState', function () {
      var conferenceState = this.get('currentCall.conferenceState');

      return conferenceState && conferenceState !== _freshfoneEmberConstantsCallState['default'].Removed;
    }),
    hasActiveParallelConference: computed('parallelConferenceStatus', function () {
      return this.get('parallelConferenceStatus') && this.get('parallelConferenceStatus') !== _freshfoneEmberConstantsCallState['default'].Removed;
    }),
    disableTransfer: computed.or('hasActiveParallelCall', 'hasActivePrimaryConference'),
    disableConference: computed('currentCall.callType', 'hasActivePrimaryConference', 'hasActiveParallelConference', 'primaryCallEnded', function () {
      var callType = this.get('currentCall.callType');

      if (this.get('primaryCallEnded')) {
        return true;
      }

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('hasActiveParallelConference') : this.get('hasActivePrimaryConference');
    }),
    transferOptions: computed('disableTransfer', 'disableConference', 'warmTransferEnabled', 'parallelCallEnabled', 'hasActiveParallelCall', function () {
      return [{
        id: _freshfoneEmberConstantsTransferOptions['default'].WarmTransfer,
        label: this.get('i18n').t('transfer.warmTransfer'),
        icon: 'icon-warm-transfer',
        visible: this.get('warmTransferEnabled'),
        disabled: this.get('disableTransfer')
      }, {
        id: _freshfoneEmberConstantsTransferOptions['default'].ColdTransfer,
        label: this.get('i18n').t('transfer.transfer_tooltip'),
        icon: 'icon-call-transfer',
        visible: true,
        disabled: this.get('disableTransfer')
      }, {
        id: _freshfoneEmberConstantsTransferOptions['default'].AddParticipants,
        label: this.get('i18n').t('transfer.add_participant'),
        icon: 'icon-add-agent',
        visible: true,
        disabled: this.get('disableConference')
      }, {
        id: _freshfoneEmberConstantsTransferOptions['default'].ParallelCall,
        label: this.get('i18n').t('transfer.parallel_call'),
        icon: 'icon-parallel-calling',
        visible: this.get('parallelCallEnabled'),
        disabled: this.get('hasActiveParallelCall')
      }];
    }),
    primaryCallGroupId: computed.alias('currentCall.groupId'),
    parallelCallGroupId: computed('parallelCalls.[]', function () {
      var _get = this.get('parallelCalls');

      var _get2 = _slicedToArray(_get, 1);

      var parallelCall = _get2[0];

      return parallelCall && parallelCall.get('id');
    }),
    callSwitchedObserver: observer('telephony.callSwitched', function () {
      if (this.get('telephony.callSwitched')) {
        this.set('isAgentsLoaded', false);
      }
    }),

    invokeLazySearch: function invokeLazySearch() {
      if (!this.get('searchString')) {
        this.setProperties({
          activeSearchTab: 'all',
          contacts: []
        });
      }

      var canShowResultTabs = this.canShowResultTabs();

      this.set('showResultTabs', canShowResultTabs);

      if (canShowResultTabs) {
        this.get('searchContacts').perform(this.get('searchString'));
      }

      /**
       * we are not observing  `searchingString` in `filteredRecords` and since we want to perform a
       * typeahead search, to reduce the backend hits we are debouncing the computation
       * which will be called at most 250 ms of every keystroke and since we are not watching for `searchString`
       * changes in `filteredRecords`, and to manually trigger the computation for the same `notifyPropertyChange`
       * is used
       */

      this.notifyPropertyChange('filteredRecords');
    },

    logError: function logError(err) {
      // eslint-disable-next-line no-console
      console.error('Some error occured', err);
    },

    filteredRecords: computed('teamsDetails.[]', 'agentDetails.[]', 'contacts.[]', 'activeSearchTab', function () {
      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return [];
      }

      var contacts = this.get('contacts');

      if (this.get('searchString')) {
        var _getFilteredRecords = this.getFilteredRecords();

        var agents = _getFilteredRecords.agents;
        var teams = _getFilteredRecords.teams;
        var activeSearchTab = this.get('activeSearchTab');

        once(this, this.updateSearchTab, agents.length, teams.length, contacts.length);

        if (activeSearchTab === 'agents') {
          return agents;
        } else if (activeSearchTab === 'teams') {
          return teams;
        } else if (activeSearchTab === 'contacts') {
          return contacts;
        }

        return [].concat(_toConsumableArray(teams), _toConsumableArray(agents), _toConsumableArray(contacts));
      }

      return [].concat(_toConsumableArray(this.get('teamsDetails')), _toConsumableArray(this.get('agentDetails')), _toConsumableArray(contacts));
    }),
    getFilteredRecords: function getFilteredRecords() {
      var exp = this.get('searchRegex');

      return {
        agents: this.get('agentDetails').filter(function (agent) {
          return exp.test(agent.name);
        }),
        teams: this.get('teamsDetails').filter(function (team) {
          return exp.test(team.name);
        })
      };
    },
    canShowResultTabs: function canShowResultTabs() {
      return (this.get('externalConferenceEnabled') || this.get('isExternalNumberRouteEnabled')) && this.get('searchString') && !this.get('isExternalTransferAllowed');
    },
    getSearchTabDisplayName: function getSearchTabDisplayName(name, translationPath, num) {
      return {
        displayName: _ember['default'].i18n.t(translationPath, { num: num }),
        name: name
      };
    },
    updateSearchTab: function updateSearchTab(agentsCount, teamsCount, contactsCount) {
      var _this = this;

      this.set('tabs', [['all', 'common.all'], ['agents', 'common.agents_count', agentsCount], ['teams', 'common.groups_count', teamsCount], ['contacts', 'common.contacts_count', contactsCount]].map(function (params) {
        return _this.getSearchTabDisplayName.apply(null, params);
      }));
    },
    filteredAgentsWithinSelectedTeam: computed('agentDetails.[]', 'selectedTeam.name', function () {
      var _this2 = this;

      return this.get('selectedTeam') ? this.get('selectedTeam').agents.map(function (agentId) {
        return _this2.get('agentDetails').find(function (agent) {
          return agent.id === agentId;
        });
      }).filter(function (agent) {
        return agent;
      }) : null;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        searchString: '',
        activeSearchTab: 'all',
        contacts: [],
        tabs: []
      });
    },

    updateCallStatus: function updateCallStatus() {
      this.get('telephony').updateCurrentCall(this.get('telephony').getCurrentCall());
      this.set('callInfoMsg', 'Call has been transferred to agent      ' + this.get('transferAgent.name'));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.$('.search-string').focus();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('telephony.event').unsubscribe('agent_status_change', this.agentStatusCallback.bind(this));
    },

    agentStatusCallback: function agentStatusCallback(data) {
      this.get('teamsDetails').setObjects(data.teams);
      this.get('agentDetails').setObjects(data.agents);
    },

    loadAgents: function loadAgents() {
      var _this3 = this;

      this.get('telephony.event').subscribe('agent_status_change', this.agentStatusCallback.bind(this));
      this.get('telephony.agent').getAvailableAgentsAndTeams().then(function (availableList) {
        run(function () {
          _this3.get('agentDetails').setObjects(availableList.agents);
          _this3.get('teamsDetails').setObjects(availableList.teams);
          _this3.toggleProperty('isAgentsLoaded');
        });
      });
    },
    stopConferencing: function stopConferencing() {
      var _this4 = this;

      run(function () {
        _this4.set('currentCall.attemptingTransferOrConference', false);
      });
    },
    searchContacts: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(queryString) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this5 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.prev = 0;

            if (!(queryString.length <= 1)) {
              context$1$0.next = 5;
              break;
            }

            this.set('contacts', []);
            context$1$0.next = 6;
            break;

          case 5:
            return context$1$0.delegateYield(regeneratorRuntime.mark(function callee$1$0() {
              var params, normalizedContacts, isOmniFeatureEnabled, searchModel, contacts;
              return regeneratorRuntime.wrap(function callee$1$0$(context$2$0) {
                while (1) switch (context$2$0.prev = context$2$0.next) {
                  case 0:
                    params = { filter: { queryString: queryString } }, normalizedContacts = [], isOmniFeatureEnabled = this.hasOmniRedirectFeatureEnabled();
                    searchModel = undefined;

                    if (isOmniFeatureEnabled) {
                      params.product = this.get('session.account.signupApp');
                      params.version = _freshfoneEmberConstantsSearchQueryConstants['default'].version;
                      searchModel = _freshfoneEmberConstantsSearchQueryConstants['default'].searchModel.externalContact;
                    } else {
                      params.source = _freshfoneEmberConstantsSearchQueryConstants['default'].source.dialler;
                      searchModel = _freshfoneEmberConstantsSearchQueryConstants['default'].searchModel.contact;
                    }
                    // eslint-disable-next-line sort-vars, one-var
                    context$2$0.next = 5;
                    return this.store.query(searchModel, params);

                  case 5:
                    contacts = context$2$0.sent;

                    contacts.forEach(function (contact) {
                      if (isOmniFeatureEnabled) {
                        normalizedContacts.push({
                          name: contact.get('name'),
                          category: 'Contact',
                          phoneNumber: contact.get('phoneNumberOrMobile')
                        });
                      } else {
                        contact.get('contactNumbers').forEach(function (contactNumber) {
                          normalizedContacts.push({
                            name: contact.get('name'),
                            category: 'Contact',
                            phoneNumber: contactNumber.get('phone_number')
                          });
                        });
                      }
                    });

                    this.set('contacts', normalizedContacts);

                  case 8:
                  case 'end':
                    return context$2$0.stop();
                }
              }, callee$1$0, _this5);
            })(), 't0', 6);

          case 6:
            context$1$0.next = 11;
            break;

          case 8:
            context$1$0.prev = 8;
            context$1$0.t1 = context$1$0['catch'](0);

            this.set('contacts', []);

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[0, 8]]);
    })).restartable(),
    addExternalNumber: function addExternalNumber(number, name) {
      var _this6 = this;

      run(function () {
        var groupId = _this6.get('hasActiveParallelCall') ? _this6.getActiveGroupId() : undefined;

        _this6.set('validatingNumber', true);
        _this6.get('telephony').getCurrentCall().initiateExternalConference(number, name, groupId).then(function () {
          run(function () {
            _this6.set('dropdownExpanded', false);
            _this6.set('validatingNumber', false);
            _this6.get('parallelCall').initializeConference(_freshfoneEmberConstantsTransferTypes['default'].ExternalConference, { name: name || number });
          });
        })['catch'](function (err) {
          run(function () {
            _this6.get('parallelCall').removeConference();
            _this6.set('isNumberInvalid', true);
            _this6.showErrorMsg(err, _this6.get('i18n').t('agent_conference.failed_to_initiate'));
            _this6.logError(err);
          });
        })['finally'](_this6.stopConferencing.bind(_this6));
      });
    },
    transferToExternalNumber: function transferToExternalNumber(type, number, name) {
      var _this7 = this;

      var stopValidation = function stopValidation() {
        _this7.setProperties({ validatingNumber: false });
      };

      (function () {
        if (type === 'BLIND') {
          _this7.set('validatingNumber', true);

          return _this7.get('telephony').getCurrentCall().transferToExternalNumber(number, name);
        }

        _this7.set('validatingNumber', true);

        return _this7.get('telephony').getCurrentCall().warmTransferToExternalNumber(number, name);
      })().then(function () {
        stopValidation();
        _this7.set('dropdownExpanded', false);
        _this7.updateCallStatus();
      })['catch'](function (error) {
        _this7.logError('error', error);
        var message = error.message || error.error;

        if (message) {
          _this7.get('notifications').error(message);
          _this7.set('errorMessage', message);
        }
        _this7.set('isNumberInvalid', true);
        stopValidation();
      })['finally'](function () {
        return _this7.set('currentCall.attemptingTransferOrConference', false);
      });
    },
    addAgent: function addAgent(agent) {
      var _this8 = this;

      var groupId = this.get('hasActiveParallelCall') ? this.getActiveGroupId() : undefined;

      this.get('telephony').getCurrentCall().initiateAgentConference(agent, groupId).then(function () {
        run(function () {
          _this8.get('parallelCall').initializeConference(_freshfoneEmberConstantsTransferTypes['default'].AgentConference, agent);
        });
      })['catch'](function (error) {
        run(function () {
          _this8.get('parallelCall').removeConference();
          _this8.showErrorMsg(error, _this8.get('i18n').t('agent_conference.failed_to_initiate'));
          _this8.logError(error);
        });
      })['finally'](this.stopConferencing.bind(this));
    },
    transferToAgent: function transferToAgent(type, agent) {
      var _this9 = this;

      this.get('telephony').getCurrentCall()[type === 'BLIND' ? 'transferToAgent' : 'warmTransferToAgent'](agent).then(function () {
        return run(function () {
          return _this9.updateCallStatus();
        });
      })['catch'](function (error) {
        return _this9.logError('Agent ' + type + ' Transfer Failed:', error);
      })['finally'](function () {
        return _this9.set('currentCall.attemptingTransferOrConference', false);
      });
    },
    transferToTeam: function transferToTeam(team) {
      var _this10 = this;

      this.get('telephony').getCurrentCall().transferToTeam(team).then(function () {
        return run(function () {
          return _this10.updateCallStatus();
        });
      })['catch'](function (error) {
        _this10.logError('Team Transfer Failed:', error);
        _this10.get('notifications').error(_ember['default'].i18n.t('transfer.failed_transfer'));
      })['finally'](function () {
        return run(function () {
          return _this10.set('currentCall.attemptingTransferOrConference', false);
        });
      });
    },
    updateCurrentCall: function updateCurrentCall() {
      var _this11 = this;

      run(function () {
        _this11.get('telephony').updateCurrentCall(_this11.get('telephony').getCurrentCall());
      });
    },
    initiateParallelCallToAgent: function initiateParallelCallToAgent(record) {
      var _this12 = this;

      return this.get('telephony').getCurrentCall().parallelCallToAgent(record)['catch'](function (error) {
        _this12.updateCurrentCall();
        _this12.logError('Failed to initiate parallel call to Agent: -', error);
        _this12.showErrorMsg(error, _this12.get('i18n').t('parallel_call.agent_parallel_call_failed'));
      });
    },
    initiateParallelCallToContact: function initiateParallelCallToContact(number, name) {
      var _this13 = this;

      this.set('validatingNumber', true);

      return this.get('telephony').getCurrentCall().parallelCallToExternalNumber(number, name).then(function () {
        return !name ? _this13.updateCurrentCall() : undefined;
      }).then(function () {
        return run(function () {
          return _this13.set('dropdownExpanded', false);
        });
      })['catch'](function (error) {
        run(function () {
          _this13.updateCurrentCall();
          _this13.logError('Failed to initiate parallel call to Contact/External number: -', error);
          _this13.showErrorMsg(error, _this13.get('i18n').t('parallel_call.contact_parallel_call_failed'));
          _this13.set('isNumberInvalid', true);
        });
      })['finally'](function () {
        return run(function () {
          return _this13.set('validatingNumber', false);
        });
      });
    },
    initiateParallelCallToTeam: function initiateParallelCallToTeam(team) {
      var _this14 = this;

      return this.get('telephony').getCurrentCall().parallelCallToTeam(team)['catch'](function (error) {
        run(function () {
          _this14.updateCurrentCall();
          _this14.logError('Failed to initiate parallel call to team: -', error);
          _this14.showErrorMsg(error, _this14.get('i18n').t('parallel_call.team_parallel_call_failed'));
        });
      });
    },
    initiateParallelCall: function initiateParallelCall(record) {
      if (!record) {
        return this.initiateParallelCallToContact(this.get('searchString'));
      }
      this.set('dropdownExpanded', false);
      switch (record.category.toLowerCase()) {
        case _freshfoneEmberConstantsTransferTypes['default'].Agent:
          this.initiateParallelCallToAgent(record);
          break;
        case _freshfoneEmberConstantsTransferTypes['default'].Contact:
          this.initiateParallelCallToContact(record.phoneNumber, record.name);
          break;
        case _freshfoneEmberConstantsTransferTypes['default'].Group:
          this.initiateParallelCallToTeam(record);
          break;
      }
    },
    initiateConference: function initiateConference(record) {
      this.$('[data-toggle=tooltip]').tooltip('hide');
      this.set('currentCall.attemptingTransferOrConference', true);

      if (!record) {
        return this.addExternalNumber(this.get('searchString'));
      }
      this.set('dropdownExpanded', false);
      switch (record.category.toLowerCase()) {
        case _freshfoneEmberConstantsTransferTypes['default'].Agent:
          this.addAgent(record);
          break;
        case _freshfoneEmberConstantsTransferTypes['default'].Contact:
          this.addExternalNumber(record.phoneNumber, record.name);
          break;
      }
    },
    initiateTransfer: function initiateTransfer(type, record) {
      this.$('[data-toggle=tooltip]').tooltip('hide');
      this.set('currentCall.attemptingTransferOrConference', true);

      if (!record) {
        return this.transferToExternalNumber(type, this.get('searchString'));
      }
      this.set('dropdownExpanded', false);
      switch (record.category.toLowerCase()) {
        case _freshfoneEmberConstantsTransferTypes['default'].Agent:
          this.transferToAgent(type, record);
          break;
        case _freshfoneEmberConstantsTransferTypes['default'].Contact:
          this.transferToExternalNumber(type, record.phoneNumber, record.name);
          break;
        case _freshfoneEmberConstantsTransferTypes['default'].Group:
          this.transferToTeam(record, event);
          break;
      }
    },
    showErrorMsg: function showErrorMsg(error, msg) {
      var message = error ? error.errors : msg;

      if (message) {
        this.get('notifications').error(message);
        this.set('errorMessage', message);
      }
    },
    getActiveGroupId: function getActiveGroupId() {
      var callType = this.get('currentCall.callType');

      return callType === _freshfoneEmberConstantsActiveCallType['default'].Parallel ? this.get('parallelCallGroupId') : this.get('primaryCallGroupId');
    },

    actions: {
      setCurrentTeam: function setCurrentTeam(team) {
        this.set('selectedTeam', team);
      },
      optionSelectedCallback: function optionSelectedCallback(_ref) {
        var id = _ref.id;
        var agent = _ref.agent;

        switch (id) {
          case _freshfoneEmberConstantsTransferOptions['default'].WarmTransfer:
            this.initiateTransfer('WARM', agent);
            break;
          case _freshfoneEmberConstantsTransferOptions['default'].ColdTransfer:
            this.initiateTransfer('BLIND', agent);
            break;
          case _freshfoneEmberConstantsTransferOptions['default'].AddParticipants:
            this.initiateConference(agent);
            break;
          case _freshfoneEmberConstantsTransferOptions['default'].ParallelCall:
            this.initiateParallelCall(agent);
            break;
        }
      },
      initiateConference: function initiateConference(record) {
        this.initiateConference(record);
      },
      initiateTransfer: function initiateTransfer(type, record) {
        this.initiateTransfer(type, record);
      },
      toggleActive: function toggleActive() {
        this.toggleProperty('isActive');
      },
      setActiveTabName: function setActiveTabName(tab) {
        this.set('activeSearchTab', tab);
      },
      closeTransfer: function closeTransfer() {
        this.send('closeDropdown');
      },
      initiateParallelCallForTeam: function initiateParallelCallForTeam(record) {
        this.initiateParallelCall(record);
      }
    }
  });
});
/* eslint-disable ember-suave/no-direct-property-access */

// eslint-disable-next-line sort-vars