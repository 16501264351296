define("freshfone-ember/helpers/event-details", ["exports", "ember"], function (exports, _ember) {
  exports.eventDetails = eventDetails;

  function eventDetails(params) {
    var content = params[0];
    var type = params[1];
    var agentNames = content ? content.split(",") : "";

    if (type == "details") {
      var text = agentNames[0] + ", " + agentNames[1];
      return text;
    } else if (type == "count") {
      return agentNames.length - 2;
    } else if (type == "split") {
      return agentNames.length > 2;
    } else if (type == "tooltip") {
      var tooltip = agentNames[2];
      for (var i = 3; i < agentNames.length; ++i) {
        tooltip += " <br/> ";
        tooltip += agentNames[i];
      }
      return tooltip;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(eventDetails);
});
// This is a helper for the tooltip in call lifecycle details of call metrics when the event has occured to multiple agents at same time.