define('freshfone-ember/components/call-logs/call-filters-v1/component', ['exports', 'freshfone-ember/utils/call-constants', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/call-logs-filter', 'moment'], function (exports, _freshfoneEmberUtilsCallConstants, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsCallLogsFilter, _moment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var equal = _ember['default'].computed.equal;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    currentUser: computed.alias('session.user'),
    signupApp: computed.alias('session.account.signupApp'),
    isUcrEnabled: computed(function () {
      return this.isUcrSearchEnabled();
    }),
    numberQueryParams: computed(function () {
      return this.checkFeature('data_scoping') ? {
        deleted: false,
        paginate: false
      } : null;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.loadSavedDateRange();
    },
    loadSavedDateRange: function loadSavedDateRange() {
      var _get = this.get('filterConfiguration.by_date');

      var start_date = _get.start_date;
      var end_date = _get.end_date;
      var timeperiod = _get.timeperiod;

      if (start_date) {
        end_date = start_date === end_date ? null : (0, _moment['default'])(end_date, 'DD MM YYYY');
        start_date = (0, _moment['default'])(start_date, 'DD MM YYYY');

        this.get('timePeriod').pushObjects([start_date, end_date, timeperiod]);
      }
    },
    click: function click(event) {
      if (event.target.parentNode.className.includes('applied-filter-container')) {
        event.stopPropagation();
      }
    },

    hasFilters: computed('filtersCount', function () {
      var _this = this;

      /*
       * ember-power-select removes selection on mousedown, thus raising whole power-select-container
       * this is to restrain the filter-tags-container with currentHeight until user raises the mouse up
       */
      if (this.get('filtersCount') < 1 && this.element && this.element.querySelector('.applied-filters')) {
        this.set('noFiltersAndMouseDown', true);
        run.later(this, function () {
          return _this.set('noFiltersAndMouseDown', false);
        }, 200);
      }

      return this.get('filtersCount') >= 1;
    }),

    timePeriodVal: computed('timePeriod.[]', function () {
      var _get2 = this.get('timePeriod');

      var _get22 = _slicedToArray(_get2, 3);

      var start_date = _get22[0];
      var end_date = _get22[1];
      var timeperiod = _get22[2];

      if (start_date) {
        start_date = start_date.format('DD MMM YYYY');
      }
      if (end_date) {
        end_date = end_date.format('DD MMM YYYY');
      }

      if (start_date) {
        this.set('filterConfiguration.by_date', {
          start_date: start_date,
          end_date: end_date,
          timeperiod: timeperiod
        });

        this.set('timePeriodLabel', timeperiod);

        if (end_date) {
          return [start_date, end_date].join('-');
        }
      }

      return start_date;
    }),
    isTimePeriodLastSevenDays: equal('timePeriodLabel', _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodLastSevenDays),
    callStatusCollection: computed(function () {
      var callStatuses = [_freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.answered, _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.missed, _freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.abandoned];

      if (this.hasFeatures(['voicemail_and_status_fallback'])) {
        callStatuses.push(_freshfoneEmberUtilsCallConstants['default'].STATUS_TYPE_HASH.voicemail);
      }

      return callStatuses;
    }),
    callTypeCollection: computed(function () {
      return [_freshfoneEmberUtilsCallConstants['default'].TYPE_HASH.incoming, _freshfoneEmberUtilsCallConstants['default'].TYPE_HASH.outgoing];
    }),
    callQueueOptions: computed(function () {
      return $.getJSON('/call_queues/list').then(function (data) {
        return data.data.map(function (ele) {
          return {
            id: ele.id,
            name: ele.attributes.name
          };
        });
      });
    }),
    waitQueueManagementOptions: computed(function () {
      return _freshfoneEmberUtilsCallConstants['default'].WAIT_QUEUE_ACTIONS_HASH;
    }),
    businessHourOptions: computed(function () {
      return [_freshfoneEmberUtilsCallConstants['default'].BUSINESS_HOUR_CALL_HASH.within, _freshfoneEmberUtilsCallConstants['default'].BUSINESS_HOUR_CALL_HASH.outside];
    }),

    reloadOnClose: observer('isModalOpen', function () {
      if (this.get('shouldReloadOnClose') && this.get('isDefaultFilters')) {
        this.get('clearFilters')();
      }

      this.set('shouldReloadOnClose', false);
    }),
    setLastSevenDaysDefault: function setLastSevenDaysDefault() {
      return [(0, _moment['default'])().subtract(6, 'days'), (0, _moment['default'])(), _freshfoneEmberConstantsCallLogsFilter['default'].callTimePeriodLastSevenDays];
    },

    resetAllFilters: function resetAllFilters() {
      this.set('serviceToggle', false);
      this.set('powerDialerCalls', false);
      this.set('numbers', []);
      this.set('timePeriod', [].concat(_toConsumableArray(this.setLastSevenDaysDefault())));
      this.set('callStatuses', []);
      this.set('callType', null);
      this.set('businessHourCall', null);
      this.set('contacts', []);
      this.set('agents', []);
      this.set('waitQueueActions', []);
      this.set('callQueues', []);
      this.set('teams', []);
      this.set('tags', []);
    },

    actions: {
      removeFilter: function removeFilter(propertyName, isArray, recordIndex) {
        if (isArray) {
          this.get(propertyName).removeAt(recordIndex);
        } else if (propertyName === 'serviceToggle' || propertyName === 'powerDialerCalls') {
          this.set(propertyName, false);
        } else {
          this.set(propertyName, null);
        }
      },
      // explicitly clearing this because, it's structure is different from other filter rows
      clearTimePeriod: function clearTimePeriod() {
        this.set('timePeriod', [].concat(_toConsumableArray(this.setLastSevenDaysDefault())));
      },
      clearAllFilters: function clearAllFilters() {
        if (!this.get('isDefaultFilters')) {
          this.resetAllFilters();
          this.set('shouldReloadOnClose', true);
        }
      },
      filterCalls: function filterCalls() {
        this.get('filterCallLogs')();
        this.set('shouldReloadOnClose', false);
      }
    }
  });
});
/* eslint-disable prefer-const */
/* eslint-disable sort-imports */
/* eslint-disable camelcase */