define('freshfone-ember/components/banner-component/chritmas-banner/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    didInsertElement: function didInsertElement() {
      this.startSnow();
    },
    startSnow: function startSnow() {
      var container = document.getElementById('tiffany-snow');
      var canvas = container.getContext("2d");
      var w = container.width = window.innerWidth;
      var h = container.height = window.innerHeight;
      var snow = undefined,
          arr = [];
      var num = 600,
          tsc = 1,
          sp = 1;
      var sc = 1,
          t = 0,
          mv = 20,
          min = 1;
      for (var i = 0; i < num; ++i) {
        snow = new Flake();
        snow.y = Math.random() * (h + 50);
        snow.x = Math.random() * w;
        snow.t = Math.random() * (Math.PI * 2);
        snow.sz = 100 / (10 + Math.random() * 100) * sc;
        snow.sp = Math.pow(snow.sz * .8, 2) * .15 * sp;
        snow.sp = snow.sp < min ? min : snow.sp;
        arr.push(snow);
      }

      go();

      function go() {
        window.requestAnimationFrame(go);
        canvas.clearRect(0, 0, w, h);
        canvas.fill();
        for (var i = 0; i < arr.length; ++i) {
          var flake = arr[i];
          flake.t += .05;
          flake.t = flake.t >= Math.PI * 2 ? 0 : flake.t;
          flake.y += flake.sp;
          flake.x += Math.sin(flake.t * tsc) * (flake.sz * .3);
          if (flake.y > h + 50) flake.y = -10 - Math.random() * mv;
          if (flake.x > w + mv) flake.x = -mv;
          if (flake.x < -mv) flake.x = w + mv;
          flake.draw();
        }
      }

      function Flake() {
        this.draw = function () {
          this.g = canvas.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.sz);
          this.g.addColorStop(0, 'hsla(255,255%,255%,1)');
          this.g.addColorStop(1, 'hsla(255,255%,255%,0)');
          canvas.moveTo(this.x, this.y);
          canvas.fillStyle = this.g;
          canvas.beginPath();
          canvas.arc(this.x, this.y, this.sz, 0, Math.PI * 2, true);
          canvas.fill();
        };
      }
    }
  });
});