define('freshfone-ember/services/toastr', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Service = _ember['default'].Service;
  var on = _ember['default'].on;
  exports['default'] = Service.extend({
    i18n: service(),
    initToaster: on('init', function () {
      toastr.options = {
        closeButton: true,
        closeHtml: '<span class="icon-close" />',
        positionClass: 'toast-top-center',
        preventDuplicates: false,
        progressBar: false,

        showDuration: 200,
        hideDuration: 200,
        closeDuration: 200,

        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        closeMethod: 'fadeOut'
      };
    }),

    generateHtml: function generateHtml(type, message) {
      var alertClass = 'icon-' + type + '-alert',
          alertType = this.get('i18n').t('toastr.' + type);

      return '\n      <i class=' + alertClass + '></i>\n      <div class="msg-container">\n        <span class="toast-type">' + alertType + '</span>\n        <span class="msg-content">' + message + '</span>\n      </div>';
    },

    clear: function clear() {
      toastr.clear();
    },

    success: function success(message, title) {
      var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      toastr.success(this.generateHtml('success', message), title, options);
    },

    info: function info(message, title) {
      var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      toastr.info(this.generateHtml('info', message), title, options);
    },

    warning: function warning(message, title) {
      var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      toastr.warning(this.generateHtml('warning', message), title, options);
    },

    error: function error(message, title) {
      var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      toastr.error(this.generateHtml('error', message), title, options);
    }
  });
});