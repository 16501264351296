define('freshfone-ember/initializers/inject-store-in-components', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('component', 'store', 'service:store');
  }

  exports['default'] = {
    name: 'inject-store-in-components',
    initialize: initialize
  };
});