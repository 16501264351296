define('freshfone-ember/helpers/assign', ['exports', 'ember-assign-helper/helpers/assign'], function (exports, _emberAssignHelperHelpersAssign) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberAssignHelperHelpersAssign['default'];
    }
  });
  Object.defineProperty(exports, 'assign', {
    enumerable: true,
    get: function get() {
      return _emberAssignHelperHelpersAssign.assign;
    }
  });
});