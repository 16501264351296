define('freshfone-ember/components/ongoing-widget/transfer/queue-transfer/component', ['exports', 'ember', 'freshfone-ember/constants/call-queues'], function (exports, _ember, _freshfoneEmberConstantsCallQueues) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var A = _ember['default'].A;
  var Component = _ember['default'].Component;
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  var $ = _ember['default'].$;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  var run = _ember['default'].run;
  var debounce = _ember['default'].run.debounce;
  var testing = _ember['default'].testing;
  var observer = _ember['default'].observer;
  var Object = _ember['default'].Object;
  exports['default'] = Component.extend({
    store: service(),
    telephony: service(),
    isCallQueuesLoaded: false,
    hideCallQueueList: false,
    dropDownOpen: false,
    isMoreQueuesLoading: false,
    callQueueDetails: A(),
    isSearched: null,
    noSearchResults: false,
    searchString: '',
    searchStringObserver: observer('searchString', function () {
      debounce(this, this.invokeLazySearch, testing ? 0 : 250);
    }),
    totalPages: 0,
    currentPage: 0,
    perPage: 5,
    getAvailableUsersPollLimit: 20,
    queuePollingTimerId: null,
    searchPlaceholder: computed(function () {
      return this.get('i18n').t('transfer.search_placeholder');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      scheduleOnce('afterRender', this, 'fetchCallQueues', 10);
    },
    pollUsers: function pollUsers() {
      var timeIntervalForPolling = testing ? _freshfoneEmberConstantsCallQueues.recentCallQueues.pollingTimeTest : _freshfoneEmberConstantsCallQueues.recentCallQueues.pollingTime;

      this.set('queuePollingTimerId', this.getQueuePollingTimerId(timeIntervalForPolling));
    },
    getQueuePollingTimerId: function getQueuePollingTimerId(pollingTime) {
      var _this = this;

      return setInterval(function () {
        return run(function () {
          return _this.pollAvailableUsers();
        });
      }, pollingTime);
    },
    buildParams: function buildParams(page, searchString) {
      var params = { page: page };

      if (searchString) {
        params['filter[queryString]'] = searchString;
        params['type[]'] = [_freshfoneEmberConstantsCallQueues.recentCallQueues.callQueue, _freshfoneEmberConstantsCallQueues.recentCallQueues.ivr];
      } else {
        params['per_page'] = this.get('perPage');
        params['type'] = _freshfoneEmberConstantsCallQueues.recentCallQueues.recentCallFlows;
      }

      return params;
    },
    pollAvailableUsers: function pollAvailableUsers() {
      var callQueuesLoaded = this.store.peekAll('call-flow').content;

      this.getAvailableUsers();

      if (testing && callQueuesLoaded.length === 0) {
        clearInterval(this.get('queuePollingTimerId'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearInterval(this.get('queuePollingTimerId'));
    },
    fetchCallQueues: function fetchCallQueues() {
      var _this2 = this;

      if (this.get('dropDownOpen')) {
        this.store.unloadAll('call-flow');
        this.set('isCallQueuesLoaded', false);
        var params = this.buildParams(1);

        this.fetchCallFlows(params).then(function (callFlows) {
          return _this2.set('callQueueDetails', callFlows);
        }).then(function () {
          _this2.getAvailableUsers();
          run(function () {
            return _this2.set('isCallQueuesLoaded', true);
          });
        });
      }
    },
    fetchCallFlows: function fetchCallFlows(params) {
      var _this3 = this;

      return this.store.query('call-flow', params).then(function (callFlows) {
        _this3.setProperties({
          totalPages: callFlows.get('meta.total-pages'),
          currentPage: callFlows.get('meta.current')
        });

        return callFlows;
      });
    },
    getAvailableUsers: function getAvailableUsers() {
      var _this4 = this;

      var callQueueIdList = this.get('callQueueDetails').mapBy('id');

      if (callQueueIdList.length) {
        this.fetchAvailableAgents(callQueueIdList).then(function (availableUsersInfo) {
          run(function () {
            if (_this4.get('isDestroyed') || _this4.get('isDestroying')) {
              return;
            }
            var availableUsersCountDetails = {};

            availableUsersInfo.forEach(function (availaleUserDetail) {
              availableUsersCountDetails['' + availaleUserDetail.id] = availaleUserDetail.available_users_count;
            });
            _this4.set('availableUsersCountDetails', Object.create(availableUsersCountDetails));
          });
        });
      }
    },
    fetchAvailableAgents: function fetchAvailableAgents(idsArray) {
      var _this5 = this;

      var avaialableUsersDetails = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      var ids = idsArray.splice(0, this.get('getAvailableUsersPollLimit'));

      return $.getJSON('/call_flows/available_users', {
        type: 'call_queue',
        ids: ids
      }).then(function (results) {
        avaialableUsersDetails.push.apply(avaialableUsersDetails, _toConsumableArray(results.data));

        return idsArray.length ? _this5.fetchAvailableAgents(idsArray, avaialableUsersDetails) : avaialableUsersDetails;
      });
    },
    searchCallQueues: function searchCallQueues(params) {
      var _this6 = this;

      this.fetchCallFlows(params).then(function (callFlows) {
        run(function () {
          _this6.setProperties({
            callQueueDetails: callFlows,
            totalPages: callFlows.get('meta.total-pages'),
            currentPage: callFlows.get('meta.current')
          });
        });

        return callFlows;
      }).then(function (callFlows) {
        if (callFlows.get('content').length) {
          _this6.getAvailableUsers();

          run(function () {
            return _this6.setProperties({
              isCallQueuesLoaded: true,
              isSearched: true
            });
          });
        } else {
          run(function () {
            _this6.setProperties({
              noSearchResults: true,
              isCallQueuesLoaded: true
            });
          });
        }
      });
    },
    invokeLazySearch: function invokeLazySearch() {
      var _this7 = this;

      run(function () {
        _this7.store.unloadAll('call-flow');
        _this7.setProperties({
          isCallQueuesLoaded: false,
          noSearchResults: false
        });
      });
      if (!this.get('searchString')) {
        this.set('isSearched', false);
        this.fetchCallQueues();

        return;
      }

      var params = this.buildParams(1, this.get('searchString'));

      this.searchCallQueues(params);
    },

    actions: {
      hideList: function hideList() {
        var _this8 = this;

        run(function () {
          _this8.store.unloadAll('call-flow');
          _this8.setProperties({
            hideCallQueueList: true,
            dropDownOpen: false
          });
        });
        clearInterval(this.get('queuePollingTimerId'));
      },
      onDropDownOpen: function onDropDownOpen() {
        var _this9 = this;

        if (this.get('disabled')) {
          return;
        }
        run(function () {
          _this9.setProperties({
            hideCallQueueList: false,
            dropDownOpen: true,
            searchString: ''
          });
        });
        this.fetchCallQueues();
        this.pollUsers();
      },
      fetchMoreQueues: function fetchMoreQueues() {
        var _this10 = this;

        this.set('isMoreQueuesLoading', true);
        var params = this.get('searchString') ? this.buildParams(this.get('currentPage') + 1, this.get('searchString')) : this.buildParams(this.get('currentPage') + 1);

        this.fetchCallFlows(params).then(function (callFlows) {
          return _this10.set('callQueueDetails', [].concat(_toConsumableArray(_this10.get('callQueueDetails').toArray()), _toConsumableArray(callFlows.toArray())));
        }).then(function () {
          _this10.getAvailableUsers();
          run(function () {
            return _this10.set('isMoreQueuesLoading', false);
          });
        });
      },
      forwardToQueue: function forwardToQueue(queueId, queueName) {
        this.set('hideCallQueueList', true);
        this.get('telephony').set('isQueueTransferInitiated', true);
        this.sendAction('forwardToQueue', queueId, queueName);
      }
    }
  });
});
/* eslint-disable dot-notation */
/* eslint-disable camelcase */