define('freshfone-ember/mixins/cleanup-tooltips', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;

  var tooltipMutationObserver = undefined;

  exports['default'] = Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      tooltipMutationObserver = new MutationObserver(function () {
        var tooltipElement = document.querySelector('body > .tooltip');

        if (tooltipElement && !document.querySelector('[aria-describedby=' + tooltipElement.id + ']')) {
          $('body > .tooltip').tooltip('destroy');
        }
      });

      tooltipMutationObserver.observe(document.querySelector('.base-component'), {
        childList: true,
        subtree: true
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (tooltipMutationObserver) {
        tooltipMutationObserver.disconnect();
      }
    }
  });
});