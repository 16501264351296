define('freshfone-ember/utils/regex-utils', ['exports'], function (exports) {
	var regexUtil = {
		_EMAIL_REGEX: /^[-a-zA-Z0-9.'’&_%+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/,
		_SUBJECT_REGEX: /^((FW: )+|(Re: )+|(Re:)+|(RE: )+|(Fwd: )+|(Fwd:)+)/,
		_CONTENT_REGEX: /\[http(s{0,1}):\/\/.*\]/i,
		_DOMAIN_REGEX: /^([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.){1,5}[a-zA-Z]{2,15}$/,
		_URL_REGEX: /^(f|ht)tps?:\/\//i,
		_SPECIAL_CHAR_REGEX: /[.*+?^${}()|[\]\\]/g,

		get: function get(key) {
			return this["_" + key];
		},
		emailCheck: function emailCheck(email) {
			return email ? email.match(this._EMAIL_REGEX) : false;
		},
		subjectTrim: function subjectTrim(subject) {
			return subject ? subject.replace(this._SUBJECT_REGEX, '') : '';
		},
		contentTrim: function contentTrim(content) {
			return content ? content.replace(this._CONTENT_REGEX, '').trim() : '';
		},
		domainCheck: function domainCheck(domain) {
			return domain ? domain.match(this._DOMAIN_REGEX) : false;
		},
		prependHttp: function prependHttp(url) {
			var regex = new RegExp("^(http|https)://", 'gi');
			return url && !regex.test(url) ? "http://" + url : url;
		},
		validUrl: function validUrl(url) {
			return this._URL_REGEX.test(url);
		},
		escapeSpecialChar: function escapeSpecialChar(input) {
			return input.replace(this._SPECIAL_CHAR_REGEX, "\\$&");
		}
	};

	exports['default'] = regexUtil;
});