define('freshfone-ember/components/account-settings/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/mixins/integration-check-mixin'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberMixinsIntegrationCheckMixin) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var reads = _Ember$computed.reads;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], _freshfoneEmberMixinsIntegrationCheckMixin['default'], {
    i18n: service(),
    accountConfigurationService: service('account-configuration'),
    notification: service('toastr'),
    accountConfiguration: alias('accountConfigurationService.config'),
    networkWarningLogs: alias('accountConfiguration.networkWarningLogs'),
    autoAnswerEnabled: alias('accountConfiguration.autoAnswerEnabled'),
    autoAnswerThreshold: alias('accountConfiguration.autoAnswerThreshold'),
    autoAnswerThresholdErrors: alias('accountConfiguration.errors.autoAnswerThreshold'),
    pageRenderedInCrm: computed(function () {
      return this.hasGlobalFeature('caller_pages_in_crm_global') && this.checkFeature('caller_pages_in_crm') && this.get('isCrmView');
    }),
    pageTitle: computed('pageRenderedInCrm', function () {
      return this.get('i18n').t(this.get('pageRenderedInCrm') ? 'admin_components.additional_settings' : 'admin_components.account_settings');
    }),
    isSaveEnabled: computed(function () {
      return this.hasFeatures(['auto_answer']) && this.hasGlobalFeature('auto_answer') || this.hasFeatures(['customise_wq_behaviour']) && this.hasGlobalFeature('customise_wq_behaviour');
    }),
    isSaving: reads('accountConfiguration.isSaving'),
    disableFormActions: computed('accountConfiguration.{isValid,hasDirtyAttributes}', 'isSaving', function () {
      return !this.get('accountConfiguration.hasDirtyAttributes') || this.get('isSaving') || !this.get('accountConfiguration.isValid');
    }),
    actions: {
      cancel: function cancel() {
        this.get('accountConfiguration').rollbackAttributes();
      },
      save: function save() {
        return this.saveConfig();
      },
      onNetworkWarningToggle: function onNetworkWarningToggle() {
        if (!(this.get('accountConfiguration.hasGlobalAnswerFeature') || this.get('accountConfiguration.hasCustomWQFeature'))) {
          return this.saveConfig();
        }
      }
    },
    saveConfig: function saveConfig() {
      var _this = this;

      return this.get('accountConfiguration').validate().then(function () {
        return _this.get('accountConfigurationService').updateConfig().then(function () {
          return _this.get('notification').success(_this.get('i18n').t('account_settings.save_success_message'));
        })['catch'](function () {
          _this.get('accountConfiguration').rollbackAttributes();
          _this.get('notification').error(_this.get('i18n').t('account_settings.save_failure'));
        });
      });
    }
  });
});