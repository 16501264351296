define('freshfone-ember/components/audio-player/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var soundManager = window.soundManager;
  var PlayState = {
    PLAYING: 1,
    PAUSED: 0
  };exports['default'] = Component.extend({
    classNames: ['audio-player'],
    classNameBindings: ['soundId'],
    hasUrl: computed.notEmpty('url'),
    soundId: computed.alias('sound.id'),
    showAudioSeek: computed.or('isPlaying', 'isPaused'),

    init: function init() {
      this._super.apply(this, arguments);
      this.createSound();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var sound = this.get('sound');

      if (sound) {
        sound.stop();
      }
    },

    isChromeOrSafari: computed(function () {
      return window.chrome || window.safari;
    }),

    totalTime: computed('url', 'totalDuration', 'duration', function () {
      var sound = this.get('sound');

      var nMSec = sound.position;

      if (isNaN(nMSec) || sound.playState === 0) {
        var duration = this.get('duration') || this.get('totalDuration') / 1000;

        nMSec = parseInt(duration, 10) * 1000;
      }

      return nMSec;
    }),

    formattedDuration: computed('url', 'currentDuration', function () {
      // convert milliseconds to mm:ss
      var sound = this.get('sound');

      var nMSec = sound.position;

      if (isNaN(nMSec) || sound.playState === 0) {
        var duration = this.get('duration') || this.get('totalDuration') / 1000;

        nMSec = parseInt(duration, 10) * 1000;
      }
      if (isNaN(nMSec)) {
        return '--:--';
      }

      var nSec = Math.floor(nMSec / 1000),
          min = Math.floor(nSec / 60),
          sec = nSec - min * 60;

      return min + ':' + (sec < 10 ? '0' + sec : sec);
    }),

    createSound: function createSound() {
      var _this = this;

      var sound = soundManager.createSound({
        url: this.get('url'),
        type: 'audio/mp3',

        onload: function onload() {
          _this.set('isLoaded', true);
        },
        onplay: function onplay() {
          _this.setProperties({
            isPlaying: true,
            isPaused: false
          });
        },
        onpause: function onpause() {
          _this.setProperties({
            isPlaying: false,
            isPaused: true
          });
        },
        onresume: function onresume() {
          _this.setProperties({
            isPlaying: true,
            isPaused: false
          });
        },
        onstop: function onstop() {
          _this.setProperties({
            isPlaying: false,
            isPaused: false
          });
        },
        onfinish: function onfinish() {
          _this.setProperties({
            isPlaying: false,
            isPaused: false
          });

          if (_this.element) {
            var input = _this.element.querySelectorAll('input[type=range]')[0];

            input.value = Math.round(sound.position);
            if (_this.get('isChromeOrSafari')) {
              var val = (input.value - input.min) / (input.max - input.min);

              input.style.backgroundImage = _this.applyGradient(val);
              input.style.borderRadius = '0 0 4px 4px';
            }
            _this.set('currentDuration', sound.position);
          }
        },

        whileplaying: function whileplaying() {
          if (_this.element) {
            var input = _this.element.querySelectorAll('input[type=range]')[0];

            input.value = Math.round(sound.position);
            if (_this.get('isChromeOrSafari')) {
              var val = (input.value - input.min) / (input.max - input.min);

              input.style.backgroundImage = _this.applyGradient(val);
              input.style.borderRadius = '0 0 4px 4px';
            }
          }
          _this.set('currentDuration', sound.position);
        }
      });

      this.set('sound', sound);
    },

    applyGradient: function applyGradient(val) {
      return 'linear-gradient(to right, #2259CC ' + val * 100 + '%, #9BB6E7 ' + val * 100 + '%)';
    },

    actions: {
      onChange: function onChange(e) {
        var element = e.target,
            sound = this.get('sound');

        sound.setPosition(element.value);
        if (sound.playState === PlayState.PAUSED) {
          sound.play();
        }
      },
      togglePlay: function togglePlay() {
        this.element.getElementsByClassName('play')[0].classList.toggle('active');
        var sound = this.get('sound');

        if (sound) {
          if (this.get('isPlaying')) {
            sound.togglePause();
          } else {
            soundManager.pauseAll();
            sound.play();
          }
        }
      }
    }
  });
});