define('freshfone-ember/mixins/push-payload', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    store: service(),
    pushPayload: function pushPayload(payload) {
      var _this = this;

      this.store.pushPayload(payload);

      if (Array.isArray(payload.data)) {
        return payload.data.map(function (_ref) {
          var id = _ref.id;
          var type = _ref.type;
          return _this.store.peekRecord(type, id);
        });
      }

      var _payload$data = payload.data;
      var id = _payload$data.id;
      var type = _payload$data.type;

      return this.store.peekRecord(type, id);
    }
  });
});