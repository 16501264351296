define('freshfone-ember/components/number-component/numbers-list/business-hours-info/component', ['exports', 'ember', 'freshfone-ember/mixins/feature-check-mixin', 'freshfone-ember/constants/static-urls'], function (exports, _ember, _freshfoneEmberMixinsFeatureCheckMixin, _freshfoneEmberConstantsStaticUrls) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_freshfoneEmberMixinsFeatureCheckMixin['default'], {
    tagName: '',
    hasFeatureBusinessHour: computed(function () {
      return this.hasFeatures(['business_hours']);
    }),
    chatBusinessHourUrl: computed(function () {
      return _freshfoneEmberConstantsStaticUrls['default'].chatBusinessHourUrl.replace('{businessId}', this.get('rule').get('businessCalendarId'));
    }),
    isEligibleForCrmFlow: computed(function () {
      return this.get('has1CXFeature') && this.get('rule').get('businessCalendarCategory') !== 0;
    })
  });
});